import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { AsyncLoadStatus } from '../../../common/types';
import InputCheckbox from '../../../components/inputs/InputCheckbox';
import { ScreenTitle } from '../../../components/layout/ScreenTitle';
import PanelContainer from '../../../containers/PanelContainer';
import { brandName } from '../../../store/global.state';
import {
  userSettings,
  userSettingsLoadStatus,
} from '../../../store/warehouse.state';
import useUpdateUserSettings from '../../../warehouse/hooks/useUpdateUserSettings';
import SettingsNotificationEmail from '../settings/SettingsNotificationEmail';

export type PanelProps = PropsWithChildren & {
  className?: string;
};

const PanelSettingsNotification: React.FC<PanelProps> = props => {
  const { t } = useTranslation('app');
  const brandTitle = useRecoilValue(brandName);

  const settings = useRecoilValue(userSettings);
  const settingsStatus = useRecoilValue(userSettingsLoadStatus);
  const updateUserSettings = useUpdateUserSettings();

  return (
    <PanelContainer
      id="panel-system-notifications"
      title={t`Notification Settings`}
      collapsible
    >
      <ScreenTitle isSticky subtitle={t`System`} title={t`Notifications`} />
      <section className="divide-menu-400/50 mb-4 w-full divide-y divide-solid p-4">
        <InputCheckbox
          name="notification"
          className="p-2"
          isDisabled={settingsStatus === AsyncLoadStatus.Loading}
          isSelected={settings?.notifications?.simulations ?? false}
          onChange={v =>
            updateUserSettings({
              notifications: {
                simulations: v,
              },
            })
          }
        >
          <div>{t`Simulation Notifications`}</div>
        </InputCheckbox>
        <InputCheckbox
          name="notification"
          className="p-2"
          isDisabled={settingsStatus === AsyncLoadStatus.Loading}
          isSelected={settings?.notifications?.news ?? false}
          onChange={v =>
            updateUserSettings({
              notifications: {
                news: v,
              },
            })
          }
        >
          <div>{t('{{ brandTitle }} News', { brandTitle })}</div>
        </InputCheckbox>
      </section>

      <SettingsNotificationEmail />
    </PanelContainer>
  );
};

export default PanelSettingsNotification;
