import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { getQualitativeColor } from '../../common/color.helper';
import {
  formatInteger,
  formatTimeAgo,
  formatTimespan,
} from '../../common/formatHelper';
import { PanelBody } from '../../components/designer/panels/PanelBody';
import InboxZero from '../../components/InboxZero';
import { InputSearch } from '../../components/inputs/InputSearch';
import { Stat } from '../../components/stats/Stat';
import { StatGroup } from '../../components/stats/StatGroup';
import { actualityResourcePolicy } from '../../feed/store/actuality.state';
import { AgentCategoryId } from '../../resourcePolicy/agentData/agent.types';
import { getAgentIcon } from '../../resourcePolicy/agentData/agentDataHelper';
import {
  feedAgentsSummary,
  feedDistinctAgents,
  feedSelectedAgentId,
} from '../store/feed.state';

const LiveFeedPanel: React.FC = () => {
  const { t } = useTranslation('feed');

  const deviceSummary = useRecoilValue(feedAgentsSummary);
  const [selectedDevice, setSelectedDevice] =
    useRecoilState(feedSelectedAgentId);

  const allAgents = useRecoilValue(feedDistinctAgents);
  const resourcePolicy = useRecoilValue(actualityResourcePolicy);
  const agentMapByResourcePolicy = _.keyBy(resourcePolicy.agents, 'id');
  const agentMapByData = _.keyBy(allAgents, 'agentId');

  const [searchTerm, setSearchTerm] = React.useState('');

  const filteredResources = React.useMemo(() => {
    if (!searchTerm) return deviceSummary;
    return deviceSummary.filter(device =>
      device.agentId.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }, [deviceSummary, searchTerm]);

  const filteredResourcesTotalCount = filteredResources.length;

  function selectDevice(device: string) {
    const isReset = device === selectedDevice;
    // setViewAs(isReset ? 'total' : 'route');
    setSelectedDevice(isReset ? null : device);
  }

  const hasDeviceData = _.size(deviceSummary) > 0;

  return (
    <PanelBody data-component="LiveFeedResourcePanel">
      <div className={classNames('')}>
        <StatGroup
          title={
            <InputSearch
              placeholder={t('By {{resource}}{{counter}}', {
                resource: 'Resource',
                counter: filteredResourcesTotalCount
                  ? ` (${filteredResourcesTotalCount})`
                  : '',
              })}
              value={searchTerm}
              onChange={(value: string) => setSearchTerm(value)}
              panelTitleMode
              classNamePlaceholder="!left-2 !top-2"
              classNameInput="!px-2 !py-1"
            />
          }
          helpNavTo={'live/feed'}
        >
          {!hasDeviceData && <InboxZero selfCenter message={t`No Data`} />}
          {hasDeviceData && !filteredResourcesTotalCount && (
            <InboxZero selfCenter message={t`No Results Found`} />
          )}

          {_(filteredResources)
            .sortBy(d => -d.totalEvents)
            .map(resource => {
              const activityTimeAgo = formatTimeAgo(new Date(resource.maxTime));
              const isSelected = selectedDevice === resource.agentId;
              const deviceColors = getQualitativeColor(
                resource.agentId,
                'dimension',
              );

              return (
                <Stat
                  key={`resource-${resource.agentId}`}
                  inPanelMode
                  isPreview
                  isSelected={isSelected}
                  legendColor={isSelected ? null : deviceColors}
                  color={isSelected ? deviceColors : null}
                  titleElement={
                    <div className={classNames('flex w-full items-center')}>
                      <span className={classNames('flex-1')}>
                        {resource.agentId}
                      </span>
                      <span
                        title={t(`timestamp: {{deviceMaxTime}}`, {
                          deviceMaxTime: new Date(resource.maxTime),
                        })}
                        className={classNames('text-xxs opacity-75')}
                      >
                        {activityTimeAgo}
                      </span>
                    </div>
                  }
                  icon={getAgentIcon(
                    agentMapByResourcePolicy[
                      agentMapByData[resource.agentId]?.agentType ?? ''
                    ]?.resourceTypeId as AgentCategoryId,
                  )}
                  classIcon={classNames('w-9 h-9 xl:w-12 xl:h-12')}
                  value={formatInteger(resource.totalEvents)}
                  unitOfMeasure={t`tasks`}
                  isActionable
                  onClick={() => selectDevice(resource.agentId)}
                >
                  {resource.agentId}
                  <div className={classNames('mt-2 text-xs opacity-70')}>
                    {formatTimespan(
                      resource.maxTime.getTime(),
                      resource.minTime.getTime(),
                    )}
                  </div>
                </Stat>
              );
            })
            .value()}
        </StatGroup>
      </div>
    </PanelBody>
  );
};

export default LiveFeedPanel;
