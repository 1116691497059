import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../components/actions/Button';
import * as Icon from '../../../components/icons';
import { ScreenTitle } from '../../../components/layout/ScreenTitle';
import PanelContainer from '../../../containers/PanelContainer';
import Callout from '../../../helpContext/Callout';
import ContactSupport from '../../../helpContext/ContactSupport';

export type PanelProps = PropsWithChildren & {
  className?: string;
};

const PanelSettingsLegal: React.FC<PanelProps> = props => {
  const { t } = useTranslation('app');

  return (
    <PanelContainer
      id="panel-system-gdpr"
      title={t`Legal (GDPR)`}
      collapsible
      // hasPadding
    >
      <ScreenTitle
        isSticky
        subtitle={t`GDPR Policy`}
        title={t`Deactivating and Deletion`}
      />

      <Callout panelMode type="suggestion">
        {t`Temporarily deactivate or permanently delete your account.`}
        <div className="mt-4">
          <Icon.TriangleInfo className="h-4 w-4 fill-current ltr:mr-2 rtl:ml-2" />
          {t`You can deactivate your account. Or if you want to permanently delete your WareBee account, let us know at `}

          <ContactSupport
            email="support+gdpr@warebee.com"
            subject="[WAREBEE] GDPR - Delete Account"
            body={`Hello, Please delete my account`}
          />
        </div>
      </Callout>

      <div className="mt-4 space-y-1 p-4">
        <Button
          className="backdrop-saturate-110 bg-opacity-80 p-2 text-start backdrop-blur-lg backdrop-filter"
          buttonSize={`lg`}
          label={t`Deactivate my Account`}
          full
          hasIconBefore
          buttonType={`primary`}
          // buttonType={`primary`}
          buttonIcon={<Icon.CircleX className={`h-6 w-6 fill-current`} />}
        />

        <Button
          className="backdrop-saturate-110 bg-opacity-80 p-2 text-start backdrop-blur-lg backdrop-filter"
          buttonSize={`lg`}
          label={t`Delete my Account and All Data`}
          full
          hasIconBefore
          buttonType={`primary`}
          // buttonType={`primary`}
          buttonIcon={<Icon.CircleX className={`h-6 w-6 fill-current`} />}
        />
      </div>
    </PanelContainer>
  );
};

export default PanelSettingsLegal;
