import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { warehouseSelected } from '../store/warehouse.state';
import {
  formatAreaBest,
  formatCO2Best,
  formatCurrencyBest,
  formatDistanceBest,
  formatInteger,
  formatShare,
  FormattedValueWithUnit,
  formatTimeBest,
  formatTimeBestToPrecision,
  formatTimespanAs24String,
  formatVolumeBest,
  formatWeightBest,
} from './formatHelper';
import { getDefaultMeasure, getMeasureTitle } from './measureHelper';

export type Formatter = {
  sizeUnit: string;
  weightUnit: string;
  formatCO2: (v: number) => FormattedValueWithUnit;
  formatDistance: (
    v: number,
    cutOf?: number,
    shouldRound?: boolean,
  ) => FormattedValueWithUnit;
  formatWeight: (v: number, cutOf?: number) => FormattedValueWithUnit;
  formatArea: (v: number) => FormattedValueWithUnit;
  formatVolume: (v: number) => FormattedValueWithUnit;
  formatTimeSpan: (v: number, cutOf?: number) => FormattedValueWithUnit;
  formatTimeBestToPrecision: (
    v: number,
    cutOf?: number,
  ) => FormattedValueWithUnit;
  formatCurrency: (v: number, cutOf?: number) => FormattedValueWithUnit;
  formatTimespanAs24String: (v: number) => FormattedValueWithUnit;
  formatShare: (v: number) => FormattedValueWithUnit;
  formatInteger: (v: number, unit?: string) => FormattedValueWithUnit;
  formatToPrecision: (
    v: number,
    precision?: number,
    unit?: string,
  ) => FormattedValueWithUnit;

  formatNumber: (
    v: number,
    precision?: number,
    unit?: string,
  ) => FormattedValueWithUnit;

  formatDistanceFractional: (
    v: number,
    cutOf?: number,
    fractionDigits?: number,
  ) => FormattedValueWithUnit;
};

function useFormatter(): Formatter {
  const wh = useRecoilValue(warehouseSelected);
  const { t } = useTranslation('measures');
  const system = wh.measurementSystem;
  if (_.isNil(system)) {
    throw new Error(t`Measurement system is not defined`);
  }

  return {
    sizeUnit: getMeasureTitle(getDefaultMeasure(system, 'size'), t),
    weightUnit: getMeasureTitle(getDefaultMeasure(system, 'weight'), t),
    formatCO2: (v: number) => formatCO2Best(v, t),
    formatDistance: (v: number, cutOf?: number, shouldRound = true) => {
      return formatDistanceBest(v, system, t, cutOf, 1, shouldRound);
    },
    formatWeight: (v: number, cutOf?: number) =>
      formatWeightBest(v, system, t, cutOf),
    formatArea: (v: number) => formatAreaBest(v, system, t),
    formatVolume: (v: number) => formatVolumeBest(v, system, t),
    formatTimeSpan: (v: number, cutOf?: number) => formatTimeBest(v, t, cutOf),
    formatTimeBestToPrecision: (v: number, cutOf?: number) =>
      formatTimeBestToPrecision(v, t, cutOf),
    formatTimespanAs24String: formatTimespanAs24String,
    formatCurrency: (v: number, cutOf?: number) =>
      formatCurrencyBest(v, wh.currency, cutOf),
    formatShare: (v: number) => formatShare(v, t),
    formatInteger: (v: number, unit = '') => ({
      value: _.isNil(v) ? '-' : formatInteger(v),
      fullString: _.isNil(v) ? '-' : `${formatInteger(v)}${unit}`,
      unit,
      raw: v,
      unitCode: unit,
    }),
    formatDistanceFractional: (
      v: number,
      cutOf?: number,
      fractionDigits = 1,
    ) => {
      return formatDistanceBest(v, system, t, cutOf, fractionDigits, false);
    },
    formatToPrecision: (v: number, precision = 2, unit = '') => ({
      value: _.isNil(v) ? '-' : v.toFixed(precision),
      fullString: _.isNil(v) ? '-' : `${v.toFixed(precision)}${unit}`,
      unit,
      raw: v,
      unitCode: unit,
    }),
    formatNumber: (v: number, precision = 2, unit = '') => ({
      value: _.isNil(v)
        ? '-'
        : Number.isInteger(v)
          ? v.toString()
          : v.toFixed(precision),
      fullString: _.isNil(v)
        ? '-'
        : `${Number.isInteger(v) ? v.toString() : v.toFixed(precision)}${unit}`,
      unit,
      raw: v,
      unitCode: unit,
    }),
  };
}

export default useFormatter;
