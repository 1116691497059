import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { billingStatusVisibilityState } from '../store/global.state';
import { AppHeaderMenu } from './AppHeaderMenu';
import { MenuGlobal } from './MenuGlobal';
import MenuSubscription from './MenuSubscription';
import { UserHeader } from './UserHeader';
import { LogoHeader } from './layout/LogoHeader';
import { Spacer } from './layout/Spacer';
import { warehouseSelectedId } from '../store/warehouse.state';

export type AppHeaderProps = {
  children?: React.ReactNode;
  active?: boolean;
  hasMenuGlobal?: boolean;
  hasMenuApps?: boolean;
  hasMenuWelcome?: boolean;
  hasHelp?: boolean;
  hasNotifications?: boolean;
  hasSetting?: boolean;
  hasUser?: boolean;
  hasSubscription?: boolean;
  isDemo?: boolean;
  isPurchasing?: boolean;
};

export const AppHeader = ({
  children,
  hasMenuGlobal,
  hasMenuApps,
  hasMenuWelcome,
  hasHelp,
  hasNotifications,
  hasSetting,
  hasUser,
  isDemo,
  isPurchasing,
  hasSubscription,
}: AppHeaderProps) => {
  const warehouseId = useRecoilValue(warehouseSelectedId);
  const isBillingStatusVisible = useRecoilValue(billingStatusVisibilityState);

  const { t } = useTranslation('app');

  return (
    <>
      <div
        data-component={`AppHeader`}
        data-label={`header`}
        aria-label={`header`}
        className={classNames('flex items-center', 'w-full', 'bg-app-header')}
      >
        <div className={classNames('flex w-full flex-1 flex-col items-center')}>
          <div className={classNames('flex w-full items-center')}>
            <LogoHeader logoFull logoSize={'sm'} />
            <Spacer flexspace />
            {isBillingStatusVisible && hasSubscription && <MenuSubscription />}
            {hasUser && <UserHeader />}
            {hasMenuGlobal && (
              <MenuGlobal
                config={{
                  setting: { show: true, showTitle: false },
                  notifications: { show: true, showTitle: false },
                  help: { show: true, showTitle: true },
                  subscription: { show: false, showTitle: false },
                }}
              />
            )}
          </div>
        </div>
      </div>
      {hasMenuApps && (
        <AppHeaderMenu
          isDemo={isDemo}
          warehouseId={warehouseId}
          canSwitchWarehouse={!isPurchasing}
          isPurchasing={isPurchasing}
        />
      )}
      {children}
    </>
  );
};
