import { PolicyFilterUnion } from '@warebee/shared/engine-model';
import {
  Field,
  ID,
  InputAndObjectType,
} from '@warebee/shared/util-backend-only-types';

@InputAndObjectType()
export class DatasetExtraFieldSettings {
  @Field()
  name: string;

  @Field({ nullable: true })
  title?: string;

  @Field()
  enabled: boolean;

  @Field()
  alias: string;
}

@InputAndObjectType()
export class DatasetExtraFieldsSettings {
  @Field(() => ID)
  datasetType: string;

  @Field(() => [DatasetExtraFieldSettings])
  fieldsSettings: DatasetExtraFieldSettings[];
}

@InputAndObjectType()
export class FilterFieldConfigCustomizable {
  @Field(() => ID)
  type: string;

  @Field()
  title: string;

  @Field({ nullable: true })
  group?: string;

  @Field({ nullable: true })
  disabled?: boolean;

  @Field({ nullable: true })
  nullOptionTitle?: string;
}

@InputAndObjectType()
export class FilterSettings {
  @Field(() => ID)
  presetType: string;

  @Field(() => [FilterFieldConfigCustomizable])
  filterConfigOverrides: FilterFieldConfigCustomizable[];
}

@InputAndObjectType()
export class FilterPreset {
  @Field(() => ID)
  id: string;

  @Field()
  title: string;

  @Field(() => PolicyFilterUnion)
  filterUnion: PolicyFilterUnion;
}

@InputAndObjectType()
export class PresentationSettings {
  @Field(() => [FilterPreset], { nullable: true })
  filterPresets?: FilterPreset[];

  @Field(() => [FilterSettings], { nullable: true })
  filterSettings?: FilterSettings[];

  @Field(() => [DatasetExtraFieldsSettings], { nullable: true })
  datasetExtraFieldsSettings?: DatasetExtraFieldsSettings[];
}
