import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import {
  FilterFieldConfigBase,
  FilterFieldConfigCustomizable,
} from '../../common/types';
import { getExtraFieldFieldConfig } from '../../policyFilters/policyFilter.helper';
import { actualityFilterSettingsByType } from '../store/actuality.state';
import { ActualityHqDataColumn } from '../store/datasetQueries/actualityHqDataRows';
import { feedDatasetExtraFields } from '../store/feed.state';
import { getActualityHqConfigBase } from '../store/getActualityHqConfigBase';

export function useActualityHqFilterConfig(): FilterFieldConfigBase<ActualityHqDataColumn>[] {
  const { t } = useTranslation('app');

  const filterSettings = useRecoilValue(
    actualityFilterSettingsByType('actualityHq'),
  );
  const feedExtraFields = useRecoilValue(feedDatasetExtraFields);
  const filterOverrides =
    filterSettings?.filterConfigOverrides as FilterFieldConfigCustomizable<ActualityHqDataColumn>[];

  const filterOverridesMap = _.keyBy(filterOverrides, f => f.type);
  const actualityHqConfigBase = getActualityHqConfigBase(t);

  const actualityHqConfigExtra: FilterFieldConfigBase<ActualityHqDataColumn>[] =
    _.map(
      feedExtraFields,
      f =>
        getExtraFieldFieldConfig(
          f,
        ) as FilterFieldConfigBase<ActualityHqDataColumn>,
    );
  const actualityHqConfig = [
    ...actualityHqConfigBase,
    ...actualityHqConfigExtra,
  ]
    .map(f => ({ ...f, ...filterOverridesMap[f.type] }))
    .filter(f => !f.disabled);

  return actualityHqConfig;
}
