import { addSeconds, eachDayOfInterval, endOfDay, startOfDay } from 'date-fns';
import Konva from 'konva';
import _ from 'lodash';
import React, { Fragment, useEffect, useRef } from 'react';
import { Layer } from 'react-konva';
import {
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from 'recoil';
import { getQualitativeColor } from '../../common/color.helper';
import { toDateFromLocaleStringDate } from '../../common/dateTimeHelper';
import ScheduledJobFeature, {
  ScheduledJobFeatureProps,
} from '../../layout/features/ScheduledJobFeature';
import TimelineFeature from '../../layout/features/TimelineFeature';
import TimelineRowFeature from '../../layout/features/TimelineRowFeature';
import { StageType } from '../../layout/stage/stage.helper';
import { stageStateById } from '../../layout/stage/stage.state';
import { viewerAppliedCachingSettings } from '../../layout/viewer/store/viewer.state';
import { analyzeGateUsageSchedule } from '../store/analyze.state';

export const jobFeatureHeight = 34;
export const ticksInPixel = 60 * 1000; // 1 min
export const stageHeight = 24 * 60;
export const jobTopMargin = 10;

export type JobsTimelineLayerProps = {
  stageId: StageType;
};

const SimulationJobsTimelineLayer: React.FC<JobsTimelineLayerProps> = props => {
  const scheduleLoadable = useRecoilValueLoadable(analyzeGateUsageSchedule);
  const setStageState = useSetRecoilState(stageStateById(props.stageId));
  const cachingSettings = useRecoilValue(viewerAppliedCachingSettings);
  const ref = useRef<Konva.Layer>(null);

  useEffect(() => {
    if (scheduleLoadable.state === 'hasValue') {
      if (_.isEmpty(scheduleLoadable.contents)) return;
      const { startTime, completionTime } = scheduleLoadable.contents;
      const start = toDateFromLocaleStringDate(startTime);
      const end = toDateFromLocaleStringDate(completionTime);
      const xMin = startOfDay(start).getTime() / ticksInPixel;
      const xMax = endOfDay(end).getTime() / ticksInPixel;
      const days = 3; // 3 day
      const xScope = xMin + (days * 24 * 3600 * 1000) / ticksInPixel;

      const key = `${xMin}-${xMax}`;
      setStageState({
        autoSizeId: `auto-size-bay-${key}`,
        contentBounds: [
          [xMin, -stageHeight / 2],
          [Math.min(xMax, xScope), stageHeight / 2],
        ],
      });
    }
  }, [scheduleLoadable]);

  useEffect(() => {
    if (ref?.current?.isCached) {
      ref?.current?.clearCache();
    }
    if (
      cachingSettings.type === 'on' ||
      (cachingSettings.type === 'auto' && cachingSettings.ratio < 1)
    ) {
      console.debug(
        'Cache JobsTimelineLayer with ratio: ',
        cachingSettings.ratio,
      );
      ref?.current?.cache({
        pixelRatio: cachingSettings.ratio,
        hitCanvasPixelRatio: cachingSettings.ratio,
      });
    }
  });

  const hasInterval = scheduleLoadable.state === 'hasValue';
  const hasJobs = scheduleLoadable.state === 'hasValue';
  const hasAnalyzedJobs = scheduleLoadable.state === 'hasValue';
  const hasShifts = scheduleLoadable.state === 'hasValue';

  const isReady = hasInterval && hasJobs && hasAnalyzedJobs && hasShifts;
  if (!isReady) return null;

  // all loaded
  const schedule = scheduleLoadable.contents;

  const resources = schedule?.resources;
  if (_.isEmpty(resources)) return null;
  const { startTime, completionTime } = scheduleLoadable.contents;
  const start = toDateFromLocaleStringDate(startTime);
  const end = toDateFromLocaleStringDate(completionTime);

  // const jobs = picklistsAllLoadable.getValue();
  // const analyzedJobs = analyzedJobsLoadable.contents;
  // const shifts = shiftsLoadable.contents;
  // const analyzedJobsMap = _.keyBy(analyzedJobs, j => j.jobId);

  const resourceCount = _.size(resources);
  if (_.isEmpty(resources)) return null;

  return (
    <Layer>
      {eachDayOfInterval({
        start,
        end,
      }).map((day, index) => {
        return (
          <TimelineFeature
            key={`day-${day}-${index}`}
            day={day}
            index={index}
            rowCount={resourceCount}
            maxShiftCount={1}
          />
        );
      })}

      {_(resources)
        // .sortBy(agentJobs => {
        //   const agentId = _.head(agentJobs)?.agentId;
        //   const firstShift = _(shifts)
        //     .filter(s => s.agentUser === agentId)
        //     .minBy(s => s.shiftStart);

        //   return firstShift.shiftStart?.getHours();
        // })
        .map((resource, resourceIndex) => {
          const resourceColor = getQualitativeColor(resource.id, 'policy');
          return (
            <Fragment key={`resource-${resource.id}`}>
              <TimelineRowFeature
                from={startOfDay(start)}
                to={endOfDay(end)}
                index={resourceIndex}
              />

              {_.map(resource.assignments, (appointment, index) => {
                const from = toDateFromLocaleStringDate(appointment.startTime);
                const to = addSeconds(from, appointment.job.duration);

                const jobFeatureProps: ScheduledJobFeatureProps = {
                  job: {
                    id: appointment.job.id,
                    from,
                    to,
                    resource: resource.id,
                    gapBefore: appointment.dockingDuration,
                    gapAfter: appointment.undockingDuration,
                  },
                  colors: resourceColor,
                  offsetY: resourceIndex * jobFeatureHeight,
                  onClick: _.noop,
                };
                return (
                  <ScheduledJobFeature
                    key={`job-${appointment.job.id}`}
                    {...jobFeatureProps}
                  />
                );
              })}
            </Fragment>
          );
        })
        .value()}
    </Layer>
  );
};

export default SimulationJobsTimelineLayer;
