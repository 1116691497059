import {
  ActivityFeedDataColumn,
  ItemSetDataTableColumn,
} from '@warebee/shared/export-converter';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import useFormatter from '../../common/useFormatter';
import { ColumnConfig } from '../../components/DatasetTable';
import useActivityFeedDataTableConfig from '../../datasetObject/useActivityFeedDataTableConfig';
import { getActivityFeedSchema } from '../../import/activityFeed/schema/activityFeedSchema';
import useItemSetDataTableConfig from '../../itemSet/useItemSetDataTableConfig';
import useLayoutDataTableConfig from '../../layout/useLayoutDataTableConfig';
import { ActualityHqDataRow } from '../store/datasetQueries/actualityHqDataRows';
import { feedDatasetExtraFields } from '../store/feed.state';

function useActualityHqDataTableConfig(): ColumnConfig<ActualityHqDataRow>[] {
  const { t: tImporter } = useTranslation('importer');
  const { t } = useTranslation('dataset');
  const activityExtraFields = useRecoilValue(feedDatasetExtraFields);

  const formatter = useFormatter();

  const schema = _.keyBy(getActivityFeedSchema(tImporter).fields, f => f.name);

  const feedFieldSkip: Set<ActivityFeedDataColumn> = new Set(['duration']);

  const itemsFieldSkip: Set<ItemSetDataTableColumn> = new Set([
    'consignee',
    'sku',
  ]);
  const locationFieldSkip: Set<string> = new Set(['__typename', 'locationId']);

  const feedFields = useActivityFeedDataTableConfig().filter(
    f => !feedFieldSkip.has(f.field),
  ) as any as ColumnConfig<ActualityHqDataRow>[];
  const itemSetFields = useItemSetDataTableConfig().filter(
    f => !itemsFieldSkip.has(f.field),
  ) as any as ColumnConfig<ActualityHqDataRow>[];

  const locationFields = useLayoutDataTableConfig().filter(
    f => !locationFieldSkip.has(f.field),
  ) as any as ColumnConfig<ActualityHqDataRow>[];

  const feedExtraFields: ColumnConfig<ActualityHqDataRow>[] = _.map(
    activityExtraFields,
    f => ({
      field: f.alias as any,
      title: f.title ?? f.name,
      hasFilter: true,
      hasSearch: true,
    }),
  );

  const columnsConfig: ColumnConfig<ActualityHqDataRow>[] = [
    ...feedFields,
    ...itemSetFields,
    ...locationFields,
    ...feedExtraFields,
    {
      field: 'cumulativePercentRank',
      title: t`Cml Percent Rank`,
    },
  ];

  return columnsConfig;
}
export default useActualityHqDataTableConfig;
