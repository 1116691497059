import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { appThemeSelectedId } from '../../store/global.state';
import { userSettings } from '../../store/warehouse.state';
import { whiteLabelConfigs } from '../../store/whitelabel.types';
import * as Logo from '../logos';

export type LogoHeaderProps = {
  children?: React.ReactNode;
  className?: string;
  label?: string;
  logoApp?: boolean;
  embeded?: boolean;
  headerMode?: boolean;
  logoFull?: boolean;
  logoSize?: string;
  navTo?: string;
};

export const LogoHeader = ({
  logoApp,
  logoFull,
  logoSize,
  children,
  className,
  embeded,
  headerMode,
  navTo,
}: LogoHeaderProps) => {
  const [id] = useState<string>(_.uniqueId());
  const settings = useRecoilValue(userSettings);
  const themeId = useRecoilValue(appThemeSelectedId);

  const matchingConfig =
    Object.values(whiteLabelConfigs).find(
      config => config.themes?.name === themeId,
    ) || whiteLabelConfigs['WAREBEE'];

  const brandConfig = matchingConfig.brand;

  const LogoAppComponent =
    brandConfig?.logoApp && Logo[brandConfig.logoApp as keyof typeof Logo]
      ? Logo[brandConfig.logoApp as keyof typeof Logo]
      : Logo.WareBeeSymbolShape;

  const LogoFullComponent =
    brandConfig?.logoFull && Logo[brandConfig.logoFull as keyof typeof Logo]
      ? Logo[brandConfig.logoFull as keyof typeof Logo]
      : Logo.WareBeeLogoFull;

  const CobrandingLogoAppComponent =
    brandConfig?.cobrandingLogoApp &&
    Logo[brandConfig.cobrandingLogoApp as keyof typeof Logo]
      ? Logo[brandConfig.cobrandingLogoApp as keyof typeof Logo]
      : undefined;

  const CobrandingLogoFullComponent =
    brandConfig?.cobrandingLogoFull &&
    Logo[brandConfig.cobrandingLogoFull as keyof typeof Logo]
      ? Logo[brandConfig.cobrandingLogoFull as keyof typeof Logo]
      : undefined;

  return (
    <Link
      id={id}
      to={navTo ? navTo : settings?.configured ? '/' : '/welcome'}
      className={classNames('flex items-center', className)}
      data-component={`LogoHeader`}
      data-label={`logo-header`}
      aria-label={`logo-header`}
    >
      {logoApp && (
        <div className="flex items-center py-1">
          <LogoAppComponent
            className={classNames(
              'fill-current',
              !logoSize && 'h-16 w-20',
              logoSize === 'xs' && 'h-4 md:h-5 lg:h-6',
              logoSize === 'header' && 'h-4 md:h-5 lg:h-7',
              embeded ? 'text-menu-text/50' : 'text-brand-warebee',
            )}
          />
          {brandConfig?.cobranding && CobrandingLogoAppComponent && (
            <>
              <hr className="bg-menu-text/30 mx-3 h-6 w-px border-0" />
              <CobrandingLogoAppComponent
                className={classNames(
                  'fill-current',
                  !logoSize && 'h-16 w-20',
                  logoSize === 'xs' && 'h-4 md:h-5 lg:h-6',
                  logoSize === 'header' && 'h-4 md:h-5 lg:h-6',
                  embeded ? 'text-menu-text/50' : 'text-menu-text',
                )}
              />
            </>
          )}
        </div>
      )}

      {logoFull && (
        <div className="flex items-center px-3 py-2">
          <LogoFullComponent
            className={classNames(
              'fill-current',
              headerMode ? '' : '',
              embeded ? 'text-menu-text/50' : 'text-brand-warebee',
              !logoSize && 'h-10',
              logoSize === 'xs' && 'h-4 md:h-5 lg:h-6',
              logoSize === 'header' && 'h-6 md:h-6 lg:h-7',
              logoSize === 'sm' && 'h-5 md:h-8 lg:h-10',
              logoSize === 'md' && 'md:h-18 -ml-2 h-12 lg:h-20',
              logoSize === 'lg' && 'h-18 md:h-20 lg:h-24',
            )}
          />
          {brandConfig?.cobranding && CobrandingLogoFullComponent && (
            <>
              <hr className="bg-menu-text/20 mx-3 h-8 w-px border-0" />
              <CobrandingLogoFullComponent
                className={classNames(
                  'fill-current',
                  headerMode ? '' : 'py-1',
                  embeded ? 'text-menu-text/50' : 'text-menu-text',
                  !logoSize && 'h-10',
                  logoSize === 'xs' && 'h-4 md:h-5 lg:h-6',
                  logoSize === 'header' && 'h-6 md:h-6 lg:h-7',
                  logoSize === 'sm' && 'h-5 md:h-8 lg:h-10',
                  logoSize === 'md' && 'md:h-18 -ml-2 h-12 lg:h-20',
                  logoSize === 'lg' && 'h-18 md:h-20 lg:h-24',
                )}
              />
            </>
          )}
        </div>
      )}
      {children}
    </Link>
  );
};
