import {
  AllocateJobEngine,
  AnalyzeResultStage,
  AnalyzeResultStatus,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import AnalyzeResultPanel from '../../analyze/AnalyzeResultPanel';
import { ANALYZE_PROGRESS_REFRESH_INTERVAL } from '../../common/constants';
import { AsyncLoadStatus } from '../../common/types';
import ErrorIndicator from '../../components/ErrorIndicator';
import {
  HelperMessage,
  HelperMessageAction,
} from '../../components/HelperMessage';
import LoadingIndicator from '../../components/LoadingIndicator';
import { Button } from '../../components/actions/Button';
import * as Icon from '../../components/icons';
import { Container } from '../../components/layout/ContainerFlex';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import SectionOptional from '../../components/layout/SectionOptional';
import { Spacer } from '../../components/layout/Spacer';
import { ActionBar } from '../../components/nav/ActionBar';
import { IconArrowDir } from '../../components/nav/IconArrowDirectional';
import { IconBackDirectional } from '../../components/nav/IconBackDirectional';
import HelperContainer from '../../containers/HelperContainer';
import Callout from '../../helpContext/Callout';
import HelpContainer from '../../helpContext/HelpContainer';
import PickingPolicyWarnings from '../../pickingPolicy/PickingPolicyWarnings';
import ResourcePolicyWarnings from '../../resourcePolicy/ResourcePolicyWarnings';
import {
  userHasAnalyse,
  userHasOptimise,
  warehouseIsDemo,
  warehouseSelectedId,
} from '../../store/warehouse.state';
import { useLoadAnalyzeResult } from '../../warehouse/hooks/useLoadAnalyzeResult';
import useRunAllocationAnalyze from '../../warehouse/hooks/useRunAllocationAnalyze';
import useRunAnalyze from '../../warehouse/hooks/useRunAnalyze';
import { HelperTemplate } from '../helper/HelperTemplate';
import useSelectSimulationWizardStep from '../hooks/useSelectSimulationWizardStep';
import {
  allocationAnalyzeResult,
  allocationRunSummary,
} from '../store/allocation/allocation.state';
import { pickingPolicyIsReadyToAnalyze } from '../store/pickingPolicy/pickingPolicy.state';
import { resourcePolicyIsReadyToAnalyze } from '../store/resourcePolicy.state';
import {
  simulationAnalyzeResult,
  simulationAnalyzeStatus,
  simulationCurrent,
  simulationLoadStatus,
  simulationWithAllocation,
} from '../store/simulation.state';
import { SimulationMenuWizardStepId } from '../store/simulation.wizard';
import ActionBarNextStep from './ActionBarNextStep';

export const SimulationAnalyzeSidebarCmp: React.FC = () => {
  const { t } = useTranslation('simulation');
  const whId = useRecoilValue(warehouseSelectedId);
  const isDemo = useRecoilValue(warehouseIsDemo);

  const simulation = useRecoilValue(simulationCurrent);
  const simulationStatus = useRecoilValue(simulationLoadStatus);

  const analyzeResult = useRecoilValue(simulationAnalyzeResult);
  const analyzeStatus = useRecoilValue(simulationAnalyzeStatus);
  const allocationAnalyze = useRecoilValue(allocationAnalyzeResult);
  const allocationRun = useRecoilValue(allocationRunSummary);

  // console.debug('Simulation ID:', simulation?.id);
  // console.debug('Allocation ID:', allocationRun?.id);

  const allowAnalyse = useRecoilValue(userHasAnalyse);
  const allowOptimise = useRecoilValue(userHasOptimise);
  // Policy Status
  const policyStatusPicking = useRecoilValue(pickingPolicyIsReadyToAnalyze);
  const policyStatusResource = useRecoilValue(resourcePolicyIsReadyToAnalyze);

  const selectStep = useSelectSimulationWizardStep();
  const loadAnalyze = useLoadAnalyzeResult();
  const runAnalyze = useRunAnalyze();
  const runAllocationAnalyze = useRunAllocationAnalyze();
  const [isStarting, setIsStarting] = useState(false);
  const [withAllocate, setWithAllocate] = useRecoilState(
    simulationWithAllocation,
  );
  const [engineAllocate, setEngineAllocate] = useState(
    AllocateJobEngine.FARGATE,
  );

  const navigate = useNavigate();

  const isAnalysing =
    analyzeStatus === AnalyzeResultStatus.ANALYZING ||
    analyzeStatus === AnalyzeResultStatus.CREATED;

  const isAllocating =
    allocationAnalyze?.status === AnalyzeResultStatus.ANALYZING ||
    allocationAnalyze?.status === AnalyzeResultStatus.CREATED;

  useEffect(() => {
    if (isAllocating) {
      const analyzeId = allocationAnalyze?.id;
      const timeoutId = setTimeout(() => {
        loadAnalyze({ analyzeId, analyzeType: 'allocate' });
      }, ANALYZE_PROGRESS_REFRESH_INTERVAL);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [allocationAnalyze, isAllocating, simulation]);

  useEffect(() => {
    if (isAnalysing) {
      const analyzeId = analyzeResult?.id ?? simulation?.analyzeResult?.id;
      const timeoutId = setTimeout(() => {
        loadAnalyze({ analyzeId, analyzeType: 'initial' });
      }, ANALYZE_PROGRESS_REFRESH_INTERVAL);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [analyzeResult, isAnalysing, simulation]);



  const isLoading =
    simulationStatus === AsyncLoadStatus.Loading || //simulation is loading
    (analyzeStatus === AnalyzeResultStatus.COMPLETED && _.isNil(analyzeResult)); //analyze result is loading;
  const hasError = analyzeStatus === AnalyzeResultStatus.FAILED;

  if (isLoading) {
    return (
      <LoadingIndicator
        message={
          isAllocating ? t`Allocating...` : t`Loading Analyse results...`
        }
        selfCenter
      />
    );
  }

  if (isAnalysing && !hasError) {
    const stageTitle = analyzeResult?.stage
      ? t(`{{stageTitle}}`, {
          stageTitle:
            analyzeResult?.stage !==
              AnalyzeResultStage.GENERATING_PICKING_REQUIREMENTS &&
            analyzeResult?.stage !== AnalyzeResultStage.ALLOCATING &&
            analyzeResult?.stage !== AnalyzeResultStage.ANALYZING &&
            analyzeResult.progress === 0
              ? t`Validating Data...`
              : analyzeResult?.stage ===
                  AnalyzeResultStage.GENERATING_PICKING_REQUIREMENTS
                ? t`Applying Policies`
                : analyzeResult?.stage === AnalyzeResultStage.ALLOCATING
                  ? t`Evaluating Dataset...`
                  : analyzeResult?.stage === AnalyzeResultStage.ANALYZING
                    ? t`Analysing Warehouse`
                    : analyzeResult?.stage === AnalyzeResultStage.ANALYZING &&
                        analyzeResult.progress === 100
                      ? t`Getting Result...`
                      : t`Analysing...`,
        })
      : t`Loading Data...`; // Step - 1

    const stageStep = analyzeResult?.stage
      ? t(`Step {{stage}} of 4`, {
          stage:
            analyzeResult?.stage !==
              AnalyzeResultStage.GENERATING_PICKING_REQUIREMENTS &&
            analyzeResult?.stage !== AnalyzeResultStage.ALLOCATING &&
            analyzeResult?.stage !== AnalyzeResultStage.ANALYZING &&
            (analyzeResult?.progress === 0 || analyzeResult?.progress === null)
              ? 1
              : analyzeResult?.stage ===
                  AnalyzeResultStage.GENERATING_PICKING_REQUIREMENTS
                ? 2
                : analyzeResult?.stage === AnalyzeResultStage.ALLOCATING
                  ? 3
                  : analyzeResult?.stage === AnalyzeResultStage.ANALYZING
                    ? 4
                    : analyzeResult?.stage === AnalyzeResultStage.ANALYZING &&
                        analyzeResult.progress === 100
                      ? 5
                      : null,
        })
      : t(`Step 1 of 4`); // Step - 1

    const progressState =
      analyzeResult?.progress || analyzeResult?.progress === 0;

    const progressValue = analyzeResult?.progress
      ? analyzeResult?.progress
      : analyzeResult?.progress === 0 || analyzeResult?.progress === null
        ? null
        : null;

    return (
      <>
        <ScreenTitle
          subtitle={t`Analyse`}
          title={t`Analyse Warehouse`}
          isSticky
          helpNavTo={'simulation/analyse/simulation-analyse'}
          icon={Icon.SimulationAnalyze}
        />
        <LoadingIndicator
          className="bg-issue-transparent bg-app-panel-dark"
          progress={progressValue}
          messageBefore={<div className="mb-4 opacity-80">{stageStep}</div>}
          message={<div className="mt-4">{stageTitle}</div>}
          selfCenter
        />
        <ActionBar sticky stickyBottom>
          {isAllocating ? (
            <></>
          ) : (
            <SectionOptional
              id={'analyze-with-allocate'}
              // value={withAllocate}
              offLabel={t`No Allocate`}
              onLabel={t`Allocate`}
              isDisabled
              className="flex-1"
              onChange={() => {}}
            />
          )}

          <Button
            label={
              isAllocating ? t`Analysing & Allocating...` : t`Analysing...`
            }
            buttonType="primary"
            hasIconAfter
            buttonIcon={<IconArrowDir />}
            isDisabled
            isLoading
            full
          />
        </ActionBar>
      </>
    );
  }

  const isPoliciesAnalyzeReady =
    policyStatusPicking?.isAnalyzeReady && policyStatusResource?.isAnalyzeReady;

  const hasIssues =
    !policyStatusResource.isAnalyzeReady || !policyStatusPicking.isAnalyzeReady;

  if (!isPoliciesAnalyzeReady && analyzeStatus !== 'COMPLETED') {
    const targetStepId: SimulationMenuWizardStepId =
      policyStatusResource.isAnalyzeReady
        ? 'policy-picking'
        : 'policy-resources';

    const targetStepTitle: string = policyStatusResource.isAnalyzeReady
      ? t`Back to Picking Policy`
      : t`Back to Resource Policy`;

    return (
      <Container col hasOverflowY>
        <ActionBar sticky stickyTop>
          <Button
            label={targetStepTitle}
            buttonType="primary"
            buttonSize="sm"
            full
            onPress={() => selectStep({ stepId: targetStepId })}
            hasIconBefore={<IconBackDirectional />}
          />
        </ActionBar>
        <ScreenTitle
          subtitle={t`Analyse`}
          title={hasIssues ? t`Pre-Analyse (Issues)` : t`Pre-Analyse`}
          isSticky
          helpNavTo={'simulation/analyse/simulation-analyse'}
          icon={Icon.SimulationAnalyze}
        />
        <div className="flex-1">
          {!policyStatusResource.isAnalyzeReady && (
            <ResourcePolicyWarnings>
              <div className="border-menu-white/50 mb-4 flex items-center border-b pb-2">
                {t`Resource Policy is incomplete.`}
              </div>
            </ResourcePolicyWarnings>
          )}
          {!policyStatusPicking.isAnalyzeReady && (
            <PickingPolicyWarnings>
              <div className="border-menu-white/50 mb-4 flex items-center border-b pb-2">
                {t`Picking Policy is incomplete.`}
              </div>
            </PickingPolicyWarnings>
          )}
        </div>
        <ActionBar sticky stickyBottom>
          <Spacer flexspace />
          <Button
            label={t`Start Analyse`}
            buttonType="primary"
            hasIconAfter
            buttonIcon={<IconArrowDir />}
            isDisabled={true}
          />
        </ActionBar>
      </Container>
    );
  }

  if (!isDemo && !allowAnalyse && _.isNil(analyzeStatus)) {
    return (
      <HelperTemplate
        title={t`Analyse Summary`}
        subtitle={t`Analyse not allowed`}
        hasPadding
      >
        <Callout panelMode type="suggestion">
          {t`Apologies, it appears you don't have an active subscription!`}
          <Button
            className="mt-8"
            label={t`Go to Pricing`}
            buttonType="primary"
            buttonSize="sm"
            full
            onPress={() => {
              navigate(`/wh/i/${whId}/pricing/`);
            }}
            hasIconAfter
            buttonIcon={<Icon.ArrowRight className={`h-5 w-5 fill-current`} />}
          />
        </Callout>
      </HelperTemplate>
    );
  }

  if (hasError) {
    return (
      <>
        <ScreenTitle
          subtitle={t`Analyse`}
          title={t`Analyse Summary`}
          isSticky
          helpNavTo={'simulation/analyse/simulation-analyse'}
          icon={Icon.SimulationAnalyze}
        />
        {isStarting ? (
          <LoadingIndicator
            message={t`Preparing data for Analysis`}
            selfCenter
          />
        ) : (
          <>
            <HelperTemplate
              title={t`Analyse Summary`}
              subtitle={t`Analyse Error`}
              hasPadding
            >
              {t`Looks like there is an issue with analysing your warehouse, please fix the issue and try again.`}
            </HelperTemplate>
            <ErrorIndicator
              selfCenter
              message={
                t`Analyze cannot be completed!` +
                ' ' +
                t`Please contact support!`
              }
            />
          </>
        )}

        <ActionBar sticky stickyBottom>
          <SectionOptional
            id={'analyze-with-allocate'}
            offLabel={`No Allocate`}
            onLabel={`Allocate`}
            value={withAllocate}
            onChange={enabled => setWithAllocate(enabled)}
          />

          <Button
            full
            label={t`Re-Start`}
            buttonType="primary"
            hasIconAfter
            buttonIcon={<IconArrowDir />}
            isLoading={isStarting}
            isDisabled={isStarting}
            onPress={async () => {
              setIsStarting(true);
              await runAnalyze(simulation.id);
              withAllocate &&
                allocationRun?.id &&
                runAllocationAnalyze(allocationRun?.id);
              setIsStarting(false);
            }}
          />
        </ActionBar>
      </>
    );
  }

  if (!analyzeStatus) {
    return (
      <>
        <Container col hasOverflowY hasExtraPadding>
          <ScreenTitle
            subtitle={withAllocate ? t`Allocate` : t`Analyse`}
            title={withAllocate ? t`Allocate Warehouse` : t`Analyse Warehouse`}
            isSticky
            helpNavTo={'simulation/analyse/simulation-analyse'}
            icon={withAllocate ? Icon.PolicyAllocate : Icon.SimulationAnalyze}
          />
          {isStarting ? (
            <LoadingIndicator
              message={t`Preparing data for Analysis`}
              selfCenter
            />
          ) : (
            <>
              {withAllocate ? (
                <>
                  <HelpContainer
                    id={'simulation/analyse/simulation-analyse-intro'}
                    hasPadding
                  />
                </>
              ) : (
                <>
                  <HelpContainer
                    id={'simulation/analyse/simulation-analyse-intro'}
                    hasPadding
                  />
                </>
              )}

              {isDemo ? (
                <HelperContainer
                  id={'helper-simulation-analyse-warning-demo'}
                  hasPadding
                >
                  <HelperMessage
                    hasPadding
                    className={`border-alerts-alert bg-alerts-alert text-menu-text mt-2 rounded border border-opacity-50 p-3 pb-20`}
                    isSuggestion
                    isError
                    hasAfter
                  >
                    {t`Apologies, this simulation is in a Demo draft mode and cannot be run!`}
                  </HelperMessage>
                </HelperContainer>
              ) : (
                <>
                  {withAllocate ? null : (
                    // <AllocationPolicyPanel />
                    <>
                      <Spacer flexspace />
                      <Callout
                        panelMode
                        type="action"
                        iconAfter
                        className="mt-8 pb-10"
                        icon={Icon.ArrowRightBottom}
                      >
                        {t`Click on`}
                        <HelperMessageAction>{t`Start Analyse`}</HelperMessageAction>
                        {t`button to analyse your warehouse`}
                      </Callout>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Container>

        <ActionBar sticky stickyBottom>
          <SectionOptional
            id={'analyze-with-allocate'}
            offLabel={`Off`}
            onLabel={`With Allocate`}
            value={withAllocate}
            onChange={enabled => setWithAllocate(enabled)}
          />

          <Button
            full
            label={isDemo ? t`Start Analyse` : t`Analyse`}
            buttonType="primary"
            hasIconAfter
            buttonIcon={<IconArrowDir />}
            isLoading={isStarting}
            isDisabled={isStarting || isDemo}
            onPress={async () => {
              setIsStarting(true);
              await runAnalyze(simulation.id);
              withAllocate &&
                allocationRun?.id &&
                runAllocationAnalyze(allocationRun?.id);
              setIsStarting(false);
            }}
          />
        </ActionBar>
      </>
    );
  }

  return (
    <>
      <AnalyzeResultPanel analyzeResult={analyzeResult} />
      {allowOptimise && <ActionBarNextStep title={t`Next`} />}
    </>
  );
};
