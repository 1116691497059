import { RunExportJobMutationVariables } from '@warebee/frontend/data-access-api-graphql';
import type { Paths } from 'type-fest';
import { ExportFilenameParams } from './export-filename.helper';

export const NULL_VALUE = '';

export const converterIds = [
  //dataset
  'DATASET_QUERY',
  'LAYOUT_LOCATIONS',
  'ASSIGNMENT',
  'ITEM_SET',
  // 'ORDER_SET',
  'ORDER_SET',
  //issues
  'ASSIGNMENT_ITEM_ISSUES',
  'ITEMS_ISSUES',
  'UOM_ISSUES',
  'ORDER_LINE_ISSUES',
  //reports
  'ORDERED_QUANTITY_REPORT',
  //compliance
  'ASSIGNMENT_COMPLIANCE',
  'VOLUME_COMPLIANCE',
  'PRODUCTS_BY_RULE',
  //analyze
  'ANALYZED_PRODUCTS',
  //optimise
  'ASSIGNMENT_DIFF',
  'REASSIGN_JOBS',
  'OPTIMIZATION_MOVES',
  //dataset objects
  'ACTIVITY_FEED',

  // allocation
  'ALLOCATION_REQUIREMENT',
  'ALLOCATION_SUMMARY',
  //other
  'LOCATIONS_STATS',

  // import
  'IMPORT_JOBS',
] as const;

export type ExportConverterId = (typeof converterIds)[number];

export type ExportConverter<T, C> = (
  input: T,
  config: C,
) => [string[], any[][]];

export type ExportJobParams<TVariables, TConfig> = Omit<
  RunExportJobMutationVariables,
  'converterId' | 'variables' | 'config'
> & {
  converterId: ExportConverterId;
  variables?: TVariables;
  config: TConfig;
  getFilename?: (params: ExportFilenameParams) => string;
  filename?: string;
};

export type ColumnConfigBase<T extends Object> = {
  field: Paths<T>;
  group?: string;
  title?: string;
  isHidden?: boolean;
  hiddenInExport?: boolean;
  hiddenInBrowser?: boolean;
};
