import {
  AnalyzeResultListItemFragment,
  AnalyzeResultLocationFragment,
  AnalyzeResultToCompareFragment,
  LoadAnalyzeToCompareDocument,
  LoadAnalyzeToCompareQuery,
  LoadAnalyzeToCompareQueryVariables,
  SimulationAnalyzeListItemFragment,
} from '@warebee/frontend/data-access-api-graphql';
import { TFunction } from 'i18next';
import _ from 'lodash';
import { secureClient } from '../GraphQLClient';
import { getSKUKey } from '../metrics/analyzeProduct/analyzeProductMetric.helper';
import {
  AnalyzeProductDataRow,
  AnalyzeProductMetricDescriptorBase,
} from '../metrics/analyzeProduct/analyzeProductMetric.types';
import { getProductCategory } from '../simulation/store/abc/simulation.ABC.helper';
import {
  getActiveProducts,
  getReassignedProducts,
  getVisitsCount,
} from '../simulation/store/analyze.helper';
import {
  AnalyzeResultCategory,
  AnalyzeResultListItem,
} from './analyzeCompare.types';

function getAnalyzeListItem(
  sim: SimulationAnalyzeListItemFragment,
  analyzeResult: AnalyzeResultListItemFragment,
  category: AnalyzeResultCategory,
): AnalyzeResultListItem {
  const simDescriptor = {
    id: sim.id,
    title: sim.title,
  };
  return {
    simulation: simDescriptor,
    analyzeId: analyzeResult.id,
    layout: _.omit(sim.layout, ['__typename']),
    category,
    createdAt: new Date(analyzeResult.createdAt),
    updatedAt: new Date(analyzeResult.updatedAt),
  };
}

export function createAnalyzeList(
  data: SimulationAnalyzeListItemFragment[],
): AnalyzeResultListItem[] {
  return _(data)
    .sortBy(s => -s.updatedAt)
    .map(sim => {
      const listItems: AnalyzeResultListItem[] = [];

      if (!_.isNil(sim?.analyzeResult)) {
        listItems.push(getAnalyzeListItem(sim, sim?.analyzeResult, 'initial'));
      }

      if (!_.isNil(sim?.latestAllocationRun?.analyzeResult)) {
        listItems.push(
          getAnalyzeListItem(
            sim,
            sim?.latestAllocationRun?.analyzeResult,
            'allocated',
          ),
        );
      }

      if (!_.isNil(sim?.latestOptimizationRun?.analyzeResult?.id)) {
        listItems.push(
          getAnalyzeListItem(
            sim,
            sim?.latestOptimizationRun?.analyzeResult,
            'optimised',
          ),
        );
      }

      return listItems;
    })
    .flatten()
    .value();
}

export function getAnalyzeTitle(
  analyzeMeta: AnalyzeResultListItem,
  t: TFunction<'simulation'>,
): string {
  let suffix = '';
  switch (analyzeMeta.category) {
    case 'initial':
      suffix = t('Initial', { ns: 'simulation' });
      break;
    case 'allocated':
      suffix = t('Allocated', { ns: 'simulation' });
      break;
    case 'optimised':
      suffix = t('Optimised', { ns: 'simulation' });
      break;
  }
  return `${analyzeMeta.simulation.title}`;
}

export async function loadAnalyzeResultCompareSummary(params: {
  analyzeId: string;
}): Promise<AnalyzeResultToCompareFragment> {
  const response = await secureClient.query<
    LoadAnalyzeToCompareQuery,
    LoadAnalyzeToCompareQueryVariables
  >({
    query: LoadAnalyzeToCompareDocument,
    variables: params,
  });

  return response.data.analyzeResult;
}

export function getAnalyzeCompareDataRows(
  data: AnalyzeResultLocationFragment[],
  metricDescriptor: AnalyzeProductMetricDescriptorBase,
): AnalyzeProductDataRow[] {
  return (
    _(data)
      .map(locationData => {
        const products =
          metricDescriptor.type === 'reassign-task-type'
            ? getReassignedProducts(locationData)
            : getActiveProducts(locationData);
        const visitsCount = getVisitsCount(products);
        return _.map(products, p => ({
          ...p,
          skuKey: getSKUKey(p),
          locationId: locationData.locationId,
          congestionZone: locationData.congestionZone,
          // location
          locationPickCount: 0,
          locationReplenishmentCount: 0,
          locationVisits: visitsCount,
          unusedVolume:
            (p.item?.baseUom?.volume ?? 0) *
            (p.replenishment?.replenishmentQuantity ?? 0),
          //product
          categoryStringVisited:
            p.pickCount > 0
              ? getProductCategory(p.cumulativePercentRank)?.key
              : null,
          categoryString: getProductCategory(p.cumulativePercentRank)?.key,
          reassignKey: _(p.reassignTasks)
            .map(t => t.action)
            .orderBy(a => a, 'asc')
            .join(' - '),
        }));
      })
      .flatten()
      //.uniqBy(metricDescriptor.uniqBy)
      .value()
  );
}
