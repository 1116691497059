import { Transition } from '@headlessui/react';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { formatInteger } from '../../common/formatHelper';
import { cn } from '../../common/utils';
import * as Icon from '../../components/icons';
import { Spacer } from '../../components/layout/Spacer';
import { CollapsibleId } from '../../store/collapsible.default';
import { collapsibleState } from '../../store/collapsible.state';
import LoadingIndicator from '../LoadingIndicator';

export type TitleSectionProps = {
  id?: CollapsibleId;
  title?: string | React.ReactNode;
  titleAlt?: string;
  dataComponent?: string;
  titleSummaryClosed?: string | React.ReactNode;
  titleSummaryOpen?: string | React.ReactNode;
  loadingMessage?: string;
  maxHeight?: number;
  loadedCount?: number;
  totalCount?: number;
  hasScroll?: boolean;
  isLoading?: boolean;
  children?: React.ReactNode;
  className?: string;
  classNameInner?: string;
  classNameChevron?: string;
  classNameAlign?: string;
  classNameTitle?: string;
  color?: [string, string];
  colorInner?: [string, string];
  hasSort?: boolean;
  hasFilter?: boolean;
  hasSearch?: boolean;
  hasViewControl?: boolean;
  hasSpacer?: boolean;
  hasAction?: React.ReactNode;
  inPanelView?: boolean;
  inSidebarView?: boolean;
  dashboardView?: boolean;
  inTabView?: boolean;
  titleView?: boolean;
  inListView?: boolean;
  collapsible?: boolean;
  fullWidth?: boolean;
  isPrimary?: boolean;
  isHelpTitle?: boolean;
  isErrorGroup?: boolean;
  isTransparent?: boolean;
  hasScreenTitle?: boolean;
  hasPadding?: boolean;
  summaryView?: boolean;
  spacingXl?: boolean;
  isClosed?: boolean;
  hasBlur?: boolean;
  isSticky?: boolean;
  eventView?: boolean;
  customOpenIcon?: React.ReactNode;
  customClosedIcon?: React.ReactNode;
};

const TitleSection: React.FC<TitleSectionProps> = props => {
  const { t } = useTranslation('app');

  const [state, setState] = useRecoilState(collapsibleState(props.id));
  const isClosed = props.collapsible && state.isCollapsed;
  const toggleOpen = () => {
    setState({
      isCollapsed: !isClosed,
    });
  };

  const [bgColor, textColor] = props.color ?? [];
  const [bgColorInner, textColorInner] = props.colorInner ?? [];

  const ItemIndicator = () => {
    return (
      <div
        data-component="ItemIndicator"
        className={cn(
          'flex items-center',
          props.inPanelView ? 'mx-1' : 'mx-2 mb-2 mt-4',
        )}
      >
        {props.totalCount !== 0 &&
          props.totalCount &&
          props.loadedCount === props.totalCount && (
            <div
              className={cn(
                'px-0.5 ltr:ml-0.5 rtl:mr-0.5',
                'text-menu-text/60',
                props.inPanelView || props.inSidebarView
                  ? 'text-xs'
                  : 'text-sm',
              )}
            >
              {'('}
              {props.totalCount}
              {')'}
            </div>
          )}

        {props.totalCount === 0 && (
          <div
            className={cn(
              'px-0.5 ltr:ml-0.5 rtl:mr-0.5',
              'text-menu-text/60',
              props.inPanelView || props.inSidebarView ? 'text-xs' : 'text-sm',
            )}
          >
            <span className="opacity-80">{'('}</span>
            <span className="mx-0.5">{t`0 Found`}</span>
            <span className="opacity-80">{')'}</span>
          </div>
        )}

        {props.loadedCount < props.totalCount && (
          <div
            data-component="titleCounter"
            className={cn(
              'px-0.5 ltr:ml-0.5 rtl:mr-0.5',
              'text-menu-text/60',
              props.inPanelView || props.inSidebarView ? 'text-xs' : 'text-sm',
            )}
          >
            <span className="opacity-80">{'('}</span>
            <span>{formatInteger(props.loadedCount)}</span>
            <span className="mx-1 opacity-80">{t`of`}</span>
            <span>{formatInteger(props.totalCount)}</span>
            <span className="opacity-80">{')'}</span>
          </div>
        )}
      </div>
    );
  };

  const styleBlur = cn(
    props.isTransparent
      ? ''
      : ' backdrop-filter backdrop-blur-lg backdrop-saturate-110 bg-opacity-60 ',
  );

  const styleStickyTop = 'sticky top-0 z-100';

  const styleIconCollapsible =
    'w-4 h-4 mx-1 any-hover:group-hover:text-menu-active';
  const styleButtonCollapsible = classNames(
    'fill-current',
    props.isErrorGroup
      ? props.summaryView
        ? 'text-menu-text any-hover:group-hover:text-menu-text'
        : 'ltr:ml-2 rtl:mr-2 text-menu-text'
      : '',
    props.inSidebarView ||
      props.summaryView ||
      props.inPanelView ||
      props.dashboardView
      ? props.classNameChevron
        ? props.classNameChevron
        : styleIconCollapsible
      : props.classNameChevron
        ? props.classNameChevron
        : styleIconCollapsible,
  );

  return (
    <>
      <h3
        data-component={`TitleSection${
          props.dataComponent ? '-' + props.dataComponent : ''
        }`}
        data-label={`title-section-${props.title || ''}`}
        aria-label={`title-section-${props.title || ''}`}
        title={props.titleAlt ? props.titleAlt : ''}
        className={classNames(
          'flex items-center py-2',
          'group',
          // 'relative', // Create an issue with the sticky top
          { 'cursor-pointer': props.collapsible },
          { 'w-full': props.fullWidth },
          { 'mt-3 py-2 ltr:-ml-8 rtl:-mr-8': props.inListView },
          { 'ltr:pr-1 ltr:xl:pr-4 rtl:pl-1 rtl:xl:pl-4': props.hasAction },
          { sticky: props.isSticky },
          { 'bg-sidebar-header backdrop-blur': props.hasBlur },
          {
            styleStickyTop: props.hasScreenTitle,
          },
          props.hasScreenTitle
            ? styleBlur +
                `${styleStickyTop} ${props.isTransparent ? '' : 'bg-app-stat/60 hover:bg-app-stat/40'}`
            : props.inSidebarView
              ? styleBlur +
                `inSidebarView sticky top-0 text-sm ${
                  props.isTransparent
                    ? ''
                    : 'bg-app-panel-dark/50 hover:bg-opacity-50'
                }`
              : props.inTabView
                ? styleBlur +
                  ' ' +
                  `inTabView sticky top-[7rem] text-2xl ${props.isTransparent ? '' : 'bg-app-panel-stat/50 hover:bg-opacity-50'}`
                : props.titleView
                  ? styleBlur +
                    ' ' +
                    `titleView z-200 sticky top-0 text-sm uppercase ${props.isTransparent ? '' : 'bg-app-panel-stat hover:bg-opacity-50'}`
                  : props.summaryView
                    ? `summaryView text-xs lg:text-sm ${props.isTransparent ? '' : 'bg-app-panel-stat/80 hover:bg-opacity-90'}`
                    : props.dashboardView
                      ? 'dashboardView text-xs lg:text-sm'
                      : props.inPanelView
                        ? styleBlur +
                          ' ' +
                          // 'sticky top-8 z-10' +
                          `inPanelView mb-0 h-9 text-xs lg:text-sm ${props.isTransparent ? '' : props.eventView ? 'bg-app-panel group-hover:bg-menu-active/50 group-hover:text-menu-active-text !bg-opacity-100' : 'bg-app-panel-stat hover:bg-opacity-50'}`
                        : props.inPanelView && props.hasScreenTitle
                          ? styleBlur +
                            ' ' +
                            `inPanelViewScreenTitle z-200 sticky top-20 text-sm ${props.isTransparent ? '' : 'bg-app-panel-stat/60 hover:bg-opacity-50'}`
                          : props.isErrorGroup
                            ? styleBlur +
                              'isErrorGroup bg-alerts-error z-200 sticky top-0 text-base hover:bg-opacity-50'
                            : props.isHelpTitle
                              ? styleBlur +
                                'isHelpTitle z-200 sticky top-20 mb-1 mt-8 py-4 text-xl'
                              : styleBlur +
                                ' ' +
                                `mt-10- z-100 sticky top-0 mb-4 px-4 py-4 text-xl md:text-2xl lg:text-3xl ${props.isTransparent ? '' : 'bg-app-panel-stat/75'}`,
          props.isPrimary || props.titleView
            ? 'text-menu-title'
            : props.dashboardView
              ? 'text-menu-h2'
              : props.inSidebarView || props.isErrorGroup || props.inPanelView
                ? 'text-menu-h3/90'
                : 'text-menu-h3/75',
          isClosed
            ? `isClosed ${
                props.titleView
                  ? 'border-menu-title border-b'
                  : 'border-menu/50 border-b'
              }`
            : `isOpen ${
                props.isErrorGroup
                  ? 'border-app-panel-dark/50 border-b'
                  : props.summaryView
                    ? 'border-menu border-b'
                    : props.dashboardView
                      ? 'border-app-stat border-b'
                      : 'border-menu-title border-b shadow'
              }`,
          props.classNameAlign || 'items-center',
          props.className,
        )}
        onClick={e => {
          if (props.collapsible) {
            e.stopPropagation();
            toggleOpen();
          }
        }}
        style={{ background: bgColor, color: textColor }}
      >
        {(props.collapsible && (
          <>
            <button
              type="button"
              className={classNames(
                props.summaryView ? '' : 'ltr:mr-1 rtl:ml-1',
              )}
              aria-label={`Button-${props.title}-${
                isClosed ? 'closed' : 'open'
              }`}
            >
              {isClosed ? (
                props.customClosedIcon ? (
                  props.customClosedIcon
                ) : (
                  <Icon.ChevronDualOpenVertical
                    className={styleButtonCollapsible}
                  />
                )
              ) : props.customOpenIcon ? (
                props.customOpenIcon
              ) : (
                <Icon.ChevronDualCloseVertical
                  className={styleButtonCollapsible}
                />
              )}
            </button>
            <div
              data-component="Title"
              data-title={props.title}
              className={classNames(
                { truncate: typeof props.title === 'string' },
                {
                  'flex-1':
                    props.hasAction ||
                    props.titleSummaryOpen ||
                    props.titleSummaryClosed ||
                    typeof props.title !== 'string',
                },
                { 'ltr:pl-4 rtl:pr-4': !props.collapsible && !props.eventView },
                { 'px-3': props.collapsible && props.eventView },
                props.classNameTitle,
              )}
            >
              {props.title}
            </div>

            {isClosed ? (
              <span
                data-component="TitleSummaryClosed"
                className={classNames('text-base')}
              >
                {props.titleSummaryClosed ? props.titleSummaryClosed : ''}
              </span>
            ) : (
              <span
                data-component="TitleSummaryOpen"
                className={classNames('text-base')}
              >
                {props.titleSummaryOpen ? props.titleSummaryOpen : ''}
              </span>
            )}
          </>
        )) || (
          <span
            data-component="Title"
            className={classNames(
              { truncate: typeof props.title === 'string' },
              { 'flex-1': props.hasAction || typeof props.title !== 'string' },
              { 'ltr:pl-2 rtl:pr-2': props.inPanelView },
              { 'ltr:pl-4 rtl:pr-4': !props.inPanelView },
            )}
          >
            {props.title}
          </span>
        )}

        {props.hasSpacer && !props.inPanelView && (
          <Spacer hasHorizontalRule flexspace panelMode />
        )}

        {props.isLoading ? (
          <LoadingIndicator transparent inline className={classNames('mx-2')} />
        ) : (
          <ItemIndicator />
        )}

        {props.hasSpacer && props.inPanelView && (
          <Spacer flexspace hasHorizontalRule noMarginRule panelMode />
        )}

        {props.hasAction}
        {props.hasSearch}
        {/* {props.hasSort && (
          <DropdownSelector
            className="text-sm text-white"
            DropAlignRight
            value={'Newest (Ascending)'}
            values={[
              'Sort by Ascending (Newest)',
              'Sort by Descending (Oldest)',
            ]}
            // onChange={}
          />
        )}
        {props.hasViewControl && (
          <DropdownSelector
            className={`text-sm`}
            classNameItem={`text-xs`}
            vertical
            headerMode
            DropAlignRight
            value={'View as List'}
            values={['View as List', 'View as Cards']}
            // onChange={}
          />
        )} */}
      </h3>

      {props.children && (
        <Transition
          data-component="TitleSectionInner"
          show={!props.collapsible || !isClosed}
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform-scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
          className={classNames(
            {
              'p-2 py-4 pb-6 xl:p-4 xl:py-8 xl:pb-10': props.hasPadding,
            },
            {
              'divide-menu bg-app-panel-dark/50 divide-y': props.hasPadding,
            },
            { 'space-y-0.5': props.summaryView },
            props.classNameInner,
          )}
          style={{ background: bgColorInner, color: textColorInner }}
        >
          {props.children}
        </Transition>
      )}
    </>
  );
};

export default TitleSection;
