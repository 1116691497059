import React from 'react';
import { cn } from '../common/utils';
import CopyToClipboardButton from '../components/actions/CopyToClipboardButton';
import * as Icon from '../components/icons';
import LinkTraced from '../components/nav/LinkTraced';
import { getFileIconByFilename } from '../import/store/import.helper';
import Tag from './Tag';

type TagProps = {
  value: string;
  url?: string;
  showFull?: boolean;
  canCopy?: boolean;
  startSlice?: number;
  endSlice?: number;
  className?: string;
};

export const TagFilename = ({
  value,
  url,
  showFull = false,
  canCopy = true,
  startSlice = 64,
  endSlice = 8,
  className,
}: TagProps) => {
  if (!value) return null;

  return (
    <Tag
      data-component="TagFilename"
      className={cn('group relative w-full', '!px-1', className)}
      classType={url ? 'py-2' : null}
      title={value}
    >
      <LinkTraced
        rel="noopener noreferrer"
        className={cn(
          'group',
          'cursor-pointer',
          'flex items-center',
          'flex-grow',
          'hover:text-menu-active',
          'w-full truncate',
          'ltr:mr-1 rtl:ml-1',
        )}
        to={url}
        target="_blank"
      >
        {React.createElement(getFileIconByFilename(value), {
          className: cn(
            'h-5 w-5 fill-current',
            'ltr:mr-1 rtl:ml-1',
            url ? ['transition-opacity', 'group-hover:opacity-30'] : '',
          ),
        })}

        {url && (
          <Icon.ArrowBottom
            className={cn(
              'h-4 w-4 fill-current',
              'absolute inset-0',
              url
                ? ['transition-opacity', 'opacity-0 group-hover:opacity-100']
                : '',
            )}
          />
        )}

        {showFull
          ? value
          : value.length > startSlice + endSlice
            ? `${value.slice(0, startSlice)} - ... - ${value.slice(-endSlice)}`
            : value}
      </LinkTraced>
      {canCopy && (
        <CopyToClipboardButton
          hasIconSmall
          value={value}
          className="absolute -left-2 top-0 hidden group-hover:inline-flex"
        />
      )}
    </Tag>
  );
};
