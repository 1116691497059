import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { AsyncLoadStatus } from '../../../common/types';
import { HelperMessage } from '../../../components/HelperMessage';
import { Button } from '../../../components/actions/Button';
import * as Icon from '../../../components/icons';
import {
  userSettings,
  userSettingsLoadStatus,
} from '../../../store/warehouse.state';
import useUpdateUserSettings from '../../hooks/useUpdateUserSettings';
import { BetaCard } from './BetaCard';

export type BetaProps = {
  children?: React.ReactNode;
};

export const BetaScheduling = ({ children }: BetaProps) => {
  const { t } = useTranslation('app-beta');

  const [id] = useState<string>(_.uniqueId());
  const settings = useRecoilValue(userSettings);
  const settingsStatus = useRecoilValue(userSettingsLoadStatus);
  const updateUserSettings = useUpdateUserSettings();

  return (
    <BetaCard subtitle={t`Beta`} title={t`Scheduling`}>
      <HelperMessage hasPadding className="flex-1">
        <h4 className="text-menu-active mb-2 mt-8 text-xl">{t`Why?`}</h4>
        <p>
          {t`Optimise warehouse operations by intelligently scheduling dock operations, labour, and inventory movements.`}
        </p>
        <h4 className="text-menu-active mb-2 mt-8 text-xl">{t`How?`}</h4>
        <p>
          {t`Advanced scheduling algorithms optimise cross-docking, transfer shipments, and labour allocation whilst considering future orders and inventory shelf life.`}
        </p>
        <h4 className="text-menu-active mb-2 mt-8 text-xl">{t`What do I get?`}</h4>
        <p>
          {t`• Efficient cross-dock operations
          • Minimised transfer shipments
          • Optimised dock locations
          • Future order visibility
          • Shelf life management
          • Smart labour scheduling`}
        </p>
      </HelperMessage>
      <Button
        className="mb-4 mt-8 rounded-full p-1"
        label={
          settings?.featureRequests?.billing
            ? t`Unsubscribe`
            : t`Join the Waitlist`
        }
        buttonType={settings?.featureRequests?.billing ? '' : 'primary'}
        isDisabled={settingsStatus === AsyncLoadStatus.Loading}
        isLoading={settingsStatus === AsyncLoadStatus.Loading}
        full
        hasIconAfter={settings?.featureRequests?.billing}
        hasIconBefore={!settings?.featureRequests?.billing}
        buttonIcon={
          settings?.featureRequests?.billing ? (
            <Icon.CircleOk className={`h-7 w-7 fill-current`} />
          ) : (
            <Icon.CircleArrowRight className={`h-8 w-8 fill-current`} />
          )
        }
        onPress={() => {
          updateUserSettings({
            featureRequests: {
              billing: !settings?.featureRequests?.billing,
            },
          });
        }}
      />
    </BetaCard>
  );
};
