import { ImportFormat } from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { cn } from '../../../common/utils';
import FileUpload from '../../../components/FileUpload';
import { Button } from '../../../components/actions/Button';
import * as Icon from '../../../components/icons';
import { Container } from '../../../components/layout/ContainerFlex';
import {
  importSelectedFile,
  importSourceSettings,
} from '../../store/import.state';
import useResetImportState from '../../useResetImportState';
import useUploadPreviewFile from '../../useUploadPreviewFile';

export type ImporterFileSelectorProps = {
  actionName: string;
  filename: string;
  disabled?: boolean;
  onFileSelect?: (file: File) => void;
};

const ImporterFileSelector: React.FC<ImporterFileSelectorProps> = props => {
  const { t } = useTranslation('importer');
  const [file, setFile] = useRecoilState(importSelectedFile);
  const [sourceSettings, setSourceSettings] =
    useRecoilState(importSourceSettings);

  const [createPreview] = useUploadPreviewFile();
  const resetStep = useResetImportState();
  const handleDatasetSelect = () => {
    createPreview({ file: null });
    resetStep({ step: 'import-transform' });
  };

  const handleFileChange = async (file: File) => {
    try {
      if (!file) {
        console.warn('No file selected');
        return;
      }

      setFile(file);

      if (props.onFileSelect) {
        await props.onFileSelect(file);
      } else {
        await createPreview({ file });
      }
    } catch (error) {
      console.error('Error handling file:', error);
      // Optionally add error handling UI feedback here
      setFile(null);
    }
  };

  let filterExtensions: string;
  let IconComponent;
  let compressionText = '';

  if (sourceSettings.compression === 'GZIP') {
    compressionText = '.gz';
  }

  switch (sourceSettings.format) {
    case 'CSV':
      filterExtensions = `.csv${compressionText}, .tsv${compressionText}, .txt${compressionText}`;
      IconComponent = Icon.LayoutWarehouseCsv;
      break;
    case 'JSON':
      filterExtensions = `.json${compressionText}, ${compressionText}`;
      IconComponent = Icon.LayoutWarehouseTemplate;
      break;
    default:
      filterExtensions = '.*';
      IconComponent = Icon.LayoutWarehouseTemplate;
      break;
  }

  const shouldImportFile = sourceSettings.format !== ImportFormat.DATASET;

  return (
    <Container
      className={classNames(
        'p-1',
        shouldImportFile &&
          (props.disabled
            ? 'striped-container-sm-disabled'
            : 'bg-barber-pole bg-barber-size bg-menu-active'),
      )}
    >
      {shouldImportFile && (
        <FileUpload accept={filterExtensions} onChange={handleFileChange}>
          <div
            className={cn(
              'flex-1',
              'flex w-full items-center justify-center',
              'mb-4',
            )}
          >
            <IconComponent
              className={cn('h-32 w-32', 'fill-current', 'text-menu/50')}
            />
          </div>
          <div className={cn('p-10', 'flex w-full flex-col items-center')}>
            {/* Rest of the UI remains the same */}
            <div
              className={cn(
                'flex items-center justify-between',
                'w-full',
                'px-4 py-2',
                'mt-4',
                'text-center',
                'rounded',
                'bg-app-panel-dark/60',
              )}
            >
              <span className={cn('text-menu-active flex-1')}>
                <span className={cn('opacity-50 ltr:mr-1 rtl:ml-1')}>
                  {t(`Accepted file type(s):`)}
                </span>
                {filterExtensions}
              </span>
            </div>
          </div>
        </FileUpload>
      )}
      {!shouldImportFile && (
        <div
          className={classNames(
            'flex-1',
            'flex w-full items-center justify-center',
            'p-4',
            'bg-sidebar-menu',
          )}
        >
          <Button
            className={classNames(
              'flex-1',
              'border-menu/50 border',
              'text-base',
              'min-w-64',
              'rounded',
            )}
            label={t`Select from Dataset`}
            buttonType="primary"
            hasIconAfter
            buttonIcon={
              <Icon.CloudUpload
                className={classNames('h-5 w-5 fill-current')}
              />
            }
            isDisabled={false}
            isLoading={false}
            onPress={handleDatasetSelect}
          />
        </div>
      )}
    </Container>
  );
};

export default ImporterFileSelector;
