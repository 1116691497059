import {
  useDemoWarehousesQuery,
  WarehouseType,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React, { PropsWithChildren, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AsyncLoadStatus } from '../../../common/types';
import { Button } from '../../../components/actions/Button';
import { HelperMessageAction } from '../../../components/HelperMessage';
import * as Icon from '../../../components/icons';
import InboxZero from '../../../components/InboxZero';
import InputCheckbox from '../../../components/inputs/InputCheckbox';
import { InputSearch } from '../../../components/inputs/InputSearch';
import { ScreenTitle } from '../../../components/layout/ScreenTitle';
import LoadingIndicator from '../../../components/LoadingIndicator';
import PanelContainer from '../../../containers/PanelContainer';
import Callout from '../../../helpContext/Callout';
import { sidebarStateByType } from '../../../store/sidebar.state';
import {
  warehouseList,
  warehouseListLoadStatus,
  warehouseSelectedId,
} from '../../../store/warehouse.state';
import useAssignDemoWarehouse from '../../../warehouse/hooks/useAssignDemoWarehouse';

export type PanelProps = PropsWithChildren & {
  className?: string;
};

const PanelSettingsWarehouses: React.FC<PanelProps> = props => {
  const { t } = useTranslation('app');
  const navigate = useNavigate();

  const warehouseId = useRecoilValue(warehouseSelectedId);

  const whList = useRecoilValue(warehouseList);
  const whListLoadStatus = useRecoilValue(warehouseListLoadStatus);

  const [sidebarState, setSidebarState] = useRecoilState(
    sidebarStateByType('sidebar-global-settings'),
  );

  const [localLoading, setLocalLoading] = useState(false);
  // Demo
  const assignDemo = useAssignDemoWarehouse();
  const [searchQuery, setSearchQuery] = useState('');

  const {
    loading: loadingDemoWh,
    error,
    data: demoWhData,
  } = useDemoWarehousesQuery();
  const demoWHlist = loadingDemoWh ? [] : demoWhData.demoWarehouses;

  return (
    <PanelContainer
      id="panel-user-warehouses"
      title={t`Warehouse Settings`}
      collapsible
      // hasPadding
    >
      <PanelContainer
        id="panel-my-warehouses"
        // hasPadding
        // collapsible
        transparent
        title={t`My Warehouses`}
        className="flex flex-wrap space-y-1"
      >
        <ScreenTitle
          isSticky
          subtitle={t`System`}
          title={t`Warehouse Settings`}
        />

        <Callout
          panelMode
          type="suggestion"
          iconAfter
          icon={Icon.ArrowRightBottom}
        >
          {t`Select`}
          <HelperMessageAction>{t`the warehouse`}</HelperMessageAction>
          {t`to change it's settings`}
        </Callout>

        <div className="p-4">
          <InputSearch
            // titleMode
            sidebarFilterMode
            placeholder={t`Search warehouses...`}
            value={searchQuery || ''}
            onChange={value => setSearchQuery(value)}
            className="!mt-0"
            classNamePlaceholder="!left-2 !top-2"
            classNameInput="!p-2 !text-xs !bg-app-panel-dark/80"
          />
        </div>

        {whListLoadStatus === AsyncLoadStatus.Loading ? (
          <LoadingIndicator />
        ) : (
          <div className="divide divide-menu-800 divide-y p-2">
            {(() => {
              const filteredWarehouses = _(whList)
                .filter(
                  wh =>
                    wh.title
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase()) &&
                    wh.type !== WarehouseType.DEMO &&
                    wh.type !== null,
                )
                .value();

              if (filteredWarehouses.length === 0) {
                return <InboxZero message={t`No Warehouses found`} />;
              }

              return filteredWarehouses.map(wh => (
                <Button
                  className="backdrop-saturate-110 bg-opacity-80 px-1 text-start backdrop-blur-lg backdrop-filter"
                  key={`wh-${wh.id}`}
                  buttonSize={'sm'}
                  buttonType={'secondary'}
                  label={wh.title}
                  full
                  hasIconBefore
                  buttonIcon={
                    <Icon.WarehouseParams1
                      className={`h-10 w-10 fill-current p-0.5`}
                    />
                  }
                  onPress={() => {
                    setSidebarState({ ...sidebarState, isCollapsed: true });
                    navigate(`/wh/i/${wh.id}/edit`);
                  }}
                />
              ));
            })()}
          </div>
        )}
      </PanelContainer>
      <PanelContainer
        id="panel-demo-warehouses"
        // hasPadding
        // collapsible
        transparent
        title={t`Demo Warehouses`}
        classNameInner="p-2"
      >
        <ScreenTitle isSticky subtitle={t`System`} title={t`Demo Warehouses`} />

        <Callout
          panelMode
          type="suggestion"
          iconAfter
          icon={Icon.ArrowRightBottom}
        >
          {t`Select`}
          <HelperMessageAction>{t`the warehouse`}</HelperMessageAction>
          {t`to add the warehouse as a Demo`}
        </Callout>

        <div className="p-2">
          {loadingDemoWh ? (
            <LoadingIndicator message={t`Loading Demo...`} />
          ) : (
            demoWHlist.map(wh => {
              const isSubscribed = _.some(
                whList,
                w => w.id === wh.warehouse.id,
              );
              return (
                <div
                  key={`${wh.warehouse.id}`}
                  className="bg-app-panel-light/50"
                >
                  {/* <Button
                    className="px-1 text-start bg-opacity-80 backdrop-filter backdrop-blur-lg backdrop-saturate-110"
                    key={`wh-${wh.warehouse.id}`}
                    buttonSize={'md'}
                    buttonType={`primary`}
                    // buttonType={'primary'}
                    label={wh.warehouse.title}
                    full
                    isDisabled={localLoading}
                    hasIconBefore
                    buttonIcon={
                      <Icon.LayoutWarehouseTemplate
                        className={`w-16 h-16 fill-current p-0.5`}
                      />
                    }
                    onPress={async () => {
                      setLocalLoading(true);
                      await assignDemo(wh.warehouse.id, !isSubscribed);
                      if (currentWhId === wh.warehouse.id && isSubscribed) {
                        navigate('/');
                      }
                      setLocalLoading(false);
                    }}
                  /> */}
                  <InputCheckbox
                    className="p-2"
                    key={`wh-demo-${wh.warehouse.id}`}
                    name="warehouse-demo"
                    isDisabled={localLoading}
                    isSelected={isSubscribed}
                    onChange={async () => {
                      setLocalLoading(true);
                      await assignDemo(wh.warehouse.id, !isSubscribed);
                      if (warehouseId === wh.warehouse.id && isSubscribed) {
                        navigate('/');
                      }
                      setLocalLoading(false);
                    }}
                  >
                    <div className="text-white">{wh.warehouse.title}</div>
                  </InputCheckbox>
                </div>
              );
            })
          )}
        </div>
      </PanelContainer>
    </PanelContainer>
  );
};

export default PanelSettingsWarehouses;
