import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { cctvEnabled } from '../../../cctv/cctv.state';
import InputCheckbox from '../../../components/inputs/InputCheckbox';
import Callout from '../../../helpContext/Callout';

export const SettingsServiceOptionsCCTV: React.FC = () => {
  const { t } = useTranslation('simulation');
  const [CCTVEnabled, setCCTVEnabled] = useRecoilState(cctvEnabled);

  return (
    <section className="divide-menu-400/50 mb-4 w-full divide-y divide-solid p-4">
      <InputCheckbox
        name="notification"
        className="p-2"
        isSelected={CCTVEnabled}
        onChange={v => setCCTVEnabled(v)}
      >
        <div>{t`CCTV Integration (Demo Warehouse)`}</div>
      </InputCheckbox>
      <Callout panelMode type="suggestion">
        {/* <svg
              id="a"
              data-name="Icons"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 128 153.42"
              className="w-12 h-12 float-left mr-2 p-0.5"
            >
              <path
                d="m60.32,15.51v130.99L5.4,114.86,60.32,15.51m4-15.51L0,116.37l64.32,37.05V0h0Z"
                fill="#ececec"
              />
              <path
                d="m107.9,127.95l-44.22,25.47V0h0l52.92,96.2-6.06,3.35L63.68,14.72v130.19l40.77-22.95m14.21-8.18"
                fill="#ececec"
              />
              <polyline
                points="118.65 113.77 112.94 103.7 119 100.35 128 116.37 111.92 125.63 108.47 119.63 118.65 113.77"
                fill="#00b8d3"
              />
            </svg> */}
        <span>{`Automate Warehouse safety by integrating with existing infrastructure like security cameras (CCTV) and sensors and gives real-time insights around working conditions, unsafe events, and operations.`}</span>
        {/* <div>
              {`Visit`}
              <a
                className="text-menu-active hover:underline mx-1"
                href="https://arvist.ai"
                target="_blank"
                rel="noreferrer"
              >
                {`https://arvist.ai`}
              </a>
              {`to learn more`}
            </div> */}
      </Callout>
    </section>
  );
};
