import type { editor } from 'monaco-editor';
export const codeEditorWarebeeThemeId = 'warebeeTheme';
export const codeEditorWarebeeTheme: editor.IStandaloneThemeData = {
  base: 'vs-dark',
  inherit: false,
  rules: [
    // Basic syntax
    { token: 'keyword', foreground: '#6dfff8' },
    { token: 'identifier.sql', foreground: '#ffbd2e' },
    { token: 'identifier', foreground: '#9CDCFE' },
    { token: 'string', foreground: '#D6FF83' },
    { token: 'number', foreground: '#59B3F7' },
    { token: 'number.hex', foreground: '#59B3F7' },
    { token: 'operator', foreground: '#CDAEFF' },
    { token: 'delimiter', foreground: '#FF5B5B' },
    { token: 'predefined', foreground: '#B35DFF' },
    { token: 'comment', foreground: '#8FA6BF' },
    { token: 'type', foreground: '#569CD6' }, // e.g., VARCHAR, INTEGER, BOOLEAN
    { token: 'variable', foreground: '#9CDCFE' }, // e.g., @myVar, $temp
    { token: 'parameter', foreground: '#9CDCFE' }, // e.g., function(param1, param2)
    { token: 'function', foreground: '#DCDCAA' }, // e.g., COUNT(), SUM(), CONCAT()
    { token: 'constant', foreground: '#4FC1FF' }, // e.g., NULL, TRUE, FALSE
    { token: 'regexp', foreground: '#D16969' }, // e.g., '^[a-z]+$', '/\d+/'
    { token: 'namespace', foreground: '#EEFF8C' }, // e.g., schema_name.table_name
    { token: 'emphasis', fontStyle: 'italic' }, // e.g., *italic text*
    { token: 'strong', fontStyle: 'bold' }, // e.g., **bold text**
    { token: 'keyword.operator', foreground: '#CDAEFF' }, // e.g., AND, OR, NOT

    // Template variables
    { token: 'dataset-variable', foreground: '#F3F65B', fontStyle: 'bold' },
    { token: 'dataset-variable-id', foreground: '#F5CD4D', fontStyle: 'bold' },
    { token: 'input-variable', foreground: '#EEFF8C', fontStyle: 'bold' },

    // Quoted identifiers
    { token: 'identifier.quoted', foreground: '#ffbd2e' },
    { token: 'identifier.unquoted', foreground: '#9CDCFE' },
  ],
  colors: {
    'editor.background': '#12151A',
    'editor.foreground': '#F8F8F2',
    'editor.lineHighlightBackground': '#2F3337',
    'editor.selectionBackground': '#264F78',
    'editorCursor.foreground': '#FFFFFF',
    'editor.selectionHighlightBackground': '#EEFF8C10',
    'editorLineNumber.foreground': '#4F5962',
    'editorLineNumber.activeForeground': '#F3F65B',
  },
};
