import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { AsyncLoadStatus } from '../../../common/types';
import { HelperMessage } from '../../../components/HelperMessage';
import { Button } from '../../../components/actions/Button';
import * as Icon from '../../../components/icons';
import {
  userSettings,
  userSettingsLoadStatus,
} from '../../../store/warehouse.state';
import useUpdateUserSettings from '../../hooks/useUpdateUserSettings';
import { BetaCard } from './BetaCard';

export type BetaProps = {
  children?: React.ReactNode;
};

export const BetaDashboards = ({ children }: BetaProps) => {
  const { t } = useTranslation('app-beta');

  const [id] = useState<string>(_.uniqueId());
  const settings = useRecoilValue(userSettings);
  const settingsStatus = useRecoilValue(userSettingsLoadStatus);
  const updateUserSettings = useUpdateUserSettings();

  return (
    <BetaCard subtitle={t`Beta`} title={t`Warehouse Control Tower`}>
      <HelperMessage hasPadding className="flex-1">
        <h4 className="text-menu-active mb-2 mt-8 text-xl">{t`Why?`}</h4>
        <p>
          {t`Get complete visibility and control over your warehouse operations with customizable dashboards and real-time monitoring capabilities.`}
        </p>
        <h4 className="text-menu-active mb-2 mt-8 text-xl">{t`How?`}</h4>
        <p>
          {t`Interactive dashboards provide temporal views of your operations - compare yesterday's performance, monitor today's activities in real-time, and plan for tomorrow's challenges. Customizable widgets and KPIs help you focus on what matters most to your operation.`}
        </p>
        <h4 className="text-menu-active mb-2 mt-8 text-xl">{t`What do I get?`}</h4>
        <p>
          {t`• Real-time warehouse activity monitoring
          • Customizable dashboard views
          • Historical performance analysis
          • Predictive operational insights
          • Live KPI tracking
          • Resource utilization metrics
          • Multi-warehouse overview
          • Automated alert system`}
        </p>
      </HelperMessage>
      <Button
        className="mb-4 mt-8 rounded-full p-1"
        label={
          settings?.featureRequests?.billing
            ? t`Unsubscribe`
            : t`Join the Waitlist`
        }
        buttonType={settings?.featureRequests?.billing ? '' : 'primary'}
        isDisabled={settingsStatus === AsyncLoadStatus.Loading}
        isLoading={settingsStatus === AsyncLoadStatus.Loading}
        full
        hasIconAfter={settings?.featureRequests?.billing}
        hasIconBefore={!settings?.featureRequests?.billing}
        buttonIcon={
          settings?.featureRequests?.billing ? (
            <Icon.CircleOk className={`h-7 w-7 fill-current`} />
          ) : (
            <Icon.CircleArrowRight className={`h-8 w-8 fill-current`} />
          )
        }
        onPress={() => {
          updateUserSettings({
            featureRequests: {
              billing: !settings?.featureRequests?.billing,
            },
          });
        }}
      />
    </BetaCard>
  );
};
