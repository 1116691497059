import {
  BatchJobStatus,
  ExportJobFragment,
  useLoadExportJobLazyQuery,
  useSendExportJobMutation,
} from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import { format } from 'date-fns-tz';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { EXPORT_PROGRESS_REFRESH_INTERVAL } from '../../common/constants';
import { formatBytes, formatTimestampToDate } from '../../common/formatHelper';
import { loggedInUser } from '../../store/auth.state';
import { brandName } from '../../store/global.state';
import {
  warehouseSelected,
  warehouseSelectedId,
} from '../../store/warehouse.state';
import { WarehouseExtraSettings } from '../../store/warehouse.types';
import * as Icon from '../icons';
import Loader from '../loaders/Loader';
import { Button } from './Button';

export type SendToEmailButtonProps = {
  title?: string;
  onStartExportClick?: () => Promise<{ job: ExportJobFragment; errors: any }>;
  templateData?: {
    export_subject?: string;
    export_body?: string;
    warehouse_id?: string;
    warehouse_name?: string;
    warehouse_company_name?: string;
    warehouse_url?: string;
    username?: string;
    name?: string;
    given_name?: string;
    family_name?: string;
    timestamp?: string;
    date_formatted?: string;
    size?: string;
    file_name?: string;
    file_url?: string;
  };
};

const SendToEmailButton: React.FC<SendToEmailButtonProps> = props => {
  const { t } = useTranslation('app');
  const brandTitle = useRecoilValue(brandName);
  const warehouse = useRecoilValue(warehouseSelected);

  const user = useRecoilValue(loggedInUser);

  const emails = (warehouse?.extraSettings as WarehouseExtraSettings)
    ?.notificationsEmails;

  const whId = useRecoilValue(warehouseSelectedId);

  const [loadExportJob, { data: exportJobResponse }] =
    useLoadExportJobLazyQuery();
  const [exportJob, setExportJob] = useState<ExportJobFragment>();
  const [exportLoading, setExportLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [showLinkReady, setShowLinkReady] = useState(false);
  const [sendExport] = useSendExportJobMutation();
  const job = exportJob
    ? exportJob.id === exportJobResponse?.exportJob?.id
      ? exportJobResponse?.exportJob
      : exportJob
    : null;

  async function send(exportJobId: string) {
    try {
      setExportLoading(true);
      const to = emails ?? [user.email];

      const exportTitle = t`Export`;
      const now = new Date();
      const timestamp = format(now, 'yyyy-MM-dd-HHmm');
      const shortWarehouseTitle = warehouse.title.replace(
        /[^a-zA-Z0-9-]/g,
        '-',
      );
      const shortWarehouseId = warehouse.id.split('-')[0];

      const export_subject = `Exported: ${job.filename}`;

      const export_body = `
      Your requested export has been processed \n
      \n ${exportTitle}
      \n ${timestamp}
      \n ${shortWarehouseId}

      \n ${job.downloadUrl}
       `;

      const { data, errors } = await sendExport({
        variables: {
          input: {
            exportJobId,
            to: to,
            templateData: {
              export_subject: export_subject,
              export_body: '',
              warehouse_id: warehouse.id,
              warehouse_name: warehouse.title,
              warehouse_company_name: warehouse.companyName,
              username: user.id,
              name: user.fullName,
              given_name: user.firstName,
              family_name: user.lastName,
              timestamp: new Date().toISOString(),
              date_formatted: formatTimestampToDate(now),
              size: formatBytes(job.fileSize),
              file_name: job.filename,
              file_url: job.downloadUrl,
            },
          },
        },
      });

      // return {
      //   errors: errors,
      //   job: data.createExportJob,
      // };
    } catch (ex) {
      setHasErrors(true);
    } finally {
      setExportLoading(false);
    }
  }

  useEffect(() => {
    let timeoutId;

    if (
      job?.status === BatchJobStatus.FAILED ||
      job?.status === BatchJobStatus.TERMINATED
    ) {
      setHasErrors(true);
      setExportLoading(false);
    }

    if (
      job?.status === BatchJobStatus.CALCULATING ||
      job?.status === BatchJobStatus.CREATED
    ) {
      timeoutId = setTimeout(() => {
        loadExportJob({ variables: { id: job.id } });
      }, EXPORT_PROGRESS_REFRESH_INTERVAL);
    }
    if (job?.status === BatchJobStatus.READY && job?.downloadUrl) {
      send(job.id);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [job, loadExportJob]);

  async function startExport() {
    setHasErrors(false);
    setExportLoading(true);
    try {
      const exportJobResponse = await props.onStartExportClick();
      if (
        _.isEmpty(exportJobResponse.errors) &&
        !_.isNil(exportJobResponse.job)
      ) {
        setExportJob(exportJobResponse.job);
      } else {
        console.error(exportJobResponse.errors);
        setHasErrors(true);
        setExportLoading(false);
      }
    } catch (ex) {
      console.error(ex);
      setHasErrors(true);
      setExportLoading(false);
    }
  }

  const getIcon = () => {
    if (hasErrors)
      return <Icon.Close className={classNames('h-4 w-4 fill-current')} />;
    if (exportLoading)
      return <Loader size={16} stroke={6} type={'loading-original'} />;
    return <Icon.Email className={classNames('h-4 w-4 fill-current')} />;
  };

  const getLabel = () => {
    if (hasErrors) return t`Error`;
    if (exportLoading) return t`Sending...`;
    return props.title ?? null;
  };

  return (
    <>
      {!showLinkReady && (
        <Button
          className={classNames(
            'hover:text-menu-active',
            'bg-menu/50 hover:bg-menu',
            'text-xxs',
            'flex items-center',
            'rounded-full',
            'px-2 py-1',
            'focus:outline-none focus:ring-0',
          )}
          buttonSize="xs"
          buttonType={hasErrors ? 'delete' : 'buttonTransparent'}
          isDisabled={exportLoading}
          //isLoading={exportLoading}
          hasIconBefore
          buttonIcon={getIcon()}
          label={getLabel()}
          onPress={startExport}
        />
      )}

      {showLinkReady && (
        <a
          rel="noopener noreferrer"
          className={classNames(
            'text-menu-active/90 hover:text-menu-active flex items-center px-2 py-0.5 text-sm',
          )}
          href={`${job.downloadUrl}`}
          target="_blank"
          onClick={() => {
            setShowLinkReady(false);
            setExportJob(null);
          }}
        >
          <Icon.CloudDownload className={classNames('h-4 w-4 fill-current')} />
          <span
            className={classNames('ltr:ml-2 rtl:mr-2')}
          >{t`Download (csv)`}</span>
        </a>
      )}
    </>
  );
};

export default SendToEmailButton;
