import {
  AgentRole,
  AnalyzeResultFragment,
  AnalyzeResultJobsConnectionFragment,
  AnalyzeResultLocationsConnectionFragment,
  AnalyzedPicklistEventsFragment,
  AssignmentChangeSequenceAction,
  GateUsageScheduleFragment,
  LoadPicklistDocument,
  LoadPicklistQuery,
  ResourceUsageSummaryFragment,
  ResourceUsageSummaryGroupBy,
  ScheduleGateUsageDocument,
  ScheduleGateUsageMutation,
  ScheduleGateUsageMutationVariables,
  SortDirection,
} from '@warebee/frontend/data-access-api-graphql';
import {
  AnalyzedProductsDataColumn,
  AnalyzedProductsDataRow,
  ReassignJobsDataColumn,
  ReassignJobsDataRow,
} from '@warebee/shared/export-converter';
import _ from 'lodash';
import { atom, selector, selectorFamily } from 'recoil';
import { secureClient } from '../../GraphQLClient';
import {
  AsyncLoadStatus,
  ContentViewAs,
  DatasetTableState,
  EventViewAs,
  ItemsViewAs,
  WorkloadViewAs,
} from '../../common/types';
import { cloneWithoutTypename } from '../../common/utils';
import {
  AnalyzeGatesStatsRow,
  getAnalyzeGatesStatsQuery,
} from '../../feed/store/datasetQueries/analyzeGatesStatsQuery';
import { executeDatasetQuery } from '../../feed/store/feed.helper';
import { warehouseSelectedId } from '../../store/warehouse.state';
import { allocationAnalyzeResult } from './allocation/allocation.state';
import { optimisationAnalyzeResult } from './optimisation.state';
import { resourcePolicy } from './resourcePolicy.state';
import {
  simulationAnalyzeId,
  simulationAnalyzeResult,
  simulationAnalyzeResultAtom,
} from './simulation.state';
import { loadWorkforceData } from './workforce.helper';

const getKey = (postfix: string) => `warebee-simulation-analyze-${postfix}`;

/**
 * ANALYZE JOBS TABLE related props
 */

export const analyzeJobDetails = atom<AnalyzeResultJobsConnectionFragment>({
  key: getKey('job-details-data'),
  default: null,
});

export const analyzeJobDetailsLoadStatus = atom<AsyncLoadStatus>({
  key: getKey('job-details-load-status'),
  default: AsyncLoadStatus.None,
});

export const analyzeJobDetailsTableState = atom<
  DatasetTableState<ReassignJobsDataColumn>
>({
  key: getKey('job-details-table-state'),
  default: {
    searchValues: {},
    sortValues: {
      stepId: SortDirection.ASC,
    },
  },
});

export const analyzeJobDetailsSelectedRow = atom<ReassignJobsDataRow>({
  key: getKey('job-details-table-selected-row'),
  default: null,
});

export const analyzeJobsTypeFilter = atom<AssignmentChangeSequenceAction>({
  key: getKey('jobs-type-filter'),
  default: null,
});

/**
 * ANALYZE PRODUCT TABLE related props
 */

export const analyzeLocationProductsData =
  atom<AnalyzeResultLocationsConnectionFragment>({
    key: getKey('location-products-data'),
    default: null,
  });

export const analyzeLocationProductsLoadStatus = atom<AsyncLoadStatus>({
  key: getKey('location-products-load-status'),
  default: AsyncLoadStatus.None,
});

export const analyzeLocationProductsTableState = atom<
  DatasetTableState<AnalyzedProductsDataColumn>
>({
  key: getKey('location-products-table-state'),
  default: {
    searchValues: {},
    sortValues: {},
  },
});

export const analyzeLocationProductsSelectedRow = atom<AnalyzedProductsDataRow>(
  {
    key: getKey('location-products-table-selected-row'),
    default: null,
  },
);

export const analyzeContentViewAs = atom<ContentViewAs>({
  key: getKey('view-as'),
  default: 'layout',
});

export const eventsContentViewAs = atom<EventViewAs>({
  key: getKey('events-view-as'),
  default: 'picking',
});

export const workloadContentViewAs = atom<WorkloadViewAs>({
  key: getKey('workload-view-as'),
  default: 'workforce',
});

export const itemsContentViewAs = atom<ItemsViewAs>({
  key: getKey('item-view-as'),
  default: 'stock',
});

export const analyzeAgentPerformanceAgentId = atom<string>({
  key: getKey('agent-performance-data'),
  default: null,
});

export const analyzeAgentPerformance = selectorFamily<
  ResourceUsageSummaryFragment[],
  string
>({
  key: getKey('agent-performance-data'),
  get:
    (analyzeId: string) =>
    async ({ get }) => {
      if (_.isNil(analyzeId)) return null;
      const agentId = get(analyzeAgentPerformanceAgentId);
      return loadWorkforceData({
        analyzeId,
        drillKeys: [
          ResourceUsageSummaryGroupBy.AGENT,
          ResourceUsageSummaryGroupBy.EVENT_TYPE,
        ],
        agentIds: _.isNil(agentId) ? null : [agentId],
      });
    },
});

export const analyzeResultById = selectorFamily<AnalyzeResultFragment, string>({
  key: getKey('result-by-id'),
  get:
    (analyzeId: string) =>
    ({ get }) => {
      const base = get(simulationAnalyzeResult);
      const allocate = get(allocationAnalyzeResult);
      const optimised = get(optimisationAnalyzeResult);
      const all = [base, allocate, optimised];

      return _.find(all, anl => anl?.id === analyzeId);
    },
});

export const analyzeResultOrderEvents = selector<
  Record<string, AnalyzedPicklistEventsFragment[]>
>({
  key: getKey('result-orders-summary'),
  get: async ({ get }) => {
    const analyzeId = get(simulationAnalyzeResultAtom)?.id;
    if (!analyzeId) return null;
    const response = await secureClient.query<LoadPicklistQuery>({
      query: LoadPicklistDocument,
      variables: {
        analyzeId,
      },
    });

    const res = response?.data?.analyzeResult?.orders;

    const picklists = _.reduce(
      res?.content,
      (acc, order) => {
        return {
          ...acc,
          [order.orderId]: order.picklists,
        };
      },
      {},
    );
    return picklists;
  },
});

export const analyzeEndpointsStats = selector<AnalyzeGatesStatsRow[]>({
  key: getKey('analyzed-events-summary'),
  get: async ({ get }) => {
    const warehouseId = get(warehouseSelectedId);
    const analyzeId = get(simulationAnalyzeId);
    const compiledQuery = getAnalyzeGatesStatsQuery({
      analyzeId,
    }).compile();
    const [result] = await executeDatasetQuery({
      warehouseId,
      compiledQuery,
      comment: '[simulation] Analyze Gates Stats',
    });
    return result;
  },
});

export const analyzeGateUsageSchedule = selector<GateUsageScheduleFragment>({
  key: getKey('gate-usage-schedule'),
  get: async ({ get }) => {
    const waitCost = get(analyzeGateUsageScheduleWaitingCost);
    const dockingDuration = get(analyzeGateUsageScheduleDockingDuration);
    const undockingDuration = get(analyzeGateUsageScheduleUnDockingDuration);
    const analyzeId = get(simulationAnalyzeResultAtom)?.id;
    if (!analyzeId) return null;

    const rp = get(resourcePolicy);
    try {
      const roleSettings = _(rp.agents)
        .map(a => a.roles)
        .flatten()
        .find(r => r.roleId === AgentRole.LOADING);
      const response = await secureClient.mutate<
        ScheduleGateUsageMutation,
        ScheduleGateUsageMutationVariables
      >({
        mutation: ScheduleGateUsageDocument,
        variables: {
          input: {
            analyzeResultId: analyzeId,
            waitCost: waitCost,
            loadingAgentSettings: cloneWithoutTypename(roleSettings),
            dockingDuration,
            undockingDuration,
          },
        },
      });

      const res = response?.data?.scheduleGateUsage;
      return res;
    } catch (ex) {
      console.error(ex);
    }
  },
});

export const analyzeGateUsageScheduleWaitingCost = atom<number>({
  key: getKey('gate-usage-schedule-waiting-cost'),
  default: 5 / 3600,
});

export const analyzeGateUsageScheduleDockingDuration = atom<number>({
  key: getKey('gate-usage-schedule-docking-duration'),
  default: 300, //5 min
});

export const analyzeGateUsageScheduleUnDockingDuration = atom<number>({
  key: getKey('gate-usage-schedule-un-docking-duration'),
  default: 300, //5 min
});
