import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import InputCheckbox from '../../../components/inputs/InputCheckbox';
import {
  calloutTypes,
  helperCalloutVisibilityState,
} from '../../../store/global.state';

export const SettingsSupportHelpers: React.FC = () => {
  const { t } = useTranslation('app');
  const [isHelperCalloutVisible, setHelperCalloutVisible] = useRecoilState(
    helperCalloutVisibilityState,
  );
  return (
    <div data-componentn="SettingsSupportHelpers">
      <label className="text-menu mb-2 mt-3">{t`Help Settings`}</label>
      {calloutTypes.map(type => (
        <InputCheckbox
          key={type}
          name={`visibility-${type}`}
          className="p-1"
          isSelected={isHelperCalloutVisible[type]}
          onChange={value => {
            setHelperCalloutVisible(prev => ({
              ...prev,
              [type]: value,
            }));
          }}
        >
          {isHelperCalloutVisible[type]
            ? t(['Hide ', ' Helpers'], { defaultValue: _.startCase(type) })
            : t(['Show ', ' Helpers'], { defaultValue: _.startCase(type) })}
        </InputCheckbox>
      ))}
    </div>
  );
};
