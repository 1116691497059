import {
  AnalyzeResultJobsSortBy,
  AnalyzeResultJobsSortOption,
  AnalyzeResultProcessType,
  AnalyzeResultReassignJobsFilter,
  LoadAnalyzeJobsDetailsDocument,
  LoadAssignmentDataDocument,
  SortDirection,
  StringSearchFilterType,
  useRunExportJobMutation,
} from '@warebee/frontend/data-access-api-graphql';
import {
  AssignmentConverterConfig,
  AssignmentExportJobParams,
  ReassignJobsConverterConfig,
  ReassignJobsDataColumn,
  ReassignJobsDataRow,
  ReassignJobsExportJobParams,
  assignmentExportJobParams,
  getReassignJobsTableRows,
  reassignJobsExportJobParams,
} from '@warebee/shared/export-converter';
import { generateExportFilename } from 'libs/shared/export-converter/src/lib/export-filename.helper';
import _ from 'lodash';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import useAssignmentDataTableConfig from '../../../assignment/useAssignmentDataTableConfig';
import { containsFilter } from '../../../common/filterHelper';
import { AsyncLoadStatus } from '../../../common/types';
import useFormatter from '../../../common/useFormatter';
import DatasetTable from '../../../components/DatasetTable';
import { analyzeProductMetricSelected } from '../../../metrics/analyzeProduct/analyzeProductMetric.state';
import { brandName } from '../../../store/global.state';
import {
  warehouseSelected,
  warehouseSelectedId,
} from '../../../store/warehouse.state';

import useLoadAnalyzeJobs from '../../hooks/useLoadAnalyzeJobs';
import {
  getProductCategories,
  getProductCategoryDescriptorExport,
} from '../../store/abc/simulation.ABC.helper';
import {
  allocationAnalyzeResult,
  allocationRunSummary,
} from '../../store/allocation/allocation.state';
import {
  analyzeJobDetails,
  analyzeJobDetailsLoadStatus,
  analyzeJobDetailsSelectedRow,
  analyzeJobDetailsTableState,
} from '../../store/analyze.state';
import { simulationCurrent } from '../../store/simulation.state';
import { getAllocateReassignJobTableConfigDefault } from './allocateReassignJobsTable.config';

const sortFieldsMapping: Partial<
  Record<ReassignJobsDataColumn, AnalyzeResultJobsSortOption[]>
> = {
  jobCost: [AnalyzeResultJobsSortOption.TOTAL_COST],
  jobDistance: [AnalyzeResultJobsSortOption.TOTAL_DISTANCE],
  jobDuration: [AnalyzeResultJobsSortOption.TOTAL_DURATION],
  stepId: [
    AnalyzeResultJobsSortOption.REASSIGN_JOB_GROUP_ID,
    AnalyzeResultJobsSortOption.REASSIGN_JOB_CHAIN_NUMBER,
    AnalyzeResultJobsSortOption.REASSIGN_JOB_SEQUENCE_NUMBER,
  ],
  estimatedCostImprovement: [
    AnalyzeResultJobsSortOption.REASSIGN_JOB_ESTIMATED_COST_IMPROVEMENT,
  ],
  //AnalyzeResultJobsSortOption.REASSIGN_JOB_ESTIMATED_AVG_GROUP_COST_IMPROVEMENT,
  //AnalyzeResultJobsSortOption.REASSIGN_JOB_ESTIMATED_TOTAL_GROUP_COST_IMPROVEMENT,
};

const AllocateReassignJobsTable: React.FC = () => {
  const { t } = useTranslation('simulation');
  const sim = useRecoilValue(simulationCurrent);
  const brandTitleState = useRecoilValue(brandName);
  const warehouse = useRecoilValue(warehouseSelected);
  const warehouseIdState = useRecoilValue(warehouseSelectedId);

  const allocationResult = useRecoilValue(allocationAnalyzeResult);
  const allocationRun = useRecoilValue(allocationRunSummary);
  const analyzeJobs = useRecoilValue(analyzeJobDetails);
  const loadStatus = useRecoilValue(analyzeJobDetailsLoadStatus);
  const [tableState, setTableState] = useRecoilState(
    analyzeJobDetailsTableState,
  );
  const setHeatmapMetric = useSetRecoilState(analyzeProductMetricSelected);
  const [selectedRow, setSelectedRow] = useRecoilState(
    analyzeJobDetailsSelectedRow,
  );
  const [loadCallback, cancelLoad] = useLoadAnalyzeJobs();
  const [runExportCSV] = useRunExportJobMutation();

  const formatter = useFormatter();
  const columnsConfigAssignment = useAssignmentDataTableConfig();
  const { searchValues, sortValues } = tableState;

  const productCategories = getProductCategories(t);

  useEffect(() => {
    setHeatmapMetric('abc');
  }, []);

  function callDataLoad(
    searchValues: Partial<Record<ReassignJobsDataColumn, string>>,
    sortValues: Partial<Record<ReassignJobsDataColumn, SortDirection>>,
    page = { isAppend: false, skip: 0 },
  ) {
    if (!allocationResult) return;

    const filter: AnalyzeResultReassignJobsFilter = {
      consignee: containsFilter(searchValues?.consignee),
      destAction: containsFilter(searchValues?.destAction),
      destLocationId: containsFilter(searchValues?.destLocationId),
      groupId: searchValues?.groupId
        ? {
            type: StringSearchFilterType.STARTS_WITH,
            value: searchValues?.groupId,
          }
        : null,
      sku: containsFilter(searchValues?.sku),
      srcLocationId: containsFilter(searchValues?.srcLocationId),
    };

    const sortBy: AnalyzeResultJobsSortBy[] = _(sortValues)
      .map((direction, key) =>
        _.map(sortFieldsMapping[key], field => ({
          field,
          direction,
        })),
      )
      .flatten()
      .compact()
      .value();

    cancelLoad();
    loadCallback({
      analyzeId: allocationResult.id,
      filter: {
        process: [AnalyzeResultProcessType.REASSIGN],
        reassignJob: filter,
      },
      sortBy: sortBy,
      ...page,
    });
  }

  useEffect(() => {
    callDataLoad(searchValues, sortValues);
    return () => {
      cancelLoad();
    };
  }, [allocationResult, searchValues, sortValues]);

  const columnsConfig = getAllocateReassignJobTableConfigDefault(t, formatter);

  function onLoadNext() {
    callDataLoad(searchValues, sortValues, {
      isAppend: true,
      skip: itemsCount,
    });
  }

  function onRowSelect(row: ReassignJobsDataRow, index: number) {
    setSelectedRow(row);
  }
  const converterConfig: ReassignJobsConverterConfig = {
    columnsConfig,
    dictionaries: {
      categories: getProductCategoryDescriptorExport(productCategories),
    },
  };

  async function startExportCSV() {
    const filename = generateExportFilename({
      exportTitle: t`allocation-tasks`,
      brandTitleState,
      warehouse,
      warehouseIdState,
      sim,
    });

    const variables: ReassignJobsExportJobParams = {
      ...reassignJobsExportJobParams,
      query: LoadAnalyzeJobsDetailsDocument.loc.source.body,
      config: converterConfig,
      variables: {
        analyzeId: allocationResult.id,
        filter: {
          process: [AnalyzeResultProcessType.REASSIGN],
        },
        page: null,
      },
      filename: `${filename}.csv`,
    };
    const { data, errors } = await runExportCSV({
      variables,
    });

    return {
      errors: errors,
      job: data.createExportJob,
    };
  }

  const isLoading =
    loadStatus === AsyncLoadStatus.Loading ||
    loadStatus === AsyncLoadStatus.None;
  const itemsCount = analyzeJobs?.content?.length || 0;
  const totalCount = analyzeJobs?.totalCount ?? 0;

  const flattenData = getReassignJobsTableRows(
    analyzeJobs?.content,
    converterConfig,
  );

  const converterAssignmentConfig: AssignmentConverterConfig = {
    columnsConfig: columnsConfigAssignment,
  };

  async function startExportAssignmentCSV() {
    const variables: AssignmentExportJobParams = {
      ...assignmentExportJobParams,
      query: LoadAssignmentDataDocument.loc.source.body,
      config: converterAssignmentConfig,
      variables: {
        assignmentId: allocationRun?.resultAssignment?.id,
        page: null,
      },
    };
    const { data, errors } = await runExportCSV({
      variables,
    });
    //
    return {
      errors: [],
      job: data.createExportJob,
    };
  }

  return (
    <DatasetTable
      id={'reassign-table'}
      hideScreenTitle
      isSticky
      isActionable
      columnsConfig={columnsConfig}
      keyFields={['consignee', 'sku', 'srcLocationId', 'destLocationId']}
      data={flattenData}
      onLoadNext={onLoadNext}
      onSearch={searchValues => setTableState({ ...tableState, searchValues })}
      sortBy={sortValues}
      onSort={sortValues => setTableState({ ...tableState, sortValues })}
      totalCount={totalCount}
      searchValues={searchValues}
      isLoading={isLoading}
      onRowSelect={onRowSelect}
      onStartExportClick={startExportCSV}
      onStartExtraExportClick={startExportAssignmentCSV}
      extraExportTitle={t`Assignment`}
      hasCounter
    />
  );
};

export default AllocateReassignJobsTable;
