import {
  BatchJobStatus,
  useExportSimulationAssignmentDetailsMutation,
} from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import { generateExportFilename } from 'libs/shared/export-converter/src/lib/export-filename.helper';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import useLoadSimulationItemSet from '../../assignmentPolicy/useLoadSimulationItemSet';
import { COMPLIANCE_PROGRESS_REFRESH_INTERVAL } from '../../common/constants';
import { formatInteger, formatPercent } from '../../common/formatHelper';
import { AsyncLoadStatus } from '../../common/types';
import InboxZero from '../../components/InboxZero';
import ButtonSwitchMulti from '../../components/actions/ButtonSwitchMulti';
import DropdownSelector from '../../components/actions/DropdownSelector';
import ExportButton from '../../components/actions/ExportButton';
import { Spacer } from '../../components/layout/Spacer';
import { Stat } from '../../components/stats/Stat';
import { StatGroup } from '../../components/stats/StatGroup';
import { viewerShowLocations } from '../../layout/viewer/store/viewer.state';
import { getABCProductHeatmapBuckets } from '../../metrics/analyzeProduct/analyzeProductMetric.helper';
import { analyzeProductMetricSelected } from '../../metrics/analyzeProduct/analyzeProductMetric.state';
import { brandName } from '../../store/global.state';
import {
  warehouseSelected,
  warehouseSelectedId,
} from '../../store/warehouse.state';
import { getProductCategories } from '../store/abc/simulation.ABC.helper';
import { analyzeABC, simulationABC } from '../store/abc/simulation.ABC.state';
import {
  simulationLayoutHeatmapFilters,
  simulationLayoutHeatmapFiltersHasAny,
} from '../store/simulation.layout.state';
import {
  simulationCurrent,
  simulationEffectiveItemSet,
  simulationEffectiveItemSetLoadStatus,
} from '../store/simulation.state';
import SimulationABCChart, { ABCSerie } from './SimulationABCChart';

export type SimulationAnalyzePanelProductProps = {
  analyzeId: string;
};

// We get multiple values for each product category to draw smooth areas on chart
// subgroupCount count of points in each category
const subgroupCount = 5;
// subgroupLengths - delta  of cumulative %  for each group
const subgroupLengths = [0.1, 0.06, 0.03, 0.01];

const SimulationAnalyzePanelItemsABC: React.FC<
  SimulationAnalyzePanelProductProps
> = props => {
  const { t } = useTranslation('simulation');
  const sim = useRecoilValue(simulationCurrent);
  const brandTitleState = useRecoilValue(brandName);
  const warehouse = useRecoilValue(warehouseSelected);
  const warehouseIdState = useRecoilValue(warehouseSelectedId);

  const [showAdvanced, setShowAdvanced] = useState<boolean>(false);
  const [showProductCount, setShowProductCount] = useState<boolean>(false);

  const [heatmapFilter, setHeatmapFilter] = useRecoilState(
    simulationLayoutHeatmapFilters,
  );
  const heatmapType = useRecoilValue(analyzeProductMetricSelected);
  const hasAnyFiltered = useRecoilValue(simulationLayoutHeatmapFiltersHasAny);
  const setShowLocation = useSetRecoilState(viewerShowLocations);
  const effectiveItemSet = useRecoilValue(simulationEffectiveItemSet);
  const effectiveItemSetLoadStatus = useRecoilValue(
    simulationEffectiveItemSetLoadStatus,
  );
  const [runExportCSV] = useExportSimulationAssignmentDetailsMutation();
  const [loadItemSetStatus, cancelLoadItemSetStatus] =
    useLoadSimulationItemSet();
  const abc = useRecoilValue(simulationABC);
  const hitABC = useRecoilValue(analyzeABC(props.analyzeId));

  console.log('### hitABC', hitABC);
  const buckets = getABCProductHeatmapBuckets();
  // Check and Re-calculate SimulationEffectiveItemSet
  useEffect(() => {
    if (effectiveItemSetLoadStatus === AsyncLoadStatus.Error) return;
    let timeoutId;

    if (_.isNil(effectiveItemSet)) {
      loadItemSetStatus({ simulationId: sim.id });
    }
    if (
      effectiveItemSet?.status === BatchJobStatus.CREATED ||
      effectiveItemSet?.status === BatchJobStatus.CALCULATING
    ) {
      timeoutId = setTimeout(() => {
        loadItemSetStatus({ simulationId: sim.id });
      }, COMPLIANCE_PROGRESS_REFRESH_INTERVAL);
    }
    return () => {
      clearTimeout(timeoutId);
      cancelLoadItemSetStatus();
    };
  }, [effectiveItemSet]);

  const debouncedClick = useCallback(
    _.debounce(
      (
        id: string,
        value: boolean,
        evt: React.MouseEvent<HTMLDivElement, MouseEvent>,
      ) => {
        if (evt.detail === 2) {
          forceSelectSingleBucket(id);
        } else {
          updateBucketFilter(id, value);
        }
      },
      400,
    ),
    [heatmapFilter, buckets],
  );

  const updateBucketFilter = (id: string, value: boolean) => {
    setHeatmapFilter({
      ...heatmapFilter,
      hiddenBuckets: {
        ...heatmapFilter.hiddenBuckets,
        [id]: !value,
      },
    });
  };

  const forceSelectSingleBucket = (id: string) => {
    const hiddenBuckets = _.reduce(
      buckets,
      (acc, b) => ({
        ...acc,
        [b.id]: b.id !== id,
      }),
      {},
    );

    setHeatmapFilter({
      ...heatmapFilter,
      hiddenBuckets,
    });
  };

  if (_.isNil(abc)) {
    return null;
  }

  const categoryDescriptors = getProductCategories(t);

  const orderSetCategoryDataRaw = abc.orderSet?.itemsByCategory.categories;
  const assignedCategoryDataRaw =
    abc.effectiveItemSet?.itemsByCategory.categories;
  const analyzedCategoryDataRaw = hitABC.categories;

  const orderLineCount = abc.orderSet?.itemsByCategory.totalOrderLineCount;

  const orderSetDataGrouped = _.chunk(orderSetCategoryDataRaw, subgroupCount);
  const assignmentDataGrouped = _.chunk(assignedCategoryDataRaw, subgroupCount);
  const analyzedDataGrouped = _.chunk(analyzedCategoryDataRaw, subgroupCount);

  const categorizedData = orderSetDataGrouped.map((orderSetChunks, index) => {
    // const simChunks = assignmentDataGrouped?.[index];
    const simChunks = analyzedDataGrouped[index];
    const minPercentRank = _.minBy(
      orderSetChunks,
      c => c.minPercentRank,
    )?.minPercentRank;

    const maxPercentRank = _.maxBy(
      orderSetChunks,
      c => c.maxPercentRank,
    )?.maxPercentRank;

    return {
      skip: _.minBy(orderSetChunks, c => c.skip)?.skip,
      count: _.sumBy(orderSetChunks, ch => ch.count),
      pickedCount: _.sumBy(simChunks, ch => ch.count),
      orderLineCount: _.sumBy(orderSetChunks, ch => ch.totalOrderLineCount),
      maxPercentRank,
      minPercentRank,
    };
  });

  const categoriesWithData = _(categoryDescriptors)
    .values()
    .map((value, index) => ({
      ...value,
      productShare: categorizedData[index].maxPercentRank,
    }))
    .keyBy(c => c.key)
    .value();

  async function startExportCSV() {
    const filename = generateExportFilename({
      exportTitle: t`analyse-abc`,
      brandTitleState,
      warehouse,
      warehouseIdState,
      sim,
    });

    const { data, errors } = await runExportCSV({
      variables: {
        simulationId: sim.id,
        rankCategories: {
          categories: _.map(categoryDescriptors, c => ({
            title: c.tag,
            categoryWidth: _.isNil(c.toCmlPercentile)
              ? null
              : (c.toCmlPercentile - c.fromCmlPercentile) * 100,
          })),
        },
        csvOptions: {},
      },
    });
    //
    return {
      errors: [],
      job: data?.exportSimulationAssignmentDetails,
      filename: `${filename}.csv`,
    };
  }

  const chartData: ABCSerie[] = _.initial(_.values(categoryDescriptors)).map(
    (descriptor, groupIndex) => {
      const dataGroup = orderSetDataGrouped[groupIndex];

      const points = _.flatten(
        dataGroup.map((data, chunkIndex) => {
          const startPoint = {
            x: data.minPercentRank,
            y: descriptor.fromCmlPercentile,
          };

          const endPoint = {
            x: data.maxPercentRank,
            y:
              descriptor.fromCmlPercentile +
              (chunkIndex + 1) * subgroupLengths[groupIndex],
          };

          return chunkIndex === 0 ? [startPoint, endPoint] : [endPoint];
        }),
      );

      return {
        id: descriptor.key,
        category: descriptor,
        data: points,
      };
    },
  );

  const fooClassnames = `bg-categoryABC-S bg-categoryABC-A bg-categoryABC-B bg-categoryABC-C bg-categoryABC-D
  any-hover:hover:bg-categoryABC-S any-hover:hover:bg-categoryABC-A any-hover:hover:bg-categoryABC-B any-hover:hover:bg-categoryABC-C any-hover:hover:bg-categoryABC-D `;

  return (
    <StatGroup
      title={t`Item Analytics (ABC)`}
      subTitle={t`Stats`}
      titleAction={
        <DropdownSelector
          classNameLabel="text-sm"
          onClick={e => {
            e.stopPropagation();
          }}
          DropAlignRight
          buttonTransparent
          vertical
          panelMode
          value={'...'}
          values={[showAdvanced ? t`Hide Options...` : t`Show Options`]} // values={options}
          onChange={async (option, e) => {
            setShowAdvanced(!showAdvanced);
          }}
        />
      }
      helpNavTo={'simulation/analyse/simulation-analyse-abc'}
    >
      {showAdvanced && (
        <div className="flex">
          <ButtonSwitchMulti
            buttonType="minimal"
            autoSize
            className="mx-2"
            selectedIndex={showProductCount ? 1 : 0}
            onClick={index => setShowProductCount(index === 1)}
            options={[{ label: t`Stock` }, { label: t`Orders` }]}
          />
          <Spacer flexspace />
          <ExportButton onStartExportClick={startExportCSV} />
        </div>
      )}
      <div className={`relative flex w-full flex-1 flex-col items-center`}>
        <div
          className={classNames(
            'p-1 md:p-2 lg:p-3 xl:p-4',
            'ltr:px-0.5 rtl:px-0.5',
            'ltr:xl:px-1 rtl:xl:px-1',
            'bg-app-panel-dark/60 m-0.5',
            'h-60 xl:h-80',
          )}
          style={{ width: '100%', minWidth: '100%' }}
        >
          <label
            aria-label="Title"
            className="max-w-ch-30 text-xxs flex-1 truncate pl-4 uppercase xl:text-sm"
          >
            {t`ABC Items analysis (Cumulative)`}
          </label>
          <div className="h-full px-0.5 pb-4 pt-4 lg:px-2">
            <SimulationABCChart
              current={chartData}
              ticks={_(categorizedData)
                .map(c => c.maxPercentRank)
                .compact()
                .value()}
              categories={categoriesWithData}
            />
          </div>
        </div>
      </div>
      {orderLineCount === 0 ? (
        <InboxZero message={t`No data for Items Analytics (ABC)`} />
      ) : (
        <div className="w-full space-y-1">
          {_.values(categoryDescriptors).map((meta, index) => {
            const data = categorizedData[index];
            // last item in descriptors is for disabled location;
            const productCount = data?.count;
            const pickedProductCount = data?.pickedCount;

            const cumulativeShareDiff = formatPercent(
              data?.maxPercentRank - data?.minPercentRank,
            );
            const orderLinesShare = formatPercent(
              data.orderLineCount / orderLineCount,
            );
            const orderLines = formatInteger(data?.orderLineCount);

            const orderLineStat = t(
              `{{cumulativeShareDiff}} of Items, {{orderLinesShare}} of Sales ({{orderLines}} OL)`,
              {
                cumulativeShareDiff,
                orderLinesShare,
                orderLines,
              },
            );
            const isDeselected =
              heatmapFilter?.hiddenBuckets[meta.key] === true;

            return productCount > 0 || pickedProductCount > 0 ? (
              <Stat
                key={`product-category-stat-${meta.key}`}
                legendColor={[meta.color, meta.textColor]}
                inPanelMode
                hasHelper
                isActionable={
                  heatmapType === 'abc' || heatmapType === 'abc_visited'
                }
                isPreview
                title={`[${meta.tag}] ${meta.title}`}
                value={
                  showProductCount
                    ? formatInteger(productCount)
                    : formatInteger(pickedProductCount)
                }
                isSectionable={
                  heatmapType === 'abc' || heatmapType === 'abc_visited'
                }
                // valueTotal={formatInteger(productCount)}
                isFullWidth
                isSelected={
                  (!isDeselected && heatmapType === 'abc') ||
                  heatmapType === 'abc_visited'
                }
                onClick={
                  evt => debouncedClick(meta.key, isDeselected, evt)
                  // setHeatmapFilter({
                  //   ...heatmapFilter,
                  //   hiddenBuckets: {
                  //     ...(heatmapFilter.hiddenBuckets ?? {}),
                  //     [meta.key]: !isDeselected,
                  //   },
                  // })
                }
              >
                {data.count > 0 && (
                  <div className="opacity-75">
                    <div>{orderLineStat}</div>
                  </div>
                )}
              </Stat>
            ) : null;
          })}
        </div>
      )}
    </StatGroup>
  );
};

export default SimulationAnalyzePanelItemsABC;
