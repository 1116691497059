import {
  Action,
  useUpdateWarehouseMutation,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import { errorAppender } from '../../store/error.state';
import {
  warehouseSelected,
  warehouseUpdateLoadStatus,
} from '../../store/warehouse.state';
import { WarehouseExtraSettings } from '../../store/warehouse.types';

export type UpdateWarehouseExtraSettingsParams = {
  patch: Partial<WarehouseExtraSettings>;
};

function useUpdateWarehouseExtraSettings() {
  const { t } = useTranslation('errors');
  const [updateWarehouse] = useUpdateWarehouseMutation();
  const errorTitleSave = t`Cannot update warehouse settings`;

  const initUpdate = useRecoilCallback(({ snapshot, set }) => async () => {
    set(warehouseUpdateLoadStatus, AsyncLoadStatus.Loading);
  });

  const update = useRecoilCallback(
    ({ snapshot, set, reset }) =>
      async (params: UpdateWarehouseExtraSettingsParams) => {
        const warehouse = await snapshot.getPromise(warehouseSelected);

        if (!warehouse) {
          handleError('No warehouse selected');
          return;
        }

        if (!warehouse.permissions) {
          handleError('Warehouse permissions not found');
          return;
        }

        if (
          !_.some(
            warehouse?.permissions?.allowed,
            action => action === Action.UPDATE,
          )
        )
          return;
        function handleError(details?: string, stack?: string) {
          console.error(errorTitleSave, details, stack);
          set(errorAppender, {
            id: nanoid(),
            title: errorTitleSave,
            details: details,
            callStack: stack,
          });
          set(warehouseUpdateLoadStatus, AsyncLoadStatus.Error);
        }

        const extraSettings: WarehouseExtraSettings = {
          ...(warehouse.extraSettings ?? {}),
          ...params.patch,
        };

        try {
          const { data, errors: updateErrors } = await updateWarehouse({
            variables: {
              input: {
                warehouseId: warehouse.id,
                extraSettings,
              },
            },
          });

          if (!_.isEmpty(updateErrors)) {
            const details = _(updateErrors)
              .map(j => j.message)
              .join(';');
            handleError(details);
            return;
          }
          set(warehouseSelected, data.updateWarehouse);
          set(warehouseUpdateLoadStatus, AsyncLoadStatus.Ok);
        } catch (ex) {
          handleError(ex?.message ?? ex);
        }
      },
  );

  async function call(params: UpdateWarehouseExtraSettingsParams) {
    await initUpdate();
    await update(params);
  }
  return [call];
}

export default useUpdateWarehouseExtraSettings;
