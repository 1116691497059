import classNames from 'classnames';
import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Virtuoso } from 'react-virtuoso';
import { useRecoilState, useRecoilValue } from 'recoil';
import { toHumanReadableDateDay } from '../../common/dateTimeHelper';
import ButtonSwitchMulti from '../../components/actions/ButtonSwitchMulti';
import { PanelBody } from '../../components/designer/panels/PanelBody';
import InboxZero from '../../components/InboxZero';
import TitleSection from '../../components/layout/TitleSection';
import { ActionBar } from '../../components/nav/ActionBar';
import FeedActivityEventDetails from '../components/FeedActivityEventDetails';
import { actualityAnalyzedEventsSummary } from '../store/actuality.state';
import { delayDuration } from '../store/feed.helper'; // Assuming this exists for issue filtering
import { feedDisplayOptionAtom, feedLatestEvents } from '../store/feed.state';
import { feedDisplayOptions } from '../store/feed.types';
import FeedEventsStats from './FeedEventsStats';

const FeedEventsPanel: React.FC = () => {
  const { t } = useTranslation('feed');
  const eventsAll = useRecoilValue(feedLatestEvents);
  const analyzeEventsSummary = useRecoilValue(actualityAnalyzedEventsSummary);

  const [feedDisplayOption, setFeedDisplayOption] = useRecoilState(
    feedDisplayOptionAtom,
  );

  const [groupingOption, setGroupingOption] = useState('byDay');

  // Determine grouping key based on option
  const getGroupingKey = event => {
    const eventDateEnd = new Date(event.eventEndTime);
    const eventDateStart = new Date(event.eventStartTime ?? null);

    return groupingOption === 'byHour'
      ? `${toHumanReadableDateDay(eventDateEnd)} (${eventDateStart.getHours()}:00 – ${eventDateEnd.getHours()}:00)`
      : toHumanReadableDateDay(eventDateEnd);
  };

  // Filter events based on legendOption
  const filteredEvents = useMemo(() => {
    if (feedDisplayOption === 'issues') {
      return _.filter(eventsAll, event => {
        const endTime = new Date(event.eventEndTime).getTime();
        const startTime = new Date(event.eventStartTime).getTime();
        return Math.abs(endTime - startTime) > delayDuration;
      });
    }
    return eventsAll;
  }, [eventsAll, feedDisplayOption]);

  // Group filtered events
  const groupedEvents = useMemo(() => {
    return _(filteredEvents)
      .groupBy(event => getGroupingKey(event))
      .map((events, key) => ({
        groupKey: key,
        events: _.orderBy(
          events,
          ['eventEndTime', 'eventStartTime', 'agentId'],
          ['desc', 'desc', 'asc'],
        ),
      }))
      .value();
  }, [filteredEvents, groupingOption]);

  // Prepare events for Virtuoso
  const eventsForVirtuoso = useMemo(() => {
    return groupedEvents.reduce((acc, group) => {
      acc.push({
        type: 'header',
        content: group.groupKey,
        events: group.events,
      });
      return acc;
    }, []);
  }, [groupedEvents]);

  // Calculate if there are multiple unique days or hours for the collapsible condition
  const hasMultipleTimeGroups = useMemo(
    () => groupedEvents.length > 1,
    [groupedEvents],
  );

  return (
    <PanelBody data-component="FeedEventsPanel" className="h-full">
      {/* // <div data-component="FeedEventsPanel" className={classNames('h-full')}> */}
      {/* <ScreenTitle
        isSticky
        fixedHeight
        title={t`Activity`}
        subtitle={t`Warehouse`}
        helpNavTo={'live/feed'}
      /> */}
      {/* <ButtonSwitchMulti
          className="mx-2 my-1 text-xs"
          autoSize
          selectedIndex={groupingOptions.findIndex(
            option => option.value === groupingOption,
          )}
          onClick={index => setGroupingOption(groupingOptions[index].value)}
          options={groupingOptions}
          buttonType="header"
        /> */}
      <ActionBar
        sticky
        stickyTop
        className={classNames('px-1 py-2', 'bg-sidebar-header/40', 'my-px')}
      >
        <ButtonSwitchMulti
          autoSize
          className="mx-2 my-1 w-full text-xs"
          selectedIndex={feedDisplayOptions.findIndex(
            option => option.value === feedDisplayOption,
          )}
          onClick={index =>
            setFeedDisplayOption(feedDisplayOptions[index].value)
          }
          options={feedDisplayOptions}
          buttonType="header"
        />
      </ActionBar>

      {feedDisplayOption === 'stats' ? (
        <FeedEventsStats />
      ) : _.isEmpty(eventsForVirtuoso) ? (
        <InboxZero selfCenter message={t`No events`} />
      ) : (
        <Virtuoso
          className={classNames('h-full overflow-x-hidden')}
          data={eventsForVirtuoso}
          itemContent={(index, item) => {
            if (item.type === 'header') {
              return (
                <TitleSection
                  id={`header-event-${item}-${index}`}
                  key={`header-event-${item}-${index}`}
                  title={item.content}
                  isSticky
                  className="!z-60 !top-0"
                  fullWidth
                  inPanelView
                  collapsible={hasMultipleTimeGroups}
                >
                  {item.events.map((evt, eventIndex) => (
                    <FeedActivityEventDetails
                      key={`feed-event-details-${evt.eventId}`}
                      event={evt}
                      index={eventIndex}
                    />
                  ))}
                </TitleSection>
              );
            }
          }}
          components={{
            List: React.forwardRef((props, ref) => (
              <div
                data-component="event-comps"
                ref={ref}
                {...props}
                className=""
              />
            )),
          }}
        />
      )}
    </PanelBody>
  );
};

export default FeedEventsPanel;
