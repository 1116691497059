import { atom, selector } from 'recoil';
import { sidebarStateByType } from '../store/sidebar.state';
import { HelpIndex } from './help.default';

const getKey = (postfix: string) => `warebee-help-${postfix}`;

export const helpSelectedIdAtom = atom<HelpIndex | null>({
  key: getKey('current-id-atom'),
  default: 'index',
});

export const helpSelectedId = selector<HelpIndex | null>({
  key: getKey('current-id'),
  get: ({ get }) => get(helpSelectedIdAtom),
  set: ({ get, set }, value) => {
    const helpSidebar = get(sidebarStateByType('sidebar-global-help'));
    const current = get(helpSelectedIdAtom);

    // Handle both setting and clearing the help state
    if (!helpSidebar.isPinned) {
      set(sidebarStateByType('sidebar-global-help'), {
        ...helpSidebar,
        isCollapsed: current === value || !value,
      });
    }

    set(helpSelectedIdAtom, value);
  },
});
