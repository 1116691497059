import { cn } from '../../../common/utils';
import KeyValueItem from '../../../components/KeyValueItem';

interface AppDataObjectIdItemProps {
  label: string;
  value?: string;
  className?: string;
  canCopy?: boolean | 'value-only';
}

export const AppDataObjectIdItem: React.FC<AppDataObjectIdItemProps> = ({
  label,
  value,
  className,
  canCopy = 'value-only',
}) => {
  if (!value) return null;

  return (
    <KeyValueItem
      keyName={label}
      keyValue={value}
      isDisabled={true}
      isCopyable={canCopy === true}
      isCopyableValueOnly={canCopy === 'value-only'}
      style={{ inline: false, noStyle: true, textSize: 'xs' }}
      classNameKeyName="text-menu-text/75 ltr:pl-3 rtl:pr-3 pb-0 mb-0" //min-w-[24ch] justify-end
      classNameKeyValue="text-base text-menu-text pt-1"
      className={cn('py-0.5', 'flex-col', className)}
    />
  );
};
