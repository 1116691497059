import 'iframe-resizer/js/iframeResizer.contentWindow';
import Konva from 'konva';
import { editor } from 'monaco-editor';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';
import 'reflect-metadata';
import App from './app/App';
import './app/components/editors/code-editor-setup';
// import './app/assets/theme/css/App.css';
import {
  ANALYTICS_STATUS,
  APP_BACKEND_URL,
  APP_BILLING_URL,
  APP_VER,
  BUILD_ENV,
  BUILD_HASH,
  BUILD_ID,
  BUILD_TIMESTAMP,
  GTM_ID,
} from './app/common/environmentVariables';
import {
  codeEditorWarebeeTheme,
  codeEditorWarebeeThemeId,
} from './app/components/editors/CodeEditorWarebeeTheme';

Konva.showWarnings = false;

editor.defineTheme(codeEditorWarebeeThemeId, codeEditorWarebeeTheme);
//editor.setTheme(codeEditorWarebeeThemeId);

const domainName = window.location.hostname;
const environment = process.env.NODE_ENV;
console.group(
  '%cWareBee INFO',
  'background-color: #ffcf00 ; color: #000000 ; font-weight: bold ; padding: 4px ;',
);
console.info('Domain:', domainName);
console.info('ENV:', BUILD_ENV);
console.info('Version:', APP_VER);
console.info('Build Date:', BUILD_TIMESTAMP, ' ', BUILD_HASH);
console.info('Build:', BUILD_ID);

console.info('API-A:', APP_BACKEND_URL);
console.info('API-B:', APP_BILLING_URL);

console.groupEnd();

function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}
if (!inIframe()) {
  if (ANALYTICS_STATUS === 'false') {
    // Disabled analytics
    console.warn('Analytics:: disabled');
  } else {
    // import the GTM Module so you can access it
    if (GTM_ID) {
      const tagManagerArgs = {
        // gtmId: environment === 'development' ? GTM_ID_DEV : GTM_ID,
        gtmId: GTM_ID,
        // auth: env.GTM_AUTH,
        // preview: env.GTM_PREVIEW,
      };

      TagManager.initialize(tagManagerArgs);
    } else {
      console.warn('Analytics: GTM disabled');
    }
  }
}

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// https://github.com/GoogleChrome/web-vitals
// reportWebVitals(console.log);
