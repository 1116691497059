import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { JsonObject } from 'type-fest';
import { AsyncLoadStatus } from '../common/types';
import DatasetTable, { ColumnConfig } from '../components/DatasetTable';
import {
  customQueryData,
  customQueryDataLoadStatus,
} from './store/customQuery.state';

export type CustomQueryDataTableProps = {
  queryDescriptorId: string;
  children?: React.ReactNode;
};

const CustomQueryDataTable: React.FC<CustomQueryDataTableProps> = props => {
  const { t } = useTranslation('dataset');
  const data = useRecoilValue(customQueryData(props.queryDescriptorId));
  const status = useRecoilValue(
    customQueryDataLoadStatus(props.queryDescriptorId),
  );

  const columnsConfig: ColumnConfig<JsonObject>[] = _.map(
    data.columns,
    column => ({
      field: _.camelCase(column.name),
      title: _.startCase(_.replace(column.name, '_', ' ')),
    }),
  ) as any;

  const isLoading = status === AsyncLoadStatus.Loading;
  const itemsCount = data?.content?.length || 0;
  const totalCount = data?.totalCount ?? 0;

  return (
    <DatasetTable
      id={`custom-query-results-${props.queryDescriptorId}`}
      data={data?.content}
      columnsConfig={columnsConfig}
      keyFields={['eventId']}
      hasCounter
      hideScreenTitle={true}
      subtitle={t`Datasets`}
      title={t`###`}
      isSticky
      onLoadNext={_.noop}
      onSearch={_.noop}
      totalCount={totalCount}
      searchValues={{}}
      isLoading={isLoading}
    />
  );
};

export default CustomQueryDataTable;
