import {
  ImportJobImportType,
  useRemoveAssignmentMutation,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import AssignmentDashboardCard from '../../assignment/AssignmentDashboardCard';
import { AsyncLoadStatus } from '../../common/types';
import * as Icon from '../../components/icons';
import { warehouseSelectedId } from '../../store/warehouse.state';
import DashboardItemsGroupContainer from '../DashboardItemsGroupContainer';
import DashboardItemContainer, {
  DashboardItemContainerProps,
} from '../containers/DashboardItemContainer';
import DashboardListItemContainer from '../containers/DashboardListItemContainer';
import useLoadAssignmentDashboard from '../hooks/useLoadAssignmentDashboard';
import DashboardAssignmentInfoCard from '../infoCards/DashboardAssignmentInfoCard';
import {
  dashboardAssignmentData,
  dashboardAssignmentLoadState,
  dashboardAssignmentState,
} from '../store/dashboard.state';
import {
  DashboardGroupState,
  DashboardItemStatusType,
  DashboardOrder,
  DashboardViewMode,
} from '../store/dashboard.types';

const DataDashboardAssignments: React.FC = () => {
  const { t } = useTranslation('app');
  const warehouseId = useRecoilValue(warehouseSelectedId);
  const [removeAssignment] = useRemoveAssignmentMutation();
  const dashData = useRecoilValue(dashboardAssignmentData);
  const [dashState, setDashState] = useRecoilState(dashboardAssignmentState);
  const loadingState = useRecoilValue(dashboardAssignmentLoadState);
  const loadDashboardData = useLoadAssignmentDashboard();

  useEffect(() => {
    loadDashboardData({ sort: dashState.order });
  }, [warehouseId]);

  function changeViewMode(viewMode: DashboardViewMode) {
    setDashState({
      ...dashState,
      viewMode,
    });
  }

  function changeSort(sortMode: DashboardOrder) {
    setDashState({
      ...dashState,
      order: sortMode,
    });
    loadDashboardData({ sort: sortMode, limit: dashState.loadedCount });
  }

  function loadMore() {
    loadDashboardData({
      sort: dashState.order,
      skip: dashState.loadedCount,
      isAppend: true,
    });
  }

  function applyFilter(
    filterProps: Pick<DashboardGroupState, 'selectedUserTags' | 'titleFilter'>,
  ) {
    setDashState({
      ...dashState,
      ...filterProps,
    });
    loadDashboardData({ sort: dashState.order, limit: null });
  }

  const getAssignmentsCards = () => {
    if (_.isEmpty(dashData)) return null;
    return _.sortBy(dashData).map(item => {
      const { id, title, description } = item;
      const path = `/wh/i/${warehouseId}/assignments/${id}`;

      const status = {
        type: DashboardItemStatusType.complete,
        title: t`Ready`,
      };

      const statusInUsed = {
        type: DashboardItemStatusType.inuse,
        title: t`In Use`,
      };

      const hasSimulations = (item.simulationsWith?.totalCount ?? 0) === 0;

      const containerProps: DashboardItemContainerProps = {
        id,
        title,
        description,
        userTags: item.userTags,
        createdDate: new Date(item.createdAt),
        updatedDate: new Date(item.updatedAt),
        path,
        status: hasSimulations ? status : statusInUsed,
        canDelete: hasSimulations,
        onDeleteClick: async () => {
          await removeAssignment({
            variables: { id: id },
          });
          loadDashboardData({ sort: dashState.order });
        },
      };

      return dashState.viewMode === DashboardViewMode.card ? (
        <DashboardItemContainer
          icon={Icon.DataAssignment}
          key={`card-assignment-${id}`}
          {...containerProps}
        >
          <AssignmentDashboardCard summary={item.summary} isActionable />
        </DashboardItemContainer>
      ) : (
        <DashboardListItemContainer
          key={`item-assignment-${id}`}
          {...containerProps}
        />
      );
    });
  };

  return (
    <DashboardItemsGroupContainer
      id={'dashboard-data-assignment'}
      title={t`Assignments`}
      state={dashState}
      isLoading={loadingState === AsyncLoadStatus.Loading}
      // collapsible
      onSortChanged={changeSort}
      onViewModeChanged={changeViewMode}
      onLoadMoreClick={loadMore}
      datasetJobType={ImportJobImportType.ASSIGNMENT}
      datasetPath="assignments"
      onFilterChange={applyFilter}
    >
      <DashboardAssignmentInfoCard
        showButtonInline={!_.isEmpty(dashData)}
        listMode={dashState.viewMode === DashboardViewMode.list}
        isLoading={loadingState === AsyncLoadStatus.Loading}
      />

      {getAssignmentsCards()}
    </DashboardItemsGroupContainer>
  );
};

export default DataDashboardAssignments;
