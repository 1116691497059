import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HelperMessage } from '../components/HelperMessage';
import { MailTo } from '../components/actions/MailTo';
import * as Icon from '../components/icons';

export type RegisterHelperPanelProps = {
  children?: React.ReactNode;
  className?: string;
  title?: string;
};

export const RegisterHelperPanel = ({
  children,
  className,
  title,
}: RegisterHelperPanelProps) => {
  const [id] = useState<string>(_.uniqueId());
  const { t } = useTranslation('app-public');

  return (
    <section
      data-component="Intro"
      id={id}
      className={classNames(
        'p-10 xl:p-20 flex flex-col items-start justify-center',
        '',
        className,
      )}
    >
      {title && (
        <div className={classNames('mb-4 text-xl xl:text-4xl')}>{title}</div>
      )}
      <div
        className={classNames('text-2xl sm:text-4xl md:text-5xl xl:text-6xl')}
      >
        {children}
      </div>
    </section>
  );
};

export type IntroProps = {
  children?: React.ReactNode;
  className?: string;
};

export const Intro = ({ children, className }: IntroProps) => {
  const [id] = useState<string>(_.uniqueId());
  const { t } = useTranslation('app-public');

  return (
    <section
      data-component="Intro"
      id={id}
      className={classNames(
        'p-10 xl:p-20 flex flex-col items-start justify-center',
        className,
      )}
    >
      <div
        className={classNames('mb-4 text-xl xl:text-4xl')}
      >{t`What is WareBee?`}</div>
      <div
        className={classNames('text-2xl sm:text-4xl md:text-5xl xl:text-6xl')}
      >
        {t`WareBee™ is a digital`}{' '}
        <span className="opacity-70">{t`(twin)`}</span>{' '}
        {t`model of the warehouse helping you to make data-driven decisions that
        save costs.`}
      </div>
      {children}
    </section>
  );
};

export const IntroHelp = ({ children, className }: IntroProps) => {
  const [id] = useState<string>(_.uniqueId());
  const { t } = useTranslation('app-public');

  return (
    <section
      data-component="IntroHelp"
      id={id}
      className={classNames(
        'p-10 xl:p-20',
        'flex flex-col items-start justify-center',
        'w-full',
        className,
      )}
    >
      <HelperMessage
        hasBackground={false}
        isSuggestion
        hasPadding
        isHero
        className={classNames('mt-4 pt-4 flex-col')}
        classNameMessage={classNames('!text-menu-active-text')}
      >
        
        <MailTo
          className="flex"
          email="support@warebee.com"
          subject="[WAREBEE] Forgot Password support"
          body={`Hello, I need support with the password reset: \n 1. My email (username) is:`}
        >
          <span
            className={classNames(
              'text-4xl sm:text-5xl md:text-6xl xl:text-8xl',
              'mt-2 ltr:mr-2 rtl:ml-2',
              'flex-1',
            )}
          >{t`Need help, with your password recovery?`}</span>
        </MailTo>
        <div className={classNames('flex ltr:justify-end rtl:justify-start')}>
          <Icon.ArrowRightBottom
            className={classNames('w-20 h-20 fill-current')}
          />
        </div>
      </HelperMessage>

      {children}
    </section>
  );
};
