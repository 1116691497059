import {
  Field,
  GraphQLLocalDateTime,
  ID,
  InputType,
} from '@warebee/shared/util-backend-only-types';
import { IsInt, IsNotEmpty } from '@warego/class-validator';

@InputType()
export class ImportOrderSetLineInput {
  @Field(() => ID)
  @IsNotEmpty()
  consignee: string;

  @Field(() => ID)
  @IsNotEmpty()
  orderId: string;

  @Field(() => GraphQLLocalDateTime, { nullable: true })
  orderDate?: string;

  @Field(() => ID, { nullable: true })
  deliveryRoute?: string;

  @Field(() => ID, { nullable: true })
  dock?: string;

  @Field(() => ID, { nullable: true })
  stagingArea?: string;

  @Field(() => ID, { nullable: true })
  waveId?: string;

  @Field(() => ID, { nullable: true })
  shipmentId?: string;

  @Field(() => GraphQLLocalDateTime, { nullable: true })
  loadByDate?: string;

  @Field()
  @IsInt()
  orderLine: number;

  @Field(() => ID)
  @IsNotEmpty()
  sku: string;

  @Field()
  @IsInt()
  quantity: number;

  @Field(() => ID, { nullable: true })
  customer?: string;

  @Field(() => ID, { nullable: true })
  pickingPriority?: string;

  @Field(() => ID, { nullable: true })
  pickingContainer?: string;
}
