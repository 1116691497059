import classNames from 'classnames';
import _ from 'lodash';
import { HTMLAttributes, useState } from 'react';
import HelpNavigate from '../../helpContext/HelpNavigate';
import { HelpIndex as HelpId, HelpIndex, HelpNavTo } from '../../helpContext/help.default';

export type StatGroupProps = {
  children?: React.ReactNode;
  childrenHeader?: React.ReactNode;
  title?: string | React.ReactNode;
  titleAction?: React.ReactNode;
  subTitle?: string | React.ReactNode;
  className?: string;
  classNameStatGroupItems?: string;
  classNameIcon?: string;
  classNameTitle?: string;
  classNameTitleContainer?: string;
  hasHelper?: boolean;
  hasListCount?: boolean;
  isPrimaryResult?: boolean;
  cardView?: boolean;
  horizontal?: boolean;
  hasNoPadding?: boolean;
  icon?: React.FC<HTMLAttributes<Element>>;
  helpNavTo?: HelpNavTo<HelpIndex>;
};

export const StatGroup = ({
  children,
  childrenHeader,
  title,
  titleAction,
  subTitle,
  className,
  classNameStatGroupItems,
  classNameIcon,
  classNameTitle,
  classNameTitleContainer,
  hasHelper,
  hasListCount,
  isPrimaryResult,
  cardView,
  icon,
  horizontal,
  hasNoPadding,
  helpNavTo,
}: StatGroupProps) => {
  const [id] = useState<string>(_.uniqueId());
  const IconItem = icon;

  const styleBlur =
    'bg-opacity-50 backdrop-filter backdrop-blur-lg backdrop-saturate-110';
  const styleBlur0 =
    'bg-opacity-60 backdrop-filter backdrop-blur-lg backdrop-saturate-0';

  return (
    <ul
      data-component="StatGroup"
      className={classNames(
        'my-0.5 w-full',
        // { 'divide-y divide-solid divide-menu-400/50': !divider },
        className,
      )}
    >
      {(title || titleAction) && (
        <li
          className={classNames(
            'group',
            'w-full',
            'flex items-center',
            { 'px-2 py-2 xl:px-4': !hasNoPadding },
            'sticky top-0',
            'z-50',
            'border-b-1 border-menu',
            { 'bg-menu-active': isPrimaryResult },
            {
              'bg-sidebar-header/70': !isPrimaryResult,
            },
            { hidden: cardView },
            styleBlur,
            classNameTitleContainer,
          )}
        >
          {icon && (
            <IconItem
              className={classNames(
                'w-full fill-current',
                'ltr:mr-2 rtl:ml-2',
                {
                  'text-menu-text': isPrimaryResult,
                },
                {
                  'text-sidebar-header-text': !isPrimaryResult,
                },
                classNameIcon,
              )}
            />
          )}

          <header
            data-component="title"
            className={classNames(
              'flex-1',
              {
                'text-menu-text': isPrimaryResult,
              },
              {
                'text-sidebar-header-text': !isPrimaryResult,
              },
              'text-xs md:text-sm xl:text-lg',
              'uppercase',
              classNameTitle,
            )}
          >
            {subTitle && (
              <div className={classNames('text-xs')}>{subTitle}</div>
            )}
            <div>{title}</div>
          </header>

          {titleAction}

          {helpNavTo && <HelpNavigate href={helpNavTo} />}
          {/* {hasHelper && (
            <Icon.CircleHelp
              className={classNames(
                'fill-current w-5 h-5 cursor-pointer',
                'text-menu-100',
                'transition duration-500 ease-in-out delay-500',
                'opacity-0 group-hover:opacity-100',
              )}
              alt="Help"
            />
          )} */}
        </li>
      )}
      {childrenHeader && (
        <li
          data-component="StatGroupItemsHeader"
          className={classNames(
            'flex flex-col',
            'w-full',
            {
              'text-menu list-outside list-decimal text-sm ltr:pl-8 rtl:pr-8':
                hasListCount,
            },
            classNameStatGroupItems,
          )}
        >
          {childrenHeader}
        </li>
      )}
      {children && (
        <li
          data-component="StatGroupItems"
          className={classNames(
            'flex w-full',
            { 'p-fix flex-col space-y-1': !horizontal },
            { 'flex-row space-x-1 py-1': horizontal }, //flex-wrap_
            // { 'border-menu-300/20 border-solid border-b': divider },
            // 'content-evenly',
            {
              'text-menu/30 list-outside list-decimal text-sm ltr:pl-8 rtl:pr-8':
                hasListCount,
            },
            classNameStatGroupItems,
          )}
        >
          {children}
        </li>
      )}
    </ul>
  );
};
