import classNames from 'classnames';
import React from 'react';
import useFormatter from '../../common/useFormatter';
import { getProductCategory } from '../../simulation/store/abc/simulation.ABC.helper';

export type ItemCategoryTagProps = {
  cmlPercent: number;
};

const ItemCategoryTag: React.FC<ItemCategoryTagProps> = props => {
  const formatter = useFormatter();

  const tooltip = formatter.formatShare(props.cmlPercent);
  const itemCategory = getProductCategory(props.cmlPercent);
  return (
    <span
      className={classNames(
        'p-1 px-2',
        'ltr:ml-2 rtl:mr-2',
        'text-xs',
        // 'text-white',
        'rounded-sm',
        'flex items-center',
      )}
      style={{
        background: itemCategory.color,
        color: itemCategory.textColor,
      }}
      title={tooltip.fullString}
    >
      {itemCategory.tag}
    </span>
  );
};

export default ItemCategoryTag;
