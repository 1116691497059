import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import { DatasetObjectDataRows } from '../../datasetObject/store/datasetObject.types';
import { executeDatasetQuery } from '../../feed/store/feed.helper';
import { errorAppender } from '../../store/error.state';
import { warehouseSelectedId } from '../../store/warehouse.state';
import {
  customQueryData,
  customQueryDataLoadStatus,
  customQueryDescriptorById,
  customQueryErrors,
} from '../store/customQuery.state';

export type LoadCustomQueryDataParams = {
  queryId: string;
};

function useLoadCustomQueryData() {
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load Custom Query Data`;
  const [observable, setObservable] = useState<ZenObservable.Subscription>();

  const resetCallback = useRecoilCallback(
    ({ reset }) =>
      async (params: LoadCustomQueryDataParams) => {
        reset(customQueryDataLoadStatus(params.queryId));
        reset(customQueryData(params.queryId));
        //reset(customQueryDataState(params.queryId));
      },
  );

  const initLoading = useRecoilCallback(
    ({ set }) =>
      async (params: LoadCustomQueryDataParams) => {
        set(customQueryDataLoadStatus(params.queryId), AsyncLoadStatus.Loading);
      },
  );

  const loadDataCallback = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: LoadCustomQueryDataParams) => {
        const warehouseId = await snapshot.getPromise(warehouseSelectedId);
        const descriptor = await snapshot.getPromise(
          customQueryDescriptorById(params.queryId),
        );
        const current = await snapshot.getPromise(
          customQueryData(params.queryId),
        );

        function handleError(details, stack) {
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(customQueryDataLoadStatus(params.queryId), AsyncLoadStatus.Error);
        }

        const [rows, columns, errors] = await executeDatasetQuery({
          warehouseId,
          compiledQuery: {
            sql: descriptor.sql,
          } as any,

          comment: '[dataset] Custom Query Data',
        });

        if (errors) {
          set(customQueryErrors(params.queryId), errors?.[0]);
        }
        let data: DatasetObjectDataRows = {
          totalCount: _.size(rows),
          content: rows,
          columns: columns ?? current?.columns,
        };

        set(customQueryData(params.queryId), data);
        set(customQueryDataLoadStatus(params.queryId), AsyncLoadStatus.Ok);
      },
  );

  async function call(params: LoadCustomQueryDataParams) {
    await initLoading(params);
    await loadDataCallback(params);
  }

  async function reset(params: LoadCustomQueryDataParams) {
    await resetCallback(params);
  }

  return [call, reset] as const;
}

export default useLoadCustomQueryData;
