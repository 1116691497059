import _ from 'lodash';
import React, { useState } from 'react';
import { cn } from '../common/utils';
// import * as Icon from '../components/icons';

export type AppFooterProps = {
  children?: React.ReactNode;
  className?: string;
};

export const AppFooter = ({ children, className }: AppFooterProps) => {
  const [id] = useState<string>(_.uniqueId());

  return (
    <footer
      data-component="AppFooter"
      id={id}
      className={cn(
        'bg-app-panel-dark/50',
        'flex items-center',
        'w-full',
        className,
      )}
    >
      {children}
    </footer>
  );
};
