import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ImportFileFooterStats } from '../../components/ImportFileFooterStats';
import UserTagsEditor from '../../components/UserTagsEditor';
import { PanelBody } from '../../components/designer/panels/PanelBody';
import { InputGroupList } from '../../components/inputs/InputGroupList';
import InputText from '../../components/inputs/InputText';
import InputTextArea from '../../components/inputs/InputTextArea';
import { Container } from '../../components/layout/ContainerFlex';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import { Spacer } from '../../components/layout/Spacer';
import { warehouseCanUpdate } from '../../store/warehouse.state';
import useUpdateDatasetObject from '../hooks/useUpdateDatasetObject';
import { datasetObjectDocument } from '../store/datasetObject.state';

const DatasetObjectStartPanel: React.FC = () => {
  const { t } = useTranslation('dataset');
  const [document, setDocument] = useRecoilState(datasetObjectDocument);
  const canUpdate = useRecoilValue(warehouseCanUpdate);
  const updateDocument = useUpdateDatasetObject();

  const changeTitle = (title: string) => {
    setDocument({ ...document, title: title });
    updateDocument({ datasetObjectId: document.id, title: title });
  };
  const changeDescription = (description: string) => {
    setDocument({ ...document, description: description });
    updateDocument({ datasetObjectId: document.id, description: description });
  };

  const updateUserTags = (userTags: string[]) => {
    setDocument({ ...document, userTags });

    updateDocument({
      datasetObjectId: document.id,
      userTags,
    });
  };

  return (
    <PanelBody className="h-full">
      <Container col hasOverflowY>
        <ScreenTitle
          isSticky
          title={t`Properties`}
          subtitle={t`Dataset object`}
        />
        <InputGroupList hasPadding hasSpacing>
          <InputText
            required
            placeholder={t`Dataset object Name`}
            label={t`Dataset object Name`}
            name={t`Dataset object Name`}
            value={document.title}
            onChange={changeTitle}
            isDisabled={!canUpdate}
            // animate={!canUpdate && document.title?.trim().length === 0}
            animate={
              !canUpdate &&
              document.title === '' &&
              document.title?.trim().length === 0
              // && document.title == null
            }
          />
          <InputTextArea
            optional
            label={t`Dataset object Description`}
            placeholder={t`About the Dataset...`}
            value={document.description}
            onChange={changeDescription}
            isDisabled={!canUpdate}
            animate={
              !canUpdate &&
              document.description === '' &&
              document.description?.trim().length === 0
              // && document.title == null
            }
          />
          <UserTagsEditor
            label={t`Tags`}
            tags={document.userTags ?? []}
            onChange={updateUserTags}
          />
        </InputGroupList>

        <Spacer flexspace />
        <ImportFileFooterStats
          className={classNames('mb-2')}
          updatedDate={document.updatedAt}
          createdDate={document.createdAt}
          importSource={document.objectType}
        />
      </Container>
    </PanelBody>
  );
};

export default DatasetObjectStartPanel;
