import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  FilterFieldConfigBase,
  FilterFieldConfigCustomizable,
} from '../../common/types';
import InboxZero from '../../components/InboxZero';
import InputCheckbox from '../../components/inputs/InputCheckbox';
import { InputGroupList } from '../../components/inputs/InputGroupList';
import { InputSearch } from '../../components/inputs/InputSearch';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import TitleSection from '../../components/layout/TitleSection';
import { ActionBar } from '../../components/nav/ActionBar';
import PanelContainer from '../../containers/PanelContainer';
import {
  DatasetExtraField,
  DBFieldType,
} from '../../datasetObject/store/datasetObject.types';
import { getExtraFieldFieldConfig } from '../../policyFilters/policyFilter.helper';
import { actualityHqFieldTypesBase } from '../store/actuality.hq.default';
import { actualityFilterSettingsByType } from '../store/actuality.state';
import { ActualityHqDataColumn } from '../store/datasetQueries/actualityHqDataRows';
import { feedDatasetExtraFields } from '../store/feed.state';
import { getActualityHqConfigBase } from '../store/getActualityHqConfigBase';

type FilterSetupSectionProps<T extends string> = {
  id: string;
  title: string;
  filterConfig: FilterFieldConfigBase<T>[];
  filterOverrides: Partial<Record<T, FilterFieldConfigCustomizable<T>>>;
  baseFields: Partial<Record<T, DBFieldType>>;
  extraFields: DatasetExtraField[];
  onChange: (
    filterOverrides: Partial<Record<T, FilterFieldConfigCustomizable<T>>>,
  ) => void;
};

function FilterSetupSection<T extends string>(
  props: FilterSetupSectionProps<T>,
) {
  const { t } = useTranslation('app');
  const [baseSearchQuery, setBaseSearchQuery] = React.useState('');
  const [extraFieldsSearchQuery, setExtraFieldsSearchQuery] =
    React.useState('');

  function onBaseFilterChange(type: T, isSelected: boolean) {
    props.onChange({
      ...props.filterOverrides,
      [type]: {
        ...props.filterOverrides[type],
        disabled: !isSelected,
      },
    });
  }

  function onExtraFilterChange(
    datasetField: DatasetExtraField,
    isSelected: boolean,
  ) {
    props.onChange({
      ...props.filterOverrides,
      [datasetField.alias]: {
        ...props.filterOverrides[datasetField.alias],
        type: datasetField.alias,
        title: datasetField.title,
        disabled: !isSelected,
      },
    });
  }

  return (
    <>
      <ScreenTitle
        // id={`activity-fields-${props.id}`}
        subtitle={t`Data Setup`}
        title={props.title}
        helpNavTo={'actuality/feed'}
        // icon={Icon.PolicyPickingAgents}
        isSticky
        className="top-8"
      />

      <TitleSection
        id={`activity-fields-base-${props.id}`}
        title={t`Base filters`}
        inSidebarView
        collapsible
      >
        <ActionBar className="p-2">
          <InputSearch
            placeholder={t`Search base filters...`}
            value={baseSearchQuery}
            onChange={value => setBaseSearchQuery(value)}
            panelTitleMode
            classNamePlaceholder="!left-2 !top-2"
            classNameInput="!px-2 !py-1"
          />
        </ActionBar>

        <InputGroupList className="space-y-2 p-1 lg:p-2 xl:p-4">
          {(() => {
            const filteredItems = _.map(props.filterConfig, cfg => {
              if (
                baseSearchQuery &&
                !cfg.title
                  ?.toLowerCase()
                  .includes(baseSearchQuery.toLowerCase())
              ) {
                return null;
              }

              const override = props.filterOverrides[cfg.type];
              const overrideConfig = { ...cfg, ...override };
              return (
                <InputCheckbox
                  key={`activity-extra-field-${overrideConfig.type}`}
                  isSelected={!overrideConfig.disabled}
                  onChange={isSelected =>
                    onBaseFilterChange(overrideConfig.type, isSelected)
                  }
                >
                  {cfg.title}
                </InputCheckbox>
              );
            }).filter(Boolean);

            return filteredItems.length > 0 ? (
              filteredItems
            ) : (
              <InboxZero selfCenter message={t`No Results Found`} />
            );
          })()}
        </InputGroupList>
      </TitleSection>

      {props.extraFields?.length > 0 && (
        <TitleSection
          id={`activity-fields-extra-${props.id}`}
          title={t`Extra fields filters`}
          inSidebarView
          collapsible
        >
          <ActionBar className="p-2">
            <InputSearch
              placeholder={t`Search fields...`}
              value={extraFieldsSearchQuery}
              onChange={value => setExtraFieldsSearchQuery(value)}
              panelTitleMode
              classNamePlaceholder="!left-2 !top-2"
              classNameInput="!px-2 !py-1"
            />
          </ActionBar>
          <InputGroupList className="space-y-2 p-1 lg:p-2 xl:p-4">
            {(() => {
              const filteredItems = _.map(props.extraFields, cfg => {
                if (
                  extraFieldsSearchQuery &&
                  !cfg.title
                    ?.toLowerCase()
                    .includes(extraFieldsSearchQuery.toLowerCase()) &&
                  !cfg.name
                    .toLowerCase()
                    .includes(extraFieldsSearchQuery.toLowerCase())
                ) {
                  return null;
                }

                const override = props.filterOverrides[cfg.alias];
                const overrideConfig = {
                  ...getExtraFieldFieldConfig(cfg),
                  ...override,
                };
                return (
                  <InputCheckbox
                    key={`activity-extra-field-${cfg.name}`}
                    isSelected={!overrideConfig.disabled}
                    onChange={isSelected =>
                      onExtraFilterChange(cfg, isSelected)
                    }
                  >
                    {cfg.title ?? cfg.name}
                  </InputCheckbox>
                );
              }).filter(Boolean);

              return filteredItems.length > 0 ? (
                filteredItems
              ) : (
                <InboxZero selfCenter message={t`No Results Found`} />
              );
            })()}
          </InputGroupList>
        </TitleSection>
      )}
    </>
  );
}

/**
 * Setup filters that will be available in filter presets
 */
const ActualityFiltersSettings: React.FC = () => {
  const { t } = useTranslation('feed');

  const [filterSettings, setFilterSettings] = useRecoilState(
    actualityFilterSettingsByType('actualityHq'),
  );

  const feedExtraFields = useRecoilValue(feedDatasetExtraFields);
  const activityFilterOverrides =
    filterSettings?.filterConfigOverrides as FilterFieldConfigCustomizable<ActualityHqDataColumn>[];

  const filterOverridesMap = _.keyBy(activityFilterOverrides, f => f.type);
  const activityFilters: FilterSetupSectionProps<ActualityHqDataColumn> = {
    id: 'activity-feed',
    title: t`Activity Events Filters`,
    filterConfig: getActualityHqConfigBase(t),
    filterOverrides: filterOverridesMap,
    baseFields: actualityHqFieldTypesBase,
    extraFields: feedExtraFields,
    onChange: filterOverrides =>
      setFilterSettings({
        presetType: 'actualityHq',
        filterConfigOverrides: _.values(filterOverrides),
      }),
  };

  return (
    <PanelContainer
      id="panel-filter-settings"
      title={t`Field Filter settings`}
      collapsible
      // hasPadding
    >
      <FilterSetupSection {...activityFilters}></FilterSetupSection>
    </PanelContainer>
  );
};

export default ActualityFiltersSettings;
