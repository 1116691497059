import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import useFormatter from '../../common/useFormatter';
import ButtonSwitchMulti from '../../components/actions/ButtonSwitchMulti';
import DropdownSelector from '../../components/actions/DropdownSelector';
import * as Icon from '../../components/icons';
import InboxZero from '../../components/InboxZero';
import { InputSearch } from '../../components/inputs/InputSearch';
import { ActionBar } from '../../components/nav/ActionBar';
import { Stat } from '../../components/stats/Stat';
import { StatGroup } from '../../components/stats/StatGroup';
import { FeedPicklistDetails } from '../components/FeedPicklistDetails';
import {
  actualityAgentsAnalyzedJobs,
  actualityJobTimingMode,
} from '../store/actuality.jobs.state';
import { actualityJobTimingModes } from '../store/feed.default';
import {
  feedAnalysedJobDisplayOptionAtom,
  feedSelectedPickListId,
} from '../store/feed.state';
import { feedDisplayOptions } from '../store/feed.types';

interface JobHistoricalStats {
  totalWeight: number;
  totalVolume: number;
  standardTime: number;
  teamAverage: number;
  idleTime: number;
  // Time between picks
  pickIntervals: number[];
  historicalPerformance: {
    date: Date;
    performance: number;
  }[];
}

const FeedPicklistPanel: React.FC = () => {
  const { t } = useTranslation('app');
  const agentsJobs = useRecoilValue(actualityAgentsAnalyzedJobs);

  const resetSelectedPicklist = useResetRecoilState(feedSelectedPickListId);
  const [timingMode, setTimingMode] = useRecoilState(actualityJobTimingMode);
  const [searchTerm, setSearchTerm] = React.useState('');
  const formatter = useFormatter();

  const [feedAnalysedJobDisplayOption, setFeedAnalysedJobDisplayOption] =
    useRecoilState(feedAnalysedJobDisplayOptionAtom);

  useEffect(() => {
    return () => {
      resetSelectedPicklist();
    };
  }, []);

  const filteredJobs = React.useMemo(() => {
    if (!searchTerm) return agentsJobs;

    return agentsJobs?.filter(job =>
      job.jobId.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }, [agentsJobs, searchTerm]);

  const jobStats = React.useMemo(() => {
    if (!filteredJobs?.length) {
      return {
        totalTimeActual: 0,
        totalTimeAnalysed: 0,
        totalTimeActualRaw: 0,
        totalTimeTravelling: 0,
        totalTimeTravellingStartEnd: 0,
        totalTimeTravellingVertical: 0,
        totalLines: 0,
        totalUOMs: 0,
        totalDistance: 0,
        avgLinesPerTime: 0,
        timeDifference: 0,
        hasTimeIssue: false,
        totalCost: 0,
        totalDurationHandling: 0,
        totalDurationPicking: 0,
        isSlowerThanActual: false,
        isFasterThanActual: false,
      };
    }

    const stats = filteredJobs.reduce(
      (acc, job) => {
        const actualDurationRaw =
          job.maxEventTime?.getTime() - job.minEventTime?.getTime() || 0;

        return {
          totalTimeActual: acc.totalTimeActual + actualDurationRaw / 1000,
          totalTimeActualRaw: acc.totalTimeActualRaw + actualDurationRaw,
          totalTimeAnalysed: acc.totalTimeAnalysed + (job.duration || 0),
          totalTimeTravelling:
            acc.totalTimeTravelling + (job.durationTravelling || 0),
          totalTimeTravellingStartEnd:
            acc.totalTimeTravellingStartEnd +
            (job.durationTravellingStartEnd || 0),
          totalTimeTravellingVertical:
            acc.totalTimeTravellingVertical +
            (job.durationTravellingVertical || 0),
          totalLines: acc.totalLines + (job.lines || 0),
          totalUOMs: acc.totalUOMs + (job.uomCount || 0),
          totalDistance: acc.totalDistance + (job.distance || 0),
          totalCost: acc.totalCost + (job.cost || 0),
          totalDurationHandling:
            acc.totalDurationHandling + (job.durationHandling || 0),
          totalDurationPicking:
            acc.totalDurationPicking + (job.durationPicking || 0),
        };
      },
      {
        totalTimeActual: 0,
        totalTimeActualRaw: 0,
        totalTimeAnalysed: 0,
        totalTimeTravelling: 0,
        totalTimeTravellingStartEnd: 0,
        totalTimeTravellingVertical: 0,
        totalLines: 0,
        totalUOMs: 0,
        totalDistance: 0,
        totalCost: 0,
        totalDurationHandling: 0,
        totalDurationPicking: 0,
      },
    );

    const avgLinesPerTime =
      stats.totalTimeActual > 0
        ? (stats.totalLines / stats.totalTimeActual) * 3600 // Convert to per hour
        : 0;

    const timeDifference = stats.totalTimeAnalysed - stats.totalTimeActual;
    const threshold = 590; // 20min
    const hasTimeIssue = Math.abs(timeDifference) > threshold;
    const isSlowerThanActual = timeDifference > threshold;
    const isFasterThanActual = timeDifference < -threshold;

    return {
      ...stats,
      avgLinesPerTime,
      timeDifference,
      hasTimeIssue,
      isSlowerThanActual,
      isFasterThanActual,
    };
  }, [filteredJobs]);

  const distance = formatter.formatDistance(jobStats.totalDistance);

  const durationActual = formatter.formatTimeBestToPrecision(
    jobStats.totalTimeActual,
  );
  const durationAnalysed = formatter.formatTimeBestToPrecision(
    jobStats.totalTimeAnalysed,
  );
  const timeDifference = formatter.formatTimeBestToPrecision(
    jobStats.timeDifference,
  );

  const durationTravelling = formatter.formatTimeSpan(
    jobStats.totalTimeTravelling,
  );
  const durationTravellingStartEnd = formatter.formatTimeSpan(
    jobStats.totalTimeTravellingStartEnd,
  );
  const durationTravellingVertical = formatter.formatTimeSpan(
    jobStats.totalTimeTravellingVertical,
  );
  const lines = formatter.formatNumber(jobStats.totalLines);
  const uoms = formatter.formatNumber(jobStats.totalUOMs);
  const avgLines = formatter.formatNumber(jobStats.avgLinesPerTime);

  const cost = formatter.formatCurrency(jobStats.totalCost);
  const durationHandling = formatter.formatTimeSpan(
    jobStats.totalDurationHandling,
  );
  const durationPicking = formatter.formatTimeSpan(
    jobStats.totalDurationPicking,
  );

  // if no jobs
  if (!agentsJobs?.length) {
    return (
      <div
        data-component="FeedPicklistPanel"
        className="relative h-full overflow-y-auto"
      >
        <InboxZero selfCenter message={t`No Resource selected`} />
      </div>
    );
  }

  return (
    <div
      data-component="FeedPicklistPanel"
      className={classNames('relative h-full overflow-y-auto')}
    >
      <ActionBar
        sticky
        stickyTop
        className={classNames('px-1 py-1', '!bg-sidebar-header/40', 'my-px')}
      >
        <ButtonSwitchMulti
          autoSize
          className="mx-2 my-1 w-full text-xs"
          selectedIndex={feedDisplayOptions.findIndex(
            option => option.value === feedAnalysedJobDisplayOption,
          )}
          onClick={index =>
            setFeedAnalysedJobDisplayOption(feedDisplayOptions[index].value)
          }
          options={feedDisplayOptions}
          buttonType="header"
        />
      </ActionBar>

      <StatGroup helpNavTo={'live/feed'} className="!m-0">
        {feedAnalysedJobDisplayOption === 'stats' ? (
          <>
            <StatGroup
              className="!m-0 flex flex-wrap gap-0.5 space-y-0"
              title={t`Time Stats`}
            >
              <div className="flex w-full gap-0.5">
                <Stat
                  title={t`Actual Time`}
                  value={durationActual.value}
                  unitOfMeasure={durationActual.unit}
                />
                {durationAnalysed.raw > 0 && (
                  <Stat
                    title={t`Standard Time`}
                    value={durationAnalysed.value}
                    unitOfMeasure={durationAnalysed.unit}
                  />
                )}
              </div>

              {durationAnalysed.raw > 0 &&
                timeDifference.raw !== durationActual.raw && (
                  <Stat
                    title={t`Time Difference`}
                    unitOfMeasure={timeDifference.unit}
                    value={timeDifference.value}
                    icon={
                      jobStats.isSlowerThanActual
                        ? Icon.TriangleInfo
                        : jobStats.isFasterThanActual
                          ? Icon.TriangleOk
                          : Icon.CircleOk
                    }
                  />
                )}
            </StatGroup>
            <StatGroup className="flex flex-wrap" title={t`Distance Stats`}>
              <Stat
                title={t`Total Distance`}
                value={distance.value}
                unitOfMeasure={distance.unit}
              />
              <div className="flex w-full gap-0.5">
                <Stat
                  title={t`Total Travel Time`}
                  unitOfMeasure={durationTravelling.unit}
                  value={durationTravelling.value}
                />
                <Stat
                  title={t`Start/End Travel`}
                  unitOfMeasure={durationTravellingStartEnd.unit}
                  value={durationTravellingStartEnd.value}
                />
              </div>
              {/* <Stat
                title={t`Vertical Travel`}
                unitOfMeasure={durationTravellingVertical.unit}
                value={durationTravellingVertical.value}
              /> */}
            </StatGroup>
            <StatGroup className="flex flex-wrap" title={t`Process Stats`}>
              <div className="flex w-full gap-0.5">
                <Stat
                  title={t`Handling Time`}
                  value={durationHandling.value}
                  unitOfMeasure={durationHandling.unit}
                />
                <Stat
                  title={t`Picking Time`}
                  value={durationPicking.value}
                  unitOfMeasure={durationPicking.unit}
                />
              </div>
            </StatGroup>
            <StatGroup className="flex flex-wrap" title={t`Performance Stats`}>
              <Stat
                title={t`Performance`}
                value={avgLines.value}
                unitOfMeasure={t`lines/hr`}
              />
              <Stat
                title={t`Total Lines`}
                value={lines.value}
                unitOfMeasure={t`lines`}
              />
              {jobStats.totalUOMs !== jobStats.totalLines && (
                <Stat
                  title={t`Total UOMs`}
                  value={uoms.value}
                  unitOfMeasure={t`units`}
                />
              )}
              {cost?.raw > 0 && (
                <Stat
                  title={t`Total Cost`}
                  value={cost.value}
                  unitOfMeasure={cost.unit}
                />
              )}

              <Stat
                title={t`Units per Hour`}
                value={
                  formatter.formatNumber(
                    (jobStats.totalUOMs / jobStats.totalTimeActual) * 3600,
                  ).value
                }
                unitOfMeasure={t`units/hr`}
              />
              <Stat
                title={t`Avg. Distance per Line`}
                value={
                  formatter.formatDistance(
                    jobStats.totalDistance / jobStats.totalLines,
                  ).value
                }
                unitOfMeasure={t`m/line`}
              />
              <Stat
                title={t`Handling Time per Unit`}
                value={
                  formatter.formatTimeSpan(
                    jobStats.totalDurationHandling / jobStats.totalUOMs,
                  ).value
                }
                unitOfMeasure={t`sec/unit`}
              />
            </StatGroup>
          </>
        ) : feedAnalysedJobDisplayOption === 'issues' ? (
          <>
            <InboxZero selfCenter message={t`No Issues found`} />
          </>
        ) : feedAnalysedJobDisplayOption === 'all' ? (
          <>
            <ActionBar
              transparent
              className={classNames(
                'p-2',
                'bg-sidebar-title/40',
                'saturate-110 backdrop-blur',
                'top-18 sticky',
                'z-header_screen',
                'gap-1',
              )}
            >
              <InputSearch
                placeholder={t('Search Activity')}
                value={searchTerm}
                onChange={(value: string) => setSearchTerm(value)}
                panelTitleMode
                classNamePlaceholder="!left-2 !top-2"
                classNameInput="!px-2 !py-1"
              />
              <DropdownSelector
                className="shrink-0 text-xs"
                value={timingMode}
                values={[...actualityJobTimingModes]}
                renderValue={v => `${t`Time View: `}${v}`}
                onChange={selectedMode => setTimingMode(selectedMode)}
                valuePrimary
                light
                widthFull
                w_sm
                DropAlignRight
                iconValue={Icon.TimeFull}
                iconValueOnly
                iconClass={classNames('w-4 h-4 xl:w-5 xl:h-5')}
              />
            </ActionBar>
            {filteredJobs?.length > 0 ? (
              <>
                {filteredJobs.map((pl, index) => (
                  <FeedPicklistDetails key={pl.jobKey} index={index} job={pl} />
                ))}
              </>
            ) : (
              <InboxZero
                selfCenter
                message={
                  searchTerm ? t`No matching jobs found` : t`No Jobs found`
                }
              />
            )}
          </>
        ) : null}
      </StatGroup>
    </div>
  );
};

export default FeedPicklistPanel;
