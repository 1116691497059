import _ from 'lodash';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import InboxZero from '../components/InboxZero';
import { Container } from '../components/layout/ContainerFlex';
import { ScreenTitle } from '../components/layout/ScreenTitle';
import SidebarContainer, {
  SidebarContainerProps,
} from '../containers/SidebarContainer';
import { useActualityHqFilterConfig } from '../feed/hooks/useActualityHqFilterConfig';
import { LoadAllPolicyFilterValuesFilterParams } from '../policyFilters/policyFilter.types';
import useLoadGenericFilterValues from '../policyFilters/useLoadGenericFilterValues';
import FilterPresetFilterEditor from './FilterPresetFilterEditor';
import {
  filterPresetEffectiveQueryBuilder,
  filterPresetFilterIntersection,
  filterPresetSelected,
  filterPresetSelectedIdentity,
} from './store/filterPreset.state';

const FilterPresetEditorSidebar: React.FC = () => {
  const { t } = useTranslation('app');
  const preset = useRecoilValue(filterPresetSelected);
  const filterIdentity = useRecoilValue(filterPresetSelectedIdentity);
  const filterIntersection = useRecoilValue(
    filterPresetFilterIntersection(filterIdentity),
  );
  const queryBuilder = useRecoilValue(filterPresetEffectiveQueryBuilder);
  const [loadFilterValues, cancelLoadFilterValues] =
    useLoadGenericFilterValues();
  const filterConfigs = useActualityHqFilterConfig();

  async function loadData(
    filterParams: LoadAllPolicyFilterValuesFilterParams<string>,
  ) {
    loadFilterValues({
      ...filterParams,
      filterConfigs,
      builder: queryBuilder.b,
    });
  }

  useEffect(() => {
    loadData({
      field: null,
      filterKey: null,
      filterIntersection,
      affect: 'all',
    });
  }, [filterIdentity]);

  if (_.isNil(filterIdentity?.filterId)) {
    return (
      <InboxZero
        selfCenter
        center
        message={t`No filter selected`}
        message_helper={t`Click on an Edit button in Policy Rule to change filters`}
      />
    );
  }

  return (
    <Container col hasOverflowY className="pb-20">
      <ScreenTitle
        subtitle={t`Filter Options for`}
        title={`${preset?.title}`}
        isSticky
        truncate
      />
      {_.map(filterConfigs, configItem => (
        <FilterPresetFilterEditor
          key={`policy-filter-editor-${configItem.type}`}
          filterConfig={configItem}
          startLoadData={loadData}
          cancelLoadData={_.noop}
        />
      ))}
    </Container>
  );
};

export default () => {
  const { t } = useTranslation('feed');

  const containerProps: SidebarContainerProps = {
    type: 'sidebar-filter-preset-editor',
    title: t`Views`,
    loadingMessage: t`Loading Filters`,
    size: 'xs',
  };

  return (
    <SidebarContainer key={containerProps.type} {...containerProps}>
      <FilterPresetEditorSidebar />
    </SidebarContainer>
  );
};
