import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonLoadMore } from '../../../components/actions/ButtonLoadMore';
import CodeEditor from '../../../components/editors/CodeEditor';
import InboxZero from '../../../components/InboxZero';
import { Container } from '../../../components/layout/ContainerFlex';
import { ActionBar } from '../../../components/nav/ActionBar';

export type ImporterJSONRawPreviewProps = {
  data: Object[];
};

const ImporterJSONRawPreview: React.FC<ImporterJSONRawPreviewProps> = props => {
  const { t } = useTranslation('importer');
  const [currentIndex, setCurrentIndex] = useState(0);

  const itemsPerPage = 1;
  const totalCount = props.data?.length ?? 0;

  const handleNext = () => {
    if (currentIndex < totalCount - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleFirst = () => {
    setCurrentIndex(0);
  };

  const handleLast = () => {
    setCurrentIndex(totalCount - 1);
  };

  const value =
    totalCount > 0 ? JSON.stringify(props.data[currentIndex], null, '\t') : '';

  return (
    <Container col className="relative">
      {totalCount === 0 ? (
        <InboxZero
          selfCenter
          hasIcon
          message={t`No Items`}
          className="!text-start"
        ></InboxZero>
      ) : (
        <>
          <ActionBar stickyTop className="p-2">
            <ButtonLoadMore
              totalCount={totalCount}
              itemsCount={currentIndex + 1}
              titleNext={t('Next')}
              titlePrev={t('Prev')}
              titleFirst={t('First')}
              titleLast={t('Last')}
              onNext={handleNext}
              onPrev={handlePrev}
              onFirst={handleFirst}
              onLast={handleLast}
            />
          </ActionBar>
          <div className="h-full overflow-hidden">
            <CodeEditor
              value={value}
              language={'json'}
              editorOptions={{ readOnly: true }}
            />
          </div>
        </>
      )}
    </Container>
  );
};

export default ImporterJSONRawPreview;
