import { ItemSetDataTableRow } from '@warebee/shared/export-converter';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import useFormatter from '../common/useFormatter';
import { ColumnConfig } from '../components/DatasetTable';
import FormattedValue from '../components/FormattedValue';
import { getItemSetSchema } from '../import/itemSet/schema/itemSetSchema';
import ItemTag from '../simulation/tags/ItemTag';

function useItemSetDataTableConfig(): ColumnConfig<ItemSetDataTableRow>[] {
  const { t: tImporter } = useTranslation('importer');
  const { t } = useTranslation('dataset');
  const formatter = useFormatter();
  const schema = _.keyBy(getItemSetSchema(tImporter).fields, f => f.name);

  const columnsConfigRaw: ColumnConfig<ItemSetDataTableRow>[] = [
    {
      field: 'consignee',
      title: t`Client (Consignee)`,
      hasFilter: true,
      isHeader: true,
    },
    {
      field: 'sku',
      title: t`Item (SKU)`,
      hasFilter: true,
      isHeader: true,
      render: (sku: string, row) => (
        <ItemTag title={sku} filters={{ consignee: row['consignee'], sku }} />
      ),
    },
    {
      field: 'skuGroup',
      title: t`Item Group`,
      hasFilter: true,
      // isHeader: true,
    },
    { field: 'uom', title: t`UOM`, hasFilter: true },
    {
      field: 'unitsPerLowestUom',
      title: t`Unit Per Lower UOM`,
      hasFilter: true,
    },
    { field: 'lowerUom', title: t`Lower UOM`, hasFilter: true },
    {
      field: 'netWeight',
      title: t`Weight`,
      hasFilter: true,
      render: (v: number) => (
        <FormattedValue value={formatter.formatWeight(v)} />
      ),
    },
    {
      field: 'length',
      title: t`Length`,
      hasFilter: true,
      render: (v: number) => (
        <FormattedValue value={formatter.formatDistance(v, 1000, false)} />
      ),
    },
    {
      field: 'width',
      title: t`Width`,
      hasFilter: true,
      render: (v: number) => (
        <FormattedValue value={formatter.formatDistance(v, 1000, false)} />
      ),
    },
    {
      field: 'height',
      title: t`Height`,
      hasFilter: true,
      render: (v: number) => (
        <FormattedValue value={formatter.formatDistance(v, 1000, false)} />
      ),
    },
    // {
    //   field: 'volume',
    //   title: t`Volume`,
    //   hasFilter: true,
    //   render: (v: number) => (
    //     <FormattedValue value={formatter.formatVolume(v)} />
    //   ),
    // },
    {
      field: 'volume',
      title: t`Volume`,
      hasFilter: true,
      render: (v: number, row) => {
        const calculatedVolume = row.length * row.width * row.height;
        const formattedRawVolume = formatter.formatVolume(v);
        const formattedCalculatedVolume =
          formatter.formatVolume(calculatedVolume);
        const volumeDifference = Math.abs(calculatedVolume - row.volume);
        const hasDifference =
          volumeDifference > 0.0001 && calculatedVolume !== 0;

        return (
          <div>
            <FormattedValue value={formattedRawVolume} />
            {hasDifference && (
              <>
                <span className="mx-1 opacity-50">{t`vs`}</span>
                <FormattedValue
                  hasIssue={hasDifference}
                  value={formattedCalculatedVolume}
                />
              </>
            )}
          </div>
        );
      },
    },
    { field: 'name', title: t`Item Name`, hasFilter: false },
    { field: 'description', title: t`Item Description`, hasFilter: false },
    { field: 'ean', title: t`EAN`, hasFilter: true },
    { field: 'upc', title: t`UPC`, hasFilter: true },
    { field: 'subGroup', title: t`Item sub-group`, hasFilter: true },
    { field: 'transportClass', title: t`Transport Class`, hasFilter: true },
    { field: 'stockCategory', title: t`Stock category`, hasFilter: true },
    { field: 'storageClass', title: t`Storage Class`, hasFilter: true },
    { field: 'pollutionClass', title: t`Pollution Class`, hasFilter: true },
    { field: 'maxStack', title: t`Max Stackability`, hasFilter: true },
  ];

  const columnsConfig = columnsConfigRaw.map(f => ({
    ...f,
    title: f.title ?? schema[f.field]?.title,
  }));

  return columnsConfig;
}
export default useItemSetDataTableConfig;
