import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { format } from 'sql-formatter';
import { AsyncLoadStatus } from '../common/types';
import { cn } from '../common/utils';
import { Button } from '../components/actions/Button';
import CopyToClipboardButton from '../components/actions/CopyToClipboardButton';
import CodeEditor from '../components/editors/CodeEditor';
import { Container } from '../components/layout/ContainerFlex';
import { Spacer } from '../components/layout/Spacer';
import { ActionBar } from '../components/nav/ActionBar';
import useLoadCustomQueryData from './hooks/useLoadCustomQueryData';
import {
  customQueryDataLoadStatus,
  customQueryDescriptorById,
  customQueryStateById,
} from './store/customQuery.state';

export type CustomQueryEditorProps = {
  queryDescriptorId: string;
};

const CustomQueryEditor: React.FC<CustomQueryEditorProps> = props => {
  const { t } = useTranslation('app');
  const [descriptor, setDescriptor] = useRecoilState(
    customQueryDescriptorById(props.queryDescriptorId),
  );
  const status = useRecoilValue(
    customQueryDataLoadStatus(props.queryDescriptorId),
  );
  const setQueryState = useSetRecoilState(
    customQueryStateById(props.queryDescriptorId),
  );
  const [call, reset] = useLoadCustomQueryData();

  function executeQuery() {
    call({
      queryId: props.queryDescriptorId,
    });
    setQueryState(true);
  }

  function setSqlQuery(sql: string) {
    setDescriptor(current => ({ ...current, sql }));
  }

  const sqlQuery = descriptor?.sql ?? '';
  const isLoading = status === AsyncLoadStatus.Loading;

  const formatSqlWithTemplates = (sql: string) => {
    // Temporarily replace all template variables with valid SQL identifiers
    // Format: {{...}} -> "TEMP_TEMPLATE_n"
    let templateMap = new Map();
    let templateCounter = 0;

    const tempSql = sql.replace(/{{[^}]+}}/g, match => {
      const placeholder = `"TEMP_TEMPLATE_${templateCounter}"`;
      templateMap.set(placeholder, match);
      templateCounter++;
      return placeholder;
    });

    // Format the SQL
    const formattedSql = format(tempSql, {
      language: 'trino',
    });

    // Restore all template variables
    return formattedSql.replace(/"TEMP_TEMPLATE_\d+"/g, placeholder =>
      templateMap.get(placeholder),
    );
  };

  return (
    <Container col className="relative">
      <div className="h-full overflow-auto">
        <CodeEditor
          value={sqlQuery}
          onChange={query => setSqlQuery(query)}
          language={'trinosql'}
          className={'flex-1'}
        />
      </div>

      <ActionBar sticky stickyBottom>
        <CopyToClipboardButton
          className={cn('rounded-none')}
          hasIcon
          hasIconSmall
          value={sqlQuery}
        />
        <Spacer flexspace />
        <Button
          buttonSize="sm"
          label={t`Prettify`}
          buttonType="secondary"
          isLoading={isLoading}
          isDisabled={isLoading}
          onPress={() => setSqlQuery(formatSqlWithTemplates(sqlQuery))}
        />
        <Button
          buttonSize="sm"
          label={isLoading ? t`Executing...` : t`Run`}
          buttonType="primary"
          isLoading={isLoading}
          hasIconBefore={isLoading}
          isDisabled={isLoading}
          onPress={() => executeQuery()}
        />
      </ActionBar>
    </Container>
  );
};
export default CustomQueryEditor;
