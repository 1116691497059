import {
  Action,
  MeasurementSystem,
  useCreateWarehouseMutation,
  useRemoveWarehouseMutation,
  useUpdateWarehouseMutation,
} from '@warebee/frontend/data-access-api-graphql';
import { CreateWarehouseInput } from '@warebee/shared/data-access-api-dto';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { NoMatch404 } from '../../NoMatch404';
import useLoadWarehouseBillingState from '../../appBilling/hooks/useLoadWarehouseBillingState';
import { currencyByCode } from '../../assets/currency';
import { WAREHOUSE_NEW_ID } from '../../common/constants';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import TitleSection from '../../components/layout/TitleSection';
import SettingsInviteUsers from '../../screens/settings/settings/SettingsInviteUsers';
import {
  warehouseList,
  warehouseListLoadStatus,
} from '../../store/warehouse.state';

export type WarehouseSettingsParams = {
  warehouseId: string;
};

const WarehouseSettingsPermissions: React.FC = () => {
  const { t: tCurrency } = useTranslation('currency');
  const { t } = useTranslation('app');

  const { warehouseId = WAREHOUSE_NEW_ID } =
    useParams<WarehouseSettingsParams>();
  const [whList, setWhList] = useRecoilState(warehouseList);
  const whListStatus = useRecoilValue(warehouseListLoadStatus);
  const [createWarehouse, { loading: loadingCreate }] =
    useCreateWarehouseMutation();
  const [updateWarehouse, { loading: loadingUpdate }] =
    useUpdateWarehouseMutation();
  const [removeWarehouse, { loading: loadingRemove }] =
    useRemoveWarehouseMutation();
  const loadWhBillingState = useLoadWarehouseBillingState();

  const navigate = useNavigate();
  const defaultInputData: CreateWarehouseInput = {
    title: '',
    address: '',
    companyName: '',
    description: '',
    measurementSystem: MeasurementSystem.METRIC,
    country: 'GB',
    currency: 'GBP',
  };
  const isNewWarehouseSetup =
    warehouseId?.toLowerCase() === WAREHOUSE_NEW_ID.toLowerCase();
  const wh = _.find(whList, wh => wh.id === warehouseId);
  const [inputData, setInputData] = useState<CreateWarehouseInput>(
    isNewWarehouseSetup ? defaultInputData : wh,
  );
  const [errors, setErrors] = useState({
    title: null,
  });
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    const wh = _.find(whList, wh => wh.id === warehouseId);
    if (!_.isNil(wh)) {
      setInputData(_.cloneDeep(wh));
    }
  }, [whList]);

  const canUpdate = _.includes(wh?.permissions?.allowed, Action.UPDATE);
  const canDelete = _.includes(wh?.permissions?.allowed, Action.DELETE);

  if (!isNewWarehouseSetup && _.isNil(wh)) {
    return <NoMatch404 title={t`Warehouse not found`} />;
  }

  async function callCreate() {
    if (_.isEmpty(inputData.title)) {
      setErrors({ title: t`Warehouse name cannot be empty` });
      return;
    }

    try {
      const response = await createWarehouse({
        variables: {
          input: inputData,
        },
      });

      if (response.errors) {
        // response.errors.forEach((e: any) => {
        //   if (
        //     e.extensions &&
        //     e.extensions.code === 'BAD_USER_INPUT' &&
        //     e.extensions.errors
        //   ) {
        //     setFieldErrors(e.extensions.errors as ValidationError[]);
        //   }
        // });
      } else {
        const wh = response.data.createWarehouse;
        setWhList([...whList, wh]);
        navigate(`/wh/i/${wh.id}`);
        loadWhBillingState([wh.id]);
      }
    } catch (ex) {
      console.log(ex);
      throw ex;
    }
  }

  async function callUpdate() {
    if (_.isEmpty(inputData.title)) {
      setErrors({ title: t`Warehouse name cannot be empty` });
      return;
    }

    try {
      const response = await updateWarehouse({
        variables: {
          input: {
            warehouseId: warehouseId,
            title: inputData.title,
            description: inputData.description,
            companyName: inputData.companyName,
            address: inputData.address,
          },
        },
      });

      if (response.errors) {
        // response.errors.forEach((e: any) => {
        //   if (
        //     e.extensions &&
        //     e.extensions.code === 'BAD_USER_INPUT' &&
        //     e.extensions.errors
        //   ) {
        //     setFieldErrors(e.extensions.errors as ValidationError[]);
        //   }
        // });
      } else {
        const wh = response.data.updateWarehouse;
        setWhList([..._.filter(whList, w => w.id !== wh.id), wh]);
        navigate(`/wh/i/${wh.id}`);
      }
    } catch (ex) {
      console.log(ex);
      throw ex;
    }
  }

  async function callDelete() {
    try {
      const response = await removeWarehouse({
        variables: {
          id: warehouseId,
        },
      });

      if (response.errors) {
        // response.errors.forEach((e: any) => {
        //   if (
        //     e.extensions &&
        //     e.extensions.code === 'BAD_USER_INPUT' &&
        //     e.extensions.errors
        //   ) {
        //     setFieldErrors(e.extensions.errors as ValidationError[]);
        //   }
        // });
      } else {
        setWhList(_.filter(whList, w => w.id !== wh.id));
        navigate(`/`);
      }
    } catch (ex) {
      console.log(ex);
      throw ex;
    }
  }

  const measureSystemOptions = [
    MeasurementSystem.METRIC,
    MeasurementSystem.IMPERIAL,
  ];

  const currencyOptions = _.keys(currencyByCode(tCurrency));

  return (
    <>
      <ScreenTitle
        hasPadding
        isHero
        title={t`Warehouse Permissions`}
        subtitle={inputData?.title ?? t`Settings`}
        isSticky
      />

      <TitleSection title={t`Invite Users`} />

      <SettingsInviteUsers />
    </>
  );
};

export default WarehouseSettingsPermissions;
