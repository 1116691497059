import { t } from 'i18next';
import React from 'react';
import { useRecoilState } from 'recoil';
import { InputValue } from '../../../components/inputs/InputValue';
import { importPreviewSizeLimit } from '../../store/import.state';

const ImporterPreviewSizeInput: React.FC = () => {
  const [importPreviewSize, setImportPreviewSize] = useRecoilState(
    importPreviewSizeLimit,
  );

  const handleSizeChange = (value: string) => {
    // Convert input value from MB to bytes before updating the state
    const sizeInBytes = parseFloat(value) * 1024 * 1024;
    setImportPreviewSize(sizeInBytes);
  };

  return (
    <InputValue
      isNumeric={true}
      title={t`Preview Size Limit (MB):`}
      value={importPreviewSize / (1024 * 1024)}
      onChange={handleSizeChange}
      labelVertical
    />
  );
};

export default ImporterPreviewSizeInput;
