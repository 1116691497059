import classNames from 'classnames';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { AsyncLoadStatus } from '../../../common/types';
import { Button } from '../../../components/actions/Button';
import { InputEmail } from '../../../components/inputs/InputText';
import { Spacer } from '../../../components/layout/Spacer';
import { ActionBar } from '../../../components/nav/ActionBar';
import { loggedInUser } from '../../../store/auth.state';
import {
  warehouseSelected,
  warehouseUpdateLoadStatus,
} from '../../../store/warehouse.state';
import { WarehouseExtraSettings } from '../../../store/warehouse.types';
import useUpdateWarehouseExtraSettings from '../../../warehouse/hooks/useUpdateWarehouseExtraSettings';

const SettingsNotificationEmail: React.FC = () => {
  const { t } = useTranslation('app');

  const wh = useRecoilValue(warehouseSelected);
  const user = useRecoilValue(loggedInUser);
  const extra: WarehouseExtraSettings = wh?.extraSettings;
  const status = useRecoilValue(warehouseUpdateLoadStatus);

  const [email, setEmail] = useState(
    _.head(extra?.notificationsEmails) ?? user.email,
  );

  useEffect(() => {
    setEmail(_.head(extra?.notificationsEmails) ?? user.email);
  }, [extra]);

  const [updateFeedSettings] = useUpdateWarehouseExtraSettings();

  function update() {
    updateFeedSettings({
      patch: { notificationsEmails: [email] },
    });
  }

  const isLoading = status === AsyncLoadStatus.Loading;
  const hasError = status === AsyncLoadStatus.Error;

  return (
    <>
      <section
        className={classNames(
          'w-full',
          'mb-4',
          'divide-menu/50 divide-y divide-solid',
          'p-4',
        )}
      >
        <InputEmail
          label={t`Email recipients for export`}
          value={email}
          onChange={v => setEmail(v)}
          className="mb-2 p-2"
        />
      </section>

      <ActionBar className={classNames('flex w-full items-end', 'p-2')}>
        <Spacer flexspace />
        <Button
          // full
          label={t`Update Notification`}
          buttonType="primary"
          buttonSize="xs"
          className={classNames('rounded')}
          isLoading={isLoading}
          isDisabled={isLoading}
          onPress={() => update()}
        />
      </ActionBar>
    </>
  );
};

export default SettingsNotificationEmail;
