import {
  ActualityMetaFragment,
  LoadActualityAnalyzeStatusDocument,
  LoadActualityAnalyzeStatusQuery,
  LoadActualityAnalyzeStatusQueryVariables,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { atom, selector, selectorFamily } from 'recoil';
import { secureClient } from '../../GraphQLClient';
import { PanelFixedHeight } from '../../common/component.types';
import { persistAtom } from '../../common/recoil/persistAtom';
import {
  AsyncLoadStatus,
  FilterFieldConfigBase,
  FilterFieldConfigCustomizable,
} from '../../common/types';
import { DatasetExtraFieldsSettings } from '../../datasetObject/store/datasetObject.types';
import { FilterPresetDataType } from '../../filterPreset/store/filterPreset.types';
import { getExtraFieldFieldConfig } from '../../policyFilters/policyFilter.helper';
import { FilterSettings } from '../../policyFilters/policyFilter.types';
import {
  AgentSettingsWithMeta,
  ResourcePolicy,
} from '../../resourcePolicy/agentData/agent.types';
import { resourcePolicySelectedAgentId } from '../../simulation/store/resourcePolicy.state';
import { warehouseSelectedId } from '../../store/warehouse.state';
import { getActualityAnalyzeJobStatus } from './actuality.helper';
import {
  ActualityAutoAnalyzeStatus,
  ActualityDataSetTableType,
  ActualityDatasetTypeKey,
  ActualityExtraSettings,
  ActualityHeatmapType,
  ActualityMainViewType,
  TimelineFilterByPerformance,
  TimelineFilterByType,
} from './actuality.types';
import {
  ActualityAnalyzedJobSummaries,
  ActualityAnalyzedJobSummary,
  getActualityAnalyzedJobSummaries,
} from './datasetQueries/actualityAnalyzedJobSummaries';
import { ActualityHqDataColumn } from './datasetQueries/actualityHqDataRows';
import {
  ActualityAnalyzedEventsSummaryRow,
  getActualityAnalyzedEventsSummary,
} from './datasetQueries/feedAnalyzedEvents';
import { executeDatasetQuery } from './feed.helper';
import { feedDatasetExtraFields, feedQueryBuilderParams } from './feed.state';
import { getActualityHqConfigBase } from './getActualityHqConfigBase';

const getKey = (postfix: string) => `warebee-actuality-${postfix}`;

//#region Actuality Document State
export const actualitySelectedId = atom({
  key: getKey('selected-id'),
  default: null,
});

export const actualitySelectedDocument = atom<ActualityMetaFragment>({
  key: getKey('selected-document'),
  default: null,
});

export const actualitySelectedDocumentStatus = atom<AsyncLoadStatus>({
  key: getKey('selected-document-status'),
  default: AsyncLoadStatus.None,
});

export const actualityDocumentUpdateStatus = atom<AsyncLoadStatus>({
  key: getKey('update-status'),
  default: AsyncLoadStatus.None,
});

export const actualityExtraSettings = atom<ActualityExtraSettings>({
  key: getKey('extra-settings'),
  default: null,
});

export const actualityDatasetExtraFieldsSettings = selector<
  DatasetExtraFieldsSettings<ActualityDatasetTypeKey>[]
>({
  key: getKey('dataset-extra-fields-settings'),
  get: ({ get }) => {
    return get(actualityExtraSettings)?.datasetExtraFieldsSettings;
  },
  set: (
    { get, set },
    value: DatasetExtraFieldsSettings<ActualityDatasetTypeKey>[],
  ) => {
    set(actualityExtraSettings, current => ({
      ...current,
      datasetExtraFieldsSettings: value,
    }));
  },
});

export const actualityDatasetExtraFieldsSettingsByType = selectorFamily<
  DatasetExtraFieldsSettings<ActualityDatasetTypeKey>,
  ActualityDatasetTypeKey
>({
  key: getKey('dataset-extra-fields-settings-by-type'),
  get:
    datasetKey =>
    ({ get }) => {
      return _.find(
        get(actualityDatasetExtraFieldsSettings),
        s => s.datasetType === datasetKey,
      );
    },
  set:
    (datasetKey: ActualityDatasetTypeKey) =>
    (
      { get, set },
      value: DatasetExtraFieldsSettings<ActualityDatasetTypeKey>,
    ) => {
      const otherDatasetExtraFieldsSettings = _.filter(
        get(actualityDatasetExtraFieldsSettings),
        f => f.datasetType !== datasetKey,
      );

      set(actualityDatasetExtraFieldsSettings, [
        ...otherDatasetExtraFieldsSettings,
        value,
      ]);
    },
});

export const actualityFilterSettings = selector<FilterSettings[]>({
  key: getKey('filter-settings'),
  get: ({ get }) => {
    return get(actualityExtraSettings)?.filterSettings;
  },
  set: ({ get, set }, value: FilterSettings[]) => {
    set(actualityExtraSettings, current => ({
      ...current,
      filterSettings: value,
    }));
  },
});

export const actualityFilterSettingsByType = selectorFamily<
  FilterSettings,
  FilterPresetDataType
>({
  key: getKey('filter-settings-by-type'),
  get:
    presetType =>
    ({ get }) => {
      return _.find(
        get(actualityFilterSettings),
        s => s.presetType === presetType,
      );
    },
  set:
    (presetType: FilterPresetDataType) =>
    ({ get, set }, value: FilterSettings) => {
      const otherDatasetExtraFieldsSettings = _.filter(
        get(actualityFilterSettings),
        f => f.presetType !== presetType,
      );

      set(actualityFilterSettings, [...otherDatasetExtraFieldsSettings, value]);
    },
});

export const actualityEffectiveFilterConfig = selector<
  FilterFieldConfigBase<ActualityHqDataColumn>[]
>({
  key: getKey('effective-filter-config'),
  get: ({ get }) => {
    const filterSettings = get(actualityFilterSettingsByType('actualityHq'));
    const feedExtraFields = get(feedDatasetExtraFields);
    const filterOverrides =
      filterSettings?.filterConfigOverrides as FilterFieldConfigCustomizable<ActualityHqDataColumn>[];

    const filterOverridesMap = _.keyBy(filterOverrides, f => f.type);
    const actualityHqConfigBase = getActualityHqConfigBase();

    const actualityHqConfigExtra: FilterFieldConfigBase<ActualityHqDataColumn>[] =
      _.map(
        feedExtraFields,
        f =>
          getExtraFieldFieldConfig(
            f,
          ) as FilterFieldConfigBase<ActualityHqDataColumn>,
      );
    const actualityHqConfig = [
      ...actualityHqConfigBase,
      ...actualityHqConfigExtra,
    ]
      .map(f => ({ ...f, ...filterOverridesMap[f.type] }))
      .filter(f => !f.disabled);

    return actualityHqConfig;
  },
});
//#endregion Actuality Document State

//#region Actuality Resource Policy
export const actualityResourcePolicy = selector<ResourcePolicy>({
  key: getKey('resource-policy'),
  get: ({ get }) => {
    const sim = get(actualitySelectedDocument);
    return (sim?.resourcePolicy as ResourcePolicy) ?? { agents: [] };
  },
  set: ({ get, set }, value: ResourcePolicy) => {
    const doc = get(actualitySelectedDocument);
    set(actualitySelectedDocument, { ...doc, resourcePolicy: value });
  },
});

export const actualityPolicyAgentById = selectorFamily<
  AgentSettingsWithMeta,
  string
>({
  key: getKey('resource-policy-agent-by-id'),
  get:
    (agentId: string) =>
    ({ get }) => {
      const policy = get(actualityResourcePolicy);
      return _.find(policy?.agents, agent => agent.id === agentId);
    },
  set:
    (agentId: string) =>
    ({ get, set }, value: AgentSettingsWithMeta) => {
      const policy = get(actualityResourcePolicy);
      set(actualityResourcePolicy, {
        ...policy,
        agents: policy.agents.map(rule => (rule.id === agentId ? value : rule)),
      });
    },
});

export const actualityResourcePolicySelectedAgent =
  selector<AgentSettingsWithMeta>({
    key: getKey('selected-rule'),
    get: ({ get }) => {
      const selectedId = get(resourcePolicySelectedAgentId);
      if (_.isNil(selectedId)) return null;
      return get(actualityPolicyAgentById(selectedId));
    },
    set: ({ get, set }, value: AgentSettingsWithMeta) => {
      const selectedId = get(resourcePolicySelectedAgentId);
      if (_.isNil(selectedId)) return null;
      set(actualityPolicyAgentById(selectedId), value);
    },
  });
//#endregion Actuality Resource Policy

export const actualityAnalyzeLoadStatus = atom<AsyncLoadStatus>({
  key: getKey('analyze-load-status'),
  default: AsyncLoadStatus.None,
});

export const actualityAnalyzedEventsSummary = selector<
  ActualityAnalyzedEventsSummaryRow<'eventType'>[]
>({
  key: getKey('analyzed-events-summary'),
  get: async ({ get }) => {
    const actualityId = get(actualitySelectedId);
    const warehouseId = get(warehouseSelectedId);
    const compiledQuery = getActualityAnalyzedEventsSummary(
      {
        ...get(feedQueryBuilderParams),
        actualityId,
      },
      'eventType',
    ).compile();
    const [result] = await executeDatasetQuery({
      warehouseId,
      compiledQuery,
      comment: '[actuality] Analyzed Events Summary',
    });
    return result as ActualityAnalyzedEventsSummaryRow<'eventType'>[];
  },
});

export const actualityAnalyzedAgentSummary = selector<
  ActualityAnalyzedEventsSummaryRow<'agentId'>[]
>({
  key: getKey('analyzed-agents-summary'),
  get: async ({ get }) => {
    const actualityId = get(actualitySelectedId);
    const warehouseId = get(warehouseSelectedId);
    const compiledQuery = getActualityAnalyzedEventsSummary(
      {
        ...get(feedQueryBuilderParams),
        actualityId,
      },
      'agentId',
    ).compile();
    const [result] = await executeDatasetQuery({
      warehouseId,
      compiledQuery,
      comment: '[actuality] Analyzed Agents Summary',
    });
    return result as any as ActualityAnalyzedEventsSummaryRow<'agentId'>[];
  },
});

export const actualityAnalyzedJobSummaries =
  selector<ActualityAnalyzedJobSummaries>({
    key: getKey('analyzed-job-summaries'),
    get: async ({ get }) => {
      const actualityId = get(actualitySelectedId);
      const warehouseId = get(warehouseSelectedId);
      const compiledQuery = getActualityAnalyzedJobSummaries({
        ...get(feedQueryBuilderParams),
        actualityId,
      }).compile();
      const [result] = await executeDatasetQuery({
        warehouseId,
        compiledQuery,
        comment: '[actuality] Analyzed Job Summaries',
      });
      return result;
    },
  });

export const actualityAnalyzedJobSummaryById = selector<
  Record<string, ActualityAnalyzedJobSummary>
>({
  key: getKey('analyzed-job-summaries-by-id'),
  get: ({ get }) => {
    const all = get(actualityAnalyzedJobSummaries);
    const grouped = _.keyBy(all, p => p.jobId);
    return grouped;
  },
});

export const actualityTimelineFilterPerformance =
  persistAtom<TimelineFilterByPerformance>({
    key: getKey('timeline-filter-resource-performance'),
    default: 'all',
  });

export const actualityTimelineFilterType = persistAtom<TimelineFilterByType>({
  key: getKey('timeline-filter-resource-type'),
  default: 'user',
});

export const actualityMainViewTypeAtom = atom<ActualityMainViewType>({
  key: getKey('main-view-type-atom'),
  default: 'layout',
});

export const actualityMainViewType = selector<ActualityMainViewType>({
  key: getKey('main-view-type'),
  get: ({ get }) => get(actualityMainViewTypeAtom),
  set: ({ set }, v) => {
    set(actualityMainViewTypeAtom, v);
    console.log('Set actualityMainViewTypeAtom', v);
  },
});

export const actualityAgentShiftCutOf = atom<number>({
  key: getKey('agent-shift-cut-of'),
  default: 2,
});

export const actualityStatusUpdateTime = atom<Date>({
  key: getKey('status-update-time'),
  default: null,
});

export const actualityAnalyzeStatus = selector<ActualityAutoAnalyzeStatus>({
  key: getKey('analyze-status'),
  get: async ({ get }): Promise<ActualityAutoAnalyzeStatus> => {
    const actuality = get(actualitySelectedDocument);

    if (!actuality?.analyzePipeline?.enabled) return 'none';
    try {
      const response = await secureClient.query<
        LoadActualityAnalyzeStatusQuery,
        LoadActualityAnalyzeStatusQueryVariables
      >({
        query: LoadActualityAnalyzeStatusDocument,
        variables: {
          actualityId: actuality.id,
        },
      });
      if (!_.isEmpty(response.errors)) {
        console.error('Cannot load actuality status', response.errors);
        return 'error';
      }

      const lastJob = _.head(
        response.data.actuality?.analyzePipeline?.jobs?.content,
      );

      if (_.isNil(lastJob)) return 'none';
      return getActualityAnalyzeJobStatus(lastJob);
    } catch (ex) {
      console.error('Cannot load aisles by plane', ex);
      return 'error';
    }
  },
});

export const actualityHeatmapTypeSelected = atom<ActualityHeatmapType>({
  key: getKey('heatmap-type-selected'),
  default: 'events',
});

export const actualityExtraDataViewHeight = atom<PanelFixedHeight>({
  key: getKey('extra-dataset-height'),
  default: 'h-default',
});

export const actualityDataSetTableTypeSelected =
  persistAtom<ActualityDataSetTableType>({
    key: getKey('data-set-table-type'),
    default: null,
  });
