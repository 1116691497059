import { t } from 'i18next';
import React, { PropsWithChildren } from 'react';
import { useRecoilValue } from 'recoil';
import { loggedInUser } from '../store/auth.state';
import {
  appThemeSelectedId,
  getDefaultBrandConfig,
} from '../store/global.state';
import { whiteLabelConfigs } from '../store/whitelabel.types';
import Callout from './Callout';

export type HelpHeaderProps = PropsWithChildren & {
  className?: string;
};

const HelpHeader: React.FC<HelpHeaderProps> = props => {
  // User's Details
  const user = useRecoilValue(loggedInUser);
  const firstName = user.firstName;

  const selectedTheme = useRecoilValue(appThemeSelectedId);

  // Find the config entry where themes.name matches currentThemeId
  const configBrandEntry = Object.entries(whiteLabelConfigs).find(
    ([_, config]) => {
      console.log('Config theme name:', config.themes?.name);
      console.log('Config selectedTheme:', selectedTheme);
      return config.themes?.name === selectedTheme;
    },
  );

  const supportEmail =
    selectedTheme && configBrandEntry
      ? configBrandEntry?.[1]?.support?.email
      : (getDefaultBrandConfig()?.support.email ?? 'support@warebee.com');

  return (
    <header className="mb-8 mt-2">
      <Callout panelMode type="suggestion">
        <div className="whitespace-pre-line">
          {t(
            `👋 Hello {{firstName}},
            I am a WareBee Helper,

            You will see me throughout the app, with helpers, tips and data insights,
            And you can email us at {{supportEmail}} get on free Chat support (with real humans).

            WareBee Team.🤓`,
            {
              firstName,
              supportEmail,
            },
          )}
        </div>
      </Callout>
    </header>
  );
};

export default HelpHeader;
