export const defaultQualitativeColors = [
  // '#19451f',
  '#38E54D',
  '#13d6af',
  '#ffa221',
  '#bdff2e',
  '#e0b6fb',
  '#e6958d',
  '#09b97e',
  '#8e9afd',
  '#ff9294',
  '#4dff8b',
  '#e4604e',
  '#2cbec6',
  '#FF97C1',
  '#2c76ff',
  '#F3950D',
  '#F4E185',
  '#ff6551',
  '#24d778',
  '#76afff',
  '#98f959',
  '#f7a258',
  '#6eadff',
  '#f97159',
  '#ebff26',
  '#07e7ba',
  '#00b5d7',
  '#d16fe4',
  '#16cc95',
  '#588fdc',
  '#F2B701',
  '#80BA5A',
  '#f28e1a',
  '#3bdfeb',
  '#f97b72',
  '#9393f6',
  '#a6ba77',
  '#8be0e6',
  '#F6CF71',
  '#F89C74',
  '#DCB0F2',
  '#87C55F',
  '#bec0ff',
  '#FE88B1',
  '#C9DB74',
  '#8BE0A4',
  '#B497E7',
  '#D3B484',
  '#B3B3B3',
  '#3d9c73',
  '#63b179',
  '#88c580',
  '#aed987',
  '#d6ec91',
  '#ffff9d',
  '#fee17e',
  '#fcc267',
  '#f7a258',
  '#ef8250',
  '#e4604e',
];

export const dimensionColors = [
  '#38e54d',
  '#b6a6fc',
  '#c7cc00',
  '#34c3ea',
  '#f4430c',
  '#ED8E8E',
  '#e29ddd',
  '#d6f941',
  '#98d2ff',
  '#e28150',
  '#48ffb3',
  '#FFD23E',
  '#9c74ff',
  '#38e54d',
  '#ff8e74',
  '#10cdf9',
  '#c14a4a',
  '#bacf66',
  '#ce66bb',
  '#74ff9c',
  '#4b82c2',
  '#82ab6c',
  '#c14a4a',
  '#f7a258',
  '#6ac879',
  '#516ecf',
  '#b497e7',
  '#a2a149',
  '#ea6b7a',
  '#73c8a9',
  '#956ff0',
  '#ff6ea6',
  '#c69f5c',
  '#38e54d',
  '#bacf66',
  '#dcf25d',
  '#fe88b1',
  '#52986c',
  '#7f3c8d',
  '#b180d3',
  '#48ffb3',
  '#8be0a4',
  '#cced4b',
  '#6f5ac2',
  '#f97b72',
  '#00c2cb',
  '#ff6da5',
  '#73c8a9',
  '#d3b484',
  '#7e9cf3',
];
export const policyColors = [
  '#2D82C7',
  '#6F8DE8',
  '#8DC452',
  '#74ffc0',
  '#2fb514',
  '#9e88fc',
  '#7F3C8D',
  '#FF6EA6',
  '#EA5275',
  '#3EC0E8',
  '#8CC464',
  '#FF9F5B',
  '#FF476E',
  '#55B8EA',
  '#FFA046',
  '#5AC854',
  '#F36D81',
  '#6F8DE8',
  '#FFE659',
  '#FF295B',
  '#6A2673',
  '#4AA3D0',
  '#FF8939',
  '#2E9D8E',
  '#E84C5E',
  '#5FA7E9',
  '#FF9C36',
  '#8DC452',
  '#FF7354',
  '#4C8BE8',
  '#F9B428',
  '#E2445E',
  '#2D82C7',
  '#FF8A00',
  '#56C9A9',
  '#F74C3C',
  '#3165AC',
  '#C8E8A8',
  '#FF6540',
  '#6190CC',
  '#ED4E64',
  '#4FA3A3',
  '#9DE84D',
  '#FF4C00',
  '#2B6AAE',
  '#FF8E30',
  '#5999D0',
  '#E93D55',
  '#4C7FE8',
  '#FF6B3B',
  '#6D96E8',
  '#FF8600',
];

export const tagsColors = [
  '#2D82C7',
  '#6F8DE8',
  '#8DC452',
  '#74ffc0',
  '#2fb514',
  '#9e88fc',
  '#7F3C8D',
  '#FF6EA6',
  '#EA5275',
  '#3EC0E8',
  '#8CC464',
  '#FF9F5B',
  '#FF476E',
  '#55B8EA',
  '#FFA046',
  '#5AC854',
  '#F36D81',
  '#6F8DE8',
  '#FFE659',
  '#FF295B',
  '#6A2673',
  '#4AA3D0',
  '#FF8939',
  '#2E9D8E',
  '#E84C5E',
  '#5FA7E9',
  '#FF9C36',
  '#8DC452',
  '#FF7354',
  '#4C8BE8',
  '#F9B428',
  '#E2445E',
  '#2D82C7',
  '#FF8A00',
  '#56C9A9',
  '#F74C3C',
  '#3165AC',
  '#C8E8A8',
  '#FF6540',
  '#6190CC',
  '#ED4E64',
  '#4FA3A3',
  '#9DE84D',
  '#FF4C00',
  '#2B6AAE',
  '#FF8E30',
  '#5999D0',
  '#E93D55',
  '#4C7FE8',
  '#FF6B3B',
  '#6D96E8',
  '#FF8600',
];

export const waypointStarColors = ['#b02286', '#C23373', '#F6635C', '#FFBA86'];
export const waypointEndColors = ['#86af1d', '#98bd3c', '#9dc569', '#bbf6b5'];

export const booleanColors = ['#e74c3c', '#2ecc71'];
export const booleanComplianceColors = ['#b02286', '#38e54d'];
