import { loader } from '@monaco-editor/react';
import * as monaco from 'monaco-editor';
import { LanguageIdEnum } from 'monaco-sql-languages';
import { language as trinoLang } from 'monaco-sql-languages/esm/languages/trino/trino';
import 'monaco-sql-languages/esm/languages/trino/trino.contribution';
import {
  codeEditorWarebeeTheme,
  codeEditorWarebeeThemeId,
} from './CodeEditorWarebeeTheme';

monaco.editor.defineTheme(codeEditorWarebeeThemeId, codeEditorWarebeeTheme);
// monaco.languages.register({ id: LanguageIdEnum.TRINO });
monaco.languages.setMonarchTokensProvider(LanguageIdEnum.TRINO, {
  ...trinoLang,

  tokenizer: {
    ...trinoLang.tokenizer,
    customParams: [
      ...trinoLang.tokenizer.customParams,
      [/\{\{#dataset\[[^\]]+\]\}\}/, 'dataset-variable-id'],
      [/\{\{#[^}]+\}\}/, 'dataset-variable'],
      [/_{3}[^\n]+_{3}/, 'input-variable'],
    ],
  },
});

// Set the language configuration for better editing experience
// monaco.languages.setLanguageConfiguration(
//   LanguageIdEnum.TRINO,
//   trinoConf,
// );

loader.config({ monaco });

// /** define MonacoEnvironment.getWorkerUrl  */
// (globalThis as any).MonacoEnvironment = {
//   getWorkerUrl: function (_moduleId: string, label: string) {
//     switch (label) {
//       case LanguageIdEnum.TRINO: {
//         return './trino.worker.js';
//       }
//       default: {
//         return './editor.worker.js';
//       }
//     }
//   },
// };

// function useCodeEditorSetup() {
//   const monaco = useMonaco();
//   useEffect(() => {
//     if (monaco) {
//       console.log('###Monaco lang: ', monaco.languages.getLanguages());
//       console.log('###Monaco lang2: ', monaco.languages.getLanguages());
//     }
//   }, [monaco]);
// }

// export default useCodeEditorSetup;
