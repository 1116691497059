import { t } from 'i18next';

export const APP_SERVICES = {
  DATA_WAREHOUSE: {
    id: 'DATA_WAREHOUSE',
    defaultName: t`Data`,
  },
  DESIGNER: {
    id: 'LAYOUT_DESIGNER',
    defaultName: t`Designer`,
  },
  SIMULATION_ANALYZE: {
    id: 'SIMULATION_ANALYZE',
    defaultName: t`Analyse`,
  },
  SIMULATION_OPTIMIZE: {
    id: 'SIMULATION_OPTIMIZE',
    defaultName: t`Optimise`,
  },
  ACTUALITY: {
    id: 'ACTUALITY',
    defaultName: t`Activity Feed`,
  },
  DASHBOARD: {
    id: 'DASHBOARD',
    defaultName: t`Dashboard`,
  },
  SCHEDULING_DOCKS: {
    id: 'SCHEDULING_DOCKS',
    defaultName: t`Docks`,
  },
  SCHEDULING: {
    id: 'SCHEDULING',
    defaultName: t`Scheduling`,
  },
  WAREHOUSE_BILLING: {
    id: 'WAREHOUSE_BILLING',
    defaultName: t`Billing`,
  },
  WMS_CONFIGURATOR: {
    id: 'WMS_CONFIGURATOR',
    defaultName: t`WMS Configurator`,
  },
} as const;

export type ServiceType = keyof typeof APP_SERVICES;

export interface WhiteLabelConfig {
  id: string;
  enabled?: boolean;
  default?: boolean;
  appName?: string;
  prefix?: string;
  suffix?: string;
  keyword?: string;
  supportEmail?: string;
  // Brand-specific information
  brand?: {
    name?: string;
    logoApp?: string;
    logoFull?: string;
    favicon?: string;
    cobranding?: boolean;
    cobrandingLogoApp?: string;
    cobrandingLogoFull?: string;
  };
  support?: {
    email?: string;
    phone?: string;
    chatEnabled?: boolean;
  };
  featuresFlags?: {
    analytics?: boolean;
    customFeatures?: string[];
  };
  themes?: {
    name: string;
  };
  languages?: {
    name?: string;
  };
  legal?: {
    path: string;
  };
}

export const whiteLabelConfigs: Record<string, WhiteLabelConfig> = {
  WAREBEE: {
    default: true,
    id: 'WareBee',
    appName: 'WareBee',
    brand: {
      name: 'WareBee',
      logoFull: 'WareBeeLogoFull',
      logoApp: 'WareBeeSymbolShape',
    },
    themes: {
      name: 'WareBee',
    },
    support: {
      email: 'support@warebee.com',
      chatEnabled: true,
    },
  },
  // WarebeeLight: {
  //   id: 'WarebeeLight',
  //   themeName: 'WareBee Light Theme',
  //   serviceName: 'WareBee',
  //   supportEmail: 'support@warebee.com',
  // },
  // WarebeeDark: {
  //   id: 'WarebeeLight',
  //   themeName: 'WareBee Dark Theme',
  //   serviceName: 'WareBee',
  //   supportEmail: 'support@warebee.com',
  // },
  PROGLOVE: {
    id: 'Proglove',
    appName: 'Warehouse 360',
    brand: {
      name: 'Proglove',
      logoFull: 'WareBeeLogoFull',
      logoApp: 'WareBeeSymbolShape',
    },
    themes: {
      name: 'Proglove',
    },
    support: {
      email: 'support@proglove.de',
      chatEnabled: false,
    },
  },
  DEVOCA: {
    id: 'Devoca',
    themes: {
      name: 'Devoca',
    },
    appName: 'WareBee',
    support: {
      email: 'support@devoca.com',
      chatEnabled: true,
    },
    enabled: false,
  },
  // RYDER: {
  //   id: 'Ryder',
  //   themes: {
  //     name: 'WareBeeLight',
  //   },
  //   appName: 'Ryder Ops Box',
  //   brand: {
  //     name: 'Ryder',
  //     logoFull: 'WareBeeLogoFull',
  //     logoApp: 'WareBeeSymbolShape',
  //   },
  //   support: {
  //     email: 'support@ryder.com',
  //     chatEnabled: false,
  //   },
  //   enabled: true,
  // },
  TECH_MAHINDRA: {
    id: 'TechMachindra',
    themes: {
      name: 'TechMachindra',
    },
    appName: 'WareBee',
    brand: {
      name: 'WareBee & Tech Machindra',
      logoFull: 'WareBeeLogoFull',
      logoApp: 'WareBeeSymbolShape',
      cobranding: true,
      cobrandingLogoFull: 'TechMachindraLogoFull',
      cobrandingLogoApp: 'TechMachindraLogoFull',
    },
    support: {
      email: 'support@techmachindra.com',
      chatEnabled: false,
    },
    enabled: true,
  },
} as const;
