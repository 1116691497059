import { TFunction } from 'i18next';

export type FailReason = {
  id: string;
  title: string;
};

export const getFailReasonTitle = (
  t: TFunction,
  reason: string,
): FailReason => {
  switch (reason) {
    case 'UNKNOWN_AGENT':
      return {
        id: reason,
        title: t('Unknown Agent or User', { ns: 'simulation' }),
      };
    case 'UNKNOWN_LOCATION':
      return {
        id: reason,
        title: t('Unknown Location', { ns: 'simulation' }),
      };
    case 'INVALID_LOCATION_FOR_JOB':
      return {
        id: reason,
        title: t('Invalid Location for Job', { ns: 'simulation' }),
      };
    case 'MULTIPLE_JOB_STARTS':
      return {
        id: reason,
        title: t('Multiple Job Starts', { ns: 'simulation' }),
      };
    case 'MULTIPLE_JOB_ENDS':
      return {
        id: reason,
        title: t('Multiple Job Ends', { ns: 'simulation' }),
      };
    case 'NO_QUANTITY':
      return {
        id: reason,
        title: t('0 Quantity', { ns: 'simulation' }),
      };
    default:
      return {
        id: reason,
        title: t('Unknown Issue', { ns: 'simulation' }),
      };
  }
};
