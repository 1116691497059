import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { cn } from '../../../common/utils';
import LanguagePrompt from '../../../components/LanguagePrompt';
import LanguageSelector from '../../../components/LanguageSelector';
import { ScreenTitle } from '../../../components/layout/ScreenTitle';
import PanelContainer from '../../../containers/PanelContainer';

export type PanelProps = PropsWithChildren & {
  className?: string;
};

const PanelSystemLanguage: React.FC<PanelProps> = props => {
  const { t } = useTranslation('app');

  return (
    <PanelContainer
      data-component="PanelSystemLanguage"
      id="panel-system-language"
      title={t`Language`}
      collapsible
      className={cn('className', props.className)}
    >
      <ScreenTitle isSticky subtitle={t`System`} title={t`Language`} />

      <div className="p-4">
        <LanguagePrompt />
        <LanguageSelector panelMode />
      </div>
      {props.children}
    </PanelContainer>
  );
};

export default PanelSystemLanguage;
