import {
  SkipLimitPageSpec,
  SortDirection,
  StringSearchFilterType,
} from '@warebee/frontend/data-access-api-graphql';
import { InferResult } from 'kysely';
import _ from 'lodash';
import {
  ActualityHqQueryBuilderParams,
  ActualityHqSortBy,
  getHqQueryBuilder,
} from './actualityQueryBuilder';

export type ActualityHqDataRows = InferResult<
  ReturnType<typeof getActualityHqDataRowsSelectPart>
>;
export type ActualityHqDataRow = ActualityHqDataRows[number];
export type ActualityHqDataColumn = keyof ActualityHqDataRow;
export type ActualityHqQueryBuilderPagedClientParams = {
  sortBy?: ActualityHqSortBy[];
  page?: SkipLimitPageSpec;
};

export type ActualityHqQueryBuilderPagedParams = ActualityHqQueryBuilderParams &
  ActualityHqQueryBuilderPagedClientParams;

function getActualityHqDataRowsSelectPart(
  params: ActualityHqQueryBuilderParams,
) {
  return getHqQueryBuilder(params).selectFrom('hq').selectAll();
}

function getActualityHqDataTotalSelectPart(
  params: ActualityHqQueryBuilderParams,
) {
  let builder = getHqQueryBuilder(params)
    .selectFrom('hq')
    .select(({ fn }) => [fn.countAll<number>().as('totalCount')]);
  return builder;
}

export function getActualityHqDataRowsQuery(
  params: ActualityHqQueryBuilderPagedParams,
) {
  let query = getActualityHqDataRowsSelectPart(params)
    .offset(params.page.skip ?? 0)
    .limit(params.page.limit ?? 100);

  _.forEach(params.sortBy, sb => {
    query = query.orderBy(
      sb.field,
      sb.direction === SortDirection.ASC ? 'asc' : 'desc',
    );
  });
  return query;
}

export function getActualityHqDataTotalQuery(
  params: ActualityHqQueryBuilderPagedParams,
) {
  let query = getActualityHqDataTotalSelectPart(params);

  if (params.filterBy) {
    query = _.reduce(
      params.filterBy,
      (q, value, key) => {
        if (
          value?.type === StringSearchFilterType.CONTAINS &&
          value?.value === 'undefined'
        ) {
          return q.where(key as any, 'is', null);
        }

        if (
          value?.type === StringSearchFilterType.CONTAINS &&
          !_.isEmpty(value?.value)
        ) {
          return q.where(key as any, 'like', `%${value.value}%`);
        }
        return q;
      },
      query,
    );
  }

  return query;
}

export function postProcessTotalCount(events: Record<string, any>[]): {
  totalCount: number;
} {
  return {
    totalCount: _.head(events)?.['total_count'] ?? 0,
  };
}
