import Konva from 'konva';
import React, { useState } from 'react';
import { KonvaNodeEvents, Rect, Text } from 'react-konva';
import { TwTheme } from '../../../Tw';
import { getBestContrastColor } from '../../common/color.helper';
import { ticksInPixel } from '../viewer/layers/SchedulerLayer';

const menuColors = TwTheme.extend.colors.menu;
const brandColors = TwTheme.extend.colors.brand;
const colors = TwTheme.extend.colors;

export const jobFeatureHeight = 34;
export const jobPadding = 8;
export const actualJobHeight = 20;
export const analyzedJobHeight = actualJobHeight / 10;

export type ScheduledJobFeatureProps = {
  job: {
    id: string;
    from: Date;
    to: Date;
    resource: string;
    gapBefore?: number;
    gapAfter?: number;
  };
  offsetY: number;
  colors?: [string, string];
  gradientColors?: string[];
  onClick: (evt: Konva.KonvaEventObject<MouseEvent>) => void;
};

function createWarningPattern(callback) {
  const size = 20; // The size of the pattern tile
  const canvas = document.createElement('canvas');
  canvas.width = size;
  canvas.height = size;
  const ctx = canvas.getContext('2d');

  // Background color
  ctx.fillStyle = 'black';
  ctx.fillRect(0, 0, size, size);

  // Diagonal yellow lines
  ctx.strokeStyle = 'yellow';
  ctx.lineWidth = 4;
  ctx.beginPath();
  ctx.moveTo(-size / 2, size / 2);
  ctx.lineTo(size / 2, size * 1.5);
  ctx.moveTo(0, 0);
  ctx.lineTo(size, size);
  ctx.moveTo(size / 2, -size / 2);
  ctx.lineTo(size * 1.5, size / 2);
  ctx.stroke();

  // Convert the canvas to an image and pass it to the callback
  const image = new Image();
  image.onload = () => callback(image);
  image.src = canvas.toDataURL();
}

const svgPattern = `
<svg width="10" height="10" xmlns="http://www.w3.org/2000/svg">
  <defs>
    <pattern id="diagonalLines" patternUnits="userSpaceOnUse" width="4" height="4">
      <path d="M-1,1 l2,-2
               M0,4 l4,-4
               M3,5 l2,-2" style="stroke:black; strokeWidth:1" />
    </pattern>
  </defs>
  <rect width="10" height="10" fill="url(#diagonalLines)" />
</svg>
`;

const ScheduledJobFeature: React.FC<ScheduledJobFeatureProps> = props => {
  const { job, offsetY = 0 } = props;

  const [hovered, setHovered] = useState(false);

  const id = job.id;
  const actualJobStart = job.from.getTime() / ticksInPixel;
  const actualJobEnd = job.to.getTime() / ticksInPixel;
  const actualJobLength = Math.max(actualJobEnd - actualJobStart, 1);

  const actualJobConfig: Konva.RectConfig = {
    id,
    x: actualJobStart,
    y: offsetY + jobPadding,
    width: actualJobLength,
    height: actualJobHeight,
    fill: props.colors[0],
    fillEnabled: true,
    opacity: hovered ? 0.5 : 0.8,
    // cornerRadius: [2, 2, 2, 2],
    strokeWidth: 0,
  };
  const beforePadding = (props.job.gapBefore * 1000) / ticksInPixel;
  const beforePlaceholderConfig: Konva.RectConfig = {
    id,
    x: actualJobStart - beforePadding,
    y: offsetY + jobPadding,
    width: beforePadding,
    height: actualJobHeight,
    fill: props.colors[0],
    fillEnabled: true,
    opacity: 0.2,
    cornerRadius: [2, 0, 0, 2],
    strokeWidth: 0,
  };

  const afterPadding = (props.job.gapAfter * 1000) / ticksInPixel;

  const afterPlaceholderConfig: Konva.RectConfig = {
    id,
    x: actualJobEnd,
    y: offsetY + jobPadding,
    width: afterPadding,
    height: actualJobHeight,
    fill: props.colors[0],
    fillEnabled: true,
    opacity: 0.2,
    cornerRadius: [0, 2, 2, 0],
    strokeWidth: 0,
  };

  // const analyzedJobConfig: Konva.RectConfig = {
  //   id: `analyzed-${id}`,
  //   x: analyzedJobStart,
  //   y: 1 + offsetY + jobPadding + actualJobHeight,
  //   width: analyzedJobDuration,
  //   height: analyzedJobHeight,
  //   fill: analyzedJobFill,
  //   fillEnabled: true,
  //   opacity: hovered ? 0.4 : 0.8,
  //   strokeWidth: 0,
  //   cornerRadius: 0.5,
  // };

  // function getDynamicFontSize(actualJobWidth) {
  //   // Minimum and maximum font sizes
  //   const minFontSize = 2;
  //   const maxFontSize = 5;

  //   // Define the width range for the smallest to the largest expected job widths
  //   const minWidthFont = 1;
  //   const maxWidthFont = 30;

  //   // Calculate the font size as a linear function of job width
  //   if (actualJobWidth <= minWidthFont) {
  //     return minFontSize;
  //   } else if (actualJobWidth >= maxWidthFont) {
  //     return maxFontSize;
  //   } else {
  //     // Linear interpolation between min and max font sizes
  //     return (
  //       minFontSize +
  //       (maxFontSize - minFontSize) *
  //         ((actualJobWidth - minWidthFont) / (maxWidthFont - minWidthFont))
  //     );
  //   }
  // }

  // const maxTextWidth = actualJobLength * 0.5;
  // const maximumFontSize = 14;
  // const initialFontSize = 10;
  // const textLength = job.jobId.length;
  // const estimatedCharWidth = initialFontSize * 0.6;
  // const estimatedTextWidth = textLength * estimatedCharWidth;
  // const scaleFactor = maxTextWidth / estimatedTextWidth;
  // let fontSize = initialFontSize * scaleFactor;
  // fontSize = Math.min(fontSize, maximumFontSize); // Apply the hard max font size
  // // const fontSize = getDynamicFontSize(actualJobWidth);

  // const jobTitleConfig: Konva.TextConfig = {
  //   // Left padding of 2px
  //   x: actualJobStart + 2,
  //   // Aligned to the bottom with 2px padding
  //   y: offsetY + jobPadding + actualJobHeight - 2,
  //   text: job.jobId,
  //   fontSize: fontSize,
  //   fontFamily: 'NeoSans-Regular',
  //   fill: getBestContrastColor(analyzedJobFill),
  //   width: actualJobLength,
  //   align: 'left',
  //   verticalAlign: 'bottom',
  //   scaleY: -1, // Mirroring the text verticallyp
  //   listening: false,
  // };

  const eventConfig: KonvaNodeEvents = {
    onClick: props.onClick,
    onTap: props.onClick,
    onMouseEnter: e => {
      const container = e.target.getStage().container();
      container.style.cursor = 'pointer';
      // setHoveredGlobal(job.jobId);
      setHovered(true);
    },

    onMouseLeave: e => {
      const container = e.target.getStage().container();
      container.style.cursor = 'default';
      // setHoveredGlobal(null);
      setHovered(false);
    },
  };

  const jobTitle = `[${props.job.resource}]-${props.job.id}]`;
  const maxTextWidth = actualJobLength * 0.5;
  const maximumFontSize = 14;
  const initialFontSize = 8;
  const textLength = jobTitle.length;
  const estimatedCharWidth = initialFontSize * 0.6;
  const estimatedTextWidth = textLength * estimatedCharWidth;
  const scaleFactor = maxTextWidth / estimatedTextWidth;
  let fontSize = initialFontSize * scaleFactor;
  fontSize = Math.min(fontSize, maximumFontSize); // Apply the hard max font size

  const jobTitleConfig: Konva.TextConfig = {
    // Left padding of 2px
    x: actualJobStart + 2,
    // Aligned to the bottom with 2px padding
    y: offsetY + jobPadding + actualJobHeight - 2,
    text: jobTitle,
    fontSize: fontSize,
    fontFamily: 'NeoSans-Regular',
    fill: getBestContrastColor(props.colors[0]),
    width: actualJobLength,
    align: 'left',
    verticalAlign: 'bottom',
    scaleY: -1, // Mirroring the text vertically
    listening: false,
  };

  return (
    <React.Fragment key={id}>
      {props.job.gapBefore > 0 && <Rect {...beforePlaceholderConfig} />}
      <Rect {...actualJobConfig} {...eventConfig} />
      {props.job.gapAfter > 0 && <Rect {...afterPlaceholderConfig} />}
      <Text {...jobTitleConfig} />
    </React.Fragment>
  );
};

export default ScheduledJobFeature;
