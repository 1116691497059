import classNames from 'classnames';
import _ from 'lodash';
import React, {
  useCallback,
  useEffect,
  useId,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import * as Icon from '../icons';

export type InputSearchProps = {
  children?: React.ReactNode;
  className?: string;
  classNameInput?: string;
  classNamePlaceholder?: string;
  title?: string;
  id_name?: string;
  value?: string;
  icon?: string;
  placeholder?: string;
  disabled?: boolean;
  titleMode?: boolean;
  sidebarFilterMode?: boolean;
  headerTableMode?: boolean;
  headerStatMode?: boolean;
  headerTitleMode?: boolean;
  panelTitleMode?: boolean;
  onChange?: (value: string) => void;
  debounceInterval?: number;
};

export const InputSearch: React.FC<InputSearchProps> = props => {
  const { placeholder } = props;
  const id = useId();
  const placeholderRef = useRef(null);
  const [inputWidth, setInputWidth] = useState(0);
  const [isValid, setValid] = useState(true);
  const [value, setValue] = useState(props.value);

  // Add a ref to store the initial width
  const initialWidthRef = useRef<number>(0);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  useLayoutEffect(() => {
    if (!placeholderRef.current) return;

    const calculateWidth = (text: string) => {
      const paddingInCh = 2;
      const maxChars = 16;
      const minPlaceholderChars = 8;

      const placeholderWidth = Math.max(
        (placeholder?.length || 0) + paddingInCh,
        minPlaceholderChars,
      );

      return text
        ? Math.max(
            Math.min(text.length + paddingInCh, maxChars),
            placeholderWidth,
          )
        : placeholderWidth;
    };

    const hiddenSpan = placeholderRef.current as HTMLSpanElement;
    const textContent = value || placeholder || '';
    hiddenSpan.textContent = textContent;

    const newWidth = calculateWidth(textContent);
    if (newWidth !== inputWidth) {
      setInputWidth(newWidth);
      if (!initialWidthRef.current) {
        initialWidthRef.current = newWidth;
      }
    }
  }, [placeholder, value, inputWidth]);

  const debouncedChange = useCallback(
    _.debounce(async (value: string) => {
      try {
        await props.onChange(value);
      } catch (ex) {
        setValid(false);
      }
    }, props.debounceInterval ?? 800),
    [id, props.onChange],
  );

  function onChangeInner(e: React.ChangeEvent<HTMLInputElement>) {
    //reset validation state
    setValid(true);
    const value = e.target.value;
    setValue(value);

    //call debounce nonChange only for valid values
    debouncedChange(value);
  }

  return (
    <label
      data-size={`inputWidth ${inputWidth}`}
      data-component="InputSearch"
      htmlFor={id}
      className={classNames(
        'relative',
        'group',
        'flex items-center',
        'cursor-pointer',
        props.headerTitleMode || props.panelTitleMode ? '' : 'mt-4',
        {
          'flex flex-1': props.headerTableMode,
          'w-full': props.sidebarFilterMode,
        },
        props.panelTitleMode && 'w-full',
        props.className,
      )}
      style={{ minWidth: `${inputWidth}ch` }}
    >
      <input
        id={id}
        className={classNames(
          // Base styles
          'flex w-full flex-1',
          props.headerTitleMode || props.panelTitleMode
            ? 'min-w-[1rem]'
            : 'min-w-[2.5rem]',
          'truncate',
          'peer',
          'appearance-none',

          // Focus and interaction states
          'focus:border-menu-active focus:outline-none',
          'focus:ring-menu-active',
          'focus:bg-app-panel-dark focus:text-menu-active',
          'focus:border-menu border-transparent',
          'valid:text-menu-active',
          'group-focus:bg-app-input/60 group-active:text-menu-text group-focus:border-menu-active group-focus:text-menu-text',

          props.headerTitleMode
            ? [
                // Base styles
                'rounded-full',
                'bg-transparent',
                'px-4 py-1',
                'text-menu-text/90',
                'text-xl md:text-2xl lg:text-3xl',

                // Focus states
                'focus:ring-0',
                'group-focus:ring-0',
                'group-active:ring-0',

                'group-hover:bg-app-input/80',
                'group-hover:text-menu-text/80',

                // Placeholder styles
                'placeholder-menu-text',
                'group-focus:placeholder:placeholder-menu-text/50',
                'group-active:placeholder:placeholder-menu-text/50',
                'focus:placeholder-menu-text/50',
                'group-focus:placeholder-menu-text/50',

                // Active states
                'group-focus:text-xl md:group-focus:text-2xl lg:group-focus:text-3xl',
                'group-active:text-xl md:group-active:text-2xl lg:group-active:text-3xl',
                'group-focus:placeholder:text-xl md:group-focus:placeholder:text-2xl lg:group-focus:placeholder:text-3xl',
                'group-active:placeholder:text-xl md:group-active:placeholder:text-2xl lg:group-active:placeholder:text-3xl',

                'placeholder:transition-all placeholder:duration-200',
              ]
            : props.panelTitleMode
              ? [
                  // Base styles
                  'rounded-lg',
                  'bg-transparent',
                  'px-1 py-0.5',
                  'text-menu-text/90',
                  'text-xs md:text-sm',

                  // Focus states
                  'focus:ring-0',
                  'group-focus:ring-0',
                  'group-active:ring-0',

                  'group-hover:bg-app-input/60',
                  'group-hover:text-menu-text/80',

                  // Placeholder styles
                  'placeholder-menu-text',
                  'group-focus:placeholder:placeholder-menu-text/50',
                  'group-active:placeholder:placeholder-menu-text/50',
                  'focus:placeholder-menu-text/50',
                  'group-focus:placeholder-menu-text/50',

                  // Active states
                  'group-focus:text-xs md:group-focus:text-sm',
                  'group-active:text-xs md:group-active:text-sm',
                  'group-focus:placeholder:text-xs md:group-focus:placeholder:text-sm',
                  'group-active:placeholder:text-xs md:group-active:placeholder:text-sm',

                  'placeholder:transition-all placeholder:duration-200',
                ]
              : props.titleMode
                ? [
                    // Base styles
                    'rounded-md',
                    'bg-transparent',
                    'px-2 py-1',
                    'text-menu-text/90',
                    'text-sm',

                    // Placeholder styles
                    'placeholder-menu-text',
                    'focus:placeholder-menu-text/30',
                    'group-focus:placeholder:text-xs',
                    'group-active:placeholder:text-xs',
                    'focus:ring-1',
                  ]
                : props.sidebarFilterMode
                  ? [
                      // Base styles
                      'rounded',
                      'px-2 py-2',
                      'text-menu-text',
                      'text-xs',
                      'focus:ring-1',
                      // Background styles
                      'bg-app-input/70',
                      'focus:bg-app-input/80',
                    ]
                  : [
                      // Default mode
                      'rounded-sm',
                      'px-2 py-1',
                      'text-menu-active-text',
                      'bg-app-input/80',
                      'focus:bg-app-input/60',
                      'focus:ring-menu-active',
                      'focus:ring-1',
                    ],
          props.classNameInput,
        )}
        placeholder={
          props.headerTitleMode || props.panelTitleMode ? placeholder : ' '
        }
        type="search"
        value={value}
        onChange={e => onChangeInner(e)}
        style={
          props.sidebarFilterMode
            ? { minWidth: `${inputWidth}+${4}ch` }
            : props.sidebarFilterMode
              ? null
              : props.headerTitleMode || props.panelTitleMode
                ? { width: `${inputWidth}ch`, minWidth: '8ch' }
                : { minWidth: `${inputWidth}+${4}ch` }
        }
      />
      {value === '' && (
        <Icon.Search
          className={classNames(
            'fill-current',
            'absolute',
            props.headerTitleMode ? 'h-4 w-4 xl:h-5 xl:w-5' : 'h-3 w-3',
            props.headerTitleMode ? 'opacity-60' : 'opacity-30',
            'group-hover:opacity-100',
            'group-hover:text-menu-active',
            'transition duration-100 ease-in-out',
            'ltr:pl-0.5 rtl:pr-0.5',
            'ltr:-ml-1 rtl:-mr-1',
            'text-menu-text',
            'group-active:text-menu-active focus:text-menu-text',
            'group-focus-visible:text-menu-300 group-focus:text-menu-text focus-within:text-menu-text',
            'right-2',
          )}
        />
      )}

      {!props.headerTitleMode && !props.panelTitleMode && (
        <span
          aria-label="label"
          className={classNames(
            'text-menu-text absolute',
            'text-sm',
            'transform duration-300',
            'peer-focus:text-xxs -peer-focus:-left-1 peer-focus:-translate-y-6',
            'peer-active:text-xxs -peer-active:-left-1 peer-active:-translate-y-6',
            value !== ''
              ? 'text-xxs hasValue text-menu-text/60 left-1 -translate-y-6'
              : 'text-menu-active left-1 top-1',
            'peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100',
            'peer-placeholder-shown:truncate',
            props.classNamePlaceholder,
          )}
        >
          {placeholder}
        </span>
      )}

      {/* Hidden for calculating placeholder text width */}
      <span
        className={classNames('invisible absolute whitespace-nowrap')}
        ref={placeholderRef}
      >
        {value || placeholder}
      </span>
    </label>
  );
};
