import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cn } from '../common/utils';
import { ButtonSidebarToggle } from './ButtonSidebarToggle';
import * as Icon from './icons';
import MenuSubscription from './MenuSubscription';
import { UserHeader } from './UserHeader';

export type MenuGlobalProps = {
  className?: string;
  children?: React.ReactNode;
  config: {
    user?: { show: boolean; showTitle: boolean };
    setting?: { show: boolean; showTitle: boolean };
    notifications?: { show: boolean; showTitle: boolean };
    help?: { show: boolean; showTitle: boolean };
    subscription?: { show: boolean; showTitle?: boolean };
  };
};

export const MenuGlobal = ({
  className,
  children,
  config: {
    user = { show: false, showTitle: false },
    setting = { show: false, showTitle: false },
    notifications = { show: false, showTitle: false },
    help = { show: false, showTitle: false },
    subscription = { show: false, showTitle: false },
  },
}: MenuGlobalProps) => {
  const [id] = useState<string>(_.uniqueId());
  const { t } = useTranslation('app');

  return (
    <ul
      data-component="MenuGlobal"
      className={cn(
        'flex items-center',
        'divide-menu-500/50 divide-x',
        className,
      )}
    >
      {children && <li>{children}</li>}

      {subscription.show && <MenuSubscription />}

      {user.show && (
        <li>
          <UserHeader />
        </li>
      )}
      {setting.show && (
        <li>
          <ButtonSidebarToggle
            title={t('Settings')}
            noTitle={!setting.showTitle}
            icon={Icon.Settings}
            sidebarID="sidebar-global-settings"
          />
        </li>
      )}

      {notifications.show && (
        <li>
          <ButtonSidebarToggle
            title={t('Notifications')}
            noTitle={!notifications.showTitle}
            icon={Icon.AlertNotificationOn}
            sidebarID="sidebar-global-notifications"
          />
        </li>
      )}

      {help.show && (
        <li>
          <ButtonSidebarToggle
            title={t('Help')}
            noTitle={!help.showTitle}
            icon={Icon.HelpBot}
            sidebarID="sidebar-global-help"
          />
        </li>
      )}
    </ul>
  );
};
