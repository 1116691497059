import { ImportJobImportType } from '@warebee/frontend/data-access-api-graphql';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import AppHeaderData from '../../components/AppHeaderData';
import { AppHeaderProps } from '../../components/AppHeaderImporter';
import { getDatasetConfigurations } from '../../import/store/import.default';
import { warehouseSelected } from '../../store/warehouse.state';

export type DashboardItemsGroupContainerProps = {
  dataType?: ImportJobImportType;
  document?: any;
  title?: string;
};

const HeaderDataset: React.FC<DashboardItemsGroupContainerProps> = ({
  dataType,
  document,
  title,
}) => {
  useEffect(() => {
    console.log('dataType changed:', dataType);
  }, [dataType]);

  const { t } = useTranslation('dataset');
  const warehouse = useRecoilValue(warehouseSelected);

  // Early return if essential props are missing
  if (!warehouse || !dataType) return null;

  const importConfigurations = getDatasetConfigurations(t);
  const datasetConfig = importConfigurations.find(
    config => config.jobType === dataType,
  );

  const basePath = `/wh/i/${warehouse.id}`;

  const headerProps: AppHeaderProps = {
    warehouseId: warehouse.id,
    breadcrumbItems: [
      { title: warehouse.title, href: `${basePath}` },
      { title: t`Data Dashboard`, href: `${basePath}/data` },
      {
        title: t(`Dataset: {{ datasetType }}`, {
          datasetType: datasetConfig?.title ?? '(...)',
        }),
        href:
          datasetConfig?.getDashboardPath(warehouse.id) ?? `${basePath}/data`,
      },
    ],
  };

  // document-specific breadcrumb
  if (document) {
    // If document is passed, add document-specific breadcrumb
    headerProps.breadcrumbItems.push({
      title: document.title ?? t`Dataset`,
      href: `${basePath}/${dataType}/${document.id ?? ''}`,
    });
  } else {
    // If no document is passed, add a different breadcrumb item
    headerProps.breadcrumbItems.push({
      title: title ?? t`Dataset`,
      //href: `/wh/i/${wh.id}/${datasetType}/general`, // Adjust the href as needed
    });
  }

  return (
    <>
      <AppHeaderData {...headerProps} />
    </>
  );
};

export default HeaderDataset;
