import { useApolloClient } from '@apollo/client';
import {
  LoadOrderSetDashboardDocument,
  LoadOrderSetDashboardQuery,
  LoadOrderSetDashboardQueryVariables,
  StringSearchFilterType,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { useRecoilCallback } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import { warehouseSelectedId } from '../../store/warehouse.state';
import { DASHBOARD_ITEMS_COUNT } from '../store/dashboard.default';
import { geSortByParams } from '../store/dashboard.helper';
import {
  dashboardOrderSetData,
  dashboardOrderSetLoadState,
  dashboardOrderSetState,
} from '../store/dashboard.state';
import { LoadDashboardParams } from '../store/dashboard.types';

function useLoadOrderSetDashboard() {
  const client = useApolloClient();

  const initLoading = useRecoilCallback(({ set }) => async () => {
    set(dashboardOrderSetLoadState, AsyncLoadStatus.Loading);
  });

  const loadDashDataCallback = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: LoadDashboardParams) => {
        const warehouseId = await snapshot.getPromise(warehouseSelectedId);
        const current = await snapshot.getPromise(dashboardOrderSetData);
        const currentState = await snapshot.getPromise(dashboardOrderSetState);

        const response = await client.query<
          LoadOrderSetDashboardQuery,
          LoadOrderSetDashboardQueryVariables
        >({
          query: LoadOrderSetDashboardDocument,
          variables: {
            warehouseId,
            sortBy: geSortByParams(params.sort),
            page: {
              skip: params.skip ?? 0,
              limit: params.limit ?? DASHBOARD_ITEMS_COUNT,
            },
            filter: {
              userTags: !_.isEmpty(currentState.selectedUserTags)
                ? currentState.selectedUserTags
                : undefined,
              title: !_.isEmpty(currentState.titleFilter)
                ? {
                    type: StringSearchFilterType.CONTAINS,
                    value: currentState.titleFilter,
                  }
                : undefined,
            },
          },
        });

        let items = response.data.warehouse.orderSets.content;

        if (params.isAppend) {
          items = [...current, ...items];
        }

        set(dashboardOrderSetData, items);
        set(dashboardOrderSetLoadState, AsyncLoadStatus.Ok);
        set(dashboardOrderSetState, {
          ...currentState,
          loadedCount: items.length,
          totalCount: response.data.warehouse.orderSets.totalCount,
          order: params.sort,
          userTags: response.data.warehouse.orderSets.allUserTags,
        });
      },
  );

  return async (params: LoadDashboardParams) => {
    await initLoading();
    await loadDashDataCallback(params);
  };
}

export default useLoadOrderSetDashboard;
