import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { PanelBody } from '../../components/designer/panels/PanelBody';
import { ImportFileFooterStats } from '../../components/ImportFileFooterStats';
import { InputGroupList } from '../../components/inputs/InputGroupList';
import InputText from '../../components/inputs/InputText';
import InputTextArea from '../../components/inputs/InputTextArea';
import { ContainerCol } from '../../components/layout/ContainerFlex';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import { Spacer } from '../../components/layout/Spacer';
import UserTagsEditor from '../../components/UserTagsEditor';
import { warehouseCanUpdate } from '../../store/warehouse.state';
import useUpdateLayout from '../hooks/useUpdateLayout';
import { layoutDocument } from '../store/layout.state';

const LayoutStartPanel: React.FC = () => {
  const { t } = useTranslation('dataset');
  const [document, setDocument] = useRecoilState(layoutDocument);
  const canUpdate = useRecoilValue(warehouseCanUpdate);
  const updateDocument = useUpdateLayout();

  const changeTitle = (title: string) => {
    setDocument({ ...document, title: title });
    updateDocument({ layoutId: document.id, title: title });
  };
  const changeDescription = (description: string) => {
    setDocument({ ...document, description: description });
    updateDocument({ layoutId: document.id, description: description });
  };

  const updateUserTags = (userTags: string[]) => {
    setDocument({ ...document, userTags });

    updateDocument({
      layoutId: document.id,
      userTags,
    });
  };

  return (
    <PanelBody className="h-full">
      <ContainerCol overflow>
        <ScreenTitle isSticky title={t`Properties`} subtitle={t`Layout`} />
        <InputGroupList hasPadding hasSpacing>
          <InputText
            required
            placeholder={t`Layout Name`}
            label={t`Layout Name`}
            name={t`Layout Name`}
            value={document.title ?? ''}
            onChange={changeTitle}
            isDisabled={!canUpdate}
          />
          <InputTextArea
            optional
            label={t`Layout Description`}
            placeholder={t`About the Layout...`}
            value={document.description ?? ''}
            onChange={changeDescription}
            isDisabled={!canUpdate}
          />
          <UserTagsEditor
            label={t`Tags`}
            tags={document.userTags ?? []}
            onChange={updateUserTags}
          />
        </InputGroupList>

        <Spacer flexspace />
        <ImportFileFooterStats
          className="mb-2 ltr:pl-2 rtl:pr-2"
          updatedDate={document.updatedAt}
          createdDate={document.createdAt}
          dataSource={document.importedFrom?.title ?? ''}
          importSource={document.importedFrom?.inputFileName ?? ''}
        />
      </ContainerCol>
    </PanelBody>
  );
};

export default LayoutStartPanel;
