import { AnalyzeResultProcessType } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { toDateFromLocaleStringDate } from '../common/dateTimeHelper';
import { formatDate, formatInteger } from '../common/formatHelper';
import { EventViewAs } from '../common/types';
import useFormatter from '../common/useFormatter';
import SwitchEvents from '../components/actions/SwitchEvents';
import { ActionBar } from '../components/nav/ActionBar';
import { Stat } from '../components/stats/Stat';
import { StatGroup } from '../components/stats/StatGroup';
import { StatListItem } from '../components/stats/StatListItem';
import AnalyzeEventsDetailsPanel from '../simulation/panels/AnalyzeEventsDetailsPanel';
import {
  getAnalyzeTotals,
  getCarbonValue,
} from '../simulation/store/analyze.helper';
import { eventsContentViewAs } from '../simulation/store/analyze.state';
import { AnalyzeComponentBaseProps } from './analyze.types';

const AnalyzeResultSummary: React.FC<AnalyzeComponentBaseProps> = props => {
  const { analyzeResult } = props;
  const formatter = useFormatter();
  const { t } = useTranslation('simulation');

  if (!analyzeResult) return null;
  const [eventViewAs, setEventViewAs] = useRecoilState(eventsContentViewAs);

  const totals = getAnalyzeTotals(analyzeResult);
  const distance = formatter.formatDistance(totals.distance);
  const duration = formatter.formatTimeSpan(totals.duration);
  const cost = formatter.formatCurrency(totals.cost);
  const totalStoredItems = formatInteger(
    analyzeResult.summary?.assignment?.totalStoredItems,
  );

  const datesArray = _(analyzeResult.picklistPickByDates)
    .compact()
    .map(toDateFromLocaleStringDate)
    .sortedUniq();

  const daysDifference = datesArray.size();
  let dateRangeString = ' ';

  if (daysDifference === 1) {
    dateRangeString = formatDate(datesArray.head());
  }

  if (daysDifference > 1) {
    dateRangeString = `${formatDate(datesArray.head())} - ${formatDate(
      datesArray.last(),
    )}`;
  }

  const pickingDetails = _.find(
    analyzeResult.costDetails?.processes,
    p => p.processType === AnalyzeResultProcessType.PICKING,
  );

  const replenishmentDetails = _.find(
    analyzeResult.costDetails?.processes,
    p => p.processType === AnalyzeResultProcessType.REPLENISHMENT,
  );

  const reassignDetails = _.find(
    analyzeResult.costDetails?.processes,
    p => p.processType === AnalyzeResultProcessType.REASSIGN,
  );

  const assembledOrdersCount = formatInteger(
    analyzeResult.totalAssembledOrders,
  );
  const partiallyAssembledOrdersCount = formatInteger(
    analyzeResult.totalAssembledPartiallyOrders,
  );
  const orderStatus = `Orders Picked: ${assembledOrdersCount} +
          Partial:  ${partiallyAssembledOrdersCount}`;

  const totalPickedOrders =
    analyzeResult.totalAssembledOrders +
    analyzeResult.totalAssembledPartiallyOrders;
  const totalPickedOrdersCount = formatInteger(totalPickedOrders);

  const assembledOrderLinesCount = formatInteger(analyzeResult.assembledLines);
  const partiallyAssembledOrderLinesCount = formatInteger(
    analyzeResult.assembledPartiallyLines,
  );

  const totalAssembledOrderLinesCountRaw =
    analyzeResult.assembledLines + analyzeResult.assembledPartiallyLines;

  const totalAssembledOrderLinesCount = formatInteger(
    totalAssembledOrderLinesCountRaw,
  );

  const pickingDurationRaw = _.find(
    analyzeResult?.costDetails?.processes,
    p => p.processType === AnalyzeResultProcessType.PICKING,
  )?.duration;

  const linesPerHourRaw =
    pickingDurationRaw > 0
      ? (totalAssembledOrderLinesCountRaw * 3600) / pickingDurationRaw
      : 0;
  const linesPerHour = formatInteger(linesPerHourRaw);

  const carbonValue = getCarbonValue(analyzeResult);
  const carbon = formatter.formatCO2(carbonValue);

  const pickingEvents = _.filter(
    analyzeResult.summary?.eventTypes,
    event => event.processType === AnalyzeResultProcessType.PICKING,
  );

  const replenishmentEvents = _.filter(
    analyzeResult.summary?.eventTypes,
    event => event.processType === AnalyzeResultProcessType.REPLENISHMENT,
  );

  const reassignEvents = _.filter(
    analyzeResult.summary?.eventTypes,
    event => event.processType === AnalyzeResultProcessType.REASSIGN,
  );

  return (
    <>
      <StatGroup
        title={t`Picking Process Summary`}
        subTitle={t`Stats`}
        helpNavTo={'simulation/analyse/simulation-analyse'}
      >
        <Stat
          title={t(`Total Picked Orders`)}
          value={totalPickedOrdersCount}
          hasHelper
          isPreview
          titleAlt={orderStatus}
          inPanelMode
          unitOfMeasure={t(`({{orderLineCount}} lines)`, {
            orderLineCount: totalAssembledOrderLinesCount,
          })}
        >
          {daysDifference > 0 && (
            <StatListItem
              // title={t`Total Period`}
              title={dateRangeString}
              value={''}
              unitOfMeasure={t(`{{daysDifference}} Days`, {
                daysDifference: daysDifference,
              })}
            />
          )}
          <StatListItem
            title={t`Total Cost`}
            value={cost.value}
            unitOfMeasure={cost.unit}
            isUnitPrefix={cost.prefixUnit}
          />

          <StatListItem
            title={t`Total Time`}
            unitOfMeasure={duration.unit}
            value={duration.value}
          />
          
          <StatListItem
            title={t`Total Distance`}
            value={distance.value}
            unitOfMeasure={distance.unit}
          />

          {linesPerHourRaw > 0 && (
            <StatListItem
              title={t`Performance`}
              value={linesPerHour}
              unitOfMeasure={t`lines/hour`}
            />
          )}

          {carbonValue > 0 && (
            <StatListItem
              title={t`Total CO2 Footprint`}
              value={carbon.value}
              unitOfMeasure={carbon.unit}
            />
          )}

          {/* <StatListItem title={t`Total Items`} value={totalStoredItems} /> */}
        </Stat>
      </StatGroup>

      <ActionBar
        stickyTop
        className="!bg-app-panel flex items-center p-1 xl:p-4"
      >
        <SwitchEvents
          selected={eventViewAs}
          onChange={v => setEventViewAs(v)}
          availableOptions={[
            ...(pickingDetails ? ['picking' as EventViewAs] : []),
            ...(replenishmentDetails ? ['replenishment' as EventViewAs] : []),
            ...(reassignDetails ? ['reassign' as EventViewAs] : []),
          ]}
        />
      </ActionBar>

      {eventViewAs === 'picking' && pickingDetails && (
        <AnalyzeEventsDetailsPanel
          title={t`Picking by event type`}
          totalTitle={t`Picking`}
          events={pickingEvents}
          eventsTotal={pickingDetails}
          showKpiSwitch
          showTotalStats
          showLegendStats
        />
      )}
      {eventViewAs === 'replenishment' && replenishmentDetails && (
        <AnalyzeEventsDetailsPanel
          title={t`Replenishment by event type`}
          totalTitle={t`Replenishment`}
          events={replenishmentEvents}
          eventsTotal={replenishmentDetails}
          showKpiSwitch
          showTotalStats
          showLegendStats
        />
      )}
      {eventViewAs === 'reassign' && reassignDetails && (
        <AnalyzeEventsDetailsPanel
          title={t`Reassign by event type`}
          totalTitle={t`Reassign`}
          events={reassignEvents}
          eventsTotal={reassignDetails}
          showKpiSwitch
          showTotalStats
          showLegendStats
        />
      )}
    </>
  );
};

export default AnalyzeResultSummary;
