import { DatasetObjectRawDataFieldSummaryFragment } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatInteger } from '../../common/formatHelper';
import { cn } from '../../common/utils';
import InboxZero from '../../components/InboxZero';
import InputCheckbox from '../../components/inputs/InputCheckbox';
import { InputGroupList } from '../../components/inputs/InputGroupList';
import { InputSearch } from '../../components/inputs/InputSearch';
import { InputValue } from '../../components/inputs/InputValue';
import TitleSection from '../../components/layout/TitleSection';
import { StatListItem } from '../../components/stats/StatListItem';
import { DatasetExtraFieldSettings } from '../store/datasetObject.types';

export type DatasetExtraFieldsSettingsEditorProps = {
  sectionId: string;
  sectionTitle: string;
  prefix: string;
  extraFields: DatasetObjectRawDataFieldSummaryFragment[];
  extraFieldsSettings: DatasetExtraFieldSettings[];
  onChange: (extraFieldsSettings: DatasetExtraFieldSettings[]) => void;
};

const DatasetExtraFieldsSettingsEditor: React.FC<
  DatasetExtraFieldsSettingsEditorProps
> = props => {
  const { t } = useTranslation('app');
  const [searchQuery, setSearchQuery] = useState('');
  const extraFieldsSettingsMap = _.keyBy(
    props.extraFieldsSettings,
    s => s.name,
  );
  const hasExtraFields = _.size(props.extraFields) > 0;

  const filteredExtraFields = (props.extraFields ?? []).filter(
    field =>
      field.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      extraFieldsSettingsMap[field.name]?.title
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase()),
  );

  const hasFilteredFields = filteredExtraFields.length > 0;

  function onChange(name: string, patch: Partial<DatasetExtraFieldSettings>) {
    const changedFieldSettings: DatasetExtraFieldSettings = {
      name,
      alias: _.camelCase(`${props.prefix}_${name}`),
      ...extraFieldsSettingsMap[name],
      ...patch,
    };
    const newSettings = _.filter(
      props.extraFieldsSettings,
      s => s.name !== name,
    );
    newSettings.push(changedFieldSettings);

    props.onChange(newSettings);
  }

  return (
    <TitleSection
      id={props.sectionId}
      title={
        hasExtraFields ? (
          <>
            <InputSearch
              placeholder={t`Search fields...`}
              value={searchQuery}
              onChange={value => setSearchQuery(value)}
              panelTitleMode
              classNamePlaceholder="!left-2 !top-2"
              classNameInput="!px-2 !py-1"
            />
          </>
        ) : (
          props.sectionTitle
        )
      }
      inSidebarView
      // collapsible
    >
      {!hasExtraFields && (
        <InboxZero selfCenter message={t`No Extra fields found`} />
      )}
      {hasExtraFields && !hasFilteredFields && (
        <InboxZero selfCenter message={t`No matching fields found`} />
      )}
      {hasExtraFields && hasFilteredFields && (
        <InputGroupList className="space-y-2 px-1">
          {_.map(filteredExtraFields, f => {
            const settings = extraFieldsSettingsMap[f.name];

            return (
              <InputCheckbox
                key={`${props.sectionId}-${f.name}`}
                isSelected={settings?.enabled}
                onChange={v => onChange(f.name, { enabled: v })}
                className={cn(
                  'p-1',
                  'rounded',
                  settings?.enabled
                    ? 'bg-menu/75 border-menu-active text-menu-active-text border shadow-xl'
                    : 'bg-menu/50',
                )}
              >
                <div className="space-y-1">
                  <InputValue
                    title={t`Field Name:`}
                    labelVertical
                    value={settings?.title ?? f.name}
                    onChange={v => onChange(f.name, { title: v })}
                    onEndEdit={v => onChange(f.name, { title: v })}
                    isDebouncedChange={true}
                    className="my-3 rounded"
                  />
                  <StatListItem
                    title={t`Raw Column:`}
                    value={f.name}
                    titleClass={'text-xxs'}
                    valueClass={'text-xxs'}
                    // styleInput
                  />
                  <StatListItem
                    title={t`Data Type(s):`}
                    value={_.join(f.type, ', ')}
                    titleClass={'text-xxs'}
                    valueClass={'text-xxs'}
                    // styleInput
                  />
                  <StatListItem
                    title={t`Data Stats`}
                    value={formatInteger(f.distinctValueCount)}
                    titleClass={'text-xxs'}
                    valueClass={'text-xxs'}
                    // styleInput
                  />
                  {/* <div>
                  <span>
                    {f.name}[{f.type}] ({f.distinctValueCount})
                  </span>
                </div> */}
                </div>
              </InputCheckbox>
            );
          })}
        </InputGroupList>
      )}
    </TitleSection>
  );
};

export default DatasetExtraFieldsSettingsEditor;
