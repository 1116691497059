import classNames from 'classnames';
import React, { Suspense, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { AsyncLoadStatus } from '../common/types';
import ErrorIndicator from '../components/ErrorIndicator';
import LoadingIndicator from '../components/LoadingIndicator';
import { PanelHeader } from '../components/designer/panels/PanelHeader';
import { Container } from '../components/layout/ContainerFlex';
import { ContainerMain } from '../components/layout/ContainerMain';
import { sidebarStateByType } from '../store/sidebar.state';
import LayoutDataView from './LayoutDataView';
import LayoutHeader from './LayoutHeader';
import { useLoadLayout } from './hooks/useLoadLayout';
import LayoutCommonInfoSidebar from './sidebar/LayoutCommonInfoSidebar';
import LayoutLocationSidebar from './sidebar/LayoutLocationSidebar';
import LayoutMainSidebar from './sidebar/LayoutMainSidebar';
import LayoutMenuSidebar from './sidebar/LayoutMenuSidebar';
import {
  layoutDocumentLoadStatus,
  layoutSelectedMenuItemId,
} from './store/layout.state';
import ViewerLayout from './viewer/ViewerLayout';

export type LayoutRootParams = {
  layoutId: string;
};
export const LayoutRoot: React.FC = () => {
  const { layoutId } = useParams<LayoutRootParams>();
  const { t } = useTranslation('dataset');
  const documentLoadStatus = useRecoilValue(layoutDocumentLoadStatus);
  const activeMenuId = useRecoilValue(layoutSelectedMenuItemId(layoutId));
  const [loadLayout, cleanupLayout] = useLoadLayout();
  const setSidebarState = useSetRecoilState(
    sidebarStateByType('sidebar-layout-main'),
  );

  useEffect(() => {
    setSidebarState({ isCollapsed: false });
    loadLayout(layoutId);
    return () => {
      cleanupLayout();
    };
  }, [layoutId]);

  return (
    <>
      <LayoutHeader />

      <ContainerMain className="designer-container designer-mode-select flex">
        {documentLoadStatus === AsyncLoadStatus.Ok ? (
          <>
            <div
              data-component="sidebar-left-container"
              className="sidebar-wrapper side-left flex"
            >
              <LayoutMenuSidebar />
              <LayoutMainSidebar />
            </div>
            <ErrorBoundary
              fallbackRender={({ error, resetErrorBoundary }) => (
                <ErrorIndicator
                  selfCenter
                  message={error.message}
                  hasReloadButton
                  exception={error}
                />
              )}
            >
              <Suspense
                fallback={
                  <Container hasOverflowY flex1 col>
                    <PanelHeader isLoading title={t`Loading Layout...`} />
                    <LoadingIndicator
                      className={classNames(
                        'bg-map-area-striped',
                        'border-app-background border-8',
                      )}
                      selfCenter
                      message={t`Loading Layout`}
                    />
                  </Container>
                }
              >
                {activeMenuId === 'layout-menu-start' ? (
                  <>
                    <ViewerLayout />
                    <div
                      data-component="sidebar-right-container"
                      className="sidebar-wrapper side-right flex"
                    >
                      <LayoutLocationSidebar />
                      <LayoutCommonInfoSidebar />
                    </div>
                  </>
                ) : (
                  <LayoutDataView />
                )}
              </Suspense>
            </ErrorBoundary>
          </>
        ) : (
          <LoadingIndicator selfCenter message={t`Loading Layout...`} />
        )}
      </ContainerMain>
    </>
  );
};

export default LayoutRoot;
