import { VolumeComplianceDataRow } from '@warebee/shared/export-converter';
import { useTranslation } from 'react-i18next';
import useFormatter from '../common/useFormatter';
import ButtonLocateLocation from '../components/ButtonLocateLocation';
import { ColumnConfig } from '../components/DatasetTable';
import FormattedValue from '../components/FormattedValue';
import { getAssignmentOccupancyStatusMap } from '../simulation/store/occupancy/assignmentOccupancy.helper';
import ItemTag from '../simulation/tags/ItemTag';
import UomTag from '../simulation/tags/UomTag';
import AssignmentOccupancyTag from './AssignmentOccupancyTag';

const useAssignmentOccupancyTableConfig = () => {
  const { t } = useTranslation('simulation');
  const formatter = useFormatter();

  const statusMap = {
    '': t`All`,
    ...getAssignmentOccupancyStatusMap(t),
  };

  const columnsConfig: ColumnConfig<VolumeComplianceDataRow>[] = [
    {
      field: 'occupancy',
      title: t`Status`,
      hasSort: true,
      isHeader: true,
      render: (v: number) => <AssignmentOccupancyTag occupancy={v} />,
      hasFilter: true,
      filterOptions: statusMap,
    },
    {
      field: 'locationId',
      title: t`Location`,
      hasFilter: true,
      isHeader: true,
      hasSort: true,
      render: (locationId: string, row) => (
        <ButtonLocateLocation locationId={locationId} />
      ),
    },
    {
      field: 'sku',
      title: t`Item (SKU)`,
      render: (sku: string, row) => (
        <ItemTag
          title={row.sku}
          filters={{ consignee: row.consignee, sku: row.sku }}
        />
      ),
    },
    {
      field: 'stockUomQuantity',
      title: t`Stock Qty`,
      render: (_, row) => (
        <UomTag
          uom={row?.stockUom?.uom ?? '—'}
          value={row?.stockUomQuantity ?? null}
        />
      ),
    },
    {
      field: 'stockUomCapacity.uomQuantity',
      title: t`Capacity (Stock)`,
      render: (qty: number, row) => (
        <UomTag uom={row?.stockUom?.uom ?? '—'} value={qty ?? null} />
      ),
    },
    {
      field: 'maxUomCapacity.uomQuantity',
      title: t`Capacity (Max UOM)`,
      render: (qty: number, row) => (
        <UomTag
          uom={row?.maxUomCapacity?.uom?.uom ?? '—'}
          value={qty ?? null}
        />
      ),
    },
    {
      field: 'stockUom.maxStack',
      title: t`Max Stackability`,
      hasFilter: true,
    },

    {
      field: 'locationVolume',
      title: t`Location Volume`,
      render: (v: number) => (
        <FormattedValue value={formatter.formatVolume(v)} />
      ),
    },
    {
      field: 'occupiedVolume',
      title: t`Occupied Volume`,
      render: (v: number) => (
        <FormattedValue value={formatter.formatVolume(v)} />
      ),
    },
    {
      field: 'stockUomCapacity.occupiedVolume',
      title: t`Capacity Volume`,
      render: (v: number) => (
        <FormattedValue value={formatter.formatVolume(v)} />
      ),
    },
    {
      field: 'stockUomCapacity.occupiedVolume',
      title: t`Capacity Volume (Share)`,
      render: (v: number, row) => (
        <AssignmentOccupancyTag occupancy={v / row.locationVolume} />
      ),
    },
    {
      field: 'occupancyRate',
      title: t`Occupancy Rate`,
      render: (v: number, row) => <AssignmentOccupancyTag occupancy={v} />,
    },
    {
      field: 'utilizationRate',
      title: t`Utilisation Rate`,
      render: (v: number, row) => <AssignmentOccupancyTag occupancy={v} />,
    },
    {
      field: 'maxUomCapacity.occupiedVolume',
      title: t`Capacity Volume (Max UOM)`,
      render: (v: number) => (
        <FormattedValue value={formatter.formatVolume(v)} />
      ),
    },
    {
      field: 'maxUomCapacity.occupiedVolume',
      title: t`Capacity Volume Share (Max UOM)`,
      render: (v: number, row) => (
        <AssignmentOccupancyTag occupancy={v / row.locationVolume} />
      ),
    },

    {
      field: 'locationPlaneTitle',
      title: t`Floor`,
      hasFilter: true,
    },
    {
      field: 'locationAisleTitle',
      title: t`Aisle`,
      hasFilter: true,
    },
    {
      field: 'locationBayTitle',
      title: t`Bay`,
      hasFilter: true,
    },
    {
      field: 'locationLevel',
      title: t`Level`,
      hasFilter: true,
    },
    {
      field: 'locationRackingType',
      title: t`Location Type`,
      hasFilter: true,
    },
  ];

  return columnsConfig;
};

export default useAssignmentOccupancyTableConfig;
