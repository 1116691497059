import classNames from 'classnames';
import _ from 'lodash';
import { Suspense, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { BrowserRouter, useSearchParams } from 'react-router-dom';
import { RecoilRoot, useRecoilValue, useSetRecoilState } from 'recoil';
import AnalyticTracker from './AnalyticTracker';
import AppRoutes from './AppRoutes';
import AuthManager from './AuthManager';
import ErrorFallback from './ErrorFallback';
import ErrorWatcher from './ErrorWatcher';
import NotificationWatcher from './NotificationWatcher';
import HubspotChat from './components/HubspotChat';
import LoadingAppWindow from './components/LoadingWindow';
import { ContainerApp } from './components/layout/ContainerApp';
import i18n from './i18n';
import RoleParamWatcher from './permissions/RoleParamWatcher';
import {
  appBrandSelectedId,
  appCoBrandSelectedId,
  appIsEmbeddedState,
  appThemeSelectedId,
  applyThemePreference,
  themeIds,
} from './store/global.state';
import {
  applyWarehouseRoutePreference,
  routeIds,
  warehouseDefaultRoute,
} from './store/warehouse.state';

const ThemeParamWatcher = () => {
  const [searchParams] = useSearchParams();
  const themeId = useRecoilValue(appThemeSelectedId);
  const setThemeId = useSetRecoilState(appThemeSelectedId);

  // Debug all URL parameters
  useEffect(() => {
    console.debug(
      '[Params] Current URL parameters:',
      Object.fromEntries(searchParams),
    );
  }, [searchParams]);

  // Brand handling
  const brandParam = searchParams.get('brand');
  const setBrandState = useSetRecoilState(appBrandSelectedId);

  // Cobrand handling
  const cobrandParam = searchParams.get('cobrand');
  const setCoBrandState = useSetRecoilState(appCoBrandSelectedId);

  // Embedded handling
  const embeddedParam = searchParams.get('isEmbedded');
  const setEmbedded = useSetRecoilState(appIsEmbeddedState);
  const isAppEmbedded = useRecoilValue(appIsEmbeddedState);

  // Theme handling
  const themeParam = searchParams.get('theme');

  // Handle brand parameter
  useEffect(() => {
    if (brandParam) {
      console.debug('[Params] Setting brand:', brandParam);
      setBrandState(brandParam);
    }
  }, [brandParam, setBrandState]);

  // Handle cobrand parameter
  useEffect(() => {
    if (cobrandParam) {
      console.debug('[Params] Setting cobrand:', cobrandParam);
      setCoBrandState(cobrandParam);
    }
  }, [cobrandParam, setCoBrandState]);

  // Handle embedded parameter
  useEffect(() => {
    if (embeddedParam !== null) {
      console.debug('[Params] Setting embedded:', embeddedParam === 'true');
      setEmbedded(embeddedParam === 'true');
    }
  }, [embeddedParam, setEmbedded]);

  // Handle theme parameter and changes
  useEffect(() => {
    if (themeParam && _.includes(themeIds, themeParam)) {
      console.debug('[Params] Setting theme from URL param:', themeParam);
      setThemeId(themeParam);
    }
  }, [themeParam, setThemeId]);

  useEffect(() => {
    if (themeId) {
      console.debug('[Params] Applying theme preference:', themeId);
      applyThemePreference(themeId);
    }
  }, [themeId]);

  return null;
};

const RouteParamWatcher = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const routeId = useRecoilValue(warehouseDefaultRoute);
  const routeParam = searchParams.get('route');

  useEffect(() => {
    applyWarehouseRoutePreference(routeId);
  }, [routeId]);

  useEffect(() => {
    if (_.includes(routeIds, routeParam)) {
      applyWarehouseRoutePreference(routeParam);
    }
  }, [routeParam]);

  return null;
};

const AppContent = () => {
  const { t } = useTranslation('app');

  return (
    // <RecoilRoot>
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <RoleParamWatcher />
        <RouteParamWatcher />
        <ThemeParamWatcher />
        <Suspense fallback={<LoadingAppWindow />}>
          <ErrorWatcher />
          <ErrorBoundary
            fallbackRender={({ error, resetErrorBoundary }) => (
              <ErrorFallback error={error} errorOrigin="unhandledException" />
            )}
          >
            <AuthManager
              errorFallback={<ErrorFallback errorOrigin="notConnected" />}
            >
              <NotificationWatcher />
              <HubspotChat />
              <AnalyticTracker />

              <ContainerApp
                aria-label="ContainerApp"
                className={classNames('bg-app-background h-full')}
              >
                <AppRoutes />
              </ContainerApp>
            </AuthManager>
          </ErrorBoundary>
        </Suspense>
      </BrowserRouter>
    </I18nextProvider>
  );
};

export default function App() {
  return (
    <RecoilRoot>
      <AppContent />
    </RecoilRoot>
  );
}
