import {
  Action,
  MeasurementSystem,
  useCreateWarehouseMutation,
  useRemoveWarehouseMutation,
  useUpdateWarehouseMutation,
} from '@warebee/frontend/data-access-api-graphql';
import { CreateWarehouseInput } from '@warebee/shared/data-access-api-dto';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import useLoadWarehouseBillingState from '../../appBilling/hooks/useLoadWarehouseBillingState';
import { WAREHOUSE_NEW_ID } from '../../common/constants';
import { AsyncLoadStatus } from '../../common/types';
import { cn } from '../../common/utils';
import { Button } from '../../components/actions/Button';
import ModalDialog from '../../components/actions/ModalDialog';
import * as Icon from '../../components/icons';
import { Container } from '../../components/layout/ContainerFlex';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import { Spacer } from '../../components/layout/Spacer';
import TitleSection from '../../components/layout/TitleSection';
import LoadingIndicator from '../../components/LoadingIndicator';
import { ActionBar } from '../../components/nav/ActionBar';
import { NoMatch404 } from '../../NoMatch404';
import {
  warehouseList,
  warehouseListLoadStatus,
} from '../../store/warehouse.state';

export type WarehouseSettingsParams = {
  warehouseId: string;
};

const WarehouseSettingsDelete: React.FC = () => {
  const { t: tCurrency } = useTranslation('currency');
  const { t } = useTranslation('app');

  const { warehouseId = WAREHOUSE_NEW_ID } =
    useParams<WarehouseSettingsParams>();
  const [whList, setWhList] = useRecoilState(warehouseList);
  const whListStatus = useRecoilValue(warehouseListLoadStatus);
  const [createWarehouse, { loading: loadingCreate }] =
    useCreateWarehouseMutation();
  const [updateWarehouse, { loading: loadingUpdate }] =
    useUpdateWarehouseMutation();
  const [removeWarehouse, { loading: loadingRemove }] =
    useRemoveWarehouseMutation();
  const loadWhBillingState = useLoadWarehouseBillingState();

  const navigate = useNavigate();
  const defaultInputData: CreateWarehouseInput = {
    title: '',
    address: '',
    companyName: '',
    description: '',
    measurementSystem: MeasurementSystem.METRIC,
    country: 'GB',
    currency: 'GBP',
  };
  const isNewWarehouseSetup =
    warehouseId?.toLowerCase() === WAREHOUSE_NEW_ID.toLowerCase();
  const wh = _.find(whList, wh => wh.id === warehouseId);
  const [inputData, setInputData] = useState<CreateWarehouseInput>(
    isNewWarehouseSetup ? defaultInputData : wh,
  );
  const [errors, setErrors] = useState({
    title: null,
  });
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    const wh = _.find(whList, wh => wh.id === warehouseId);
    if (!_.isNil(wh)) {
      setInputData(_.cloneDeep(wh));
    }
  }, [whList]);

  const canUpdate = _.includes(wh?.permissions?.allowed, Action.UPDATE);
  const canDelete = _.includes(wh?.permissions?.allowed, Action.DELETE);

  console.log('wh?.permissions', wh?.permissions);

  if (!isNewWarehouseSetup && _.isNil(wh)) {
    return <NoMatch404 title={t`Warehouse not found`} />;
  }

  async function callDelete() {
    try {
      const response = await removeWarehouse({
        variables: {
          id: warehouseId,
        },
      });

      if (response.errors) {
        // response.errors.forEach((e: any) => {
        //   if (
        //     e.extensions &&
        //     e.extensions.code === 'BAD_USER_INPUT' &&
        //     e.extensions.errors
        //   ) {
        //     setFieldErrors(e.extensions.errors as ValidationError[]);
        //   }
        // });
      } else {
        setWhList(_.filter(whList, w => w.id !== wh.id));
        navigate(`/`);
      }
    } catch (ex) {
      console.log(ex);
      throw ex;
    }
  }

  return (
    <>
      <ScreenTitle
        hasPadding
        isHero
        title={t`Warehouse Extra Settings`}
        subtitle={inputData?.title ?? t`Settings`}
        isSticky
      />
      {inputData && whListStatus === AsyncLoadStatus.Ok ? (
        <Container col hasOverflowY>
          <>
            <TitleSection inPanelView title={t`Delete Warehouse`} />
            <div
              className={cn(
                'm-3 mb-4 mt-8',
                'flex flex-col space-y-4 xl:flex-row',
                'bg-menu/50 backdrop-saturate-110 backdrop-blur-lg backdrop-filter',
                'p-4 xl:p-10',
              )}
            >
              <div className="text-menu-active flex-1">
                {canDelete
                  ? t`Delete warehouse and all it's data. This action cannot be undone!`
                  : t`You don't have permission to delete this warehouse. Please contact your administrator if you need this access.`}
              </div>
            </div>
          </>

          <ActionBar sticky stickyBottom>
            <Spacer flexspace />
            <Button
              className="self-end"
              label={
                loadingCreate ? t`Deleting Warehouse...` : t`Delete Warehouse`
              }
              buttonType="delete"
              onPress={() => setIsDialogOpen(true)}
              isDisabled={!canDelete || loadingRemove || loadingUpdate}
              isLoading={loadingRemove || loadingUpdate}
              hasIconAfter={<Icon.CircleX className={`h-5 w-5 fill-current`} />}
            />
          </ActionBar>
        </Container>
      ) : (
        <LoadingIndicator />
      )}

      <ModalDialog
        title={t(
          `Are you sure you want to delete warehouse {{whTitle}} and all it's data?
          This action cannot be undone!`,
          { whTitle: inputData?.title },
        )}
        okButtonTitle={t`Delete Warehouse`}
        okButtonType={'delete'}
        isOpen={isDialogOpen}
        setIsOpen={setIsDialogOpen}
        hasCancelButton
        onConfirm={callDelete}
      />
    </>
  );
};

export default WarehouseSettingsDelete;
