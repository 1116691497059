import { KyselyFilterDescSet } from '@warebee/shared/athena-db-model';
import _ from 'lodash';
import { FilterFieldConfigBase } from '../../common/types';
import { getFilterDescriptorDataTypeByConfig } from '../../policyFilters/policyFilter.helper';

// export function getActualityFilterDescSet(): KyselyFilterDescSet<
//   ActualityHqDataColumn,
//   any,
//   any
// > {
//   return _.transform(
//     actualityHqFieldTypesBase,
//     (acc, type, filterKey) => {
//       acc[filterKey] = [
//         getFilterDescriptorDataType(type),
//         eb => eb.ref(filterKey),
//       ];
//     },
//     {} as KyselyFilterDescSet<ActualityHqDataColumn, any, any>,
//   );
// }

export function getActualityFilterDescSet2<T extends string>(
  filterConfig: FilterFieldConfigBase<T>[],
): KyselyFilterDescSet<T, any, any> {
  return _.transform(
    filterConfig,
    (acc, cfg) => {
      acc[cfg.type] = [
        getFilterDescriptorDataTypeByConfig(cfg),
        eb => eb.ref(cfg.type),
      ];
    },
    {} as KyselyFilterDescSet<T, any, any>,
  );
}
