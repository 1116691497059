import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { warehouseIsDemo, warehouseSelected } from '../store/warehouse.state';
import { WarehouseExtraSettings } from '../store/warehouse.types';
import AppHeaderMenuItem from './AppHeaderMenuItem';
import { ButtonMenuWarehouses } from './ButtonMenuWarehouses';
import { ButtonSidebarToggle } from './ButtonSidebarToggle';
import { LabelModes } from './LabelModes';
import * as Icon from './icons';

const WarehouseTitleLabelDemo = ({ warehouseId }: AppHeaderMenuProps) => {
  const { t } = useTranslation('app');
  const isDemo = useRecoilValue(warehouseIsDemo);

  return (
    <div
      data-component="WarehouseTitleLabelDemo"
      className={classNames('flex flex-1 flex-col', 'font-title', 'mb-0.5')}
    >
      <div className={classNames('flex items-center truncate text-xs')}>
        {isDemo ? (
          <LabelModes title={t`Warehouse Demo`} type="DEMO" />
        ) : (
          <span className="">{t`Warehouse`}</span>
        )}
      </div>
    </div>
  );
};

const WarehouseTitle = ({ warehouseId }: AppHeaderMenuProps) => {
  const warehouse = useRecoilValue(warehouseSelected);

  return (
    <div
      data-component="WarehouseTitle"
      className={classNames(
        'text-xl xl:text-3xl',
        'truncate',
        'max-w-ch-30',
        'w-full',
      )}
    >
      {warehouse?.title}
    </div>
  );
};

export type AppHeaderMenuProps = {
  warehouseId?: string;
  children?: React.ReactNode;
  active?: boolean;
  isDemo?: boolean;
  isPurchasing?: boolean;
  canSwitchWarehouse?: boolean;
  showIfActive?: boolean;
};

// Add a type definition for menu items
type MenuItem = {
  id: string;
  title: string;
  subtitle: string;
  to: string;
  default: boolean;
  beta?: boolean;
  isDemo?: boolean;
  disabled?: boolean;
  order: number;
  isExact: boolean;
  collapsed?: string;
  showIfActive?: boolean;
  isPurchasing?: boolean;
  widthCustom?: string;
};

export const AppHeaderMenu = ({
  warehouseId,
  isPurchasing,
  canSwitchWarehouse,
}: AppHeaderMenuProps) => {
  const { t } = useTranslation('app');
  const wh = useRecoilValue(warehouseSelected);
  const isDemo = useRecoilValue(warehouseIsDemo);
  const location = useLocation();

  const hasActivityFeed = (wh?.extraSettings as WarehouseExtraSettings)
    ?.liveFeed?.enabled;

  const [id] = useState<string>(_.uniqueId());
  const basePath = `/wh/i/${warehouseId}`;

  const menuItems: MenuItem[] = [
    {
      id: 'dashboards',
      title: t`Dashboards`,
      subtitle: t`Warehouse`,
      to: `${basePath}/dashboards`,
      default: true,
      isDemo: isDemo,
      beta: true,
      disabled: true,
      order: 2,
      isExact: true,
    },

    {
      id: 'schedule',
      title: t`Schedule`,
      subtitle: t`Warehouse`,
      to: `${basePath}/schedule`,
      default: true,
      isDemo: isDemo,
      beta: true,
      disabled: true,
      order: 2,
      isExact: true,
    },

    hasActivityFeed && {
      id: 'activity',
      title: t`Activity`,
      titleShort: t`Activity`,
      subtitle: t`Warehouse`,
      to: `${basePath}/feed/`,
      default: true,
      isDemo: isDemo,
      beta: false,
      disabled: false,
      order: 1,
      isExact: true,
    },
    {
      id: 'simulations',
      title: t`Simulations`,
      subtitle: t`Digital Twin`,
      to: `${basePath}/simulations`,
      default: true,
      isDemo: isDemo,
      beta: false,
      disabled: false,
      order: 2,
      isExact: true,
    },
    {
      id: 'data',
      title: t`Data`,
      subtitle: t`Warehouse`,
      to: `${basePath}/data`,
      default: false,
      beta: false,
      isDemo: isDemo,
      disabled: false,
      order: 1,
      isExact: false,
    },
    {
      id: 'designer',
      title: t`Designer`,
      subtitle: t`Warehouse`,
      to: `${basePath}/designer`,
      default: false,
      beta: false,
      isDemo: isDemo,
      disabled: false,
      order: 3,
      isExact: false,
    },

    {
      id: 'billingStatus',
      title: t`Billing Status`,
      subtitle: t`Warehouse Settings`,
      to: `${basePath}/settings`,
      default: false,
      beta: false,
      disabled: false,
      collapsed: 'sm',
      order: 1,
      isExact: false,
    },
    {
      id: 'billing',
      title: t`Billing`,
      subtitle: t`Revenue Assurance`,
      to: `${basePath}/billing`,
      default: false,
      beta: true,
      disabled: false,
      collapsed: 'sm',
      order: 4,
      isExact: true,
    },
    {
      id: 'contracts',
      title: t`Contracts`,
      subtitle: t`Audit`,
      to: `${basePath}/contracts`,
      default: false,
      beta: true,
      disabled: true,
      collapsed: 'sm',
      order: 5,
      isExact: true,
    },
    {
      id: 'marketplace',
      title: t`Marketplace`,
      subtitle: t`Warehouse`,
      to: `${basePath}/marketplace`,
      default: false,
      beta: true,
      disabled: true,
      collapsed: 'sm',
      order: 6,
      isExact: true,
    },
    {
      id: 'sales',
      title: t`Sales`,
      subtitle: t`AI Assistant`,
      to: `${basePath}/sales`,
      default: false,
      beta: true,
      disabled: true,
      collapsed: 'sm',
      order: 7,
      isExact: true,
    },
    {
      id: 'plansPricing',
      title: t`Plans & Pricing`,
      subtitle: t`Warehouse Settings`,
      to: `${basePath}/pricing`,
      default: false,
      beta: false,
      disabled: false,
      collapsed: 'sm',
      order: 1,
      isExact: false,
      showIfActive: true,
      isPurchasing: true,
      widthCustom: 'max-w-10 min-w-10',
    },
    // {
    //   title: t`More...`,
    //   subtitle: t`Try`,
    //   to: `${basePath}/beta`,
    //   default: false,
    //   disabled: false,
    //   beta: true,
    //   order: 4,
    //   isExact: true,
    //   widthCustom: 'max-w-10 min-w-10',
    // },
  ].filter(Boolean);

  const queryMenuItem: MenuItem = {
    id: 'query',
    title: t`Query`,
    subtitle: t`Warehouse Data`,
    to: `${basePath}/query`,
    default: false,
    beta: true,
    isDemo: isDemo,
    disabled: false,
    order: 1,
    isExact: true,
  };

  // Define route configurations
  const routeConfigs = {
    query: {
      pattern: '/query',
      visibleMenuIds: ['query', 'data'],
    },
    // Add more route configurations as needed
  };

  const getFilteredMenuItems = () => {
    // Find matching route config
    const matchingRoute = Object.values(routeConfigs).find(config =>
      location.pathname.includes(config.pattern),
    );

    if (matchingRoute) {
      const allMenuItems = [...menuItems, queryMenuItem];
      return matchingRoute.visibleMenuIds
        .map(id => allMenuItems.find(item => item.id === id))
        .filter(Boolean);
    }

    return menuItems;
  };

  return (
    <div
      data-component="AppHeaderMenuWrapper"
      className={classNames(
        'flex items-center',
        'w-full',
        'border-menu-active border-b',
        'bg-app-header',
      )}
    >
      <header
        data-component="AppHeaderMenu"
        id={id}
        className={classNames(
          'flex items-center',
          'flex-1',
          'w-full',
          'p-0',
          'm-0',
        )}
      >
        <nav
          className={classNames(
            'flex flex-1 items-end',
            'ltr:justify-start rtl:justify-end',
            'w-full truncate',
          )}
        >
          <ButtonMenuWarehouses
            className={classNames(
              'flex flex-1 md:flex-none',
              'w-full truncate',
            )}
            collapsible={canSwitchWarehouse || !isPurchasing}
          >
            <div
              className={classNames(
                'flex flex-1 flex-col items-start',
                'truncate',
                'px-2 py-2',
              )}
            >
              <WarehouseTitleLabelDemo />
              <WarehouseTitle />
            </div>
          </ButtonMenuWarehouses>

          <div
            data-component="AppHeaderMenu"
            className={classNames(
              'hidden flex-1 md:flex ltr:justify-end rtl:justify-start',
            )}
          >
            {isPurchasing ? (
              <>
                <AppHeaderMenuItem
                  key={`header-item-purchases-purchase`}
                  exactActive={true}
                  title={t`Purchase Plan`}
                  subtitle={t`Warehouse Setup`}
                  to={``}
                  active={true}
                />
                <AppHeaderMenuItem
                  key={`header-item-purchases-cancel`}
                  exactActive={true}
                  title={t`Cancel Purchase`}
                  // subtitle={`Warehouse Setup`}
                  to={`${basePath}`}
                  icon={
                    <Icon.CircleX
                      className={classNames(
                        'fill-current',
                        'h-7 w-7',
                        'ltr:mr-2 rtl:ml-2',
                      )}
                    />
                  }
                  active={true}
                />
              </>
            ) : (
              <>
                {getFilteredMenuItems().map(item => (
                  <AppHeaderMenuItem
                    key={`header-item-${item.title}`}
                    exactActive={item.isExact}
                    isInBeta={item.beta}
                    isDemo={item.isDemo}
                    title={item.title}
                    subtitle={item.subtitle}
                    to={item.to}
                    hidden={
                      item.disabled ||
                      item.collapsed === 'sm' ||
                      item.showIfActive
                    }
                    active={item.default}
                    widthCustom={item.widthCustom}
                  />
                ))}
              </>
            )}
          </div>
          {!isPurchasing && (
            <div
              className={classNames(
                'border-app-panel/20 ltr:border-l rtl:border-r',
                'min-w-20',
              )}
            >
              <ButtonSidebarToggle
                title={t`Menu`}
                noTitle
                icon={Icon.MenuBars3Bars}
                buttonSize={'menu'}
                sidebarID="sidebar-global-menu"
              />
            </div>
          )}
        </nav>
      </header>
    </div>
  );
};
