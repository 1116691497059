import {
  AssignmentDashboardItemFragment,
  DatasetObjectFragment,
  ItemSetDashboardItemFragment,
  LayoutDashboardItemFragment,
  LayoutImportDashboardItemFragment,
  OrderSetDashboardItemFragment,
  SimulationDashboardItemFragment,
} from '@warebee/frontend/data-access-api-graphql';
import { atom, selector, selectorFamily } from 'recoil';
import { persistAtom } from '../../common/recoil/persistAtom';
import { AsyncLoadStatus } from '../../common/types';
import { warehouseSelectedId } from '../../store/warehouse.state';
import { getDefaultDashboardState } from './dashboard.default';
import { DashboardGroupId, DashboardGroupState } from './dashboard.types';

const getKey = (postfix: string) => `warebee-dashboard-${postfix}`;

type WhId = string;

const dashboardStatesAllAtom = persistAtom<
  Record<WhId, Record<DashboardGroupId, DashboardGroupState>>
>({
  key: getKey('dashboard-states-all-atom'),
  default: {},
});

export const dashboardStateById = selectorFamily<
  DashboardGroupState,
  DashboardGroupId
>({
  key: getKey('state-by-id'),
  get:
    (id: DashboardGroupId) =>
    ({ get }) => {
      const whId = get(warehouseSelectedId);
      return (
        get(dashboardStatesAllAtom)?.[whId]?.[id] ?? getDefaultDashboardState()
      );
    },
  set:
    (id: DashboardGroupId) =>
    ({ get, set }, value: DashboardGroupState) => {
      const whId = get(warehouseSelectedId);
      set(dashboardStatesAllAtom, current => ({
        ...current,
        [whId]: {
          ...current[whId],
          [id]: value,
        },
      }));
    },
});

export const dashboardSimulationState = selector<DashboardGroupState>({
  key: getKey('sim-state'),
  get: ({ get }) => get(dashboardStateById('simulations')),
  set: ({ set }, value: DashboardGroupState) =>
    set(dashboardStateById('simulations'), value),
});

export const dashboardSimulationData = atom<SimulationDashboardItemFragment[]>({
  key: getKey('sim-data'),
  default: null,
});

export const dashboardSimulationLoadState = atom<AsyncLoadStatus>({
  key: getKey('sim-load-status'),
  default: AsyncLoadStatus.None,
});

export const showHelperCardState = persistAtom({
  key: 'showHelperCardState',
  default: false,
});

export const showHeaderAddButtonState = persistAtom({
  key: 'showHeaderAddButton',
  default: false,
});

export const showAutoUpdateDateTimeState = persistAtom({
  key: 'showAutoUpdateDateTime',
  default: false,
});

export const dashboardLayoutState = selector<DashboardGroupState>({
  key: getKey('layout-state'),
  get: ({ get }) => get(dashboardStateById('assignments')),
  set: ({ set }, value: DashboardGroupState) =>
    set(dashboardStateById('assignments'), value),
});

//  Layout
export const dashboardLayoutData = atom<
  (LayoutDashboardItemFragment | LayoutImportDashboardItemFragment)[]
>({
  key: getKey('layout-data'),
  default: null,
});

export const dashboardLayoutLoadState = atom<AsyncLoadStatus>({
  key: getKey('layout-load-status'),
  default: AsyncLoadStatus.None,
});

//Assignment

export const dashboardAssignmentState = selector<DashboardGroupState>({
  key: getKey('assignment-state'),
  get: ({ get }) => get(dashboardStateById('assignments')),
  set: ({ set }, value: DashboardGroupState) =>
    set(dashboardStateById('assignments'), value),
});

export const dashboardAssignmentData = atom<AssignmentDashboardItemFragment[]>({
  key: getKey('assignment-data'),
  default: null,
});

export const dashboardAssignmentLoadState = atom<AsyncLoadStatus>({
  key: getKey('assignment-load-status'),
  default: AsyncLoadStatus.None,
});

//Order set

export const dashboardOrderSetState = selector<DashboardGroupState>({
  key: getKey('order-set-state'),
  get: ({ get }) => get(dashboardStateById('orders')),
  set: ({ set }, value: DashboardGroupState) =>
    set(dashboardStateById('orders'), value),
});

export const dashboardOrderSetData = atom<OrderSetDashboardItemFragment[]>({
  key: getKey('order-set-data'),
  default: null,
});

export const dashboardOrderSetLoadState = atom<AsyncLoadStatus>({
  key: getKey('order-set-load-status'),
  default: AsyncLoadStatus.None,
});

//Item set

export const dashboardItemSetState = selector<DashboardGroupState>({
  key: getKey('item-set-state'),
  get: ({ get }) => get(dashboardStateById('items')),
  set: ({ set }, value: DashboardGroupState) =>
    set(dashboardStateById('items'), value),
});

export const dashboardItemSetData = atom<ItemSetDashboardItemFragment[]>({
  //ItemSetDashboardItemFragment
  key: getKey('item-set-data'),
  default: null,
});

export const dashboardItemSetLoadState = atom<AsyncLoadStatus>({
  key: getKey('item-set-load-status'),
  default: AsyncLoadStatus.None,
});

//Activity feed

export const dashboardActivityFeedState = selector<DashboardGroupState>({
  key: getKey('activity-feed-state'),
  get: ({ get }) => get(dashboardStateById('activity')),
  set: ({ set }, value: DashboardGroupState) =>
    set(dashboardStateById('activity'), value),
});

export const dashboardActivityFeedData = atom<DatasetObjectFragment[]>({
  key: getKey('activity-feed-data'),
  default: null,
});

export const dashboardActivityFeedLoadState = atom<AsyncLoadStatus>({
  key: getKey('activity-feed-load-status'),
  default: AsyncLoadStatus.None,
});

//Dataset objects

export const dashboardDatasetObjectState = selector<DashboardGroupState>({
  key: getKey('dataset-state'),
  get: ({ get }) => get(dashboardStateById('datasets')),
  set: ({ set }, value: DashboardGroupState) =>
    set(dashboardStateById('datasets'), value),
});

export const dashboardDatasetObjectData = atom<DatasetObjectFragment[]>({
  key: getKey('dataset-object-data'),
  default: null,
});

export const dashboardDatasetObjectLoadState = atom<AsyncLoadStatus>({
  key: getKey('dataset-object-load-status'),
  default: AsyncLoadStatus.None,
});
