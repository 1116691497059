import React from 'react';
import { useTranslation } from 'react-i18next';
import { DashboardActionbar } from '../../components/DashboardActionbar';
import { Container } from '../../components/layout/ContainerFlex';
import { ContainerMain } from '../../components/layout/ContainerMain';
import TitleSection from '../../components/layout/TitleSection';
import WarehouseContainer from '../WarehouseContainer';
import { BetaDashboards } from './cards/BetaDashboards';

export const WarehouseDashboardDashboards: React.FC = () => {
  const { t } = useTranslation('app-beta');

  return (
    <WarehouseContainer fullHeaders={true}>
      <DashboardActionbar />
      <ContainerMain>
        <Container col hasOverflowY hasExtraPadding>
          <TitleSection title={t`Dashboards`} />
          <div className="p-4">
            <BetaDashboards />
          </div>
        </Container>
      </ContainerMain>
    </WarehouseContainer>
  );
};

export default WarehouseDashboardDashboards;
