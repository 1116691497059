import classNames from 'classnames';
// import 'prismjs/components/prism-json';
// import 'prismjs/components/prism-sql';
// import { highlight } from 'prismjs/components/prism-core';
import React, { PropsWithChildren, ReactNode } from 'react';
import { cn } from '../common/utils';
import CopyToClipboardButton from '../components/actions/CopyToClipboardButton';
import CodeEditor from '../components/editors/CodeEditor';
import { ActionBar } from '../components/nav/ActionBar';

export type CodeSnippetProps = PropsWithChildren & {
  className?: string;
  title?: string;
  code?: string;
  children?: ReactNode;
  canCopy?: boolean;
};

// Improved function to convert children to a string recursively while preserving whitespace
const convertChildrenToString = (children: ReactNode): string => {
  // Handle string directly without modification
  if (typeof children === 'string') {
    return children;
  }

  // Handle React elements
  if (React.isValidElement(children)) {
    if ('props' in children) {
      // Prioritize original content if available
      if (children.props.originalMarkdown) {
        return children.props.originalMarkdown;
      }
      if (children.props.raw) {
        return children.props.raw;
      }
      // Recursively process children
      return convertChildrenToString(children.props.children);
    }
  }

  // Handle arrays by joining without additional formatting
  if (Array.isArray(children)) {
    return children.map(child => convertChildrenToString(child)).join('');
  }

  return '';
};

const CodeSnippet: React.FC<CodeSnippetProps> = props => {
  // Convert props.children to a string
  const codeString = convertChildrenToString(props.children);

  return (
    <>
      {(props.title || props.canCopy) && (
        <ActionBar
          className={cn('bg-app-panel-dark/20', 'px-2', 'py-1', 'rounded-t-sm')}
        >
          <div className="text-xxs flex-1">{props.title}</div>

          <CopyToClipboardButton
            // className={cn('rounded-none')}
            hasIconSmall
            value={codeString}
          />
        </ActionBar>
      )}

      <div
        data-component="CodeSnippet"
        className={classNames(
          'font-mono text-xs',
          'bg-app-panel-dark/60',
          'text-menu-text/60',
          'text-left',
          'whitespace-pre-wrap',
          'select-text',
          'p-0.5',
          'w-full',
          'rounded-b-sm',
          'mb-2',
          'min-h-[24px]',
          'overflow-hidden',
          'flex flex-col',
          props.className,
        )}
      >
        <CodeEditor
          value={(props.children as any)?.props?.children}
          language={'trinosql'}
          // Calculate height based on content: line count * line height + padding
          height={`${((props.children as any)?.props?.children?.split('\n').length || 1) * 29}px`}
          minHeight="42px"
          className="flex-1"
          editorOptions={{
            readOnly: true,
            scrollBeyondLastLine: false,
            overviewRulerBorder: false,
            overviewRulerLanes: 0,
            hideCursorInOverviewRuler: true,
            lineDecorationsWidth: 10,
            scrollbar: {
              vertical: 'hidden',
              horizontal: 'hidden',
              handleMouseWheel: false,
            },
            guides: {
              indentation: false,
              highlightActiveIndentation: true,
              // bracketPairs: false,
            },
            fontSize: 13,
            lineNumbers: 'off',
            folding: false,
            tabSize: 2,
            padding: { top: 10, bottom: 10 },
          }}
        />
        {/* <Editor
          value={(props.children as any)?.props?.children}
          disabled={true}
          onValueChange={_.noop}
          highlight={code => (_.isEmpty(code) ? code : highlight(code, sql))}
          // highlight={code => Prism.highlight(code, Prism.languages.sql, 'sql')}
          padding={10}
          style={{
            position: 'relative',
            overflowY: 'auto',
            fontFamily: 'monospace',
            fontSize: 12,
            whiteSpace: 'pre-wrap',
            tabSize: 4,
            lineHeight: '1.5',
          }}
          textareaClassName="whitespace-pre-wrap"
          preClassName="whitespace-pre-wrap"
        >
        </Editor> */}
      </div>
    </>
  );
};

export default CodeSnippet;
