import {
  ImportJobImportType,
  MappingErrorFragment,
  MappingSpecInput,
} from '@warebee/frontend/data-access-api-graphql';
import {
  MappingMeasuresValuesType,
  MappingSpec,
  formatToAlias,
} from '@warebee/shared/import-converter';
import _ from 'lodash';
import * as Icon from '../../components/icons';
import {
  MappingFieldSettings,
  MappingSettings,
  MappingSettingsMutablePart,
  ValidationError,
} from './import.types';

export const importTypePath: Record<ImportJobImportType, string> = {
  [ImportJobImportType.ACTIVITY_FEED]: 'activity',
  [ImportJobImportType.CONVERTED_LAYOUT]: 'layout_import',
  [ImportJobImportType.LAYOUT]: 'layout',
  [ImportJobImportType.ASSIGNMENT]: 'assignment',
  [ImportJobImportType.ITEM_SET]: 'items',
  [ImportJobImportType.ORDER_SET]: 'orders',
  [ImportJobImportType.ASSIGNMENT_POLICY]: 'ap',
  [ImportJobImportType.GENERIC]: 'dataset',
};
export function createDefaultMapping<T>(
  settings: MappingSettings<T>,
  headers: string[],
): MappingSettings<T> {
  if (_.isEmpty(headers))
    return {
      ...settings,
      fields: [],
    };

  return {
    ...settings,
    fields: _.map(settings.fields, f => {
      const matchCol = _.findIndex(headers, h =>
        _.some(f.aliases, alias => formatToAlias(alias) === formatToAlias(h)),
      );

      return {
        ...f,
        mappedColumnIndex: matchCol >= 0 ? matchCol : null,
        columnName: matchCol >= 0 ? headers[matchCol] : null,
      };
    }),
  };
}

export type UploadFileParams = {
  url: string;
  blob: Blob;
  onProgress: (event: ProgressEvent) => void;
  onComplete: (event: ProgressEvent, data: string) => void;
  onError: (event: ProgressEvent) => void;
};

export function uploadFile(params: UploadFileParams) {
  const formData = new FormData();
  formData.append('file1', params.blob);
  const ajax = new XMLHttpRequest();

  ajax.upload.addEventListener(
    'progress',
    (event: ProgressEvent) => {
      // console.log('progress', event);
      params.onProgress(event);
    },
    false,
  );

  ajax.addEventListener(
    'load',
    (event: ProgressEvent) => {
      const etag = ajax.getResponseHeader('ETag');
      // console.log('load', event, etag, ajax.getAllResponseHeaders());
      params.onComplete(event, etag);
    },
    false,
  );

  ajax.addEventListener(
    'error',
    (event: ProgressEvent) => {
      console.log('error', event);
      params.onError(event);
    },
    false,
  );
  // ajax.addEventListener('abort', abortHandler, false);
  ajax.open('PUT', params.url);
  ajax.send(params.blob);
}

export function getChunkKey(jobId: string, index: number) {
  return `${jobId}-${index}`;
}

export function createMappingSpecInput(
  settings: MappingSettings<Object>,
): MappingSpecInput {
  return {
    fields: _(settings?.fields)
      .filter(f => !_.isNil(f.columnName))
      .map(f => ({
        columnName: f.columnName,
        measureValue: f.measureValue,
        name: f.name,
        valueResolver: _.isNil(f.valueResolver)
          ? null
          : {
              values: _.map(f.valueResolver, (value, key) => ({
                title: key.toLowerCase(),
                value: value.toString(),
              })),
            },
      }))
      .value(),
  };
}

export function createMappingSpec<T>(
  settings: MappingSettings<T>,
): MappingSpec<T> {
  return {
    fields: _(settings?.fields)
      .filter(f => !_.isNil(f.columnName))
      .map(f => ({
        measureValue: f.measureValue,
        name: f.name,
        valueResolver: _.isNil(f.valueResolver)
          ? null
          : {
              values: _.map(f.valueResolver, (value, key) => ({
                title: key,
                value: value.toString(),
              })),
            },
      }))
      .value(),
  };
}

export function createMappingSettingMutableInput<T>(
  settings: MappingSettings<T>,
): MappingSettingsMutablePart<T> {
  return {
    ...settings,
    fields: _(settings?.fields)
      .map(f => _.pick(f, ['name', 'aliases', 'measureValue', 'valueResolver']))
      .value(),
  };
}

export function mergeMappingSettings<T = Object>(
  settings: MappingSettings<T>,
  mutablePart: MappingSettingsMutablePart<T>,
): MappingSettings<T> {
  const mutableMap = _.keyBy(mutablePart?.fields, f => f.name);
  return {
    ...mutablePart,
    fields: _(settings?.fields)
      .map(f => ({
        ...f,
        ...(mutableMap[f.name as string] ?? {}),
      }))
      .value(),
  };
}

export type ChangeMappingParams = {
  mapping: MappingSettings<Object>;
  schemaField: string;
  columnName: string;
};

/**
 * Change field mapping
 *  - if field was already mapped, remove previous value from aliases
 * @param params
 * @returns
 */
export function changeMappingColumn(
  params: ChangeMappingParams,
): MappingSettings<Object> {
  const currentFieldSettings = _.find(
    params.mapping?.fields,
    f => f.name === params.schemaField,
  );

  if (_.isNil(currentFieldSettings)) {
    throw new Error('Unable to change mapping: Target field was not found');
  }

  let aliases = [...currentFieldSettings.aliases];
  const wasMapped = !_.isNull(currentFieldSettings.columnName);
  if (wasMapped) {
    aliases = _.filter(
      currentFieldSettings.aliases,
      v => formatToAlias(v) !== formatToAlias(currentFieldSettings.columnName),
    );
  }

  if (!_.isNil(params.columnName)) {
    aliases.push(formatToAlias(params.columnName));
  }

  const newFieldSettings: MappingFieldSettings<Object> = {
    ...currentFieldSettings,
    columnName: params.columnName,
    aliases: aliases,
  };

  return {
    ...params.mapping,
    fields: _.map(params.mapping?.fields, f =>
      f.name === params.schemaField ? newFieldSettings : f,
    ),
  };
}

export type ChangeMappingMeasureParams = {
  mapping: MappingSettings<Object>;
  schemaField: string;
  measureValueType: MappingMeasuresValuesType;
};

/**
 *
 * @param field
 * @param measure
 * @returns
 */
export function changeMappingMeasure(
  params: ChangeMappingMeasureParams,
): MappingSettings<Object> {
  const currentFieldSettings = _.find(
    params.mapping?.fields,
    f => f.name === params.schemaField,
  );

  if (_.isNil(currentFieldSettings)) {
    throw new Error('Unable to change mapping: Target field was not found');
  }

  const newFieldSettings: MappingFieldSettings<Object> = {
    ...currentFieldSettings,
    measureValue: params.measureValueType,
  };

  return {
    ...params.mapping,
    fields: _.map(params.mapping?.fields, f =>
      f.name === params.schemaField ? newFieldSettings : f,
    ),
  };
}

// --- Convert to JSON
export function createPreviewObject(
  data: string[][],
  headers: string[],
): Record<string, string>[] {
  return _.map(data, row => {
    return _.reduce(
      row,
      (acc, col, index) => ({
        ...acc,
        [headers[index]]: col,
      }),
      {} as Record<string, string>,
    );
  });
}

// --- Transformation
export type TransformJSONParams = {
  source: Record<string, string>[];
  expression: string;
};

export type TransformJSONResult = {
  errors: string[];
  result: Record<string, string>[];
};

export function transformJSON(
  params: TransformJSONParams,
): TransformJSONResult {
  let errors: string[] = [];

  let result = params.source;

  if (_.isEmpty(params.expression))
    return {
      errors,
      result,
    };

  try {
    // result = jq.json(params.source, params.expression);
  } catch (ex) {
    console.error(ex);
    errors.push(ex?.stack ?? ex?.message ?? ex);
  }
  return {
    errors,
    result,
  };
}

export function getEqualMapExpression(
  source: Record<string, string>[],
): string {
  const headerRow = _.keys(_.head(source));
  const allColumnsString = headerRow
    .map(column => `    "${column}"`)
    .join(',\n');

  return `map(\n{\n${allColumnsString}\n})`;
}

export type PrepareValidationErrorsParams = {
  mappingSettings: MappingSettings<Object>;
  errors: MappingErrorFragment[];
};

export function processValidationErrors(
  params: PrepareValidationErrorsParams,
): ValidationError[] {
  const fieldMap = _.keyBy(params.mappingSettings.fields, f => f.name);
  return _.map(params.errors, e => ({
    ...e,
    fieldTitle: e.property ? fieldMap[e.property]?.title : null,
    csvColumn: e.property ? fieldMap[e.property]?.columnName : null,
    canResolve: !_.isNil(
      fieldMap[e.property]?.valueResolver ??
        fieldMap[e.property]?.defaultValueResolver,
    ),
  }));
}
export function createValidationErrorFromMessage(
  message: string,
): ValidationError {
  return {
    message: message,
    code: '503',
    csvColumn: null,
    fieldTitle: null,
    canResolve: false,
  };
}

// export const decompress = async (
//   byteArray: string[],
//   encoding = 'gzip' as CompressionFormat
// ): Promise<string> => {
//   const cs = new DecompressionStream(encoding)
//   const writer = cs.writable.getWriter()
//   writer.write(byteArray)
//   writer.close()
//   const arrayBuffer = await new Response(cs.readable).arrayBuffer()
//   return new TextDecoder().decode(arrayBuffer)
// }

export const FILE_EXTENSIONS = {
  CSV: ['.csv', '.txt', '.tsv', '.tab', '.dat', '.asc', '.log'],
  JSON: ['.json', '.jsonl', '.ndjson'],
  XLS: ['.xls', '.xlsx'],
} as const;

export const FILE_MIME_TYPES = {
  CSV: [
    'text/csv',
    'text/plain',
    'text/tab-separated-values',
    'application/csv',
    'application/x-csv',
    'text/x-csv',
    'text/comma-separated-values',
    'text/x-comma-separated-values',
  ],
  JSON: ['application/json', 'application/x-jsonlines', 'application/x-ndjson'],
  GZIP: ['application/gzip', 'application/x-gzip'],
} as const;

export function getFileExtensionsForFormat(
  format: 'CSV' | 'JSON',
  includeGzip = false,
): string[] {
  const baseExtensions = [...FILE_EXTENSIONS[format]];
  if (!includeGzip) return baseExtensions;

  return [...baseExtensions, ...baseExtensions.map(ext => `${ext}.gz`)];
}

export function getFileExtensionsFilter(
  format: 'CSV' | 'JSON',
  includeGzip = false,
): string {
  return getFileExtensionsForFormat(format, includeGzip).join(', ');
}

export const isTextBasedFile = (file: File) => {
  const allMimeTypes: string[] = [
    ...FILE_MIME_TYPES.CSV,
    ...FILE_MIME_TYPES.JSON,
    ...FILE_MIME_TYPES.GZIP,
  ];

  const allExtensions = [
    ...getFileExtensionsForFormat('CSV', true),
    ...getFileExtensionsForFormat('JSON', true),
  ];

  // Check MIME type
  const hasSupportedMimeType = allMimeTypes.includes(file.type);
  // Check file extension
  const hasTextExtension = allExtensions.some(ext =>
    file.name.toLowerCase().endsWith(ext),
  );

  return hasSupportedMimeType || hasTextExtension;
};

export function getIconByExtension(
  format: 'CSV' | 'JSON',
  compressionExt = '',
): string {
  const extensions = FILE_EXTENSIONS[format]
    .map(ext => `${ext}${compressionExt}`)
    .join(', ');
  return extensions;
}

export function getFileIconByFilename(filename: string) {
  const extension = filename.split('.').pop()?.toLowerCase();

  // Check if it's a CSV-like file
  if (extension && FILE_EXTENSIONS.CSV.some(ext => ext.includes(extension))) {
    return Icon.FiletypeCsv;
  }

  // Check if it's a JSON-like file
  if (extension && FILE_EXTENSIONS.JSON.some(ext => ext.includes(extension))) {
    return Icon.FiletypeJson;
  }

  if (extension && FILE_EXTENSIONS.XLS.some(ext => ext.includes(extension))) {
    return Icon.FiletypeXlsx;
  }
  // Additional common file types
  switch (extension) {
    case 'zip':
    case 'gz':
      return Icon.FiletypeData;
    default:
      return Icon.FiletypeAny;
  }
}
