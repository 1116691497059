import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ImportFileFooterStats } from '../../components/ImportFileFooterStats';
import UserTagsEditor from '../../components/UserTagsEditor';
import { PanelBody } from '../../components/designer/panels/PanelBody';
import { InputGroupList } from '../../components/inputs/InputGroupList';
import InputText from '../../components/inputs/InputText';
import InputTextArea from '../../components/inputs/InputTextArea';
import { ContainerCol } from '../../components/layout/ContainerFlex';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import { Spacer } from '../../components/layout/Spacer';
import { warehouseCanUpdate } from '../../store/warehouse.state';
import useUpdateOrderSet from '../hooks/useUpdateOrderSet';
import { orderSetDocument } from '../store/orderSet.state';

const OrderSetStartPanel: React.FC = () => {
  const { t } = useTranslation('dataset');
  const [document, setDocument] = useRecoilState(orderSetDocument);
  const canUpdate = useRecoilValue(warehouseCanUpdate);
  const updateDocument = useUpdateOrderSet();

  const changeTitle = (title: string) => {
    setDocument({ ...document, title: title });
    updateDocument({ orderSetId: document.id, title: title });
  };
  const changeDescription = (description: string) => {
    setDocument({ ...document, description: description });
    updateDocument({ orderSetId: document.id, description: description });
  };

  const updateUserTags = (userTags: string[]) => {
    setDocument({ ...document, userTags });

    updateDocument({
      orderSetId: document.id,
      userTags,
    });
  };

  return (
    <PanelBody className="h-full">
      <ContainerCol overflow>
        <ScreenTitle
          isSticky
          title={t`Info`}
          subtitle={t`Order Set`}
          // hasStepCounter={'1.'}
        />
        <InputGroupList hasPadding hasSpacing>
          <InputText
            required
            placeholder={t`Order Set Name`}
            label={t`Order Set Name`}
            name={t`Order Set Name`}
            value={document?.title ?? ''}
            onChange={changeTitle}
            isDisabled={!canUpdate}
            animate={!canUpdate && !document.title?.trim()}
          />
          <InputTextArea
            optional
            label={t`Order Set Description`}
            placeholder={t`About the Order Set...`}
            value={document?.description ?? ''}
            onChange={changeDescription}
            isDisabled={!canUpdate}
            animate={!canUpdate && !document.description?.trim()}
          />
          <UserTagsEditor
            label={t`Tags`}
            tags={document.userTags ?? []}
            onChange={updateUserTags}
          />
        </InputGroupList>

        <Spacer flexspace />
        <ImportFileFooterStats
          className={classNames('mb-2 ltr:pl-2 rtl:pr-2')}
          updatedDate={document.updatedAt}
          createdDate={document.createdAt}
          importSource={document.inputFileName}
        />
      </ContainerCol>
    </PanelBody>
  );
};

export default OrderSetStartPanel;
