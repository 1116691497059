import {
  SizeComplianceLocationDetailsFragment,
  SizeComplianceLocationFilter,
  SizeComplianceLocationFragment,
  SizeComplianceLocationSortBy,
  SizeComplianceLocationSortOption,
  SizeComplianceStatus,
  SortDirection,
  useExportSizeComplianceMutation,
} from '@warebee/frontend/data-access-api-graphql';
import { generateExportFilename } from 'libs/shared/export-converter/src/lib/export-filename.helper';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { containsFilter, numberFilter } from '../../../../common/filterHelper';
import { AsyncLoadStatus } from '../../../../common/types';
import ButtonLocateLocation from '../../../../components/ButtonLocateLocation';
import DatasetTable, {
  ColumnConfig,
} from '../../../../components/DatasetTable';
import { Container } from '../../../../components/layout/ContainerFlex';
import {
  viewerSelectedBayIdAtom,
  viewerSelectedLocationIdWithToggle,
  viewerSelectedPlaneId,
} from '../../../../layout/viewer/store/viewer.state';
import { brandName } from '../../../../store/global.state';
import {
  warehouseSelected,
  warehouseSelectedId,
} from '../../../../store/warehouse.state';
import useLoadSizeComplianceLocations from '../../../hooks/useLoadSizeComplianceLocations';
import { simulationCurrent } from '../../../store/simulation.state';
import { getSizeComplianceStatusMap } from '../../../store/sizeCompliance.helper';
import {
  sizeComplianceDataLoadStatus,
  sizeComplianceLocationsData,
  sizeComplianceLocationsState,
  sizeComplianceMeta,
} from '../../../store/sizeCompliance.state';
import SizeComplianceStatusTag from './SizeComplianceStatusTag';

const sortFieldsMapping: Partial<
  Record<SizeComplianceLocationTableColumn, SizeComplianceLocationSortOption>
> = {
  locationId: SizeComplianceLocationSortOption.LOCATION_ID,
};

type SizeComplianceLocationTableRow = SizeComplianceLocationDetailsFragment & {
  locationId: string;
  area: string;
  rackType: string;
  bay: string;
  floor: string;
  level: number;
  locationData: SizeComplianceLocationFragment;
};
export type SizeComplianceLocationTableColumn =
  keyof SizeComplianceLocationTableRow;

const SizeComplianceLocationsTable: React.FC = () => {
  const { t } = useTranslation('simulation');

  const sim = useRecoilValue(simulationCurrent);
  const brandTitleState = useRecoilValue(brandName);
  const warehouse = useRecoilValue(warehouseSelected);
  const warehouseIdState = useRecoilValue(warehouseSelectedId);

  const loadStatus = useRecoilValue(sizeComplianceDataLoadStatus);
  const locData = useRecoilValue(sizeComplianceLocationsData);
  const [loadCallback, cancelLoad] = useLoadSizeComplianceLocations();
  const [state, setState] = useRecoilState(sizeComplianceLocationsState);
  const selectLocation = useSetRecoilState(viewerSelectedLocationIdWithToggle);
  const selectBay = useSetRecoilState(viewerSelectedBayIdAtom);
  const selectPlane = useSetRecoilState(viewerSelectedPlaneId);
  const [runExportCSV] = useExportSizeComplianceMutation();
  const complianceMeta = useRecoilValue(sizeComplianceMeta);
  const { searchValues, sortValues } = state;

  function callDataLoad(
    searchValues: Partial<Record<SizeComplianceLocationTableColumn, string>>,
    sortValues: Partial<
      Record<SizeComplianceLocationTableColumn, SortDirection>
    >,
    page = { isAppend: false, skip: 0 },
  ) {
    const filter: SizeComplianceLocationFilter = {
      status: searchValues['status']
        ? ([searchValues.status] as SizeComplianceStatus[])
        : null,
      locationId: containsFilter(searchValues.locationId),
      warehouseArea: containsFilter(searchValues.area),
      locationPlaneTitle: containsFilter(searchValues.floor),
      locationBayTitle: containsFilter(searchValues.bay),
      locationRackingType: containsFilter(searchValues.rackType),
      locationLevel: numberFilter(searchValues.level),
    };

    const sortBy: SizeComplianceLocationSortBy[] = _.map(
      sortValues,
      (value, key, index) => {
        const sortObjectKey = sortFieldsMapping[key];
        return sortObjectKey
          ? { field: sortObjectKey, direction: value }
          : null;
      },
    ).filter(i => !!i);

    cancelLoad();
    loadCallback({
      filter,
      sortBy,
      ...page,
    });
  }

  useEffect(() => {
    callDataLoad(searchValues, sortValues);
  }, [searchValues, sortValues]);

  async function startExportCSV() {
    const filename = generateExportFilename({
      exportTitle: t`compliance-size`,
      brandTitleState,
      warehouse,
      warehouseIdState,
      sim,
    });
    const { data, errors } = await runExportCSV({
      variables: {
        sizeComplianceId: complianceMeta.id,
      },
    });
    //
    return {
      errors: [],
      job: data.exportSizeCompliance,
      filename: `${filename}.csv`,
    };
  }

  const statusMap = {
    '': t`All`,
    ...getSizeComplianceStatusMap(t),
  };

  const columnsConfig: ColumnConfig<SizeComplianceLocationTableRow>[] = [
    {
      field: 'status',
      title: t`Status`,
      hasFilter: true,

      render: (status, row) => <SizeComplianceStatusTag status={row.status} />,
      filterOptions: statusMap,
    },
    {
      field: 'locationId',
      title: t`Location`,
      hasFilter: true,
      isHeader: true,
      hasSort: true,
      render: (locationId: string, row) => (
        <ButtonLocateLocation locationId={locationId} />
      ),
    },
    {
      field: 'area',
      title: t`Area`,
      hasFilter: true,
    },
    {
      field: 'rackType',
      title: t`Rack Type`,
      hasFilter: true,
    },
    {
      field: 'bay',
      title: t`Bay`,
      hasFilter: true,
    },
    {
      field: 'floor',
      title: t`Floor`,
      hasFilter: true,
    },
    {
      field: 'level',
      title: t`Level`,
      hasFilter: true,
    },
  ];

  const isLoading = loadStatus === AsyncLoadStatus.Loading;
  const itemsCount = locData?.content?.length || 0;
  const totalCount = locData?.totalCount ?? 0;

  const reduceLocation = (
    locationSizeData: SizeComplianceLocationDetailsFragment,
  ): SizeComplianceLocationTableRow => {
    const l = locationSizeData.location;
    return {
      ...locationSizeData,
      locationId: l.locationId,
      area: l.warehouseArea,
      rackType: l.locationRackingType,
      bay: l.bay?.title,
      floor: l.plane?.title,
      level: l.locationLevel,
      locationData: l,
    };
  };

  const data = _.map(locData?.content, reduceLocation);

  function onLoadNext() {
    callDataLoad(searchValues, sortValues, {
      isAppend: true,
      skip: itemsCount,
    });
  }

  function onRowSelect(row: SizeComplianceLocationTableRow, index: number) {
    const targetPlaneId = row.locationData?.plane?.id;
    const targetBayId = row.locationData.bay?.id;
    const targetLocationId = row.locationData.locationId;
    targetPlaneId && selectPlane(targetPlaneId);
    targetBayId && selectBay(targetBayId);
    targetLocationId && selectLocation(targetLocationId);
  }
  return (
    <Container col hasOverflowY>
      <DatasetTable
        isActionable
        subtitle={t(`{{totalCount}} Filtered Items for`, {
          totalCount,
        })}
        title={t`Locations — size compliance`}
        isSticky
        id={'size-compliance-bay-table'}
        columnsConfig={columnsConfig}
        keyFields={['locationId']}
        data={data}
        onLoadNext={onLoadNext}
        onSearch={searchValues => setState({ ...state, searchValues })}
        sortBy={sortValues}
        onSort={sortValues => setState({ ...state, sortValues })}
        totalCount={totalCount}
        searchValues={searchValues}
        isLoading={isLoading}
        onRowSelect={onRowSelect}
        onStartExportClick={startExportCSV}
      />
    </Container>
  );
};

export default SizeComplianceLocationsTable;
