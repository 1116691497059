import { WarehouseType } from '@warebee/frontend/data-access-api-graphql';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { cn } from '../../../common/utils';
import { InputSearch } from '../../../components/inputs/InputSearch';
import KeyValueItem from '../../../components/KeyValueItem';
import TitleSection from '../../../components/layout/TitleSection';
import PanelContainer from '../../../containers/PanelContainer';
import { optimisationResult } from '../../../simulation/store/optimisation.state';
import {
  simulationCurrent,
  simulationEffectiveAssignmentId,
} from '../../../simulation/store/simulation.state';
import { loggedInUser } from '../../../store/auth.state';
import { warehouseList } from '../../../store/warehouse.state';
import { AppDataObjectIdItem } from './AppDataObjectIdItem';
import { PanelProps } from './PanelSettingsGDPR';

const PanelSupportData: React.FC<PanelProps> = props => {
  const { t } = useTranslation('app');

  const [searchQuery, setSearchQuery] = useState('');
  const whList = useRecoilValue(warehouseList);
  const user = useRecoilValue(loggedInUser);

  const simulation = useRecoilValue(simulationCurrent);
  const effectiveAssignmentId = useRecoilValue(simulationEffectiveAssignmentId);
  const optimisation = useRecoilValue(optimisationResult);

  // Add this filtered list
  const filteredWhList =
    whList?.filter(
      wh =>
        wh.title.toLowerCase().includes(searchQuery.toLowerCase()) &&
        wh.type !== WarehouseType.DEMO &&
        wh.type !== null,
    ) ?? [];

  return (
    <PanelContainer
      data-component="PanelSupportData"
      id="panel-system-support-data"
      title={t`Support Data`}
      collapsible
      className={cn('', props.className)}
    >
      <TitleSection title={`Users`} inPanelView>
        <KeyValueItem
          keyName={`User`}
          keyValue={user.id}
          isDisabled={true}
          isCopyableValueOnly
          style={{ inline: true, noStyle: true }}
          classNameKeyName="text-menu-text/75 min-w-[8ch] justify-end"
          classNameKeyValue="text-md text-menu-text monospace"
          className="py-0.5"
        />
      </TitleSection>

      {simulation && (
        <TitleSection
          title={`Simulation`}
          inPanelView
          classNameInner="py-4 gap-y-0.5 divide-menu/75 divide-y divide-solid px-4"
        >
          <AppDataObjectIdItem
            label="Simulation ID"
            value={simulation.id}
            canCopy
          />
          <TitleSection title={`Datasets`} inPanelView />
          <AppDataObjectIdItem
            label="Layout"
            value={simulation.layout?.id}
            canCopy
          />
          <AppDataObjectIdItem
            label={'Assignment'}
            value={simulation.assignment?.id}
          />
          <AppDataObjectIdItem
            label="Items"
            value={simulation.itemSet?.id}
            canCopy
          />
          <AppDataObjectIdItem
            label="Order Line Set"
            value={simulation.orderLineSet?.id}
            canCopy
          />
          <AppDataObjectIdItem
            label="Effective Items"
            value={simulation.effectiveItemSet?.id}
            canCopy
          />
          <AppDataObjectIdItem
            label={`Effective Assignment ${
              effectiveAssignmentId === simulation?.assignment?.id
                ? '(Original)'
                : '(Allocated)'
            }`}
            value={effectiveAssignmentId}
            canCopy
          />

          <AppDataObjectIdItem
            label="[Analyse] Allocated Assignment ID"
            value={simulation?.latestAllocationRun?.resultAssignment?.id}
            canCopy
          />
          <AppDataObjectIdItem
            label="[Analyse] Optimised Assignment ID"
            value={optimisation?.resultAssignment?.id}
            canCopy
          />

          <TitleSection title={`Analyse`} inPanelView />
          <AppDataObjectIdItem
            label="[Analyse] Analyse ID"
            value={simulation?.analyzeResult?.id}
            canCopy
          />
          <AppDataObjectIdItem
            label="[Analyse] Allocate ID"
            value={simulation?.latestAllocationRun?.analyzeResult?.id}
            canCopy
          />
          <AppDataObjectIdItem
            label="[Analyse] Optimised ID"
            value={optimisation?.analyzeResult.id}
            canCopy
          />

          <TitleSection title={`Runs`} inPanelView />
          <AppDataObjectIdItem
            label="Optimise Run ID"
            value={simulation?.optimizationRuns?.content?.[0]?.id}
            canCopy
          />
          <AppDataObjectIdItem
            label="Allocate Run ID"
            value={simulation?.latestAllocationRun?.id}
            canCopy
          />
        </TitleSection>
      )}
      <TitleSection
        title={`Warehouses`}
        inPanelView
        classNameInner="py-4"
        collapsible
      >
        <div className="mb-2 px-4 py-2">
          <InputSearch
            // titleMode
            sidebarFilterMode
            placeholder={t`Search warehouses...`}
            value={searchQuery || ''}
            onChange={value => setSearchQuery(value)}
            className="!mt-0"
            classNamePlaceholder="!left-2 !top-2"
            classNameInput="!p-2 !text-xs !bg-app-panel-dark/80"
          />
        </div>

        <ul>
          {filteredWhList.map(wh => (
            <li className="border-menu/50 border px-6 py-1" key={wh.id}>
              <AppDataObjectIdItem label={wh.title} value={wh.id} />

              {/* <KeyValueItem
                keyName={`Name`}
                keyValue={wh.title}
                isDisabled={true}
                isCopyableValueOnly
                style={{ inline: true, noStyle: true }}
                classNameKeyName="text-menu-text/75 min-w-[8ch] justify-end"
                classNameKeyValue="text-lg text-menu-text"
                className="py-0.5"
              />
              <KeyValueItem
                keyName={`ID`}
                keyValue={wh.id}
                isDisabled={true}
                isCopyableValueOnly
                style={{ inline: true, noStyle: true }}
                classNameKeyName="text-menu-text/75 min-w-[8ch] justify-end"
                classNameKeyValue="text-md text-menu-text monospace"
                className="py-0.5"
              /> */}
            </li>
          ))}
        </ul>
      </TitleSection>

      {props.children}
    </PanelContainer>
  );
};

export default PanelSupportData;
