import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { cn } from '../../common/utils';
import { HelperMessageAction } from '../../components/HelperMessage';
import * as Icon from '../../components/icons';
import { InputGroupList } from '../../components/inputs/InputGroupList';
import InputText from '../../components/inputs/InputText';
import InputTextArea from '../../components/inputs/InputTextArea';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import SectionOptional from '../../components/layout/SectionOptional';
import { Spacer } from '../../components/layout/Spacer';
import UserTagsEditor from '../../components/UserTagsEditor';
import Callout from '../../helpContext/Callout';
import { ModeSelectorDropdown } from '../sidebar/MenuModeSelector';
import {
  simulationIsEditable,
  simulationManualLock,
} from '../store/simulation.state';

export type PanelSimulationMetaInfo = {
  title: string;
  description: string;
  isDisabled?: boolean;
  userTags?: string[];
  onTitleChange: (title: string) => void;
  onDescriptionChange: (description: string) => void;
  onTagsUpdate: (userTags: string[]) => void;
};

const PanelSimulationMetaInfo: React.FC<PanelSimulationMetaInfo> = props => {
  const { t } = useTranslation('simulation');

  const [isSimulationManuallyLocked, setIsManuallyLocked] =
    useRecoilState(simulationManualLock);

  const isSimulationEditable = useRecoilValue(simulationIsEditable);
  const [showAdvanced, setShowAdvanced] = useState<boolean>(false);

  return (
    <>
      <ScreenTitle
        title={t`Simulation Setup`}
        subtitle={t`Getting Started`}
        isSticky
        helpNavTo={'simulation/simulation-getting-started'}
        icon={Icon.SimulationAddLayout}
      />
      <Callout panelMode type="suggestion" iconAfter icon={Icon.ArrowBottom}>
        {t`Let's get started with the new Warehouse Simulation.`}
      </Callout>
      <InputGroupList hasPadding hasSpacing>
        <InputText
          required
          placeholder={t`Simulation Name`}
          label={t`Simulation Name`}
          name={t`Simulation Name`}
          value={props.title ?? ''}
          onChange={props.onTitleChange}
          isDisabled={props.isDisabled}
          animate={
            !props.isDisabled &&
            props.title === '' &&
            props.title?.trim().length === 0
            // && props.title == null
          }
        />

        <InputTextArea
          optional
          label={t`Simulation Description`}
          placeholder={t`About the simulation...`}
          value={props.description ?? ''}
          onChange={props.onDescriptionChange}
          isDisabled={props.isDisabled}
          animate={
            !props.isDisabled &&
            props.description === '' &&
            props.description?.trim().length === 0
            // && props.description == null
          }
        />

        <UserTagsEditor
          label={t`Tags`}
          tags={props.userTags ?? []}
          onChange={props.onTagsUpdate}
        />
      </InputGroupList>
      <div className="p-4">
        <span
          className={cn(
            'text-menu-text/60 cursor-pointer text-sm hover:underline',
          )}
          onClick={() => {
            setShowAdvanced(!showAdvanced);
          }}
        >
          {showAdvanced ? t`Hide advanced setup` : t`Show advanced setup`}
        </span>
      </div>

      {showAdvanced && (
        <div className="p-2">
          <InputGroupList className="p-4">
            <ModeSelectorDropdown />
          </InputGroupList>
          <InputGroupList className="space-y-2">
            <Callout panelMode type="admin">
              <div>
                {isSimulationManuallyLocked
                  ? t`This simulation is locked for editing`
                  : isSimulationEditable
                    ? t`This simulation is open for editing`
                    : t`This simulation is locked`}
              </div>

              <SectionOptional
                id={'simulation-lock'}
                title={t`Simulation Lock`}
                value={isSimulationManuallyLocked}
                onChange={setIsManuallyLocked}
                // isDisabled={isEditable}
              ></SectionOptional>
            </Callout>
          </InputGroupList>
        </div>
      )}

      <Callout panelMode type="tip" iconBefore icon={Icon.ArrowTop}>
        {t`We suggest to give the simulation a name and a short description, helping you better identify it in the future.`}
      </Callout>
      <Spacer flexspace />
      <Callout panelMode type="action" iconAfter icon={Icon.ArrowRightBottom}>
        {t`Click`}
        <HelperMessageAction>{t`Select Data Set`}</HelperMessageAction>
        {t`button to continue to the next step.`}
      </Callout>
    </>
  );
};

export default PanelSimulationMetaInfo;
function setSimulation(arg0: any) {
  throw new Error('Function not implemented.');
}

function updateSim(arg0: { userTags: string[] }) {
  throw new Error('Function not implemented.');
}
