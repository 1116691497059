import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import InputCheckbox from '../../../components/inputs/InputCheckbox';
import { chatState } from '../../../store/global.state';

export const SettingsSupportChat: React.FC = () => {
  const { t } = useTranslation('app');
  const [chatEnabled, setChatEnabled] = useRecoilState(chatState);

  const handleChange = (value: boolean) => {
    console.log('Changing chat state to:', value);
    setChatEnabled(value);
  };

  return (
    <InputCheckbox
      name="Chat"
      className="p-2"
      isSelected={chatEnabled}
      onChange={handleChange}
    >
      <div>{t`Chat (Support)`}</div>
    </InputCheckbox>
  );
};
