import React from 'react';
import { useTranslation } from 'react-i18next';
import { DashboardActionbar } from '../../components/DashboardActionbar';
import { HelperMessage } from '../../components/HelperMessage';
import { Container } from '../../components/layout/ContainerFlex';
import { ContainerMain } from '../../components/layout/ContainerMain';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import { ItemListCard } from '../../dashboard/containers/ItemListCard';
import { ItemListCardsContainer } from '../../dashboard/containers/ItemListCardsContainer';
import WarehouseContainer from '../WarehouseContainer';
import { BetaBilling } from './cards/BetaBilling';
import { BetaContracts } from './cards/BetaContracts';
import { BetaDashboards } from './cards/BetaDashboards';
import { BetaMarketplace } from './cards/BetaMarketplace';
import { BetaSales } from './cards/BetaSales';
import { BetaScheduling } from './cards/BetaScheduling';

export const WarehouseDashboardBeta: React.FC = () => {
  const { t } = useTranslation('app-beta');

  return (
    <WarehouseContainer fullHeaders={true}>
      <DashboardActionbar />
      <ContainerMain data-component="WarehouseDashboardBeta">
        <Container col hasOverflowY hasExtraPadding flex1>
          <ItemListCard fullWidth>
            <ScreenTitle
              // subtitle={t`Digital Twin`}
              title={t`Join our Beta program today!`}
            />
            <HelperMessage hasPadding>
              <p>{t`And get valuable insights before everyone else`}</p>
            </HelperMessage>
          </ItemListCard>

          <ItemListCardsContainer className="flex-col gap-4 p-4">
            <BetaScheduling />
            <BetaDashboards />
            <BetaSales />
            <BetaBilling />
            <BetaContracts />
            <BetaMarketplace />
          </ItemListCardsContainer>
        </Container>
      </ContainerMain>
    </WarehouseContainer>
  );
};

export default WarehouseDashboardBeta;
