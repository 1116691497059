import {
  ActivityEventProcessType,
  ActivityEventType,
} from '@warebee/shared/import-converter';
import { sql } from 'kysely';
import { datasetQueryBuilder } from './datasetQueryBuilder';
import {
  ActivityFeedQueryBuilderParams,
  getActivityFeedFilteredQueryBuilder,
} from './feedEventsQueryBuilders';

/**
 *  Create AT (Analytic Table) for extended activity feed events (with ABC, and distribution stats)
 *
 */

export function feedExtendedEventsQueryBuilder(
  params: ActivityFeedQueryBuilderParams,
) {
  let builder = datasetQueryBuilder
    .with(
      cte => cte('af'),
      db => {
        return getActivityFeedFilteredQueryBuilder(params, db);
      },
    )
    .with(
      cte => cte('afo'),
      db => {
        return db
          .selectFrom('af')
          .select(({ fn }) => [
            fn.coalesce('jobGroupId', 'jobId').as('orderId'),
            'skuKey',
            fn.sum('quantity').as('quantity'),
            fn.min('eventEndTime').as('orderDate'),
            fn('date_trunc', [sql.raw("'day'"), fn.min('eventEndTime')]).as(
              'orderDay',
            ),
            fn('date_trunc', [sql.raw("'week'"), fn.min('eventEndTime')]).as(
              'orderWeek',
            ),
          ])
          .where('sku', 'is not', null)
          .where('eventProcessType', '=', ActivityEventProcessType.PICKING)
          .where('eventType', '=', ActivityEventType.PICK)
          .groupBy(({ fn }) => [fn.coalesce('jobGroupId', 'jobId'), 'skuKey']);
      },
    )
    .with(
      cte => cte('afos'),
      db => {
        return db
          .selectFrom('afo')
          .selectAll()
          .select(({ fn }) => [
            fn
              .countAll()
              .over(ob => ob.partitionBy(['orderDay', 'skuKey']))
              .as('dailyLinesCount'),
            fn
              .countAll()
              .over(ob => ob.partitionBy(['orderWeek', 'skuKey']))
              .as('weeklyLinesCount'),
            fn
              .agg('dense_rank')
              .over(ob => ob.orderBy('orderDay', 'asc'))
              .as('dayRank'),
            fn
              .agg('dense_rank')
              .over(ob => ob.orderBy('orderWeek', 'asc'))
              .as('weekRank'),
          ]);
      },
    )
    .with(
      cte => cte('item_stats'),
      db => {
        return db
          .selectFrom('afos')
          .select(({ fn, cast }) => [
            'skuKey',
            fn.countAll<number>().as('orderLineCount'),
            fn.sum<number>('quantity').as('quantityOrdered'),
            fn
              .agg<number>('percent_rank')
              .over(ob =>
                ob
                  .orderBy(sql`count(1)` as any, 'desc')
                  .orderBy('skuKey', 'asc'),
              )
              .as('percentRank'),
            sql<number>`
              coalesce(
                cast(
                  sum(count(1)) over (
                    partition by 1
                    order by 
                      count(1) desc,
                      ${sql.ref('skuKey')} 
                      rows between unbounded preceding
                      and 1 preceding
                  ) as double
                ),
                0
            ) / greatest(1, sum(count(1)) over (partition by 1))`.as(
              'cumulativePercentRank',
            ),

            fn.count<number>('orderDay').distinct().as('totalDaysOrdered'),
            fn.count<number>('orderWeek').distinct().as('totalWeeksOrdered'),
            fn.max<number>('dailyLinesCount').as('maxDailyLinesCount'),
            fn.min<number>('dailyLinesCount').as('minDailyLinesCount'),
            sql<number>`${cast(fn.countAll(), 'double precision')} / ${fn.max('dayRank')}`.as(
              'avgDailyLinesCount',
            ),

            fn.max<number>('weeklyLinesCount').as('maxWeeklyLinesCount'),
            fn.min<number>('weeklyLinesCount').as('minWeeklyLinesCount'),
            sql<number>`${cast(fn.countAll(), 'double precision')} / ${fn.max('dayRank')}`.as(
              'avgWeeklyLinesCount',
            ),
          ])
          .groupBy(['skuKey']);
      },
    );

  return builder;
}
