import { format } from 'date-fns';

export interface ExportFilenameParams {
  prefix?: string;
  warehouseTitle?: string;
  warehouseId?: string;
  simulationId?: string;
  exportTitle?: string;
  exportId?: string;
  dateFormat?: string;
  brandTitleState: string;
  warehouse?: { title?: string };
  warehouseIdState?: string;
  sim?: { id?: string };
}

export const generateExportFilename = ({
  prefix,
  warehouseTitle: warehouseTitleProp,
  warehouseId: warehouseIdProp,
  simulationId: simulationIdProp,
  exportTitle,
  exportId,
  dateFormat = 'yyyy-MM-dd-HHmm',
  brandTitleState,
  warehouse,
  warehouseIdState,
  sim,
}: ExportFilenameParams): string => {
  const prefixTitle = prefix ?? brandTitleState;
  const warehouseTitle = warehouseTitleProp ?? warehouse?.title ?? '';
  const warehouseId = warehouseIdProp ?? warehouseIdState ?? '';
  const simulationId = simulationIdProp ?? sim?.id;

  const timestamp = format(new Date(), dateFormat);

  const shortWarehouseTitle = warehouseTitle.replace(/[^a-zA-Z0-9-]/g, '-');
  const shortWarehouseId = warehouseId ? warehouseId.split('-')[0] : '';
  const shortSimulationId = simulationId?.split('-')[0];
  const shortExportId =
    shortSimulationId && shortWarehouseId
      ? `${shortWarehouseId}--${shortSimulationId}`
      : shortWarehouseId || '';

  return `${prefixTitle}-${shortWarehouseTitle}--${exportTitle}--${timestamp}--${shortExportId}`;
};
