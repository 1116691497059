import {
  AnalyzeResultLocationsAndProductsFilter,
  AnalyzeResultLocationsSortBy,
  AnalyzeResultLocationsSortOption,
  LoadAnalyzeLocationsDocument,
  SortDirection,
  useRunExportJobMutation,
} from '@warebee/frontend/data-access-api-graphql';
import {
  AnalyzedProductsConverterConfig,
  AnalyzedProductsDataColumn,
  AnalyzedProductsDataRow,
  analyzedProductsExportJobParams,
  AnalyzedProductsExportJobParams,
  getAnalyzedProductsTableRows,
} from '@warebee/shared/export-converter';
import { generateExportFilename } from 'libs/shared/export-converter/src/lib/export-filename.helper';
import _ from 'lodash';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { containsFilter } from '../common/filterHelper';
import { AsyncLoadStatus } from '../common/types';
import useFormatter from '../common/useFormatter';
import DatasetTable from '../components/DatasetTable';
import useLoadAnalyzedProducts from '../simulation/hooks/useLoadAnalyzedProducts';
import {
  getProductCategories,
  getProductCategoryDescriptorExport,
} from '../simulation/store/abc/simulation.ABC.helper';
import {
  analyzeLocationProductsData,
  analyzeLocationProductsLoadStatus,
  analyzeLocationProductsSelectedRow,
  analyzeLocationProductsTableState,
} from '../simulation/store/analyze.state';
import { simulationCurrent } from '../simulation/store/simulation.state';
import { getIndexedStackingPolicyRulesTitles } from '../simulation/store/stackingPolicy/stackingPolicy.helper';
import { stackingPolicyDocument } from '../simulation/store/stackingPolicy/stackingPolicy.state';
import { brandName } from '../store/global.state';
import {
  warehouseSelected,
  warehouseSelectedId,
} from '../store/warehouse.state';

import { getAnalyzedProductsTableConfigDefault } from './AnalyzedProductsTable.config';

const sortFieldsMapping: Partial<
  Record<AnalyzedProductsDataColumn, AnalyzeResultLocationsSortOption[]>
> = {
  hitCount: [AnalyzeResultLocationsSortOption.HIT_COUNT],
};

export type AnalyzedProductsTableProps = {
  analyzeId: string;
};

const AnalyzedProductsTable: React.FC<AnalyzedProductsTableProps> = props => {
  const { t } = useTranslation('simulation');
  const { analyzeId } = props;
  const sim = useRecoilValue(simulationCurrent);
  const brandTitleState = useRecoilValue(brandName);
  const warehouse = useRecoilValue(warehouseSelected);
  const warehouseIdState = useRecoilValue(warehouseSelectedId);

  const stackingPolicy = useRecoilValue(stackingPolicyDocument);
  const locationProductsData = useRecoilValue(analyzeLocationProductsData);
  const loadStatus = useRecoilValue(analyzeLocationProductsLoadStatus);
  const [tableState, setTableState] = useRecoilState(
    analyzeLocationProductsTableState,
  );
  const [selectedRow, setSelectedRow] = useRecoilState(
    analyzeLocationProductsSelectedRow,
  );
  const [loadCallback, cancelLoad] = useLoadAnalyzedProducts();
  const [runExportCSV] = useRunExportJobMutation();

  const formatter = useFormatter();
  const { searchValues, sortValues } = tableState;

  const productCategories = getProductCategories(t);

  const indexedPolicyRules = getIndexedStackingPolicyRulesTitles(
    stackingPolicy,
    t,
  );
  function callDataLoad(
    searchValues: Partial<Record<AnalyzedProductsDataColumn, string>>,
    sortValues: Partial<Record<AnalyzedProductsDataColumn, SortDirection>>,
    page = { isAppend: false, skip: 0 },
  ) {
    const filter: AnalyzeResultLocationsAndProductsFilter = {
      locationId: containsFilter(searchValues?.locationId),
      item: {
        consignee: containsFilter(searchValues?.consignee),
        sku: containsFilter(searchValues?.sku),
      },
    };

    const sortBy: AnalyzeResultLocationsSortBy[] = _(sortValues)
      .map((direction, key) =>
        _.map(sortFieldsMapping[key], field => ({
          field,
          direction,
        })),
      )
      .flatten()
      .compact()
      .value();

    cancelLoad();
    loadCallback({
      analyzeId,
      sortBy,
      filter,
      ...page,
    });
  }

  useEffect(() => {
    callDataLoad(searchValues, sortValues);
  }, [analyzeId, searchValues, sortValues]);

  const columnsConfig = getAnalyzedProductsTableConfigDefault(t, formatter);

  function onLoadNext() {
    callDataLoad(searchValues, sortValues, {
      isAppend: true,
      skip: itemsCount,
    });
  }

  function onRowSelect(row: AnalyzedProductsDataRow, index: number) {
    setSelectedRow(row);
  }

  const converterConfig: AnalyzedProductsConverterConfig = {
    columnsConfig,
    dictionaries: {
      categories: getProductCategoryDescriptorExport(productCategories),
      stackingPolicyIndexedRuleTitles: indexedPolicyRules,
    },
  };

  async function startExportCSV() {
    const filename = generateExportFilename({
      exportTitle: t`analyse`,
      brandTitleState,
      warehouse,
      warehouseIdState,
      sim,
    });
    const variables: AnalyzedProductsExportJobParams = {
      ...analyzedProductsExportJobParams,
      query: LoadAnalyzeLocationsDocument.loc.source.body,
      config: converterConfig,
      variables: {
        analyzeId,
        page: null,
      },
      filename: `${filename}.csv`,
    };
    const { data, errors } = await runExportCSV({
      variables,
    });

    return {
      errors: errors,
      job: data.createExportJob,
    };
  }

  const isLoading =
    loadStatus === AsyncLoadStatus.Loading ||
    loadStatus === AsyncLoadStatus.None;
  const itemsCount = locationProductsData?.content?.length || 0;
  const totalCount = locationProductsData?.totalCount ?? 0;

  const flattenData = getAnalyzedProductsTableRows(
    locationProductsData?.content,
    converterConfig,
  );

  return (
    <DatasetTable
      id={'analyzed-products-table'}
      hideScreenTitle
      isSticky
      isActionable
      columnsConfig={columnsConfig}
      keyFields={['locationId', 'itemId']}
      data={flattenData}
      onLoadNext={onLoadNext}
      onSearch={searchValues => setTableState({ ...tableState, searchValues })}
      sortBy={sortValues}
      onSort={sortValues => setTableState({ ...tableState, sortValues })}
      totalCount={totalCount}
      searchValues={searchValues}
      isLoading={isLoading}
      onRowSelect={onRowSelect}
      onStartExportClick={startExportCSV}
      hasCounter
    />
  );
};

export default AnalyzedProductsTable;
