import { BillableOperationType } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import AppBillingWarehouseStatus from '../../../appBilling/AppBillingWarehouseStatus';
import {
  appBillingWarehouseLoadStatus,
  appBillingWarehouseState,
  appBillingWarehouseStateSummary,
} from '../../../appBilling/store/appBilling.state';
import { formatDateTime } from '../../../common/formatHelper';
import BillingTag from '../../../components/billing/BillingTag';
import InboxZero from '../../../components/InboxZero';
import InputCheckbox from '../../../components/inputs/InputCheckbox';
import { ScreenTitle } from '../../../components/layout/ScreenTitle';
import LinkTraced from '../../../components/nav/LinkTraced';
import PanelContainer from '../../../containers/PanelContainer';
import Callout from '../../../helpContext/Callout';
import { loggedInUser } from '../../../store/auth.state';
import { billingStatusVisibilityState } from '../../../store/global.state';
import { warehouseSelectedId } from '../../../store/warehouse.state';

export type PanelProps = PropsWithChildren & {
  className?: string;
};

const PanelSettingBilling: React.FC<PanelProps> = props => {
  const { t } = useTranslation('app');
  const whId = useRecoilValue(warehouseSelectedId);
  const user = useRecoilValue(loggedInUser);

  const billingState = useRecoilValue(appBillingWarehouseStateSummary);
  const billingStateLoadStatus = useRecoilValue(appBillingWarehouseLoadStatus);
  const whBillingState = useRecoilValue(appBillingWarehouseState);
  const subscription = _.head(whBillingState?.currentSubscriptions);
  const [isBillingStatusVisible, setBillingStatusVisible] = useRecoilState(
    billingStatusVisibilityState,
  );
  const toggleBillingStatusVisibility = () => {
    setBillingStatusVisible(!isBillingStatusVisible);
  };

  let billingSummaryMessage = '';

  if (subscription) {
    billingSummaryMessage = t(`Your subscription is active until {{endDate}}`, {
      endDate: formatDateTime(new Date(subscription.currentPeriodEnd)),
    });
  } else if (
    _.find(
      whBillingState?.balance,
      b => b.operationType === BillableOperationType.ANALYZE,
    )?.balance > 0
  ) {
    billingSummaryMessage = t(
      `You have {{simulationCount}} analyse simulations left.`,
      {
        simulationCount: _.find(
          whBillingState?.balance,
          b => b.operationType === BillableOperationType.ANALYZE,
        )?.balance,
      },
    );
  } else if (
    _.find(
      whBillingState?.balance,
      b => b.operationType === BillableOperationType.OPTIMIZE,
    )?.balance > 0
  ) {
    billingSummaryMessage = t(
      `You have {{simulationCount}} optimize simulations left.`,
      {
        simulationCount: _.find(
          whBillingState?.balance,
          b => b.operationType === BillableOperationType.OPTIMIZE,
        )?.balance,
      },
    );
  }
  // } else if
  //    ( billingState === 'isTrial')
  // {
  //   billingSummaryMessage = t(`You are currently running a trial`);
  // }
  // } else if
  //    ( billingState === 'unpaid')
  // {
  //   billingSummaryMessage = t(`This warehouse, has no active subscription, please purchase one to continue.`);
  // }
  else {
    billingSummaryMessage = t(
      `This warehouse has no active subscription, please subscribe to continue.`,
    );
  }

  return (
    <PanelContainer
      id="panel-account-subscription"
      title={t`Subscription & Billing`}
      collapsible
      // hasPadding
    >
      <ScreenTitle
        isSticky
        subtitle={t`Billing`}
        title={t`Subscriptions & Purchases`}
      />

      <div className="mb-4 p-4">
        <label className="text-menu-200 mb-2 mt-3">{t`Status`}</label>

        <LinkTraced
          to={`/wh/i/${whId}/pricing/`}
          data-label={`BillingState-setting-button-${billingState}`}
          aria-label={`BillingState-setting-button-${billingState}`}
          titleTrace={`Billing: ${billingState}`}
        >
          <BillingTag
            billingState={billingState}
            billingStateLoadStatus={billingStateLoadStatus}
          />
        </LinkTraced>
      </div>

      <Callout panelMode type="suggestion">
        {billingSummaryMessage}
        <AppBillingWarehouseStatus />
      </Callout>

      <ScreenTitle isSticky subtitle={t`Billing`} title={t`Invoices`} />
      <Callout panelMode type="suggestion">
        <InboxZero message={t`Please contact support`} />
      </Callout>

      <div className="mb-4 p-4">
        <label className="text-menu-200 mb-2 mt-3">{t`Billing Alerts`}</label>
        <InputCheckbox
          name="visibility"
          className="p-2"
          isSelected={isBillingStatusVisible}
          onChange={toggleBillingStatusVisibility}
        >
          {isBillingStatusVisible
            ? 'Hide Billing Alerts'
            : 'Show Billing Alerts'}
        </InputCheckbox>
      </div>
    </PanelContainer>
  );
};

export default PanelSettingBilling;
