import classNames from 'classnames';
import React, { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { cn } from '../common/utils';
import CopyToClipboardButton from './actions/CopyToClipboardButton';
import * as Icon from './icons';

export type KeyValueItemProps = {
  children?: React.ReactNode;
  className?: string;
  keyName?: string | React.ReactNode;
  classNameKeyName?: string;
  keyValue: string | React.ReactNode;
  classNameKeyValue?: string;
  isRemovable?: boolean;
  isCopyable?: boolean;
  isCopyableValueOnly?: boolean;
  isDisabled?: boolean;
  isActive?: boolean;
  isNot?: boolean;
  hasIssue?: boolean;
  truncate?: boolean;
  onRemoveClick?: () => void;
  onClick?: () => void;
  icon?: React.FC<HTMLAttributes<Element>>;
  iconClassName?: string;
  style?: {
    inline?: boolean;
    transparent?: boolean;
    noStyle?: boolean;
    textSize?: 'minimal' | 'xxs' | 'xs' | 'sm' | 'base' | 'lg';
    customClassName?: string;
  };
};

const KeyValueItem: React.FC<KeyValueItemProps> = props => {
  const { t } = useTranslation('app');
  const IconItem = props.icon;

  // Add this line to determine if we should use the small icon
  const useSmallIcon =
    props.style?.textSize === 'xs' ||
    props.style?.textSize === 'xxs' ||
    props.style?.textSize === 'minimal';

  return (
    <div
      data-component="KeyValueItem"
      data-label={`KeyValueItem-${props.keyName}`}
      aria-label={`KeyValueItem-${props.keyName}`}
      className={cn(
        'group',
        'relative',
        'flex flex-1',
        !props.style?.noStyle && 'bg-app-panel/50',
        { 'cursor-pointer': !props.isDisabled },
        { 'cursor-default': props.isDisabled },
        props.className,
      )}
    >
      <button
        data-component="KeyValueItemAction"
        type="button"
        onClick={e => {
          props.onClick && props.onClick();
        }}
        disabled={props.isDisabled}
        className={cn([
          'flex flex-1',
          'w-full',
          { 'bg-alerts-alert text-menu-text': props.hasIssue },
          {
            'rounded px-1 py-1 text-xs shadow xl:text-base':
              props.keyName && !props.style?.noStyle,
            'items-center': props.icon,
            'xl:items-top items-start': !props.icon,
            'bg-app-panel-light/50 text-menu-text hover:bg-menu-active/80 hover:text-menu-active-text':
              !props.isActive && !props.isDisabled && !props.hasIssue,
            'bg-app-panel-dark/60 text-menu-text hover:bg-app-panel-dark/50 hover:text-menu-text':
              props.isActive && !props.hasIssue && !props.style?.noStyle,
            'border-app-background/50 border hover:rounded group-hover:rounded':
              props.isDisabled && !props.style?.noStyle,
            'hover:rounded-r-none group-hover:rounded-l group-hover:rounded-r-none':
              props.isRemovable && !props.isDisabled,
          },
        ])}
      >
        {props.icon && (
          <IconItem
            className={classNames(
              'fill-current',
              props.iconClassName
                ? props.iconClassName
                : 'h-5 w-5 xl:h-6 xl:w-6 ltr:ml-1 ltr:mr-2 rtl:ml-2 rtl:mr-1',
            )}
          />
        )}
        <div
          data-component="containerKeyValue"
          className={cn([
            'flex flex-1',
            'w-full',
            props.style?.inline
              ? 'border-menu-50 flex-row items-center py-2'
              : 'flex-col',
            'whitespace-pre-line',
            props.style?.customClassName,
          ])}
        >
          {props.keyName && (
            <div
              data-component="keyName"
              className={cn([
                'select-all',
                'flex items-start',
                'ltr:pr-1 rtl:pl-1',
                props.style?.textSize || 'text-xs uppercase opacity-75',
                props.style?.inline ? 'ltr:mr-0.5 rtl:ml-0.5' : 'my-2',
                props.classNameKeyName,
              ])}
            >
              {props.keyName}
              <span className={cn('mx-px')}>{`:`}</span>
            </div>
          )}

          {props.keyValue && (
            <div
              data-component="keyValue"
              className={cn([
                'select-all',
                'flex flex-1 items-center',
                'text-start',
                !props.style?.noStyle && 'bg-app-panel-dark rounded',
                !props.style?.inline && 'p-3',
                'font-bold',
                'text-menu-text/75',
                props.truncate && 'truncate',
                props.style?.textSize || 'text-xs',
                props.classNameKeyValue,
              ])}
            >
              <span className={cn(props.truncate && 'truncate')}>
                {props.keyValue}
              </span>
            </div>
          )}
        </div>
      </button>

      {props.isRemovable && (
        <button
          data-component="ButtonRemove"
          type="button"
          onClick={e => {
            e.stopPropagation();
            props.onRemoveClick && props.onRemoveClick();
          }}
          className={cn([
            'relative',
            '-ml-px px-1 py-1',
            'hover:rounded-r group-hover:rounded-r',
            'hidden group-hover:flex',
            'transition duration-200',
            'rounded-r',
            {
              'bg-app-panel/70 text-menu-text hover:bg-menu-hover hover:text-menu-text group-hover:text-menu-text':
                !props.isActive,
              'bg-app-panel-dark/70 text-menu-active hover:bg-menu/50 hover:text-menu-active':
                props.isActive,
              'hover:bg-app-panel-light hover:text-menu-text':
                !props.isDisabled,
              'bg-opacity-50': props.isDisabled,
            },
          ])}
        >
          <Icon.CircleX className={cn('h-5 w-5 fill-current')} />
        </button>
      )}

      {props.isCopyable && (
        <div
          className={cn(
            'p-1',
            'hidden group-hover:flex',
            'absolute',
            '-top-2',
            '-right-2',
          )}
        >
          <CopyToClipboardButton
            hasIconSmall={useSmallIcon}
            hasIcon={!useSmallIcon}
            value={`${props.keyName ? props.keyName + ':\n' : ''}${props.keyValue}`}
          />
        </div>
      )}

      {props.isCopyableValueOnly && (
        <div
          className={cn(
            'p-1',
            'hidden group-hover:flex',
            'absolute',
            '-top-2',
            '-right-2',
          )}
        >
          <CopyToClipboardButton
            hasIconSmall={useSmallIcon}
            hasIcon={!useSmallIcon}
            value={`${props.keyValue}`}
          />
        </div>
      )}
      {props.children}
    </div>
  );
};

export default KeyValueItem;
