import {
  Field,
  ID,
  InputAndObjectType,
} from '@warebee/shared/util-backend-only-types';
import {
  Filter,
  FilterBase,
  FilterIntersection,
  FilterUnion,
} from './filter.model';

@InputAndObjectType()
export class PolicyFilter extends FilterBase implements Filter<string> {
  @Field(() => ID)
  type: string;
}

@InputAndObjectType()
export class PolicyFilterIntersection
  implements FilterIntersection<PolicyFilter>
{
  @Field(() => ID, { nullable: true })
  id?: string;

  @Field(() => [PolicyFilter])
  allOf: PolicyFilter[];
}

@InputAndObjectType()
export class PolicyFilterUnion implements FilterUnion<PolicyFilter> {
  @Field(() => [PolicyFilterIntersection])
  anyOf: PolicyFilterIntersection[];
}
