import classNames from 'classnames';
import _ from 'lodash';
// import 'prismjs/themes/prism-okaidia.css';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { format } from 'sql-formatter';
import { AsyncLoadStatus } from '../../../common/types';
import { cn } from '../../../common/utils';
import { FormErrorField } from '../../../components/FormError';
import { Button } from '../../../components/actions/Button';
import ButtonSwitchMulti from '../../../components/actions/ButtonSwitchMulti';
import CopyToClipboardButton from '../../../components/actions/CopyToClipboardButton';
import CodeEditor from '../../../components/editors/CodeEditor';
import { Container } from '../../../components/layout/ContainerFlex';
import { Spacer } from '../../../components/layout/Spacer';
import { ActionBar } from '../../../components/nav/ActionBar';
import PanelContainer from '../../../containers/PanelContainer';
import { createDefaultMapping } from '../../store/import.helper';
import {
  importJob,
  importMappingSettingsByType,
  importRawPreview,
  importTransformedPreview,
  importTransformedPreviewStatus,
  importTypeCurrent,
} from '../../store/import.state';
import useApplyImportTransformation from '../../useApplyImportTransformation';
import useApplyMappingSettings from '../../useApplyMappingSettings';

const DEFAULT_SQL_TEMPLATE_INPUT = `SELECT 
  * 
FROM 
  {{#input}}`;

const DEFAULT_SQL_TEMPLATE_DATASET = `SELECT 
  * 
FROM 
  {{#dataset[DATASET_ID]}}`;

const TEMPLATE_VARIABLES = {
  input: '{{#input}}',
  dataset: '{{#dataset[DATASET_ID]}}',
};

export type ImporterTransformExpressionEditor = {
  actionBefore?: React.ReactNode;
  actionAfter?: React.ReactNode;
};
const ImporterTransformExpressionEditor: React.FC<
  ImporterTransformExpressionEditor
> = props => {
  const { t } = useTranslation('importer');
  const datasetType = useRecoilValue(importTypeCurrent);
  const [mapping] = useRecoilState(importMappingSettingsByType(datasetType));
  const applySettings = useApplyMappingSettings();
  const status = useRecoilValue(importTransformedPreviewStatus);
  const { id: jobId } = useRecoilValue(importJob);
  const previewData = useRecoilValue(importRawPreview);
  const [transformedData, setTransformedData] = useRecoilState(
    importTransformedPreview,
  );
  const [callTransformation, cancelTransformation] =
    useApplyImportTransformation();
  const [sqlQuery, setSqlQuery] = useState(mapping.transformation?.query);
  const hasTransformation = mapping?.transformation?.enabled;

  useEffect(() => {
    if (hasTransformation && _.isEmpty(sqlQuery)) {
      setSqlQuery(
        datasetType === 'dataset'
          ? `${DEFAULT_SQL_TEMPLATE_DATASET}`
          : `${DEFAULT_SQL_TEMPLATE_INPUT}`,
      );
    }
  }, [hasTransformation, sqlQuery, datasetType]);

  function applyTransformation(isEnabled: boolean) {
    let newMapping = _.cloneDeep(mapping);
    if (!isEnabled) {
      setTransformedData(null);
      newMapping = createDefaultMapping(newMapping, previewData.fields);
    }

    applySettings({
      skipValidation: true,
      mappingSettings: {
        ...newMapping,
        transformation: {
          enabled: isEnabled,
          query: sqlQuery,
        },
      },
    });

    isEnabled && callTransformation({ jobId, query: sqlQuery });
  }

  const isLoading = status === AsyncLoadStatus.Loading;

  const formatSqlWithTemplates = (sql: string) => {
    // Temporarily replace all template variables with valid SQL identifiers
    // Format: {{...}} -> "TEMP_TEMPLATE_n"
    let templateMap = new Map();
    let templateCounter = 0;

    const tempSql = sql.replace(/{{[^}]+}}/g, match => {
      const placeholder = `"TEMP_TEMPLATE_${templateCounter}"`;
      templateMap.set(placeholder, match);
      templateCounter++;
      return placeholder;
    });

    // Format the SQL
    const formattedSql = format(tempSql, {
      language: 'trino',
    });

    // Restore all template variables
    return formattedSql.replace(/"TEMP_TEMPLATE_\d+"/g, placeholder =>
      templateMap.get(placeholder),
    );
  };

  return (
    <>
      <PanelContainer
        id={`import-data-transform-code`}
        collapsible
        isAccordion
        title={
          <div className={classNames('flex flex-1 items-center', 'gap-1')}>
            {props.actionBefore}

            {/* <div className="flex-1">{t`Transform Logic`}</div>
            <div onClick={e => e.stopPropagation()}>
              <InputCheckbox
                isDisabled={isLoading}
                label={t`Use transformation`}
                isSelected={mapping?.transformation?.enabled}
                onChange={v => applyTransformation(v)}
              >{t`Use transformation`}</InputCheckbox>
            </div> */}
            <div
              className="flex items-center"
              onClick={e => e.stopPropagation()}
            >
              <ButtonSwitchMulti
                autoSize
                className=""
                buttonType={
                  mapping?.transformation?.enabled ? 'switchon' : 'minimal'
                }
                // buttonType="switchon"
                classNameLabel="text-xxs py-0.5 px-2"
                options={[
                  { label: t('Use transformation') },
                  { label: t('Off') },

                  // {
                  //   icon: (
                  //     <Icon.Code
                  //       className={classNames('h-5 w-5', 'fill-current')}
                  //     />
                  //   ),
                  // },
                ]}
                selectedIndex={mapping?.transformation?.enabled ? 0 : 1}
                onClick={() =>
                  applyTransformation(!mapping?.transformation?.enabled)
                }
              />

              {/* <ButtonSwitchMulti
                autoSize
                className="!py-0.5"
                buttonType={'minimal'}
                // buttonType="switchon"
                classNameLabel="text-xxs !py-0.5 px-2"
                options={[
                  {
                    icon: (
                      <Icon.CirclePlus
                        className={classNames(
                          'h-5 w-5',
                          'fill-current',
                          'text-menu-text',
                          'flex-1',
                          'opacity-30',
                        )}
                      />
                    ),
                  },
                ]}
                selectedIndex={0}
                onClick={() =>
                  applyTransformation(!mapping?.transformation?.enabled)
                }
                disabled
              /> */}
            </div>
            {props.actionAfter}
          </div>
        }
      >
        <Container col className="relative">
          <div className="flex h-full overflow-hidden">
            <CodeEditor
              value={sqlQuery}
              onChange={query => setSqlQuery(query)}
              language={'trinosql'}
              placeholder={
                datasetType === 'dataset'
                  ? DEFAULT_SQL_TEMPLATE_DATASET
                  : DEFAULT_SQL_TEMPLATE_INPUT
              }
              className={
                mapping?.transformation?.enabled ? 'flex-1' : 'opacity-50'
              }
            />
            {/* 
            <Editor
              className="flex-1"
              value={sqlQuery}
              onValueChange={query => setSqlQuery(query)}
              highlight={code =>
                _.isEmpty(code) ? code : highlight(code, sql)
              }
              style={{
                position: 'relative',
                overflowY: 'auto',
                fontFamily: 'monospace',
                fontSize: 14,
                paddingBottom: '3rem',
              }}
              placeholder={
                datasetType === 'dataset'
                  ? DEFAULT_SQL_TEMPLATE_DATASET
                  : DEFAULT_SQL_TEMPLATE_INPUT
              }
            /> */}
          </div>

          <ActionBar sticky stickyBottom>
            <CopyToClipboardButton
              className={cn('rounded-none')}
              // title={t`Copy Query`}
              hasIcon
              hasIconSmall
              value={sqlQuery}
            />
            <Spacer flexspace />
            <Button
              buttonSize="sm"
              label={t`Prettify`}
              buttonType="secondary"
              isLoading={isLoading}
              isDisabled={isLoading}
              onPress={() => setSqlQuery(formatSqlWithTemplates(sqlQuery))}
            />
            <Button
              buttonSize="sm"
              label={isLoading ? t`Transforming...` : t`Transform`}
              buttonType="primary"
              isLoading={isLoading}
              hasIconBefore={isLoading}
              isDisabled={isLoading}
              onPress={() => applyTransformation(true)}
            />
          </ActionBar>
        </Container>
      </PanelContainer>
      {!_.isEmpty(transformedData?.errors) && (
        <PanelContainer
          id={`import-data-transform-code-error`}
          collapsible
          className="bg-alerts-error/70"
          title={t`Transform Errors`}
        >
          <FormErrorField message={_.head(transformedData.errors)} />
        </PanelContainer>
      )}
    </>
  );
};
export default ImporterTransformExpressionEditor;
