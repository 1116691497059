import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { cn } from '../../common/utils';
import { InputSearch } from '../inputs/InputSearch';
import LoadingIndicator from '../LoadingIndicator';
import { IconChevronDirection } from './IconChevronDirectional';
import LinkTraced from './LinkTraced';

export type NavBreadcrumbsItem = {
  title: string;
  href?: string;
};

export type NavBreadcrumbsProps = {
  children?: React.ReactNode;
  items: NavBreadcrumbsItem[];
  className?: string;
  hasSearch?: boolean;
  isLoading?: boolean;
  isLoadingLabel?: string;
};

const NavBreadcrumbs: React.FC<NavBreadcrumbsProps> = props => {
  const { items, className = '' } = props;
  const { t } = useTranslation('simulation');

  return (
    <nav
      data-component="NavBreadcrumbs"
      id="navigation-breadcrumbs"
      className={cn('flex flex-1 items-center', 'w-full', className)}
      aria-label="Breadcrumb"
    >
      <ol
        className={cn(
          'flex flex-1 items-center',
          'w-full',
          'text-xs md:text-base xl:text-lg',
        )}
      >
        {items
          .filter(item => !_.isEmpty(item.title))
          .map((item, index) => {
            const isCurrent = index === items.length - 1;
            const chevronClass = isCurrent
              ? 'text-menu-active/50'
              : 'text-app-header-text/50';
            const textClass = isCurrent
              ? 'font-bold text-menu-active'
              : 'text-app-header-text';
            return (
              <li
                data-component="NavBreadcrumbItem"
                key={`breadcrumb-item-${item.title}-${index}`}
                className={cn('group flex min-w-0 items-center')}
              >
                <div className={cn('flex min-w-0 flex-1 items-center')}>
                  {index !== 0 ? (
                    <IconChevronDirection className={chevronClass} />
                  ) : null}
                  {item.href ? (
                    <LinkTraced
                      to={item.href}
                      className={cn(
                        'ltr:ml-0.5 rtl:mr-0.5',
                        'group-hover:text-menu-hover',
                        'max-w-full truncate',
                        textClass,
                      )}
                      titleTrace={item.title}
                      aria-current={isCurrent ? 'page' : undefined}
                    >
                      {item.title}
                    </LinkTraced>
                  ) : (
                    <div
                      className={cn(
                        'ltr:ml-0.5 rtl:mr-0.5',
                        'max-w-full truncate',
                        textClass,
                      )}
                    >
                      {item.title}
                    </div>
                  )}
                </div>
              </li>
            );
          })}
        {props.isLoading && (
          <li className={cn('flex items-center', 'mx-2')}>
            <LoadingIndicator
              circleSize={20}
              circleStroke={8}
              transparent
              oneLiner
            >
              {props.isLoadingLabel && (
                <div className="mx-2 text-xs opacity-75">
                  {props.isLoadingLabel}
                </div>
              )}
            </LoadingIndicator>
          </li>
        )}
      </ol>

      {props.children}
      {props.hasSearch && <InputSearch placeholder={t`Search`} />}
    </nav>
  );
};

export default NavBreadcrumbs;
