import {
  AllocationPolicyInput,
  AllocationSettingsFragment,
} from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  toDateFromLocaleStringDate,
  toLocaleDateTimeString,
} from '../../../common/dateTimeHelper';
import { cn, getIndexedTitle } from '../../../common/utils';
import InboxZero from '../../../components/InboxZero';
import { Button } from '../../../components/actions/Button';
import * as Icon from '../../../components/icons';
import DateRangePicker from '../../../components/inputs/calendar/DateRangePicker';
import { Container } from '../../../components/layout/ContainerFlex';
import SectionOptional from '../../../components/layout/SectionOptional';
import TitleSection from '../../../components/layout/TitleSection';
import { StatusTag } from '../../../components/nav/StatusTag';
import PolicyRuleSectionTitle from '../../../components/policies/PolicyRuleSectionTitle';
import { PolicyWell } from '../../../components/policies/PolicyWell';
import { collapsibleStateAtom } from '../../../store/collapsible.state';
import { sidebarStateByType } from '../../../store/sidebar.state';
import useUpdateSimulation from '../../hooks/useUpdateSimulation';
import {
  ALLOCATION_POLICY_DEFAULT_RULE_ID,
  getAllocationPolicyInput,
} from '../../store/allocation/allocation.helper';
import {
  allocationPolicy,
  deallocationPolicySelectedIdentity,
} from '../../store/allocationPolicy/allocationPolicy.state';
import { simulationOrderSetSummary } from '../../store/simulation.layout.state';
import {
  simulationCurrent,
  simulationIsEditable,
} from '../../store/simulation.state';
import AllocationPolicyRule from './AllocationPolicyRule';

const AllocationPolicy: React.FC = () => {
  const { t } = useTranslation('simulation');
  const orderSetSummary = useRecoilValue(simulationOrderSetSummary);
  const [sim, updateSim] = useRecoilState(simulationCurrent);
  const [policy, setPolicy] = useRecoilState(allocationPolicy);
  const [selectedIdentity, setSelectedIdentity] = useRecoilState(
    deallocationPolicySelectedIdentity,
  );
  const [policyEditPanelState, setPolicyEditPanelState] = useRecoilState(
    sidebarStateByType('sidebar-policy-allocation-editor'),
  );
  const [collapsible, setCollapsible] = useRecoilState(collapsibleStateAtom);
  const canUpdate = useRecoilValue(simulationIsEditable);

  const [savedPolicy, setSavedPolicy] = useState<AllocationPolicyInput>(null);
  const updateSimulation = useUpdateSimulation();

  // // trigger policy saving and policy check request when AP changed
  useEffect(() => {
    const policyToSave = getAllocationPolicyInput(policy);

    const isPolicyChanged = !_.isEqual(policyToSave, savedPolicy);
    const isInitial = savedPolicy === null;
    setSavedPolicy(policyToSave);
    if (!isPolicyChanged) return;

    if (!isInitial) {
      updateSimulation({
        allocationSettings: {
          ...sim.allocationSettings,
          allocationPolicy: policyToSave,
        },
      });
    }
  }, [policy]);

  useEffect(() => {
    return () => {
      setPolicyEditPanelState({
        ...policyEditPanelState,
        isCollapsed: true,
        isHidden: true,
      });
      setSelectedIdentity(null);
    };
  }, []);

  function updateAllocationSettings(
    patch: Partial<AllocationSettingsFragment>,
  ) {
    updateSim(current => ({
      ...current,
      allocationSettings: {
        ...(current.allocationSettings ?? {}),
        ...patch,
      },
    }));

    updateSimulation({
      allocationSettings: {
        ...(sim.allocationSettings ?? {}),
        ...patch,
      },
    });
  }

  const removeRule = (ruleId: string) => {
    if (selectedIdentity?.ruleId === ruleId) {
      setSelectedIdentity(null);
    }
    setPolicy({
      ...policy,
      rules: _.filter(policy.rules, rule => rule.id !== ruleId),
    });
  };

  const addRule = () => {
    const ruleId = nanoid();

    const ruleTitle = getIndexedTitle(
      new Set(_.map(policy.rules, r => r.title)),
      t`Allocate Rule #`,
    );
    setPolicy({
      ...policy,
      rules: [
        ...(policy.rules ?? []),
        {
          id: ruleId,
          title: ruleTitle,
          itemsMatch: { anyOf: [] },
        },
      ],
    });

    setCollapsible({
      ...collapsible,
      [ruleId]: { isCollapsed: false },
    });
  };

  const hasDateRange = !_.isNil(orderSetSummary?.dateRange);
  const range = [
    orderSetSummary?.dateRange?.from,
    orderSetSummary?.dateRange?.to,
  ];
  const fromRaw = sim.allocationSettings?.orderSetFilter?.orderDate?.from;
  const from = fromRaw ? toDateFromLocaleStringDate(fromRaw) : new Date();
  const toRaw = sim.allocationSettings?.orderSetFilter?.orderDate?.to;
  const to = toRaw ? toDateFromLocaleStringDate(toRaw) : new Date();

  const hasRules = !_.isEmpty(policy?.rules);
  const rulesCount = _.size(policy?.rules);

  function moveRule(from, to) {
    const newRules = [...policy.rules];
    newRules.splice(to, 0, newRules.splice(from, 1)[0]);
    setPolicy({
      ...policy,
      rules: newRules,
    });
  }

  function getPolicyHeaderContent() {
    if (canUpdate && hasRules) {
      return (
        <Button
          label={t`Add Policy`}
          className={classNames('rounded ltr:ml-4 rtl:mr-4')}
          buttonSize="xs"
          buttonType="primary"
          hasIconAfter={<Icon.CirclePlus className={`h-5 w-5 fill-current`} />}
          onPress={() => addRule()}
        />
      );
    }

    if (!canUpdate) {
      return (
        <StatusTag
          title={t`Policy Locked`}
          type="locked"
          modeStyle="stroke"
          icon={Icon.Lock}
          onPress={null}
        />
      );
    }

    return null;
  }

  return (
    <Container col hasOverflowY>
      {/* <ScreenTitle
        title={t`Allocation Policy`}
        subtitle={t`Allocation Policy`}
        isSticky
        icon={Icon.PolicyAssignment}
      /> */}

      <TitleSection
        title={t`Allocation Settings`}
        collapsible
        inPanelView
        hasScreenTitle
        classNameInner="bg-app-panel-dark/50 space-y-2 p-1 lg:p-2 xl:p-4"
      >
        {hasDateRange && (
          <SectionOptional
            id={'allocation-limit-by-date'}
            title={t`Limit date`}
            value={!_.isNil(sim.allocationSettings?.orderSetFilter?.orderDate)}
            onChange={enabled =>
              updateAllocationSettings({
                orderSetFilter: {
                  orderDate: enabled
                    ? {
                        from: range[0],
                        to: range[1],
                      }
                    : null,
                },
              })
            }
            classNameChildren="!px-4"
            helpNavTo={'simulation/allocate/simulation-allocate#limit-date'}
          >
            <DateRangePicker
              headerMode
              classNameInput="flex-1"
              value={[from, to]}
              minValue={
                range?.[0] ? toDateFromLocaleStringDate(range?.[0]) : null
              }
              maxValue={
                range?.[1] ? toDateFromLocaleStringDate(range?.[1]) : null
              }
              onChange={v =>
                updateAllocationSettings({
                  orderSetFilter: {
                    orderDate: {
                      from: toLocaleDateTimeString(v[0]),
                      to: toLocaleDateTimeString(v[1]),
                    },
                  },
                })
              }
              onResetClick={() =>
                updateAllocationSettings({
                  orderSetFilter: {
                    orderDate: {
                      from: range[0],
                      to: range[1],
                    },
                  },
                })
              }
              showTimeMenu={true}
              showDateMenu={true}
              // headerMode
            />
          </SectionOptional>
        )}
        <SectionOptional
          id={'allocation-use-order-demand'}
          title={t`Limit Source`}
          onLabel={t`Stock Data`}
          offLabel={t`Order Data`}
          helpNavTo={
            'simulation/allocate/simulation-allocate#allocation-settings'
          }
          value={
            !(
              sim.allocationSettings?.requirementSource?.useOrderSetDemand ===
              false
            )
          }
          onChange={enabled =>
            updateAllocationSettings({
              requirementSource: {
                useOrderSetDemand: enabled,
              },
            })
          }
        />

        <SectionOptional
          id={'allocation-limit-by-assignment'}
          title={t`Limit to`}
          onLabel={t`Stock`}
          offLabel={t`Max. Capacity`}
          value={!!sim.allocationSettings?.stockLimitSettings?.enabled}
          helpNavTo={'simulation/allocate/simulation-allocate#limit-to'}
          onChange={enabled =>
            updateAllocationSettings({
              stockLimitSettings: {
                enabled: enabled,
              },
            })
          }
        />
      </TitleSection>

      <TitleSection
        title={
          <PolicyRuleSectionTitle isFeasible={true} counter={rulesCount} />
        }
        className={classNames('z-400 top-12 xl:top-20')}
        inPanelView
        hasScreenTitle
        hasAction={getPolicyHeaderContent()}
      />

      {/* <AllocationPolicyRuleDetailWatcher /> */}
      <Container col flex1 hasOverflowY>
        <PolicyWell fullHeight isDisabled={!canUpdate} isCentred={!hasRules}>
          {!hasRules && (
            <InboxZero selfCenter hasIcon message={t`No Rules found`}>
              {canUpdate && (
                <div
                  className={classNames(
                    'mt-4 flex flex-col items-center space-y-2 xl:flex-row xl:space-y-0',
                  )}
                >
                  <Button
                    full
                    buttonSize="xs"
                    buttonType="primary"
                    label={t`Add Policy`}
                    className="w-full flex-1 rounded"
                    onPress={addRule}
                    hasIconAfter={
                      <Icon.CirclePlus className={`h-5 w-5 fill-current`} />
                    }
                  />
                </div>
              )}
            </InboxZero>
          )}

          {_.map(policy?.rules, (rule, index) => {
            return (
              <AllocationPolicyRule
                isDisabled={!canUpdate}
                key={`allocation-rule-${rule.id}`}
                ruleId={rule.id}
                index={index + 1}
                isRemovable={canUpdate}
                canDelete={canUpdate}
                canRename={canUpdate}
                onDeleteClick={() => removeRule(rule.id)}
                canMoveUp={index > 0}
                onMoveUp={() => moveRule(index, index - 1)}
                canMoveDown={index < rulesCount - 1}
                onMoveDown={() => moveRule(index, index + 1)}
              />
            );
          })}
        </PolicyWell>

        <TitleSection title={t`Default Policy`} inPanelView />
        <PolicyWell
          data-component="PolicyWellAllocationDefault"
          fullHeight
          isDisabled={!canUpdate}
          className={cn('mb-2 flex-1')}
          classNameChildren={cn('space-y-2 pb-10')}
        >
          <AllocationPolicyRule
            isDisabled={!canUpdate}
            key={`allocation-rule-default`}
            ruleId={ALLOCATION_POLICY_DEFAULT_RULE_ID}
            // index={index + 1}
            isRemovable={false}
            canDelete={false}
            canRename={false}
            // onDeleteClick={() => removeRule(rule.id)}
          />
        </PolicyWell>
      </Container>
    </Container>
  );
};

export default AllocationPolicy;
