import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import ScreenHeader from './ScreenHeader';
import { Spacer } from './layout/Spacer';
import NavBreadcrumbs, { NavBreadcrumbsItem } from './nav/NavBreadcrumbs';

export type AppHeaderProps = PropsWithChildren & {
  warehouseId: string;
  breadcrumbItems: NavBreadcrumbsItem[];
};

const AppHeaderImporter: React.FC<AppHeaderProps> = props => {
  const { t } = useTranslation('app');

  return (
    <ScreenHeader
      backPath={`/wh/i/${props.warehouseId}/data`}
      subtitle={t`WareBee Importer`}
      logo
    >
      <NavBreadcrumbs items={props.breadcrumbItems} />

      <Spacer flexspace />
      {props.children}
    </ScreenHeader>
  );
};

export default React.memo(AppHeaderImporter);
