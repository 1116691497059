import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cn } from '../common/utils';
import * as Icon from '../components/icons';
import { Button } from './actions/Button';
import { InputValue } from './inputs/InputValue';
import UserTag from './UserTag';

export type UserTagsEditorProps = {
  tags: string[];
  onChange: (tags: string[]) => void;
  label?: string;
};

const UserTagsEditor: React.FC<UserTagsEditorProps> = props => {
  const { t } = useTranslation('app');
  const [newTagTitle, setNewTagTitle] = useState('');

  function addTag() {
    props.onChange(_.uniq([...props.tags, _.trim(newTagTitle)]));
    setNewTagTitle('');
  }

  function removeTag(tag: string) {
    props.onChange(_.filter(props.tags, v => v !== tag));
  }

  return (
    <div data-component="UserTagsEditor" className="flex flex-1 flex-col">
      {props.label && (
        <div className={cn('text-menu-text mb-2 text-sm')}>
          {props.label}
          {/* {required && <span className={classNames('opacity-75')}>{'*'}</span>}
        {readonly && <span className={classNames('opacity-75')}>{'—'}</span>} */}
        </div>
      )}

      <div
        className={cn(
          'bg-app-input/50',
          'border-menu-active/50 border',
          'mt-1',
          'flex flex-wrap gap-1',
          'items-start justify-start justify-items-start',
          'rounded',
          'px-2 py-4',
        )}
      >
        {_.map(_.sortBy(props.tags), tag => (
          <UserTag
            title={tag}
            enabled
            removable
            onRemove={() => removeTag(tag)}
            noColor
          />
        ))}
        <div className="flex flex-1 items-center">
          <InputValue
            className={
              cn(props.tags.length === 0 ? 'flex-1' : '')
              // 'h-8',
              // 'flex-1',
            }
            placeholder={t`tag...`}
            classNameValue={classNames(
              '!text-xs',
              '!rounded-l-full',
              '!px-2 !py-1',
              'border-menu-active/50 border',
              'bg-app-panel/30 active:bg-app-panel focus:bg-app-panel/75 text-menu-active',
            )}
            key={`tag-title-editor`}
            value={newTagTitle}
            onChange={setNewTagTitle}
            roundedLeft
          />
          <Button
            // label={t`Add`}
            className={classNames('rounded-r-full !py-0.5')}
            buttonSize="xs"
            buttonType="primary"
            hasIconAfter={
              <Icon.CirclePlus className={cn('h-5 w-5 fill-current')} />
            }
            onPress={() => addTag()}
          />
        </div>
      </div>
    </div>
  );
};

export default UserTagsEditor;
