import _ from 'lodash';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cn } from '../../../common/utils';
import CopyToClipboardButton from '../../../components/actions/CopyToClipboardButton';
import CodeEditor from '../../../components/editors/CodeEditor';
import { Container } from '../../../components/layout/ContainerFlex';
import { Spacer } from '../../../components/layout/Spacer';
import { ActionBar } from '../../../components/nav/ActionBar';

export type ImporterSQLPreviewProps = {
  data: Object[];
};

const jsonToSQL = (json: Object): string => {
  const keys = Object.keys(json);
  const columns = keys.map(key => `"${key}"`).join(',\n  ');
  return `
SELECT
  ${columns}
FROM
  {{#input}}
`;
};

const ImporterSqlPreview: React.FC<ImporterSQLPreviewProps> = props => {
  const { t } = useTranslation('importer');
  const [currentIndex, setCurrentIndex] = useState(0);

  const sqlQuery = jsonToSQL(props.data[currentIndex]);

  return (
    <Container col className="relative">
      <div className="h-full overflow-hidden">
        <ActionBar sticky stickyTop>
          <CopyToClipboardButton
            className={cn('rounded-none')}
            // title={t`Copy Query`}
            hasIcon
            hasIconSmall
            value={sqlQuery}
          />
          <Spacer flexspace />
        </ActionBar>

        <CodeEditor
          value={sqlQuery}
          onChange={_.noop}
          language="trinosql"
          // placeholder="Enter your SQL query here..."
          editorOptions={{ readOnly: true }}
          // height="300px"
        />

        {/* <Editor
          value={sqlQuery}
          disabled={true}
          onValueChange={_.noop}
          highlight={code => (_.isEmpty(code) ? code : highlight(code, sql))}
          // highlight={code => Prism.highlight(code, Prism.languages.sql, 'sql')}
          padding={10}
          style={{
            position: 'relative',
            overflowY: 'auto',
            fontFamily: 'monospace',
            fontSize: 12,
            paddingBottom: '3rem',
          }}
        /> */}
      </div>
    </Container>
  );
};

export default ImporterSqlPreview;
