import { t } from 'i18next';
import { APP_VER } from '../common/environmentVariables';
import { cn } from '../common/utils';
// import * as Icon from '../components/icons';

export type ComponentProps = {
  className?: string;
};

export const AppVersion = (props: ComponentProps) => {
  // const [id] = useState<string>(_.uniqueId());

  return (
    <div
      className={cn(
        'flex flex-1 items-center text-center text-xs',
        props.className,
      )}
    >
      <div>{t`v.`}</div>
      <div className={cn('ltr:ml-1 rtl:mr-1')}>{APP_VER}</div>
    </div>
  );
};
