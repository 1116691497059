import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { PanelBody } from '../../components/designer/panels/PanelBody';
import { ImportFileFooterStats } from '../../components/ImportFileFooterStats';
import { InputGroupList } from '../../components/inputs/InputGroupList';
import InputText from '../../components/inputs/InputText';
import InputTextArea from '../../components/inputs/InputTextArea';
import { ContainerCol } from '../../components/layout/ContainerFlex';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import { Spacer } from '../../components/layout/Spacer';
import UserTagsEditor from '../../components/UserTagsEditor';
import { warehouseCanUpdate } from '../../store/warehouse.state';
import useUpdateItemSet from '../hooks/useUpdateItemSet';
import { itemSetDocument } from '../store/itemSet.state';

const ItemSetStartPanel: React.FC = () => {
  const { t } = useTranslation('dataset');
  // const [itemSet, setItemSet] = useRecoilState(itemSetDocument);
  const [document, setDocument] = useRecoilState(itemSetDocument);

  const canUpdate = useRecoilValue(warehouseCanUpdate);
  const updateDocument = useUpdateItemSet();

  const changeTitle = (title: string) => {
    setDocument({ ...document, title: title });
    updateDocument({ itemSetId: document.id, title: title });
  };
  const changeDescription = (description: string) => {
    setDocument({ ...document, description: description });
    updateDocument({ itemSetId: document.id, description: description });
  };

  const updateUserTags = (userTags: string[]) => {
    setDocument({ ...document, userTags });

    updateDocument({
      itemSetId: document.id,
      userTags,
    });
  };

  return (
    <PanelBody className="h-full">
      <ContainerCol overflow>
        <ScreenTitle isSticky title={t`Properties`} subtitle={t`Items Set`} />
        <InputGroupList hasPadding hasSpacing>
          <InputText
            required
            placeholder={t`Item Set Name`}
            label={t`Item Set Name`}
            name={t`Item Set Name`}
            value={document.title}
            onChange={changeTitle}
            isDisabled={!canUpdate}
            animate={!canUpdate && !document.title}
          />
          <InputTextArea
            optional
            label={t`Item Set Description`}
            placeholder={t`About the Item Set...`}
            value={document.description ?? ''}
            onChange={changeDescription}
            isDisabled={!canUpdate}
            animate={!canUpdate && !(document.description ?? '')}
          />
          <UserTagsEditor
            label={t`Tags`}
            tags={document.userTags ?? []}
            onChange={updateUserTags}
          />
        </InputGroupList>

        <Spacer flexspace />
        <ImportFileFooterStats
          className="mb-2 ltr:pl-2 rtl:pr-2"
          updatedDate={document.updatedAt}
          createdDate={document.createdAt}
          importSource={document.inputFileName}
        />
      </ContainerCol>
    </PanelBody>
  );
};

export default ItemSetStartPanel;
