import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import PanelContainer from '../../../containers/PanelContainer';
import { SettingsEngineAllocate } from '../settings/SettingsEngineAllocate';

export type PanelProps = PropsWithChildren & {
  className?: string;
};

const PanelSystemEngineOptions: React.FC<PanelProps> = props => {
  const { t } = useTranslation('app');

  return (
    <PanelContainer
      id="panel-system-run-options"
      title={t`Run Options`}
      collapsible
      hasPadding
    >
      <SettingsEngineAllocate />

      {props.children}
    </PanelContainer>
  );
};

export default PanelSystemEngineOptions;
