import React from 'react';
import { useTranslation } from 'react-i18next';
import { getQualitativeColor } from '../common/color.helper';
import { cn } from '../common/utils';
import * as Icon from './icons';

export type QueryTagProps = {
  title?: string;
  enabled?: boolean;
  actionable?: boolean;
  removable?: boolean;
  noColor?: boolean;

  className?: string;

  onClick?: () => void;
  onRemove?: () => void;
};

const QueryTag: React.FC<QueryTagProps> = props => {
  const { t } = useTranslation('app');
  const [bgColor, textColor] = getQualitativeColor(props.title, 'tags');
  return (
    <div
      data-component="QueryTag"
      className={cn(
        'group:querytag',
        'flex items-center',
        'rounded-full border px-1 py-0.5',
        {
          'cursor-pointer': props.actionable,
        },
        props.noColor &&
          (props.actionable
            ? 'border-menu hover:border-menu-actve/80 bg-app-panel hover:bg-menu-active hover:text-menu-active-text hover:shadow-lg'
            : 'border-menu bg-app-panel'),
        props.enabled &&
          props.noColor &&
          'bg-menu-active text-menu-active-text shadow-md',
        props.className,
      )}
      style={{
        backgroundColor: props.enabled && !props.noColor ? bgColor : null,
        color: props.enabled && !props.noColor ? textColor : null,
        borderColor: props.noColor ? null : bgColor,
      }}
      onClick={props.onClick}
    >
      <span
        title={props.title}
        className={cn('select-text', 'text-xs', 'px-3', {
          'ltr:pr-1': props.removable,
        })}
      >
        {props.title}
      </span>
      {props.removable && (
        <span
          title={t`Remove tag`}
          className="group-hover:querytag:opacity-75 cursor-pointer"
          onClick={e => {
            e.stopPropagation();
            props.onRemove();
          }}
        >
          <Icon.CircleX className="h-5 w-5 fill-current" />
        </span>
      )}
    </div>
  );
};

export default QueryTag;
