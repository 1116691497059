import _ from 'lodash';
import { atom, selector } from 'recoil';
import { persistAtom } from '../common/recoil/persistAtom';
import { whiteLabelConfigs } from './whitelabel.types';

export const getDefaultBrandConfig = () =>
  Object.values(whiteLabelConfigs).find(config => config.default);

export const themeIds = Object.values(whiteLabelConfigs)
  .filter(config => config.themes?.name)
  .map(config => config.themes!.name);

export type ThemeId = keyof typeof whiteLabelConfigs;

const getKey = (postfix: string) => `warebee-global-${postfix}`;

export const importTriggeredBySim = atom<string>({
  key: getKey('import-triggered-by'),
  default: null,
});

export const billingStatusVisibilityState = persistAtom({
  key: 'billingStatusVisibility',
  default: true,
});

export const calloutTypes: CalloutType[] = [
  'tip',
  'suggestion',
  'caution',
  'check',
  'note',
  'warning',
  'action',
  'counter',
];

export const helperCalloutVisibilityState = persistAtom<
  Record<CalloutType, boolean>
>({
  key: 'helperCalloutVisibility',
  default: {
    tip: true,
    suggestion: true,
    caution: true,
    check: true,
    note: true,
    warning: true,
    action: true,
    counter: true,
  },
});

export const getWhiteLabelConfigValue = selector({
  key: getKey('config-value'),
  get:
    ({ get }) =>
    (path: string) => {
      const selectedTheme = get(appThemeSelectedId);
      const brandKey = selectedTheme?.toUpperCase();

      if (brandKey && whiteLabelConfigs[brandKey]) {
        return _.get(whiteLabelConfigs[brandKey], path);
      }

      const defaultConfig = getDefaultBrandConfig();
      if (defaultConfig) {
        return _.get(defaultConfig, path);
      }

      return null;
    },
});

export const brandName = selector({
  key: getKey('brand-name'),
  get: ({ get }) => {
    const getValue = get(getWhiteLabelConfigValue);
    return getValue('appName') || 'WareBee';
  },
});

export const brandID = selector({
  key: getKey('brand-id'),
  get: ({ get }) => {
    const getValue = get(getWhiteLabelConfigValue);
    return getValue('id') || 'WareBee';
  },
});

export const themeName = selector({
  key: getKey('theme-name'),
  get: ({ get }) => {
    const selectedTheme = get(appThemeSelectedId);
    return selectedTheme
      ? whiteLabelConfigs[selectedTheme]?.themes.name
      : getDefaultBrandConfig().themes.name; // Use the default config
  },
});

export const applyThemePreference = (theme: ThemeId) => {
  const root = window.document.documentElement;
  _.map(themeIds, id => root.classList.remove(id));
  !_.isNil(theme) && root.classList.add(theme);
};

export const appThemeSelectedId = persistAtom<ThemeId>({
  key: getKey('theme-id'),
  default: null,
});

export const appBrandSelectedId = persistAtom<ThemeId>({
  key: getKey('app-brand-id'),
  default: 'WAREBEE',
});

export const appCoBrandSelectedId = persistAtom<ThemeId>({
  key: getKey('app-co-brand-id'),
  default: null,
});

export const appIsEmbeddedState = atom<boolean>({
  key: 'appIsEmbeddedState',
  default: false,
});

export const analyticsUserState = persistAtom<boolean>({
  key: getKey('state-analytics'),
  default: true,
});

export const chatState = persistAtom<boolean>({
  key: getKey('state-chat'),
  default: true,
});

export type CalloutType =
  | 'tip'
  | 'suggestion'
  | 'caution'
  | 'check'
  | 'note'
  | 'warning'
  | 'action'
  | 'counter';
