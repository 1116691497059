import { DBFieldType } from '../../datasetObject/store/datasetObject.types';
import { ActualityHqDataColumn } from './datasetQueries/actualityHqDataRows';

export const actualityHqFieldTypesBase: Partial<
  Record<ActualityHqDataColumn, DBFieldType>
> = {
  eventId: 'varchar',
  eventProcessType: 'varchar',
  eventType: 'varchar',
  consignee: 'varchar',
  sku: 'varchar',
  uom: 'varchar',
  quantity: 'integer',
  scanCode: 'varchar',
  warehouseAreaSource: 'varchar',
  locationIdSource: 'varchar',
  warehouseArea: 'varchar',
  locationId: 'varchar',
  agentType: 'varchar',
  agentId: 'varchar',
  agentUser: 'varchar',
  agentEnergy: 'double',
  agentSignalStrength: 'double',
  eventStartTime: 'timestamp',
  jobId: 'varchar',
  jobLine: 'integer',
  jobGroupId: 'varchar',
  datasetObjectId: 'varchar',
  eventEndTime: 'timestamp',
  importJobId: 'varchar',
  skuKey: 'varchar',
  jobKey: 'varchar',
  pickByAssignment: 'boolean',
  skuGroup: 'varchar',
  name: 'varchar',
  description: 'varchar',
  subGroup: 'varchar',
  transportClass: 'varchar',
  stockCategory: 'varchar',
  storageClass: 'varchar',
  pollutionClass: 'varchar',
  lowerUom: 'varchar',
  netWeight: 'double',
  length: 'double',
  width: 'double',
  height: 'double',
  volume: 'double',
  unitsPerLowestUom: 'double',
  ean: 'varchar',
  upc: 'varchar',
  layoutLocationId: 'varchar',
  locationOrder: 'varchar',
  locationStatus: 'boolean',
  locationLevel: 'integer',
  locationLength: 'double',
  locationWidth: 'double',
  locationHeight: 'double',
  locationWeight: 'double',
  locationBayId: 'varchar',
  locationBayTitle: 'varchar',
  locationBayPosition: 'integer',
  locationDepthPosition: 'integer',
  locationUsageType: 'varchar',
  locmhtype: 'varchar',
  locationRackingType: 'varchar',
  layoutWarehouseArea: 'varchar',
  locationSide: 'varchar',
  congestionZone: 'varchar',
  locationBayProjection: 'double',
  locationHeightFromFloor: 'double',
  locationDepthFromFront: 'double',
  locationIndexFromFront: 'integer',
  bayType: 'varchar',
  aisleId: 'varchar',
  aisleTitle: 'varchar',
  planeId: 'varchar',
  planeTitle: 'varchar',
  assignmentItem: 'varchar',
  lastEventTime: 'double',
  orderLineCount: 'integer',
  quantityOrdered: 'integer',
  percentRank: 'double',
  cumulativePercentRank: 'double',
  totalDaysOrdered: 'integer',
  totalWeeksOrdered: 'integer',
  maxDailyLinesCount: 'integer',
  minDailyLinesCount: 'integer',
  avgDailyLinesCount: 'double',
  maxWeeklyLinesCount: 'integer',
  minWeeklyLinesCount: 'integer',
  avgWeeklyLinesCount: 'double',
};
