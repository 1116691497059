import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatInteger } from '../common/formatHelper';
import { cn } from '../common/utils';
import { TagDataType } from '../helpContext/TagDataType';
import { Stat } from './stats/Stat';

export type DatasetObjectFieldSummary = {
  name: string;
  type?: string[];
  hasData?: boolean;
  distinctValueCount?: number;
  numberRange?: [number, number];
  valueCounters?: Record<string, number>;
  totalSum?: number;
};

type DatasetFieldSummaryProps = {
  field: DatasetObjectFieldSummary;
};

const DatasetFieldSummary: React.FC<DatasetFieldSummaryProps> = props => {
  const { field } = props;
  const hasIssue = _.size(field.type) > 1;
  return (
    <li key={field.name}>
      <Stat
        key={field.name}
        inPanelMode
        canCopy
        hasIssue={!field.hasData}
        titleElement={
          <>
            <div className="flex items-center">
              <div>{field.name}</div>
              {_.map(field.type, t => (
                <TagDataType
                  key={t}
                  className={!field.hasData ? 'bg-alerts-warn' : ''}
                >
                  {t}
                </TagDataType>
              ))}
            </div>
          </>
        }
        value={formatInteger(field.distinctValueCount)}
      />
    </li>
  );
};

export type DatasetFieldsSummaryProps = {
  fields: DatasetObjectFieldSummary[];
};

const DatasetFieldsSummary: React.FC<DatasetFieldsSummaryProps> = props => {
  const { t } = useTranslation('importer');

  if (!props.fields || props.fields.length === 0) {
    return null;
  }

  return (
    <ul
      data-component="DatasetFieldsSummary"
      className={cn('w-full space-y-0.5 p-1')}
    >
      {props.fields.map(f => (
        <DatasetFieldSummary key={f.name} field={f} />
      ))}
    </ul>
  );
};

export default DatasetFieldsSummary;
