import { Transition } from '@headlessui/react';
import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import HelpNavigate from '../helpContext/HelpNavigate';
import { CollapsibleId } from '../store/collapsible.default';
import { HelpNavTo, HelpIndex } from '../helpContext/help.default';

export type SwitchContainerProps = PropsWithChildren & {
  id: CollapsibleId;
  title: string;
  classNameHeader?: string;
  classNameInner?: string;
  classNameChildren?: string;
  className?: string;
  hasAction?: React.ReactNode;
  disabled?: boolean;
  isOpen?: boolean;
  hasInnerPadding?: boolean;
  helpNavTo?: HelpNavTo<HelpIndex>;
};

const SwitchContainer: React.FC<SwitchContainerProps> = props => {
  const { isOpen } = props;
  return (
    <div
      data-component="SwitchContainer"
      className={classNames(
        'text-menu-text',
        {
          'rounded shadow-xl': isOpen && !props.disabled,
        },
        {
          'bg-app-panel rounded shadow-xl': isOpen && props.disabled,
        },
        props.className,
      )}
    >
      <div
        className={classNames(
          'z-container group',
          'sticky top-0',
          'px-1 py-2 xl:px-2 xl:py-3',
          'flex items-center',
          'transition delay-75 duration-100 ease-in-out',
          {
            'bg-app-panel/40': isOpen,
          },
          {
            'bg-app-panel/5 rounded': !isOpen,
          },
          {
            'rounded-t': isOpen && props.children,
          },
          props.classNameHeader,
        )}
      >
        {props.helpNavTo && <HelpNavigate href={props.helpNavTo} />}

        <h6 className="mr-0 flex h-full w-full flex-1 flex-col items-start lg:flex-row lg:items-center">
          <span
            data-component="Title"
            className={classNames(
              'w-full flex-1',
              'truncate',
              'px-1',
              'text-start text-sm',
              'mb-0 xl:mb-0',
            )}
          >
            {props.title}
          </span>
          {props.hasAction}
        </h6>
      </div>
      {props.children && (
        <Transition
          show={isOpen}
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
          className={props.classNameInner}
        >
          <section
            data-component="SwitchContainerBody"
            className={classNames(
              'text-xs lg:text-sm',
              'z-0',
              'bg-app-panel/20',
              props.hasInnerPadding && 'p-0.5 pb-3 pt-2 lg:px-1 2xl:px-2',
              'rounded-b',
              props.classNameChildren,
            )}
          >
            {props.children}
          </section>
        </Transition>
      )}
    </div>
  );
};

export default SwitchContainer;
