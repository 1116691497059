import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { getHeatmapBucketByValue } from '../../common/heatmap.helper';
import { cn } from '../../common/utils';
import ButtonLocateLocation from '../../components/ButtonLocateLocation';
import { ColumnConfig } from '../../components/DatasetTable';
import { useActualityEffectiveMetricDescriptors } from '../../metrics/useActualityEffectiveMetricDescriptors';
import ActualityEntityTag from '../components/ActualityIEntityTag';
import {
  actualityEffectiveHeatmapBuckets,
  actualityEffectiveHeatmapRangeDescriptor,
  actualityMetricSelected,
} from '../store/actuality.heatmap.state';
import { ActualityHeatmapDataRow } from '../store/datasetQueries/actualityHeatmapData';

function useActualityHeatmapDataTableConfig(): ColumnConfig<ActualityHeatmapDataRow>[] {
  const { t } = useTranslation('feed');

  const heatmapMetricId = useRecoilValue(actualityMetricSelected);
  const metricDescriptorMap = useActualityEffectiveMetricDescriptors();
  const buckets = useRecoilValue(actualityEffectiveHeatmapBuckets);
  const rangeDescriptor = useRecoilValue(
    actualityEffectiveHeatmapRangeDescriptor,
  );

  const bucketsMap = _.keyBy(buckets, b => b.id);
  const metricDescriptor = metricDescriptorMap[heatmapMetricId];

  function renderDimensionValue(v: any) {
    const bucket = getHeatmapBucketByValue({
      data: {
        [metricDescriptor.path]: v,
      },
      buckets: bucketsMap,
      metricDescriptor: metricDescriptor as any,
      rangeDescriptor,
    });

    let valueString = metricDescriptor.format
      ? metricDescriptor.format(v)
      : v.toString();

    return (
      <span
        className={cn(
          'p-1 px-2',
          'ltr:ml-2 rtl:mr-2',
          'text-xs',
          'rounded-sm',
          'flex items-center',
        )}
        style={{
          background: bucket?.color,
          color: bucket?.textColor,
        }}
        title={v}
      >
        {valueString}
      </span>
    );
  }

  const columnsConfig: ColumnConfig<ActualityHeatmapDataRow>[] = [
    {
      field: 'locationId',
      title: t`Location`,
      isHeader: true,
      hasFilter: true,
      render: (locationId: string, row) => (
        <ButtonLocateLocation locationId={locationId} />
      ),
    },
    {
      field: 'consignee',
      title: t`Consignee`,
      hasFilter: true,
      render: (consignee: string, row) => {
        if (!row) return null;
        return (
          <ActualityEntityTag
            entity="item"
            title={consignee}
            filters={{ consignee: row['consignee'], sku: row['sku'] }}
          />
        );
      },
    },
    {
      field: 'sku',
      title: t`SKU`,
      hasFilter: true,
      render: (sku: string, row) => {
        if (!row) return null;
        return (
          <ActualityEntityTag
            entity="item"
            title={sku}
            filters={{ consignee: row['consignee'], sku }}
          />
        );
      },
    },
    {
      field: 'dimensionValue',
      title: metricDescriptor.title,
      render: renderDimensionValue,
    },
  ];

  return columnsConfig;
}

export default useActualityHeatmapDataTableConfig;
