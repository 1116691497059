import { LayoutImportConverterLocationFragment } from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  getDefaultMeasure,
  getMeasureTitle,
} from '../../../../common/measureHelper';
import useFormatter from '../../../../common/useFormatter';
import InboxZero from '../../../../components/InboxZero';
import { WarehouseNavigator } from '../../../../components/WarehouseNavigator';
import { WarehouseNavigatorItem } from '../../../../components/WarehouseNavigatorItem';
import DropdownSelector from '../../../../components/actions/DropdownSelector';
import * as Icon from '../../../../components/icons';
import InputNumber from '../../../../components/inputs/InputNumber';
import { Container } from '../../../../components/layout/ContainerFlex';
import TitleSection from '../../../../components/layout/TitleSection';
import { StatListItem } from '../../../../components/stats/StatListItem';
import PanelContainer from '../../../../containers/PanelContainer';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../../../containers/SidebarContainer';
import { warehouseSelected } from '../../../../store/warehouse.state';
import {
  converterEditableArea,
  converterSelectedArea,
} from '../store/converter.area.state';
import {
  converterLayoutSelectedBay,
  converterLayoutSelectedLocation,
} from '../store/converter.layout.state';
import { converterLocationPatchSetter } from '../store/converter.state';
import AreaSummary from './AreaSummary';

const ConverterLocationSidebarCmp: React.FC = () => {
  const { t: tMeasures } = useTranslation('measures');
  const { t } = useTranslation('designer');
  const formatter = useFormatter();

  const wh = useRecoilValue(warehouseSelected);

  const area = useRecoilValue(converterEditableArea);
  const location = useRecoilValue(converterLayoutSelectedLocation);
  const bay = useRecoilValue(converterLayoutSelectedBay);
  const setPatches = useSetRecoilState(converterLocationPatchSetter);
  const areaSelected = useRecoilValue(converterSelectedArea);

  if (!location) {
    if (areaSelected) {
      return <AreaSummary />;
    }
    return (
      <InboxZero
        message={t`No Location selected`}
        message_helper={t`Click on bay map to select`}
        hasIcon
        selfCenter
      />
    );
  }
  function patch(p: Partial<LayoutImportConverterLocationFragment>) {
    setPatches({
      areaId: area.id,
      bayId: location.locationBayId,
      patch: { ...p, locationId: location.locationId },
    });
  }
  const sizeMeasureValue = getMeasureTitle(
    getDefaultMeasure(wh.measurementSystem, 'size'),
    tMeasures,
  );

  // Volume - Calculated
  const volumeCalculatedRaw =
    location.locationLength * location.locationWidth * location.locationHeight;
  const volumeCalculated = formatter.formatVolume(volumeCalculatedRaw);

  return (
    <>
      <WarehouseNavigator>
        <WarehouseNavigatorItem
          name={t`Level`}
          value={location.locationLevel}
          active
        />
        <WarehouseNavigatorItem
          name={t`Location`}
          value={location.locationId}
          active
        />
      </WarehouseNavigator>
      <Container col hasOverflowY key={`location-props-${location.locationId}`}>
        <PanelContainer
          id="panel-object-property-location-designer"
          title={t`Location Properties`}
          // collapsible
          // hasPadding
        >
          <TitleSection
            inPanelView
            title={t`Status`}
            classNameInner="p-1 xl:p-4"
          >
            <DropdownSelector
              className="my-1 text-xs"
              panelMode
              label={t`Status`}
              value={location.locationStatus ? 'Enabled' : 'Disabled'}
              values={['Disabled', 'Enabled']}
              onChange={value => {
                const newStatus = value === 'Enabled';
                if (location.locationStatus !== newStatus) {
                  patch({ locationStatus: newStatus });
                }
              }}
            />
          </TitleSection>

          <TitleSection
            inPanelView
            title={t`Properties`}
            classNameInner="p-1 xl:p-4"
          >
            <StatListItem
              title={t`Racking Type (Bay)`}
              value={bay?.details?.bayType ?? '—'}
              titleClass={'text-xs'}
              valueClass={'text-xxs'}
              canCopy
            />

            <StatListItem
              title={t`Location Type`}
              value={location.locationRackingType ?? '—'}
              titleClass={'text-xs'}
              valueClass={'text-xxs'}
              canCopy
            />
            <StatListItem
              title={t`Picking Order`}
              value={location.locationOrder ?? '—'}
              titleClass={'text-xs'}
              valueClass={'text-xxs'}
              canCopy
            />
            <StatListItem
              title={t`Work Zone`}
              value={location.congestionZone ?? '—'}
              titleClass={'text-xs'}
              valueClass={'text-xxs'}
              canCopy
            />

            <StatListItem
              title={t`Side`}
              value={location.locationSide}
              titleClass={'text-xs'}
              valueClass={'text-xxs'}
              canCopy
            />
            <StatListItem
              title={t`Bay Order`}
              value={bay?.details?.locationOrder ?? '—'}
              titleClass={'text-xs'}
              valueClass={'text-xxs'}
              canCopy
            />
            {/* {location.locationIndexFromFront && (
            <StatListItem
              title={t`Position Index`}
              value={location.locationIndexFromFront.toFixed(0) ?? '—'}
              titleClass={'text-xs'}
              valueClass={'text-xxs'}
            />
          )} */}
            <StatListItem
              title={t`Position in Bay`}
              value={location.locationBayPosition.toFixed(0) ?? '—'}
              titleClass={'text-xs'}
              valueClass={'text-xxs'}
              canCopy
            />
            {location.locationDepthPosition && (
              <StatListItem
                title={t`Position Depth`}
                value={location.locationDepthPosition.toFixed(0) ?? '—'}
                titleClass={'text-xs'}
                valueClass={'text-xxs'}
                canCopy
              />
            )}
            {location.locationUsageType && (
              <StatListItem
                title={t`Usage Type`}
                value={location.locationUsageType ?? '—'}
                titleClass={'text-xs'}
                valueClass={'text-xxs'}
                canCopy
              />
            )}
          </TitleSection>
          <TitleSection inPanelView title={t`Size`} classNameInner="p-1 xl:p-4">
            <InputNumber
              title={t`Width`}
              icon={<Icon.ObjectBoxSideX className="h-8 w-8 fill-current" />}
              value={location.locationWidth}
              unitOfMeasure={sizeMeasureValue}
              onChange={v => patch({ locationWidth: v })}
              range={[1, null]}
            />
            <InputNumber
              title={t`Height`}
              icon={<Icon.ObjectBoxSideY className="h-8 w-8 fill-current" />}
              value={location.locationHeight}
              unitOfMeasure={sizeMeasureValue}
              onChange={v => patch({ locationHeight: v })}
              range={[1, null]}
            />
            <InputNumber
              title={t`Length`}
              icon={<Icon.ObjectBoxSideZ className="h-8 w-8 fill-current" />}
              value={location.locationLength}
              unitOfMeasure={sizeMeasureValue}
              onChange={v => patch({ locationLength: v })}
              range={[1, null]}
            />
            {/* <InputNumber
            title={t`Weight`}
            icon={<Icon.ObjectBoxSideZ className="h-8 w-8 fill-current" />}
            value={location.locationWeight}
            unitOfMeasure={``}
            onChange={v => patch({ locationWeight: v })}
            range={[0, null]}
          /> */}
            <InputNumber
              title={t`HFF`}
              icon={<Icon.MeasurementRuler className="h-8 w-8 fill-current" />}
              value={location.locationHeightFromFloor}
              unitOfMeasure={sizeMeasureValue}
              disabled
            />

            <InputNumber
              title={t`Volume`}
              icon={<Icon.AxisXyz className="h-8 w-8 fill-current" />}
              value={volumeCalculated.raw}
              unitOfMeasure={volumeCalculated.unit}
              disabled
              range={[1, null]}
            />
            {location.locationDepthFromFront > 0 && (
              <StatListItem
                title={
                  <>
                    <div
                      className={classNames(
                        'text-menu-text m-1 ml-0 flex h-10 w-10 items-center p-1 shadow-md ltr:mr-2 rtl:ml-2',
                        'bg-menu-100 rounded bg-opacity-50',
                        // 'hover:bg-brand',
                      )}
                    >
                      <Icon.AxisXyz className="h-8 w-8 fill-current" />
                    </div>
                    <span className="ml-1">{t`Depth`}</span>
                  </>
                }
                styleInput
                value={location.locationDepthFromFront ?? '—'}
                unitOfMeasure={sizeMeasureValue}
              />
            )}
            <InputNumber
              title={t`Bay Width (External)`}
              icon={<Icon.AisleWidth className="h-8 w-8 fill-current" />}
              value={bay.details.width}
              unitOfMeasure={sizeMeasureValue}
              disabled
            />
          </TitleSection>
        </PanelContainer>
      </Container>
    </>
  );
};

export default () => {
  const { t } = useTranslation('designer');

  const containerProps: SidebarContainerProps = {
    type: 'sidebar-converter-info-location',
    title: t`Location Info`,
    size: 'xs',
  };

  return (
    <SidebarContainer key={containerProps.type} {...containerProps}>
      <ConverterLocationSidebarCmp />
    </SidebarContainer>
  );
};
