import { endOfDay } from 'date-fns';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Button } from '../../../../components/actions/Button';
import * as Icon from '../../../../components/icons';
import DateRangePicker from '../../../../components/inputs/calendar/DateRangePicker';
import { resourcePolicyAgentById } from '../../../store/resourcePolicy.state';
import { getWorkforceShiftSettingsDefault } from '../../../store/workforce.helper';
import {
  workforceScheduleSetting,
  workforceSimulateInterval,
} from '../../../store/workforce.state';
import { WorkforceShiftSettings } from '../../../store/workforce.types';
import WorkforceShift from './WorkforceShift';

export type WorkforceScheduleSettingsProps = {
  agentId: string;
  scheduleId: string;
};

const WorkforceScheduleSettings: React.FC<
  WorkforceScheduleSettingsProps
> = props => {
  const { t } = useTranslation('simulation');
  const [scheduleSettings, setScheduleSettings] = useRecoilState(
    workforceScheduleSetting(props.scheduleId),
  );
  const forecastSimulationPeriod = useRecoilValue(workforceSimulateInterval);
  const agent = useRecoilValue(resourcePolicyAgentById(props.agentId));

  const setInterval = (start: Date, end: Date) => {
    setScheduleSettings({
      ...scheduleSettings,
      period: {
        absolute: {
          start: start.getTime(),
          end: endOfDay(end).getTime(),
        },
      },
    });
  };

  const updateShift = (shift: WorkforceShiftSettings) => {
    setScheduleSettings({
      ...scheduleSettings,
      shiftSettings: {
        ...scheduleSettings.shiftSettings,
        [shift.id]: shift,
      },
    });
  };

  const removeShift = (shiftId: string) => {
    setScheduleSettings({
      ...scheduleSettings,
      shiftSettings: _.omit(scheduleSettings.shiftSettings, shiftId),
    });
  };

  const addShift = () => {
    const shift: WorkforceShiftSettings = getWorkforceShiftSettingsDefault(
      agent,
      _.map(scheduleSettings?.shiftSettings, s => s.title),
    );
    setScheduleSettings({
      ...scheduleSettings,
      shiftSettings: {
        ...scheduleSettings.shiftSettings,
        [shift.id]: shift,
      },
    });
  };

  return (
    <div
      data-component="WorkforceAgentSimulationSetup"
      className={`my-1 flex flex-col rounded shadow-xl`}
    >
      <div className="p-2 shadow-xl">
        {/* //bg-app-panel-dark/60 rounded */}
        {!scheduleSettings.isDefault && (
          <>
            <div className="text-menu-active flex-1 rounded p-1 text-xs">{t`Time Schedule`}</div>
            <div className="mb-2 flex-1">
              <DateRangePicker
                headerMode
                value={[
                  new Date(scheduleSettings.period.absolute.start),
                  new Date(scheduleSettings.period.absolute.end),
                ]}
                onChange={range => setInterval(...range)}
              />
            </div>
          </>
        )}

        {_.map(scheduleSettings.shiftSettings, shift => (
          <WorkforceShift
            key={shift.id}
            shift={shift}
            setShift={updateShift}
            onDelete={() => removeShift(shift.id)}
          />
        ))}

        <Button
          className="my-2 rounded"
          buttonType="primary"
          buttonSize="xs"
          isDisabled={false}
          full
          hasIconAfter
          onPress={addShift}
          label={t`Add shift`}
          buttonIcon={<Icon.Plus className={`h-3 w-3 fill-current`} />}
        />
      </div>
    </div>
  );
};

export default WorkforceScheduleSettings;
