import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { JsonObject } from 'type-fest';
import { formatInteger } from '../common/formatHelper';
import { DatasetObjectFieldSummary } from './DatasetFieldsSummary';
import { ColumnConfig } from './DatasetTable';
import { StatListItem } from './stats/StatListItem';

export type DatasetTableColumnSummaryProps = {
  columnConfig: ColumnConfig<JsonObject>;
  summary: DatasetObjectFieldSummary;
};

const DatasetTableColumnSummary: React.FC<
  DatasetTableColumnSummaryProps
> = props => {
  const { t } = useTranslation('importer');

  return (
    <div className="flex w-full flex-1 flex-col">
      {props.summary.totalSum > 0 && (
        <StatListItem
          title={t`Total:`}
          value={formatInteger(props.summary.totalSum)}
          className="bg-app-panel-stat !m-0 h-full !border-0"
          titleClass="text-minimal"
        />
      )}

      {props.summary.distinctValueCount > 0 && (
        <StatListItem
          title={t`Distinct:`}
          value={formatInteger(props.summary.distinctValueCount)}
          className="bg-app-panel-stat !m-0 h-full !border-0"
          titleClass="text-minimal"
        />
      )}
      {!_.isEmpty(props.summary.valueCounters) &&
        _.map(props.summary.valueCounters, (value, key) =>
          value > 0 ? (
            <StatListItem
              key={`columns-stats-counter-${key}`}
              title={key}
              value={formatInteger(value)}
              className="bg-app-panel-stat !m-0 h-full !border-0"
              titleClass="text-minimal"
            />
          ) : null,
        )}
    </div>
  );
};

export default DatasetTableColumnSummary;
