import {
  SkipLimitPageSpec,
  SortDirection,
  StringSearchFilter,
  StringSearchFilterType,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { datasetQueryBuilder } from './queryBuilder/datasetQueryBuilder';

export type GenericDatasetDataSortBy = {
  direction?: SortDirection;
  field: string;
};

export type GenericDatasetDataFilterBy = Partial<
  Record<string, StringSearchFilter>
>;

export type GenericDatasetDataRowsBuilderPagedParams = {
  datasetId?: string;
  sortBy?: GenericDatasetDataSortBy;
  filterBy?: GenericDatasetDataFilterBy;
  page?: SkipLimitPageSpec;
};

export function getGenericDatasetDataRowsQuery(
  params: GenericDatasetDataRowsBuilderPagedParams,
) {
  let query = datasetQueryBuilder
    .selectFrom(`{{#dataset[${params.datasetId}]}}`)
    .selectAll()

    //sort
    .$if(!_.isNil(params.sortBy?.field), qb =>
      qb.orderBy(
        params.sortBy.field,
        params.sortBy?.direction === SortDirection.ASC ? 'asc' : 'desc',
      ),
    )
    //page
    .offset(params.page?.skip ?? 0)
    .limit(params.page?.limit ?? 100);

  if (params.filterBy) {
    query = _.reduce(
      params.filterBy,
      (q, value, key) => {
        if (
          value?.type === StringSearchFilterType.CONTAINS &&
          !_.isEmpty(value?.value)
        ) {
          return q.where(key as any, 'like', `%${value.value}%`);
        }
        return q;
      },
      query,
    );
  }

  return query;
}

export function getGenericDatasetTotalRowsQuery(
  params: GenericDatasetDataRowsBuilderPagedParams,
) {
  let query = datasetQueryBuilder
    .selectFrom(`{{#dataset[${params.datasetId}]}}`)
    .select(({ fn }) => [fn.countAll<number>().as('totalCount')]);
  if (params.filterBy) {
    query = _.reduce(
      params.filterBy,
      (q, value, key) => {
        if (
          value?.type === StringSearchFilterType.CONTAINS &&
          !_.isEmpty(value?.value)
        ) {
          return q.where(key as any, 'like', `%${value.value}%`);
        }
        return q;
      },
      query,
    );
  }

  return query;
}
