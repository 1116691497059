import { Popover } from '@headlessui/react';
import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ErrorContainer from '../containers/ErrorContainer';
import { ErrorDetails } from '../store/error.state';
import { Button } from './actions/Button';
import * as Icon from './icons';
import { Spacer } from './layout/Spacer';
import { ActionBar } from './nav/ActionBar';

export type ErrorProps = {
  errors: ErrorDetails[];
  onClose: (index: number[]) => void;
  closable?: boolean;
};

type ErrorGroup = {
  indexes: number[];
  errors: ErrorDetails[];
};

const ErrorPopover: React.FC<ErrorProps> = props => {
  const { t } = useTranslation('errors');

  const [showDetails, setShowDetails] = useState<boolean>(false);

  const errorGroups: Record<string, ErrorGroup> = _.reduce(
    props.errors,
    (acc, error, index) => {
      const groupKey = error.title;
      return {
        ...acc,
        [groupKey]: {
          indexes: [...(acc[groupKey]?.indexes ?? []), index],
          errors: [...(acc[groupKey]?.errors ?? []), error],
        },
      };
    },
    {},
  );

  return (
    <Popover
      data-component="ErrorPopover"
      className={`z-alert absolute top-1 select-text ltr:right-1 rtl:left-1`}
    >
      {_.map(errorGroups, (errorGroup, groupTitle) => {
        const count = errorGroup.indexes.length;
        const suffix =
          count > 1
            ? t(`And {{errorCount}} more errors.`, { errorCount: count - 1 })
            : '';

        const er = _.head(errorGroup.errors);
        return (
          <Popover.Panel
            key={`error-popover-${groupTitle}`}
            static
            className={`mb-4 w-80 rounded px-0 py-1 text-white`}
          >
            <div
              className={`bg-alerts-error/70 backdrop-saturate-110 flex flex-col rounded-t p-2 backdrop-blur-lg`}
            >
              <div className={`mb-2 flex items-start`}>
                <div
                  className={`flex flex-1 items-start text-xl ltr:pl-1 rtl:pr-1`}
                >
                  <Icon.TriangleInfo className="h-6 w-6 fill-current pb-0.5 pt-1 ltr:ml-1 ltr:mr-1 rtl:ml-1 rtl:mr-1" />
                  <div
                    className={`border-app-panel-dark/30 h-full flex-1 select-text ltr:ml-1 ltr:border-l ltr:pl-2 rtl:mr-1 rtl:border-r rtl:pr-2`}
                  >
                    <div>
                      <span>{groupTitle ? groupTitle : er.details}</span>
                      {suffix && (
                        <span>
                          {` `}
                          {suffix}
                        </span>
                      )}
                    </div>

                    {showDetails && er.callStack && (
                      <button
                        className={classNames(
                          'border-menu flex items-center rounded border px-2 text-sm uppercase opacity-75',
                        )}
                        type="button"
                        onClick={() => setShowDetails(!showDetails)}
                      >
                        {showDetails
                          ? t`Hide Error Details`
                          : t`Show Error Details`}

                        <Icon.MenuDotsHorizontal
                          className={classNames(
                            'h-6 w-6',
                            'pb-0.5 pt-1',
                            'ltr:ml-1 rtl:mr-1',
                            'fill-current',
                          )}
                        />
                      </button>
                    )}
                  </div>
                </div>

                <button
                  type="button"
                  className={classNames(
                    'ltr:ml-4 rtl:pr-4',
                    'hover:bg-app-panel-dark/50 rounded-full',
                    'h-7 w-7',
                    'flex items-center justify-center',
                    'text-menu-text/60 hover:text-menu-text',
                  )}
                  aria-label={`Close Error`}
                  onClick={() => props.onClose(errorGroup.indexes)}
                >
                  <Icon.Close className={classNames('h-4 w-4 fill-current')} />
                </button>
              </div>

              {showDetails && er.callStack && (
                <>
                  <h4
                    className={classNames('mb-1 mt-4 text-xs uppercase')}
                  >{t`Error Details`}</h4>
                  <ErrorContainer
                    icon={Icon.TriangleInfo}
                    id={`error-stack-${er.id}`}
                    title={
                      er.details
                        ? er.details
                        : er.callStack.split(' ').slice(0, 6).join(' ')
                    }
                    collapsible
                  >
                    <div
                      className={classNames(
                        'p-3',
                        'overflow-auto',
                        'max-h-80 min-h-5',
                        'rounded',
                        'border-menu/50 border',
                        'text-xs',
                        'select-text',
                      )}
                    >
                      {er.callStack}
                    </div>
                  </ErrorContainer>
                </>
              )}
            </div>
            <ActionBar
              transparent
              className={classNames(
                'bg-alerts-error/80 backdrop-saturate-110 rounded-b p-2 backdrop-blur-lg',
              )}
            >
              <Spacer flexspace />
              <Button
                buttonSize="xs"
                buttonType="basic"
                className={classNames('border-text-light border')}
                onPress={() => props.onClose(errorGroup.indexes)}
              >{t`Dismiss`}</Button>
            </ActionBar>
          </Popover.Panel>
        );
      })}
    </Popover>
  );
};
export default ErrorPopover;
