import { atom, atomFamily, selectorFamily } from 'recoil';
import { AsyncLoadStatus, DataTableState } from '../../common/types';
import { DatasetObjectDataRows } from '../../datasetObject/store/datasetObject.types';
import { CustomQueryDescriptor } from './customQuery.types';

const getKey = (postfix: string) => `warebee-custom-query-${postfix}`;
type QueryId = string;

export const customQueryDescriptorsAll = atom<
  Record<QueryId, CustomQueryDescriptor>
>({
  key: getKey('queries-all'),
  default: {},
});

export const customQueryDescriptorById = selectorFamily<
  CustomQueryDescriptor,
  QueryId
>({
  key: getKey('by-id'),
  get:
    queryId =>
    ({ get }) =>
      get(customQueryDescriptorsAll)?.[queryId],
  set:
    queryId =>
    ({ get, set }, value: CustomQueryDescriptor) =>
      set(customQueryDescriptorsAll, current => ({
        ...current,
        [queryId]: value,
      })),
});

export const customQueryData = atomFamily<DatasetObjectDataRows, QueryId>({
  key: getKey('table-data'),
  default: {
    totalCount: 0,
    content: [],
    columns: [],
  },
});

export const customQueryErrors = atomFamily<string, QueryId>({
  key: getKey('errors'),
  default: null,
});

export const customQueryDataLoadStatus = atomFamily<AsyncLoadStatus, QueryId>({
  key: getKey('table-data-status'),
  default: AsyncLoadStatus.None,
});

export const customQueryDataState = atomFamily<DataTableState<string>, QueryId>(
  {
    key: getKey('table-data-state'),
    default: {
      sortValues: {},
      searchValues: {},
    },
  },
);

export const customQueryStateAll = atom<Record<QueryId, boolean>>({
  key: getKey('state-all'),
  default: {},
});
export const customQueryStateById = selectorFamily<boolean, QueryId>({
  key: getKey('state-by-id'),
  get:
    queryId =>
    ({ get }) =>
      get(customQueryStateAll)?.[queryId],
  set:
    queryId =>
    ({ get, set }, value: boolean) =>
      set(customQueryStateAll, current => ({
        ...current,
        [queryId]: value,
      })),
});
