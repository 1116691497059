import {
  LoadOrderSetIssueDataDocument,
  OrderSetIssueUnpickableLineReason,
  useRunExportJobMutation,
} from '@warebee/frontend/data-access-api-graphql';
import {
  OrderLineIssuesConverterConfig,
  OrderLineIssuesDataRow,
  OrderLineIssuesExportJobParams,
  getOrderLineIssuesTableRows,
  orderLineIssuesExportJobParams,
} from '@warebee/shared/export-converter';
import { generateExportFilename } from 'libs/shared/export-converter/src/lib/export-filename.helper';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { AsyncLoadStatus } from '../../../common/types';
import DatasetTable, { ColumnConfig } from '../../../components/DatasetTable';
import { Container } from '../../../components/layout/ContainerFlex';
import { brandName } from '../../../store/global.state';
import {
  warehouseSelected,
  warehouseSelectedId,
} from '../../../store/warehouse.state';
import useLoadOrderLinesIssues from '../../hooks/useLoadOrderLinesIssues';
import { getOrderIssueDescriptors } from '../../store/simulation.issues.helper';
import {
  simulationIssueSelected,
  simulationIssuesOrderLinesData,
  simulationIssuesOrderLinesStatus,
} from '../../store/simulation.issues.state';
import { simulationCurrent } from '../../store/simulation.state';
import ItemTag from '../../tags/ItemTag';
import DatasetIssueTag from './DatasetIssueTag';

const OrderLinesIssuesTable: React.FC = () => {
  const { t } = useTranslation('simulation');

  const sim = useRecoilValue(simulationCurrent);
  const brandTitleState = useRecoilValue(brandName);
  const warehouse = useRecoilValue(warehouseSelected);
  const warehouseIdState = useRecoilValue(warehouseSelectedId);

  const orderLinesData = useRecoilValue(simulationIssuesOrderLinesData);
  const orderLinesDataLoadStatus = useRecoilValue(
    simulationIssuesOrderLinesStatus,
  );
  const selectedIssue = useRecoilValue(simulationIssueSelected);
  const [loadCallback, cancelLoad] = useLoadOrderLinesIssues();
  const [runExportCSV] = useRunExportJobMutation();
  const searchValues = {};

  const tableTitle = t`Order Lines with issues`;
  const tableSubTitle = t`Order Lines with issues`;

  function callDataLoad(page = { isAppend: false, skip: 0 }) {
    if (selectedIssue?.group !== 'orderSet') return;

    const filter /*: OrderLinesFilter*/ = {
      consigneeContains: searchValues['consignee'],
      skuContains: searchValues['sku'],
      skuGroupContains: searchValues['skuGroup'],
    };

    cancelLoad();
    loadCallback({
      //filter,
      ...page,
    });
  }

  useEffect(() => {
    callDataLoad();
  }, [selectedIssue]);

  const columnsConfig: ColumnConfig<OrderLineIssuesDataRow>[] = [
    {
      field: 'reason',
      title: t`Issue`,
      hiddenInExport: true,
      render: v => {
        if (_.isNil(v)) {
          return '-';
        }
        return _.map(v, reason => (
          <DatasetIssueTag
            key={reason}
            identity={{
              group: 'orderSet',
              reason: reason as any,
            }}
          />
        ));
      },
    },
    {
      field: 'reasonString',
      title: t`Issue`,
      hiddenInBrowser: true,
    },

    { field: 'orderId', title: t`Order Id`, hasFilter: true },
    {
      field: 'consignee',
      title: t`Client (Consignee)`,
      isHeader: true,
    },
    {
      field: 'sku',
      title: t`Item (SKU)`,
      render: (sku: string, row) => (
        <ItemTag title={sku} filters={{ consignee: row['consignee'], sku }} />
      ),
    },
  ];

  function onLoadNext() {
    callDataLoad({
      isAppend: true,
      skip: itemsCount,
    });
  }

  const converterConfig: OrderLineIssuesConverterConfig = {
    columnsConfig,
    dictionaries: {
      reasons: _.reduce(
        _.values(getOrderIssueDescriptors(t)),
        (acc, i) => ({
          ...acc,
          [i.id]: i.tag,
        }),
        {} as Record<OrderSetIssueUnpickableLineReason, string>,
      ),
    },
  };

  async function startExportCSV() {
    const filename = generateExportFilename({
      exportTitle: t`issues-order-lines`,
      brandTitleState,
      warehouse,
      warehouseIdState,
      sim,
    });

    const key = {
      layoutId: sim.layout?.id,
      assignmentId: sim.assignment?.id,
      itemSetId: sim.itemSet?.id,
      orderSetId: sim.orderSet?.id,
    };
    const variables: OrderLineIssuesExportJobParams = {
      ...orderLineIssuesExportJobParams,
      query: LoadOrderSetIssueDataDocument.loc.source.body,
      config: converterConfig,
      variables: {
        key,
        page: null,
        reason: selectedIssue?.reason as OrderSetIssueUnpickableLineReason,
      },
      filename: `${filename}.csv`,
    };
    const { data, errors } = await runExportCSV({
      variables,
    });

    return {
      errors: errors,
      job: data.createExportJob,
    };
  }

  const isLoading = orderLinesDataLoadStatus === AsyncLoadStatus.Loading;
  const itemsCount = orderLinesData?.content?.length || 0;
  const totalCount = orderLinesData?.totalCount ?? 0;

  const flattenData = getOrderLineIssuesTableRows(
    orderLinesData?.content,
    converterConfig,
  );

  return (
    <Container col hasOverflowY>
      <DatasetTable
        // subtitle={tableSubTitle}
        title={tableTitle}
        isSticky
        id={selectedIssue?.reason}
        columnsConfig={columnsConfig}
        keyFields={['orderId', 'consignee', 'sku']}
        data={flattenData}
        onLoadNext={onLoadNext}
        onSearch={_.noop}
        totalCount={totalCount}
        searchValues={searchValues}
        isLoading={isLoading}
        onStartExportClick={startExportCSV}
        hasCounter
      />
    </Container>
  );
};

export default OrderLinesIssuesTable;
