import classNames from 'classnames';
import React from 'react';

const sortOptions = ['Ascending', 'Descending'] as const;
type SortOptionsTuple = typeof sortOptions;
export type sortOption = SortOptionsTuple[number];

export type TableThProps = {
  children?: React.ReactNode;
  className?: string;
  classNameChildren?: string;
  value?: string | React.ReactNode;
  selectable?: boolean;
  isWide?: boolean;
  isNarrow?: boolean;
  hidden?: boolean;
  hasFilter?: boolean;
  hasWidth?: number;
  cellSize?: string;
  panelMode?: boolean;
  isSticky?: boolean;
  isFull?: boolean;
  rowSpan?: number;
  colSpan?: number;
  hasStickyTitleBefore?: boolean;
  sortable?: boolean;
};

export const TableTh = ({
  children,
  className,
  classNameChildren,
  value,
  selectable,
  isWide,
  isNarrow,
  hasWidth,
  cellSize,
  hidden,
  hasFilter,
  panelMode,
  isSticky,
  rowSpan,
  colSpan,
  isFull,
  hasStickyTitleBefore,
  sortable,
}: TableThProps) => {
  selectable = false;

  return (
    <th
      rowSpan={rowSpan}
      colSpan={colSpan}
      className={classNames(
        { 'top-[5.5rem]': isSticky && hasStickyTitleBefore },
        { 'top-8': panelMode && !hasStickyTitleBefore },
        {
          'sticky top-0 z-50 bg-opacity-80 backdrop-blur backdrop-filter':
            isSticky,
        },
        {
          'w-full': isFull,
        },
        {
          'w-1/2': isWide,
        },
        {
          'w-1/4': isNarrow,
        },
        { 'px-4 py-2': !cellSize },
        { 'px-4 py-3': cellSize === 'lg' },
        { 'px-4 py-2': cellSize === 'md' },
        // { 'p-1 px-2 text-sm': cellSize === 'sm' },
        cellSize === 'sm' && `${hasFilter ? 'p-2' : 'p-1'} px-2 text-sm`,
        cellSize === 'xs' && `${hasFilter ? 'p-2' : 'p-1'} px-2 text-xs`,
        hasWidth && 'w-' + hasWidth,
        'border-app-panel-dark/20 border-r',
        'text-start',
        'bg-table-header text-table-header-text',
        {
          hidden: hidden,
        },
        {
          'min-w-fit pr-1': sortable,
        },
        className,
      )}
    >
      <div
        className={classNames({
          'flex w-full items-end': children || sortable,
        })}
      >
        <div
          className={classNames(
            'flex flex-1',
            sortable ? 'items-end' : 'items-center',
          )}
        >
          {value && (
            <div
              className={classNames(
                // 'max-w-thlong',
                'min-w-fit',
                'truncate',
                {
                  'mt-4 flex-1 pb-1': children || panelMode,
                },
              )}
            >
              {value}
            </div>
          )}
          {children && (
            <div
              data-component="TableHeaderHelper"
              className={classNames(
                'flex',
                // ' items-center',
                'items-end',
                // 'w-full', // issue when there is value

                { 'w-full flex-1': panelMode || isWide || !value },
                classNameChildren,
              )}
            >
              {children}
            </div>
          )}
        </div>

        {/* {sortable && (
          <DropdownSelector
            DropAlignRight
            buttonTransparent
            vertical
            // panelMode
            tableMode
            // className="mx-1 mt-2"
            w_sm
            value={'...'}
            values={[...sortOptions]}
            onChange={(option: sortOption) => {
              switch (option) {
                case 'Ascending':
                  //
                  break;
                case 'Descending':
                  //
                  break;
              }
            }}
          />
        )} */}
      </div>
    </th>
  );
};
