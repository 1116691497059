import cn from 'classnames';
import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import SwitchContainer from '../../containers/SwitchContainer';
import { HelpIndex as HelpId, HelpIndex, HelpNavTo } from '../../helpContext/help.default';
import ButtonSwitchMulti, {
  ButtonSwitchMultiOption,
} from '../actions/ButtonSwitchMulti';

export type SectionOptionalProps = PropsWithChildren & {
  id: string;
  title?: string;
  value?: boolean;
  onChange?: (enabled: boolean) => void;
  className?: string;
  classNameInner?: string;
  classNameChildren?: string;
  inset?: boolean;
  isDisabled?: boolean;
  offLabel?: string;
  onLabel?: string;
  hasInnerPadding?: boolean;
  helpNavTo?: HelpNavTo<HelpIndex>;
};

const SectionOptional: React.FC<SectionOptionalProps> = props => {
  const { t } = useTranslation('app');

  const offLabel = props.offLabel ?? t`Off`;
  const onLabel = props.onLabel ?? t`On`;

  const options: ButtonSwitchMultiOption[] = [
    { label: offLabel },
    { label: onLabel },
  ];

  const isOpen = props.value;
  const selectedIndex = props.value ? 1 : 0;

  return (
    <SwitchContainer
      id={props.id}
      title={props.title}
      className={cn(props.className, { '-mx-1 mb-0 mt-4': props.inset })}
      classNameInner={cn(props.classNameInner, {
        'p-4 mb-4 space-y-2': !props.inset && !props.children,
      })}
      classNameChildren={props.classNameChildren}
      isOpen={isOpen}
      disabled={props.isDisabled}
      hasInnerPadding={props.hasInnerPadding ?? true}
      helpNavTo={props.helpNavTo}
      hasAction={
        <ButtonSwitchMulti
          buttonType="switchon"
          autoSize
          className="text-xs ltr:pr-1 rtl:pl-1"
          selectedIndex={selectedIndex}
          onClick={i => props.onChange(!!i)}
          options={options}
          disabled={props.isDisabled}
        />
      }
    >
      {props.children}
    </SwitchContainer>
  );
};

export default SectionOptional;
