import type { AriaTextFieldProps } from '@react-types/textfield';
import classNames from 'classnames';
import React from 'react';
import { useTextField } from 'react-aria';
import { useTranslation } from 'react-i18next';
import useSharedRef from '../../utils/use-shared-ref';

export type InputTextAreaProps = AriaTextFieldProps & {
  className?: string;
  classNameLabel?: string;
  classNameLabelTitle?: string;
  animate?: boolean;
  optional?: boolean;
  rows?: number;
};

const InputTextArea = React.forwardRef<HTMLTextAreaElement, InputTextAreaProps>(
  (props, ref) => {
    const {
      label,
      className,
      classNameLabel,
      classNameLabelTitle,
      animate,
      optional,
      rows = 3,
      isDisabled,
      isReadOnly,
      ...otherProps
    } = props;

    const { t } = useTranslation('app');
    const sharedRef = useSharedRef<HTMLTextAreaElement>(ref);

    const { labelProps, inputProps } = useTextField(
      {
        ...otherProps,
        label,
        inputElementType: 'textarea',
        isDisabled,
        isReadOnly,
        value: props.value === null ? '' : props.value,
      },
      sharedRef,
    );

    return (
      <label
        {...labelProps}
        className={classNames(
          'flex flex-col',
          isDisabled ? 'text-menu-text/50' : 'text-menu-text',
          classNameLabel,
        )}
      >
        <div className={classNames(classNameLabelTitle ?? 'mb-2 text-sm')}>
          {label}
          {optional && (
            <span className="opacity-50 ltr:ml-2 rtl:mr-2">
              {t('(Optional)')}
            </span>
          )}
        </div>
        <textarea
          {...inputProps}
          ref={sharedRef}
          rows={rows}
          className={classNames(
            'w-full flex-1 p-3 text-lg',
            'bg-app-input/90 text-menu-text',
            'placeholder-opacity-30',
            'shadow focus:shadow-xl active:shadow-xl',
            'focus:outline-none focus:ring-0',
            'focus:placeholder-menu-active-secondary focus:bg-app-input/75',
            'active:text-menu-active active:bg-app-input active:border-opacity-100',
            {
              'border-animation animate-rotate-border-90': animate,
              'read-only:border-menu-active/20 read-only:hover:border-menu-active/10 read-only:text-menu-text/50 read-only:hover:text-menu-text/50 read-only:cursor-not-allowed read-only:shadow-none':
                isReadOnly,
              'disabled:border-menu/10 disabled:hover:border-menu/10 disabled:text-menu-text/75 text-menu-text/50 bg-app-input/60 disabled:cursor-not-allowed':
                isDisabled,
              'border-menu-active/50 placeholder-menu-text/30 hover:border-menu-active hover:text-menu-text hover:placeholder-menu-active-secondary focus:border-menu-active focus:text-menu-text hover:border-opacity-100 focus:border-opacity-100':
                !isDisabled && !isReadOnly,
            },
            className,
          )}
        />
      </label>
    );
  },
);

export default InputTextArea;
