import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { cn } from '../../common/utils';
import InputSlider from '../../components/inputs/InputSlider';
import { ContainerMap } from '../../components/layout/ContainerMap';
import { Spacer } from '../../components/layout/Spacer';
import LoadingIndicator from '../../components/LoadingIndicator';
import { ActionBar } from '../../components/nav/ActionBar';
import StageContainer from '../../layout/stage/StageContainer';
import { getAgentEditorSettings } from '../../resourcePolicy/AgentEditorSettings';
import { warehouseSelected } from '../../store/warehouse.state';
import {
  analyzeGateUsageScheduleDockingDuration,
  analyzeGateUsageScheduleUnDockingDuration,
  analyzeGateUsageScheduleWaitingCost,
} from '../store/analyze.state';
import { scheduleDockCostControlsState } from './schedule.state';
import SimulationJobsTimelineLayer from './SimulationJobsTimelineLayer';
import SimulationJobTimelineDataTable from './SimulationJobTimelineDataTable';

const SimulationJobsTimeline: React.FC = () => {
  const { t } = useTranslation('simulation');
  const wh = useRecoilValue(warehouseSelected);
  const [waitingCost, setWaitingCost] = useRecoilState(
    analyzeGateUsageScheduleWaitingCost,
  );
  const [dockingDuration, setDockingDuration] = useRecoilState(
    analyzeGateUsageScheduleDockingDuration,
  );
  const [undockingDuration, setUndockingDuration] = useRecoilState(
    analyzeGateUsageScheduleUnDockingDuration,
  );
  const [showScheduleDockCostControls, setShowScheduleDockCostControls] =
    useRecoilState(scheduleDockCostControlsState);

  const editorSettings = getAgentEditorSettings(
    t,
    wh.measurementSystem,
    wh.currency,
  );

  const costSettings = editorSettings.hourCost;
  return (
    <>
      <ContainerMap>
        <Suspense fallback={<LoadingIndicator message={t`Loading Schedule`} />}>
          <StageContainer type="jobs-schedule" darkBg={true}>
            <SimulationJobsTimelineLayer stageId="jobs-schedule" />
          </StageContainer>
          {/* <TimelineTooltip stageId="jobs-schedule" /> */}
        </Suspense>
      </ContainerMap>
      {showScheduleDockCostControls && (
        <ActionBar className={cn('p-2')}>
          <Spacer flexspace />
          <InputSlider
            title={t`Idle time cost`}
            isMinimal
            min={costSettings.min}
            max={costSettings.max}
            values={[costSettings.load(waitingCost)]}
            stepSize={costSettings.step}
            unitOfMeasure={costSettings.unitOfMeasure}
            onChange={v => setWaitingCost(costSettings.save(v[0]))}
            segmentsClassNames={[
              'bg-menu-button',
              'bg-menu-active',
              'bg-menu-button',
            ]}
          />
          <InputSlider
            title={t`Docking Gap Before`}
            isMinimal
            min={0}
            max={500}
            values={[dockingDuration]}
            stepSize={1}
            unitOfMeasure={t`sec`}
            onChange={v => setDockingDuration(v[0])}
            segmentsClassNames={[
              'bg-menu-button',
              'bg-menu-active',
              'bg-menu-button',
            ]}
          />
          <InputSlider
            title={t`Docking Gap Before`}
            isMinimal
            min={0}
            max={500}
            values={[undockingDuration]}
            stepSize={1}
            unitOfMeasure={t`sec`}
            onChange={v => setUndockingDuration(v[0])}
            segmentsClassNames={[
              'bg-menu-button',
              'bg-menu-active',
              'bg-menu-button',
            ]}
          />
        </ActionBar>
      )}
      <SimulationJobTimelineDataTable />
    </>
  );
};

export default SimulationJobsTimeline;
