import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import LoadingIndicator from '../../components/LoadingIndicator';
import ButtonSwitchMulti from '../../components/actions/ButtonSwitchMulti';
import { Container } from '../../components/layout/ContainerFlex';
import CustomQueryRoot from '../../customQuery/CustomQueryRoot';
import ImporterDataPreview from '../csv/components/ImporterDataPreview';
import ImporterTransformExpressionEditor from '../csv/components/ImporterTransformExpressionEditor';
import { getImportConfiguration } from '../store/import.default';
import {
  importJob,
  importRawPreview,
  importTransformedPreview,
  importTypeCurrent,
} from '../store/import.state';
import { ImportStepContainerProps } from '../store/import.types';

const ImportTransformStep: React.FC<ImportStepContainerProps> = props => {
  const { t } = useTranslation('importer');
  const [previewData] = useRecoilState(importRawPreview);
  const transformedData = useRecoilValue(importTransformedPreview);
  const job = useRecoilValue(importJob);
  const datasetType = useRecoilValue(importTypeCurrent);
  const isDatasetSource = datasetType === 'dataset';
  const [showCustomQuery, setShowCustomQuery] = useState(false);

  if (_.isNil(job))
    return (
      <LoadingIndicator
        message={t('Loading Preview...')}
        // message={t('{{filename}}', {
        //   filename: datasSourceFile?.name,
        // })}
        selfCenter
      />
    );

  const config = getImportConfiguration(props.type, t);
  const isParsed = !_.isNil(previewData);

  const previewSize = _.size(previewData.data);
  const transformedSize = _.size(transformedData?.data);
  return (
    <Container
      hasOverflowY
      componentName="ImportTransformStep"
      col
      className="flex-1 space-y-px"
    >
      {!isDatasetSource && (
        <ImporterDataPreview
          id={'source'}
          data={previewData.data}
          collapsible
          columns={previewData.fields}
        />
      )}
      {!showCustomQuery && (
        <>
          <ImporterTransformExpressionEditor
            actionAfter={
              <ButtonSwitchMulti
                autoSize
                buttonType={'minimal'}
                classNameLabel="text-xxs py-0.5 px-2"
                options={[
                  { label: t('Transformation') },
                  { label: t('Custom Query') },
                ]}
                selectedIndex={showCustomQuery ? 1 : 0}
                onClick={index => setShowCustomQuery(index === 1)}
              />
            }
          />
          <ImporterDataPreview
            id={'transformed'}
            data={transformedData?.data}
            columns={transformedData?.fields}
            collapsible
          />
        </>
      )}
      {showCustomQuery && (
        <CustomQueryRoot
          actionBefore={
            <ButtonSwitchMulti
              autoSize
              buttonType={'minimal'}
              classNameLabel="text-xxs py-0.5 px-2"
              options={[
                { label: t('Transformation') },
                { label: t('Custom Query') },
              ]}
              selectedIndex={showCustomQuery ? 1 : 0}
              onClick={index => setShowCustomQuery(index === 1)}
            />
          }
        />
      )}
    </Container>
  );
};

export default ImportTransformStep;
