import { PresentationSettings } from '@warebee/shared/engine-model';
import { Field, ID, InputType } from '@warebee/shared/util-backend-only-types';
import { IsNotEmpty } from '@warego/class-validator';
import { CreateBaseEntityInput } from './create-base-entity.input';

@InputType({ isAbstract: true })
export class CreateBaseWarehouseEntityInput extends CreateBaseEntityInput {
  @Field(() => ID)
  @IsNotEmpty()
  warehouseId: string;

  @Field(() => PresentationSettings, { nullable: true })
  presentationSettings?: PresentationSettings;
}
