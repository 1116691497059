import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { cn } from '../../../../common/utils';
import ButtonToggle from '../../../../components/actions/ButtonToggle';
import * as Icon from '../../../../components/icons';
import { InputGroupList } from '../../../../components/inputs/InputGroupList';
import { Container } from '../../../../components/layout/ContainerFlex';
import { StatGroup } from '../../../../components/stats/StatGroup';
import { updateAllPolicyFilterValues } from '../../../../policyFilters/policyFilter.helper';
import useLoadLocationFilterValues from '../../../../policyFilters/useLoadLocationFilterValues';
import { getLocationFilterConfigCommon } from '../../../store/assignmentPolicy.default';
import { assignmentOccupancyViewMode } from '../../../store/occupancy/assignmentOccupancy.state';
import AssignmentOccupancyFilterEditor from './AssignmentOccupancyFilterEditor';

const AssignmentOccupancyFilterPanel: React.FC = () => {
  const { t } = useTranslation('simulation');
  const [startLoadData, cancelLoadData] = useLoadLocationFilterValues();
  const [viewMode, setViewMode] = useRecoilState(assignmentOccupancyViewMode);

  const locationsFilterConfig = getLocationFilterConfigCommon(t);
  useEffect(() => {
    updateAllPolicyFilterValues({
      filterConfig: locationsFilterConfig,
      selectedFilterId: null,
      policyFilterUnion: null, //rule.locationsMatch,
      load: startLoadData,
      cancel: cancelLoadData,
    });
  }, []);

  return (
    <Container hasOverflowY className="h-full">
      <StatGroup
        className="!my-0"
        classNameStatGroupItems="!mt-0 !pt-0"
        classNameTitle="!text-xs"
        title={t`Items filter`}
        titleAction={
          <>
            <ButtonToggle
              className={cn(
                'group',
                'rounded',
                'flex items-center justify-center',
                'h-8 w-8',
                'bg-menu/30',
                'hover:bg-menu-active hover:text-menu-active-text',
              )}
              onChange={() =>
                setViewMode(viewMode === 'filters' ? 'summary' : 'filters')
              }
            >
              {viewMode === 'filters' ? (
                <Icon.Close className={cn('h-4 w-4 fill-current p-0.5')} />
              ) : (
                <Icon.Filter className={cn('h-4 w-4 fill-current p-0.5')} />
              )}
            </ButtonToggle>
          </>
        }
      >
        <InputGroupList>
          {_.map(locationsFilterConfig, filterConfig => {
            return (
              <AssignmentOccupancyFilterEditor
                key={`item-set-filter-${filterConfig.type}`}
                filterConfig={filterConfig}
                startLoadData={startLoadData}
                cancelLoadData={cancelLoadData}
              />
            );
          })}
        </InputGroupList>
      </StatGroup>
    </Container>
  );
};

export default AssignmentOccupancyFilterPanel;
