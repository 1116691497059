import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { Container } from '../../components/layout/ContainerFlex';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';
import Callout from '../../helpContext/Callout';
import { loggedInUser } from '../../store/auth.state';
import { brandName, CalloutType } from '../../store/global.state';
import PanelDisplaySettings from './panels/PanelDisplaySettings';
import PanelIntegration from './panels/PanelIntegration';
import PanelSettingsAccount from './panels/PanelSettingsAccount';
import PanelSettingBilling from './panels/PanelSettingsBilling';
import PanelSettingsGDPR from './panels/PanelSettingsGDPR';
import PanelSettingsLegal from './panels/PanelSettingsLegal';
import PanelSettingsNotification from './panels/PanelSettingsNotification';
import PanelSettingsSupport from './panels/PanelSettingsSupport';
import PanelSettingsWarehouses from './panels/PanelSettingsWarehouses';
import PanelSupportData from './panels/PanelSupportData';
import PanelSystemEngineOptions from './panels/PanelSystemEngineOptions';
import PanelSystemInfo from './panels/PanelSystemInfo';
import PanelSystemLanguage from './panels/PanelSystemLanguage';
import { PanelSystemPerformance } from './panels/PanelSystemPerformance';

const calloutTypes: CalloutType[] = [
  'tip',
  'suggestion',
  'caution',
  'check',
  'note',
  'warning',
  'action',
  'counter',
];

const SidebarSettings: React.FC = props => {
  const { t } = useTranslation('app');

  const user = useRecoilValue(loggedInUser);
  const brandTitle = useRecoilValue(brandName);

  return (
    <Container col hasOverflowY>
      <Callout panelMode type="suggestion">
        <div>{t(`Hello ${user.firstName}`)}</div>
        <div>{t('I am {{ brandTitle }} helper', { brandTitle })}</div>
        <div>{t`Here you can change your account and warehouse settings`}</div>
      </Callout>

      <PanelSettingsAccount />
      <PanelSettingBilling />
      <PanelSettingsNotification />

      <PanelSystemLanguage />
      <PanelSystemPerformance />

      <PanelSettingsWarehouses />
      <PanelIntegration />

      <PanelDisplaySettings />

      <PanelSettingsSupport />
      {/* <PanelPrivacy /> */}
      <PanelSystemInfo />
      <PanelSystemEngineOptions />
      <PanelSupportData />
      <PanelSettingsLegal />
      <PanelSettingsGDPR />

      <div className="h-20 pb-10"></div>
    </Container>
  );
};

export default () => {
  const { t } = useTranslation('app');

  const containerProps: SidebarContainerProps = {
    type: 'sidebar-global-settings',
    title: t`Settings`,
    loadingMessage: t`Loading Settings`,
    size: 'xl',
    className: 'shadow-2xl',
    isGlobal: true,
    sideRight: true,
  };

  return (
    <SidebarContainer {...containerProps} sideRight isGlobal>
      <SidebarSettings />
    </SidebarContainer>
  );
};
