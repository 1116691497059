import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  GeoJSONBBox: { input: GeoJSON.BBox; output: GeoJSON.BBox };
  GeoJSONGeometry: { input: GeoJSON.Geometry; output: GeoJSON.Geometry };
  GeoJSONPoint: { input: GeoJSON.Point; output: GeoJSON.Point };
  GeoJSONPolygon: { input: GeoJSON.Polygon; output: GeoJSON.Polygon };
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any };
  /** Local datetime in ISO8601 format without timezone, e.g. 2022-05-04T22:43:30.353 (a format used by by js-joda's LocalDateTime.parse()/LocalDateTime.toString()) */
  LocalDateTime: { input: string; output: string };
  /** Local time in ISO8601 format without timezone, e.g. 22:43:30.353 (a format used by by js-joda's LocalTime.parse()/LocalTime.toString()) */
  LocalTime: { input: any; output: any };
  /** `Date` type as integer. Type represents date and time as number of milliseconds from start of UNIX epoch. */
  Timestamp: { input: number; output: number };
};

export enum Action {
  ADMIN = 'ADMIN',
  DELETE = 'DELETE',
  EXPORT = 'EXPORT',
  READ = 'READ',
  UPDATE = 'UPDATE',
}

export type ActualitiesConnection = {
  __typename?: 'ActualitiesConnection';
  allUserTags: Array<Scalars['String']['output']>;
  /** Content of collection or the current page of collection if pagination is used */
  content: Array<Actuality>;
  /** Total number of elements in this collection (ignoring pagination constraints, if any) */
  totalCount: Scalars['Float']['output'];
};

export type ActualitiesSortBy = {
  /** Sorting direction */
  direction?: SortDirection;
  /** Field (sort option) to sort by */
  field: BaseEntitySortOption;
};

export type Actuality = {
  __typename?: 'Actuality';
  activityFeedId?: Maybe<Scalars['ID']['output']>;
  analyzePipeline?: Maybe<ProcessingPipeline>;
  assignmentCapacityPolicy?: Maybe<AssignmentCapacityPolicy>;
  assignmentId?: Maybe<Scalars['ID']['output']>;
  assignmentPolicy?: Maybe<AssignmentPolicy>;
  createdAt: Scalars['Timestamp']['output'];
  description?: Maybe<Scalars['String']['output']>;
  extraSettings?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['ID']['output'];
  itemSetId?: Maybe<Scalars['ID']['output']>;
  layoutId?: Maybe<Scalars['ID']['output']>;
  locationSharingPolicy?: Maybe<LocationSharingPolicy>;
  orderSetId?: Maybe<Scalars['ID']['output']>;
  pickingPolicy?: Maybe<PickingPolicy>;
  presentationSettings?: Maybe<PresentationSettings>;
  resourcePolicy?: Maybe<ResourcePolicy>;
  routingPolicy?: Maybe<RoutingPolicy>;
  stackingPolicy?: Maybe<StackingPolicy>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['Timestamp']['output'];
  userTags?: Maybe<Array<Scalars['String']['output']>>;
  warehouse: Warehouse;
  waypointPolicy?: Maybe<WaypointPolicy>;
};

export type ActualityFilter = {
  title?: InputMaybe<StringSearchFilter>;
  userTags?: InputMaybe<Array<Scalars['String']['input']>>;
  userTagsHasAll?: InputMaybe<Array<Scalars['String']['input']>>;
  userTagsHasAny?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type AgentCapacitySettings = {
  __typename?: 'AgentCapacitySettings';
  maxUomQuantity?: Maybe<Array<AgentCapacityUomSettings>>;
  maxVolume?: Maybe<Scalars['Float']['output']>;
  maxWeight?: Maybe<Scalars['Float']['output']>;
};

export type AgentCapacitySettingsInput = {
  maxUomQuantity?: InputMaybe<Array<AgentCapacityUomSettingsInput>>;
  maxVolume?: InputMaybe<Scalars['Float']['input']>;
  maxWeight?: InputMaybe<Scalars['Float']['input']>;
};

export type AgentCapacityUomSettings = {
  __typename?: 'AgentCapacityUomSettings';
  quantity?: Maybe<Scalars['Float']['output']>;
  uom: Scalars['ID']['output'];
};

export type AgentCapacityUomSettingsInput = {
  quantity?: InputMaybe<Scalars['Float']['input']>;
  uom: Scalars['ID']['input'];
};

export type AgentCostSettings = {
  __typename?: 'AgentCostSettings';
  costPerSecond?: Maybe<Scalars['Float']['output']>;
};

export type AgentCostSettingsInput = {
  costPerSecond?: InputMaybe<Scalars['Float']['input']>;
};

export type AgentPowerSourceSettings = {
  __typename?: 'AgentPowerSourceSettings';
  energyConsumptionPerSecond?: Maybe<Scalars['Float']['output']>;
};

export type AgentPowerSourceSettingsInput = {
  energyConsumptionPerSecond?: InputMaybe<Scalars['Float']['input']>;
};

export enum AgentRole {
  LOADING = 'LOADING',
  PICKING = 'PICKING',
  REASSIGN = 'REASSIGN',
  REPLENISHMENT = 'REPLENISHMENT',
}

export type AgentRoleSettings = {
  __typename?: 'AgentRoleSettings';
  disabled?: Maybe<Scalars['Boolean']['output']>;
  handling?: Maybe<HandlingEventSettings>;
  handlingCompletion?: Maybe<PicklistLineProcessingEventSettings>;
  handlingPreparation?: Maybe<PicklistLineProcessingEventSettings>;
  postHandling?: Maybe<PicklistProcessingEventSettings>;
  preHandling?: Maybe<PicklistProcessingEventSettings>;
  reordering?: Maybe<ReorderEventSettings>;
  roleId: AgentRole;
};

export type AgentRoleSettingsInput = {
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  handling?: InputMaybe<HandlingEventSettingsInput>;
  handlingCompletion?: InputMaybe<PicklistLineProcessingEventSettingsInput>;
  handlingPreparation?: InputMaybe<PicklistLineProcessingEventSettingsInput>;
  postHandling?: InputMaybe<PicklistProcessingEventSettingsInput>;
  preHandling?: InputMaybe<PicklistProcessingEventSettingsInput>;
  reordering?: InputMaybe<ReorderEventSettingsInput>;
  roleId: AgentRole;
};

export type AgentSettings = {
  __typename?: 'AgentSettings';
  cost?: Maybe<AgentCostSettings>;
  id: Scalars['ID']['output'];
  metadata?: Maybe<Scalars['JSON']['output']>;
  resourceTypeId: Scalars['ID']['output'];
  roles?: Maybe<Array<AgentRoleSettings>>;
  specs?: Maybe<AgentSpecs>;
  title?: Maybe<Scalars['String']['output']>;
  utilisation?: Maybe<AgentUtilisationSettings>;
};

export type AgentSettingsInput = {
  cost?: InputMaybe<AgentCostSettingsInput>;
  id: Scalars['ID']['input'];
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  resourceTypeId: Scalars['ID']['input'];
  roles?: InputMaybe<Array<AgentRoleSettingsInput>>;
  specs?: InputMaybe<AgentSpecsInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  utilisation?: InputMaybe<AgentUtilisationSettingsInput>;
};

export type AgentSpecs = {
  __typename?: 'AgentSpecs';
  capacity?: Maybe<AgentCapacitySettings>;
  horizontalTravelling?: Maybe<HorizontalTravellingEventSettings>;
  powerSource?: Maybe<AgentPowerSourceSettings>;
  verticalTravelling?: Maybe<VerticalTravellingEventSettings>;
};

export type AgentSpecsInput = {
  capacity?: InputMaybe<AgentCapacitySettingsInput>;
  horizontalTravelling?: InputMaybe<HorizontalTravellingEventSettingsInput>;
  powerSource?: InputMaybe<AgentPowerSourceSettingsInput>;
  verticalTravelling?: InputMaybe<VerticalTravellingEventSettingsInput>;
};

export type AgentUtilisationSettings = {
  __typename?: 'AgentUtilisationSettings';
  agentAmount?: Maybe<Scalars['Float']['output']>;
  secondsOperation?: Maybe<Scalars['Float']['output']>;
};

export type AgentUtilisationSettingsInput = {
  agentAmount?: InputMaybe<Scalars['Float']['input']>;
  secondsOperation?: InputMaybe<Scalars['Float']['input']>;
};

export type Aisle = {
  __typename?: 'Aisle';
  aisleType?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  nonNavigable?: Maybe<Scalars['Boolean']['output']>;
  points: Array<Point>;
  title?: Maybe<Scalars['String']['output']>;
};

export type AisleInput = {
  aisleType?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  nonNavigable?: InputMaybe<Scalars['Boolean']['input']>;
  points: Array<PointInput>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type AislePortal = {
  __typename?: 'AislePortal';
  aisleId1: Scalars['ID']['output'];
  aisleId2: Scalars['ID']['output'];
  coords: Segment;
};

export type AislePortalInput = {
  aisleId1: Scalars['ID']['input'];
  aisleId2: Scalars['ID']['input'];
  coords: SegmentInput;
};

export enum AisleSide {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export enum AllocateJobEngine {
  FARGATE = 'FARGATE',
  LAMBDA = 'LAMBDA',
}

export enum AllocationAllowReplacement {
  ALWAYS = 'ALWAYS',
  FOR_PICKABILITY = 'FOR_PICKABILITY',
  NEVER = 'NEVER',
}

export type AllocationConstraintSettings = {
  __typename?: 'AllocationConstraintSettings';
  assignmentPolicyConstraint?: Maybe<AssignmentPolicyConstraintSettings>;
  bayWidthConstraint?: Maybe<BayWidthConstraintSettings>;
  locationSizeConstraint?: Maybe<LocationSizeConstraintSettings>;
};

export type AllocationConstraintSettingsInput = {
  assignmentPolicyConstraint?: InputMaybe<AssignmentPolicyConstraintSettingsInput>;
  bayWidthConstraint?: InputMaybe<BayWidthConstraintSettingsInput>;
  locationSizeConstraint?: InputMaybe<LocationSizeConstraintSettingsInput>;
};

export enum AllocationEnforcePickability {
  FIRST = 'FIRST',
  LAST = 'LAST',
  NEVER = 'NEVER',
}

export enum AllocationItemPriority {
  CUSTOM = 'CUSTOM',
  DAILY_ORDER_LINE_COUNT_AVG = 'DAILY_ORDER_LINE_COUNT_AVG',
  DAILY_ORDER_LINE_COUNT_CV = 'DAILY_ORDER_LINE_COUNT_CV',
  DAILY_ORDER_LINE_COUNT_MAX = 'DAILY_ORDER_LINE_COUNT_MAX',
  ESTIMATED_REPLENISHMENT_COUNT = 'ESTIMATED_REPLENISHMENT_COUNT',
  MAX_STACK = 'MAX_STACK',
  ORDER_LINE_COUNT = 'ORDER_LINE_COUNT',
  POLLUTION_CLASS = 'POLLUTION_CLASS',
  REMAINING_REQUIRED_VOLUME = 'REMAINING_REQUIRED_VOLUME',
  SKU_GROUP = 'SKU_GROUP',
  STOCK_CATEGORY = 'STOCK_CATEGORY',
  STORAGE_CLASS = 'STORAGE_CLASS',
  SUBGROUP = 'SUBGROUP',
  TOTAL_DAYS_WITH_ORDERS = 'TOTAL_DAYS_WITH_ORDERS',
  TOTAL_WEEKS_WITH_ORDERS = 'TOTAL_WEEKS_WITH_ORDERS',
  TRANSPORT_CLASS = 'TRANSPORT_CLASS',
  UOM_HEIGHT = 'UOM_HEIGHT',
  UOM_LENGTH = 'UOM_LENGTH',
  UOM_VOLUME = 'UOM_VOLUME',
  UOM_WIDTH = 'UOM_WIDTH',
  WEEKLY_DAYS_WITH_ORDERS_AVG = 'WEEKLY_DAYS_WITH_ORDERS_AVG',
  WEEKLY_DAYS_WITH_ORDERS_CV = 'WEEKLY_DAYS_WITH_ORDERS_CV',
  WEEKLY_DAYS_WITH_ORDERS_MAX = 'WEEKLY_DAYS_WITH_ORDERS_MAX',
  WEEKLY_ORDER_LINE_COUNT_AVG = 'WEEKLY_ORDER_LINE_COUNT_AVG',
  WEEKLY_ORDER_LINE_COUNT_CV = 'WEEKLY_ORDER_LINE_COUNT_CV',
  WEEKLY_ORDER_LINE_COUNT_MAX = 'WEEKLY_ORDER_LINE_COUNT_MAX',
}

export type AllocationItemPrioritySpec = {
  __typename?: 'AllocationItemPrioritySpec';
  customOrderRules?: Maybe<Array<SimulationItemFilterUnion>>;
  direction?: Maybe<AllocationPriorityDirection>;
  priority: AllocationItemPriority;
};

export type AllocationItemPrioritySpecInput = {
  customOrderRules?: InputMaybe<Array<SimulationItemFilterUnionInput>>;
  direction?: InputMaybe<AllocationPriorityDirection>;
  priority: AllocationItemPriority;
};

export enum AllocationLimitApplyTo {
  REQUIREMENT = 'REQUIREMENT',
  RULE = 'RULE',
}

export type AllocationLimitSettings = {
  __typename?: 'AllocationLimitSettings';
  applyTo?: Maybe<AllocationLimitApplyTo>;
  maxAllocatedAssignments?: Maybe<Scalars['Float']['output']>;
  maxAllocatedVolume?: Maybe<Scalars['Float']['output']>;
  maxPickableAssignments?: Maybe<Scalars['Float']['output']>;
  maxPickableVolume?: Maybe<Scalars['Float']['output']>;
  minAllocatedAssignments?: Maybe<Scalars['Float']['output']>;
  minPickableAssignments?: Maybe<Scalars['Float']['output']>;
};

export type AllocationLimitSettingsInput = {
  applyTo?: InputMaybe<AllocationLimitApplyTo>;
  maxAllocatedAssignments?: InputMaybe<Scalars['Float']['input']>;
  maxAllocatedVolume?: InputMaybe<Scalars['Float']['input']>;
  maxPickableAssignments?: InputMaybe<Scalars['Float']['input']>;
  maxPickableVolume?: InputMaybe<Scalars['Float']['input']>;
  minAllocatedAssignments?: InputMaybe<Scalars['Float']['input']>;
  minPickableAssignments?: InputMaybe<Scalars['Float']['input']>;
};

export enum AllocationLocationPriority {
  BAY_TITLE = 'BAY_TITLE',
  BAY_WIDTH = 'BAY_WIDTH',
  CUSTOM = 'CUSTOM',
  DISTANCE_FROM_START = 'DISTANCE_FROM_START',
  DISTANCE_TO_END = 'DISTANCE_TO_END',
  LOCATION_HEIGHT = 'LOCATION_HEIGHT',
  LOCATION_LENGTH = 'LOCATION_LENGTH',
  LOCATION_LEVEL = 'LOCATION_LEVEL',
  LOCATION_ORDER = 'LOCATION_ORDER',
  LOCATION_VOLUME = 'LOCATION_VOLUME',
  LOCATION_WIDTH = 'LOCATION_WIDTH',
}

export type AllocationLocationPrioritySpec = {
  __typename?: 'AllocationLocationPrioritySpec';
  customOrderRules?: Maybe<Array<LocationFilterUnion>>;
  direction?: Maybe<AllocationPriorityDirection>;
  priority: AllocationLocationPriority;
};

export type AllocationLocationPrioritySpecInput = {
  customOrderRules?: InputMaybe<Array<LocationFilterUnionInput>>;
  direction?: InputMaybe<AllocationPriorityDirection>;
  priority: AllocationLocationPriority;
};

export type AllocationMultiplexingSettings = {
  __typename?: 'AllocationMultiplexingSettings';
  allow?: Maybe<AllocationAllowReplacement>;
};

export type AllocationMultiplexingSettingsInput = {
  allow?: InputMaybe<AllocationAllowReplacement>;
};

export type AllocationPickabilitySettings = {
  __typename?: 'AllocationPickabilitySettings';
  enforcePickability?: Maybe<AllocationEnforcePickability>;
};

export type AllocationPickabilitySettingsInput = {
  enforcePickability?: InputMaybe<AllocationEnforcePickability>;
};

export type AllocationPolicy = {
  __typename?: 'AllocationPolicy';
  defaultRule?: Maybe<AllocationRule>;
  rules?: Maybe<Array<AllocationRule>>;
};

export type AllocationPolicyInput = {
  defaultRule?: InputMaybe<AllocationRuleInput>;
  rules?: InputMaybe<Array<AllocationRuleInput>>;
};

export enum AllocationPriorityDirection {
  ASC = 'ASC',
  DEFAULT = 'DEFAULT',
  DESC = 'DESC',
}

export type AllocationPrioritySettings = {
  __typename?: 'AllocationPrioritySettings';
  itemPriority?: Maybe<Array<AllocationItemPrioritySpec>>;
  locationPriority?: Maybe<Array<AllocationLocationPrioritySpec>>;
};

export type AllocationPrioritySettingsInput = {
  itemPriority?: InputMaybe<Array<AllocationItemPrioritySpecInput>>;
  locationPriority?: InputMaybe<Array<AllocationLocationPrioritySpecInput>>;
};

export type AllocationReplacementSettings = {
  __typename?: 'AllocationReplacementSettings';
  allow?: Maybe<AllocationAllowReplacement>;
};

export type AllocationReplacementSettingsInput = {
  allow?: InputMaybe<AllocationAllowReplacement>;
};

export type AllocationRequirement = {
  __typename?: 'AllocationRequirement';
  aggregatedPreferredRequiredVolume?: Maybe<Scalars['Float']['output']>;
  aggregatedRequiredQuantity?: Maybe<Scalars['Float']['output']>;
  aggregatedUnpickableRequiredQuantity?: Maybe<Scalars['Float']['output']>;
  consignee: Scalars['ID']['output'];
  cumulativePercentRank?: Maybe<Scalars['Float']['output']>;
  details?: Maybe<AllocationRequirementDetails>;
  itemId: Scalars['ID']['output'];
  orderLineCount: Scalars['Float']['output'];
  orderLineRuleId: Scalars['ID']['output'];
  percentRank?: Maybe<Scalars['Float']['output']>;
  pollutionClass?: Maybe<Scalars['String']['output']>;
  possibleLocationMask: Scalars['String']['output'];
  preferredRequiredVolume: Scalars['Float']['output'];
  requiredLocationMask: Scalars['String']['output'];
  requiredQuantity: Scalars['Float']['output'];
  sku: Scalars['ID']['output'];
  skuGroup?: Maybe<Scalars['String']['output']>;
  stockCategory?: Maybe<Scalars['String']['output']>;
  storageClass?: Maybe<Scalars['String']['output']>;
  subGroup?: Maybe<Scalars['String']['output']>;
  transportClass?: Maybe<Scalars['String']['output']>;
  unpickableRequiredQuantity: Scalars['Float']['output'];
};

export enum AllocationRequirementAggregationMethod {
  DAILY_AVERAGE = 'DAILY_AVERAGE',
  DAILY_MAX = 'DAILY_MAX',
  WEEKLY_MAX = 'WEEKLY_MAX',
}

export type AllocationRequirementAggregationSettings = {
  __typename?: 'AllocationRequirementAggregationSettings';
  aggregation?: Maybe<AllocationRequirementAggregationMethod>;
  numberOfPeriods?: Maybe<Scalars['Float']['output']>;
};

export type AllocationRequirementAggregationSettingsInput = {
  aggregation?: InputMaybe<AllocationRequirementAggregationMethod>;
  numberOfPeriods?: InputMaybe<Scalars['Float']['input']>;
};

export type AllocationRequirementDetails = {
  __typename?: 'AllocationRequirementDetails';
  preferredUoms?: Maybe<Array<AllocationRequirementUomQuantity>>;
  rules?: Maybe<Array<AllocationRequirementRuleDetails>>;
};

export enum AllocationRequirementGroupBy {
  CONSIGNEE = 'CONSIGNEE',
  ITEM_ID = 'ITEM_ID',
  ORDER_LINE_RULE_ID = 'ORDER_LINE_RULE_ID',
  POSSIBLE_LOCATION_MASK = 'POSSIBLE_LOCATION_MASK',
  REQUIRED_LOCATION_MASK = 'REQUIRED_LOCATION_MASK',
  SKU = 'SKU',
}

export type AllocationRequirementLocation = {
  __typename?: 'AllocationRequirementLocation';
  aisleId: Scalars['ID']['output'];
  locationHeight: Scalars['Float']['output'];
  locationId: Scalars['String']['output'];
  locationLength: Scalars['Float']['output'];
  locationLevel: Scalars['Float']['output'];
  locationRackingType: Scalars['String']['output'];
  locationSide?: Maybe<Scalars['ID']['output']>;
  locationStatus: Scalars['Boolean']['output'];
  locationUsageType: Scalars['String']['output'];
  locationVolume: Scalars['Float']['output'];
  locationWeight: Scalars['Float']['output'];
  locationWidth: Scalars['Float']['output'];
  locmhtype?: Maybe<Scalars['String']['output']>;
  mask: Scalars['String']['output'];
  planeId: Scalars['ID']['output'];
  warehouseArea: Scalars['String']['output'];
};

export enum AllocationRequirementLocationGroupBy {
  LEVEL = 'LEVEL',
  MASK = 'MASK',
  PLANE_ID = 'PLANE_ID',
}

export type AllocationRequirementLocationSortBy = {
  /** Sorting direction */
  direction?: SortDirection;
  /** Field (sort option) to sort by */
  field: AllocationRequirementLocationSortOption;
};

export enum AllocationRequirementLocationSortOption {
  LOCATION_ID = 'LOCATION_ID',
}

export type AllocationRequirementLocationSummary = {
  __typename?: 'AllocationRequirementLocationSummary';
  key: AllocationRequirementLocationSummaryKey;
  locationCount: Scalars['Float']['output'];
  locationVolume: Scalars['Float']['output'];
};

export type AllocationRequirementLocationSummaryKey = {
  __typename?: 'AllocationRequirementLocationSummaryKey';
  level?: Maybe<Scalars['ID']['output']>;
  mask?: Maybe<Scalars['ID']['output']>;
  planeId?: Maybe<Scalars['ID']['output']>;
};

export type AllocationRequirementLocationsConnection = {
  __typename?: 'AllocationRequirementLocationsConnection';
  /** Content of collection or the current page of collection if pagination is used */
  content: Array<AllocationRequirementLocation>;
  /** Total number of elements in this collection (ignoring pagination constraints, if any) */
  totalCount: Scalars['Float']['output'];
};

export type AllocationRequirementLocationsFilter = {
  level?: InputMaybe<Array<Scalars['Int']['input']>>;
  maskMatches?: InputMaybe<Array<LocationMaskSpecInput>>;
  planeId?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type AllocationRequirementRuleDetails = {
  __typename?: 'AllocationRequirementRuleDetails';
  aggregatedPreferredRequiredVolume?: Maybe<Scalars['Float']['output']>;
  aggregatedRequiredQuantity?: Maybe<Scalars['Float']['output']>;
  allowedUoms: Array<Scalars['ID']['output']>;
  mask: Scalars['String']['output'];
  pickingRuleId: Scalars['String']['output'];
  preferredRequiredVolume: Scalars['Float']['output'];
  preferredUoms: Array<AllocationRequirementUomQuantity>;
  requiredQuantity: Scalars['Float']['output'];
};

export enum AllocationRequirementRuleType {
  ASSIGNMENT_RULE = 'ASSIGNMENT_RULE',
  PICKING_RULE = 'PICKING_RULE',
}

export type AllocationRequirementSet = {
  __typename?: 'AllocationRequirementSet';
  allocationRun?: Maybe<AllocationRun>;
  assignment?: Maybe<Assignment>;
  assignmentPolicy?: Maybe<AssignmentPolicy>;
  completedAt?: Maybe<Scalars['Timestamp']['output']>;
  createdAt: Scalars['Timestamp']['output'];
  id: Scalars['ID']['output'];
  itemSet: ItemSet;
  layout: Layout;
  locationSharingPolicy?: Maybe<LocationSharingPolicy>;
  locations: AllocationRequirementLocationsConnection;
  locationsSummary: Array<AllocationRequirementLocationSummary>;
  orderSet: OrderSet;
  pickingPolicy: PickingPolicy;
  progress: Scalars['Float']['output'];
  requirements: AllocationRequirementsConnection;
  requirementsSummary: Array<AllocationRequirementSummary>;
  resourcePolicy?: Maybe<ResourcePolicy>;
  settings?: Maybe<AllocationRequirementSetSettings>;
  simulation: Simulation;
  stackingPolicy?: Maybe<StackingPolicy>;
  startedAt?: Maybe<Scalars['Timestamp']['output']>;
  status: BatchJobStatus;
  summary?: Maybe<AllocationRequirementSetSummary>;
  updatedAt: Scalars['Timestamp']['output'];
  warehouse: Warehouse;
};

export type AllocationRequirementSetLocationsArgs = {
  filter?: InputMaybe<AllocationRequirementLocationsFilter>;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<AllocationRequirementLocationSortBy>>;
};

export type AllocationRequirementSetLocationsSummaryArgs = {
  filter?: InputMaybe<AllocationRequirementLocationsFilter>;
  groupBy?: InputMaybe<Array<AllocationRequirementLocationGroupBy>>;
};

export type AllocationRequirementSetRequirementsArgs = {
  filter?: InputMaybe<AllocationRequirementsFilter>;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<AllocationRequirementSortBy>>;
};

export type AllocationRequirementSetRequirementsSummaryArgs = {
  filter?: InputMaybe<AllocationRequirementsFilter>;
  groupBy?: InputMaybe<Array<AllocationRequirementGroupBy>>;
};

export type AllocationRequirementSetRule = {
  __typename?: 'AllocationRequirementSetRule';
  id: Scalars['ID']['output'];
  itemsMatch?: Maybe<SimulationItemFilterUnion>;
  requirementAggregationSettings?: Maybe<AllocationRequirementAggregationSettings>;
  title?: Maybe<Scalars['String']['output']>;
  uomTypes?: Maybe<Array<Scalars['String']['output']>>;
};

export type AllocationRequirementSetRuleDetails = {
  __typename?: 'AllocationRequirementSetRuleDetails';
  id: Scalars['ID']['output'];
  mask: Scalars['ID']['output'];
  type: AllocationRequirementRuleType;
};

export type AllocationRequirementSetRuleInput = {
  id: Scalars['ID']['input'];
  itemsMatch?: InputMaybe<SimulationItemFilterUnionInput>;
  requirementAggregationSettings?: InputMaybe<AllocationRequirementAggregationSettingsInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  uomTypes?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type AllocationRequirementSetSettings = {
  __typename?: 'AllocationRequirementSetSettings';
  defaultRule?: Maybe<AllocationRequirementSetRule>;
  orderSetFilter?: Maybe<OrderSetFilter>;
  requirementSource?: Maybe<AllocationRequirementSourceSettings>;
  rules?: Maybe<Array<AllocationRequirementSetRule>>;
};

export type AllocationRequirementSetSettingsInput = {
  defaultRule?: InputMaybe<AllocationRequirementSetRuleInput>;
  orderSetFilter?: InputMaybe<OrderSetFilterInput>;
  requirementSource?: InputMaybe<AllocationRequirementSourceSettingsInput>;
  rules?: InputMaybe<Array<AllocationRequirementSetRuleInput>>;
};

export type AllocationRequirementSetSummary = {
  __typename?: 'AllocationRequirementSetSummary';
  maskLength: Scalars['Float']['output'];
  rules: Array<AllocationRequirementSetRuleDetails>;
};

export type AllocationRequirementSortBy = {
  /** Sorting direction */
  direction?: SortDirection;
  /** Field (sort option) to sort by */
  field: AllocationRequirementSortOption;
};

export enum AllocationRequirementSortOption {
  CONSIGNEE = 'CONSIGNEE',
  CUMULATIVE_PERCENT_RANK = 'CUMULATIVE_PERCENT_RANK',
  ORDER_LINE_COUNT = 'ORDER_LINE_COUNT',
  ORDER_LINE_RULE_ID = 'ORDER_LINE_RULE_ID',
  PRECENT_RANK = 'PRECENT_RANK',
  REQUIRED_QUANTITY = 'REQUIRED_QUANTITY',
  SKU = 'SKU',
}

export type AllocationRequirementSourceSettings = {
  __typename?: 'AllocationRequirementSourceSettings';
  useOrderSetDemand?: Maybe<Scalars['Boolean']['output']>;
};

export type AllocationRequirementSourceSettingsInput = {
  useOrderSetDemand?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AllocationRequirementSummary = {
  __typename?: 'AllocationRequirementSummary';
  aggregatedPreferredRequiredVolume?: Maybe<Scalars['Float']['output']>;
  aggregatedRequiredQuantity?: Maybe<Scalars['Float']['output']>;
  aggregatedUnpickableRequiredQuantity?: Maybe<Scalars['Float']['output']>;
  allocationRequirementCount: Scalars['Float']['output'];
  itemCount: Scalars['Float']['output'];
  key: AllocationRequirementSummaryKey;
  preferredRequiredVolume: Scalars['Float']['output'];
  requiredQuantity: Scalars['Float']['output'];
  unpickableRequiredQuantity: Scalars['Float']['output'];
};

export type AllocationRequirementSummaryKey = {
  __typename?: 'AllocationRequirementSummaryKey';
  consignee?: Maybe<Scalars['ID']['output']>;
  itemId?: Maybe<Scalars['ID']['output']>;
  orderLineRuleId?: Maybe<Scalars['ID']['output']>;
  possibleLocationMask?: Maybe<Scalars['ID']['output']>;
  requiredLocationMask?: Maybe<Scalars['ID']['output']>;
  sku?: Maybe<Scalars['ID']['output']>;
};

export type AllocationRequirementUomQuantity = {
  __typename?: 'AllocationRequirementUomQuantity';
  aggregatedQuantity?: Maybe<Scalars['Float']['output']>;
  aggregatedTotalVolume?: Maybe<Scalars['Float']['output']>;
  aggregatedUomQuantity?: Maybe<Scalars['Float']['output']>;
  quantity: Scalars['Float']['output'];
  totalVolume: Scalars['Float']['output'];
  uom?: Maybe<Scalars['String']['output']>;
  uomQuantity: Scalars['Float']['output'];
};

export type AllocationRequirementsConnection = {
  __typename?: 'AllocationRequirementsConnection';
  /** Content of collection or the current page of collection if pagination is used */
  content: Array<AllocationRequirement>;
  /** Total number of elements in this collection (ignoring pagination constraints, if any) */
  totalCount: Scalars['Float']['output'];
};

export type AllocationRequirementsFilter = {
  consignee?: InputMaybe<Array<Scalars['ID']['input']>>;
  consigneeContains?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Array<Scalars['ID']['input']>>;
  itemsMatch?: InputMaybe<SimulationItemFilterUnionInput>;
  orderLineRuleId?: InputMaybe<Array<Scalars['ID']['input']>>;
  sku?: InputMaybe<Array<Scalars['ID']['input']>>;
  skuContains?: InputMaybe<Scalars['String']['input']>;
  skuGroup?: InputMaybe<Array<Scalars['ID']['input']>>;
  skuGroupContains?: InputMaybe<Scalars['String']['input']>;
};

export type AllocationRoundSettings = {
  __typename?: 'AllocationRoundSettings';
  maxAssignmentsPerRound?: Maybe<Scalars['Float']['output']>;
};

export type AllocationRoundSettingsInput = {
  maxAssignmentsPerRound?: InputMaybe<Scalars['Float']['input']>;
};

export type AllocationRule = {
  __typename?: 'AllocationRule';
  constraintSettings?: Maybe<AllocationConstraintSettings>;
  id: Scalars['ID']['output'];
  itemsMatch?: Maybe<SimulationItemFilterUnion>;
  limitSettings?: Maybe<AllocationLimitSettings>;
  locationsMatch?: Maybe<LocationFilterUnion>;
  multiplexingSettings?: Maybe<AllocationMultiplexingSettings>;
  pickabilitySettings?: Maybe<AllocationPickabilitySettings>;
  prioritySettings?: Maybe<AllocationPrioritySettings>;
  replacementSettings?: Maybe<AllocationReplacementSettings>;
  requirementAggregationSettings?: Maybe<AllocationRequirementAggregationSettings>;
  roundSettings?: Maybe<AllocationRoundSettings>;
  title?: Maybe<Scalars['String']['output']>;
  uomTypes?: Maybe<Array<Scalars['String']['output']>>;
};

export type AllocationRuleInput = {
  constraintSettings?: InputMaybe<AllocationConstraintSettingsInput>;
  id: Scalars['ID']['input'];
  itemsMatch?: InputMaybe<SimulationItemFilterUnionInput>;
  limitSettings?: InputMaybe<AllocationLimitSettingsInput>;
  locationsMatch?: InputMaybe<LocationFilterUnionInput>;
  multiplexingSettings?: InputMaybe<AllocationMultiplexingSettingsInput>;
  pickabilitySettings?: InputMaybe<AllocationPickabilitySettingsInput>;
  prioritySettings?: InputMaybe<AllocationPrioritySettingsInput>;
  replacementSettings?: InputMaybe<AllocationReplacementSettingsInput>;
  requirementAggregationSettings?: InputMaybe<AllocationRequirementAggregationSettingsInput>;
  roundSettings?: InputMaybe<AllocationRoundSettingsInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  uomTypes?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type AllocationRun = {
  __typename?: 'AllocationRun';
  allocationRequirements?: Maybe<AllocationRequirementSet>;
  allocationRunType: AllocationType;
  allocationSettings?: Maybe<AllocationSettings>;
  allocationSummary?: Maybe<AllocationSummary>;
  /** Analysis of assignment produced by this allocation run */
  analyzeResult?: Maybe<AnalyzeResult>;
  assignment: Assignment;
  assignmentChange?: Maybe<AssignmentChange>;
  assignmentPolicy?: Maybe<AssignmentPolicy>;
  completedAt?: Maybe<Scalars['Timestamp']['output']>;
  createdAt: Scalars['Timestamp']['output'];
  id: Scalars['ID']['output'];
  itemSet: ItemSet;
  layout: Layout;
  locationSharingPolicy?: Maybe<LocationSharingPolicy>;
  orderSet: OrderSet;
  pickingPolicy?: Maybe<PickingPolicy>;
  /** Current optimization progress */
  progress?: Maybe<Scalars['Float']['output']>;
  resourcePolicy?: Maybe<ResourcePolicy>;
  /** Resulting assignment */
  resultAssignment?: Maybe<Assignment>;
  resultAssignmentId?: Maybe<Scalars['ID']['output']>;
  resultRequirementsSummary: Array<AllocationRunResultRequirementSummary>;
  results: AllocationRunResultRequirementsConnection;
  routingPolicy?: Maybe<RoutingPolicy>;
  seed?: Maybe<Scalars['Float']['output']>;
  simulation: Simulation;
  stackingPolicy?: Maybe<StackingPolicy>;
  stage?: Maybe<AllocationRunStage>;
  startedAt?: Maybe<Scalars['Timestamp']['output']>;
  /** Current status of allocation job */
  status: BatchJobStatus;
  updatedAt: Scalars['Timestamp']['output'];
  warehouse: Warehouse;
  waypointPolicy?: Maybe<WaypointPolicy>;
};

export type AllocationRunResultRequirementsSummaryArgs = {
  filter?: InputMaybe<AllocationRunResultRequirementsFilter>;
  groupBy?: InputMaybe<Array<AllocationRunResultRequirementGroupBy>>;
};

export type AllocationRunResultsArgs = {
  filter?: InputMaybe<AllocationRunResultRequirementsFilter>;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<AllocationRunResultRequirementSortBy>>;
};

export enum AllocationRunResultDeallocatedReason {
  DEALLOCATE_RULE = 'DEALLOCATE_RULE',
  NON_COMPLIANT = 'NON_COMPLIANT',
  REPLACED = 'REPLACED',
}

export type AllocationRunResultRequirement = {
  __typename?: 'AllocationRunResultRequirement';
  consignee: Scalars['ID']['output'];
  details?: Maybe<AllocationRunResultRequirementDetails>;
  itemId: Scalars['ID']['output'];
  orderLineCount: Scalars['Float']['output'];
  orderLineRuleId: Scalars['ID']['output'];
  requiredQuantity: Scalars['Float']['output'];
  sku: Scalars['ID']['output'];
};

export type AllocationRunResultRequirementDeallocatedQuantity = {
  __typename?: 'AllocationRunResultRequirementDeallocatedQuantity';
  constraints?: Maybe<Array<Scalars['ID']['output']>>;
  deallocateRuleIds?: Maybe<Array<Scalars['ID']['output']>>;
  locations?: Maybe<
    Array<AllocationRunResultRequirementLocationDeallocatedQuantity>
  >;
  reasons?: Maybe<Array<AllocationRunResultDeallocatedReason>>;
  totalQuantity?: Maybe<Scalars['Float']['output']>;
  uoms?: Maybe<Array<AllocationRequirementUomQuantity>>;
};

export type AllocationRunResultRequirementDeallocatedQuantitySummary = {
  __typename?: 'AllocationRunResultRequirementDeallocatedQuantitySummary';
  constraints?: Maybe<Array<Scalars['ID']['output']>>;
  deallocateRuleIds?: Maybe<Array<Scalars['ID']['output']>>;
  locations?: Maybe<
    Array<AllocationRunResultRequirementLocationDeallocatedQuantity>
  >;
  reasons?: Maybe<Array<AllocationRunResultDeallocatedReason>>;
  totalLocationCount?: Maybe<Scalars['Float']['output']>;
  totalQuantity?: Maybe<Scalars['Float']['output']>;
  uoms?: Maybe<Array<AllocationRequirementUomQuantity>>;
};

export type AllocationRunResultRequirementDetails = {
  __typename?: 'AllocationRunResultRequirementDetails';
  allocated?: Maybe<AllocationRunResultRequirementPickableQuantity>;
  allocatedFulfilled?: Maybe<AllocationRunResultRequirementPickableQuantity>;
  allocatedShared?: Maybe<AllocationRunResultRequirementPickableQuantity>;
  deallocated?: Maybe<AllocationRunResultRequirementDeallocatedQuantity>;
  initiallyPickable?: Maybe<AllocationRunResultRequirementPickableQuantity>;
  initiallyPickableFulfilled?: Maybe<AllocationRunResultRequirementPickableQuantity>;
  /** order demand */
  required?: Maybe<AllocationRunResultRequirementQuantity>;
  /** max(toAllocateRequired, toReallocate) */
  toAllocate?: Maybe<AllocationRunResultRequirementQuantity>;
  /** max(0, required - initiallyPickable) */
  toAllocateRequired?: Maybe<AllocationRunResultRequirementQuantity>;
  toReallocate?: Maybe<AllocationRunResultRequirementQuantity>;
  unallocated?: Maybe<AllocationRunResultRequirementUnallocatedQuantity>;
  unpickable?: Maybe<AllocationRunResultRequirementQuantity>;
};

export enum AllocationRunResultRequirementGroupBy {
  CONSIGNEE = 'CONSIGNEE',
  ITEM_ID = 'ITEM_ID',
  ORDER_LINE_RULE_ID = 'ORDER_LINE_RULE_ID',
  POLLUTION_CLASS = 'POLLUTION_CLASS',
  SKU = 'SKU',
  SKU_GROUP = 'SKU_GROUP',
  STOCK_CATEGORY = 'STOCK_CATEGORY',
  STORAGE_CLASS = 'STORAGE_CLASS',
  SUBGROUP = 'SUBGROUP',
  TRANSPORT_CLASS = 'TRANSPORT_CLASS',
}

export type AllocationRunResultRequirementLocationDeallocatedQuantity = {
  __typename?: 'AllocationRunResultRequirementLocationDeallocatedQuantity';
  constraints?: Maybe<Array<Scalars['ID']['output']>>;
  deallocateRuleIds?: Maybe<Array<Scalars['ID']['output']>>;
  locationId: Scalars['ID']['output'];
  reason: AllocationRunResultDeallocatedReason;
  totalQuantity?: Maybe<Scalars['Float']['output']>;
  uoms?: Maybe<Array<AllocationRequirementUomQuantity>>;
};

export type AllocationRunResultRequirementLocationQuantity = {
  __typename?: 'AllocationRunResultRequirementLocationQuantity';
  locationId: Scalars['ID']['output'];
  totalQuantity?: Maybe<Scalars['Float']['output']>;
  uoms?: Maybe<Array<AllocationRequirementUomQuantity>>;
};

export type AllocationRunResultRequirementPickableQuantity = {
  __typename?: 'AllocationRunResultRequirementPickableQuantity';
  locations?: Maybe<Array<AllocationRunResultRequirementLocationQuantity>>;
  totalQuantity?: Maybe<Scalars['Float']['output']>;
  uoms?: Maybe<Array<AllocationRequirementUomQuantity>>;
};

export type AllocationRunResultRequirementPickableQuantitySummary = {
  __typename?: 'AllocationRunResultRequirementPickableQuantitySummary';
  locations?: Maybe<Array<AllocationRunResultRequirementLocationQuantity>>;
  totalLocationCount?: Maybe<Scalars['Float']['output']>;
  totalQuantity?: Maybe<Scalars['Float']['output']>;
  uoms?: Maybe<Array<AllocationRequirementUomQuantity>>;
};

export type AllocationRunResultRequirementQuantity = {
  __typename?: 'AllocationRunResultRequirementQuantity';
  totalQuantity?: Maybe<Scalars['Float']['output']>;
  uoms?: Maybe<Array<AllocationRequirementUomQuantity>>;
};

export type AllocationRunResultRequirementSortBy = {
  /** Sorting direction */
  direction?: SortDirection;
  /** Field (sort option) to sort by */
  field: AllocationRunResultRequirementSortOption;
};

export enum AllocationRunResultRequirementSortOption {
  CONSIGNEE = 'CONSIGNEE',
  ORDER_LINE_COUNT = 'ORDER_LINE_COUNT',
  ORDER_LINE_RULE_ID = 'ORDER_LINE_RULE_ID',
  REQUIRED_QUANTITY = 'REQUIRED_QUANTITY',
  SKU = 'SKU',
}

export type AllocationRunResultRequirementSummary = {
  __typename?: 'AllocationRunResultRequirementSummary';
  allocated?: Maybe<AllocationRunResultRequirementPickableQuantitySummary>;
  allocatedFulfilled?: Maybe<AllocationRunResultRequirementPickableQuantitySummary>;
  allocatedShared?: Maybe<AllocationRunResultRequirementPickableQuantitySummary>;
  allocationRequirementCount?: Maybe<Scalars['Float']['output']>;
  deallocated?: Maybe<AllocationRunResultRequirementDeallocatedQuantitySummary>;
  initiallyPickable?: Maybe<AllocationRunResultRequirementPickableQuantitySummary>;
  initiallyPickableFulfilled?: Maybe<AllocationRunResultRequirementPickableQuantitySummary>;
  itemCount?: Maybe<Scalars['Float']['output']>;
  key: AllocationRunResultRequirementSummaryKey;
  required?: Maybe<AllocationRunResultRequirementQuantity>;
  requiredQuantity?: Maybe<Scalars['Float']['output']>;
  toAllocate?: Maybe<AllocationRunResultRequirementQuantity>;
  toAllocateRequired?: Maybe<AllocationRunResultRequirementQuantity>;
  toReallocate?: Maybe<AllocationRunResultRequirementQuantity>;
  unallocated?: Maybe<AllocationRunResultRequirementUnallocatedQuantity>;
  unpickable?: Maybe<AllocationRunResultRequirementQuantity>;
};

export type AllocationRunResultRequirementSummaryKey = {
  __typename?: 'AllocationRunResultRequirementSummaryKey';
  consignee?: Maybe<Scalars['ID']['output']>;
  itemId?: Maybe<Scalars['ID']['output']>;
  orderLineRuleId?: Maybe<Scalars['ID']['output']>;
  pollutionClass?: Maybe<Scalars['String']['output']>;
  sku?: Maybe<Scalars['ID']['output']>;
  skuGroup?: Maybe<Scalars['ID']['output']>;
  stockCategory?: Maybe<Scalars['String']['output']>;
  storageClass?: Maybe<Scalars['String']['output']>;
  subGroup?: Maybe<Scalars['ID']['output']>;
  transportClass?: Maybe<Scalars['String']['output']>;
};

export type AllocationRunResultRequirementUnallocatedQuantity = {
  __typename?: 'AllocationRunResultRequirementUnallocatedQuantity';
  reasons?: Maybe<Array<AllocationRunResultUnallocatedReason>>;
  totalQuantity?: Maybe<Scalars['Float']['output']>;
  uoms?: Maybe<Array<AllocationRequirementUomQuantity>>;
};

export type AllocationRunResultRequirementsConnection = {
  __typename?: 'AllocationRunResultRequirementsConnection';
  /** Content of collection or the current page of collection if pagination is used */
  content: Array<AllocationRunResultRequirement>;
  /** Total number of elements in this collection (ignoring pagination constraints, if any) */
  totalCount: Scalars['Float']['output'];
};

export type AllocationRunResultRequirementsFilter = {
  consignee?: InputMaybe<Array<Scalars['ID']['input']>>;
  consigneeContains?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Array<Scalars['ID']['input']>>;
  itemsMatch?: InputMaybe<SimulationItemFilterUnionInput>;
  orderLineRuleId?: InputMaybe<Array<Scalars['ID']['input']>>;
  sku?: InputMaybe<Array<Scalars['ID']['input']>>;
  skuContains?: InputMaybe<Scalars['String']['input']>;
  skuGroup?: InputMaybe<Array<Scalars['ID']['input']>>;
  skuGroupContains?: InputMaybe<Scalars['String']['input']>;
};

export enum AllocationRunResultUnallocatedReason {
  DEALLOCATE_RULE = 'DEALLOCATE_RULE',
  NO_RULE = 'NO_RULE',
  NO_SPACE = 'NO_SPACE',
  NO_SPACE_FOR_PICKABILITY = 'NO_SPACE_FOR_PICKABILITY',
  NO_STOCK = 'NO_STOCK',
  ROUND_LIMIT = 'ROUND_LIMIT',
}

export enum AllocationRunSortOption {
  CREATED_AT = 'CREATED_AT',
  UPDATED_AT = 'UPDATED_AT',
}

export enum AllocationRunStage {
  ALLOCATE = 'ALLOCATE',
  ANALYZING = 'ANALYZING',
  CALCULATE_ALLOCATION_REQUIREMENTS = 'CALCULATE_ALLOCATION_REQUIREMENTS',
}

export type AllocationRunsConnection = {
  __typename?: 'AllocationRunsConnection';
  /** Content of collection or the current page of collection if pagination is used */
  content: Array<AllocationRun>;
  /** Total number of elements in this collection (ignoring pagination constraints, if any) */
  totalCount: Scalars['Float']['output'];
};

export type AllocationRunsFilter = {
  allocationRunType?: InputMaybe<Array<AllocationType>>;
  status?: InputMaybe<Array<BatchJobStatus>>;
};

export type AllocationRunsSortBy = {
  /** Sorting direction */
  direction?: SortDirection;
  /** Field (sort option) to sort by */
  field: AllocationRunSortOption;
};

export type AllocationSettings = {
  __typename?: 'AllocationSettings';
  allocationPolicy?: Maybe<AllocationPolicy>;
  deallocationPolicy?: Maybe<DeallocationPolicy>;
  orderSetFilter?: Maybe<OrderSetFilter>;
  requirementSource?: Maybe<AllocationRequirementSourceSettings>;
  stockLimitSettings?: Maybe<AllocationStockLimitSettings>;
};

export type AllocationSettingsInput = {
  allocationPolicy?: InputMaybe<AllocationPolicyInput>;
  deallocationPolicy?: InputMaybe<DeallocationPolicyInput>;
  orderSetFilter?: InputMaybe<OrderSetFilterInput>;
  requirementSource?: InputMaybe<AllocationRequirementSourceSettingsInput>;
  stockLimitSettings?: InputMaybe<AllocationStockLimitSettingsInput>;
};

export type AllocationStockLimitSettings = {
  __typename?: 'AllocationStockLimitSettings';
  enabled?: Maybe<Scalars['Boolean']['output']>;
};

export type AllocationStockLimitSettingsInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AllocationSummary = {
  __typename?: 'AllocationSummary';
  constraints: Array<ConstraintSummary>;
};

export enum AllocationType {
  DRAFT = 'DRAFT',
  FULL = 'FULL',
}

export type AnalyzeActualityInput = {
  __typename?: 'AnalyzeActualityInput';
  actualityId: Scalars['ID']['output'];
  engine?: Maybe<AnalyzeActualityJobEngine>;
  newEventsOnly?: Maybe<Scalars['Boolean']['output']>;
  sourceEventFilter?: Maybe<AnalyzeActualitySourceEventFilter>;
};

export type AnalyzeActualityInputInput = {
  actualityId: Scalars['ID']['input'];
  engine?: InputMaybe<AnalyzeActualityJobEngine>;
  newEventsOnly?: InputMaybe<Scalars['Boolean']['input']>;
  sourceEventFilter?: InputMaybe<AnalyzeActualitySourceEventFilterInput>;
};

export enum AnalyzeActualityJobEngine {
  FARGATE = 'FARGATE',
  LAMBDA = 'LAMBDA',
}

export type AnalyzeActualityJobInput = {
  __typename?: 'AnalyzeActualityJobInput';
  engine?: Maybe<AnalyzeActualityJobEngine>;
  newEventsOnly?: Maybe<Scalars['Boolean']['output']>;
  sourceEventFilter?: Maybe<AnalyzeActualitySourceEventFilter>;
};

export type AnalyzeActualityJobOutput = {
  __typename?: 'AnalyzeActualityJobOutput';
  generatedAnalyzeEvents?: Maybe<Scalars['Float']['output']>;
  generatedEvents?: Maybe<Scalars['Float']['output']>;
  generatedIgnoredEvents?: Maybe<Scalars['Float']['output']>;
  processedEvents?: Maybe<Scalars['Float']['output']>;
  processedJobs?: Maybe<Scalars['Float']['output']>;
};

export type AnalyzeActualityPipelineSettings = {
  __typename?: 'AnalyzeActualityPipelineSettings';
  actualityId: Scalars['ID']['output'];
};

export type AnalyzeActualitySourceEventFilter = {
  __typename?: 'AnalyzeActualitySourceEventFilter';
  jobDateFrom?: Maybe<Scalars['LocalDateTime']['output']>;
  jobDateTo?: Maybe<Scalars['LocalDateTime']['output']>;
};

export type AnalyzeActualitySourceEventFilterInput = {
  jobDateFrom?: InputMaybe<Scalars['LocalDateTime']['input']>;
  jobDateTo?: InputMaybe<Scalars['LocalDateTime']['input']>;
};

export type AnalyzeActualityStatus = {
  __typename?: 'AnalyzeActualityStatus';
  actualityId: Scalars['ID']['output'];
  input: AnalyzeActualityInput;
  status: BatchJobExecutionStatus;
  token: Scalars['ID']['output'];
};

export type AnalyzeAllocationRunInput = {
  allocationRunId: Scalars['ID']['input'];
  startNow?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum AnalyzeOrderLineStatus {
  ASSEMBLED = 'ASSEMBLED',
  ASSEMBLED_PARTIALLY = 'ASSEMBLED_PARTIALLY',
  IGNORED = 'IGNORED',
  UNDEFINED = 'UNDEFINED',
}

export enum AnalyzeOrderStatus {
  ASSEMBLED = 'ASSEMBLED',
  ASSEMBLED_PARTIALLY = 'ASSEMBLED_PARTIALLY',
  IGNORED = 'IGNORED',
  UNDEFINED = 'UNDEFINED',
}

export type AnalyzePicklistInput = {
  itemSetId?: InputMaybe<Scalars['ID']['input']>;
  layoutId: Scalars['ID']['input'];
  pickingPolicy?: InputMaybe<PickingPolicyInput>;
  picklists: Array<PicklistInput>;
  resourcePolicy: ResourcePolicyInput;
  routingPolicy?: InputMaybe<RoutingPolicyInput>;
  saveResult?: InputMaybe<AnalyzePicklistSaveResultInput>;
  stackingPolicy?: InputMaybe<StackingPolicyInput>;
  waypointPolicy?: InputMaybe<WaypointPolicyInput>;
};

export type AnalyzePicklistResult = {
  __typename?: 'AnalyzePicklistResult';
  picklists: Array<AnalyzedPicklist>;
};

export type AnalyzePicklistSaveResultInput = {
  documentId: Scalars['ID']['input'];
  warehouseId: Scalars['ID']['input'];
};

/** Result of data set analysis. */
export type AnalyzeResult = {
  __typename?: 'AnalyzeResult';
  allocationRun?: Maybe<AllocationRun>;
  analyzeSettings?: Maybe<AnalyzeSettings>;
  assembledLines: Scalars['Float']['output'];
  assembledPartiallyLines: Scalars['Float']['output'];
  assignment: Assignment;
  assignmentChange?: Maybe<AssignmentChange>;
  completedAt?: Maybe<Scalars['Timestamp']['output']>;
  costDetails?: Maybe<AnalyzeResultCostDetails>;
  createdAt: Scalars['Timestamp']['output'];
  effectiveItemSet?: Maybe<SimulationItemSet>;
  effectiveSimulation: Simulation;
  id: Scalars['ID']['output'];
  ignoredLines: Scalars['Float']['output'];
  itemSet: ItemSet;
  jobs?: Maybe<AnalyzeResultJobsConnection>;
  layout: Layout;
  loadBalancingRuleSummary: LoadBalancingRuleSummary;
  maxLocationHitCount?: Maybe<Scalars['Int']['output']>;
  maxReorderSummary?: Maybe<AnalyzeResultReorderSummary>;
  optimizationRun?: Maybe<OptimizationRun>;
  orderSet: OrderSet;
  orders?: Maybe<AnalyzeResultOrdersConnection>;
  pickedProductsByCategory?: Maybe<AnalyzeResultProductsByCategoryConnection>;
  pickingPolicy?: Maybe<PickingPolicy>;
  picklistPickByDates: Array<Maybe<Scalars['LocalDateTime']['output']>>;
  picklistSet?: Maybe<PicklistSet>;
  progress?: Maybe<Scalars['Float']['output']>;
  replenishmentJobs?: Maybe<AnalyzeResultReplenishmentJobsConnection>;
  resourcePolicy?: Maybe<ResourcePolicy>;
  resourceUsageSummary: Array<ResourceUsageSummary>;
  routingPolicy?: Maybe<RoutingPolicy>;
  simulation?: Maybe<Simulation>;
  stackingPolicy?: Maybe<StackingPolicy>;
  stage?: Maybe<AnalyzeResultStage>;
  startedAt?: Maybe<Scalars['Timestamp']['output']>;
  status: AnalyzeResultStatus;
  summary?: Maybe<AnalyzeResultSummary>;
  totalAssembledOrders: Scalars['Float']['output'];
  totalAssembledPartiallyOrders: Scalars['Float']['output'];
  totalCost: Scalars['Float']['output'];
  totalDistance: Scalars['Float']['output'];
  totalDuration: Scalars['Float']['output'];
  totalIgnoredOrders: Scalars['Float']['output'];
  totalLines: Scalars['Float']['output'];
  totalOrders: Scalars['Float']['output'];
  updatedAt: Scalars['Timestamp']['output'];
  visitedLocations?: Maybe<AnalyzeResultLocationsConnection>;
  warehouse: Warehouse;
  waypointPolicy?: Maybe<WaypointPolicy>;
  waypoints?: Maybe<AnalyzeResultWaypointsConnection>;
};

/** Result of data set analysis. */
export type AnalyzeResultJobsArgs = {
  filter?: InputMaybe<AnalyzeResultJobsFilter>;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<AnalyzeResultJobsSortBy>>;
};

/** Result of data set analysis. */
export type AnalyzeResultLoadBalancingRuleSummaryArgs = {
  locationFilter?: InputMaybe<LayoutLocationFilter>;
  rule: LoadBalancingRuleInput;
};

/** Result of data set analysis. */
export type AnalyzeResultMaxLocationHitCountArgs = {
  filter?: InputMaybe<AnalyzeResultLocationsFilter>;
  processType?: InputMaybe<Array<AnalyzeResultProcessType>>;
};

/** Result of data set analysis. */
export type AnalyzeResultMaxReorderSummaryArgs = {
  filter?: InputMaybe<AnalyzeResultLocationsFilter>;
  summaryType?: InputMaybe<AnalyzeResultReorderSummaryType>;
};

/** Result of data set analysis. */
export type AnalyzeResultOrdersArgs = {
  filter?: InputMaybe<AnalyzeResultOrdersFilter>;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<AnalyzeResultOrdersSortBy>>;
};

/** Result of data set analysis. */
export type AnalyzeResultPickedProductsByCategoryArgs = {
  categories: AnalyzeResultProductsByCategoryRequest;
};

/** Result of data set analysis. */
export type AnalyzeResultPicklistPickByDatesArgs = {
  truncateTo?: InputMaybe<DateTimeTruncateTo>;
};

/** Result of data set analysis. */
export type AnalyzeResultReplenishmentJobsArgs = {
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<AnalyzeResultReplenishmentJobsSortBy>>;
};

/** Result of data set analysis. */
export type AnalyzeResultResourceUsageSummaryArgs = {
  filter?: InputMaybe<ResourceUsageSummaryFilter>;
  groupBy?: InputMaybe<Array<ResourceUsageSummaryGroupBy>>;
};

/** Result of data set analysis. */
export type AnalyzeResultVisitedLocationsArgs = {
  filter?: InputMaybe<AnalyzeResultLocationsAndProductsFilter>;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<AnalyzeResultLocationsSortBy>>;
};

/** Result of data set analysis. */
export type AnalyzeResultWaypointsArgs = {
  filter?: InputMaybe<AnalyzeResultWaypointsFilter>;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<AnalyzeResultWaypointsSortBy>>;
};

export type AnalyzeResultAgentSummary = {
  __typename?: 'AnalyzeResultAgentSummary';
  agent: Scalars['ID']['output'];
  eventTypes: Array<AnalyzeResultEventTypeSummary>;
};

export type AnalyzeResultAssignmentSummary = {
  __typename?: 'AnalyzeResultAssignmentSummary';
  totalStoredItems?: Maybe<Scalars['Float']['output']>;
};

export type AnalyzeResultCostDetails = {
  __typename?: 'AnalyzeResultCostDetails';
  processes: Array<AnalyzeResultProcessCost>;
};

export type AnalyzeResultEvent = {
  __typename?: 'AnalyzeResultEvent';
  agents: Array<Scalars['ID']['output']>;
  cost: Scalars['Float']['output'];
  details?: Maybe<PicklistEventDetails>;
  duration: Scalars['Float']['output'];
  endPosition?: Maybe<Point>;
  eventType: PicklistEventType;
  locationId?: Maybe<Scalars['ID']['output']>;
  orderIds: Array<Scalars['ID']['output']>;
  picklistLine?: Maybe<Scalars['Float']['output']>;
  sequenceId: Scalars['Float']['output'];
  sourceEventId?: Maybe<Scalars['ID']['output']>;
  startPosition: Point;
};

export type AnalyzeResultEventTypeSummary = {
  __typename?: 'AnalyzeResultEventTypeSummary';
  count: Scalars['Float']['output'];
  eventType: PicklistEventType;
  processType?: Maybe<AnalyzeResultProcessType>;
  totalCost: Scalars['Float']['output'];
  totalDuration: Scalars['Float']['output'];
};

export type AnalyzeResultJob = {
  __typename?: 'AnalyzeResultJob';
  agents: Array<Scalars['ID']['output']>;
  count: Scalars['Float']['output'];
  details?: Maybe<AnalyzeResultJobDetails>;
  id: Scalars['ID']['output'];
  jobCost: Scalars['Float']['output'];
  jobDistance: Scalars['Float']['output'];
  jobDuration: Scalars['Float']['output'];
  planeIds: Array<Scalars['ID']['output']>;
  process: AnalyzeResultProcessType;
  summary?: Maybe<AnalyzeResultJobSummary>;
  title?: Maybe<Scalars['String']['output']>;
  totalCost: Scalars['Float']['output'];
  totalDistance: Scalars['Float']['output'];
  totalDuration: Scalars['Float']['output'];
};

export type AnalyzeResultJobDetails =
  | LoadingJobDetails
  | PickingJobDetails
  | ReassignJobDetails
  | ReplenishmentJobDetails;

export type AnalyzeResultJobSummary = {
  __typename?: 'AnalyzeResultJobSummary';
  agents: Array<AnalyzeResultAgentSummary>;
};

export type AnalyzeResultJobsConnection = {
  __typename?: 'AnalyzeResultJobsConnection';
  /** Content of collection or the current page of collection if pagination is used */
  content: Array<AnalyzeResultJob>;
  /** Total number of elements in this collection (ignoring pagination constraints, if any) */
  totalCount: Scalars['Float']['output'];
};

export type AnalyzeResultJobsFilter = {
  process?: InputMaybe<Array<AnalyzeResultProcessType>>;
  reassignJob?: InputMaybe<AnalyzeResultReassignJobsFilter>;
};

export type AnalyzeResultJobsSortBy = {
  /** Sorting direction */
  direction?: SortDirection;
  /** Field (sort option) to sort by */
  field: AnalyzeResultJobsSortOption;
};

export enum AnalyzeResultJobsSortOption {
  REASSIGN_JOB_CHAIN_NUMBER = 'REASSIGN_JOB_CHAIN_NUMBER',
  REASSIGN_JOB_ESTIMATED_AVG_GROUP_COST_IMPROVEMENT = 'REASSIGN_JOB_ESTIMATED_AVG_GROUP_COST_IMPROVEMENT',
  REASSIGN_JOB_ESTIMATED_COST_IMPROVEMENT = 'REASSIGN_JOB_ESTIMATED_COST_IMPROVEMENT',
  REASSIGN_JOB_ESTIMATED_TOTAL_GROUP_COST_IMPROVEMENT = 'REASSIGN_JOB_ESTIMATED_TOTAL_GROUP_COST_IMPROVEMENT',
  REASSIGN_JOB_GROUP_ID = 'REASSIGN_JOB_GROUP_ID',
  REASSIGN_JOB_SEQUENCE_NUMBER = 'REASSIGN_JOB_SEQUENCE_NUMBER',
  TOTAL_COST = 'TOTAL_COST',
  TOTAL_DISTANCE = 'TOTAL_DISTANCE',
  TOTAL_DURATION = 'TOTAL_DURATION',
}

export type AnalyzeResultLocation = {
  __typename?: 'AnalyzeResultLocation';
  aisleId: Scalars['ID']['output'];
  congestionZone?: Maybe<Scalars['ID']['output']>;
  hitCount: Scalars['Float']['output'];
  locationBayId: Scalars['ID']['output'];
  locationId: Scalars['ID']['output'];
  locationLevel: Scalars['Float']['output'];
  planeId: Scalars['ID']['output'];
  products: Array<AnalyzeResultLocationProductInfo>;
};

export type AnalyzeResultLocationProductFilter = {
  consignee?: InputMaybe<StringSearchFilter>;
  sku?: InputMaybe<StringSearchFilter>;
};

export type AnalyzeResultLocationProductInfo = {
  __typename?: 'AnalyzeResultLocationProductInfo';
  /** Summary of reorder events that were applied to this item picked from this location */
  appliedReorder?: Maybe<AnalyzeResultReorderSummary>;
  consignee: Scalars['ID']['output'];
  /** @deprecated in favour of item.cumulativePercentRank */
  cumulativePercentRank?: Maybe<Scalars['Float']['output']>;
  /** Total number of access events for all processes */
  hitCount: Scalars['Float']['output'];
  item?: Maybe<SimulationItem>;
  itemId: Scalars['ID']['output'];
  /**
   * Percent rank of this product in the list of all analyzed products with non-zero hit count ordered by hit count descending as number from 0 to 1
   * @deprecated in favour of item.percentRank
   */
  percentRank?: Maybe<Scalars['Float']['output']>;
  /** Number of access events during picking */
  pickCount: Scalars['Float']['output'];
  /** Total picked quantity */
  quantityPicked: Scalars['Float']['output'];
  quantityReassigned?: Maybe<Scalars['Float']['output']>;
  /** Total quantity of replenished items */
  quantityReplenished?: Maybe<Scalars['Float']['output']>;
  reassignCount?: Maybe<Scalars['Float']['output']>;
  reassignTasks?: Maybe<Array<AnalyzeResultLocationProductReassignTask>>;
  /** Replenishment job details */
  replenishment?: Maybe<AnalyzeResultLocationProductReplenishmentInfo>;
  /** Number of replenishments */
  replenishmentCount?: Maybe<Scalars['Float']['output']>;
  sku: Scalars['ID']['output'];
  stackingCategoryIndex?: Maybe<Scalars['Int']['output']>;
  /** Summary of reorder events triggered by picking of this item from this location */
  triggeredReorder?: Maybe<AnalyzeResultReorderSummary>;
};

export type AnalyzeResultLocationProductReassignTask = {
  __typename?: 'AnalyzeResultLocationProductReassignTask';
  action: ReassignTaskAction;
  breakdownByUom: Array<ItemUomQuantity>;
  jobId: Scalars['ID']['output'];
  quantity: Scalars['Float']['output'];
};

export type AnalyzeResultLocationProductReplenishmentInfo = {
  __typename?: 'AnalyzeResultLocationProductReplenishmentInfo';
  /** Quantity to replenish in single replenishment job */
  replenishmentQuantity: Scalars['Float']['output'];
  replenishmentUom?: Maybe<Scalars['ID']['output']>;
  replenishmentUomQuantity: Scalars['Float']['output'];
};

export type AnalyzeResultLocationsAndProductsFilter = {
  item?: InputMaybe<AnalyzeResultLocationProductFilter>;
  locationBayId?: InputMaybe<Array<Scalars['ID']['input']>>;
  locationId?: InputMaybe<StringSearchFilter>;
  locationLevel?: InputMaybe<Array<Scalars['Float']['input']>>;
  planeId?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type AnalyzeResultLocationsConnection = {
  __typename?: 'AnalyzeResultLocationsConnection';
  /** Content of collection or the current page of collection if pagination is used */
  content: Array<AnalyzeResultLocation>;
  /** Total number of elements in this collection (ignoring pagination constraints, if any) */
  totalCount: Scalars['Float']['output'];
};

export type AnalyzeResultLocationsFilter = {
  locationBayId?: InputMaybe<Array<Scalars['ID']['input']>>;
  locationId?: InputMaybe<StringSearchFilter>;
  locationLevel?: InputMaybe<Array<Scalars['Float']['input']>>;
  planeId?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type AnalyzeResultLocationsSortBy = {
  /** Sorting direction */
  direction?: SortDirection;
  /** Field (sort option) to sort by */
  field: AnalyzeResultLocationsSortOption;
};

export enum AnalyzeResultLocationsSortOption {
  HIT_COUNT = 'HIT_COUNT',
}

export type AnalyzeResultOrder = {
  __typename?: 'AnalyzeResultOrder';
  assembledLines: Scalars['Float']['output'];
  assembledPartiallyLines: Scalars['Float']['output'];
  consignee?: Maybe<Scalars['ID']['output']>;
  /** Opaque order key that combines consignee and sku */
  id: Scalars['ID']['output'];
  ignoredLines: Scalars['Float']['output'];
  missingProducts: Array<ProductLocationId>;
  order: OrderHeader;
  orderDate?: Maybe<Scalars['LocalDateTime']['output']>;
  orderId?: Maybe<Scalars['ID']['output']>;
  orderLinesStatus?: Maybe<Array<AnalyzeResultOrderLine>>;
  /** SKUs of picked products */
  pickedProductSkus?: Maybe<Array<Scalars['ID']['output']>>;
  picklists: Array<AnalyzeResultPicklist>;
  status: AnalyzeOrderStatus;
  totalCost: Scalars['Float']['output'];
  totalDistance: Scalars['Float']['output'];
  totalDuration: Scalars['Float']['output'];
  totalLines: Scalars['Float']['output'];
  unknownProducts: Array<ProductId>;
  unreachableProducts: Array<ProductLocationId>;
  /** Visited location ids */
  visitedLocations?: Maybe<Array<Scalars['ID']['output']>>;
};

export type AnalyzeResultOrderPicklistsArgs = {
  filter?: InputMaybe<AnalyzeResultPicklistsFilter>;
};

export type AnalyzeResultOrderLine = {
  __typename?: 'AnalyzeResultOrderLine';
  orderLine: Scalars['Float']['output'];
  status: AnalyzeOrderLineStatus;
};

export type AnalyzeResultOrdersConnection = {
  __typename?: 'AnalyzeResultOrdersConnection';
  /** Content of collection or the current page of collection if pagination is used */
  content: Array<AnalyzeResultOrder>;
  /** Total number of elements in this collection (ignoring pagination constraints, if any) */
  totalCount: Scalars['Float']['output'];
};

export type AnalyzeResultOrdersFilter = {
  consigneeContains?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Array<Scalars['ID']['input']>>;
  orderDateFrom?: InputMaybe<Scalars['LocalDateTime']['input']>;
  orderDateTo?: InputMaybe<Scalars['LocalDateTime']['input']>;
  orderIdContains?: InputMaybe<Scalars['ID']['input']>;
  pickedProductSku?: InputMaybe<Array<Scalars['ID']['input']>>;
  pickedProductSkuContains?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<Array<AnalyzeOrderStatus>>;
  visitedLocationId?: InputMaybe<Array<Scalars['ID']['input']>>;
  visitedLocationIdContains?: InputMaybe<Scalars['ID']['input']>;
};

export type AnalyzeResultOrdersSortBy = {
  /** Sorting direction */
  direction?: SortDirection;
  /** Field (sort option) to sort by */
  field: AnalyzeResultOrdersSortOption;
};

export enum AnalyzeResultOrdersSortOption {
  TOTAL_COST = 'TOTAL_COST',
  TOTAL_DISTANCE = 'TOTAL_DISTANCE',
  TOTAL_DURATION = 'TOTAL_DURATION',
}

export type AnalyzeResultPicklist = {
  __typename?: 'AnalyzeResultPicklist';
  agents: Array<Scalars['ID']['output']>;
  events: Array<AnalyzeResultEvent>;
  ignoredLines?: Maybe<Array<AnalyzeResultPicklistIgnoredLine>>;
  orderIds: Array<Scalars['ID']['output']>;
  orderLineRuleId?: Maybe<Scalars['ID']['output']>;
  pickByDate?: Maybe<Scalars['LocalDateTime']['output']>;
  pickingMethod: Scalars['String']['output'];
  pickingWave?: Maybe<PickingWave>;
  pickingWaveId?: Maybe<Scalars['ID']['output']>;
  picklist: Picklist;
  picklistGroupKey?: Maybe<Scalars['ID']['output']>;
  picklistId: Scalars['ID']['output'];
  planeId: Scalars['ID']['output'];
  summary?: Maybe<AnalyzeResultPicklistSummary>;
  totalCost: Scalars['Float']['output'];
  totalDistance: Scalars['Float']['output'];
  totalDuration: Scalars['Float']['output'];
  totalEvents: Scalars['Float']['output'];
  totalLines: Scalars['Float']['output'];
  totalOrderLines: Scalars['Float']['output'];
  unitOfWork?: Maybe<Scalars['ID']['output']>;
};

export type AnalyzeResultPicklistIgnoredLine = {
  __typename?: 'AnalyzeResultPicklistIgnoredLine';
  locationId: Scalars['ID']['output'];
  picklistLine: Scalars['Float']['output'];
};

export type AnalyzeResultPicklistSummary = {
  __typename?: 'AnalyzeResultPicklistSummary';
  agents: Array<AnalyzeResultAgentSummary>;
};

export type AnalyzeResultPicklistsFilter = {
  picklistId?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type AnalyzeResultProcessCost = {
  __typename?: 'AnalyzeResultProcessCost';
  cost: Scalars['Float']['output'];
  distance: Scalars['Float']['output'];
  duration: Scalars['Float']['output'];
  processType: AnalyzeResultProcessType;
};

export enum AnalyzeResultProcessType {
  LOADING = 'LOADING',
  PICKING = 'PICKING',
  REASSIGN = 'REASSIGN',
  REPLENISHMENT = 'REPLENISHMENT',
}

export type AnalyzeResultProduct = {
  __typename?: 'AnalyzeResultProduct';
  consignee: Scalars['ID']['output'];
  cumulativePercentRank?: Maybe<Scalars['Float']['output']>;
  hitCount: Scalars['Float']['output'];
  itemId: Scalars['ID']['output'];
  percentRank?: Maybe<Scalars['Float']['output']>;
  quantityOrdered: Scalars['Float']['output'];
  sku: Scalars['ID']['output'];
};

export type AnalyzeResultProductsByCategoryConnection = {
  __typename?: 'AnalyzeResultProductsByCategoryConnection';
  categories: Array<AnalyzeResultProductsCategory>;
  totalCount: Scalars['Float']['output'];
  totalHitCount: Scalars['Float']['output'];
};

export type AnalyzeResultProductsByCategoryRequest = {
  categoryType?: AnalyzeResultProductsCategoryType;
  categoryWidths: Array<Scalars['Float']['input']>;
};

export type AnalyzeResultProductsCategory = {
  __typename?: 'AnalyzeResultProductsCategory';
  count: Scalars['Float']['output'];
  limit: Scalars['Float']['output'];
  maxBoundary?: Maybe<Scalars['Float']['output']>;
  maxCumulativePercentRank?: Maybe<Scalars['Float']['output']>;
  maxPercentRank?: Maybe<Scalars['Float']['output']>;
  minBoundary?: Maybe<Scalars['Float']['output']>;
  minCumulativePercentRank?: Maybe<Scalars['Float']['output']>;
  minPercentRank?: Maybe<Scalars['Float']['output']>;
  products: Array<AnalyzeResultProduct>;
  skip: Scalars['Float']['output'];
  totalHitCount: Scalars['Float']['output'];
};

export enum AnalyzeResultProductsCategoryType {
  CUMULATIVE_PERCENT_RANK = 'CUMULATIVE_PERCENT_RANK',
  PERCENT_RANK = 'PERCENT_RANK',
}

export type AnalyzeResultReassignJobsFilter = {
  consignee?: InputMaybe<StringSearchFilter>;
  destAction?: InputMaybe<StringSearchFilter>;
  destLocationId?: InputMaybe<StringSearchFilter>;
  groupId?: InputMaybe<StringSearchFilter>;
  sku?: InputMaybe<StringSearchFilter>;
  srcLocationId?: InputMaybe<StringSearchFilter>;
};

export type AnalyzeResultReorderSummary = {
  __typename?: 'AnalyzeResultReorderSummary';
  count: Scalars['Float']['output'];
  totalCost: Scalars['Float']['output'];
  totalDuration: Scalars['Float']['output'];
  totalReorderedWeight: Scalars['Float']['output'];
};

export enum AnalyzeResultReorderSummaryType {
  APPLIED_REORDER = 'APPLIED_REORDER',
  TRIGGERED_REORDER = 'TRIGGERED_REORDER',
}

export type AnalyzeResultReplenishmentJobLine = {
  __typename?: 'AnalyzeResultReplenishmentJobLine';
  destLocationId: Scalars['ID']['output'];
  itemId: Scalars['ID']['output'];
  quantity: Scalars['Float']['output'];
  replenishmentJobLine: Scalars['Float']['output'];
  uom?: Maybe<Scalars['String']['output']>;
  uomQuantity: Scalars['Float']['output'];
};

export type AnalyzeResultReplenishmentJobWithDetails = {
  __typename?: 'AnalyzeResultReplenishmentJobWithDetails';
  agents: Array<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  jobCost: Scalars['Float']['output'];
  jobDistance: Scalars['Float']['output'];
  jobDuration: Scalars['Float']['output'];
  lines: Array<AnalyzeResultReplenishmentJobLine>;
  planeId: Scalars['ID']['output'];
  replenishmentCount: Scalars['Float']['output'];
  replenishmentMethod: Scalars['String']['output'];
  totalCost: Scalars['Float']['output'];
  totalDistance: Scalars['Float']['output'];
  totalDuration: Scalars['Float']['output'];
};

export type AnalyzeResultReplenishmentJobsConnection = {
  __typename?: 'AnalyzeResultReplenishmentJobsConnection';
  /** Content of collection or the current page of collection if pagination is used */
  content: Array<AnalyzeResultReplenishmentJobWithDetails>;
  /** Total number of elements in this collection (ignoring pagination constraints, if any) */
  totalCount: Scalars['Float']['output'];
};

export type AnalyzeResultReplenishmentJobsSortBy = {
  /** Sorting direction */
  direction?: SortDirection;
  /** Field (sort option) to sort by */
  field: AnalyzeResultReplenishmentJobsSortOption;
};

export enum AnalyzeResultReplenishmentJobsSortOption {
  TOTAL_COST = 'TOTAL_COST',
  TOTAL_DISTANCE = 'TOTAL_DISTANCE',
  TOTAL_DURATION = 'TOTAL_DURATION',
}

export enum AnalyzeResultStage {
  ALLOCATING = 'ALLOCATING',
  ANALYZING = 'ANALYZING',
  GENERATING_PICKING_REQUIREMENTS = 'GENERATING_PICKING_REQUIREMENTS',
}

export enum AnalyzeResultStatus {
  ANALYZING = 'ANALYZING',
  COMPLETED = 'COMPLETED',
  CREATED = 'CREATED',
  FAILED = 'FAILED',
  TERMINATED = 'TERMINATED',
}

export type AnalyzeResultSummary = {
  __typename?: 'AnalyzeResultSummary';
  agents: Array<AnalyzeResultAgentSummary>;
  assignment?: Maybe<AnalyzeResultAssignmentSummary>;
  eventTypes: Array<AnalyzeResultEventTypeSummary>;
};

export type AnalyzeResultWaypoint = {
  __typename?: 'AnalyzeResultWaypoint';
  hitCount: Scalars['Float']['output'];
  planeId: Scalars['ID']['output'];
  waypointId: Scalars['String']['output'];
  waypointType: Scalars['String']['output'];
  x: Scalars['Float']['output'];
  y: Scalars['Float']['output'];
};

export type AnalyzeResultWaypointsConnection = {
  __typename?: 'AnalyzeResultWaypointsConnection';
  /** Retrieved content */
  content: Array<AnalyzeResultWaypoint>;
};

export type AnalyzeResultWaypointsFilter = {
  planeId?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type AnalyzeResultWaypointsSortBy = {
  /** Sorting direction */
  direction?: SortDirection;
  /** Field (sort option) to sort by */
  field: AnalyzeResultWaypointsSortOption;
};

export enum AnalyzeResultWaypointsSortOption {
  HIT_COUNT = 'HIT_COUNT',
}

export type AnalyzeSettings = {
  __typename?: 'AnalyzeSettings';
  orderSetFilter?: Maybe<OrderSetFilter>;
  reassignJobs?: Maybe<ReassignJobsSettings>;
  saveEvents?: Maybe<Scalars['Boolean']['output']>;
};

export type AnalyzeSettingsInput = {
  orderSetFilter?: InputMaybe<OrderSetFilterInput>;
  reassignJobs?: InputMaybe<ReassignJobsSettingsInput>;
  saveEvents?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AnalyzeSimulationInput = {
  simulationId: Scalars['ID']['input'];
  startNow?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AnalyzeSimulationPayload = {
  __typename?: 'AnalyzeSimulationPayload';
  analyzeResult: AnalyzeResult;
};

export type AnalyzedPicklist = {
  __typename?: 'AnalyzedPicklist';
  events: Array<AnalyzeResultEvent>;
  picklistId: Scalars['ID']['output'];
  totalCost: Scalars['Float']['output'];
  totalDistance: Scalars['Float']['output'];
  totalDuration: Scalars['Float']['output'];
};

export type ApplyAssignmentItemFilterInput = {
  assignmentId: Scalars['ID']['input'];
  filters?: InputMaybe<Array<InputMaybe<ItemFilterUnionInput>>>;
  itemSetId: Scalars['ID']['input'];
  layoutId: Scalars['ID']['input'];
  matchingOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ApplyStackingPolicyInput = {
  itemSetId: Scalars['ID']['input'];
  policy: StackingPolicyInput;
};

export type ApplyStackingPolicyResult = {
  __typename?: 'ApplyStackingPolicyResult';
  /** Content of collection or the current page of collection if pagination is used */
  content: Array<ItemStackingDetails>;
  totalBottomCount: Scalars['Float']['output'];
  /** Total number of elements in this collection (ignoring pagination constraints, if any) */
  totalCount: Scalars['Float']['output'];
  totalTopCount: Scalars['Float']['output'];
};

export type AssignedLocationCapacityDetails = {
  __typename?: 'AssignedLocationCapacityDetails';
  assignedCapacityDetails?: Maybe<Array<ItemUomQuantity>>;
  locationId: Scalars['ID']['output'];
  stockQuantityDetails?: Maybe<Array<ItemUomQuantity>>;
};

export type Assignment = {
  __typename?: 'Assignment';
  actualitiesWith: ActualitiesConnection;
  createdAt: Scalars['Timestamp']['output'];
  description?: Maybe<Scalars['String']['output']>;
  extraSettings?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['ID']['output'];
  importedAt?: Maybe<Scalars['Timestamp']['output']>;
  inputFileName?: Maybe<Scalars['String']['output']>;
  items: AssignmentItemsConnection;
  presentationSettings?: Maybe<PresentationSettings>;
  simulationsWith: SimulationsConnection;
  status: AssignmentImportStatus;
  summary?: Maybe<AssignmentSummary>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['Timestamp']['output'];
  userTags?: Maybe<Array<Scalars['String']['output']>>;
  warehouse: Warehouse;
};

export type AssignmentActualitiesWithArgs = {
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<ActualitiesSortBy>>;
};

export type AssignmentItemsArgs = {
  filter?: InputMaybe<AssignmentItemsSearchFilter>;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<AssignmentItemsSortBy>>;
};

export type AssignmentSimulationsWithArgs = {
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<SimulationsSortBy>>;
};

export type AssignmentCapacityPolicy = {
  __typename?: 'AssignmentCapacityPolicy';
  locationFillingMethod?: Maybe<LocationFillingMethod>;
};

export type AssignmentCapacityPolicyInput = {
  locationFillingMethod?: InputMaybe<LocationFillingMethod>;
};

export type AssignmentCapacitySettings = {
  __typename?: 'AssignmentCapacitySettings';
  avoidMerge?: Maybe<Scalars['Boolean']['output']>;
};

export type AssignmentCapacitySettingsInput = {
  avoidMerge?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AssignmentChange = {
  __typename?: 'AssignmentChange';
  moves: Array<AssignmentChangeItemMove>;
  prevAssignmentId?: Maybe<Scalars['ID']['output']>;
};

export type AssignmentChangeItemMove = {
  __typename?: 'AssignmentChangeItemMove';
  consignee: Scalars['ID']['output'];
  destCapacity?: Maybe<AssignmentItemCapacity>;
  destLocationId?: Maybe<Scalars['ID']['output']>;
  estimatedCostImprovement?: Maybe<Scalars['Float']['output']>;
  itemId: Scalars['ID']['output'];
  movedQuantity?: Maybe<AssignmentItemCapacity>;
  reason?: Maybe<AssignmentChangeReason>;
  sku: Scalars['ID']['output'];
  srcCapacity?: Maybe<AssignmentItemCapacity>;
  srcLocationId?: Maybe<Scalars['ID']['output']>;
};

export enum AssignmentChangeReason {
  ALLOCATE = 'ALLOCATE',
  ALLOCATE_AND_OPTIMIZE = 'ALLOCATE_AND_OPTIMIZE',
  OPTIMIZE = 'OPTIMIZE',
}

export type AssignmentChangeSequence = {
  __typename?: 'AssignmentChangeSequence';
  groupCount: Scalars['Float']['output'];
  moves: Array<AssignmentChangeSequenceMove>;
};

export enum AssignmentChangeSequenceAction {
  EXCHANGE = 'EXCHANGE',
  PUT = 'PUT',
  STASH = 'STASH',
  TAKE = 'TAKE',
  UNSTASH = 'UNSTASH',
}

export type AssignmentChangeSequenceMove = {
  __typename?: 'AssignmentChangeSequenceMove';
  chainNumber: Scalars['Float']['output'];
  consignee: Scalars['ID']['output'];
  destAction: AssignmentChangeSequenceAction;
  destCapacity?: Maybe<AssignmentItemCapacity>;
  destLocationId?: Maybe<Scalars['ID']['output']>;
  estimatedCostImprovement?: Maybe<Scalars['Float']['output']>;
  groupId: Scalars['ID']['output'];
  itemId: Scalars['ID']['output'];
  moveId: Scalars['ID']['output'];
  movedQuantity?: Maybe<AssignmentItemCapacity>;
  reason?: Maybe<AssignmentChangeReason>;
  sequenceNumber: Scalars['Float']['output'];
  sku: Scalars['ID']['output'];
  srcCapacity?: Maybe<AssignmentItemCapacity>;
  srcLocationId?: Maybe<Scalars['ID']['output']>;
};

export type AssignmentCompliance = {
  __typename?: 'AssignmentCompliance';
  assignment: Assignment;
  assignmentId: Scalars['ID']['output'];
  assignmentPolicy?: Maybe<AssignmentPolicy>;
  completedAt?: Maybe<Scalars['Timestamp']['output']>;
  createdAt: Scalars['Timestamp']['output'];
  id: Scalars['ID']['output'];
  itemSet: ItemSet;
  items: AssignmentComplianceItemsConnection;
  layout: Layout;
  locationSharingPolicy?: Maybe<LocationSharingPolicy>;
  progress: Scalars['Float']['output'];
  simulation: Simulation;
  startedAt?: Maybe<Scalars['Timestamp']['output']>;
  status: BatchJobStatus;
  summary?: Maybe<AssignmentComplianceSummary>;
  updatedAt: Scalars['Timestamp']['output'];
  warehouse: Warehouse;
};

export type AssignmentComplianceItemsArgs = {
  filter?: InputMaybe<AssignmentComplianceItemFilter>;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<AssignmentComplianceItemSortBy>>;
};

export type AssignmentComplianceItem = {
  __typename?: 'AssignmentComplianceItem';
  consignee: Scalars['ID']['output'];
  item?: Maybe<Item>;
  itemId: Scalars['ID']['output'];
  /** @deprecated in favour of itemRuleIds */
  itemRuleId?: Maybe<Scalars['ID']['output']>;
  itemRuleIds?: Maybe<Array<Scalars['ID']['output']>>;
  location?: Maybe<LayoutLocation>;
  locationId: Scalars['ID']['output'];
  /** @deprecated in favour of locationRuleIds */
  locationRuleId?: Maybe<Scalars['ID']['output']>;
  locationRuleIds?: Maybe<Array<Scalars['ID']['output']>>;
  sku: Scalars['ID']['output'];
  status: AssignmentComplianceItemStatus;
  violatedRuleIds?: Maybe<Array<Scalars['ID']['output']>>;
};

export type AssignmentComplianceItemFilter = {
  bayId?: InputMaybe<Array<Scalars['ID']['input']>>;
  itemRuleId?: InputMaybe<Array<Scalars['ID']['input']>>;
  level?: InputMaybe<Array<Scalars['Float']['input']>>;
  locationRuleId?: InputMaybe<Array<Scalars['ID']['input']>>;
  locationUsageType?: InputMaybe<Array<Scalars['ID']['input']>>;
  planeId?: InputMaybe<Array<Scalars['ID']['input']>>;
  status?: InputMaybe<Array<AssignmentComplianceItemStatus>>;
  violatedRuleId?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type AssignmentComplianceItemSortBy = {
  /** Sorting direction */
  direction?: SortDirection;
  /** Field (sort option) to sort by */
  field: AssignmentComplianceItemSortOption;
};

export enum AssignmentComplianceItemSortOption {
  CONSIGNEE = 'CONSIGNEE',
  LOCATION_ID = 'LOCATION_ID',
  SKU = 'SKU',
}

export enum AssignmentComplianceItemStatus {
  COMPLIANT = 'COMPLIANT',
  NONCOMPLIANT = 'NONCOMPLIANT',
}

export type AssignmentComplianceItemsConnection = {
  __typename?: 'AssignmentComplianceItemsConnection';
  /** Content of collection or the current page of collection if pagination is used */
  content: Array<AssignmentComplianceItem>;
  /** Total number of elements in this collection (ignoring pagination constraints, if any) */
  totalCount: Scalars['Float']['output'];
};

export type AssignmentComplianceSummary = {
  __typename?: 'AssignmentComplianceSummary';
  rulesSummary: AssignmentPolicyComplianceRulesSummary;
  totalSummary?: Maybe<AssignmentPolicyComplianceRuleMatch>;
};

export type AssignmentConsigneeSummary = {
  __typename?: 'AssignmentConsigneeSummary';
  consignee: Scalars['ID']['output'];
  itemsCount: Scalars['Float']['output'];
  locationsCount: Scalars['Float']['output'];
  productsCount: Scalars['Float']['output'];
};

export type AssignmentDetailsItem = {
  __typename?: 'AssignmentDetailsItem';
  consignee: Scalars['ID']['output'];
  cumulativePercentRank?: Maybe<Scalars['Float']['output']>;
  locationId: Scalars['ID']['output'];
  orderLineCount: Scalars['Float']['output'];
  percentRank?: Maybe<Scalars['Float']['output']>;
  quantityOrdered: Scalars['Float']['output'];
  sku: Scalars['ID']['output'];
};

export type AssignmentDetailsItemsSortBy = {
  /** Sorting direction */
  direction?: SortDirection;
  /** Field (sort option) to sort by */
  field: AssignmentDetailsItemsSortOption;
};

export enum AssignmentDetailsItemsSortOption {
  CONSIGNEE = 'CONSIGNEE',
  HIT_COUNT = 'HIT_COUNT',
  LOCATION_ID = 'LOCATION_ID',
  SKU = 'SKU',
}

export type AssignmentDiff = {
  __typename?: 'AssignmentDiff';
  items: AssignmentDiffItemsConnection;
};

export type AssignmentDiffItemsArgs = {
  filter?: InputMaybe<AssignmentDiffItemsFilter>;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<AssignmentDiffItemsSortBy>>;
};

export type AssignmentDiffItem = {
  __typename?: 'AssignmentDiffItem';
  cumulativePercentRank?: Maybe<Scalars['Float']['output']>;
  destLocations: Array<LocationReference>;
  product: ProductId;
  productDetails?: Maybe<OrderSetItem>;
  srcLocations: Array<LocationReference>;
};

export type AssignmentDiffItemsConnection = {
  __typename?: 'AssignmentDiffItemsConnection';
  /** Content of collection or the current page of collection if pagination is used */
  content: Array<AssignmentDiffItem>;
  /** Total number of elements in this collection (ignoring pagination constraints, if any) */
  totalCount: Scalars['Float']['output'];
};

export type AssignmentDiffItemsFilter = {
  destLocationIdContains?: InputMaybe<Scalars['String']['input']>;
  productConsigneeContains?: InputMaybe<Scalars['String']['input']>;
  productSkuContains?: InputMaybe<Scalars['String']['input']>;
  srcLocationIdContains?: InputMaybe<Scalars['String']['input']>;
};

export type AssignmentDiffItemsSortBy = {
  /** Sorting direction */
  direction?: SortDirection;
  /** Field (sort option) to sort by */
  field: AssignmentDiffItemsSortOption;
};

export enum AssignmentDiffItemsSortOption {
  CONSIGNEE = 'CONSIGNEE',
  HIT_COUNT = 'HIT_COUNT',
  SKU = 'SKU',
}

export enum AssignmentImportStatus {
  COMPUTED = 'COMPUTED',
  IMPORTED = 'IMPORTED',
  IMPORTING = 'IMPORTING',
  REASSIGNED = 'REASSIGNED',
}

export type AssignmentIssueInvalidLocation = {
  __typename?: 'AssignmentIssueInvalidLocation';
  itemIds: Array<Scalars['String']['output']>;
  locationBayId?: Maybe<Scalars['ID']['output']>;
  locationId: Scalars['ID']['output'];
  locationLevel?: Maybe<Scalars['Float']['output']>;
  planeId?: Maybe<Scalars['ID']['output']>;
  productIds: Array<ProductId>;
  reason: Array<AssignmentIssueInvalidLocationReason>;
};

export enum AssignmentIssueInvalidLocationReason {
  DISABLED_LOCATION_WITH_ITEMS = 'DISABLED_LOCATION_WITH_ITEMS',
  EMPTY_LOCATION = 'EMPTY_LOCATION',
  LOCATION_WITH_MIXED_ITEMS = 'LOCATION_WITH_MIXED_ITEMS',
  UNKNOWN_LOCATION = 'UNKNOWN_LOCATION',
}

export type AssignmentIssueInvalidLocationsConnection = {
  __typename?: 'AssignmentIssueInvalidLocationsConnection';
  /** Content of collection or the current page of collection if pagination is used */
  content: Array<AssignmentIssueInvalidLocation>;
  /** Total number of elements in this collection (ignoring pagination constraints, if any) */
  totalCount: Scalars['Float']['output'];
};

export type AssignmentIssueInvalidLocationsFilter = {
  locationBayId?: InputMaybe<Array<Scalars['ID']['input']>>;
  locationLevel?: InputMaybe<Array<Scalars['Int']['input']>>;
  planeId?: InputMaybe<Array<Scalars['ID']['input']>>;
  reason?: InputMaybe<Array<AssignmentIssueInvalidLocationReason>>;
};

export type AssignmentIssueInvalidLocationsSummary = {
  __typename?: 'AssignmentIssueInvalidLocationsSummary';
  count: Scalars['Float']['output'];
  locationLevel?: Maybe<Scalars['Float']['output']>;
  planeId?: Maybe<Scalars['ID']['output']>;
  reason: AssignmentIssueInvalidLocationReason;
};

export type AssignmentIssueSet = {
  __typename?: 'AssignmentIssueSet';
  assignmentId: Scalars['ID']['output'];
  completedAt?: Maybe<Scalars['Timestamp']['output']>;
  createdAt: Scalars['Timestamp']['output'];
  id: Scalars['ID']['output'];
  invalidLocations: AssignmentIssueInvalidLocationsConnection;
  layoutId: Scalars['ID']['output'];
  progress: Scalars['Float']['output'];
  startedAt?: Maybe<Scalars['Timestamp']['output']>;
  status: BatchJobStatus;
  summary?: Maybe<AssignmentIssueSummary>;
  updatedAt: Scalars['Timestamp']['output'];
};

export type AssignmentIssueSetInvalidLocationsArgs = {
  filter?: InputMaybe<AssignmentIssueInvalidLocationsFilter>;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<LayoutLocationsSortBy>>;
};

export type AssignmentIssueSetKey = {
  assignmentId: Scalars['ID']['input'];
  layoutId: Scalars['ID']['input'];
};

export type AssignmentIssueSummary = {
  __typename?: 'AssignmentIssueSummary';
  invalidLocations: Array<AssignmentIssueInvalidLocationsSummary>;
  totalAssignedLocations: Scalars['Float']['output'];
  totalAssignmentItems: Scalars['Float']['output'];
  totalInvalidLocations: Scalars['Float']['output'];
  totalLocations: Scalars['Float']['output'];
};

export type AssignmentItem = {
  __typename?: 'AssignmentItem';
  consignee: Scalars['ID']['output'];
  itemId: Scalars['ID']['output'];
  locationId: Scalars['ID']['output'];
  sku: Scalars['ID']['output'];
  stockUom?: Maybe<Scalars['ID']['output']>;
  stockUomQuantity?: Maybe<Scalars['Float']['output']>;
};

export type AssignmentItemCapacity = {
  __typename?: 'AssignmentItemCapacity';
  breakdownByUom: Array<ItemUomQuantity>;
};

export type AssignmentItemWithMatchingFilter = {
  __typename?: 'AssignmentItemWithMatchingFilter';
  consignee: Scalars['ID']['output'];
  item?: Maybe<Item>;
  itemId: Scalars['ID']['output'];
  location?: Maybe<LayoutLocation>;
  locationId: Scalars['ID']['output'];
  matchingFilter?: Maybe<Scalars['Int']['output']>;
  sku: Scalars['ID']['output'];
  stockUom?: Maybe<Scalars['ID']['output']>;
  stockUomQuantity?: Maybe<Scalars['Float']['output']>;
};

export type AssignmentItemWithMatchingFiltersConnection = {
  __typename?: 'AssignmentItemWithMatchingFiltersConnection';
  /** Content of collection or the current page of collection if pagination is used */
  content: Array<AssignmentItemWithMatchingFilter>;
  /** Total number of elements in this collection (ignoring pagination constraints, if any) */
  totalCount: Scalars['Float']['output'];
};

export type AssignmentItemsConnection = {
  __typename?: 'AssignmentItemsConnection';
  /** Content of collection or the current page of collection if pagination is used */
  content: Array<AssignmentItem>;
  /** Total number of elements in this collection (ignoring pagination constraints, if any) */
  totalCount: Scalars['Float']['output'];
};

export type AssignmentItemsExtendedFilter = {
  bayId?: InputMaybe<Array<Scalars['ID']['input']>>;
  consignee?: InputMaybe<StringSearchFilter>;
  id?: InputMaybe<StringSearchFilter>;
  level?: InputMaybe<Array<Scalars['Float']['input']>>;
  locationId?: InputMaybe<Array<Scalars['ID']['input']>>;
  planeId?: InputMaybe<Array<Scalars['ID']['input']>>;
  pollutionClass?: InputMaybe<StringSearchFilter>;
  sku?: InputMaybe<StringSearchFilter>;
  skuGroup?: InputMaybe<StringSearchFilter>;
  stockCategory?: InputMaybe<StringSearchFilter>;
  storageClass?: InputMaybe<StringSearchFilter>;
  subGroup?: InputMaybe<StringSearchFilter>;
  transportClass?: InputMaybe<StringSearchFilter>;
};

export type AssignmentItemsSearchFilter = {
  consignee?: InputMaybe<StringSearchFilter>;
  locationId?: InputMaybe<StringSearchFilter>;
  sku?: InputMaybe<StringSearchFilter>;
};

export type AssignmentItemsSortBy = {
  /** Sorting direction */
  direction?: SortDirection;
  /** Field (sort option) to sort by */
  field: AssignmentItemsSortOptions;
};

export enum AssignmentItemsSortOptions {
  CONSIGNEE = 'CONSIGNEE',
  LOCATION_ID = 'LOCATION_ID',
  SKU = 'SKU',
}

export type AssignmentOccupancy = {
  __typename?: 'AssignmentOccupancy';
  assignment: Assignment;
  completedAt?: Maybe<Scalars['Timestamp']['output']>;
  createdAt: Scalars['Timestamp']['output'];
  id: Scalars['ID']['output'];
  itemSet: ItemSet;
  layout: Layout;
  locations: AssignmentOccupancyLocationsConnection;
  locationsByOccupancyCategory: AssignmentOccupancyLocationsByCategoryConnection;
  primarySimulation: Simulation;
  progress: Scalars['Float']['output'];
  startedAt?: Maybe<Scalars['Timestamp']['output']>;
  status: BatchJobStatus;
  summary?: Maybe<AssignmentOccupancySummary>;
  summaryByGroup: Array<AssignmentOccupancySummaryGroup>;
  updatedAt: Scalars['Timestamp']['output'];
  warehouse: Warehouse;
};

export type AssignmentOccupancyLocationsArgs = {
  filter?: InputMaybe<AssignmentOccupancyLocationFilter>;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<AssignmentOccupancyLocationSortBy>>;
};

export type AssignmentOccupancyLocationsByOccupancyCategoryArgs = {
  filter?: InputMaybe<AssignmentOccupancyLocationFilter>;
  input: AssignmentOccupancyLocationsByCategoryRequest;
};

export type AssignmentOccupancySummaryByGroupArgs = {
  filter?: InputMaybe<AssignmentOccupancyLocationFilter>;
  groupBy?: InputMaybe<Array<AssignmentOccupancySummaryGroupBy>>;
};

export type AssignmentOccupancyCapacity = {
  __typename?: 'AssignmentOccupancyCapacity';
  occupiedVolume?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  uom?: Maybe<ItemUomVolume>;
  uomQuantity?: Maybe<Scalars['Float']['output']>;
};

export type AssignmentOccupancyCapacitySummary = {
  __typename?: 'AssignmentOccupancyCapacitySummary';
  emptyLocations?: Maybe<Scalars['Float']['output']>;
  locationsWithCapacity?: Maybe<Scalars['Float']['output']>;
  locationsWithUnknownCapacity?: Maybe<Scalars['Float']['output']>;
  totalCapacityVolume?: Maybe<Scalars['Float']['output']>;
  totalEmptyVolume?: Maybe<Scalars['Float']['output']>;
  totalUnknownVolume?: Maybe<Scalars['Float']['output']>;
};

export type AssignmentOccupancyLocationDetails = {
  __typename?: 'AssignmentOccupancyLocationDetails';
  items: Array<AssignmentOccupancyLocationItem>;
  location: LayoutLocation;
  locationId: Scalars['ID']['output'];
  locationVolume?: Maybe<Scalars['Float']['output']>;
  occupancy?: Maybe<Scalars['Float']['output']>;
  occupiedVolume?: Maybe<Scalars['Float']['output']>;
  status: AssignmentOccupancyLocationStatus;
};

export type AssignmentOccupancyLocationFilter = {
  bayId?: InputMaybe<Array<Scalars['ID']['input']>>;
  level?: InputMaybe<Array<Scalars['Float']['input']>>;
  locationAisleTitle?: InputMaybe<StringSearchFilter>;
  locationBayPosition?: InputMaybe<NumberSearchFilter>;
  locationBayTitle?: InputMaybe<StringSearchFilter>;
  locationHeight?: InputMaybe<NumberSearchFilter>;
  locationId?: InputMaybe<StringSearchFilter>;
  locationLength?: InputMaybe<NumberSearchFilter>;
  locationLevel?: InputMaybe<NumberSearchFilter>;
  locationOrder?: InputMaybe<NumberSearchFilter>;
  locationPlaneTitle?: InputMaybe<StringSearchFilter>;
  locationRackingType?: InputMaybe<StringSearchFilter>;
  locationStatus?: InputMaybe<BooleanSearchFilter>;
  locationUsageType?: InputMaybe<StringSearchFilter>;
  locationWeight?: InputMaybe<NumberSearchFilter>;
  locationWidth?: InputMaybe<NumberSearchFilter>;
  locationsMatch?: InputMaybe<LocationFilterUnionInput>;
  locmhtype?: InputMaybe<StringSearchFilter>;
  maxOccupancy?: InputMaybe<Scalars['Float']['input']>;
  minOccupancy?: InputMaybe<Scalars['Float']['input']>;
  planeId?: InputMaybe<Array<Scalars['ID']['input']>>;
  status?: InputMaybe<Array<AssignmentOccupancyLocationStatus>>;
  stockUomQuantityCompliance?: InputMaybe<Array<StockUomQuantityCompliance>>;
  warehouseArea?: InputMaybe<StringSearchFilter>;
};

export type AssignmentOccupancyLocationItem = {
  __typename?: 'AssignmentOccupancyLocationItem';
  consignee: Scalars['ID']['output'];
  itemId: Scalars['ID']['output'];
  maxUomCapacity?: Maybe<AssignmentOccupancyCapacity>;
  occupiedVolume?: Maybe<Scalars['Float']['output']>;
  sku: Scalars['ID']['output'];
  status: AssignmentOccupancyLocationItemStatus;
  stockQuantity?: Maybe<Scalars['Float']['output']>;
  stockUom?: Maybe<ItemUomVolume>;
  stockUomCapacity?: Maybe<AssignmentOccupancyCapacity>;
  stockUomQuantity?: Maybe<Scalars['Float']['output']>;
  stockUomQuantityCompliance?: Maybe<StockUomQuantityCompliance>;
};

export enum AssignmentOccupancyLocationItemStatus {
  CALCULATED = 'CALCULATED',
  UNKNOWN_ITEM = 'UNKNOWN_ITEM',
  UNKNOWN_ITEM_STOCK = 'UNKNOWN_ITEM_STOCK',
  UNKNOWN_ITEM_STOCK_UOM = 'UNKNOWN_ITEM_STOCK_UOM',
  UNKNOWN_ITEM_STOCK_UOM_VOLUME = 'UNKNOWN_ITEM_STOCK_UOM_VOLUME',
  UNKNOWN_LOCATION_VOLUME = 'UNKNOWN_LOCATION_VOLUME',
}

export type AssignmentOccupancyLocationSortBy = {
  /** Sorting direction */
  direction?: SortDirection;
  /** Field (sort option) to sort by */
  field: AssignmentOccupancyLocationSortOption;
};

export enum AssignmentOccupancyLocationSortOption {
  LOCATION_ID = 'LOCATION_ID',
  OCCUPANCY = 'OCCUPANCY',
}

export enum AssignmentOccupancyLocationStatus {
  EMPTY = 'EMPTY',
  OCCUPIED = 'OCCUPIED',
  PARTIALLY_UNKNOWN_ITEMS_VOLUME = 'PARTIALLY_UNKNOWN_ITEMS_VOLUME',
  UNKNOWN_ITEMS_VOLUME = 'UNKNOWN_ITEMS_VOLUME',
  UNKNOWN_LOCATION_VOLUME = 'UNKNOWN_LOCATION_VOLUME',
}

export type AssignmentOccupancyLocationsByCategoryConnection = {
  __typename?: 'AssignmentOccupancyLocationsByCategoryConnection';
  categories: Array<AssignmentOccupancyLocationsCategory>;
  categoriesByStatus: Array<AssignmentOccupancyLocationsCategoryByStatus>;
  totalCount: Scalars['Float']['output'];
};

export type AssignmentOccupancyLocationsByCategoryRequest = {
  categoryWidths: Array<Scalars['Float']['input']>;
};

export type AssignmentOccupancyLocationsCategory = {
  __typename?: 'AssignmentOccupancyLocationsCategory';
  count: Scalars['Float']['output'];
  maxBoundary?: Maybe<Scalars['Float']['output']>;
  maxOccupancy?: Maybe<Scalars['Float']['output']>;
  minBoundary?: Maybe<Scalars['Float']['output']>;
  minOccupancy?: Maybe<Scalars['Float']['output']>;
  totalOccupiedVolume?: Maybe<Scalars['Float']['output']>;
  totalStorageVolume?: Maybe<Scalars['Float']['output']>;
};

export type AssignmentOccupancyLocationsCategoryByStatus = {
  __typename?: 'AssignmentOccupancyLocationsCategoryByStatus';
  count: Scalars['Float']['output'];
  status: AssignmentOccupancyLocationStatus;
  totalStorageVolume?: Maybe<Scalars['Float']['output']>;
};

export type AssignmentOccupancyLocationsConnection = {
  __typename?: 'AssignmentOccupancyLocationsConnection';
  /** Content of collection or the current page of collection if pagination is used */
  content: Array<AssignmentOccupancyLocationDetails>;
  /** Total number of elements in this collection (ignoring pagination constraints, if any) */
  totalCount: Scalars['Float']['output'];
};

export type AssignmentOccupancySummary = {
  __typename?: 'AssignmentOccupancySummary';
  maxOccupancy?: Maybe<Scalars['Float']['output']>;
  maxUomCapacitySummary?: Maybe<AssignmentOccupancyCapacitySummary>;
  stockUomCapacitySummary?: Maybe<AssignmentOccupancyCapacitySummary>;
  totalOccupancy?: Maybe<Scalars['Float']['output']>;
  totalOccupiedVolume?: Maybe<Scalars['Float']['output']>;
  totalStorageVolume?: Maybe<Scalars['Float']['output']>;
  totalUnknownVolume?: Maybe<Scalars['Float']['output']>;
};

export type AssignmentOccupancySummaryGroup = {
  __typename?: 'AssignmentOccupancySummaryGroup';
  key: AssignmentOccupancySummaryGroupKey;
  locationCount: Scalars['Float']['output'];
  maxOccupancy?: Maybe<Scalars['Float']['output']>;
  maxUomCapacitySummary?: Maybe<AssignmentOccupancyCapacitySummary>;
  stockUomCapacitySummary?: Maybe<AssignmentOccupancyCapacitySummary>;
  totalOccupancy?: Maybe<Scalars['Float']['output']>;
  totalOccupiedVolume?: Maybe<Scalars['Float']['output']>;
  totalStorageVolume?: Maybe<Scalars['Float']['output']>;
  totalUnknownVolume?: Maybe<Scalars['Float']['output']>;
};

export enum AssignmentOccupancySummaryGroupBy {
  LEVEL = 'LEVEL',
  LOCATION_RACKING_TYPE = 'LOCATION_RACKING_TYPE',
  LOCATION_USAGE_TYPE = 'LOCATION_USAGE_TYPE',
  PLANE = 'PLANE',
  STATUS = 'STATUS',
  WAREHOUSE_AREA = 'WAREHOUSE_AREA',
}

export type AssignmentOccupancySummaryGroupKey = {
  __typename?: 'AssignmentOccupancySummaryGroupKey';
  locationLevel?: Maybe<Scalars['Float']['output']>;
  locationRackingType?: Maybe<Scalars['ID']['output']>;
  locationUsageType?: Maybe<Scalars['ID']['output']>;
  planeId?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<AssignmentOccupancyLocationStatus>;
  warehouseArea?: Maybe<Scalars['ID']['output']>;
};

export type AssignmentPolicy = {
  __typename?: 'AssignmentPolicy';
  fallbackItemsAllowAnyLocations?: Maybe<Scalars['Boolean']['output']>;
  fallbackLocationsAllowAnyItems?: Maybe<Scalars['Boolean']['output']>;
  fallbackLocationsMatch?: Maybe<LocationFilterUnion>;
  rules: Array<AssignmentPolicyRule>;
};

export type AssignmentPolicyComplianceRuleMatch = {
  __typename?: 'AssignmentPolicyComplianceRuleMatch';
  compliantCount: Scalars['Float']['output'];
  nonCompliantCount: Scalars['Float']['output'];
  totalCount: Scalars['Float']['output'];
};

export type AssignmentPolicyComplianceRuleSummary = {
  __typename?: 'AssignmentPolicyComplianceRuleSummary';
  matchedByItem: AssignmentPolicyComplianceRuleMatch;
  matchedByLocation: AssignmentPolicyComplianceRuleMatch;
  rule?: Maybe<AssignmentPolicyRule>;
  ruleId: Scalars['ID']['output'];
};

export type AssignmentPolicyComplianceRulesSummary = {
  __typename?: 'AssignmentPolicyComplianceRulesSummary';
  rules: Array<AssignmentPolicyComplianceRuleSummary>;
  unmatchedByItem: AssignmentPolicyComplianceRuleMatch;
  unmatchedByLocation: AssignmentPolicyComplianceRuleMatch;
};

export type AssignmentPolicyConstraintSettings = {
  __typename?: 'AssignmentPolicyConstraintSettings';
  disabled?: Maybe<Scalars['Boolean']['output']>;
};

export type AssignmentPolicyConstraintSettingsInput = {
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AssignmentPolicyInput = {
  fallbackItemsAllowAnyLocations?: InputMaybe<Scalars['Boolean']['input']>;
  fallbackLocationsAllowAnyItems?: InputMaybe<Scalars['Boolean']['input']>;
  fallbackLocationsMatch?: InputMaybe<LocationFilterUnionInput>;
  rules: Array<AssignmentPolicyRuleInput>;
};

export type AssignmentPolicyRule = {
  __typename?: 'AssignmentPolicyRule';
  id: Scalars['ID']['output'];
  locationsMatch?: Maybe<LocationFilterUnion>;
  productsMatch?: Maybe<SimulationItemFilterUnion>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<AssignmentPolicyRuleMatchType>;
};

export type AssignmentPolicyRuleInput = {
  id: Scalars['ID']['input'];
  locationsMatch?: InputMaybe<LocationFilterUnionInput>;
  productsMatch?: InputMaybe<SimulationItemFilterUnionInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<AssignmentPolicyRuleMatchType>;
};

export type AssignmentPolicyRuleIntersectionSummary = {
  __typename?: 'AssignmentPolicyRuleIntersectionSummary';
  itemsMatch: Scalars['Float']['output'];
  locationsMatch: Scalars['Float']['output'];
  rules: Array<AssignmentPolicyRule>;
};

export enum AssignmentPolicyRuleMatchType {
  ITEM_MUST_BE_IN_LOCATION = 'ITEM_MUST_BE_IN_LOCATION',
  LOCATION_MUST_CONTAIN_ITEM = 'LOCATION_MUST_CONTAIN_ITEM',
  STRICT_MATCH = 'STRICT_MATCH',
}

export type AssignmentPolicyRuleSummary = {
  __typename?: 'AssignmentPolicyRuleSummary';
  itemsMatch: Scalars['Float']['output'];
  locationsMatch: Scalars['Float']['output'];
  rule: AssignmentPolicyRule;
};

export type AssignmentPolicySummary = {
  __typename?: 'AssignmentPolicySummary';
  ruleIntersections: Array<AssignmentPolicyRuleIntersectionSummary>;
  rules: Array<AssignmentPolicyRuleSummary>;
};

export type AssignmentSubsetSpec = {
  __typename?: 'AssignmentSubsetSpec';
  /** @deprecated in favour of unassignedLocationsMatch = EXCLUDE/INCLUDE */
  allowEmptyLocations?: Maybe<Scalars['Boolean']['output']>;
  itemsMatch?: Maybe<SimulationItemFilterUnion>;
  locationsMatch?: Maybe<LocationFilterUnion>;
  unassignedLocationsMatch?: Maybe<UnassignedLocationsMatch>;
};

export type AssignmentSubsetSpecInput = {
  allowEmptyLocations?: InputMaybe<Scalars['Boolean']['input']>;
  itemsMatch?: InputMaybe<SimulationItemFilterUnionInput>;
  locationsMatch?: InputMaybe<LocationFilterUnionInput>;
  unassignedLocationsMatch?: InputMaybe<UnassignedLocationsMatch>;
};

export type AssignmentSubsetSummary = {
  __typename?: 'AssignmentSubsetSummary';
  assignmentItemCount: Scalars['Float']['output'];
  emptyLocationCount: Scalars['Float']['output'];
  itemCount: Scalars['Float']['output'];
  locationCount: Scalars['Float']['output'];
};

export type AssignmentSummary = {
  __typename?: 'AssignmentSummary';
  consignees: Array<AssignmentConsigneeSummary>;
  fields?: Maybe<Array<DatasetObjectFieldSummary>>;
  rawDataFields?: Maybe<Array<DatasetObjectRawDataFieldSummary>>;
  totalItemsCount: Scalars['Float']['output'];
  totalLocationsCount: Scalars['Float']['output'];
  totalProductsCount: Scalars['Float']['output'];
};

export type AssignmentsConnection = {
  __typename?: 'AssignmentsConnection';
  allUserTags: Array<Scalars['String']['output']>;
  /** Content of collection or the current page of collection if pagination is used */
  content: Array<Assignment>;
  /** Total number of elements in this collection (ignoring pagination constraints, if any) */
  totalCount: Scalars['Float']['output'];
};

export type AssignmentsFilter = {
  status?: InputMaybe<Array<AssignmentImportStatus>>;
  title?: InputMaybe<StringSearchFilter>;
  userTags?: InputMaybe<Array<Scalars['String']['input']>>;
  userTagsHasAll?: InputMaybe<Array<Scalars['String']['input']>>;
  userTagsHasAny?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type AssignmentsSortBy = {
  /** Sorting direction */
  direction?: SortDirection;
  /** Field (sort option) to sort by */
  field: BaseEntitySortOption;
};

export type AwsAccessKey = {
  __typename?: 'AwsAccessKey';
  accessKeyId: Scalars['String']['output'];
  secretAccessKey: Scalars['String']['output'];
};

export enum BaseEntitySortOption {
  CREATED_AT = 'CREATED_AT',
  TITLE = 'TITLE',
  UPDATED_AT = 'UPDATED_AT',
}

export type BaseUom = {
  __typename?: 'BaseUom';
  ean?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  length?: Maybe<Scalars['Float']['output']>;
  maxStack?: Maybe<Scalars['Int']['output']>;
  netWeight?: Maybe<Scalars['Float']['output']>;
  uom?: Maybe<Scalars['ID']['output']>;
  upc?: Maybe<Scalars['String']['output']>;
  volume?: Maybe<Scalars['Float']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

export type BatchBuildLayoutRouteInput = {
  agentId?: InputMaybe<Scalars['ID']['input']>;
  includeTerminals?: InputMaybe<Scalars['Boolean']['input']>;
  layoutId: Scalars['ID']['input'];
  locations: Array<Array<Scalars['ID']['input']>>;
  order?: InputMaybe<LayoutRouteOrder>;
  routingPolicy?: InputMaybe<RoutingPolicyInput>;
};

export enum BatchJobEngine {
  FARGATE = 'FARGATE',
  LAMBDA = 'LAMBDA',
}

export enum BatchJobExecutionStatus {
  ABORTED = 'ABORTED',
  FAILED = 'FAILED',
  RUNNING = 'RUNNING',
  SUCCEEDED = 'SUCCEEDED',
  TIMED_OUT = 'TIMED_OUT',
}

export enum BatchJobStatus {
  CALCULATING = 'CALCULATING',
  CREATED = 'CREATED',
  FAILED = 'FAILED',
  READY = 'READY',
  RESTARTED = 'RESTARTED',
  TERMINATED = 'TERMINATED',
}

export type BatchStartImportJobProcessingRequest = {
  ids: Array<Scalars['ID']['input']>;
  options?: InputMaybe<StartImportJobProcessingOptions>;
};

export type Bay = {
  __typename?: 'Bay';
  bayType?: Maybe<Scalars['ID']['output']>;
  depth: Scalars['Float']['output'];
  frontEdge: Vector;
  hasPass: Scalars['Boolean']['output'];
  height?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  levels?: Maybe<Array<BayLevel>>;
  locationOrder: BayLocationOrder;
  position: Point;
  title?: Maybe<Scalars['String']['output']>;
  width: Scalars['Float']['output'];
};

export type BayInput = {
  bayType?: InputMaybe<Scalars['ID']['input']>;
  depth: Scalars['Float']['input'];
  frontEdge: VectorInput;
  hasPass: Scalars['Boolean']['input'];
  height?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['ID']['input'];
  levels?: InputMaybe<Array<BayLevelInput>>;
  locationOrder: BayLocationOrder;
  position: PointInput;
  title?: InputMaybe<Scalars['String']['input']>;
  width: Scalars['Float']['input'];
};

export type BayLevel = {
  __typename?: 'BayLevel';
  heightFromFloor: Scalars['Float']['output'];
  level: Scalars['Float']['output'];
  levelHeight: Scalars['Float']['output'];
};

export type BayLevelInput = {
  heightFromFloor: Scalars['Float']['input'];
  level: Scalars['Float']['input'];
  levelHeight: Scalars['Float']['input'];
};

export enum BayLocationOrder {
  LTR = 'LTR',
  RTL = 'RTL',
}

export type BayPortal = {
  __typename?: 'BayPortal';
  aisleId: Scalars['ID']['output'];
  bayId: Scalars['ID']['output'];
  bayProjection: Segment1D;
  coords: Segment;
};

export type BayPortalInput = {
  aisleId: Scalars['ID']['input'];
  bayId: Scalars['ID']['input'];
  bayProjection: Segment1DInput;
  coords: SegmentInput;
};

export type BayTypeModel = {
  __typename?: 'BayTypeModel';
  bayType?: Maybe<Scalars['String']['output']>;
  maxWeight?: Maybe<Scalars['Float']['output']>;
  shelfHeight?: Maybe<Scalars['Float']['output']>;
  verticalFrameProfile?: Maybe<Scalars['Float']['output']>;
};

export type BayTypeModelInput = {
  bayType?: InputMaybe<Scalars['String']['input']>;
  maxWeight?: InputMaybe<Scalars['Float']['input']>;
  shelfHeight?: InputMaybe<Scalars['Float']['input']>;
  verticalFrameProfile?: InputMaybe<Scalars['Float']['input']>;
};

export type BayWeightComplianceSummary = {
  __typename?: 'BayWeightComplianceSummary';
  items: Array<BayWeightComplianceSummaryItem>;
};

export type BayWeightComplianceSummaryItem = {
  __typename?: 'BayWeightComplianceSummaryItem';
  compliantCount: Scalars['Float']['output'];
  floorLevelCount: Scalars['Float']['output'];
  nonCompliantCount: Scalars['Float']['output'];
  planeId: Scalars['ID']['output'];
  totalCount: Scalars['Float']['output'];
  unassignedCount: Scalars['Float']['output'];
  unknownCount: Scalars['Float']['output'];
};

export type BayWidthConstraintSettings = {
  __typename?: 'BayWidthConstraintSettings';
  disabled?: Maybe<Scalars['Boolean']['output']>;
};

export type BayWidthConstraintSettingsInput = {
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BeginFileMultipartUploadInput = {
  id: StoredFileIdInput;
  parts: Scalars['Int']['input'];
};

export type BeginFileMultipartUploadResult = {
  __typename?: 'BeginFileMultipartUploadResult';
  id: StoredFileId;
  uploadId: Scalars['ID']['output'];
  uploadLinks: Array<Scalars['String']['output']>;
};

export type BeginImportPipelineMultipartUploadInput = {
  importPipelineId: Scalars['ID']['input'];
  parts: Scalars['Int']['input'];
};

export type BeginImportPipelineMultipartUploadResult = {
  __typename?: 'BeginImportPipelineMultipartUploadResult';
  importPipelineId: Scalars['ID']['output'];
  uploadId: Scalars['ID']['output'];
  uploadLinks: Array<Scalars['String']['output']>;
};

export enum BillableOperationType {
  ANALYZE = 'ANALYZE',
  OPTIMIZE = 'OPTIMIZE',
}

export type BooleanSearchFilter = {
  value: Scalars['Boolean']['input'];
};

export type BuildLayoutRouteInput = {
  agentId?: InputMaybe<Scalars['ID']['input']>;
  includeTerminals?: InputMaybe<Scalars['Boolean']['input']>;
  layoutId: Scalars['ID']['input'];
  locations: Array<Scalars['ID']['input']>;
  order?: InputMaybe<LayoutRouteOrder>;
  routingPolicy?: InputMaybe<RoutingPolicyInput>;
};

export type BuildSimulationLayoutRouteInput = {
  agentId?: InputMaybe<Scalars['ID']['input']>;
  includeTerminals?: InputMaybe<Scalars['Boolean']['input']>;
  locations: Array<Scalars['ID']['input']>;
  order?: InputMaybe<LayoutRouteOrder>;
  routingPolicy?: InputMaybe<RoutingPolicyInput>;
  simulationId: Scalars['ID']['input'];
};

export type CalculateSimulationAllocationRequirementSetInput = {
  deletePrevious?: InputMaybe<Scalars['Boolean']['input']>;
  settings?: InputMaybe<AllocationRequirementSetSettingsInput>;
  simulationId: Scalars['ID']['input'];
  startNow?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CalculateSimulationAssignmentComplianceOptionsInput = {
  assignmentId?: InputMaybe<Scalars['ID']['input']>;
};

export type CalculateSimulationAssignmentOccupancyOptionsInput = {
  assignmentId?: InputMaybe<Scalars['ID']['input']>;
};

export type CalculateSimulationSizeComplianceOptionsInput = {
  assignmentId?: InputMaybe<Scalars['ID']['input']>;
};

export type CalculateSimulationWeightComplianceOptionsInput = {
  assignmentId?: InputMaybe<Scalars['ID']['input']>;
};

export type CategoryMappingRule = {
  categoryWidth?: InputMaybe<Scalars['Float']['input']>;
  title: Scalars['String']['input'];
};

export type CategoryMappingRules = {
  categories: Array<CategoryMappingRule>;
};

export type ChangeDemoWarehouseSubscriptionInput = {
  subscribed: Scalars['Boolean']['input'];
  warehouseId: Scalars['ID']['input'];
};

export type CheckOptimizationSwapPolicyInput = {
  policy?: InputMaybe<SwapSettingsInput>;
  simulationId: Scalars['ID']['input'];
};

export type CheckOptimizationSwapPolicyJob = {
  __typename?: 'CheckOptimizationSwapPolicyJob';
  assignment: Assignment;
  assignmentPolicy?: Maybe<AssignmentPolicy>;
  completedAt?: Maybe<Scalars['Timestamp']['output']>;
  createdAt: Scalars['Timestamp']['output'];
  id: Scalars['ID']['output'];
  itemSet: ItemSet;
  layout: Layout;
  orderSet: OrderSet;
  pickingPolicy?: Maybe<PickingPolicy>;
  progress: Scalars['Float']['output'];
  result?: Maybe<CheckedOptimizationSwapPolicy>;
  simulation: Simulation;
  startedAt?: Maybe<Scalars['Timestamp']['output']>;
  status: BatchJobStatus;
  swapPolicy?: Maybe<SwapSettings>;
  updatedAt: Scalars['Timestamp']['output'];
};

export enum CheckOptimizationSwapPolicyNoValidSwapsReason {
  CONSTRAINT_VIOLATION = 'CONSTRAINT_VIOLATION',
  EMPTY_DESTINATION_SET = 'EMPTY_DESTINATION_SET',
  EMPTY_SOURCE_SET = 'EMPTY_SOURCE_SET',
  NO_MATCH_BY_PICKING_POLICY = 'NO_MATCH_BY_PICKING_POLICY',
  NO_MATCH_BY_SWAP_RULES = 'NO_MATCH_BY_SWAP_RULES',
}

export type CheckSimulationAssignmentPolicyInput = {
  includeUnassigned?: InputMaybe<Scalars['Boolean']['input']>;
  policy?: InputMaybe<AssignmentPolicyInput>;
  simulationId: Scalars['ID']['input'];
};

export type CheckSimulationAssignmentPolicyResult = {
  __typename?: 'CheckSimulationAssignmentPolicyResult';
  policy: CheckedAssignmentPolicy;
};

export type CheckSimulationLayoutAccessibilityInput = {
  routingPolicy?: InputMaybe<RoutingPolicyInput>;
  simulationId: Scalars['ID']['input'];
};

export type CheckSimulationLayoutAccessibilityResult = {
  __typename?: 'CheckSimulationLayoutAccessibilityResult';
  rules: Array<CheckedRoutingPolicyRule>;
  totalLocations: Scalars['Float']['output'];
};

export type CheckSimulationPickingPolicyInput = {
  /** Picking policy to check; if not specified, simulation's picking policy will be used */
  pickingPolicy?: InputMaybe<PickingPolicyInput>;
  simulationId: Scalars['ID']['input'];
};

export type CheckSimulationPickingPolicyResult = {
  __typename?: 'CheckSimulationPickingPolicyResult';
  policy: CheckedPickingPolicy;
};

export type CheckedAssignmentPolicy = {
  __typename?: 'CheckedAssignmentPolicy';
  allLocations: FilterMatch;
  allProducts: FilterMatch;
  fallbackItemFilterMatch: FilterMatch;
  fallbackItemsAllowAnyLocations?: Maybe<Scalars['Boolean']['output']>;
  fallbackLocationFilterMatch: FilterMatch;
  fallbackLocationsAllowAnyItems?: Maybe<Scalars['Boolean']['output']>;
  fallbackLocationsMatch?: Maybe<LocationFilterUnion>;
  rules: Array<CheckedAssignmentPolicyRule>;
};

export type CheckedAssignmentPolicyRule = {
  __typename?: 'CheckedAssignmentPolicyRule';
  id: Scalars['ID']['output'];
  locationFilterMatch: FilterMatch;
  locationsMatch?: Maybe<LocationFilterUnion>;
  productFilterMatch: FilterMatch;
  productsMatch?: Maybe<SimulationItemFilterUnion>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<AssignmentPolicyRuleMatchType>;
};

export type CheckedOptimizationSwapPolicy = {
  __typename?: 'CheckedOptimizationSwapPolicy';
  hasValidSwaps: Scalars['Boolean']['output'];
  rules: Array<CheckedOptimizationSwapRule>;
};

export type CheckedOptimizationSwapRule = {
  __typename?: 'CheckedOptimizationSwapRule';
  dest?: Maybe<AssignmentSubsetSpec>;
  destMatch: AssignmentSubsetSummary;
  details?: Maybe<CheckedOptimizationSwapRuleDetails>;
  hasValidSwaps: Scalars['Boolean']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  src?: Maybe<AssignmentSubsetSpec>;
  srcMatch: AssignmentSubsetSummary;
  title?: Maybe<Scalars['String']['output']>;
};

export type CheckedOptimizationSwapRuleDetails = {
  __typename?: 'CheckedOptimizationSwapRuleDetails';
  noValidSwapsReasons?: Maybe<
    Array<CheckOptimizationSwapPolicyNoValidSwapsReason>
  >;
  violatedConstrains?: Maybe<Array<Scalars['ID']['output']>>;
};

export type CheckedPickingPolicy = {
  __typename?: 'CheckedPickingPolicy';
  orderLineRules?: Maybe<Array<CheckedPickingPolicyOrderLineRule>>;
  unmatchedOrderLines: SimpleFilterMatch;
};

export type CheckedPickingPolicyOrderLineRule = {
  __typename?: 'CheckedPickingPolicyOrderLineRule';
  id: Scalars['ID']['output'];
  orderLineFilterMatch: SimpleFilterMatch;
  orderLinesMatch?: Maybe<OrderLineFilterUnion>;
  pickingRequirementsSettings?: Maybe<PickingPolicyPickingRequirementsSettings>;
  pickingRules?: Maybe<Array<CheckedPickingPolicyPickingRule>>;
  picklistSettings?: Maybe<PickingPolicyPicklistSettings>;
  title?: Maybe<Scalars['String']['output']>;
  /** if true, use picking container property from order line to separate picking requirements and calculate reorder separately */
  usePickingContainer?: Maybe<Scalars['Boolean']['output']>;
};

export type CheckedPickingPolicyPickingRule = {
  __typename?: 'CheckedPickingPolicyPickingRule';
  agentId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  locationFilterMatch: SimpleFilterMatch;
  locationSelectionMode?: Maybe<PickingPolicyLocationSelectionMode>;
  locationsMatch?: Maybe<LocationFilterUnion>;
  picklistGroupKey?: Maybe<Scalars['ID']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  uomTypes?: Maybe<Array<Scalars['String']['output']>>;
  waypointSettings?: Maybe<PickingPolicyWaypointSettings>;
};

export type CheckedRoutingPolicyRule = {
  __typename?: 'CheckedRoutingPolicyRule';
  agentIds: Array<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  planes: Array<CheckedRoutingPolicyRulePlane>;
  totalAccessibleLocations: Scalars['Float']['output'];
  totalInaccessibleFromStartLocations: Scalars['Float']['output'];
  totalInaccessibleToEndLocations: Scalars['Float']['output'];
};

export type CheckedRoutingPolicyRulePlane = {
  __typename?: 'CheckedRoutingPolicyRulePlane';
  planeId: Scalars['ID']['output'];
  totalAccessibleLocations: Scalars['Float']['output'];
  totalInaccessibleFromStartLocations: Scalars['Float']['output'];
  totalInaccessibleToEndLocations: Scalars['Float']['output'];
};

export type CompletedFileMultipartUploadInput = {
  etags: Array<Scalars['ID']['input']>;
  id: StoredFileIdInput;
  uploadId: Scalars['ID']['input'];
};

export type CompletedImportJobMultipartUploadInput = {
  etags: Array<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
};

export type CompletedImportPipelineMultipartUploadInput = {
  etags: Array<Scalars['ID']['input']>;
  importPipelineId: Scalars['ID']['input'];
  uploadId: Scalars['ID']['input'];
};

export type ConstraintState = {
  __typename?: 'ConstraintState';
  compliantCount: Scalars['Float']['output'];
  nonCompliantCount: Scalars['Float']['output'];
  unknownCount: Scalars['Float']['output'];
};

export type ConstraintSummary = {
  __typename?: 'ConstraintSummary';
  after: ConstraintState;
  before: ConstraintState;
  enforced?: Maybe<Scalars['Boolean']['output']>;
  makeCompliant?: Maybe<Scalars['Boolean']['output']>;
  type: Scalars['ID']['output'];
};

export type ConvertLayoutImportInput = {
  extraSettings?: InputMaybe<Scalars['JSON']['input']>;
  layoutImportId: Scalars['ID']['input'];
  layoutMap: SrcLayoutMapInput;
};

export type CountAssignmentItemsByMatchingFilterResult = {
  __typename?: 'CountAssignmentItemsByMatchingFilterResult';
  matchesCount: Array<Scalars['Int']['output']>;
  unmatchedCount: Scalars['Int']['output'];
};

export type CreateActualityInput = {
  activityFeedId?: InputMaybe<Scalars['ID']['input']>;
  assignmentCapacityPolicy?: InputMaybe<AssignmentCapacityPolicyInput>;
  assignmentId?: InputMaybe<Scalars['ID']['input']>;
  assignmentPolicy?: InputMaybe<AssignmentPolicyInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  extraSettings?: InputMaybe<Scalars['JSON']['input']>;
  itemSetId?: InputMaybe<Scalars['ID']['input']>;
  layoutId?: InputMaybe<Scalars['ID']['input']>;
  locationSharingPolicy?: InputMaybe<LocationSharingPolicyInput>;
  orderSetId?: InputMaybe<Scalars['ID']['input']>;
  pickingPolicy?: InputMaybe<PickingPolicyInput>;
  presentationSettings?: InputMaybe<PresentationSettingsInput>;
  resourcePolicy?: InputMaybe<ResourcePolicyInput>;
  routingPolicy?: InputMaybe<RoutingPolicyInput>;
  stackingPolicy?: InputMaybe<StackingPolicyInput>;
  title: Scalars['String']['input'];
  userTags?: InputMaybe<Array<Scalars['String']['input']>>;
  warehouseId: Scalars['ID']['input'];
  waypointPolicy?: InputMaybe<WaypointPolicyInput>;
};

export type CreateAllocationRunInput = {
  allocationRunType?: InputMaybe<AllocationType>;
  allocationSettings?: InputMaybe<AllocationSettingsInput>;
  engine?: InputMaybe<AllocateJobEngine>;
  seed?: InputMaybe<Scalars['Float']['input']>;
  simulationId: Scalars['ID']['input'];
  startNow?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateAssignmentInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  extraSettings?: InputMaybe<Scalars['JSON']['input']>;
  inputFileName: Scalars['String']['input'];
  presentationSettings?: InputMaybe<PresentationSettingsInput>;
  title: Scalars['String']['input'];
  userTags?: InputMaybe<Array<Scalars['String']['input']>>;
  warehouseId: Scalars['ID']['input'];
};

export type CreateExportJobInput = {
  converterConfig?: InputMaybe<Scalars['JSON']['input']>;
  converterId: Scalars['String']['input'];
  csvOptions?: InputMaybe<CsvExportOptionsInput>;
  filename: Scalars['String']['input'];
  inputSizeHint?: InputMaybe<Scalars['Float']['input']>;
  query: Scalars['String']['input'];
  sourceId: Scalars['ID']['input'];
  sourceType: Scalars['ID']['input'];
  variables?: InputMaybe<Scalars['JSON']['input']>;
};

export type CreateImportJobInput = {
  filename?: InputMaybe<Scalars['String']['input']>;
  format?: InputMaybe<ImportFormat>;
  importType: ImportJobImportType;
  settings?: InputMaybe<ImportJobMappingSettingsInput>;
  sourceSettings?: InputMaybe<ImportJobSourceSettingsInput>;
  targetId?: InputMaybe<Scalars['ID']['input']>;
  targetSettings?: InputMaybe<ImportJobTargetSettingsInput>;
  targetTitle?: InputMaybe<Scalars['String']['input']>;
  warehouseId: Scalars['ID']['input'];
};

export type CreateImportPipelineFromImportJobInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  enabled?: Scalars['Boolean']['input'];
  extraSettings?: InputMaybe<Scalars['JSON']['input']>;
  importJobId: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  warehouseId: Scalars['ID']['input'];
};

export type CreateImportPipelineInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  enabled?: Scalars['Boolean']['input'];
  extraSettings?: InputMaybe<Scalars['JSON']['input']>;
  importType: ImportJobImportType;
  settings: ImportPipelineSettingsInput;
  title?: InputMaybe<Scalars['String']['input']>;
  warehouseId: Scalars['ID']['input'];
};

export type CreateImportPipelineJobInput = {
  filename?: InputMaybe<Scalars['String']['input']>;
  importPipelineId: Scalars['ID']['input'];
  startNowIfPossible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateIntegrationUserInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  extraSettings?: InputMaybe<Scalars['JSON']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  warehouseId: Scalars['ID']['input'];
};

export type CreateItemSetInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  extraSettings?: InputMaybe<Scalars['JSON']['input']>;
  inputFileName: Scalars['String']['input'];
  presentationSettings?: InputMaybe<PresentationSettingsInput>;
  title: Scalars['String']['input'];
  userTags?: InputMaybe<Array<Scalars['String']['input']>>;
  warehouseId: Scalars['ID']['input'];
};

export type CreateLayoutImportInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  extraSettings?: InputMaybe<Scalars['JSON']['input']>;
  inputFileName: Scalars['String']['input'];
  presentationSettings?: InputMaybe<PresentationSettingsInput>;
  title: Scalars['String']['input'];
  userTags?: InputMaybe<Array<Scalars['String']['input']>>;
  warehouseId: Scalars['ID']['input'];
};

export type CreateOptimizationRunInput = {
  engine?: InputMaybe<OptimizeJobEngine>;
  nodes?: InputMaybe<Scalars['Int']['input']>;
  optimizationSettings?: InputMaybe<OptimizationSettingsInput>;
  seed?: InputMaybe<Scalars['Float']['input']>;
  simulationId?: InputMaybe<Scalars['ID']['input']>;
  source?: InputMaybe<OptimizationRunSourceReferenceInput>;
  startNow?: InputMaybe<Scalars['Boolean']['input']>;
  steps?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateOrderSetInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  extraSettings?: InputMaybe<Scalars['JSON']['input']>;
  inputFileName: Scalars['String']['input'];
  presentationSettings?: InputMaybe<PresentationSettingsInput>;
  title: Scalars['String']['input'];
  userTags?: InputMaybe<Array<Scalars['String']['input']>>;
  warehouseId: Scalars['ID']['input'];
};

export type CreateProcessingPipelineInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  enabled?: Scalars['Boolean']['input'];
  extraSettings?: InputMaybe<Scalars['JSON']['input']>;
  jobInput?: InputMaybe<Scalars['JSON']['input']>;
  pipelineType: ProcessingPipelineType;
  settings?: InputMaybe<Scalars['JSON']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  triggerSettings?: InputMaybe<ProcessingPipelineTriggerSettingsInput>;
  warehouseId: Scalars['ID']['input'];
};

export type CreateSimulationInput = {
  allocationSettings?: InputMaybe<AllocationSettingsInput>;
  assignmentCapacityPolicy?: InputMaybe<AssignmentCapacityPolicyInput>;
  assignmentId?: InputMaybe<Scalars['ID']['input']>;
  assignmentPolicy?: InputMaybe<AssignmentPolicyInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  extraSettings?: InputMaybe<Scalars['JSON']['input']>;
  itemSetId?: InputMaybe<Scalars['ID']['input']>;
  layoutId?: InputMaybe<Scalars['ID']['input']>;
  locationSharingPolicy?: InputMaybe<LocationSharingPolicyInput>;
  optimizationSettings?: InputMaybe<OptimizationSettingsInput>;
  orderSetId?: InputMaybe<Scalars['ID']['input']>;
  pickingPolicy?: InputMaybe<PickingPolicyInput>;
  presentationSettings?: InputMaybe<PresentationSettingsInput>;
  resourcePolicy?: InputMaybe<ResourcePolicyInput>;
  routingPolicy?: InputMaybe<RoutingPolicyInput>;
  stackingPolicy?: InputMaybe<StackingPolicyInput>;
  title: Scalars['String']['input'];
  userTags?: InputMaybe<Array<Scalars['String']['input']>>;
  warehouseId: Scalars['ID']['input'];
  waypointPolicy?: InputMaybe<WaypointPolicyInput>;
};

export type CreateWarehouseInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  country?: Scalars['ID']['input'];
  currency?: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  extraSettings?: InputMaybe<Scalars['JSON']['input']>;
  measurementSystem?: MeasurementSystem;
  title: Scalars['String']['input'];
  userTags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CsvExportOptionsInput = {
  decimalPlaces?: InputMaybe<Scalars['Float']['input']>;
  encoding?: InputMaybe<Scalars['ID']['input']>;
  escape?: InputMaybe<Scalars['String']['input']>;
  quote?: InputMaybe<Scalars['String']['input']>;
  separator?: InputMaybe<Scalars['String']['input']>;
};

export type CsvImportOptions = {
  __typename?: 'CsvImportOptions';
  encoding?: Maybe<ImportJobInputEncoding>;
  escape?: Maybe<Scalars['String']['output']>;
  quote?: Maybe<Scalars['String']['output']>;
  separator?: Maybe<Scalars['String']['output']>;
  skipFooterLines?: Maybe<Scalars['Int']['output']>;
  skipHeaderLines?: Maybe<Scalars['Int']['output']>;
};

export type CsvImportOptionsInput = {
  encoding?: InputMaybe<ImportJobInputEncoding>;
  escape?: InputMaybe<Scalars['String']['input']>;
  quote?: InputMaybe<Scalars['String']['input']>;
  separator?: InputMaybe<Scalars['String']['input']>;
  skipFooterLines?: InputMaybe<Scalars['Int']['input']>;
  skipHeaderLines?: InputMaybe<Scalars['Int']['input']>;
};

export type DatasetExtraFieldSettings = {
  __typename?: 'DatasetExtraFieldSettings';
  alias: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type DatasetExtraFieldSettingsInput = {
  alias: Scalars['String']['input'];
  enabled: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type DatasetExtraFieldsSettings = {
  __typename?: 'DatasetExtraFieldsSettings';
  datasetType: Scalars['ID']['output'];
  fieldsSettings: Array<DatasetExtraFieldSettings>;
};

export type DatasetExtraFieldsSettingsInput = {
  datasetType: Scalars['ID']['input'];
  fieldsSettings: Array<DatasetExtraFieldSettingsInput>;
};

export type DatasetObject = {
  __typename?: 'DatasetObject';
  actualitiesWith: ActualitiesConnection;
  content: Array<Scalars['JSON']['output']>;
  count: Scalars['Int']['output'];
  createdAt: Scalars['Timestamp']['output'];
  description?: Maybe<Scalars['String']['output']>;
  extraSettings?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['ID']['output'];
  importedAt?: Maybe<Scalars['Timestamp']['output']>;
  objectType: ImportJobImportType;
  presentationSettings?: Maybe<PresentationSettings>;
  /** @deprecated in favour of warehouse.datasetObjectQuery */
  query: DatasetObjectQueryResponse;
  settings?: Maybe<Scalars['JSON']['output']>;
  simulationsWith: SimulationsConnection;
  status: DatasetObjectStatus;
  summary?: Maybe<DatasetObjectSummary>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['Timestamp']['output'];
  userTags?: Maybe<Array<Scalars['String']['output']>>;
  warehouse: Warehouse;
};

export type DatasetObjectActualitiesWithArgs = {
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<ActualitiesSortBy>>;
};

export type DatasetObjectQueryArgs = {
  parameters?: InputMaybe<Array<Scalars['JSON']['input']>>;
  query?: InputMaybe<Scalars['String']['input']>;
};

export type DatasetObjectSimulationsWithArgs = {
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<SimulationsSortBy>>;
};

export type DatasetObjectDateRange = {
  __typename?: 'DatasetObjectDateRange';
  from?: Maybe<Scalars['LocalDateTime']['output']>;
  to?: Maybe<Scalars['LocalDateTime']['output']>;
};

export type DatasetObjectFieldSummary = {
  __typename?: 'DatasetObjectFieldSummary';
  distinctValueCount?: Maybe<Scalars['Float']['output']>;
  hasData?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['ID']['output'];
};

export type DatasetObjectFilter = {
  objectType?: InputMaybe<Array<ImportJobImportType>>;
  status?: InputMaybe<Array<DatasetObjectStatus>>;
  title?: InputMaybe<StringSearchFilter>;
  userTags?: InputMaybe<Array<Scalars['String']['input']>>;
  userTagsHasAll?: InputMaybe<Array<Scalars['String']['input']>>;
  userTagsHasAny?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DatasetObjectQueryColumn = {
  __typename?: 'DatasetObjectQueryColumn';
  name: Scalars['ID']['output'];
  type: Scalars['ID']['output'];
};

export type DatasetObjectQueryResponse = {
  __typename?: 'DatasetObjectQueryResponse';
  columns: Array<DatasetObjectQueryColumn>;
  result: Scalars['JSON']['output'];
};

export type DatasetObjectRawDataFieldSummary = {
  __typename?: 'DatasetObjectRawDataFieldSummary';
  distinctValueCount?: Maybe<Scalars['Float']['output']>;
  hasData?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['ID']['output'];
  type?: Maybe<Array<Scalars['ID']['output']>>;
};

export enum DatasetObjectStatus {
  IMPORTED = 'IMPORTED',
  IMPORTING = 'IMPORTING',
  POSTPROCESSING = 'POSTPROCESSING',
}

export type DatasetObjectSummary = {
  __typename?: 'DatasetObjectSummary';
  dateRange?: Maybe<DatasetObjectDateRange>;
  fields?: Maybe<Array<DatasetObjectFieldSummary>>;
  generatedAt?: Maybe<Scalars['Timestamp']['output']>;
  rawDataFields?: Maybe<Array<DatasetObjectRawDataFieldSummary>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type DatasetObjectsConnection = {
  __typename?: 'DatasetObjectsConnection';
  allUserTags: Array<Scalars['String']['output']>;
  /** Content of collection or the current page of collection if pagination is used */
  content: Array<DatasetObject>;
  /** Total number of elements in this collection (ignoring pagination constraints, if any) */
  totalCount: Scalars['Float']['output'];
};

export type DatasetObjectsSortBy = {
  /** Sorting direction */
  direction?: SortDirection;
  /** Field (sort option) to sort by */
  field: BaseEntitySortOption;
};

export enum DateTimeTruncateTo {
  DAY = 'DAY',
  HOUR = 'HOUR',
  MINUTE = 'MINUTE',
  MONTH = 'MONTH',
  SECOND = 'SECOND',
  WEEK = 'WEEK',
  YEAR = 'YEAR',
}

export enum DeadStockSwapMode {
  EXCLUDE_DEAD_STOCK = 'EXCLUDE_DEAD_STOCK',
  INCLUDE_DEAD_STOCK = 'INCLUDE_DEAD_STOCK',
  SWAP_DEAD_STOCK_WITH_OTHERS = 'SWAP_DEAD_STOCK_WITH_OTHERS',
}

export enum DeallocateType {
  ALL = 'ALL',
  NONE = 'NONE',
  NON_COMPLIANT = 'NON_COMPLIANT',
}

export type DeallocationPolicy = {
  __typename?: 'DeallocationPolicy';
  defaultRule?: Maybe<DeallocationRule>;
  rules?: Maybe<Array<DeallocationRule>>;
};

export type DeallocationPolicyInput = {
  defaultRule?: InputMaybe<DeallocationRuleInput>;
  rules?: InputMaybe<Array<DeallocationRuleInput>>;
};

export type DeallocationRule = {
  __typename?: 'DeallocationRule';
  constraintSettings?: Maybe<AllocationConstraintSettings>;
  deallocateType?: Maybe<DeallocateType>;
  id?: Maybe<Scalars['ID']['output']>;
  itemsMatch?: Maybe<SimulationItemFilterUnion>;
  locationsMatch?: Maybe<LocationFilterUnion>;
  reallocateQuantitySettings?: Maybe<ReallocateQuantitySettings>;
  reallocateType?: Maybe<ReallocateType>;
  title?: Maybe<Scalars['String']['output']>;
};

export type DeallocationRuleInput = {
  constraintSettings?: InputMaybe<AllocationConstraintSettingsInput>;
  deallocateType?: InputMaybe<DeallocateType>;
  id?: InputMaybe<Scalars['ID']['input']>;
  itemsMatch?: InputMaybe<SimulationItemFilterUnionInput>;
  locationsMatch?: InputMaybe<LocationFilterUnionInput>;
  reallocateQuantitySettings?: InputMaybe<ReallocateQuantitySettingsInput>;
  reallocateType?: InputMaybe<ReallocateType>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type DeleteImportPipelineConnectorInput = {
  connectorId: Scalars['ID']['input'];
  importPipelineId: Scalars['ID']['input'];
};

export type DemoWarehouse = {
  __typename?: 'DemoWarehouse';
  subscribed: Scalars['Boolean']['output'];
  warehouse: Warehouse;
};

export type DuplicateActualityInput = {
  actualityId: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type DuplicationSimulationInput = {
  simulationId: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ExportJob = {
  __typename?: 'ExportJob';
  completedAt?: Maybe<Scalars['Timestamp']['output']>;
  createdAt: Scalars['Timestamp']['output'];
  downloadUrl?: Maybe<Scalars['String']['output']>;
  expiresAt: Scalars['Timestamp']['output'];
  fileSize?: Maybe<Scalars['Float']['output']>;
  filename: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  progress: Scalars['Float']['output'];
  sourceId: Scalars['ID']['output'];
  sourceType: Scalars['ID']['output'];
  startedAt?: Maybe<Scalars['Timestamp']['output']>;
  status: BatchJobStatus;
  updatedAt: Scalars['Timestamp']['output'];
};

export type FeatureRequests = {
  __typename?: 'FeatureRequests';
  billing?: Maybe<Scalars['Boolean']['output']>;
  contracts?: Maybe<Scalars['Boolean']['output']>;
  marketplace?: Maybe<Scalars['Boolean']['output']>;
  optimise?: Maybe<Scalars['Boolean']['output']>;
  sales?: Maybe<Scalars['Boolean']['output']>;
};

export type FeatureRequestsInput = {
  billing?: InputMaybe<Scalars['Boolean']['input']>;
  contracts?: InputMaybe<Scalars['Boolean']['input']>;
  marketplace?: InputMaybe<Scalars['Boolean']['input']>;
  optimise?: InputMaybe<Scalars['Boolean']['input']>;
  sales?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum FilterEngineType {
  NEW_ATHENA = 'NEW_ATHENA',
  OLD_POSTGRES = 'OLD_POSTGRES',
}

export type FilterFieldConfigCustomizable = {
  __typename?: 'FilterFieldConfigCustomizable';
  disabled?: Maybe<Scalars['Boolean']['output']>;
  group?: Maybe<Scalars['String']['output']>;
  nullOptionTitle?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  type: Scalars['ID']['output'];
};

export type FilterFieldConfigCustomizableInput = {
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  group?: InputMaybe<Scalars['String']['input']>;
  nullOptionTitle?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  type: Scalars['ID']['input'];
};

export type FilterMatch = {
  __typename?: 'FilterMatch';
  exclusive: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
};

export type FilterPreset = {
  __typename?: 'FilterPreset';
  filterUnion: PolicyFilterUnion;
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
};

export type FilterPresetInput = {
  filterUnion: PolicyFilterUnionInput;
  id: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

export type FilterSettings = {
  __typename?: 'FilterSettings';
  filterConfigOverrides: Array<FilterFieldConfigCustomizable>;
  presetType: Scalars['ID']['output'];
};

export type FilterSettingsInput = {
  filterConfigOverrides: Array<FilterFieldConfigCustomizableInput>;
  presetType: Scalars['ID']['input'];
};

export type FilterValue = {
  __typename?: 'FilterValue';
  title: Scalars['String']['output'];
};

export type FilterValueInput = {
  title: Scalars['String']['input'];
};

export type FilterValuesConnection = {
  __typename?: 'FilterValuesConnection';
  /** Content of collection or the current page of collection if pagination is used */
  content?: Maybe<Array<FilterValue>>;
  range?: Maybe<NumberFilterRange>;
  /** Total number of elements in this collection (ignoring pagination constraints, if any) */
  totalCount?: Maybe<Scalars['Float']['output']>;
};

export type FindEffectiveItemFilterValuesInput = {
  /** Filter type to find possible values for */
  filterType: SimulationItemFilterType;
  /** Only return values that match this filters */
  includeMatching?: InputMaybe<SimulationItemFilterIntersectionInput>;
  includeUnassigned?: InputMaybe<Scalars['Boolean']['input']>;
  /** Only return values with titles starting with this string */
  query?: InputMaybe<Scalars['String']['input']>;
};

export type FindEffectiveItemsByFilter = {
  excludeMatching?: InputMaybe<Array<SimulationItemFilterUnionInput>>;
  includeMatching?: InputMaybe<SimulationItemFilterUnionInput>;
  includeUnassigned?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FindItemFilterValuesInput = {
  /** Filter type to find possible values for */
  filterType: ItemFilterType;
  /** Only return values that match this filters */
  includeMatching?: InputMaybe<ItemFilterIntersectionInput>;
  itemSetId: Scalars['ID']['input'];
  /** Only return values with titles starting with this string */
  query?: InputMaybe<Scalars['String']['input']>;
};

export type FindItemsByFilterInput = {
  excludeMatching?: InputMaybe<Array<ItemFilterUnionInput>>;
  includeMatching?: InputMaybe<ItemFilterUnionInput>;
  itemSetId: Scalars['ID']['input'];
};

export type FindLayoutInaccessibleLocationsByRuleInput = {
  featureRules?: InputMaybe<Array<RoutingPolicyFeatureRuleInput>>;
  layoutId: Scalars['ID']['input'];
  threshold?: InputMaybe<RoutingPolicyDirectionThresholdInput>;
};

export type FindLocationFilterValuesFilterInput = {
  /** Filter type to find possible values for */
  filterType: LocationFilterType;
  page?: InputMaybe<SkipLimitPageSpec>;
  /** Only return values with titles starting with this string */
  query?: InputMaybe<Scalars['String']['input']>;
};

export type FindLocationFilterValuesFilterResult = {
  __typename?: 'FindLocationFilterValuesFilterResult';
  /** Content of collection or the current page of collection if pagination is used */
  content?: Maybe<Array<FilterValue>>;
  /** Filter type to find possible values for */
  filterType: LocationFilterType;
  range?: Maybe<NumberFilterRange>;
  /** Total number of elements in this collection (ignoring pagination constraints, if any) */
  totalCount?: Maybe<Scalars['Float']['output']>;
};

export type FindLocationFilterValuesInput = {
  filters: Array<FindLocationFilterValuesFilterInput>;
  /** Only return values that match these filters */
  includeMatching?: InputMaybe<LocationFilterIntersectionInput>;
};

export type FindLocationsByFilterInput = {
  excludeMatching?: InputMaybe<Array<LocationFilterUnionInput>>;
  includeMatching?: InputMaybe<LocationFilterUnionInput>;
};

export type FindSimulationInaccessibleLocationsByRuleInput = {
  simulationId: Scalars['ID']['input'];
};

export type FindSimulationItemFilterValuesInput = {
  /** Filter type to find possible values for */
  filterType: SimulationItemFilterType;
  /** Only return values that match this filters */
  includeMatching?: InputMaybe<SimulationItemFilterIntersectionInput>;
  includeUnassigned?: InputMaybe<Scalars['Boolean']['input']>;
  /** Only return values with titles starting with this string */
  query?: InputMaybe<Scalars['String']['input']>;
  simulationId: Scalars['ID']['input'];
};

export type FindSimulationItemsByFilter = {
  excludeMatching?: InputMaybe<Array<SimulationItemFilterUnionInput>>;
  includeMatching?: InputMaybe<SimulationItemFilterUnionInput>;
  includeUnassigned?: InputMaybe<Scalars['Boolean']['input']>;
  simulationId: Scalars['ID']['input'];
};

export type FindSimulationOrderLineFilterValuesInput = {
  /** Filter type to find possible values for */
  filterType: OrderLineFilterType;
  /** Only return values that match this filters */
  includeMatching?: InputMaybe<OrderLineFilterIntersectionInput>;
  /** Only return values with titles starting with this string */
  query?: InputMaybe<Scalars['String']['input']>;
  simulationId: Scalars['ID']['input'];
};

export type FindSimulationOrderLinesByFilter = {
  excludeMatching?: InputMaybe<Array<OrderLineFilterUnionInput>>;
  includeMatching?: InputMaybe<OrderLineFilterUnionInput>;
  simulationId: Scalars['ID']['input'];
};

export type GateUsageSchedule = {
  __typename?: 'GateUsageSchedule';
  completionTime: Scalars['LocalDateTime']['output'];
  deadlines: Array<Scalars['LocalDateTime']['output']>;
  resources: Array<GateUsageScheduleResource>;
  startTime: Scalars['LocalDateTime']['output'];
  totalCost: GateUsageScheduleCost;
  totalCostWithoutWaiting: GateUsageScheduleCost;
};

export type GateUsageScheduleCost = {
  __typename?: 'GateUsageScheduleCost';
  cost: Scalars['Float']['output'];
  tardiness: Scalars['Float']['output'];
};

export type GateUsageScheduleJob = {
  __typename?: 'GateUsageScheduleJob';
  deadline?: Maybe<Scalars['LocalDateTime']['output']>;
  duration: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
};

export type GateUsageScheduleJobAssignment = {
  __typename?: 'GateUsageScheduleJobAssignment';
  completionTime: Scalars['LocalDateTime']['output'];
  dockingDuration?: Maybe<Scalars['Float']['output']>;
  job: GateUsageScheduleJob;
  jobDetails?: Maybe<AnalyzeResultJob>;
  startTime: Scalars['LocalDateTime']['output'];
  totalDuration?: Maybe<Scalars['Float']['output']>;
  undockingDuration?: Maybe<Scalars['Float']['output']>;
};

export type GateUsageScheduleResource = {
  __typename?: 'GateUsageScheduleResource';
  assignments: Array<GateUsageScheduleJobAssignment>;
  id: Scalars['ID']['output'];
};

export type GeneratePicklistsJob = {
  __typename?: 'GeneratePicklistsJob';
  assignment: Assignment;
  assignmentCapacityPolicy?: Maybe<AssignmentCapacityPolicy>;
  createdAt: Scalars['Timestamp']['output'];
  id: Scalars['ID']['output'];
  itemSet: ItemSet;
  layout: Layout;
  orderSet: OrderSet;
  pickingPolicy?: Maybe<PickingPolicy>;
  picklists?: Maybe<PicklistsConnection>;
  resourcePolicy?: Maybe<ResourcePolicy>;
  routingPolicy?: Maybe<RoutingPolicy>;
  simulation: Simulation;
  status: GeneratePicklistsJobStatus;
  updatedAt: Scalars['Timestamp']['output'];
  warehouse: Warehouse;
};

export type GeneratePicklistsJobPicklistsArgs = {
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<PicklistsSortBy>>;
};

export enum GeneratePicklistsJobStatus {
  CALCULATING = 'CALCULATING',
  CREATED = 'CREATED',
  READY = 'READY',
}

export type GenerateSimulationOrderedQuantityReportOptionsInput = {
  assignmentId?: InputMaybe<Scalars['ID']['input']>;
};

export type GenerateSimulationPicklistsInput = {
  simulationId: Scalars['ID']['input'];
  startNow?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GroupLocationsByFilterInput = {
  filters: Array<InputMaybe<LocationFilterUnionInput>>;
  matchingOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GroupLocationsByFilterResult = {
  __typename?: 'GroupLocationsByFilterResult';
  filterMatches: Array<LayoutLocationIdsConnection>;
  unmatched?: Maybe<LayoutLocationIdsConnection>;
};

export type HandlingEventSettings = {
  __typename?: 'HandlingEventSettings';
  heightPenalty?: Maybe<Array<HandlingHeightPenaltySettings>>;
  timeFixed?: Maybe<Scalars['Float']['output']>;
  timePerUom?: Maybe<Array<UomHandlingSettings>>;
  timePerVolume?: Maybe<Scalars['Float']['output']>;
  timePerWeight?: Maybe<Scalars['Float']['output']>;
};

export type HandlingEventSettingsInput = {
  heightPenalty?: InputMaybe<Array<HandlingHeightPenaltySettingsInput>>;
  timeFixed?: InputMaybe<Scalars['Float']['input']>;
  timePerUom?: InputMaybe<Array<UomHandlingSettingsInput>>;
  timePerVolume?: InputMaybe<Scalars['Float']['input']>;
  timePerWeight?: InputMaybe<Scalars['Float']['input']>;
};

export type HandlingExecutionDetails = {
  __typename?: 'HandlingExecutionDetails';
  uom?: Maybe<Scalars['String']['output']>;
  uomQuantity: Scalars['Float']['output'];
  volume?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
};

export type HandlingHeightPenaltySettings = {
  __typename?: 'HandlingHeightPenaltySettings';
  locationHeightFromFloor: NumberFilterRange;
  timeFixed?: Maybe<Scalars['Float']['output']>;
  timePerUom?: Maybe<Array<UomHandlingSettings>>;
  timePerVolume?: Maybe<Scalars['Float']['output']>;
  timePerWeight?: Maybe<Scalars['Float']['output']>;
};

export type HandlingHeightPenaltySettingsInput = {
  locationHeightFromFloor: NumberFilterRangeInput;
  timeFixed?: InputMaybe<Scalars['Float']['input']>;
  timePerUom?: InputMaybe<Array<UomHandlingSettingsInput>>;
  timePerVolume?: InputMaybe<Scalars['Float']['input']>;
  timePerWeight?: InputMaybe<Scalars['Float']['input']>;
};

export type HandlingPalletReorderCategory = {
  __typename?: 'HandlingPalletReorderCategory';
  stackingPriority: Array<Scalars['Int']['output']>;
  uoms: Array<UomHandlingDetails>;
  volume?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
};

export type HandlingPalletReorderingDetails = {
  __typename?: 'HandlingPalletReorderingDetails';
  reordered: Array<HandlingPalletReorderCategory>;
  stackingPriority: Array<Scalars['Int']['output']>;
};

export type HorizontalTravellingEventSettings = {
  __typename?: 'HorizontalTravellingEventSettings';
  speed?: Maybe<SpeedSettings>;
};

export type HorizontalTravellingEventSettingsInput = {
  speed?: InputMaybe<SpeedSettingsInput>;
};

export enum ImportFormat {
  CSV = 'CSV',
  DATASET = 'DATASET',
  JSON = 'JSON',
}

export type ImportJob = {
  __typename?: 'ImportJob';
  completedAt?: Maybe<Scalars['Timestamp']['output']>;
  createdAt: Scalars['Timestamp']['output'];
  downloadUrl?: Maybe<Scalars['String']['output']>;
  errors: ImportJobErrorsConnection;
  extraSettings?: Maybe<Scalars['JSON']['output']>;
  fileSize?: Maybe<Scalars['Float']['output']>;
  /** @deprecated in favour of sourceSettings.filename */
  filename?: Maybe<Scalars['String']['output']>;
  /** @deprecated in favour of sourceSettings.format */
  format: ImportFormat;
  id: Scalars['ID']['output'];
  importJobKey?: Maybe<Scalars['ID']['output']>;
  importPipeline?: Maybe<ImportPipeline>;
  importPipelineId?: Maybe<Scalars['ID']['output']>;
  importType: ImportJobImportType;
  lines: Array<Array<Scalars['String']['output']>>;
  locale?: Maybe<Scalars['ID']['output']>;
  previewLines: Array<Array<Scalars['String']['output']>>;
  previewQuery: PreviewQueryResponse;
  previewUploadUrl: Scalars['String']['output'];
  progress: Scalars['Float']['output'];
  sanitizedFailureReason?: Maybe<Scalars['String']['output']>;
  settings?: Maybe<ImportJobMappingSettings>;
  /** @deprecated in favour of sourceSettings.source */
  source: ImportSource;
  sourceSettings: ImportJobSourceSettings;
  stage?: Maybe<ImportJobProcessingStage>;
  startedAt?: Maybe<Scalars['Timestamp']['output']>;
  state?: Maybe<ImportJobState>;
  status: BatchJobStatus;
  summary?: Maybe<ImportJobSummary>;
  targetId?: Maybe<Scalars['ID']['output']>;
  targetSettings: ImportJobTargetSettings;
  targetTitle?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Timestamp']['output'];
  uploadUrl: Scalars['String']['output'];
  uploaded?: Maybe<Scalars['Boolean']['output']>;
  userId: Scalars['ID']['output'];
  warehouse: Warehouse;
};

export type ImportJobErrorsArgs = {
  page?: InputMaybe<SkipLimitPageSpec>;
};

export type ImportJobLinesArgs = {
  count?: Scalars['Float']['input'];
};

export type ImportJobPreviewLinesArgs = {
  count?: Scalars['Float']['input'];
};

export type ImportJobPreviewQueryArgs = {
  options?: InputMaybe<ImportJobPreviewQueryOptions>;
  query?: InputMaybe<Scalars['String']['input']>;
};

export type ImportJobPreviewUploadUrlArgs = {
  contentLength: Scalars['Float']['input'];
};

export type ImportJobAppendTargetSettings = {
  __typename?: 'ImportJobAppendTargetSettings';
  overwrite?: Maybe<Scalars['Boolean']['output']>;
  targetId: Scalars['ID']['output'];
};

export type ImportJobAppendTargetSettingsInput = {
  overwrite?: InputMaybe<Scalars['Boolean']['input']>;
  targetId: Scalars['ID']['input'];
};

export type ImportJobDatasetSourceSettings = {
  __typename?: 'ImportJobDatasetSourceSettings';
  sourceId: Scalars['ID']['output'];
  sourceType: ImportJobImportType;
};

export type ImportJobDatasetSourceSettingsInput = {
  sourceId: Scalars['ID']['input'];
  sourceType: ImportJobImportType;
};

export type ImportJobErrorsConnection = {
  __typename?: 'ImportJobErrorsConnection';
  /** Content of collection or the current page of collection if pagination is used */
  content: Array<MappingError>;
  /** Total number of elements in this collection (ignoring pagination constraints, if any) */
  totalCount: Scalars['Float']['output'];
};

export type ImportJobExternalSourceSettings = {
  __typename?: 'ImportJobExternalSourceSettings';
  s3FolderUrl: Scalars['String']['output'];
};

export type ImportJobExternalSourceSettingsInput = {
  s3FolderUrl: Scalars['String']['input'];
};

export enum ImportJobImportType {
  ACTIVITY_FEED = 'ACTIVITY_FEED',
  ASSIGNMENT = 'ASSIGNMENT',
  ASSIGNMENT_POLICY = 'ASSIGNMENT_POLICY',
  CONVERTED_LAYOUT = 'CONVERTED_LAYOUT',
  GENERIC = 'GENERIC',
  ITEM_SET = 'ITEM_SET',
  LAYOUT = 'LAYOUT',
  ORDER_SET = 'ORDER_SET',
}

export enum ImportJobInputEncoding {
  UTF_8 = 'UTF_8',
  UTF_16 = 'UTF_16',
  UTF_16BE = 'UTF_16BE',
  UTF_16LE = 'UTF_16LE',
  WINDOWS_1251 = 'WINDOWS_1251',
  WINDOWS_1252 = 'WINDOWS_1252',
}

export type ImportJobMappingSettings = {
  __typename?: 'ImportJobMappingSettings';
  columnNames?: Maybe<Array<Scalars['String']['output']>>;
  csvOptions?: Maybe<CsvImportOptions>;
  hasHeaders?: Maybe<Scalars['Boolean']['output']>;
  mapping?: Maybe<MappingSpec>;
  transformation?: Maybe<ImportJobTransformationSettings>;
};

export type ImportJobMappingSettingsInput = {
  columnNames?: InputMaybe<Array<Scalars['String']['input']>>;
  csvOptions?: InputMaybe<CsvImportOptionsInput>;
  hasHeaders?: InputMaybe<Scalars['Boolean']['input']>;
  mapping?: InputMaybe<MappingSpecInput>;
  transformation?: InputMaybe<ImportJobTransformationSettingsInput>;
};

export type ImportJobNewTargetSettings = {
  __typename?: 'ImportJobNewTargetSettings';
  targetTitle: Scalars['String']['output'];
};

export type ImportJobNewTargetSettingsInput = {
  targetTitle: Scalars['String']['input'];
};

export type ImportJobPipelineSourceSettings = {
  __typename?: 'ImportJobPipelineSourceSettings';
  compression?: Maybe<ImportJobSourceCompression>;
  format: ImportFormat;
};

export type ImportJobPipelineSourceSettingsInput = {
  compression?: InputMaybe<ImportJobSourceCompression>;
  format: ImportFormat;
};

export type ImportJobPipelineTargetSettings = {
  __typename?: 'ImportJobPipelineTargetSettings';
  appendSettings?: Maybe<ImportJobAppendTargetSettings>;
  newSettings?: Maybe<ImportJobNewTargetSettings>;
  target: ImportJobTargetType;
};

export type ImportJobPipelineTargetSettingsInput = {
  appendSettings?: InputMaybe<ImportJobAppendTargetSettingsInput>;
  newSettings?: InputMaybe<ImportJobNewTargetSettingsInput>;
  target: ImportJobTargetType;
};

export type ImportJobPreviewQueryOptions = {
  inputLimit?: InputMaybe<Scalars['Float']['input']>;
  outputLimit?: InputMaybe<Scalars['Float']['input']>;
};

export enum ImportJobProcessingStage {
  IMPORTING = 'IMPORTING',
  MAPPING = 'MAPPING',
  PARSING = 'PARSING',
  POSTPROCESSING = 'POSTPROCESSING',
  POST_MAPPING_VALIDATION = 'POST_MAPPING_VALIDATION',
  PRE_MAPPING_VALIDATION = 'PRE_MAPPING_VALIDATION',
  TRANSFORMING = 'TRANSFORMING',
  VALIDATING = 'VALIDATING',
}

export enum ImportJobSortOption {
  CREATED_AT = 'CREATED_AT',
  UPDATED_AT = 'UPDATED_AT',
}

export enum ImportJobSourceCompression {
  GZIP = 'GZIP',
  NO = 'NO',
}

export type ImportJobSourceSettings = {
  __typename?: 'ImportJobSourceSettings';
  compression?: Maybe<ImportJobSourceCompression>;
  datasetSourceSettings?: Maybe<ImportJobDatasetSourceSettings>;
  externalSourceSettings?: Maybe<ImportJobExternalSourceSettings>;
  filename?: Maybe<Scalars['String']['output']>;
  format: ImportFormat;
  source: ImportSource;
};

export type ImportJobSourceSettingsInput = {
  compression?: InputMaybe<ImportJobSourceCompression>;
  datasetSourceSettings?: InputMaybe<ImportJobDatasetSourceSettingsInput>;
  externalSourceSettings?: InputMaybe<ImportJobExternalSourceSettingsInput>;
  filename?: InputMaybe<Scalars['String']['input']>;
  format: ImportFormat;
  source: ImportSource;
};

export type ImportJobState = {
  __typename?: 'ImportJobState';
  connectorType?: Maybe<ImportPipelineConnectorType>;
  restarted?: Maybe<Scalars['Boolean']['output']>;
};

export type ImportJobSummary = {
  __typename?: 'ImportJobSummary';
  errorCount?: Maybe<Scalars['Int']['output']>;
  importedLineCount?: Maybe<Scalars['Int']['output']>;
  inputLineCount?: Maybe<Scalars['Int']['output']>;
};

export type ImportJobTargetSettings = {
  __typename?: 'ImportJobTargetSettings';
  appendSettings?: Maybe<ImportJobAppendTargetSettings>;
  newSettings?: Maybe<ImportJobNewTargetSettings>;
  target: ImportJobTargetType;
};

export type ImportJobTargetSettingsInput = {
  appendSettings?: InputMaybe<ImportJobAppendTargetSettingsInput>;
  newSettings?: InputMaybe<ImportJobNewTargetSettingsInput>;
  target: ImportJobTargetType;
};

export enum ImportJobTargetType {
  APPEND = 'APPEND',
  NEW = 'NEW',
}

export type ImportJobTransformationSettings = {
  __typename?: 'ImportJobTransformationSettings';
  enabled: Scalars['Boolean']['output'];
  query?: Maybe<Scalars['String']['output']>;
};

export type ImportJobTransformationSettingsInput = {
  enabled: Scalars['Boolean']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
};

export type ImportJobsConnection = {
  __typename?: 'ImportJobsConnection';
  /** Content of collection or the current page of collection if pagination is used */
  content: Array<ImportJob>;
  /** Total number of elements in this collection (ignoring pagination constraints, if any) */
  totalCount: Scalars['Float']['output'];
};

export type ImportJobsFilter = {
  createdAt?: InputMaybe<TimestampSearchFilter>;
  importPipelineId?: InputMaybe<Array<Scalars['ID']['input']>>;
  importType?: InputMaybe<Array<ImportJobImportType>>;
  isUploadOrRestarted?: InputMaybe<Scalars['Boolean']['input']>;
  source?: InputMaybe<Array<ImportSource>>;
  status?: InputMaybe<Array<BatchJobStatus>>;
  targetId?: InputMaybe<Array<Scalars['ID']['input']>>;
  warehouseId?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type ImportJobsSortBy = {
  /** Sorting direction */
  direction?: SortDirection;
  /** Field (sort option) to sort by */
  field: ImportJobSortOption;
};

export type ImportPipeline = {
  __typename?: 'ImportPipeline';
  connectorsState: Array<ImportPipelineConnectorDetails>;
  createdAt: Scalars['Timestamp']['output'];
  description?: Maybe<Scalars['String']['output']>;
  enabled: Scalars['Boolean']['output'];
  extraSettings?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['ID']['output'];
  importType: ImportJobImportType;
  jobs: ImportJobsConnection;
  settings?: Maybe<ImportPipelineSettings>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Timestamp']['output'];
  userId: Scalars['ID']['output'];
  warehouse: Warehouse;
};

export type ImportPipelineJobsArgs = {
  filter?: InputMaybe<ImportJobsFilter>;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<ImportJobsSortBy>>;
};

export enum ImportPipelineAfterPullAction {
  DELETE = 'DELETE',
}

export type ImportPipelineAfterPullActionSettings = {
  __typename?: 'ImportPipelineAfterPullActionSettings';
  action: ImportPipelineAfterPullAction;
};

export type ImportPipelineAfterPullActionSettingsInput = {
  action: ImportPipelineAfterPullAction;
};

export type ImportPipelineApiPushConnectorDetails = {
  __typename?: 'ImportPipelineApiPushConnectorDetails';
  apiAccessKey: Scalars['String']['output'];
  apiUrl?: Maybe<Scalars['String']['output']>;
};

export type ImportPipelineApiPushConnectorState = {
  __typename?: 'ImportPipelineApiPushConnectorState';
  apiAccessKey: Scalars['String']['output'];
};

export type ImportPipelineConnectorDetails = {
  __typename?: 'ImportPipelineConnectorDetails';
  apiPush?: Maybe<ImportPipelineApiPushConnectorDetails>;
  datasetChanged?: Maybe<ImportPipelineDatasetChangedConnectorDetails>;
  emailPush?: Maybe<ImportPipelineEmailPushConnectorDetails>;
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  s3Push?: Maybe<ImportPipelineS3PushConnectorDetails>;
  type: ImportPipelineConnectorType;
};

export type ImportPipelineConnectorSettings = {
  __typename?: 'ImportPipelineConnectorSettings';
  datasetChanged?: Maybe<ImportPipelineDatasetChangedConnectorSettings>;
  description?: Maybe<Scalars['String']['output']>;
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  s3Push?: Maybe<ImportPipelineS3PushConnectorSettings>;
  sftpPull?: Maybe<ImportPipelineSftpPullConnectorSettings>;
  type: ImportPipelineConnectorType;
};

export type ImportPipelineConnectorSettingsInput = {
  datasetChanged?: InputMaybe<ImportPipelineDatasetChangedConnectorSettingsInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  enabled: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  s3Push?: InputMaybe<ImportPipelineS3PushConnectorSettingsInput>;
  sftpPull?: InputMaybe<ImportPipelineSftpPullConnectorSettingsInput>;
  type: ImportPipelineConnectorType;
};

export enum ImportPipelineConnectorType {
  API_PUSH = 'API_PUSH',
  DATASET_CHANGED = 'DATASET_CHANGED',
  EMAIL_PUSH = 'EMAIL_PUSH',
  S3_PUSH = 'S3_PUSH',
  SFTP_PULL = 'SFTP_PULL',
}

export type ImportPipelineDatasetChangedConnectorDetails = {
  __typename?: 'ImportPipelineDatasetChangedConnectorDetails';
  sources: Array<DatasetObject>;
};

export type ImportPipelineDatasetChangedConnectorSettings = {
  __typename?: 'ImportPipelineDatasetChangedConnectorSettings';
  sources: Array<ImportPipelineDatasetChangedConnectorSource>;
};

export type ImportPipelineDatasetChangedConnectorSettingsInput = {
  sources: Array<ImportPipelineDatasetChangedConnectorSourceInput>;
};

export type ImportPipelineDatasetChangedConnectorSource = {
  __typename?: 'ImportPipelineDatasetChangedConnectorSource';
  id: Scalars['ID']['output'];
};

export type ImportPipelineDatasetChangedConnectorSourceInput = {
  id: Scalars['ID']['input'];
};

export type ImportPipelineEmailPushConnectorDetails = {
  __typename?: 'ImportPipelineEmailPushConnectorDetails';
  address: Scalars['String']['output'];
};

export type ImportPipelineIntegrationSettings = {
  __typename?: 'ImportPipelineIntegrationSettings';
  connectors?: Maybe<Array<ImportPipelineConnectorSettings>>;
};

export type ImportPipelineIntegrationSettingsInput = {
  connectors?: InputMaybe<Array<ImportPipelineConnectorSettingsInput>>;
};

export type ImportPipelinePullScheduleSettings = {
  __typename?: 'ImportPipelinePullScheduleSettings';
  cronExpression?: Maybe<Scalars['String']['output']>;
};

export type ImportPipelinePullScheduleSettingsInput = {
  cronExpression?: InputMaybe<Scalars['String']['input']>;
};

export type ImportPipelineS3PushConnectorDetails = {
  __typename?: 'ImportPipelineS3PushConnectorDetails';
  awsCredentials?: Maybe<AwsAccessKey>;
  s3Url?: Maybe<Scalars['String']['output']>;
};

export type ImportPipelineS3PushConnectorSettings = {
  __typename?: 'ImportPipelineS3PushConnectorSettings';
  integrationUserId?: Maybe<Scalars['ID']['output']>;
};

export type ImportPipelineS3PushConnectorSettingsInput = {
  integrationUserId?: InputMaybe<Scalars['ID']['input']>;
};

export type ImportPipelineS3PushConnectorState = {
  __typename?: 'ImportPipelineS3PushConnectorState';
  awsCredentials?: Maybe<AwsAccessKey>;
};

export type ImportPipelineSettings = {
  __typename?: 'ImportPipelineSettings';
  integrationSettings?: Maybe<ImportPipelineIntegrationSettings>;
  mappingSettings: ImportJobMappingSettings;
  sourceSettings: ImportJobPipelineSourceSettings;
  targetSettings: ImportJobPipelineTargetSettings;
};

export type ImportPipelineSettingsInput = {
  integrationSettings?: InputMaybe<ImportPipelineIntegrationSettingsInput>;
  mappingSettings: ImportJobMappingSettingsInput;
  sourceSettings: ImportJobPipelineSourceSettingsInput;
  targetSettings: ImportJobPipelineTargetSettingsInput;
};

export type ImportPipelineSftpPullConnectorSettings = {
  __typename?: 'ImportPipelineSftpPullConnectorSettings';
  afterPull?: Maybe<ImportPipelineAfterPullActionSettings>;
  host: Scalars['String']['output'];
  password: Scalars['String']['output'];
  path?: Maybe<Scalars['String']['output']>;
  pattern?: Maybe<Scalars['String']['output']>;
  port?: Maybe<Scalars['Int']['output']>;
  schedule?: Maybe<ImportPipelinePullScheduleSettings>;
  username: Scalars['String']['output'];
};

export type ImportPipelineSftpPullConnectorSettingsInput = {
  afterPull?: InputMaybe<ImportPipelineAfterPullActionSettingsInput>;
  host: Scalars['String']['input'];
  password: Scalars['String']['input'];
  path?: InputMaybe<Scalars['String']['input']>;
  pattern?: InputMaybe<Scalars['String']['input']>;
  port?: InputMaybe<Scalars['Int']['input']>;
  schedule?: InputMaybe<ImportPipelinePullScheduleSettingsInput>;
  username: Scalars['String']['input'];
};

export enum ImportPipelineSortOption {
  CREATED_AT = 'CREATED_AT',
  UPDATED_AT = 'UPDATED_AT',
}

export type ImportPipelinesConnection = {
  __typename?: 'ImportPipelinesConnection';
  /** Content of collection or the current page of collection if pagination is used */
  content: Array<ImportPipeline>;
  /** Total number of elements in this collection (ignoring pagination constraints, if any) */
  totalCount: Scalars['Float']['output'];
};

export type ImportPipelinesFilter = {
  importType?: InputMaybe<Array<ImportJobImportType>>;
  targetId?: InputMaybe<Array<Scalars['ID']['input']>>;
  title?: InputMaybe<StringSearchFilter>;
  warehouseId?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type ImportPipelinesSortBy = {
  /** Sorting direction */
  direction?: SortDirection;
  /** Field (sort option) to sort by */
  field: ImportPipelineSortOption;
};

export enum ImportSource {
  DATASET = 'DATASET',
  EXTERNAL = 'EXTERNAL',
  PIPELINE = 'PIPELINE',
  UPLOAD = 'UPLOAD',
}

export type InaccessibleLayoutLocation = {
  __typename?: 'InaccessibleLayoutLocation';
  aisle?: Maybe<LayoutFeature>;
  aisleId: Scalars['ID']['output'];
  bay?: Maybe<LayoutFeature>;
  bayType?: Maybe<Scalars['String']['output']>;
  congestionZone?: Maybe<Scalars['ID']['output']>;
  locationBayId: Scalars['ID']['output'];
  locationBayPosition: Scalars['Float']['output'];
  locationBayProjection: Scalars['Float']['output'];
  locationDepthFromFront: Scalars['Float']['output'];
  locationDepthPosition: Scalars['Float']['output'];
  locationHeight: Scalars['Float']['output'];
  locationHeightFromFloor: Scalars['Float']['output'];
  locationId: Scalars['ID']['output'];
  locationIndexFromFront: Scalars['Float']['output'];
  locationLength: Scalars['Float']['output'];
  locationLevel: Scalars['Float']['output'];
  locationOrder: Scalars['String']['output'];
  locationRackingType: Scalars['String']['output'];
  locationSide?: Maybe<Scalars['ID']['output']>;
  locationStatus: Scalars['Boolean']['output'];
  locationUsageType: Scalars['String']['output'];
  locationWeight: Scalars['Float']['output'];
  locationWidth: Scalars['Float']['output'];
  locmhtype?: Maybe<Scalars['String']['output']>;
  plane?: Maybe<LayoutPlane>;
  planeId: Scalars['ID']['output'];
  portal_deprecated?: Maybe<Scalars['GeoJSONPoint']['output']>;
  portals?: Maybe<Array<LocationPortal>>;
  reason: Array<LayoutLocationInaccessibilityReason>;
  shape?: Maybe<Scalars['GeoJSONPolygon']['output']>;
  warehouseArea: Scalars['String']['output'];
};

export type InaccessibleLayoutLocationsConnection = {
  __typename?: 'InaccessibleLayoutLocationsConnection';
  /** Content of collection or the current page of collection if pagination is used */
  content: Array<InaccessibleLayoutLocation>;
  /** Total number of elements in this collection (ignoring pagination constraints, if any) */
  totalCount: Scalars['Float']['output'];
};

export type IntegrationUser = {
  __typename?: 'IntegrationUser';
  awsCredentials?: Maybe<IntegrationUserAccessKey>;
  canDelete: Scalars['Boolean']['output'];
  createdAt: Scalars['Timestamp']['output'];
  description?: Maybe<Scalars['String']['output']>;
  enabled: Scalars['Boolean']['output'];
  extraSettings?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['ID']['output'];
  importPipelines: Array<ImportPipeline>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Timestamp']['output'];
  warehouseDefault: Scalars['Boolean']['output'];
};

export type IntegrationUserAccessKey = {
  __typename?: 'IntegrationUserAccessKey';
  accessKeyId: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['Float']['output']>;
  secretAccessKey: Scalars['String']['output'];
};

export type Item = {
  __typename?: 'Item';
  baseUom?: Maybe<BaseUom>;
  consignee: Scalars['ID']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  pollutionClass?: Maybe<Scalars['String']['output']>;
  sku: Scalars['ID']['output'];
  skuGroup?: Maybe<Scalars['String']['output']>;
  stockCategory?: Maybe<Scalars['String']['output']>;
  storageClass?: Maybe<Scalars['String']['output']>;
  subGroup?: Maybe<Scalars['String']['output']>;
  transportClass?: Maybe<Scalars['String']['output']>;
  uoms: Array<ItemUom>;
};

export type ItemFilter = {
  __typename?: 'ItemFilter';
  isNot?: Maybe<Scalars['Boolean']['output']>;
  isNull?: Maybe<Scalars['Boolean']['output']>;
  range?: Maybe<NumberFilterRange>;
  stringRange?: Maybe<StringFilterRange>;
  type: ItemFilterType;
  valueIn?: Maybe<Array<FilterValue>>;
};

export type ItemFilterInput = {
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  range?: InputMaybe<NumberFilterRangeInput>;
  stringRange?: InputMaybe<StringFilterRangeInput>;
  type: ItemFilterType;
  valueIn?: InputMaybe<Array<FilterValueInput>>;
};

export type ItemFilterIntersection = {
  __typename?: 'ItemFilterIntersection';
  allOf: Array<ItemFilter>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type ItemFilterIntersectionInput = {
  allOf: Array<ItemFilterInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export enum ItemFilterType {
  CONSIGNEE = 'CONSIGNEE',
  HEIGHT = 'HEIGHT',
  LENGTH = 'LENGTH',
  NET_WEIGHT = 'NET_WEIGHT',
  POLLUTION_CLASS = 'POLLUTION_CLASS',
  SKU = 'SKU',
  SKU_GROUP = 'SKU_GROUP',
  STOCK_CATEGORY = 'STOCK_CATEGORY',
  STORAGE_CLASS = 'STORAGE_CLASS',
  SUB_GROUP = 'SUB_GROUP',
  TRANSPORT_CLASS = 'TRANSPORT_CLASS',
  VOLUME = 'VOLUME',
  WIDTH = 'WIDTH',
}

export type ItemFilterUnion = {
  __typename?: 'ItemFilterUnion';
  anyOf: Array<ItemFilterIntersection>;
};

export type ItemFilterUnionInput = {
  anyOf: Array<ItemFilterIntersectionInput>;
};

export type ItemQuantityDetails = {
  __typename?: 'ItemQuantityDetails';
  breakdownByUom: Array<ItemUomQuantity>;
  quantityPerUom: Array<ItemUomQuantity>;
};

export type ItemSet = {
  __typename?: 'ItemSet';
  actualitiesWith: ActualitiesConnection;
  createdAt: Scalars['Timestamp']['output'];
  description?: Maybe<Scalars['String']['output']>;
  extraSettings?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['ID']['output'];
  importedAt?: Maybe<Scalars['Timestamp']['output']>;
  inputFileName?: Maybe<Scalars['String']['output']>;
  itemUoms: ItemUomOrItemsConnection;
  items: ItemsConnection;
  presentationSettings?: Maybe<PresentationSettings>;
  simulationsWith: SimulationsConnection;
  /** @deprecated in favour of simulation's stacking policy */
  stackingPolicy?: Maybe<StackingPolicy>;
  status: ItemSetImportStatus;
  summary?: Maybe<ItemSetSummary>;
  title: Scalars['String']['output'];
  uomTypes: Array<ItemUomType>;
  updatedAt: Scalars['Timestamp']['output'];
  userTags?: Maybe<Array<Scalars['String']['output']>>;
  warehouse: Warehouse;
};

export type ItemSetActualitiesWithArgs = {
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<ActualitiesSortBy>>;
};

export type ItemSetItemUomsArgs = {
  filter?: InputMaybe<ItemUomOrItemsSearchFilter>;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<ItemUomOrItemsSortBy>>;
};

export type ItemSetItemsArgs = {
  filter?: InputMaybe<ItemsFilter>;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<ItemsSortBy>>;
};

export type ItemSetSimulationsWithArgs = {
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<SimulationsSortBy>>;
};

export type ItemSetConsigneeSummary = {
  __typename?: 'ItemSetConsigneeSummary';
  consignee: Scalars['ID']['output'];
  itemsCount: Scalars['Float']['output'];
};

export enum ItemSetImportStatus {
  IMPORTED = 'IMPORTED',
  IMPORTING = 'IMPORTING',
  POSTPROCESSING = 'POSTPROCESSING',
}

export type ItemSetIssueInvalidItem = {
  __typename?: 'ItemSetIssueInvalidItem';
  consignee: Scalars['ID']['output'];
  itemId: Scalars['ID']['output'];
  reason: Array<ItemSetIssueInvalidItemReason>;
  sku: Scalars['ID']['output'];
};

export enum ItemSetIssueInvalidItemReason {
  NO_UOMS = 'NO_UOMS',
}

export type ItemSetIssueInvalidItemsConnection = {
  __typename?: 'ItemSetIssueInvalidItemsConnection';
  /** Content of collection or the current page of collection if pagination is used */
  content: Array<ItemSetIssueInvalidItem>;
  /** Total number of elements in this collection (ignoring pagination constraints, if any) */
  totalCount: Scalars['Float']['output'];
};

export type ItemSetIssueInvalidItemsFilter = {
  reason?: InputMaybe<Array<ItemSetIssueInvalidItemReason>>;
};

export type ItemSetIssueInvalidItemsSummary = {
  __typename?: 'ItemSetIssueInvalidItemsSummary';
  count: Scalars['Float']['output'];
  reason: ItemSetIssueInvalidItemReason;
};

export type ItemSetIssueInvalidUom = {
  __typename?: 'ItemSetIssueInvalidUom';
  consignee: Scalars['ID']['output'];
  itemId: Scalars['ID']['output'];
  reason: Array<ItemSetIssueInvalidUomReason>;
  sku: Scalars['ID']['output'];
  uom: Scalars['ID']['output'];
};

export enum ItemSetIssueInvalidUomReason {
  INVALID_VOLUME = 'INVALID_VOLUME',
  INVALID_WEIGHT = 'INVALID_WEIGHT',
}

export type ItemSetIssueInvalidUomsConnection = {
  __typename?: 'ItemSetIssueInvalidUomsConnection';
  /** Content of collection or the current page of collection if pagination is used */
  content: Array<ItemSetIssueInvalidUom>;
  /** Total number of elements in this collection (ignoring pagination constraints, if any) */
  totalCount: Scalars['Float']['output'];
};

export type ItemSetIssueInvalidUomsFilter = {
  reason?: InputMaybe<Array<ItemSetIssueInvalidUomReason>>;
};

export type ItemSetIssueInvalidUomsSummary = {
  __typename?: 'ItemSetIssueInvalidUomsSummary';
  count: Scalars['Float']['output'];
  reason: ItemSetIssueInvalidUomReason;
};

export type ItemSetIssueSet = {
  __typename?: 'ItemSetIssueSet';
  assignmentId: Scalars['ID']['output'];
  completedAt?: Maybe<Scalars['Timestamp']['output']>;
  createdAt: Scalars['Timestamp']['output'];
  id: Scalars['ID']['output'];
  invalidItems: ItemSetIssueInvalidItemsConnection;
  invalidUoms: ItemSetIssueInvalidUomsConnection;
  itemSetId: Scalars['ID']['output'];
  progress: Scalars['Float']['output'];
  startedAt?: Maybe<Scalars['Timestamp']['output']>;
  status: BatchJobStatus;
  summary?: Maybe<ItemSetIssueSummary>;
  unknownAssignedItems: ItemSetIssueUnknownAssignedItemsConnection;
  updatedAt: Scalars['Timestamp']['output'];
};

export type ItemSetIssueSetInvalidItemsArgs = {
  filter?: InputMaybe<ItemSetIssueInvalidItemsFilter>;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<ItemsSortBy>>;
};

export type ItemSetIssueSetInvalidUomsArgs = {
  filter?: InputMaybe<ItemSetIssueInvalidUomsFilter>;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<ItemUomOrItemsSortBy>>;
};

export type ItemSetIssueSetUnknownAssignedItemsArgs = {
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<ItemsSortBy>>;
};

export type ItemSetIssueSetKey = {
  assignmentId: Scalars['ID']['input'];
  itemSetId: Scalars['ID']['input'];
};

export type ItemSetIssueSummary = {
  __typename?: 'ItemSetIssueSummary';
  invalidItems: Array<ItemSetIssueInvalidItemsSummary>;
  invalidUoms: Array<ItemSetIssueInvalidUomsSummary>;
  totalAssignedItems: Scalars['Float']['output'];
  totalInvalidItems: Scalars['Float']['output'];
  totalInvalidUoms: Scalars['Float']['output'];
  totalItems: Scalars['Float']['output'];
  totalUoms: Scalars['Float']['output'];
  unknownAssignedItems: Scalars['Float']['output'];
};

export type ItemSetIssueUnknownAssignedItem = {
  __typename?: 'ItemSetIssueUnknownAssignedItem';
  consignee: Scalars['ID']['output'];
  itemId: Scalars['ID']['output'];
  locationIds: Array<Scalars['ID']['output']>;
  sku: Scalars['ID']['output'];
};

export type ItemSetIssueUnknownAssignedItemsConnection = {
  __typename?: 'ItemSetIssueUnknownAssignedItemsConnection';
  /** Content of collection or the current page of collection if pagination is used */
  content: Array<ItemSetIssueUnknownAssignedItem>;
  /** Total number of elements in this collection (ignoring pagination constraints, if any) */
  totalCount: Scalars['Float']['output'];
};

export type ItemSetSummary = {
  __typename?: 'ItemSetSummary';
  consignees: Array<ItemSetConsigneeSummary>;
  fields?: Maybe<Array<DatasetObjectFieldSummary>>;
  generatedAt?: Maybe<Scalars['Float']['output']>;
  startedAt?: Maybe<Scalars['Float']['output']>;
  totalItemsCount: Scalars['Float']['output'];
  version?: Maybe<Scalars['String']['output']>;
};

export type ItemSetsConnection = {
  __typename?: 'ItemSetsConnection';
  allUserTags: Array<Scalars['String']['output']>;
  /** Content of collection or the current page of collection if pagination is used */
  content: Array<ItemSet>;
  /** Total number of elements in this collection (ignoring pagination constraints, if any) */
  totalCount: Scalars['Float']['output'];
};

export type ItemSetsFilter = {
  status?: InputMaybe<Array<ItemSetImportStatus>>;
  title?: InputMaybe<StringSearchFilter>;
  userTags?: InputMaybe<Array<Scalars['String']['input']>>;
  userTagsHasAll?: InputMaybe<Array<Scalars['String']['input']>>;
  userTagsHasAny?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ItemSetsSortBy = {
  /** Sorting direction */
  direction?: SortDirection;
  /** Field (sort option) to sort by */
  field: BaseEntitySortOption;
};

export type ItemStackingDetails = {
  __typename?: 'ItemStackingDetails';
  item: Item;
  stackingPolicyRuleId?: Maybe<Scalars['ID']['output']>;
};

export type ItemUom = {
  __typename?: 'ItemUom';
  ean?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  itemId: Scalars['ID']['output'];
  length?: Maybe<Scalars['Float']['output']>;
  lowerUom?: Maybe<Scalars['ID']['output']>;
  maxStack?: Maybe<Scalars['Int']['output']>;
  netWeight?: Maybe<Scalars['Float']['output']>;
  unitsPerLowestUom?: Maybe<Scalars['Float']['output']>;
  uom: Scalars['ID']['output'];
  upc?: Maybe<Scalars['String']['output']>;
  volume?: Maybe<Scalars['Float']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

export type ItemUomDimensions = {
  __typename?: 'ItemUomDimensions';
  height?: Maybe<Scalars['Float']['output']>;
  length?: Maybe<Scalars['Float']['output']>;
  uom: Scalars['ID']['output'];
  width?: Maybe<Scalars['Float']['output']>;
};

/** Uom with associated item, or just item for items that have no uoms */
export type ItemUomOrItem = {
  __typename?: 'ItemUomOrItem';
  item: Item;
  uom?: Maybe<ItemUom>;
};

export type ItemUomOrItemsConnection = {
  __typename?: 'ItemUomOrItemsConnection';
  /** Content of collection or the current page of collection if pagination is used */
  content: Array<ItemUomOrItem>;
  /** Total number of elements in this collection (ignoring pagination constraints, if any) */
  totalCount: Scalars['Float']['output'];
};

export type ItemUomOrItemsSearchFilter = {
  consignee?: InputMaybe<StringSearchFilter>;
  height?: InputMaybe<NumberSearchFilter>;
  id?: InputMaybe<StringSearchFilter>;
  length?: InputMaybe<NumberSearchFilter>;
  lowerUom?: InputMaybe<StringSearchFilter>;
  maxStack?: InputMaybe<NumberSearchFilter>;
  netWeight?: InputMaybe<NumberSearchFilter>;
  pollutionClass?: InputMaybe<StringSearchFilter>;
  sku?: InputMaybe<StringSearchFilter>;
  skuGroup?: InputMaybe<StringSearchFilter>;
  stockCategory?: InputMaybe<StringSearchFilter>;
  storageClass?: InputMaybe<StringSearchFilter>;
  subGroup?: InputMaybe<StringSearchFilter>;
  transportClass?: InputMaybe<StringSearchFilter>;
  unitsPerLowestUom?: InputMaybe<NumberSearchFilter>;
  uom?: InputMaybe<StringSearchFilter>;
  volume?: InputMaybe<NumberSearchFilter>;
  width?: InputMaybe<NumberSearchFilter>;
};

export type ItemUomOrItemsSortBy = {
  /** Sorting direction */
  direction?: SortDirection;
  /** Field (sort option) to sort by */
  field: ItemUomsSortOptions;
};

export type ItemUomQuantity = {
  __typename?: 'ItemUomQuantity';
  quantity: Scalars['Float']['output'];
  uom?: Maybe<Scalars['String']['output']>;
  uomQuantity: Scalars['Float']['output'];
};

export type ItemUomType = {
  __typename?: 'ItemUomType';
  uom: Scalars['ID']['output'];
};

export type ItemUomVolume = {
  __typename?: 'ItemUomVolume';
  height?: Maybe<Scalars['Float']['output']>;
  length?: Maybe<Scalars['Float']['output']>;
  maxStack?: Maybe<Scalars['Int']['output']>;
  uom: Scalars['ID']['output'];
  volume?: Maybe<Scalars['Float']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

export enum ItemUomsSortOptions {
  CONSIGNEE = 'CONSIGNEE',
  SKU = 'SKU',
  UOM = 'UOM',
}

export type ItemsConnection = {
  __typename?: 'ItemsConnection';
  /** Content of collection or the current page of collection if pagination is used */
  content: Array<Item>;
  /** Total number of elements in this collection (ignoring pagination constraints, if any) */
  totalCount: Scalars['Float']['output'];
};

export type ItemsFilter = {
  consignee?: InputMaybe<Array<Scalars['ID']['input']>>;
  consigneeContains?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Array<Scalars['ID']['input']>>;
  sku?: InputMaybe<Array<Scalars['ID']['input']>>;
  skuContains?: InputMaybe<Scalars['String']['input']>;
  skuGroup?: InputMaybe<Array<Scalars['ID']['input']>>;
  skuGroupContains?: InputMaybe<Scalars['String']['input']>;
};

export type ItemsSortBy = {
  /** Sorting direction */
  direction?: SortDirection;
  /** Field (sort option) to sort by */
  field: ItemsSortOptions;
};

export enum ItemsSortOptions {
  CONSIGNEE = 'CONSIGNEE',
  SKU = 'SKU',
}

export type Layout = {
  __typename?: 'Layout';
  actualitiesWith: ActualitiesConnection;
  connectivityGraph: LayoutConnectivityGraph;
  createdAt: Scalars['Timestamp']['output'];
  description?: Maybe<Scalars['String']['output']>;
  extraSettings?: Maybe<Scalars['JSON']['output']>;
  features: Array<LayoutFeature>;
  id: Scalars['ID']['output'];
  importedFrom?: Maybe<LayoutImport>;
  layoutMap: LayoutMap;
  locationFilterValues: Array<FindLocationFilterValuesFilterResult>;
  locationGroupsByFilter: GroupLocationsByFilterResult;
  locations: LayoutLocationsConnection;
  locationsByFilter: LayoutLocationsConnectionWithSummary;
  locationsWithMatchingFilters: LayoutLocationWithMatchingFiltersConnection;
  planes: Array<LayoutPlane>;
  presentationSettings?: Maybe<PresentationSettings>;
  settings?: Maybe<LayoutSettings>;
  simulationsWith: SimulationsConnection;
  status: LayoutStatus;
  summary?: Maybe<LayoutSummary>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['Timestamp']['output'];
  userTags?: Maybe<Array<Scalars['String']['output']>>;
  warehouse: Warehouse;
};

export type LayoutActualitiesWithArgs = {
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<SimulationsSortBy>>;
};

export type LayoutFeaturesArgs = {
  filter?: InputMaybe<LayoutFeatureFilterInput>;
};

export type LayoutLocationFilterValuesArgs = {
  engine?: FilterEngineType;
  input: FindLocationFilterValuesInput;
};

export type LayoutLocationGroupsByFilterArgs = {
  filter?: InputMaybe<LayoutLocationFilter>;
  input: GroupLocationsByFilterInput;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<LayoutLocationsSortBy>>;
};

export type LayoutLocationsArgs = {
  filter?: InputMaybe<LayoutLocationsSearchFilter>;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<LayoutLocationsSortBy>>;
};

export type LayoutLocationsByFilterArgs = {
  filter?: InputMaybe<LayoutLocationFilter>;
  input: FindLocationsByFilterInput;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<LayoutLocationsSortBy>>;
  summary?: InputMaybe<LayoutLocationsSummaryRequest>;
};

export type LayoutLocationsWithMatchingFiltersArgs = {
  filter?: InputMaybe<LayoutLocationFilter>;
  input: GroupLocationsByFilterInput;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<LayoutLocationsSortBy>>;
};

export type LayoutSimulationsWithArgs = {
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<SimulationsSortBy>>;
};

export type LayoutAisleDetails = {
  __typename?: 'LayoutAisleDetails';
  aisleType?: Maybe<Scalars['ID']['output']>;
};

export type LayoutAreaSummary = {
  __typename?: 'LayoutAreaSummary';
  aisleCount: Scalars['Float']['output'];
  areaId: Scalars['ID']['output'];
  bayCount: Scalars['Float']['output'];
  locationCount: Scalars['Float']['output'];
  title: Scalars['String']['output'];
};

export type LayoutBayAisleConnection = {
  __typename?: 'LayoutBayAisleConnection';
  aisle: LayoutFeature;
  portal: BayPortal;
};

export type LayoutBayDetails = {
  __typename?: 'LayoutBayDetails';
  bayType?: Maybe<Scalars['ID']['output']>;
  depth: Scalars['Float']['output'];
  frontEdge: Vector;
  height?: Maybe<Scalars['Float']['output']>;
  levels?: Maybe<Array<BayLevel>>;
  locationOrder: BayLocationOrder;
  position: Point;
  width: Scalars['Float']['output'];
};

export type LayoutConnectivityGraph = {
  __typename?: 'LayoutConnectivityGraph';
  planes: Array<PlaneConnectivityGraph>;
};

export type LayoutConnectivityGraphInput = {
  planes: Array<PlaneConnectivityGraphInput>;
};

export type LayoutFeature = {
  __typename?: 'LayoutFeature';
  bayAisles: Array<LayoutBayAisleConnection>;
  computedBayShape?: Maybe<Scalars['GeoJSONPolygon']['output']>;
  details?: Maybe<LayoutFeatureDetails>;
  id: Scalars['ID']['output'];
  innerShape?: Maybe<Scalars['GeoJSONPolygon']['output']>;
  navigable: Scalars['Boolean']['output'];
  planeId: Scalars['ID']['output'];
  shape?: Maybe<Scalars['GeoJSONPolygon']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type: LayoutFeatureType;
};

export type LayoutFeatureDetails = LayoutAisleDetails | LayoutBayDetails;

export type LayoutFeatureFilterInput = {
  id?: InputMaybe<Array<Scalars['ID']['input']>>;
  planeId?: InputMaybe<Array<Scalars['ID']['input']>>;
  type?: InputMaybe<Array<LayoutFeatureType>>;
};

export enum LayoutFeatureType {
  AISLE = 'AISLE',
  BAY = 'BAY',
}

export type LayoutImport = {
  __typename?: 'LayoutImport';
  areas: Array<LayoutImportArea>;
  createdAt: Scalars['Timestamp']['output'];
  description?: Maybe<Scalars['String']['output']>;
  extraSettings?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['ID']['output'];
  importTime?: Maybe<Scalars['Int']['output']>;
  importedAt: Scalars['Timestamp']['output'];
  inputFileName: Scalars['String']['output'];
  locations: LayoutImportLocationsConnection;
  presentationSettings?: Maybe<PresentationSettings>;
  settings?: Maybe<Scalars['JSON']['output']>;
  status: LayoutImportStatus;
  summary?: Maybe<LayoutImportSummary>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['Timestamp']['output'];
  userTags?: Maybe<Array<Scalars['String']['output']>>;
  warehouse: Warehouse;
};

export type LayoutImportAreasArgs = {
  filter?: InputMaybe<LayoutImportLocationFilter>;
};

export type LayoutImportLocationsArgs = {
  filter?: InputMaybe<LayoutImportLocationFilter>;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<LayoutImportLocationsSortBy>>;
};

export type LayoutImportAisle = {
  __typename?: 'LayoutImportAisle';
  aisle: Scalars['String']['output'];
  aisleId: Scalars['String']['output'];
  locationCount: Scalars['Float']['output'];
  sides: Array<LayoutImportSide>;
};

export type LayoutImportArea = {
  __typename?: 'LayoutImportArea';
  aisles: Array<LayoutImportAisle>;
  area: Scalars['String']['output'];
  locationCount: Scalars['Float']['output'];
};

export type LayoutImportAreaSummary = {
  __typename?: 'LayoutImportAreaSummary';
  aisleCount: Scalars['Float']['output'];
  areaId: Scalars['ID']['output'];
  bayCount: Scalars['Float']['output'];
  locationCount: Scalars['Float']['output'];
  title: Scalars['String']['output'];
};

export type LayoutImportBay = {
  __typename?: 'LayoutImportBay';
  bay: Scalars['Float']['output'];
  bayId: Scalars['String']['output'];
  bayType?: Maybe<Scalars['String']['output']>;
  depth: Scalars['Float']['output'];
  levels: Array<LayoutImportLevelSummary>;
  locationCount: Scalars['Float']['output'];
  locations: Array<LayoutImportLocation>;
};

export type LayoutImportLevelSummary = {
  __typename?: 'LayoutImportLevelSummary';
  height: Scalars['Float']['output'];
  level: Scalars['Float']['output'];
  locationCount: Scalars['Float']['output'];
  usedWidth: Scalars['Float']['output'];
};

export type LayoutImportLocation = {
  __typename?: 'LayoutImportLocation';
  baySide: Scalars['String']['output'];
  bayType?: Maybe<Scalars['String']['output']>;
  locationAisle: Scalars['String']['output'];
  locationBay: Scalars['Float']['output'];
  locationBayId: Scalars['String']['output'];
  locationBayPosition: Scalars['Float']['output'];
  locationDepthPosition: Scalars['Float']['output'];
  locationHeight: Scalars['Float']['output'];
  locationId: Scalars['String']['output'];
  locationKey: Scalars['String']['output'];
  locationLength: Scalars['Float']['output'];
  locationLevel: Scalars['Float']['output'];
  locationOrder: Scalars['String']['output'];
  locationRackingType: Scalars['String']['output'];
  locationStatus: Scalars['Boolean']['output'];
  locationUsageType: Scalars['String']['output'];
  locationWeight: Scalars['Float']['output'];
  locationWidth: Scalars['Float']['output'];
  locmhtype: Scalars['String']['output'];
  portalSpecs?: Maybe<Array<LocationPortalSpec>>;
  warehouseArea: Scalars['String']['output'];
};

export type LayoutImportLocationFilter = {
  area?: InputMaybe<Array<Scalars['ID']['input']>>;
  bayId?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type LayoutImportLocationsConnection = {
  __typename?: 'LayoutImportLocationsConnection';
  /** Content of collection or the current page of collection if pagination is used */
  content: Array<LayoutImportLocation>;
  /** Total number of elements in this collection (ignoring pagination constraints, if any) */
  totalCount: Scalars['Float']['output'];
};

export type LayoutImportLocationsSortBy = {
  /** Sorting direction */
  direction?: SortDirection;
  /** Field (sort option) to sort by */
  field: LayoutImportLocationsSortOptions;
};

export enum LayoutImportLocationsSortOptions {
  LOCATION_ID = 'LOCATION_ID',
}

export type LayoutImportSide = {
  __typename?: 'LayoutImportSide';
  bays: Array<LayoutImportBay>;
  locationCount: Scalars['Float']['output'];
  side: AisleSide;
};

export enum LayoutImportStatus {
  CONVERTED = 'CONVERTED',
  IMPORTED = 'IMPORTED',
  IMPORTING = 'IMPORTING',
}

export type LayoutImportSummary = {
  __typename?: 'LayoutImportSummary';
  aisleCount: Scalars['Float']['output'];
  areas: Array<LayoutImportAreaSummary>;
  bayCount: Scalars['Float']['output'];
  disabledLocationCount: Scalars['Float']['output'];
  fields?: Maybe<Array<DatasetObjectFieldSummary>>;
  locationCount: Scalars['Float']['output'];
};

export type LayoutImportsConnection = {
  __typename?: 'LayoutImportsConnection';
  allUserTags: Array<Scalars['String']['output']>;
  /** Content of collection or the current page of collection if pagination is used */
  content: Array<LayoutImport>;
  /** Total number of elements in this collection (ignoring pagination constraints, if any) */
  totalCount: Scalars['Float']['output'];
};

export type LayoutImportsFilter = {
  status?: InputMaybe<Array<LayoutImportStatus>>;
  title?: InputMaybe<StringSearchFilter>;
  userTags?: InputMaybe<Array<Scalars['String']['input']>>;
  userTagsHasAll?: InputMaybe<Array<Scalars['String']['input']>>;
  userTagsHasAny?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type LayoutImportsSortBy = {
  /** Sorting direction */
  direction?: SortDirection;
  /** Field (sort option) to sort by */
  field: BaseEntitySortOption;
};

export type LayoutIssueInvalidLocation = {
  __typename?: 'LayoutIssueInvalidLocation';
  locationBayId: Scalars['ID']['output'];
  locationId: Scalars['ID']['output'];
  locationLevel: Scalars['Float']['output'];
  planeId: Scalars['ID']['output'];
  reason: Array<LayoutIssueInvalidLocationReason>;
};

export enum LayoutIssueInvalidLocationReason {
  DISABLED = 'DISABLED',
}

export type LayoutIssueInvalidLocationsConnection = {
  __typename?: 'LayoutIssueInvalidLocationsConnection';
  /** Content of collection or the current page of collection if pagination is used */
  content: Array<LayoutIssueInvalidLocation>;
  /** Total number of elements in this collection (ignoring pagination constraints, if any) */
  totalCount: Scalars['Float']['output'];
};

export type LayoutIssueInvalidLocationsSummary = {
  __typename?: 'LayoutIssueInvalidLocationsSummary';
  count: Scalars['Float']['output'];
  locationLevel?: Maybe<Scalars['Float']['output']>;
  planeId?: Maybe<Scalars['ID']['output']>;
  reason: LayoutIssueInvalidLocationReason;
};

export type LayoutIssueSet = {
  __typename?: 'LayoutIssueSet';
  completedAt?: Maybe<Scalars['Timestamp']['output']>;
  createdAt: Scalars['Timestamp']['output'];
  id: Scalars['ID']['output'];
  invalidLocations: LayoutIssueInvalidLocationsConnection;
  layoutId: Scalars['ID']['output'];
  progress: Scalars['Float']['output'];
  startedAt?: Maybe<Scalars['Timestamp']['output']>;
  status: BatchJobStatus;
  summary?: Maybe<LayoutIssueSummary>;
  updatedAt: Scalars['Timestamp']['output'];
};

export type LayoutIssueSetInvalidLocationsArgs = {
  filter?: InputMaybe<LayoutLocationIssueFilter>;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<LayoutLocationsSortBy>>;
};

export type LayoutIssueSetKey = {
  layoutId: Scalars['ID']['input'];
};

export type LayoutIssueSummary = {
  __typename?: 'LayoutIssueSummary';
  invalidLocations: Array<LayoutIssueInvalidLocationsSummary>;
  totalInvalidLocations: Scalars['Float']['output'];
  totalLocations: Scalars['Float']['output'];
};

export type LayoutLocation = {
  __typename?: 'LayoutLocation';
  aisle?: Maybe<LayoutFeature>;
  aisleId: Scalars['ID']['output'];
  bay?: Maybe<LayoutFeature>;
  bayType?: Maybe<Scalars['String']['output']>;
  congestionZone?: Maybe<Scalars['ID']['output']>;
  locationBayId: Scalars['ID']['output'];
  locationBayPosition: Scalars['Float']['output'];
  locationBayProjection: Scalars['Float']['output'];
  locationDepthFromFront: Scalars['Float']['output'];
  locationDepthPosition: Scalars['Float']['output'];
  locationHeight: Scalars['Float']['output'];
  locationHeightFromFloor: Scalars['Float']['output'];
  locationId: Scalars['ID']['output'];
  locationIndexFromFront: Scalars['Float']['output'];
  locationLength: Scalars['Float']['output'];
  locationLevel: Scalars['Float']['output'];
  locationOrder: Scalars['String']['output'];
  locationRackingType: Scalars['String']['output'];
  locationSide?: Maybe<Scalars['ID']['output']>;
  locationStatus: Scalars['Boolean']['output'];
  locationUsageType: Scalars['String']['output'];
  locationWeight: Scalars['Float']['output'];
  locationWidth: Scalars['Float']['output'];
  locmhtype?: Maybe<Scalars['String']['output']>;
  plane?: Maybe<LayoutPlane>;
  planeId: Scalars['ID']['output'];
  portal_deprecated?: Maybe<Scalars['GeoJSONPoint']['output']>;
  portals?: Maybe<Array<LocationPortal>>;
  shape?: Maybe<Scalars['GeoJSONPolygon']['output']>;
  warehouseArea: Scalars['String']['output'];
};

export type LayoutLocationFilter = {
  bayId?: InputMaybe<Array<Scalars['ID']['input']>>;
  level?: InputMaybe<Array<Scalars['Float']['input']>>;
  locationId?: InputMaybe<Array<Scalars['ID']['input']>>;
  planeId?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type LayoutLocationIdsConnection = {
  __typename?: 'LayoutLocationIdsConnection';
  /** Content of collection or the current page of collection if pagination is used */
  content: Array<Scalars['ID']['output']>;
  /** Total number of elements in this collection (ignoring pagination constraints, if any) */
  totalCount: Scalars['Float']['output'];
};

export enum LayoutLocationInaccessibilityReason {
  NO_ACCESS_FROM_START = 'NO_ACCESS_FROM_START',
  NO_ACCESS_TO_END = 'NO_ACCESS_TO_END',
}

export type LayoutLocationIssueFilter = {
  locationBayId?: InputMaybe<Array<Scalars['ID']['input']>>;
  locationLevel?: InputMaybe<Array<Scalars['Int']['input']>>;
  planeId?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type LayoutLocationSummaryUomCount = {
  __typename?: 'LayoutLocationSummaryUomCount';
  id?: Maybe<Scalars['ID']['output']>;
  uomCount: Scalars['Float']['output'];
};

export type LayoutLocationWithMatchingFilters = {
  __typename?: 'LayoutLocationWithMatchingFilters';
  allMatchingFilters: Array<Scalars['Float']['output']>;
  locationId: Scalars['ID']['output'];
};

export type LayoutLocationWithMatchingFiltersConnection = {
  __typename?: 'LayoutLocationWithMatchingFiltersConnection';
  /** Content of collection or the current page of collection if pagination is used */
  content: Array<LayoutLocationWithMatchingFilters>;
  /** Total number of elements in this collection (ignoring pagination constraints, if any) */
  totalCount: Scalars['Float']['output'];
};

export type LayoutLocationsConnection = {
  __typename?: 'LayoutLocationsConnection';
  /** Content of collection or the current page of collection if pagination is used */
  content: Array<LayoutLocation>;
  /** Total number of elements in this collection (ignoring pagination constraints, if any) */
  totalCount: Scalars['Float']['output'];
};

export type LayoutLocationsConnectionWithSummary = {
  __typename?: 'LayoutLocationsConnectionWithSummary';
  /** Content of collection or the current page of collection if pagination is used */
  content: Array<LayoutLocation>;
  summary?: Maybe<Array<LayoutLocationsSummary>>;
  /** Total number of elements in this collection (ignoring pagination constraints, if any) */
  totalCount: Scalars['Float']['output'];
};

export type LayoutLocationsSearchFilter = {
  bayId?: InputMaybe<Array<Scalars['ID']['input']>>;
  locationAisleTitle?: InputMaybe<StringSearchFilter>;
  locationBayPosition?: InputMaybe<NumberSearchFilter>;
  locationBayTitle?: InputMaybe<StringSearchFilter>;
  locationHeight?: InputMaybe<NumberSearchFilter>;
  locationId?: InputMaybe<StringSearchFilter>;
  locationLength?: InputMaybe<NumberSearchFilter>;
  locationLevel?: InputMaybe<NumberSearchFilter>;
  locationOrder?: InputMaybe<NumberSearchFilter>;
  locationPlaneTitle?: InputMaybe<StringSearchFilter>;
  locationRackingType?: InputMaybe<StringSearchFilter>;
  locationStatus?: InputMaybe<BooleanSearchFilter>;
  locationUsageType?: InputMaybe<StringSearchFilter>;
  locationWeight?: InputMaybe<NumberSearchFilter>;
  locationWidth?: InputMaybe<NumberSearchFilter>;
  locmhtype?: InputMaybe<StringSearchFilter>;
  planeId?: InputMaybe<Array<Scalars['ID']['input']>>;
  warehouseArea?: InputMaybe<StringSearchFilter>;
};

export type LayoutLocationsSortBy = {
  /** Sorting direction */
  direction?: SortDirection;
  /** Field (sort option) to sort by */
  field: LayoutLocationsSortOptions;
};

export enum LayoutLocationsSortOptions {
  LOCATION_ID = 'LOCATION_ID',
}

export type LayoutLocationsSummary = {
  __typename?: 'LayoutLocationsSummary';
  key: LayoutLocationsSummaryKey;
  locationCount: Scalars['Float']['output'];
  uoms?: Maybe<Array<LayoutLocationSummaryUomCount>>;
};

export enum LayoutLocationsSummaryGroupBy {
  ACCESS_AISLE = 'ACCESS_AISLE',
  AISLE = 'AISLE',
  ASSIGNED = 'ASSIGNED',
  CONGESTION_ZONE = 'CONGESTION_ZONE',
  CONSIGNEE = 'CONSIGNEE',
  LEVEL = 'LEVEL',
  LOCATION_HEIGHT = 'LOCATION_HEIGHT',
  LOCATION_LENGTH = 'LOCATION_LENGTH',
  LOCATION_RACKING_TYPE = 'LOCATION_RACKING_TYPE',
  LOCATION_STATUS = 'LOCATION_STATUS',
  LOCATION_USAGE_TYPE = 'LOCATION_USAGE_TYPE',
  LOCATION_WIDTH = 'LOCATION_WIDTH',
  PLANE = 'PLANE',
  SIDE = 'SIDE',
  WAREHOUSE_AREA = 'WAREHOUSE_AREA',
}

export type LayoutLocationsSummaryKey = {
  __typename?: 'LayoutLocationsSummaryKey';
  accessAisleId?: Maybe<Scalars['String']['output']>;
  aisleId?: Maybe<Scalars['ID']['output']>;
  assigned?: Maybe<Scalars['Boolean']['output']>;
  congestionZone?: Maybe<Scalars['ID']['output']>;
  consignee?: Maybe<Scalars['String']['output']>;
  locationHeight?: Maybe<Scalars['Float']['output']>;
  locationLength?: Maybe<Scalars['Float']['output']>;
  locationLevel?: Maybe<Scalars['Float']['output']>;
  locationRackingType?: Maybe<Scalars['String']['output']>;
  locationSide?: Maybe<Scalars['ID']['output']>;
  locationStatus?: Maybe<Scalars['Boolean']['output']>;
  locationUsageType?: Maybe<Scalars['String']['output']>;
  locationWidth?: Maybe<Scalars['Float']['output']>;
  planeId?: Maybe<Scalars['ID']['output']>;
  warehouseArea?: Maybe<Scalars['String']['output']>;
};

export type LayoutLocationsSummaryRequest = {
  assignmentId?: InputMaybe<Scalars['ID']['input']>;
  groupBy?: InputMaybe<Array<LayoutLocationsSummaryGroupBy>>;
  uoms?: InputMaybe<Array<LayoutLocationsSummaryUomSpec>>;
};

export type LayoutLocationsSummaryUomSpec = {
  height?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  length?: InputMaybe<Scalars['Float']['input']>;
  width?: InputMaybe<Scalars['Float']['input']>;
};

export type LayoutMap = {
  __typename?: 'LayoutMap';
  bayTypes?: Maybe<Array<BayTypeModel>>;
  locationTypes?: Maybe<Array<LocationTypeModel>>;
  planes: Array<Plane>;
};

export type LayoutMapInput = {
  bayTypes?: InputMaybe<Array<BayTypeModelInput>>;
  locationTypes?: InputMaybe<Array<LocationTypeModelInput>>;
  planes: Array<PlaneInput>;
};

export type LayoutOrDraft = Layout | LayoutImport;

export type LayoutPlane = {
  __typename?: 'LayoutPlane';
  boundingBox?: Maybe<Scalars['GeoJSONBBox']['output']>;
  connectivityGraph?: Maybe<PlaneConnectivityGraph2>;
  end?: Maybe<PlaneAccessSpec>;
  id: Scalars['ID']['output'];
  levels?: Maybe<Array<Scalars['Float']['output']>>;
  outlineShape?: Maybe<Scalars['GeoJSONGeometry']['output']>;
  start?: Maybe<PlaneAccessSpec>;
  title?: Maybe<Scalars['String']['output']>;
};

export type LayoutPlaneRoute = {
  __typename?: 'LayoutPlaneRoute';
  distance: Scalars['Float']['output'];
  planeId: Scalars['ID']['output'];
  unreachableLocations: Array<Scalars['ID']['output']>;
  unreachableTerminals: Array<PicklistEventWaypoint>;
  waypoints: Array<PicklistEventWaypoint>;
};

export type LayoutRoute = {
  __typename?: 'LayoutRoute';
  routes: Array<LayoutPlaneRoute>;
  unknownLocations: Array<Scalars['ID']['output']>;
};

export enum LayoutRouteOrder {
  KEEP_ORDER = 'KEEP_ORDER',
  LOCATION_ORDER = 'LOCATION_ORDER',
}

export type LayoutSettings = {
  __typename?: 'LayoutSettings';
  bayTypes?: Maybe<Array<BayTypeModel>>;
  locationTypes?: Maybe<Array<LocationTypeModel>>;
};

export enum LayoutStatus {
  CONVERSION_FAILED = 'CONVERSION_FAILED',
  CONVERTING = 'CONVERTING',
  READY = 'READY',
}

export type LayoutSummary = {
  __typename?: 'LayoutSummary';
  aisleCount: Scalars['Float']['output'];
  areas: Array<LayoutAreaSummary>;
  bayCount: Scalars['Float']['output'];
  disabledLocationCount: Scalars['Float']['output'];
  fields?: Maybe<Array<DatasetObjectFieldSummary>>;
  locationCount: Scalars['Float']['output'];
};

export type LayoutsConnection = {
  __typename?: 'LayoutsConnection';
  allUserTags: Array<Scalars['String']['output']>;
  /** Content of collection or the current page of collection if pagination is used */
  content: Array<Layout>;
  /** Total number of elements in this collection (ignoring pagination constraints, if any) */
  totalCount: Scalars['Float']['output'];
};

export type LayoutsFilter = {
  status?: InputMaybe<Array<LayoutStatus>>;
  title?: InputMaybe<StringSearchFilter>;
  userTags?: InputMaybe<Array<Scalars['String']['input']>>;
  userTagsHasAll?: InputMaybe<Array<Scalars['String']['input']>>;
  userTagsHasAny?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type LayoutsOrDraftsConnection = {
  __typename?: 'LayoutsOrDraftsConnection';
  allUserTags: Array<Scalars['String']['output']>;
  /** Content of collection or the current page of collection if pagination is used */
  content: Array<LayoutOrDraft>;
  /** Total number of elements in this collection (ignoring pagination constraints, if any) */
  totalCount: Scalars['Float']['output'];
};

export type LayoutsOrDraftsFilter = {
  draft?: InputMaybe<LayoutImportsFilter>;
  layout?: InputMaybe<LayoutsFilter>;
};

export type LayoutsOrDraftsSortBy = {
  /** Sorting direction */
  direction?: SortDirection;
  /** Field (sort option) to sort by */
  field: BaseEntitySortOption;
};

export type LayoutsSortBy = {
  /** Sorting direction */
  direction?: SortDirection;
  /** Field (sort option) to sort by */
  field: BaseEntitySortOption;
};

export type LimitSettings = {
  __typename?: 'LimitSettings';
  maxMoves?: Maybe<Scalars['Float']['output']>;
};

export type LimitSettingsInput = {
  maxMoves?: InputMaybe<Scalars['Float']['input']>;
};

export type LoadBalancingConstraint = {
  __typename?: 'LoadBalancingConstraint';
  maxRelativeDeviation: Scalars['Float']['output'];
};

export type LoadBalancingConstraintInput = {
  maxRelativeDeviation: Scalars['Float']['input'];
};

export type LoadBalancingPolicy = {
  __typename?: 'LoadBalancingPolicy';
  rules: Array<LoadBalancingRule>;
};

export type LoadBalancingPolicyInput = {
  rules: Array<LoadBalancingRuleInput>;
};

export type LoadBalancingRule = {
  __typename?: 'LoadBalancingRule';
  constraint?: Maybe<LoadBalancingConstraint>;
  id?: Maybe<Scalars['ID']['output']>;
  include?: Maybe<LocationFilterUnion>;
  objectiveWeight?: Maybe<Scalars['Float']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type: LoadBalancingZoneType;
};

export type LoadBalancingRuleInput = {
  constraint?: InputMaybe<LoadBalancingConstraintInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  include?: InputMaybe<LocationFilterUnionInput>;
  objectiveWeight?: InputMaybe<Scalars['Float']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type: LoadBalancingZoneType;
};

export type LoadBalancingRuleSummary = {
  __typename?: 'LoadBalancingRuleSummary';
  constraintSatisfied?: Maybe<Scalars['Boolean']['output']>;
  locationCount: Scalars['Float']['output'];
  maxRelativeDeviation: Scalars['Float']['output'];
  meanLoad: Scalars['Float']['output'];
  stdDev: Scalars['Float']['output'];
  totalLoad: Scalars['Float']['output'];
  variance: Scalars['Float']['output'];
  zones: Array<LoadBalancingZoneSummary>;
};

export type LoadBalancingZoneSummary = {
  __typename?: 'LoadBalancingZoneSummary';
  locationCount: Scalars['Float']['output'];
  locationIds: Array<Scalars['ID']['output']>;
  zoneId: Scalars['ID']['output'];
  zoneLoad: Scalars['Float']['output'];
};

export enum LoadBalancingZoneType {
  AISLE = 'AISLE',
  BAY = 'BAY',
  CONGESTION_ZONE = 'CONGESTION_ZONE',
  PLANE = 'PLANE',
  WAREHOUSE_AREA = 'WAREHOUSE_AREA',
}

export type LoadingJobDetails = {
  __typename?: 'LoadingJobDetails';
  deadline: Scalars['LocalDateTime']['output'];
  netWeight: Scalars['Float']['output'];
  orderCount: Scalars['Float']['output'];
  picklistCount: Scalars['Float']['output'];
  picklistLineCount: Scalars['Float']['output'];
  possibleGates: Array<LoadingJobGateDetails>;
  quantity: Scalars['Float']['output'];
  shipmentId: Scalars['ID']['output'];
  uomQuantities: Array<LoadingJobUomQuantity>;
  volume: Scalars['Float']['output'];
};

export type LoadingJobGateDetails = {
  __typename?: 'LoadingJobGateDetails';
  cost: Scalars['Float']['output'];
  distance: Scalars['Float']['output'];
  duration: Scalars['Float']['output'];
  gateId: Scalars['ID']['output'];
};

export type LoadingJobUomQuantity = {
  __typename?: 'LoadingJobUomQuantity';
  uom: Scalars['ID']['output'];
  uomQuantity: Scalars['Float']['output'];
};

export type LocalDateRangeFilter = {
  __typename?: 'LocalDateRangeFilter';
  excludeFrom?: Maybe<Scalars['Boolean']['output']>;
  excludeTo?: Maybe<Scalars['Boolean']['output']>;
  from?: Maybe<Scalars['LocalDateTime']['output']>;
  includeNull?: Maybe<Scalars['Boolean']['output']>;
  to?: Maybe<Scalars['LocalDateTime']['output']>;
};

export type LocalDateRangeFilterInput = {
  excludeFrom?: InputMaybe<Scalars['Boolean']['input']>;
  excludeTo?: InputMaybe<Scalars['Boolean']['input']>;
  from?: InputMaybe<Scalars['LocalDateTime']['input']>;
  includeNull?: InputMaybe<Scalars['Boolean']['input']>;
  to?: InputMaybe<Scalars['LocalDateTime']['input']>;
};

export type LocalDateTimeSearchFilter = {
  excludeFrom?: InputMaybe<Scalars['Boolean']['input']>;
  excludeTo?: InputMaybe<Scalars['Boolean']['input']>;
  from?: InputMaybe<Scalars['LocalDateTime']['input']>;
  to?: InputMaybe<Scalars['LocalDateTime']['input']>;
};

export type LocationAisleInfo = {
  __typename?: 'LocationAisleInfo';
  id: Scalars['ID']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type LocationBayInfo = {
  __typename?: 'LocationBayInfo';
  id: Scalars['ID']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export enum LocationFillingMethod {
  FILL = 'FILL',
  MAX_UOM = 'MAX_UOM',
}

export type LocationFilter = {
  __typename?: 'LocationFilter';
  isNot?: Maybe<Scalars['Boolean']['output']>;
  isNull?: Maybe<Scalars['Boolean']['output']>;
  range?: Maybe<NumberFilterRange>;
  stringRange?: Maybe<StringFilterRange>;
  type: LocationFilterType;
  valueIn?: Maybe<Array<FilterValue>>;
};

export type LocationFilterInput = {
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  range?: InputMaybe<NumberFilterRangeInput>;
  stringRange?: InputMaybe<StringFilterRangeInput>;
  type: LocationFilterType;
  valueIn?: InputMaybe<Array<FilterValueInput>>;
};

export type LocationFilterIntersection = {
  __typename?: 'LocationFilterIntersection';
  allOf: Array<LocationFilter>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type LocationFilterIntersectionInput = {
  allOf: Array<LocationFilterInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export enum LocationFilterType {
  ACCESS_AISLE = 'ACCESS_AISLE',
  AISLE = 'AISLE',
  AREA = 'AREA',
  BAY = 'BAY',
  BAY_LOCATION_ORDER = 'BAY_LOCATION_ORDER',
  BAY_TYPE = 'BAY_TYPE',
  CONGESTION_ZONE = 'CONGESTION_ZONE',
  DEPTH_FROM_FRONT = 'DEPTH_FROM_FRONT',
  DEPTH_POSITION = 'DEPTH_POSITION',
  HEIGHT_FROM_FLOOR = 'HEIGHT_FROM_FLOOR',
  INDEX_FROM_FRONT = 'INDEX_FROM_FRONT',
  LEVEL = 'LEVEL',
  LOCATION = 'LOCATION',
  MHTYPE = 'MHTYPE',
  PLANE = 'PLANE',
  RACKING_TYPE = 'RACKING_TYPE',
  SIDE = 'SIDE',
  USAGE_TYPE = 'USAGE_TYPE',
}

export type LocationFilterUnion = {
  __typename?: 'LocationFilterUnion';
  anyOf: Array<LocationFilterIntersection>;
};

export type LocationFilterUnionInput = {
  anyOf: Array<LocationFilterIntersectionInput>;
};

export type LocationMaskSpecInput = {
  possibleLocationMask?: InputMaybe<Scalars['ID']['input']>;
  requiredLocationMask?: InputMaybe<Scalars['ID']['input']>;
};

export type LocationPlaneInfo = {
  __typename?: 'LocationPlaneInfo';
  id: Scalars['ID']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type LocationPortal = {
  __typename?: 'LocationPortal';
  aisle?: Maybe<LayoutFeature>;
  aisleId: Scalars['String']['output'];
  position: Point;
  type?: Maybe<LocationPortalType>;
};

export type LocationPortalSpec = {
  __typename?: 'LocationPortalSpec';
  aisleId: Scalars['ID']['output'];
  type?: Maybe<LocationPortalType>;
};

export type LocationPortalSpecInput = {
  aisleId: Scalars['ID']['input'];
  type?: InputMaybe<LocationPortalType>;
};

export enum LocationPortalType {
  DEFAULT = 'DEFAULT',
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
}

export type LocationReference = {
  __typename?: 'LocationReference';
  location?: Maybe<LayoutLocation>;
  locationId: Scalars['ID']['output'];
};

export type LocationSharingPolicy = {
  __typename?: 'LocationSharingPolicy';
  defaultMaxItemsPerLocation?: Maybe<Scalars['Float']['output']>;
  rules?: Maybe<Array<LocationSharingPolicyRule>>;
};

export type LocationSharingPolicyInput = {
  defaultMaxItemsPerLocation?: InputMaybe<Scalars['Float']['input']>;
  rules?: InputMaybe<Array<LocationSharingPolicyRuleInput>>;
};

export type LocationSharingPolicyRule = {
  __typename?: 'LocationSharingPolicyRule';
  id: Scalars['ID']['output'];
  locationsMatch?: Maybe<LocationFilterUnion>;
  maxItemsPerLocation: Scalars['Float']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type LocationSharingPolicyRuleInput = {
  id: Scalars['ID']['input'];
  locationsMatch?: InputMaybe<LocationFilterUnionInput>;
  maxItemsPerLocation: Scalars['Float']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type LocationSizeConstraintSettings = {
  __typename?: 'LocationSizeConstraintSettings';
  disabled?: Maybe<Scalars['Boolean']['output']>;
  unknownAsNoncompliant?: Maybe<Scalars['Boolean']['output']>;
};

export type LocationSizeConstraintSettingsInput = {
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  unknownAsNoncompliant?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LocationTypeModel = {
  __typename?: 'LocationTypeModel';
  gapDepth?: Maybe<Scalars['Float']['output']>;
  gapHeight?: Maybe<Scalars['Float']['output']>;
  gapWidth?: Maybe<Scalars['Float']['output']>;
  isWeakDepth?: Maybe<Scalars['Boolean']['output']>;
  locationRackingType?: Maybe<Scalars['String']['output']>;
  minDepth?: Maybe<Scalars['Float']['output']>;
};

export type LocationTypeModelInput = {
  gapDepth?: InputMaybe<Scalars['Float']['input']>;
  gapHeight?: InputMaybe<Scalars['Float']['input']>;
  gapWidth?: InputMaybe<Scalars['Float']['input']>;
  isWeakDepth?: InputMaybe<Scalars['Boolean']['input']>;
  locationRackingType?: InputMaybe<Scalars['String']['input']>;
  minDepth?: InputMaybe<Scalars['Float']['input']>;
};

export type LocationWeightComplianceSummary = {
  __typename?: 'LocationWeightComplianceSummary';
  items: Array<LocationWeightComplianceSummaryItem>;
};

export type LocationWeightComplianceSummaryItem = {
  __typename?: 'LocationWeightComplianceSummaryItem';
  compliantCount: Scalars['Float']['output'];
  floorLevelCount: Scalars['Float']['output'];
  level: Scalars['Float']['output'];
  nonCompliantCount: Scalars['Float']['output'];
  planeId: Scalars['ID']['output'];
  totalCount: Scalars['Float']['output'];
  unassignedCount: Scalars['Float']['output'];
  unknownCount: Scalars['Float']['output'];
};

export type MappingError = {
  __typename?: 'MappingError';
  code: Scalars['ID']['output'];
  count?: Maybe<Scalars['Float']['output']>;
  line?: Maybe<Scalars['Float']['output']>;
  lines?: Maybe<Array<Scalars['Int']['output']>>;
  mappedValue?: Maybe<Scalars['JSON']['output']>;
  message: Scalars['String']['output'];
  property?: Maybe<Scalars['ID']['output']>;
  type?: Maybe<Scalars['ID']['output']>;
  value?: Maybe<Scalars['String']['output']>;
  valueResolver?: Maybe<MappingValueResolverSpec>;
};

export type MappingFieldPreprocessingSpec = {
  __typename?: 'MappingFieldPreprocessingSpec';
  /** do not treat empty strings as NULL */
  allowEmptyStrings?: Maybe<Scalars['Boolean']['output']>;
  /** do not treat 'NULL' strings as NULL */
  allowNullStrings?: Maybe<Scalars['Boolean']['output']>;
  /** do not trim value before mapping */
  doNotTrim?: Maybe<Scalars['Boolean']['output']>;
};

export type MappingFieldPreprocessingSpecInput = {
  /** do not treat empty strings as NULL */
  allowEmptyStrings?: InputMaybe<Scalars['Boolean']['input']>;
  /** do not treat 'NULL' strings as NULL */
  allowNullStrings?: InputMaybe<Scalars['Boolean']['input']>;
  /** do not trim value before mapping */
  doNotTrim?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MappingFieldSpec = {
  __typename?: 'MappingFieldSpec';
  columnName?: Maybe<Scalars['ID']['output']>;
  index?: Maybe<Scalars['Int']['output']>;
  measureValue?: Maybe<Scalars['ID']['output']>;
  name: Scalars['ID']['output'];
  preprocessing?: Maybe<MappingFieldPreprocessingSpec>;
  valueResolver?: Maybe<MappingValueResolverSpec>;
};

export type MappingFieldSpecInput = {
  columnName?: InputMaybe<Scalars['ID']['input']>;
  index?: InputMaybe<Scalars['Int']['input']>;
  measureValue?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['ID']['input'];
  preprocessing?: InputMaybe<MappingFieldPreprocessingSpecInput>;
  valueResolver?: InputMaybe<MappingValueResolverSpecInput>;
};

export type MappingSpec = {
  __typename?: 'MappingSpec';
  fields: Array<MappingFieldSpec>;
};

export type MappingSpecInput = {
  fields: Array<MappingFieldSpecInput>;
};

export type MappingValueResolverSpec = {
  __typename?: 'MappingValueResolverSpec';
  values: Array<MappingValueResolverSpecItem>;
};

export type MappingValueResolverSpecInput = {
  values: Array<MappingValueResolverSpecItemInput>;
};

export type MappingValueResolverSpecItem = {
  __typename?: 'MappingValueResolverSpecItem';
  title: Scalars['String']['output'];
  value: Scalars['ID']['output'];
};

export type MappingValueResolverSpecItemInput = {
  title: Scalars['String']['input'];
  value: Scalars['ID']['input'];
};

export enum MeasurementSystem {
  IMPERIAL = 'IMPERIAL',
  METRIC = 'METRIC',
}

export type MinImprovementRateSettings = {
  __typename?: 'MinImprovementRateSettings';
  /** Stop optimization if improvement change for the given number of steps is below this limit */
  minImprovementChange: Scalars['Float']['output'];
  /** Window to measure improvement change, in steps */
  steps: Scalars['Float']['output'];
};

export type MinImprovementRateSettingsInput = {
  /** Stop optimization if improvement change for the given number of steps is below this limit */
  minImprovementChange: Scalars['Float']['input'];
  /** Window to measure improvement change, in steps */
  steps: Scalars['Float']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  analyzeActuality: AnalyzeActualityStatus;
  analyzeAllocationRun: AnalyzeResult;
  analyzePicklist: AnalyzePicklistResult;
  analyzeSimulation: AnalyzeSimulationPayload;
  batchStartImportJobProcessing: Array<ImportJob>;
  beginFileMultipartUpload: BeginFileMultipartUploadResult;
  beginFileUpload: Scalars['String']['output'];
  beginImportJobMultipartUpload: Array<Scalars['String']['output']>;
  beginImportPipelineMultipartUpload: BeginImportPipelineMultipartUploadResult;
  calculateSimulationAllocationRequirements: AllocationRequirementSet;
  calculateSimulationAssignmentCompliance: AssignmentCompliance;
  calculateSimulationAssignmentOccupancy: AssignmentOccupancy;
  calculateSimulationSizeCompliance: SizeCompliance;
  calculateSimulationWeightCompliance: WeightCompliance;
  changeDemoWarehouseSubscription: DemoWarehouse;
  completeFileMultipartUpload: StoredFileId;
  completeImportJobMultipartUpload: ImportJob;
  completeImportJobUpload: ImportJob;
  completeImportPipelineMultipartUpload: ImportPipeline;
  convertLayoutImport: Layout;
  copyLayout: Layout;
  createActuality: Actuality;
  createAllocationRun: AllocationRun;
  createAssignment: Assignment;
  createExportJob: ExportJob;
  createImportJob: ImportJob;
  createImportPipeline: ImportPipeline;
  createImportPipelineFromImportJob: ImportPipeline;
  createImportPipelineJob: ImportJob;
  createIntegrationUser: IntegrationUser;
  createItemSet: ItemSet;
  createLayoutImport: LayoutImport;
  /** Create optimization run for the given simulation and submit it for execution */
  createOptimizationRun: OptimizationRun;
  createOrderSet: OrderSet;
  createProcessingPipeline: ProcessingPipeline;
  createSimulation: Simulation;
  createWarehouse: Warehouse;
  deleteFile: Scalars['Boolean']['output'];
  deleteImportPipelineConnector: ImportPipeline;
  duplicateActuality: Actuality;
  duplicateSimulation: Simulation;
  exportAssignmentCompliance: ExportJob;
  exportAssignmentOccupancy: ExportJob;
  exportBayWeightCompliance: ExportJob;
  exportLayoutLocations: ExportJob;
  exportLocationWeightCompliance: ExportJob;
  exportOptimizationRunAssignmentDiff: ExportJob;
  exportSimulationAssignmentDetails: ExportJob;
  exportSizeCompliance: ExportJob;
  generateAssignmentIssueSet: AssignmentIssueSet;
  generateItemSetIssueSet: ItemSetIssueSet;
  generateLayoutIssueSet: LayoutIssueSet;
  generateOrderSetIssueSet: OrderSetIssueSet;
  generateSimulationItemSet: SimulationItemSet;
  generateSimulationOrderLineSet: SimulationOrderLineSet;
  generateSimulationOrderedQuantityReport: OrderedQuantityReport;
  generateSimulationPicklists: GeneratePicklistsJob;
  prepareImportJobPreviewTable: Array<Scalars['ID']['output']>;
  putImportPipelineConnector: ImportPipeline;
  removeActuality: Scalars['Boolean']['output'];
  removeAssignment: Scalars['Boolean']['output'];
  removeDatasetObject: Scalars['Boolean']['output'];
  removeImportJobs: Scalars['Int']['output'];
  removeImportJobsAsync: Scalars['Int']['output'];
  removeImportPipeline: Scalars['Boolean']['output'];
  removeIntegrationUser: Scalars['Boolean']['output'];
  removeItemSet: Scalars['Boolean']['output'];
  removeLayout: Scalars['Boolean']['output'];
  removeLayoutImport: Scalars['Boolean']['output'];
  removeOrderSet: Scalars['Boolean']['output'];
  removeProcessingPipeline: Scalars['Boolean']['output'];
  removeSimulation: Scalars['Boolean']['output'];
  removeWarehouse: Scalars['Boolean']['output'];
  restartAllocationRun: AllocationRun;
  saveLayoutImportConverterState: LayoutImport;
  scheduleGateUsage: GateUsageSchedule;
  sendExportJob: ExportJob;
  sendUserInvitationLink: Scalars['Boolean']['output'];
  startCheckOptimizationSwapPolicyJob: CheckOptimizationSwapPolicyJob;
  startImportJobProcessing: ImportJob;
  stopOptimizationRun: OptimizationRun;
  testImportPipelineSftpConnector: TestImportPipelineSftpConnectorResult;
  triggerProcessingPipeline?: Maybe<ProcessingPipelineJob>;
  updateActuality: Actuality;
  updateAssignment: Assignment;
  updateCurrentUserSettings: UserSettings;
  updateDatasetObject: DatasetObject;
  updateImportJob: ImportJob;
  updateImportPipeline: ImportPipeline;
  updateIntegrationUser: IntegrationUser;
  updateItemSet: ItemSet;
  updateLayout: Layout;
  updateLayoutImport: LayoutImport;
  updateOrderSet: OrderSet;
  updateProcessingPipeline: ProcessingPipeline;
  updateSimulation: Simulation;
  updateWarehouse: Warehouse;
  validateImportJobPreviewMapping: ImportJobErrorsConnection;
};

export type MutationAnalyzeActualityArgs = {
  input: AnalyzeActualityInputInput;
};

export type MutationAnalyzeAllocationRunArgs = {
  input: AnalyzeAllocationRunInput;
};

export type MutationAnalyzePicklistArgs = {
  input: AnalyzePicklistInput;
};

export type MutationAnalyzeSimulationArgs = {
  input: AnalyzeSimulationInput;
};

export type MutationBatchStartImportJobProcessingArgs = {
  input: BatchStartImportJobProcessingRequest;
};

export type MutationBeginFileMultipartUploadArgs = {
  input: BeginFileMultipartUploadInput;
};

export type MutationBeginFileUploadArgs = {
  id: StoredFileIdInput;
};

export type MutationBeginImportJobMultipartUploadArgs = {
  id: Scalars['String']['input'];
  parts: Scalars['Float']['input'];
};

export type MutationBeginImportPipelineMultipartUploadArgs = {
  input: BeginImportPipelineMultipartUploadInput;
};

export type MutationCalculateSimulationAllocationRequirementsArgs = {
  input: CalculateSimulationAllocationRequirementSetInput;
};

export type MutationCalculateSimulationAssignmentComplianceArgs = {
  options?: InputMaybe<CalculateSimulationAssignmentComplianceOptionsInput>;
  simulationId: Scalars['String']['input'];
};

export type MutationCalculateSimulationAssignmentOccupancyArgs = {
  options?: InputMaybe<CalculateSimulationAssignmentOccupancyOptionsInput>;
  simulationId: Scalars['String']['input'];
};

export type MutationCalculateSimulationSizeComplianceArgs = {
  options?: InputMaybe<CalculateSimulationSizeComplianceOptionsInput>;
  simulationId: Scalars['String']['input'];
};

export type MutationCalculateSimulationWeightComplianceArgs = {
  options?: InputMaybe<CalculateSimulationWeightComplianceOptionsInput>;
  simulationId: Scalars['String']['input'];
};

export type MutationChangeDemoWarehouseSubscriptionArgs = {
  input: ChangeDemoWarehouseSubscriptionInput;
};

export type MutationCompleteFileMultipartUploadArgs = {
  input: CompletedFileMultipartUploadInput;
};

export type MutationCompleteImportJobMultipartUploadArgs = {
  input: CompletedImportJobMultipartUploadInput;
};

export type MutationCompleteImportJobUploadArgs = {
  id: Scalars['String']['input'];
};

export type MutationCompleteImportPipelineMultipartUploadArgs = {
  input: CompletedImportPipelineMultipartUploadInput;
};

export type MutationConvertLayoutImportArgs = {
  input: ConvertLayoutImportInput;
};

export type MutationCopyLayoutArgs = {
  srcId: Scalars['ID']['input'];
};

export type MutationCreateActualityArgs = {
  input: CreateActualityInput;
};

export type MutationCreateAllocationRunArgs = {
  input: CreateAllocationRunInput;
};

export type MutationCreateAssignmentArgs = {
  input: CreateAssignmentInput;
};

export type MutationCreateExportJobArgs = {
  input: CreateExportJobInput;
};

export type MutationCreateImportJobArgs = {
  input: CreateImportJobInput;
};

export type MutationCreateImportPipelineArgs = {
  input: CreateImportPipelineInput;
};

export type MutationCreateImportPipelineFromImportJobArgs = {
  input: CreateImportPipelineFromImportJobInput;
};

export type MutationCreateImportPipelineJobArgs = {
  input: CreateImportPipelineJobInput;
};

export type MutationCreateIntegrationUserArgs = {
  input: CreateIntegrationUserInput;
};

export type MutationCreateItemSetArgs = {
  input: CreateItemSetInput;
};

export type MutationCreateLayoutImportArgs = {
  input: CreateLayoutImportInput;
  warehouseId: Scalars['ID']['input'];
};

export type MutationCreateOptimizationRunArgs = {
  input: CreateOptimizationRunInput;
};

export type MutationCreateOrderSetArgs = {
  input: CreateOrderSetInput;
};

export type MutationCreateProcessingPipelineArgs = {
  input: CreateProcessingPipelineInput;
};

export type MutationCreateSimulationArgs = {
  input: CreateSimulationInput;
};

export type MutationCreateWarehouseArgs = {
  input: CreateWarehouseInput;
};

export type MutationDeleteFileArgs = {
  id: StoredFileIdInput;
};

export type MutationDeleteImportPipelineConnectorArgs = {
  forceUpdateIntegration?: InputMaybe<Scalars['Boolean']['input']>;
  input: DeleteImportPipelineConnectorInput;
};

export type MutationDuplicateActualityArgs = {
  input: DuplicateActualityInput;
};

export type MutationDuplicateSimulationArgs = {
  input: DuplicationSimulationInput;
};

export type MutationExportAssignmentComplianceArgs = {
  assignmentComplianceId: Scalars['String']['input'];
  csvOptions?: InputMaybe<CsvExportOptionsInput>;
  filter?: InputMaybe<AssignmentComplianceItemFilter>;
};

export type MutationExportAssignmentOccupancyArgs = {
  assignmentOccupancyId: Scalars['String']['input'];
  csvOptions?: InputMaybe<CsvExportOptionsInput>;
  filter?: InputMaybe<AssignmentOccupancyLocationFilter>;
};

export type MutationExportBayWeightComplianceArgs = {
  csvOptions?: InputMaybe<CsvExportOptionsInput>;
  filter?: InputMaybe<WeightComplianceBayFilter>;
  weightComplianceId: Scalars['String']['input'];
};

export type MutationExportLayoutLocationsArgs = {
  csvOptions?: InputMaybe<CsvExportOptionsInput>;
  id: Scalars['String']['input'];
};

export type MutationExportLocationWeightComplianceArgs = {
  csvOptions?: InputMaybe<CsvExportOptionsInput>;
  filter?: InputMaybe<WeightComplianceLocationFilter>;
  weightComplianceId: Scalars['String']['input'];
};

export type MutationExportOptimizationRunAssignmentDiffArgs = {
  csvOptions?: InputMaybe<CsvExportOptionsInput>;
  cumulativePercentRankCategories?: InputMaybe<CategoryMappingRules>;
  filter?: InputMaybe<AssignmentDiffItemsFilter>;
  id: Scalars['String']['input'];
  sortBy?: InputMaybe<Array<AssignmentDiffItemsSortBy>>;
};

export type MutationExportSimulationAssignmentDetailsArgs = {
  csvOptions?: InputMaybe<CsvExportOptionsInput>;
  cumulativePercentRankCategories?: InputMaybe<CategoryMappingRules>;
  options?: InputMaybe<SimulationAssignmentDetailsOptions>;
  simulationId: Scalars['String']['input'];
  sortBy?: Array<AssignmentDetailsItemsSortBy>;
};

export type MutationExportSizeComplianceArgs = {
  csvOptions?: InputMaybe<CsvExportOptionsInput>;
  filter?: InputMaybe<SizeComplianceLocationFilter>;
  sizeComplianceId: Scalars['String']['input'];
};

export type MutationGenerateAssignmentIssueSetArgs = {
  key: AssignmentIssueSetKey;
};

export type MutationGenerateItemSetIssueSetArgs = {
  key: ItemSetIssueSetKey;
};

export type MutationGenerateLayoutIssueSetArgs = {
  key: LayoutIssueSetKey;
};

export type MutationGenerateOrderSetIssueSetArgs = {
  key: OrderSetIssueSetKey;
};

export type MutationGenerateSimulationItemSetArgs = {
  simulationId: Scalars['String']['input'];
};

export type MutationGenerateSimulationOrderLineSetArgs = {
  simulationId: Scalars['String']['input'];
};

export type MutationGenerateSimulationOrderedQuantityReportArgs = {
  options?: InputMaybe<GenerateSimulationOrderedQuantityReportOptionsInput>;
  simulationId: Scalars['String']['input'];
};

export type MutationGenerateSimulationPicklistsArgs = {
  input: GenerateSimulationPicklistsInput;
};

export type MutationPrepareImportJobPreviewTableArgs = {
  id: Scalars['String']['input'];
  options?: InputMaybe<PrepareImportJobPreviewTableOptions>;
};

export type MutationPutImportPipelineConnectorArgs = {
  forceUpdateIntegration?: InputMaybe<Scalars['Boolean']['input']>;
  input: PutImportPipelineConnectorInput;
};

export type MutationRemoveActualityArgs = {
  actualityId: Scalars['String']['input'];
};

export type MutationRemoveAssignmentArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRemoveDatasetObjectArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRemoveImportJobsArgs = {
  filter?: InputMaybe<ImportJobsFilter>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  options?: InputMaybe<RemoveImportJobOptions>;
};

export type MutationRemoveImportJobsAsyncArgs = {
  filter?: InputMaybe<ImportJobsFilter>;
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  options?: InputMaybe<RemoveImportJobOptions>;
};

export type MutationRemoveImportPipelineArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveIntegrationUserArgs = {
  input: RemoveIntegrationUserInput;
};

export type MutationRemoveItemSetArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRemoveLayoutArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRemoveLayoutImportArgs = {
  layoutImportId: Scalars['ID']['input'];
};

export type MutationRemoveOrderSetArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRemoveProcessingPipelineArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemoveSimulationArgs = {
  simulationId: Scalars['String']['input'];
};

export type MutationRemoveWarehouseArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRestartAllocationRunArgs = {
  input: RestartAllocationRunInput;
};

export type MutationSaveLayoutImportConverterStateArgs = {
  input: SaveLayoutImportConverterStateInput;
};

export type MutationScheduleGateUsageArgs = {
  input: ScheduleGateUsageInput;
};

export type MutationSendExportJobArgs = {
  input: SendExportJobInput;
};

export type MutationSendUserInvitationLinkArgs = {
  input: SendUserInvitationLinkInput;
};

export type MutationStartCheckOptimizationSwapPolicyJobArgs = {
  input: CheckOptimizationSwapPolicyInput;
};

export type MutationStartImportJobProcessingArgs = {
  id: Scalars['String']['input'];
  options?: InputMaybe<StartImportJobProcessingOptions>;
};

export type MutationStopOptimizationRunArgs = {
  id: Scalars['String']['input'];
};

export type MutationTestImportPipelineSftpConnectorArgs = {
  sftp: TestImportPipelineSftpConnectorInput;
};

export type MutationTriggerProcessingPipelineArgs = {
  input: TriggerProcessingPipelineInput;
};

export type MutationUpdateActualityArgs = {
  input: UpdateActualityInput;
};

export type MutationUpdateAssignmentArgs = {
  input: UpdateAssignmentInput;
};

export type MutationUpdateCurrentUserSettingsArgs = {
  input: UpdateUserSettingsInput;
};

export type MutationUpdateDatasetObjectArgs = {
  input: UpdateDatasetObjectInput;
};

export type MutationUpdateImportJobArgs = {
  input: UpdateImportJobInput;
};

export type MutationUpdateImportPipelineArgs = {
  forceUpdateIntegration?: InputMaybe<Scalars['Boolean']['input']>;
  input: UpdateImportPipelineInput;
};

export type MutationUpdateIntegrationUserArgs = {
  input: UpdateIntegrationUserInput;
};

export type MutationUpdateItemSetArgs = {
  input: UpdateItemSetInput;
};

export type MutationUpdateLayoutArgs = {
  input: UpdateLayoutInput;
};

export type MutationUpdateLayoutImportArgs = {
  input: UpdateLayoutImportInput;
};

export type MutationUpdateOrderSetArgs = {
  input: UpdateOrderSetInput;
};

export type MutationUpdateProcessingPipelineArgs = {
  input: UpdateProcessingPipelineInput;
};

export type MutationUpdateSimulationArgs = {
  input: UpdateSimulationInput;
};

export type MutationUpdateWarehouseArgs = {
  input: UpdateWarehouseInput;
};

export type MutationValidateImportJobPreviewMappingArgs = {
  id: Scalars['String']['input'];
  mapping?: InputMaybe<MappingSpecInput>;
  options?: InputMaybe<ImportJobPreviewQueryOptions>;
  page?: InputMaybe<SkipLimitPageSpec>;
  transformation?: InputMaybe<ImportJobTransformationSettingsInput>;
};

export type NumberFilterRange = {
  __typename?: 'NumberFilterRange';
  excludeFrom?: Maybe<Scalars['Boolean']['output']>;
  excludeTo?: Maybe<Scalars['Boolean']['output']>;
  from?: Maybe<Scalars['Float']['output']>;
  to?: Maybe<Scalars['Float']['output']>;
};

export type NumberFilterRangeInput = {
  excludeFrom?: InputMaybe<Scalars['Boolean']['input']>;
  excludeTo?: InputMaybe<Scalars['Boolean']['input']>;
  from?: InputMaybe<Scalars['Float']['input']>;
  to?: InputMaybe<Scalars['Float']['input']>;
};

export type NumberSearchFilter = {
  type?: NumberSearchFilterType;
  value: Scalars['Float']['input'];
};

export enum NumberSearchFilterType {
  EQ = 'EQ',
  GE = 'GE',
  GT = 'GT',
  LE = 'LE',
  LT = 'LT',
}

export type OptimizationImprovementSummary = {
  __typename?: 'OptimizationImprovementSummary';
  effectiveImprovement: Scalars['Float']['output'];
  initialEffectiveCost: Scalars['Float']['output'];
  objectives: Array<OptimizationObjectiveImprovementSummary>;
  optimizedEffectiveCost: Scalars['Float']['output'];
};

export type OptimizationObjectiveImprovementSummary = {
  __typename?: 'OptimizationObjectiveImprovementSummary';
  id?: Maybe<Scalars['ID']['output']>;
  improvement: Scalars['Float']['output'];
  initialCost: Scalars['Float']['output'];
  objectiveWeight: Scalars['Float']['output'];
  optimizedCost: Scalars['Float']['output'];
  type: OptimizationObjectiveType;
};

export enum OptimizationObjectiveType {
  COST = 'COST',
  LOAD_BALANCING = 'LOAD_BALANCING',
}

export type OptimizationPolicyOverrides = {
  __typename?: 'OptimizationPolicyOverrides';
  resourcePolicy?: Maybe<ResourcePolicy>;
};

export type OptimizationPolicyOverridesInput = {
  resourcePolicy?: InputMaybe<ResourcePolicyInput>;
};

/** Single optimization run with particular data set and parameters */
export type OptimizationRun = {
  __typename?: 'OptimizationRun';
  /** Analysis of assignment produced by this optimization run */
  analyzeResult?: Maybe<AnalyzeResult>;
  assignment: Assignment;
  assignmentChange?: Maybe<AssignmentChange>;
  assignmentChangeSequence?: Maybe<AssignmentChangeSequence>;
  assignmentDiff?: Maybe<AssignmentDiff>;
  assignmentPolicy?: Maybe<AssignmentPolicy>;
  completedAt?: Maybe<Scalars['Timestamp']['output']>;
  createdAt: Scalars['Timestamp']['output'];
  elapsedRunningTime: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  /** Total improvement */
  improvement?: Maybe<Scalars['Float']['output']>;
  /** Cost before optimization */
  initialCost?: Maybe<Scalars['Float']['output']>;
  itemSet: ItemSet;
  latestProgressDetails?: Maybe<OptimizationRunProgress>;
  layout: Layout;
  locationSharingPolicy?: Maybe<LocationSharingPolicy>;
  optimizationSettings?: Maybe<OptimizationRunSettings>;
  optimizationSummary?: Maybe<OptimizationSummary>;
  /** Optimize phase improvement */
  optimizeImprovement?: Maybe<Scalars['Float']['output']>;
  /** Cost after optimize phase */
  optimizedCost?: Maybe<Scalars['Float']['output']>;
  orderSet: OrderSet;
  pickingPolicy?: Maybe<PickingPolicy>;
  /** Current optimization progress */
  progress?: Maybe<Scalars['Float']['output']>;
  progressDetails: Array<OptimizationRunProgress>;
  /** Reassign phase improvement */
  reassignImprovement?: Maybe<Scalars['Float']['output']>;
  /** Cost after reassign phase */
  reassignedCost?: Maybe<Scalars['Float']['output']>;
  resourcePolicy?: Maybe<ResourcePolicy>;
  /** Resulting assignment */
  resultAssignment?: Maybe<Assignment>;
  resultAssignmentId?: Maybe<Scalars['ID']['output']>;
  routingPolicy?: Maybe<RoutingPolicy>;
  seed?: Maybe<Scalars['Float']['output']>;
  simulation: Simulation;
  stackingPolicy?: Maybe<StackingPolicy>;
  startedAt?: Maybe<Scalars['Timestamp']['output']>;
  /** Current status of optimization run */
  status: OptimizationRunStatus;
  steps?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['Timestamp']['output'];
  warehouse: Warehouse;
  waypointPolicy?: Maybe<WaypointPolicy>;
};

export type OptimizationRunProgress = {
  __typename?: 'OptimizationRunProgress';
  completedAt: Scalars['Timestamp']['output'];
  currentCost: Scalars['Float']['output'];
  currentMoveCount?: Maybe<Scalars['Float']['output']>;
  currentOptimizeImprovement?: Maybe<Scalars['Float']['output']>;
  currentTotalImprovement?: Maybe<Scalars['Float']['output']>;
  details?: Maybe<OptimizationRunProgressDetails>;
  elapsedComputeTime: Scalars['Float']['output'];
  progress: Scalars['Float']['output'];
  remainingComputeTime?: Maybe<Scalars['Float']['output']>;
  startedAt: Scalars['Timestamp']['output'];
  step: Scalars['Float']['output'];
};

export type OptimizationRunProgressDetails = {
  __typename?: 'OptimizationRunProgressDetails';
  improvement?: Maybe<OptimizationImprovementSummary>;
};

export type OptimizationRunSettings = {
  __typename?: 'OptimizationRunSettings';
  assignmentCapacitySettings?: Maybe<AssignmentCapacitySettings>;
  bayWidthConstraint?: Maybe<BayWidthConstraintSettings>;
  initialAssignmentChange?: Maybe<AssignmentChange>;
  limitSettings?: Maybe<LimitSettings>;
  loadBalancing?: Maybe<LoadBalancingPolicy>;
  locationSizeConstraint?: Maybe<LocationSizeConstraintSettings>;
  orderSetFilter?: Maybe<OrderSetFilter>;
  pickabilityConstraint?: Maybe<PickabilityConstraintSettings>;
  policyOverrides?: Maybe<OptimizationPolicyOverrides>;
  reassignJobs?: Maybe<ReassignJobsSettings>;
  source?: Maybe<OptimizationRunSourceReference>;
  swapSettings?: Maybe<SwapSettings>;
  terminationSettings?: Maybe<TerminationSettings>;
};

export enum OptimizationRunSortOption {
  CREATED_AT = 'CREATED_AT',
  UPDATED_AT = 'UPDATED_AT',
}

export type OptimizationRunSourceReference = {
  __typename?: 'OptimizationRunSourceReference';
  id: Scalars['ID']['output'];
  type: OptimizationRunSourceType;
};

export type OptimizationRunSourceReferenceInput = {
  id: Scalars['ID']['input'];
  type: OptimizationRunSourceType;
};

export enum OptimizationRunSourceType {
  ALLOCATION_RUN = 'ALLOCATION_RUN',
  SIMULATION = 'SIMULATION',
}

export enum OptimizationRunStatus {
  COMPLETED = 'COMPLETED',
  CREATED = 'CREATED',
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
  STOPPING = 'STOPPING',
}

export type OptimizationRunsConnection = {
  __typename?: 'OptimizationRunsConnection';
  /** Content of collection or the current page of collection if pagination is used */
  content: Array<OptimizationRun>;
  /** Total number of elements in this collection (ignoring pagination constraints, if any) */
  totalCount: Scalars['Float']['output'];
};

export type OptimizationRunsSortBy = {
  /** Sorting direction */
  direction?: SortDirection;
  /** Field (sort option) to sort by */
  field: OptimizationRunSortOption;
};

export type OptimizationSettings = {
  __typename?: 'OptimizationSettings';
  assignmentCapacitySettings?: Maybe<AssignmentCapacitySettings>;
  bayWidthConstraint?: Maybe<BayWidthConstraintSettings>;
  limitSettings?: Maybe<LimitSettings>;
  loadBalancing?: Maybe<LoadBalancingPolicy>;
  locationSizeConstraint?: Maybe<LocationSizeConstraintSettings>;
  orderSetFilter?: Maybe<OrderSetFilter>;
  pickabilityConstraint?: Maybe<PickabilityConstraintSettings>;
  policyOverrides?: Maybe<OptimizationPolicyOverrides>;
  reassignJobs?: Maybe<ReassignJobsSettings>;
  swapSettings?: Maybe<SwapSettings>;
  terminationSettings?: Maybe<TerminationSettings>;
};

export type OptimizationSettingsInput = {
  assignmentCapacitySettings?: InputMaybe<AssignmentCapacitySettingsInput>;
  bayWidthConstraint?: InputMaybe<BayWidthConstraintSettingsInput>;
  limitSettings?: InputMaybe<LimitSettingsInput>;
  loadBalancing?: InputMaybe<LoadBalancingPolicyInput>;
  locationSizeConstraint?: InputMaybe<LocationSizeConstraintSettingsInput>;
  orderSetFilter?: InputMaybe<OrderSetFilterInput>;
  pickabilityConstraint?: InputMaybe<PickabilityConstraintSettingsInput>;
  policyOverrides?: InputMaybe<OptimizationPolicyOverridesInput>;
  reassignJobs?: InputMaybe<ReassignJobsSettingsInput>;
  swapSettings?: InputMaybe<SwapSettingsInput>;
  terminationSettings?: InputMaybe<TerminationSettingsInput>;
};

export enum OptimizationStopReason {
  MIN_IMPROVEMENT_RATE = 'MIN_IMPROVEMENT_RATE',
  STEP_LIMIT = 'STEP_LIMIT',
  TARGET_IMPROVEMENT = 'TARGET_IMPROVEMENT',
  TIME_LIMIT = 'TIME_LIMIT',
  USER_REQUEST = 'USER_REQUEST',
}

export type OptimizationSummary = {
  __typename?: 'OptimizationSummary';
  constraints: Array<ConstraintSummary>;
  effectiveSteps?: Maybe<Scalars['Float']['output']>;
  improvement?: Maybe<OptimizationImprovementSummary>;
  moveCount?: Maybe<Scalars['Float']['output']>;
  stopReason?: Maybe<OptimizationStopReason>;
  totalComputeTime?: Maybe<Scalars['Float']['output']>;
};

export type OptimizationSwapRule = {
  __typename?: 'OptimizationSwapRule';
  dest?: Maybe<AssignmentSubsetSpec>;
  id?: Maybe<Scalars['ID']['output']>;
  src?: Maybe<AssignmentSubsetSpec>;
  title?: Maybe<Scalars['String']['output']>;
};

export type OptimizationSwapRuleInput = {
  dest?: InputMaybe<AssignmentSubsetSpecInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  src?: InputMaybe<AssignmentSubsetSpecInput>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export enum OptimizeJobEngine {
  FARGATE = 'FARGATE',
  LAMBDA = 'LAMBDA',
  MPI = 'MPI',
}

export type OrderFilter = {
  orderId?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type OrderHeader = {
  __typename?: 'OrderHeader';
  consignee: Scalars['ID']['output'];
  customer?: Maybe<Scalars['ID']['output']>;
  deliveryRoute?: Maybe<Scalars['ID']['output']>;
  details: Array<OrderLine>;
  dock?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  importJobId?: Maybe<Scalars['ID']['output']>;
  loadByDate?: Maybe<Scalars['LocalDateTime']['output']>;
  orderDate?: Maybe<Scalars['LocalDateTime']['output']>;
  orderId: Scalars['ID']['output'];
  shipmentId?: Maybe<Scalars['ID']['output']>;
  stagingArea?: Maybe<Scalars['ID']['output']>;
  waveId?: Maybe<Scalars['ID']['output']>;
};

export type OrderLine = {
  __typename?: 'OrderLine';
  consignee: Scalars['ID']['output'];
  importJobId?: Maybe<Scalars['ID']['output']>;
  itemDetails?: Maybe<OrderSetItem>;
  itemId: Scalars['ID']['output'];
  orderHeader: OrderHeader;
  orderId: Scalars['ID']['output'];
  orderLine: Scalars['Float']['output'];
  pickingContainer?: Maybe<Scalars['ID']['output']>;
  pickingPriority?: Maybe<Scalars['ID']['output']>;
  quantity: Scalars['Float']['output'];
  sku: Scalars['ID']['output'];
};

export type OrderLineFilter = {
  __typename?: 'OrderLineFilter';
  isNot?: Maybe<Scalars['Boolean']['output']>;
  isNull?: Maybe<Scalars['Boolean']['output']>;
  range?: Maybe<NumberFilterRange>;
  stringRange?: Maybe<StringFilterRange>;
  type: OrderLineFilterType;
  valueIn?: Maybe<Array<FilterValue>>;
};

export type OrderLineFilterInput = {
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  range?: InputMaybe<NumberFilterRangeInput>;
  stringRange?: InputMaybe<StringFilterRangeInput>;
  type: OrderLineFilterType;
  valueIn?: InputMaybe<Array<FilterValueInput>>;
};

export type OrderLineFilterIntersection = {
  __typename?: 'OrderLineFilterIntersection';
  allOf: Array<OrderLineFilter>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type OrderLineFilterIntersectionInput = {
  allOf: Array<OrderLineFilterInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export enum OrderLineFilterType {
  CONSIGNEE = 'CONSIGNEE',
  CUMULATIVE_PERCENT_RANK = 'CUMULATIVE_PERCENT_RANK',
  CUSTOMER = 'CUSTOMER',
  DELIVERY_ROUTE = 'DELIVERY_ROUTE',
  DOCK = 'DOCK',
  HEIGHT = 'HEIGHT',
  LENGTH = 'LENGTH',
  NET_WEIGHT = 'NET_WEIGHT',
  ORDER_LINE_COUNT = 'ORDER_LINE_COUNT',
  ORDER_LINE_QUANTITY = 'ORDER_LINE_QUANTITY',
  ORDER_LINE_VOLUME = 'ORDER_LINE_VOLUME',
  ORDER_LINE_WEIGHT = 'ORDER_LINE_WEIGHT',
  ORDER_TIME_MATCH = 'ORDER_TIME_MATCH',
  ORDER_TOTAL_VOLUME = 'ORDER_TOTAL_VOLUME',
  ORDER_TOTAL_WEIGHT = 'ORDER_TOTAL_WEIGHT',
  PERCENT_RANK = 'PERCENT_RANK',
  POLLUTION_CLASS = 'POLLUTION_CLASS',
  SHIPMENT_ID = 'SHIPMENT_ID',
  SKU = 'SKU',
  SKU_GROUP = 'SKU_GROUP',
  STAGING_AREA = 'STAGING_AREA',
  STOCK_CATEGORY = 'STOCK_CATEGORY',
  STORAGE_CLASS = 'STORAGE_CLASS',
  SUB_GROUP = 'SUB_GROUP',
  TRANSPORT_CLASS = 'TRANSPORT_CLASS',
  VOLUME = 'VOLUME',
  WAVE_ID = 'WAVE_ID',
  WIDTH = 'WIDTH',
}

export type OrderLineFilterUnion = {
  __typename?: 'OrderLineFilterUnion';
  anyOf: Array<OrderLineFilterIntersection>;
};

export type OrderLineFilterUnionInput = {
  anyOf: Array<OrderLineFilterIntersectionInput>;
};

export type OrderLineSearchFilter = {
  consignee?: InputMaybe<StringSearchFilter>;
  customer?: InputMaybe<StringSearchFilter>;
  deliveryRoute?: InputMaybe<StringSearchFilter>;
  dock?: InputMaybe<StringSearchFilter>;
  loadByDate?: InputMaybe<LocalDateTimeSearchFilter>;
  orderDate?: InputMaybe<LocalDateTimeSearchFilter>;
  orderId?: InputMaybe<StringSearchFilter>;
  orderLine?: InputMaybe<NumberSearchFilter>;
  quantity?: InputMaybe<NumberSearchFilter>;
  shipmentId?: InputMaybe<StringSearchFilter>;
  sku?: InputMaybe<StringSearchFilter>;
  stagingArea?: InputMaybe<StringSearchFilter>;
  waveId?: InputMaybe<StringSearchFilter>;
};

export type OrderLinesConnection = {
  __typename?: 'OrderLinesConnection';
  /** Content of collection or the current page of collection if pagination is used */
  content: Array<OrderLine>;
  /** Total number of elements in this collection (ignoring pagination constraints, if any) */
  totalCount: Scalars['Float']['output'];
};

export type OrderLinesSortBy = {
  /** Sorting direction */
  direction?: SortDirection;
  /** Field (sort option) to sort by */
  field: OrderLinesSortOptions;
};

export enum OrderLinesSortOptions {
  CONSIGNEE = 'CONSIGNEE',
  ORDER_ID = 'ORDER_ID',
  ORDER_KEY = 'ORDER_KEY',
  ORDER_LINE = 'ORDER_LINE',
}

export type OrderSet = {
  __typename?: 'OrderSet';
  actualitiesWith: ActualitiesConnection;
  createdAt: Scalars['Timestamp']['output'];
  description?: Maybe<Scalars['String']['output']>;
  extraSettings?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['ID']['output'];
  importedAt?: Maybe<Scalars['Timestamp']['output']>;
  inputFileName?: Maybe<Scalars['String']['output']>;
  itemsByCategory?: Maybe<OrderSetItemsByCategoryConnection>;
  orderLines: OrderLinesConnection;
  orders: OrdersConnection;
  presentationSettings?: Maybe<PresentationSettings>;
  simulationsWith: SimulationsConnection;
  status: OrderSetImportStatus;
  summary?: Maybe<OrderSetSummary>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['Timestamp']['output'];
  userTags?: Maybe<Array<Scalars['String']['output']>>;
  warehouse: Warehouse;
};

export type OrderSetActualitiesWithArgs = {
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<ActualitiesSortBy>>;
};

export type OrderSetItemsByCategoryArgs = {
  categories: OrderSetItemsByCategoryRequest;
};

export type OrderSetOrderLinesArgs = {
  filter?: InputMaybe<OrderLineSearchFilter>;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<OrderLinesSortBy>>;
};

export type OrderSetOrdersArgs = {
  filter?: InputMaybe<OrderFilter>;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<OrdersSortBy>>;
};

export type OrderSetSimulationsWithArgs = {
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<SimulationsSortBy>>;
};

export type OrderSetConsigneeSummary = {
  __typename?: 'OrderSetConsigneeSummary';
  consignee: Scalars['ID']['output'];
  orderCount: Scalars['Float']['output'];
  orderLineCount: Scalars['Float']['output'];
  productCount: Scalars['Float']['output'];
};

export type OrderSetFilter = {
  __typename?: 'OrderSetFilter';
  orderDate?: Maybe<LocalDateRangeFilter>;
};

export type OrderSetFilterInput = {
  orderDate?: InputMaybe<LocalDateRangeFilterInput>;
};

export enum OrderSetImportStatus {
  IMPORTED = 'IMPORTED',
  IMPORTING = 'IMPORTING',
  POSTPROCESSING = 'POSTPROCESSING',
}

export type OrderSetIssueSet = {
  __typename?: 'OrderSetIssueSet';
  assignmentId: Scalars['ID']['output'];
  completedAt?: Maybe<Scalars['Timestamp']['output']>;
  createdAt: Scalars['Timestamp']['output'];
  id: Scalars['ID']['output'];
  itemSetId: Scalars['ID']['output'];
  layoutId: Scalars['ID']['output'];
  orderSetId: Scalars['ID']['output'];
  progress: Scalars['Float']['output'];
  startedAt?: Maybe<Scalars['Timestamp']['output']>;
  status: BatchJobStatus;
  summary?: Maybe<OrderSetIssueSummary>;
  unpickableItems: OrderSetIssueUnpickableItemsConnection;
  unpickableLines: OrderSetIssueUnpickableLinesConnection;
  unusedLocations: OrderSetIssueUnusedLocationsConnection;
  updatedAt: Scalars['Timestamp']['output'];
};

export type OrderSetIssueSetUnpickableItemsArgs = {
  filter?: InputMaybe<OrderSetIssueUnpickableItemsFilter>;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<ItemsSortBy>>;
};

export type OrderSetIssueSetUnpickableLinesArgs = {
  filter?: InputMaybe<OrderSetIssueUnpickableLinesFilter>;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<OrderLinesSortBy>>;
};

export type OrderSetIssueSetUnusedLocationsArgs = {
  filter?: InputMaybe<LayoutLocationIssueFilter>;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<LayoutLocationsSortBy>>;
};

export type OrderSetIssueSetKey = {
  assignmentId: Scalars['ID']['input'];
  itemSetId: Scalars['ID']['input'];
  layoutId: Scalars['ID']['input'];
  orderSetId: Scalars['ID']['input'];
};

export type OrderSetIssueSummary = {
  __typename?: 'OrderSetIssueSummary';
  totalItems?: Maybe<Scalars['Float']['output']>;
  totalLocations: Scalars['Float']['output'];
  totalOrderLines: Scalars['Float']['output'];
  totalOrderedItems?: Maybe<Scalars['Float']['output']>;
  totalUnpickableItems?: Maybe<Scalars['Float']['output']>;
  totalUnpickableLines: Scalars['Float']['output'];
  totalUnusedLocations: Scalars['Float']['output'];
  unpickableItems?: Maybe<Array<OrderSetIssueUnpickableItemsSummary>>;
  unpickableLines: Array<OrderSetIssueUnpickableLinesSummary>;
  unusedLocations: Array<OrderSetIssueUnusedLocationsSummary>;
};

export type OrderSetIssueUnpickableItem = {
  __typename?: 'OrderSetIssueUnpickableItem';
  consignee: Scalars['ID']['output'];
  itemId: Scalars['ID']['output'];
  orderLineCount: Scalars['Int']['output'];
  reason: Array<OrderSetIssueUnpickableLineReason>;
  sku: Scalars['ID']['output'];
};

export type OrderSetIssueUnpickableItemsConnection = {
  __typename?: 'OrderSetIssueUnpickableItemsConnection';
  /** Content of collection or the current page of collection if pagination is used */
  content: Array<OrderSetIssueUnpickableItem>;
  /** Total number of elements in this collection (ignoring pagination constraints, if any) */
  totalCount: Scalars['Float']['output'];
};

export type OrderSetIssueUnpickableItemsFilter = {
  consignee?: InputMaybe<Array<Scalars['ID']['input']>>;
  reason?: InputMaybe<Array<OrderSetIssueUnpickableLineReason>>;
};

export type OrderSetIssueUnpickableItemsSummary = {
  __typename?: 'OrderSetIssueUnpickableItemsSummary';
  count: Scalars['Float']['output'];
  reason: OrderSetIssueUnpickableLineReason;
};

export type OrderSetIssueUnpickableLine = {
  __typename?: 'OrderSetIssueUnpickableLine';
  consignee: Scalars['ID']['output'];
  itemId: Scalars['ID']['output'];
  locationIds?: Maybe<Array<Scalars['String']['output']>>;
  orderId: Scalars['ID']['output'];
  orderKey: Scalars['ID']['output'];
  orderLine: Scalars['Float']['output'];
  reason: Array<OrderSetIssueUnpickableLineReason>;
  sku: Scalars['ID']['output'];
};

export enum OrderSetIssueUnpickableLineReason {
  DISABLED_LOCATIONS = 'DISABLED_LOCATIONS',
  MISSING_UOMS = 'MISSING_UOMS',
  UNASSIGNED_ITEM = 'UNASSIGNED_ITEM',
  UNKNOWN_ITEM = 'UNKNOWN_ITEM',
  UNKNOWN_LOCATIONS = 'UNKNOWN_LOCATIONS',
}

export type OrderSetIssueUnpickableLinesConnection = {
  __typename?: 'OrderSetIssueUnpickableLinesConnection';
  /** Content of collection or the current page of collection if pagination is used */
  content: Array<OrderSetIssueUnpickableLine>;
  /** Total number of elements in this collection (ignoring pagination constraints, if any) */
  totalCount: Scalars['Float']['output'];
};

export type OrderSetIssueUnpickableLinesFilter = {
  reason?: InputMaybe<Array<OrderSetIssueUnpickableLineReason>>;
};

export type OrderSetIssueUnpickableLinesSummary = {
  __typename?: 'OrderSetIssueUnpickableLinesSummary';
  count: Scalars['Float']['output'];
  reason: OrderSetIssueUnpickableLineReason;
};

export type OrderSetIssueUnusedLocation = {
  __typename?: 'OrderSetIssueUnusedLocation';
  locationBayId: Scalars['ID']['output'];
  locationId: Scalars['ID']['output'];
  locationLevel: Scalars['Float']['output'];
  planeId: Scalars['ID']['output'];
};

export type OrderSetIssueUnusedLocationsConnection = {
  __typename?: 'OrderSetIssueUnusedLocationsConnection';
  /** Content of collection or the current page of collection if pagination is used */
  content: Array<OrderSetIssueUnusedLocation>;
  /** Total number of elements in this collection (ignoring pagination constraints, if any) */
  totalCount: Scalars['Float']['output'];
};

export type OrderSetIssueUnusedLocationsSummary = {
  __typename?: 'OrderSetIssueUnusedLocationsSummary';
  count: Scalars['Float']['output'];
  locationLevel?: Maybe<Scalars['Float']['output']>;
  planeId?: Maybe<Scalars['ID']['output']>;
};

export type OrderSetItem = {
  __typename?: 'OrderSetItem';
  consignee: Scalars['ID']['output'];
  cumulativePercentRank?: Maybe<Scalars['Float']['output']>;
  details?: Maybe<OrderSetItemDetails>;
  itemId: Scalars['ID']['output'];
  orderLineCount: Scalars['Float']['output'];
  percentRank?: Maybe<Scalars['Float']['output']>;
  quantityOrdered: Scalars['Float']['output'];
  sku: Scalars['ID']['output'];
};

export type OrderSetItemDailyStats = {
  __typename?: 'OrderSetItemDailyStats';
  orderLineCount: ValueStats;
  totalDaysWithOrders: Scalars['Float']['output'];
};

export type OrderSetItemDetails = {
  __typename?: 'OrderSetItemDetails';
  dailyStats?: Maybe<OrderSetItemDailyStats>;
  weeklyStats?: Maybe<OrderSetItemWeeklyStats>;
};

export type OrderSetItemWeeklyStats = {
  __typename?: 'OrderSetItemWeeklyStats';
  daysWithOrders: ValueStats;
  orderLineCount: ValueStats;
  totalWeeksWithOrders: Scalars['Float']['output'];
};

export type OrderSetItemsByCategoryConnection = {
  __typename?: 'OrderSetItemsByCategoryConnection';
  categories: Array<OrderSetItemsCategory>;
  totalCount: Scalars['Float']['output'];
  totalOrderLineCount: Scalars['Float']['output'];
};

export type OrderSetItemsByCategoryRequest = {
  categoryType?: OrderSetItemsCategoryType;
  categoryWidths: Array<Scalars['Float']['input']>;
};

export type OrderSetItemsCategory = {
  __typename?: 'OrderSetItemsCategory';
  count: Scalars['Float']['output'];
  limit: Scalars['Float']['output'];
  maxBoundary?: Maybe<Scalars['Float']['output']>;
  maxCumulativePercentRank?: Maybe<Scalars['Float']['output']>;
  maxPercentRank?: Maybe<Scalars['Float']['output']>;
  minBoundary?: Maybe<Scalars['Float']['output']>;
  minCumulativePercentRank?: Maybe<Scalars['Float']['output']>;
  minPercentRank?: Maybe<Scalars['Float']['output']>;
  products: Array<OrderSetItem>;
  skip: Scalars['Float']['output'];
  totalOrderLineCount: Scalars['Float']['output'];
};

export enum OrderSetItemsCategoryType {
  CUMULATIVE_PERCENT_RANK = 'CUMULATIVE_PERCENT_RANK',
  PERCENT_RANK = 'PERCENT_RANK',
}

export type OrderSetSummary = {
  __typename?: 'OrderSetSummary';
  consignees: Array<OrderSetConsigneeSummary>;
  dateRange?: Maybe<DatasetObjectDateRange>;
  fields?: Maybe<Array<DatasetObjectFieldSummary>>;
  generatedAt?: Maybe<Scalars['Float']['output']>;
  orderCount: Scalars['Float']['output'];
  productCount?: Maybe<Scalars['Float']['output']>;
  startedAt?: Maybe<Scalars['Float']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

export type OrderSetsConnection = {
  __typename?: 'OrderSetsConnection';
  allUserTags: Array<Scalars['String']['output']>;
  /** Content of collection or the current page of collection if pagination is used */
  content: Array<OrderSet>;
  /** Total number of elements in this collection (ignoring pagination constraints, if any) */
  totalCount: Scalars['Float']['output'];
};

export type OrderSetsFilter = {
  status?: InputMaybe<Array<OrderSetImportStatus>>;
  title?: InputMaybe<StringSearchFilter>;
  userTags?: InputMaybe<Array<Scalars['String']['input']>>;
  userTagsHasAll?: InputMaybe<Array<Scalars['String']['input']>>;
  userTagsHasAny?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type OrderSetsSortBy = {
  /** Sorting direction */
  direction?: SortDirection;
  /** Field (sort option) to sort by */
  field: BaseEntitySortOption;
};

export type OrderedQuantityReport = {
  __typename?: 'OrderedQuantityReport';
  assignment: Assignment;
  assignmentCapacityPolicy?: Maybe<AssignmentCapacityPolicy>;
  completedAt?: Maybe<Scalars['Timestamp']['output']>;
  createdAt: Scalars['Timestamp']['output'];
  id: Scalars['ID']['output'];
  itemSet: ItemSet;
  items: OrderedQuantityReportItemsConnection;
  layout: Layout;
  orderSet: OrderSet;
  progress: Scalars['Float']['output'];
  simulation: Simulation;
  startedAt?: Maybe<Scalars['Timestamp']['output']>;
  status: BatchJobStatus;
  summary?: Maybe<OrderedQuantityReportSummary>;
  updatedAt: Scalars['Timestamp']['output'];
  warehouse: Warehouse;
};

export type OrderedQuantityReportItemsArgs = {
  filter?: InputMaybe<OrderedQuantityReportItemFilter>;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<OrderedQuantityReportItemSortBy>>;
};

export type OrderedQuantityReportItem = {
  __typename?: 'OrderedQuantityReportItem';
  assignedCapacity: Scalars['Float']['output'];
  assignedCapacityGap: Scalars['Float']['output'];
  consignee: Scalars['ID']['output'];
  details?: Maybe<OrderedQuantityReportItemDetails>;
  itemId: Scalars['ID']['output'];
  orderedQuantity: Scalars['Float']['output'];
  sku: Scalars['ID']['output'];
  status: OrderedQuantityReportItemStatus;
  stockQuantity?: Maybe<Scalars['Float']['output']>;
  stockQuantityGap?: Maybe<Scalars['Float']['output']>;
};

export type OrderedQuantityReportItemDetails = {
  __typename?: 'OrderedQuantityReportItemDetails';
  assignedCapacityGapDetails?: Maybe<ItemQuantityDetails>;
  assignedLocations?: Maybe<Array<AssignedLocationCapacityDetails>>;
  orderedQuantityDetails?: Maybe<ItemQuantityDetails>;
  stockQuantityGapDetails?: Maybe<ItemQuantityDetails>;
};

export type OrderedQuantityReportItemFilter = {
  consignee?: InputMaybe<StringSearchFilter>;
  sku?: InputMaybe<StringSearchFilter>;
  status?: InputMaybe<Array<OrderedQuantityReportItemStatus>>;
};

export type OrderedQuantityReportItemSortBy = {
  /** Sorting direction */
  direction?: SortDirection;
  /** Field (sort option) to sort by */
  field: OrderedQuantityReportItemSortOption;
};

export enum OrderedQuantityReportItemSortOption {
  ASSIGNED_CAPACITY = 'ASSIGNED_CAPACITY',
  ASSIGNED_CAPACITY_GAP = 'ASSIGNED_CAPACITY_GAP',
  CONSIGNEE = 'CONSIGNEE',
  ORDERED_QUANTITY = 'ORDERED_QUANTITY',
  SKU = 'SKU',
  STOCK_QUANTITY = 'STOCK_QUANTITY',
}

export enum OrderedQuantityReportItemStatus {
  ENOUGH = 'ENOUGH',
  NOT_ENOUGH = 'NOT_ENOUGH',
  NOT_ORDERED = 'NOT_ORDERED',
  UNKNOWN_ASSIGNED = 'UNKNOWN_ASSIGNED',
  UNKNOWN_BOTH = 'UNKNOWN_BOTH',
  UNKNOWN_ORDERED = 'UNKNOWN_ORDERED',
}

export type OrderedQuantityReportItemsConnection = {
  __typename?: 'OrderedQuantityReportItemsConnection';
  /** Content of collection or the current page of collection if pagination is used */
  content: Array<OrderedQuantityReportItem>;
  /** Total number of elements in this collection (ignoring pagination constraints, if any) */
  totalCount: Scalars['Float']['output'];
};

export type OrderedQuantityReportSummary = {
  __typename?: 'OrderedQuantityReportSummary';
  enoughCount: Scalars['Float']['output'];
  notEnoughCount: Scalars['Float']['output'];
  notOrderedCount: Scalars['Float']['output'];
  unknownAssignedCount?: Maybe<Scalars['Float']['output']>;
  unknownBothCount?: Maybe<Scalars['Float']['output']>;
  unknownOrderedCount?: Maybe<Scalars['Float']['output']>;
};

export type OrdersConnection = {
  __typename?: 'OrdersConnection';
  /** Content of collection or the current page of collection if pagination is used */
  content: Array<OrderHeader>;
  /** Total number of elements in this collection (ignoring pagination constraints, if any) */
  totalCount: Scalars['Float']['output'];
};

export type OrdersSortBy = {
  /** Sorting direction */
  direction?: SortDirection;
  /** Field (sort option) to sort by */
  field: OrdersSortOptions;
};

export enum OrdersSortOptions {
  CONSIGNEE = 'CONSIGNEE',
  ORDER_ID = 'ORDER_ID',
}

export enum PickabilityConstraintMode {
  KEEP_PICKABILITY = 'KEEP_PICKABILITY',
  NO_CONSTRAINT = 'NO_CONSTRAINT',
  NO_MOVES_BETWEEN_PICKING_RULES = 'NO_MOVES_BETWEEN_PICKING_RULES',
}

export type PickabilityConstraintSettings = {
  __typename?: 'PickabilityConstraintSettings';
  disabled?: Maybe<Scalars['Boolean']['output']>;
  mode?: Maybe<PickabilityConstraintMode>;
};

export type PickabilityConstraintSettingsInput = {
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  mode?: InputMaybe<PickabilityConstraintMode>;
};

export type PickingJobDetails = {
  __typename?: 'PickingJobDetails';
  ignoredLines?: Maybe<Array<AnalyzeResultPicklistIgnoredLine>>;
  orderIds: Array<Scalars['ID']['output']>;
  orderLineRuleId?: Maybe<Scalars['ID']['output']>;
  pickByDate?: Maybe<Scalars['LocalDateTime']['output']>;
  pickingWaveId?: Maybe<Scalars['ID']['output']>;
  picklistGroupKey?: Maybe<Scalars['ID']['output']>;
  totalLines: Scalars['Float']['output'];
  totalOrderLines: Scalars['Float']['output'];
  unitOfWork?: Maybe<Scalars['ID']['output']>;
};

export type PickingPolicy = {
  __typename?: 'PickingPolicy';
  orderLineRules?: Maybe<Array<PickingPolicyOrderLineRule>>;
};

export type PickingPolicyInput = {
  orderLineRules?: InputMaybe<Array<PickingPolicyOrderLineRuleInput>>;
};

export enum PickingPolicyLocationSelectionMode {
  LOCATION_PRIORITY = 'LOCATION_PRIORITY',
  ROUND_ROBIN = 'ROUND_ROBIN',
}

export type PickingPolicyOrderLineRule = {
  __typename?: 'PickingPolicyOrderLineRule';
  id: Scalars['ID']['output'];
  orderLinesMatch?: Maybe<OrderLineFilterUnion>;
  pickingRequirementsSettings?: Maybe<PickingPolicyPickingRequirementsSettings>;
  pickingRules?: Maybe<Array<PickingPolicyPickingRule>>;
  picklistSettings?: Maybe<PickingPolicyPicklistSettings>;
  title?: Maybe<Scalars['String']['output']>;
  /** if true, use picking container property from order line to separate picking requirements and calculate reorder separately */
  usePickingContainer?: Maybe<Scalars['Boolean']['output']>;
};

export type PickingPolicyOrderLineRuleInput = {
  id: Scalars['ID']['input'];
  orderLinesMatch?: InputMaybe<OrderLineFilterUnionInput>;
  pickingRequirementsSettings?: InputMaybe<PickingPolicyPickingRequirementsSettingsInput>;
  pickingRules?: InputMaybe<Array<PickingPolicyPickingRuleInput>>;
  picklistSettings?: InputMaybe<PickingPolicyPicklistSettingsInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  /** if true, use picking container property from order line to separate picking requirements and calculate reorder separately */
  usePickingContainer?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PickingPolicyPickingRequirementsSettings = {
  __typename?: 'PickingPolicyPickingRequirementsSettings';
  batch?: Maybe<PickingRequirementsBatchSettings>;
  endLocationIdSource?: Maybe<PickingRequirementTerminalLocationSource>;
  groupBy: PickingRequirementsGroupBy;
  startLocationIdSource?: Maybe<PickingRequirementTerminalLocationSource>;
  wave?: Maybe<PickingWaveSettings>;
};

export type PickingPolicyPickingRequirementsSettingsInput = {
  batch?: InputMaybe<PickingRequirementsBatchSettingsInput>;
  endLocationIdSource?: InputMaybe<PickingRequirementTerminalLocationSource>;
  groupBy: PickingRequirementsGroupBy;
  startLocationIdSource?: InputMaybe<PickingRequirementTerminalLocationSource>;
  wave?: InputMaybe<PickingWaveSettingsInput>;
};

export type PickingPolicyPickingRule = {
  __typename?: 'PickingPolicyPickingRule';
  agentId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  locationSelectionMode?: Maybe<PickingPolicyLocationSelectionMode>;
  locationsMatch?: Maybe<LocationFilterUnion>;
  picklistGroupKey?: Maybe<Scalars['ID']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  uomTypes?: Maybe<Array<Scalars['String']['output']>>;
  waypointSettings?: Maybe<PickingPolicyWaypointSettings>;
};

export type PickingPolicyPickingRuleInput = {
  agentId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  locationSelectionMode?: InputMaybe<PickingPolicyLocationSelectionMode>;
  locationsMatch?: InputMaybe<LocationFilterUnionInput>;
  picklistGroupKey?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  uomTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  waypointSettings?: InputMaybe<PickingPolicyWaypointSettingsInput>;
};

export type PickingPolicyPicklistSettings = {
  __typename?: 'PickingPolicyPicklistSettings';
  picklistLinesOrder?: Maybe<Array<PicklistLinesSortBy>>;
  /** sort picklist lines by order line's picking priority field when splitting into picklist */
  usePickingPriority?: Maybe<Scalars['Boolean']['output']>;
};

export type PickingPolicyPicklistSettingsInput = {
  picklistLinesOrder?: InputMaybe<Array<PicklistLinesSortByInput>>;
  /** sort picklist lines by order line's picking priority field when splitting into picklist */
  usePickingPriority?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PickingPolicyWaypointSettings = {
  __typename?: 'PickingPolicyWaypointSettings';
  endLocationIds?: Maybe<Array<Scalars['ID']['output']>>;
  ruleId?: Maybe<Scalars['ID']['output']>;
  startLocationIds?: Maybe<Array<Scalars['ID']['output']>>;
};

export type PickingPolicyWaypointSettingsInput = {
  endLocationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  ruleId?: InputMaybe<Scalars['ID']['input']>;
  startLocationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type PickingRequirementDetails = {
  __typename?: 'PickingRequirementDetails';
  endLocationId?: Maybe<Scalars['ID']['output']>;
  shipmentId?: Maybe<Scalars['ID']['output']>;
  startLocationId?: Maybe<Scalars['ID']['output']>;
};

export enum PickingRequirementTerminalLocationSource {
  DELIVERY_ROUTE = 'DELIVERY_ROUTE',
  DOCK = 'DOCK',
  STAGING_AREA = 'STAGING_AREA',
  WAVE_ID = 'WAVE_ID',
}

export type PickingRequirementsBatchSettings = {
  __typename?: 'PickingRequirementsBatchSettings';
  maxOrderCount?: Maybe<Scalars['Float']['output']>;
  maxOrderLineCount?: Maybe<Scalars['Float']['output']>;
};

export type PickingRequirementsBatchSettingsInput = {
  maxOrderCount?: InputMaybe<Scalars['Float']['input']>;
  maxOrderLineCount?: InputMaybe<Scalars['Float']['input']>;
};

export enum PickingRequirementsGroupBy {
  BATCH = 'BATCH',
  CUSTOMER = 'CUSTOMER',
  ITEM = 'ITEM',
  ORDER = 'ORDER',
}

export type PickingWave = {
  __typename?: 'PickingWave';
  deliveryRoute?: Maybe<Scalars['ID']['output']>;
  dock?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  orderLineRuleId: Scalars['ID']['output'];
  pickByDate?: Maybe<Scalars['LocalDateTime']['output']>;
  stagingArea?: Maybe<Scalars['ID']['output']>;
  waveId?: Maybe<Scalars['ID']['output']>;
};

export enum PickingWavePickByDateMode {
  DATE = 'DATE',
  DATETIME = 'DATETIME',
  FIXED_FRAMES = 'FIXED_FRAMES',
}

export type PickingWavePickByDateSettings = {
  __typename?: 'PickingWavePickByDateSettings';
  fixedDeadlines?: Maybe<Array<Scalars['LocalTime']['output']>>;
  mode: PickingWavePickByDateMode;
};

export type PickingWavePickByDateSettingsInput = {
  fixedDeadlines?: InputMaybe<Array<Scalars['LocalTime']['input']>>;
  mode: PickingWavePickByDateMode;
};

export type PickingWaveSettings = {
  __typename?: 'PickingWaveSettings';
  pickByDateSettings?: Maybe<PickingWavePickByDateSettings>;
  useDeliveryRoute?: Maybe<Scalars['Boolean']['output']>;
  useDock?: Maybe<Scalars['Boolean']['output']>;
  useStagingArea?: Maybe<Scalars['Boolean']['output']>;
  useWaveId?: Maybe<Scalars['Boolean']['output']>;
};

export type PickingWaveSettingsInput = {
  pickByDateSettings?: InputMaybe<PickingWavePickByDateSettingsInput>;
  useDeliveryRoute?: InputMaybe<Scalars['Boolean']['input']>;
  useDock?: InputMaybe<Scalars['Boolean']['input']>;
  useStagingArea?: InputMaybe<Scalars['Boolean']['input']>;
  useWaveId?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Picklist = {
  __typename?: 'Picklist';
  agents: Array<Scalars['ID']['output']>;
  details?: Maybe<PicklistDetails>;
  id: Scalars['ID']['output'];
  lines: Array<PicklistLine>;
  orderIds: Array<Scalars['ID']['output']>;
  orderLineRuleId?: Maybe<Scalars['ID']['output']>;
  pickByDate?: Maybe<Scalars['LocalDateTime']['output']>;
  pickingMethod: Scalars['String']['output'];
  pickingWaveId?: Maybe<Scalars['ID']['output']>;
  picklistGroupKey?: Maybe<Scalars['ID']['output']>;
  planeId: Scalars['ID']['output'];
  title?: Maybe<Scalars['String']['output']>;
  totalNetWeight?: Maybe<Scalars['Float']['output']>;
  totalVolume?: Maybe<Scalars['Float']['output']>;
  unitOfWork?: Maybe<Scalars['ID']['output']>;
};

export type PicklistDetails = {
  __typename?: 'PicklistDetails';
  endLocationId?: Maybe<Scalars['ID']['output']>;
  possibleEndLocations?: Maybe<Array<PicklistTerminalLocationDetails>>;
  shipmentId?: Maybe<Scalars['ID']['output']>;
  startLocationId?: Maybe<Scalars['ID']['output']>;
};

export type PicklistEventDetails =
  | HandlingExecutionDetails
  | HandlingPalletReorderingDetails
  | PicklistLineProcessingEventDetails
  | PicklistProcessingEventDetails
  | SourceEventIgnoredDetails
  | TravellingHorizontalDetails
  | TravellingVerticalDetails;

export enum PicklistEventType {
  HANDLING_EXECUTION = 'HANDLING_EXECUTION',
  HANDLING_ON_COMPLETION = 'HANDLING_ON_COMPLETION',
  HANDLING_PALLET_REORDERING = 'HANDLING_PALLET_REORDERING',
  HANDLING_PREPARATION = 'HANDLING_PREPARATION',
  POST_HANDLING = 'POST_HANDLING',
  PRE_HANDLING = 'PRE_HANDLING',
  SOURCE_EVENT_IGNORED = 'SOURCE_EVENT_IGNORED',
  TRAVELLING_HORIZONTAL = 'TRAVELLING_HORIZONTAL',
  TRAVELLING_HORIZONTAL_END = 'TRAVELLING_HORIZONTAL_END',
  TRAVELLING_HORIZONTAL_START = 'TRAVELLING_HORIZONTAL_START',
  TRAVELLING_VERTICAL = 'TRAVELLING_VERTICAL',
}

export type PicklistEventWaypoint = {
  __typename?: 'PicklistEventWaypoint';
  id: Scalars['ID']['output'];
  position: Point;
  type: WaypointType;
};

export type PicklistInput = {
  agents: Array<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  jobDate: Scalars['LocalDateTime']['input'];
  lines: Array<PicklistLineInput>;
  orderIds: Array<Scalars['ID']['input']>;
  orderLineRuleId?: InputMaybe<Scalars['ID']['input']>;
  pickByDate?: InputMaybe<Scalars['LocalDateTime']['input']>;
  pickingWaveId?: InputMaybe<Scalars['ID']['input']>;
  picklistGroupKey?: InputMaybe<Scalars['ID']['input']>;
  planeId: Scalars['ID']['input'];
  processType?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  totalNetWeight?: InputMaybe<Scalars['Float']['input']>;
  totalVolume?: InputMaybe<Scalars['Float']['input']>;
  unitOfWork?: InputMaybe<Scalars['ID']['input']>;
};

export type PicklistLine = {
  __typename?: 'PicklistLine';
  consignee: Scalars['ID']['output'];
  details?: Maybe<PicklistLineDetails>;
  itemId: Scalars['ID']['output'];
  locationId: Scalars['ID']['output'];
  netWeight?: Maybe<Scalars['Float']['output']>;
  orderLineRuleId?: Maybe<Scalars['ID']['output']>;
  orderLines?: Maybe<Scalars['JSON']['output']>;
  pickingPriority?: Maybe<Scalars['ID']['output']>;
  pickingRuleId?: Maybe<Scalars['ID']['output']>;
  picklistLine: Scalars['Float']['output'];
  quantity: Scalars['Float']['output'];
  sku: Scalars['ID']['output'];
  uom?: Maybe<Scalars['String']['output']>;
  uomQuantity: Scalars['Float']['output'];
  volume?: Maybe<Scalars['Float']['output']>;
};

export type PicklistLineDetails = {
  __typename?: 'PicklistLineDetails';
  pickingContainer?: Maybe<Scalars['ID']['output']>;
  sourceEventId?: Maybe<Scalars['ID']['output']>;
  stackingPriority?: Maybe<Array<Scalars['Int']['output']>>;
};

export type PicklistLineDetailsInput = {
  pickingContainer?: InputMaybe<Scalars['ID']['input']>;
  sourceEventId?: InputMaybe<Scalars['ID']['input']>;
  stackingPriority?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type PicklistLineInput = {
  details?: InputMaybe<PicklistLineDetailsInput>;
  itemId: Scalars['ID']['input'];
  locationId: Scalars['ID']['input'];
  netWeight?: InputMaybe<Scalars['Float']['input']>;
  orderLineRuleId?: InputMaybe<Scalars['ID']['input']>;
  orderLines?: InputMaybe<Scalars['JSON']['input']>;
  pickingPriority?: InputMaybe<Scalars['ID']['input']>;
  pickingRuleId?: InputMaybe<Scalars['ID']['input']>;
  picklistLine: Scalars['Float']['input'];
  quantity: Scalars['Float']['input'];
  uom?: InputMaybe<Scalars['String']['input']>;
  uomQuantity: Scalars['Float']['input'];
  volume?: InputMaybe<Scalars['Float']['input']>;
};

export type PicklistLineProcessingEventDetails = {
  __typename?: 'PicklistLineProcessingEventDetails';
  uomQuantity: Scalars['Float']['output'];
};

export type PicklistLineProcessingEventSettings = {
  __typename?: 'PicklistLineProcessingEventSettings';
  timeFixed?: Maybe<Scalars['Float']['output']>;
  timePerUom?: Maybe<Scalars['Float']['output']>;
};

export type PicklistLineProcessingEventSettingsInput = {
  timeFixed?: InputMaybe<Scalars['Float']['input']>;
  timePerUom?: InputMaybe<Scalars['Float']['input']>;
};

export enum PicklistLinesOrderOptions {
  CONSIGNEE = 'CONSIGNEE',
  LOCATION_ID = 'LOCATION_ID',
  LOCATION_LEVEL = 'LOCATION_LEVEL',
  LOCATION_ORDER = 'LOCATION_ORDER',
  POLLUTION_CLASS = 'POLLUTION_CLASS',
  SKU = 'SKU',
  SKU_GROUP = 'SKU_GROUP',
  STACKING_PRIORITY = 'STACKING_PRIORITY',
  STOCK_CATEGORY = 'STOCK_CATEGORY',
  STORAGE_CLASS = 'STORAGE_CLASS',
  SUB_GROUP = 'SUB_GROUP',
  TRANSPORT_CLASS = 'TRANSPORT_CLASS',
}

export type PicklistLinesSortBy = {
  __typename?: 'PicklistLinesSortBy';
  /** Sorting direction */
  direction: PicklistLinesSortDirection;
  nullsFirst?: Maybe<Scalars['Boolean']['output']>;
  type: PicklistLinesOrderOptions;
};

export type PicklistLinesSortByInput = {
  /** Sorting direction */
  direction?: PicklistLinesSortDirection;
  nullsFirst?: InputMaybe<Scalars['Boolean']['input']>;
  type: PicklistLinesOrderOptions;
};

/** Sorting direction */
export enum PicklistLinesSortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type PicklistLinesSummary = {
  __typename?: 'PicklistLinesSummary';
  count: Scalars['Int']['output'];
  key: PicklistLinesSummaryKey;
  netWeight: Scalars['Float']['output'];
  quantity: Scalars['Float']['output'];
  uomQuantity: Scalars['Float']['output'];
  volume: Scalars['Float']['output'];
};

export enum PicklistLinesSummaryGroupBy {
  LOCATION_ID = 'LOCATION_ID',
  STACKING_PRIORITY = 'STACKING_PRIORITY',
  UOM = 'UOM',
}

export type PicklistLinesSummaryKey = {
  __typename?: 'PicklistLinesSummaryKey';
  locationId?: Maybe<Scalars['ID']['output']>;
  stackingPriority?: Maybe<Array<Scalars['Int']['output']>>;
  uom?: Maybe<Scalars['ID']['output']>;
};

export type PicklistProcessingEventDetails = {
  __typename?: 'PicklistProcessingEventDetails';
  orderCount: Scalars['Float']['output'];
  orderLineCount: Scalars['Float']['output'];
  totalUomQuantity: Scalars['Float']['output'];
};

export type PicklistProcessingEventSettings = {
  __typename?: 'PicklistProcessingEventSettings';
  timeFixed?: Maybe<Scalars['Float']['output']>;
  timePerOrder?: Maybe<Scalars['Float']['output']>;
  timePerPicklistLine?: Maybe<Scalars['Float']['output']>;
  timePerUom?: Maybe<Scalars['Float']['output']>;
};

export type PicklistProcessingEventSettingsInput = {
  timeFixed?: InputMaybe<Scalars['Float']['input']>;
  timePerOrder?: InputMaybe<Scalars['Float']['input']>;
  timePerPicklistLine?: InputMaybe<Scalars['Float']['input']>;
  timePerUom?: InputMaybe<Scalars['Float']['input']>;
};

export type PicklistSet = {
  __typename?: 'PicklistSet';
  analyzeResult?: Maybe<AnalyzeResult>;
  generatePicklistsJob?: Maybe<GeneratePicklistsJob>;
  id: Scalars['ID']['output'];
  linesSummary: Array<PicklistLinesSummary>;
};

export type PicklistSetLinesSummaryArgs = {
  groupBy?: InputMaybe<Array<PicklistLinesSummaryGroupBy>>;
};

export enum PicklistSortOptions {
  TITLE = 'TITLE',
}

export type PicklistTerminalLocationDetails = {
  __typename?: 'PicklistTerminalLocationDetails';
  cost: Scalars['Float']['output'];
  distance: Scalars['Float']['output'];
  duration: Scalars['Float']['output'];
  locationId: Scalars['ID']['output'];
};

export type PicklistsConnection = {
  __typename?: 'PicklistsConnection';
  /** Content of collection or the current page of collection if pagination is used */
  content: Array<Picklist>;
  /** Total number of elements in this collection (ignoring pagination constraints, if any) */
  totalCount: Scalars['Float']['output'];
};

export type PicklistsSortBy = {
  /** Sorting direction */
  direction?: SortDirection;
  /** Field (sort option) to sort by */
  field: PicklistSortOptions;
};

export type Plane = {
  __typename?: 'Plane';
  aisles: Array<Aisle>;
  bays: Array<Bay>;
  end?: Maybe<PlaneAccessSpec>;
  id: Scalars['ID']['output'];
  start?: Maybe<PlaneAccessSpec>;
  title?: Maybe<Scalars['String']['output']>;
};

export type PlaneAccessSpec = {
  __typename?: 'PlaneAccessSpec';
  aisleId: Scalars['ID']['output'];
  position: Point;
};

export type PlaneAccessSpecInput = {
  aisleId: Scalars['ID']['input'];
  position: PointInput;
};

export type PlaneConnectivityGraph = {
  __typename?: 'PlaneConnectivityGraph';
  aislePortals: Array<AislePortal>;
  bayPortals: Array<BayPortal>;
  id: Scalars['ID']['output'];
};

export type PlaneConnectivityGraph2 = {
  __typename?: 'PlaneConnectivityGraph2';
  aislePortals: Array<AislePortal>;
  bayPortals: Array<BayPortal>;
};

export type PlaneConnectivityGraphInput = {
  aislePortals: Array<AislePortalInput>;
  bayPortals: Array<BayPortalInput>;
  id: Scalars['ID']['input'];
};

export type PlaneInput = {
  aisles: Array<AisleInput>;
  bays: Array<BayInput>;
  end?: InputMaybe<PlaneAccessSpecInput>;
  id: Scalars['ID']['input'];
  start?: InputMaybe<PlaneAccessSpecInput>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type Point = {
  __typename?: 'Point';
  x: Scalars['Float']['output'];
  y: Scalars['Float']['output'];
};

export type PointInput = {
  x: Scalars['Float']['input'];
  y: Scalars['Float']['input'];
};

export type PolicyFilter = {
  __typename?: 'PolicyFilter';
  isNot?: Maybe<Scalars['Boolean']['output']>;
  isNull?: Maybe<Scalars['Boolean']['output']>;
  range?: Maybe<NumberFilterRange>;
  stringRange?: Maybe<StringFilterRange>;
  type: Scalars['ID']['output'];
  valueIn?: Maybe<Array<FilterValue>>;
};

export type PolicyFilterInput = {
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  range?: InputMaybe<NumberFilterRangeInput>;
  stringRange?: InputMaybe<StringFilterRangeInput>;
  type: Scalars['ID']['input'];
  valueIn?: InputMaybe<Array<FilterValueInput>>;
};

export type PolicyFilterIntersection = {
  __typename?: 'PolicyFilterIntersection';
  allOf: Array<PolicyFilter>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type PolicyFilterIntersectionInput = {
  allOf: Array<PolicyFilterInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type PolicyFilterUnion = {
  __typename?: 'PolicyFilterUnion';
  anyOf: Array<PolicyFilterIntersection>;
};

export type PolicyFilterUnionInput = {
  anyOf: Array<PolicyFilterIntersectionInput>;
};

export type PrepareImportJobPreviewTableOptions = {
  csvOptions?: InputMaybe<CsvImportOptionsInput>;
  hasHeader?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PresentationSettings = {
  __typename?: 'PresentationSettings';
  datasetExtraFieldsSettings?: Maybe<Array<DatasetExtraFieldsSettings>>;
  filterPresets?: Maybe<Array<FilterPreset>>;
  filterSettings?: Maybe<Array<FilterSettings>>;
};

export type PresentationSettingsInput = {
  datasetExtraFieldsSettings?: InputMaybe<
    Array<DatasetExtraFieldsSettingsInput>
  >;
  filterPresets?: InputMaybe<Array<FilterPresetInput>>;
  filterSettings?: InputMaybe<Array<FilterSettingsInput>>;
};

export type PreviewQueryColumn = {
  __typename?: 'PreviewQueryColumn';
  name: Scalars['ID']['output'];
  type: Scalars['ID']['output'];
};

export type PreviewQueryResponse = {
  __typename?: 'PreviewQueryResponse';
  columns: Array<PreviewQueryColumn>;
  result: Scalars['JSON']['output'];
};

export type ProcessingPipeline = {
  __typename?: 'ProcessingPipeline';
  createdAt: Scalars['Timestamp']['output'];
  description?: Maybe<Scalars['String']['output']>;
  enabled: Scalars['Boolean']['output'];
  extraSettings?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['ID']['output'];
  jobInput?: Maybe<ProcessingPipelineJobInput>;
  jobs: ProcessingPipelineJobsConnection;
  pipelineType: ProcessingPipelineType;
  settings?: Maybe<ProcessingPipelineSettings>;
  title?: Maybe<Scalars['String']['output']>;
  triggerSettings?: Maybe<ProcessingPipelineTriggerSettings>;
  updatedAt: Scalars['Timestamp']['output'];
  userId: Scalars['ID']['output'];
  warehouse: Warehouse;
  warehouseId: Scalars['ID']['output'];
};

export type ProcessingPipelineJobsArgs = {
  filter?: InputMaybe<ProcessingPipelineJobsFilter>;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<ProcessingPipelineJobsSortBy>>;
};

export type ProcessingPipelineJob = {
  __typename?: 'ProcessingPipelineJob';
  completedAt?: Maybe<Scalars['Timestamp']['output']>;
  createdAt: Scalars['Timestamp']['output'];
  details?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['ID']['output'];
  jobInput?: Maybe<ProcessingPipelineJobInput>;
  jobOutput?: Maybe<ProcessingPipelineJobOutput>;
  pipelineType: ProcessingPipelineType;
  progress: Scalars['Float']['output'];
  settings?: Maybe<ProcessingPipelineSettings>;
  skippable: Scalars['Boolean']['output'];
  startedAt?: Maybe<Scalars['Timestamp']['output']>;
  status: ProcessingPipelineJobStatus;
  updatedAt: Scalars['Timestamp']['output'];
};

export type ProcessingPipelineJobInput = AnalyzeActualityJobInput;

export type ProcessingPipelineJobOutput = AnalyzeActualityJobOutput;

export enum ProcessingPipelineJobSortOption {
  CREATED_AT = 'CREATED_AT',
  UPDATED_AT = 'UPDATED_AT',
}

export enum ProcessingPipelineJobStatus {
  COMPLETED = 'COMPLETED',
  CREATED = 'CREATED',
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
  QUEUED = 'QUEUED',
  SKIPPED = 'SKIPPED',
  STARTING = 'STARTING',
  TERMINATED = 'TERMINATED',
}

export type ProcessingPipelineJobsConnection = {
  __typename?: 'ProcessingPipelineJobsConnection';
  /** Content of collection or the current page of collection if pagination is used */
  content: Array<ProcessingPipelineJob>;
  /** Total number of elements in this collection (ignoring pagination constraints, if any) */
  totalCount: Scalars['Float']['output'];
};

export type ProcessingPipelineJobsFilter = {
  status?: InputMaybe<Array<ProcessingPipelineJobStatus>>;
};

export type ProcessingPipelineJobsSortBy = {
  /** Sorting direction */
  direction?: SortDirection;
  /** Field (sort option) to sort by */
  field: ProcessingPipelineJobSortOption;
};

export type ProcessingPipelineSettings = AnalyzeActualityPipelineSettings;

export enum ProcessingPipelineSortOption {
  CREATED_AT = 'CREATED_AT',
  UPDATED_AT = 'UPDATED_AT',
}

export type ProcessingPipelineTriggerSettings = {
  __typename?: 'ProcessingPipelineTriggerSettings';
  dummy?: Maybe<Scalars['String']['output']>;
};

export type ProcessingPipelineTriggerSettingsInput = {
  dummy?: InputMaybe<Scalars['String']['input']>;
};

export enum ProcessingPipelineType {
  ANALYZE_ACTUALITY = 'ANALYZE_ACTUALITY',
}

export type ProcessingPipelinesConnection = {
  __typename?: 'ProcessingPipelinesConnection';
  /** Content of collection or the current page of collection if pagination is used */
  content: Array<ProcessingPipeline>;
  /** Total number of elements in this collection (ignoring pagination constraints, if any) */
  totalCount: Scalars['Float']['output'];
};

export type ProcessingPipelinesSortBy = {
  /** Sorting direction */
  direction?: SortDirection;
  /** Field (sort option) to sort by */
  field: ProcessingPipelineSortOption;
};

export type ProductId = {
  __typename?: 'ProductId';
  consignee: Scalars['ID']['output'];
  sku: Scalars['ID']['output'];
};

export type ProductIdInput = {
  consignee: Scalars['ID']['input'];
  sku: Scalars['ID']['input'];
};

export type ProductLocationId = {
  __typename?: 'ProductLocationId';
  consignee: Scalars['ID']['output'];
  locationId: Scalars['ID']['output'];
  sku: Scalars['ID']['output'];
};

export type PutImportPipelineConnectorInput = {
  connector: ImportPipelineConnectorSettingsInput;
  importPipelineId: Scalars['ID']['input'];
};

export type Query = {
  __typename?: 'Query';
  actuality?: Maybe<Actuality>;
  allocationRequirementSet?: Maybe<AllocationRequirementSet>;
  allocationRun?: Maybe<AllocationRun>;
  analyzeActualityStatus: AnalyzeActualityStatus;
  analyzeResult?: Maybe<AnalyzeResult>;
  applyAssignmentItemFilter: AssignmentItemWithMatchingFiltersConnection;
  applyStackingPolicyRules: ApplyStackingPolicyResult;
  assignment?: Maybe<Assignment>;
  assignmentIssueSet?: Maybe<AssignmentIssueSet>;
  assignments: AssignmentsConnection;
  batchBuildLayoutRoute: Array<LayoutRoute>;
  buildLayoutRoute: LayoutRoute;
  buildSimulationLayoutRoute: LayoutRoute;
  checkOptimizationSwapPolicyJob?: Maybe<CheckOptimizationSwapPolicyJob>;
  /** @deprecated use getSimulationAssignmentPolicySummary instead */
  checkSimulationAssignmentPolicy: CheckSimulationAssignmentPolicyResult;
  checkSimulationLayoutAccessibility: CheckSimulationLayoutAccessibilityResult;
  checkSimulationPickingPolicy: CheckSimulationPickingPolicyResult;
  countAssignmentItemsByMatchingFilter: CountAssignmentItemsByMatchingFilterResult;
  currentUser: UserDetails;
  currentUserSettings?: Maybe<UserSettings>;
  datasetObject?: Maybe<DatasetObject>;
  demoWarehouses: Array<DemoWarehouse>;
  exportJob?: Maybe<ExportJob>;
  exportJobs: Array<ExportJob>;
  findBySimulation?: Maybe<AllocationRequirementSet>;
  findItemFilterValues: FilterValuesConnection;
  findItemsByFilter: ItemsConnection;
  findLayoutInaccessibleLocations: InaccessibleLayoutLocationsConnection;
  findSimulationInaccessibleLocations: InaccessibleLayoutLocationsConnection;
  /** @deprecated in favour of Simulation.effectiveItemSet.itemFilterValues */
  findSimulationItemFilterValues: FilterValuesConnection;
  /** @deprecated in favour of Simulation.effectiveItemSet.itemsByFilter */
  findSimulationItemsByFilter: SimulationItemsConnection;
  findSimulationOrderLineFilterValues: FilterValuesConnection;
  findSimulationOrderLinesByFilter: SimulationOrderLinesConnection;
  getSimulationAssignmentPolicySummary: AssignmentPolicySummary;
  importJob?: Maybe<ImportJob>;
  importJobs: ImportJobsConnection;
  importPipeline?: Maybe<ImportPipeline>;
  importPipelines: ImportPipelinesConnection;
  integrationUser?: Maybe<IntegrationUser>;
  itemSet?: Maybe<ItemSet>;
  itemSetIssueSet?: Maybe<ItemSetIssueSet>;
  layout?: Maybe<Layout>;
  layoutImport?: Maybe<LayoutImport>;
  layoutImports: LayoutImportsConnection;
  layoutIssueSet?: Maybe<LayoutIssueSet>;
  layouts: LayoutsConnection;
  optimizationRun?: Maybe<OptimizationRun>;
  orderSet?: Maybe<OrderSet>;
  orderSetIssueSet?: Maybe<OrderSetIssueSet>;
  orderSets: OrderSetsConnection;
  processingPipeline?: Maybe<ProcessingPipeline>;
  simulation?: Maybe<Simulation>;
  sizeCompliance: SizeCompliance;
  warehouse?: Maybe<Warehouse>;
  warehouses: WarehousesConnection;
};

export type QueryActualityArgs = {
  id: Scalars['ID']['input'];
};

export type QueryAllocationRequirementSetArgs = {
  id: Scalars['String']['input'];
};

export type QueryAllocationRunArgs = {
  id: Scalars['ID']['input'];
};

export type QueryAnalyzeActualityStatusArgs = {
  token: Scalars['String']['input'];
};

export type QueryAnalyzeResultArgs = {
  id: Scalars['ID']['input'];
};

export type QueryApplyAssignmentItemFilterArgs = {
  filter?: InputMaybe<AssignmentItemsExtendedFilter>;
  input: ApplyAssignmentItemFilterInput;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<AssignmentItemsSortBy>>;
};

export type QueryApplyStackingPolicyRulesArgs = {
  filter?: InputMaybe<ItemsFilter>;
  input: ApplyStackingPolicyInput;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<ItemsSortBy>>;
};

export type QueryAssignmentArgs = {
  id: Scalars['ID']['input'];
};

export type QueryAssignmentIssueSetArgs = {
  key: AssignmentIssueSetKey;
};

export type QueryAssignmentsArgs = {
  filter?: InputMaybe<AssignmentsFilter>;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<AssignmentsSortBy>>;
};

export type QueryBatchBuildLayoutRouteArgs = {
  input: BatchBuildLayoutRouteInput;
};

export type QueryBuildLayoutRouteArgs = {
  input: BuildLayoutRouteInput;
};

export type QueryBuildSimulationLayoutRouteArgs = {
  input: BuildSimulationLayoutRouteInput;
};

export type QueryCheckOptimizationSwapPolicyJobArgs = {
  id: Scalars['String']['input'];
};

export type QueryCheckSimulationAssignmentPolicyArgs = {
  input: CheckSimulationAssignmentPolicyInput;
};

export type QueryCheckSimulationLayoutAccessibilityArgs = {
  input: CheckSimulationLayoutAccessibilityInput;
};

export type QueryCheckSimulationPickingPolicyArgs = {
  input: CheckSimulationPickingPolicyInput;
};

export type QueryCountAssignmentItemsByMatchingFilterArgs = {
  filter?: InputMaybe<AssignmentItemsExtendedFilter>;
  input: ApplyAssignmentItemFilterInput;
};

export type QueryDatasetObjectArgs = {
  id: Scalars['String']['input'];
};

export type QueryExportJobArgs = {
  id: Scalars['String']['input'];
};

export type QueryFindItemFilterValuesArgs = {
  input: FindItemFilterValuesInput;
  page?: InputMaybe<SkipLimitPageSpec>;
};

export type QueryFindItemsByFilterArgs = {
  filter?: InputMaybe<ItemsFilter>;
  input: FindItemsByFilterInput;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<ItemsSortBy>>;
};

export type QueryFindLayoutInaccessibleLocationsArgs = {
  filter?: InputMaybe<LayoutLocationsSearchFilter>;
  input: FindLayoutInaccessibleLocationsByRuleInput;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<LayoutLocationsSortBy>>;
};

export type QueryFindSimulationInaccessibleLocationsArgs = {
  filter?: InputMaybe<LayoutLocationsSearchFilter>;
  input: FindSimulationInaccessibleLocationsByRuleInput;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<LayoutLocationsSortBy>>;
};

export type QueryFindSimulationItemFilterValuesArgs = {
  input: FindSimulationItemFilterValuesInput;
  page?: InputMaybe<SkipLimitPageSpec>;
};

export type QueryFindSimulationItemsByFilterArgs = {
  filter?: InputMaybe<ItemsFilter>;
  input: FindSimulationItemsByFilter;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<ItemsSortBy>>;
};

export type QueryFindSimulationOrderLineFilterValuesArgs = {
  input: FindSimulationOrderLineFilterValuesInput;
  page?: InputMaybe<SkipLimitPageSpec>;
};

export type QueryFindSimulationOrderLinesByFilterArgs = {
  input: FindSimulationOrderLinesByFilter;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<SimulationOrderLinesSortBy>>;
};

export type QueryGetSimulationAssignmentPolicySummaryArgs = {
  id: Scalars['String']['input'];
  options?: InputMaybe<SimulationAssignmentPolicySummaryOptions>;
};

export type QueryImportJobArgs = {
  id: Scalars['String']['input'];
};

export type QueryImportJobsArgs = {
  filter?: InputMaybe<ImportJobsFilter>;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<ImportJobsSortBy>>;
};

export type QueryImportPipelineArgs = {
  id: Scalars['String']['input'];
};

export type QueryImportPipelinesArgs = {
  filter?: InputMaybe<ImportPipelinesFilter>;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<ImportPipelinesSortBy>>;
};

export type QueryIntegrationUserArgs = {
  id: Scalars['String']['input'];
};

export type QueryItemSetArgs = {
  id: Scalars['ID']['input'];
};

export type QueryItemSetIssueSetArgs = {
  key: ItemSetIssueSetKey;
};

export type QueryLayoutArgs = {
  id: Scalars['ID']['input'];
};

export type QueryLayoutImportArgs = {
  id: Scalars['ID']['input'];
};

export type QueryLayoutImportsArgs = {
  filter?: InputMaybe<LayoutImportsFilter>;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<LayoutImportsSortBy>>;
};

export type QueryLayoutIssueSetArgs = {
  key: LayoutIssueSetKey;
};

export type QueryLayoutsArgs = {
  filter?: InputMaybe<LayoutsFilter>;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<LayoutsSortBy>>;
};

export type QueryOptimizationRunArgs = {
  id: Scalars['ID']['input'];
};

export type QueryOrderSetArgs = {
  id: Scalars['ID']['input'];
};

export type QueryOrderSetIssueSetArgs = {
  key: OrderSetIssueSetKey;
};

export type QueryOrderSetsArgs = {
  filter?: InputMaybe<OrderSetsFilter>;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<OrderSetsSortBy>>;
};

export type QueryProcessingPipelineArgs = {
  id: Scalars['String']['input'];
};

export type QuerySimulationArgs = {
  id: Scalars['ID']['input'];
};

export type QuerySizeComplianceArgs = {
  id: Scalars['String']['input'];
};

export type QueryWarehouseArgs = {
  id: Scalars['ID']['input'];
};

export type QueryWarehousesArgs = {
  filter?: InputMaybe<WarehouseFilter>;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<WarehousesSortBy>>;
};

export type ReallocateQuantitySettings = {
  __typename?: 'ReallocateQuantitySettings';
  fixedUom?: Maybe<Scalars['ID']['output']>;
  fixedUomQuantity?: Maybe<Scalars['Float']['output']>;
  reallocateQuantity?: Maybe<ReallocateQuantitySource>;
  reallocateUom?: Maybe<ReallocateUomSource>;
};

export type ReallocateQuantitySettingsInput = {
  fixedUom?: InputMaybe<Scalars['ID']['input']>;
  fixedUomQuantity?: InputMaybe<Scalars['Float']['input']>;
  reallocateQuantity?: InputMaybe<ReallocateQuantitySource>;
  reallocateUom?: InputMaybe<ReallocateUomSource>;
};

export enum ReallocateQuantitySource {
  BY_CAPACITY = 'BY_CAPACITY',
  FIXED_QUANTITY = 'FIXED_QUANTITY',
  STOCK_UOM_QUANTITY = 'STOCK_UOM_QUANTITY',
}

export enum ReallocateType {
  NONE = 'NONE',
  REALLOCATE = 'REALLOCATE',
}

export enum ReallocateUomSource {
  CALCULATED_STOCK_UOM = 'CALCULATED_STOCK_UOM',
  CAPACITY_UOM = 'CAPACITY_UOM',
  FIXED_UOM = 'FIXED_UOM',
  STOCK_UOM = 'STOCK_UOM',
}

export enum ReassignJobCycleMode {
  EXCHANGE = 'EXCHANGE',
  STASH = 'STASH',
}

export type ReassignJobDetails = {
  __typename?: 'ReassignJobDetails';
  chainNumber?: Maybe<Scalars['Float']['output']>;
  estimatedCostImprovement?: Maybe<Scalars['Float']['output']>;
  groupId?: Maybe<Scalars['ID']['output']>;
  moves: Array<ReassignJobMove>;
  reasons?: Maybe<Array<AssignmentChangeReason>>;
  sequenceNumber?: Maybe<Scalars['Float']['output']>;
};

export type ReassignJobMove = {
  __typename?: 'ReassignJobMove';
  chainNumber?: Maybe<Scalars['Float']['output']>;
  consignee: Scalars['ID']['output'];
  destAction?: Maybe<AssignmentChangeSequenceAction>;
  destCapacity?: Maybe<AssignmentItemCapacity>;
  destLocationId?: Maybe<Scalars['ID']['output']>;
  estimatedCostImprovement?: Maybe<Scalars['Float']['output']>;
  groupId?: Maybe<Scalars['ID']['output']>;
  item?: Maybe<ReassignJobMoveItem>;
  itemId: Scalars['ID']['output'];
  moveId?: Maybe<Scalars['ID']['output']>;
  movedQuantity?: Maybe<AssignmentItemCapacity>;
  reason?: Maybe<AssignmentChangeReason>;
  sequenceNumber?: Maybe<Scalars['Float']['output']>;
  sku: Scalars['ID']['output'];
  srcCapacity?: Maybe<AssignmentItemCapacity>;
  srcLocationId?: Maybe<Scalars['ID']['output']>;
};

export type ReassignJobMoveItem = {
  __typename?: 'ReassignJobMoveItem';
  baseUom?: Maybe<BaseUom>;
  consignee: Scalars['ID']['output'];
  cumulativePercentRank?: Maybe<Scalars['Float']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  details?: Maybe<OrderSetItemDetails>;
  id: Scalars['ID']['output'];
  locationIds?: Maybe<Array<Scalars['ID']['output']>>;
  name?: Maybe<Scalars['String']['output']>;
  orderLineCount: Scalars['Float']['output'];
  percentRank?: Maybe<Scalars['Float']['output']>;
  pollutionClass?: Maybe<Scalars['String']['output']>;
  quantityOrdered: Scalars['Float']['output'];
  sku: Scalars['ID']['output'];
  skuGroup?: Maybe<Scalars['String']['output']>;
  stockCategory?: Maybe<Scalars['String']['output']>;
  storageClass?: Maybe<Scalars['String']['output']>;
  subGroup?: Maybe<Scalars['String']['output']>;
  transportClass?: Maybe<Scalars['String']['output']>;
};

/** Controls reassign cost calculation during optimize */
export type ReassignJobsSettings = {
  __typename?: 'ReassignJobsSettings';
  cycleMode?: Maybe<ReassignJobCycleMode>;
  /** Do not include reassign cost into account when optimizing */
  disabled?: Maybe<Scalars['Boolean']['output']>;
};

/** Controls reassign cost calculation during optimize */
export type ReassignJobsSettingsInput = {
  cycleMode?: InputMaybe<ReassignJobCycleMode>;
  /** Do not include reassign cost into account when optimizing */
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum ReassignTaskAction {
  PUT = 'PUT',
  TAKE = 'TAKE',
}

export type RemoveImportJobOptions = {
  removeImportedData?: Scalars['Boolean']['input'];
  removeInputFile?: Scalars['Boolean']['input'];
  removeJob?: Scalars['Boolean']['input'];
};

export type RemoveIntegrationUserInput = {
  integrationUserId: Scalars['ID']['input'];
};

export type ReorderEventSettings = {
  __typename?: 'ReorderEventSettings';
  timeFixed?: Maybe<Scalars['Float']['output']>;
  timePerReorderedPosition?: Maybe<Scalars['Float']['output']>;
  timePerUom?: Maybe<Array<UomHandlingSettings>>;
  timePerVolume?: Maybe<Scalars['Float']['output']>;
  timePerWeight?: Maybe<Scalars['Float']['output']>;
};

export type ReorderEventSettingsInput = {
  timeFixed?: InputMaybe<Scalars['Float']['input']>;
  timePerReorderedPosition?: InputMaybe<Scalars['Float']['input']>;
  timePerUom?: InputMaybe<Array<UomHandlingSettingsInput>>;
  timePerVolume?: InputMaybe<Scalars['Float']['input']>;
  timePerWeight?: InputMaybe<Scalars['Float']['input']>;
};

export type ReplenishmentJobDetails = {
  __typename?: 'ReplenishmentJobDetails';
  lines: Array<ReplenishmentJobLineDetails>;
};

export type ReplenishmentJobLineDetails = {
  __typename?: 'ReplenishmentJobLineDetails';
  destLocationId: Scalars['ID']['output'];
  itemId: Scalars['ID']['output'];
  quantity: Scalars['Float']['output'];
  uom?: Maybe<Scalars['String']['output']>;
  uomQuantity: Scalars['Float']['output'];
};

export type ResourcePolicy = {
  __typename?: 'ResourcePolicy';
  agents: Array<AgentSettings>;
};

export type ResourcePolicyInput = {
  agents: Array<AgentSettingsInput>;
};

export type ResourceUsageSummary = {
  __typename?: 'ResourceUsageSummary';
  key: ResourceUsageSummaryKey;
  totalCost: Scalars['Float']['output'];
  totalDuration: Scalars['Float']['output'];
  totalEvents: Scalars['Float']['output'];
  totalOrders: Scalars['Float']['output'];
  totalPicklistLines: Scalars['Float']['output'];
  totalPicklists: Scalars['Float']['output'];
};

export type ResourceUsageSummaryFilter = {
  agent?: InputMaybe<Array<Scalars['ID']['input']>>;
  eventType?: InputMaybe<Array<PicklistEventType>>;
  orderLineRuleId?: InputMaybe<Array<Scalars['ID']['input']>>;
  pickByDateFrom?: InputMaybe<Scalars['ID']['input']>;
  pickByDateTo?: InputMaybe<Scalars['ID']['input']>;
  picklistGroupKey?: InputMaybe<Array<Scalars['ID']['input']>>;
  planeId?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export enum ResourceUsageSummaryGroupBy {
  AGENT = 'AGENT',
  EVENT_TYPE = 'EVENT_TYPE',
  ORDER_LINE_RULE = 'ORDER_LINE_RULE',
  PICKLIST_GROUP_KEY = 'PICKLIST_GROUP_KEY',
  PICK_BY_DATE = 'PICK_BY_DATE',
  PLANE = 'PLANE',
}

export type ResourceUsageSummaryKey = {
  __typename?: 'ResourceUsageSummaryKey';
  agent?: Maybe<Scalars['ID']['output']>;
  eventType?: Maybe<PicklistEventType>;
  orderLineRuleId?: Maybe<Scalars['ID']['output']>;
  pickByDate?: Maybe<Scalars['ID']['output']>;
  picklistGroupKey?: Maybe<Scalars['ID']['output']>;
  planeId?: Maybe<Scalars['ID']['output']>;
};

export type RestartAllocationRunInput = {
  allocationRunId: Scalars['ID']['input'];
  allocationRunType?: InputMaybe<AllocationType>;
  allocationSettings?: InputMaybe<AllocationSettingsInput>;
  engine?: InputMaybe<AllocateJobEngine>;
  seed?: InputMaybe<Scalars['Float']['input']>;
  updateSettingsFromSimulation?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RoutingPolicy = {
  __typename?: 'RoutingPolicy';
  defaultRule?: Maybe<RoutingPolicyRule>;
  rules?: Maybe<Array<RoutingPolicyAgentRule>>;
  threshold?: Maybe<RoutingPolicyDirectionThreshold>;
};

export type RoutingPolicyAgentRule = {
  __typename?: 'RoutingPolicyAgentRule';
  agentIds: Array<Scalars['ID']['output']>;
  featureRules?: Maybe<Array<RoutingPolicyFeatureRule>>;
  id?: Maybe<Scalars['ID']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type RoutingPolicyAgentRuleInput = {
  agentIds: Array<Scalars['ID']['input']>;
  featureRules?: InputMaybe<Array<RoutingPolicyFeatureRuleInput>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type RoutingPolicyDirectionThreshold = {
  __typename?: 'RoutingPolicyDirectionThreshold';
  angle?: Maybe<Scalars['Float']['output']>;
  distance?: Maybe<Scalars['Float']['output']>;
};

export type RoutingPolicyDirectionThresholdInput = {
  angle?: InputMaybe<Scalars['Float']['input']>;
  distance?: InputMaybe<Scalars['Float']['input']>;
};

export type RoutingPolicyFeatureRule = {
  __typename?: 'RoutingPolicyFeatureRule';
  direction?: Maybe<Vector>;
  featureId: Scalars['ID']['output'];
  threshold?: Maybe<RoutingPolicyDirectionThreshold>;
};

export type RoutingPolicyFeatureRuleInput = {
  direction?: InputMaybe<VectorInput>;
  featureId: Scalars['ID']['input'];
  threshold?: InputMaybe<RoutingPolicyDirectionThresholdInput>;
};

export type RoutingPolicyInput = {
  defaultRule?: InputMaybe<RoutingPolicyRuleInput>;
  rules?: InputMaybe<Array<RoutingPolicyAgentRuleInput>>;
  threshold?: InputMaybe<RoutingPolicyDirectionThresholdInput>;
};

export type RoutingPolicyRule = {
  __typename?: 'RoutingPolicyRule';
  featureRules?: Maybe<Array<RoutingPolicyFeatureRule>>;
};

export type RoutingPolicyRuleInput = {
  featureRules?: InputMaybe<Array<RoutingPolicyFeatureRuleInput>>;
};

export type SaveLayoutImportConverterStateInput = {
  id: Scalars['ID']['input'];
  settings?: InputMaybe<Scalars['JSON']['input']>;
};

export type ScheduleGateUsageInput = {
  analyzeResultId: Scalars['ID']['input'];
  dockingDuration?: InputMaybe<Scalars['Float']['input']>;
  loadingAgentSettings?: InputMaybe<AgentRoleSettingsInput>;
  startTime?: InputMaybe<Scalars['LocalDateTime']['input']>;
  undockingDuration?: InputMaybe<Scalars['Float']['input']>;
  waitCost: Scalars['Float']['input'];
};

export type Segment = {
  __typename?: 'Segment';
  end: Point;
  start: Point;
};

export type Segment1D = {
  __typename?: 'Segment1D';
  end: Scalars['Float']['output'];
  start: Scalars['Float']['output'];
};

export type Segment1DInput = {
  end: Scalars['Float']['input'];
  start: Scalars['Float']['input'];
};

export type SegmentInput = {
  end: PointInput;
  start: PointInput;
};

export type SendExportJobInput = {
  exportJobId: Scalars['ID']['input'];
  templateData: Scalars['JSON']['input'];
  to: Array<Scalars['String']['input']>;
};

export type SendUserInvitationLinkInput = {
  email: Scalars['String']['input'];
  enforceMfa?: InputMaybe<Scalars['Boolean']['input']>;
  warehouse: Array<SendUserInvitationLinkWarehouse>;
};

export type SendUserInvitationLinkWarehouse = {
  id: Scalars['ID']['input'];
  readonly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SimpleFilterMatch = {
  __typename?: 'SimpleFilterMatch';
  count: Scalars['Float']['output'];
};

export type Simulation = {
  __typename?: 'Simulation';
  allocationRequirementSet?: Maybe<AllocationRequirementSet>;
  allocationRuns: AllocationRunsConnection;
  allocationSettings?: Maybe<AllocationSettings>;
  analyzeResult?: Maybe<AnalyzeResult>;
  assignment?: Maybe<Assignment>;
  assignmentCapacityPolicy?: Maybe<AssignmentCapacityPolicy>;
  assignmentCompliance?: Maybe<AssignmentCompliance>;
  assignmentDetails: Array<AssignmentDetailsItem>;
  assignmentIssues?: Maybe<AssignmentIssueSet>;
  assignmentOccupancy?: Maybe<AssignmentOccupancy>;
  assignmentPolicy?: Maybe<AssignmentPolicy>;
  createdAt: Scalars['Timestamp']['output'];
  description?: Maybe<Scalars['String']['output']>;
  effectiveItemSet?: Maybe<SimulationItemSet>;
  extraSettings?: Maybe<Scalars['JSON']['output']>;
  generatedPicklists?: Maybe<GeneratePicklistsJob>;
  id: Scalars['ID']['output'];
  itemSet?: Maybe<ItemSet>;
  itemSetIssues?: Maybe<ItemSetIssueSet>;
  latestAllocationRun?: Maybe<AllocationRun>;
  latestOptimizationRun?: Maybe<OptimizationRun>;
  layout?: Maybe<Layout>;
  layoutIssues?: Maybe<LayoutIssueSet>;
  locationSharingPolicy?: Maybe<LocationSharingPolicy>;
  optimizationRuns: OptimizationRunsConnection;
  optimizationSettings?: Maybe<OptimizationSettings>;
  orderLineSet?: Maybe<SimulationOrderLineSet>;
  orderSet?: Maybe<OrderSet>;
  orderSetIssues?: Maybe<OrderSetIssueSet>;
  orderedQuantityReport?: Maybe<OrderedQuantityReport>;
  pickingPolicy?: Maybe<PickingPolicy>;
  presentationSettings?: Maybe<PresentationSettings>;
  resourcePolicy?: Maybe<ResourcePolicy>;
  routingPolicy?: Maybe<RoutingPolicy>;
  sizeCompliance?: Maybe<SizeCompliance>;
  stackingPolicy?: Maybe<StackingPolicy>;
  status: SimulationStatus;
  title: Scalars['String']['output'];
  updatedAt: Scalars['Timestamp']['output'];
  userTags?: Maybe<Array<Scalars['String']['output']>>;
  warehouse: Warehouse;
  waypointPolicy?: Maybe<WaypointPolicy>;
  weightCompliance?: Maybe<WeightCompliance>;
};

export type SimulationAllocationRunsArgs = {
  filter?: InputMaybe<AllocationRunsFilter>;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<AllocationRunsSortBy>>;
};

export type SimulationAssignmentComplianceArgs = {
  filter?: InputMaybe<SimulationAssignmentComplianceFilter>;
};

export type SimulationAssignmentDetailsArgs = {
  options?: InputMaybe<SimulationAssignmentDetailsOptions>;
  sortBy?: Array<AssignmentDetailsItemsSortBy>;
};

export type SimulationAssignmentOccupancyArgs = {
  filter?: InputMaybe<SimulationAssignmentOccupancyFilter>;
};

export type SimulationLatestAllocationRunArgs = {
  filter?: InputMaybe<AllocationRunsFilter>;
};

export type SimulationOptimizationRunsArgs = {
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<OptimizationRunsSortBy>>;
};

export type SimulationAssignmentComplianceFilter = {
  assignmentId?: InputMaybe<Scalars['ID']['input']>;
};

export type SimulationAssignmentDetailsOptions = {
  assignmentId?: InputMaybe<Scalars['ID']['input']>;
};

export type SimulationAssignmentOccupancyFilter = {
  assignmentId?: InputMaybe<Scalars['ID']['input']>;
};

export type SimulationAssignmentPolicySummaryOptions = {
  includeUnassigned?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SimulationFilter = {
  title?: InputMaybe<StringSearchFilter>;
  userTags?: InputMaybe<Array<Scalars['String']['input']>>;
  userTagsHasAll?: InputMaybe<Array<Scalars['String']['input']>>;
  userTagsHasAny?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type SimulationItem = {
  __typename?: 'SimulationItem';
  baseUom?: Maybe<BaseUom>;
  consignee: Scalars['ID']['output'];
  cumulativePercentRank?: Maybe<Scalars['Float']['output']>;
  details?: Maybe<OrderSetItemDetails>;
  id: Scalars['ID']['output'];
  locationIds?: Maybe<Array<Scalars['ID']['output']>>;
  orderLineCount: Scalars['Float']['output'];
  percentRank?: Maybe<Scalars['Float']['output']>;
  pollutionClass?: Maybe<Scalars['String']['output']>;
  quantityOrdered: Scalars['Float']['output'];
  sku: Scalars['ID']['output'];
  skuGroup?: Maybe<Scalars['String']['output']>;
  stockCategory?: Maybe<Scalars['String']['output']>;
  storageClass?: Maybe<Scalars['String']['output']>;
  subGroup?: Maybe<Scalars['String']['output']>;
  transportClass?: Maybe<Scalars['String']['output']>;
};

export type SimulationItemFilter = {
  __typename?: 'SimulationItemFilter';
  isNot?: Maybe<Scalars['Boolean']['output']>;
  isNull?: Maybe<Scalars['Boolean']['output']>;
  range?: Maybe<NumberFilterRange>;
  stringRange?: Maybe<StringFilterRange>;
  type: SimulationItemFilterType;
  valueIn?: Maybe<Array<FilterValue>>;
};

export type SimulationItemFilterInput = {
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  range?: InputMaybe<NumberFilterRangeInput>;
  stringRange?: InputMaybe<StringFilterRangeInput>;
  type: SimulationItemFilterType;
  valueIn?: InputMaybe<Array<FilterValueInput>>;
};

export type SimulationItemFilterIntersection = {
  __typename?: 'SimulationItemFilterIntersection';
  allOf: Array<SimulationItemFilter>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type SimulationItemFilterIntersectionInput = {
  allOf: Array<SimulationItemFilterInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export enum SimulationItemFilterType {
  ASSIGNMENT_COUNT = 'ASSIGNMENT_COUNT',
  CONSIGNEE = 'CONSIGNEE',
  CUMULATIVE_PERCENT_RANK = 'CUMULATIVE_PERCENT_RANK',
  DAILY_ORDER_LINE_COUNT_AVG = 'DAILY_ORDER_LINE_COUNT_AVG',
  DAILY_ORDER_LINE_COUNT_MAX = 'DAILY_ORDER_LINE_COUNT_MAX',
  HEIGHT = 'HEIGHT',
  LENGTH = 'LENGTH',
  NET_WEIGHT = 'NET_WEIGHT',
  PERCENT_RANK = 'PERCENT_RANK',
  POLLUTION_CLASS = 'POLLUTION_CLASS',
  SKU = 'SKU',
  SKU_GROUP = 'SKU_GROUP',
  STOCK_CATEGORY = 'STOCK_CATEGORY',
  STORAGE_CLASS = 'STORAGE_CLASS',
  SUB_GROUP = 'SUB_GROUP',
  TOTAL_ORDER_LINE_COUNT = 'TOTAL_ORDER_LINE_COUNT',
  TOTAL_QUANTITY_ORDERED = 'TOTAL_QUANTITY_ORDERED',
  TRANSPORT_CLASS = 'TRANSPORT_CLASS',
  VOLUME = 'VOLUME',
  WEEKLY_DAYS_WITH_ORDERS_AVG = 'WEEKLY_DAYS_WITH_ORDERS_AVG',
  WEEKLY_DAYS_WITH_ORDERS_MAX = 'WEEKLY_DAYS_WITH_ORDERS_MAX',
  WEEKLY_ORDER_LINE_COUNT_AVG = 'WEEKLY_ORDER_LINE_COUNT_AVG',
  WEEKLY_ORDER_LINE_COUNT_MAX = 'WEEKLY_ORDER_LINE_COUNT_MAX',
  WIDTH = 'WIDTH',
}

export type SimulationItemFilterUnion = {
  __typename?: 'SimulationItemFilterUnion';
  anyOf: Array<SimulationItemFilterIntersection>;
};

export type SimulationItemFilterUnionInput = {
  anyOf: Array<SimulationItemFilterIntersectionInput>;
};

export type SimulationItemSet = {
  __typename?: 'SimulationItemSet';
  completedAt?: Maybe<Scalars['Timestamp']['output']>;
  createdAt: Scalars['Timestamp']['output'];
  id: Scalars['ID']['output'];
  itemFilterValues: FilterValuesConnection;
  itemsByCategory?: Maybe<SimulationItemsByCategoryConnection>;
  itemsByFilter: SimulationItemsConnection;
  progress: Scalars['Float']['output'];
  startedAt?: Maybe<Scalars['Timestamp']['output']>;
  status: BatchJobStatus;
  updatedAt: Scalars['Timestamp']['output'];
};

export type SimulationItemSetItemFilterValuesArgs = {
  input: FindEffectiveItemFilterValuesInput;
  page?: InputMaybe<SkipLimitPageSpec>;
};

export type SimulationItemSetItemsByCategoryArgs = {
  categories: SimulationItemsByCategoryRequest;
};

export type SimulationItemSetItemsByFilterArgs = {
  filter?: InputMaybe<ItemsFilter>;
  input: FindEffectiveItemsByFilter;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<ItemsSortBy>>;
};

export type SimulationItemsByCategoryConnection = {
  __typename?: 'SimulationItemsByCategoryConnection';
  categories: Array<SimulationItemsCategory>;
  totalCount: Scalars['Float']['output'];
  totalOrderLineCount: Scalars['Float']['output'];
};

export type SimulationItemsByCategoryRequest = {
  categoryType?: SimulationItemsCategoryType;
  categoryWidths: Array<Scalars['Float']['input']>;
};

export type SimulationItemsCategory = {
  __typename?: 'SimulationItemsCategory';
  count: Scalars['Float']['output'];
  limit: Scalars['Float']['output'];
  maxBoundary?: Maybe<Scalars['Float']['output']>;
  maxCumulativePercentRank?: Maybe<Scalars['Float']['output']>;
  maxPercentRank?: Maybe<Scalars['Float']['output']>;
  minBoundary?: Maybe<Scalars['Float']['output']>;
  minCumulativePercentRank?: Maybe<Scalars['Float']['output']>;
  minPercentRank?: Maybe<Scalars['Float']['output']>;
  products: Array<SimulationItem>;
  skip: Scalars['Float']['output'];
  totalOrderLineCount: Scalars['Float']['output'];
};

export enum SimulationItemsCategoryType {
  CUMULATIVE_PERCENT_RANK = 'CUMULATIVE_PERCENT_RANK',
  PERCENT_RANK = 'PERCENT_RANK',
}

export type SimulationItemsConnection = {
  __typename?: 'SimulationItemsConnection';
  /** Content of collection or the current page of collection if pagination is used */
  content: Array<SimulationItem>;
  /** Total number of elements in this collection (ignoring pagination constraints, if any) */
  totalCount: Scalars['Float']['output'];
};

export type SimulationOrderLine = {
  __typename?: 'SimulationOrderLine';
  baseUom?: Maybe<BaseUom>;
  consignee: Scalars['ID']['output'];
  customer?: Maybe<Scalars['ID']['output']>;
  deliveryRoute?: Maybe<Scalars['ID']['output']>;
  dock?: Maybe<Scalars['ID']['output']>;
  itemCumulativePercentRank?: Maybe<Scalars['Float']['output']>;
  itemId: Scalars['ID']['output'];
  itemPercentRank?: Maybe<Scalars['Float']['output']>;
  loadByDate?: Maybe<Scalars['LocalDateTime']['output']>;
  orderDate?: Maybe<Scalars['LocalDateTime']['output']>;
  orderId: Scalars['ID']['output'];
  orderLine: Scalars['Float']['output'];
  orderLineCount: Scalars['Float']['output'];
  orderLineId: Scalars['ID']['output'];
  orderLineQuantity: Scalars['Float']['output'];
  orderLineVolume?: Maybe<Scalars['Float']['output']>;
  orderLineWeight?: Maybe<Scalars['Float']['output']>;
  orderTotalVolume?: Maybe<Scalars['Float']['output']>;
  orderTotalWeight?: Maybe<Scalars['Float']['output']>;
  pickingContainer?: Maybe<Scalars['ID']['output']>;
  pickingPriority?: Maybe<Scalars['ID']['output']>;
  pollutionClass?: Maybe<Scalars['String']['output']>;
  shipmentId?: Maybe<Scalars['ID']['output']>;
  sku: Scalars['ID']['output'];
  skuGroup?: Maybe<Scalars['String']['output']>;
  stagingArea?: Maybe<Scalars['ID']['output']>;
  stockCategory?: Maybe<Scalars['String']['output']>;
  storageClass?: Maybe<Scalars['String']['output']>;
  subGroup?: Maybe<Scalars['String']['output']>;
  transportClass?: Maybe<Scalars['String']['output']>;
  waveId?: Maybe<Scalars['ID']['output']>;
};

export type SimulationOrderLineSet = {
  __typename?: 'SimulationOrderLineSet';
  completedAt?: Maybe<Scalars['Timestamp']['output']>;
  createdAt: Scalars['Timestamp']['output'];
  id: Scalars['ID']['output'];
  progress: Scalars['Float']['output'];
  startedAt?: Maybe<Scalars['Timestamp']['output']>;
  status: BatchJobStatus;
  updatedAt: Scalars['Timestamp']['output'];
};

export type SimulationOrderLinesConnection = {
  __typename?: 'SimulationOrderLinesConnection';
  /** Content of collection or the current page of collection if pagination is used */
  content: Array<SimulationOrderLine>;
  /** Total number of elements in this collection (ignoring pagination constraints, if any) */
  totalCount: Scalars['Float']['output'];
};

export type SimulationOrderLinesSortBy = {
  /** Sorting direction */
  direction?: SortDirection;
  /** Field (sort option) to sort by */
  field: SimulationOrderLinesSortOptions;
};

export enum SimulationOrderLinesSortOptions {
  CONSIGNEE = 'CONSIGNEE',
  ORDER_ID = 'ORDER_ID',
  ORDER_LINE = 'ORDER_LINE',
}

export enum SimulationStatus {
  ANALYZED = 'ANALYZED',
  DRAFT = 'DRAFT',
  OPTIMIZED = 'OPTIMIZED',
}

export type SimulationsConnection = {
  __typename?: 'SimulationsConnection';
  allUserTags: Array<Scalars['String']['output']>;
  /** Content of collection or the current page of collection if pagination is used */
  content: Array<Simulation>;
  /** Total number of elements in this collection (ignoring pagination constraints, if any) */
  totalCount: Scalars['Float']['output'];
};

export type SimulationsSortBy = {
  /** Sorting direction */
  direction?: SortDirection;
  /** Field (sort option) to sort by */
  field: BaseEntitySortOption;
};

export type SizeCompliance = {
  __typename?: 'SizeCompliance';
  assignment: Assignment;
  completedAt?: Maybe<Scalars['Timestamp']['output']>;
  createdAt: Scalars['Timestamp']['output'];
  id: Scalars['ID']['output'];
  itemSet: ItemSet;
  layout: Layout;
  locations: SizeComplianceLocationsConnection;
  progress: Scalars['Float']['output'];
  simulation: Simulation;
  startedAt?: Maybe<Scalars['Timestamp']['output']>;
  status: BatchJobStatus;
  summary?: Maybe<SizeComplianceSummary>;
  updatedAt: Scalars['Timestamp']['output'];
  warehouse: Warehouse;
};

export type SizeComplianceLocationsArgs = {
  filter?: InputMaybe<SizeComplianceLocationFilter>;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<SizeComplianceLocationSortBy>>;
};

export type SizeComplianceLocationDetails = {
  __typename?: 'SizeComplianceLocationDetails';
  items: Array<SizeComplianceLocationItem>;
  location: LayoutLocation;
  locationId: Scalars['ID']['output'];
  status: SizeComplianceStatus;
};

export type SizeComplianceLocationFilter = {
  bayId?: InputMaybe<Array<Scalars['ID']['input']>>;
  level?: InputMaybe<Array<Scalars['Float']['input']>>;
  locationAisleTitle?: InputMaybe<StringSearchFilter>;
  locationBayPosition?: InputMaybe<NumberSearchFilter>;
  locationBayTitle?: InputMaybe<StringSearchFilter>;
  locationHeight?: InputMaybe<NumberSearchFilter>;
  locationId?: InputMaybe<StringSearchFilter>;
  locationLength?: InputMaybe<NumberSearchFilter>;
  locationLevel?: InputMaybe<NumberSearchFilter>;
  locationOrder?: InputMaybe<NumberSearchFilter>;
  locationPlaneTitle?: InputMaybe<StringSearchFilter>;
  locationRackingType?: InputMaybe<StringSearchFilter>;
  locationStatus?: InputMaybe<BooleanSearchFilter>;
  locationUsageType?: InputMaybe<StringSearchFilter>;
  locationWeight?: InputMaybe<NumberSearchFilter>;
  locationWidth?: InputMaybe<NumberSearchFilter>;
  locmhtype?: InputMaybe<StringSearchFilter>;
  planeId?: InputMaybe<Array<Scalars['ID']['input']>>;
  status?: InputMaybe<Array<SizeComplianceStatus>>;
  warehouseArea?: InputMaybe<StringSearchFilter>;
};

export type SizeComplianceLocationItem = {
  __typename?: 'SizeComplianceLocationItem';
  consignee: Scalars['ID']['output'];
  itemId: Scalars['ID']['output'];
  sku: Scalars['ID']['output'];
  status: SizeComplianceStatus;
  uom?: Maybe<ItemUomDimensions>;
};

export type SizeComplianceLocationSortBy = {
  /** Sorting direction */
  direction?: SortDirection;
  /** Field (sort option) to sort by */
  field: SizeComplianceLocationSortOption;
};

export enum SizeComplianceLocationSortOption {
  LOCATION_ID = 'LOCATION_ID',
}

export type SizeComplianceLocationsConnection = {
  __typename?: 'SizeComplianceLocationsConnection';
  /** Content of collection or the current page of collection if pagination is used */
  content: Array<SizeComplianceLocationDetails>;
  /** Total number of elements in this collection (ignoring pagination constraints, if any) */
  totalCount: Scalars['Float']['output'];
};

export enum SizeComplianceStatus {
  COMPLIANT = 'COMPLIANT',
  NONCOMPLIANT = 'NONCOMPLIANT',
  UNASSIGNED = 'UNASSIGNED',
  UNKNOWN = 'UNKNOWN',
}

export type SizeComplianceSummary = {
  __typename?: 'SizeComplianceSummary';
  items: Array<SizeComplianceSummaryItem>;
};

export type SizeComplianceSummaryItem = {
  __typename?: 'SizeComplianceSummaryItem';
  compliantCount: Scalars['Float']['output'];
  level: Scalars['Float']['output'];
  nonCompliantCount: Scalars['Float']['output'];
  planeId: Scalars['ID']['output'];
  totalCount: Scalars['Float']['output'];
  unassignedCount: Scalars['Float']['output'];
  unknownCount: Scalars['Float']['output'];
};

/** Pagination specification with 'skip' and 'limit' values */
export type SkipLimitPageSpec = {
  /** Max number of objects to retrieve */
  limit?: InputMaybe<Scalars['Float']['input']>;
  /** Number of objects to skip */
  skip?: InputMaybe<Scalars['Float']['input']>;
};

/** Sorting direction */
export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type SourceEventIgnoredDetails = {
  __typename?: 'SourceEventIgnoredDetails';
  reason: SourceEventIgnoredReason;
  sourceEventType?: Maybe<Scalars['ID']['output']>;
};

export enum SourceEventIgnoredReason {
  INVALID_LOCATION_FOR_JOB = 'INVALID_LOCATION_FOR_JOB',
  MULTIPLE_JOB_ENDS = 'MULTIPLE_JOB_ENDS',
  MULTIPLE_JOB_STARTS = 'MULTIPLE_JOB_STARTS',
  NO_QUANTITY = 'NO_QUANTITY',
  UNKNOWN_AGENT = 'UNKNOWN_AGENT',
  UNKNOWN_LOCATION = 'UNKNOWN_LOCATION',
}

export type SpeedSettings = {
  __typename?: 'SpeedSettings';
  speedLaden?: Maybe<Scalars['Float']['output']>;
  speedUnladen?: Maybe<Scalars['Float']['output']>;
};

export type SpeedSettingsInput = {
  speedLaden?: InputMaybe<Scalars['Float']['input']>;
  speedUnladen?: InputMaybe<Scalars['Float']['input']>;
};

export type SrcBay = {
  __typename?: 'SrcBay';
  aisleId?: Maybe<Scalars['ID']['output']>;
  bayType?: Maybe<Scalars['ID']['output']>;
  depth: Scalars['Float']['output'];
  frontEdge: Vector;
  hasPass: Scalars['Boolean']['output'];
  height?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  levels?: Maybe<Array<BayLevel>>;
  locationOrder: BayLocationOrder;
  position: Point;
  title?: Maybe<Scalars['String']['output']>;
  width: Scalars['Float']['output'];
};

export type SrcBayInput = {
  aisleId?: InputMaybe<Scalars['ID']['input']>;
  bayType?: InputMaybe<Scalars['ID']['input']>;
  depth: Scalars['Float']['input'];
  frontEdge: VectorInput;
  hasPass: Scalars['Boolean']['input'];
  height?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['ID']['input'];
  levels?: InputMaybe<Array<BayLevelInput>>;
  locationOrder: BayLocationOrder;
  position: PointInput;
  title?: InputMaybe<Scalars['String']['input']>;
  width: Scalars['Float']['input'];
};

export type SrcLayoutMapInput = {
  bayTypes?: InputMaybe<Array<BayTypeModelInput>>;
  locationTypes?: InputMaybe<Array<LocationTypeModelInput>>;
  planes: Array<SrcPlaneInput>;
};

export type SrcPlane = {
  __typename?: 'SrcPlane';
  aisles: Array<Aisle>;
  bays: Array<SrcBay>;
  end?: Maybe<PlaneAccessSpec>;
  id: Scalars['ID']['output'];
  start?: Maybe<PlaneAccessSpec>;
  title?: Maybe<Scalars['String']['output']>;
};

export type SrcPlaneInput = {
  aisles: Array<AisleInput>;
  bays: Array<SrcBayInput>;
  end?: InputMaybe<PlaneAccessSpecInput>;
  id: Scalars['ID']['input'];
  start?: InputMaybe<PlaneAccessSpecInput>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type StackingPolicy = {
  __typename?: 'StackingPolicy';
  /** @deprecated in favour of bottomRules */
  bottom?: Maybe<StackingPolicyRule>;
  bottomRules?: Maybe<Array<StackingPolicyRule>>;
  fallbackRule?: Maybe<StackingPolicyFallbackRule>;
  /** @deprecated in favour of topRules */
  top?: Maybe<StackingPolicyRule>;
  topRules?: Maybe<Array<StackingPolicyRule>>;
  /** @deprecated in favour of fallbackRule.subcategories.boundaries */
  totalNetWeightRange?: Maybe<NumberFilterRange>;
};

export type StackingPolicyFallbackRule = {
  __typename?: 'StackingPolicyFallbackRule';
  subcategories?: Maybe<StackingPolicyRuleSubcategories>;
};

export type StackingPolicyFallbackRuleInput = {
  subcategories?: InputMaybe<StackingPolicyRuleSubcategoriesInput>;
};

export type StackingPolicyInput = {
  bottom?: InputMaybe<StackingPolicyRuleInput>;
  bottomRules?: InputMaybe<Array<StackingPolicyRuleInput>>;
  fallbackRule?: InputMaybe<StackingPolicyFallbackRuleInput>;
  top?: InputMaybe<StackingPolicyRuleInput>;
  topRules?: InputMaybe<Array<StackingPolicyRuleInput>>;
  totalNetWeightRange?: InputMaybe<NumberFilterRangeInput>;
};

export type StackingPolicyRule = {
  __typename?: 'StackingPolicyRule';
  id: Scalars['ID']['output'];
  itemsMatch?: Maybe<ItemFilterUnion>;
  subcategories?: Maybe<StackingPolicyRuleSubcategories>;
  title?: Maybe<Scalars['String']['output']>;
};

export type StackingPolicyRuleInput = {
  id: Scalars['ID']['input'];
  itemsMatch?: InputMaybe<ItemFilterUnionInput>;
  subcategories?: InputMaybe<StackingPolicyRuleSubcategoriesInput>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type StackingPolicyRuleSubcategories = {
  __typename?: 'StackingPolicyRuleSubcategories';
  boundaries?: Maybe<Array<Scalars['Float']['output']>>;
  step?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<StackingPolicyRuleSubcategoryType>;
};

export type StackingPolicyRuleSubcategoriesInput = {
  boundaries?: InputMaybe<Array<Scalars['Float']['input']>>;
  step?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<StackingPolicyRuleSubcategoryType>;
};

export enum StackingPolicyRuleSubcategoryType {
  BASE_UOM_WEIGHT = 'BASE_UOM_WEIGHT',
  PICKED_UOM_WEIGHT = 'PICKED_UOM_WEIGHT',
  PICKLIST_LINE_WEIGHT = 'PICKLIST_LINE_WEIGHT',
}

export type StartImportJobProcessingOptions = {
  engine?: InputMaybe<BatchJobEngine>;
  restart?: InputMaybe<Scalars['Boolean']['input']>;
  updateSettingsFromPipeline?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum StockUomQuantityCompliance {
  COMPLIANT = 'COMPLIANT',
  NON_COMPLIANT = 'NON_COMPLIANT',
  UNKNOWN = 'UNKNOWN',
}

export type StoredFile = {
  __typename?: 'StoredFile';
  downloadUrl: Scalars['String']['output'];
  lastModified: Scalars['Timestamp']['output'];
  name: Scalars['String']['output'];
  size: Scalars['Float']['output'];
  warehouseId: Scalars['ID']['output'];
};

export type StoredFileId = {
  __typename?: 'StoredFileId';
  name: Scalars['String']['output'];
  warehouseId: Scalars['ID']['output'];
};

export type StoredFileIdInput = {
  name: Scalars['String']['input'];
  warehouseId: Scalars['ID']['input'];
};

export type StringFilterRange = {
  __typename?: 'StringFilterRange';
  excludeFrom?: Maybe<Scalars['Boolean']['output']>;
  excludeTo?: Maybe<Scalars['Boolean']['output']>;
  from?: Maybe<Scalars['String']['output']>;
  to?: Maybe<Scalars['String']['output']>;
};

export type StringFilterRangeInput = {
  excludeFrom?: InputMaybe<Scalars['Boolean']['input']>;
  excludeTo?: InputMaybe<Scalars['Boolean']['input']>;
  from?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['String']['input']>;
};

export type StringSearchFilter = {
  type?: StringSearchFilterType;
  value: Scalars['String']['input'];
};

export enum StringSearchFilterType {
  CONTAINS = 'CONTAINS',
  EQ = 'EQ',
  EQ_CI = 'EQ_CI',
  STARTS_WITH = 'STARTS_WITH',
}

export enum SwapMode {
  ALL = 'ALL',
  OCCUPIED_ONLY = 'OCCUPIED_ONLY',
  USED_ONLY = 'USED_ONLY',
}

export type SwapSettings = {
  __typename?: 'SwapSettings';
  deadStock?: Maybe<DeadStockSwapMode>;
  rules?: Maybe<Array<OptimizationSwapRule>>;
  /** @deprecated in favour of detailed rules */
  swapMode?: Maybe<SwapMode>;
};

export type SwapSettingsInput = {
  deadStock?: InputMaybe<DeadStockSwapMode>;
  rules?: InputMaybe<Array<OptimizationSwapRuleInput>>;
  swapMode?: InputMaybe<SwapMode>;
};

export type TerminationSettings = {
  __typename?: 'TerminationSettings';
  /** Stop optimization when improvement rate is below the given one */
  minImprovementRate?: Maybe<MinImprovementRateSettings>;
  /** Stop optimization when this improvement is achieved (in percent) */
  targetImprovement?: Maybe<Scalars['Float']['output']>;
  /** Stop optimization after running for this time (in seconds) */
  timeLimit?: Maybe<Scalars['Float']['output']>;
};

export type TerminationSettingsInput = {
  /** Stop optimization when improvement rate is below the given one */
  minImprovementRate?: InputMaybe<MinImprovementRateSettingsInput>;
  /** Stop optimization when this improvement is achieved (in percent) */
  targetImprovement?: InputMaybe<Scalars['Float']['input']>;
  /** Stop optimization after running for this time (in seconds) */
  timeLimit?: InputMaybe<Scalars['Float']['input']>;
};

export type TestImportPipelineSftpConnectorInput = {
  host: Scalars['String']['input'];
  password: Scalars['String']['input'];
  path?: InputMaybe<Scalars['String']['input']>;
  pattern?: InputMaybe<Scalars['String']['input']>;
  port?: InputMaybe<Scalars['Int']['input']>;
  username: Scalars['String']['input'];
};

export type TestImportPipelineSftpConnectorResult = {
  __typename?: 'TestImportPipelineSftpConnectorResult';
  matchedFiles: Scalars['Int']['output'];
  totalFiles: Scalars['Int']['output'];
};

export type TimestampSearchFilter = {
  excludeFrom?: InputMaybe<Scalars['Boolean']['input']>;
  excludeTo?: InputMaybe<Scalars['Boolean']['input']>;
  from?: InputMaybe<Scalars['Timestamp']['input']>;
  to?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type TravellingHorizontalDetails = {
  __typename?: 'TravellingHorizontalDetails';
  distance: Scalars['Float']['output'];
  route: Array<PicklistEventWaypoint>;
  volume?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
};

export type TravellingVerticalDetails = {
  __typename?: 'TravellingVerticalDetails';
  distance: Scalars['Float']['output'];
  volume?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
};

export type TriggerProcessingPipelineInput = {
  overrideJobInput?: InputMaybe<Scalars['JSON']['input']>;
  pipelineId: Scalars['ID']['input'];
};

export enum UnassignedLocationsMatch {
  EXCLUDE = 'EXCLUDE',
  INCLUDE = 'INCLUDE',
  UNASSIGNED_ONLY = 'UNASSIGNED_ONLY',
}

export type UomHandlingDetails = {
  __typename?: 'UomHandlingDetails';
  uom?: Maybe<Scalars['String']['output']>;
  uomQuantity: Scalars['Float']['output'];
};

export type UomHandlingSettings = {
  __typename?: 'UomHandlingSettings';
  timePerUom?: Maybe<Scalars['Float']['output']>;
  uom: Scalars['ID']['output'];
};

export type UomHandlingSettingsInput = {
  timePerUom?: InputMaybe<Scalars['Float']['input']>;
  uom: Scalars['ID']['input'];
};

export type UomQuantity = {
  __typename?: 'UomQuantity';
  quantity: Scalars['Float']['output'];
  uom: UomWeight;
  uomQuantity: Scalars['Float']['output'];
};

export type UomWeight = {
  __typename?: 'UomWeight';
  netWeight?: Maybe<Scalars['Float']['output']>;
  unitsPerLowestUom?: Maybe<Scalars['Float']['output']>;
  uom: Scalars['ID']['output'];
  volume?: Maybe<Scalars['Float']['output']>;
};

export type UpdateActualityInput = {
  activityFeedId?: InputMaybe<Scalars['ID']['input']>;
  actualityId: Scalars['ID']['input'];
  assignmentCapacityPolicy?: InputMaybe<AssignmentCapacityPolicyInput>;
  assignmentId?: InputMaybe<Scalars['ID']['input']>;
  assignmentPolicy?: InputMaybe<AssignmentPolicyInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  extraSettings?: InputMaybe<Scalars['JSON']['input']>;
  itemSetId?: InputMaybe<Scalars['ID']['input']>;
  layoutId?: InputMaybe<Scalars['ID']['input']>;
  locationSharingPolicy?: InputMaybe<LocationSharingPolicyInput>;
  orderSetId?: InputMaybe<Scalars['ID']['input']>;
  pickingPolicy?: InputMaybe<PickingPolicyInput>;
  presentationSettings?: InputMaybe<PresentationSettingsInput>;
  resourcePolicy?: InputMaybe<ResourcePolicyInput>;
  routingPolicy?: InputMaybe<RoutingPolicyInput>;
  stackingPolicy?: InputMaybe<StackingPolicyInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  userTags?: InputMaybe<Array<Scalars['String']['input']>>;
  waypointPolicy?: InputMaybe<WaypointPolicyInput>;
};

export type UpdateAssignmentInput = {
  assignmentId: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['ID']['input']>;
  extraSettings?: InputMaybe<Scalars['JSON']['input']>;
  presentationSettings?: InputMaybe<PresentationSettingsInput>;
  title?: InputMaybe<Scalars['ID']['input']>;
  userTags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateDatasetObjectInput = {
  datasetObjectId: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['ID']['input']>;
  extraSettings?: InputMaybe<Scalars['JSON']['input']>;
  presentationSettings?: InputMaybe<PresentationSettingsInput>;
  title?: InputMaybe<Scalars['ID']['input']>;
  userTags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateImportJobInput = {
  extraSettings?: InputMaybe<Scalars['JSON']['input']>;
  id: Scalars['ID']['input'];
  settings?: InputMaybe<ImportJobMappingSettingsInput>;
  sourceSettings?: InputMaybe<ImportJobSourceSettingsInput>;
  targetSettings?: InputMaybe<ImportJobTargetSettingsInput>;
  targetTitle?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateImportPipelineInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  extraSettings?: InputMaybe<Scalars['JSON']['input']>;
  importPipelineId: Scalars['ID']['input'];
  settings?: InputMaybe<ImportPipelineSettingsInput>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateIntegrationUserInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  extraSettings?: InputMaybe<Scalars['JSON']['input']>;
  integrationUserId: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateItemSetInput = {
  description?: InputMaybe<Scalars['ID']['input']>;
  extraSettings?: InputMaybe<Scalars['JSON']['input']>;
  itemSetId: Scalars['ID']['input'];
  presentationSettings?: InputMaybe<PresentationSettingsInput>;
  stackingPolicy?: InputMaybe<StackingPolicyInput>;
  title?: InputMaybe<Scalars['ID']['input']>;
  userTags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateLayoutImportInput = {
  description?: InputMaybe<Scalars['ID']['input']>;
  extraSettings?: InputMaybe<Scalars['JSON']['input']>;
  layoutImportId: Scalars['ID']['input'];
  presentationSettings?: InputMaybe<PresentationSettingsInput>;
  title?: InputMaybe<Scalars['ID']['input']>;
  userTags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateLayoutInput = {
  description?: InputMaybe<Scalars['ID']['input']>;
  extraSettings?: InputMaybe<Scalars['JSON']['input']>;
  layoutId: Scalars['ID']['input'];
  presentationSettings?: InputMaybe<PresentationSettingsInput>;
  title?: InputMaybe<Scalars['ID']['input']>;
  userTags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateOrderSetInput = {
  description?: InputMaybe<Scalars['ID']['input']>;
  extraSettings?: InputMaybe<Scalars['JSON']['input']>;
  orderSetId: Scalars['ID']['input'];
  presentationSettings?: InputMaybe<PresentationSettingsInput>;
  title?: InputMaybe<Scalars['ID']['input']>;
  userTags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateProcessingPipelineInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  enabled?: Scalars['Boolean']['input'];
  extraSettings?: InputMaybe<Scalars['JSON']['input']>;
  jobInput?: InputMaybe<Scalars['JSON']['input']>;
  pipelineId: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  triggerSettings?: InputMaybe<ProcessingPipelineTriggerSettingsInput>;
};

export type UpdateSimulationInput = {
  allocationSettings?: InputMaybe<AllocationSettingsInput>;
  assignmentCapacityPolicy?: InputMaybe<AssignmentCapacityPolicyInput>;
  assignmentId?: InputMaybe<Scalars['ID']['input']>;
  assignmentPolicy?: InputMaybe<AssignmentPolicyInput>;
  description?: InputMaybe<Scalars['ID']['input']>;
  extraSettings?: InputMaybe<Scalars['JSON']['input']>;
  itemSetId?: InputMaybe<Scalars['ID']['input']>;
  layoutId?: InputMaybe<Scalars['ID']['input']>;
  locationSharingPolicy?: InputMaybe<LocationSharingPolicyInput>;
  optimizationSettings?: InputMaybe<OptimizationSettingsInput>;
  orderSetId?: InputMaybe<Scalars['ID']['input']>;
  pickingPolicy?: InputMaybe<PickingPolicyInput>;
  presentationSettings?: InputMaybe<PresentationSettingsInput>;
  resourcePolicy?: InputMaybe<ResourcePolicyInput>;
  routingPolicy?: InputMaybe<RoutingPolicyInput>;
  simulationId: Scalars['ID']['input'];
  stackingPolicy?: InputMaybe<StackingPolicyInput>;
  title?: InputMaybe<Scalars['ID']['input']>;
  userTags?: InputMaybe<Array<Scalars['String']['input']>>;
  waypointPolicy?: InputMaybe<WaypointPolicyInput>;
};

export type UpdateUserLegalConsentInput = {
  version: Scalars['String']['input'];
};

export type UpdateUserLegalConsentsInput = {
  privacyPolicy?: InputMaybe<UpdateUserLegalConsentInput>;
  termsPolicy?: InputMaybe<UpdateUserLegalConsentInput>;
};

export type UpdateUserSettingsInput = {
  configured?: InputMaybe<Scalars['Boolean']['input']>;
  consents?: InputMaybe<UpdateUserLegalConsentsInput>;
  featureRequests?: InputMaybe<FeatureRequestsInput>;
  notifications?: InputMaybe<UserNotificationsInput>;
};

export type UpdateWarehouseInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['ID']['input']>;
  extraSettings?: InputMaybe<Scalars['JSON']['input']>;
  presentationSettings?: InputMaybe<PresentationSettingsInput>;
  title?: InputMaybe<Scalars['ID']['input']>;
  userTags?: InputMaybe<Array<Scalars['String']['input']>>;
  warehouseId: Scalars['ID']['input'];
};

export type UserDetails = {
  __typename?: 'UserDetails';
  email: Scalars['String']['output'];
  familyName: Scalars['String']['output'];
  givenName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  settings?: Maybe<UserSettings>;
};

export type UserLegalConsent = {
  __typename?: 'UserLegalConsent';
  consentedAt: Scalars['Timestamp']['output'];
  version: Scalars['String']['output'];
};

export type UserLegalConsents = {
  __typename?: 'UserLegalConsents';
  privacyPolicy?: Maybe<UserLegalConsent>;
  termsPolicy?: Maybe<UserLegalConsent>;
};

export type UserNotifications = {
  __typename?: 'UserNotifications';
  insights?: Maybe<Scalars['Boolean']['output']>;
  news?: Maybe<Scalars['Boolean']['output']>;
  simulations?: Maybe<Scalars['Boolean']['output']>;
};

export type UserNotificationsInput = {
  insights?: InputMaybe<Scalars['Boolean']['input']>;
  news?: InputMaybe<Scalars['Boolean']['input']>;
  simulations?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserSettings = {
  __typename?: 'UserSettings';
  canCreateTrialWarehouse: Scalars['Boolean']['output'];
  configured: Scalars['Boolean']['output'];
  configuredAt?: Maybe<Scalars['Timestamp']['output']>;
  consents?: Maybe<UserLegalConsents>;
  createdAt: Scalars['Timestamp']['output'];
  featureFlags: Scalars['JSON']['output'];
  featureRequests?: Maybe<FeatureRequests>;
  notifications?: Maybe<UserNotifications>;
  trialWarehouseCreated: Scalars['Boolean']['output'];
  trialWarehouseCreatedAt?: Maybe<Scalars['Timestamp']['output']>;
  updatedAt: Scalars['Timestamp']['output'];
};

export type ValueStats = {
  __typename?: 'ValueStats';
  avg?: Maybe<Scalars['Float']['output']>;
  cv?: Maybe<Scalars['Float']['output']>;
  max?: Maybe<Scalars['Float']['output']>;
};

export type Vector = {
  __typename?: 'Vector';
  x: Scalars['Float']['output'];
  y: Scalars['Float']['output'];
};

export type VectorInput = {
  x: Scalars['Float']['input'];
  y: Scalars['Float']['input'];
};

export type VerticalTravellingEventSettings = {
  __typename?: 'VerticalTravellingEventSettings';
  speedDown?: Maybe<SpeedSettings>;
  speedUp?: Maybe<SpeedSettings>;
};

export type VerticalTravellingEventSettingsInput = {
  speedDown?: InputMaybe<SpeedSettingsInput>;
  speedUp?: InputMaybe<SpeedSettingsInput>;
};

export type Warehouse = {
  __typename?: 'Warehouse';
  actualities: ActualitiesConnection;
  address?: Maybe<Scalars['String']['output']>;
  allowedOperations: Array<BillableOperationType>;
  assignments: AssignmentsConnection;
  companyName?: Maybe<Scalars['String']['output']>;
  country: Scalars['ID']['output'];
  createdAt: Scalars['Timestamp']['output'];
  currency: Scalars['ID']['output'];
  datasetObjectQuery: DatasetObjectQueryResponse;
  datasetObjects: DatasetObjectsConnection;
  description?: Maybe<Scalars['String']['output']>;
  extraSettings?: Maybe<Scalars['JSON']['output']>;
  files: Array<StoredFile>;
  id: Scalars['ID']['output'];
  importJobs: ImportJobsConnection;
  importPipelines: ImportPipelinesConnection;
  integrationUsers: Array<IntegrationUser>;
  itemSets: ItemSetsConnection;
  layoutImports: LayoutImportsConnection;
  layouts: LayoutsConnection;
  layoutsOrDrafts: LayoutsOrDraftsConnection;
  measurementSystem: MeasurementSystem;
  orderSets: OrderSetsConnection;
  permissions: WarehouseAclPermissions;
  processingPipelines: ProcessingPipelinesConnection;
  simulations: SimulationsConnection;
  title: Scalars['String']['output'];
  type: WarehouseType;
  updatedAt: Scalars['Timestamp']['output'];
  userTags?: Maybe<Array<Scalars['String']['output']>>;
};

export type WarehouseActualitiesArgs = {
  filter?: InputMaybe<ActualityFilter>;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<ActualitiesSortBy>>;
};

export type WarehouseAssignmentsArgs = {
  filter?: InputMaybe<AssignmentsFilter>;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<AssignmentsSortBy>>;
};

export type WarehouseDatasetObjectQueryArgs = {
  parameters?: InputMaybe<Array<Scalars['JSON']['input']>>;
  query: Scalars['String']['input'];
};

export type WarehouseDatasetObjectsArgs = {
  filter?: InputMaybe<DatasetObjectFilter>;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<DatasetObjectsSortBy>>;
};

export type WarehouseImportJobsArgs = {
  filter?: InputMaybe<WarehouseImportJobsFilter>;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<ImportJobsSortBy>>;
};

export type WarehouseImportPipelinesArgs = {
  filter?: InputMaybe<WarehouseImportPipelinesFilter>;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<ImportPipelinesSortBy>>;
};

export type WarehouseItemSetsArgs = {
  filter?: InputMaybe<ItemSetsFilter>;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<ItemSetsSortBy>>;
};

export type WarehouseLayoutImportsArgs = {
  filter?: InputMaybe<LayoutImportsFilter>;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<LayoutImportsSortBy>>;
};

export type WarehouseLayoutsArgs = {
  filter?: InputMaybe<LayoutsFilter>;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<LayoutsSortBy>>;
};

export type WarehouseLayoutsOrDraftsArgs = {
  filter?: InputMaybe<LayoutsOrDraftsFilter>;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<LayoutsOrDraftsSortBy>>;
};

export type WarehouseOrderSetsArgs = {
  filter?: InputMaybe<OrderSetsFilter>;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<OrderSetsSortBy>>;
};

export type WarehouseProcessingPipelinesArgs = {
  filter?: InputMaybe<WarehouseProcessingPipelinesFilter>;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<ProcessingPipelinesSortBy>>;
};

export type WarehouseSimulationsArgs = {
  filter?: InputMaybe<SimulationFilter>;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<SimulationsSortBy>>;
};

export type WarehouseAclPermissions = {
  __typename?: 'WarehouseAclPermissions';
  allowed: Array<Action>;
  token?: Maybe<Scalars['String']['output']>;
};

export type WarehouseFilter = {
  title?: InputMaybe<StringSearchFilter>;
  userTags?: InputMaybe<Array<Scalars['String']['input']>>;
  userTagsHasAll?: InputMaybe<Array<Scalars['String']['input']>>;
  userTagsHasAny?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type WarehouseImportJobsFilter = {
  createdAt?: InputMaybe<TimestampSearchFilter>;
  importPipelineId?: InputMaybe<Array<Scalars['ID']['input']>>;
  importType?: InputMaybe<Array<ImportJobImportType>>;
  isUploadOrRestarted?: InputMaybe<Scalars['Boolean']['input']>;
  source?: InputMaybe<Array<ImportSource>>;
  status?: InputMaybe<Array<BatchJobStatus>>;
  targetId?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type WarehouseImportPipelinesFilter = {
  importType?: InputMaybe<Array<ImportJobImportType>>;
  targetId?: InputMaybe<Array<Scalars['ID']['input']>>;
  title?: InputMaybe<StringSearchFilter>;
};

export type WarehouseProcessingPipelinesFilter = {
  pipelineType?: InputMaybe<Array<ProcessingPipelineType>>;
  title?: InputMaybe<StringSearchFilter>;
};

export enum WarehouseSortOption {
  CREATED_AT = 'CREATED_AT',
  TITLE = 'TITLE',
  TYPE = 'TYPE',
  UPDATED_AT = 'UPDATED_AT',
}

export enum WarehouseType {
  DEMO = 'DEMO',
  NORMAL = 'NORMAL',
}

export type WarehousesConnection = {
  __typename?: 'WarehousesConnection';
  allUserTags: Array<Scalars['String']['output']>;
  /** Content of collection or the current page of collection if pagination is used */
  content: Array<Warehouse>;
  /** Total number of elements in this collection (ignoring pagination constraints, if any) */
  totalCount: Scalars['Float']['output'];
};

export type WarehousesSortBy = {
  /** Sorting direction */
  direction?: SortDirection;
  /** Field (sort option) to sort by */
  field: WarehouseSortOption;
};

export type WaypointPolicy = {
  __typename?: 'WaypointPolicy';
  rules?: Maybe<Array<WaypointPolicyRule>>;
};

export type WaypointPolicyInput = {
  rules?: InputMaybe<Array<WaypointPolicyRuleInput>>;
};

export type WaypointPolicyRule = {
  __typename?: 'WaypointPolicyRule';
  endPoints?: Maybe<WaypointPolicyRuleTerminalPoints>;
  id: Scalars['ID']['output'];
  startPoints?: Maybe<WaypointPolicyRuleTerminalPoints>;
  title?: Maybe<Scalars['String']['output']>;
};

export type WaypointPolicyRuleInput = {
  endPoints?: InputMaybe<WaypointPolicyRuleTerminalPointsInput>;
  id: Scalars['ID']['input'];
  startPoints?: InputMaybe<WaypointPolicyRuleTerminalPointsInput>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type WaypointPolicyRuleTerminalPoints = {
  __typename?: 'WaypointPolicyRuleTerminalPoints';
  locationsMatch?: Maybe<LocationFilterUnion>;
};

export type WaypointPolicyRuleTerminalPointsInput = {
  locationsMatch?: InputMaybe<LocationFilterUnionInput>;
};

export enum WaypointType {
  INTERNAL = 'INTERNAL',
  LOCATION = 'LOCATION',
  PORTAL = 'PORTAL',
  TERMINAL = 'TERMINAL',
}

export type WeightCompliance = {
  __typename?: 'WeightCompliance';
  assignment: Assignment;
  assignmentCapacityPolicy?: Maybe<AssignmentCapacityPolicy>;
  baySummary?: Maybe<BayWeightComplianceSummary>;
  bays: WeightComplianceBaysConnection;
  completedAt?: Maybe<Scalars['Timestamp']['output']>;
  createdAt: Scalars['Timestamp']['output'];
  id: Scalars['ID']['output'];
  itemSet: ItemSet;
  layout: Layout;
  locationSummary?: Maybe<LocationWeightComplianceSummary>;
  locations: WeightComplianceLocationsConnection;
  progress: Scalars['Float']['output'];
  simulation: Simulation;
  startedAt?: Maybe<Scalars['Timestamp']['output']>;
  status: BatchJobStatus;
  updatedAt: Scalars['Timestamp']['output'];
  warehouse: Warehouse;
};

export type WeightComplianceBaysArgs = {
  filter?: InputMaybe<WeightComplianceBayFilter>;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<WeightComplianceBaySortBy>>;
};

export type WeightComplianceLocationsArgs = {
  filter?: InputMaybe<WeightComplianceLocationFilter>;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<Array<WeightComplianceLocationSortBy>>;
};

export type WeightComplianceBayDetails = {
  __typename?: 'WeightComplianceBayDetails';
  bay: LayoutFeature;
  bayId: Scalars['ID']['output'];
  bayType?: Maybe<Scalars['ID']['output']>;
  legacyBay: Bay;
  maxWeight?: Maybe<Scalars['Float']['output']>;
  plane: LayoutPlane;
  status: WeightComplianceStatus;
  weight?: Maybe<Scalars['Float']['output']>;
};

export type WeightComplianceBayFilter = {
  bayId?: InputMaybe<Array<Scalars['ID']['input']>>;
  locationBayTitle?: InputMaybe<StringSearchFilter>;
  locationPlaneTitle?: InputMaybe<StringSearchFilter>;
  planeId?: InputMaybe<Array<Scalars['ID']['input']>>;
  status?: InputMaybe<Array<WeightComplianceStatus>>;
};

export type WeightComplianceBaySortBy = {
  /** Sorting direction */
  direction?: SortDirection;
  /** Field (sort option) to sort by */
  field: WeightComplianceBaySortOption;
};

export enum WeightComplianceBaySortOption {
  BAY_TITLE = 'BAY_TITLE',
}

export type WeightComplianceBaysConnection = {
  __typename?: 'WeightComplianceBaysConnection';
  /** Content of collection or the current page of collection if pagination is used */
  content: Array<WeightComplianceBayDetails>;
  /** Total number of elements in this collection (ignoring pagination constraints, if any) */
  totalCount: Scalars['Float']['output'];
};

export type WeightComplianceLocationDetails = {
  __typename?: 'WeightComplianceLocationDetails';
  items: Array<WeightComplianceLocationItem>;
  location: LayoutLocation;
  locationId: Scalars['ID']['output'];
  maxWeight?: Maybe<Scalars['Float']['output']>;
  status: WeightComplianceStatus;
  volume?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
};

export type WeightComplianceLocationFilter = {
  bayId?: InputMaybe<Array<Scalars['ID']['input']>>;
  level?: InputMaybe<Array<Scalars['Float']['input']>>;
  locationAisleTitle?: InputMaybe<StringSearchFilter>;
  locationBayPosition?: InputMaybe<NumberSearchFilter>;
  locationBayTitle?: InputMaybe<StringSearchFilter>;
  locationHeight?: InputMaybe<NumberSearchFilter>;
  locationId?: InputMaybe<StringSearchFilter>;
  locationLength?: InputMaybe<NumberSearchFilter>;
  locationLevel?: InputMaybe<NumberSearchFilter>;
  locationOrder?: InputMaybe<NumberSearchFilter>;
  locationPlaneTitle?: InputMaybe<StringSearchFilter>;
  locationRackingType?: InputMaybe<StringSearchFilter>;
  locationStatus?: InputMaybe<BooleanSearchFilter>;
  locationUsageType?: InputMaybe<StringSearchFilter>;
  locationWeight?: InputMaybe<NumberSearchFilter>;
  locationWidth?: InputMaybe<NumberSearchFilter>;
  locmhtype?: InputMaybe<StringSearchFilter>;
  planeId?: InputMaybe<Array<Scalars['ID']['input']>>;
  status?: InputMaybe<Array<WeightComplianceStatus>>;
  warehouseArea?: InputMaybe<StringSearchFilter>;
};

export type WeightComplianceLocationItem = {
  __typename?: 'WeightComplianceLocationItem';
  consignee: Scalars['ID']['output'];
  quantity?: Maybe<Scalars['Float']['output']>;
  sku: Scalars['ID']['output'];
  uoms?: Maybe<Array<UomQuantity>>;
  volume?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
};

export type WeightComplianceLocationSortBy = {
  /** Sorting direction */
  direction?: SortDirection;
  /** Field (sort option) to sort by */
  field: WeightComplianceLocationSortOption;
};

export enum WeightComplianceLocationSortOption {
  LOCATION_ID = 'LOCATION_ID',
}

export type WeightComplianceLocationsConnection = {
  __typename?: 'WeightComplianceLocationsConnection';
  /** Content of collection or the current page of collection if pagination is used */
  content: Array<WeightComplianceLocationDetails>;
  /** Total number of elements in this collection (ignoring pagination constraints, if any) */
  totalCount: Scalars['Float']['output'];
};

export enum WeightComplianceStatus {
  COMPLIANT = 'COMPLIANT',
  FLOOR_LEVEL = 'FLOOR_LEVEL',
  NONCOMPLIANT = 'NONCOMPLIANT',
  UNASSIGNED = 'UNASSIGNED',
  UNKNOWN = 'UNKNOWN',
}

export type CreateAssignmentMutationVariables = Exact<{
  input: CreateAssignmentInput;
}>;

export type CreateAssignmentMutation = {
  __typename?: 'Mutation';
  createAssignment: { __typename?: 'Assignment'; id: string };
};

export type UpdateAssignmentMutationVariables = Exact<{
  assignmentInput: UpdateAssignmentInput;
}>;

export type UpdateAssignmentMutation = {
  __typename?: 'Mutation';
  updateAssignment: {
    __typename?: 'Assignment';
    id: string;
    description?: string | null;
  };
};

export type RemoveAssignmentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type RemoveAssignmentMutation = {
  __typename?: 'Mutation';
  removeAssignment: boolean;
};

export type AssignmentFragment = {
  __typename?: 'Assignment';
  id: string;
  title: string;
  description?: string | null;
  createdAt: number;
  updatedAt: number;
  inputFileName?: string | null;
  status: AssignmentImportStatus;
  userTags?: Array<string> | null;
  summary?: {
    __typename?: 'AssignmentSummary';
    totalItemsCount: number;
    totalLocationsCount: number;
    totalProductsCount: number;
    consignees: Array<{
      __typename?: 'AssignmentConsigneeSummary';
      consignee: string;
      itemsCount: number;
      locationsCount: number;
      productsCount: number;
    }>;
    fields?: Array<{
      __typename?: 'DatasetObjectFieldSummary';
      name: string;
      hasData?: boolean | null;
      distinctValueCount?: number | null;
    }> | null;
    rawDataFields?: Array<{
      __typename?: 'DatasetObjectRawDataFieldSummary';
      name: string;
      type?: Array<string> | null;
      hasData?: boolean | null;
      distinctValueCount?: number | null;
    }> | null;
  } | null;
  simulationsWith: {
    __typename?: 'SimulationsConnection';
    totalCount: number;
    content: Array<{
      __typename?: 'Simulation';
      id: string;
      title: string;
      description?: string | null;
      createdAt: number;
      updatedAt: number;
      userTags?: Array<string> | null;
    }>;
  };
};

export type AssignmentMetaFragment = {
  __typename?: 'Assignment';
  id: string;
  title: string;
  description?: string | null;
  createdAt: number;
  updatedAt: number;
  inputFileName?: string | null;
  status: AssignmentImportStatus;
  userTags?: Array<string> | null;
};

export type LoadAllAssignmentsMetaQueryVariables = Exact<{
  warehouseId: Scalars['ID']['input'];
}>;

export type LoadAllAssignmentsMetaQuery = {
  __typename?: 'Query';
  warehouse?: {
    __typename?: 'Warehouse';
    assignments: {
      __typename?: 'AssignmentsConnection';
      content: Array<{
        __typename?: 'Assignment';
        id: string;
        title: string;
        description?: string | null;
        createdAt: number;
        updatedAt: number;
        inputFileName?: string | null;
        status: AssignmentImportStatus;
        userTags?: Array<string> | null;
      }>;
    };
  } | null;
};

export type LoadAssignmentSummaryQueryVariables = Exact<{
  assignmentId: Scalars['ID']['input'];
}>;

export type LoadAssignmentSummaryQuery = {
  __typename?: 'Query';
  assignment?: {
    __typename?: 'Assignment';
    id: string;
    title: string;
    description?: string | null;
    createdAt: number;
    updatedAt: number;
    inputFileName?: string | null;
    status: AssignmentImportStatus;
    userTags?: Array<string> | null;
    summary?: {
      __typename?: 'AssignmentSummary';
      totalItemsCount: number;
      totalLocationsCount: number;
      totalProductsCount: number;
      consignees: Array<{
        __typename?: 'AssignmentConsigneeSummary';
        consignee: string;
        itemsCount: number;
        locationsCount: number;
        productsCount: number;
      }>;
      fields?: Array<{
        __typename?: 'DatasetObjectFieldSummary';
        name: string;
        hasData?: boolean | null;
        distinctValueCount?: number | null;
      }> | null;
      rawDataFields?: Array<{
        __typename?: 'DatasetObjectRawDataFieldSummary';
        name: string;
        type?: Array<string> | null;
        hasData?: boolean | null;
        distinctValueCount?: number | null;
      }> | null;
    } | null;
  } | null;
};

export type AssignmentItemDataFragment = {
  __typename?: 'AssignmentItem';
  consignee: string;
  sku: string;
  locationId: string;
  stockUom?: string | null;
  stockUomQuantity?: number | null;
};

export type LoadAssignmentDataQueryVariables = Exact<{
  assignmentId: Scalars['ID']['input'];
  page?: InputMaybe<SkipLimitPageSpec>;
  filter?: InputMaybe<AssignmentItemsSearchFilter>;
  sortBy?: InputMaybe<Array<AssignmentItemsSortBy> | AssignmentItemsSortBy>;
}>;

export type LoadAssignmentDataQuery = {
  __typename?: 'Query';
  assignment?: {
    __typename?: 'Assignment';
    id: string;
    title: string;
    description?: string | null;
    createdAt: number;
    updatedAt: number;
    inputFileName?: string | null;
    status: AssignmentImportStatus;
    userTags?: Array<string> | null;
    summary?: {
      __typename?: 'AssignmentSummary';
      totalItemsCount: number;
      totalLocationsCount: number;
      totalProductsCount: number;
      consignees: Array<{
        __typename?: 'AssignmentConsigneeSummary';
        consignee: string;
        itemsCount: number;
        locationsCount: number;
        productsCount: number;
      }>;
      fields?: Array<{
        __typename?: 'DatasetObjectFieldSummary';
        name: string;
        hasData?: boolean | null;
        distinctValueCount?: number | null;
      }> | null;
      rawDataFields?: Array<{
        __typename?: 'DatasetObjectRawDataFieldSummary';
        name: string;
        type?: Array<string> | null;
        hasData?: boolean | null;
        distinctValueCount?: number | null;
      }> | null;
    } | null;
    items: {
      __typename?: 'AssignmentItemsConnection';
      totalCount: number;
      content: Array<{
        __typename?: 'AssignmentItem';
        consignee: string;
        sku: string;
        locationId: string;
        stockUom?: string | null;
        stockUomQuantity?: number | null;
      }>;
    };
  } | null;
};

export type LoadAssignmentQueryVariables = Exact<{
  assignmentId: Scalars['ID']['input'];
}>;

export type LoadAssignmentQuery = {
  __typename?: 'Query';
  assignment?: {
    __typename?: 'Assignment';
    id: string;
    title: string;
    description?: string | null;
    createdAt: number;
    updatedAt: number;
    inputFileName?: string | null;
    status: AssignmentImportStatus;
    userTags?: Array<string> | null;
    summary?: {
      __typename?: 'AssignmentSummary';
      totalItemsCount: number;
      totalLocationsCount: number;
      totalProductsCount: number;
      consignees: Array<{
        __typename?: 'AssignmentConsigneeSummary';
        consignee: string;
        itemsCount: number;
        locationsCount: number;
        productsCount: number;
      }>;
      fields?: Array<{
        __typename?: 'DatasetObjectFieldSummary';
        name: string;
        hasData?: boolean | null;
        distinctValueCount?: number | null;
      }> | null;
      rawDataFields?: Array<{
        __typename?: 'DatasetObjectRawDataFieldSummary';
        name: string;
        type?: Array<string> | null;
        hasData?: boolean | null;
        distinctValueCount?: number | null;
      }> | null;
    } | null;
    simulationsWith: {
      __typename?: 'SimulationsConnection';
      totalCount: number;
      content: Array<{
        __typename?: 'Simulation';
        id: string;
        title: string;
        description?: string | null;
        createdAt: number;
        updatedAt: number;
        userTags?: Array<string> | null;
      }>;
    };
  } | null;
};

export type AssignmentPolicyComplianceRuleMatchFragment = {
  __typename?: 'AssignmentPolicyComplianceRuleMatch';
  totalCount: number;
  compliantCount: number;
  nonCompliantCount: number;
};

export type AssignmentPolicyComplianceRuleSummaryFragment = {
  __typename?: 'AssignmentPolicyComplianceRuleSummary';
  ruleId: string;
  matchedByLocation: {
    __typename?: 'AssignmentPolicyComplianceRuleMatch';
    totalCount: number;
    compliantCount: number;
    nonCompliantCount: number;
  };
  matchedByItem: {
    __typename?: 'AssignmentPolicyComplianceRuleMatch';
    totalCount: number;
    compliantCount: number;
    nonCompliantCount: number;
  };
};

export type AssignmentPolicyComplianceRulesSummaryFragment = {
  __typename?: 'AssignmentPolicyComplianceRulesSummary';
  rules: Array<{
    __typename?: 'AssignmentPolicyComplianceRuleSummary';
    ruleId: string;
    matchedByLocation: {
      __typename?: 'AssignmentPolicyComplianceRuleMatch';
      totalCount: number;
      compliantCount: number;
      nonCompliantCount: number;
    };
    matchedByItem: {
      __typename?: 'AssignmentPolicyComplianceRuleMatch';
      totalCount: number;
      compliantCount: number;
      nonCompliantCount: number;
    };
  }>;
  unmatchedByLocation: {
    __typename?: 'AssignmentPolicyComplianceRuleMatch';
    totalCount: number;
    compliantCount: number;
    nonCompliantCount: number;
  };
};

export type AssignmentComplianceSummaryFragment = {
  __typename?: 'AssignmentCompliance';
  id: string;
  status: BatchJobStatus;
  assignmentId: string;
  summary?: {
    __typename?: 'AssignmentComplianceSummary';
    totalSummary?: {
      __typename?: 'AssignmentPolicyComplianceRuleMatch';
      nonCompliantCount: number;
      compliantCount: number;
      totalCount: number;
    } | null;
    rulesSummary: {
      __typename?: 'AssignmentPolicyComplianceRulesSummary';
      rules: Array<{
        __typename?: 'AssignmentPolicyComplianceRuleSummary';
        ruleId: string;
        matchedByLocation: {
          __typename?: 'AssignmentPolicyComplianceRuleMatch';
          totalCount: number;
          compliantCount: number;
          nonCompliantCount: number;
        };
        matchedByItem: {
          __typename?: 'AssignmentPolicyComplianceRuleMatch';
          totalCount: number;
          compliantCount: number;
          nonCompliantCount: number;
        };
      }>;
      unmatchedByLocation: {
        __typename?: 'AssignmentPolicyComplianceRuleMatch';
        totalCount: number;
        compliantCount: number;
        nonCompliantCount: number;
      };
    };
  } | null;
};

export type AssignmentComplianceDataFragment = {
  __typename?: 'AssignmentComplianceItemsConnection';
  totalCount: number;
  content: Array<{
    __typename?: 'AssignmentComplianceItem';
    itemId: string;
    consignee: string;
    sku: string;
    locationId: string;
    itemRuleIds?: Array<string> | null;
    locationRuleIds?: Array<string> | null;
    violatedRuleIds?: Array<string> | null;
    status: AssignmentComplianceItemStatus;
    item?: {
      __typename?: 'Item';
      skuGroup?: string | null;
      subGroup?: string | null;
    } | null;
  }>;
};

export type AssignmentComplianceItemFragment = {
  __typename?: 'AssignmentComplianceItem';
  itemId: string;
  consignee: string;
  sku: string;
  locationId: string;
  itemRuleIds?: Array<string> | null;
  locationRuleIds?: Array<string> | null;
  violatedRuleIds?: Array<string> | null;
  status: AssignmentComplianceItemStatus;
  item?: {
    __typename?: 'Item';
    skuGroup?: string | null;
    subGroup?: string | null;
  } | null;
};

export type LoadAssignmentComplianceSummaryQueryVariables = Exact<{
  simulationId: Scalars['ID']['input'];
  assignmentId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type LoadAssignmentComplianceSummaryQuery = {
  __typename?: 'Query';
  simulation?: {
    __typename?: 'Simulation';
    assignmentCompliance?: {
      __typename?: 'AssignmentCompliance';
      id: string;
      status: BatchJobStatus;
      assignmentId: string;
      summary?: {
        __typename?: 'AssignmentComplianceSummary';
        totalSummary?: {
          __typename?: 'AssignmentPolicyComplianceRuleMatch';
          nonCompliantCount: number;
          compliantCount: number;
          totalCount: number;
        } | null;
        rulesSummary: {
          __typename?: 'AssignmentPolicyComplianceRulesSummary';
          rules: Array<{
            __typename?: 'AssignmentPolicyComplianceRuleSummary';
            ruleId: string;
            matchedByLocation: {
              __typename?: 'AssignmentPolicyComplianceRuleMatch';
              totalCount: number;
              compliantCount: number;
              nonCompliantCount: number;
            };
            matchedByItem: {
              __typename?: 'AssignmentPolicyComplianceRuleMatch';
              totalCount: number;
              compliantCount: number;
              nonCompliantCount: number;
            };
          }>;
          unmatchedByLocation: {
            __typename?: 'AssignmentPolicyComplianceRuleMatch';
            totalCount: number;
            compliantCount: number;
            nonCompliantCount: number;
          };
        };
      } | null;
    } | null;
  } | null;
};

export type RunCalculateAssignmentComplianceMutationVariables = Exact<{
  simulationId: Scalars['String']['input'];
  assignmentId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type RunCalculateAssignmentComplianceMutation = {
  __typename?: 'Mutation';
  calculateSimulationAssignmentCompliance: {
    __typename?: 'AssignmentCompliance';
    id: string;
    status: BatchJobStatus;
    assignmentId: string;
    summary?: {
      __typename?: 'AssignmentComplianceSummary';
      totalSummary?: {
        __typename?: 'AssignmentPolicyComplianceRuleMatch';
        nonCompliantCount: number;
        compliantCount: number;
        totalCount: number;
      } | null;
      rulesSummary: {
        __typename?: 'AssignmentPolicyComplianceRulesSummary';
        rules: Array<{
          __typename?: 'AssignmentPolicyComplianceRuleSummary';
          ruleId: string;
          matchedByLocation: {
            __typename?: 'AssignmentPolicyComplianceRuleMatch';
            totalCount: number;
            compliantCount: number;
            nonCompliantCount: number;
          };
          matchedByItem: {
            __typename?: 'AssignmentPolicyComplianceRuleMatch';
            totalCount: number;
            compliantCount: number;
            nonCompliantCount: number;
          };
        }>;
        unmatchedByLocation: {
          __typename?: 'AssignmentPolicyComplianceRuleMatch';
          totalCount: number;
          compliantCount: number;
          nonCompliantCount: number;
        };
      };
    } | null;
  };
};

export type LoadAssignmentComplianceLocationsQueryVariables = Exact<{
  simulationId: Scalars['ID']['input'];
  assignmentId?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<AssignmentComplianceItemFilter>;
}>;

export type LoadAssignmentComplianceLocationsQuery = {
  __typename?: 'Query';
  simulation?: {
    __typename?: 'Simulation';
    assignmentCompliance?: {
      __typename?: 'AssignmentCompliance';
      id: string;
      assignmentId: string;
      items: {
        __typename?: 'AssignmentComplianceItemsConnection';
        totalCount: number;
        content: Array<{
          __typename?: 'AssignmentComplianceItem';
          itemId: string;
          consignee: string;
          sku: string;
          locationId: string;
          itemRuleIds?: Array<string> | null;
          locationRuleIds?: Array<string> | null;
          violatedRuleIds?: Array<string> | null;
          status: AssignmentComplianceItemStatus;
          item?: {
            __typename?: 'Item';
            skuGroup?: string | null;
            subGroup?: string | null;
          } | null;
        }>;
      };
    } | null;
  } | null;
};

export type LoadAssignmentComplianceItemsQueryVariables = Exact<{
  simulationId: Scalars['ID']['input'];
  assignmentId?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<AssignmentComplianceItemFilter>;
  sortBy?: InputMaybe<
    Array<AssignmentComplianceItemSortBy> | AssignmentComplianceItemSortBy
  >;
  page?: InputMaybe<SkipLimitPageSpec>;
}>;

export type LoadAssignmentComplianceItemsQuery = {
  __typename?: 'Query';
  simulation?: {
    __typename?: 'Simulation';
    assignmentCompliance?: {
      __typename?: 'AssignmentCompliance';
      id: string;
      assignmentId: string;
      items: {
        __typename?: 'AssignmentComplianceItemsConnection';
        totalCount: number;
        content: Array<{
          __typename?: 'AssignmentComplianceItem';
          itemId: string;
          consignee: string;
          sku: string;
          locationId: string;
          itemRuleIds?: Array<string> | null;
          locationRuleIds?: Array<string> | null;
          violatedRuleIds?: Array<string> | null;
          status: AssignmentComplianceItemStatus;
          item?: {
            __typename?: 'Item';
            skuGroup?: string | null;
            subGroup?: string | null;
          } | null;
        }>;
      };
    } | null;
  } | null;
};

export type AssignmentOccupancyLocationFragment = {
  __typename?: 'LayoutLocation';
  locationId: string;
  locationLevel: number;
  locationRackingType: string;
  warehouseArea: string;
  plane?: {
    __typename?: 'LayoutPlane';
    id: string;
    title?: string | null;
  } | null;
  aisle?: {
    __typename?: 'LayoutFeature';
    id: string;
    title?: string | null;
  } | null;
  bay?: {
    __typename?: 'LayoutFeature';
    id: string;
    title?: string | null;
  } | null;
};

export type AssignmentOccupancyLocationItemFragment = {
  __typename?: 'AssignmentOccupancyLocationItem';
  itemId: string;
  consignee: string;
  sku: string;
  status: AssignmentOccupancyLocationItemStatus;
  stockUomQuantity?: number | null;
  stockUomQuantityCompliance?: StockUomQuantityCompliance | null;
  occupiedVolume?: number | null;
  stockUom?: {
    __typename?: 'ItemUomVolume';
    uom: string;
    length?: number | null;
    width?: number | null;
    height?: number | null;
    volume?: number | null;
    maxStack?: number | null;
  } | null;
  stockUomCapacity?: {
    __typename?: 'AssignmentOccupancyCapacity';
    uomQuantity?: number | null;
    quantity?: number | null;
    occupiedVolume?: number | null;
  } | null;
  maxUomCapacity?: {
    __typename?: 'AssignmentOccupancyCapacity';
    uomQuantity?: number | null;
    quantity?: number | null;
    occupiedVolume?: number | null;
    uom?: {
      __typename?: 'ItemUomVolume';
      uom: string;
      maxStack?: number | null;
    } | null;
  } | null;
};

export type AssignmentOccupancyLocationDetailsFragment = {
  __typename?: 'AssignmentOccupancyLocationDetails';
  locationId: string;
  locationVolume?: number | null;
  occupiedVolume?: number | null;
  occupancy?: number | null;
  status: AssignmentOccupancyLocationStatus;
  location: {
    __typename?: 'LayoutLocation';
    locationId: string;
    locationLevel: number;
    locationRackingType: string;
    warehouseArea: string;
    plane?: {
      __typename?: 'LayoutPlane';
      id: string;
      title?: string | null;
    } | null;
    aisle?: {
      __typename?: 'LayoutFeature';
      id: string;
      title?: string | null;
    } | null;
    bay?: {
      __typename?: 'LayoutFeature';
      id: string;
      title?: string | null;
    } | null;
  };
  items: Array<{
    __typename?: 'AssignmentOccupancyLocationItem';
    itemId: string;
    consignee: string;
    sku: string;
    status: AssignmentOccupancyLocationItemStatus;
    stockUomQuantity?: number | null;
    stockUomQuantityCompliance?: StockUomQuantityCompliance | null;
    occupiedVolume?: number | null;
    stockUom?: {
      __typename?: 'ItemUomVolume';
      uom: string;
      length?: number | null;
      width?: number | null;
      height?: number | null;
      volume?: number | null;
      maxStack?: number | null;
    } | null;
    stockUomCapacity?: {
      __typename?: 'AssignmentOccupancyCapacity';
      uomQuantity?: number | null;
      quantity?: number | null;
      occupiedVolume?: number | null;
    } | null;
    maxUomCapacity?: {
      __typename?: 'AssignmentOccupancyCapacity';
      uomQuantity?: number | null;
      quantity?: number | null;
      occupiedVolume?: number | null;
      uom?: {
        __typename?: 'ItemUomVolume';
        uom: string;
        maxStack?: number | null;
      } | null;
    } | null;
  }>;
};

export type AssignmentOccupancyLocationStatusFragment = {
  __typename?: 'AssignmentOccupancyLocationDetails';
  locationId: string;
  locationVolume?: number | null;
  occupiedVolume?: number | null;
  occupancy?: number | null;
  status: AssignmentOccupancyLocationStatus;
  items: Array<{
    __typename?: 'AssignmentOccupancyLocationItem';
    itemId: string;
    consignee: string;
    sku: string;
    status: AssignmentOccupancyLocationItemStatus;
    stockUomQuantity?: number | null;
    stockUomQuantityCompliance?: StockUomQuantityCompliance | null;
    occupiedVolume?: number | null;
    stockUom?: {
      __typename?: 'ItemUomVolume';
      uom: string;
      length?: number | null;
      width?: number | null;
      height?: number | null;
      volume?: number | null;
      maxStack?: number | null;
    } | null;
    stockUomCapacity?: {
      __typename?: 'AssignmentOccupancyCapacity';
      uomQuantity?: number | null;
      quantity?: number | null;
      occupiedVolume?: number | null;
    } | null;
    maxUomCapacity?: {
      __typename?: 'AssignmentOccupancyCapacity';
      uomQuantity?: number | null;
      quantity?: number | null;
      occupiedVolume?: number | null;
      uom?: {
        __typename?: 'ItemUomVolume';
        uom: string;
        maxStack?: number | null;
      } | null;
    } | null;
  }>;
};

export type AssignmentOccupancyCapacitySummaryFragment = {
  __typename?: 'AssignmentOccupancyCapacitySummary';
  locationsWithCapacity?: number | null;
  locationsWithUnknownCapacity?: number | null;
  emptyLocations?: number | null;
  totalCapacityVolume?: number | null;
  totalEmptyVolume?: number | null;
  totalUnknownVolume?: number | null;
};

export type AssignmentOccupancySummaryFragment = {
  __typename?: 'AssignmentOccupancySummary';
  totalStorageVolume?: number | null;
  totalOccupiedVolume?: number | null;
  totalUnknownVolume?: number | null;
  totalOccupancy?: number | null;
  maxOccupancy?: number | null;
  stockUomCapacitySummary?: {
    __typename?: 'AssignmentOccupancyCapacitySummary';
    locationsWithCapacity?: number | null;
    locationsWithUnknownCapacity?: number | null;
    emptyLocations?: number | null;
    totalCapacityVolume?: number | null;
    totalEmptyVolume?: number | null;
    totalUnknownVolume?: number | null;
  } | null;
  maxUomCapacitySummary?: {
    __typename?: 'AssignmentOccupancyCapacitySummary';
    locationsWithCapacity?: number | null;
    locationsWithUnknownCapacity?: number | null;
    emptyLocations?: number | null;
    totalCapacityVolume?: number | null;
    totalEmptyVolume?: number | null;
    totalUnknownVolume?: number | null;
  } | null;
};

export type AssignmentOccupancyMetaFragment = {
  __typename?: 'AssignmentOccupancy';
  id: string;
  status: BatchJobStatus;
  progress: number;
  createdAt: number;
  updatedAt: number;
  summary?: {
    __typename?: 'AssignmentOccupancySummary';
    totalStorageVolume?: number | null;
    totalOccupiedVolume?: number | null;
    totalUnknownVolume?: number | null;
    totalOccupancy?: number | null;
    maxOccupancy?: number | null;
    stockUomCapacitySummary?: {
      __typename?: 'AssignmentOccupancyCapacitySummary';
      locationsWithCapacity?: number | null;
      locationsWithUnknownCapacity?: number | null;
      emptyLocations?: number | null;
      totalCapacityVolume?: number | null;
      totalEmptyVolume?: number | null;
      totalUnknownVolume?: number | null;
    } | null;
    maxUomCapacitySummary?: {
      __typename?: 'AssignmentOccupancyCapacitySummary';
      locationsWithCapacity?: number | null;
      locationsWithUnknownCapacity?: number | null;
      emptyLocations?: number | null;
      totalCapacityVolume?: number | null;
      totalEmptyVolume?: number | null;
      totalUnknownVolume?: number | null;
    } | null;
  } | null;
};

export type AssignmentOccupancyLocationsCategoryFragment = {
  __typename?: 'AssignmentOccupancyLocationsCategory';
  minBoundary?: number | null;
  maxBoundary?: number | null;
  minOccupancy?: number | null;
  maxOccupancy?: number | null;
  count: number;
  totalStorageVolume?: number | null;
  totalOccupiedVolume?: number | null;
};

export type AssignmentOccupancyLocationsCategoryByStatusFragment = {
  __typename?: 'AssignmentOccupancyLocationsCategoryByStatus';
  status: AssignmentOccupancyLocationStatus;
  count: number;
  totalStorageVolume?: number | null;
};

export type AssignmentOccupancyFragment = {
  __typename?: 'AssignmentOccupancy';
  id: string;
  status: BatchJobStatus;
  progress: number;
  createdAt: number;
  updatedAt: number;
  locationsByOccupancyCategory: {
    __typename?: 'AssignmentOccupancyLocationsByCategoryConnection';
    totalCount: number;
    categoriesByStatus: Array<{
      __typename?: 'AssignmentOccupancyLocationsCategoryByStatus';
      status: AssignmentOccupancyLocationStatus;
      count: number;
      totalStorageVolume?: number | null;
    }>;
    categories: Array<{
      __typename?: 'AssignmentOccupancyLocationsCategory';
      minBoundary?: number | null;
      maxBoundary?: number | null;
      minOccupancy?: number | null;
      maxOccupancy?: number | null;
      count: number;
      totalStorageVolume?: number | null;
      totalOccupiedVolume?: number | null;
    }>;
  };
  summary?: {
    __typename?: 'AssignmentOccupancySummary';
    totalStorageVolume?: number | null;
    totalOccupiedVolume?: number | null;
    totalUnknownVolume?: number | null;
    totalOccupancy?: number | null;
    maxOccupancy?: number | null;
    stockUomCapacitySummary?: {
      __typename?: 'AssignmentOccupancyCapacitySummary';
      locationsWithCapacity?: number | null;
      locationsWithUnknownCapacity?: number | null;
      emptyLocations?: number | null;
      totalCapacityVolume?: number | null;
      totalEmptyVolume?: number | null;
      totalUnknownVolume?: number | null;
    } | null;
    maxUomCapacitySummary?: {
      __typename?: 'AssignmentOccupancyCapacitySummary';
      locationsWithCapacity?: number | null;
      locationsWithUnknownCapacity?: number | null;
      emptyLocations?: number | null;
      totalCapacityVolume?: number | null;
      totalEmptyVolume?: number | null;
      totalUnknownVolume?: number | null;
    } | null;
  } | null;
};

export type AssignmentOccupancyDataFragment = {
  __typename?: 'AssignmentOccupancyLocationsConnection';
  totalCount: number;
  content: Array<{
    __typename?: 'AssignmentOccupancyLocationDetails';
    locationId: string;
    locationVolume?: number | null;
    occupiedVolume?: number | null;
    occupancy?: number | null;
    status: AssignmentOccupancyLocationStatus;
    location: {
      __typename?: 'LayoutLocation';
      locationId: string;
      locationLevel: number;
      locationRackingType: string;
      warehouseArea: string;
      plane?: {
        __typename?: 'LayoutPlane';
        id: string;
        title?: string | null;
      } | null;
      aisle?: {
        __typename?: 'LayoutFeature';
        id: string;
        title?: string | null;
      } | null;
      bay?: {
        __typename?: 'LayoutFeature';
        id: string;
        title?: string | null;
      } | null;
    };
    items: Array<{
      __typename?: 'AssignmentOccupancyLocationItem';
      itemId: string;
      consignee: string;
      sku: string;
      status: AssignmentOccupancyLocationItemStatus;
      stockUomQuantity?: number | null;
      stockUomQuantityCompliance?: StockUomQuantityCompliance | null;
      occupiedVolume?: number | null;
      stockUom?: {
        __typename?: 'ItemUomVolume';
        uom: string;
        length?: number | null;
        width?: number | null;
        height?: number | null;
        volume?: number | null;
        maxStack?: number | null;
      } | null;
      stockUomCapacity?: {
        __typename?: 'AssignmentOccupancyCapacity';
        uomQuantity?: number | null;
        quantity?: number | null;
        occupiedVolume?: number | null;
      } | null;
      maxUomCapacity?: {
        __typename?: 'AssignmentOccupancyCapacity';
        uomQuantity?: number | null;
        quantity?: number | null;
        occupiedVolume?: number | null;
        uom?: {
          __typename?: 'ItemUomVolume';
          uom: string;
          maxStack?: number | null;
        } | null;
      } | null;
    }>;
  }>;
};

export type LoadAssignmentOccupancyLocationsQueryVariables = Exact<{
  simulationId: Scalars['ID']['input'];
  assignmentId: Scalars['ID']['input'];
  filter?: InputMaybe<AssignmentOccupancyLocationFilter>;
  sortBy?: InputMaybe<
    Array<AssignmentOccupancyLocationSortBy> | AssignmentOccupancyLocationSortBy
  >;
  page?: InputMaybe<SkipLimitPageSpec>;
}>;

export type LoadAssignmentOccupancyLocationsQuery = {
  __typename?: 'Query';
  simulation?: {
    __typename?: 'Simulation';
    assignmentOccupancy?: {
      __typename?: 'AssignmentOccupancy';
      locations: {
        __typename?: 'AssignmentOccupancyLocationsConnection';
        totalCount: number;
        content: Array<{
          __typename?: 'AssignmentOccupancyLocationDetails';
          locationId: string;
          locationVolume?: number | null;
          occupiedVolume?: number | null;
          occupancy?: number | null;
          status: AssignmentOccupancyLocationStatus;
          location: {
            __typename?: 'LayoutLocation';
            locationId: string;
            locationLevel: number;
            locationRackingType: string;
            warehouseArea: string;
            plane?: {
              __typename?: 'LayoutPlane';
              id: string;
              title?: string | null;
            } | null;
            aisle?: {
              __typename?: 'LayoutFeature';
              id: string;
              title?: string | null;
            } | null;
            bay?: {
              __typename?: 'LayoutFeature';
              id: string;
              title?: string | null;
            } | null;
          };
          items: Array<{
            __typename?: 'AssignmentOccupancyLocationItem';
            itemId: string;
            consignee: string;
            sku: string;
            status: AssignmentOccupancyLocationItemStatus;
            stockUomQuantity?: number | null;
            stockUomQuantityCompliance?: StockUomQuantityCompliance | null;
            occupiedVolume?: number | null;
            stockUom?: {
              __typename?: 'ItemUomVolume';
              uom: string;
              length?: number | null;
              width?: number | null;
              height?: number | null;
              volume?: number | null;
              maxStack?: number | null;
            } | null;
            stockUomCapacity?: {
              __typename?: 'AssignmentOccupancyCapacity';
              uomQuantity?: number | null;
              quantity?: number | null;
              occupiedVolume?: number | null;
            } | null;
            maxUomCapacity?: {
              __typename?: 'AssignmentOccupancyCapacity';
              uomQuantity?: number | null;
              quantity?: number | null;
              occupiedVolume?: number | null;
              uom?: {
                __typename?: 'ItemUomVolume';
                uom: string;
                maxStack?: number | null;
              } | null;
            } | null;
          }>;
        }>;
      };
    } | null;
  } | null;
};

export type LoadAssignmentOccupancyForLocationsStatusOnlyQueryVariables =
  Exact<{
    simulationId: Scalars['ID']['input'];
    assignmentId: Scalars['ID']['input'];
    filter?: InputMaybe<AssignmentOccupancyLocationFilter>;
    sortBy?: InputMaybe<
      | Array<AssignmentOccupancyLocationSortBy>
      | AssignmentOccupancyLocationSortBy
    >;
    page?: InputMaybe<SkipLimitPageSpec>;
  }>;

export type LoadAssignmentOccupancyForLocationsStatusOnlyQuery = {
  __typename?: 'Query';
  simulation?: {
    __typename?: 'Simulation';
    assignmentOccupancy?: {
      __typename?: 'AssignmentOccupancy';
      locations: {
        __typename?: 'AssignmentOccupancyLocationsConnection';
        totalCount: number;
        content: Array<{
          __typename?: 'AssignmentOccupancyLocationDetails';
          locationId: string;
          locationVolume?: number | null;
          occupiedVolume?: number | null;
          occupancy?: number | null;
          status: AssignmentOccupancyLocationStatus;
          items: Array<{
            __typename?: 'AssignmentOccupancyLocationItem';
            itemId: string;
            consignee: string;
            sku: string;
            status: AssignmentOccupancyLocationItemStatus;
            stockUomQuantity?: number | null;
            stockUomQuantityCompliance?: StockUomQuantityCompliance | null;
            occupiedVolume?: number | null;
            stockUom?: {
              __typename?: 'ItemUomVolume';
              uom: string;
              length?: number | null;
              width?: number | null;
              height?: number | null;
              volume?: number | null;
              maxStack?: number | null;
            } | null;
            stockUomCapacity?: {
              __typename?: 'AssignmentOccupancyCapacity';
              uomQuantity?: number | null;
              quantity?: number | null;
              occupiedVolume?: number | null;
            } | null;
            maxUomCapacity?: {
              __typename?: 'AssignmentOccupancyCapacity';
              uomQuantity?: number | null;
              quantity?: number | null;
              occupiedVolume?: number | null;
              uom?: {
                __typename?: 'ItemUomVolume';
                uom: string;
                maxStack?: number | null;
              } | null;
            } | null;
          }>;
        }>;
      };
    } | null;
  } | null;
};

export type LoadAssignmentOccupancyMetaQueryVariables = Exact<{
  simulationId: Scalars['ID']['input'];
  assignmentId: Scalars['ID']['input'];
}>;

export type LoadAssignmentOccupancyMetaQuery = {
  __typename?: 'Query';
  simulation?: {
    __typename?: 'Simulation';
    assignmentOccupancy?: {
      __typename?: 'AssignmentOccupancy';
      id: string;
      status: BatchJobStatus;
      progress: number;
      createdAt: number;
      updatedAt: number;
      summary?: {
        __typename?: 'AssignmentOccupancySummary';
        totalStorageVolume?: number | null;
        totalOccupiedVolume?: number | null;
        totalUnknownVolume?: number | null;
        totalOccupancy?: number | null;
        maxOccupancy?: number | null;
        stockUomCapacitySummary?: {
          __typename?: 'AssignmentOccupancyCapacitySummary';
          locationsWithCapacity?: number | null;
          locationsWithUnknownCapacity?: number | null;
          emptyLocations?: number | null;
          totalCapacityVolume?: number | null;
          totalEmptyVolume?: number | null;
          totalUnknownVolume?: number | null;
        } | null;
        maxUomCapacitySummary?: {
          __typename?: 'AssignmentOccupancyCapacitySummary';
          locationsWithCapacity?: number | null;
          locationsWithUnknownCapacity?: number | null;
          emptyLocations?: number | null;
          totalCapacityVolume?: number | null;
          totalEmptyVolume?: number | null;
          totalUnknownVolume?: number | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type AssignmentOccupancySummaryGroupFragment = {
  __typename?: 'AssignmentOccupancySummaryGroup';
  totalStorageVolume?: number | null;
  totalOccupiedVolume?: number | null;
  totalUnknownVolume?: number | null;
  totalOccupancy?: number | null;
  maxOccupancy?: number | null;
  locationCount: number;
  stockUomCapacitySummary?: {
    __typename?: 'AssignmentOccupancyCapacitySummary';
    locationsWithCapacity?: number | null;
    locationsWithUnknownCapacity?: number | null;
    emptyLocations?: number | null;
    totalCapacityVolume?: number | null;
    totalEmptyVolume?: number | null;
    totalUnknownVolume?: number | null;
  } | null;
  maxUomCapacitySummary?: {
    __typename?: 'AssignmentOccupancyCapacitySummary';
    locationsWithCapacity?: number | null;
    locationsWithUnknownCapacity?: number | null;
    emptyLocations?: number | null;
    totalCapacityVolume?: number | null;
    totalEmptyVolume?: number | null;
    totalUnknownVolume?: number | null;
  } | null;
  key: {
    __typename?: 'AssignmentOccupancySummaryGroupKey';
    status?: AssignmentOccupancyLocationStatus | null;
    planeId?: string | null;
    warehouseArea?: string | null;
    locationLevel?: number | null;
    locationRackingType?: string | null;
  };
};

export type LoadAssignmentOccupancyByGroupQueryVariables = Exact<{
  simulationId: Scalars['ID']['input'];
  assignmentId: Scalars['ID']['input'];
  filter?: InputMaybe<AssignmentOccupancyLocationFilter>;
  groupBy?: InputMaybe<
    Array<AssignmentOccupancySummaryGroupBy> | AssignmentOccupancySummaryGroupBy
  >;
}>;

export type LoadAssignmentOccupancyByGroupQuery = {
  __typename?: 'Query';
  simulation?: {
    __typename?: 'Simulation';
    assignmentOccupancy?: {
      __typename?: 'AssignmentOccupancy';
      summaryByGroup: Array<{
        __typename?: 'AssignmentOccupancySummaryGroup';
        totalStorageVolume?: number | null;
        totalOccupiedVolume?: number | null;
        totalUnknownVolume?: number | null;
        totalOccupancy?: number | null;
        maxOccupancy?: number | null;
        locationCount: number;
        stockUomCapacitySummary?: {
          __typename?: 'AssignmentOccupancyCapacitySummary';
          locationsWithCapacity?: number | null;
          locationsWithUnknownCapacity?: number | null;
          emptyLocations?: number | null;
          totalCapacityVolume?: number | null;
          totalEmptyVolume?: number | null;
          totalUnknownVolume?: number | null;
        } | null;
        maxUomCapacitySummary?: {
          __typename?: 'AssignmentOccupancyCapacitySummary';
          locationsWithCapacity?: number | null;
          locationsWithUnknownCapacity?: number | null;
          emptyLocations?: number | null;
          totalCapacityVolume?: number | null;
          totalEmptyVolume?: number | null;
          totalUnknownVolume?: number | null;
        } | null;
        key: {
          __typename?: 'AssignmentOccupancySummaryGroupKey';
          status?: AssignmentOccupancyLocationStatus | null;
          planeId?: string | null;
          warehouseArea?: string | null;
          locationLevel?: number | null;
          locationRackingType?: string | null;
        };
      }>;
    } | null;
  } | null;
};

export type RunCalculateSimulationAssignmentOccupancyMutationVariables = Exact<{
  simulationId: Scalars['String']['input'];
  assignmentId: Scalars['ID']['input'];
}>;

export type RunCalculateSimulationAssignmentOccupancyMutation = {
  __typename?: 'Mutation';
  calculateSimulationAssignmentOccupancy: {
    __typename?: 'AssignmentOccupancy';
    id: string;
    status: BatchJobStatus;
    progress: number;
    createdAt: number;
    updatedAt: number;
    summary?: {
      __typename?: 'AssignmentOccupancySummary';
      totalStorageVolume?: number | null;
      totalOccupiedVolume?: number | null;
      totalUnknownVolume?: number | null;
      totalOccupancy?: number | null;
      maxOccupancy?: number | null;
      stockUomCapacitySummary?: {
        __typename?: 'AssignmentOccupancyCapacitySummary';
        locationsWithCapacity?: number | null;
        locationsWithUnknownCapacity?: number | null;
        emptyLocations?: number | null;
        totalCapacityVolume?: number | null;
        totalEmptyVolume?: number | null;
        totalUnknownVolume?: number | null;
      } | null;
      maxUomCapacitySummary?: {
        __typename?: 'AssignmentOccupancyCapacitySummary';
        locationsWithCapacity?: number | null;
        locationsWithUnknownCapacity?: number | null;
        emptyLocations?: number | null;
        totalCapacityVolume?: number | null;
        totalEmptyVolume?: number | null;
        totalUnknownVolume?: number | null;
      } | null;
    } | null;
  };
};

export type LoadAssignmentOccupancyQueryVariables = Exact<{
  simulationId: Scalars['ID']['input'];
  assignmentId: Scalars['ID']['input'];
  categoryWidths: Array<Scalars['Float']['input']> | Scalars['Float']['input'];
  filter?: InputMaybe<AssignmentOccupancyLocationFilter>;
}>;

export type LoadAssignmentOccupancyQuery = {
  __typename?: 'Query';
  simulation?: {
    __typename?: 'Simulation';
    assignmentOccupancy?: {
      __typename?: 'AssignmentOccupancy';
      id: string;
      status: BatchJobStatus;
      progress: number;
      createdAt: number;
      updatedAt: number;
      locationsByOccupancyCategory: {
        __typename?: 'AssignmentOccupancyLocationsByCategoryConnection';
        totalCount: number;
        categoriesByStatus: Array<{
          __typename?: 'AssignmentOccupancyLocationsCategoryByStatus';
          status: AssignmentOccupancyLocationStatus;
          count: number;
          totalStorageVolume?: number | null;
        }>;
        categories: Array<{
          __typename?: 'AssignmentOccupancyLocationsCategory';
          minBoundary?: number | null;
          maxBoundary?: number | null;
          minOccupancy?: number | null;
          maxOccupancy?: number | null;
          count: number;
          totalStorageVolume?: number | null;
          totalOccupiedVolume?: number | null;
        }>;
      };
      summary?: {
        __typename?: 'AssignmentOccupancySummary';
        totalStorageVolume?: number | null;
        totalOccupiedVolume?: number | null;
        totalUnknownVolume?: number | null;
        totalOccupancy?: number | null;
        maxOccupancy?: number | null;
        stockUomCapacitySummary?: {
          __typename?: 'AssignmentOccupancyCapacitySummary';
          locationsWithCapacity?: number | null;
          locationsWithUnknownCapacity?: number | null;
          emptyLocations?: number | null;
          totalCapacityVolume?: number | null;
          totalEmptyVolume?: number | null;
          totalUnknownVolume?: number | null;
        } | null;
        maxUomCapacitySummary?: {
          __typename?: 'AssignmentOccupancyCapacitySummary';
          locationsWithCapacity?: number | null;
          locationsWithUnknownCapacity?: number | null;
          emptyLocations?: number | null;
          totalCapacityVolume?: number | null;
          totalEmptyVolume?: number | null;
          totalUnknownVolume?: number | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type FindProductFilterValuesQueryVariables = Exact<{
  input: FindSimulationItemFilterValuesInput;
  page?: InputMaybe<SkipLimitPageSpec>;
}>;

export type FindProductFilterValuesQuery = {
  __typename?: 'Query';
  findSimulationItemFilterValues: {
    __typename?: 'FilterValuesConnection';
    totalCount?: number | null;
    content?: Array<{ __typename?: 'FilterValue'; title: string }> | null;
    range?: {
      __typename?: 'NumberFilterRange';
      from?: number | null;
      to?: number | null;
    } | null;
  };
};

export type FindLocationFilterValuesFilterResultFragment = {
  __typename?: 'FindLocationFilterValuesFilterResult';
  filterType: LocationFilterType;
  totalCount?: number | null;
  content?: Array<{ __typename?: 'FilterValue'; title: string }> | null;
};

export type FindLocationFilterValuesQueryVariables = Exact<{
  layoutId: Scalars['ID']['input'];
  input: FindLocationFilterValuesInput;
}>;

export type FindLocationFilterValuesQuery = {
  __typename?: 'Query';
  layout?: {
    __typename?: 'Layout';
    locationFilterValues: Array<{
      __typename?: 'FindLocationFilterValuesFilterResult';
      filterType: LocationFilterType;
      totalCount?: number | null;
      content?: Array<{ __typename?: 'FilterValue'; title: string }> | null;
    }>;
  } | null;
};

export type SimulationItemFilterFragment = {
  __typename?: 'SimulationItemFilter';
  type: SimulationItemFilterType;
  isNull?: boolean | null;
  isNot?: boolean | null;
  valueIn?: Array<{ __typename?: 'FilterValue'; title: string }> | null;
  range?: {
    __typename?: 'NumberFilterRange';
    from?: number | null;
    to?: number | null;
  } | null;
  stringRange?: {
    __typename?: 'StringFilterRange';
    from?: string | null;
    excludeFrom?: boolean | null;
    to?: string | null;
    excludeTo?: boolean | null;
  } | null;
};

export type SimulationItemFilterIntersectionFragment = {
  __typename?: 'SimulationItemFilterIntersection';
  id?: string | null;
  allOf: Array<{
    __typename?: 'SimulationItemFilter';
    type: SimulationItemFilterType;
    isNull?: boolean | null;
    isNot?: boolean | null;
    valueIn?: Array<{ __typename?: 'FilterValue'; title: string }> | null;
    range?: {
      __typename?: 'NumberFilterRange';
      from?: number | null;
      to?: number | null;
    } | null;
    stringRange?: {
      __typename?: 'StringFilterRange';
      from?: string | null;
      excludeFrom?: boolean | null;
      to?: string | null;
      excludeTo?: boolean | null;
    } | null;
  }>;
};

export type SimulationItemFilterUnionFragment = {
  __typename?: 'SimulationItemFilterUnion';
  anyOf: Array<{
    __typename?: 'SimulationItemFilterIntersection';
    id?: string | null;
    allOf: Array<{
      __typename?: 'SimulationItemFilter';
      type: SimulationItemFilterType;
      isNull?: boolean | null;
      isNot?: boolean | null;
      valueIn?: Array<{ __typename?: 'FilterValue'; title: string }> | null;
      range?: {
        __typename?: 'NumberFilterRange';
        from?: number | null;
        to?: number | null;
      } | null;
      stringRange?: {
        __typename?: 'StringFilterRange';
        from?: string | null;
        excludeFrom?: boolean | null;
        to?: string | null;
        excludeTo?: boolean | null;
      } | null;
    }>;
  }>;
};

export type LocationFilterFragment = {
  __typename?: 'LocationFilter';
  type: LocationFilterType;
  isNull?: boolean | null;
  isNot?: boolean | null;
  valueIn?: Array<{ __typename?: 'FilterValue'; title: string }> | null;
  range?: {
    __typename?: 'NumberFilterRange';
    from?: number | null;
    to?: number | null;
  } | null;
  stringRange?: {
    __typename?: 'StringFilterRange';
    from?: string | null;
    excludeFrom?: boolean | null;
    to?: string | null;
    excludeTo?: boolean | null;
  } | null;
};

export type LocationFilterIntersectionFragment = {
  __typename?: 'LocationFilterIntersection';
  id?: string | null;
  allOf: Array<{
    __typename?: 'LocationFilter';
    type: LocationFilterType;
    isNull?: boolean | null;
    isNot?: boolean | null;
    valueIn?: Array<{ __typename?: 'FilterValue'; title: string }> | null;
    range?: {
      __typename?: 'NumberFilterRange';
      from?: number | null;
      to?: number | null;
    } | null;
    stringRange?: {
      __typename?: 'StringFilterRange';
      from?: string | null;
      excludeFrom?: boolean | null;
      to?: string | null;
      excludeTo?: boolean | null;
    } | null;
  }>;
};

export type LocationFilterUnionFragment = {
  __typename?: 'LocationFilterUnion';
  anyOf: Array<{
    __typename?: 'LocationFilterIntersection';
    id?: string | null;
    allOf: Array<{
      __typename?: 'LocationFilter';
      type: LocationFilterType;
      isNull?: boolean | null;
      isNot?: boolean | null;
      valueIn?: Array<{ __typename?: 'FilterValue'; title: string }> | null;
      range?: {
        __typename?: 'NumberFilterRange';
        from?: number | null;
        to?: number | null;
      } | null;
      stringRange?: {
        __typename?: 'StringFilterRange';
        from?: string | null;
        excludeFrom?: boolean | null;
        to?: string | null;
        excludeTo?: boolean | null;
      } | null;
    }>;
  }>;
};

export type AssignmentPolicyRuleFragment = {
  __typename?: 'AssignmentPolicyRule';
  id: string;
  title?: string | null;
  type?: AssignmentPolicyRuleMatchType | null;
  productsMatch?: {
    __typename?: 'SimulationItemFilterUnion';
    anyOf: Array<{
      __typename?: 'SimulationItemFilterIntersection';
      id?: string | null;
      allOf: Array<{
        __typename?: 'SimulationItemFilter';
        type: SimulationItemFilterType;
        isNull?: boolean | null;
        isNot?: boolean | null;
        valueIn?: Array<{ __typename?: 'FilterValue'; title: string }> | null;
        range?: {
          __typename?: 'NumberFilterRange';
          from?: number | null;
          to?: number | null;
        } | null;
        stringRange?: {
          __typename?: 'StringFilterRange';
          from?: string | null;
          excludeFrom?: boolean | null;
          to?: string | null;
          excludeTo?: boolean | null;
        } | null;
      }>;
    }>;
  } | null;
  locationsMatch?: {
    __typename?: 'LocationFilterUnion';
    anyOf: Array<{
      __typename?: 'LocationFilterIntersection';
      id?: string | null;
      allOf: Array<{
        __typename?: 'LocationFilter';
        type: LocationFilterType;
        isNull?: boolean | null;
        isNot?: boolean | null;
        valueIn?: Array<{ __typename?: 'FilterValue'; title: string }> | null;
        range?: {
          __typename?: 'NumberFilterRange';
          from?: number | null;
          to?: number | null;
        } | null;
        stringRange?: {
          __typename?: 'StringFilterRange';
          from?: string | null;
          excludeFrom?: boolean | null;
          to?: string | null;
          excludeTo?: boolean | null;
        } | null;
      }>;
    }>;
  } | null;
};

export type AssignmentPolicyFragment = {
  __typename?: 'AssignmentPolicy';
  fallbackLocationsAllowAnyItems?: boolean | null;
  fallbackItemsAllowAnyLocations?: boolean | null;
  rules: Array<{
    __typename?: 'AssignmentPolicyRule';
    id: string;
    title?: string | null;
    type?: AssignmentPolicyRuleMatchType | null;
    productsMatch?: {
      __typename?: 'SimulationItemFilterUnion';
      anyOf: Array<{
        __typename?: 'SimulationItemFilterIntersection';
        id?: string | null;
        allOf: Array<{
          __typename?: 'SimulationItemFilter';
          type: SimulationItemFilterType;
          isNull?: boolean | null;
          isNot?: boolean | null;
          valueIn?: Array<{ __typename?: 'FilterValue'; title: string }> | null;
          range?: {
            __typename?: 'NumberFilterRange';
            from?: number | null;
            to?: number | null;
          } | null;
          stringRange?: {
            __typename?: 'StringFilterRange';
            from?: string | null;
            excludeFrom?: boolean | null;
            to?: string | null;
            excludeTo?: boolean | null;
          } | null;
        }>;
      }>;
    } | null;
    locationsMatch?: {
      __typename?: 'LocationFilterUnion';
      anyOf: Array<{
        __typename?: 'LocationFilterIntersection';
        id?: string | null;
        allOf: Array<{
          __typename?: 'LocationFilter';
          type: LocationFilterType;
          isNull?: boolean | null;
          isNot?: boolean | null;
          valueIn?: Array<{ __typename?: 'FilterValue'; title: string }> | null;
          range?: {
            __typename?: 'NumberFilterRange';
            from?: number | null;
            to?: number | null;
          } | null;
          stringRange?: {
            __typename?: 'StringFilterRange';
            from?: string | null;
            excludeFrom?: boolean | null;
            to?: string | null;
            excludeTo?: boolean | null;
          } | null;
        }>;
      }>;
    } | null;
  }>;
  fallbackLocationsMatch?: {
    __typename?: 'LocationFilterUnion';
    anyOf: Array<{
      __typename?: 'LocationFilterIntersection';
      id?: string | null;
      allOf: Array<{
        __typename?: 'LocationFilter';
        type: LocationFilterType;
        isNull?: boolean | null;
        isNot?: boolean | null;
        valueIn?: Array<{ __typename?: 'FilterValue'; title: string }> | null;
        range?: {
          __typename?: 'NumberFilterRange';
          from?: number | null;
          to?: number | null;
        } | null;
        stringRange?: {
          __typename?: 'StringFilterRange';
          from?: string | null;
          excludeFrom?: boolean | null;
          to?: string | null;
          excludeTo?: boolean | null;
        } | null;
      }>;
    }>;
  } | null;
};

export type FilterMatchFragment = {
  __typename?: 'FilterMatch';
  total: number;
  exclusive: number;
};

export type CheckedAssignmentPolicyRuleFragment = {
  __typename?: 'CheckedAssignmentPolicyRule';
  id: string;
  title?: string | null;
  productFilterMatch: {
    __typename?: 'FilterMatch';
    total: number;
    exclusive: number;
  };
  locationFilterMatch: {
    __typename?: 'FilterMatch';
    total: number;
    exclusive: number;
  };
};

export type CheckedAssignmentPolicyFragment = {
  __typename?: 'CheckedAssignmentPolicy';
  rules: Array<{
    __typename?: 'CheckedAssignmentPolicyRule';
    id: string;
    title?: string | null;
    productFilterMatch: {
      __typename?: 'FilterMatch';
      total: number;
      exclusive: number;
    };
    locationFilterMatch: {
      __typename?: 'FilterMatch';
      total: number;
      exclusive: number;
    };
  }>;
  fallbackLocationFilterMatch: {
    __typename?: 'FilterMatch';
    total: number;
    exclusive: number;
  };
  fallbackItemFilterMatch: {
    __typename?: 'FilterMatch';
    total: number;
    exclusive: number;
  };
  allProducts: { __typename?: 'FilterMatch'; total: number; exclusive: number };
  allLocations: {
    __typename?: 'FilterMatch';
    total: number;
    exclusive: number;
  };
};

export type CheckSimulationAssignmentPolicyResultFragment = {
  __typename?: 'CheckSimulationAssignmentPolicyResult';
  policy: {
    __typename?: 'CheckedAssignmentPolicy';
    rules: Array<{
      __typename?: 'CheckedAssignmentPolicyRule';
      id: string;
      title?: string | null;
      productFilterMatch: {
        __typename?: 'FilterMatch';
        total: number;
        exclusive: number;
      };
      locationFilterMatch: {
        __typename?: 'FilterMatch';
        total: number;
        exclusive: number;
      };
    }>;
    fallbackLocationFilterMatch: {
      __typename?: 'FilterMatch';
      total: number;
      exclusive: number;
    };
    fallbackItemFilterMatch: {
      __typename?: 'FilterMatch';
      total: number;
      exclusive: number;
    };
    allProducts: {
      __typename?: 'FilterMatch';
      total: number;
      exclusive: number;
    };
    allLocations: {
      __typename?: 'FilterMatch';
      total: number;
      exclusive: number;
    };
  };
};

export type CheckAssignmentPolicyQueryVariables = Exact<{
  input: CheckSimulationAssignmentPolicyInput;
}>;

export type CheckAssignmentPolicyQuery = {
  __typename?: 'Query';
  checkSimulationAssignmentPolicy: {
    __typename?: 'CheckSimulationAssignmentPolicyResult';
    policy: {
      __typename?: 'CheckedAssignmentPolicy';
      rules: Array<{
        __typename?: 'CheckedAssignmentPolicyRule';
        id: string;
        title?: string | null;
        productFilterMatch: {
          __typename?: 'FilterMatch';
          total: number;
          exclusive: number;
        };
        locationFilterMatch: {
          __typename?: 'FilterMatch';
          total: number;
          exclusive: number;
        };
      }>;
      fallbackLocationFilterMatch: {
        __typename?: 'FilterMatch';
        total: number;
        exclusive: number;
      };
      fallbackItemFilterMatch: {
        __typename?: 'FilterMatch';
        total: number;
        exclusive: number;
      };
      allProducts: {
        __typename?: 'FilterMatch';
        total: number;
        exclusive: number;
      };
      allLocations: {
        __typename?: 'FilterMatch';
        total: number;
        exclusive: number;
      };
    };
  };
};

export type ProductIdFragment = {
  __typename?: 'ProductId';
  consignee: string;
  sku: string;
};

export type SimulationItemBaseUomDataFragment = {
  __typename?: 'BaseUom';
  uom?: string | null;
  netWeight?: number | null;
  length?: number | null;
  width?: number | null;
  height?: number | null;
  volume?: number | null;
  maxStack?: number | null;
};

export type SimulationItemDataFragment = {
  __typename?: 'SimulationItem';
  id: string;
  consignee: string;
  sku: string;
  skuGroup?: string | null;
  subGroup?: string | null;
  transportClass?: string | null;
  stockCategory?: string | null;
  percentRank?: number | null;
  cumulativePercentRank?: number | null;
  baseUom?: {
    __typename?: 'BaseUom';
    uom?: string | null;
    netWeight?: number | null;
    length?: number | null;
    width?: number | null;
    height?: number | null;
    volume?: number | null;
    maxStack?: number | null;
  } | null;
};

export type FindSimulationItemsByFilterQueryVariables = Exact<{
  input: FindSimulationItemsByFilter;
  page?: InputMaybe<SkipLimitPageSpec>;
  filter?: InputMaybe<ItemsFilter>;
}>;

export type FindSimulationItemsByFilterQuery = {
  __typename?: 'Query';
  findSimulationItemsByFilter: {
    __typename?: 'SimulationItemsConnection';
    totalCount: number;
    content: Array<{
      __typename?: 'SimulationItem';
      id: string;
      consignee: string;
      sku: string;
      skuGroup?: string | null;
      subGroup?: string | null;
      transportClass?: string | null;
      stockCategory?: string | null;
      percentRank?: number | null;
      cumulativePercentRank?: number | null;
      baseUom?: {
        __typename?: 'BaseUom';
        uom?: string | null;
        netWeight?: number | null;
        length?: number | null;
        width?: number | null;
        height?: number | null;
        volume?: number | null;
        maxStack?: number | null;
      } | null;
    }>;
  };
};

export type FindSimulationItemsTotalByFilterQueryVariables = Exact<{
  input: FindSimulationItemsByFilter;
  filter?: InputMaybe<ItemsFilter>;
}>;

export type FindSimulationItemsTotalByFilterQuery = {
  __typename?: 'Query';
  findSimulationItemsByFilter: {
    __typename?: 'SimulationItemsConnection';
    totalCount: number;
  };
};

export type SimulationItemSetFragment = {
  __typename?: 'SimulationItemSet';
  id: string;
  status: BatchJobStatus;
  createdAt: number;
};

export type LoadSimulationItemSetQueryVariables = Exact<{
  simulationId: Scalars['ID']['input'];
}>;

export type LoadSimulationItemSetQuery = {
  __typename?: 'Query';
  simulation?: {
    __typename?: 'Simulation';
    effectiveItemSet?: {
      __typename?: 'SimulationItemSet';
      id: string;
      status: BatchJobStatus;
      createdAt: number;
    } | null;
  } | null;
};

export type RunGenerateSimulationItemSetMutationVariables = Exact<{
  simulationId: Scalars['String']['input'];
}>;

export type RunGenerateSimulationItemSetMutation = {
  __typename?: 'Mutation';
  generateSimulationItemSet: {
    __typename?: 'SimulationItemSet';
    id: string;
    status: BatchJobStatus;
    createdAt: number;
  };
};

export type AssignmentItemStatFragment = {
  __typename?: 'Item';
  id: string;
  consignee: string;
  sku: string;
  description?: string | null;
  skuGroup?: string | null;
  subGroup?: string | null;
  pollutionClass?: string | null;
  transportClass?: string | null;
  stockCategory?: string | null;
  storageClass?: string | null;
  baseUom?: {
    __typename?: 'BaseUom';
    uom?: string | null;
    netWeight?: number | null;
    volume?: number | null;
    length?: number | null;
    width?: number | null;
    height?: number | null;
    maxStack?: number | null;
  } | null;
};

export type AssignmentLocationItemStatFragment = {
  __typename?: 'AssignmentItemWithMatchingFilter';
  locationId: string;
  stockUom?: string | null;
  stockUomQuantity?: number | null;
  item?: {
    __typename?: 'Item';
    id: string;
    consignee: string;
    sku: string;
    description?: string | null;
    skuGroup?: string | null;
    subGroup?: string | null;
    pollutionClass?: string | null;
    transportClass?: string | null;
    stockCategory?: string | null;
    storageClass?: string | null;
    baseUom?: {
      __typename?: 'BaseUom';
      uom?: string | null;
      netWeight?: number | null;
      volume?: number | null;
      length?: number | null;
      width?: number | null;
      height?: number | null;
      maxStack?: number | null;
    } | null;
  } | null;
  location?: {
    __typename?: 'LayoutLocation';
    locationWidth: number;
    locationHeight: number;
    locationLength: number;
    locationRackingType: string;
    locationUsageType: string;
    locationOrder: string;
    congestionZone?: string | null;
  } | null;
};

export type LoadAssignmentStatsQueryVariables = Exact<{
  assignmentId: Scalars['ID']['input'];
  itemSetId: Scalars['ID']['input'];
  layoutId: Scalars['ID']['input'];
  filter?: InputMaybe<AssignmentItemsExtendedFilter>;
  itemFilter?: InputMaybe<
    Array<InputMaybe<ItemFilterUnionInput>> | InputMaybe<ItemFilterUnionInput>
  >;
  matchingOnly?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type LoadAssignmentStatsQuery = {
  __typename?: 'Query';
  applyAssignmentItemFilter: {
    __typename?: 'AssignmentItemWithMatchingFiltersConnection';
    content: Array<{
      __typename?: 'AssignmentItemWithMatchingFilter';
      locationId: string;
      stockUom?: string | null;
      stockUomQuantity?: number | null;
      item?: {
        __typename?: 'Item';
        id: string;
        consignee: string;
        sku: string;
        description?: string | null;
        skuGroup?: string | null;
        subGroup?: string | null;
        pollutionClass?: string | null;
        transportClass?: string | null;
        stockCategory?: string | null;
        storageClass?: string | null;
        baseUom?: {
          __typename?: 'BaseUom';
          uom?: string | null;
          netWeight?: number | null;
          volume?: number | null;
          length?: number | null;
          width?: number | null;
          height?: number | null;
          maxStack?: number | null;
        } | null;
      } | null;
      location?: {
        __typename?: 'LayoutLocation';
        locationWidth: number;
        locationHeight: number;
        locationLength: number;
        locationRackingType: string;
        locationUsageType: string;
        locationOrder: string;
        congestionZone?: string | null;
      } | null;
    }>;
  };
};

export type ExportJobFragment = {
  __typename?: 'ExportJob';
  id: string;
  status: BatchJobStatus;
  downloadUrl?: string | null;
  fileSize?: number | null;
  filename: string;
};

export type LoadExportJobQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type LoadExportJobQuery = {
  __typename?: 'Query';
  exportJob?: {
    __typename?: 'ExportJob';
    id: string;
    status: BatchJobStatus;
    downloadUrl?: string | null;
    fileSize?: number | null;
    filename: string;
  } | null;
};

export type ExportLayoutLocationsMutationVariables = Exact<{
  layoutId: Scalars['String']['input'];
}>;

export type ExportLayoutLocationsMutation = {
  __typename?: 'Mutation';
  exportLayoutLocations: {
    __typename?: 'ExportJob';
    id: string;
    status: BatchJobStatus;
    downloadUrl?: string | null;
    fileSize?: number | null;
    filename: string;
  };
};

export type ExportLocationWeightComplianceMutationVariables = Exact<{
  csvOptions?: InputMaybe<CsvExportOptionsInput>;
  filter?: InputMaybe<WeightComplianceLocationFilter>;
  weightComplianceId: Scalars['String']['input'];
}>;

export type ExportLocationWeightComplianceMutation = {
  __typename?: 'Mutation';
  exportLocationWeightCompliance: {
    __typename?: 'ExportJob';
    id: string;
    status: BatchJobStatus;
    downloadUrl?: string | null;
    fileSize?: number | null;
    filename: string;
  };
};

export type ExportBayWeightComplianceMutationVariables = Exact<{
  csvOptions?: InputMaybe<CsvExportOptionsInput>;
  filter?: InputMaybe<WeightComplianceBayFilter>;
  weightComplianceId: Scalars['String']['input'];
}>;

export type ExportBayWeightComplianceMutation = {
  __typename?: 'Mutation';
  exportBayWeightCompliance: {
    __typename?: 'ExportJob';
    id: string;
    status: BatchJobStatus;
    downloadUrl?: string | null;
    fileSize?: number | null;
    filename: string;
  };
};

export type ExportSizeComplianceMutationVariables = Exact<{
  csvOptions?: InputMaybe<CsvExportOptionsInput>;
  filter?: InputMaybe<SizeComplianceLocationFilter>;
  sizeComplianceId: Scalars['String']['input'];
}>;

export type ExportSizeComplianceMutation = {
  __typename?: 'Mutation';
  exportSizeCompliance: {
    __typename?: 'ExportJob';
    id: string;
    status: BatchJobStatus;
    downloadUrl?: string | null;
    fileSize?: number | null;
    filename: string;
  };
};

export type ExportAssignmentComplianceMutationVariables = Exact<{
  csvOptions?: InputMaybe<CsvExportOptionsInput>;
  filter?: InputMaybe<AssignmentComplianceItemFilter>;
  assignmentComplianceId: Scalars['String']['input'];
}>;

export type ExportAssignmentComplianceMutation = {
  __typename?: 'Mutation';
  exportAssignmentCompliance: {
    __typename?: 'ExportJob';
    id: string;
    status: BatchJobStatus;
    downloadUrl?: string | null;
    fileSize?: number | null;
    filename: string;
  };
};

export type ExportOptimizationRunAssignmentDiffMutationVariables = Exact<{
  id: Scalars['String']['input'];
  filter?: InputMaybe<AssignmentDiffItemsFilter>;
  rankCategories?: InputMaybe<CategoryMappingRules>;
}>;

export type ExportOptimizationRunAssignmentDiffMutation = {
  __typename?: 'Mutation';
  exportOptimizationRunAssignmentDiff: {
    __typename?: 'ExportJob';
    id: string;
    status: BatchJobStatus;
    downloadUrl?: string | null;
    fileSize?: number | null;
    filename: string;
  };
};

export type ExportAssignmentOccupancyMutationVariables = Exact<{
  csvOptions?: InputMaybe<CsvExportOptionsInput>;
  filter?: InputMaybe<AssignmentOccupancyLocationFilter>;
  assignmentOccupancyId: Scalars['String']['input'];
}>;

export type ExportAssignmentOccupancyMutation = {
  __typename?: 'Mutation';
  exportAssignmentOccupancy: {
    __typename?: 'ExportJob';
    id: string;
    status: BatchJobStatus;
    downloadUrl?: string | null;
    fileSize?: number | null;
    filename: string;
  };
};

export type ExportSimulationAssignmentDetailsMutationVariables = Exact<{
  csvOptions?: InputMaybe<CsvExportOptionsInput>;
  simulationId: Scalars['String']['input'];
  rankCategories?: InputMaybe<CategoryMappingRules>;
}>;

export type ExportSimulationAssignmentDetailsMutation = {
  __typename?: 'Mutation';
  exportSimulationAssignmentDetails: {
    __typename?: 'ExportJob';
    id: string;
    status: BatchJobStatus;
    downloadUrl?: string | null;
    fileSize?: number | null;
    filename: string;
  };
};

export type RunExportJobMutationVariables = Exact<{
  converterId: Scalars['String']['input'];
  query: Scalars['String']['input'];
  variables?: InputMaybe<Scalars['JSON']['input']>;
  filename: Scalars['String']['input'];
  config: Scalars['JSON']['input'];
  csvOptions?: InputMaybe<CsvExportOptionsInput>;
}>;

export type RunExportJobMutation = {
  __typename?: 'Mutation';
  createExportJob: {
    __typename?: 'ExportJob';
    id: string;
    status: BatchJobStatus;
    downloadUrl?: string | null;
    fileSize?: number | null;
    filename: string;
  };
};

export type SendExportJobMutationVariables = Exact<{
  input: SendExportJobInput;
}>;

export type SendExportJobMutation = {
  __typename?: 'Mutation';
  sendExportJob: {
    __typename?: 'ExportJob';
    id: string;
    status: BatchJobStatus;
  };
};

export type MappingValueResolverSpecItemFragment = {
  __typename?: 'MappingValueResolverSpecItem';
  title: string;
  value: string;
};

export type MappingValueResolverSpecFragment = {
  __typename?: 'MappingValueResolverSpec';
  values: Array<{
    __typename?: 'MappingValueResolverSpecItem';
    title: string;
    value: string;
  }>;
};

export type ImportJobTransformationSettingsFragment = {
  __typename?: 'ImportJobTransformationSettings';
  enabled: boolean;
  query?: string | null;
};

export type CsvImportOptionsFragment = {
  __typename?: 'CsvImportOptions';
  separator?: string | null;
  quote?: string | null;
  escape?: string | null;
};

export type MappingFieldSpecFragment = {
  __typename?: 'MappingFieldSpec';
  name: string;
  columnName?: string | null;
  index?: number | null;
  measureValue?: string | null;
  valueResolver?: {
    __typename?: 'MappingValueResolverSpec';
    values: Array<{
      __typename?: 'MappingValueResolverSpecItem';
      title: string;
      value: string;
    }>;
  } | null;
};

export type ImportJobMappingSettingsFragment = {
  __typename?: 'ImportJobMappingSettings';
  hasHeaders?: boolean | null;
  columnNames?: Array<string> | null;
  mapping?: {
    __typename?: 'MappingSpec';
    fields: Array<{
      __typename?: 'MappingFieldSpec';
      name: string;
      columnName?: string | null;
      index?: number | null;
      measureValue?: string | null;
      valueResolver?: {
        __typename?: 'MappingValueResolverSpec';
        values: Array<{
          __typename?: 'MappingValueResolverSpecItem';
          title: string;
          value: string;
        }>;
      } | null;
    }>;
  } | null;
  csvOptions?: {
    __typename?: 'CsvImportOptions';
    separator?: string | null;
    quote?: string | null;
    escape?: string | null;
  } | null;
  transformation?: {
    __typename?: 'ImportJobTransformationSettings';
    enabled: boolean;
    query?: string | null;
  } | null;
};

export type ImportJobSourceSettingsFragment = {
  __typename?: 'ImportJobSourceSettings';
  source: ImportSource;
  format: ImportFormat;
  filename?: string | null;
  compression?: ImportJobSourceCompression | null;
  externalSourceSettings?: {
    __typename?: 'ImportJobExternalSourceSettings';
    s3FolderUrl: string;
  } | null;
};

export type ImportJobBaseFragment = {
  __typename?: 'ImportJob';
  id: string;
  status: BatchJobStatus;
  progress: number;
  createdAt: number;
  updatedAt: number;
  startedAt?: number | null;
  completedAt?: number | null;
  userId: string;
  importType: ImportJobImportType;
  uploaded?: boolean | null;
  stage?: ImportJobProcessingStage | null;
  targetTitle?: string | null;
  locale?: string | null;
  extraSettings?: any | null;
  targetId?: string | null;
  downloadUrl?: string | null;
  sanitizedFailureReason?: string | null;
  importPipelineId?: string | null;
  importPipeline?: {
    __typename?: 'ImportPipeline';
    id: string;
    userId: string;
    importType: ImportJobImportType;
    title?: string | null;
    description?: string | null;
    enabled: boolean;
    createdAt: number;
    updatedAt: number;
    extraSettings?: any | null;
    settings?: {
      __typename?: 'ImportPipelineSettings';
      sourceSettings: {
        __typename?: 'ImportJobPipelineSourceSettings';
        format: ImportFormat;
        compression?: ImportJobSourceCompression | null;
      };
      targetSettings: {
        __typename?: 'ImportJobPipelineTargetSettings';
        target: ImportJobTargetType;
        newSettings?: {
          __typename?: 'ImportJobNewTargetSettings';
          targetTitle: string;
        } | null;
        appendSettings?: {
          __typename?: 'ImportJobAppendTargetSettings';
          targetId: string;
          overwrite?: boolean | null;
        } | null;
      };
      mappingSettings: {
        __typename?: 'ImportJobMappingSettings';
        hasHeaders?: boolean | null;
        columnNames?: Array<string> | null;
        mapping?: {
          __typename?: 'MappingSpec';
          fields: Array<{
            __typename?: 'MappingFieldSpec';
            name: string;
            columnName?: string | null;
            index?: number | null;
            measureValue?: string | null;
            valueResolver?: {
              __typename?: 'MappingValueResolverSpec';
              values: Array<{
                __typename?: 'MappingValueResolverSpecItem';
                title: string;
                value: string;
              }>;
            } | null;
          }>;
        } | null;
        csvOptions?: {
          __typename?: 'CsvImportOptions';
          separator?: string | null;
          quote?: string | null;
          escape?: string | null;
        } | null;
        transformation?: {
          __typename?: 'ImportJobTransformationSettings';
          enabled: boolean;
          query?: string | null;
        } | null;
      };
      integrationSettings?: {
        __typename?: 'ImportPipelineIntegrationSettings';
        connectors?: Array<{
          __typename?: 'ImportPipelineConnectorSettings';
          id: string;
          type: ImportPipelineConnectorType;
          enabled: boolean;
          sftpPull?: {
            __typename?: 'ImportPipelineSftpPullConnectorSettings';
            host: string;
            port?: number | null;
            username: string;
            password: string;
            path?: string | null;
            pattern?: string | null;
            schedule?: {
              __typename?: 'ImportPipelinePullScheduleSettings';
              cronExpression?: string | null;
            } | null;
            afterPull?: {
              __typename?: 'ImportPipelineAfterPullActionSettings';
              action: ImportPipelineAfterPullAction;
            } | null;
          } | null;
          datasetChanged?: {
            __typename?: 'ImportPipelineDatasetChangedConnectorSettings';
            sources: Array<{
              __typename?: 'ImportPipelineDatasetChangedConnectorSource';
              id: string;
            }>;
          } | null;
        }> | null;
      } | null;
    } | null;
    jobs: { __typename?: 'ImportJobsConnection'; totalCount: number };
    connectorsState: Array<{
      __typename?: 'ImportPipelineConnectorDetails';
      id: string;
      s3Push?: {
        __typename?: 'ImportPipelineS3PushConnectorDetails';
        s3Url?: string | null;
        awsCredentials?: {
          __typename?: 'AwsAccessKey';
          accessKeyId: string;
          secretAccessKey: string;
        } | null;
      } | null;
      apiPush?: {
        __typename?: 'ImportPipelineApiPushConnectorDetails';
        apiUrl?: string | null;
        apiAccessKey: string;
      } | null;
      emailPush?: {
        __typename?: 'ImportPipelineEmailPushConnectorDetails';
        address: string;
      } | null;
      datasetChanged?: {
        __typename?: 'ImportPipelineDatasetChangedConnectorDetails';
        sources: Array<{
          __typename?: 'DatasetObject';
          id: string;
          title: string;
          objectType: ImportJobImportType;
        }>;
      } | null;
    }>;
  } | null;
  sourceSettings: {
    __typename?: 'ImportJobSourceSettings';
    source: ImportSource;
    format: ImportFormat;
    filename?: string | null;
    compression?: ImportJobSourceCompression | null;
    externalSourceSettings?: {
      __typename?: 'ImportJobExternalSourceSettings';
      s3FolderUrl: string;
    } | null;
  };
  settings?: {
    __typename?: 'ImportJobMappingSettings';
    hasHeaders?: boolean | null;
    columnNames?: Array<string> | null;
    mapping?: {
      __typename?: 'MappingSpec';
      fields: Array<{
        __typename?: 'MappingFieldSpec';
        name: string;
        columnName?: string | null;
        index?: number | null;
        measureValue?: string | null;
        valueResolver?: {
          __typename?: 'MappingValueResolverSpec';
          values: Array<{
            __typename?: 'MappingValueResolverSpecItem';
            title: string;
            value: string;
          }>;
        } | null;
      }>;
    } | null;
    csvOptions?: {
      __typename?: 'CsvImportOptions';
      separator?: string | null;
      quote?: string | null;
      escape?: string | null;
    } | null;
    transformation?: {
      __typename?: 'ImportJobTransformationSettings';
      enabled: boolean;
      query?: string | null;
    } | null;
  } | null;
  summary?: {
    __typename?: 'ImportJobSummary';
    inputLineCount?: number | null;
    importedLineCount?: number | null;
    errorCount?: number | null;
  } | null;
};

export type ImportJobFragment = {
  __typename?: 'ImportJob';
  id: string;
  status: BatchJobStatus;
  progress: number;
  createdAt: number;
  updatedAt: number;
  startedAt?: number | null;
  completedAt?: number | null;
  userId: string;
  importType: ImportJobImportType;
  uploaded?: boolean | null;
  stage?: ImportJobProcessingStage | null;
  targetTitle?: string | null;
  locale?: string | null;
  extraSettings?: any | null;
  targetId?: string | null;
  downloadUrl?: string | null;
  sanitizedFailureReason?: string | null;
  importPipelineId?: string | null;
  errors: {
    __typename?: 'ImportJobErrorsConnection';
    totalCount: number;
    content: Array<{
      __typename?: 'MappingError';
      code: string;
      type?: string | null;
      property?: string | null;
      message: string;
      value?: string | null;
      line?: number | null;
      count?: number | null;
      valueResolver?: {
        __typename?: 'MappingValueResolverSpec';
        values: Array<{
          __typename?: 'MappingValueResolverSpecItem';
          value: string;
          title: string;
        }>;
      } | null;
    }>;
  };
  importPipeline?: {
    __typename?: 'ImportPipeline';
    id: string;
    userId: string;
    importType: ImportJobImportType;
    title?: string | null;
    description?: string | null;
    enabled: boolean;
    createdAt: number;
    updatedAt: number;
    extraSettings?: any | null;
    settings?: {
      __typename?: 'ImportPipelineSettings';
      sourceSettings: {
        __typename?: 'ImportJobPipelineSourceSettings';
        format: ImportFormat;
        compression?: ImportJobSourceCompression | null;
      };
      targetSettings: {
        __typename?: 'ImportJobPipelineTargetSettings';
        target: ImportJobTargetType;
        newSettings?: {
          __typename?: 'ImportJobNewTargetSettings';
          targetTitle: string;
        } | null;
        appendSettings?: {
          __typename?: 'ImportJobAppendTargetSettings';
          targetId: string;
          overwrite?: boolean | null;
        } | null;
      };
      mappingSettings: {
        __typename?: 'ImportJobMappingSettings';
        hasHeaders?: boolean | null;
        columnNames?: Array<string> | null;
        mapping?: {
          __typename?: 'MappingSpec';
          fields: Array<{
            __typename?: 'MappingFieldSpec';
            name: string;
            columnName?: string | null;
            index?: number | null;
            measureValue?: string | null;
            valueResolver?: {
              __typename?: 'MappingValueResolverSpec';
              values: Array<{
                __typename?: 'MappingValueResolverSpecItem';
                title: string;
                value: string;
              }>;
            } | null;
          }>;
        } | null;
        csvOptions?: {
          __typename?: 'CsvImportOptions';
          separator?: string | null;
          quote?: string | null;
          escape?: string | null;
        } | null;
        transformation?: {
          __typename?: 'ImportJobTransformationSettings';
          enabled: boolean;
          query?: string | null;
        } | null;
      };
      integrationSettings?: {
        __typename?: 'ImportPipelineIntegrationSettings';
        connectors?: Array<{
          __typename?: 'ImportPipelineConnectorSettings';
          id: string;
          type: ImportPipelineConnectorType;
          enabled: boolean;
          sftpPull?: {
            __typename?: 'ImportPipelineSftpPullConnectorSettings';
            host: string;
            port?: number | null;
            username: string;
            password: string;
            path?: string | null;
            pattern?: string | null;
            schedule?: {
              __typename?: 'ImportPipelinePullScheduleSettings';
              cronExpression?: string | null;
            } | null;
            afterPull?: {
              __typename?: 'ImportPipelineAfterPullActionSettings';
              action: ImportPipelineAfterPullAction;
            } | null;
          } | null;
          datasetChanged?: {
            __typename?: 'ImportPipelineDatasetChangedConnectorSettings';
            sources: Array<{
              __typename?: 'ImportPipelineDatasetChangedConnectorSource';
              id: string;
            }>;
          } | null;
        }> | null;
      } | null;
    } | null;
    jobs: { __typename?: 'ImportJobsConnection'; totalCount: number };
    connectorsState: Array<{
      __typename?: 'ImportPipelineConnectorDetails';
      id: string;
      s3Push?: {
        __typename?: 'ImportPipelineS3PushConnectorDetails';
        s3Url?: string | null;
        awsCredentials?: {
          __typename?: 'AwsAccessKey';
          accessKeyId: string;
          secretAccessKey: string;
        } | null;
      } | null;
      apiPush?: {
        __typename?: 'ImportPipelineApiPushConnectorDetails';
        apiUrl?: string | null;
        apiAccessKey: string;
      } | null;
      emailPush?: {
        __typename?: 'ImportPipelineEmailPushConnectorDetails';
        address: string;
      } | null;
      datasetChanged?: {
        __typename?: 'ImportPipelineDatasetChangedConnectorDetails';
        sources: Array<{
          __typename?: 'DatasetObject';
          id: string;
          title: string;
          objectType: ImportJobImportType;
        }>;
      } | null;
    }>;
  } | null;
  sourceSettings: {
    __typename?: 'ImportJobSourceSettings';
    source: ImportSource;
    format: ImportFormat;
    filename?: string | null;
    compression?: ImportJobSourceCompression | null;
    externalSourceSettings?: {
      __typename?: 'ImportJobExternalSourceSettings';
      s3FolderUrl: string;
    } | null;
  };
  settings?: {
    __typename?: 'ImportJobMappingSettings';
    hasHeaders?: boolean | null;
    columnNames?: Array<string> | null;
    mapping?: {
      __typename?: 'MappingSpec';
      fields: Array<{
        __typename?: 'MappingFieldSpec';
        name: string;
        columnName?: string | null;
        index?: number | null;
        measureValue?: string | null;
        valueResolver?: {
          __typename?: 'MappingValueResolverSpec';
          values: Array<{
            __typename?: 'MappingValueResolverSpecItem';
            title: string;
            value: string;
          }>;
        } | null;
      }>;
    } | null;
    csvOptions?: {
      __typename?: 'CsvImportOptions';
      separator?: string | null;
      quote?: string | null;
      escape?: string | null;
    } | null;
    transformation?: {
      __typename?: 'ImportJobTransformationSettings';
      enabled: boolean;
      query?: string | null;
    } | null;
  } | null;
  summary?: {
    __typename?: 'ImportJobSummary';
    inputLineCount?: number | null;
    importedLineCount?: number | null;
    errorCount?: number | null;
  } | null;
};

export type MappingErrorFragment = {
  __typename?: 'MappingError';
  code: string;
  type?: string | null;
  property?: string | null;
  message: string;
  value?: string | null;
  line?: number | null;
  count?: number | null;
  valueResolver?: {
    __typename?: 'MappingValueResolverSpec';
    values: Array<{
      __typename?: 'MappingValueResolverSpecItem';
      value: string;
      title: string;
    }>;
  } | null;
};

export type CreateImportJobMutationVariables = Exact<{
  input: CreateImportJobInput;
}>;

export type CreateImportJobMutation = {
  __typename?: 'Mutation';
  createImportJob: {
    __typename?: 'ImportJob';
    id: string;
    status: BatchJobStatus;
    progress: number;
    createdAt: number;
    updatedAt: number;
    startedAt?: number | null;
    completedAt?: number | null;
    userId: string;
    importType: ImportJobImportType;
    uploaded?: boolean | null;
    stage?: ImportJobProcessingStage | null;
    targetTitle?: string | null;
    locale?: string | null;
    extraSettings?: any | null;
    targetId?: string | null;
    downloadUrl?: string | null;
    sanitizedFailureReason?: string | null;
    importPipelineId?: string | null;
    errors: {
      __typename?: 'ImportJobErrorsConnection';
      totalCount: number;
      content: Array<{
        __typename?: 'MappingError';
        code: string;
        type?: string | null;
        property?: string | null;
        message: string;
        value?: string | null;
        line?: number | null;
        count?: number | null;
        valueResolver?: {
          __typename?: 'MappingValueResolverSpec';
          values: Array<{
            __typename?: 'MappingValueResolverSpecItem';
            value: string;
            title: string;
          }>;
        } | null;
      }>;
    };
    importPipeline?: {
      __typename?: 'ImportPipeline';
      id: string;
      userId: string;
      importType: ImportJobImportType;
      title?: string | null;
      description?: string | null;
      enabled: boolean;
      createdAt: number;
      updatedAt: number;
      extraSettings?: any | null;
      settings?: {
        __typename?: 'ImportPipelineSettings';
        sourceSettings: {
          __typename?: 'ImportJobPipelineSourceSettings';
          format: ImportFormat;
          compression?: ImportJobSourceCompression | null;
        };
        targetSettings: {
          __typename?: 'ImportJobPipelineTargetSettings';
          target: ImportJobTargetType;
          newSettings?: {
            __typename?: 'ImportJobNewTargetSettings';
            targetTitle: string;
          } | null;
          appendSettings?: {
            __typename?: 'ImportJobAppendTargetSettings';
            targetId: string;
            overwrite?: boolean | null;
          } | null;
        };
        mappingSettings: {
          __typename?: 'ImportJobMappingSettings';
          hasHeaders?: boolean | null;
          columnNames?: Array<string> | null;
          mapping?: {
            __typename?: 'MappingSpec';
            fields: Array<{
              __typename?: 'MappingFieldSpec';
              name: string;
              columnName?: string | null;
              index?: number | null;
              measureValue?: string | null;
              valueResolver?: {
                __typename?: 'MappingValueResolverSpec';
                values: Array<{
                  __typename?: 'MappingValueResolverSpecItem';
                  title: string;
                  value: string;
                }>;
              } | null;
            }>;
          } | null;
          csvOptions?: {
            __typename?: 'CsvImportOptions';
            separator?: string | null;
            quote?: string | null;
            escape?: string | null;
          } | null;
          transformation?: {
            __typename?: 'ImportJobTransformationSettings';
            enabled: boolean;
            query?: string | null;
          } | null;
        };
        integrationSettings?: {
          __typename?: 'ImportPipelineIntegrationSettings';
          connectors?: Array<{
            __typename?: 'ImportPipelineConnectorSettings';
            id: string;
            type: ImportPipelineConnectorType;
            enabled: boolean;
            sftpPull?: {
              __typename?: 'ImportPipelineSftpPullConnectorSettings';
              host: string;
              port?: number | null;
              username: string;
              password: string;
              path?: string | null;
              pattern?: string | null;
              schedule?: {
                __typename?: 'ImportPipelinePullScheduleSettings';
                cronExpression?: string | null;
              } | null;
              afterPull?: {
                __typename?: 'ImportPipelineAfterPullActionSettings';
                action: ImportPipelineAfterPullAction;
              } | null;
            } | null;
            datasetChanged?: {
              __typename?: 'ImportPipelineDatasetChangedConnectorSettings';
              sources: Array<{
                __typename?: 'ImportPipelineDatasetChangedConnectorSource';
                id: string;
              }>;
            } | null;
          }> | null;
        } | null;
      } | null;
      jobs: { __typename?: 'ImportJobsConnection'; totalCount: number };
      connectorsState: Array<{
        __typename?: 'ImportPipelineConnectorDetails';
        id: string;
        s3Push?: {
          __typename?: 'ImportPipelineS3PushConnectorDetails';
          s3Url?: string | null;
          awsCredentials?: {
            __typename?: 'AwsAccessKey';
            accessKeyId: string;
            secretAccessKey: string;
          } | null;
        } | null;
        apiPush?: {
          __typename?: 'ImportPipelineApiPushConnectorDetails';
          apiUrl?: string | null;
          apiAccessKey: string;
        } | null;
        emailPush?: {
          __typename?: 'ImportPipelineEmailPushConnectorDetails';
          address: string;
        } | null;
        datasetChanged?: {
          __typename?: 'ImportPipelineDatasetChangedConnectorDetails';
          sources: Array<{
            __typename?: 'DatasetObject';
            id: string;
            title: string;
            objectType: ImportJobImportType;
          }>;
        } | null;
      }>;
    } | null;
    sourceSettings: {
      __typename?: 'ImportJobSourceSettings';
      source: ImportSource;
      format: ImportFormat;
      filename?: string | null;
      compression?: ImportJobSourceCompression | null;
      externalSourceSettings?: {
        __typename?: 'ImportJobExternalSourceSettings';
        s3FolderUrl: string;
      } | null;
    };
    settings?: {
      __typename?: 'ImportJobMappingSettings';
      hasHeaders?: boolean | null;
      columnNames?: Array<string> | null;
      mapping?: {
        __typename?: 'MappingSpec';
        fields: Array<{
          __typename?: 'MappingFieldSpec';
          name: string;
          columnName?: string | null;
          index?: number | null;
          measureValue?: string | null;
          valueResolver?: {
            __typename?: 'MappingValueResolverSpec';
            values: Array<{
              __typename?: 'MappingValueResolverSpecItem';
              title: string;
              value: string;
            }>;
          } | null;
        }>;
      } | null;
      csvOptions?: {
        __typename?: 'CsvImportOptions';
        separator?: string | null;
        quote?: string | null;
        escape?: string | null;
      } | null;
      transformation?: {
        __typename?: 'ImportJobTransformationSettings';
        enabled: boolean;
        query?: string | null;
      } | null;
    } | null;
    summary?: {
      __typename?: 'ImportJobSummary';
      inputLineCount?: number | null;
      importedLineCount?: number | null;
      errorCount?: number | null;
    } | null;
  };
};

export type UpdateImportJobMutationVariables = Exact<{
  input: UpdateImportJobInput;
}>;

export type UpdateImportJobMutation = {
  __typename?: 'Mutation';
  updateImportJob: {
    __typename?: 'ImportJob';
    id: string;
    status: BatchJobStatus;
    progress: number;
    createdAt: number;
    updatedAt: number;
    startedAt?: number | null;
    completedAt?: number | null;
    userId: string;
    importType: ImportJobImportType;
    uploaded?: boolean | null;
    stage?: ImportJobProcessingStage | null;
    targetTitle?: string | null;
    locale?: string | null;
    extraSettings?: any | null;
    targetId?: string | null;
    downloadUrl?: string | null;
    sanitizedFailureReason?: string | null;
    importPipelineId?: string | null;
    errors: {
      __typename?: 'ImportJobErrorsConnection';
      totalCount: number;
      content: Array<{
        __typename?: 'MappingError';
        code: string;
        type?: string | null;
        property?: string | null;
        message: string;
        value?: string | null;
        line?: number | null;
        count?: number | null;
        valueResolver?: {
          __typename?: 'MappingValueResolverSpec';
          values: Array<{
            __typename?: 'MappingValueResolverSpecItem';
            value: string;
            title: string;
          }>;
        } | null;
      }>;
    };
    importPipeline?: {
      __typename?: 'ImportPipeline';
      id: string;
      userId: string;
      importType: ImportJobImportType;
      title?: string | null;
      description?: string | null;
      enabled: boolean;
      createdAt: number;
      updatedAt: number;
      extraSettings?: any | null;
      settings?: {
        __typename?: 'ImportPipelineSettings';
        sourceSettings: {
          __typename?: 'ImportJobPipelineSourceSettings';
          format: ImportFormat;
          compression?: ImportJobSourceCompression | null;
        };
        targetSettings: {
          __typename?: 'ImportJobPipelineTargetSettings';
          target: ImportJobTargetType;
          newSettings?: {
            __typename?: 'ImportJobNewTargetSettings';
            targetTitle: string;
          } | null;
          appendSettings?: {
            __typename?: 'ImportJobAppendTargetSettings';
            targetId: string;
            overwrite?: boolean | null;
          } | null;
        };
        mappingSettings: {
          __typename?: 'ImportJobMappingSettings';
          hasHeaders?: boolean | null;
          columnNames?: Array<string> | null;
          mapping?: {
            __typename?: 'MappingSpec';
            fields: Array<{
              __typename?: 'MappingFieldSpec';
              name: string;
              columnName?: string | null;
              index?: number | null;
              measureValue?: string | null;
              valueResolver?: {
                __typename?: 'MappingValueResolverSpec';
                values: Array<{
                  __typename?: 'MappingValueResolverSpecItem';
                  title: string;
                  value: string;
                }>;
              } | null;
            }>;
          } | null;
          csvOptions?: {
            __typename?: 'CsvImportOptions';
            separator?: string | null;
            quote?: string | null;
            escape?: string | null;
          } | null;
          transformation?: {
            __typename?: 'ImportJobTransformationSettings';
            enabled: boolean;
            query?: string | null;
          } | null;
        };
        integrationSettings?: {
          __typename?: 'ImportPipelineIntegrationSettings';
          connectors?: Array<{
            __typename?: 'ImportPipelineConnectorSettings';
            id: string;
            type: ImportPipelineConnectorType;
            enabled: boolean;
            sftpPull?: {
              __typename?: 'ImportPipelineSftpPullConnectorSettings';
              host: string;
              port?: number | null;
              username: string;
              password: string;
              path?: string | null;
              pattern?: string | null;
              schedule?: {
                __typename?: 'ImportPipelinePullScheduleSettings';
                cronExpression?: string | null;
              } | null;
              afterPull?: {
                __typename?: 'ImportPipelineAfterPullActionSettings';
                action: ImportPipelineAfterPullAction;
              } | null;
            } | null;
            datasetChanged?: {
              __typename?: 'ImportPipelineDatasetChangedConnectorSettings';
              sources: Array<{
                __typename?: 'ImportPipelineDatasetChangedConnectorSource';
                id: string;
              }>;
            } | null;
          }> | null;
        } | null;
      } | null;
      jobs: { __typename?: 'ImportJobsConnection'; totalCount: number };
      connectorsState: Array<{
        __typename?: 'ImportPipelineConnectorDetails';
        id: string;
        s3Push?: {
          __typename?: 'ImportPipelineS3PushConnectorDetails';
          s3Url?: string | null;
          awsCredentials?: {
            __typename?: 'AwsAccessKey';
            accessKeyId: string;
            secretAccessKey: string;
          } | null;
        } | null;
        apiPush?: {
          __typename?: 'ImportPipelineApiPushConnectorDetails';
          apiUrl?: string | null;
          apiAccessKey: string;
        } | null;
        emailPush?: {
          __typename?: 'ImportPipelineEmailPushConnectorDetails';
          address: string;
        } | null;
        datasetChanged?: {
          __typename?: 'ImportPipelineDatasetChangedConnectorDetails';
          sources: Array<{
            __typename?: 'DatasetObject';
            id: string;
            title: string;
            objectType: ImportJobImportType;
          }>;
        } | null;
      }>;
    } | null;
    sourceSettings: {
      __typename?: 'ImportJobSourceSettings';
      source: ImportSource;
      format: ImportFormat;
      filename?: string | null;
      compression?: ImportJobSourceCompression | null;
      externalSourceSettings?: {
        __typename?: 'ImportJobExternalSourceSettings';
        s3FolderUrl: string;
      } | null;
    };
    settings?: {
      __typename?: 'ImportJobMappingSettings';
      hasHeaders?: boolean | null;
      columnNames?: Array<string> | null;
      mapping?: {
        __typename?: 'MappingSpec';
        fields: Array<{
          __typename?: 'MappingFieldSpec';
          name: string;
          columnName?: string | null;
          index?: number | null;
          measureValue?: string | null;
          valueResolver?: {
            __typename?: 'MappingValueResolverSpec';
            values: Array<{
              __typename?: 'MappingValueResolverSpecItem';
              title: string;
              value: string;
            }>;
          } | null;
        }>;
      } | null;
      csvOptions?: {
        __typename?: 'CsvImportOptions';
        separator?: string | null;
        quote?: string | null;
        escape?: string | null;
      } | null;
      transformation?: {
        __typename?: 'ImportJobTransformationSettings';
        enabled: boolean;
        query?: string | null;
      } | null;
    } | null;
    summary?: {
      __typename?: 'ImportJobSummary';
      inputLineCount?: number | null;
      importedLineCount?: number | null;
      errorCount?: number | null;
    } | null;
  };
};

export type BeginImportJobMultipartUploadMutationVariables = Exact<{
  id: Scalars['String']['input'];
  parts: Scalars['Float']['input'];
}>;

export type BeginImportJobMultipartUploadMutation = {
  __typename?: 'Mutation';
  beginImportJobMultipartUpload: Array<string>;
};

export type CompleteImportJobMultipartUploadMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  etags: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type CompleteImportJobMultipartUploadMutation = {
  __typename?: 'Mutation';
  completeImportJobMultipartUpload: {
    __typename?: 'ImportJob';
    id: string;
    status: BatchJobStatus;
    progress: number;
    createdAt: number;
    updatedAt: number;
    startedAt?: number | null;
    completedAt?: number | null;
    userId: string;
    importType: ImportJobImportType;
    uploaded?: boolean | null;
    stage?: ImportJobProcessingStage | null;
    targetTitle?: string | null;
    locale?: string | null;
    extraSettings?: any | null;
    targetId?: string | null;
    downloadUrl?: string | null;
    sanitizedFailureReason?: string | null;
    importPipelineId?: string | null;
    errors: {
      __typename?: 'ImportJobErrorsConnection';
      totalCount: number;
      content: Array<{
        __typename?: 'MappingError';
        code: string;
        type?: string | null;
        property?: string | null;
        message: string;
        value?: string | null;
        line?: number | null;
        count?: number | null;
        valueResolver?: {
          __typename?: 'MappingValueResolverSpec';
          values: Array<{
            __typename?: 'MappingValueResolverSpecItem';
            value: string;
            title: string;
          }>;
        } | null;
      }>;
    };
    importPipeline?: {
      __typename?: 'ImportPipeline';
      id: string;
      userId: string;
      importType: ImportJobImportType;
      title?: string | null;
      description?: string | null;
      enabled: boolean;
      createdAt: number;
      updatedAt: number;
      extraSettings?: any | null;
      settings?: {
        __typename?: 'ImportPipelineSettings';
        sourceSettings: {
          __typename?: 'ImportJobPipelineSourceSettings';
          format: ImportFormat;
          compression?: ImportJobSourceCompression | null;
        };
        targetSettings: {
          __typename?: 'ImportJobPipelineTargetSettings';
          target: ImportJobTargetType;
          newSettings?: {
            __typename?: 'ImportJobNewTargetSettings';
            targetTitle: string;
          } | null;
          appendSettings?: {
            __typename?: 'ImportJobAppendTargetSettings';
            targetId: string;
            overwrite?: boolean | null;
          } | null;
        };
        mappingSettings: {
          __typename?: 'ImportJobMappingSettings';
          hasHeaders?: boolean | null;
          columnNames?: Array<string> | null;
          mapping?: {
            __typename?: 'MappingSpec';
            fields: Array<{
              __typename?: 'MappingFieldSpec';
              name: string;
              columnName?: string | null;
              index?: number | null;
              measureValue?: string | null;
              valueResolver?: {
                __typename?: 'MappingValueResolverSpec';
                values: Array<{
                  __typename?: 'MappingValueResolverSpecItem';
                  title: string;
                  value: string;
                }>;
              } | null;
            }>;
          } | null;
          csvOptions?: {
            __typename?: 'CsvImportOptions';
            separator?: string | null;
            quote?: string | null;
            escape?: string | null;
          } | null;
          transformation?: {
            __typename?: 'ImportJobTransformationSettings';
            enabled: boolean;
            query?: string | null;
          } | null;
        };
        integrationSettings?: {
          __typename?: 'ImportPipelineIntegrationSettings';
          connectors?: Array<{
            __typename?: 'ImportPipelineConnectorSettings';
            id: string;
            type: ImportPipelineConnectorType;
            enabled: boolean;
            sftpPull?: {
              __typename?: 'ImportPipelineSftpPullConnectorSettings';
              host: string;
              port?: number | null;
              username: string;
              password: string;
              path?: string | null;
              pattern?: string | null;
              schedule?: {
                __typename?: 'ImportPipelinePullScheduleSettings';
                cronExpression?: string | null;
              } | null;
              afterPull?: {
                __typename?: 'ImportPipelineAfterPullActionSettings';
                action: ImportPipelineAfterPullAction;
              } | null;
            } | null;
            datasetChanged?: {
              __typename?: 'ImportPipelineDatasetChangedConnectorSettings';
              sources: Array<{
                __typename?: 'ImportPipelineDatasetChangedConnectorSource';
                id: string;
              }>;
            } | null;
          }> | null;
        } | null;
      } | null;
      jobs: { __typename?: 'ImportJobsConnection'; totalCount: number };
      connectorsState: Array<{
        __typename?: 'ImportPipelineConnectorDetails';
        id: string;
        s3Push?: {
          __typename?: 'ImportPipelineS3PushConnectorDetails';
          s3Url?: string | null;
          awsCredentials?: {
            __typename?: 'AwsAccessKey';
            accessKeyId: string;
            secretAccessKey: string;
          } | null;
        } | null;
        apiPush?: {
          __typename?: 'ImportPipelineApiPushConnectorDetails';
          apiUrl?: string | null;
          apiAccessKey: string;
        } | null;
        emailPush?: {
          __typename?: 'ImportPipelineEmailPushConnectorDetails';
          address: string;
        } | null;
        datasetChanged?: {
          __typename?: 'ImportPipelineDatasetChangedConnectorDetails';
          sources: Array<{
            __typename?: 'DatasetObject';
            id: string;
            title: string;
            objectType: ImportJobImportType;
          }>;
        } | null;
      }>;
    } | null;
    sourceSettings: {
      __typename?: 'ImportJobSourceSettings';
      source: ImportSource;
      format: ImportFormat;
      filename?: string | null;
      compression?: ImportJobSourceCompression | null;
      externalSourceSettings?: {
        __typename?: 'ImportJobExternalSourceSettings';
        s3FolderUrl: string;
      } | null;
    };
    settings?: {
      __typename?: 'ImportJobMappingSettings';
      hasHeaders?: boolean | null;
      columnNames?: Array<string> | null;
      mapping?: {
        __typename?: 'MappingSpec';
        fields: Array<{
          __typename?: 'MappingFieldSpec';
          name: string;
          columnName?: string | null;
          index?: number | null;
          measureValue?: string | null;
          valueResolver?: {
            __typename?: 'MappingValueResolverSpec';
            values: Array<{
              __typename?: 'MappingValueResolverSpecItem';
              title: string;
              value: string;
            }>;
          } | null;
        }>;
      } | null;
      csvOptions?: {
        __typename?: 'CsvImportOptions';
        separator?: string | null;
        quote?: string | null;
        escape?: string | null;
      } | null;
      transformation?: {
        __typename?: 'ImportJobTransformationSettings';
        enabled: boolean;
        query?: string | null;
      } | null;
    } | null;
    summary?: {
      __typename?: 'ImportJobSummary';
      inputLineCount?: number | null;
      importedLineCount?: number | null;
      errorCount?: number | null;
    } | null;
  };
};

export type StartImportJobProcessingMutationVariables = Exact<{
  id: Scalars['String']['input'];
  options?: InputMaybe<StartImportJobProcessingOptions>;
}>;

export type StartImportJobProcessingMutation = {
  __typename?: 'Mutation';
  startImportJobProcessing: {
    __typename?: 'ImportJob';
    id: string;
    status: BatchJobStatus;
    progress: number;
    createdAt: number;
    updatedAt: number;
    startedAt?: number | null;
    completedAt?: number | null;
    userId: string;
    importType: ImportJobImportType;
    uploaded?: boolean | null;
    stage?: ImportJobProcessingStage | null;
    targetTitle?: string | null;
    locale?: string | null;
    extraSettings?: any | null;
    targetId?: string | null;
    downloadUrl?: string | null;
    sanitizedFailureReason?: string | null;
    importPipelineId?: string | null;
    errors: {
      __typename?: 'ImportJobErrorsConnection';
      totalCount: number;
      content: Array<{
        __typename?: 'MappingError';
        code: string;
        type?: string | null;
        property?: string | null;
        message: string;
        value?: string | null;
        line?: number | null;
        count?: number | null;
        valueResolver?: {
          __typename?: 'MappingValueResolverSpec';
          values: Array<{
            __typename?: 'MappingValueResolverSpecItem';
            value: string;
            title: string;
          }>;
        } | null;
      }>;
    };
    importPipeline?: {
      __typename?: 'ImportPipeline';
      id: string;
      userId: string;
      importType: ImportJobImportType;
      title?: string | null;
      description?: string | null;
      enabled: boolean;
      createdAt: number;
      updatedAt: number;
      extraSettings?: any | null;
      settings?: {
        __typename?: 'ImportPipelineSettings';
        sourceSettings: {
          __typename?: 'ImportJobPipelineSourceSettings';
          format: ImportFormat;
          compression?: ImportJobSourceCompression | null;
        };
        targetSettings: {
          __typename?: 'ImportJobPipelineTargetSettings';
          target: ImportJobTargetType;
          newSettings?: {
            __typename?: 'ImportJobNewTargetSettings';
            targetTitle: string;
          } | null;
          appendSettings?: {
            __typename?: 'ImportJobAppendTargetSettings';
            targetId: string;
            overwrite?: boolean | null;
          } | null;
        };
        mappingSettings: {
          __typename?: 'ImportJobMappingSettings';
          hasHeaders?: boolean | null;
          columnNames?: Array<string> | null;
          mapping?: {
            __typename?: 'MappingSpec';
            fields: Array<{
              __typename?: 'MappingFieldSpec';
              name: string;
              columnName?: string | null;
              index?: number | null;
              measureValue?: string | null;
              valueResolver?: {
                __typename?: 'MappingValueResolverSpec';
                values: Array<{
                  __typename?: 'MappingValueResolverSpecItem';
                  title: string;
                  value: string;
                }>;
              } | null;
            }>;
          } | null;
          csvOptions?: {
            __typename?: 'CsvImportOptions';
            separator?: string | null;
            quote?: string | null;
            escape?: string | null;
          } | null;
          transformation?: {
            __typename?: 'ImportJobTransformationSettings';
            enabled: boolean;
            query?: string | null;
          } | null;
        };
        integrationSettings?: {
          __typename?: 'ImportPipelineIntegrationSettings';
          connectors?: Array<{
            __typename?: 'ImportPipelineConnectorSettings';
            id: string;
            type: ImportPipelineConnectorType;
            enabled: boolean;
            sftpPull?: {
              __typename?: 'ImportPipelineSftpPullConnectorSettings';
              host: string;
              port?: number | null;
              username: string;
              password: string;
              path?: string | null;
              pattern?: string | null;
              schedule?: {
                __typename?: 'ImportPipelinePullScheduleSettings';
                cronExpression?: string | null;
              } | null;
              afterPull?: {
                __typename?: 'ImportPipelineAfterPullActionSettings';
                action: ImportPipelineAfterPullAction;
              } | null;
            } | null;
            datasetChanged?: {
              __typename?: 'ImportPipelineDatasetChangedConnectorSettings';
              sources: Array<{
                __typename?: 'ImportPipelineDatasetChangedConnectorSource';
                id: string;
              }>;
            } | null;
          }> | null;
        } | null;
      } | null;
      jobs: { __typename?: 'ImportJobsConnection'; totalCount: number };
      connectorsState: Array<{
        __typename?: 'ImportPipelineConnectorDetails';
        id: string;
        s3Push?: {
          __typename?: 'ImportPipelineS3PushConnectorDetails';
          s3Url?: string | null;
          awsCredentials?: {
            __typename?: 'AwsAccessKey';
            accessKeyId: string;
            secretAccessKey: string;
          } | null;
        } | null;
        apiPush?: {
          __typename?: 'ImportPipelineApiPushConnectorDetails';
          apiUrl?: string | null;
          apiAccessKey: string;
        } | null;
        emailPush?: {
          __typename?: 'ImportPipelineEmailPushConnectorDetails';
          address: string;
        } | null;
        datasetChanged?: {
          __typename?: 'ImportPipelineDatasetChangedConnectorDetails';
          sources: Array<{
            __typename?: 'DatasetObject';
            id: string;
            title: string;
            objectType: ImportJobImportType;
          }>;
        } | null;
      }>;
    } | null;
    sourceSettings: {
      __typename?: 'ImportJobSourceSettings';
      source: ImportSource;
      format: ImportFormat;
      filename?: string | null;
      compression?: ImportJobSourceCompression | null;
      externalSourceSettings?: {
        __typename?: 'ImportJobExternalSourceSettings';
        s3FolderUrl: string;
      } | null;
    };
    settings?: {
      __typename?: 'ImportJobMappingSettings';
      hasHeaders?: boolean | null;
      columnNames?: Array<string> | null;
      mapping?: {
        __typename?: 'MappingSpec';
        fields: Array<{
          __typename?: 'MappingFieldSpec';
          name: string;
          columnName?: string | null;
          index?: number | null;
          measureValue?: string | null;
          valueResolver?: {
            __typename?: 'MappingValueResolverSpec';
            values: Array<{
              __typename?: 'MappingValueResolverSpecItem';
              title: string;
              value: string;
            }>;
          } | null;
        }>;
      } | null;
      csvOptions?: {
        __typename?: 'CsvImportOptions';
        separator?: string | null;
        quote?: string | null;
        escape?: string | null;
      } | null;
      transformation?: {
        __typename?: 'ImportJobTransformationSettings';
        enabled: boolean;
        query?: string | null;
      } | null;
    } | null;
    summary?: {
      __typename?: 'ImportJobSummary';
      inputLineCount?: number | null;
      importedLineCount?: number | null;
      errorCount?: number | null;
    } | null;
  };
};

export type LoadImportJobQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type LoadImportJobQuery = {
  __typename?: 'Query';
  importJob?: {
    __typename?: 'ImportJob';
    id: string;
    status: BatchJobStatus;
    progress: number;
    createdAt: number;
    updatedAt: number;
    startedAt?: number | null;
    completedAt?: number | null;
    userId: string;
    importType: ImportJobImportType;
    uploaded?: boolean | null;
    stage?: ImportJobProcessingStage | null;
    targetTitle?: string | null;
    locale?: string | null;
    extraSettings?: any | null;
    targetId?: string | null;
    downloadUrl?: string | null;
    sanitizedFailureReason?: string | null;
    importPipelineId?: string | null;
    errors: {
      __typename?: 'ImportJobErrorsConnection';
      totalCount: number;
      content: Array<{
        __typename?: 'MappingError';
        code: string;
        type?: string | null;
        property?: string | null;
        message: string;
        value?: string | null;
        line?: number | null;
        count?: number | null;
        valueResolver?: {
          __typename?: 'MappingValueResolverSpec';
          values: Array<{
            __typename?: 'MappingValueResolverSpecItem';
            value: string;
            title: string;
          }>;
        } | null;
      }>;
    };
    importPipeline?: {
      __typename?: 'ImportPipeline';
      id: string;
      userId: string;
      importType: ImportJobImportType;
      title?: string | null;
      description?: string | null;
      enabled: boolean;
      createdAt: number;
      updatedAt: number;
      extraSettings?: any | null;
      settings?: {
        __typename?: 'ImportPipelineSettings';
        sourceSettings: {
          __typename?: 'ImportJobPipelineSourceSettings';
          format: ImportFormat;
          compression?: ImportJobSourceCompression | null;
        };
        targetSettings: {
          __typename?: 'ImportJobPipelineTargetSettings';
          target: ImportJobTargetType;
          newSettings?: {
            __typename?: 'ImportJobNewTargetSettings';
            targetTitle: string;
          } | null;
          appendSettings?: {
            __typename?: 'ImportJobAppendTargetSettings';
            targetId: string;
            overwrite?: boolean | null;
          } | null;
        };
        mappingSettings: {
          __typename?: 'ImportJobMappingSettings';
          hasHeaders?: boolean | null;
          columnNames?: Array<string> | null;
          mapping?: {
            __typename?: 'MappingSpec';
            fields: Array<{
              __typename?: 'MappingFieldSpec';
              name: string;
              columnName?: string | null;
              index?: number | null;
              measureValue?: string | null;
              valueResolver?: {
                __typename?: 'MappingValueResolverSpec';
                values: Array<{
                  __typename?: 'MappingValueResolverSpecItem';
                  title: string;
                  value: string;
                }>;
              } | null;
            }>;
          } | null;
          csvOptions?: {
            __typename?: 'CsvImportOptions';
            separator?: string | null;
            quote?: string | null;
            escape?: string | null;
          } | null;
          transformation?: {
            __typename?: 'ImportJobTransformationSettings';
            enabled: boolean;
            query?: string | null;
          } | null;
        };
        integrationSettings?: {
          __typename?: 'ImportPipelineIntegrationSettings';
          connectors?: Array<{
            __typename?: 'ImportPipelineConnectorSettings';
            id: string;
            type: ImportPipelineConnectorType;
            enabled: boolean;
            sftpPull?: {
              __typename?: 'ImportPipelineSftpPullConnectorSettings';
              host: string;
              port?: number | null;
              username: string;
              password: string;
              path?: string | null;
              pattern?: string | null;
              schedule?: {
                __typename?: 'ImportPipelinePullScheduleSettings';
                cronExpression?: string | null;
              } | null;
              afterPull?: {
                __typename?: 'ImportPipelineAfterPullActionSettings';
                action: ImportPipelineAfterPullAction;
              } | null;
            } | null;
            datasetChanged?: {
              __typename?: 'ImportPipelineDatasetChangedConnectorSettings';
              sources: Array<{
                __typename?: 'ImportPipelineDatasetChangedConnectorSource';
                id: string;
              }>;
            } | null;
          }> | null;
        } | null;
      } | null;
      jobs: { __typename?: 'ImportJobsConnection'; totalCount: number };
      connectorsState: Array<{
        __typename?: 'ImportPipelineConnectorDetails';
        id: string;
        s3Push?: {
          __typename?: 'ImportPipelineS3PushConnectorDetails';
          s3Url?: string | null;
          awsCredentials?: {
            __typename?: 'AwsAccessKey';
            accessKeyId: string;
            secretAccessKey: string;
          } | null;
        } | null;
        apiPush?: {
          __typename?: 'ImportPipelineApiPushConnectorDetails';
          apiUrl?: string | null;
          apiAccessKey: string;
        } | null;
        emailPush?: {
          __typename?: 'ImportPipelineEmailPushConnectorDetails';
          address: string;
        } | null;
        datasetChanged?: {
          __typename?: 'ImportPipelineDatasetChangedConnectorDetails';
          sources: Array<{
            __typename?: 'DatasetObject';
            id: string;
            title: string;
            objectType: ImportJobImportType;
          }>;
        } | null;
      }>;
    } | null;
    sourceSettings: {
      __typename?: 'ImportJobSourceSettings';
      source: ImportSource;
      format: ImportFormat;
      filename?: string | null;
      compression?: ImportJobSourceCompression | null;
      externalSourceSettings?: {
        __typename?: 'ImportJobExternalSourceSettings';
        s3FolderUrl: string;
      } | null;
    };
    settings?: {
      __typename?: 'ImportJobMappingSettings';
      hasHeaders?: boolean | null;
      columnNames?: Array<string> | null;
      mapping?: {
        __typename?: 'MappingSpec';
        fields: Array<{
          __typename?: 'MappingFieldSpec';
          name: string;
          columnName?: string | null;
          index?: number | null;
          measureValue?: string | null;
          valueResolver?: {
            __typename?: 'MappingValueResolverSpec';
            values: Array<{
              __typename?: 'MappingValueResolverSpecItem';
              title: string;
              value: string;
            }>;
          } | null;
        }>;
      } | null;
      csvOptions?: {
        __typename?: 'CsvImportOptions';
        separator?: string | null;
        quote?: string | null;
        escape?: string | null;
      } | null;
      transformation?: {
        __typename?: 'ImportJobTransformationSettings';
        enabled: boolean;
        query?: string | null;
      } | null;
    } | null;
    summary?: {
      __typename?: 'ImportJobSummary';
      inputLineCount?: number | null;
      importedLineCount?: number | null;
      errorCount?: number | null;
    } | null;
  } | null;
};

export type LoadImportPreviewUrlQueryVariables = Exact<{
  jobId: Scalars['String']['input'];
  contentLength: Scalars['Float']['input'];
}>;

export type LoadImportPreviewUrlQuery = {
  __typename?: 'Query';
  importJob?: { __typename?: 'ImportJob'; previewUploadUrl: string } | null;
};

export type GetImportPreviewColumnsMutationVariables = Exact<{
  jobId: Scalars['String']['input'];
  options?: InputMaybe<PrepareImportJobPreviewTableOptions>;
}>;

export type GetImportPreviewColumnsMutation = {
  __typename?: 'Mutation';
  prepareImportJobPreviewTable: Array<string>;
};

export type GetImportTransformedPreviewQueryVariables = Exact<{
  jobId: Scalars['String']['input'];
  query: Scalars['String']['input'];
}>;

export type GetImportTransformedPreviewQuery = {
  __typename?: 'Query';
  importJob?: {
    __typename?: 'ImportJob';
    previewQuery: {
      __typename?: 'PreviewQueryResponse';
      result: any;
      columns: Array<{
        __typename?: 'PreviewQueryColumn';
        name: string;
        type: string;
      }>;
    };
  } | null;
};

export type ValidatePreviewMutationVariables = Exact<{
  jobId: Scalars['String']['input'];
  mapping: MappingSpecInput;
  transformation?: InputMaybe<ImportJobTransformationSettingsInput>;
  page?: InputMaybe<SkipLimitPageSpec>;
}>;

export type ValidatePreviewMutation = {
  __typename?: 'Mutation';
  validateImportJobPreviewMapping: {
    __typename?: 'ImportJobErrorsConnection';
    totalCount: number;
    content: Array<{
      __typename?: 'MappingError';
      code: string;
      type?: string | null;
      property?: string | null;
      message: string;
      value?: string | null;
      line?: number | null;
      count?: number | null;
      valueResolver?: {
        __typename?: 'MappingValueResolverSpec';
        values: Array<{
          __typename?: 'MappingValueResolverSpecItem';
          value: string;
          title: string;
        }>;
      } | null;
    }>;
  };
};

export type WarehouseMetaFragment = {
  __typename?: 'Warehouse';
  id: string;
  title: string;
  description?: string | null;
  createdAt: number;
  updatedAt: number;
  companyName?: string | null;
  address?: string | null;
  measurementSystem: MeasurementSystem;
  country: string;
  currency: string;
  type: WarehouseType;
  extraSettings?: any | null;
  permissions: {
    __typename?: 'WarehouseAclPermissions';
    allowed: Array<Action>;
  };
};

export type GetWarehousesQueryVariables = Exact<{ [key: string]: never }>;

export type GetWarehousesQuery = {
  __typename?: 'Query';
  warehouses: {
    __typename?: 'WarehousesConnection';
    content: Array<{
      __typename?: 'Warehouse';
      id: string;
      title: string;
      description?: string | null;
      createdAt: number;
      updatedAt: number;
      companyName?: string | null;
      address?: string | null;
      measurementSystem: MeasurementSystem;
      country: string;
      currency: string;
      type: WarehouseType;
      extraSettings?: any | null;
      permissions: {
        __typename?: 'WarehouseAclPermissions';
        allowed: Array<Action>;
      };
    }>;
  };
};

export type GetWarehousesPermissionsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetWarehousesPermissionsQuery = {
  __typename?: 'Query';
  warehouses: {
    __typename?: 'WarehousesConnection';
    content: Array<{
      __typename?: 'Warehouse';
      id: string;
      permissions: {
        __typename?: 'WarehouseAclPermissions';
        allowed: Array<Action>;
        token?: string | null;
      };
    }>;
  };
};

export type SendUserInvitationMutationVariables = Exact<{
  input: SendUserInvitationLinkInput;
}>;

export type SendUserInvitationMutation = {
  __typename?: 'Mutation';
  sendUserInvitationLink: boolean;
};

export type ImportJobsConnectionFragment = {
  __typename?: 'ImportJobsConnection';
  totalCount: number;
  content: Array<{
    __typename?: 'ImportJob';
    id: string;
    status: BatchJobStatus;
    progress: number;
    createdAt: number;
    updatedAt: number;
    startedAt?: number | null;
    completedAt?: number | null;
    userId: string;
    importType: ImportJobImportType;
    uploaded?: boolean | null;
    stage?: ImportJobProcessingStage | null;
    targetTitle?: string | null;
    locale?: string | null;
    extraSettings?: any | null;
    targetId?: string | null;
    downloadUrl?: string | null;
    sanitizedFailureReason?: string | null;
    importPipelineId?: string | null;
    importPipeline?: {
      __typename?: 'ImportPipeline';
      id: string;
      userId: string;
      importType: ImportJobImportType;
      title?: string | null;
      description?: string | null;
      enabled: boolean;
      createdAt: number;
      updatedAt: number;
      extraSettings?: any | null;
      settings?: {
        __typename?: 'ImportPipelineSettings';
        sourceSettings: {
          __typename?: 'ImportJobPipelineSourceSettings';
          format: ImportFormat;
          compression?: ImportJobSourceCompression | null;
        };
        targetSettings: {
          __typename?: 'ImportJobPipelineTargetSettings';
          target: ImportJobTargetType;
          newSettings?: {
            __typename?: 'ImportJobNewTargetSettings';
            targetTitle: string;
          } | null;
          appendSettings?: {
            __typename?: 'ImportJobAppendTargetSettings';
            targetId: string;
            overwrite?: boolean | null;
          } | null;
        };
        mappingSettings: {
          __typename?: 'ImportJobMappingSettings';
          hasHeaders?: boolean | null;
          columnNames?: Array<string> | null;
          mapping?: {
            __typename?: 'MappingSpec';
            fields: Array<{
              __typename?: 'MappingFieldSpec';
              name: string;
              columnName?: string | null;
              index?: number | null;
              measureValue?: string | null;
              valueResolver?: {
                __typename?: 'MappingValueResolverSpec';
                values: Array<{
                  __typename?: 'MappingValueResolverSpecItem';
                  title: string;
                  value: string;
                }>;
              } | null;
            }>;
          } | null;
          csvOptions?: {
            __typename?: 'CsvImportOptions';
            separator?: string | null;
            quote?: string | null;
            escape?: string | null;
          } | null;
          transformation?: {
            __typename?: 'ImportJobTransformationSettings';
            enabled: boolean;
            query?: string | null;
          } | null;
        };
        integrationSettings?: {
          __typename?: 'ImportPipelineIntegrationSettings';
          connectors?: Array<{
            __typename?: 'ImportPipelineConnectorSettings';
            id: string;
            type: ImportPipelineConnectorType;
            enabled: boolean;
            sftpPull?: {
              __typename?: 'ImportPipelineSftpPullConnectorSettings';
              host: string;
              port?: number | null;
              username: string;
              password: string;
              path?: string | null;
              pattern?: string | null;
              schedule?: {
                __typename?: 'ImportPipelinePullScheduleSettings';
                cronExpression?: string | null;
              } | null;
              afterPull?: {
                __typename?: 'ImportPipelineAfterPullActionSettings';
                action: ImportPipelineAfterPullAction;
              } | null;
            } | null;
            datasetChanged?: {
              __typename?: 'ImportPipelineDatasetChangedConnectorSettings';
              sources: Array<{
                __typename?: 'ImportPipelineDatasetChangedConnectorSource';
                id: string;
              }>;
            } | null;
          }> | null;
        } | null;
      } | null;
      jobs: { __typename?: 'ImportJobsConnection'; totalCount: number };
      connectorsState: Array<{
        __typename?: 'ImportPipelineConnectorDetails';
        id: string;
        s3Push?: {
          __typename?: 'ImportPipelineS3PushConnectorDetails';
          s3Url?: string | null;
          awsCredentials?: {
            __typename?: 'AwsAccessKey';
            accessKeyId: string;
            secretAccessKey: string;
          } | null;
        } | null;
        apiPush?: {
          __typename?: 'ImportPipelineApiPushConnectorDetails';
          apiUrl?: string | null;
          apiAccessKey: string;
        } | null;
        emailPush?: {
          __typename?: 'ImportPipelineEmailPushConnectorDetails';
          address: string;
        } | null;
        datasetChanged?: {
          __typename?: 'ImportPipelineDatasetChangedConnectorDetails';
          sources: Array<{
            __typename?: 'DatasetObject';
            id: string;
            title: string;
            objectType: ImportJobImportType;
          }>;
        } | null;
      }>;
    } | null;
    sourceSettings: {
      __typename?: 'ImportJobSourceSettings';
      source: ImportSource;
      format: ImportFormat;
      filename?: string | null;
      compression?: ImportJobSourceCompression | null;
      externalSourceSettings?: {
        __typename?: 'ImportJobExternalSourceSettings';
        s3FolderUrl: string;
      } | null;
    };
    settings?: {
      __typename?: 'ImportJobMappingSettings';
      hasHeaders?: boolean | null;
      columnNames?: Array<string> | null;
      mapping?: {
        __typename?: 'MappingSpec';
        fields: Array<{
          __typename?: 'MappingFieldSpec';
          name: string;
          columnName?: string | null;
          index?: number | null;
          measureValue?: string | null;
          valueResolver?: {
            __typename?: 'MappingValueResolverSpec';
            values: Array<{
              __typename?: 'MappingValueResolverSpecItem';
              title: string;
              value: string;
            }>;
          } | null;
        }>;
      } | null;
      csvOptions?: {
        __typename?: 'CsvImportOptions';
        separator?: string | null;
        quote?: string | null;
        escape?: string | null;
      } | null;
      transformation?: {
        __typename?: 'ImportJobTransformationSettings';
        enabled: boolean;
        query?: string | null;
      } | null;
    } | null;
    summary?: {
      __typename?: 'ImportJobSummary';
      inputLineCount?: number | null;
      importedLineCount?: number | null;
      errorCount?: number | null;
    } | null;
  }>;
};

export type LoadImportJobsQueryVariables = Exact<{
  filter?: InputMaybe<ImportJobsFilter>;
  sortBy?: InputMaybe<Array<ImportJobsSortBy> | ImportJobsSortBy>;
  page?: InputMaybe<SkipLimitPageSpec>;
}>;

export type LoadImportJobsQuery = {
  __typename?: 'Query';
  importJobs: {
    __typename?: 'ImportJobsConnection';
    totalCount: number;
    content: Array<{
      __typename?: 'ImportJob';
      id: string;
      status: BatchJobStatus;
      progress: number;
      createdAt: number;
      updatedAt: number;
      startedAt?: number | null;
      completedAt?: number | null;
      userId: string;
      importType: ImportJobImportType;
      uploaded?: boolean | null;
      stage?: ImportJobProcessingStage | null;
      targetTitle?: string | null;
      locale?: string | null;
      extraSettings?: any | null;
      targetId?: string | null;
      downloadUrl?: string | null;
      sanitizedFailureReason?: string | null;
      importPipelineId?: string | null;
      importPipeline?: {
        __typename?: 'ImportPipeline';
        id: string;
        userId: string;
        importType: ImportJobImportType;
        title?: string | null;
        description?: string | null;
        enabled: boolean;
        createdAt: number;
        updatedAt: number;
        extraSettings?: any | null;
        settings?: {
          __typename?: 'ImportPipelineSettings';
          sourceSettings: {
            __typename?: 'ImportJobPipelineSourceSettings';
            format: ImportFormat;
            compression?: ImportJobSourceCompression | null;
          };
          targetSettings: {
            __typename?: 'ImportJobPipelineTargetSettings';
            target: ImportJobTargetType;
            newSettings?: {
              __typename?: 'ImportJobNewTargetSettings';
              targetTitle: string;
            } | null;
            appendSettings?: {
              __typename?: 'ImportJobAppendTargetSettings';
              targetId: string;
              overwrite?: boolean | null;
            } | null;
          };
          mappingSettings: {
            __typename?: 'ImportJobMappingSettings';
            hasHeaders?: boolean | null;
            columnNames?: Array<string> | null;
            mapping?: {
              __typename?: 'MappingSpec';
              fields: Array<{
                __typename?: 'MappingFieldSpec';
                name: string;
                columnName?: string | null;
                index?: number | null;
                measureValue?: string | null;
                valueResolver?: {
                  __typename?: 'MappingValueResolverSpec';
                  values: Array<{
                    __typename?: 'MappingValueResolverSpecItem';
                    title: string;
                    value: string;
                  }>;
                } | null;
              }>;
            } | null;
            csvOptions?: {
              __typename?: 'CsvImportOptions';
              separator?: string | null;
              quote?: string | null;
              escape?: string | null;
            } | null;
            transformation?: {
              __typename?: 'ImportJobTransformationSettings';
              enabled: boolean;
              query?: string | null;
            } | null;
          };
          integrationSettings?: {
            __typename?: 'ImportPipelineIntegrationSettings';
            connectors?: Array<{
              __typename?: 'ImportPipelineConnectorSettings';
              id: string;
              type: ImportPipelineConnectorType;
              enabled: boolean;
              sftpPull?: {
                __typename?: 'ImportPipelineSftpPullConnectorSettings';
                host: string;
                port?: number | null;
                username: string;
                password: string;
                path?: string | null;
                pattern?: string | null;
                schedule?: {
                  __typename?: 'ImportPipelinePullScheduleSettings';
                  cronExpression?: string | null;
                } | null;
                afterPull?: {
                  __typename?: 'ImportPipelineAfterPullActionSettings';
                  action: ImportPipelineAfterPullAction;
                } | null;
              } | null;
              datasetChanged?: {
                __typename?: 'ImportPipelineDatasetChangedConnectorSettings';
                sources: Array<{
                  __typename?: 'ImportPipelineDatasetChangedConnectorSource';
                  id: string;
                }>;
              } | null;
            }> | null;
          } | null;
        } | null;
        jobs: { __typename?: 'ImportJobsConnection'; totalCount: number };
        connectorsState: Array<{
          __typename?: 'ImportPipelineConnectorDetails';
          id: string;
          s3Push?: {
            __typename?: 'ImportPipelineS3PushConnectorDetails';
            s3Url?: string | null;
            awsCredentials?: {
              __typename?: 'AwsAccessKey';
              accessKeyId: string;
              secretAccessKey: string;
            } | null;
          } | null;
          apiPush?: {
            __typename?: 'ImportPipelineApiPushConnectorDetails';
            apiUrl?: string | null;
            apiAccessKey: string;
          } | null;
          emailPush?: {
            __typename?: 'ImportPipelineEmailPushConnectorDetails';
            address: string;
          } | null;
          datasetChanged?: {
            __typename?: 'ImportPipelineDatasetChangedConnectorDetails';
            sources: Array<{
              __typename?: 'DatasetObject';
              id: string;
              title: string;
              objectType: ImportJobImportType;
            }>;
          } | null;
        }>;
      } | null;
      sourceSettings: {
        __typename?: 'ImportJobSourceSettings';
        source: ImportSource;
        format: ImportFormat;
        filename?: string | null;
        compression?: ImportJobSourceCompression | null;
        externalSourceSettings?: {
          __typename?: 'ImportJobExternalSourceSettings';
          s3FolderUrl: string;
        } | null;
      };
      settings?: {
        __typename?: 'ImportJobMappingSettings';
        hasHeaders?: boolean | null;
        columnNames?: Array<string> | null;
        mapping?: {
          __typename?: 'MappingSpec';
          fields: Array<{
            __typename?: 'MappingFieldSpec';
            name: string;
            columnName?: string | null;
            index?: number | null;
            measureValue?: string | null;
            valueResolver?: {
              __typename?: 'MappingValueResolverSpec';
              values: Array<{
                __typename?: 'MappingValueResolverSpecItem';
                title: string;
                value: string;
              }>;
            } | null;
          }>;
        } | null;
        csvOptions?: {
          __typename?: 'CsvImportOptions';
          separator?: string | null;
          quote?: string | null;
          escape?: string | null;
        } | null;
        transformation?: {
          __typename?: 'ImportJobTransformationSettings';
          enabled: boolean;
          query?: string | null;
        } | null;
      } | null;
      summary?: {
        __typename?: 'ImportJobSummary';
        inputLineCount?: number | null;
        importedLineCount?: number | null;
        errorCount?: number | null;
      } | null;
    }>;
  };
};

export type RemoveImportJobMutationVariables = Exact<{
  id: Scalars['String']['input'];
  options?: InputMaybe<RemoveImportJobOptions>;
}>;

export type RemoveImportJobMutation = {
  __typename?: 'Mutation';
  removeImportJobs: number;
};

export type RestartImportJobsMutationVariables = Exact<{
  input: BatchStartImportJobProcessingRequest;
}>;

export type RestartImportJobsMutation = {
  __typename?: 'Mutation';
  batchStartImportJobProcessing: Array<{
    __typename?: 'ImportJob';
    id: string;
    status: BatchJobStatus;
    progress: number;
    createdAt: number;
    updatedAt: number;
    startedAt?: number | null;
    completedAt?: number | null;
    userId: string;
    importType: ImportJobImportType;
    uploaded?: boolean | null;
    stage?: ImportJobProcessingStage | null;
    targetTitle?: string | null;
    locale?: string | null;
    extraSettings?: any | null;
    targetId?: string | null;
    downloadUrl?: string | null;
    sanitizedFailureReason?: string | null;
    importPipelineId?: string | null;
    errors: {
      __typename?: 'ImportJobErrorsConnection';
      totalCount: number;
      content: Array<{
        __typename?: 'MappingError';
        code: string;
        type?: string | null;
        property?: string | null;
        message: string;
        value?: string | null;
        line?: number | null;
        count?: number | null;
        valueResolver?: {
          __typename?: 'MappingValueResolverSpec';
          values: Array<{
            __typename?: 'MappingValueResolverSpecItem';
            value: string;
            title: string;
          }>;
        } | null;
      }>;
    };
    importPipeline?: {
      __typename?: 'ImportPipeline';
      id: string;
      userId: string;
      importType: ImportJobImportType;
      title?: string | null;
      description?: string | null;
      enabled: boolean;
      createdAt: number;
      updatedAt: number;
      extraSettings?: any | null;
      settings?: {
        __typename?: 'ImportPipelineSettings';
        sourceSettings: {
          __typename?: 'ImportJobPipelineSourceSettings';
          format: ImportFormat;
          compression?: ImportJobSourceCompression | null;
        };
        targetSettings: {
          __typename?: 'ImportJobPipelineTargetSettings';
          target: ImportJobTargetType;
          newSettings?: {
            __typename?: 'ImportJobNewTargetSettings';
            targetTitle: string;
          } | null;
          appendSettings?: {
            __typename?: 'ImportJobAppendTargetSettings';
            targetId: string;
            overwrite?: boolean | null;
          } | null;
        };
        mappingSettings: {
          __typename?: 'ImportJobMappingSettings';
          hasHeaders?: boolean | null;
          columnNames?: Array<string> | null;
          mapping?: {
            __typename?: 'MappingSpec';
            fields: Array<{
              __typename?: 'MappingFieldSpec';
              name: string;
              columnName?: string | null;
              index?: number | null;
              measureValue?: string | null;
              valueResolver?: {
                __typename?: 'MappingValueResolverSpec';
                values: Array<{
                  __typename?: 'MappingValueResolverSpecItem';
                  title: string;
                  value: string;
                }>;
              } | null;
            }>;
          } | null;
          csvOptions?: {
            __typename?: 'CsvImportOptions';
            separator?: string | null;
            quote?: string | null;
            escape?: string | null;
          } | null;
          transformation?: {
            __typename?: 'ImportJobTransformationSettings';
            enabled: boolean;
            query?: string | null;
          } | null;
        };
        integrationSettings?: {
          __typename?: 'ImportPipelineIntegrationSettings';
          connectors?: Array<{
            __typename?: 'ImportPipelineConnectorSettings';
            id: string;
            type: ImportPipelineConnectorType;
            enabled: boolean;
            sftpPull?: {
              __typename?: 'ImportPipelineSftpPullConnectorSettings';
              host: string;
              port?: number | null;
              username: string;
              password: string;
              path?: string | null;
              pattern?: string | null;
              schedule?: {
                __typename?: 'ImportPipelinePullScheduleSettings';
                cronExpression?: string | null;
              } | null;
              afterPull?: {
                __typename?: 'ImportPipelineAfterPullActionSettings';
                action: ImportPipelineAfterPullAction;
              } | null;
            } | null;
            datasetChanged?: {
              __typename?: 'ImportPipelineDatasetChangedConnectorSettings';
              sources: Array<{
                __typename?: 'ImportPipelineDatasetChangedConnectorSource';
                id: string;
              }>;
            } | null;
          }> | null;
        } | null;
      } | null;
      jobs: { __typename?: 'ImportJobsConnection'; totalCount: number };
      connectorsState: Array<{
        __typename?: 'ImportPipelineConnectorDetails';
        id: string;
        s3Push?: {
          __typename?: 'ImportPipelineS3PushConnectorDetails';
          s3Url?: string | null;
          awsCredentials?: {
            __typename?: 'AwsAccessKey';
            accessKeyId: string;
            secretAccessKey: string;
          } | null;
        } | null;
        apiPush?: {
          __typename?: 'ImportPipelineApiPushConnectorDetails';
          apiUrl?: string | null;
          apiAccessKey: string;
        } | null;
        emailPush?: {
          __typename?: 'ImportPipelineEmailPushConnectorDetails';
          address: string;
        } | null;
        datasetChanged?: {
          __typename?: 'ImportPipelineDatasetChangedConnectorDetails';
          sources: Array<{
            __typename?: 'DatasetObject';
            id: string;
            title: string;
            objectType: ImportJobImportType;
          }>;
        } | null;
      }>;
    } | null;
    sourceSettings: {
      __typename?: 'ImportJobSourceSettings';
      source: ImportSource;
      format: ImportFormat;
      filename?: string | null;
      compression?: ImportJobSourceCompression | null;
      externalSourceSettings?: {
        __typename?: 'ImportJobExternalSourceSettings';
        s3FolderUrl: string;
      } | null;
    };
    settings?: {
      __typename?: 'ImportJobMappingSettings';
      hasHeaders?: boolean | null;
      columnNames?: Array<string> | null;
      mapping?: {
        __typename?: 'MappingSpec';
        fields: Array<{
          __typename?: 'MappingFieldSpec';
          name: string;
          columnName?: string | null;
          index?: number | null;
          measureValue?: string | null;
          valueResolver?: {
            __typename?: 'MappingValueResolverSpec';
            values: Array<{
              __typename?: 'MappingValueResolverSpecItem';
              title: string;
              value: string;
            }>;
          } | null;
        }>;
      } | null;
      csvOptions?: {
        __typename?: 'CsvImportOptions';
        separator?: string | null;
        quote?: string | null;
        escape?: string | null;
      } | null;
      transformation?: {
        __typename?: 'ImportJobTransformationSettings';
        enabled: boolean;
        query?: string | null;
      } | null;
    } | null;
    summary?: {
      __typename?: 'ImportJobSummary';
      inputLineCount?: number | null;
      importedLineCount?: number | null;
      errorCount?: number | null;
    } | null;
  }>;
};

export type CreateImportPipelineJobMutationVariables = Exact<{
  input: CreateImportPipelineJobInput;
}>;

export type CreateImportPipelineJobMutation = {
  __typename?: 'Mutation';
  createImportPipelineJob: {
    __typename?: 'ImportJob';
    id: string;
    status: BatchJobStatus;
    progress: number;
    createdAt: number;
    updatedAt: number;
    startedAt?: number | null;
    completedAt?: number | null;
    userId: string;
    importType: ImportJobImportType;
    uploaded?: boolean | null;
    stage?: ImportJobProcessingStage | null;
    targetTitle?: string | null;
    locale?: string | null;
    extraSettings?: any | null;
    targetId?: string | null;
    downloadUrl?: string | null;
    sanitizedFailureReason?: string | null;
    importPipelineId?: string | null;
    errors: {
      __typename?: 'ImportJobErrorsConnection';
      totalCount: number;
      content: Array<{
        __typename?: 'MappingError';
        code: string;
        type?: string | null;
        property?: string | null;
        message: string;
        value?: string | null;
        line?: number | null;
        count?: number | null;
        valueResolver?: {
          __typename?: 'MappingValueResolverSpec';
          values: Array<{
            __typename?: 'MappingValueResolverSpecItem';
            value: string;
            title: string;
          }>;
        } | null;
      }>;
    };
    importPipeline?: {
      __typename?: 'ImportPipeline';
      id: string;
      userId: string;
      importType: ImportJobImportType;
      title?: string | null;
      description?: string | null;
      enabled: boolean;
      createdAt: number;
      updatedAt: number;
      extraSettings?: any | null;
      settings?: {
        __typename?: 'ImportPipelineSettings';
        sourceSettings: {
          __typename?: 'ImportJobPipelineSourceSettings';
          format: ImportFormat;
          compression?: ImportJobSourceCompression | null;
        };
        targetSettings: {
          __typename?: 'ImportJobPipelineTargetSettings';
          target: ImportJobTargetType;
          newSettings?: {
            __typename?: 'ImportJobNewTargetSettings';
            targetTitle: string;
          } | null;
          appendSettings?: {
            __typename?: 'ImportJobAppendTargetSettings';
            targetId: string;
            overwrite?: boolean | null;
          } | null;
        };
        mappingSettings: {
          __typename?: 'ImportJobMappingSettings';
          hasHeaders?: boolean | null;
          columnNames?: Array<string> | null;
          mapping?: {
            __typename?: 'MappingSpec';
            fields: Array<{
              __typename?: 'MappingFieldSpec';
              name: string;
              columnName?: string | null;
              index?: number | null;
              measureValue?: string | null;
              valueResolver?: {
                __typename?: 'MappingValueResolverSpec';
                values: Array<{
                  __typename?: 'MappingValueResolverSpecItem';
                  title: string;
                  value: string;
                }>;
              } | null;
            }>;
          } | null;
          csvOptions?: {
            __typename?: 'CsvImportOptions';
            separator?: string | null;
            quote?: string | null;
            escape?: string | null;
          } | null;
          transformation?: {
            __typename?: 'ImportJobTransformationSettings';
            enabled: boolean;
            query?: string | null;
          } | null;
        };
        integrationSettings?: {
          __typename?: 'ImportPipelineIntegrationSettings';
          connectors?: Array<{
            __typename?: 'ImportPipelineConnectorSettings';
            id: string;
            type: ImportPipelineConnectorType;
            enabled: boolean;
            sftpPull?: {
              __typename?: 'ImportPipelineSftpPullConnectorSettings';
              host: string;
              port?: number | null;
              username: string;
              password: string;
              path?: string | null;
              pattern?: string | null;
              schedule?: {
                __typename?: 'ImportPipelinePullScheduleSettings';
                cronExpression?: string | null;
              } | null;
              afterPull?: {
                __typename?: 'ImportPipelineAfterPullActionSettings';
                action: ImportPipelineAfterPullAction;
              } | null;
            } | null;
            datasetChanged?: {
              __typename?: 'ImportPipelineDatasetChangedConnectorSettings';
              sources: Array<{
                __typename?: 'ImportPipelineDatasetChangedConnectorSource';
                id: string;
              }>;
            } | null;
          }> | null;
        } | null;
      } | null;
      jobs: { __typename?: 'ImportJobsConnection'; totalCount: number };
      connectorsState: Array<{
        __typename?: 'ImportPipelineConnectorDetails';
        id: string;
        s3Push?: {
          __typename?: 'ImportPipelineS3PushConnectorDetails';
          s3Url?: string | null;
          awsCredentials?: {
            __typename?: 'AwsAccessKey';
            accessKeyId: string;
            secretAccessKey: string;
          } | null;
        } | null;
        apiPush?: {
          __typename?: 'ImportPipelineApiPushConnectorDetails';
          apiUrl?: string | null;
          apiAccessKey: string;
        } | null;
        emailPush?: {
          __typename?: 'ImportPipelineEmailPushConnectorDetails';
          address: string;
        } | null;
        datasetChanged?: {
          __typename?: 'ImportPipelineDatasetChangedConnectorDetails';
          sources: Array<{
            __typename?: 'DatasetObject';
            id: string;
            title: string;
            objectType: ImportJobImportType;
          }>;
        } | null;
      }>;
    } | null;
    sourceSettings: {
      __typename?: 'ImportJobSourceSettings';
      source: ImportSource;
      format: ImportFormat;
      filename?: string | null;
      compression?: ImportJobSourceCompression | null;
      externalSourceSettings?: {
        __typename?: 'ImportJobExternalSourceSettings';
        s3FolderUrl: string;
      } | null;
    };
    settings?: {
      __typename?: 'ImportJobMappingSettings';
      hasHeaders?: boolean | null;
      columnNames?: Array<string> | null;
      mapping?: {
        __typename?: 'MappingSpec';
        fields: Array<{
          __typename?: 'MappingFieldSpec';
          name: string;
          columnName?: string | null;
          index?: number | null;
          measureValue?: string | null;
          valueResolver?: {
            __typename?: 'MappingValueResolverSpec';
            values: Array<{
              __typename?: 'MappingValueResolverSpecItem';
              title: string;
              value: string;
            }>;
          } | null;
        }>;
      } | null;
      csvOptions?: {
        __typename?: 'CsvImportOptions';
        separator?: string | null;
        quote?: string | null;
        escape?: string | null;
      } | null;
      transformation?: {
        __typename?: 'ImportJobTransformationSettings';
        enabled: boolean;
        query?: string | null;
      } | null;
    } | null;
    summary?: {
      __typename?: 'ImportJobSummary';
      inputLineCount?: number | null;
      importedLineCount?: number | null;
      errorCount?: number | null;
    } | null;
  };
};

export type ImportJobPipelineSourceSettingsFragment = {
  __typename?: 'ImportJobPipelineSourceSettings';
  format: ImportFormat;
  compression?: ImportJobSourceCompression | null;
};

export type ImportJobPipelineTargetSettingsFragment = {
  __typename?: 'ImportJobPipelineTargetSettings';
  target: ImportJobTargetType;
  newSettings?: {
    __typename?: 'ImportJobNewTargetSettings';
    targetTitle: string;
  } | null;
  appendSettings?: {
    __typename?: 'ImportJobAppendTargetSettings';
    targetId: string;
    overwrite?: boolean | null;
  } | null;
};

export type ImportPipelineSftpPullConnectorSettingsFragment = {
  __typename?: 'ImportPipelineSftpPullConnectorSettings';
  host: string;
  port?: number | null;
  username: string;
  password: string;
  path?: string | null;
  pattern?: string | null;
  schedule?: {
    __typename?: 'ImportPipelinePullScheduleSettings';
    cronExpression?: string | null;
  } | null;
  afterPull?: {
    __typename?: 'ImportPipelineAfterPullActionSettings';
    action: ImportPipelineAfterPullAction;
  } | null;
};

export type ImportPipelineDatasetChangedConnectorDetailsFragment = {
  __typename?: 'ImportPipelineDatasetChangedConnectorDetails';
  sources: Array<{
    __typename?: 'DatasetObject';
    id: string;
    title: string;
    objectType: ImportJobImportType;
  }>;
};

export type ImportPipelineDatasetChangedConnectorSettingsFragment = {
  __typename?: 'ImportPipelineDatasetChangedConnectorSettings';
  sources: Array<{
    __typename?: 'ImportPipelineDatasetChangedConnectorSource';
    id: string;
  }>;
};

export type ImportPipelineConnectorSettingsFragment = {
  __typename?: 'ImportPipelineConnectorSettings';
  id: string;
  type: ImportPipelineConnectorType;
  enabled: boolean;
  sftpPull?: {
    __typename?: 'ImportPipelineSftpPullConnectorSettings';
    host: string;
    port?: number | null;
    username: string;
    password: string;
    path?: string | null;
    pattern?: string | null;
    schedule?: {
      __typename?: 'ImportPipelinePullScheduleSettings';
      cronExpression?: string | null;
    } | null;
    afterPull?: {
      __typename?: 'ImportPipelineAfterPullActionSettings';
      action: ImportPipelineAfterPullAction;
    } | null;
  } | null;
  datasetChanged?: {
    __typename?: 'ImportPipelineDatasetChangedConnectorSettings';
    sources: Array<{
      __typename?: 'ImportPipelineDatasetChangedConnectorSource';
      id: string;
    }>;
  } | null;
};

export type ImportPipelineSettingsFragment = {
  __typename?: 'ImportPipelineSettings';
  sourceSettings: {
    __typename?: 'ImportJobPipelineSourceSettings';
    format: ImportFormat;
    compression?: ImportJobSourceCompression | null;
  };
  targetSettings: {
    __typename?: 'ImportJobPipelineTargetSettings';
    target: ImportJobTargetType;
    newSettings?: {
      __typename?: 'ImportJobNewTargetSettings';
      targetTitle: string;
    } | null;
    appendSettings?: {
      __typename?: 'ImportJobAppendTargetSettings';
      targetId: string;
      overwrite?: boolean | null;
    } | null;
  };
  mappingSettings: {
    __typename?: 'ImportJobMappingSettings';
    hasHeaders?: boolean | null;
    columnNames?: Array<string> | null;
    mapping?: {
      __typename?: 'MappingSpec';
      fields: Array<{
        __typename?: 'MappingFieldSpec';
        name: string;
        columnName?: string | null;
        index?: number | null;
        measureValue?: string | null;
        valueResolver?: {
          __typename?: 'MappingValueResolverSpec';
          values: Array<{
            __typename?: 'MappingValueResolverSpecItem';
            title: string;
            value: string;
          }>;
        } | null;
      }>;
    } | null;
    csvOptions?: {
      __typename?: 'CsvImportOptions';
      separator?: string | null;
      quote?: string | null;
      escape?: string | null;
    } | null;
    transformation?: {
      __typename?: 'ImportJobTransformationSettings';
      enabled: boolean;
      query?: string | null;
    } | null;
  };
  integrationSettings?: {
    __typename?: 'ImportPipelineIntegrationSettings';
    connectors?: Array<{
      __typename?: 'ImportPipelineConnectorSettings';
      id: string;
      type: ImportPipelineConnectorType;
      enabled: boolean;
      sftpPull?: {
        __typename?: 'ImportPipelineSftpPullConnectorSettings';
        host: string;
        port?: number | null;
        username: string;
        password: string;
        path?: string | null;
        pattern?: string | null;
        schedule?: {
          __typename?: 'ImportPipelinePullScheduleSettings';
          cronExpression?: string | null;
        } | null;
        afterPull?: {
          __typename?: 'ImportPipelineAfterPullActionSettings';
          action: ImportPipelineAfterPullAction;
        } | null;
      } | null;
      datasetChanged?: {
        __typename?: 'ImportPipelineDatasetChangedConnectorSettings';
        sources: Array<{
          __typename?: 'ImportPipelineDatasetChangedConnectorSource';
          id: string;
        }>;
      } | null;
    }> | null;
  } | null;
};

export type ImportPipelineFragment = {
  __typename?: 'ImportPipeline';
  id: string;
  userId: string;
  importType: ImportJobImportType;
  title?: string | null;
  description?: string | null;
  enabled: boolean;
  createdAt: number;
  updatedAt: number;
  extraSettings?: any | null;
  settings?: {
    __typename?: 'ImportPipelineSettings';
    sourceSettings: {
      __typename?: 'ImportJobPipelineSourceSettings';
      format: ImportFormat;
      compression?: ImportJobSourceCompression | null;
    };
    targetSettings: {
      __typename?: 'ImportJobPipelineTargetSettings';
      target: ImportJobTargetType;
      newSettings?: {
        __typename?: 'ImportJobNewTargetSettings';
        targetTitle: string;
      } | null;
      appendSettings?: {
        __typename?: 'ImportJobAppendTargetSettings';
        targetId: string;
        overwrite?: boolean | null;
      } | null;
    };
    mappingSettings: {
      __typename?: 'ImportJobMappingSettings';
      hasHeaders?: boolean | null;
      columnNames?: Array<string> | null;
      mapping?: {
        __typename?: 'MappingSpec';
        fields: Array<{
          __typename?: 'MappingFieldSpec';
          name: string;
          columnName?: string | null;
          index?: number | null;
          measureValue?: string | null;
          valueResolver?: {
            __typename?: 'MappingValueResolverSpec';
            values: Array<{
              __typename?: 'MappingValueResolverSpecItem';
              title: string;
              value: string;
            }>;
          } | null;
        }>;
      } | null;
      csvOptions?: {
        __typename?: 'CsvImportOptions';
        separator?: string | null;
        quote?: string | null;
        escape?: string | null;
      } | null;
      transformation?: {
        __typename?: 'ImportJobTransformationSettings';
        enabled: boolean;
        query?: string | null;
      } | null;
    };
    integrationSettings?: {
      __typename?: 'ImportPipelineIntegrationSettings';
      connectors?: Array<{
        __typename?: 'ImportPipelineConnectorSettings';
        id: string;
        type: ImportPipelineConnectorType;
        enabled: boolean;
        sftpPull?: {
          __typename?: 'ImportPipelineSftpPullConnectorSettings';
          host: string;
          port?: number | null;
          username: string;
          password: string;
          path?: string | null;
          pattern?: string | null;
          schedule?: {
            __typename?: 'ImportPipelinePullScheduleSettings';
            cronExpression?: string | null;
          } | null;
          afterPull?: {
            __typename?: 'ImportPipelineAfterPullActionSettings';
            action: ImportPipelineAfterPullAction;
          } | null;
        } | null;
        datasetChanged?: {
          __typename?: 'ImportPipelineDatasetChangedConnectorSettings';
          sources: Array<{
            __typename?: 'ImportPipelineDatasetChangedConnectorSource';
            id: string;
          }>;
        } | null;
      }> | null;
    } | null;
  } | null;
  jobs: { __typename?: 'ImportJobsConnection'; totalCount: number };
  connectorsState: Array<{
    __typename?: 'ImportPipelineConnectorDetails';
    id: string;
    s3Push?: {
      __typename?: 'ImportPipelineS3PushConnectorDetails';
      s3Url?: string | null;
      awsCredentials?: {
        __typename?: 'AwsAccessKey';
        accessKeyId: string;
        secretAccessKey: string;
      } | null;
    } | null;
    apiPush?: {
      __typename?: 'ImportPipelineApiPushConnectorDetails';
      apiUrl?: string | null;
      apiAccessKey: string;
    } | null;
    emailPush?: {
      __typename?: 'ImportPipelineEmailPushConnectorDetails';
      address: string;
    } | null;
    datasetChanged?: {
      __typename?: 'ImportPipelineDatasetChangedConnectorDetails';
      sources: Array<{
        __typename?: 'DatasetObject';
        id: string;
        title: string;
        objectType: ImportJobImportType;
      }>;
    } | null;
  }>;
};

export type ImportPipelinesConnectionFragment = {
  __typename?: 'ImportPipelinesConnection';
  totalCount: number;
  content: Array<{
    __typename?: 'ImportPipeline';
    id: string;
    userId: string;
    importType: ImportJobImportType;
    title?: string | null;
    description?: string | null;
    enabled: boolean;
    createdAt: number;
    updatedAt: number;
    extraSettings?: any | null;
    settings?: {
      __typename?: 'ImportPipelineSettings';
      sourceSettings: {
        __typename?: 'ImportJobPipelineSourceSettings';
        format: ImportFormat;
        compression?: ImportJobSourceCompression | null;
      };
      targetSettings: {
        __typename?: 'ImportJobPipelineTargetSettings';
        target: ImportJobTargetType;
        newSettings?: {
          __typename?: 'ImportJobNewTargetSettings';
          targetTitle: string;
        } | null;
        appendSettings?: {
          __typename?: 'ImportJobAppendTargetSettings';
          targetId: string;
          overwrite?: boolean | null;
        } | null;
      };
      mappingSettings: {
        __typename?: 'ImportJobMappingSettings';
        hasHeaders?: boolean | null;
        columnNames?: Array<string> | null;
        mapping?: {
          __typename?: 'MappingSpec';
          fields: Array<{
            __typename?: 'MappingFieldSpec';
            name: string;
            columnName?: string | null;
            index?: number | null;
            measureValue?: string | null;
            valueResolver?: {
              __typename?: 'MappingValueResolverSpec';
              values: Array<{
                __typename?: 'MappingValueResolverSpecItem';
                title: string;
                value: string;
              }>;
            } | null;
          }>;
        } | null;
        csvOptions?: {
          __typename?: 'CsvImportOptions';
          separator?: string | null;
          quote?: string | null;
          escape?: string | null;
        } | null;
        transformation?: {
          __typename?: 'ImportJobTransformationSettings';
          enabled: boolean;
          query?: string | null;
        } | null;
      };
      integrationSettings?: {
        __typename?: 'ImportPipelineIntegrationSettings';
        connectors?: Array<{
          __typename?: 'ImportPipelineConnectorSettings';
          id: string;
          type: ImportPipelineConnectorType;
          enabled: boolean;
          sftpPull?: {
            __typename?: 'ImportPipelineSftpPullConnectorSettings';
            host: string;
            port?: number | null;
            username: string;
            password: string;
            path?: string | null;
            pattern?: string | null;
            schedule?: {
              __typename?: 'ImportPipelinePullScheduleSettings';
              cronExpression?: string | null;
            } | null;
            afterPull?: {
              __typename?: 'ImportPipelineAfterPullActionSettings';
              action: ImportPipelineAfterPullAction;
            } | null;
          } | null;
          datasetChanged?: {
            __typename?: 'ImportPipelineDatasetChangedConnectorSettings';
            sources: Array<{
              __typename?: 'ImportPipelineDatasetChangedConnectorSource';
              id: string;
            }>;
          } | null;
        }> | null;
      } | null;
    } | null;
    jobs: { __typename?: 'ImportJobsConnection'; totalCount: number };
    connectorsState: Array<{
      __typename?: 'ImportPipelineConnectorDetails';
      id: string;
      s3Push?: {
        __typename?: 'ImportPipelineS3PushConnectorDetails';
        s3Url?: string | null;
        awsCredentials?: {
          __typename?: 'AwsAccessKey';
          accessKeyId: string;
          secretAccessKey: string;
        } | null;
      } | null;
      apiPush?: {
        __typename?: 'ImportPipelineApiPushConnectorDetails';
        apiUrl?: string | null;
        apiAccessKey: string;
      } | null;
      emailPush?: {
        __typename?: 'ImportPipelineEmailPushConnectorDetails';
        address: string;
      } | null;
      datasetChanged?: {
        __typename?: 'ImportPipelineDatasetChangedConnectorDetails';
        sources: Array<{
          __typename?: 'DatasetObject';
          id: string;
          title: string;
          objectType: ImportJobImportType;
        }>;
      } | null;
    }>;
  }>;
};

export type SftpTestResultFragment = {
  __typename?: 'TestImportPipelineSftpConnectorResult';
  totalFiles: number;
  matchedFiles: number;
};

export type LoadImportPipelinesQueryVariables = Exact<{
  filter?: InputMaybe<ImportPipelinesFilter>;
  sortBy?: InputMaybe<Array<ImportPipelinesSortBy> | ImportPipelinesSortBy>;
  page?: InputMaybe<SkipLimitPageSpec>;
}>;

export type LoadImportPipelinesQuery = {
  __typename?: 'Query';
  importPipelines: {
    __typename?: 'ImportPipelinesConnection';
    totalCount: number;
    content: Array<{
      __typename?: 'ImportPipeline';
      id: string;
      userId: string;
      importType: ImportJobImportType;
      title?: string | null;
      description?: string | null;
      enabled: boolean;
      createdAt: number;
      updatedAt: number;
      extraSettings?: any | null;
      settings?: {
        __typename?: 'ImportPipelineSettings';
        sourceSettings: {
          __typename?: 'ImportJobPipelineSourceSettings';
          format: ImportFormat;
          compression?: ImportJobSourceCompression | null;
        };
        targetSettings: {
          __typename?: 'ImportJobPipelineTargetSettings';
          target: ImportJobTargetType;
          newSettings?: {
            __typename?: 'ImportJobNewTargetSettings';
            targetTitle: string;
          } | null;
          appendSettings?: {
            __typename?: 'ImportJobAppendTargetSettings';
            targetId: string;
            overwrite?: boolean | null;
          } | null;
        };
        mappingSettings: {
          __typename?: 'ImportJobMappingSettings';
          hasHeaders?: boolean | null;
          columnNames?: Array<string> | null;
          mapping?: {
            __typename?: 'MappingSpec';
            fields: Array<{
              __typename?: 'MappingFieldSpec';
              name: string;
              columnName?: string | null;
              index?: number | null;
              measureValue?: string | null;
              valueResolver?: {
                __typename?: 'MappingValueResolverSpec';
                values: Array<{
                  __typename?: 'MappingValueResolverSpecItem';
                  title: string;
                  value: string;
                }>;
              } | null;
            }>;
          } | null;
          csvOptions?: {
            __typename?: 'CsvImportOptions';
            separator?: string | null;
            quote?: string | null;
            escape?: string | null;
          } | null;
          transformation?: {
            __typename?: 'ImportJobTransformationSettings';
            enabled: boolean;
            query?: string | null;
          } | null;
        };
        integrationSettings?: {
          __typename?: 'ImportPipelineIntegrationSettings';
          connectors?: Array<{
            __typename?: 'ImportPipelineConnectorSettings';
            id: string;
            type: ImportPipelineConnectorType;
            enabled: boolean;
            sftpPull?: {
              __typename?: 'ImportPipelineSftpPullConnectorSettings';
              host: string;
              port?: number | null;
              username: string;
              password: string;
              path?: string | null;
              pattern?: string | null;
              schedule?: {
                __typename?: 'ImportPipelinePullScheduleSettings';
                cronExpression?: string | null;
              } | null;
              afterPull?: {
                __typename?: 'ImportPipelineAfterPullActionSettings';
                action: ImportPipelineAfterPullAction;
              } | null;
            } | null;
            datasetChanged?: {
              __typename?: 'ImportPipelineDatasetChangedConnectorSettings';
              sources: Array<{
                __typename?: 'ImportPipelineDatasetChangedConnectorSource';
                id: string;
              }>;
            } | null;
          }> | null;
        } | null;
      } | null;
      jobs: { __typename?: 'ImportJobsConnection'; totalCount: number };
      connectorsState: Array<{
        __typename?: 'ImportPipelineConnectorDetails';
        id: string;
        s3Push?: {
          __typename?: 'ImportPipelineS3PushConnectorDetails';
          s3Url?: string | null;
          awsCredentials?: {
            __typename?: 'AwsAccessKey';
            accessKeyId: string;
            secretAccessKey: string;
          } | null;
        } | null;
        apiPush?: {
          __typename?: 'ImportPipelineApiPushConnectorDetails';
          apiUrl?: string | null;
          apiAccessKey: string;
        } | null;
        emailPush?: {
          __typename?: 'ImportPipelineEmailPushConnectorDetails';
          address: string;
        } | null;
        datasetChanged?: {
          __typename?: 'ImportPipelineDatasetChangedConnectorDetails';
          sources: Array<{
            __typename?: 'DatasetObject';
            id: string;
            title: string;
            objectType: ImportJobImportType;
          }>;
        } | null;
      }>;
    }>;
  };
};

export type LoadImportPipelineQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type LoadImportPipelineQuery = {
  __typename?: 'Query';
  importPipeline?: {
    __typename?: 'ImportPipeline';
    id: string;
    userId: string;
    importType: ImportJobImportType;
    title?: string | null;
    description?: string | null;
    enabled: boolean;
    createdAt: number;
    updatedAt: number;
    extraSettings?: any | null;
    settings?: {
      __typename?: 'ImportPipelineSettings';
      sourceSettings: {
        __typename?: 'ImportJobPipelineSourceSettings';
        format: ImportFormat;
        compression?: ImportJobSourceCompression | null;
      };
      targetSettings: {
        __typename?: 'ImportJobPipelineTargetSettings';
        target: ImportJobTargetType;
        newSettings?: {
          __typename?: 'ImportJobNewTargetSettings';
          targetTitle: string;
        } | null;
        appendSettings?: {
          __typename?: 'ImportJobAppendTargetSettings';
          targetId: string;
          overwrite?: boolean | null;
        } | null;
      };
      mappingSettings: {
        __typename?: 'ImportJobMappingSettings';
        hasHeaders?: boolean | null;
        columnNames?: Array<string> | null;
        mapping?: {
          __typename?: 'MappingSpec';
          fields: Array<{
            __typename?: 'MappingFieldSpec';
            name: string;
            columnName?: string | null;
            index?: number | null;
            measureValue?: string | null;
            valueResolver?: {
              __typename?: 'MappingValueResolverSpec';
              values: Array<{
                __typename?: 'MappingValueResolverSpecItem';
                title: string;
                value: string;
              }>;
            } | null;
          }>;
        } | null;
        csvOptions?: {
          __typename?: 'CsvImportOptions';
          separator?: string | null;
          quote?: string | null;
          escape?: string | null;
        } | null;
        transformation?: {
          __typename?: 'ImportJobTransformationSettings';
          enabled: boolean;
          query?: string | null;
        } | null;
      };
      integrationSettings?: {
        __typename?: 'ImportPipelineIntegrationSettings';
        connectors?: Array<{
          __typename?: 'ImportPipelineConnectorSettings';
          id: string;
          type: ImportPipelineConnectorType;
          enabled: boolean;
          sftpPull?: {
            __typename?: 'ImportPipelineSftpPullConnectorSettings';
            host: string;
            port?: number | null;
            username: string;
            password: string;
            path?: string | null;
            pattern?: string | null;
            schedule?: {
              __typename?: 'ImportPipelinePullScheduleSettings';
              cronExpression?: string | null;
            } | null;
            afterPull?: {
              __typename?: 'ImportPipelineAfterPullActionSettings';
              action: ImportPipelineAfterPullAction;
            } | null;
          } | null;
          datasetChanged?: {
            __typename?: 'ImportPipelineDatasetChangedConnectorSettings';
            sources: Array<{
              __typename?: 'ImportPipelineDatasetChangedConnectorSource';
              id: string;
            }>;
          } | null;
        }> | null;
      } | null;
    } | null;
    jobs: { __typename?: 'ImportJobsConnection'; totalCount: number };
    connectorsState: Array<{
      __typename?: 'ImportPipelineConnectorDetails';
      id: string;
      s3Push?: {
        __typename?: 'ImportPipelineS3PushConnectorDetails';
        s3Url?: string | null;
        awsCredentials?: {
          __typename?: 'AwsAccessKey';
          accessKeyId: string;
          secretAccessKey: string;
        } | null;
      } | null;
      apiPush?: {
        __typename?: 'ImportPipelineApiPushConnectorDetails';
        apiUrl?: string | null;
        apiAccessKey: string;
      } | null;
      emailPush?: {
        __typename?: 'ImportPipelineEmailPushConnectorDetails';
        address: string;
      } | null;
      datasetChanged?: {
        __typename?: 'ImportPipelineDatasetChangedConnectorDetails';
        sources: Array<{
          __typename?: 'DatasetObject';
          id: string;
          title: string;
          objectType: ImportJobImportType;
        }>;
      } | null;
    }>;
  } | null;
};

export type CreateImportPipelineMutationVariables = Exact<{
  input: CreateImportPipelineInput;
}>;

export type CreateImportPipelineMutation = {
  __typename?: 'Mutation';
  createImportPipeline: {
    __typename?: 'ImportPipeline';
    id: string;
    userId: string;
    importType: ImportJobImportType;
    title?: string | null;
    description?: string | null;
    enabled: boolean;
    createdAt: number;
    updatedAt: number;
    extraSettings?: any | null;
    settings?: {
      __typename?: 'ImportPipelineSettings';
      sourceSettings: {
        __typename?: 'ImportJobPipelineSourceSettings';
        format: ImportFormat;
        compression?: ImportJobSourceCompression | null;
      };
      targetSettings: {
        __typename?: 'ImportJobPipelineTargetSettings';
        target: ImportJobTargetType;
        newSettings?: {
          __typename?: 'ImportJobNewTargetSettings';
          targetTitle: string;
        } | null;
        appendSettings?: {
          __typename?: 'ImportJobAppendTargetSettings';
          targetId: string;
          overwrite?: boolean | null;
        } | null;
      };
      mappingSettings: {
        __typename?: 'ImportJobMappingSettings';
        hasHeaders?: boolean | null;
        columnNames?: Array<string> | null;
        mapping?: {
          __typename?: 'MappingSpec';
          fields: Array<{
            __typename?: 'MappingFieldSpec';
            name: string;
            columnName?: string | null;
            index?: number | null;
            measureValue?: string | null;
            valueResolver?: {
              __typename?: 'MappingValueResolverSpec';
              values: Array<{
                __typename?: 'MappingValueResolverSpecItem';
                title: string;
                value: string;
              }>;
            } | null;
          }>;
        } | null;
        csvOptions?: {
          __typename?: 'CsvImportOptions';
          separator?: string | null;
          quote?: string | null;
          escape?: string | null;
        } | null;
        transformation?: {
          __typename?: 'ImportJobTransformationSettings';
          enabled: boolean;
          query?: string | null;
        } | null;
      };
      integrationSettings?: {
        __typename?: 'ImportPipelineIntegrationSettings';
        connectors?: Array<{
          __typename?: 'ImportPipelineConnectorSettings';
          id: string;
          type: ImportPipelineConnectorType;
          enabled: boolean;
          sftpPull?: {
            __typename?: 'ImportPipelineSftpPullConnectorSettings';
            host: string;
            port?: number | null;
            username: string;
            password: string;
            path?: string | null;
            pattern?: string | null;
            schedule?: {
              __typename?: 'ImportPipelinePullScheduleSettings';
              cronExpression?: string | null;
            } | null;
            afterPull?: {
              __typename?: 'ImportPipelineAfterPullActionSettings';
              action: ImportPipelineAfterPullAction;
            } | null;
          } | null;
          datasetChanged?: {
            __typename?: 'ImportPipelineDatasetChangedConnectorSettings';
            sources: Array<{
              __typename?: 'ImportPipelineDatasetChangedConnectorSource';
              id: string;
            }>;
          } | null;
        }> | null;
      } | null;
    } | null;
    jobs: { __typename?: 'ImportJobsConnection'; totalCount: number };
    connectorsState: Array<{
      __typename?: 'ImportPipelineConnectorDetails';
      id: string;
      s3Push?: {
        __typename?: 'ImportPipelineS3PushConnectorDetails';
        s3Url?: string | null;
        awsCredentials?: {
          __typename?: 'AwsAccessKey';
          accessKeyId: string;
          secretAccessKey: string;
        } | null;
      } | null;
      apiPush?: {
        __typename?: 'ImportPipelineApiPushConnectorDetails';
        apiUrl?: string | null;
        apiAccessKey: string;
      } | null;
      emailPush?: {
        __typename?: 'ImportPipelineEmailPushConnectorDetails';
        address: string;
      } | null;
      datasetChanged?: {
        __typename?: 'ImportPipelineDatasetChangedConnectorDetails';
        sources: Array<{
          __typename?: 'DatasetObject';
          id: string;
          title: string;
          objectType: ImportJobImportType;
        }>;
      } | null;
    }>;
  };
};

export type CreateImportPipelineFromImportJobMutationVariables = Exact<{
  input: CreateImportPipelineFromImportJobInput;
}>;

export type CreateImportPipelineFromImportJobMutation = {
  __typename?: 'Mutation';
  createImportPipelineFromImportJob: {
    __typename?: 'ImportPipeline';
    id: string;
    userId: string;
    importType: ImportJobImportType;
    title?: string | null;
    description?: string | null;
    enabled: boolean;
    createdAt: number;
    updatedAt: number;
    extraSettings?: any | null;
    settings?: {
      __typename?: 'ImportPipelineSettings';
      sourceSettings: {
        __typename?: 'ImportJobPipelineSourceSettings';
        format: ImportFormat;
        compression?: ImportJobSourceCompression | null;
      };
      targetSettings: {
        __typename?: 'ImportJobPipelineTargetSettings';
        target: ImportJobTargetType;
        newSettings?: {
          __typename?: 'ImportJobNewTargetSettings';
          targetTitle: string;
        } | null;
        appendSettings?: {
          __typename?: 'ImportJobAppendTargetSettings';
          targetId: string;
          overwrite?: boolean | null;
        } | null;
      };
      mappingSettings: {
        __typename?: 'ImportJobMappingSettings';
        hasHeaders?: boolean | null;
        columnNames?: Array<string> | null;
        mapping?: {
          __typename?: 'MappingSpec';
          fields: Array<{
            __typename?: 'MappingFieldSpec';
            name: string;
            columnName?: string | null;
            index?: number | null;
            measureValue?: string | null;
            valueResolver?: {
              __typename?: 'MappingValueResolverSpec';
              values: Array<{
                __typename?: 'MappingValueResolverSpecItem';
                title: string;
                value: string;
              }>;
            } | null;
          }>;
        } | null;
        csvOptions?: {
          __typename?: 'CsvImportOptions';
          separator?: string | null;
          quote?: string | null;
          escape?: string | null;
        } | null;
        transformation?: {
          __typename?: 'ImportJobTransformationSettings';
          enabled: boolean;
          query?: string | null;
        } | null;
      };
      integrationSettings?: {
        __typename?: 'ImportPipelineIntegrationSettings';
        connectors?: Array<{
          __typename?: 'ImportPipelineConnectorSettings';
          id: string;
          type: ImportPipelineConnectorType;
          enabled: boolean;
          sftpPull?: {
            __typename?: 'ImportPipelineSftpPullConnectorSettings';
            host: string;
            port?: number | null;
            username: string;
            password: string;
            path?: string | null;
            pattern?: string | null;
            schedule?: {
              __typename?: 'ImportPipelinePullScheduleSettings';
              cronExpression?: string | null;
            } | null;
            afterPull?: {
              __typename?: 'ImportPipelineAfterPullActionSettings';
              action: ImportPipelineAfterPullAction;
            } | null;
          } | null;
          datasetChanged?: {
            __typename?: 'ImportPipelineDatasetChangedConnectorSettings';
            sources: Array<{
              __typename?: 'ImportPipelineDatasetChangedConnectorSource';
              id: string;
            }>;
          } | null;
        }> | null;
      } | null;
    } | null;
    jobs: { __typename?: 'ImportJobsConnection'; totalCount: number };
    connectorsState: Array<{
      __typename?: 'ImportPipelineConnectorDetails';
      id: string;
      s3Push?: {
        __typename?: 'ImportPipelineS3PushConnectorDetails';
        s3Url?: string | null;
        awsCredentials?: {
          __typename?: 'AwsAccessKey';
          accessKeyId: string;
          secretAccessKey: string;
        } | null;
      } | null;
      apiPush?: {
        __typename?: 'ImportPipelineApiPushConnectorDetails';
        apiUrl?: string | null;
        apiAccessKey: string;
      } | null;
      emailPush?: {
        __typename?: 'ImportPipelineEmailPushConnectorDetails';
        address: string;
      } | null;
      datasetChanged?: {
        __typename?: 'ImportPipelineDatasetChangedConnectorDetails';
        sources: Array<{
          __typename?: 'DatasetObject';
          id: string;
          title: string;
          objectType: ImportJobImportType;
        }>;
      } | null;
    }>;
  };
};

export type RemoveImportPipelineMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type RemoveImportPipelineMutation = {
  __typename?: 'Mutation';
  removeImportPipeline: boolean;
};

export type UpdateImportPipelineMutationVariables = Exact<{
  input: UpdateImportPipelineInput;
}>;

export type UpdateImportPipelineMutation = {
  __typename?: 'Mutation';
  updateImportPipeline: {
    __typename?: 'ImportPipeline';
    id: string;
    userId: string;
    importType: ImportJobImportType;
    title?: string | null;
    description?: string | null;
    enabled: boolean;
    createdAt: number;
    updatedAt: number;
    extraSettings?: any | null;
    settings?: {
      __typename?: 'ImportPipelineSettings';
      sourceSettings: {
        __typename?: 'ImportJobPipelineSourceSettings';
        format: ImportFormat;
        compression?: ImportJobSourceCompression | null;
      };
      targetSettings: {
        __typename?: 'ImportJobPipelineTargetSettings';
        target: ImportJobTargetType;
        newSettings?: {
          __typename?: 'ImportJobNewTargetSettings';
          targetTitle: string;
        } | null;
        appendSettings?: {
          __typename?: 'ImportJobAppendTargetSettings';
          targetId: string;
          overwrite?: boolean | null;
        } | null;
      };
      mappingSettings: {
        __typename?: 'ImportJobMappingSettings';
        hasHeaders?: boolean | null;
        columnNames?: Array<string> | null;
        mapping?: {
          __typename?: 'MappingSpec';
          fields: Array<{
            __typename?: 'MappingFieldSpec';
            name: string;
            columnName?: string | null;
            index?: number | null;
            measureValue?: string | null;
            valueResolver?: {
              __typename?: 'MappingValueResolverSpec';
              values: Array<{
                __typename?: 'MappingValueResolverSpecItem';
                title: string;
                value: string;
              }>;
            } | null;
          }>;
        } | null;
        csvOptions?: {
          __typename?: 'CsvImportOptions';
          separator?: string | null;
          quote?: string | null;
          escape?: string | null;
        } | null;
        transformation?: {
          __typename?: 'ImportJobTransformationSettings';
          enabled: boolean;
          query?: string | null;
        } | null;
      };
      integrationSettings?: {
        __typename?: 'ImportPipelineIntegrationSettings';
        connectors?: Array<{
          __typename?: 'ImportPipelineConnectorSettings';
          id: string;
          type: ImportPipelineConnectorType;
          enabled: boolean;
          sftpPull?: {
            __typename?: 'ImportPipelineSftpPullConnectorSettings';
            host: string;
            port?: number | null;
            username: string;
            password: string;
            path?: string | null;
            pattern?: string | null;
            schedule?: {
              __typename?: 'ImportPipelinePullScheduleSettings';
              cronExpression?: string | null;
            } | null;
            afterPull?: {
              __typename?: 'ImportPipelineAfterPullActionSettings';
              action: ImportPipelineAfterPullAction;
            } | null;
          } | null;
          datasetChanged?: {
            __typename?: 'ImportPipelineDatasetChangedConnectorSettings';
            sources: Array<{
              __typename?: 'ImportPipelineDatasetChangedConnectorSource';
              id: string;
            }>;
          } | null;
        }> | null;
      } | null;
    } | null;
    jobs: { __typename?: 'ImportJobsConnection'; totalCount: number };
    connectorsState: Array<{
      __typename?: 'ImportPipelineConnectorDetails';
      id: string;
      s3Push?: {
        __typename?: 'ImportPipelineS3PushConnectorDetails';
        s3Url?: string | null;
        awsCredentials?: {
          __typename?: 'AwsAccessKey';
          accessKeyId: string;
          secretAccessKey: string;
        } | null;
      } | null;
      apiPush?: {
        __typename?: 'ImportPipelineApiPushConnectorDetails';
        apiUrl?: string | null;
        apiAccessKey: string;
      } | null;
      emailPush?: {
        __typename?: 'ImportPipelineEmailPushConnectorDetails';
        address: string;
      } | null;
      datasetChanged?: {
        __typename?: 'ImportPipelineDatasetChangedConnectorDetails';
        sources: Array<{
          __typename?: 'DatasetObject';
          id: string;
          title: string;
          objectType: ImportJobImportType;
        }>;
      } | null;
    }>;
  };
};

export type TestImportPipelineSftpConnectorMutationVariables = Exact<{
  sftp: TestImportPipelineSftpConnectorInput;
}>;

export type TestImportPipelineSftpConnectorMutation = {
  __typename?: 'Mutation';
  testImportPipelineSftpConnector: {
    __typename?: 'TestImportPipelineSftpConnectorResult';
    totalFiles: number;
    matchedFiles: number;
  };
};

export type BeginImportPipelineMultipartUploadMutationVariables = Exact<{
  input: BeginImportPipelineMultipartUploadInput;
}>;

export type BeginImportPipelineMultipartUploadMutation = {
  __typename?: 'Mutation';
  beginImportPipelineMultipartUpload: {
    __typename?: 'BeginImportPipelineMultipartUploadResult';
    uploadId: string;
    uploadLinks: Array<string>;
  };
};

export type CompleteImportPipelineMultipartUploadMutationVariables = Exact<{
  input: CompletedImportPipelineMultipartUploadInput;
}>;

export type CompleteImportPipelineMultipartUploadMutation = {
  __typename?: 'Mutation';
  completeImportPipelineMultipartUpload: {
    __typename?: 'ImportPipeline';
    id: string;
  };
};

export type CreateItemSetMutationVariables = Exact<{
  input: CreateItemSetInput;
}>;

export type CreateItemSetMutation = {
  __typename?: 'Mutation';
  createItemSet: { __typename?: 'ItemSet'; id: string };
};

export type UpdateItemSetMutationVariables = Exact<{
  input: UpdateItemSetInput;
}>;

export type UpdateItemSetMutation = {
  __typename?: 'Mutation';
  updateItemSet: {
    __typename?: 'ItemSet';
    id: string;
    description?: string | null;
  };
};

export type RemoveItemSetMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type RemoveItemSetMutation = {
  __typename?: 'Mutation';
  removeItemSet: boolean;
};

export type ItemFilterFragment = {
  __typename?: 'ItemFilter';
  type: ItemFilterType;
  isNot?: boolean | null;
  isNull?: boolean | null;
  valueIn?: Array<{ __typename?: 'FilterValue'; title: string }> | null;
  range?: {
    __typename?: 'NumberFilterRange';
    from?: number | null;
    to?: number | null;
  } | null;
  stringRange?: {
    __typename?: 'StringFilterRange';
    from?: string | null;
    excludeFrom?: boolean | null;
    to?: string | null;
    excludeTo?: boolean | null;
  } | null;
};

export type ItemFilterIntersectionFragment = {
  __typename?: 'ItemFilterIntersection';
  id?: string | null;
  allOf: Array<{
    __typename?: 'ItemFilter';
    type: ItemFilterType;
    isNot?: boolean | null;
    isNull?: boolean | null;
    valueIn?: Array<{ __typename?: 'FilterValue'; title: string }> | null;
    range?: {
      __typename?: 'NumberFilterRange';
      from?: number | null;
      to?: number | null;
    } | null;
    stringRange?: {
      __typename?: 'StringFilterRange';
      from?: string | null;
      excludeFrom?: boolean | null;
      to?: string | null;
      excludeTo?: boolean | null;
    } | null;
  }>;
};

export type ItemFilterUnionFragment = {
  __typename?: 'ItemFilterUnion';
  anyOf: Array<{
    __typename?: 'ItemFilterIntersection';
    id?: string | null;
    allOf: Array<{
      __typename?: 'ItemFilter';
      type: ItemFilterType;
      isNot?: boolean | null;
      isNull?: boolean | null;
      valueIn?: Array<{ __typename?: 'FilterValue'; title: string }> | null;
      range?: {
        __typename?: 'NumberFilterRange';
        from?: number | null;
        to?: number | null;
      } | null;
      stringRange?: {
        __typename?: 'StringFilterRange';
        from?: string | null;
        excludeFrom?: boolean | null;
        to?: string | null;
        excludeTo?: boolean | null;
      } | null;
    }>;
  }>;
};

export type ItemSetFragment = {
  __typename?: 'ItemSet';
  id: string;
  title: string;
  status: ItemSetImportStatus;
  description?: string | null;
  createdAt: number;
  updatedAt: number;
  inputFileName?: string | null;
  userTags?: Array<string> | null;
  stackingPolicy?: {
    __typename?: 'StackingPolicy';
    topRules?: Array<{
      __typename?: 'StackingPolicyRule';
      id: string;
      title?: string | null;
      itemsMatch?: {
        __typename?: 'ItemFilterUnion';
        anyOf: Array<{
          __typename?: 'ItemFilterIntersection';
          id?: string | null;
          allOf: Array<{
            __typename?: 'ItemFilter';
            type: ItemFilterType;
            isNot?: boolean | null;
            isNull?: boolean | null;
            valueIn?: Array<{
              __typename?: 'FilterValue';
              title: string;
            }> | null;
            range?: {
              __typename?: 'NumberFilterRange';
              from?: number | null;
              to?: number | null;
            } | null;
            stringRange?: {
              __typename?: 'StringFilterRange';
              from?: string | null;
              excludeFrom?: boolean | null;
              to?: string | null;
              excludeTo?: boolean | null;
            } | null;
          }>;
        }>;
      } | null;
      subcategories?: {
        __typename?: 'StackingPolicyRuleSubcategories';
        type?: StackingPolicyRuleSubcategoryType | null;
        step?: number | null;
        boundaries?: Array<number> | null;
      } | null;
    }> | null;
    bottomRules?: Array<{
      __typename?: 'StackingPolicyRule';
      id: string;
      title?: string | null;
      itemsMatch?: {
        __typename?: 'ItemFilterUnion';
        anyOf: Array<{
          __typename?: 'ItemFilterIntersection';
          id?: string | null;
          allOf: Array<{
            __typename?: 'ItemFilter';
            type: ItemFilterType;
            isNot?: boolean | null;
            isNull?: boolean | null;
            valueIn?: Array<{
              __typename?: 'FilterValue';
              title: string;
            }> | null;
            range?: {
              __typename?: 'NumberFilterRange';
              from?: number | null;
              to?: number | null;
            } | null;
            stringRange?: {
              __typename?: 'StringFilterRange';
              from?: string | null;
              excludeFrom?: boolean | null;
              to?: string | null;
              excludeTo?: boolean | null;
            } | null;
          }>;
        }>;
      } | null;
      subcategories?: {
        __typename?: 'StackingPolicyRuleSubcategories';
        type?: StackingPolicyRuleSubcategoryType | null;
        step?: number | null;
        boundaries?: Array<number> | null;
      } | null;
    }> | null;
    fallbackRule?: {
      __typename?: 'StackingPolicyFallbackRule';
      subcategories?: {
        __typename?: 'StackingPolicyRuleSubcategories';
        type?: StackingPolicyRuleSubcategoryType | null;
        step?: number | null;
        boundaries?: Array<number> | null;
      } | null;
    } | null;
  } | null;
  simulationsWith: {
    __typename?: 'SimulationsConnection';
    totalCount: number;
    content: Array<{
      __typename?: 'Simulation';
      id: string;
      title: string;
      description?: string | null;
      createdAt: number;
      updatedAt: number;
      userTags?: Array<string> | null;
    }>;
  };
  summary?: {
    __typename?: 'ItemSetSummary';
    totalItemsCount: number;
    consignees: Array<{
      __typename?: 'ItemSetConsigneeSummary';
      consignee: string;
      itemsCount: number;
    }>;
  } | null;
};

export type ItemDataFragment = {
  __typename?: 'Item';
  id: string;
  consignee: string;
  sku: string;
  skuGroup?: string | null;
  name?: string | null;
  description?: string | null;
  subGroup?: string | null;
  transportClass?: string | null;
  stockCategory?: string | null;
  storageClass?: string | null;
  baseUom?: {
    __typename?: 'BaseUom';
    uom?: string | null;
    netWeight?: number | null;
    length?: number | null;
    width?: number | null;
    height?: number | null;
    volume?: number | null;
    maxStack?: number | null;
    ean?: string | null;
    upc?: string | null;
  } | null;
};

export type ItemFragment = {
  __typename?: 'Item';
  id: string;
  consignee: string;
  sku: string;
  skuGroup?: string | null;
  name?: string | null;
  description?: string | null;
  subGroup?: string | null;
  transportClass?: string | null;
  stockCategory?: string | null;
  storageClass?: string | null;
  pollutionClass?: string | null;
};

export type UomFragment = {
  __typename?: 'ItemUom';
  uom: string;
  netWeight?: number | null;
  length?: number | null;
  width?: number | null;
  height?: number | null;
  volume?: number | null;
  unitsPerLowestUom?: number | null;
  lowerUom?: string | null;
  ean?: string | null;
  upc?: string | null;
  maxStack?: number | null;
};

export type ItemUomFragment = {
  __typename?: 'ItemUomOrItem';
  item: {
    __typename?: 'Item';
    id: string;
    consignee: string;
    sku: string;
    skuGroup?: string | null;
    name?: string | null;
    description?: string | null;
    subGroup?: string | null;
    transportClass?: string | null;
    stockCategory?: string | null;
    storageClass?: string | null;
    pollutionClass?: string | null;
  };
  uom?: {
    __typename?: 'ItemUom';
    uom: string;
    netWeight?: number | null;
    length?: number | null;
    width?: number | null;
    height?: number | null;
    volume?: number | null;
    unitsPerLowestUom?: number | null;
    lowerUom?: string | null;
    ean?: string | null;
    upc?: string | null;
    maxStack?: number | null;
  } | null;
};

export type ItemSetDataFragment = {
  __typename?: 'ItemUomOrItemsConnection';
  totalCount: number;
  content: Array<{
    __typename?: 'ItemUomOrItem';
    item: {
      __typename?: 'Item';
      id: string;
      consignee: string;
      sku: string;
      skuGroup?: string | null;
      name?: string | null;
      description?: string | null;
      subGroup?: string | null;
      transportClass?: string | null;
      stockCategory?: string | null;
      storageClass?: string | null;
      pollutionClass?: string | null;
    };
    uom?: {
      __typename?: 'ItemUom';
      uom: string;
      netWeight?: number | null;
      length?: number | null;
      width?: number | null;
      height?: number | null;
      volume?: number | null;
      unitsPerLowestUom?: number | null;
      lowerUom?: string | null;
      ean?: string | null;
      upc?: string | null;
      maxStack?: number | null;
    } | null;
  }>;
};

export type LoadItemSetDataQueryVariables = Exact<{
  itemSetId: Scalars['ID']['input'];
  page?: InputMaybe<SkipLimitPageSpec>;
  filter?: InputMaybe<ItemUomOrItemsSearchFilter>;
  sortBy?: InputMaybe<Array<ItemUomOrItemsSortBy> | ItemUomOrItemsSortBy>;
}>;

export type LoadItemSetDataQuery = {
  __typename?: 'Query';
  itemSet?: {
    __typename?: 'ItemSet';
    itemUoms: {
      __typename?: 'ItemUomOrItemsConnection';
      totalCount: number;
      content: Array<{
        __typename?: 'ItemUomOrItem';
        item: {
          __typename?: 'Item';
          id: string;
          consignee: string;
          sku: string;
          skuGroup?: string | null;
          name?: string | null;
          description?: string | null;
          subGroup?: string | null;
          transportClass?: string | null;
          stockCategory?: string | null;
          storageClass?: string | null;
          pollutionClass?: string | null;
        };
        uom?: {
          __typename?: 'ItemUom';
          uom: string;
          netWeight?: number | null;
          length?: number | null;
          width?: number | null;
          height?: number | null;
          volume?: number | null;
          unitsPerLowestUom?: number | null;
          lowerUom?: string | null;
          ean?: string | null;
          upc?: string | null;
          maxStack?: number | null;
        } | null;
      }>;
    };
  } | null;
};

export type LoadItemSetQueryVariables = Exact<{
  itemSetId: Scalars['ID']['input'];
}>;

export type LoadItemSetQuery = {
  __typename?: 'Query';
  itemSet?: {
    __typename?: 'ItemSet';
    id: string;
    title: string;
    status: ItemSetImportStatus;
    description?: string | null;
    createdAt: number;
    updatedAt: number;
    inputFileName?: string | null;
    userTags?: Array<string> | null;
    stackingPolicy?: {
      __typename?: 'StackingPolicy';
      topRules?: Array<{
        __typename?: 'StackingPolicyRule';
        id: string;
        title?: string | null;
        itemsMatch?: {
          __typename?: 'ItemFilterUnion';
          anyOf: Array<{
            __typename?: 'ItemFilterIntersection';
            id?: string | null;
            allOf: Array<{
              __typename?: 'ItemFilter';
              type: ItemFilterType;
              isNot?: boolean | null;
              isNull?: boolean | null;
              valueIn?: Array<{
                __typename?: 'FilterValue';
                title: string;
              }> | null;
              range?: {
                __typename?: 'NumberFilterRange';
                from?: number | null;
                to?: number | null;
              } | null;
              stringRange?: {
                __typename?: 'StringFilterRange';
                from?: string | null;
                excludeFrom?: boolean | null;
                to?: string | null;
                excludeTo?: boolean | null;
              } | null;
            }>;
          }>;
        } | null;
        subcategories?: {
          __typename?: 'StackingPolicyRuleSubcategories';
          type?: StackingPolicyRuleSubcategoryType | null;
          step?: number | null;
          boundaries?: Array<number> | null;
        } | null;
      }> | null;
      bottomRules?: Array<{
        __typename?: 'StackingPolicyRule';
        id: string;
        title?: string | null;
        itemsMatch?: {
          __typename?: 'ItemFilterUnion';
          anyOf: Array<{
            __typename?: 'ItemFilterIntersection';
            id?: string | null;
            allOf: Array<{
              __typename?: 'ItemFilter';
              type: ItemFilterType;
              isNot?: boolean | null;
              isNull?: boolean | null;
              valueIn?: Array<{
                __typename?: 'FilterValue';
                title: string;
              }> | null;
              range?: {
                __typename?: 'NumberFilterRange';
                from?: number | null;
                to?: number | null;
              } | null;
              stringRange?: {
                __typename?: 'StringFilterRange';
                from?: string | null;
                excludeFrom?: boolean | null;
                to?: string | null;
                excludeTo?: boolean | null;
              } | null;
            }>;
          }>;
        } | null;
        subcategories?: {
          __typename?: 'StackingPolicyRuleSubcategories';
          type?: StackingPolicyRuleSubcategoryType | null;
          step?: number | null;
          boundaries?: Array<number> | null;
        } | null;
      }> | null;
      fallbackRule?: {
        __typename?: 'StackingPolicyFallbackRule';
        subcategories?: {
          __typename?: 'StackingPolicyRuleSubcategories';
          type?: StackingPolicyRuleSubcategoryType | null;
          step?: number | null;
          boundaries?: Array<number> | null;
        } | null;
      } | null;
    } | null;
    simulationsWith: {
      __typename?: 'SimulationsConnection';
      totalCount: number;
      content: Array<{
        __typename?: 'Simulation';
        id: string;
        title: string;
        description?: string | null;
        createdAt: number;
        updatedAt: number;
        userTags?: Array<string> | null;
      }>;
    };
    summary?: {
      __typename?: 'ItemSetSummary';
      totalItemsCount: number;
      consignees: Array<{
        __typename?: 'ItemSetConsigneeSummary';
        consignee: string;
        itemsCount: number;
      }>;
    } | null;
  } | null;
};

export type FindItemSetFilterValuesQueryVariables = Exact<{
  input: FindItemFilterValuesInput;
  page?: InputMaybe<SkipLimitPageSpec>;
}>;

export type FindItemSetFilterValuesQuery = {
  __typename?: 'Query';
  findItemFilterValues: {
    __typename?: 'FilterValuesConnection';
    totalCount?: number | null;
    content?: Array<{ __typename?: 'FilterValue'; title: string }> | null;
    range?: {
      __typename?: 'NumberFilterRange';
      from?: number | null;
      to?: number | null;
    } | null;
  };
};

export type FindItemsByFilterQueryVariables = Exact<{
  input: FindItemsByFilterInput;
  page?: InputMaybe<SkipLimitPageSpec>;
  filter?: InputMaybe<ItemsFilter>;
  sortBy?: InputMaybe<Array<ItemsSortBy> | ItemsSortBy>;
}>;

export type FindItemsByFilterQuery = {
  __typename?: 'Query';
  findItemsByFilter: {
    __typename?: 'ItemsConnection';
    totalCount: number;
    content: Array<{
      __typename?: 'Item';
      id: string;
      consignee: string;
      sku: string;
      skuGroup?: string | null;
      name?: string | null;
      description?: string | null;
      subGroup?: string | null;
      transportClass?: string | null;
      stockCategory?: string | null;
      storageClass?: string | null;
      baseUom?: {
        __typename?: 'BaseUom';
        uom?: string | null;
        netWeight?: number | null;
        length?: number | null;
        width?: number | null;
        height?: number | null;
        volume?: number | null;
        maxStack?: number | null;
        ean?: string | null;
        upc?: string | null;
      } | null;
    }>;
  };
};

export type ItemStackingDetailsFragment = {
  __typename?: 'ItemStackingDetails';
  stackingPolicyRuleId?: string | null;
  item: {
    __typename?: 'Item';
    id: string;
    consignee: string;
    sku: string;
    skuGroup?: string | null;
    name?: string | null;
    description?: string | null;
    subGroup?: string | null;
    transportClass?: string | null;
    stockCategory?: string | null;
    storageClass?: string | null;
    baseUom?: {
      __typename?: 'BaseUom';
      uom?: string | null;
      netWeight?: number | null;
      length?: number | null;
      width?: number | null;
      height?: number | null;
      volume?: number | null;
      maxStack?: number | null;
      ean?: string | null;
      upc?: string | null;
    } | null;
  };
};

export type FindItemsByPolicyQueryVariables = Exact<{
  input: ApplyStackingPolicyInput;
  page?: InputMaybe<SkipLimitPageSpec>;
  filter?: InputMaybe<ItemsFilter>;
  sortBy?: InputMaybe<Array<ItemsSortBy> | ItemsSortBy>;
}>;

export type FindItemsByPolicyQuery = {
  __typename?: 'Query';
  applyStackingPolicyRules: {
    __typename?: 'ApplyStackingPolicyResult';
    totalCount: number;
    content: Array<{
      __typename?: 'ItemStackingDetails';
      stackingPolicyRuleId?: string | null;
      item: {
        __typename?: 'Item';
        id: string;
        consignee: string;
        sku: string;
        skuGroup?: string | null;
        name?: string | null;
        description?: string | null;
        subGroup?: string | null;
        transportClass?: string | null;
        stockCategory?: string | null;
        storageClass?: string | null;
        baseUom?: {
          __typename?: 'BaseUom';
          uom?: string | null;
          netWeight?: number | null;
          length?: number | null;
          width?: number | null;
          height?: number | null;
          volume?: number | null;
          maxStack?: number | null;
          ean?: string | null;
          upc?: string | null;
        } | null;
      };
    }>;
  };
};

export type StackingTotalsFragment = {
  __typename?: 'ApplyStackingPolicyResult';
  totalTopCount: number;
  totalBottomCount: number;
  totalCount: number;
};

export type LoadItemsCountByPolicyQueryVariables = Exact<{
  input: ApplyStackingPolicyInput;
}>;

export type LoadItemsCountByPolicyQuery = {
  __typename?: 'Query';
  applyStackingPolicyRules: {
    __typename?: 'ApplyStackingPolicyResult';
    totalTopCount: number;
    totalBottomCount: number;
    totalCount: number;
  };
};

export type ItemSetMetaFragment = {
  __typename?: 'ItemSet';
  id: string;
  title: string;
  status: ItemSetImportStatus;
  description?: string | null;
  createdAt: number;
  updatedAt: number;
  inputFileName?: string | null;
  userTags?: Array<string> | null;
};

export type LoadAllItemSetsMetaQueryVariables = Exact<{
  warehouseId: Scalars['ID']['input'];
}>;

export type LoadAllItemSetsMetaQuery = {
  __typename?: 'Query';
  warehouse?: {
    __typename?: 'Warehouse';
    itemSets: {
      __typename?: 'ItemSetsConnection';
      content: Array<{
        __typename?: 'ItemSet';
        id: string;
        title: string;
        status: ItemSetImportStatus;
        description?: string | null;
        createdAt: number;
        updatedAt: number;
        inputFileName?: string | null;
        userTags?: Array<string> | null;
      }>;
    };
  } | null;
};

export type ItemSetSummaryFragment = {
  __typename?: 'ItemSetSummary';
  totalItemsCount: number;
  consignees: Array<{
    __typename?: 'ItemSetConsigneeSummary';
    consignee: string;
    itemsCount: number;
  }>;
};

export type LoadItemSetSummaryQueryVariables = Exact<{
  itemSetId: Scalars['ID']['input'];
}>;

export type LoadItemSetSummaryQuery = {
  __typename?: 'Query';
  itemSet?: {
    __typename?: 'ItemSet';
    id: string;
    title: string;
    status: ItemSetImportStatus;
    description?: string | null;
    createdAt: number;
    updatedAt: number;
    inputFileName?: string | null;
    userTags?: Array<string> | null;
    summary?: {
      __typename?: 'ItemSetSummary';
      totalItemsCount: number;
      consignees: Array<{
        __typename?: 'ItemSetConsigneeSummary';
        consignee: string;
        itemsCount: number;
      }>;
    } | null;
  } | null;
};

export type LoadItemSetUomsQueryVariables = Exact<{
  itemSetId: Scalars['ID']['input'];
}>;

export type LoadItemSetUomsQuery = {
  __typename?: 'Query';
  itemSet?: {
    __typename?: 'ItemSet';
    uomTypes: Array<{ __typename?: 'ItemUomType'; uom: string }>;
  } | null;
};

export type CreateLayoutImportMutationVariables = Exact<{
  warehouseId: Scalars['ID']['input'];
  input: CreateLayoutImportInput;
}>;

export type CreateLayoutImportMutation = {
  __typename?: 'Mutation';
  createLayoutImport: { __typename?: 'LayoutImport'; id: string };
};

export type UpdateLayoutImportMutationVariables = Exact<{
  layoutImportInput: UpdateLayoutImportInput;
}>;

export type UpdateLayoutImportMutation = {
  __typename?: 'Mutation';
  updateLayoutImport: {
    __typename?: 'LayoutImport';
    id: string;
    description?: string | null;
  };
};

export type RemoveLayoutImportMutationVariables = Exact<{
  layoutImportId: Scalars['ID']['input'];
}>;

export type RemoveLayoutImportMutation = {
  __typename?: 'Mutation';
  removeLayoutImport: boolean;
};

export type LayoutImportMetaFragment = {
  __typename?: 'LayoutImport';
  id: string;
  title: string;
  description?: string | null;
  createdAt: number;
  updatedAt: number;
};

export type LoadLayoutImportSummaryQueryVariables = Exact<{
  layoutImportId: Scalars['ID']['input'];
}>;

export type LoadLayoutImportSummaryQuery = {
  __typename?: 'Query';
  layoutImport?: {
    __typename?: 'LayoutImport';
    id: string;
    title: string;
    description?: string | null;
    createdAt: number;
    updatedAt: number;
    summary?: {
      __typename?: 'LayoutImportSummary';
      locationCount: number;
      disabledLocationCount: number;
      aisleCount: number;
      bayCount: number;
      areas: Array<{
        __typename?: 'LayoutImportAreaSummary';
        areaId: string;
        title: string;
        locationCount: number;
      }>;
    } | null;
  } | null;
};

export type LayoutImportConverterLocationFragment = {
  __typename?: 'LayoutImportLocation';
  locationKey: string;
  locationId: string;
  locationOrder: string;
  locationStatus: boolean;
  locationLevel: number;
  locationLength: number;
  locationWidth: number;
  locationHeight: number;
  locationWeight: number;
  locationAisle: string;
  locationBayId: string;
  locationBay: number;
  locationBayPosition: number;
  locationDepthPosition: number;
  locationUsageType: string;
  locmhtype: string;
  locationRackingType: string;
  bayType?: string | null;
  warehouseArea: string;
  baySide: string;
  portalSpecs?: Array<{
    __typename?: 'LocationPortalSpec';
    aisleId: string;
    type?: LocationPortalType | null;
  }> | null;
};

export type LayoutImportConverterBayFragment = {
  __typename?: 'LayoutImportBay';
  bayId: string;
  bay: number;
  bayType?: string | null;
  depth: number;
  locationCount: number;
  locations: Array<{
    __typename?: 'LayoutImportLocation';
    locationKey: string;
    locationId: string;
    locationOrder: string;
    locationStatus: boolean;
    locationLevel: number;
    locationLength: number;
    locationWidth: number;
    locationHeight: number;
    locationWeight: number;
    locationAisle: string;
    locationBayId: string;
    locationBay: number;
    locationBayPosition: number;
    locationDepthPosition: number;
    locationUsageType: string;
    locmhtype: string;
    locationRackingType: string;
    bayType?: string | null;
    warehouseArea: string;
    baySide: string;
    portalSpecs?: Array<{
      __typename?: 'LocationPortalSpec';
      aisleId: string;
      type?: LocationPortalType | null;
    }> | null;
  }>;
  levels: Array<{
    __typename?: 'LayoutImportLevelSummary';
    level: number;
    locationCount: number;
    usedWidth: number;
    height: number;
  }>;
};

export type LayoutImportConverterSideFragment = {
  __typename?: 'LayoutImportSide';
  side: AisleSide;
  bays: Array<{
    __typename?: 'LayoutImportBay';
    bayId: string;
    bay: number;
    bayType?: string | null;
    depth: number;
    locationCount: number;
    locations: Array<{
      __typename?: 'LayoutImportLocation';
      locationKey: string;
      locationId: string;
      locationOrder: string;
      locationStatus: boolean;
      locationLevel: number;
      locationLength: number;
      locationWidth: number;
      locationHeight: number;
      locationWeight: number;
      locationAisle: string;
      locationBayId: string;
      locationBay: number;
      locationBayPosition: number;
      locationDepthPosition: number;
      locationUsageType: string;
      locmhtype: string;
      locationRackingType: string;
      bayType?: string | null;
      warehouseArea: string;
      baySide: string;
      portalSpecs?: Array<{
        __typename?: 'LocationPortalSpec';
        aisleId: string;
        type?: LocationPortalType | null;
      }> | null;
    }>;
    levels: Array<{
      __typename?: 'LayoutImportLevelSummary';
      level: number;
      locationCount: number;
      usedWidth: number;
      height: number;
    }>;
  }>;
};

export type LayoutImportConverterAisleFragment = {
  __typename?: 'LayoutImportAisle';
  aisleId: string;
  aisle: string;
  sides: Array<{
    __typename?: 'LayoutImportSide';
    side: AisleSide;
    bays: Array<{
      __typename?: 'LayoutImportBay';
      bayId: string;
      bay: number;
      bayType?: string | null;
      depth: number;
      locationCount: number;
      locations: Array<{
        __typename?: 'LayoutImportLocation';
        locationKey: string;
        locationId: string;
        locationOrder: string;
        locationStatus: boolean;
        locationLevel: number;
        locationLength: number;
        locationWidth: number;
        locationHeight: number;
        locationWeight: number;
        locationAisle: string;
        locationBayId: string;
        locationBay: number;
        locationBayPosition: number;
        locationDepthPosition: number;
        locationUsageType: string;
        locmhtype: string;
        locationRackingType: string;
        bayType?: string | null;
        warehouseArea: string;
        baySide: string;
        portalSpecs?: Array<{
          __typename?: 'LocationPortalSpec';
          aisleId: string;
          type?: LocationPortalType | null;
        }> | null;
      }>;
      levels: Array<{
        __typename?: 'LayoutImportLevelSummary';
        level: number;
        locationCount: number;
        usedWidth: number;
        height: number;
      }>;
    }>;
  }>;
};

export type LayoutImportConverterAreaFragment = {
  __typename?: 'LayoutImportArea';
  area: string;
  locationCount: number;
  aisles: Array<{
    __typename?: 'LayoutImportAisle';
    aisleId: string;
    aisle: string;
    sides: Array<{
      __typename?: 'LayoutImportSide';
      side: AisleSide;
      bays: Array<{
        __typename?: 'LayoutImportBay';
        bayId: string;
        bay: number;
        bayType?: string | null;
        depth: number;
        locationCount: number;
        locations: Array<{
          __typename?: 'LayoutImportLocation';
          locationKey: string;
          locationId: string;
          locationOrder: string;
          locationStatus: boolean;
          locationLevel: number;
          locationLength: number;
          locationWidth: number;
          locationHeight: number;
          locationWeight: number;
          locationAisle: string;
          locationBayId: string;
          locationBay: number;
          locationBayPosition: number;
          locationDepthPosition: number;
          locationUsageType: string;
          locmhtype: string;
          locationRackingType: string;
          bayType?: string | null;
          warehouseArea: string;
          baySide: string;
          portalSpecs?: Array<{
            __typename?: 'LocationPortalSpec';
            aisleId: string;
            type?: LocationPortalType | null;
          }> | null;
        }>;
        levels: Array<{
          __typename?: 'LayoutImportLevelSummary';
          level: number;
          locationCount: number;
          usedWidth: number;
          height: number;
        }>;
      }>;
    }>;
  }>;
};

export type LayoutImportConverterDataFragment = {
  __typename?: 'LayoutImport';
  id: string;
  title: string;
  description?: string | null;
  status: LayoutImportStatus;
  settings?: any | null;
  createdAt: number;
  updatedAt: number;
  inputFileName: string;
  warehouse: { __typename?: 'Warehouse'; title: string };
  locations: {
    __typename?: 'LayoutImportLocationsConnection';
    totalCount: number;
  };
  converterSummary?: {
    __typename?: 'LayoutImportSummary';
    locationCount: number;
    disabledLocationCount: number;
    aisleCount: number;
    bayCount: number;
    areas: Array<{
      __typename?: 'LayoutImportAreaSummary';
      areaId: string;
      title: string;
      locationCount: number;
    }>;
  } | null;
};

export type LoadLayoutImportConverterDataQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type LoadLayoutImportConverterDataQuery = {
  __typename?: 'Query';
  layoutImport?: {
    __typename?: 'LayoutImport';
    id: string;
    title: string;
    description?: string | null;
    status: LayoutImportStatus;
    settings?: any | null;
    createdAt: number;
    updatedAt: number;
    inputFileName: string;
    warehouse: { __typename?: 'Warehouse'; title: string };
    locations: {
      __typename?: 'LayoutImportLocationsConnection';
      totalCount: number;
    };
    converterSummary?: {
      __typename?: 'LayoutImportSummary';
      locationCount: number;
      disabledLocationCount: number;
      aisleCount: number;
      bayCount: number;
      areas: Array<{
        __typename?: 'LayoutImportAreaSummary';
        areaId: string;
        title: string;
        locationCount: number;
      }>;
    } | null;
  } | null;
};

export type LayoutImportLocationSummaryFragment = {
  __typename?: 'LayoutImportLocation';
  locationId: string;
  locationLevel: number;
  locationWidth: number;
  locationLength: number;
  locationHeight: number;
  locationRackingType: string;
  locationBayPosition: number;
  locationDepthPosition: number;
};

export type LoadLayoutImportBayLocationsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  bayId: Scalars['ID']['input'];
}>;

export type LoadLayoutImportBayLocationsQuery = {
  __typename?: 'Query';
  layoutImport?: {
    __typename?: 'LayoutImport';
    locations: {
      __typename?: 'LayoutImportLocationsConnection';
      content: Array<{
        __typename?: 'LayoutImportLocation';
        locationId: string;
        locationLevel: number;
        locationWidth: number;
        locationLength: number;
        locationHeight: number;
        locationRackingType: string;
        locationBayPosition: number;
        locationDepthPosition: number;
      }>;
    };
  } | null;
};

export type LoadLayoutImportLocationsQueryVariables = Exact<{
  layoutImportId: Scalars['ID']['input'];
  page?: InputMaybe<SkipLimitPageSpec>;
}>;

export type LoadLayoutImportLocationsQuery = {
  __typename?: 'Query';
  layoutImport?: {
    __typename?: 'LayoutImport';
    locations: {
      __typename?: 'LayoutImportLocationsConnection';
      totalCount: number;
      content: Array<{
        __typename?: 'LayoutImportLocation';
        locationKey: string;
        locationId: string;
        locationOrder: string;
        locationStatus: boolean;
        locationLevel: number;
        locationLength: number;
        locationWidth: number;
        locationHeight: number;
        locationWeight: number;
        locationAisle: string;
        locationBayId: string;
        locationBay: number;
        locationBayPosition: number;
        locationDepthPosition: number;
        locationUsageType: string;
        locmhtype: string;
        locationRackingType: string;
        bayType?: string | null;
        warehouseArea: string;
        baySide: string;
        portalSpecs?: Array<{
          __typename?: 'LocationPortalSpec';
          aisleId: string;
          type?: LocationPortalType | null;
        }> | null;
      }>;
    };
  } | null;
};

export type SaveLayoutImportConverterStateMutationVariables = Exact<{
  input: SaveLayoutImportConverterStateInput;
}>;

export type SaveLayoutImportConverterStateMutation = {
  __typename?: 'Mutation';
  saveLayoutImportConverterState: { __typename?: 'LayoutImport'; id: string };
};

export type ConvertLayoutImportMutationVariables = Exact<{
  input: ConvertLayoutImportInput;
}>;

export type ConvertLayoutImportMutation = {
  __typename?: 'Mutation';
  convertLayoutImport: {
    __typename?: 'Layout';
    id: string;
    status: LayoutStatus;
  };
};

export type LoadBalancingConstraintFragment = {
  __typename?: 'LoadBalancingConstraint';
  maxRelativeDeviation: number;
};

export type LoadBalancingRuleFragment = {
  __typename?: 'LoadBalancingRule';
  id?: string | null;
  title?: string | null;
  type: LoadBalancingZoneType;
  objectiveWeight?: number | null;
  constraint?: {
    __typename?: 'LoadBalancingConstraint';
    maxRelativeDeviation: number;
  } | null;
  include?: {
    __typename?: 'LocationFilterUnion';
    anyOf: Array<{
      __typename?: 'LocationFilterIntersection';
      id?: string | null;
      allOf: Array<{
        __typename?: 'LocationFilter';
        type: LocationFilterType;
        isNull?: boolean | null;
        isNot?: boolean | null;
        valueIn?: Array<{ __typename?: 'FilterValue'; title: string }> | null;
        range?: {
          __typename?: 'NumberFilterRange';
          from?: number | null;
          to?: number | null;
        } | null;
        stringRange?: {
          __typename?: 'StringFilterRange';
          from?: string | null;
          excludeFrom?: boolean | null;
          to?: string | null;
          excludeTo?: boolean | null;
        } | null;
      }>;
    }>;
  } | null;
};

export type LoadBalancingPolicyFragment = {
  __typename?: 'LoadBalancingPolicy';
  rules: Array<{
    __typename?: 'LoadBalancingRule';
    id?: string | null;
    title?: string | null;
    type: LoadBalancingZoneType;
    objectiveWeight?: number | null;
    constraint?: {
      __typename?: 'LoadBalancingConstraint';
      maxRelativeDeviation: number;
    } | null;
    include?: {
      __typename?: 'LocationFilterUnion';
      anyOf: Array<{
        __typename?: 'LocationFilterIntersection';
        id?: string | null;
        allOf: Array<{
          __typename?: 'LocationFilter';
          type: LocationFilterType;
          isNull?: boolean | null;
          isNot?: boolean | null;
          valueIn?: Array<{ __typename?: 'FilterValue'; title: string }> | null;
          range?: {
            __typename?: 'NumberFilterRange';
            from?: number | null;
            to?: number | null;
          } | null;
          stringRange?: {
            __typename?: 'StringFilterRange';
            from?: string | null;
            excludeFrom?: boolean | null;
            to?: string | null;
            excludeTo?: boolean | null;
          } | null;
        }>;
      }>;
    } | null;
  }>;
};

export type LoadBalancingZoneSummaryFragment = {
  __typename?: 'LoadBalancingZoneSummary';
  zoneId: string;
  zoneLoad: number;
  locationCount: number;
};

export type LoadBalancingZoneSummaryWithLocationsFragment = {
  __typename?: 'LoadBalancingZoneSummary';
  locationIds: Array<string>;
  zoneId: string;
  zoneLoad: number;
  locationCount: number;
};

export type LoadBalancingRuleSummaryBaseFragment = {
  __typename?: 'LoadBalancingRuleSummary';
  totalLoad: number;
  meanLoad: number;
  variance: number;
  stdDev: number;
  maxRelativeDeviation: number;
  constraintSatisfied?: boolean | null;
  locationCount: number;
};

export type LoadBalancingRuleSummaryFragment = {
  __typename?: 'LoadBalancingRuleSummary';
  totalLoad: number;
  meanLoad: number;
  variance: number;
  stdDev: number;
  maxRelativeDeviation: number;
  constraintSatisfied?: boolean | null;
  locationCount: number;
  zones: Array<{
    __typename?: 'LoadBalancingZoneSummary';
    zoneId: string;
    zoneLoad: number;
    locationCount: number;
  }>;
};

export type LoadBalancingRuleSummaryWithLocationsFragment = {
  __typename?: 'LoadBalancingRuleSummary';
  totalLoad: number;
  meanLoad: number;
  variance: number;
  stdDev: number;
  maxRelativeDeviation: number;
  constraintSatisfied?: boolean | null;
  locationCount: number;
  zones: Array<{
    __typename?: 'LoadBalancingZoneSummary';
    locationIds: Array<string>;
    zoneId: string;
    zoneLoad: number;
    locationCount: number;
  }>;
};

export type LoadBalancingRuleLocationsQueryVariables = Exact<{
  analyzeId: Scalars['ID']['input'];
  rule: LoadBalancingRuleInput;
  layoutLocationFilter?: InputMaybe<LayoutLocationFilter>;
}>;

export type LoadBalancingRuleLocationsQuery = {
  __typename?: 'Query';
  analyzeResult?: {
    __typename?: 'AnalyzeResult';
    loadBalancingRuleSummary: {
      __typename?: 'LoadBalancingRuleSummary';
      totalLoad: number;
      meanLoad: number;
      variance: number;
      stdDev: number;
      maxRelativeDeviation: number;
      constraintSatisfied?: boolean | null;
      locationCount: number;
      zones: Array<{
        __typename?: 'LoadBalancingZoneSummary';
        locationIds: Array<string>;
        zoneId: string;
        zoneLoad: number;
        locationCount: number;
      }>;
    };
  } | null;
};

export type LoadBalancingRuleSummaryQueryVariables = Exact<{
  analyzeId: Scalars['ID']['input'];
  rule: LoadBalancingRuleInput;
}>;

export type LoadBalancingRuleSummaryQuery = {
  __typename?: 'Query';
  analyzeResult?: {
    __typename?: 'AnalyzeResult';
    loadBalancingRuleSummary: {
      __typename?: 'LoadBalancingRuleSummary';
      totalLoad: number;
      meanLoad: number;
      variance: number;
      stdDev: number;
      maxRelativeDeviation: number;
      constraintSatisfied?: boolean | null;
      locationCount: number;
      zones: Array<{
        __typename?: 'LoadBalancingZoneSummary';
        zoneId: string;
        zoneLoad: number;
        locationCount: number;
      }>;
    };
  } | null;
};

export type LocationSharingPolicyRuleFragment = {
  __typename?: 'LocationSharingPolicyRule';
  id: string;
  title?: string | null;
  maxItemsPerLocation: number;
  locationsMatch?: {
    __typename?: 'LocationFilterUnion';
    anyOf: Array<{
      __typename?: 'LocationFilterIntersection';
      id?: string | null;
      allOf: Array<{
        __typename?: 'LocationFilter';
        type: LocationFilterType;
        isNull?: boolean | null;
        isNot?: boolean | null;
        valueIn?: Array<{ __typename?: 'FilterValue'; title: string }> | null;
        range?: {
          __typename?: 'NumberFilterRange';
          from?: number | null;
          to?: number | null;
        } | null;
        stringRange?: {
          __typename?: 'StringFilterRange';
          from?: string | null;
          excludeFrom?: boolean | null;
          to?: string | null;
          excludeTo?: boolean | null;
        } | null;
      }>;
    }>;
  } | null;
};

export type LocationSharingPolicyFragment = {
  __typename?: 'LocationSharingPolicy';
  defaultMaxItemsPerLocation?: number | null;
  rules?: Array<{
    __typename?: 'LocationSharingPolicyRule';
    id: string;
    title?: string | null;
    maxItemsPerLocation: number;
    locationsMatch?: {
      __typename?: 'LocationFilterUnion';
      anyOf: Array<{
        __typename?: 'LocationFilterIntersection';
        id?: string | null;
        allOf: Array<{
          __typename?: 'LocationFilter';
          type: LocationFilterType;
          isNull?: boolean | null;
          isNot?: boolean | null;
          valueIn?: Array<{ __typename?: 'FilterValue'; title: string }> | null;
          range?: {
            __typename?: 'NumberFilterRange';
            from?: number | null;
            to?: number | null;
          } | null;
          stringRange?: {
            __typename?: 'StringFilterRange';
            from?: string | null;
            excludeFrom?: boolean | null;
            to?: string | null;
            excludeTo?: boolean | null;
          } | null;
        }>;
      }>;
    } | null;
  }> | null;
};

export type CreateOptimizationRunMutationVariables = Exact<{
  input: CreateOptimizationRunInput;
}>;

export type CreateOptimizationRunMutation = {
  __typename?: 'Mutation';
  createOptimizationRun: {
    __typename?: 'OptimizationRun';
    id: string;
    progress?: number | null;
    steps?: number | null;
    seed?: number | null;
    status: OptimizationRunStatus;
    startedAt?: number | null;
    completedAt?: number | null;
    elapsedRunningTime: number;
    initialCost?: number | null;
    reassignedCost?: number | null;
    optimizedCost?: number | null;
    reassignImprovement?: number | null;
    optimizeImprovement?: number | null;
    improvement?: number | null;
    progressDetails: Array<{
      __typename?: 'OptimizationRunProgress';
      step: number;
      startedAt: number;
      completedAt: number;
      progress: number;
      currentCost: number;
      elapsedComputeTime: number;
      remainingComputeTime?: number | null;
      currentMoveCount?: number | null;
      currentOptimizeImprovement?: number | null;
      currentTotalImprovement?: number | null;
      details?: {
        __typename?: 'OptimizationRunProgressDetails';
        improvement?: {
          __typename?: 'OptimizationImprovementSummary';
          effectiveImprovement: number;
          initialEffectiveCost: number;
          optimizedEffectiveCost: number;
          objectives: Array<{
            __typename?: 'OptimizationObjectiveImprovementSummary';
            id?: string | null;
            type: OptimizationObjectiveType;
            initialCost: number;
            objectiveWeight: number;
            improvement: number;
          }>;
        } | null;
      } | null;
    }>;
    analyzeResult?: {
      __typename?: 'AnalyzeResult';
      picklistPickByDates: Array<string | null>;
      maxLocationHitCount?: number | null;
      id: string;
      status: AnalyzeResultStatus;
      stage?: AnalyzeResultStage | null;
      progress?: number | null;
      totalOrders: number;
      totalAssembledOrders: number;
      totalAssembledPartiallyOrders: number;
      totalLines: number;
      assembledLines: number;
      assembledPartiallyLines: number;
      ignoredLines: number;
      totalDistance: number;
      totalCost: number;
      totalDuration: number;
      maxReorderTriggeredSummary?: {
        __typename?: 'AnalyzeResultReorderSummary';
        count: number;
        totalDuration: number;
        totalCost: number;
        totalReorderedWeight: number;
      } | null;
      maxReorderAppliedSummary?: {
        __typename?: 'AnalyzeResultReorderSummary';
        count: number;
        totalDuration: number;
        totalCost: number;
        totalReorderedWeight: number;
      } | null;
      costDetails?: {
        __typename?: 'AnalyzeResultCostDetails';
        processes: Array<{
          __typename?: 'AnalyzeResultProcessCost';
          processType: AnalyzeResultProcessType;
          distance: number;
          duration: number;
          cost: number;
        }>;
      } | null;
      summary?: {
        __typename?: 'AnalyzeResultSummary';
        eventTypes: Array<{
          __typename?: 'AnalyzeResultEventTypeSummary';
          eventType: PicklistEventType;
          processType?: AnalyzeResultProcessType | null;
          count: number;
          totalDuration: number;
          totalCost: number;
        }>;
        agents: Array<{
          __typename?: 'AnalyzeResultAgentSummary';
          agent: string;
          eventTypes: Array<{
            __typename?: 'AnalyzeResultEventTypeSummary';
            eventType: PicklistEventType;
            processType?: AnalyzeResultProcessType | null;
            count: number;
            totalDuration: number;
            totalCost: number;
          }>;
        }>;
        assignment?: {
          __typename?: 'AnalyzeResultAssignmentSummary';
          totalStoredItems?: number | null;
        } | null;
      } | null;
      resourcePolicy?: {
        __typename?: 'ResourcePolicy';
        agents: Array<{
          __typename?: 'AgentSettings';
          id: string;
          specs?: {
            __typename?: 'AgentSpecs';
            powerSource?: {
              __typename?: 'AgentPowerSourceSettings';
              energyConsumptionPerSecond?: number | null;
            } | null;
          } | null;
        }>;
      } | null;
    } | null;
    optimizationSummary?: {
      __typename?: 'OptimizationSummary';
      stopReason?: OptimizationStopReason | null;
      effectiveSteps?: number | null;
      totalComputeTime?: number | null;
      moveCount?: number | null;
      improvement?: {
        __typename?: 'OptimizationImprovementSummary';
        effectiveImprovement: number;
        initialEffectiveCost: number;
        optimizedEffectiveCost: number;
        objectives: Array<{
          __typename?: 'OptimizationObjectiveImprovementSummary';
          id?: string | null;
          type: OptimizationObjectiveType;
          initialCost: number;
          objectiveWeight: number;
          improvement: number;
        }>;
      } | null;
    } | null;
    resultAssignment?: { __typename?: 'Assignment'; id: string } | null;
    optimizationSettings?: {
      __typename?: 'OptimizationRunSettings';
      source?: {
        __typename?: 'OptimizationRunSourceReference';
        type: OptimizationRunSourceType;
        id: string;
      } | null;
    } | null;
  };
};

export type StopOptimizationRunMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type StopOptimizationRunMutation = {
  __typename?: 'Mutation';
  stopOptimizationRun: {
    __typename?: 'OptimizationRun';
    id: string;
    progress?: number | null;
    steps?: number | null;
    seed?: number | null;
    status: OptimizationRunStatus;
    startedAt?: number | null;
    completedAt?: number | null;
    elapsedRunningTime: number;
    initialCost?: number | null;
    reassignedCost?: number | null;
    optimizedCost?: number | null;
    reassignImprovement?: number | null;
    optimizeImprovement?: number | null;
    improvement?: number | null;
    progressDetails: Array<{
      __typename?: 'OptimizationRunProgress';
      step: number;
      startedAt: number;
      completedAt: number;
      progress: number;
      currentCost: number;
      elapsedComputeTime: number;
      remainingComputeTime?: number | null;
      currentMoveCount?: number | null;
      currentOptimizeImprovement?: number | null;
      currentTotalImprovement?: number | null;
      details?: {
        __typename?: 'OptimizationRunProgressDetails';
        improvement?: {
          __typename?: 'OptimizationImprovementSummary';
          effectiveImprovement: number;
          initialEffectiveCost: number;
          optimizedEffectiveCost: number;
          objectives: Array<{
            __typename?: 'OptimizationObjectiveImprovementSummary';
            id?: string | null;
            type: OptimizationObjectiveType;
            initialCost: number;
            objectiveWeight: number;
            improvement: number;
          }>;
        } | null;
      } | null;
    }>;
    analyzeResult?: {
      __typename?: 'AnalyzeResult';
      picklistPickByDates: Array<string | null>;
      maxLocationHitCount?: number | null;
      id: string;
      status: AnalyzeResultStatus;
      stage?: AnalyzeResultStage | null;
      progress?: number | null;
      totalOrders: number;
      totalAssembledOrders: number;
      totalAssembledPartiallyOrders: number;
      totalLines: number;
      assembledLines: number;
      assembledPartiallyLines: number;
      ignoredLines: number;
      totalDistance: number;
      totalCost: number;
      totalDuration: number;
      maxReorderTriggeredSummary?: {
        __typename?: 'AnalyzeResultReorderSummary';
        count: number;
        totalDuration: number;
        totalCost: number;
        totalReorderedWeight: number;
      } | null;
      maxReorderAppliedSummary?: {
        __typename?: 'AnalyzeResultReorderSummary';
        count: number;
        totalDuration: number;
        totalCost: number;
        totalReorderedWeight: number;
      } | null;
      costDetails?: {
        __typename?: 'AnalyzeResultCostDetails';
        processes: Array<{
          __typename?: 'AnalyzeResultProcessCost';
          processType: AnalyzeResultProcessType;
          distance: number;
          duration: number;
          cost: number;
        }>;
      } | null;
      summary?: {
        __typename?: 'AnalyzeResultSummary';
        eventTypes: Array<{
          __typename?: 'AnalyzeResultEventTypeSummary';
          eventType: PicklistEventType;
          processType?: AnalyzeResultProcessType | null;
          count: number;
          totalDuration: number;
          totalCost: number;
        }>;
        agents: Array<{
          __typename?: 'AnalyzeResultAgentSummary';
          agent: string;
          eventTypes: Array<{
            __typename?: 'AnalyzeResultEventTypeSummary';
            eventType: PicklistEventType;
            processType?: AnalyzeResultProcessType | null;
            count: number;
            totalDuration: number;
            totalCost: number;
          }>;
        }>;
        assignment?: {
          __typename?: 'AnalyzeResultAssignmentSummary';
          totalStoredItems?: number | null;
        } | null;
      } | null;
      resourcePolicy?: {
        __typename?: 'ResourcePolicy';
        agents: Array<{
          __typename?: 'AgentSettings';
          id: string;
          specs?: {
            __typename?: 'AgentSpecs';
            powerSource?: {
              __typename?: 'AgentPowerSourceSettings';
              energyConsumptionPerSecond?: number | null;
            } | null;
          } | null;
        }>;
      } | null;
    } | null;
    optimizationSummary?: {
      __typename?: 'OptimizationSummary';
      stopReason?: OptimizationStopReason | null;
      effectiveSteps?: number | null;
      totalComputeTime?: number | null;
      moveCount?: number | null;
      improvement?: {
        __typename?: 'OptimizationImprovementSummary';
        effectiveImprovement: number;
        initialEffectiveCost: number;
        optimizedEffectiveCost: number;
        objectives: Array<{
          __typename?: 'OptimizationObjectiveImprovementSummary';
          id?: string | null;
          type: OptimizationObjectiveType;
          initialCost: number;
          objectiveWeight: number;
          improvement: number;
        }>;
      } | null;
    } | null;
    resultAssignment?: { __typename?: 'Assignment'; id: string } | null;
    optimizationSettings?: {
      __typename?: 'OptimizationRunSettings';
      source?: {
        __typename?: 'OptimizationRunSourceReference';
        type: OptimizationRunSourceType;
        id: string;
      } | null;
    } | null;
  };
};

export type OptimizationRunStatusFragment = {
  __typename?: 'OptimizationRun';
  id: string;
  progress?: number | null;
  steps?: number | null;
  status: OptimizationRunStatus;
};

export type LoadOptimizationRunStatusQueryVariables = Exact<{
  optimisationRunId: Scalars['ID']['input'];
}>;

export type LoadOptimizationRunStatusQuery = {
  __typename?: 'Query';
  optimizationRun?: {
    __typename?: 'OptimizationRun';
    id: string;
    progress?: number | null;
    steps?: number | null;
    status: OptimizationRunStatus;
  } | null;
};

export type OptimizationRunProgressFragment = {
  __typename?: 'OptimizationRunProgress';
  step: number;
  startedAt: number;
  completedAt: number;
  progress: number;
  currentCost: number;
  elapsedComputeTime: number;
  remainingComputeTime?: number | null;
  currentMoveCount?: number | null;
  currentOptimizeImprovement?: number | null;
  currentTotalImprovement?: number | null;
  details?: {
    __typename?: 'OptimizationRunProgressDetails';
    improvement?: {
      __typename?: 'OptimizationImprovementSummary';
      effectiveImprovement: number;
      initialEffectiveCost: number;
      optimizedEffectiveCost: number;
      objectives: Array<{
        __typename?: 'OptimizationObjectiveImprovementSummary';
        id?: string | null;
        type: OptimizationObjectiveType;
        initialCost: number;
        objectiveWeight: number;
        improvement: number;
      }>;
    } | null;
  } | null;
};

export type OptimizationImprovementSummaryFragment = {
  __typename?: 'OptimizationImprovementSummary';
  effectiveImprovement: number;
  initialEffectiveCost: number;
  optimizedEffectiveCost: number;
  objectives: Array<{
    __typename?: 'OptimizationObjectiveImprovementSummary';
    id?: string | null;
    type: OptimizationObjectiveType;
    initialCost: number;
    objectiveWeight: number;
    improvement: number;
  }>;
};

export type OptimizationRunBaseWithoutAnalyzeFragment = {
  __typename?: 'OptimizationRun';
  id: string;
  progress?: number | null;
  steps?: number | null;
  seed?: number | null;
  status: OptimizationRunStatus;
  startedAt?: number | null;
  completedAt?: number | null;
  elapsedRunningTime: number;
  initialCost?: number | null;
  reassignedCost?: number | null;
  optimizedCost?: number | null;
  reassignImprovement?: number | null;
  optimizeImprovement?: number | null;
  improvement?: number | null;
  optimizationSummary?: {
    __typename?: 'OptimizationSummary';
    stopReason?: OptimizationStopReason | null;
    effectiveSteps?: number | null;
    totalComputeTime?: number | null;
    moveCount?: number | null;
    improvement?: {
      __typename?: 'OptimizationImprovementSummary';
      effectiveImprovement: number;
      initialEffectiveCost: number;
      optimizedEffectiveCost: number;
      objectives: Array<{
        __typename?: 'OptimizationObjectiveImprovementSummary';
        id?: string | null;
        type: OptimizationObjectiveType;
        initialCost: number;
        objectiveWeight: number;
        improvement: number;
      }>;
    } | null;
  } | null;
  resultAssignment?: { __typename?: 'Assignment'; id: string } | null;
  optimizationSettings?: {
    __typename?: 'OptimizationRunSettings';
    source?: {
      __typename?: 'OptimizationRunSourceReference';
      type: OptimizationRunSourceType;
      id: string;
    } | null;
  } | null;
};

export type OptimizationRunBaseFragment = {
  __typename?: 'OptimizationRun';
  id: string;
  progress?: number | null;
  steps?: number | null;
  seed?: number | null;
  status: OptimizationRunStatus;
  startedAt?: number | null;
  completedAt?: number | null;
  elapsedRunningTime: number;
  initialCost?: number | null;
  reassignedCost?: number | null;
  optimizedCost?: number | null;
  reassignImprovement?: number | null;
  optimizeImprovement?: number | null;
  improvement?: number | null;
  analyzeResult?: {
    __typename?: 'AnalyzeResult';
    picklistPickByDates: Array<string | null>;
    maxLocationHitCount?: number | null;
    id: string;
    status: AnalyzeResultStatus;
    stage?: AnalyzeResultStage | null;
    progress?: number | null;
    totalOrders: number;
    totalAssembledOrders: number;
    totalAssembledPartiallyOrders: number;
    totalLines: number;
    assembledLines: number;
    assembledPartiallyLines: number;
    ignoredLines: number;
    totalDistance: number;
    totalCost: number;
    totalDuration: number;
    maxReorderTriggeredSummary?: {
      __typename?: 'AnalyzeResultReorderSummary';
      count: number;
      totalDuration: number;
      totalCost: number;
      totalReorderedWeight: number;
    } | null;
    maxReorderAppliedSummary?: {
      __typename?: 'AnalyzeResultReorderSummary';
      count: number;
      totalDuration: number;
      totalCost: number;
      totalReorderedWeight: number;
    } | null;
    costDetails?: {
      __typename?: 'AnalyzeResultCostDetails';
      processes: Array<{
        __typename?: 'AnalyzeResultProcessCost';
        processType: AnalyzeResultProcessType;
        distance: number;
        duration: number;
        cost: number;
      }>;
    } | null;
    summary?: {
      __typename?: 'AnalyzeResultSummary';
      eventTypes: Array<{
        __typename?: 'AnalyzeResultEventTypeSummary';
        eventType: PicklistEventType;
        processType?: AnalyzeResultProcessType | null;
        count: number;
        totalDuration: number;
        totalCost: number;
      }>;
      agents: Array<{
        __typename?: 'AnalyzeResultAgentSummary';
        agent: string;
        eventTypes: Array<{
          __typename?: 'AnalyzeResultEventTypeSummary';
          eventType: PicklistEventType;
          processType?: AnalyzeResultProcessType | null;
          count: number;
          totalDuration: number;
          totalCost: number;
        }>;
      }>;
      assignment?: {
        __typename?: 'AnalyzeResultAssignmentSummary';
        totalStoredItems?: number | null;
      } | null;
    } | null;
    resourcePolicy?: {
      __typename?: 'ResourcePolicy';
      agents: Array<{
        __typename?: 'AgentSettings';
        id: string;
        specs?: {
          __typename?: 'AgentSpecs';
          powerSource?: {
            __typename?: 'AgentPowerSourceSettings';
            energyConsumptionPerSecond?: number | null;
          } | null;
        } | null;
      }>;
    } | null;
  } | null;
  optimizationSummary?: {
    __typename?: 'OptimizationSummary';
    stopReason?: OptimizationStopReason | null;
    effectiveSteps?: number | null;
    totalComputeTime?: number | null;
    moveCount?: number | null;
    improvement?: {
      __typename?: 'OptimizationImprovementSummary';
      effectiveImprovement: number;
      initialEffectiveCost: number;
      optimizedEffectiveCost: number;
      objectives: Array<{
        __typename?: 'OptimizationObjectiveImprovementSummary';
        id?: string | null;
        type: OptimizationObjectiveType;
        initialCost: number;
        objectiveWeight: number;
        improvement: number;
      }>;
    } | null;
  } | null;
  resultAssignment?: { __typename?: 'Assignment'; id: string } | null;
  optimizationSettings?: {
    __typename?: 'OptimizationRunSettings';
    source?: {
      __typename?: 'OptimizationRunSourceReference';
      type: OptimizationRunSourceType;
      id: string;
    } | null;
  } | null;
};

export type AssignmentChangeItemMoveDetailsFragment = {
  __typename?: 'AssignmentChangeSequenceMove';
  moveId: string;
  groupId: string;
  chainNumber: number;
  sequenceNumber: number;
  itemId: string;
  consignee: string;
  sku: string;
  srcLocationId?: string | null;
  destLocationId?: string | null;
  destAction: AssignmentChangeSequenceAction;
};

export type OptimizationRunForDashboardFragment = {
  __typename?: 'OptimizationRun';
  id: string;
  progress?: number | null;
  steps?: number | null;
  seed?: number | null;
  status: OptimizationRunStatus;
  startedAt?: number | null;
  completedAt?: number | null;
  elapsedRunningTime: number;
  initialCost?: number | null;
  reassignedCost?: number | null;
  optimizedCost?: number | null;
  reassignImprovement?: number | null;
  optimizeImprovement?: number | null;
  improvement?: number | null;
  analyzeResult?: {
    __typename?: 'AnalyzeResult';
    id: string;
    status: AnalyzeResultStatus;
    stage?: AnalyzeResultStage | null;
    progress?: number | null;
    totalOrders: number;
    totalAssembledOrders: number;
    totalAssembledPartiallyOrders: number;
    totalLines: number;
    assembledLines: number;
    assembledPartiallyLines: number;
    ignoredLines: number;
    totalDistance: number;
    totalCost: number;
    totalDuration: number;
    costDetails?: {
      __typename?: 'AnalyzeResultCostDetails';
      processes: Array<{
        __typename?: 'AnalyzeResultProcessCost';
        processType: AnalyzeResultProcessType;
        distance: number;
        duration: number;
        cost: number;
      }>;
    } | null;
    summary?: {
      __typename?: 'AnalyzeResultSummary';
      eventTypes: Array<{
        __typename?: 'AnalyzeResultEventTypeSummary';
        eventType: PicklistEventType;
        processType?: AnalyzeResultProcessType | null;
        count: number;
        totalDuration: number;
        totalCost: number;
      }>;
      agents: Array<{
        __typename?: 'AnalyzeResultAgentSummary';
        agent: string;
        eventTypes: Array<{
          __typename?: 'AnalyzeResultEventTypeSummary';
          eventType: PicklistEventType;
          processType?: AnalyzeResultProcessType | null;
          count: number;
          totalDuration: number;
          totalCost: number;
        }>;
      }>;
      assignment?: {
        __typename?: 'AnalyzeResultAssignmentSummary';
        totalStoredItems?: number | null;
      } | null;
    } | null;
    resourcePolicy?: {
      __typename?: 'ResourcePolicy';
      agents: Array<{
        __typename?: 'AgentSettings';
        id: string;
        specs?: {
          __typename?: 'AgentSpecs';
          powerSource?: {
            __typename?: 'AgentPowerSourceSettings';
            energyConsumptionPerSecond?: number | null;
          } | null;
        } | null;
      }>;
    } | null;
  } | null;
  latestProgressDetails?: {
    __typename?: 'OptimizationRunProgress';
    step: number;
    startedAt: number;
    completedAt: number;
    progress: number;
    currentCost: number;
    elapsedComputeTime: number;
    remainingComputeTime?: number | null;
    currentMoveCount?: number | null;
    currentOptimizeImprovement?: number | null;
    currentTotalImprovement?: number | null;
    details?: {
      __typename?: 'OptimizationRunProgressDetails';
      improvement?: {
        __typename?: 'OptimizationImprovementSummary';
        effectiveImprovement: number;
        initialEffectiveCost: number;
        optimizedEffectiveCost: number;
        objectives: Array<{
          __typename?: 'OptimizationObjectiveImprovementSummary';
          id?: string | null;
          type: OptimizationObjectiveType;
          initialCost: number;
          objectiveWeight: number;
          improvement: number;
        }>;
      } | null;
    } | null;
  } | null;
  optimizationSummary?: {
    __typename?: 'OptimizationSummary';
    stopReason?: OptimizationStopReason | null;
    effectiveSteps?: number | null;
    totalComputeTime?: number | null;
    moveCount?: number | null;
    improvement?: {
      __typename?: 'OptimizationImprovementSummary';
      effectiveImprovement: number;
      initialEffectiveCost: number;
      optimizedEffectiveCost: number;
      objectives: Array<{
        __typename?: 'OptimizationObjectiveImprovementSummary';
        id?: string | null;
        type: OptimizationObjectiveType;
        initialCost: number;
        objectiveWeight: number;
        improvement: number;
      }>;
    } | null;
  } | null;
  resultAssignment?: { __typename?: 'Assignment'; id: string } | null;
  optimizationSettings?: {
    __typename?: 'OptimizationRunSettings';
    source?: {
      __typename?: 'OptimizationRunSourceReference';
      type: OptimizationRunSourceType;
      id: string;
    } | null;
  } | null;
};

export type OptimizationRunWithLastStepFragment = {
  __typename?: 'OptimizationRun';
  id: string;
  progress?: number | null;
  steps?: number | null;
  seed?: number | null;
  status: OptimizationRunStatus;
  startedAt?: number | null;
  completedAt?: number | null;
  elapsedRunningTime: number;
  initialCost?: number | null;
  reassignedCost?: number | null;
  optimizedCost?: number | null;
  reassignImprovement?: number | null;
  optimizeImprovement?: number | null;
  improvement?: number | null;
  latestProgressDetails?: {
    __typename?: 'OptimizationRunProgress';
    step: number;
    startedAt: number;
    completedAt: number;
    progress: number;
    currentCost: number;
    elapsedComputeTime: number;
    remainingComputeTime?: number | null;
    currentMoveCount?: number | null;
    currentOptimizeImprovement?: number | null;
    currentTotalImprovement?: number | null;
    details?: {
      __typename?: 'OptimizationRunProgressDetails';
      improvement?: {
        __typename?: 'OptimizationImprovementSummary';
        effectiveImprovement: number;
        initialEffectiveCost: number;
        optimizedEffectiveCost: number;
        objectives: Array<{
          __typename?: 'OptimizationObjectiveImprovementSummary';
          id?: string | null;
          type: OptimizationObjectiveType;
          initialCost: number;
          objectiveWeight: number;
          improvement: number;
        }>;
      } | null;
    } | null;
  } | null;
  analyzeResult?: {
    __typename?: 'AnalyzeResult';
    picklistPickByDates: Array<string | null>;
    maxLocationHitCount?: number | null;
    id: string;
    status: AnalyzeResultStatus;
    stage?: AnalyzeResultStage | null;
    progress?: number | null;
    totalOrders: number;
    totalAssembledOrders: number;
    totalAssembledPartiallyOrders: number;
    totalLines: number;
    assembledLines: number;
    assembledPartiallyLines: number;
    ignoredLines: number;
    totalDistance: number;
    totalCost: number;
    totalDuration: number;
    maxReorderTriggeredSummary?: {
      __typename?: 'AnalyzeResultReorderSummary';
      count: number;
      totalDuration: number;
      totalCost: number;
      totalReorderedWeight: number;
    } | null;
    maxReorderAppliedSummary?: {
      __typename?: 'AnalyzeResultReorderSummary';
      count: number;
      totalDuration: number;
      totalCost: number;
      totalReorderedWeight: number;
    } | null;
    costDetails?: {
      __typename?: 'AnalyzeResultCostDetails';
      processes: Array<{
        __typename?: 'AnalyzeResultProcessCost';
        processType: AnalyzeResultProcessType;
        distance: number;
        duration: number;
        cost: number;
      }>;
    } | null;
    summary?: {
      __typename?: 'AnalyzeResultSummary';
      eventTypes: Array<{
        __typename?: 'AnalyzeResultEventTypeSummary';
        eventType: PicklistEventType;
        processType?: AnalyzeResultProcessType | null;
        count: number;
        totalDuration: number;
        totalCost: number;
      }>;
      agents: Array<{
        __typename?: 'AnalyzeResultAgentSummary';
        agent: string;
        eventTypes: Array<{
          __typename?: 'AnalyzeResultEventTypeSummary';
          eventType: PicklistEventType;
          processType?: AnalyzeResultProcessType | null;
          count: number;
          totalDuration: number;
          totalCost: number;
        }>;
      }>;
      assignment?: {
        __typename?: 'AnalyzeResultAssignmentSummary';
        totalStoredItems?: number | null;
      } | null;
    } | null;
    resourcePolicy?: {
      __typename?: 'ResourcePolicy';
      agents: Array<{
        __typename?: 'AgentSettings';
        id: string;
        specs?: {
          __typename?: 'AgentSpecs';
          powerSource?: {
            __typename?: 'AgentPowerSourceSettings';
            energyConsumptionPerSecond?: number | null;
          } | null;
        } | null;
      }>;
    } | null;
  } | null;
  optimizationSummary?: {
    __typename?: 'OptimizationSummary';
    stopReason?: OptimizationStopReason | null;
    effectiveSteps?: number | null;
    totalComputeTime?: number | null;
    moveCount?: number | null;
    improvement?: {
      __typename?: 'OptimizationImprovementSummary';
      effectiveImprovement: number;
      initialEffectiveCost: number;
      optimizedEffectiveCost: number;
      objectives: Array<{
        __typename?: 'OptimizationObjectiveImprovementSummary';
        id?: string | null;
        type: OptimizationObjectiveType;
        initialCost: number;
        objectiveWeight: number;
        improvement: number;
      }>;
    } | null;
  } | null;
  resultAssignment?: { __typename?: 'Assignment'; id: string } | null;
  optimizationSettings?: {
    __typename?: 'OptimizationRunSettings';
    source?: {
      __typename?: 'OptimizationRunSourceReference';
      type: OptimizationRunSourceType;
      id: string;
    } | null;
  } | null;
};

export type OptimizationRunFragment = {
  __typename?: 'OptimizationRun';
  id: string;
  progress?: number | null;
  steps?: number | null;
  seed?: number | null;
  status: OptimizationRunStatus;
  startedAt?: number | null;
  completedAt?: number | null;
  elapsedRunningTime: number;
  initialCost?: number | null;
  reassignedCost?: number | null;
  optimizedCost?: number | null;
  reassignImprovement?: number | null;
  optimizeImprovement?: number | null;
  improvement?: number | null;
  progressDetails: Array<{
    __typename?: 'OptimizationRunProgress';
    step: number;
    startedAt: number;
    completedAt: number;
    progress: number;
    currentCost: number;
    elapsedComputeTime: number;
    remainingComputeTime?: number | null;
    currentMoveCount?: number | null;
    currentOptimizeImprovement?: number | null;
    currentTotalImprovement?: number | null;
    details?: {
      __typename?: 'OptimizationRunProgressDetails';
      improvement?: {
        __typename?: 'OptimizationImprovementSummary';
        effectiveImprovement: number;
        initialEffectiveCost: number;
        optimizedEffectiveCost: number;
        objectives: Array<{
          __typename?: 'OptimizationObjectiveImprovementSummary';
          id?: string | null;
          type: OptimizationObjectiveType;
          initialCost: number;
          objectiveWeight: number;
          improvement: number;
        }>;
      } | null;
    } | null;
  }>;
  analyzeResult?: {
    __typename?: 'AnalyzeResult';
    picklistPickByDates: Array<string | null>;
    maxLocationHitCount?: number | null;
    id: string;
    status: AnalyzeResultStatus;
    stage?: AnalyzeResultStage | null;
    progress?: number | null;
    totalOrders: number;
    totalAssembledOrders: number;
    totalAssembledPartiallyOrders: number;
    totalLines: number;
    assembledLines: number;
    assembledPartiallyLines: number;
    ignoredLines: number;
    totalDistance: number;
    totalCost: number;
    totalDuration: number;
    maxReorderTriggeredSummary?: {
      __typename?: 'AnalyzeResultReorderSummary';
      count: number;
      totalDuration: number;
      totalCost: number;
      totalReorderedWeight: number;
    } | null;
    maxReorderAppliedSummary?: {
      __typename?: 'AnalyzeResultReorderSummary';
      count: number;
      totalDuration: number;
      totalCost: number;
      totalReorderedWeight: number;
    } | null;
    costDetails?: {
      __typename?: 'AnalyzeResultCostDetails';
      processes: Array<{
        __typename?: 'AnalyzeResultProcessCost';
        processType: AnalyzeResultProcessType;
        distance: number;
        duration: number;
        cost: number;
      }>;
    } | null;
    summary?: {
      __typename?: 'AnalyzeResultSummary';
      eventTypes: Array<{
        __typename?: 'AnalyzeResultEventTypeSummary';
        eventType: PicklistEventType;
        processType?: AnalyzeResultProcessType | null;
        count: number;
        totalDuration: number;
        totalCost: number;
      }>;
      agents: Array<{
        __typename?: 'AnalyzeResultAgentSummary';
        agent: string;
        eventTypes: Array<{
          __typename?: 'AnalyzeResultEventTypeSummary';
          eventType: PicklistEventType;
          processType?: AnalyzeResultProcessType | null;
          count: number;
          totalDuration: number;
          totalCost: number;
        }>;
      }>;
      assignment?: {
        __typename?: 'AnalyzeResultAssignmentSummary';
        totalStoredItems?: number | null;
      } | null;
    } | null;
    resourcePolicy?: {
      __typename?: 'ResourcePolicy';
      agents: Array<{
        __typename?: 'AgentSettings';
        id: string;
        specs?: {
          __typename?: 'AgentSpecs';
          powerSource?: {
            __typename?: 'AgentPowerSourceSettings';
            energyConsumptionPerSecond?: number | null;
          } | null;
        } | null;
      }>;
    } | null;
  } | null;
  optimizationSummary?: {
    __typename?: 'OptimizationSummary';
    stopReason?: OptimizationStopReason | null;
    effectiveSteps?: number | null;
    totalComputeTime?: number | null;
    moveCount?: number | null;
    improvement?: {
      __typename?: 'OptimizationImprovementSummary';
      effectiveImprovement: number;
      initialEffectiveCost: number;
      optimizedEffectiveCost: number;
      objectives: Array<{
        __typename?: 'OptimizationObjectiveImprovementSummary';
        id?: string | null;
        type: OptimizationObjectiveType;
        initialCost: number;
        objectiveWeight: number;
        improvement: number;
      }>;
    } | null;
  } | null;
  resultAssignment?: { __typename?: 'Assignment'; id: string } | null;
  optimizationSettings?: {
    __typename?: 'OptimizationRunSettings';
    source?: {
      __typename?: 'OptimizationRunSourceReference';
      type: OptimizationRunSourceType;
      id: string;
    } | null;
  } | null;
};

export type LoadOptimizationAnalyzeQueryVariables = Exact<{
  optimisationRunId: Scalars['ID']['input'];
}>;

export type LoadOptimizationAnalyzeQuery = {
  __typename?: 'Query';
  optimizationRun?: {
    __typename?: 'OptimizationRun';
    id: string;
    progress?: number | null;
    steps?: number | null;
    seed?: number | null;
    status: OptimizationRunStatus;
    startedAt?: number | null;
    completedAt?: number | null;
    elapsedRunningTime: number;
    initialCost?: number | null;
    reassignedCost?: number | null;
    optimizedCost?: number | null;
    reassignImprovement?: number | null;
    optimizeImprovement?: number | null;
    improvement?: number | null;
    progressDetails: Array<{
      __typename?: 'OptimizationRunProgress';
      step: number;
      startedAt: number;
      completedAt: number;
      progress: number;
      currentCost: number;
      elapsedComputeTime: number;
      remainingComputeTime?: number | null;
      currentMoveCount?: number | null;
      currentOptimizeImprovement?: number | null;
      currentTotalImprovement?: number | null;
      details?: {
        __typename?: 'OptimizationRunProgressDetails';
        improvement?: {
          __typename?: 'OptimizationImprovementSummary';
          effectiveImprovement: number;
          initialEffectiveCost: number;
          optimizedEffectiveCost: number;
          objectives: Array<{
            __typename?: 'OptimizationObjectiveImprovementSummary';
            id?: string | null;
            type: OptimizationObjectiveType;
            initialCost: number;
            objectiveWeight: number;
            improvement: number;
          }>;
        } | null;
      } | null;
    }>;
    analyzeResult?: {
      __typename?: 'AnalyzeResult';
      picklistPickByDates: Array<string | null>;
      maxLocationHitCount?: number | null;
      id: string;
      status: AnalyzeResultStatus;
      stage?: AnalyzeResultStage | null;
      progress?: number | null;
      totalOrders: number;
      totalAssembledOrders: number;
      totalAssembledPartiallyOrders: number;
      totalLines: number;
      assembledLines: number;
      assembledPartiallyLines: number;
      ignoredLines: number;
      totalDistance: number;
      totalCost: number;
      totalDuration: number;
      maxReorderTriggeredSummary?: {
        __typename?: 'AnalyzeResultReorderSummary';
        count: number;
        totalDuration: number;
        totalCost: number;
        totalReorderedWeight: number;
      } | null;
      maxReorderAppliedSummary?: {
        __typename?: 'AnalyzeResultReorderSummary';
        count: number;
        totalDuration: number;
        totalCost: number;
        totalReorderedWeight: number;
      } | null;
      costDetails?: {
        __typename?: 'AnalyzeResultCostDetails';
        processes: Array<{
          __typename?: 'AnalyzeResultProcessCost';
          processType: AnalyzeResultProcessType;
          distance: number;
          duration: number;
          cost: number;
        }>;
      } | null;
      summary?: {
        __typename?: 'AnalyzeResultSummary';
        eventTypes: Array<{
          __typename?: 'AnalyzeResultEventTypeSummary';
          eventType: PicklistEventType;
          processType?: AnalyzeResultProcessType | null;
          count: number;
          totalDuration: number;
          totalCost: number;
        }>;
        agents: Array<{
          __typename?: 'AnalyzeResultAgentSummary';
          agent: string;
          eventTypes: Array<{
            __typename?: 'AnalyzeResultEventTypeSummary';
            eventType: PicklistEventType;
            processType?: AnalyzeResultProcessType | null;
            count: number;
            totalDuration: number;
            totalCost: number;
          }>;
        }>;
        assignment?: {
          __typename?: 'AnalyzeResultAssignmentSummary';
          totalStoredItems?: number | null;
        } | null;
      } | null;
      resourcePolicy?: {
        __typename?: 'ResourcePolicy';
        agents: Array<{
          __typename?: 'AgentSettings';
          id: string;
          specs?: {
            __typename?: 'AgentSpecs';
            powerSource?: {
              __typename?: 'AgentPowerSourceSettings';
              energyConsumptionPerSecond?: number | null;
            } | null;
          } | null;
        }>;
      } | null;
    } | null;
    optimizationSummary?: {
      __typename?: 'OptimizationSummary';
      stopReason?: OptimizationStopReason | null;
      effectiveSteps?: number | null;
      totalComputeTime?: number | null;
      moveCount?: number | null;
      improvement?: {
        __typename?: 'OptimizationImprovementSummary';
        effectiveImprovement: number;
        initialEffectiveCost: number;
        optimizedEffectiveCost: number;
        objectives: Array<{
          __typename?: 'OptimizationObjectiveImprovementSummary';
          id?: string | null;
          type: OptimizationObjectiveType;
          initialCost: number;
          objectiveWeight: number;
          improvement: number;
        }>;
      } | null;
    } | null;
    resultAssignment?: { __typename?: 'Assignment'; id: string } | null;
    optimizationSettings?: {
      __typename?: 'OptimizationRunSettings';
      source?: {
        __typename?: 'OptimizationRunSourceReference';
        type: OptimizationRunSourceType;
        id: string;
      } | null;
    } | null;
  } | null;
};

export type AssignmentDiffItemFragment = {
  __typename?: 'AssignmentDiffItem';
  product: { __typename?: 'ProductId'; consignee: string; sku: string };
  srcLocations: Array<{ __typename?: 'LocationReference'; locationId: string }>;
  destLocations: Array<{
    __typename?: 'LocationReference';
    locationId: string;
  }>;
  productDetails?: {
    __typename?: 'OrderSetItem';
    orderLineCount: number;
    cumulativePercentRank?: number | null;
  } | null;
};

export type AssignmentDiffItemFullFragment = {
  __typename?: 'AssignmentDiffItem';
  product: { __typename?: 'ProductId'; consignee: string; sku: string };
  srcLocations: Array<{
    __typename?: 'LocationReference';
    locationId: string;
    location?: {
      __typename?: 'LayoutLocation';
      locationRackingType: string;
      locationBayPosition: number;
      locationLevel: number;
      locationWidth: number;
      locationLength: number;
      locationHeight: number;
      warehouseArea: string;
      locationHeightFromFloor: number;
      locationDepthPosition: number;
      locationDepthFromFront: number;
      locationIndexFromFront: number;
      locationUsageType: string;
      congestionZone?: string | null;
      locationSide?: string | null;
      locationId: string;
      locationStatus: boolean;
      locationBayId: string;
      locationOrder: string;
      shape?: GeoJSON.Polygon | null;
      portals?: Array<{
        __typename?: 'LocationPortal';
        type?: LocationPortalType | null;
        aisleId: string;
        position: { __typename?: 'Point'; x: number; y: number };
      }> | null;
      plane?: {
        __typename?: 'LayoutPlane';
        id: string;
        title?: string | null;
      } | null;
      bay?: {
        __typename?: 'LayoutFeature';
        id: string;
        title?: string | null;
      } | null;
      aisle?: {
        __typename?: 'LayoutFeature';
        id: string;
        title?: string | null;
      } | null;
    } | null;
  }>;
  destLocations: Array<{
    __typename?: 'LocationReference';
    locationId: string;
    location?: {
      __typename?: 'LayoutLocation';
      locationRackingType: string;
      locationBayPosition: number;
      locationLevel: number;
      locationWidth: number;
      locationLength: number;
      locationHeight: number;
      warehouseArea: string;
      locationHeightFromFloor: number;
      locationDepthPosition: number;
      locationDepthFromFront: number;
      locationIndexFromFront: number;
      locationUsageType: string;
      congestionZone?: string | null;
      locationSide?: string | null;
      locationId: string;
      locationStatus: boolean;
      locationBayId: string;
      locationOrder: string;
      shape?: GeoJSON.Polygon | null;
      portals?: Array<{
        __typename?: 'LocationPortal';
        type?: LocationPortalType | null;
        aisleId: string;
        position: { __typename?: 'Point'; x: number; y: number };
      }> | null;
      plane?: {
        __typename?: 'LayoutPlane';
        id: string;
        title?: string | null;
      } | null;
      bay?: {
        __typename?: 'LayoutFeature';
        id: string;
        title?: string | null;
      } | null;
      aisle?: {
        __typename?: 'LayoutFeature';
        id: string;
        title?: string | null;
      } | null;
    } | null;
  }>;
  productDetails?: {
    __typename?: 'OrderSetItem';
    orderLineCount: number;
    cumulativePercentRank?: number | null;
  } | null;
};

export type LoadOptimizationDiffQueryVariables = Exact<{
  optimisationRunId: Scalars['ID']['input'];
  filter?: InputMaybe<AssignmentDiffItemsFilter>;
  sortBy?: InputMaybe<
    Array<AssignmentDiffItemsSortBy> | AssignmentDiffItemsSortBy
  >;
  page?: InputMaybe<SkipLimitPageSpec>;
}>;

export type LoadOptimizationDiffQuery = {
  __typename?: 'Query';
  optimizationRun?: {
    __typename?: 'OptimizationRun';
    assignmentDiff?: {
      __typename?: 'AssignmentDiff';
      items: {
        __typename?: 'AssignmentDiffItemsConnection';
        totalCount: number;
        content: Array<{
          __typename?: 'AssignmentDiffItem';
          product: { __typename?: 'ProductId'; consignee: string; sku: string };
          srcLocations: Array<{
            __typename?: 'LocationReference';
            locationId: string;
          }>;
          destLocations: Array<{
            __typename?: 'LocationReference';
            locationId: string;
          }>;
          productDetails?: {
            __typename?: 'OrderSetItem';
            orderLineCount: number;
            cumulativePercentRank?: number | null;
          } | null;
        }>;
      };
    } | null;
  } | null;
};

export type LoadOptimizationDiffFullQueryVariables = Exact<{
  optimisationRunId: Scalars['ID']['input'];
  filter?: InputMaybe<AssignmentDiffItemsFilter>;
  sortBy?: InputMaybe<
    Array<AssignmentDiffItemsSortBy> | AssignmentDiffItemsSortBy
  >;
  page?: InputMaybe<SkipLimitPageSpec>;
}>;

export type LoadOptimizationDiffFullQuery = {
  __typename?: 'Query';
  optimizationRun?: {
    __typename?: 'OptimizationRun';
    assignmentDiff?: {
      __typename?: 'AssignmentDiff';
      items: {
        __typename?: 'AssignmentDiffItemsConnection';
        totalCount: number;
        content: Array<{
          __typename?: 'AssignmentDiffItem';
          product: { __typename?: 'ProductId'; consignee: string; sku: string };
          srcLocations: Array<{
            __typename?: 'LocationReference';
            locationId: string;
            location?: {
              __typename?: 'LayoutLocation';
              locationRackingType: string;
              locationBayPosition: number;
              locationLevel: number;
              locationWidth: number;
              locationLength: number;
              locationHeight: number;
              warehouseArea: string;
              locationHeightFromFloor: number;
              locationDepthPosition: number;
              locationDepthFromFront: number;
              locationIndexFromFront: number;
              locationUsageType: string;
              congestionZone?: string | null;
              locationSide?: string | null;
              locationId: string;
              locationStatus: boolean;
              locationBayId: string;
              locationOrder: string;
              shape?: GeoJSON.Polygon | null;
              portals?: Array<{
                __typename?: 'LocationPortal';
                type?: LocationPortalType | null;
                aisleId: string;
                position: { __typename?: 'Point'; x: number; y: number };
              }> | null;
              plane?: {
                __typename?: 'LayoutPlane';
                id: string;
                title?: string | null;
              } | null;
              bay?: {
                __typename?: 'LayoutFeature';
                id: string;
                title?: string | null;
              } | null;
              aisle?: {
                __typename?: 'LayoutFeature';
                id: string;
                title?: string | null;
              } | null;
            } | null;
          }>;
          destLocations: Array<{
            __typename?: 'LocationReference';
            locationId: string;
            location?: {
              __typename?: 'LayoutLocation';
              locationRackingType: string;
              locationBayPosition: number;
              locationLevel: number;
              locationWidth: number;
              locationLength: number;
              locationHeight: number;
              warehouseArea: string;
              locationHeightFromFloor: number;
              locationDepthPosition: number;
              locationDepthFromFront: number;
              locationIndexFromFront: number;
              locationUsageType: string;
              congestionZone?: string | null;
              locationSide?: string | null;
              locationId: string;
              locationStatus: boolean;
              locationBayId: string;
              locationOrder: string;
              shape?: GeoJSON.Polygon | null;
              portals?: Array<{
                __typename?: 'LocationPortal';
                type?: LocationPortalType | null;
                aisleId: string;
                position: { __typename?: 'Point'; x: number; y: number };
              }> | null;
              plane?: {
                __typename?: 'LayoutPlane';
                id: string;
                title?: string | null;
              } | null;
              bay?: {
                __typename?: 'LayoutFeature';
                id: string;
                title?: string | null;
              } | null;
              aisle?: {
                __typename?: 'LayoutFeature';
                id: string;
                title?: string | null;
              } | null;
            } | null;
          }>;
          productDetails?: {
            __typename?: 'OrderSetItem';
            orderLineCount: number;
            cumulativePercentRank?: number | null;
          } | null;
        }>;
      };
    } | null;
  } | null;
};

export type LoadOptimizationMovesQueryVariables = Exact<{
  optimisationRunId: Scalars['ID']['input'];
}>;

export type LoadOptimizationMovesQuery = {
  __typename?: 'Query';
  optimizationRun?: {
    __typename?: 'OptimizationRun';
    assignmentChangeSequence?: {
      __typename?: 'AssignmentChangeSequence';
      moves: Array<{
        __typename?: 'AssignmentChangeSequenceMove';
        moveId: string;
        groupId: string;
        chainNumber: number;
        sequenceNumber: number;
        itemId: string;
        consignee: string;
        sku: string;
        srcLocationId?: string | null;
        destLocationId?: string | null;
        destAction: AssignmentChangeSequenceAction;
      }>;
    } | null;
  } | null;
};

export type TerminationSettingsFragment = {
  __typename?: 'TerminationSettings';
  targetImprovement?: number | null;
  timeLimit?: number | null;
  minImprovementRate?: {
    __typename?: 'MinImprovementRateSettings';
    minImprovementChange: number;
    steps: number;
  } | null;
};

export type LocationSizeConstraintSettingsFragment = {
  __typename?: 'LocationSizeConstraintSettings';
  disabled?: boolean | null;
};

export type BayWidthConstraintSettingsFragment = {
  __typename?: 'BayWidthConstraintSettings';
  disabled?: boolean | null;
};

export type LimitSettingsFragment = {
  __typename?: 'LimitSettings';
  maxMoves?: number | null;
};

export type OptimizationSettingsFragment = {
  __typename?: 'OptimizationSettings';
  locationSizeConstraint?: {
    __typename?: 'LocationSizeConstraintSettings';
    disabled?: boolean | null;
  } | null;
  bayWidthConstraint?: {
    __typename?: 'BayWidthConstraintSettings';
    disabled?: boolean | null;
  } | null;
  terminationSettings?: {
    __typename?: 'TerminationSettings';
    targetImprovement?: number | null;
    timeLimit?: number | null;
    minImprovementRate?: {
      __typename?: 'MinImprovementRateSettings';
      minImprovementChange: number;
      steps: number;
    } | null;
  } | null;
  swapSettings?: {
    __typename?: 'SwapSettings';
    rules?: Array<{
      __typename?: 'OptimizationSwapRule';
      id?: string | null;
      title?: string | null;
      src?: {
        __typename?: 'AssignmentSubsetSpec';
        unassignedLocationsMatch?: UnassignedLocationsMatch | null;
        locationsMatch?: {
          __typename?: 'LocationFilterUnion';
          anyOf: Array<{
            __typename?: 'LocationFilterIntersection';
            id?: string | null;
            allOf: Array<{
              __typename?: 'LocationFilter';
              type: LocationFilterType;
              isNull?: boolean | null;
              isNot?: boolean | null;
              valueIn?: Array<{
                __typename?: 'FilterValue';
                title: string;
              }> | null;
              range?: {
                __typename?: 'NumberFilterRange';
                from?: number | null;
                to?: number | null;
              } | null;
              stringRange?: {
                __typename?: 'StringFilterRange';
                from?: string | null;
                excludeFrom?: boolean | null;
                to?: string | null;
                excludeTo?: boolean | null;
              } | null;
            }>;
          }>;
        } | null;
        itemsMatch?: {
          __typename?: 'SimulationItemFilterUnion';
          anyOf: Array<{
            __typename?: 'SimulationItemFilterIntersection';
            id?: string | null;
            allOf: Array<{
              __typename?: 'SimulationItemFilter';
              type: SimulationItemFilterType;
              isNull?: boolean | null;
              isNot?: boolean | null;
              valueIn?: Array<{
                __typename?: 'FilterValue';
                title: string;
              }> | null;
              range?: {
                __typename?: 'NumberFilterRange';
                from?: number | null;
                to?: number | null;
              } | null;
              stringRange?: {
                __typename?: 'StringFilterRange';
                from?: string | null;
                excludeFrom?: boolean | null;
                to?: string | null;
                excludeTo?: boolean | null;
              } | null;
            }>;
          }>;
        } | null;
      } | null;
      dest?: {
        __typename?: 'AssignmentSubsetSpec';
        unassignedLocationsMatch?: UnassignedLocationsMatch | null;
        locationsMatch?: {
          __typename?: 'LocationFilterUnion';
          anyOf: Array<{
            __typename?: 'LocationFilterIntersection';
            id?: string | null;
            allOf: Array<{
              __typename?: 'LocationFilter';
              type: LocationFilterType;
              isNull?: boolean | null;
              isNot?: boolean | null;
              valueIn?: Array<{
                __typename?: 'FilterValue';
                title: string;
              }> | null;
              range?: {
                __typename?: 'NumberFilterRange';
                from?: number | null;
                to?: number | null;
              } | null;
              stringRange?: {
                __typename?: 'StringFilterRange';
                from?: string | null;
                excludeFrom?: boolean | null;
                to?: string | null;
                excludeTo?: boolean | null;
              } | null;
            }>;
          }>;
        } | null;
        itemsMatch?: {
          __typename?: 'SimulationItemFilterUnion';
          anyOf: Array<{
            __typename?: 'SimulationItemFilterIntersection';
            id?: string | null;
            allOf: Array<{
              __typename?: 'SimulationItemFilter';
              type: SimulationItemFilterType;
              isNull?: boolean | null;
              isNot?: boolean | null;
              valueIn?: Array<{
                __typename?: 'FilterValue';
                title: string;
              }> | null;
              range?: {
                __typename?: 'NumberFilterRange';
                from?: number | null;
                to?: number | null;
              } | null;
              stringRange?: {
                __typename?: 'StringFilterRange';
                from?: string | null;
                excludeFrom?: boolean | null;
                to?: string | null;
                excludeTo?: boolean | null;
              } | null;
            }>;
          }>;
        } | null;
      } | null;
    }> | null;
  } | null;
  loadBalancing?: {
    __typename?: 'LoadBalancingPolicy';
    rules: Array<{
      __typename?: 'LoadBalancingRule';
      id?: string | null;
      title?: string | null;
      type: LoadBalancingZoneType;
      objectiveWeight?: number | null;
      constraint?: {
        __typename?: 'LoadBalancingConstraint';
        maxRelativeDeviation: number;
      } | null;
      include?: {
        __typename?: 'LocationFilterUnion';
        anyOf: Array<{
          __typename?: 'LocationFilterIntersection';
          id?: string | null;
          allOf: Array<{
            __typename?: 'LocationFilter';
            type: LocationFilterType;
            isNull?: boolean | null;
            isNot?: boolean | null;
            valueIn?: Array<{
              __typename?: 'FilterValue';
              title: string;
            }> | null;
            range?: {
              __typename?: 'NumberFilterRange';
              from?: number | null;
              to?: number | null;
            } | null;
            stringRange?: {
              __typename?: 'StringFilterRange';
              from?: string | null;
              excludeFrom?: boolean | null;
              to?: string | null;
              excludeTo?: boolean | null;
            } | null;
          }>;
        }>;
      } | null;
    }>;
  } | null;
  limitSettings?: {
    __typename?: 'LimitSettings';
    maxMoves?: number | null;
  } | null;
  reassignJobs?: {
    __typename?: 'ReassignJobsSettings';
    disabled?: boolean | null;
    cycleMode?: ReassignJobCycleMode | null;
  } | null;
  policyOverrides?: {
    __typename?: 'OptimizationPolicyOverrides';
    resourcePolicy?: {
      __typename?: 'ResourcePolicy';
      agents: Array<{
        __typename?: 'AgentSettings';
        id: string;
        title?: string | null;
        resourceTypeId: string;
        metadata?: any | null;
        roles?: Array<{
          __typename?: 'AgentRoleSettings';
          roleId: AgentRole;
          disabled?: boolean | null;
          preHandling?: {
            __typename?: 'PicklistProcessingEventSettings';
            timeFixed?: number | null;
            timePerOrder?: number | null;
            timePerPicklistLine?: number | null;
            timePerUom?: number | null;
          } | null;
          handlingPreparation?: {
            __typename?: 'PicklistLineProcessingEventSettings';
            timeFixed?: number | null;
            timePerUom?: number | null;
          } | null;
          handling?: {
            __typename?: 'HandlingEventSettings';
            timeFixed?: number | null;
            timePerWeight?: number | null;
            timePerVolume?: number | null;
            timePerUom?: Array<{
              __typename?: 'UomHandlingSettings';
              uom: string;
              timePerUom?: number | null;
            }> | null;
          } | null;
          reordering?: {
            __typename?: 'ReorderEventSettings';
            timeFixed?: number | null;
            timePerReorderedPosition?: number | null;
            timePerWeight?: number | null;
            timePerVolume?: number | null;
            timePerUom?: Array<{
              __typename?: 'UomHandlingSettings';
              uom: string;
              timePerUom?: number | null;
            }> | null;
          } | null;
          handlingCompletion?: {
            __typename?: 'PicklistLineProcessingEventSettings';
            timeFixed?: number | null;
            timePerUom?: number | null;
          } | null;
          postHandling?: {
            __typename?: 'PicklistProcessingEventSettings';
            timeFixed?: number | null;
            timePerOrder?: number | null;
            timePerPicklistLine?: number | null;
            timePerUom?: number | null;
          } | null;
        }> | null;
        specs?: {
          __typename?: 'AgentSpecs';
          horizontalTravelling?: {
            __typename?: 'HorizontalTravellingEventSettings';
            speed?: {
              __typename?: 'SpeedSettings';
              speedLaden?: number | null;
              speedUnladen?: number | null;
            } | null;
          } | null;
          verticalTravelling?: {
            __typename?: 'VerticalTravellingEventSettings';
            speedUp?: {
              __typename?: 'SpeedSettings';
              speedLaden?: number | null;
              speedUnladen?: number | null;
            } | null;
            speedDown?: {
              __typename?: 'SpeedSettings';
              speedLaden?: number | null;
              speedUnladen?: number | null;
            } | null;
          } | null;
          capacity?: {
            __typename?: 'AgentCapacitySettings';
            maxVolume?: number | null;
            maxWeight?: number | null;
            maxUomQuantity?: Array<{
              __typename?: 'AgentCapacityUomSettings';
              uom: string;
              quantity?: number | null;
            }> | null;
          } | null;
          powerSource?: {
            __typename?: 'AgentPowerSourceSettings';
            energyConsumptionPerSecond?: number | null;
          } | null;
        } | null;
        cost?: {
          __typename?: 'AgentCostSettings';
          costPerSecond?: number | null;
        } | null;
        utilisation?: {
          __typename?: 'AgentUtilisationSettings';
          agentAmount?: number | null;
          secondsOperation?: number | null;
        } | null;
      }>;
    } | null;
  } | null;
  pickabilityConstraint?: {
    __typename?: 'PickabilityConstraintSettings';
    disabled?: boolean | null;
  } | null;
  assignmentCapacitySettings?: {
    __typename?: 'AssignmentCapacitySettings';
    avoidMerge?: boolean | null;
  } | null;
};

export type OrderedQuantityReportSummaryFragment = {
  __typename?: 'OrderedQuantityReport';
  id: string;
  status: BatchJobStatus;
  progress: number;
  summary?: {
    __typename?: 'OrderedQuantityReportSummary';
    enoughCount: number;
    notEnoughCount: number;
    notOrderedCount: number;
  } | null;
};

export type ItemQuantityDetailsFragment = {
  __typename?: 'ItemQuantityDetails';
  breakdownByUom: Array<{
    __typename?: 'ItemUomQuantity';
    quantity: number;
    uom?: string | null;
    uomQuantity: number;
  }>;
  quantityPerUom: Array<{
    __typename?: 'ItemUomQuantity';
    quantity: number;
    uom?: string | null;
    uomQuantity: number;
  }>;
};

export type OrderedQuantityItemFragment = {
  __typename?: 'OrderedQuantityReportItem';
  itemId: string;
  consignee: string;
  sku: string;
  status: OrderedQuantityReportItemStatus;
  orderedQuantity: number;
  assignedCapacity: number;
  stockQuantity?: number | null;
  stockQuantityGap?: number | null;
  assignedCapacityGap: number;
  details?: {
    __typename?: 'OrderedQuantityReportItemDetails';
    orderedQuantityDetails?: {
      __typename?: 'ItemQuantityDetails';
      breakdownByUom: Array<{
        __typename?: 'ItemUomQuantity';
        quantity: number;
        uom?: string | null;
        uomQuantity: number;
      }>;
      quantityPerUom: Array<{
        __typename?: 'ItemUomQuantity';
        quantity: number;
        uom?: string | null;
        uomQuantity: number;
      }>;
    } | null;
    stockQuantityGapDetails?: {
      __typename?: 'ItemQuantityDetails';
      breakdownByUom: Array<{
        __typename?: 'ItemUomQuantity';
        quantity: number;
        uom?: string | null;
        uomQuantity: number;
      }>;
      quantityPerUom: Array<{
        __typename?: 'ItemUomQuantity';
        quantity: number;
        uom?: string | null;
        uomQuantity: number;
      }>;
    } | null;
    assignedCapacityGapDetails?: {
      __typename?: 'ItemQuantityDetails';
      breakdownByUom: Array<{
        __typename?: 'ItemUomQuantity';
        quantity: number;
        uom?: string | null;
        uomQuantity: number;
      }>;
      quantityPerUom: Array<{
        __typename?: 'ItemUomQuantity';
        quantity: number;
        uom?: string | null;
        uomQuantity: number;
      }>;
    } | null;
  } | null;
};

export type OrderedQuantityItemsFragment = {
  __typename?: 'OrderedQuantityReportItemsConnection';
  totalCount: number;
  content: Array<{
    __typename?: 'OrderedQuantityReportItem';
    itemId: string;
    consignee: string;
    sku: string;
    status: OrderedQuantityReportItemStatus;
    orderedQuantity: number;
    assignedCapacity: number;
    stockQuantity?: number | null;
    stockQuantityGap?: number | null;
    assignedCapacityGap: number;
    details?: {
      __typename?: 'OrderedQuantityReportItemDetails';
      orderedQuantityDetails?: {
        __typename?: 'ItemQuantityDetails';
        breakdownByUom: Array<{
          __typename?: 'ItemUomQuantity';
          quantity: number;
          uom?: string | null;
          uomQuantity: number;
        }>;
        quantityPerUom: Array<{
          __typename?: 'ItemUomQuantity';
          quantity: number;
          uom?: string | null;
          uomQuantity: number;
        }>;
      } | null;
      stockQuantityGapDetails?: {
        __typename?: 'ItemQuantityDetails';
        breakdownByUom: Array<{
          __typename?: 'ItemUomQuantity';
          quantity: number;
          uom?: string | null;
          uomQuantity: number;
        }>;
        quantityPerUom: Array<{
          __typename?: 'ItemUomQuantity';
          quantity: number;
          uom?: string | null;
          uomQuantity: number;
        }>;
      } | null;
      assignedCapacityGapDetails?: {
        __typename?: 'ItemQuantityDetails';
        breakdownByUom: Array<{
          __typename?: 'ItemUomQuantity';
          quantity: number;
          uom?: string | null;
          uomQuantity: number;
        }>;
        quantityPerUom: Array<{
          __typename?: 'ItemUomQuantity';
          quantity: number;
          uom?: string | null;
          uomQuantity: number;
        }>;
      } | null;
    } | null;
  }>;
};

export type LoadOrderedQuantitySummaryQueryVariables = Exact<{
  simulationId: Scalars['ID']['input'];
}>;

export type LoadOrderedQuantitySummaryQuery = {
  __typename?: 'Query';
  simulation?: {
    __typename?: 'Simulation';
    orderedQuantityReport?: {
      __typename?: 'OrderedQuantityReport';
      id: string;
      status: BatchJobStatus;
      progress: number;
      summary?: {
        __typename?: 'OrderedQuantityReportSummary';
        enoughCount: number;
        notEnoughCount: number;
        notOrderedCount: number;
      } | null;
    } | null;
  } | null;
};

export type LoadOrderedQuantityItemsQueryVariables = Exact<{
  simulationId: Scalars['ID']['input'];
  sortBy?: InputMaybe<
    Array<OrderedQuantityReportItemSortBy> | OrderedQuantityReportItemSortBy
  >;
  filter?: InputMaybe<OrderedQuantityReportItemFilter>;
  page?: InputMaybe<SkipLimitPageSpec>;
}>;

export type LoadOrderedQuantityItemsQuery = {
  __typename?: 'Query';
  simulation?: {
    __typename?: 'Simulation';
    orderedQuantityReport?: {
      __typename?: 'OrderedQuantityReport';
      id: string;
      status: BatchJobStatus;
      items: {
        __typename?: 'OrderedQuantityReportItemsConnection';
        totalCount: number;
        content: Array<{
          __typename?: 'OrderedQuantityReportItem';
          itemId: string;
          consignee: string;
          sku: string;
          status: OrderedQuantityReportItemStatus;
          orderedQuantity: number;
          assignedCapacity: number;
          stockQuantity?: number | null;
          stockQuantityGap?: number | null;
          assignedCapacityGap: number;
          details?: {
            __typename?: 'OrderedQuantityReportItemDetails';
            orderedQuantityDetails?: {
              __typename?: 'ItemQuantityDetails';
              breakdownByUom: Array<{
                __typename?: 'ItemUomQuantity';
                quantity: number;
                uom?: string | null;
                uomQuantity: number;
              }>;
              quantityPerUom: Array<{
                __typename?: 'ItemUomQuantity';
                quantity: number;
                uom?: string | null;
                uomQuantity: number;
              }>;
            } | null;
            stockQuantityGapDetails?: {
              __typename?: 'ItemQuantityDetails';
              breakdownByUom: Array<{
                __typename?: 'ItemUomQuantity';
                quantity: number;
                uom?: string | null;
                uomQuantity: number;
              }>;
              quantityPerUom: Array<{
                __typename?: 'ItemUomQuantity';
                quantity: number;
                uom?: string | null;
                uomQuantity: number;
              }>;
            } | null;
            assignedCapacityGapDetails?: {
              __typename?: 'ItemQuantityDetails';
              breakdownByUom: Array<{
                __typename?: 'ItemUomQuantity';
                quantity: number;
                uom?: string | null;
                uomQuantity: number;
              }>;
              quantityPerUom: Array<{
                __typename?: 'ItemUomQuantity';
                quantity: number;
                uom?: string | null;
                uomQuantity: number;
              }>;
            } | null;
          } | null;
        }>;
      };
    } | null;
  } | null;
};

export type RunGenerateQuantityReportMutationVariables = Exact<{
  simulationId: Scalars['String']['input'];
}>;

export type RunGenerateQuantityReportMutation = {
  __typename?: 'Mutation';
  generateSimulationOrderedQuantityReport: {
    __typename?: 'OrderedQuantityReport';
    id: string;
    status: BatchJobStatus;
    progress: number;
  };
};

export type CreateOrderSetMutationVariables = Exact<{
  input: CreateOrderSetInput;
}>;

export type CreateOrderSetMutation = {
  __typename?: 'Mutation';
  createOrderSet: { __typename?: 'OrderSet'; id: string };
};

export type UpdateOrderSetMutationVariables = Exact<{
  orderSetInput: UpdateOrderSetInput;
}>;

export type UpdateOrderSetMutation = {
  __typename?: 'Mutation';
  updateOrderSet: {
    __typename?: 'OrderSet';
    id: string;
    description?: string | null;
  };
};

export type RemoveOrderSetMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type RemoveOrderSetMutation = {
  __typename?: 'Mutation';
  removeOrderSet: boolean;
};

export type OrderSetMetaFragment = {
  __typename?: 'OrderSet';
  id: string;
  title: string;
  status: OrderSetImportStatus;
  description?: string | null;
  createdAt: number;
  updatedAt: number;
  inputFileName?: string | null;
  userTags?: Array<string> | null;
};

export type LoadAllOrderSetsMetaQueryVariables = Exact<{
  warehouseId: Scalars['ID']['input'];
}>;

export type LoadAllOrderSetsMetaQuery = {
  __typename?: 'Query';
  warehouse?: {
    __typename?: 'Warehouse';
    orderSets: {
      __typename?: 'OrderSetsConnection';
      content: Array<{
        __typename?: 'OrderSet';
        id: string;
        title: string;
        status: OrderSetImportStatus;
        description?: string | null;
        createdAt: number;
        updatedAt: number;
        inputFileName?: string | null;
        userTags?: Array<string> | null;
      }>;
    };
  } | null;
};

export type LoadOrderSetsSummaryQueryVariables = Exact<{
  orderSetId: Scalars['ID']['input'];
}>;

export type LoadOrderSetsSummaryQuery = {
  __typename?: 'Query';
  orderSet?: {
    __typename?: 'OrderSet';
    id: string;
    title: string;
    status: OrderSetImportStatus;
    description?: string | null;
    createdAt: number;
    updatedAt: number;
    inputFileName?: string | null;
    userTags?: Array<string> | null;
    summary?: {
      __typename?: 'OrderSetSummary';
      orderCount: number;
      productCount?: number | null;
      consignees: Array<{
        __typename?: 'OrderSetConsigneeSummary';
        consignee: string;
        orderCount: number;
        orderLineCount: number;
        productCount: number;
      }>;
      dateRange?: {
        __typename?: 'DatasetObjectDateRange';
        from?: string | null;
        to?: string | null;
      } | null;
    } | null;
  } | null;
};

export type OrderHeaderDataFragment = {
  __typename?: 'OrderHeader';
  id: string;
  consignee: string;
  customer?: string | null;
  orderId: string;
  orderDate?: string | null;
  deliveryRoute?: string | null;
  dock?: string | null;
  stagingArea?: string | null;
  waveId?: string | null;
  shipmentId?: string | null;
  loadByDate?: string | null;
};

export type OrderSetItemFragment = {
  __typename?: 'OrderSetItem';
  cumulativePercentRank?: number | null;
  details?: {
    __typename?: 'OrderSetItemDetails';
    dailyStats?: {
      __typename?: 'OrderSetItemDailyStats';
      totalDaysWithOrders: number;
      orderLineCount: {
        __typename?: 'ValueStats';
        max?: number | null;
        avg?: number | null;
        cv?: number | null;
      };
    } | null;
    weeklyStats?: {
      __typename?: 'OrderSetItemWeeklyStats';
      totalWeeksWithOrders: number;
      daysWithOrders: {
        __typename?: 'ValueStats';
        max?: number | null;
        avg?: number | null;
        cv?: number | null;
      };
      orderLineCount: {
        __typename?: 'ValueStats';
        max?: number | null;
        avg?: number | null;
        cv?: number | null;
      };
    } | null;
  } | null;
};

export type OrderLineDataFragment = {
  __typename?: 'OrderLine';
  orderId: string;
  orderLine: number;
  sku: string;
  quantity: number;
  pickingPriority?: string | null;
  pickingContainer?: string | null;
  orderHeader: {
    __typename?: 'OrderHeader';
    id: string;
    consignee: string;
    customer?: string | null;
    orderId: string;
    orderDate?: string | null;
    deliveryRoute?: string | null;
    dock?: string | null;
    stagingArea?: string | null;
    waveId?: string | null;
    shipmentId?: string | null;
    loadByDate?: string | null;
  };
  itemDetails?: {
    __typename?: 'OrderSetItem';
    cumulativePercentRank?: number | null;
    details?: {
      __typename?: 'OrderSetItemDetails';
      dailyStats?: {
        __typename?: 'OrderSetItemDailyStats';
        totalDaysWithOrders: number;
        orderLineCount: {
          __typename?: 'ValueStats';
          max?: number | null;
          avg?: number | null;
          cv?: number | null;
        };
      } | null;
      weeklyStats?: {
        __typename?: 'OrderSetItemWeeklyStats';
        totalWeeksWithOrders: number;
        daysWithOrders: {
          __typename?: 'ValueStats';
          max?: number | null;
          avg?: number | null;
          cv?: number | null;
        };
        orderLineCount: {
          __typename?: 'ValueStats';
          max?: number | null;
          avg?: number | null;
          cv?: number | null;
        };
      } | null;
    } | null;
  } | null;
};

export type LoadOrderSetDataQueryVariables = Exact<{
  orderSetId: Scalars['ID']['input'];
  page?: InputMaybe<SkipLimitPageSpec>;
  filter?: InputMaybe<OrderLineSearchFilter>;
  sortBy?: InputMaybe<Array<OrderLinesSortBy> | OrderLinesSortBy>;
}>;

export type LoadOrderSetDataQuery = {
  __typename?: 'Query';
  orderSet?: {
    __typename?: 'OrderSet';
    orderLines: {
      __typename?: 'OrderLinesConnection';
      totalCount: number;
      content: Array<{
        __typename?: 'OrderLine';
        orderId: string;
        orderLine: number;
        sku: string;
        quantity: number;
        pickingPriority?: string | null;
        pickingContainer?: string | null;
        orderHeader: {
          __typename?: 'OrderHeader';
          id: string;
          consignee: string;
          customer?: string | null;
          orderId: string;
          orderDate?: string | null;
          deliveryRoute?: string | null;
          dock?: string | null;
          stagingArea?: string | null;
          waveId?: string | null;
          shipmentId?: string | null;
          loadByDate?: string | null;
        };
        itemDetails?: {
          __typename?: 'OrderSetItem';
          cumulativePercentRank?: number | null;
          details?: {
            __typename?: 'OrderSetItemDetails';
            dailyStats?: {
              __typename?: 'OrderSetItemDailyStats';
              totalDaysWithOrders: number;
              orderLineCount: {
                __typename?: 'ValueStats';
                max?: number | null;
                avg?: number | null;
                cv?: number | null;
              };
            } | null;
            weeklyStats?: {
              __typename?: 'OrderSetItemWeeklyStats';
              totalWeeksWithOrders: number;
              daysWithOrders: {
                __typename?: 'ValueStats';
                max?: number | null;
                avg?: number | null;
                cv?: number | null;
              };
              orderLineCount: {
                __typename?: 'ValueStats';
                max?: number | null;
                avg?: number | null;
                cv?: number | null;
              };
            } | null;
          } | null;
        } | null;
      }>;
    };
  } | null;
};

export type OrderSetFragment = {
  __typename?: 'OrderSet';
  status: OrderSetImportStatus;
  id: string;
  title: string;
  description?: string | null;
  createdAt: number;
  updatedAt: number;
  inputFileName?: string | null;
  userTags?: Array<string> | null;
  summary?: {
    __typename?: 'OrderSetSummary';
    orderCount: number;
    productCount?: number | null;
    consignees: Array<{
      __typename?: 'OrderSetConsigneeSummary';
      consignee: string;
      orderCount: number;
      orderLineCount: number;
      productCount: number;
    }>;
    dateRange?: {
      __typename?: 'DatasetObjectDateRange';
      from?: string | null;
      to?: string | null;
    } | null;
  } | null;
  simulationsWith: {
    __typename?: 'SimulationsConnection';
    totalCount: number;
    content: Array<{
      __typename?: 'Simulation';
      id: string;
      title: string;
      description?: string | null;
      createdAt: number;
      updatedAt: number;
      userTags?: Array<string> | null;
    }>;
  };
};

export type LoadOrderSetQueryVariables = Exact<{
  orderSetId: Scalars['ID']['input'];
}>;

export type LoadOrderSetQuery = {
  __typename?: 'Query';
  orderSet?: {
    __typename?: 'OrderSet';
    status: OrderSetImportStatus;
    id: string;
    title: string;
    description?: string | null;
    createdAt: number;
    updatedAt: number;
    inputFileName?: string | null;
    userTags?: Array<string> | null;
    summary?: {
      __typename?: 'OrderSetSummary';
      orderCount: number;
      productCount?: number | null;
      consignees: Array<{
        __typename?: 'OrderSetConsigneeSummary';
        consignee: string;
        orderCount: number;
        orderLineCount: number;
        productCount: number;
      }>;
      dateRange?: {
        __typename?: 'DatasetObjectDateRange';
        from?: string | null;
        to?: string | null;
      } | null;
    } | null;
    simulationsWith: {
      __typename?: 'SimulationsConnection';
      totalCount: number;
      content: Array<{
        __typename?: 'Simulation';
        id: string;
        title: string;
        description?: string | null;
        createdAt: number;
        updatedAt: number;
        userTags?: Array<string> | null;
      }>;
    };
  } | null;
};

export type OrderLineFilterFragment = {
  __typename?: 'OrderLineFilter';
  type: OrderLineFilterType;
  isNull?: boolean | null;
  isNot?: boolean | null;
  valueIn?: Array<{ __typename?: 'FilterValue'; title: string }> | null;
  range?: {
    __typename?: 'NumberFilterRange';
    from?: number | null;
    to?: number | null;
  } | null;
  stringRange?: {
    __typename?: 'StringFilterRange';
    from?: string | null;
    excludeFrom?: boolean | null;
    to?: string | null;
    excludeTo?: boolean | null;
  } | null;
};

export type OrderLineFilterIntersectionFragment = {
  __typename?: 'OrderLineFilterIntersection';
  id?: string | null;
  allOf: Array<{
    __typename?: 'OrderLineFilter';
    type: OrderLineFilterType;
    isNull?: boolean | null;
    isNot?: boolean | null;
    valueIn?: Array<{ __typename?: 'FilterValue'; title: string }> | null;
    range?: {
      __typename?: 'NumberFilterRange';
      from?: number | null;
      to?: number | null;
    } | null;
    stringRange?: {
      __typename?: 'StringFilterRange';
      from?: string | null;
      excludeFrom?: boolean | null;
      to?: string | null;
      excludeTo?: boolean | null;
    } | null;
  }>;
};

export type OrderLineFilterUnionFragment = {
  __typename?: 'OrderLineFilterUnion';
  anyOf: Array<{
    __typename?: 'OrderLineFilterIntersection';
    id?: string | null;
    allOf: Array<{
      __typename?: 'OrderLineFilter';
      type: OrderLineFilterType;
      isNull?: boolean | null;
      isNot?: boolean | null;
      valueIn?: Array<{ __typename?: 'FilterValue'; title: string }> | null;
      range?: {
        __typename?: 'NumberFilterRange';
        from?: number | null;
        to?: number | null;
      } | null;
      stringRange?: {
        __typename?: 'StringFilterRange';
        from?: string | null;
        excludeFrom?: boolean | null;
        to?: string | null;
        excludeTo?: boolean | null;
      } | null;
    }>;
  }>;
};

export type PickingRequirementsBatchSettingsFragment = {
  __typename?: 'PickingRequirementsBatchSettings';
  maxOrderCount?: number | null;
  maxOrderLineCount?: number | null;
};

export type PickingRequirementsSettingsFragment = {
  __typename?: 'PickingPolicyPickingRequirementsSettings';
  groupBy: PickingRequirementsGroupBy;
  startLocationIdSource?: PickingRequirementTerminalLocationSource | null;
  endLocationIdSource?: PickingRequirementTerminalLocationSource | null;
  wave?: {
    __typename?: 'PickingWaveSettings';
    useDeliveryRoute?: boolean | null;
    useDock?: boolean | null;
    useStagingArea?: boolean | null;
    useWaveId?: boolean | null;
    pickByDateSettings?: {
      __typename?: 'PickingWavePickByDateSettings';
      mode: PickingWavePickByDateMode;
      fixedDeadlines?: Array<any> | null;
    } | null;
  } | null;
  batch?: {
    __typename?: 'PickingRequirementsBatchSettings';
    maxOrderCount?: number | null;
    maxOrderLineCount?: number | null;
  } | null;
};

export type PickingWavePickByDateSettingsFragment = {
  __typename?: 'PickingWavePickByDateSettings';
  mode: PickingWavePickByDateMode;
  fixedDeadlines?: Array<any> | null;
};

export type PickingWaveSettingsFragment = {
  __typename?: 'PickingWaveSettings';
  useDeliveryRoute?: boolean | null;
  useDock?: boolean | null;
  useStagingArea?: boolean | null;
  useWaveId?: boolean | null;
  pickByDateSettings?: {
    __typename?: 'PickingWavePickByDateSettings';
    mode: PickingWavePickByDateMode;
    fixedDeadlines?: Array<any> | null;
  } | null;
};

export type PicklistLinesSortByFragment = {
  __typename?: 'PicklistLinesSortBy';
  type: PicklistLinesOrderOptions;
  direction: PicklistLinesSortDirection;
  nullsFirst?: boolean | null;
};

export type PickingPolicyOrderLineRuleFragment = {
  __typename?: 'PickingPolicyOrderLineRule';
  id: string;
  title?: string | null;
  usePickingContainer?: boolean | null;
  pickingRequirementsSettings?: {
    __typename?: 'PickingPolicyPickingRequirementsSettings';
    groupBy: PickingRequirementsGroupBy;
    startLocationIdSource?: PickingRequirementTerminalLocationSource | null;
    endLocationIdSource?: PickingRequirementTerminalLocationSource | null;
    wave?: {
      __typename?: 'PickingWaveSettings';
      useDeliveryRoute?: boolean | null;
      useDock?: boolean | null;
      useStagingArea?: boolean | null;
      useWaveId?: boolean | null;
      pickByDateSettings?: {
        __typename?: 'PickingWavePickByDateSettings';
        mode: PickingWavePickByDateMode;
        fixedDeadlines?: Array<any> | null;
      } | null;
    } | null;
    batch?: {
      __typename?: 'PickingRequirementsBatchSettings';
      maxOrderCount?: number | null;
      maxOrderLineCount?: number | null;
    } | null;
  } | null;
  orderLinesMatch?: {
    __typename?: 'OrderLineFilterUnion';
    anyOf: Array<{
      __typename?: 'OrderLineFilterIntersection';
      id?: string | null;
      allOf: Array<{
        __typename?: 'OrderLineFilter';
        type: OrderLineFilterType;
        isNull?: boolean | null;
        isNot?: boolean | null;
        valueIn?: Array<{ __typename?: 'FilterValue'; title: string }> | null;
        range?: {
          __typename?: 'NumberFilterRange';
          from?: number | null;
          to?: number | null;
        } | null;
        stringRange?: {
          __typename?: 'StringFilterRange';
          from?: string | null;
          excludeFrom?: boolean | null;
          to?: string | null;
          excludeTo?: boolean | null;
        } | null;
      }>;
    }>;
  } | null;
  pickingRules?: Array<{
    __typename?: 'PickingPolicyPickingRule';
    id: string;
    title?: string | null;
    uomTypes?: Array<string> | null;
    agentId: string;
    locationSelectionMode?: PickingPolicyLocationSelectionMode | null;
    picklistGroupKey?: string | null;
    locationsMatch?: {
      __typename?: 'LocationFilterUnion';
      anyOf: Array<{
        __typename?: 'LocationFilterIntersection';
        id?: string | null;
        allOf: Array<{
          __typename?: 'LocationFilter';
          type: LocationFilterType;
          isNull?: boolean | null;
          isNot?: boolean | null;
          valueIn?: Array<{ __typename?: 'FilterValue'; title: string }> | null;
          range?: {
            __typename?: 'NumberFilterRange';
            from?: number | null;
            to?: number | null;
          } | null;
          stringRange?: {
            __typename?: 'StringFilterRange';
            from?: string | null;
            excludeFrom?: boolean | null;
            to?: string | null;
            excludeTo?: boolean | null;
          } | null;
        }>;
      }>;
    } | null;
    waypointSettings?: {
      __typename?: 'PickingPolicyWaypointSettings';
      ruleId?: string | null;
    } | null;
  }> | null;
  picklistSettings?: {
    __typename?: 'PickingPolicyPicklistSettings';
    usePickingPriority?: boolean | null;
    picklistLinesOrder?: Array<{
      __typename?: 'PicklistLinesSortBy';
      type: PicklistLinesOrderOptions;
      direction: PicklistLinesSortDirection;
      nullsFirst?: boolean | null;
    }> | null;
  } | null;
};

export type PickingPolicyPikingRuleConfigurationFragment = {
  __typename?: 'PickingPolicyPickingRule';
  uomTypes?: Array<string> | null;
  agentId: string;
  locationSelectionMode?: PickingPolicyLocationSelectionMode | null;
  picklistGroupKey?: string | null;
  waypointSettings?: {
    __typename?: 'PickingPolicyWaypointSettings';
    ruleId?: string | null;
  } | null;
};

export type PickingPolicyPickingRuleFragment = {
  __typename?: 'PickingPolicyPickingRule';
  id: string;
  title?: string | null;
  uomTypes?: Array<string> | null;
  agentId: string;
  locationSelectionMode?: PickingPolicyLocationSelectionMode | null;
  picklistGroupKey?: string | null;
  locationsMatch?: {
    __typename?: 'LocationFilterUnion';
    anyOf: Array<{
      __typename?: 'LocationFilterIntersection';
      id?: string | null;
      allOf: Array<{
        __typename?: 'LocationFilter';
        type: LocationFilterType;
        isNull?: boolean | null;
        isNot?: boolean | null;
        valueIn?: Array<{ __typename?: 'FilterValue'; title: string }> | null;
        range?: {
          __typename?: 'NumberFilterRange';
          from?: number | null;
          to?: number | null;
        } | null;
        stringRange?: {
          __typename?: 'StringFilterRange';
          from?: string | null;
          excludeFrom?: boolean | null;
          to?: string | null;
          excludeTo?: boolean | null;
        } | null;
      }>;
    }>;
  } | null;
  waypointSettings?: {
    __typename?: 'PickingPolicyWaypointSettings';
    ruleId?: string | null;
  } | null;
};

export type PickingPolicyFragment = {
  __typename?: 'PickingPolicy';
  orderLineRules?: Array<{
    __typename?: 'PickingPolicyOrderLineRule';
    id: string;
    title?: string | null;
    usePickingContainer?: boolean | null;
    pickingRequirementsSettings?: {
      __typename?: 'PickingPolicyPickingRequirementsSettings';
      groupBy: PickingRequirementsGroupBy;
      startLocationIdSource?: PickingRequirementTerminalLocationSource | null;
      endLocationIdSource?: PickingRequirementTerminalLocationSource | null;
      wave?: {
        __typename?: 'PickingWaveSettings';
        useDeliveryRoute?: boolean | null;
        useDock?: boolean | null;
        useStagingArea?: boolean | null;
        useWaveId?: boolean | null;
        pickByDateSettings?: {
          __typename?: 'PickingWavePickByDateSettings';
          mode: PickingWavePickByDateMode;
          fixedDeadlines?: Array<any> | null;
        } | null;
      } | null;
      batch?: {
        __typename?: 'PickingRequirementsBatchSettings';
        maxOrderCount?: number | null;
        maxOrderLineCount?: number | null;
      } | null;
    } | null;
    orderLinesMatch?: {
      __typename?: 'OrderLineFilterUnion';
      anyOf: Array<{
        __typename?: 'OrderLineFilterIntersection';
        id?: string | null;
        allOf: Array<{
          __typename?: 'OrderLineFilter';
          type: OrderLineFilterType;
          isNull?: boolean | null;
          isNot?: boolean | null;
          valueIn?: Array<{ __typename?: 'FilterValue'; title: string }> | null;
          range?: {
            __typename?: 'NumberFilterRange';
            from?: number | null;
            to?: number | null;
          } | null;
          stringRange?: {
            __typename?: 'StringFilterRange';
            from?: string | null;
            excludeFrom?: boolean | null;
            to?: string | null;
            excludeTo?: boolean | null;
          } | null;
        }>;
      }>;
    } | null;
    pickingRules?: Array<{
      __typename?: 'PickingPolicyPickingRule';
      id: string;
      title?: string | null;
      uomTypes?: Array<string> | null;
      agentId: string;
      locationSelectionMode?: PickingPolicyLocationSelectionMode | null;
      picklistGroupKey?: string | null;
      locationsMatch?: {
        __typename?: 'LocationFilterUnion';
        anyOf: Array<{
          __typename?: 'LocationFilterIntersection';
          id?: string | null;
          allOf: Array<{
            __typename?: 'LocationFilter';
            type: LocationFilterType;
            isNull?: boolean | null;
            isNot?: boolean | null;
            valueIn?: Array<{
              __typename?: 'FilterValue';
              title: string;
            }> | null;
            range?: {
              __typename?: 'NumberFilterRange';
              from?: number | null;
              to?: number | null;
            } | null;
            stringRange?: {
              __typename?: 'StringFilterRange';
              from?: string | null;
              excludeFrom?: boolean | null;
              to?: string | null;
              excludeTo?: boolean | null;
            } | null;
          }>;
        }>;
      } | null;
      waypointSettings?: {
        __typename?: 'PickingPolicyWaypointSettings';
        ruleId?: string | null;
      } | null;
    }> | null;
    picklistSettings?: {
      __typename?: 'PickingPolicyPicklistSettings';
      usePickingPriority?: boolean | null;
      picklistLinesOrder?: Array<{
        __typename?: 'PicklistLinesSortBy';
        type: PicklistLinesOrderOptions;
        direction: PicklistLinesSortDirection;
        nullsFirst?: boolean | null;
      }> | null;
    } | null;
  }> | null;
};

export type SimulationOrderLineSetFragment = {
  __typename?: 'SimulationOrderLineSet';
  id: string;
  status: BatchJobStatus;
  progress: number;
  createdAt: number;
};

export type LoadOrderLineSetQueryVariables = Exact<{
  simulationId: Scalars['ID']['input'];
}>;

export type LoadOrderLineSetQuery = {
  __typename?: 'Query';
  simulation?: {
    __typename?: 'Simulation';
    orderLineSet?: {
      __typename?: 'SimulationOrderLineSet';
      id: string;
      status: BatchJobStatus;
      progress: number;
      createdAt: number;
    } | null;
  } | null;
};

export type RunGenerateSimulationOrderLineSetMutationVariables = Exact<{
  simulationId: Scalars['String']['input'];
}>;

export type RunGenerateSimulationOrderLineSetMutation = {
  __typename?: 'Mutation';
  generateSimulationOrderLineSet: {
    __typename?: 'SimulationOrderLineSet';
    id: string;
    status: BatchJobStatus;
    progress: number;
    createdAt: number;
  };
};

export type BaseUomFragment = {
  __typename?: 'BaseUom';
  uom?: string | null;
  width?: number | null;
  height?: number | null;
  length?: number | null;
  netWeight?: number | null;
  volume?: number | null;
  maxStack?: number | null;
};

export type SimulationOrderLineFragment = {
  __typename?: 'SimulationOrderLine';
  orderLineId: string;
  consignee: string;
  orderId: string;
  orderDate?: string | null;
  customer?: string | null;
  deliveryRoute?: string | null;
  dock?: string | null;
  stagingArea?: string | null;
  waveId?: string | null;
  orderLineCount: number;
  orderTotalVolume?: number | null;
  orderTotalWeight?: number | null;
  orderLine: number;
  sku: string;
  orderLineQuantity: number;
  pickingPriority?: string | null;
  pickingContainer?: string | null;
  orderLineVolume?: number | null;
  orderLineWeight?: number | null;
  itemId: string;
  skuGroup?: string | null;
  subGroup?: string | null;
  transportClass?: string | null;
  stockCategory?: string | null;
  storageClass?: string | null;
  pollutionClass?: string | null;
  itemPercentRank?: number | null;
  itemCumulativePercentRank?: number | null;
  shipmentId?: string | null;
  loadByDate?: string | null;
  baseUom?: {
    __typename?: 'BaseUom';
    uom?: string | null;
    width?: number | null;
    height?: number | null;
    length?: number | null;
    netWeight?: number | null;
    volume?: number | null;
    maxStack?: number | null;
  } | null;
};

export type SimulationOrderLinesConnectionFragment = {
  __typename?: 'SimulationOrderLinesConnection';
  totalCount: number;
  content: Array<{
    __typename?: 'SimulationOrderLine';
    orderLineId: string;
    consignee: string;
    orderId: string;
    orderDate?: string | null;
    customer?: string | null;
    deliveryRoute?: string | null;
    dock?: string | null;
    stagingArea?: string | null;
    waveId?: string | null;
    orderLineCount: number;
    orderTotalVolume?: number | null;
    orderTotalWeight?: number | null;
    orderLine: number;
    sku: string;
    orderLineQuantity: number;
    pickingPriority?: string | null;
    pickingContainer?: string | null;
    orderLineVolume?: number | null;
    orderLineWeight?: number | null;
    itemId: string;
    skuGroup?: string | null;
    subGroup?: string | null;
    transportClass?: string | null;
    stockCategory?: string | null;
    storageClass?: string | null;
    pollutionClass?: string | null;
    itemPercentRank?: number | null;
    itemCumulativePercentRank?: number | null;
    shipmentId?: string | null;
    loadByDate?: string | null;
    baseUom?: {
      __typename?: 'BaseUom';
      uom?: string | null;
      width?: number | null;
      height?: number | null;
      length?: number | null;
      netWeight?: number | null;
      volume?: number | null;
      maxStack?: number | null;
    } | null;
  }>;
};

export type FindOrderLinesByFilterQueryVariables = Exact<{
  input: FindSimulationOrderLinesByFilter;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<
    Array<SimulationOrderLinesSortBy> | SimulationOrderLinesSortBy
  >;
}>;

export type FindOrderLinesByFilterQuery = {
  __typename?: 'Query';
  findSimulationOrderLinesByFilter: {
    __typename?: 'SimulationOrderLinesConnection';
    totalCount: number;
    content: Array<{
      __typename?: 'SimulationOrderLine';
      orderLineId: string;
      consignee: string;
      orderId: string;
      orderDate?: string | null;
      customer?: string | null;
      deliveryRoute?: string | null;
      dock?: string | null;
      stagingArea?: string | null;
      waveId?: string | null;
      orderLineCount: number;
      orderTotalVolume?: number | null;
      orderTotalWeight?: number | null;
      orderLine: number;
      sku: string;
      orderLineQuantity: number;
      pickingPriority?: string | null;
      pickingContainer?: string | null;
      orderLineVolume?: number | null;
      orderLineWeight?: number | null;
      itemId: string;
      skuGroup?: string | null;
      subGroup?: string | null;
      transportClass?: string | null;
      stockCategory?: string | null;
      storageClass?: string | null;
      pollutionClass?: string | null;
      itemPercentRank?: number | null;
      itemCumulativePercentRank?: number | null;
      shipmentId?: string | null;
      loadByDate?: string | null;
      baseUom?: {
        __typename?: 'BaseUom';
        uom?: string | null;
        width?: number | null;
        height?: number | null;
        length?: number | null;
        netWeight?: number | null;
        volume?: number | null;
        maxStack?: number | null;
      } | null;
    }>;
  };
};

export type CheckedPickingPolicyOrderLineRuleFragment = {
  __typename?: 'CheckedPickingPolicyOrderLineRule';
  id: string;
  orderLineFilterMatch: { __typename?: 'SimpleFilterMatch'; count: number };
  pickingRules?: Array<{
    __typename?: 'CheckedPickingPolicyPickingRule';
    id: string;
    locationFilterMatch: { __typename?: 'SimpleFilterMatch'; count: number };
  }> | null;
};

export type CheckSimulationPickingPolicyResultFragment = {
  __typename?: 'CheckSimulationPickingPolicyResult';
  policy: {
    __typename?: 'CheckedPickingPolicy';
    orderLineRules?: Array<{
      __typename?: 'CheckedPickingPolicyOrderLineRule';
      id: string;
      orderLineFilterMatch: { __typename?: 'SimpleFilterMatch'; count: number };
      pickingRules?: Array<{
        __typename?: 'CheckedPickingPolicyPickingRule';
        id: string;
        locationFilterMatch: {
          __typename?: 'SimpleFilterMatch';
          count: number;
        };
      }> | null;
    }> | null;
    unmatchedOrderLines: { __typename?: 'SimpleFilterMatch'; count: number };
  };
};

export type CheckPickingPolicyQueryVariables = Exact<{
  input: CheckSimulationPickingPolicyInput;
}>;

export type CheckPickingPolicyQuery = {
  __typename?: 'Query';
  checkSimulationPickingPolicy: {
    __typename?: 'CheckSimulationPickingPolicyResult';
    policy: {
      __typename?: 'CheckedPickingPolicy';
      orderLineRules?: Array<{
        __typename?: 'CheckedPickingPolicyOrderLineRule';
        id: string;
        orderLineFilterMatch: {
          __typename?: 'SimpleFilterMatch';
          count: number;
        };
        pickingRules?: Array<{
          __typename?: 'CheckedPickingPolicyPickingRule';
          id: string;
          locationFilterMatch: {
            __typename?: 'SimpleFilterMatch';
            count: number;
          };
        }> | null;
      }> | null;
      unmatchedOrderLines: { __typename?: 'SimpleFilterMatch'; count: number };
    };
  };
};

export type FilterValueFragment = { __typename?: 'FilterValue'; title: string };

export type NumberFilterRangeFragment = {
  __typename?: 'NumberFilterRange';
  from?: number | null;
  to?: number | null;
};

export type StringFilterRangeFragment = {
  __typename?: 'StringFilterRange';
  from?: string | null;
  excludeFrom?: boolean | null;
  to?: string | null;
  excludeTo?: boolean | null;
};

export type FilterValuesConnectionFragment = {
  __typename?: 'FilterValuesConnection';
  totalCount?: number | null;
  content?: Array<{ __typename?: 'FilterValue'; title: string }> | null;
  range?: {
    __typename?: 'NumberFilterRange';
    from?: number | null;
    to?: number | null;
  } | null;
};

export type SpeedSettingsFragment = {
  __typename?: 'SpeedSettings';
  speedLaden?: number | null;
  speedUnladen?: number | null;
};

export type PicklistProcessingEventSettingsFragment = {
  __typename?: 'PicklistProcessingEventSettings';
  timeFixed?: number | null;
  timePerOrder?: number | null;
  timePerPicklistLine?: number | null;
  timePerUom?: number | null;
};

export type PicklistLineProcessingEventSettingsFragment = {
  __typename?: 'PicklistLineProcessingEventSettings';
  timeFixed?: number | null;
  timePerUom?: number | null;
};

export type HorizontalTravellingEventSettingsFragment = {
  __typename?: 'HorizontalTravellingEventSettings';
  speed?: {
    __typename?: 'SpeedSettings';
    speedLaden?: number | null;
    speedUnladen?: number | null;
  } | null;
};

export type UomHandlingSettingsFragment = {
  __typename?: 'UomHandlingSettings';
  uom: string;
  timePerUom?: number | null;
};

export type AgentCapacityUomSettingsFragment = {
  __typename?: 'AgentCapacityUomSettings';
  uom: string;
  quantity?: number | null;
};

export type VerticalTravellingEventSettingsFragment = {
  __typename?: 'VerticalTravellingEventSettings';
  speedUp?: {
    __typename?: 'SpeedSettings';
    speedLaden?: number | null;
    speedUnladen?: number | null;
  } | null;
  speedDown?: {
    __typename?: 'SpeedSettings';
    speedLaden?: number | null;
    speedUnladen?: number | null;
  } | null;
};

export type HandlingEventSettingsFragment = {
  __typename?: 'HandlingEventSettings';
  timeFixed?: number | null;
  timePerWeight?: number | null;
  timePerVolume?: number | null;
  timePerUom?: Array<{
    __typename?: 'UomHandlingSettings';
    uom: string;
    timePerUom?: number | null;
  }> | null;
};

export type ReorderEventSettingsFragment = {
  __typename?: 'ReorderEventSettings';
  timeFixed?: number | null;
  timePerReorderedPosition?: number | null;
  timePerWeight?: number | null;
  timePerVolume?: number | null;
  timePerUom?: Array<{
    __typename?: 'UomHandlingSettings';
    uom: string;
    timePerUom?: number | null;
  }> | null;
};

export type AgentCostSettingsFragment = {
  __typename?: 'AgentCostSettings';
  costPerSecond?: number | null;
};

export type AgentUtilisationSettingsFragment = {
  __typename?: 'AgentUtilisationSettings';
  agentAmount?: number | null;
  secondsOperation?: number | null;
};

export type AgentSpecsFragment = {
  __typename?: 'AgentSpecs';
  horizontalTravelling?: {
    __typename?: 'HorizontalTravellingEventSettings';
    speed?: {
      __typename?: 'SpeedSettings';
      speedLaden?: number | null;
      speedUnladen?: number | null;
    } | null;
  } | null;
  verticalTravelling?: {
    __typename?: 'VerticalTravellingEventSettings';
    speedUp?: {
      __typename?: 'SpeedSettings';
      speedLaden?: number | null;
      speedUnladen?: number | null;
    } | null;
    speedDown?: {
      __typename?: 'SpeedSettings';
      speedLaden?: number | null;
      speedUnladen?: number | null;
    } | null;
  } | null;
  capacity?: {
    __typename?: 'AgentCapacitySettings';
    maxVolume?: number | null;
    maxWeight?: number | null;
    maxUomQuantity?: Array<{
      __typename?: 'AgentCapacityUomSettings';
      uom: string;
      quantity?: number | null;
    }> | null;
  } | null;
  powerSource?: {
    __typename?: 'AgentPowerSourceSettings';
    energyConsumptionPerSecond?: number | null;
  } | null;
};

export type AgentRoleSettingsFragment = {
  __typename?: 'AgentRoleSettings';
  roleId: AgentRole;
  disabled?: boolean | null;
  preHandling?: {
    __typename?: 'PicklistProcessingEventSettings';
    timeFixed?: number | null;
    timePerOrder?: number | null;
    timePerPicklistLine?: number | null;
    timePerUom?: number | null;
  } | null;
  handlingPreparation?: {
    __typename?: 'PicklistLineProcessingEventSettings';
    timeFixed?: number | null;
    timePerUom?: number | null;
  } | null;
  handling?: {
    __typename?: 'HandlingEventSettings';
    timeFixed?: number | null;
    timePerWeight?: number | null;
    timePerVolume?: number | null;
    timePerUom?: Array<{
      __typename?: 'UomHandlingSettings';
      uom: string;
      timePerUom?: number | null;
    }> | null;
  } | null;
  reordering?: {
    __typename?: 'ReorderEventSettings';
    timeFixed?: number | null;
    timePerReorderedPosition?: number | null;
    timePerWeight?: number | null;
    timePerVolume?: number | null;
    timePerUom?: Array<{
      __typename?: 'UomHandlingSettings';
      uom: string;
      timePerUom?: number | null;
    }> | null;
  } | null;
  handlingCompletion?: {
    __typename?: 'PicklistLineProcessingEventSettings';
    timeFixed?: number | null;
    timePerUom?: number | null;
  } | null;
  postHandling?: {
    __typename?: 'PicklistProcessingEventSettings';
    timeFixed?: number | null;
    timePerOrder?: number | null;
    timePerPicklistLine?: number | null;
    timePerUom?: number | null;
  } | null;
};

export type AgentSettingsFragment = {
  __typename?: 'AgentSettings';
  id: string;
  title?: string | null;
  resourceTypeId: string;
  metadata?: any | null;
  roles?: Array<{
    __typename?: 'AgentRoleSettings';
    roleId: AgentRole;
    disabled?: boolean | null;
    preHandling?: {
      __typename?: 'PicklistProcessingEventSettings';
      timeFixed?: number | null;
      timePerOrder?: number | null;
      timePerPicklistLine?: number | null;
      timePerUom?: number | null;
    } | null;
    handlingPreparation?: {
      __typename?: 'PicklistLineProcessingEventSettings';
      timeFixed?: number | null;
      timePerUom?: number | null;
    } | null;
    handling?: {
      __typename?: 'HandlingEventSettings';
      timeFixed?: number | null;
      timePerWeight?: number | null;
      timePerVolume?: number | null;
      timePerUom?: Array<{
        __typename?: 'UomHandlingSettings';
        uom: string;
        timePerUom?: number | null;
      }> | null;
    } | null;
    reordering?: {
      __typename?: 'ReorderEventSettings';
      timeFixed?: number | null;
      timePerReorderedPosition?: number | null;
      timePerWeight?: number | null;
      timePerVolume?: number | null;
      timePerUom?: Array<{
        __typename?: 'UomHandlingSettings';
        uom: string;
        timePerUom?: number | null;
      }> | null;
    } | null;
    handlingCompletion?: {
      __typename?: 'PicklistLineProcessingEventSettings';
      timeFixed?: number | null;
      timePerUom?: number | null;
    } | null;
    postHandling?: {
      __typename?: 'PicklistProcessingEventSettings';
      timeFixed?: number | null;
      timePerOrder?: number | null;
      timePerPicklistLine?: number | null;
      timePerUom?: number | null;
    } | null;
  }> | null;
  specs?: {
    __typename?: 'AgentSpecs';
    horizontalTravelling?: {
      __typename?: 'HorizontalTravellingEventSettings';
      speed?: {
        __typename?: 'SpeedSettings';
        speedLaden?: number | null;
        speedUnladen?: number | null;
      } | null;
    } | null;
    verticalTravelling?: {
      __typename?: 'VerticalTravellingEventSettings';
      speedUp?: {
        __typename?: 'SpeedSettings';
        speedLaden?: number | null;
        speedUnladen?: number | null;
      } | null;
      speedDown?: {
        __typename?: 'SpeedSettings';
        speedLaden?: number | null;
        speedUnladen?: number | null;
      } | null;
    } | null;
    capacity?: {
      __typename?: 'AgentCapacitySettings';
      maxVolume?: number | null;
      maxWeight?: number | null;
      maxUomQuantity?: Array<{
        __typename?: 'AgentCapacityUomSettings';
        uom: string;
        quantity?: number | null;
      }> | null;
    } | null;
    powerSource?: {
      __typename?: 'AgentPowerSourceSettings';
      energyConsumptionPerSecond?: number | null;
    } | null;
  } | null;
  cost?: {
    __typename?: 'AgentCostSettings';
    costPerSecond?: number | null;
  } | null;
  utilisation?: {
    __typename?: 'AgentUtilisationSettings';
    agentAmount?: number | null;
    secondsOperation?: number | null;
  } | null;
};

export type ResourcePolicyFragment = {
  __typename?: 'ResourcePolicy';
  agents: Array<{
    __typename?: 'AgentSettings';
    id: string;
    title?: string | null;
    resourceTypeId: string;
    metadata?: any | null;
    roles?: Array<{
      __typename?: 'AgentRoleSettings';
      roleId: AgentRole;
      disabled?: boolean | null;
      preHandling?: {
        __typename?: 'PicklistProcessingEventSettings';
        timeFixed?: number | null;
        timePerOrder?: number | null;
        timePerPicklistLine?: number | null;
        timePerUom?: number | null;
      } | null;
      handlingPreparation?: {
        __typename?: 'PicklistLineProcessingEventSettings';
        timeFixed?: number | null;
        timePerUom?: number | null;
      } | null;
      handling?: {
        __typename?: 'HandlingEventSettings';
        timeFixed?: number | null;
        timePerWeight?: number | null;
        timePerVolume?: number | null;
        timePerUom?: Array<{
          __typename?: 'UomHandlingSettings';
          uom: string;
          timePerUom?: number | null;
        }> | null;
      } | null;
      reordering?: {
        __typename?: 'ReorderEventSettings';
        timeFixed?: number | null;
        timePerReorderedPosition?: number | null;
        timePerWeight?: number | null;
        timePerVolume?: number | null;
        timePerUom?: Array<{
          __typename?: 'UomHandlingSettings';
          uom: string;
          timePerUom?: number | null;
        }> | null;
      } | null;
      handlingCompletion?: {
        __typename?: 'PicklistLineProcessingEventSettings';
        timeFixed?: number | null;
        timePerUom?: number | null;
      } | null;
      postHandling?: {
        __typename?: 'PicklistProcessingEventSettings';
        timeFixed?: number | null;
        timePerOrder?: number | null;
        timePerPicklistLine?: number | null;
        timePerUom?: number | null;
      } | null;
    }> | null;
    specs?: {
      __typename?: 'AgentSpecs';
      horizontalTravelling?: {
        __typename?: 'HorizontalTravellingEventSettings';
        speed?: {
          __typename?: 'SpeedSettings';
          speedLaden?: number | null;
          speedUnladen?: number | null;
        } | null;
      } | null;
      verticalTravelling?: {
        __typename?: 'VerticalTravellingEventSettings';
        speedUp?: {
          __typename?: 'SpeedSettings';
          speedLaden?: number | null;
          speedUnladen?: number | null;
        } | null;
        speedDown?: {
          __typename?: 'SpeedSettings';
          speedLaden?: number | null;
          speedUnladen?: number | null;
        } | null;
      } | null;
      capacity?: {
        __typename?: 'AgentCapacitySettings';
        maxVolume?: number | null;
        maxWeight?: number | null;
        maxUomQuantity?: Array<{
          __typename?: 'AgentCapacityUomSettings';
          uom: string;
          quantity?: number | null;
        }> | null;
      } | null;
      powerSource?: {
        __typename?: 'AgentPowerSourceSettings';
        energyConsumptionPerSecond?: number | null;
      } | null;
    } | null;
    cost?: {
      __typename?: 'AgentCostSettings';
      costPerSecond?: number | null;
    } | null;
    utilisation?: {
      __typename?: 'AgentUtilisationSettings';
      agentAmount?: number | null;
      secondsOperation?: number | null;
    } | null;
  }>;
};

export type RoutingPolicyDirectionThresholdFragment = {
  __typename?: 'RoutingPolicyDirectionThreshold';
  angle?: number | null;
  distance?: number | null;
};

export type RoutingPolicyFeatureRuleFragment = {
  __typename?: 'RoutingPolicyFeatureRule';
  featureId: string;
  direction?: { __typename?: 'Vector'; x: number; y: number } | null;
  threshold?: {
    __typename?: 'RoutingPolicyDirectionThreshold';
    angle?: number | null;
    distance?: number | null;
  } | null;
};

export type RoutingPolicyRuleFragment = {
  __typename?: 'RoutingPolicyRule';
  featureRules?: Array<{
    __typename?: 'RoutingPolicyFeatureRule';
    featureId: string;
    direction?: { __typename?: 'Vector'; x: number; y: number } | null;
    threshold?: {
      __typename?: 'RoutingPolicyDirectionThreshold';
      angle?: number | null;
      distance?: number | null;
    } | null;
  }> | null;
};

export type RoutingPolicyAgentRuleFragment = {
  __typename?: 'RoutingPolicyAgentRule';
  id?: string | null;
  title?: string | null;
  agentIds: Array<string>;
  featureRules?: Array<{
    __typename?: 'RoutingPolicyFeatureRule';
    featureId: string;
    direction?: { __typename?: 'Vector'; x: number; y: number } | null;
    threshold?: {
      __typename?: 'RoutingPolicyDirectionThreshold';
      angle?: number | null;
      distance?: number | null;
    } | null;
  }> | null;
};

export type RoutingPolicyFragment = {
  __typename?: 'RoutingPolicy';
  rules?: Array<{
    __typename?: 'RoutingPolicyAgentRule';
    id?: string | null;
    title?: string | null;
    agentIds: Array<string>;
    featureRules?: Array<{
      __typename?: 'RoutingPolicyFeatureRule';
      featureId: string;
      direction?: { __typename?: 'Vector'; x: number; y: number } | null;
      threshold?: {
        __typename?: 'RoutingPolicyDirectionThreshold';
        angle?: number | null;
        distance?: number | null;
      } | null;
    }> | null;
  }> | null;
  defaultRule?: {
    __typename?: 'RoutingPolicyRule';
    featureRules?: Array<{
      __typename?: 'RoutingPolicyFeatureRule';
      featureId: string;
      direction?: { __typename?: 'Vector'; x: number; y: number } | null;
      threshold?: {
        __typename?: 'RoutingPolicyDirectionThreshold';
        angle?: number | null;
        distance?: number | null;
      } | null;
    }> | null;
  } | null;
  threshold?: {
    __typename?: 'RoutingPolicyDirectionThreshold';
    angle?: number | null;
    distance?: number | null;
  } | null;
};

export type LoadRoutingInaccessibleTotalQueryVariables = Exact<{
  input: FindLayoutInaccessibleLocationsByRuleInput;
  page?: InputMaybe<SkipLimitPageSpec>;
  filter?: InputMaybe<LayoutLocationsSearchFilter>;
  sortBy?: InputMaybe<Array<LayoutLocationsSortBy> | LayoutLocationsSortBy>;
}>;

export type LoadRoutingInaccessibleTotalQuery = {
  __typename?: 'Query';
  findLayoutInaccessibleLocations: {
    __typename?: 'InaccessibleLayoutLocationsConnection';
    totalCount: number;
  };
};

export type InaccessibleLayoutLocationFragment = {
  __typename?: 'InaccessibleLayoutLocation';
  locationId: string;
  reason: Array<LayoutLocationInaccessibilityReason>;
};

export type LoadRoutingInaccessibleLocationsQueryVariables = Exact<{
  input: FindLayoutInaccessibleLocationsByRuleInput;
  page?: InputMaybe<SkipLimitPageSpec>;
  filter?: InputMaybe<LayoutLocationsSearchFilter>;
  sortBy?: InputMaybe<Array<LayoutLocationsSortBy> | LayoutLocationsSortBy>;
}>;

export type LoadRoutingInaccessibleLocationsQuery = {
  __typename?: 'Query';
  findLayoutInaccessibleLocations: {
    __typename?: 'InaccessibleLayoutLocationsConnection';
    totalCount: number;
    content: Array<{
      __typename?: 'InaccessibleLayoutLocation';
      reason: Array<LayoutLocationInaccessibilityReason>;
      locationId: string;
    }>;
  };
};

export type LayoutPlaneRouteFragment = {
  __typename?: 'LayoutPlaneRoute';
  planeId: string;
  distance: number;
  unreachableLocations: Array<string>;
  unreachableTerminals: Array<{
    __typename?: 'PicklistEventWaypoint';
    id: string;
    type: WaypointType;
    position: { __typename?: 'Point'; x: number; y: number };
  }>;
  waypoints: Array<{
    __typename?: 'PicklistEventWaypoint';
    id: string;
    type: WaypointType;
    position: { __typename?: 'Point'; x: number; y: number };
  }>;
};

export type LayoutRouteFragment = {
  __typename?: 'LayoutRoute';
  routes: Array<{
    __typename?: 'LayoutPlaneRoute';
    planeId: string;
    distance: number;
    unreachableLocations: Array<string>;
    unreachableTerminals: Array<{
      __typename?: 'PicklistEventWaypoint';
      id: string;
      type: WaypointType;
      position: { __typename?: 'Point'; x: number; y: number };
    }>;
    waypoints: Array<{
      __typename?: 'PicklistEventWaypoint';
      id: string;
      type: WaypointType;
      position: { __typename?: 'Point'; x: number; y: number };
    }>;
  }>;
};

export type BuildSimulationLayoutRouteQueryVariables = Exact<{
  input: BuildSimulationLayoutRouteInput;
}>;

export type BuildSimulationLayoutRouteQuery = {
  __typename?: 'Query';
  buildSimulationLayoutRoute: {
    __typename?: 'LayoutRoute';
    routes: Array<{
      __typename?: 'LayoutPlaneRoute';
      planeId: string;
      distance: number;
      unreachableLocations: Array<string>;
      unreachableTerminals: Array<{
        __typename?: 'PicklistEventWaypoint';
        id: string;
        type: WaypointType;
        position: { __typename?: 'Point'; x: number; y: number };
      }>;
      waypoints: Array<{
        __typename?: 'PicklistEventWaypoint';
        id: string;
        type: WaypointType;
        position: { __typename?: 'Point'; x: number; y: number };
      }>;
    }>;
  };
};

export type BuildLayoutRouteQueryVariables = Exact<{
  input: BuildLayoutRouteInput;
}>;

export type BuildLayoutRouteQuery = {
  __typename?: 'Query';
  buildLayoutRoute: {
    __typename?: 'LayoutRoute';
    routes: Array<{
      __typename?: 'LayoutPlaneRoute';
      planeId: string;
      distance: number;
      unreachableLocations: Array<string>;
      unreachableTerminals: Array<{
        __typename?: 'PicklistEventWaypoint';
        id: string;
        type: WaypointType;
        position: { __typename?: 'Point'; x: number; y: number };
      }>;
      waypoints: Array<{
        __typename?: 'PicklistEventWaypoint';
        id: string;
        type: WaypointType;
        position: { __typename?: 'Point'; x: number; y: number };
      }>;
    }>;
  };
};

export type BatchBuildLayoutRouteQueryVariables = Exact<{
  input: BatchBuildLayoutRouteInput;
}>;

export type BatchBuildLayoutRouteQuery = {
  __typename?: 'Query';
  batchBuildLayoutRoute: Array<{
    __typename?: 'LayoutRoute';
    routes: Array<{
      __typename?: 'LayoutPlaneRoute';
      planeId: string;
      distance: number;
      unreachableLocations: Array<string>;
      unreachableTerminals: Array<{
        __typename?: 'PicklistEventWaypoint';
        id: string;
        type: WaypointType;
        position: { __typename?: 'Point'; x: number; y: number };
      }>;
      waypoints: Array<{
        __typename?: 'PicklistEventWaypoint';
        id: string;
        type: WaypointType;
        position: { __typename?: 'Point'; x: number; y: number };
      }>;
    }>;
  }>;
};

export type OrderSetItemsCategoryFragment = {
  __typename?: 'OrderSetItemsCategory';
  skip: number;
  count: number;
  limit: number;
  minPercentRank?: number | null;
  maxPercentRank?: number | null;
  minCumulativePercentRank?: number | null;
  maxCumulativePercentRank?: number | null;
  totalOrderLineCount: number;
};

export type SimulationItemsCategoryFragment = {
  __typename?: 'SimulationItemsCategory';
  skip: number;
  count: number;
  limit: number;
  minBoundary?: number | null;
  maxBoundary?: number | null;
  minPercentRank?: number | null;
  maxPercentRank?: number | null;
  minCumulativePercentRank?: number | null;
  maxCumulativePercentRank?: number | null;
  totalOrderLineCount: number;
};

export type SimulationAbcFragment = {
  __typename?: 'Simulation';
  orderSet?: {
    __typename?: 'OrderSet';
    itemsByCategory?: {
      __typename?: 'OrderSetItemsByCategoryConnection';
      totalCount: number;
      totalOrderLineCount: number;
      categories: Array<{
        __typename?: 'OrderSetItemsCategory';
        skip: number;
        count: number;
        limit: number;
        minPercentRank?: number | null;
        maxPercentRank?: number | null;
        minCumulativePercentRank?: number | null;
        maxCumulativePercentRank?: number | null;
        totalOrderLineCount: number;
      }>;
    } | null;
  } | null;
  effectiveItemSet?: {
    __typename?: 'SimulationItemSet';
    itemsByCategory?: {
      __typename?: 'SimulationItemsByCategoryConnection';
      totalCount: number;
      totalOrderLineCount: number;
      categories: Array<{
        __typename?: 'SimulationItemsCategory';
        skip: number;
        count: number;
        limit: number;
        minBoundary?: number | null;
        maxBoundary?: number | null;
        minPercentRank?: number | null;
        maxPercentRank?: number | null;
        minCumulativePercentRank?: number | null;
        maxCumulativePercentRank?: number | null;
        totalOrderLineCount: number;
      }>;
    } | null;
  } | null;
};

export type LoadProductByCategoriesQueryVariables = Exact<{
  simulationId: Scalars['ID']['input'];
  categoryWidths: Array<Scalars['Float']['input']> | Scalars['Float']['input'];
}>;

export type LoadProductByCategoriesQuery = {
  __typename?: 'Query';
  simulation?: {
    __typename?: 'Simulation';
    orderSet?: {
      __typename?: 'OrderSet';
      itemsByCategory?: {
        __typename?: 'OrderSetItemsByCategoryConnection';
        totalCount: number;
        totalOrderLineCount: number;
        categories: Array<{
          __typename?: 'OrderSetItemsCategory';
          skip: number;
          count: number;
          limit: number;
          minPercentRank?: number | null;
          maxPercentRank?: number | null;
          minCumulativePercentRank?: number | null;
          maxCumulativePercentRank?: number | null;
          totalOrderLineCount: number;
        }>;
      } | null;
    } | null;
    effectiveItemSet?: {
      __typename?: 'SimulationItemSet';
      itemsByCategory?: {
        __typename?: 'SimulationItemsByCategoryConnection';
        totalCount: number;
        totalOrderLineCount: number;
        categories: Array<{
          __typename?: 'SimulationItemsCategory';
          skip: number;
          count: number;
          limit: number;
          minBoundary?: number | null;
          maxBoundary?: number | null;
          minPercentRank?: number | null;
          maxPercentRank?: number | null;
          minCumulativePercentRank?: number | null;
          maxCumulativePercentRank?: number | null;
          totalOrderLineCount: number;
        }>;
      } | null;
    } | null;
  } | null;
};

export type SimulationItemMetaFragment = {
  __typename?: 'SimulationItem';
  id: string;
  percentRank?: number | null;
  cumulativePercentRank?: number | null;
};

export type LoadSimulationProductMetaQueryVariables = Exact<{
  simulationId: Scalars['ID']['input'];
}>;

export type LoadSimulationProductMetaQuery = {
  __typename?: 'Query';
  findSimulationItemsByFilter: {
    __typename?: 'SimulationItemsConnection';
    totalCount: number;
    content: Array<{
      __typename?: 'SimulationItem';
      id: string;
      percentRank?: number | null;
      cumulativePercentRank?: number | null;
    }>;
  };
};

export type SimulationRefSummaryFragment = {
  __typename?: 'Simulation';
  id: string;
  title: string;
  description?: string | null;
  createdAt: number;
  updatedAt: number;
  userTags?: Array<string> | null;
};

export type SimulationMetaStatusFragment = {
  __typename?: 'Simulation';
  id: string;
  userTags?: Array<string> | null;
  extraSettings?: any | null;
  sizeCompliance?: {
    __typename?: 'SizeCompliance';
    id: string;
    status: BatchJobStatus;
    summary?: {
      __typename?: 'SizeComplianceSummary';
      items: Array<{
        __typename?: 'SizeComplianceSummaryItem';
        totalCount: number;
        compliantCount: number;
        nonCompliantCount: number;
        unassignedCount: number;
        unknownCount: number;
        planeId: string;
        level: number;
      }>;
    } | null;
  } | null;
  weightCompliance?: {
    __typename?: 'WeightCompliance';
    id: string;
    status: BatchJobStatus;
    locationSummary?: {
      __typename?: 'LocationWeightComplianceSummary';
      items: Array<{
        __typename?: 'LocationWeightComplianceSummaryItem';
        totalCount: number;
        compliantCount: number;
        nonCompliantCount: number;
        unassignedCount: number;
        unknownCount: number;
        floorLevelCount: number;
        planeId: string;
        level: number;
      }>;
    } | null;
    baySummary?: {
      __typename?: 'BayWeightComplianceSummary';
      items: Array<{
        __typename?: 'BayWeightComplianceSummaryItem';
        totalCount: number;
        compliantCount: number;
        nonCompliantCount: number;
        unassignedCount: number;
        unknownCount: number;
        floorLevelCount: number;
        planeId: string;
      }>;
    } | null;
  } | null;
  orderLineSet?: {
    __typename?: 'SimulationOrderLineSet';
    id: string;
    status: BatchJobStatus;
    progress: number;
    createdAt: number;
  } | null;
  effectiveItemSet?: {
    __typename?: 'SimulationItemSet';
    id: string;
    status: BatchJobStatus;
    createdAt: number;
  } | null;
  layoutIssues?: {
    __typename?: 'LayoutIssueSet';
    status: BatchJobStatus;
    progress: number;
    summary?: {
      __typename?: 'LayoutIssueSummary';
      totalLocations: number;
      totalInvalidLocations: number;
      invalidLocations: Array<{
        __typename?: 'LayoutIssueInvalidLocationsSummary';
        planeId?: string | null;
        locationLevel?: number | null;
        reason: LayoutIssueInvalidLocationReason;
        count: number;
      }>;
    } | null;
  } | null;
  assignmentIssues?: {
    __typename?: 'AssignmentIssueSet';
    status: BatchJobStatus;
    progress: number;
    summary?: {
      __typename?: 'AssignmentIssueSummary';
      totalLocations: number;
      totalAssignedLocations: number;
      totalAssignmentItems: number;
      totalInvalidLocations: number;
      invalidLocations: Array<{
        __typename?: 'AssignmentIssueInvalidLocationsSummary';
        planeId?: string | null;
        locationLevel?: number | null;
        reason: AssignmentIssueInvalidLocationReason;
        count: number;
      }>;
    } | null;
  } | null;
  itemSetIssues?: {
    __typename?: 'ItemSetIssueSet';
    status: BatchJobStatus;
    progress: number;
    summary?: {
      __typename?: 'ItemSetIssueSummary';
      totalItems: number;
      totalUoms: number;
      totalInvalidItems: number;
      totalInvalidUoms: number;
      totalAssignedItems: number;
      unknownAssignedItems: number;
      invalidItems: Array<{
        __typename?: 'ItemSetIssueInvalidItemsSummary';
        reason: ItemSetIssueInvalidItemReason;
        count: number;
      }>;
      invalidUoms: Array<{
        __typename?: 'ItemSetIssueInvalidUomsSummary';
        reason: ItemSetIssueInvalidUomReason;
        count: number;
      }>;
    } | null;
  } | null;
  orderSetIssues?: {
    __typename?: 'OrderSetIssueSet';
    status: BatchJobStatus;
    progress: number;
    summary?: {
      __typename?: 'OrderSetIssueSummary';
      totalLocations: number;
      totalOrderLines: number;
      totalUnpickableLines: number;
      totalItems?: number | null;
      unpickableLines: Array<{
        __typename?: 'OrderSetIssueUnpickableLinesSummary';
        reason: OrderSetIssueUnpickableLineReason;
        count: number;
      }>;
      unpickableItems?: Array<{
        __typename?: 'OrderSetIssueUnpickableItemsSummary';
        reason: OrderSetIssueUnpickableLineReason;
        count: number;
      }> | null;
    } | null;
  } | null;
  allocationRequirementSet?: {
    __typename?: 'AllocationRequirementSet';
    id: string;
    status: BatchJobStatus;
    progress: number;
  } | null;
};

export type SimulationMetaFragment = {
  __typename?: 'Simulation';
  title: string;
  description?: string | null;
  status: SimulationStatus;
  updatedAt: number;
  id: string;
  userTags?: Array<string> | null;
  extraSettings?: any | null;
  layout?: { __typename?: 'Layout'; id: string } | null;
  assignment?: { __typename?: 'Assignment'; id: string; title: string } | null;
  orderSet?: { __typename?: 'OrderSet'; id: string } | null;
  itemSet?: {
    __typename?: 'ItemSet';
    id: string;
    uomTypes: Array<{ __typename?: 'ItemUomType'; uom: string }>;
  } | null;
  orderedQuantityReport?: {
    __typename?: 'OrderedQuantityReport';
    id: string;
    status: BatchJobStatus;
    progress: number;
    summary?: {
      __typename?: 'OrderedQuantityReportSummary';
      enoughCount: number;
      notEnoughCount: number;
      notOrderedCount: number;
    } | null;
  } | null;
  routingPolicy?: {
    __typename?: 'RoutingPolicy';
    rules?: Array<{
      __typename?: 'RoutingPolicyAgentRule';
      id?: string | null;
      title?: string | null;
      agentIds: Array<string>;
      featureRules?: Array<{
        __typename?: 'RoutingPolicyFeatureRule';
        featureId: string;
        direction?: { __typename?: 'Vector'; x: number; y: number } | null;
        threshold?: {
          __typename?: 'RoutingPolicyDirectionThreshold';
          angle?: number | null;
          distance?: number | null;
        } | null;
      }> | null;
    }> | null;
    defaultRule?: {
      __typename?: 'RoutingPolicyRule';
      featureRules?: Array<{
        __typename?: 'RoutingPolicyFeatureRule';
        featureId: string;
        direction?: { __typename?: 'Vector'; x: number; y: number } | null;
        threshold?: {
          __typename?: 'RoutingPolicyDirectionThreshold';
          angle?: number | null;
          distance?: number | null;
        } | null;
      }> | null;
    } | null;
    threshold?: {
      __typename?: 'RoutingPolicyDirectionThreshold';
      angle?: number | null;
      distance?: number | null;
    } | null;
  } | null;
  stackingPolicy?: {
    __typename?: 'StackingPolicy';
    topRules?: Array<{
      __typename?: 'StackingPolicyRule';
      id: string;
      title?: string | null;
      itemsMatch?: {
        __typename?: 'ItemFilterUnion';
        anyOf: Array<{
          __typename?: 'ItemFilterIntersection';
          id?: string | null;
          allOf: Array<{
            __typename?: 'ItemFilter';
            type: ItemFilterType;
            isNot?: boolean | null;
            isNull?: boolean | null;
            valueIn?: Array<{
              __typename?: 'FilterValue';
              title: string;
            }> | null;
            range?: {
              __typename?: 'NumberFilterRange';
              from?: number | null;
              to?: number | null;
            } | null;
            stringRange?: {
              __typename?: 'StringFilterRange';
              from?: string | null;
              excludeFrom?: boolean | null;
              to?: string | null;
              excludeTo?: boolean | null;
            } | null;
          }>;
        }>;
      } | null;
      subcategories?: {
        __typename?: 'StackingPolicyRuleSubcategories';
        type?: StackingPolicyRuleSubcategoryType | null;
        step?: number | null;
        boundaries?: Array<number> | null;
      } | null;
    }> | null;
    bottomRules?: Array<{
      __typename?: 'StackingPolicyRule';
      id: string;
      title?: string | null;
      itemsMatch?: {
        __typename?: 'ItemFilterUnion';
        anyOf: Array<{
          __typename?: 'ItemFilterIntersection';
          id?: string | null;
          allOf: Array<{
            __typename?: 'ItemFilter';
            type: ItemFilterType;
            isNot?: boolean | null;
            isNull?: boolean | null;
            valueIn?: Array<{
              __typename?: 'FilterValue';
              title: string;
            }> | null;
            range?: {
              __typename?: 'NumberFilterRange';
              from?: number | null;
              to?: number | null;
            } | null;
            stringRange?: {
              __typename?: 'StringFilterRange';
              from?: string | null;
              excludeFrom?: boolean | null;
              to?: string | null;
              excludeTo?: boolean | null;
            } | null;
          }>;
        }>;
      } | null;
      subcategories?: {
        __typename?: 'StackingPolicyRuleSubcategories';
        type?: StackingPolicyRuleSubcategoryType | null;
        step?: number | null;
        boundaries?: Array<number> | null;
      } | null;
    }> | null;
    fallbackRule?: {
      __typename?: 'StackingPolicyFallbackRule';
      subcategories?: {
        __typename?: 'StackingPolicyRuleSubcategories';
        type?: StackingPolicyRuleSubcategoryType | null;
        step?: number | null;
        boundaries?: Array<number> | null;
      } | null;
    } | null;
  } | null;
  assignmentPolicy?: {
    __typename?: 'AssignmentPolicy';
    fallbackLocationsAllowAnyItems?: boolean | null;
    fallbackItemsAllowAnyLocations?: boolean | null;
    rules: Array<{
      __typename?: 'AssignmentPolicyRule';
      id: string;
      title?: string | null;
      type?: AssignmentPolicyRuleMatchType | null;
      productsMatch?: {
        __typename?: 'SimulationItemFilterUnion';
        anyOf: Array<{
          __typename?: 'SimulationItemFilterIntersection';
          id?: string | null;
          allOf: Array<{
            __typename?: 'SimulationItemFilter';
            type: SimulationItemFilterType;
            isNull?: boolean | null;
            isNot?: boolean | null;
            valueIn?: Array<{
              __typename?: 'FilterValue';
              title: string;
            }> | null;
            range?: {
              __typename?: 'NumberFilterRange';
              from?: number | null;
              to?: number | null;
            } | null;
            stringRange?: {
              __typename?: 'StringFilterRange';
              from?: string | null;
              excludeFrom?: boolean | null;
              to?: string | null;
              excludeTo?: boolean | null;
            } | null;
          }>;
        }>;
      } | null;
      locationsMatch?: {
        __typename?: 'LocationFilterUnion';
        anyOf: Array<{
          __typename?: 'LocationFilterIntersection';
          id?: string | null;
          allOf: Array<{
            __typename?: 'LocationFilter';
            type: LocationFilterType;
            isNull?: boolean | null;
            isNot?: boolean | null;
            valueIn?: Array<{
              __typename?: 'FilterValue';
              title: string;
            }> | null;
            range?: {
              __typename?: 'NumberFilterRange';
              from?: number | null;
              to?: number | null;
            } | null;
            stringRange?: {
              __typename?: 'StringFilterRange';
              from?: string | null;
              excludeFrom?: boolean | null;
              to?: string | null;
              excludeTo?: boolean | null;
            } | null;
          }>;
        }>;
      } | null;
    }>;
    fallbackLocationsMatch?: {
      __typename?: 'LocationFilterUnion';
      anyOf: Array<{
        __typename?: 'LocationFilterIntersection';
        id?: string | null;
        allOf: Array<{
          __typename?: 'LocationFilter';
          type: LocationFilterType;
          isNull?: boolean | null;
          isNot?: boolean | null;
          valueIn?: Array<{ __typename?: 'FilterValue'; title: string }> | null;
          range?: {
            __typename?: 'NumberFilterRange';
            from?: number | null;
            to?: number | null;
          } | null;
          stringRange?: {
            __typename?: 'StringFilterRange';
            from?: string | null;
            excludeFrom?: boolean | null;
            to?: string | null;
            excludeTo?: boolean | null;
          } | null;
        }>;
      }>;
    } | null;
  } | null;
  locationSharingPolicy?: {
    __typename?: 'LocationSharingPolicy';
    defaultMaxItemsPerLocation?: number | null;
    rules?: Array<{
      __typename?: 'LocationSharingPolicyRule';
      id: string;
      title?: string | null;
      maxItemsPerLocation: number;
      locationsMatch?: {
        __typename?: 'LocationFilterUnion';
        anyOf: Array<{
          __typename?: 'LocationFilterIntersection';
          id?: string | null;
          allOf: Array<{
            __typename?: 'LocationFilter';
            type: LocationFilterType;
            isNull?: boolean | null;
            isNot?: boolean | null;
            valueIn?: Array<{
              __typename?: 'FilterValue';
              title: string;
            }> | null;
            range?: {
              __typename?: 'NumberFilterRange';
              from?: number | null;
              to?: number | null;
            } | null;
            stringRange?: {
              __typename?: 'StringFilterRange';
              from?: string | null;
              excludeFrom?: boolean | null;
              to?: string | null;
              excludeTo?: boolean | null;
            } | null;
          }>;
        }>;
      } | null;
    }> | null;
  } | null;
  resourcePolicy?: {
    __typename?: 'ResourcePolicy';
    agents: Array<{
      __typename?: 'AgentSettings';
      id: string;
      title?: string | null;
      resourceTypeId: string;
      metadata?: any | null;
      roles?: Array<{
        __typename?: 'AgentRoleSettings';
        roleId: AgentRole;
        disabled?: boolean | null;
        preHandling?: {
          __typename?: 'PicklistProcessingEventSettings';
          timeFixed?: number | null;
          timePerOrder?: number | null;
          timePerPicklistLine?: number | null;
          timePerUom?: number | null;
        } | null;
        handlingPreparation?: {
          __typename?: 'PicklistLineProcessingEventSettings';
          timeFixed?: number | null;
          timePerUom?: number | null;
        } | null;
        handling?: {
          __typename?: 'HandlingEventSettings';
          timeFixed?: number | null;
          timePerWeight?: number | null;
          timePerVolume?: number | null;
          timePerUom?: Array<{
            __typename?: 'UomHandlingSettings';
            uom: string;
            timePerUom?: number | null;
          }> | null;
        } | null;
        reordering?: {
          __typename?: 'ReorderEventSettings';
          timeFixed?: number | null;
          timePerReorderedPosition?: number | null;
          timePerWeight?: number | null;
          timePerVolume?: number | null;
          timePerUom?: Array<{
            __typename?: 'UomHandlingSettings';
            uom: string;
            timePerUom?: number | null;
          }> | null;
        } | null;
        handlingCompletion?: {
          __typename?: 'PicklistLineProcessingEventSettings';
          timeFixed?: number | null;
          timePerUom?: number | null;
        } | null;
        postHandling?: {
          __typename?: 'PicklistProcessingEventSettings';
          timeFixed?: number | null;
          timePerOrder?: number | null;
          timePerPicklistLine?: number | null;
          timePerUom?: number | null;
        } | null;
      }> | null;
      specs?: {
        __typename?: 'AgentSpecs';
        horizontalTravelling?: {
          __typename?: 'HorizontalTravellingEventSettings';
          speed?: {
            __typename?: 'SpeedSettings';
            speedLaden?: number | null;
            speedUnladen?: number | null;
          } | null;
        } | null;
        verticalTravelling?: {
          __typename?: 'VerticalTravellingEventSettings';
          speedUp?: {
            __typename?: 'SpeedSettings';
            speedLaden?: number | null;
            speedUnladen?: number | null;
          } | null;
          speedDown?: {
            __typename?: 'SpeedSettings';
            speedLaden?: number | null;
            speedUnladen?: number | null;
          } | null;
        } | null;
        capacity?: {
          __typename?: 'AgentCapacitySettings';
          maxVolume?: number | null;
          maxWeight?: number | null;
          maxUomQuantity?: Array<{
            __typename?: 'AgentCapacityUomSettings';
            uom: string;
            quantity?: number | null;
          }> | null;
        } | null;
        powerSource?: {
          __typename?: 'AgentPowerSourceSettings';
          energyConsumptionPerSecond?: number | null;
        } | null;
      } | null;
      cost?: {
        __typename?: 'AgentCostSettings';
        costPerSecond?: number | null;
      } | null;
      utilisation?: {
        __typename?: 'AgentUtilisationSettings';
        agentAmount?: number | null;
        secondsOperation?: number | null;
      } | null;
    }>;
  } | null;
  waypointPolicy?: {
    __typename?: 'WaypointPolicy';
    rules?: Array<{
      __typename?: 'WaypointPolicyRule';
      id: string;
      title?: string | null;
      startPoints?: {
        __typename?: 'WaypointPolicyRuleTerminalPoints';
        locationsMatch?: {
          __typename?: 'LocationFilterUnion';
          anyOf: Array<{
            __typename?: 'LocationFilterIntersection';
            id?: string | null;
            allOf: Array<{
              __typename?: 'LocationFilter';
              type: LocationFilterType;
              isNull?: boolean | null;
              isNot?: boolean | null;
              valueIn?: Array<{
                __typename?: 'FilterValue';
                title: string;
              }> | null;
              range?: {
                __typename?: 'NumberFilterRange';
                from?: number | null;
                to?: number | null;
              } | null;
              stringRange?: {
                __typename?: 'StringFilterRange';
                from?: string | null;
                excludeFrom?: boolean | null;
                to?: string | null;
                excludeTo?: boolean | null;
              } | null;
            }>;
          }>;
        } | null;
      } | null;
      endPoints?: {
        __typename?: 'WaypointPolicyRuleTerminalPoints';
        locationsMatch?: {
          __typename?: 'LocationFilterUnion';
          anyOf: Array<{
            __typename?: 'LocationFilterIntersection';
            id?: string | null;
            allOf: Array<{
              __typename?: 'LocationFilter';
              type: LocationFilterType;
              isNull?: boolean | null;
              isNot?: boolean | null;
              valueIn?: Array<{
                __typename?: 'FilterValue';
                title: string;
              }> | null;
              range?: {
                __typename?: 'NumberFilterRange';
                from?: number | null;
                to?: number | null;
              } | null;
              stringRange?: {
                __typename?: 'StringFilterRange';
                from?: string | null;
                excludeFrom?: boolean | null;
                to?: string | null;
                excludeTo?: boolean | null;
              } | null;
            }>;
          }>;
        } | null;
      } | null;
    }> | null;
  } | null;
  pickingPolicy?: {
    __typename?: 'PickingPolicy';
    orderLineRules?: Array<{
      __typename?: 'PickingPolicyOrderLineRule';
      id: string;
      title?: string | null;
      usePickingContainer?: boolean | null;
      pickingRequirementsSettings?: {
        __typename?: 'PickingPolicyPickingRequirementsSettings';
        groupBy: PickingRequirementsGroupBy;
        startLocationIdSource?: PickingRequirementTerminalLocationSource | null;
        endLocationIdSource?: PickingRequirementTerminalLocationSource | null;
        wave?: {
          __typename?: 'PickingWaveSettings';
          useDeliveryRoute?: boolean | null;
          useDock?: boolean | null;
          useStagingArea?: boolean | null;
          useWaveId?: boolean | null;
          pickByDateSettings?: {
            __typename?: 'PickingWavePickByDateSettings';
            mode: PickingWavePickByDateMode;
            fixedDeadlines?: Array<any> | null;
          } | null;
        } | null;
        batch?: {
          __typename?: 'PickingRequirementsBatchSettings';
          maxOrderCount?: number | null;
          maxOrderLineCount?: number | null;
        } | null;
      } | null;
      orderLinesMatch?: {
        __typename?: 'OrderLineFilterUnion';
        anyOf: Array<{
          __typename?: 'OrderLineFilterIntersection';
          id?: string | null;
          allOf: Array<{
            __typename?: 'OrderLineFilter';
            type: OrderLineFilterType;
            isNull?: boolean | null;
            isNot?: boolean | null;
            valueIn?: Array<{
              __typename?: 'FilterValue';
              title: string;
            }> | null;
            range?: {
              __typename?: 'NumberFilterRange';
              from?: number | null;
              to?: number | null;
            } | null;
            stringRange?: {
              __typename?: 'StringFilterRange';
              from?: string | null;
              excludeFrom?: boolean | null;
              to?: string | null;
              excludeTo?: boolean | null;
            } | null;
          }>;
        }>;
      } | null;
      pickingRules?: Array<{
        __typename?: 'PickingPolicyPickingRule';
        id: string;
        title?: string | null;
        uomTypes?: Array<string> | null;
        agentId: string;
        locationSelectionMode?: PickingPolicyLocationSelectionMode | null;
        picklistGroupKey?: string | null;
        locationsMatch?: {
          __typename?: 'LocationFilterUnion';
          anyOf: Array<{
            __typename?: 'LocationFilterIntersection';
            id?: string | null;
            allOf: Array<{
              __typename?: 'LocationFilter';
              type: LocationFilterType;
              isNull?: boolean | null;
              isNot?: boolean | null;
              valueIn?: Array<{
                __typename?: 'FilterValue';
                title: string;
              }> | null;
              range?: {
                __typename?: 'NumberFilterRange';
                from?: number | null;
                to?: number | null;
              } | null;
              stringRange?: {
                __typename?: 'StringFilterRange';
                from?: string | null;
                excludeFrom?: boolean | null;
                to?: string | null;
                excludeTo?: boolean | null;
              } | null;
            }>;
          }>;
        } | null;
        waypointSettings?: {
          __typename?: 'PickingPolicyWaypointSettings';
          ruleId?: string | null;
        } | null;
      }> | null;
      picklistSettings?: {
        __typename?: 'PickingPolicyPicklistSettings';
        usePickingPriority?: boolean | null;
        picklistLinesOrder?: Array<{
          __typename?: 'PicklistLinesSortBy';
          type: PicklistLinesOrderOptions;
          direction: PicklistLinesSortDirection;
          nullsFirst?: boolean | null;
        }> | null;
      } | null;
    }> | null;
  } | null;
  allocationSettings?: {
    __typename?: 'AllocationSettings';
    allocationPolicy?: {
      __typename?: 'AllocationPolicy';
      rules?: Array<{
        __typename?: 'AllocationRule';
        id: string;
        title?: string | null;
        uomTypes?: Array<string> | null;
        itemsMatch?: {
          __typename?: 'SimulationItemFilterUnion';
          anyOf: Array<{
            __typename?: 'SimulationItemFilterIntersection';
            id?: string | null;
            allOf: Array<{
              __typename?: 'SimulationItemFilter';
              type: SimulationItemFilterType;
              isNull?: boolean | null;
              isNot?: boolean | null;
              valueIn?: Array<{
                __typename?: 'FilterValue';
                title: string;
              }> | null;
              range?: {
                __typename?: 'NumberFilterRange';
                from?: number | null;
                to?: number | null;
              } | null;
              stringRange?: {
                __typename?: 'StringFilterRange';
                from?: string | null;
                excludeFrom?: boolean | null;
                to?: string | null;
                excludeTo?: boolean | null;
              } | null;
            }>;
          }>;
        } | null;
        locationsMatch?: {
          __typename?: 'LocationFilterUnion';
          anyOf: Array<{
            __typename?: 'LocationFilterIntersection';
            id?: string | null;
            allOf: Array<{
              __typename?: 'LocationFilter';
              type: LocationFilterType;
              isNull?: boolean | null;
              isNot?: boolean | null;
              valueIn?: Array<{
                __typename?: 'FilterValue';
                title: string;
              }> | null;
              range?: {
                __typename?: 'NumberFilterRange';
                from?: number | null;
                to?: number | null;
              } | null;
              stringRange?: {
                __typename?: 'StringFilterRange';
                from?: string | null;
                excludeFrom?: boolean | null;
                to?: string | null;
                excludeTo?: boolean | null;
              } | null;
            }>;
          }>;
        } | null;
        limitSettings?: {
          __typename?: 'AllocationLimitSettings';
          applyTo?: AllocationLimitApplyTo | null;
          maxPickableAssignments?: number | null;
          maxAllocatedAssignments?: number | null;
          maxPickableVolume?: number | null;
          maxAllocatedVolume?: number | null;
        } | null;
        roundSettings?: {
          __typename?: 'AllocationRoundSettings';
          maxAssignmentsPerRound?: number | null;
        } | null;
        pickabilitySettings?: {
          __typename?: 'AllocationPickabilitySettings';
          enforcePickability?: AllocationEnforcePickability | null;
        } | null;
        prioritySettings?: {
          __typename?: 'AllocationPrioritySettings';
          itemPriority?: Array<{
            __typename?: 'AllocationItemPrioritySpec';
            priority: AllocationItemPriority;
            direction?: AllocationPriorityDirection | null;
          }> | null;
          locationPriority?: Array<{
            __typename?: 'AllocationLocationPrioritySpec';
            priority: AllocationLocationPriority;
            direction?: AllocationPriorityDirection | null;
          }> | null;
        } | null;
        replacementSettings?: {
          __typename?: 'AllocationReplacementSettings';
          allow?: AllocationAllowReplacement | null;
        } | null;
        multiplexingSettings?: {
          __typename?: 'AllocationMultiplexingSettings';
          allow?: AllocationAllowReplacement | null;
        } | null;
        requirementAggregationSettings?: {
          __typename?: 'AllocationRequirementAggregationSettings';
          aggregation?: AllocationRequirementAggregationMethod | null;
          numberOfPeriods?: number | null;
        } | null;
        constraintSettings?: {
          __typename?: 'AllocationConstraintSettings';
          assignmentPolicyConstraint?: {
            __typename?: 'AssignmentPolicyConstraintSettings';
            disabled?: boolean | null;
          } | null;
          locationSizeConstraint?: {
            __typename?: 'LocationSizeConstraintSettings';
            disabled?: boolean | null;
            unknownAsNoncompliant?: boolean | null;
          } | null;
          bayWidthConstraint?: {
            __typename?: 'BayWidthConstraintSettings';
            disabled?: boolean | null;
          } | null;
        } | null;
      }> | null;
      defaultRule?: {
        __typename?: 'AllocationRule';
        id: string;
        title?: string | null;
        uomTypes?: Array<string> | null;
        itemsMatch?: {
          __typename?: 'SimulationItemFilterUnion';
          anyOf: Array<{
            __typename?: 'SimulationItemFilterIntersection';
            id?: string | null;
            allOf: Array<{
              __typename?: 'SimulationItemFilter';
              type: SimulationItemFilterType;
              isNull?: boolean | null;
              isNot?: boolean | null;
              valueIn?: Array<{
                __typename?: 'FilterValue';
                title: string;
              }> | null;
              range?: {
                __typename?: 'NumberFilterRange';
                from?: number | null;
                to?: number | null;
              } | null;
              stringRange?: {
                __typename?: 'StringFilterRange';
                from?: string | null;
                excludeFrom?: boolean | null;
                to?: string | null;
                excludeTo?: boolean | null;
              } | null;
            }>;
          }>;
        } | null;
        locationsMatch?: {
          __typename?: 'LocationFilterUnion';
          anyOf: Array<{
            __typename?: 'LocationFilterIntersection';
            id?: string | null;
            allOf: Array<{
              __typename?: 'LocationFilter';
              type: LocationFilterType;
              isNull?: boolean | null;
              isNot?: boolean | null;
              valueIn?: Array<{
                __typename?: 'FilterValue';
                title: string;
              }> | null;
              range?: {
                __typename?: 'NumberFilterRange';
                from?: number | null;
                to?: number | null;
              } | null;
              stringRange?: {
                __typename?: 'StringFilterRange';
                from?: string | null;
                excludeFrom?: boolean | null;
                to?: string | null;
                excludeTo?: boolean | null;
              } | null;
            }>;
          }>;
        } | null;
        limitSettings?: {
          __typename?: 'AllocationLimitSettings';
          applyTo?: AllocationLimitApplyTo | null;
          maxPickableAssignments?: number | null;
          maxAllocatedAssignments?: number | null;
          maxPickableVolume?: number | null;
          maxAllocatedVolume?: number | null;
        } | null;
        roundSettings?: {
          __typename?: 'AllocationRoundSettings';
          maxAssignmentsPerRound?: number | null;
        } | null;
        pickabilitySettings?: {
          __typename?: 'AllocationPickabilitySettings';
          enforcePickability?: AllocationEnforcePickability | null;
        } | null;
        prioritySettings?: {
          __typename?: 'AllocationPrioritySettings';
          itemPriority?: Array<{
            __typename?: 'AllocationItemPrioritySpec';
            priority: AllocationItemPriority;
            direction?: AllocationPriorityDirection | null;
          }> | null;
          locationPriority?: Array<{
            __typename?: 'AllocationLocationPrioritySpec';
            priority: AllocationLocationPriority;
            direction?: AllocationPriorityDirection | null;
          }> | null;
        } | null;
        replacementSettings?: {
          __typename?: 'AllocationReplacementSettings';
          allow?: AllocationAllowReplacement | null;
        } | null;
        multiplexingSettings?: {
          __typename?: 'AllocationMultiplexingSettings';
          allow?: AllocationAllowReplacement | null;
        } | null;
        requirementAggregationSettings?: {
          __typename?: 'AllocationRequirementAggregationSettings';
          aggregation?: AllocationRequirementAggregationMethod | null;
          numberOfPeriods?: number | null;
        } | null;
        constraintSettings?: {
          __typename?: 'AllocationConstraintSettings';
          assignmentPolicyConstraint?: {
            __typename?: 'AssignmentPolicyConstraintSettings';
            disabled?: boolean | null;
          } | null;
          locationSizeConstraint?: {
            __typename?: 'LocationSizeConstraintSettings';
            disabled?: boolean | null;
            unknownAsNoncompliant?: boolean | null;
          } | null;
          bayWidthConstraint?: {
            __typename?: 'BayWidthConstraintSettings';
            disabled?: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
    deallocationPolicy?: {
      __typename?: 'DeallocationPolicy';
      rules?: Array<{
        __typename?: 'DeallocationRule';
        id?: string | null;
        title?: string | null;
        deallocateType?: DeallocateType | null;
        reallocateType?: ReallocateType | null;
        itemsMatch?: {
          __typename?: 'SimulationItemFilterUnion';
          anyOf: Array<{
            __typename?: 'SimulationItemFilterIntersection';
            id?: string | null;
            allOf: Array<{
              __typename?: 'SimulationItemFilter';
              type: SimulationItemFilterType;
              isNull?: boolean | null;
              isNot?: boolean | null;
              valueIn?: Array<{
                __typename?: 'FilterValue';
                title: string;
              }> | null;
              range?: {
                __typename?: 'NumberFilterRange';
                from?: number | null;
                to?: number | null;
              } | null;
              stringRange?: {
                __typename?: 'StringFilterRange';
                from?: string | null;
                excludeFrom?: boolean | null;
                to?: string | null;
                excludeTo?: boolean | null;
              } | null;
            }>;
          }>;
        } | null;
        locationsMatch?: {
          __typename?: 'LocationFilterUnion';
          anyOf: Array<{
            __typename?: 'LocationFilterIntersection';
            id?: string | null;
            allOf: Array<{
              __typename?: 'LocationFilter';
              type: LocationFilterType;
              isNull?: boolean | null;
              isNot?: boolean | null;
              valueIn?: Array<{
                __typename?: 'FilterValue';
                title: string;
              }> | null;
              range?: {
                __typename?: 'NumberFilterRange';
                from?: number | null;
                to?: number | null;
              } | null;
              stringRange?: {
                __typename?: 'StringFilterRange';
                from?: string | null;
                excludeFrom?: boolean | null;
                to?: string | null;
                excludeTo?: boolean | null;
              } | null;
            }>;
          }>;
        } | null;
        constraintSettings?: {
          __typename?: 'AllocationConstraintSettings';
          assignmentPolicyConstraint?: {
            __typename?: 'AssignmentPolicyConstraintSettings';
            disabled?: boolean | null;
          } | null;
          locationSizeConstraint?: {
            __typename?: 'LocationSizeConstraintSettings';
            disabled?: boolean | null;
            unknownAsNoncompliant?: boolean | null;
          } | null;
          bayWidthConstraint?: {
            __typename?: 'BayWidthConstraintSettings';
            disabled?: boolean | null;
          } | null;
        } | null;
        reallocateQuantitySettings?: {
          __typename?: 'ReallocateQuantitySettings';
          reallocateUom?: ReallocateUomSource | null;
          reallocateQuantity?: ReallocateQuantitySource | null;
          fixedUom?: string | null;
          fixedUomQuantity?: number | null;
        } | null;
      }> | null;
      defaultRule?: {
        __typename?: 'DeallocationRule';
        id?: string | null;
        title?: string | null;
        deallocateType?: DeallocateType | null;
        reallocateType?: ReallocateType | null;
        itemsMatch?: {
          __typename?: 'SimulationItemFilterUnion';
          anyOf: Array<{
            __typename?: 'SimulationItemFilterIntersection';
            id?: string | null;
            allOf: Array<{
              __typename?: 'SimulationItemFilter';
              type: SimulationItemFilterType;
              isNull?: boolean | null;
              isNot?: boolean | null;
              valueIn?: Array<{
                __typename?: 'FilterValue';
                title: string;
              }> | null;
              range?: {
                __typename?: 'NumberFilterRange';
                from?: number | null;
                to?: number | null;
              } | null;
              stringRange?: {
                __typename?: 'StringFilterRange';
                from?: string | null;
                excludeFrom?: boolean | null;
                to?: string | null;
                excludeTo?: boolean | null;
              } | null;
            }>;
          }>;
        } | null;
        locationsMatch?: {
          __typename?: 'LocationFilterUnion';
          anyOf: Array<{
            __typename?: 'LocationFilterIntersection';
            id?: string | null;
            allOf: Array<{
              __typename?: 'LocationFilter';
              type: LocationFilterType;
              isNull?: boolean | null;
              isNot?: boolean | null;
              valueIn?: Array<{
                __typename?: 'FilterValue';
                title: string;
              }> | null;
              range?: {
                __typename?: 'NumberFilterRange';
                from?: number | null;
                to?: number | null;
              } | null;
              stringRange?: {
                __typename?: 'StringFilterRange';
                from?: string | null;
                excludeFrom?: boolean | null;
                to?: string | null;
                excludeTo?: boolean | null;
              } | null;
            }>;
          }>;
        } | null;
        constraintSettings?: {
          __typename?: 'AllocationConstraintSettings';
          assignmentPolicyConstraint?: {
            __typename?: 'AssignmentPolicyConstraintSettings';
            disabled?: boolean | null;
          } | null;
          locationSizeConstraint?: {
            __typename?: 'LocationSizeConstraintSettings';
            disabled?: boolean | null;
            unknownAsNoncompliant?: boolean | null;
          } | null;
          bayWidthConstraint?: {
            __typename?: 'BayWidthConstraintSettings';
            disabled?: boolean | null;
          } | null;
        } | null;
        reallocateQuantitySettings?: {
          __typename?: 'ReallocateQuantitySettings';
          reallocateUom?: ReallocateUomSource | null;
          reallocateQuantity?: ReallocateQuantitySource | null;
          fixedUom?: string | null;
          fixedUomQuantity?: number | null;
        } | null;
      } | null;
    } | null;
    orderSetFilter?: {
      __typename?: 'OrderSetFilter';
      orderDate?: {
        __typename?: 'LocalDateRangeFilter';
        from?: string | null;
        to?: string | null;
        excludeFrom?: boolean | null;
        excludeTo?: boolean | null;
        includeNull?: boolean | null;
      } | null;
    } | null;
    requirementSource?: {
      __typename?: 'AllocationRequirementSourceSettings';
      useOrderSetDemand?: boolean | null;
    } | null;
    stockLimitSettings?: {
      __typename?: 'AllocationStockLimitSettings';
      enabled?: boolean | null;
    } | null;
  } | null;
  analyzeResult?: {
    __typename?: 'AnalyzeResult';
    id: string;
    status: AnalyzeResultStatus;
  } | null;
  optimizationRuns: {
    __typename?: 'OptimizationRunsConnection';
    content: Array<{
      __typename?: 'OptimizationRun';
      id: string;
      status: OptimizationRunStatus;
    }>;
  };
  optimizationSettings?: {
    __typename?: 'OptimizationSettings';
    locationSizeConstraint?: {
      __typename?: 'LocationSizeConstraintSettings';
      disabled?: boolean | null;
    } | null;
    bayWidthConstraint?: {
      __typename?: 'BayWidthConstraintSettings';
      disabled?: boolean | null;
    } | null;
    terminationSettings?: {
      __typename?: 'TerminationSettings';
      targetImprovement?: number | null;
      timeLimit?: number | null;
      minImprovementRate?: {
        __typename?: 'MinImprovementRateSettings';
        minImprovementChange: number;
        steps: number;
      } | null;
    } | null;
    swapSettings?: {
      __typename?: 'SwapSettings';
      rules?: Array<{
        __typename?: 'OptimizationSwapRule';
        id?: string | null;
        title?: string | null;
        src?: {
          __typename?: 'AssignmentSubsetSpec';
          unassignedLocationsMatch?: UnassignedLocationsMatch | null;
          locationsMatch?: {
            __typename?: 'LocationFilterUnion';
            anyOf: Array<{
              __typename?: 'LocationFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'LocationFilter';
                type: LocationFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
          itemsMatch?: {
            __typename?: 'SimulationItemFilterUnion';
            anyOf: Array<{
              __typename?: 'SimulationItemFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'SimulationItemFilter';
                type: SimulationItemFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
        } | null;
        dest?: {
          __typename?: 'AssignmentSubsetSpec';
          unassignedLocationsMatch?: UnassignedLocationsMatch | null;
          locationsMatch?: {
            __typename?: 'LocationFilterUnion';
            anyOf: Array<{
              __typename?: 'LocationFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'LocationFilter';
                type: LocationFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
          itemsMatch?: {
            __typename?: 'SimulationItemFilterUnion';
            anyOf: Array<{
              __typename?: 'SimulationItemFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'SimulationItemFilter';
                type: SimulationItemFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
        } | null;
      }> | null;
    } | null;
    loadBalancing?: {
      __typename?: 'LoadBalancingPolicy';
      rules: Array<{
        __typename?: 'LoadBalancingRule';
        id?: string | null;
        title?: string | null;
        type: LoadBalancingZoneType;
        objectiveWeight?: number | null;
        constraint?: {
          __typename?: 'LoadBalancingConstraint';
          maxRelativeDeviation: number;
        } | null;
        include?: {
          __typename?: 'LocationFilterUnion';
          anyOf: Array<{
            __typename?: 'LocationFilterIntersection';
            id?: string | null;
            allOf: Array<{
              __typename?: 'LocationFilter';
              type: LocationFilterType;
              isNull?: boolean | null;
              isNot?: boolean | null;
              valueIn?: Array<{
                __typename?: 'FilterValue';
                title: string;
              }> | null;
              range?: {
                __typename?: 'NumberFilterRange';
                from?: number | null;
                to?: number | null;
              } | null;
              stringRange?: {
                __typename?: 'StringFilterRange';
                from?: string | null;
                excludeFrom?: boolean | null;
                to?: string | null;
                excludeTo?: boolean | null;
              } | null;
            }>;
          }>;
        } | null;
      }>;
    } | null;
    limitSettings?: {
      __typename?: 'LimitSettings';
      maxMoves?: number | null;
    } | null;
    reassignJobs?: {
      __typename?: 'ReassignJobsSettings';
      disabled?: boolean | null;
      cycleMode?: ReassignJobCycleMode | null;
    } | null;
    policyOverrides?: {
      __typename?: 'OptimizationPolicyOverrides';
      resourcePolicy?: {
        __typename?: 'ResourcePolicy';
        agents: Array<{
          __typename?: 'AgentSettings';
          id: string;
          title?: string | null;
          resourceTypeId: string;
          metadata?: any | null;
          roles?: Array<{
            __typename?: 'AgentRoleSettings';
            roleId: AgentRole;
            disabled?: boolean | null;
            preHandling?: {
              __typename?: 'PicklistProcessingEventSettings';
              timeFixed?: number | null;
              timePerOrder?: number | null;
              timePerPicklistLine?: number | null;
              timePerUom?: number | null;
            } | null;
            handlingPreparation?: {
              __typename?: 'PicklistLineProcessingEventSettings';
              timeFixed?: number | null;
              timePerUom?: number | null;
            } | null;
            handling?: {
              __typename?: 'HandlingEventSettings';
              timeFixed?: number | null;
              timePerWeight?: number | null;
              timePerVolume?: number | null;
              timePerUom?: Array<{
                __typename?: 'UomHandlingSettings';
                uom: string;
                timePerUom?: number | null;
              }> | null;
            } | null;
            reordering?: {
              __typename?: 'ReorderEventSettings';
              timeFixed?: number | null;
              timePerReorderedPosition?: number | null;
              timePerWeight?: number | null;
              timePerVolume?: number | null;
              timePerUom?: Array<{
                __typename?: 'UomHandlingSettings';
                uom: string;
                timePerUom?: number | null;
              }> | null;
            } | null;
            handlingCompletion?: {
              __typename?: 'PicklistLineProcessingEventSettings';
              timeFixed?: number | null;
              timePerUom?: number | null;
            } | null;
            postHandling?: {
              __typename?: 'PicklistProcessingEventSettings';
              timeFixed?: number | null;
              timePerOrder?: number | null;
              timePerPicklistLine?: number | null;
              timePerUom?: number | null;
            } | null;
          }> | null;
          specs?: {
            __typename?: 'AgentSpecs';
            horizontalTravelling?: {
              __typename?: 'HorizontalTravellingEventSettings';
              speed?: {
                __typename?: 'SpeedSettings';
                speedLaden?: number | null;
                speedUnladen?: number | null;
              } | null;
            } | null;
            verticalTravelling?: {
              __typename?: 'VerticalTravellingEventSettings';
              speedUp?: {
                __typename?: 'SpeedSettings';
                speedLaden?: number | null;
                speedUnladen?: number | null;
              } | null;
              speedDown?: {
                __typename?: 'SpeedSettings';
                speedLaden?: number | null;
                speedUnladen?: number | null;
              } | null;
            } | null;
            capacity?: {
              __typename?: 'AgentCapacitySettings';
              maxVolume?: number | null;
              maxWeight?: number | null;
              maxUomQuantity?: Array<{
                __typename?: 'AgentCapacityUomSettings';
                uom: string;
                quantity?: number | null;
              }> | null;
            } | null;
            powerSource?: {
              __typename?: 'AgentPowerSourceSettings';
              energyConsumptionPerSecond?: number | null;
            } | null;
          } | null;
          cost?: {
            __typename?: 'AgentCostSettings';
            costPerSecond?: number | null;
          } | null;
          utilisation?: {
            __typename?: 'AgentUtilisationSettings';
            agentAmount?: number | null;
            secondsOperation?: number | null;
          } | null;
        }>;
      } | null;
    } | null;
    pickabilityConstraint?: {
      __typename?: 'PickabilityConstraintSettings';
      disabled?: boolean | null;
    } | null;
    assignmentCapacitySettings?: {
      __typename?: 'AssignmentCapacitySettings';
      avoidMerge?: boolean | null;
    } | null;
  } | null;
  latestAllocationRun?: {
    __typename?: 'AllocationRun';
    id: string;
    progress?: number | null;
    seed?: number | null;
    status: BatchJobStatus;
    startedAt?: number | null;
    completedAt?: number | null;
    createdAt: number;
    allocationRunType: AllocationType;
    allocationSettings?: {
      __typename?: 'AllocationSettings';
      allocationPolicy?: {
        __typename?: 'AllocationPolicy';
        rules?: Array<{
          __typename?: 'AllocationRule';
          id: string;
          title?: string | null;
          uomTypes?: Array<string> | null;
          itemsMatch?: {
            __typename?: 'SimulationItemFilterUnion';
            anyOf: Array<{
              __typename?: 'SimulationItemFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'SimulationItemFilter';
                type: SimulationItemFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
          locationsMatch?: {
            __typename?: 'LocationFilterUnion';
            anyOf: Array<{
              __typename?: 'LocationFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'LocationFilter';
                type: LocationFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
          limitSettings?: {
            __typename?: 'AllocationLimitSettings';
            applyTo?: AllocationLimitApplyTo | null;
            maxPickableAssignments?: number | null;
            maxAllocatedAssignments?: number | null;
            maxPickableVolume?: number | null;
            maxAllocatedVolume?: number | null;
          } | null;
          roundSettings?: {
            __typename?: 'AllocationRoundSettings';
            maxAssignmentsPerRound?: number | null;
          } | null;
          pickabilitySettings?: {
            __typename?: 'AllocationPickabilitySettings';
            enforcePickability?: AllocationEnforcePickability | null;
          } | null;
          prioritySettings?: {
            __typename?: 'AllocationPrioritySettings';
            itemPriority?: Array<{
              __typename?: 'AllocationItemPrioritySpec';
              priority: AllocationItemPriority;
              direction?: AllocationPriorityDirection | null;
            }> | null;
            locationPriority?: Array<{
              __typename?: 'AllocationLocationPrioritySpec';
              priority: AllocationLocationPriority;
              direction?: AllocationPriorityDirection | null;
            }> | null;
          } | null;
          replacementSettings?: {
            __typename?: 'AllocationReplacementSettings';
            allow?: AllocationAllowReplacement | null;
          } | null;
          multiplexingSettings?: {
            __typename?: 'AllocationMultiplexingSettings';
            allow?: AllocationAllowReplacement | null;
          } | null;
          requirementAggregationSettings?: {
            __typename?: 'AllocationRequirementAggregationSettings';
            aggregation?: AllocationRequirementAggregationMethod | null;
            numberOfPeriods?: number | null;
          } | null;
          constraintSettings?: {
            __typename?: 'AllocationConstraintSettings';
            assignmentPolicyConstraint?: {
              __typename?: 'AssignmentPolicyConstraintSettings';
              disabled?: boolean | null;
            } | null;
            locationSizeConstraint?: {
              __typename?: 'LocationSizeConstraintSettings';
              disabled?: boolean | null;
              unknownAsNoncompliant?: boolean | null;
            } | null;
            bayWidthConstraint?: {
              __typename?: 'BayWidthConstraintSettings';
              disabled?: boolean | null;
            } | null;
          } | null;
        }> | null;
        defaultRule?: {
          __typename?: 'AllocationRule';
          id: string;
          title?: string | null;
          uomTypes?: Array<string> | null;
          itemsMatch?: {
            __typename?: 'SimulationItemFilterUnion';
            anyOf: Array<{
              __typename?: 'SimulationItemFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'SimulationItemFilter';
                type: SimulationItemFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
          locationsMatch?: {
            __typename?: 'LocationFilterUnion';
            anyOf: Array<{
              __typename?: 'LocationFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'LocationFilter';
                type: LocationFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
          limitSettings?: {
            __typename?: 'AllocationLimitSettings';
            applyTo?: AllocationLimitApplyTo | null;
            maxPickableAssignments?: number | null;
            maxAllocatedAssignments?: number | null;
            maxPickableVolume?: number | null;
            maxAllocatedVolume?: number | null;
          } | null;
          roundSettings?: {
            __typename?: 'AllocationRoundSettings';
            maxAssignmentsPerRound?: number | null;
          } | null;
          pickabilitySettings?: {
            __typename?: 'AllocationPickabilitySettings';
            enforcePickability?: AllocationEnforcePickability | null;
          } | null;
          prioritySettings?: {
            __typename?: 'AllocationPrioritySettings';
            itemPriority?: Array<{
              __typename?: 'AllocationItemPrioritySpec';
              priority: AllocationItemPriority;
              direction?: AllocationPriorityDirection | null;
            }> | null;
            locationPriority?: Array<{
              __typename?: 'AllocationLocationPrioritySpec';
              priority: AllocationLocationPriority;
              direction?: AllocationPriorityDirection | null;
            }> | null;
          } | null;
          replacementSettings?: {
            __typename?: 'AllocationReplacementSettings';
            allow?: AllocationAllowReplacement | null;
          } | null;
          multiplexingSettings?: {
            __typename?: 'AllocationMultiplexingSettings';
            allow?: AllocationAllowReplacement | null;
          } | null;
          requirementAggregationSettings?: {
            __typename?: 'AllocationRequirementAggregationSettings';
            aggregation?: AllocationRequirementAggregationMethod | null;
            numberOfPeriods?: number | null;
          } | null;
          constraintSettings?: {
            __typename?: 'AllocationConstraintSettings';
            assignmentPolicyConstraint?: {
              __typename?: 'AssignmentPolicyConstraintSettings';
              disabled?: boolean | null;
            } | null;
            locationSizeConstraint?: {
              __typename?: 'LocationSizeConstraintSettings';
              disabled?: boolean | null;
              unknownAsNoncompliant?: boolean | null;
            } | null;
            bayWidthConstraint?: {
              __typename?: 'BayWidthConstraintSettings';
              disabled?: boolean | null;
            } | null;
          } | null;
        } | null;
      } | null;
      deallocationPolicy?: {
        __typename?: 'DeallocationPolicy';
        rules?: Array<{
          __typename?: 'DeallocationRule';
          id?: string | null;
          title?: string | null;
          deallocateType?: DeallocateType | null;
          reallocateType?: ReallocateType | null;
          itemsMatch?: {
            __typename?: 'SimulationItemFilterUnion';
            anyOf: Array<{
              __typename?: 'SimulationItemFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'SimulationItemFilter';
                type: SimulationItemFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
          locationsMatch?: {
            __typename?: 'LocationFilterUnion';
            anyOf: Array<{
              __typename?: 'LocationFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'LocationFilter';
                type: LocationFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
          constraintSettings?: {
            __typename?: 'AllocationConstraintSettings';
            assignmentPolicyConstraint?: {
              __typename?: 'AssignmentPolicyConstraintSettings';
              disabled?: boolean | null;
            } | null;
            locationSizeConstraint?: {
              __typename?: 'LocationSizeConstraintSettings';
              disabled?: boolean | null;
              unknownAsNoncompliant?: boolean | null;
            } | null;
            bayWidthConstraint?: {
              __typename?: 'BayWidthConstraintSettings';
              disabled?: boolean | null;
            } | null;
          } | null;
          reallocateQuantitySettings?: {
            __typename?: 'ReallocateQuantitySettings';
            reallocateUom?: ReallocateUomSource | null;
            reallocateQuantity?: ReallocateQuantitySource | null;
            fixedUom?: string | null;
            fixedUomQuantity?: number | null;
          } | null;
        }> | null;
        defaultRule?: {
          __typename?: 'DeallocationRule';
          id?: string | null;
          title?: string | null;
          deallocateType?: DeallocateType | null;
          reallocateType?: ReallocateType | null;
          itemsMatch?: {
            __typename?: 'SimulationItemFilterUnion';
            anyOf: Array<{
              __typename?: 'SimulationItemFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'SimulationItemFilter';
                type: SimulationItemFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
          locationsMatch?: {
            __typename?: 'LocationFilterUnion';
            anyOf: Array<{
              __typename?: 'LocationFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'LocationFilter';
                type: LocationFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
          constraintSettings?: {
            __typename?: 'AllocationConstraintSettings';
            assignmentPolicyConstraint?: {
              __typename?: 'AssignmentPolicyConstraintSettings';
              disabled?: boolean | null;
            } | null;
            locationSizeConstraint?: {
              __typename?: 'LocationSizeConstraintSettings';
              disabled?: boolean | null;
              unknownAsNoncompliant?: boolean | null;
            } | null;
            bayWidthConstraint?: {
              __typename?: 'BayWidthConstraintSettings';
              disabled?: boolean | null;
            } | null;
          } | null;
          reallocateQuantitySettings?: {
            __typename?: 'ReallocateQuantitySettings';
            reallocateUom?: ReallocateUomSource | null;
            reallocateQuantity?: ReallocateQuantitySource | null;
            fixedUom?: string | null;
            fixedUomQuantity?: number | null;
          } | null;
        } | null;
      } | null;
      orderSetFilter?: {
        __typename?: 'OrderSetFilter';
        orderDate?: {
          __typename?: 'LocalDateRangeFilter';
          from?: string | null;
          to?: string | null;
          excludeFrom?: boolean | null;
          excludeTo?: boolean | null;
          includeNull?: boolean | null;
        } | null;
      } | null;
      requirementSource?: {
        __typename?: 'AllocationRequirementSourceSettings';
        useOrderSetDemand?: boolean | null;
      } | null;
      stockLimitSettings?: {
        __typename?: 'AllocationStockLimitSettings';
        enabled?: boolean | null;
      } | null;
    } | null;
    resultAssignment?: { __typename?: 'Assignment'; id: string } | null;
    analyzeResult?: {
      __typename?: 'AnalyzeResult';
      id: string;
      status: AnalyzeResultStatus;
    } | null;
    resultRequirementsSummary: Array<{
      __typename?: 'AllocationRunResultRequirementSummary';
      key: {
        __typename?: 'AllocationRunResultRequirementSummaryKey';
        itemId?: string | null;
        consignee?: string | null;
        orderLineRuleId?: string | null;
        sku?: string | null;
        skuGroup?: string | null;
        subGroup?: string | null;
        transportClass?: string | null;
        stockCategory?: string | null;
        storageClass?: string | null;
        pollutionClass?: string | null;
      };
      required?: {
        __typename?: 'AllocationRunResultRequirementQuantity';
        totalQuantity?: number | null;
        uoms?: Array<{
          __typename?: 'AllocationRequirementUomQuantity';
          uom?: string | null;
          uomQuantity: number;
        }> | null;
      } | null;
      unpickable?: {
        __typename?: 'AllocationRunResultRequirementQuantity';
        totalQuantity?: number | null;
        uoms?: Array<{
          __typename?: 'AllocationRequirementUomQuantity';
          uom?: string | null;
          uomQuantity: number;
        }> | null;
      } | null;
      initiallyPickable?: {
        __typename?: 'AllocationRunResultRequirementPickableQuantitySummary';
        totalQuantity?: number | null;
        totalLocationCount?: number | null;
        uoms?: Array<{
          __typename?: 'AllocationRequirementUomQuantity';
          uom?: string | null;
          uomQuantity: number;
          totalVolume: number;
        }> | null;
      } | null;
      initiallyPickableFulfilled?: {
        __typename?: 'AllocationRunResultRequirementPickableQuantitySummary';
        totalQuantity?: number | null;
        totalLocationCount?: number | null;
        uoms?: Array<{
          __typename?: 'AllocationRequirementUomQuantity';
          uom?: string | null;
          uomQuantity: number;
          totalVolume: number;
        }> | null;
      } | null;
      toAllocateRequired?: {
        __typename?: 'AllocationRunResultRequirementQuantity';
        totalQuantity?: number | null;
        uoms?: Array<{
          __typename?: 'AllocationRequirementUomQuantity';
          uom?: string | null;
          uomQuantity: number;
          totalVolume: number;
          aggregatedTotalVolume?: number | null;
          aggregatedQuantity?: number | null;
          aggregatedUomQuantity?: number | null;
        }> | null;
      } | null;
      toReallocate?: {
        __typename?: 'AllocationRunResultRequirementQuantity';
        totalQuantity?: number | null;
        uoms?: Array<{
          __typename?: 'AllocationRequirementUomQuantity';
          uom?: string | null;
          uomQuantity: number;
          totalVolume: number;
          aggregatedTotalVolume?: number | null;
          aggregatedQuantity?: number | null;
          aggregatedUomQuantity?: number | null;
        }> | null;
      } | null;
      toAllocate?: {
        __typename?: 'AllocationRunResultRequirementQuantity';
        totalQuantity?: number | null;
        uoms?: Array<{
          __typename?: 'AllocationRequirementUomQuantity';
          uom?: string | null;
          uomQuantity: number;
          totalVolume: number;
          aggregatedTotalVolume?: number | null;
          aggregatedQuantity?: number | null;
          aggregatedUomQuantity?: number | null;
        }> | null;
      } | null;
      allocated?: {
        __typename?: 'AllocationRunResultRequirementPickableQuantitySummary';
        totalQuantity?: number | null;
        totalLocationCount?: number | null;
        uoms?: Array<{
          __typename?: 'AllocationRequirementUomQuantity';
          uom?: string | null;
          uomQuantity: number;
          totalVolume: number;
        }> | null;
      } | null;
      allocatedShared?: {
        __typename?: 'AllocationRunResultRequirementPickableQuantitySummary';
        totalQuantity?: number | null;
        totalLocationCount?: number | null;
        uoms?: Array<{
          __typename?: 'AllocationRequirementUomQuantity';
          uom?: string | null;
          uomQuantity: number;
          totalVolume: number;
        }> | null;
      } | null;
      allocatedFulfilled?: {
        __typename?: 'AllocationRunResultRequirementPickableQuantitySummary';
        totalQuantity?: number | null;
        totalLocationCount?: number | null;
        uoms?: Array<{
          __typename?: 'AllocationRequirementUomQuantity';
          uom?: string | null;
          uomQuantity: number;
          totalVolume: number;
        }> | null;
      } | null;
      unallocated?: {
        __typename?: 'AllocationRunResultRequirementUnallocatedQuantity';
        totalQuantity?: number | null;
        reasons?: Array<AllocationRunResultUnallocatedReason> | null;
        uoms?: Array<{
          __typename?: 'AllocationRequirementUomQuantity';
          uom?: string | null;
          uomQuantity: number;
        }> | null;
      } | null;
      deallocated?: {
        __typename?: 'AllocationRunResultRequirementDeallocatedQuantitySummary';
        totalQuantity?: number | null;
        totalLocationCount?: number | null;
        reasons?: Array<AllocationRunResultDeallocatedReason> | null;
        uoms?: Array<{
          __typename?: 'AllocationRequirementUomQuantity';
          uom?: string | null;
          uomQuantity: number;
          totalVolume: number;
        }> | null;
      } | null;
    }>;
  } | null;
  sizeCompliance?: {
    __typename?: 'SizeCompliance';
    id: string;
    status: BatchJobStatus;
    summary?: {
      __typename?: 'SizeComplianceSummary';
      items: Array<{
        __typename?: 'SizeComplianceSummaryItem';
        totalCount: number;
        compliantCount: number;
        nonCompliantCount: number;
        unassignedCount: number;
        unknownCount: number;
        planeId: string;
        level: number;
      }>;
    } | null;
  } | null;
  weightCompliance?: {
    __typename?: 'WeightCompliance';
    id: string;
    status: BatchJobStatus;
    locationSummary?: {
      __typename?: 'LocationWeightComplianceSummary';
      items: Array<{
        __typename?: 'LocationWeightComplianceSummaryItem';
        totalCount: number;
        compliantCount: number;
        nonCompliantCount: number;
        unassignedCount: number;
        unknownCount: number;
        floorLevelCount: number;
        planeId: string;
        level: number;
      }>;
    } | null;
    baySummary?: {
      __typename?: 'BayWeightComplianceSummary';
      items: Array<{
        __typename?: 'BayWeightComplianceSummaryItem';
        totalCount: number;
        compliantCount: number;
        nonCompliantCount: number;
        unassignedCount: number;
        unknownCount: number;
        floorLevelCount: number;
        planeId: string;
      }>;
    } | null;
  } | null;
  orderLineSet?: {
    __typename?: 'SimulationOrderLineSet';
    id: string;
    status: BatchJobStatus;
    progress: number;
    createdAt: number;
  } | null;
  effectiveItemSet?: {
    __typename?: 'SimulationItemSet';
    id: string;
    status: BatchJobStatus;
    createdAt: number;
  } | null;
  layoutIssues?: {
    __typename?: 'LayoutIssueSet';
    status: BatchJobStatus;
    progress: number;
    summary?: {
      __typename?: 'LayoutIssueSummary';
      totalLocations: number;
      totalInvalidLocations: number;
      invalidLocations: Array<{
        __typename?: 'LayoutIssueInvalidLocationsSummary';
        planeId?: string | null;
        locationLevel?: number | null;
        reason: LayoutIssueInvalidLocationReason;
        count: number;
      }>;
    } | null;
  } | null;
  assignmentIssues?: {
    __typename?: 'AssignmentIssueSet';
    status: BatchJobStatus;
    progress: number;
    summary?: {
      __typename?: 'AssignmentIssueSummary';
      totalLocations: number;
      totalAssignedLocations: number;
      totalAssignmentItems: number;
      totalInvalidLocations: number;
      invalidLocations: Array<{
        __typename?: 'AssignmentIssueInvalidLocationsSummary';
        planeId?: string | null;
        locationLevel?: number | null;
        reason: AssignmentIssueInvalidLocationReason;
        count: number;
      }>;
    } | null;
  } | null;
  itemSetIssues?: {
    __typename?: 'ItemSetIssueSet';
    status: BatchJobStatus;
    progress: number;
    summary?: {
      __typename?: 'ItemSetIssueSummary';
      totalItems: number;
      totalUoms: number;
      totalInvalidItems: number;
      totalInvalidUoms: number;
      totalAssignedItems: number;
      unknownAssignedItems: number;
      invalidItems: Array<{
        __typename?: 'ItemSetIssueInvalidItemsSummary';
        reason: ItemSetIssueInvalidItemReason;
        count: number;
      }>;
      invalidUoms: Array<{
        __typename?: 'ItemSetIssueInvalidUomsSummary';
        reason: ItemSetIssueInvalidUomReason;
        count: number;
      }>;
    } | null;
  } | null;
  orderSetIssues?: {
    __typename?: 'OrderSetIssueSet';
    status: BatchJobStatus;
    progress: number;
    summary?: {
      __typename?: 'OrderSetIssueSummary';
      totalLocations: number;
      totalOrderLines: number;
      totalUnpickableLines: number;
      totalItems?: number | null;
      unpickableLines: Array<{
        __typename?: 'OrderSetIssueUnpickableLinesSummary';
        reason: OrderSetIssueUnpickableLineReason;
        count: number;
      }>;
      unpickableItems?: Array<{
        __typename?: 'OrderSetIssueUnpickableItemsSummary';
        reason: OrderSetIssueUnpickableLineReason;
        count: number;
      }> | null;
    } | null;
  } | null;
  allocationRequirementSet?: {
    __typename?: 'AllocationRequirementSet';
    id: string;
    status: BatchJobStatus;
    progress: number;
  } | null;
};

export type CreateSimulationMutationVariables = Exact<{
  input: CreateSimulationInput;
}>;

export type CreateSimulationMutation = {
  __typename?: 'Mutation';
  createSimulation: {
    __typename?: 'Simulation';
    title: string;
    description?: string | null;
    status: SimulationStatus;
    updatedAt: number;
    id: string;
    userTags?: Array<string> | null;
    extraSettings?: any | null;
    layout?: { __typename?: 'Layout'; id: string } | null;
    assignment?: {
      __typename?: 'Assignment';
      id: string;
      title: string;
    } | null;
    orderSet?: { __typename?: 'OrderSet'; id: string } | null;
    itemSet?: {
      __typename?: 'ItemSet';
      id: string;
      uomTypes: Array<{ __typename?: 'ItemUomType'; uom: string }>;
    } | null;
    orderedQuantityReport?: {
      __typename?: 'OrderedQuantityReport';
      id: string;
      status: BatchJobStatus;
      progress: number;
      summary?: {
        __typename?: 'OrderedQuantityReportSummary';
        enoughCount: number;
        notEnoughCount: number;
        notOrderedCount: number;
      } | null;
    } | null;
    routingPolicy?: {
      __typename?: 'RoutingPolicy';
      rules?: Array<{
        __typename?: 'RoutingPolicyAgentRule';
        id?: string | null;
        title?: string | null;
        agentIds: Array<string>;
        featureRules?: Array<{
          __typename?: 'RoutingPolicyFeatureRule';
          featureId: string;
          direction?: { __typename?: 'Vector'; x: number; y: number } | null;
          threshold?: {
            __typename?: 'RoutingPolicyDirectionThreshold';
            angle?: number | null;
            distance?: number | null;
          } | null;
        }> | null;
      }> | null;
      defaultRule?: {
        __typename?: 'RoutingPolicyRule';
        featureRules?: Array<{
          __typename?: 'RoutingPolicyFeatureRule';
          featureId: string;
          direction?: { __typename?: 'Vector'; x: number; y: number } | null;
          threshold?: {
            __typename?: 'RoutingPolicyDirectionThreshold';
            angle?: number | null;
            distance?: number | null;
          } | null;
        }> | null;
      } | null;
      threshold?: {
        __typename?: 'RoutingPolicyDirectionThreshold';
        angle?: number | null;
        distance?: number | null;
      } | null;
    } | null;
    stackingPolicy?: {
      __typename?: 'StackingPolicy';
      topRules?: Array<{
        __typename?: 'StackingPolicyRule';
        id: string;
        title?: string | null;
        itemsMatch?: {
          __typename?: 'ItemFilterUnion';
          anyOf: Array<{
            __typename?: 'ItemFilterIntersection';
            id?: string | null;
            allOf: Array<{
              __typename?: 'ItemFilter';
              type: ItemFilterType;
              isNot?: boolean | null;
              isNull?: boolean | null;
              valueIn?: Array<{
                __typename?: 'FilterValue';
                title: string;
              }> | null;
              range?: {
                __typename?: 'NumberFilterRange';
                from?: number | null;
                to?: number | null;
              } | null;
              stringRange?: {
                __typename?: 'StringFilterRange';
                from?: string | null;
                excludeFrom?: boolean | null;
                to?: string | null;
                excludeTo?: boolean | null;
              } | null;
            }>;
          }>;
        } | null;
        subcategories?: {
          __typename?: 'StackingPolicyRuleSubcategories';
          type?: StackingPolicyRuleSubcategoryType | null;
          step?: number | null;
          boundaries?: Array<number> | null;
        } | null;
      }> | null;
      bottomRules?: Array<{
        __typename?: 'StackingPolicyRule';
        id: string;
        title?: string | null;
        itemsMatch?: {
          __typename?: 'ItemFilterUnion';
          anyOf: Array<{
            __typename?: 'ItemFilterIntersection';
            id?: string | null;
            allOf: Array<{
              __typename?: 'ItemFilter';
              type: ItemFilterType;
              isNot?: boolean | null;
              isNull?: boolean | null;
              valueIn?: Array<{
                __typename?: 'FilterValue';
                title: string;
              }> | null;
              range?: {
                __typename?: 'NumberFilterRange';
                from?: number | null;
                to?: number | null;
              } | null;
              stringRange?: {
                __typename?: 'StringFilterRange';
                from?: string | null;
                excludeFrom?: boolean | null;
                to?: string | null;
                excludeTo?: boolean | null;
              } | null;
            }>;
          }>;
        } | null;
        subcategories?: {
          __typename?: 'StackingPolicyRuleSubcategories';
          type?: StackingPolicyRuleSubcategoryType | null;
          step?: number | null;
          boundaries?: Array<number> | null;
        } | null;
      }> | null;
      fallbackRule?: {
        __typename?: 'StackingPolicyFallbackRule';
        subcategories?: {
          __typename?: 'StackingPolicyRuleSubcategories';
          type?: StackingPolicyRuleSubcategoryType | null;
          step?: number | null;
          boundaries?: Array<number> | null;
        } | null;
      } | null;
    } | null;
    assignmentPolicy?: {
      __typename?: 'AssignmentPolicy';
      fallbackLocationsAllowAnyItems?: boolean | null;
      fallbackItemsAllowAnyLocations?: boolean | null;
      rules: Array<{
        __typename?: 'AssignmentPolicyRule';
        id: string;
        title?: string | null;
        type?: AssignmentPolicyRuleMatchType | null;
        productsMatch?: {
          __typename?: 'SimulationItemFilterUnion';
          anyOf: Array<{
            __typename?: 'SimulationItemFilterIntersection';
            id?: string | null;
            allOf: Array<{
              __typename?: 'SimulationItemFilter';
              type: SimulationItemFilterType;
              isNull?: boolean | null;
              isNot?: boolean | null;
              valueIn?: Array<{
                __typename?: 'FilterValue';
                title: string;
              }> | null;
              range?: {
                __typename?: 'NumberFilterRange';
                from?: number | null;
                to?: number | null;
              } | null;
              stringRange?: {
                __typename?: 'StringFilterRange';
                from?: string | null;
                excludeFrom?: boolean | null;
                to?: string | null;
                excludeTo?: boolean | null;
              } | null;
            }>;
          }>;
        } | null;
        locationsMatch?: {
          __typename?: 'LocationFilterUnion';
          anyOf: Array<{
            __typename?: 'LocationFilterIntersection';
            id?: string | null;
            allOf: Array<{
              __typename?: 'LocationFilter';
              type: LocationFilterType;
              isNull?: boolean | null;
              isNot?: boolean | null;
              valueIn?: Array<{
                __typename?: 'FilterValue';
                title: string;
              }> | null;
              range?: {
                __typename?: 'NumberFilterRange';
                from?: number | null;
                to?: number | null;
              } | null;
              stringRange?: {
                __typename?: 'StringFilterRange';
                from?: string | null;
                excludeFrom?: boolean | null;
                to?: string | null;
                excludeTo?: boolean | null;
              } | null;
            }>;
          }>;
        } | null;
      }>;
      fallbackLocationsMatch?: {
        __typename?: 'LocationFilterUnion';
        anyOf: Array<{
          __typename?: 'LocationFilterIntersection';
          id?: string | null;
          allOf: Array<{
            __typename?: 'LocationFilter';
            type: LocationFilterType;
            isNull?: boolean | null;
            isNot?: boolean | null;
            valueIn?: Array<{
              __typename?: 'FilterValue';
              title: string;
            }> | null;
            range?: {
              __typename?: 'NumberFilterRange';
              from?: number | null;
              to?: number | null;
            } | null;
            stringRange?: {
              __typename?: 'StringFilterRange';
              from?: string | null;
              excludeFrom?: boolean | null;
              to?: string | null;
              excludeTo?: boolean | null;
            } | null;
          }>;
        }>;
      } | null;
    } | null;
    locationSharingPolicy?: {
      __typename?: 'LocationSharingPolicy';
      defaultMaxItemsPerLocation?: number | null;
      rules?: Array<{
        __typename?: 'LocationSharingPolicyRule';
        id: string;
        title?: string | null;
        maxItemsPerLocation: number;
        locationsMatch?: {
          __typename?: 'LocationFilterUnion';
          anyOf: Array<{
            __typename?: 'LocationFilterIntersection';
            id?: string | null;
            allOf: Array<{
              __typename?: 'LocationFilter';
              type: LocationFilterType;
              isNull?: boolean | null;
              isNot?: boolean | null;
              valueIn?: Array<{
                __typename?: 'FilterValue';
                title: string;
              }> | null;
              range?: {
                __typename?: 'NumberFilterRange';
                from?: number | null;
                to?: number | null;
              } | null;
              stringRange?: {
                __typename?: 'StringFilterRange';
                from?: string | null;
                excludeFrom?: boolean | null;
                to?: string | null;
                excludeTo?: boolean | null;
              } | null;
            }>;
          }>;
        } | null;
      }> | null;
    } | null;
    resourcePolicy?: {
      __typename?: 'ResourcePolicy';
      agents: Array<{
        __typename?: 'AgentSettings';
        id: string;
        title?: string | null;
        resourceTypeId: string;
        metadata?: any | null;
        roles?: Array<{
          __typename?: 'AgentRoleSettings';
          roleId: AgentRole;
          disabled?: boolean | null;
          preHandling?: {
            __typename?: 'PicklistProcessingEventSettings';
            timeFixed?: number | null;
            timePerOrder?: number | null;
            timePerPicklistLine?: number | null;
            timePerUom?: number | null;
          } | null;
          handlingPreparation?: {
            __typename?: 'PicklistLineProcessingEventSettings';
            timeFixed?: number | null;
            timePerUom?: number | null;
          } | null;
          handling?: {
            __typename?: 'HandlingEventSettings';
            timeFixed?: number | null;
            timePerWeight?: number | null;
            timePerVolume?: number | null;
            timePerUom?: Array<{
              __typename?: 'UomHandlingSettings';
              uom: string;
              timePerUom?: number | null;
            }> | null;
          } | null;
          reordering?: {
            __typename?: 'ReorderEventSettings';
            timeFixed?: number | null;
            timePerReorderedPosition?: number | null;
            timePerWeight?: number | null;
            timePerVolume?: number | null;
            timePerUom?: Array<{
              __typename?: 'UomHandlingSettings';
              uom: string;
              timePerUom?: number | null;
            }> | null;
          } | null;
          handlingCompletion?: {
            __typename?: 'PicklistLineProcessingEventSettings';
            timeFixed?: number | null;
            timePerUom?: number | null;
          } | null;
          postHandling?: {
            __typename?: 'PicklistProcessingEventSettings';
            timeFixed?: number | null;
            timePerOrder?: number | null;
            timePerPicklistLine?: number | null;
            timePerUom?: number | null;
          } | null;
        }> | null;
        specs?: {
          __typename?: 'AgentSpecs';
          horizontalTravelling?: {
            __typename?: 'HorizontalTravellingEventSettings';
            speed?: {
              __typename?: 'SpeedSettings';
              speedLaden?: number | null;
              speedUnladen?: number | null;
            } | null;
          } | null;
          verticalTravelling?: {
            __typename?: 'VerticalTravellingEventSettings';
            speedUp?: {
              __typename?: 'SpeedSettings';
              speedLaden?: number | null;
              speedUnladen?: number | null;
            } | null;
            speedDown?: {
              __typename?: 'SpeedSettings';
              speedLaden?: number | null;
              speedUnladen?: number | null;
            } | null;
          } | null;
          capacity?: {
            __typename?: 'AgentCapacitySettings';
            maxVolume?: number | null;
            maxWeight?: number | null;
            maxUomQuantity?: Array<{
              __typename?: 'AgentCapacityUomSettings';
              uom: string;
              quantity?: number | null;
            }> | null;
          } | null;
          powerSource?: {
            __typename?: 'AgentPowerSourceSettings';
            energyConsumptionPerSecond?: number | null;
          } | null;
        } | null;
        cost?: {
          __typename?: 'AgentCostSettings';
          costPerSecond?: number | null;
        } | null;
        utilisation?: {
          __typename?: 'AgentUtilisationSettings';
          agentAmount?: number | null;
          secondsOperation?: number | null;
        } | null;
      }>;
    } | null;
    waypointPolicy?: {
      __typename?: 'WaypointPolicy';
      rules?: Array<{
        __typename?: 'WaypointPolicyRule';
        id: string;
        title?: string | null;
        startPoints?: {
          __typename?: 'WaypointPolicyRuleTerminalPoints';
          locationsMatch?: {
            __typename?: 'LocationFilterUnion';
            anyOf: Array<{
              __typename?: 'LocationFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'LocationFilter';
                type: LocationFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
        } | null;
        endPoints?: {
          __typename?: 'WaypointPolicyRuleTerminalPoints';
          locationsMatch?: {
            __typename?: 'LocationFilterUnion';
            anyOf: Array<{
              __typename?: 'LocationFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'LocationFilter';
                type: LocationFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
        } | null;
      }> | null;
    } | null;
    pickingPolicy?: {
      __typename?: 'PickingPolicy';
      orderLineRules?: Array<{
        __typename?: 'PickingPolicyOrderLineRule';
        id: string;
        title?: string | null;
        usePickingContainer?: boolean | null;
        pickingRequirementsSettings?: {
          __typename?: 'PickingPolicyPickingRequirementsSettings';
          groupBy: PickingRequirementsGroupBy;
          startLocationIdSource?: PickingRequirementTerminalLocationSource | null;
          endLocationIdSource?: PickingRequirementTerminalLocationSource | null;
          wave?: {
            __typename?: 'PickingWaveSettings';
            useDeliveryRoute?: boolean | null;
            useDock?: boolean | null;
            useStagingArea?: boolean | null;
            useWaveId?: boolean | null;
            pickByDateSettings?: {
              __typename?: 'PickingWavePickByDateSettings';
              mode: PickingWavePickByDateMode;
              fixedDeadlines?: Array<any> | null;
            } | null;
          } | null;
          batch?: {
            __typename?: 'PickingRequirementsBatchSettings';
            maxOrderCount?: number | null;
            maxOrderLineCount?: number | null;
          } | null;
        } | null;
        orderLinesMatch?: {
          __typename?: 'OrderLineFilterUnion';
          anyOf: Array<{
            __typename?: 'OrderLineFilterIntersection';
            id?: string | null;
            allOf: Array<{
              __typename?: 'OrderLineFilter';
              type: OrderLineFilterType;
              isNull?: boolean | null;
              isNot?: boolean | null;
              valueIn?: Array<{
                __typename?: 'FilterValue';
                title: string;
              }> | null;
              range?: {
                __typename?: 'NumberFilterRange';
                from?: number | null;
                to?: number | null;
              } | null;
              stringRange?: {
                __typename?: 'StringFilterRange';
                from?: string | null;
                excludeFrom?: boolean | null;
                to?: string | null;
                excludeTo?: boolean | null;
              } | null;
            }>;
          }>;
        } | null;
        pickingRules?: Array<{
          __typename?: 'PickingPolicyPickingRule';
          id: string;
          title?: string | null;
          uomTypes?: Array<string> | null;
          agentId: string;
          locationSelectionMode?: PickingPolicyLocationSelectionMode | null;
          picklistGroupKey?: string | null;
          locationsMatch?: {
            __typename?: 'LocationFilterUnion';
            anyOf: Array<{
              __typename?: 'LocationFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'LocationFilter';
                type: LocationFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
          waypointSettings?: {
            __typename?: 'PickingPolicyWaypointSettings';
            ruleId?: string | null;
          } | null;
        }> | null;
        picklistSettings?: {
          __typename?: 'PickingPolicyPicklistSettings';
          usePickingPriority?: boolean | null;
          picklistLinesOrder?: Array<{
            __typename?: 'PicklistLinesSortBy';
            type: PicklistLinesOrderOptions;
            direction: PicklistLinesSortDirection;
            nullsFirst?: boolean | null;
          }> | null;
        } | null;
      }> | null;
    } | null;
    allocationSettings?: {
      __typename?: 'AllocationSettings';
      allocationPolicy?: {
        __typename?: 'AllocationPolicy';
        rules?: Array<{
          __typename?: 'AllocationRule';
          id: string;
          title?: string | null;
          uomTypes?: Array<string> | null;
          itemsMatch?: {
            __typename?: 'SimulationItemFilterUnion';
            anyOf: Array<{
              __typename?: 'SimulationItemFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'SimulationItemFilter';
                type: SimulationItemFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
          locationsMatch?: {
            __typename?: 'LocationFilterUnion';
            anyOf: Array<{
              __typename?: 'LocationFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'LocationFilter';
                type: LocationFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
          limitSettings?: {
            __typename?: 'AllocationLimitSettings';
            applyTo?: AllocationLimitApplyTo | null;
            maxPickableAssignments?: number | null;
            maxAllocatedAssignments?: number | null;
            maxPickableVolume?: number | null;
            maxAllocatedVolume?: number | null;
          } | null;
          roundSettings?: {
            __typename?: 'AllocationRoundSettings';
            maxAssignmentsPerRound?: number | null;
          } | null;
          pickabilitySettings?: {
            __typename?: 'AllocationPickabilitySettings';
            enforcePickability?: AllocationEnforcePickability | null;
          } | null;
          prioritySettings?: {
            __typename?: 'AllocationPrioritySettings';
            itemPriority?: Array<{
              __typename?: 'AllocationItemPrioritySpec';
              priority: AllocationItemPriority;
              direction?: AllocationPriorityDirection | null;
            }> | null;
            locationPriority?: Array<{
              __typename?: 'AllocationLocationPrioritySpec';
              priority: AllocationLocationPriority;
              direction?: AllocationPriorityDirection | null;
            }> | null;
          } | null;
          replacementSettings?: {
            __typename?: 'AllocationReplacementSettings';
            allow?: AllocationAllowReplacement | null;
          } | null;
          multiplexingSettings?: {
            __typename?: 'AllocationMultiplexingSettings';
            allow?: AllocationAllowReplacement | null;
          } | null;
          requirementAggregationSettings?: {
            __typename?: 'AllocationRequirementAggregationSettings';
            aggregation?: AllocationRequirementAggregationMethod | null;
            numberOfPeriods?: number | null;
          } | null;
          constraintSettings?: {
            __typename?: 'AllocationConstraintSettings';
            assignmentPolicyConstraint?: {
              __typename?: 'AssignmentPolicyConstraintSettings';
              disabled?: boolean | null;
            } | null;
            locationSizeConstraint?: {
              __typename?: 'LocationSizeConstraintSettings';
              disabled?: boolean | null;
              unknownAsNoncompliant?: boolean | null;
            } | null;
            bayWidthConstraint?: {
              __typename?: 'BayWidthConstraintSettings';
              disabled?: boolean | null;
            } | null;
          } | null;
        }> | null;
        defaultRule?: {
          __typename?: 'AllocationRule';
          id: string;
          title?: string | null;
          uomTypes?: Array<string> | null;
          itemsMatch?: {
            __typename?: 'SimulationItemFilterUnion';
            anyOf: Array<{
              __typename?: 'SimulationItemFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'SimulationItemFilter';
                type: SimulationItemFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
          locationsMatch?: {
            __typename?: 'LocationFilterUnion';
            anyOf: Array<{
              __typename?: 'LocationFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'LocationFilter';
                type: LocationFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
          limitSettings?: {
            __typename?: 'AllocationLimitSettings';
            applyTo?: AllocationLimitApplyTo | null;
            maxPickableAssignments?: number | null;
            maxAllocatedAssignments?: number | null;
            maxPickableVolume?: number | null;
            maxAllocatedVolume?: number | null;
          } | null;
          roundSettings?: {
            __typename?: 'AllocationRoundSettings';
            maxAssignmentsPerRound?: number | null;
          } | null;
          pickabilitySettings?: {
            __typename?: 'AllocationPickabilitySettings';
            enforcePickability?: AllocationEnforcePickability | null;
          } | null;
          prioritySettings?: {
            __typename?: 'AllocationPrioritySettings';
            itemPriority?: Array<{
              __typename?: 'AllocationItemPrioritySpec';
              priority: AllocationItemPriority;
              direction?: AllocationPriorityDirection | null;
            }> | null;
            locationPriority?: Array<{
              __typename?: 'AllocationLocationPrioritySpec';
              priority: AllocationLocationPriority;
              direction?: AllocationPriorityDirection | null;
            }> | null;
          } | null;
          replacementSettings?: {
            __typename?: 'AllocationReplacementSettings';
            allow?: AllocationAllowReplacement | null;
          } | null;
          multiplexingSettings?: {
            __typename?: 'AllocationMultiplexingSettings';
            allow?: AllocationAllowReplacement | null;
          } | null;
          requirementAggregationSettings?: {
            __typename?: 'AllocationRequirementAggregationSettings';
            aggregation?: AllocationRequirementAggregationMethod | null;
            numberOfPeriods?: number | null;
          } | null;
          constraintSettings?: {
            __typename?: 'AllocationConstraintSettings';
            assignmentPolicyConstraint?: {
              __typename?: 'AssignmentPolicyConstraintSettings';
              disabled?: boolean | null;
            } | null;
            locationSizeConstraint?: {
              __typename?: 'LocationSizeConstraintSettings';
              disabled?: boolean | null;
              unknownAsNoncompliant?: boolean | null;
            } | null;
            bayWidthConstraint?: {
              __typename?: 'BayWidthConstraintSettings';
              disabled?: boolean | null;
            } | null;
          } | null;
        } | null;
      } | null;
      deallocationPolicy?: {
        __typename?: 'DeallocationPolicy';
        rules?: Array<{
          __typename?: 'DeallocationRule';
          id?: string | null;
          title?: string | null;
          deallocateType?: DeallocateType | null;
          reallocateType?: ReallocateType | null;
          itemsMatch?: {
            __typename?: 'SimulationItemFilterUnion';
            anyOf: Array<{
              __typename?: 'SimulationItemFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'SimulationItemFilter';
                type: SimulationItemFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
          locationsMatch?: {
            __typename?: 'LocationFilterUnion';
            anyOf: Array<{
              __typename?: 'LocationFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'LocationFilter';
                type: LocationFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
          constraintSettings?: {
            __typename?: 'AllocationConstraintSettings';
            assignmentPolicyConstraint?: {
              __typename?: 'AssignmentPolicyConstraintSettings';
              disabled?: boolean | null;
            } | null;
            locationSizeConstraint?: {
              __typename?: 'LocationSizeConstraintSettings';
              disabled?: boolean | null;
              unknownAsNoncompliant?: boolean | null;
            } | null;
            bayWidthConstraint?: {
              __typename?: 'BayWidthConstraintSettings';
              disabled?: boolean | null;
            } | null;
          } | null;
          reallocateQuantitySettings?: {
            __typename?: 'ReallocateQuantitySettings';
            reallocateUom?: ReallocateUomSource | null;
            reallocateQuantity?: ReallocateQuantitySource | null;
            fixedUom?: string | null;
            fixedUomQuantity?: number | null;
          } | null;
        }> | null;
        defaultRule?: {
          __typename?: 'DeallocationRule';
          id?: string | null;
          title?: string | null;
          deallocateType?: DeallocateType | null;
          reallocateType?: ReallocateType | null;
          itemsMatch?: {
            __typename?: 'SimulationItemFilterUnion';
            anyOf: Array<{
              __typename?: 'SimulationItemFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'SimulationItemFilter';
                type: SimulationItemFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
          locationsMatch?: {
            __typename?: 'LocationFilterUnion';
            anyOf: Array<{
              __typename?: 'LocationFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'LocationFilter';
                type: LocationFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
          constraintSettings?: {
            __typename?: 'AllocationConstraintSettings';
            assignmentPolicyConstraint?: {
              __typename?: 'AssignmentPolicyConstraintSettings';
              disabled?: boolean | null;
            } | null;
            locationSizeConstraint?: {
              __typename?: 'LocationSizeConstraintSettings';
              disabled?: boolean | null;
              unknownAsNoncompliant?: boolean | null;
            } | null;
            bayWidthConstraint?: {
              __typename?: 'BayWidthConstraintSettings';
              disabled?: boolean | null;
            } | null;
          } | null;
          reallocateQuantitySettings?: {
            __typename?: 'ReallocateQuantitySettings';
            reallocateUom?: ReallocateUomSource | null;
            reallocateQuantity?: ReallocateQuantitySource | null;
            fixedUom?: string | null;
            fixedUomQuantity?: number | null;
          } | null;
        } | null;
      } | null;
      orderSetFilter?: {
        __typename?: 'OrderSetFilter';
        orderDate?: {
          __typename?: 'LocalDateRangeFilter';
          from?: string | null;
          to?: string | null;
          excludeFrom?: boolean | null;
          excludeTo?: boolean | null;
          includeNull?: boolean | null;
        } | null;
      } | null;
      requirementSource?: {
        __typename?: 'AllocationRequirementSourceSettings';
        useOrderSetDemand?: boolean | null;
      } | null;
      stockLimitSettings?: {
        __typename?: 'AllocationStockLimitSettings';
        enabled?: boolean | null;
      } | null;
    } | null;
    analyzeResult?: {
      __typename?: 'AnalyzeResult';
      id: string;
      status: AnalyzeResultStatus;
    } | null;
    optimizationRuns: {
      __typename?: 'OptimizationRunsConnection';
      content: Array<{
        __typename?: 'OptimizationRun';
        id: string;
        status: OptimizationRunStatus;
      }>;
    };
    optimizationSettings?: {
      __typename?: 'OptimizationSettings';
      locationSizeConstraint?: {
        __typename?: 'LocationSizeConstraintSettings';
        disabled?: boolean | null;
      } | null;
      bayWidthConstraint?: {
        __typename?: 'BayWidthConstraintSettings';
        disabled?: boolean | null;
      } | null;
      terminationSettings?: {
        __typename?: 'TerminationSettings';
        targetImprovement?: number | null;
        timeLimit?: number | null;
        minImprovementRate?: {
          __typename?: 'MinImprovementRateSettings';
          minImprovementChange: number;
          steps: number;
        } | null;
      } | null;
      swapSettings?: {
        __typename?: 'SwapSettings';
        rules?: Array<{
          __typename?: 'OptimizationSwapRule';
          id?: string | null;
          title?: string | null;
          src?: {
            __typename?: 'AssignmentSubsetSpec';
            unassignedLocationsMatch?: UnassignedLocationsMatch | null;
            locationsMatch?: {
              __typename?: 'LocationFilterUnion';
              anyOf: Array<{
                __typename?: 'LocationFilterIntersection';
                id?: string | null;
                allOf: Array<{
                  __typename?: 'LocationFilter';
                  type: LocationFilterType;
                  isNull?: boolean | null;
                  isNot?: boolean | null;
                  valueIn?: Array<{
                    __typename?: 'FilterValue';
                    title: string;
                  }> | null;
                  range?: {
                    __typename?: 'NumberFilterRange';
                    from?: number | null;
                    to?: number | null;
                  } | null;
                  stringRange?: {
                    __typename?: 'StringFilterRange';
                    from?: string | null;
                    excludeFrom?: boolean | null;
                    to?: string | null;
                    excludeTo?: boolean | null;
                  } | null;
                }>;
              }>;
            } | null;
            itemsMatch?: {
              __typename?: 'SimulationItemFilterUnion';
              anyOf: Array<{
                __typename?: 'SimulationItemFilterIntersection';
                id?: string | null;
                allOf: Array<{
                  __typename?: 'SimulationItemFilter';
                  type: SimulationItemFilterType;
                  isNull?: boolean | null;
                  isNot?: boolean | null;
                  valueIn?: Array<{
                    __typename?: 'FilterValue';
                    title: string;
                  }> | null;
                  range?: {
                    __typename?: 'NumberFilterRange';
                    from?: number | null;
                    to?: number | null;
                  } | null;
                  stringRange?: {
                    __typename?: 'StringFilterRange';
                    from?: string | null;
                    excludeFrom?: boolean | null;
                    to?: string | null;
                    excludeTo?: boolean | null;
                  } | null;
                }>;
              }>;
            } | null;
          } | null;
          dest?: {
            __typename?: 'AssignmentSubsetSpec';
            unassignedLocationsMatch?: UnassignedLocationsMatch | null;
            locationsMatch?: {
              __typename?: 'LocationFilterUnion';
              anyOf: Array<{
                __typename?: 'LocationFilterIntersection';
                id?: string | null;
                allOf: Array<{
                  __typename?: 'LocationFilter';
                  type: LocationFilterType;
                  isNull?: boolean | null;
                  isNot?: boolean | null;
                  valueIn?: Array<{
                    __typename?: 'FilterValue';
                    title: string;
                  }> | null;
                  range?: {
                    __typename?: 'NumberFilterRange';
                    from?: number | null;
                    to?: number | null;
                  } | null;
                  stringRange?: {
                    __typename?: 'StringFilterRange';
                    from?: string | null;
                    excludeFrom?: boolean | null;
                    to?: string | null;
                    excludeTo?: boolean | null;
                  } | null;
                }>;
              }>;
            } | null;
            itemsMatch?: {
              __typename?: 'SimulationItemFilterUnion';
              anyOf: Array<{
                __typename?: 'SimulationItemFilterIntersection';
                id?: string | null;
                allOf: Array<{
                  __typename?: 'SimulationItemFilter';
                  type: SimulationItemFilterType;
                  isNull?: boolean | null;
                  isNot?: boolean | null;
                  valueIn?: Array<{
                    __typename?: 'FilterValue';
                    title: string;
                  }> | null;
                  range?: {
                    __typename?: 'NumberFilterRange';
                    from?: number | null;
                    to?: number | null;
                  } | null;
                  stringRange?: {
                    __typename?: 'StringFilterRange';
                    from?: string | null;
                    excludeFrom?: boolean | null;
                    to?: string | null;
                    excludeTo?: boolean | null;
                  } | null;
                }>;
              }>;
            } | null;
          } | null;
        }> | null;
      } | null;
      loadBalancing?: {
        __typename?: 'LoadBalancingPolicy';
        rules: Array<{
          __typename?: 'LoadBalancingRule';
          id?: string | null;
          title?: string | null;
          type: LoadBalancingZoneType;
          objectiveWeight?: number | null;
          constraint?: {
            __typename?: 'LoadBalancingConstraint';
            maxRelativeDeviation: number;
          } | null;
          include?: {
            __typename?: 'LocationFilterUnion';
            anyOf: Array<{
              __typename?: 'LocationFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'LocationFilter';
                type: LocationFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
        }>;
      } | null;
      limitSettings?: {
        __typename?: 'LimitSettings';
        maxMoves?: number | null;
      } | null;
      reassignJobs?: {
        __typename?: 'ReassignJobsSettings';
        disabled?: boolean | null;
        cycleMode?: ReassignJobCycleMode | null;
      } | null;
      policyOverrides?: {
        __typename?: 'OptimizationPolicyOverrides';
        resourcePolicy?: {
          __typename?: 'ResourcePolicy';
          agents: Array<{
            __typename?: 'AgentSettings';
            id: string;
            title?: string | null;
            resourceTypeId: string;
            metadata?: any | null;
            roles?: Array<{
              __typename?: 'AgentRoleSettings';
              roleId: AgentRole;
              disabled?: boolean | null;
              preHandling?: {
                __typename?: 'PicklistProcessingEventSettings';
                timeFixed?: number | null;
                timePerOrder?: number | null;
                timePerPicklistLine?: number | null;
                timePerUom?: number | null;
              } | null;
              handlingPreparation?: {
                __typename?: 'PicklistLineProcessingEventSettings';
                timeFixed?: number | null;
                timePerUom?: number | null;
              } | null;
              handling?: {
                __typename?: 'HandlingEventSettings';
                timeFixed?: number | null;
                timePerWeight?: number | null;
                timePerVolume?: number | null;
                timePerUom?: Array<{
                  __typename?: 'UomHandlingSettings';
                  uom: string;
                  timePerUom?: number | null;
                }> | null;
              } | null;
              reordering?: {
                __typename?: 'ReorderEventSettings';
                timeFixed?: number | null;
                timePerReorderedPosition?: number | null;
                timePerWeight?: number | null;
                timePerVolume?: number | null;
                timePerUom?: Array<{
                  __typename?: 'UomHandlingSettings';
                  uom: string;
                  timePerUom?: number | null;
                }> | null;
              } | null;
              handlingCompletion?: {
                __typename?: 'PicklistLineProcessingEventSettings';
                timeFixed?: number | null;
                timePerUom?: number | null;
              } | null;
              postHandling?: {
                __typename?: 'PicklistProcessingEventSettings';
                timeFixed?: number | null;
                timePerOrder?: number | null;
                timePerPicklistLine?: number | null;
                timePerUom?: number | null;
              } | null;
            }> | null;
            specs?: {
              __typename?: 'AgentSpecs';
              horizontalTravelling?: {
                __typename?: 'HorizontalTravellingEventSettings';
                speed?: {
                  __typename?: 'SpeedSettings';
                  speedLaden?: number | null;
                  speedUnladen?: number | null;
                } | null;
              } | null;
              verticalTravelling?: {
                __typename?: 'VerticalTravellingEventSettings';
                speedUp?: {
                  __typename?: 'SpeedSettings';
                  speedLaden?: number | null;
                  speedUnladen?: number | null;
                } | null;
                speedDown?: {
                  __typename?: 'SpeedSettings';
                  speedLaden?: number | null;
                  speedUnladen?: number | null;
                } | null;
              } | null;
              capacity?: {
                __typename?: 'AgentCapacitySettings';
                maxVolume?: number | null;
                maxWeight?: number | null;
                maxUomQuantity?: Array<{
                  __typename?: 'AgentCapacityUomSettings';
                  uom: string;
                  quantity?: number | null;
                }> | null;
              } | null;
              powerSource?: {
                __typename?: 'AgentPowerSourceSettings';
                energyConsumptionPerSecond?: number | null;
              } | null;
            } | null;
            cost?: {
              __typename?: 'AgentCostSettings';
              costPerSecond?: number | null;
            } | null;
            utilisation?: {
              __typename?: 'AgentUtilisationSettings';
              agentAmount?: number | null;
              secondsOperation?: number | null;
            } | null;
          }>;
        } | null;
      } | null;
      pickabilityConstraint?: {
        __typename?: 'PickabilityConstraintSettings';
        disabled?: boolean | null;
      } | null;
      assignmentCapacitySettings?: {
        __typename?: 'AssignmentCapacitySettings';
        avoidMerge?: boolean | null;
      } | null;
    } | null;
    latestAllocationRun?: {
      __typename?: 'AllocationRun';
      id: string;
      progress?: number | null;
      seed?: number | null;
      status: BatchJobStatus;
      startedAt?: number | null;
      completedAt?: number | null;
      createdAt: number;
      allocationRunType: AllocationType;
      allocationSettings?: {
        __typename?: 'AllocationSettings';
        allocationPolicy?: {
          __typename?: 'AllocationPolicy';
          rules?: Array<{
            __typename?: 'AllocationRule';
            id: string;
            title?: string | null;
            uomTypes?: Array<string> | null;
            itemsMatch?: {
              __typename?: 'SimulationItemFilterUnion';
              anyOf: Array<{
                __typename?: 'SimulationItemFilterIntersection';
                id?: string | null;
                allOf: Array<{
                  __typename?: 'SimulationItemFilter';
                  type: SimulationItemFilterType;
                  isNull?: boolean | null;
                  isNot?: boolean | null;
                  valueIn?: Array<{
                    __typename?: 'FilterValue';
                    title: string;
                  }> | null;
                  range?: {
                    __typename?: 'NumberFilterRange';
                    from?: number | null;
                    to?: number | null;
                  } | null;
                  stringRange?: {
                    __typename?: 'StringFilterRange';
                    from?: string | null;
                    excludeFrom?: boolean | null;
                    to?: string | null;
                    excludeTo?: boolean | null;
                  } | null;
                }>;
              }>;
            } | null;
            locationsMatch?: {
              __typename?: 'LocationFilterUnion';
              anyOf: Array<{
                __typename?: 'LocationFilterIntersection';
                id?: string | null;
                allOf: Array<{
                  __typename?: 'LocationFilter';
                  type: LocationFilterType;
                  isNull?: boolean | null;
                  isNot?: boolean | null;
                  valueIn?: Array<{
                    __typename?: 'FilterValue';
                    title: string;
                  }> | null;
                  range?: {
                    __typename?: 'NumberFilterRange';
                    from?: number | null;
                    to?: number | null;
                  } | null;
                  stringRange?: {
                    __typename?: 'StringFilterRange';
                    from?: string | null;
                    excludeFrom?: boolean | null;
                    to?: string | null;
                    excludeTo?: boolean | null;
                  } | null;
                }>;
              }>;
            } | null;
            limitSettings?: {
              __typename?: 'AllocationLimitSettings';
              applyTo?: AllocationLimitApplyTo | null;
              maxPickableAssignments?: number | null;
              maxAllocatedAssignments?: number | null;
              maxPickableVolume?: number | null;
              maxAllocatedVolume?: number | null;
            } | null;
            roundSettings?: {
              __typename?: 'AllocationRoundSettings';
              maxAssignmentsPerRound?: number | null;
            } | null;
            pickabilitySettings?: {
              __typename?: 'AllocationPickabilitySettings';
              enforcePickability?: AllocationEnforcePickability | null;
            } | null;
            prioritySettings?: {
              __typename?: 'AllocationPrioritySettings';
              itemPriority?: Array<{
                __typename?: 'AllocationItemPrioritySpec';
                priority: AllocationItemPriority;
                direction?: AllocationPriorityDirection | null;
              }> | null;
              locationPriority?: Array<{
                __typename?: 'AllocationLocationPrioritySpec';
                priority: AllocationLocationPriority;
                direction?: AllocationPriorityDirection | null;
              }> | null;
            } | null;
            replacementSettings?: {
              __typename?: 'AllocationReplacementSettings';
              allow?: AllocationAllowReplacement | null;
            } | null;
            multiplexingSettings?: {
              __typename?: 'AllocationMultiplexingSettings';
              allow?: AllocationAllowReplacement | null;
            } | null;
            requirementAggregationSettings?: {
              __typename?: 'AllocationRequirementAggregationSettings';
              aggregation?: AllocationRequirementAggregationMethod | null;
              numberOfPeriods?: number | null;
            } | null;
            constraintSettings?: {
              __typename?: 'AllocationConstraintSettings';
              assignmentPolicyConstraint?: {
                __typename?: 'AssignmentPolicyConstraintSettings';
                disabled?: boolean | null;
              } | null;
              locationSizeConstraint?: {
                __typename?: 'LocationSizeConstraintSettings';
                disabled?: boolean | null;
                unknownAsNoncompliant?: boolean | null;
              } | null;
              bayWidthConstraint?: {
                __typename?: 'BayWidthConstraintSettings';
                disabled?: boolean | null;
              } | null;
            } | null;
          }> | null;
          defaultRule?: {
            __typename?: 'AllocationRule';
            id: string;
            title?: string | null;
            uomTypes?: Array<string> | null;
            itemsMatch?: {
              __typename?: 'SimulationItemFilterUnion';
              anyOf: Array<{
                __typename?: 'SimulationItemFilterIntersection';
                id?: string | null;
                allOf: Array<{
                  __typename?: 'SimulationItemFilter';
                  type: SimulationItemFilterType;
                  isNull?: boolean | null;
                  isNot?: boolean | null;
                  valueIn?: Array<{
                    __typename?: 'FilterValue';
                    title: string;
                  }> | null;
                  range?: {
                    __typename?: 'NumberFilterRange';
                    from?: number | null;
                    to?: number | null;
                  } | null;
                  stringRange?: {
                    __typename?: 'StringFilterRange';
                    from?: string | null;
                    excludeFrom?: boolean | null;
                    to?: string | null;
                    excludeTo?: boolean | null;
                  } | null;
                }>;
              }>;
            } | null;
            locationsMatch?: {
              __typename?: 'LocationFilterUnion';
              anyOf: Array<{
                __typename?: 'LocationFilterIntersection';
                id?: string | null;
                allOf: Array<{
                  __typename?: 'LocationFilter';
                  type: LocationFilterType;
                  isNull?: boolean | null;
                  isNot?: boolean | null;
                  valueIn?: Array<{
                    __typename?: 'FilterValue';
                    title: string;
                  }> | null;
                  range?: {
                    __typename?: 'NumberFilterRange';
                    from?: number | null;
                    to?: number | null;
                  } | null;
                  stringRange?: {
                    __typename?: 'StringFilterRange';
                    from?: string | null;
                    excludeFrom?: boolean | null;
                    to?: string | null;
                    excludeTo?: boolean | null;
                  } | null;
                }>;
              }>;
            } | null;
            limitSettings?: {
              __typename?: 'AllocationLimitSettings';
              applyTo?: AllocationLimitApplyTo | null;
              maxPickableAssignments?: number | null;
              maxAllocatedAssignments?: number | null;
              maxPickableVolume?: number | null;
              maxAllocatedVolume?: number | null;
            } | null;
            roundSettings?: {
              __typename?: 'AllocationRoundSettings';
              maxAssignmentsPerRound?: number | null;
            } | null;
            pickabilitySettings?: {
              __typename?: 'AllocationPickabilitySettings';
              enforcePickability?: AllocationEnforcePickability | null;
            } | null;
            prioritySettings?: {
              __typename?: 'AllocationPrioritySettings';
              itemPriority?: Array<{
                __typename?: 'AllocationItemPrioritySpec';
                priority: AllocationItemPriority;
                direction?: AllocationPriorityDirection | null;
              }> | null;
              locationPriority?: Array<{
                __typename?: 'AllocationLocationPrioritySpec';
                priority: AllocationLocationPriority;
                direction?: AllocationPriorityDirection | null;
              }> | null;
            } | null;
            replacementSettings?: {
              __typename?: 'AllocationReplacementSettings';
              allow?: AllocationAllowReplacement | null;
            } | null;
            multiplexingSettings?: {
              __typename?: 'AllocationMultiplexingSettings';
              allow?: AllocationAllowReplacement | null;
            } | null;
            requirementAggregationSettings?: {
              __typename?: 'AllocationRequirementAggregationSettings';
              aggregation?: AllocationRequirementAggregationMethod | null;
              numberOfPeriods?: number | null;
            } | null;
            constraintSettings?: {
              __typename?: 'AllocationConstraintSettings';
              assignmentPolicyConstraint?: {
                __typename?: 'AssignmentPolicyConstraintSettings';
                disabled?: boolean | null;
              } | null;
              locationSizeConstraint?: {
                __typename?: 'LocationSizeConstraintSettings';
                disabled?: boolean | null;
                unknownAsNoncompliant?: boolean | null;
              } | null;
              bayWidthConstraint?: {
                __typename?: 'BayWidthConstraintSettings';
                disabled?: boolean | null;
              } | null;
            } | null;
          } | null;
        } | null;
        deallocationPolicy?: {
          __typename?: 'DeallocationPolicy';
          rules?: Array<{
            __typename?: 'DeallocationRule';
            id?: string | null;
            title?: string | null;
            deallocateType?: DeallocateType | null;
            reallocateType?: ReallocateType | null;
            itemsMatch?: {
              __typename?: 'SimulationItemFilterUnion';
              anyOf: Array<{
                __typename?: 'SimulationItemFilterIntersection';
                id?: string | null;
                allOf: Array<{
                  __typename?: 'SimulationItemFilter';
                  type: SimulationItemFilterType;
                  isNull?: boolean | null;
                  isNot?: boolean | null;
                  valueIn?: Array<{
                    __typename?: 'FilterValue';
                    title: string;
                  }> | null;
                  range?: {
                    __typename?: 'NumberFilterRange';
                    from?: number | null;
                    to?: number | null;
                  } | null;
                  stringRange?: {
                    __typename?: 'StringFilterRange';
                    from?: string | null;
                    excludeFrom?: boolean | null;
                    to?: string | null;
                    excludeTo?: boolean | null;
                  } | null;
                }>;
              }>;
            } | null;
            locationsMatch?: {
              __typename?: 'LocationFilterUnion';
              anyOf: Array<{
                __typename?: 'LocationFilterIntersection';
                id?: string | null;
                allOf: Array<{
                  __typename?: 'LocationFilter';
                  type: LocationFilterType;
                  isNull?: boolean | null;
                  isNot?: boolean | null;
                  valueIn?: Array<{
                    __typename?: 'FilterValue';
                    title: string;
                  }> | null;
                  range?: {
                    __typename?: 'NumberFilterRange';
                    from?: number | null;
                    to?: number | null;
                  } | null;
                  stringRange?: {
                    __typename?: 'StringFilterRange';
                    from?: string | null;
                    excludeFrom?: boolean | null;
                    to?: string | null;
                    excludeTo?: boolean | null;
                  } | null;
                }>;
              }>;
            } | null;
            constraintSettings?: {
              __typename?: 'AllocationConstraintSettings';
              assignmentPolicyConstraint?: {
                __typename?: 'AssignmentPolicyConstraintSettings';
                disabled?: boolean | null;
              } | null;
              locationSizeConstraint?: {
                __typename?: 'LocationSizeConstraintSettings';
                disabled?: boolean | null;
                unknownAsNoncompliant?: boolean | null;
              } | null;
              bayWidthConstraint?: {
                __typename?: 'BayWidthConstraintSettings';
                disabled?: boolean | null;
              } | null;
            } | null;
            reallocateQuantitySettings?: {
              __typename?: 'ReallocateQuantitySettings';
              reallocateUom?: ReallocateUomSource | null;
              reallocateQuantity?: ReallocateQuantitySource | null;
              fixedUom?: string | null;
              fixedUomQuantity?: number | null;
            } | null;
          }> | null;
          defaultRule?: {
            __typename?: 'DeallocationRule';
            id?: string | null;
            title?: string | null;
            deallocateType?: DeallocateType | null;
            reallocateType?: ReallocateType | null;
            itemsMatch?: {
              __typename?: 'SimulationItemFilterUnion';
              anyOf: Array<{
                __typename?: 'SimulationItemFilterIntersection';
                id?: string | null;
                allOf: Array<{
                  __typename?: 'SimulationItemFilter';
                  type: SimulationItemFilterType;
                  isNull?: boolean | null;
                  isNot?: boolean | null;
                  valueIn?: Array<{
                    __typename?: 'FilterValue';
                    title: string;
                  }> | null;
                  range?: {
                    __typename?: 'NumberFilterRange';
                    from?: number | null;
                    to?: number | null;
                  } | null;
                  stringRange?: {
                    __typename?: 'StringFilterRange';
                    from?: string | null;
                    excludeFrom?: boolean | null;
                    to?: string | null;
                    excludeTo?: boolean | null;
                  } | null;
                }>;
              }>;
            } | null;
            locationsMatch?: {
              __typename?: 'LocationFilterUnion';
              anyOf: Array<{
                __typename?: 'LocationFilterIntersection';
                id?: string | null;
                allOf: Array<{
                  __typename?: 'LocationFilter';
                  type: LocationFilterType;
                  isNull?: boolean | null;
                  isNot?: boolean | null;
                  valueIn?: Array<{
                    __typename?: 'FilterValue';
                    title: string;
                  }> | null;
                  range?: {
                    __typename?: 'NumberFilterRange';
                    from?: number | null;
                    to?: number | null;
                  } | null;
                  stringRange?: {
                    __typename?: 'StringFilterRange';
                    from?: string | null;
                    excludeFrom?: boolean | null;
                    to?: string | null;
                    excludeTo?: boolean | null;
                  } | null;
                }>;
              }>;
            } | null;
            constraintSettings?: {
              __typename?: 'AllocationConstraintSettings';
              assignmentPolicyConstraint?: {
                __typename?: 'AssignmentPolicyConstraintSettings';
                disabled?: boolean | null;
              } | null;
              locationSizeConstraint?: {
                __typename?: 'LocationSizeConstraintSettings';
                disabled?: boolean | null;
                unknownAsNoncompliant?: boolean | null;
              } | null;
              bayWidthConstraint?: {
                __typename?: 'BayWidthConstraintSettings';
                disabled?: boolean | null;
              } | null;
            } | null;
            reallocateQuantitySettings?: {
              __typename?: 'ReallocateQuantitySettings';
              reallocateUom?: ReallocateUomSource | null;
              reallocateQuantity?: ReallocateQuantitySource | null;
              fixedUom?: string | null;
              fixedUomQuantity?: number | null;
            } | null;
          } | null;
        } | null;
        orderSetFilter?: {
          __typename?: 'OrderSetFilter';
          orderDate?: {
            __typename?: 'LocalDateRangeFilter';
            from?: string | null;
            to?: string | null;
            excludeFrom?: boolean | null;
            excludeTo?: boolean | null;
            includeNull?: boolean | null;
          } | null;
        } | null;
        requirementSource?: {
          __typename?: 'AllocationRequirementSourceSettings';
          useOrderSetDemand?: boolean | null;
        } | null;
        stockLimitSettings?: {
          __typename?: 'AllocationStockLimitSettings';
          enabled?: boolean | null;
        } | null;
      } | null;
      resultAssignment?: { __typename?: 'Assignment'; id: string } | null;
      analyzeResult?: {
        __typename?: 'AnalyzeResult';
        id: string;
        status: AnalyzeResultStatus;
      } | null;
      resultRequirementsSummary: Array<{
        __typename?: 'AllocationRunResultRequirementSummary';
        key: {
          __typename?: 'AllocationRunResultRequirementSummaryKey';
          itemId?: string | null;
          consignee?: string | null;
          orderLineRuleId?: string | null;
          sku?: string | null;
          skuGroup?: string | null;
          subGroup?: string | null;
          transportClass?: string | null;
          stockCategory?: string | null;
          storageClass?: string | null;
          pollutionClass?: string | null;
        };
        required?: {
          __typename?: 'AllocationRunResultRequirementQuantity';
          totalQuantity?: number | null;
          uoms?: Array<{
            __typename?: 'AllocationRequirementUomQuantity';
            uom?: string | null;
            uomQuantity: number;
          }> | null;
        } | null;
        unpickable?: {
          __typename?: 'AllocationRunResultRequirementQuantity';
          totalQuantity?: number | null;
          uoms?: Array<{
            __typename?: 'AllocationRequirementUomQuantity';
            uom?: string | null;
            uomQuantity: number;
          }> | null;
        } | null;
        initiallyPickable?: {
          __typename?: 'AllocationRunResultRequirementPickableQuantitySummary';
          totalQuantity?: number | null;
          totalLocationCount?: number | null;
          uoms?: Array<{
            __typename?: 'AllocationRequirementUomQuantity';
            uom?: string | null;
            uomQuantity: number;
            totalVolume: number;
          }> | null;
        } | null;
        initiallyPickableFulfilled?: {
          __typename?: 'AllocationRunResultRequirementPickableQuantitySummary';
          totalQuantity?: number | null;
          totalLocationCount?: number | null;
          uoms?: Array<{
            __typename?: 'AllocationRequirementUomQuantity';
            uom?: string | null;
            uomQuantity: number;
            totalVolume: number;
          }> | null;
        } | null;
        toAllocateRequired?: {
          __typename?: 'AllocationRunResultRequirementQuantity';
          totalQuantity?: number | null;
          uoms?: Array<{
            __typename?: 'AllocationRequirementUomQuantity';
            uom?: string | null;
            uomQuantity: number;
            totalVolume: number;
            aggregatedTotalVolume?: number | null;
            aggregatedQuantity?: number | null;
            aggregatedUomQuantity?: number | null;
          }> | null;
        } | null;
        toReallocate?: {
          __typename?: 'AllocationRunResultRequirementQuantity';
          totalQuantity?: number | null;
          uoms?: Array<{
            __typename?: 'AllocationRequirementUomQuantity';
            uom?: string | null;
            uomQuantity: number;
            totalVolume: number;
            aggregatedTotalVolume?: number | null;
            aggregatedQuantity?: number | null;
            aggregatedUomQuantity?: number | null;
          }> | null;
        } | null;
        toAllocate?: {
          __typename?: 'AllocationRunResultRequirementQuantity';
          totalQuantity?: number | null;
          uoms?: Array<{
            __typename?: 'AllocationRequirementUomQuantity';
            uom?: string | null;
            uomQuantity: number;
            totalVolume: number;
            aggregatedTotalVolume?: number | null;
            aggregatedQuantity?: number | null;
            aggregatedUomQuantity?: number | null;
          }> | null;
        } | null;
        allocated?: {
          __typename?: 'AllocationRunResultRequirementPickableQuantitySummary';
          totalQuantity?: number | null;
          totalLocationCount?: number | null;
          uoms?: Array<{
            __typename?: 'AllocationRequirementUomQuantity';
            uom?: string | null;
            uomQuantity: number;
            totalVolume: number;
          }> | null;
        } | null;
        allocatedShared?: {
          __typename?: 'AllocationRunResultRequirementPickableQuantitySummary';
          totalQuantity?: number | null;
          totalLocationCount?: number | null;
          uoms?: Array<{
            __typename?: 'AllocationRequirementUomQuantity';
            uom?: string | null;
            uomQuantity: number;
            totalVolume: number;
          }> | null;
        } | null;
        allocatedFulfilled?: {
          __typename?: 'AllocationRunResultRequirementPickableQuantitySummary';
          totalQuantity?: number | null;
          totalLocationCount?: number | null;
          uoms?: Array<{
            __typename?: 'AllocationRequirementUomQuantity';
            uom?: string | null;
            uomQuantity: number;
            totalVolume: number;
          }> | null;
        } | null;
        unallocated?: {
          __typename?: 'AllocationRunResultRequirementUnallocatedQuantity';
          totalQuantity?: number | null;
          reasons?: Array<AllocationRunResultUnallocatedReason> | null;
          uoms?: Array<{
            __typename?: 'AllocationRequirementUomQuantity';
            uom?: string | null;
            uomQuantity: number;
          }> | null;
        } | null;
        deallocated?: {
          __typename?: 'AllocationRunResultRequirementDeallocatedQuantitySummary';
          totalQuantity?: number | null;
          totalLocationCount?: number | null;
          reasons?: Array<AllocationRunResultDeallocatedReason> | null;
          uoms?: Array<{
            __typename?: 'AllocationRequirementUomQuantity';
            uom?: string | null;
            uomQuantity: number;
            totalVolume: number;
          }> | null;
        } | null;
      }>;
    } | null;
    sizeCompliance?: {
      __typename?: 'SizeCompliance';
      id: string;
      status: BatchJobStatus;
      summary?: {
        __typename?: 'SizeComplianceSummary';
        items: Array<{
          __typename?: 'SizeComplianceSummaryItem';
          totalCount: number;
          compliantCount: number;
          nonCompliantCount: number;
          unassignedCount: number;
          unknownCount: number;
          planeId: string;
          level: number;
        }>;
      } | null;
    } | null;
    weightCompliance?: {
      __typename?: 'WeightCompliance';
      id: string;
      status: BatchJobStatus;
      locationSummary?: {
        __typename?: 'LocationWeightComplianceSummary';
        items: Array<{
          __typename?: 'LocationWeightComplianceSummaryItem';
          totalCount: number;
          compliantCount: number;
          nonCompliantCount: number;
          unassignedCount: number;
          unknownCount: number;
          floorLevelCount: number;
          planeId: string;
          level: number;
        }>;
      } | null;
      baySummary?: {
        __typename?: 'BayWeightComplianceSummary';
        items: Array<{
          __typename?: 'BayWeightComplianceSummaryItem';
          totalCount: number;
          compliantCount: number;
          nonCompliantCount: number;
          unassignedCount: number;
          unknownCount: number;
          floorLevelCount: number;
          planeId: string;
        }>;
      } | null;
    } | null;
    orderLineSet?: {
      __typename?: 'SimulationOrderLineSet';
      id: string;
      status: BatchJobStatus;
      progress: number;
      createdAt: number;
    } | null;
    effectiveItemSet?: {
      __typename?: 'SimulationItemSet';
      id: string;
      status: BatchJobStatus;
      createdAt: number;
    } | null;
    layoutIssues?: {
      __typename?: 'LayoutIssueSet';
      status: BatchJobStatus;
      progress: number;
      summary?: {
        __typename?: 'LayoutIssueSummary';
        totalLocations: number;
        totalInvalidLocations: number;
        invalidLocations: Array<{
          __typename?: 'LayoutIssueInvalidLocationsSummary';
          planeId?: string | null;
          locationLevel?: number | null;
          reason: LayoutIssueInvalidLocationReason;
          count: number;
        }>;
      } | null;
    } | null;
    assignmentIssues?: {
      __typename?: 'AssignmentIssueSet';
      status: BatchJobStatus;
      progress: number;
      summary?: {
        __typename?: 'AssignmentIssueSummary';
        totalLocations: number;
        totalAssignedLocations: number;
        totalAssignmentItems: number;
        totalInvalidLocations: number;
        invalidLocations: Array<{
          __typename?: 'AssignmentIssueInvalidLocationsSummary';
          planeId?: string | null;
          locationLevel?: number | null;
          reason: AssignmentIssueInvalidLocationReason;
          count: number;
        }>;
      } | null;
    } | null;
    itemSetIssues?: {
      __typename?: 'ItemSetIssueSet';
      status: BatchJobStatus;
      progress: number;
      summary?: {
        __typename?: 'ItemSetIssueSummary';
        totalItems: number;
        totalUoms: number;
        totalInvalidItems: number;
        totalInvalidUoms: number;
        totalAssignedItems: number;
        unknownAssignedItems: number;
        invalidItems: Array<{
          __typename?: 'ItemSetIssueInvalidItemsSummary';
          reason: ItemSetIssueInvalidItemReason;
          count: number;
        }>;
        invalidUoms: Array<{
          __typename?: 'ItemSetIssueInvalidUomsSummary';
          reason: ItemSetIssueInvalidUomReason;
          count: number;
        }>;
      } | null;
    } | null;
    orderSetIssues?: {
      __typename?: 'OrderSetIssueSet';
      status: BatchJobStatus;
      progress: number;
      summary?: {
        __typename?: 'OrderSetIssueSummary';
        totalLocations: number;
        totalOrderLines: number;
        totalUnpickableLines: number;
        totalItems?: number | null;
        unpickableLines: Array<{
          __typename?: 'OrderSetIssueUnpickableLinesSummary';
          reason: OrderSetIssueUnpickableLineReason;
          count: number;
        }>;
        unpickableItems?: Array<{
          __typename?: 'OrderSetIssueUnpickableItemsSummary';
          reason: OrderSetIssueUnpickableLineReason;
          count: number;
        }> | null;
      } | null;
    } | null;
    allocationRequirementSet?: {
      __typename?: 'AllocationRequirementSet';
      id: string;
      status: BatchJobStatus;
      progress: number;
    } | null;
  };
};

export type LoadSimulationQueryVariables = Exact<{
  simulationId: Scalars['ID']['input'];
}>;

export type LoadSimulationQuery = {
  __typename?: 'Query';
  simulation?: {
    __typename?: 'Simulation';
    title: string;
    description?: string | null;
    status: SimulationStatus;
    updatedAt: number;
    id: string;
    userTags?: Array<string> | null;
    extraSettings?: any | null;
    layout?: { __typename?: 'Layout'; id: string } | null;
    assignment?: {
      __typename?: 'Assignment';
      id: string;
      title: string;
    } | null;
    orderSet?: { __typename?: 'OrderSet'; id: string } | null;
    itemSet?: {
      __typename?: 'ItemSet';
      id: string;
      uomTypes: Array<{ __typename?: 'ItemUomType'; uom: string }>;
    } | null;
    orderedQuantityReport?: {
      __typename?: 'OrderedQuantityReport';
      id: string;
      status: BatchJobStatus;
      progress: number;
      summary?: {
        __typename?: 'OrderedQuantityReportSummary';
        enoughCount: number;
        notEnoughCount: number;
        notOrderedCount: number;
      } | null;
    } | null;
    routingPolicy?: {
      __typename?: 'RoutingPolicy';
      rules?: Array<{
        __typename?: 'RoutingPolicyAgentRule';
        id?: string | null;
        title?: string | null;
        agentIds: Array<string>;
        featureRules?: Array<{
          __typename?: 'RoutingPolicyFeatureRule';
          featureId: string;
          direction?: { __typename?: 'Vector'; x: number; y: number } | null;
          threshold?: {
            __typename?: 'RoutingPolicyDirectionThreshold';
            angle?: number | null;
            distance?: number | null;
          } | null;
        }> | null;
      }> | null;
      defaultRule?: {
        __typename?: 'RoutingPolicyRule';
        featureRules?: Array<{
          __typename?: 'RoutingPolicyFeatureRule';
          featureId: string;
          direction?: { __typename?: 'Vector'; x: number; y: number } | null;
          threshold?: {
            __typename?: 'RoutingPolicyDirectionThreshold';
            angle?: number | null;
            distance?: number | null;
          } | null;
        }> | null;
      } | null;
      threshold?: {
        __typename?: 'RoutingPolicyDirectionThreshold';
        angle?: number | null;
        distance?: number | null;
      } | null;
    } | null;
    stackingPolicy?: {
      __typename?: 'StackingPolicy';
      topRules?: Array<{
        __typename?: 'StackingPolicyRule';
        id: string;
        title?: string | null;
        itemsMatch?: {
          __typename?: 'ItemFilterUnion';
          anyOf: Array<{
            __typename?: 'ItemFilterIntersection';
            id?: string | null;
            allOf: Array<{
              __typename?: 'ItemFilter';
              type: ItemFilterType;
              isNot?: boolean | null;
              isNull?: boolean | null;
              valueIn?: Array<{
                __typename?: 'FilterValue';
                title: string;
              }> | null;
              range?: {
                __typename?: 'NumberFilterRange';
                from?: number | null;
                to?: number | null;
              } | null;
              stringRange?: {
                __typename?: 'StringFilterRange';
                from?: string | null;
                excludeFrom?: boolean | null;
                to?: string | null;
                excludeTo?: boolean | null;
              } | null;
            }>;
          }>;
        } | null;
        subcategories?: {
          __typename?: 'StackingPolicyRuleSubcategories';
          type?: StackingPolicyRuleSubcategoryType | null;
          step?: number | null;
          boundaries?: Array<number> | null;
        } | null;
      }> | null;
      bottomRules?: Array<{
        __typename?: 'StackingPolicyRule';
        id: string;
        title?: string | null;
        itemsMatch?: {
          __typename?: 'ItemFilterUnion';
          anyOf: Array<{
            __typename?: 'ItemFilterIntersection';
            id?: string | null;
            allOf: Array<{
              __typename?: 'ItemFilter';
              type: ItemFilterType;
              isNot?: boolean | null;
              isNull?: boolean | null;
              valueIn?: Array<{
                __typename?: 'FilterValue';
                title: string;
              }> | null;
              range?: {
                __typename?: 'NumberFilterRange';
                from?: number | null;
                to?: number | null;
              } | null;
              stringRange?: {
                __typename?: 'StringFilterRange';
                from?: string | null;
                excludeFrom?: boolean | null;
                to?: string | null;
                excludeTo?: boolean | null;
              } | null;
            }>;
          }>;
        } | null;
        subcategories?: {
          __typename?: 'StackingPolicyRuleSubcategories';
          type?: StackingPolicyRuleSubcategoryType | null;
          step?: number | null;
          boundaries?: Array<number> | null;
        } | null;
      }> | null;
      fallbackRule?: {
        __typename?: 'StackingPolicyFallbackRule';
        subcategories?: {
          __typename?: 'StackingPolicyRuleSubcategories';
          type?: StackingPolicyRuleSubcategoryType | null;
          step?: number | null;
          boundaries?: Array<number> | null;
        } | null;
      } | null;
    } | null;
    assignmentPolicy?: {
      __typename?: 'AssignmentPolicy';
      fallbackLocationsAllowAnyItems?: boolean | null;
      fallbackItemsAllowAnyLocations?: boolean | null;
      rules: Array<{
        __typename?: 'AssignmentPolicyRule';
        id: string;
        title?: string | null;
        type?: AssignmentPolicyRuleMatchType | null;
        productsMatch?: {
          __typename?: 'SimulationItemFilterUnion';
          anyOf: Array<{
            __typename?: 'SimulationItemFilterIntersection';
            id?: string | null;
            allOf: Array<{
              __typename?: 'SimulationItemFilter';
              type: SimulationItemFilterType;
              isNull?: boolean | null;
              isNot?: boolean | null;
              valueIn?: Array<{
                __typename?: 'FilterValue';
                title: string;
              }> | null;
              range?: {
                __typename?: 'NumberFilterRange';
                from?: number | null;
                to?: number | null;
              } | null;
              stringRange?: {
                __typename?: 'StringFilterRange';
                from?: string | null;
                excludeFrom?: boolean | null;
                to?: string | null;
                excludeTo?: boolean | null;
              } | null;
            }>;
          }>;
        } | null;
        locationsMatch?: {
          __typename?: 'LocationFilterUnion';
          anyOf: Array<{
            __typename?: 'LocationFilterIntersection';
            id?: string | null;
            allOf: Array<{
              __typename?: 'LocationFilter';
              type: LocationFilterType;
              isNull?: boolean | null;
              isNot?: boolean | null;
              valueIn?: Array<{
                __typename?: 'FilterValue';
                title: string;
              }> | null;
              range?: {
                __typename?: 'NumberFilterRange';
                from?: number | null;
                to?: number | null;
              } | null;
              stringRange?: {
                __typename?: 'StringFilterRange';
                from?: string | null;
                excludeFrom?: boolean | null;
                to?: string | null;
                excludeTo?: boolean | null;
              } | null;
            }>;
          }>;
        } | null;
      }>;
      fallbackLocationsMatch?: {
        __typename?: 'LocationFilterUnion';
        anyOf: Array<{
          __typename?: 'LocationFilterIntersection';
          id?: string | null;
          allOf: Array<{
            __typename?: 'LocationFilter';
            type: LocationFilterType;
            isNull?: boolean | null;
            isNot?: boolean | null;
            valueIn?: Array<{
              __typename?: 'FilterValue';
              title: string;
            }> | null;
            range?: {
              __typename?: 'NumberFilterRange';
              from?: number | null;
              to?: number | null;
            } | null;
            stringRange?: {
              __typename?: 'StringFilterRange';
              from?: string | null;
              excludeFrom?: boolean | null;
              to?: string | null;
              excludeTo?: boolean | null;
            } | null;
          }>;
        }>;
      } | null;
    } | null;
    locationSharingPolicy?: {
      __typename?: 'LocationSharingPolicy';
      defaultMaxItemsPerLocation?: number | null;
      rules?: Array<{
        __typename?: 'LocationSharingPolicyRule';
        id: string;
        title?: string | null;
        maxItemsPerLocation: number;
        locationsMatch?: {
          __typename?: 'LocationFilterUnion';
          anyOf: Array<{
            __typename?: 'LocationFilterIntersection';
            id?: string | null;
            allOf: Array<{
              __typename?: 'LocationFilter';
              type: LocationFilterType;
              isNull?: boolean | null;
              isNot?: boolean | null;
              valueIn?: Array<{
                __typename?: 'FilterValue';
                title: string;
              }> | null;
              range?: {
                __typename?: 'NumberFilterRange';
                from?: number | null;
                to?: number | null;
              } | null;
              stringRange?: {
                __typename?: 'StringFilterRange';
                from?: string | null;
                excludeFrom?: boolean | null;
                to?: string | null;
                excludeTo?: boolean | null;
              } | null;
            }>;
          }>;
        } | null;
      }> | null;
    } | null;
    resourcePolicy?: {
      __typename?: 'ResourcePolicy';
      agents: Array<{
        __typename?: 'AgentSettings';
        id: string;
        title?: string | null;
        resourceTypeId: string;
        metadata?: any | null;
        roles?: Array<{
          __typename?: 'AgentRoleSettings';
          roleId: AgentRole;
          disabled?: boolean | null;
          preHandling?: {
            __typename?: 'PicklistProcessingEventSettings';
            timeFixed?: number | null;
            timePerOrder?: number | null;
            timePerPicklistLine?: number | null;
            timePerUom?: number | null;
          } | null;
          handlingPreparation?: {
            __typename?: 'PicklistLineProcessingEventSettings';
            timeFixed?: number | null;
            timePerUom?: number | null;
          } | null;
          handling?: {
            __typename?: 'HandlingEventSettings';
            timeFixed?: number | null;
            timePerWeight?: number | null;
            timePerVolume?: number | null;
            timePerUom?: Array<{
              __typename?: 'UomHandlingSettings';
              uom: string;
              timePerUom?: number | null;
            }> | null;
          } | null;
          reordering?: {
            __typename?: 'ReorderEventSettings';
            timeFixed?: number | null;
            timePerReorderedPosition?: number | null;
            timePerWeight?: number | null;
            timePerVolume?: number | null;
            timePerUom?: Array<{
              __typename?: 'UomHandlingSettings';
              uom: string;
              timePerUom?: number | null;
            }> | null;
          } | null;
          handlingCompletion?: {
            __typename?: 'PicklistLineProcessingEventSettings';
            timeFixed?: number | null;
            timePerUom?: number | null;
          } | null;
          postHandling?: {
            __typename?: 'PicklistProcessingEventSettings';
            timeFixed?: number | null;
            timePerOrder?: number | null;
            timePerPicklistLine?: number | null;
            timePerUom?: number | null;
          } | null;
        }> | null;
        specs?: {
          __typename?: 'AgentSpecs';
          horizontalTravelling?: {
            __typename?: 'HorizontalTravellingEventSettings';
            speed?: {
              __typename?: 'SpeedSettings';
              speedLaden?: number | null;
              speedUnladen?: number | null;
            } | null;
          } | null;
          verticalTravelling?: {
            __typename?: 'VerticalTravellingEventSettings';
            speedUp?: {
              __typename?: 'SpeedSettings';
              speedLaden?: number | null;
              speedUnladen?: number | null;
            } | null;
            speedDown?: {
              __typename?: 'SpeedSettings';
              speedLaden?: number | null;
              speedUnladen?: number | null;
            } | null;
          } | null;
          capacity?: {
            __typename?: 'AgentCapacitySettings';
            maxVolume?: number | null;
            maxWeight?: number | null;
            maxUomQuantity?: Array<{
              __typename?: 'AgentCapacityUomSettings';
              uom: string;
              quantity?: number | null;
            }> | null;
          } | null;
          powerSource?: {
            __typename?: 'AgentPowerSourceSettings';
            energyConsumptionPerSecond?: number | null;
          } | null;
        } | null;
        cost?: {
          __typename?: 'AgentCostSettings';
          costPerSecond?: number | null;
        } | null;
        utilisation?: {
          __typename?: 'AgentUtilisationSettings';
          agentAmount?: number | null;
          secondsOperation?: number | null;
        } | null;
      }>;
    } | null;
    waypointPolicy?: {
      __typename?: 'WaypointPolicy';
      rules?: Array<{
        __typename?: 'WaypointPolicyRule';
        id: string;
        title?: string | null;
        startPoints?: {
          __typename?: 'WaypointPolicyRuleTerminalPoints';
          locationsMatch?: {
            __typename?: 'LocationFilterUnion';
            anyOf: Array<{
              __typename?: 'LocationFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'LocationFilter';
                type: LocationFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
        } | null;
        endPoints?: {
          __typename?: 'WaypointPolicyRuleTerminalPoints';
          locationsMatch?: {
            __typename?: 'LocationFilterUnion';
            anyOf: Array<{
              __typename?: 'LocationFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'LocationFilter';
                type: LocationFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
        } | null;
      }> | null;
    } | null;
    pickingPolicy?: {
      __typename?: 'PickingPolicy';
      orderLineRules?: Array<{
        __typename?: 'PickingPolicyOrderLineRule';
        id: string;
        title?: string | null;
        usePickingContainer?: boolean | null;
        pickingRequirementsSettings?: {
          __typename?: 'PickingPolicyPickingRequirementsSettings';
          groupBy: PickingRequirementsGroupBy;
          startLocationIdSource?: PickingRequirementTerminalLocationSource | null;
          endLocationIdSource?: PickingRequirementTerminalLocationSource | null;
          wave?: {
            __typename?: 'PickingWaveSettings';
            useDeliveryRoute?: boolean | null;
            useDock?: boolean | null;
            useStagingArea?: boolean | null;
            useWaveId?: boolean | null;
            pickByDateSettings?: {
              __typename?: 'PickingWavePickByDateSettings';
              mode: PickingWavePickByDateMode;
              fixedDeadlines?: Array<any> | null;
            } | null;
          } | null;
          batch?: {
            __typename?: 'PickingRequirementsBatchSettings';
            maxOrderCount?: number | null;
            maxOrderLineCount?: number | null;
          } | null;
        } | null;
        orderLinesMatch?: {
          __typename?: 'OrderLineFilterUnion';
          anyOf: Array<{
            __typename?: 'OrderLineFilterIntersection';
            id?: string | null;
            allOf: Array<{
              __typename?: 'OrderLineFilter';
              type: OrderLineFilterType;
              isNull?: boolean | null;
              isNot?: boolean | null;
              valueIn?: Array<{
                __typename?: 'FilterValue';
                title: string;
              }> | null;
              range?: {
                __typename?: 'NumberFilterRange';
                from?: number | null;
                to?: number | null;
              } | null;
              stringRange?: {
                __typename?: 'StringFilterRange';
                from?: string | null;
                excludeFrom?: boolean | null;
                to?: string | null;
                excludeTo?: boolean | null;
              } | null;
            }>;
          }>;
        } | null;
        pickingRules?: Array<{
          __typename?: 'PickingPolicyPickingRule';
          id: string;
          title?: string | null;
          uomTypes?: Array<string> | null;
          agentId: string;
          locationSelectionMode?: PickingPolicyLocationSelectionMode | null;
          picklistGroupKey?: string | null;
          locationsMatch?: {
            __typename?: 'LocationFilterUnion';
            anyOf: Array<{
              __typename?: 'LocationFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'LocationFilter';
                type: LocationFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
          waypointSettings?: {
            __typename?: 'PickingPolicyWaypointSettings';
            ruleId?: string | null;
          } | null;
        }> | null;
        picklistSettings?: {
          __typename?: 'PickingPolicyPicklistSettings';
          usePickingPriority?: boolean | null;
          picklistLinesOrder?: Array<{
            __typename?: 'PicklistLinesSortBy';
            type: PicklistLinesOrderOptions;
            direction: PicklistLinesSortDirection;
            nullsFirst?: boolean | null;
          }> | null;
        } | null;
      }> | null;
    } | null;
    allocationSettings?: {
      __typename?: 'AllocationSettings';
      allocationPolicy?: {
        __typename?: 'AllocationPolicy';
        rules?: Array<{
          __typename?: 'AllocationRule';
          id: string;
          title?: string | null;
          uomTypes?: Array<string> | null;
          itemsMatch?: {
            __typename?: 'SimulationItemFilterUnion';
            anyOf: Array<{
              __typename?: 'SimulationItemFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'SimulationItemFilter';
                type: SimulationItemFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
          locationsMatch?: {
            __typename?: 'LocationFilterUnion';
            anyOf: Array<{
              __typename?: 'LocationFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'LocationFilter';
                type: LocationFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
          limitSettings?: {
            __typename?: 'AllocationLimitSettings';
            applyTo?: AllocationLimitApplyTo | null;
            maxPickableAssignments?: number | null;
            maxAllocatedAssignments?: number | null;
            maxPickableVolume?: number | null;
            maxAllocatedVolume?: number | null;
          } | null;
          roundSettings?: {
            __typename?: 'AllocationRoundSettings';
            maxAssignmentsPerRound?: number | null;
          } | null;
          pickabilitySettings?: {
            __typename?: 'AllocationPickabilitySettings';
            enforcePickability?: AllocationEnforcePickability | null;
          } | null;
          prioritySettings?: {
            __typename?: 'AllocationPrioritySettings';
            itemPriority?: Array<{
              __typename?: 'AllocationItemPrioritySpec';
              priority: AllocationItemPriority;
              direction?: AllocationPriorityDirection | null;
            }> | null;
            locationPriority?: Array<{
              __typename?: 'AllocationLocationPrioritySpec';
              priority: AllocationLocationPriority;
              direction?: AllocationPriorityDirection | null;
            }> | null;
          } | null;
          replacementSettings?: {
            __typename?: 'AllocationReplacementSettings';
            allow?: AllocationAllowReplacement | null;
          } | null;
          multiplexingSettings?: {
            __typename?: 'AllocationMultiplexingSettings';
            allow?: AllocationAllowReplacement | null;
          } | null;
          requirementAggregationSettings?: {
            __typename?: 'AllocationRequirementAggregationSettings';
            aggregation?: AllocationRequirementAggregationMethod | null;
            numberOfPeriods?: number | null;
          } | null;
          constraintSettings?: {
            __typename?: 'AllocationConstraintSettings';
            assignmentPolicyConstraint?: {
              __typename?: 'AssignmentPolicyConstraintSettings';
              disabled?: boolean | null;
            } | null;
            locationSizeConstraint?: {
              __typename?: 'LocationSizeConstraintSettings';
              disabled?: boolean | null;
              unknownAsNoncompliant?: boolean | null;
            } | null;
            bayWidthConstraint?: {
              __typename?: 'BayWidthConstraintSettings';
              disabled?: boolean | null;
            } | null;
          } | null;
        }> | null;
        defaultRule?: {
          __typename?: 'AllocationRule';
          id: string;
          title?: string | null;
          uomTypes?: Array<string> | null;
          itemsMatch?: {
            __typename?: 'SimulationItemFilterUnion';
            anyOf: Array<{
              __typename?: 'SimulationItemFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'SimulationItemFilter';
                type: SimulationItemFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
          locationsMatch?: {
            __typename?: 'LocationFilterUnion';
            anyOf: Array<{
              __typename?: 'LocationFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'LocationFilter';
                type: LocationFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
          limitSettings?: {
            __typename?: 'AllocationLimitSettings';
            applyTo?: AllocationLimitApplyTo | null;
            maxPickableAssignments?: number | null;
            maxAllocatedAssignments?: number | null;
            maxPickableVolume?: number | null;
            maxAllocatedVolume?: number | null;
          } | null;
          roundSettings?: {
            __typename?: 'AllocationRoundSettings';
            maxAssignmentsPerRound?: number | null;
          } | null;
          pickabilitySettings?: {
            __typename?: 'AllocationPickabilitySettings';
            enforcePickability?: AllocationEnforcePickability | null;
          } | null;
          prioritySettings?: {
            __typename?: 'AllocationPrioritySettings';
            itemPriority?: Array<{
              __typename?: 'AllocationItemPrioritySpec';
              priority: AllocationItemPriority;
              direction?: AllocationPriorityDirection | null;
            }> | null;
            locationPriority?: Array<{
              __typename?: 'AllocationLocationPrioritySpec';
              priority: AllocationLocationPriority;
              direction?: AllocationPriorityDirection | null;
            }> | null;
          } | null;
          replacementSettings?: {
            __typename?: 'AllocationReplacementSettings';
            allow?: AllocationAllowReplacement | null;
          } | null;
          multiplexingSettings?: {
            __typename?: 'AllocationMultiplexingSettings';
            allow?: AllocationAllowReplacement | null;
          } | null;
          requirementAggregationSettings?: {
            __typename?: 'AllocationRequirementAggregationSettings';
            aggregation?: AllocationRequirementAggregationMethod | null;
            numberOfPeriods?: number | null;
          } | null;
          constraintSettings?: {
            __typename?: 'AllocationConstraintSettings';
            assignmentPolicyConstraint?: {
              __typename?: 'AssignmentPolicyConstraintSettings';
              disabled?: boolean | null;
            } | null;
            locationSizeConstraint?: {
              __typename?: 'LocationSizeConstraintSettings';
              disabled?: boolean | null;
              unknownAsNoncompliant?: boolean | null;
            } | null;
            bayWidthConstraint?: {
              __typename?: 'BayWidthConstraintSettings';
              disabled?: boolean | null;
            } | null;
          } | null;
        } | null;
      } | null;
      deallocationPolicy?: {
        __typename?: 'DeallocationPolicy';
        rules?: Array<{
          __typename?: 'DeallocationRule';
          id?: string | null;
          title?: string | null;
          deallocateType?: DeallocateType | null;
          reallocateType?: ReallocateType | null;
          itemsMatch?: {
            __typename?: 'SimulationItemFilterUnion';
            anyOf: Array<{
              __typename?: 'SimulationItemFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'SimulationItemFilter';
                type: SimulationItemFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
          locationsMatch?: {
            __typename?: 'LocationFilterUnion';
            anyOf: Array<{
              __typename?: 'LocationFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'LocationFilter';
                type: LocationFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
          constraintSettings?: {
            __typename?: 'AllocationConstraintSettings';
            assignmentPolicyConstraint?: {
              __typename?: 'AssignmentPolicyConstraintSettings';
              disabled?: boolean | null;
            } | null;
            locationSizeConstraint?: {
              __typename?: 'LocationSizeConstraintSettings';
              disabled?: boolean | null;
              unknownAsNoncompliant?: boolean | null;
            } | null;
            bayWidthConstraint?: {
              __typename?: 'BayWidthConstraintSettings';
              disabled?: boolean | null;
            } | null;
          } | null;
          reallocateQuantitySettings?: {
            __typename?: 'ReallocateQuantitySettings';
            reallocateUom?: ReallocateUomSource | null;
            reallocateQuantity?: ReallocateQuantitySource | null;
            fixedUom?: string | null;
            fixedUomQuantity?: number | null;
          } | null;
        }> | null;
        defaultRule?: {
          __typename?: 'DeallocationRule';
          id?: string | null;
          title?: string | null;
          deallocateType?: DeallocateType | null;
          reallocateType?: ReallocateType | null;
          itemsMatch?: {
            __typename?: 'SimulationItemFilterUnion';
            anyOf: Array<{
              __typename?: 'SimulationItemFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'SimulationItemFilter';
                type: SimulationItemFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
          locationsMatch?: {
            __typename?: 'LocationFilterUnion';
            anyOf: Array<{
              __typename?: 'LocationFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'LocationFilter';
                type: LocationFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
          constraintSettings?: {
            __typename?: 'AllocationConstraintSettings';
            assignmentPolicyConstraint?: {
              __typename?: 'AssignmentPolicyConstraintSettings';
              disabled?: boolean | null;
            } | null;
            locationSizeConstraint?: {
              __typename?: 'LocationSizeConstraintSettings';
              disabled?: boolean | null;
              unknownAsNoncompliant?: boolean | null;
            } | null;
            bayWidthConstraint?: {
              __typename?: 'BayWidthConstraintSettings';
              disabled?: boolean | null;
            } | null;
          } | null;
          reallocateQuantitySettings?: {
            __typename?: 'ReallocateQuantitySettings';
            reallocateUom?: ReallocateUomSource | null;
            reallocateQuantity?: ReallocateQuantitySource | null;
            fixedUom?: string | null;
            fixedUomQuantity?: number | null;
          } | null;
        } | null;
      } | null;
      orderSetFilter?: {
        __typename?: 'OrderSetFilter';
        orderDate?: {
          __typename?: 'LocalDateRangeFilter';
          from?: string | null;
          to?: string | null;
          excludeFrom?: boolean | null;
          excludeTo?: boolean | null;
          includeNull?: boolean | null;
        } | null;
      } | null;
      requirementSource?: {
        __typename?: 'AllocationRequirementSourceSettings';
        useOrderSetDemand?: boolean | null;
      } | null;
      stockLimitSettings?: {
        __typename?: 'AllocationStockLimitSettings';
        enabled?: boolean | null;
      } | null;
    } | null;
    analyzeResult?: {
      __typename?: 'AnalyzeResult';
      id: string;
      status: AnalyzeResultStatus;
    } | null;
    optimizationRuns: {
      __typename?: 'OptimizationRunsConnection';
      content: Array<{
        __typename?: 'OptimizationRun';
        id: string;
        status: OptimizationRunStatus;
      }>;
    };
    optimizationSettings?: {
      __typename?: 'OptimizationSettings';
      locationSizeConstraint?: {
        __typename?: 'LocationSizeConstraintSettings';
        disabled?: boolean | null;
      } | null;
      bayWidthConstraint?: {
        __typename?: 'BayWidthConstraintSettings';
        disabled?: boolean | null;
      } | null;
      terminationSettings?: {
        __typename?: 'TerminationSettings';
        targetImprovement?: number | null;
        timeLimit?: number | null;
        minImprovementRate?: {
          __typename?: 'MinImprovementRateSettings';
          minImprovementChange: number;
          steps: number;
        } | null;
      } | null;
      swapSettings?: {
        __typename?: 'SwapSettings';
        rules?: Array<{
          __typename?: 'OptimizationSwapRule';
          id?: string | null;
          title?: string | null;
          src?: {
            __typename?: 'AssignmentSubsetSpec';
            unassignedLocationsMatch?: UnassignedLocationsMatch | null;
            locationsMatch?: {
              __typename?: 'LocationFilterUnion';
              anyOf: Array<{
                __typename?: 'LocationFilterIntersection';
                id?: string | null;
                allOf: Array<{
                  __typename?: 'LocationFilter';
                  type: LocationFilterType;
                  isNull?: boolean | null;
                  isNot?: boolean | null;
                  valueIn?: Array<{
                    __typename?: 'FilterValue';
                    title: string;
                  }> | null;
                  range?: {
                    __typename?: 'NumberFilterRange';
                    from?: number | null;
                    to?: number | null;
                  } | null;
                  stringRange?: {
                    __typename?: 'StringFilterRange';
                    from?: string | null;
                    excludeFrom?: boolean | null;
                    to?: string | null;
                    excludeTo?: boolean | null;
                  } | null;
                }>;
              }>;
            } | null;
            itemsMatch?: {
              __typename?: 'SimulationItemFilterUnion';
              anyOf: Array<{
                __typename?: 'SimulationItemFilterIntersection';
                id?: string | null;
                allOf: Array<{
                  __typename?: 'SimulationItemFilter';
                  type: SimulationItemFilterType;
                  isNull?: boolean | null;
                  isNot?: boolean | null;
                  valueIn?: Array<{
                    __typename?: 'FilterValue';
                    title: string;
                  }> | null;
                  range?: {
                    __typename?: 'NumberFilterRange';
                    from?: number | null;
                    to?: number | null;
                  } | null;
                  stringRange?: {
                    __typename?: 'StringFilterRange';
                    from?: string | null;
                    excludeFrom?: boolean | null;
                    to?: string | null;
                    excludeTo?: boolean | null;
                  } | null;
                }>;
              }>;
            } | null;
          } | null;
          dest?: {
            __typename?: 'AssignmentSubsetSpec';
            unassignedLocationsMatch?: UnassignedLocationsMatch | null;
            locationsMatch?: {
              __typename?: 'LocationFilterUnion';
              anyOf: Array<{
                __typename?: 'LocationFilterIntersection';
                id?: string | null;
                allOf: Array<{
                  __typename?: 'LocationFilter';
                  type: LocationFilterType;
                  isNull?: boolean | null;
                  isNot?: boolean | null;
                  valueIn?: Array<{
                    __typename?: 'FilterValue';
                    title: string;
                  }> | null;
                  range?: {
                    __typename?: 'NumberFilterRange';
                    from?: number | null;
                    to?: number | null;
                  } | null;
                  stringRange?: {
                    __typename?: 'StringFilterRange';
                    from?: string | null;
                    excludeFrom?: boolean | null;
                    to?: string | null;
                    excludeTo?: boolean | null;
                  } | null;
                }>;
              }>;
            } | null;
            itemsMatch?: {
              __typename?: 'SimulationItemFilterUnion';
              anyOf: Array<{
                __typename?: 'SimulationItemFilterIntersection';
                id?: string | null;
                allOf: Array<{
                  __typename?: 'SimulationItemFilter';
                  type: SimulationItemFilterType;
                  isNull?: boolean | null;
                  isNot?: boolean | null;
                  valueIn?: Array<{
                    __typename?: 'FilterValue';
                    title: string;
                  }> | null;
                  range?: {
                    __typename?: 'NumberFilterRange';
                    from?: number | null;
                    to?: number | null;
                  } | null;
                  stringRange?: {
                    __typename?: 'StringFilterRange';
                    from?: string | null;
                    excludeFrom?: boolean | null;
                    to?: string | null;
                    excludeTo?: boolean | null;
                  } | null;
                }>;
              }>;
            } | null;
          } | null;
        }> | null;
      } | null;
      loadBalancing?: {
        __typename?: 'LoadBalancingPolicy';
        rules: Array<{
          __typename?: 'LoadBalancingRule';
          id?: string | null;
          title?: string | null;
          type: LoadBalancingZoneType;
          objectiveWeight?: number | null;
          constraint?: {
            __typename?: 'LoadBalancingConstraint';
            maxRelativeDeviation: number;
          } | null;
          include?: {
            __typename?: 'LocationFilterUnion';
            anyOf: Array<{
              __typename?: 'LocationFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'LocationFilter';
                type: LocationFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
        }>;
      } | null;
      limitSettings?: {
        __typename?: 'LimitSettings';
        maxMoves?: number | null;
      } | null;
      reassignJobs?: {
        __typename?: 'ReassignJobsSettings';
        disabled?: boolean | null;
        cycleMode?: ReassignJobCycleMode | null;
      } | null;
      policyOverrides?: {
        __typename?: 'OptimizationPolicyOverrides';
        resourcePolicy?: {
          __typename?: 'ResourcePolicy';
          agents: Array<{
            __typename?: 'AgentSettings';
            id: string;
            title?: string | null;
            resourceTypeId: string;
            metadata?: any | null;
            roles?: Array<{
              __typename?: 'AgentRoleSettings';
              roleId: AgentRole;
              disabled?: boolean | null;
              preHandling?: {
                __typename?: 'PicklistProcessingEventSettings';
                timeFixed?: number | null;
                timePerOrder?: number | null;
                timePerPicklistLine?: number | null;
                timePerUom?: number | null;
              } | null;
              handlingPreparation?: {
                __typename?: 'PicklistLineProcessingEventSettings';
                timeFixed?: number | null;
                timePerUom?: number | null;
              } | null;
              handling?: {
                __typename?: 'HandlingEventSettings';
                timeFixed?: number | null;
                timePerWeight?: number | null;
                timePerVolume?: number | null;
                timePerUom?: Array<{
                  __typename?: 'UomHandlingSettings';
                  uom: string;
                  timePerUom?: number | null;
                }> | null;
              } | null;
              reordering?: {
                __typename?: 'ReorderEventSettings';
                timeFixed?: number | null;
                timePerReorderedPosition?: number | null;
                timePerWeight?: number | null;
                timePerVolume?: number | null;
                timePerUom?: Array<{
                  __typename?: 'UomHandlingSettings';
                  uom: string;
                  timePerUom?: number | null;
                }> | null;
              } | null;
              handlingCompletion?: {
                __typename?: 'PicklistLineProcessingEventSettings';
                timeFixed?: number | null;
                timePerUom?: number | null;
              } | null;
              postHandling?: {
                __typename?: 'PicklistProcessingEventSettings';
                timeFixed?: number | null;
                timePerOrder?: number | null;
                timePerPicklistLine?: number | null;
                timePerUom?: number | null;
              } | null;
            }> | null;
            specs?: {
              __typename?: 'AgentSpecs';
              horizontalTravelling?: {
                __typename?: 'HorizontalTravellingEventSettings';
                speed?: {
                  __typename?: 'SpeedSettings';
                  speedLaden?: number | null;
                  speedUnladen?: number | null;
                } | null;
              } | null;
              verticalTravelling?: {
                __typename?: 'VerticalTravellingEventSettings';
                speedUp?: {
                  __typename?: 'SpeedSettings';
                  speedLaden?: number | null;
                  speedUnladen?: number | null;
                } | null;
                speedDown?: {
                  __typename?: 'SpeedSettings';
                  speedLaden?: number | null;
                  speedUnladen?: number | null;
                } | null;
              } | null;
              capacity?: {
                __typename?: 'AgentCapacitySettings';
                maxVolume?: number | null;
                maxWeight?: number | null;
                maxUomQuantity?: Array<{
                  __typename?: 'AgentCapacityUomSettings';
                  uom: string;
                  quantity?: number | null;
                }> | null;
              } | null;
              powerSource?: {
                __typename?: 'AgentPowerSourceSettings';
                energyConsumptionPerSecond?: number | null;
              } | null;
            } | null;
            cost?: {
              __typename?: 'AgentCostSettings';
              costPerSecond?: number | null;
            } | null;
            utilisation?: {
              __typename?: 'AgentUtilisationSettings';
              agentAmount?: number | null;
              secondsOperation?: number | null;
            } | null;
          }>;
        } | null;
      } | null;
      pickabilityConstraint?: {
        __typename?: 'PickabilityConstraintSettings';
        disabled?: boolean | null;
      } | null;
      assignmentCapacitySettings?: {
        __typename?: 'AssignmentCapacitySettings';
        avoidMerge?: boolean | null;
      } | null;
    } | null;
    latestAllocationRun?: {
      __typename?: 'AllocationRun';
      id: string;
      progress?: number | null;
      seed?: number | null;
      status: BatchJobStatus;
      startedAt?: number | null;
      completedAt?: number | null;
      createdAt: number;
      allocationRunType: AllocationType;
      allocationSettings?: {
        __typename?: 'AllocationSettings';
        allocationPolicy?: {
          __typename?: 'AllocationPolicy';
          rules?: Array<{
            __typename?: 'AllocationRule';
            id: string;
            title?: string | null;
            uomTypes?: Array<string> | null;
            itemsMatch?: {
              __typename?: 'SimulationItemFilterUnion';
              anyOf: Array<{
                __typename?: 'SimulationItemFilterIntersection';
                id?: string | null;
                allOf: Array<{
                  __typename?: 'SimulationItemFilter';
                  type: SimulationItemFilterType;
                  isNull?: boolean | null;
                  isNot?: boolean | null;
                  valueIn?: Array<{
                    __typename?: 'FilterValue';
                    title: string;
                  }> | null;
                  range?: {
                    __typename?: 'NumberFilterRange';
                    from?: number | null;
                    to?: number | null;
                  } | null;
                  stringRange?: {
                    __typename?: 'StringFilterRange';
                    from?: string | null;
                    excludeFrom?: boolean | null;
                    to?: string | null;
                    excludeTo?: boolean | null;
                  } | null;
                }>;
              }>;
            } | null;
            locationsMatch?: {
              __typename?: 'LocationFilterUnion';
              anyOf: Array<{
                __typename?: 'LocationFilterIntersection';
                id?: string | null;
                allOf: Array<{
                  __typename?: 'LocationFilter';
                  type: LocationFilterType;
                  isNull?: boolean | null;
                  isNot?: boolean | null;
                  valueIn?: Array<{
                    __typename?: 'FilterValue';
                    title: string;
                  }> | null;
                  range?: {
                    __typename?: 'NumberFilterRange';
                    from?: number | null;
                    to?: number | null;
                  } | null;
                  stringRange?: {
                    __typename?: 'StringFilterRange';
                    from?: string | null;
                    excludeFrom?: boolean | null;
                    to?: string | null;
                    excludeTo?: boolean | null;
                  } | null;
                }>;
              }>;
            } | null;
            limitSettings?: {
              __typename?: 'AllocationLimitSettings';
              applyTo?: AllocationLimitApplyTo | null;
              maxPickableAssignments?: number | null;
              maxAllocatedAssignments?: number | null;
              maxPickableVolume?: number | null;
              maxAllocatedVolume?: number | null;
            } | null;
            roundSettings?: {
              __typename?: 'AllocationRoundSettings';
              maxAssignmentsPerRound?: number | null;
            } | null;
            pickabilitySettings?: {
              __typename?: 'AllocationPickabilitySettings';
              enforcePickability?: AllocationEnforcePickability | null;
            } | null;
            prioritySettings?: {
              __typename?: 'AllocationPrioritySettings';
              itemPriority?: Array<{
                __typename?: 'AllocationItemPrioritySpec';
                priority: AllocationItemPriority;
                direction?: AllocationPriorityDirection | null;
              }> | null;
              locationPriority?: Array<{
                __typename?: 'AllocationLocationPrioritySpec';
                priority: AllocationLocationPriority;
                direction?: AllocationPriorityDirection | null;
              }> | null;
            } | null;
            replacementSettings?: {
              __typename?: 'AllocationReplacementSettings';
              allow?: AllocationAllowReplacement | null;
            } | null;
            multiplexingSettings?: {
              __typename?: 'AllocationMultiplexingSettings';
              allow?: AllocationAllowReplacement | null;
            } | null;
            requirementAggregationSettings?: {
              __typename?: 'AllocationRequirementAggregationSettings';
              aggregation?: AllocationRequirementAggregationMethod | null;
              numberOfPeriods?: number | null;
            } | null;
            constraintSettings?: {
              __typename?: 'AllocationConstraintSettings';
              assignmentPolicyConstraint?: {
                __typename?: 'AssignmentPolicyConstraintSettings';
                disabled?: boolean | null;
              } | null;
              locationSizeConstraint?: {
                __typename?: 'LocationSizeConstraintSettings';
                disabled?: boolean | null;
                unknownAsNoncompliant?: boolean | null;
              } | null;
              bayWidthConstraint?: {
                __typename?: 'BayWidthConstraintSettings';
                disabled?: boolean | null;
              } | null;
            } | null;
          }> | null;
          defaultRule?: {
            __typename?: 'AllocationRule';
            id: string;
            title?: string | null;
            uomTypes?: Array<string> | null;
            itemsMatch?: {
              __typename?: 'SimulationItemFilterUnion';
              anyOf: Array<{
                __typename?: 'SimulationItemFilterIntersection';
                id?: string | null;
                allOf: Array<{
                  __typename?: 'SimulationItemFilter';
                  type: SimulationItemFilterType;
                  isNull?: boolean | null;
                  isNot?: boolean | null;
                  valueIn?: Array<{
                    __typename?: 'FilterValue';
                    title: string;
                  }> | null;
                  range?: {
                    __typename?: 'NumberFilterRange';
                    from?: number | null;
                    to?: number | null;
                  } | null;
                  stringRange?: {
                    __typename?: 'StringFilterRange';
                    from?: string | null;
                    excludeFrom?: boolean | null;
                    to?: string | null;
                    excludeTo?: boolean | null;
                  } | null;
                }>;
              }>;
            } | null;
            locationsMatch?: {
              __typename?: 'LocationFilterUnion';
              anyOf: Array<{
                __typename?: 'LocationFilterIntersection';
                id?: string | null;
                allOf: Array<{
                  __typename?: 'LocationFilter';
                  type: LocationFilterType;
                  isNull?: boolean | null;
                  isNot?: boolean | null;
                  valueIn?: Array<{
                    __typename?: 'FilterValue';
                    title: string;
                  }> | null;
                  range?: {
                    __typename?: 'NumberFilterRange';
                    from?: number | null;
                    to?: number | null;
                  } | null;
                  stringRange?: {
                    __typename?: 'StringFilterRange';
                    from?: string | null;
                    excludeFrom?: boolean | null;
                    to?: string | null;
                    excludeTo?: boolean | null;
                  } | null;
                }>;
              }>;
            } | null;
            limitSettings?: {
              __typename?: 'AllocationLimitSettings';
              applyTo?: AllocationLimitApplyTo | null;
              maxPickableAssignments?: number | null;
              maxAllocatedAssignments?: number | null;
              maxPickableVolume?: number | null;
              maxAllocatedVolume?: number | null;
            } | null;
            roundSettings?: {
              __typename?: 'AllocationRoundSettings';
              maxAssignmentsPerRound?: number | null;
            } | null;
            pickabilitySettings?: {
              __typename?: 'AllocationPickabilitySettings';
              enforcePickability?: AllocationEnforcePickability | null;
            } | null;
            prioritySettings?: {
              __typename?: 'AllocationPrioritySettings';
              itemPriority?: Array<{
                __typename?: 'AllocationItemPrioritySpec';
                priority: AllocationItemPriority;
                direction?: AllocationPriorityDirection | null;
              }> | null;
              locationPriority?: Array<{
                __typename?: 'AllocationLocationPrioritySpec';
                priority: AllocationLocationPriority;
                direction?: AllocationPriorityDirection | null;
              }> | null;
            } | null;
            replacementSettings?: {
              __typename?: 'AllocationReplacementSettings';
              allow?: AllocationAllowReplacement | null;
            } | null;
            multiplexingSettings?: {
              __typename?: 'AllocationMultiplexingSettings';
              allow?: AllocationAllowReplacement | null;
            } | null;
            requirementAggregationSettings?: {
              __typename?: 'AllocationRequirementAggregationSettings';
              aggregation?: AllocationRequirementAggregationMethod | null;
              numberOfPeriods?: number | null;
            } | null;
            constraintSettings?: {
              __typename?: 'AllocationConstraintSettings';
              assignmentPolicyConstraint?: {
                __typename?: 'AssignmentPolicyConstraintSettings';
                disabled?: boolean | null;
              } | null;
              locationSizeConstraint?: {
                __typename?: 'LocationSizeConstraintSettings';
                disabled?: boolean | null;
                unknownAsNoncompliant?: boolean | null;
              } | null;
              bayWidthConstraint?: {
                __typename?: 'BayWidthConstraintSettings';
                disabled?: boolean | null;
              } | null;
            } | null;
          } | null;
        } | null;
        deallocationPolicy?: {
          __typename?: 'DeallocationPolicy';
          rules?: Array<{
            __typename?: 'DeallocationRule';
            id?: string | null;
            title?: string | null;
            deallocateType?: DeallocateType | null;
            reallocateType?: ReallocateType | null;
            itemsMatch?: {
              __typename?: 'SimulationItemFilterUnion';
              anyOf: Array<{
                __typename?: 'SimulationItemFilterIntersection';
                id?: string | null;
                allOf: Array<{
                  __typename?: 'SimulationItemFilter';
                  type: SimulationItemFilterType;
                  isNull?: boolean | null;
                  isNot?: boolean | null;
                  valueIn?: Array<{
                    __typename?: 'FilterValue';
                    title: string;
                  }> | null;
                  range?: {
                    __typename?: 'NumberFilterRange';
                    from?: number | null;
                    to?: number | null;
                  } | null;
                  stringRange?: {
                    __typename?: 'StringFilterRange';
                    from?: string | null;
                    excludeFrom?: boolean | null;
                    to?: string | null;
                    excludeTo?: boolean | null;
                  } | null;
                }>;
              }>;
            } | null;
            locationsMatch?: {
              __typename?: 'LocationFilterUnion';
              anyOf: Array<{
                __typename?: 'LocationFilterIntersection';
                id?: string | null;
                allOf: Array<{
                  __typename?: 'LocationFilter';
                  type: LocationFilterType;
                  isNull?: boolean | null;
                  isNot?: boolean | null;
                  valueIn?: Array<{
                    __typename?: 'FilterValue';
                    title: string;
                  }> | null;
                  range?: {
                    __typename?: 'NumberFilterRange';
                    from?: number | null;
                    to?: number | null;
                  } | null;
                  stringRange?: {
                    __typename?: 'StringFilterRange';
                    from?: string | null;
                    excludeFrom?: boolean | null;
                    to?: string | null;
                    excludeTo?: boolean | null;
                  } | null;
                }>;
              }>;
            } | null;
            constraintSettings?: {
              __typename?: 'AllocationConstraintSettings';
              assignmentPolicyConstraint?: {
                __typename?: 'AssignmentPolicyConstraintSettings';
                disabled?: boolean | null;
              } | null;
              locationSizeConstraint?: {
                __typename?: 'LocationSizeConstraintSettings';
                disabled?: boolean | null;
                unknownAsNoncompliant?: boolean | null;
              } | null;
              bayWidthConstraint?: {
                __typename?: 'BayWidthConstraintSettings';
                disabled?: boolean | null;
              } | null;
            } | null;
            reallocateQuantitySettings?: {
              __typename?: 'ReallocateQuantitySettings';
              reallocateUom?: ReallocateUomSource | null;
              reallocateQuantity?: ReallocateQuantitySource | null;
              fixedUom?: string | null;
              fixedUomQuantity?: number | null;
            } | null;
          }> | null;
          defaultRule?: {
            __typename?: 'DeallocationRule';
            id?: string | null;
            title?: string | null;
            deallocateType?: DeallocateType | null;
            reallocateType?: ReallocateType | null;
            itemsMatch?: {
              __typename?: 'SimulationItemFilterUnion';
              anyOf: Array<{
                __typename?: 'SimulationItemFilterIntersection';
                id?: string | null;
                allOf: Array<{
                  __typename?: 'SimulationItemFilter';
                  type: SimulationItemFilterType;
                  isNull?: boolean | null;
                  isNot?: boolean | null;
                  valueIn?: Array<{
                    __typename?: 'FilterValue';
                    title: string;
                  }> | null;
                  range?: {
                    __typename?: 'NumberFilterRange';
                    from?: number | null;
                    to?: number | null;
                  } | null;
                  stringRange?: {
                    __typename?: 'StringFilterRange';
                    from?: string | null;
                    excludeFrom?: boolean | null;
                    to?: string | null;
                    excludeTo?: boolean | null;
                  } | null;
                }>;
              }>;
            } | null;
            locationsMatch?: {
              __typename?: 'LocationFilterUnion';
              anyOf: Array<{
                __typename?: 'LocationFilterIntersection';
                id?: string | null;
                allOf: Array<{
                  __typename?: 'LocationFilter';
                  type: LocationFilterType;
                  isNull?: boolean | null;
                  isNot?: boolean | null;
                  valueIn?: Array<{
                    __typename?: 'FilterValue';
                    title: string;
                  }> | null;
                  range?: {
                    __typename?: 'NumberFilterRange';
                    from?: number | null;
                    to?: number | null;
                  } | null;
                  stringRange?: {
                    __typename?: 'StringFilterRange';
                    from?: string | null;
                    excludeFrom?: boolean | null;
                    to?: string | null;
                    excludeTo?: boolean | null;
                  } | null;
                }>;
              }>;
            } | null;
            constraintSettings?: {
              __typename?: 'AllocationConstraintSettings';
              assignmentPolicyConstraint?: {
                __typename?: 'AssignmentPolicyConstraintSettings';
                disabled?: boolean | null;
              } | null;
              locationSizeConstraint?: {
                __typename?: 'LocationSizeConstraintSettings';
                disabled?: boolean | null;
                unknownAsNoncompliant?: boolean | null;
              } | null;
              bayWidthConstraint?: {
                __typename?: 'BayWidthConstraintSettings';
                disabled?: boolean | null;
              } | null;
            } | null;
            reallocateQuantitySettings?: {
              __typename?: 'ReallocateQuantitySettings';
              reallocateUom?: ReallocateUomSource | null;
              reallocateQuantity?: ReallocateQuantitySource | null;
              fixedUom?: string | null;
              fixedUomQuantity?: number | null;
            } | null;
          } | null;
        } | null;
        orderSetFilter?: {
          __typename?: 'OrderSetFilter';
          orderDate?: {
            __typename?: 'LocalDateRangeFilter';
            from?: string | null;
            to?: string | null;
            excludeFrom?: boolean | null;
            excludeTo?: boolean | null;
            includeNull?: boolean | null;
          } | null;
        } | null;
        requirementSource?: {
          __typename?: 'AllocationRequirementSourceSettings';
          useOrderSetDemand?: boolean | null;
        } | null;
        stockLimitSettings?: {
          __typename?: 'AllocationStockLimitSettings';
          enabled?: boolean | null;
        } | null;
      } | null;
      resultAssignment?: { __typename?: 'Assignment'; id: string } | null;
      analyzeResult?: {
        __typename?: 'AnalyzeResult';
        id: string;
        status: AnalyzeResultStatus;
      } | null;
      resultRequirementsSummary: Array<{
        __typename?: 'AllocationRunResultRequirementSummary';
        key: {
          __typename?: 'AllocationRunResultRequirementSummaryKey';
          itemId?: string | null;
          consignee?: string | null;
          orderLineRuleId?: string | null;
          sku?: string | null;
          skuGroup?: string | null;
          subGroup?: string | null;
          transportClass?: string | null;
          stockCategory?: string | null;
          storageClass?: string | null;
          pollutionClass?: string | null;
        };
        required?: {
          __typename?: 'AllocationRunResultRequirementQuantity';
          totalQuantity?: number | null;
          uoms?: Array<{
            __typename?: 'AllocationRequirementUomQuantity';
            uom?: string | null;
            uomQuantity: number;
          }> | null;
        } | null;
        unpickable?: {
          __typename?: 'AllocationRunResultRequirementQuantity';
          totalQuantity?: number | null;
          uoms?: Array<{
            __typename?: 'AllocationRequirementUomQuantity';
            uom?: string | null;
            uomQuantity: number;
          }> | null;
        } | null;
        initiallyPickable?: {
          __typename?: 'AllocationRunResultRequirementPickableQuantitySummary';
          totalQuantity?: number | null;
          totalLocationCount?: number | null;
          uoms?: Array<{
            __typename?: 'AllocationRequirementUomQuantity';
            uom?: string | null;
            uomQuantity: number;
            totalVolume: number;
          }> | null;
        } | null;
        initiallyPickableFulfilled?: {
          __typename?: 'AllocationRunResultRequirementPickableQuantitySummary';
          totalQuantity?: number | null;
          totalLocationCount?: number | null;
          uoms?: Array<{
            __typename?: 'AllocationRequirementUomQuantity';
            uom?: string | null;
            uomQuantity: number;
            totalVolume: number;
          }> | null;
        } | null;
        toAllocateRequired?: {
          __typename?: 'AllocationRunResultRequirementQuantity';
          totalQuantity?: number | null;
          uoms?: Array<{
            __typename?: 'AllocationRequirementUomQuantity';
            uom?: string | null;
            uomQuantity: number;
            totalVolume: number;
            aggregatedTotalVolume?: number | null;
            aggregatedQuantity?: number | null;
            aggregatedUomQuantity?: number | null;
          }> | null;
        } | null;
        toReallocate?: {
          __typename?: 'AllocationRunResultRequirementQuantity';
          totalQuantity?: number | null;
          uoms?: Array<{
            __typename?: 'AllocationRequirementUomQuantity';
            uom?: string | null;
            uomQuantity: number;
            totalVolume: number;
            aggregatedTotalVolume?: number | null;
            aggregatedQuantity?: number | null;
            aggregatedUomQuantity?: number | null;
          }> | null;
        } | null;
        toAllocate?: {
          __typename?: 'AllocationRunResultRequirementQuantity';
          totalQuantity?: number | null;
          uoms?: Array<{
            __typename?: 'AllocationRequirementUomQuantity';
            uom?: string | null;
            uomQuantity: number;
            totalVolume: number;
            aggregatedTotalVolume?: number | null;
            aggregatedQuantity?: number | null;
            aggregatedUomQuantity?: number | null;
          }> | null;
        } | null;
        allocated?: {
          __typename?: 'AllocationRunResultRequirementPickableQuantitySummary';
          totalQuantity?: number | null;
          totalLocationCount?: number | null;
          uoms?: Array<{
            __typename?: 'AllocationRequirementUomQuantity';
            uom?: string | null;
            uomQuantity: number;
            totalVolume: number;
          }> | null;
        } | null;
        allocatedShared?: {
          __typename?: 'AllocationRunResultRequirementPickableQuantitySummary';
          totalQuantity?: number | null;
          totalLocationCount?: number | null;
          uoms?: Array<{
            __typename?: 'AllocationRequirementUomQuantity';
            uom?: string | null;
            uomQuantity: number;
            totalVolume: number;
          }> | null;
        } | null;
        allocatedFulfilled?: {
          __typename?: 'AllocationRunResultRequirementPickableQuantitySummary';
          totalQuantity?: number | null;
          totalLocationCount?: number | null;
          uoms?: Array<{
            __typename?: 'AllocationRequirementUomQuantity';
            uom?: string | null;
            uomQuantity: number;
            totalVolume: number;
          }> | null;
        } | null;
        unallocated?: {
          __typename?: 'AllocationRunResultRequirementUnallocatedQuantity';
          totalQuantity?: number | null;
          reasons?: Array<AllocationRunResultUnallocatedReason> | null;
          uoms?: Array<{
            __typename?: 'AllocationRequirementUomQuantity';
            uom?: string | null;
            uomQuantity: number;
          }> | null;
        } | null;
        deallocated?: {
          __typename?: 'AllocationRunResultRequirementDeallocatedQuantitySummary';
          totalQuantity?: number | null;
          totalLocationCount?: number | null;
          reasons?: Array<AllocationRunResultDeallocatedReason> | null;
          uoms?: Array<{
            __typename?: 'AllocationRequirementUomQuantity';
            uom?: string | null;
            uomQuantity: number;
            totalVolume: number;
          }> | null;
        } | null;
      }>;
    } | null;
    sizeCompliance?: {
      __typename?: 'SizeCompliance';
      id: string;
      status: BatchJobStatus;
      summary?: {
        __typename?: 'SizeComplianceSummary';
        items: Array<{
          __typename?: 'SizeComplianceSummaryItem';
          totalCount: number;
          compliantCount: number;
          nonCompliantCount: number;
          unassignedCount: number;
          unknownCount: number;
          planeId: string;
          level: number;
        }>;
      } | null;
    } | null;
    weightCompliance?: {
      __typename?: 'WeightCompliance';
      id: string;
      status: BatchJobStatus;
      locationSummary?: {
        __typename?: 'LocationWeightComplianceSummary';
        items: Array<{
          __typename?: 'LocationWeightComplianceSummaryItem';
          totalCount: number;
          compliantCount: number;
          nonCompliantCount: number;
          unassignedCount: number;
          unknownCount: number;
          floorLevelCount: number;
          planeId: string;
          level: number;
        }>;
      } | null;
      baySummary?: {
        __typename?: 'BayWeightComplianceSummary';
        items: Array<{
          __typename?: 'BayWeightComplianceSummaryItem';
          totalCount: number;
          compliantCount: number;
          nonCompliantCount: number;
          unassignedCount: number;
          unknownCount: number;
          floorLevelCount: number;
          planeId: string;
        }>;
      } | null;
    } | null;
    orderLineSet?: {
      __typename?: 'SimulationOrderLineSet';
      id: string;
      status: BatchJobStatus;
      progress: number;
      createdAt: number;
    } | null;
    effectiveItemSet?: {
      __typename?: 'SimulationItemSet';
      id: string;
      status: BatchJobStatus;
      createdAt: number;
    } | null;
    layoutIssues?: {
      __typename?: 'LayoutIssueSet';
      status: BatchJobStatus;
      progress: number;
      summary?: {
        __typename?: 'LayoutIssueSummary';
        totalLocations: number;
        totalInvalidLocations: number;
        invalidLocations: Array<{
          __typename?: 'LayoutIssueInvalidLocationsSummary';
          planeId?: string | null;
          locationLevel?: number | null;
          reason: LayoutIssueInvalidLocationReason;
          count: number;
        }>;
      } | null;
    } | null;
    assignmentIssues?: {
      __typename?: 'AssignmentIssueSet';
      status: BatchJobStatus;
      progress: number;
      summary?: {
        __typename?: 'AssignmentIssueSummary';
        totalLocations: number;
        totalAssignedLocations: number;
        totalAssignmentItems: number;
        totalInvalidLocations: number;
        invalidLocations: Array<{
          __typename?: 'AssignmentIssueInvalidLocationsSummary';
          planeId?: string | null;
          locationLevel?: number | null;
          reason: AssignmentIssueInvalidLocationReason;
          count: number;
        }>;
      } | null;
    } | null;
    itemSetIssues?: {
      __typename?: 'ItemSetIssueSet';
      status: BatchJobStatus;
      progress: number;
      summary?: {
        __typename?: 'ItemSetIssueSummary';
        totalItems: number;
        totalUoms: number;
        totalInvalidItems: number;
        totalInvalidUoms: number;
        totalAssignedItems: number;
        unknownAssignedItems: number;
        invalidItems: Array<{
          __typename?: 'ItemSetIssueInvalidItemsSummary';
          reason: ItemSetIssueInvalidItemReason;
          count: number;
        }>;
        invalidUoms: Array<{
          __typename?: 'ItemSetIssueInvalidUomsSummary';
          reason: ItemSetIssueInvalidUomReason;
          count: number;
        }>;
      } | null;
    } | null;
    orderSetIssues?: {
      __typename?: 'OrderSetIssueSet';
      status: BatchJobStatus;
      progress: number;
      summary?: {
        __typename?: 'OrderSetIssueSummary';
        totalLocations: number;
        totalOrderLines: number;
        totalUnpickableLines: number;
        totalItems?: number | null;
        unpickableLines: Array<{
          __typename?: 'OrderSetIssueUnpickableLinesSummary';
          reason: OrderSetIssueUnpickableLineReason;
          count: number;
        }>;
        unpickableItems?: Array<{
          __typename?: 'OrderSetIssueUnpickableItemsSummary';
          reason: OrderSetIssueUnpickableLineReason;
          count: number;
        }> | null;
      } | null;
    } | null;
    allocationRequirementSet?: {
      __typename?: 'AllocationRequirementSet';
      id: string;
      status: BatchJobStatus;
      progress: number;
    } | null;
  } | null;
};

export type UpdateSimulationMutationVariables = Exact<{
  input: UpdateSimulationInput;
}>;

export type UpdateSimulationMutation = {
  __typename?: 'Mutation';
  updateSimulation: {
    __typename?: 'Simulation';
    id: string;
    userTags?: Array<string> | null;
    extraSettings?: any | null;
    sizeCompliance?: {
      __typename?: 'SizeCompliance';
      id: string;
      status: BatchJobStatus;
      summary?: {
        __typename?: 'SizeComplianceSummary';
        items: Array<{
          __typename?: 'SizeComplianceSummaryItem';
          totalCount: number;
          compliantCount: number;
          nonCompliantCount: number;
          unassignedCount: number;
          unknownCount: number;
          planeId: string;
          level: number;
        }>;
      } | null;
    } | null;
    weightCompliance?: {
      __typename?: 'WeightCompliance';
      id: string;
      status: BatchJobStatus;
      locationSummary?: {
        __typename?: 'LocationWeightComplianceSummary';
        items: Array<{
          __typename?: 'LocationWeightComplianceSummaryItem';
          totalCount: number;
          compliantCount: number;
          nonCompliantCount: number;
          unassignedCount: number;
          unknownCount: number;
          floorLevelCount: number;
          planeId: string;
          level: number;
        }>;
      } | null;
      baySummary?: {
        __typename?: 'BayWeightComplianceSummary';
        items: Array<{
          __typename?: 'BayWeightComplianceSummaryItem';
          totalCount: number;
          compliantCount: number;
          nonCompliantCount: number;
          unassignedCount: number;
          unknownCount: number;
          floorLevelCount: number;
          planeId: string;
        }>;
      } | null;
    } | null;
    orderLineSet?: {
      __typename?: 'SimulationOrderLineSet';
      id: string;
      status: BatchJobStatus;
      progress: number;
      createdAt: number;
    } | null;
    effectiveItemSet?: {
      __typename?: 'SimulationItemSet';
      id: string;
      status: BatchJobStatus;
      createdAt: number;
    } | null;
    layoutIssues?: {
      __typename?: 'LayoutIssueSet';
      status: BatchJobStatus;
      progress: number;
      summary?: {
        __typename?: 'LayoutIssueSummary';
        totalLocations: number;
        totalInvalidLocations: number;
        invalidLocations: Array<{
          __typename?: 'LayoutIssueInvalidLocationsSummary';
          planeId?: string | null;
          locationLevel?: number | null;
          reason: LayoutIssueInvalidLocationReason;
          count: number;
        }>;
      } | null;
    } | null;
    assignmentIssues?: {
      __typename?: 'AssignmentIssueSet';
      status: BatchJobStatus;
      progress: number;
      summary?: {
        __typename?: 'AssignmentIssueSummary';
        totalLocations: number;
        totalAssignedLocations: number;
        totalAssignmentItems: number;
        totalInvalidLocations: number;
        invalidLocations: Array<{
          __typename?: 'AssignmentIssueInvalidLocationsSummary';
          planeId?: string | null;
          locationLevel?: number | null;
          reason: AssignmentIssueInvalidLocationReason;
          count: number;
        }>;
      } | null;
    } | null;
    itemSetIssues?: {
      __typename?: 'ItemSetIssueSet';
      status: BatchJobStatus;
      progress: number;
      summary?: {
        __typename?: 'ItemSetIssueSummary';
        totalItems: number;
        totalUoms: number;
        totalInvalidItems: number;
        totalInvalidUoms: number;
        totalAssignedItems: number;
        unknownAssignedItems: number;
        invalidItems: Array<{
          __typename?: 'ItemSetIssueInvalidItemsSummary';
          reason: ItemSetIssueInvalidItemReason;
          count: number;
        }>;
        invalidUoms: Array<{
          __typename?: 'ItemSetIssueInvalidUomsSummary';
          reason: ItemSetIssueInvalidUomReason;
          count: number;
        }>;
      } | null;
    } | null;
    orderSetIssues?: {
      __typename?: 'OrderSetIssueSet';
      status: BatchJobStatus;
      progress: number;
      summary?: {
        __typename?: 'OrderSetIssueSummary';
        totalLocations: number;
        totalOrderLines: number;
        totalUnpickableLines: number;
        totalItems?: number | null;
        unpickableLines: Array<{
          __typename?: 'OrderSetIssueUnpickableLinesSummary';
          reason: OrderSetIssueUnpickableLineReason;
          count: number;
        }>;
        unpickableItems?: Array<{
          __typename?: 'OrderSetIssueUnpickableItemsSummary';
          reason: OrderSetIssueUnpickableLineReason;
          count: number;
        }> | null;
      } | null;
    } | null;
    allocationRequirementSet?: {
      __typename?: 'AllocationRequirementSet';
      id: string;
      status: BatchJobStatus;
      progress: number;
    } | null;
  };
};

export type RemoveSimulationMutationVariables = Exact<{
  simulationId: Scalars['String']['input'];
}>;

export type RemoveSimulationMutation = {
  __typename?: 'Mutation';
  removeSimulation: boolean;
};

export type DuplicateSimulationMutationVariables = Exact<{
  simulationId: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
}>;

export type DuplicateSimulationMutation = {
  __typename?: 'Mutation';
  duplicateSimulation: { __typename?: 'Simulation'; id: string };
};

export type SizeComplianceLocationFragment = {
  __typename?: 'LayoutLocation';
  locationId: string;
  locationLevel: number;
  locationRackingType: string;
  warehouseArea: string;
  bay?: {
    __typename?: 'LayoutFeature';
    id: string;
    title?: string | null;
  } | null;
  plane?: {
    __typename?: 'LayoutPlane';
    id: string;
    title?: string | null;
  } | null;
};

export type SizeComplianceLocationItemFragment = {
  __typename?: 'SizeComplianceLocationItem';
  consignee: string;
  sku: string;
  status: SizeComplianceStatus;
  uom?: {
    __typename?: 'ItemUomDimensions';
    uom: string;
    width?: number | null;
    length?: number | null;
    height?: number | null;
  } | null;
};

export type SizeComplianceLocationDetailsFragment = {
  __typename?: 'SizeComplianceLocationDetails';
  status: SizeComplianceStatus;
  location: {
    __typename?: 'LayoutLocation';
    locationId: string;
    locationLevel: number;
    locationRackingType: string;
    warehouseArea: string;
    bay?: {
      __typename?: 'LayoutFeature';
      id: string;
      title?: string | null;
    } | null;
    plane?: {
      __typename?: 'LayoutPlane';
      id: string;
      title?: string | null;
    } | null;
  };
  items: Array<{
    __typename?: 'SizeComplianceLocationItem';
    consignee: string;
    sku: string;
    status: SizeComplianceStatus;
    uom?: {
      __typename?: 'ItemUomDimensions';
      uom: string;
      width?: number | null;
      length?: number | null;
      height?: number | null;
    } | null;
  }>;
};

export type SizeComplianceLocationStatusFragment = {
  __typename?: 'SizeComplianceLocationDetails';
  locationId: string;
  status: SizeComplianceStatus;
};

export type SizeComplianceSummaryItemFragment = {
  __typename?: 'SizeComplianceSummaryItem';
  totalCount: number;
  compliantCount: number;
  nonCompliantCount: number;
  unassignedCount: number;
  unknownCount: number;
  planeId: string;
  level: number;
};

export type SizeComplianceMetaFragment = {
  __typename?: 'SizeCompliance';
  id: string;
  status: BatchJobStatus;
  summary?: {
    __typename?: 'SizeComplianceSummary';
    items: Array<{
      __typename?: 'SizeComplianceSummaryItem';
      totalCount: number;
      compliantCount: number;
      nonCompliantCount: number;
      unassignedCount: number;
      unknownCount: number;
      planeId: string;
      level: number;
    }>;
  } | null;
};

export type LoadSizeComplianceForLocationsQueryVariables = Exact<{
  simulationId: Scalars['ID']['input'];
  filter?: InputMaybe<SizeComplianceLocationFilter>;
  sortBy?: InputMaybe<
    Array<SizeComplianceLocationSortBy> | SizeComplianceLocationSortBy
  >;
  page?: InputMaybe<SkipLimitPageSpec>;
}>;

export type LoadSizeComplianceForLocationsQuery = {
  __typename?: 'Query';
  simulation?: {
    __typename?: 'Simulation';
    sizeCompliance?: {
      __typename?: 'SizeCompliance';
      locations: {
        __typename?: 'SizeComplianceLocationsConnection';
        totalCount: number;
        content: Array<{
          __typename?: 'SizeComplianceLocationDetails';
          status: SizeComplianceStatus;
          location: {
            __typename?: 'LayoutLocation';
            locationId: string;
            locationLevel: number;
            locationRackingType: string;
            warehouseArea: string;
            bay?: {
              __typename?: 'LayoutFeature';
              id: string;
              title?: string | null;
            } | null;
            plane?: {
              __typename?: 'LayoutPlane';
              id: string;
              title?: string | null;
            } | null;
          };
          items: Array<{
            __typename?: 'SizeComplianceLocationItem';
            consignee: string;
            sku: string;
            status: SizeComplianceStatus;
            uom?: {
              __typename?: 'ItemUomDimensions';
              uom: string;
              width?: number | null;
              length?: number | null;
              height?: number | null;
            } | null;
          }>;
        }>;
      };
    } | null;
  } | null;
};

export type LoadSizeComplianceForLocationsStatusOnlyQueryVariables = Exact<{
  simulationId: Scalars['ID']['input'];
  filter?: InputMaybe<SizeComplianceLocationFilter>;
  sortBy?: InputMaybe<
    Array<SizeComplianceLocationSortBy> | SizeComplianceLocationSortBy
  >;
  page?: InputMaybe<SkipLimitPageSpec>;
}>;

export type LoadSizeComplianceForLocationsStatusOnlyQuery = {
  __typename?: 'Query';
  simulation?: {
    __typename?: 'Simulation';
    sizeCompliance?: {
      __typename?: 'SizeCompliance';
      locations: {
        __typename?: 'SizeComplianceLocationsConnection';
        totalCount: number;
        content: Array<{
          __typename?: 'SizeComplianceLocationDetails';
          locationId: string;
          status: SizeComplianceStatus;
        }>;
      };
    } | null;
  } | null;
};

export type LoadSizeComplianceMetaQueryVariables = Exact<{
  simulationId: Scalars['ID']['input'];
}>;

export type LoadSizeComplianceMetaQuery = {
  __typename?: 'Query';
  simulation?: {
    __typename?: 'Simulation';
    sizeCompliance?: {
      __typename?: 'SizeCompliance';
      id: string;
      status: BatchJobStatus;
      summary?: {
        __typename?: 'SizeComplianceSummary';
        items: Array<{
          __typename?: 'SizeComplianceSummaryItem';
          totalCount: number;
          compliantCount: number;
          nonCompliantCount: number;
          unassignedCount: number;
          unknownCount: number;
          planeId: string;
          level: number;
        }>;
      } | null;
    } | null;
  } | null;
};

export type RunCalculateSizeComplianceMutationVariables = Exact<{
  simulationId: Scalars['String']['input'];
}>;

export type RunCalculateSizeComplianceMutation = {
  __typename?: 'Mutation';
  calculateSimulationSizeCompliance: {
    __typename?: 'SizeCompliance';
    id: string;
    status: BatchJobStatus;
    summary?: {
      __typename?: 'SizeComplianceSummary';
      items: Array<{
        __typename?: 'SizeComplianceSummaryItem';
        totalCount: number;
        compliantCount: number;
        nonCompliantCount: number;
        unassignedCount: number;
        unknownCount: number;
        planeId: string;
        level: number;
      }>;
    } | null;
  };
};

export type StackingPolicyRuleSubcategoriesFragment = {
  __typename?: 'StackingPolicyRuleSubcategories';
  type?: StackingPolicyRuleSubcategoryType | null;
  step?: number | null;
  boundaries?: Array<number> | null;
};

export type StackingPolicyFallbackRuleFragment = {
  __typename?: 'StackingPolicyFallbackRule';
  subcategories?: {
    __typename?: 'StackingPolicyRuleSubcategories';
    type?: StackingPolicyRuleSubcategoryType | null;
    step?: number | null;
    boundaries?: Array<number> | null;
  } | null;
};

export type StackingPolicyRuleFragment = {
  __typename?: 'StackingPolicyRule';
  id: string;
  title?: string | null;
  itemsMatch?: {
    __typename?: 'ItemFilterUnion';
    anyOf: Array<{
      __typename?: 'ItemFilterIntersection';
      id?: string | null;
      allOf: Array<{
        __typename?: 'ItemFilter';
        type: ItemFilterType;
        isNot?: boolean | null;
        isNull?: boolean | null;
        valueIn?: Array<{ __typename?: 'FilterValue'; title: string }> | null;
        range?: {
          __typename?: 'NumberFilterRange';
          from?: number | null;
          to?: number | null;
        } | null;
        stringRange?: {
          __typename?: 'StringFilterRange';
          from?: string | null;
          excludeFrom?: boolean | null;
          to?: string | null;
          excludeTo?: boolean | null;
        } | null;
      }>;
    }>;
  } | null;
  subcategories?: {
    __typename?: 'StackingPolicyRuleSubcategories';
    type?: StackingPolicyRuleSubcategoryType | null;
    step?: number | null;
    boundaries?: Array<number> | null;
  } | null;
};

export type StackingPolicyFragment = {
  __typename?: 'StackingPolicy';
  topRules?: Array<{
    __typename?: 'StackingPolicyRule';
    id: string;
    title?: string | null;
    itemsMatch?: {
      __typename?: 'ItemFilterUnion';
      anyOf: Array<{
        __typename?: 'ItemFilterIntersection';
        id?: string | null;
        allOf: Array<{
          __typename?: 'ItemFilter';
          type: ItemFilterType;
          isNot?: boolean | null;
          isNull?: boolean | null;
          valueIn?: Array<{ __typename?: 'FilterValue'; title: string }> | null;
          range?: {
            __typename?: 'NumberFilterRange';
            from?: number | null;
            to?: number | null;
          } | null;
          stringRange?: {
            __typename?: 'StringFilterRange';
            from?: string | null;
            excludeFrom?: boolean | null;
            to?: string | null;
            excludeTo?: boolean | null;
          } | null;
        }>;
      }>;
    } | null;
    subcategories?: {
      __typename?: 'StackingPolicyRuleSubcategories';
      type?: StackingPolicyRuleSubcategoryType | null;
      step?: number | null;
      boundaries?: Array<number> | null;
    } | null;
  }> | null;
  bottomRules?: Array<{
    __typename?: 'StackingPolicyRule';
    id: string;
    title?: string | null;
    itemsMatch?: {
      __typename?: 'ItemFilterUnion';
      anyOf: Array<{
        __typename?: 'ItemFilterIntersection';
        id?: string | null;
        allOf: Array<{
          __typename?: 'ItemFilter';
          type: ItemFilterType;
          isNot?: boolean | null;
          isNull?: boolean | null;
          valueIn?: Array<{ __typename?: 'FilterValue'; title: string }> | null;
          range?: {
            __typename?: 'NumberFilterRange';
            from?: number | null;
            to?: number | null;
          } | null;
          stringRange?: {
            __typename?: 'StringFilterRange';
            from?: string | null;
            excludeFrom?: boolean | null;
            to?: string | null;
            excludeTo?: boolean | null;
          } | null;
        }>;
      }>;
    } | null;
    subcategories?: {
      __typename?: 'StackingPolicyRuleSubcategories';
      type?: StackingPolicyRuleSubcategoryType | null;
      step?: number | null;
      boundaries?: Array<number> | null;
    } | null;
  }> | null;
  fallbackRule?: {
    __typename?: 'StackingPolicyFallbackRule';
    subcategories?: {
      __typename?: 'StackingPolicyRuleSubcategories';
      type?: StackingPolicyRuleSubcategoryType | null;
      step?: number | null;
      boundaries?: Array<number> | null;
    } | null;
  } | null;
};

export type AssignmentsMatchFragment = {
  __typename?: 'AssignmentSubsetSpec';
  unassignedLocationsMatch?: UnassignedLocationsMatch | null;
  locationsMatch?: {
    __typename?: 'LocationFilterUnion';
    anyOf: Array<{
      __typename?: 'LocationFilterIntersection';
      id?: string | null;
      allOf: Array<{
        __typename?: 'LocationFilter';
        type: LocationFilterType;
        isNull?: boolean | null;
        isNot?: boolean | null;
        valueIn?: Array<{ __typename?: 'FilterValue'; title: string }> | null;
        range?: {
          __typename?: 'NumberFilterRange';
          from?: number | null;
          to?: number | null;
        } | null;
        stringRange?: {
          __typename?: 'StringFilterRange';
          from?: string | null;
          excludeFrom?: boolean | null;
          to?: string | null;
          excludeTo?: boolean | null;
        } | null;
      }>;
    }>;
  } | null;
  itemsMatch?: {
    __typename?: 'SimulationItemFilterUnion';
    anyOf: Array<{
      __typename?: 'SimulationItemFilterIntersection';
      id?: string | null;
      allOf: Array<{
        __typename?: 'SimulationItemFilter';
        type: SimulationItemFilterType;
        isNull?: boolean | null;
        isNot?: boolean | null;
        valueIn?: Array<{ __typename?: 'FilterValue'; title: string }> | null;
        range?: {
          __typename?: 'NumberFilterRange';
          from?: number | null;
          to?: number | null;
        } | null;
        stringRange?: {
          __typename?: 'StringFilterRange';
          from?: string | null;
          excludeFrom?: boolean | null;
          to?: string | null;
          excludeTo?: boolean | null;
        } | null;
      }>;
    }>;
  } | null;
};

export type SwapPolicyRuleFragment = {
  __typename?: 'OptimizationSwapRule';
  id?: string | null;
  title?: string | null;
  src?: {
    __typename?: 'AssignmentSubsetSpec';
    unassignedLocationsMatch?: UnassignedLocationsMatch | null;
    locationsMatch?: {
      __typename?: 'LocationFilterUnion';
      anyOf: Array<{
        __typename?: 'LocationFilterIntersection';
        id?: string | null;
        allOf: Array<{
          __typename?: 'LocationFilter';
          type: LocationFilterType;
          isNull?: boolean | null;
          isNot?: boolean | null;
          valueIn?: Array<{ __typename?: 'FilterValue'; title: string }> | null;
          range?: {
            __typename?: 'NumberFilterRange';
            from?: number | null;
            to?: number | null;
          } | null;
          stringRange?: {
            __typename?: 'StringFilterRange';
            from?: string | null;
            excludeFrom?: boolean | null;
            to?: string | null;
            excludeTo?: boolean | null;
          } | null;
        }>;
      }>;
    } | null;
    itemsMatch?: {
      __typename?: 'SimulationItemFilterUnion';
      anyOf: Array<{
        __typename?: 'SimulationItemFilterIntersection';
        id?: string | null;
        allOf: Array<{
          __typename?: 'SimulationItemFilter';
          type: SimulationItemFilterType;
          isNull?: boolean | null;
          isNot?: boolean | null;
          valueIn?: Array<{ __typename?: 'FilterValue'; title: string }> | null;
          range?: {
            __typename?: 'NumberFilterRange';
            from?: number | null;
            to?: number | null;
          } | null;
          stringRange?: {
            __typename?: 'StringFilterRange';
            from?: string | null;
            excludeFrom?: boolean | null;
            to?: string | null;
            excludeTo?: boolean | null;
          } | null;
        }>;
      }>;
    } | null;
  } | null;
  dest?: {
    __typename?: 'AssignmentSubsetSpec';
    unassignedLocationsMatch?: UnassignedLocationsMatch | null;
    locationsMatch?: {
      __typename?: 'LocationFilterUnion';
      anyOf: Array<{
        __typename?: 'LocationFilterIntersection';
        id?: string | null;
        allOf: Array<{
          __typename?: 'LocationFilter';
          type: LocationFilterType;
          isNull?: boolean | null;
          isNot?: boolean | null;
          valueIn?: Array<{ __typename?: 'FilterValue'; title: string }> | null;
          range?: {
            __typename?: 'NumberFilterRange';
            from?: number | null;
            to?: number | null;
          } | null;
          stringRange?: {
            __typename?: 'StringFilterRange';
            from?: string | null;
            excludeFrom?: boolean | null;
            to?: string | null;
            excludeTo?: boolean | null;
          } | null;
        }>;
      }>;
    } | null;
    itemsMatch?: {
      __typename?: 'SimulationItemFilterUnion';
      anyOf: Array<{
        __typename?: 'SimulationItemFilterIntersection';
        id?: string | null;
        allOf: Array<{
          __typename?: 'SimulationItemFilter';
          type: SimulationItemFilterType;
          isNull?: boolean | null;
          isNot?: boolean | null;
          valueIn?: Array<{ __typename?: 'FilterValue'; title: string }> | null;
          range?: {
            __typename?: 'NumberFilterRange';
            from?: number | null;
            to?: number | null;
          } | null;
          stringRange?: {
            __typename?: 'StringFilterRange';
            from?: string | null;
            excludeFrom?: boolean | null;
            to?: string | null;
            excludeTo?: boolean | null;
          } | null;
        }>;
      }>;
    } | null;
  } | null;
};

export type SwapPolicyFragment = {
  __typename?: 'SwapSettings';
  rules?: Array<{
    __typename?: 'OptimizationSwapRule';
    id?: string | null;
    title?: string | null;
    src?: {
      __typename?: 'AssignmentSubsetSpec';
      unassignedLocationsMatch?: UnassignedLocationsMatch | null;
      locationsMatch?: {
        __typename?: 'LocationFilterUnion';
        anyOf: Array<{
          __typename?: 'LocationFilterIntersection';
          id?: string | null;
          allOf: Array<{
            __typename?: 'LocationFilter';
            type: LocationFilterType;
            isNull?: boolean | null;
            isNot?: boolean | null;
            valueIn?: Array<{
              __typename?: 'FilterValue';
              title: string;
            }> | null;
            range?: {
              __typename?: 'NumberFilterRange';
              from?: number | null;
              to?: number | null;
            } | null;
            stringRange?: {
              __typename?: 'StringFilterRange';
              from?: string | null;
              excludeFrom?: boolean | null;
              to?: string | null;
              excludeTo?: boolean | null;
            } | null;
          }>;
        }>;
      } | null;
      itemsMatch?: {
        __typename?: 'SimulationItemFilterUnion';
        anyOf: Array<{
          __typename?: 'SimulationItemFilterIntersection';
          id?: string | null;
          allOf: Array<{
            __typename?: 'SimulationItemFilter';
            type: SimulationItemFilterType;
            isNull?: boolean | null;
            isNot?: boolean | null;
            valueIn?: Array<{
              __typename?: 'FilterValue';
              title: string;
            }> | null;
            range?: {
              __typename?: 'NumberFilterRange';
              from?: number | null;
              to?: number | null;
            } | null;
            stringRange?: {
              __typename?: 'StringFilterRange';
              from?: string | null;
              excludeFrom?: boolean | null;
              to?: string | null;
              excludeTo?: boolean | null;
            } | null;
          }>;
        }>;
      } | null;
    } | null;
    dest?: {
      __typename?: 'AssignmentSubsetSpec';
      unassignedLocationsMatch?: UnassignedLocationsMatch | null;
      locationsMatch?: {
        __typename?: 'LocationFilterUnion';
        anyOf: Array<{
          __typename?: 'LocationFilterIntersection';
          id?: string | null;
          allOf: Array<{
            __typename?: 'LocationFilter';
            type: LocationFilterType;
            isNull?: boolean | null;
            isNot?: boolean | null;
            valueIn?: Array<{
              __typename?: 'FilterValue';
              title: string;
            }> | null;
            range?: {
              __typename?: 'NumberFilterRange';
              from?: number | null;
              to?: number | null;
            } | null;
            stringRange?: {
              __typename?: 'StringFilterRange';
              from?: string | null;
              excludeFrom?: boolean | null;
              to?: string | null;
              excludeTo?: boolean | null;
            } | null;
          }>;
        }>;
      } | null;
      itemsMatch?: {
        __typename?: 'SimulationItemFilterUnion';
        anyOf: Array<{
          __typename?: 'SimulationItemFilterIntersection';
          id?: string | null;
          allOf: Array<{
            __typename?: 'SimulationItemFilter';
            type: SimulationItemFilterType;
            isNull?: boolean | null;
            isNot?: boolean | null;
            valueIn?: Array<{
              __typename?: 'FilterValue';
              title: string;
            }> | null;
            range?: {
              __typename?: 'NumberFilterRange';
              from?: number | null;
              to?: number | null;
            } | null;
            stringRange?: {
              __typename?: 'StringFilterRange';
              from?: string | null;
              excludeFrom?: boolean | null;
              to?: string | null;
              excludeTo?: boolean | null;
            } | null;
          }>;
        }>;
      } | null;
    } | null;
  }> | null;
};

export type AssignmentSubsetSummaryFragment = {
  __typename?: 'AssignmentSubsetSummary';
  itemCount: number;
  locationCount: number;
  assignmentItemCount: number;
  emptyLocationCount: number;
};

export type CheckedOptimizationSwapRuleDetailsFragment = {
  __typename?: 'CheckedOptimizationSwapRuleDetails';
  noValidSwapsReasons?: Array<CheckOptimizationSwapPolicyNoValidSwapsReason> | null;
  violatedConstrains?: Array<string> | null;
};

export type CheckedOptimizationSwapRuleFragment = {
  __typename?: 'CheckedOptimizationSwapRule';
  id?: string | null;
  title?: string | null;
  hasValidSwaps: boolean;
  srcMatch: {
    __typename?: 'AssignmentSubsetSummary';
    itemCount: number;
    locationCount: number;
    assignmentItemCount: number;
    emptyLocationCount: number;
  };
  destMatch: {
    __typename?: 'AssignmentSubsetSummary';
    itemCount: number;
    locationCount: number;
    assignmentItemCount: number;
    emptyLocationCount: number;
  };
  details?: {
    __typename?: 'CheckedOptimizationSwapRuleDetails';
    noValidSwapsReasons?: Array<CheckOptimizationSwapPolicyNoValidSwapsReason> | null;
    violatedConstrains?: Array<string> | null;
  } | null;
};

export type CheckOptimizationSwapPolicyResultFragment = {
  __typename?: 'CheckedOptimizationSwapPolicy';
  hasValidSwaps: boolean;
  rules: Array<{
    __typename?: 'CheckedOptimizationSwapRule';
    id?: string | null;
    title?: string | null;
    hasValidSwaps: boolean;
    srcMatch: {
      __typename?: 'AssignmentSubsetSummary';
      itemCount: number;
      locationCount: number;
      assignmentItemCount: number;
      emptyLocationCount: number;
    };
    destMatch: {
      __typename?: 'AssignmentSubsetSummary';
      itemCount: number;
      locationCount: number;
      assignmentItemCount: number;
      emptyLocationCount: number;
    };
    details?: {
      __typename?: 'CheckedOptimizationSwapRuleDetails';
      noValidSwapsReasons?: Array<CheckOptimizationSwapPolicyNoValidSwapsReason> | null;
      violatedConstrains?: Array<string> | null;
    } | null;
  }>;
};

export type CheckOptimizationSwapPolicyJobFragment = {
  __typename?: 'CheckOptimizationSwapPolicyJob';
  id: string;
  status: BatchJobStatus;
  result?: {
    __typename?: 'CheckedOptimizationSwapPolicy';
    hasValidSwaps: boolean;
    rules: Array<{
      __typename?: 'CheckedOptimizationSwapRule';
      id?: string | null;
      title?: string | null;
      hasValidSwaps: boolean;
      srcMatch: {
        __typename?: 'AssignmentSubsetSummary';
        itemCount: number;
        locationCount: number;
        assignmentItemCount: number;
        emptyLocationCount: number;
      };
      destMatch: {
        __typename?: 'AssignmentSubsetSummary';
        itemCount: number;
        locationCount: number;
        assignmentItemCount: number;
        emptyLocationCount: number;
      };
      details?: {
        __typename?: 'CheckedOptimizationSwapRuleDetails';
        noValidSwapsReasons?: Array<CheckOptimizationSwapPolicyNoValidSwapsReason> | null;
        violatedConstrains?: Array<string> | null;
      } | null;
    }>;
  } | null;
};

export type RunCheckSwapPolicyMutationVariables = Exact<{
  input: CheckOptimizationSwapPolicyInput;
}>;

export type RunCheckSwapPolicyMutation = {
  __typename?: 'Mutation';
  startCheckOptimizationSwapPolicyJob: {
    __typename?: 'CheckOptimizationSwapPolicyJob';
    id: string;
    status: BatchJobStatus;
  };
};

export type LoadCheckSwapPolicyJobQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type LoadCheckSwapPolicyJobQuery = {
  __typename?: 'Query';
  checkOptimizationSwapPolicyJob?: {
    __typename?: 'CheckOptimizationSwapPolicyJob';
    id: string;
    status: BatchJobStatus;
    result?: {
      __typename?: 'CheckedOptimizationSwapPolicy';
      hasValidSwaps: boolean;
      rules: Array<{
        __typename?: 'CheckedOptimizationSwapRule';
        id?: string | null;
        title?: string | null;
        hasValidSwaps: boolean;
        srcMatch: {
          __typename?: 'AssignmentSubsetSummary';
          itemCount: number;
          locationCount: number;
          assignmentItemCount: number;
          emptyLocationCount: number;
        };
        destMatch: {
          __typename?: 'AssignmentSubsetSummary';
          itemCount: number;
          locationCount: number;
          assignmentItemCount: number;
          emptyLocationCount: number;
        };
        details?: {
          __typename?: 'CheckedOptimizationSwapRuleDetails';
          noValidSwapsReasons?: Array<CheckOptimizationSwapPolicyNoValidSwapsReason> | null;
          violatedConstrains?: Array<string> | null;
        } | null;
      }>;
    } | null;
  } | null;
};

export type UserSettingsBaseFragment = {
  __typename?: 'UserSettings';
  configured: boolean;
  configuredAt?: number | null;
  trialWarehouseCreated: boolean;
  trialWarehouseCreatedAt?: number | null;
  createdAt: number;
  updatedAt: number;
  canCreateTrialWarehouse: boolean;
  consents?: {
    __typename?: 'UserLegalConsents';
    privacyPolicy?: {
      __typename?: 'UserLegalConsent';
      version: string;
      consentedAt: number;
    } | null;
    termsPolicy?: {
      __typename?: 'UserLegalConsent';
      version: string;
      consentedAt: number;
    } | null;
  } | null;
  notifications?: {
    __typename?: 'UserNotifications';
    simulations?: boolean | null;
    news?: boolean | null;
    insights?: boolean | null;
  } | null;
};

export type UserSettingsFragment = {
  __typename?: 'UserSettings';
  featureFlags: any;
  configured: boolean;
  configuredAt?: number | null;
  trialWarehouseCreated: boolean;
  trialWarehouseCreatedAt?: number | null;
  createdAt: number;
  updatedAt: number;
  canCreateTrialWarehouse: boolean;
  featureRequests?: {
    __typename?: 'FeatureRequests';
    billing?: boolean | null;
    contracts?: boolean | null;
    marketplace?: boolean | null;
    optimise?: boolean | null;
    sales?: boolean | null;
  } | null;
  consents?: {
    __typename?: 'UserLegalConsents';
    privacyPolicy?: {
      __typename?: 'UserLegalConsent';
      version: string;
      consentedAt: number;
    } | null;
    termsPolicy?: {
      __typename?: 'UserLegalConsent';
      version: string;
      consentedAt: number;
    } | null;
  } | null;
  notifications?: {
    __typename?: 'UserNotifications';
    simulations?: boolean | null;
    news?: boolean | null;
    insights?: boolean | null;
  } | null;
};

export type LoadCurrentUserSettingsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type LoadCurrentUserSettingsQuery = {
  __typename?: 'Query';
  currentUserSettings?: {
    __typename?: 'UserSettings';
    featureFlags: any;
    configured: boolean;
    configuredAt?: number | null;
    trialWarehouseCreated: boolean;
    trialWarehouseCreatedAt?: number | null;
    createdAt: number;
    updatedAt: number;
    canCreateTrialWarehouse: boolean;
    featureRequests?: {
      __typename?: 'FeatureRequests';
      billing?: boolean | null;
      contracts?: boolean | null;
      marketplace?: boolean | null;
      optimise?: boolean | null;
      sales?: boolean | null;
    } | null;
    consents?: {
      __typename?: 'UserLegalConsents';
      privacyPolicy?: {
        __typename?: 'UserLegalConsent';
        version: string;
        consentedAt: number;
      } | null;
      termsPolicy?: {
        __typename?: 'UserLegalConsent';
        version: string;
        consentedAt: number;
      } | null;
    } | null;
    notifications?: {
      __typename?: 'UserNotifications';
      simulations?: boolean | null;
      news?: boolean | null;
      insights?: boolean | null;
    } | null;
  } | null;
};

export type LoadCurrentUserBaseSettingQueryVariables = Exact<{
  [key: string]: never;
}>;

export type LoadCurrentUserBaseSettingQuery = {
  __typename?: 'Query';
  currentUserSettings?: {
    __typename?: 'UserSettings';
    configured: boolean;
    configuredAt?: number | null;
    trialWarehouseCreated: boolean;
    trialWarehouseCreatedAt?: number | null;
    createdAt: number;
    updatedAt: number;
    canCreateTrialWarehouse: boolean;
    consents?: {
      __typename?: 'UserLegalConsents';
      privacyPolicy?: {
        __typename?: 'UserLegalConsent';
        version: string;
        consentedAt: number;
      } | null;
      termsPolicy?: {
        __typename?: 'UserLegalConsent';
        version: string;
        consentedAt: number;
      } | null;
    } | null;
    notifications?: {
      __typename?: 'UserNotifications';
      simulations?: boolean | null;
      news?: boolean | null;
      insights?: boolean | null;
    } | null;
  } | null;
};

export type UpdateCurrentUserSettingsMutationVariables = Exact<{
  input: UpdateUserSettingsInput;
}>;

export type UpdateCurrentUserSettingsMutation = {
  __typename?: 'Mutation';
  updateCurrentUserSettings: {
    __typename?: 'UserSettings';
    featureFlags: any;
    configured: boolean;
    configuredAt?: number | null;
    trialWarehouseCreated: boolean;
    trialWarehouseCreatedAt?: number | null;
    createdAt: number;
    updatedAt: number;
    canCreateTrialWarehouse: boolean;
    featureRequests?: {
      __typename?: 'FeatureRequests';
      billing?: boolean | null;
      contracts?: boolean | null;
      marketplace?: boolean | null;
      optimise?: boolean | null;
      sales?: boolean | null;
    } | null;
    consents?: {
      __typename?: 'UserLegalConsents';
      privacyPolicy?: {
        __typename?: 'UserLegalConsent';
        version: string;
        consentedAt: number;
      } | null;
      termsPolicy?: {
        __typename?: 'UserLegalConsent';
        version: string;
        consentedAt: number;
      } | null;
    } | null;
    notifications?: {
      __typename?: 'UserNotifications';
      simulations?: boolean | null;
      news?: boolean | null;
      insights?: boolean | null;
    } | null;
  };
};

export type WaypointPolicyRuleTerminalPointsFragment = {
  __typename?: 'WaypointPolicyRuleTerminalPoints';
  locationsMatch?: {
    __typename?: 'LocationFilterUnion';
    anyOf: Array<{
      __typename?: 'LocationFilterIntersection';
      id?: string | null;
      allOf: Array<{
        __typename?: 'LocationFilter';
        type: LocationFilterType;
        isNull?: boolean | null;
        isNot?: boolean | null;
        valueIn?: Array<{ __typename?: 'FilterValue'; title: string }> | null;
        range?: {
          __typename?: 'NumberFilterRange';
          from?: number | null;
          to?: number | null;
        } | null;
        stringRange?: {
          __typename?: 'StringFilterRange';
          from?: string | null;
          excludeFrom?: boolean | null;
          to?: string | null;
          excludeTo?: boolean | null;
        } | null;
      }>;
    }>;
  } | null;
};

export type WaypointPolicyRuleFragment = {
  __typename?: 'WaypointPolicyRule';
  id: string;
  title?: string | null;
  startPoints?: {
    __typename?: 'WaypointPolicyRuleTerminalPoints';
    locationsMatch?: {
      __typename?: 'LocationFilterUnion';
      anyOf: Array<{
        __typename?: 'LocationFilterIntersection';
        id?: string | null;
        allOf: Array<{
          __typename?: 'LocationFilter';
          type: LocationFilterType;
          isNull?: boolean | null;
          isNot?: boolean | null;
          valueIn?: Array<{ __typename?: 'FilterValue'; title: string }> | null;
          range?: {
            __typename?: 'NumberFilterRange';
            from?: number | null;
            to?: number | null;
          } | null;
          stringRange?: {
            __typename?: 'StringFilterRange';
            from?: string | null;
            excludeFrom?: boolean | null;
            to?: string | null;
            excludeTo?: boolean | null;
          } | null;
        }>;
      }>;
    } | null;
  } | null;
  endPoints?: {
    __typename?: 'WaypointPolicyRuleTerminalPoints';
    locationsMatch?: {
      __typename?: 'LocationFilterUnion';
      anyOf: Array<{
        __typename?: 'LocationFilterIntersection';
        id?: string | null;
        allOf: Array<{
          __typename?: 'LocationFilter';
          type: LocationFilterType;
          isNull?: boolean | null;
          isNot?: boolean | null;
          valueIn?: Array<{ __typename?: 'FilterValue'; title: string }> | null;
          range?: {
            __typename?: 'NumberFilterRange';
            from?: number | null;
            to?: number | null;
          } | null;
          stringRange?: {
            __typename?: 'StringFilterRange';
            from?: string | null;
            excludeFrom?: boolean | null;
            to?: string | null;
            excludeTo?: boolean | null;
          } | null;
        }>;
      }>;
    } | null;
  } | null;
};

export type WaypointPolicyFragment = {
  __typename?: 'WaypointPolicy';
  rules?: Array<{
    __typename?: 'WaypointPolicyRule';
    id: string;
    title?: string | null;
    startPoints?: {
      __typename?: 'WaypointPolicyRuleTerminalPoints';
      locationsMatch?: {
        __typename?: 'LocationFilterUnion';
        anyOf: Array<{
          __typename?: 'LocationFilterIntersection';
          id?: string | null;
          allOf: Array<{
            __typename?: 'LocationFilter';
            type: LocationFilterType;
            isNull?: boolean | null;
            isNot?: boolean | null;
            valueIn?: Array<{
              __typename?: 'FilterValue';
              title: string;
            }> | null;
            range?: {
              __typename?: 'NumberFilterRange';
              from?: number | null;
              to?: number | null;
            } | null;
            stringRange?: {
              __typename?: 'StringFilterRange';
              from?: string | null;
              excludeFrom?: boolean | null;
              to?: string | null;
              excludeTo?: boolean | null;
            } | null;
          }>;
        }>;
      } | null;
    } | null;
    endPoints?: {
      __typename?: 'WaypointPolicyRuleTerminalPoints';
      locationsMatch?: {
        __typename?: 'LocationFilterUnion';
        anyOf: Array<{
          __typename?: 'LocationFilterIntersection';
          id?: string | null;
          allOf: Array<{
            __typename?: 'LocationFilter';
            type: LocationFilterType;
            isNull?: boolean | null;
            isNot?: boolean | null;
            valueIn?: Array<{
              __typename?: 'FilterValue';
              title: string;
            }> | null;
            range?: {
              __typename?: 'NumberFilterRange';
              from?: number | null;
              to?: number | null;
            } | null;
            stringRange?: {
              __typename?: 'StringFilterRange';
              from?: string | null;
              excludeFrom?: boolean | null;
              to?: string | null;
              excludeTo?: boolean | null;
            } | null;
          }>;
        }>;
      } | null;
    } | null;
  }> | null;
};

export type CreateWarehouseMutationVariables = Exact<{
  input: CreateWarehouseInput;
}>;

export type CreateWarehouseMutation = {
  __typename?: 'Mutation';
  createWarehouse: {
    __typename?: 'Warehouse';
    id: string;
    title: string;
    description?: string | null;
    createdAt: number;
    updatedAt: number;
    companyName?: string | null;
    address?: string | null;
    measurementSystem: MeasurementSystem;
    country: string;
    currency: string;
    type: WarehouseType;
    extraSettings?: any | null;
    permissions: {
      __typename?: 'WarehouseAclPermissions';
      allowed: Array<Action>;
    };
  };
};

export type UpdateWarehouseMutationVariables = Exact<{
  input: UpdateWarehouseInput;
}>;

export type UpdateWarehouseMutation = {
  __typename?: 'Mutation';
  updateWarehouse: {
    __typename?: 'Warehouse';
    id: string;
    title: string;
    description?: string | null;
    createdAt: number;
    updatedAt: number;
    companyName?: string | null;
    address?: string | null;
    measurementSystem: MeasurementSystem;
    country: string;
    currency: string;
    type: WarehouseType;
    extraSettings?: any | null;
    permissions: {
      __typename?: 'WarehouseAclPermissions';
      allowed: Array<Action>;
    };
  };
};

export type RemoveWarehouseMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type RemoveWarehouseMutation = {
  __typename?: 'Mutation';
  removeWarehouse: boolean;
};

export type ChangeDemoWarehouseSubscriptionMutationVariables = Exact<{
  input: ChangeDemoWarehouseSubscriptionInput;
}>;

export type ChangeDemoWarehouseSubscriptionMutation = {
  __typename?: 'Mutation';
  changeDemoWarehouseSubscription: {
    __typename?: 'DemoWarehouse';
    subscribed: boolean;
    warehouse: { __typename?: 'Warehouse'; id: string };
  };
};

export type DemoWarehousesQueryVariables = Exact<{ [key: string]: never }>;

export type DemoWarehousesQuery = {
  __typename?: 'Query';
  demoWarehouses: Array<{
    __typename?: 'DemoWarehouse';
    subscribed: boolean;
    warehouse: {
      __typename?: 'Warehouse';
      id: string;
      title: string;
      measurementSystem: MeasurementSystem;
    };
  }>;
};

export type SimulationsConnectionFragment = {
  __typename?: 'SimulationsConnection';
  totalCount: number;
  content: Array<{
    __typename?: 'Simulation';
    id: string;
    title: string;
    description?: string | null;
    createdAt: number;
    updatedAt: number;
    userTags?: Array<string> | null;
  }>;
};

export type LayoutAreaSummaryFragment = {
  __typename?: 'LayoutAreaSummary';
  areaId: string;
  title: string;
  locationCount: number;
  aisleCount: number;
  bayCount: number;
};

export type LayoutSummaryFragment = {
  __typename?: 'LayoutSummary';
  locationCount: number;
  disabledLocationCount: number;
  aisleCount: number;
  bayCount: number;
  areas: Array<{
    __typename?: 'LayoutAreaSummary';
    areaId: string;
    title: string;
    locationCount: number;
    aisleCount: number;
    bayCount: number;
  }>;
};

export type LayoutDashboardItemFragment = {
  __typename?: 'Layout';
  id: string;
  title: string;
  description?: string | null;
  createdAt: number;
  updatedAt: number;
  userTags?: Array<string> | null;
  importedFrom?: {
    __typename?: 'LayoutImport';
    id: string;
    title: string;
    inputFileName: string;
  } | null;
  summary?: {
    __typename?: 'LayoutSummary';
    locationCount: number;
    disabledLocationCount: number;
    aisleCount: number;
    bayCount: number;
    areas: Array<{
      __typename?: 'LayoutAreaSummary';
      areaId: string;
      title: string;
      locationCount: number;
      aisleCount: number;
      bayCount: number;
    }>;
  } | null;
  simulationsWith: {
    __typename?: 'SimulationsConnection';
    totalCount: number;
    content: Array<{
      __typename?: 'Simulation';
      id: string;
      title: string;
      description?: string | null;
      createdAt: number;
      updatedAt: number;
      userTags?: Array<string> | null;
    }>;
  };
};

export type LayoutImportAreaSummaryFragment = {
  __typename?: 'LayoutImportAreaSummary';
  areaId: string;
  title: string;
  locationCount: number;
};

export type LayoutImportSummaryFragment = {
  __typename?: 'LayoutImportSummary';
  locationCount: number;
  disabledLocationCount: number;
  aisleCount: number;
  bayCount: number;
  areas: Array<{
    __typename?: 'LayoutImportAreaSummary';
    areaId: string;
    title: string;
    locationCount: number;
  }>;
};

export type LayoutImportDashboardItemFragment = {
  __typename?: 'LayoutImport';
  id: string;
  title: string;
  description?: string | null;
  createdAt: number;
  updatedAt: number;
  importTime?: number | null;
  userTags?: Array<string> | null;
  converterSummary?: {
    __typename?: 'LayoutImportSummary';
    locationCount: number;
    disabledLocationCount: number;
    aisleCount: number;
    bayCount: number;
    areas: Array<{
      __typename?: 'LayoutImportAreaSummary';
      areaId: string;
      title: string;
      locationCount: number;
    }>;
  } | null;
};

export type AssignmentConsigneeSummaryFragment = {
  __typename?: 'AssignmentConsigneeSummary';
  consignee: string;
  itemsCount: number;
  locationsCount: number;
  productsCount: number;
};

export type AssignmentSummaryFragment = {
  __typename?: 'AssignmentSummary';
  totalItemsCount: number;
  totalLocationsCount: number;
  totalProductsCount: number;
  consignees: Array<{
    __typename?: 'AssignmentConsigneeSummary';
    consignee: string;
    itemsCount: number;
    locationsCount: number;
    productsCount: number;
  }>;
  fields?: Array<{
    __typename?: 'DatasetObjectFieldSummary';
    name: string;
    hasData?: boolean | null;
    distinctValueCount?: number | null;
  }> | null;
  rawDataFields?: Array<{
    __typename?: 'DatasetObjectRawDataFieldSummary';
    name: string;
    type?: Array<string> | null;
    hasData?: boolean | null;
    distinctValueCount?: number | null;
  }> | null;
};

export type AssignmentDashboardItemFragment = {
  __typename?: 'Assignment';
  id: string;
  title: string;
  description?: string | null;
  createdAt: number;
  updatedAt: number;
  userTags?: Array<string> | null;
  summary?: {
    __typename?: 'AssignmentSummary';
    totalItemsCount: number;
    totalLocationsCount: number;
    totalProductsCount: number;
    consignees: Array<{
      __typename?: 'AssignmentConsigneeSummary';
      consignee: string;
      itemsCount: number;
      locationsCount: number;
      productsCount: number;
    }>;
    fields?: Array<{
      __typename?: 'DatasetObjectFieldSummary';
      name: string;
      hasData?: boolean | null;
      distinctValueCount?: number | null;
    }> | null;
    rawDataFields?: Array<{
      __typename?: 'DatasetObjectRawDataFieldSummary';
      name: string;
      type?: Array<string> | null;
      hasData?: boolean | null;
      distinctValueCount?: number | null;
    }> | null;
  } | null;
  simulationsWith: {
    __typename?: 'SimulationsConnection';
    totalCount: number;
    content: Array<{
      __typename?: 'Simulation';
      id: string;
      title: string;
      description?: string | null;
      createdAt: number;
      updatedAt: number;
      userTags?: Array<string> | null;
    }>;
  };
};

export type OrderSetConsigneeSummaryFragment = {
  __typename?: 'OrderSetConsigneeSummary';
  consignee: string;
  orderCount: number;
  orderLineCount: number;
  productCount: number;
};

export type OrderSetSummaryFragment = {
  __typename?: 'OrderSetSummary';
  orderCount: number;
  productCount?: number | null;
  consignees: Array<{
    __typename?: 'OrderSetConsigneeSummary';
    consignee: string;
    orderCount: number;
    orderLineCount: number;
    productCount: number;
  }>;
  dateRange?: {
    __typename?: 'DatasetObjectDateRange';
    from?: string | null;
    to?: string | null;
  } | null;
};

export type OrderSetDashboardItemFragment = {
  __typename?: 'OrderSet';
  id: string;
  title: string;
  description?: string | null;
  updatedAt: number;
  createdAt: number;
  userTags?: Array<string> | null;
  summary?: {
    __typename?: 'OrderSetSummary';
    orderCount: number;
    productCount?: number | null;
    consignees: Array<{
      __typename?: 'OrderSetConsigneeSummary';
      consignee: string;
      orderCount: number;
      orderLineCount: number;
      productCount: number;
    }>;
    dateRange?: {
      __typename?: 'DatasetObjectDateRange';
      from?: string | null;
      to?: string | null;
    } | null;
  } | null;
  simulationsWith: {
    __typename?: 'SimulationsConnection';
    totalCount: number;
    content: Array<{
      __typename?: 'Simulation';
      id: string;
      title: string;
      description?: string | null;
      createdAt: number;
      updatedAt: number;
      userTags?: Array<string> | null;
    }>;
  };
};

export type ItemSetDashboardItemFragment = {
  __typename?: 'ItemSet';
  id: string;
  title: string;
  description?: string | null;
  updatedAt: number;
  createdAt: number;
  userTags?: Array<string> | null;
  summary?: {
    __typename?: 'ItemSetSummary';
    totalItemsCount: number;
    consignees: Array<{
      __typename?: 'ItemSetConsigneeSummary';
      consignee: string;
      itemsCount: number;
    }>;
  } | null;
  simulationsWith: {
    __typename?: 'SimulationsConnection';
    totalCount: number;
    content: Array<{
      __typename?: 'Simulation';
      id: string;
      title: string;
      description?: string | null;
      createdAt: number;
      updatedAt: number;
      userTags?: Array<string> | null;
    }>;
  };
};

export type SimulationDashboardItemFragment = {
  __typename?: 'Simulation';
  id: string;
  title: string;
  description?: string | null;
  updatedAt: number;
  createdAt: number;
  status: SimulationStatus;
  userTags?: Array<string> | null;
  layout?: {
    __typename?: 'Layout';
    id: string;
    title: string;
    summary?: {
      __typename?: 'LayoutSummary';
      locationCount: number;
      disabledLocationCount: number;
      aisleCount: number;
      bayCount: number;
      areas: Array<{
        __typename?: 'LayoutAreaSummary';
        areaId: string;
        title: string;
        locationCount: number;
        aisleCount: number;
        bayCount: number;
      }>;
    } | null;
  } | null;
  assignment?: { __typename?: 'Assignment'; id: string; title: string } | null;
  orderSet?: { __typename?: 'OrderSet'; id: string; title: string } | null;
  itemSet?: { __typename?: 'ItemSet'; id: string; title: string } | null;
  resourcePolicy?: {
    __typename?: 'ResourcePolicy';
    agents: Array<{
      __typename?: 'AgentSettings';
      id: string;
      title?: string | null;
      resourceTypeId: string;
      metadata?: any | null;
      roles?: Array<{
        __typename?: 'AgentRoleSettings';
        roleId: AgentRole;
        disabled?: boolean | null;
        preHandling?: {
          __typename?: 'PicklistProcessingEventSettings';
          timeFixed?: number | null;
          timePerOrder?: number | null;
          timePerPicklistLine?: number | null;
          timePerUom?: number | null;
        } | null;
        handlingPreparation?: {
          __typename?: 'PicklistLineProcessingEventSettings';
          timeFixed?: number | null;
          timePerUom?: number | null;
        } | null;
        handling?: {
          __typename?: 'HandlingEventSettings';
          timeFixed?: number | null;
          timePerWeight?: number | null;
          timePerVolume?: number | null;
          timePerUom?: Array<{
            __typename?: 'UomHandlingSettings';
            uom: string;
            timePerUom?: number | null;
          }> | null;
        } | null;
        reordering?: {
          __typename?: 'ReorderEventSettings';
          timeFixed?: number | null;
          timePerReorderedPosition?: number | null;
          timePerWeight?: number | null;
          timePerVolume?: number | null;
          timePerUom?: Array<{
            __typename?: 'UomHandlingSettings';
            uom: string;
            timePerUom?: number | null;
          }> | null;
        } | null;
        handlingCompletion?: {
          __typename?: 'PicklistLineProcessingEventSettings';
          timeFixed?: number | null;
          timePerUom?: number | null;
        } | null;
        postHandling?: {
          __typename?: 'PicklistProcessingEventSettings';
          timeFixed?: number | null;
          timePerOrder?: number | null;
          timePerPicklistLine?: number | null;
          timePerUom?: number | null;
        } | null;
      }> | null;
      specs?: {
        __typename?: 'AgentSpecs';
        horizontalTravelling?: {
          __typename?: 'HorizontalTravellingEventSettings';
          speed?: {
            __typename?: 'SpeedSettings';
            speedLaden?: number | null;
            speedUnladen?: number | null;
          } | null;
        } | null;
        verticalTravelling?: {
          __typename?: 'VerticalTravellingEventSettings';
          speedUp?: {
            __typename?: 'SpeedSettings';
            speedLaden?: number | null;
            speedUnladen?: number | null;
          } | null;
          speedDown?: {
            __typename?: 'SpeedSettings';
            speedLaden?: number | null;
            speedUnladen?: number | null;
          } | null;
        } | null;
        capacity?: {
          __typename?: 'AgentCapacitySettings';
          maxVolume?: number | null;
          maxWeight?: number | null;
          maxUomQuantity?: Array<{
            __typename?: 'AgentCapacityUomSettings';
            uom: string;
            quantity?: number | null;
          }> | null;
        } | null;
        powerSource?: {
          __typename?: 'AgentPowerSourceSettings';
          energyConsumptionPerSecond?: number | null;
        } | null;
      } | null;
      cost?: {
        __typename?: 'AgentCostSettings';
        costPerSecond?: number | null;
      } | null;
      utilisation?: {
        __typename?: 'AgentUtilisationSettings';
        agentAmount?: number | null;
        secondsOperation?: number | null;
      } | null;
    }>;
  } | null;
  optimizationSettings?: {
    __typename?: 'OptimizationSettings';
    locationSizeConstraint?: {
      __typename?: 'LocationSizeConstraintSettings';
      disabled?: boolean | null;
    } | null;
    bayWidthConstraint?: {
      __typename?: 'BayWidthConstraintSettings';
      disabled?: boolean | null;
    } | null;
    terminationSettings?: {
      __typename?: 'TerminationSettings';
      targetImprovement?: number | null;
      timeLimit?: number | null;
      minImprovementRate?: {
        __typename?: 'MinImprovementRateSettings';
        minImprovementChange: number;
        steps: number;
      } | null;
    } | null;
    swapSettings?: {
      __typename?: 'SwapSettings';
      rules?: Array<{
        __typename?: 'OptimizationSwapRule';
        id?: string | null;
        title?: string | null;
        src?: {
          __typename?: 'AssignmentSubsetSpec';
          unassignedLocationsMatch?: UnassignedLocationsMatch | null;
          locationsMatch?: {
            __typename?: 'LocationFilterUnion';
            anyOf: Array<{
              __typename?: 'LocationFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'LocationFilter';
                type: LocationFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
          itemsMatch?: {
            __typename?: 'SimulationItemFilterUnion';
            anyOf: Array<{
              __typename?: 'SimulationItemFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'SimulationItemFilter';
                type: SimulationItemFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
        } | null;
        dest?: {
          __typename?: 'AssignmentSubsetSpec';
          unassignedLocationsMatch?: UnassignedLocationsMatch | null;
          locationsMatch?: {
            __typename?: 'LocationFilterUnion';
            anyOf: Array<{
              __typename?: 'LocationFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'LocationFilter';
                type: LocationFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
          itemsMatch?: {
            __typename?: 'SimulationItemFilterUnion';
            anyOf: Array<{
              __typename?: 'SimulationItemFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'SimulationItemFilter';
                type: SimulationItemFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
        } | null;
      }> | null;
    } | null;
    loadBalancing?: {
      __typename?: 'LoadBalancingPolicy';
      rules: Array<{
        __typename?: 'LoadBalancingRule';
        id?: string | null;
        title?: string | null;
        type: LoadBalancingZoneType;
        objectiveWeight?: number | null;
        constraint?: {
          __typename?: 'LoadBalancingConstraint';
          maxRelativeDeviation: number;
        } | null;
        include?: {
          __typename?: 'LocationFilterUnion';
          anyOf: Array<{
            __typename?: 'LocationFilterIntersection';
            id?: string | null;
            allOf: Array<{
              __typename?: 'LocationFilter';
              type: LocationFilterType;
              isNull?: boolean | null;
              isNot?: boolean | null;
              valueIn?: Array<{
                __typename?: 'FilterValue';
                title: string;
              }> | null;
              range?: {
                __typename?: 'NumberFilterRange';
                from?: number | null;
                to?: number | null;
              } | null;
              stringRange?: {
                __typename?: 'StringFilterRange';
                from?: string | null;
                excludeFrom?: boolean | null;
                to?: string | null;
                excludeTo?: boolean | null;
              } | null;
            }>;
          }>;
        } | null;
      }>;
    } | null;
    limitSettings?: {
      __typename?: 'LimitSettings';
      maxMoves?: number | null;
    } | null;
    reassignJobs?: {
      __typename?: 'ReassignJobsSettings';
      disabled?: boolean | null;
      cycleMode?: ReassignJobCycleMode | null;
    } | null;
    policyOverrides?: {
      __typename?: 'OptimizationPolicyOverrides';
      resourcePolicy?: {
        __typename?: 'ResourcePolicy';
        agents: Array<{
          __typename?: 'AgentSettings';
          id: string;
          title?: string | null;
          resourceTypeId: string;
          metadata?: any | null;
          roles?: Array<{
            __typename?: 'AgentRoleSettings';
            roleId: AgentRole;
            disabled?: boolean | null;
            preHandling?: {
              __typename?: 'PicklistProcessingEventSettings';
              timeFixed?: number | null;
              timePerOrder?: number | null;
              timePerPicklistLine?: number | null;
              timePerUom?: number | null;
            } | null;
            handlingPreparation?: {
              __typename?: 'PicklistLineProcessingEventSettings';
              timeFixed?: number | null;
              timePerUom?: number | null;
            } | null;
            handling?: {
              __typename?: 'HandlingEventSettings';
              timeFixed?: number | null;
              timePerWeight?: number | null;
              timePerVolume?: number | null;
              timePerUom?: Array<{
                __typename?: 'UomHandlingSettings';
                uom: string;
                timePerUom?: number | null;
              }> | null;
            } | null;
            reordering?: {
              __typename?: 'ReorderEventSettings';
              timeFixed?: number | null;
              timePerReorderedPosition?: number | null;
              timePerWeight?: number | null;
              timePerVolume?: number | null;
              timePerUom?: Array<{
                __typename?: 'UomHandlingSettings';
                uom: string;
                timePerUom?: number | null;
              }> | null;
            } | null;
            handlingCompletion?: {
              __typename?: 'PicklistLineProcessingEventSettings';
              timeFixed?: number | null;
              timePerUom?: number | null;
            } | null;
            postHandling?: {
              __typename?: 'PicklistProcessingEventSettings';
              timeFixed?: number | null;
              timePerOrder?: number | null;
              timePerPicklistLine?: number | null;
              timePerUom?: number | null;
            } | null;
          }> | null;
          specs?: {
            __typename?: 'AgentSpecs';
            horizontalTravelling?: {
              __typename?: 'HorizontalTravellingEventSettings';
              speed?: {
                __typename?: 'SpeedSettings';
                speedLaden?: number | null;
                speedUnladen?: number | null;
              } | null;
            } | null;
            verticalTravelling?: {
              __typename?: 'VerticalTravellingEventSettings';
              speedUp?: {
                __typename?: 'SpeedSettings';
                speedLaden?: number | null;
                speedUnladen?: number | null;
              } | null;
              speedDown?: {
                __typename?: 'SpeedSettings';
                speedLaden?: number | null;
                speedUnladen?: number | null;
              } | null;
            } | null;
            capacity?: {
              __typename?: 'AgentCapacitySettings';
              maxVolume?: number | null;
              maxWeight?: number | null;
              maxUomQuantity?: Array<{
                __typename?: 'AgentCapacityUomSettings';
                uom: string;
                quantity?: number | null;
              }> | null;
            } | null;
            powerSource?: {
              __typename?: 'AgentPowerSourceSettings';
              energyConsumptionPerSecond?: number | null;
            } | null;
          } | null;
          cost?: {
            __typename?: 'AgentCostSettings';
            costPerSecond?: number | null;
          } | null;
          utilisation?: {
            __typename?: 'AgentUtilisationSettings';
            agentAmount?: number | null;
            secondsOperation?: number | null;
          } | null;
        }>;
      } | null;
    } | null;
    pickabilityConstraint?: {
      __typename?: 'PickabilityConstraintSettings';
      disabled?: boolean | null;
    } | null;
    assignmentCapacitySettings?: {
      __typename?: 'AssignmentCapacitySettings';
      avoidMerge?: boolean | null;
    } | null;
  } | null;
  analyzeResult?: {
    __typename?: 'AnalyzeResult';
    startedAt?: number | null;
    completedAt?: number | null;
    id: string;
    status: AnalyzeResultStatus;
    stage?: AnalyzeResultStage | null;
    progress?: number | null;
    totalOrders: number;
    totalAssembledOrders: number;
    totalAssembledPartiallyOrders: number;
    totalLines: number;
    assembledLines: number;
    assembledPartiallyLines: number;
    ignoredLines: number;
    totalDistance: number;
    totalCost: number;
    totalDuration: number;
    costDetails?: {
      __typename?: 'AnalyzeResultCostDetails';
      processes: Array<{
        __typename?: 'AnalyzeResultProcessCost';
        processType: AnalyzeResultProcessType;
        distance: number;
        duration: number;
        cost: number;
      }>;
    } | null;
    summary?: {
      __typename?: 'AnalyzeResultSummary';
      eventTypes: Array<{
        __typename?: 'AnalyzeResultEventTypeSummary';
        eventType: PicklistEventType;
        processType?: AnalyzeResultProcessType | null;
        count: number;
        totalDuration: number;
        totalCost: number;
      }>;
      agents: Array<{
        __typename?: 'AnalyzeResultAgentSummary';
        agent: string;
        eventTypes: Array<{
          __typename?: 'AnalyzeResultEventTypeSummary';
          eventType: PicklistEventType;
          processType?: AnalyzeResultProcessType | null;
          count: number;
          totalDuration: number;
          totalCost: number;
        }>;
      }>;
      assignment?: {
        __typename?: 'AnalyzeResultAssignmentSummary';
        totalStoredItems?: number | null;
      } | null;
    } | null;
    resourcePolicy?: {
      __typename?: 'ResourcePolicy';
      agents: Array<{
        __typename?: 'AgentSettings';
        id: string;
        specs?: {
          __typename?: 'AgentSpecs';
          powerSource?: {
            __typename?: 'AgentPowerSourceSettings';
            energyConsumptionPerSecond?: number | null;
          } | null;
        } | null;
      }>;
    } | null;
  } | null;
  latestOptimizationRun?: {
    __typename?: 'OptimizationRun';
    id: string;
    progress?: number | null;
    steps?: number | null;
    seed?: number | null;
    status: OptimizationRunStatus;
    startedAt?: number | null;
    completedAt?: number | null;
    elapsedRunningTime: number;
    initialCost?: number | null;
    reassignedCost?: number | null;
    optimizedCost?: number | null;
    reassignImprovement?: number | null;
    optimizeImprovement?: number | null;
    improvement?: number | null;
    analyzeResult?: {
      __typename?: 'AnalyzeResult';
      id: string;
      status: AnalyzeResultStatus;
      stage?: AnalyzeResultStage | null;
      progress?: number | null;
      totalOrders: number;
      totalAssembledOrders: number;
      totalAssembledPartiallyOrders: number;
      totalLines: number;
      assembledLines: number;
      assembledPartiallyLines: number;
      ignoredLines: number;
      totalDistance: number;
      totalCost: number;
      totalDuration: number;
      costDetails?: {
        __typename?: 'AnalyzeResultCostDetails';
        processes: Array<{
          __typename?: 'AnalyzeResultProcessCost';
          processType: AnalyzeResultProcessType;
          distance: number;
          duration: number;
          cost: number;
        }>;
      } | null;
      summary?: {
        __typename?: 'AnalyzeResultSummary';
        eventTypes: Array<{
          __typename?: 'AnalyzeResultEventTypeSummary';
          eventType: PicklistEventType;
          processType?: AnalyzeResultProcessType | null;
          count: number;
          totalDuration: number;
          totalCost: number;
        }>;
        agents: Array<{
          __typename?: 'AnalyzeResultAgentSummary';
          agent: string;
          eventTypes: Array<{
            __typename?: 'AnalyzeResultEventTypeSummary';
            eventType: PicklistEventType;
            processType?: AnalyzeResultProcessType | null;
            count: number;
            totalDuration: number;
            totalCost: number;
          }>;
        }>;
        assignment?: {
          __typename?: 'AnalyzeResultAssignmentSummary';
          totalStoredItems?: number | null;
        } | null;
      } | null;
      resourcePolicy?: {
        __typename?: 'ResourcePolicy';
        agents: Array<{
          __typename?: 'AgentSettings';
          id: string;
          specs?: {
            __typename?: 'AgentSpecs';
            powerSource?: {
              __typename?: 'AgentPowerSourceSettings';
              energyConsumptionPerSecond?: number | null;
            } | null;
          } | null;
        }>;
      } | null;
    } | null;
    latestProgressDetails?: {
      __typename?: 'OptimizationRunProgress';
      step: number;
      startedAt: number;
      completedAt: number;
      progress: number;
      currentCost: number;
      elapsedComputeTime: number;
      remainingComputeTime?: number | null;
      currentMoveCount?: number | null;
      currentOptimizeImprovement?: number | null;
      currentTotalImprovement?: number | null;
      details?: {
        __typename?: 'OptimizationRunProgressDetails';
        improvement?: {
          __typename?: 'OptimizationImprovementSummary';
          effectiveImprovement: number;
          initialEffectiveCost: number;
          optimizedEffectiveCost: number;
          objectives: Array<{
            __typename?: 'OptimizationObjectiveImprovementSummary';
            id?: string | null;
            type: OptimizationObjectiveType;
            initialCost: number;
            objectiveWeight: number;
            improvement: number;
          }>;
        } | null;
      } | null;
    } | null;
    optimizationSummary?: {
      __typename?: 'OptimizationSummary';
      stopReason?: OptimizationStopReason | null;
      effectiveSteps?: number | null;
      totalComputeTime?: number | null;
      moveCount?: number | null;
      improvement?: {
        __typename?: 'OptimizationImprovementSummary';
        effectiveImprovement: number;
        initialEffectiveCost: number;
        optimizedEffectiveCost: number;
        objectives: Array<{
          __typename?: 'OptimizationObjectiveImprovementSummary';
          id?: string | null;
          type: OptimizationObjectiveType;
          initialCost: number;
          objectiveWeight: number;
          improvement: number;
        }>;
      } | null;
    } | null;
    resultAssignment?: { __typename?: 'Assignment'; id: string } | null;
    optimizationSettings?: {
      __typename?: 'OptimizationRunSettings';
      source?: {
        __typename?: 'OptimizationRunSourceReference';
        type: OptimizationRunSourceType;
        id: string;
      } | null;
    } | null;
  } | null;
  latestAllocationRun?: {
    __typename?: 'AllocationRun';
    id: string;
    progress?: number | null;
    status: BatchJobStatus;
    startedAt?: number | null;
    completedAt?: number | null;
    analyzeResult?: {
      __typename?: 'AnalyzeResult';
      id: string;
      status: AnalyzeResultStatus;
      stage?: AnalyzeResultStage | null;
      progress?: number | null;
      totalOrders: number;
      totalAssembledOrders: number;
      totalAssembledPartiallyOrders: number;
      totalLines: number;
      assembledLines: number;
      assembledPartiallyLines: number;
      ignoredLines: number;
      totalDistance: number;
      totalCost: number;
      totalDuration: number;
      costDetails?: {
        __typename?: 'AnalyzeResultCostDetails';
        processes: Array<{
          __typename?: 'AnalyzeResultProcessCost';
          processType: AnalyzeResultProcessType;
          distance: number;
          duration: number;
          cost: number;
        }>;
      } | null;
      summary?: {
        __typename?: 'AnalyzeResultSummary';
        eventTypes: Array<{
          __typename?: 'AnalyzeResultEventTypeSummary';
          eventType: PicklistEventType;
          processType?: AnalyzeResultProcessType | null;
          count: number;
          totalDuration: number;
          totalCost: number;
        }>;
        agents: Array<{
          __typename?: 'AnalyzeResultAgentSummary';
          agent: string;
          eventTypes: Array<{
            __typename?: 'AnalyzeResultEventTypeSummary';
            eventType: PicklistEventType;
            processType?: AnalyzeResultProcessType | null;
            count: number;
            totalDuration: number;
            totalCost: number;
          }>;
        }>;
        assignment?: {
          __typename?: 'AnalyzeResultAssignmentSummary';
          totalStoredItems?: number | null;
        } | null;
      } | null;
      resourcePolicy?: {
        __typename?: 'ResourcePolicy';
        agents: Array<{
          __typename?: 'AgentSettings';
          id: string;
          specs?: {
            __typename?: 'AgentSpecs';
            powerSource?: {
              __typename?: 'AgentPowerSourceSettings';
              energyConsumptionPerSecond?: number | null;
            } | null;
          } | null;
        }>;
      } | null;
    } | null;
  } | null;
};

export type DashboardSimulationFragment = {
  __typename?: 'SimulationsConnection';
  totalCount: number;
  allUserTags: Array<string>;
  content: Array<{
    __typename?: 'Simulation';
    id: string;
    title: string;
    description?: string | null;
    updatedAt: number;
    createdAt: number;
    status: SimulationStatus;
    userTags?: Array<string> | null;
    layout?: {
      __typename?: 'Layout';
      id: string;
      title: string;
      summary?: {
        __typename?: 'LayoutSummary';
        locationCount: number;
        disabledLocationCount: number;
        aisleCount: number;
        bayCount: number;
        areas: Array<{
          __typename?: 'LayoutAreaSummary';
          areaId: string;
          title: string;
          locationCount: number;
          aisleCount: number;
          bayCount: number;
        }>;
      } | null;
    } | null;
    assignment?: {
      __typename?: 'Assignment';
      id: string;
      title: string;
    } | null;
    orderSet?: { __typename?: 'OrderSet'; id: string; title: string } | null;
    itemSet?: { __typename?: 'ItemSet'; id: string; title: string } | null;
    resourcePolicy?: {
      __typename?: 'ResourcePolicy';
      agents: Array<{
        __typename?: 'AgentSettings';
        id: string;
        title?: string | null;
        resourceTypeId: string;
        metadata?: any | null;
        roles?: Array<{
          __typename?: 'AgentRoleSettings';
          roleId: AgentRole;
          disabled?: boolean | null;
          preHandling?: {
            __typename?: 'PicklistProcessingEventSettings';
            timeFixed?: number | null;
            timePerOrder?: number | null;
            timePerPicklistLine?: number | null;
            timePerUom?: number | null;
          } | null;
          handlingPreparation?: {
            __typename?: 'PicklistLineProcessingEventSettings';
            timeFixed?: number | null;
            timePerUom?: number | null;
          } | null;
          handling?: {
            __typename?: 'HandlingEventSettings';
            timeFixed?: number | null;
            timePerWeight?: number | null;
            timePerVolume?: number | null;
            timePerUom?: Array<{
              __typename?: 'UomHandlingSettings';
              uom: string;
              timePerUom?: number | null;
            }> | null;
          } | null;
          reordering?: {
            __typename?: 'ReorderEventSettings';
            timeFixed?: number | null;
            timePerReorderedPosition?: number | null;
            timePerWeight?: number | null;
            timePerVolume?: number | null;
            timePerUom?: Array<{
              __typename?: 'UomHandlingSettings';
              uom: string;
              timePerUom?: number | null;
            }> | null;
          } | null;
          handlingCompletion?: {
            __typename?: 'PicklistLineProcessingEventSettings';
            timeFixed?: number | null;
            timePerUom?: number | null;
          } | null;
          postHandling?: {
            __typename?: 'PicklistProcessingEventSettings';
            timeFixed?: number | null;
            timePerOrder?: number | null;
            timePerPicklistLine?: number | null;
            timePerUom?: number | null;
          } | null;
        }> | null;
        specs?: {
          __typename?: 'AgentSpecs';
          horizontalTravelling?: {
            __typename?: 'HorizontalTravellingEventSettings';
            speed?: {
              __typename?: 'SpeedSettings';
              speedLaden?: number | null;
              speedUnladen?: number | null;
            } | null;
          } | null;
          verticalTravelling?: {
            __typename?: 'VerticalTravellingEventSettings';
            speedUp?: {
              __typename?: 'SpeedSettings';
              speedLaden?: number | null;
              speedUnladen?: number | null;
            } | null;
            speedDown?: {
              __typename?: 'SpeedSettings';
              speedLaden?: number | null;
              speedUnladen?: number | null;
            } | null;
          } | null;
          capacity?: {
            __typename?: 'AgentCapacitySettings';
            maxVolume?: number | null;
            maxWeight?: number | null;
            maxUomQuantity?: Array<{
              __typename?: 'AgentCapacityUomSettings';
              uom: string;
              quantity?: number | null;
            }> | null;
          } | null;
          powerSource?: {
            __typename?: 'AgentPowerSourceSettings';
            energyConsumptionPerSecond?: number | null;
          } | null;
        } | null;
        cost?: {
          __typename?: 'AgentCostSettings';
          costPerSecond?: number | null;
        } | null;
        utilisation?: {
          __typename?: 'AgentUtilisationSettings';
          agentAmount?: number | null;
          secondsOperation?: number | null;
        } | null;
      }>;
    } | null;
    optimizationSettings?: {
      __typename?: 'OptimizationSettings';
      locationSizeConstraint?: {
        __typename?: 'LocationSizeConstraintSettings';
        disabled?: boolean | null;
      } | null;
      bayWidthConstraint?: {
        __typename?: 'BayWidthConstraintSettings';
        disabled?: boolean | null;
      } | null;
      terminationSettings?: {
        __typename?: 'TerminationSettings';
        targetImprovement?: number | null;
        timeLimit?: number | null;
        minImprovementRate?: {
          __typename?: 'MinImprovementRateSettings';
          minImprovementChange: number;
          steps: number;
        } | null;
      } | null;
      swapSettings?: {
        __typename?: 'SwapSettings';
        rules?: Array<{
          __typename?: 'OptimizationSwapRule';
          id?: string | null;
          title?: string | null;
          src?: {
            __typename?: 'AssignmentSubsetSpec';
            unassignedLocationsMatch?: UnassignedLocationsMatch | null;
            locationsMatch?: {
              __typename?: 'LocationFilterUnion';
              anyOf: Array<{
                __typename?: 'LocationFilterIntersection';
                id?: string | null;
                allOf: Array<{
                  __typename?: 'LocationFilter';
                  type: LocationFilterType;
                  isNull?: boolean | null;
                  isNot?: boolean | null;
                  valueIn?: Array<{
                    __typename?: 'FilterValue';
                    title: string;
                  }> | null;
                  range?: {
                    __typename?: 'NumberFilterRange';
                    from?: number | null;
                    to?: number | null;
                  } | null;
                  stringRange?: {
                    __typename?: 'StringFilterRange';
                    from?: string | null;
                    excludeFrom?: boolean | null;
                    to?: string | null;
                    excludeTo?: boolean | null;
                  } | null;
                }>;
              }>;
            } | null;
            itemsMatch?: {
              __typename?: 'SimulationItemFilterUnion';
              anyOf: Array<{
                __typename?: 'SimulationItemFilterIntersection';
                id?: string | null;
                allOf: Array<{
                  __typename?: 'SimulationItemFilter';
                  type: SimulationItemFilterType;
                  isNull?: boolean | null;
                  isNot?: boolean | null;
                  valueIn?: Array<{
                    __typename?: 'FilterValue';
                    title: string;
                  }> | null;
                  range?: {
                    __typename?: 'NumberFilterRange';
                    from?: number | null;
                    to?: number | null;
                  } | null;
                  stringRange?: {
                    __typename?: 'StringFilterRange';
                    from?: string | null;
                    excludeFrom?: boolean | null;
                    to?: string | null;
                    excludeTo?: boolean | null;
                  } | null;
                }>;
              }>;
            } | null;
          } | null;
          dest?: {
            __typename?: 'AssignmentSubsetSpec';
            unassignedLocationsMatch?: UnassignedLocationsMatch | null;
            locationsMatch?: {
              __typename?: 'LocationFilterUnion';
              anyOf: Array<{
                __typename?: 'LocationFilterIntersection';
                id?: string | null;
                allOf: Array<{
                  __typename?: 'LocationFilter';
                  type: LocationFilterType;
                  isNull?: boolean | null;
                  isNot?: boolean | null;
                  valueIn?: Array<{
                    __typename?: 'FilterValue';
                    title: string;
                  }> | null;
                  range?: {
                    __typename?: 'NumberFilterRange';
                    from?: number | null;
                    to?: number | null;
                  } | null;
                  stringRange?: {
                    __typename?: 'StringFilterRange';
                    from?: string | null;
                    excludeFrom?: boolean | null;
                    to?: string | null;
                    excludeTo?: boolean | null;
                  } | null;
                }>;
              }>;
            } | null;
            itemsMatch?: {
              __typename?: 'SimulationItemFilterUnion';
              anyOf: Array<{
                __typename?: 'SimulationItemFilterIntersection';
                id?: string | null;
                allOf: Array<{
                  __typename?: 'SimulationItemFilter';
                  type: SimulationItemFilterType;
                  isNull?: boolean | null;
                  isNot?: boolean | null;
                  valueIn?: Array<{
                    __typename?: 'FilterValue';
                    title: string;
                  }> | null;
                  range?: {
                    __typename?: 'NumberFilterRange';
                    from?: number | null;
                    to?: number | null;
                  } | null;
                  stringRange?: {
                    __typename?: 'StringFilterRange';
                    from?: string | null;
                    excludeFrom?: boolean | null;
                    to?: string | null;
                    excludeTo?: boolean | null;
                  } | null;
                }>;
              }>;
            } | null;
          } | null;
        }> | null;
      } | null;
      loadBalancing?: {
        __typename?: 'LoadBalancingPolicy';
        rules: Array<{
          __typename?: 'LoadBalancingRule';
          id?: string | null;
          title?: string | null;
          type: LoadBalancingZoneType;
          objectiveWeight?: number | null;
          constraint?: {
            __typename?: 'LoadBalancingConstraint';
            maxRelativeDeviation: number;
          } | null;
          include?: {
            __typename?: 'LocationFilterUnion';
            anyOf: Array<{
              __typename?: 'LocationFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'LocationFilter';
                type: LocationFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
        }>;
      } | null;
      limitSettings?: {
        __typename?: 'LimitSettings';
        maxMoves?: number | null;
      } | null;
      reassignJobs?: {
        __typename?: 'ReassignJobsSettings';
        disabled?: boolean | null;
        cycleMode?: ReassignJobCycleMode | null;
      } | null;
      policyOverrides?: {
        __typename?: 'OptimizationPolicyOverrides';
        resourcePolicy?: {
          __typename?: 'ResourcePolicy';
          agents: Array<{
            __typename?: 'AgentSettings';
            id: string;
            title?: string | null;
            resourceTypeId: string;
            metadata?: any | null;
            roles?: Array<{
              __typename?: 'AgentRoleSettings';
              roleId: AgentRole;
              disabled?: boolean | null;
              preHandling?: {
                __typename?: 'PicklistProcessingEventSettings';
                timeFixed?: number | null;
                timePerOrder?: number | null;
                timePerPicklistLine?: number | null;
                timePerUom?: number | null;
              } | null;
              handlingPreparation?: {
                __typename?: 'PicklistLineProcessingEventSettings';
                timeFixed?: number | null;
                timePerUom?: number | null;
              } | null;
              handling?: {
                __typename?: 'HandlingEventSettings';
                timeFixed?: number | null;
                timePerWeight?: number | null;
                timePerVolume?: number | null;
                timePerUom?: Array<{
                  __typename?: 'UomHandlingSettings';
                  uom: string;
                  timePerUom?: number | null;
                }> | null;
              } | null;
              reordering?: {
                __typename?: 'ReorderEventSettings';
                timeFixed?: number | null;
                timePerReorderedPosition?: number | null;
                timePerWeight?: number | null;
                timePerVolume?: number | null;
                timePerUom?: Array<{
                  __typename?: 'UomHandlingSettings';
                  uom: string;
                  timePerUom?: number | null;
                }> | null;
              } | null;
              handlingCompletion?: {
                __typename?: 'PicklistLineProcessingEventSettings';
                timeFixed?: number | null;
                timePerUom?: number | null;
              } | null;
              postHandling?: {
                __typename?: 'PicklistProcessingEventSettings';
                timeFixed?: number | null;
                timePerOrder?: number | null;
                timePerPicklistLine?: number | null;
                timePerUom?: number | null;
              } | null;
            }> | null;
            specs?: {
              __typename?: 'AgentSpecs';
              horizontalTravelling?: {
                __typename?: 'HorizontalTravellingEventSettings';
                speed?: {
                  __typename?: 'SpeedSettings';
                  speedLaden?: number | null;
                  speedUnladen?: number | null;
                } | null;
              } | null;
              verticalTravelling?: {
                __typename?: 'VerticalTravellingEventSettings';
                speedUp?: {
                  __typename?: 'SpeedSettings';
                  speedLaden?: number | null;
                  speedUnladen?: number | null;
                } | null;
                speedDown?: {
                  __typename?: 'SpeedSettings';
                  speedLaden?: number | null;
                  speedUnladen?: number | null;
                } | null;
              } | null;
              capacity?: {
                __typename?: 'AgentCapacitySettings';
                maxVolume?: number | null;
                maxWeight?: number | null;
                maxUomQuantity?: Array<{
                  __typename?: 'AgentCapacityUomSettings';
                  uom: string;
                  quantity?: number | null;
                }> | null;
              } | null;
              powerSource?: {
                __typename?: 'AgentPowerSourceSettings';
                energyConsumptionPerSecond?: number | null;
              } | null;
            } | null;
            cost?: {
              __typename?: 'AgentCostSettings';
              costPerSecond?: number | null;
            } | null;
            utilisation?: {
              __typename?: 'AgentUtilisationSettings';
              agentAmount?: number | null;
              secondsOperation?: number | null;
            } | null;
          }>;
        } | null;
      } | null;
      pickabilityConstraint?: {
        __typename?: 'PickabilityConstraintSettings';
        disabled?: boolean | null;
      } | null;
      assignmentCapacitySettings?: {
        __typename?: 'AssignmentCapacitySettings';
        avoidMerge?: boolean | null;
      } | null;
    } | null;
    analyzeResult?: {
      __typename?: 'AnalyzeResult';
      startedAt?: number | null;
      completedAt?: number | null;
      id: string;
      status: AnalyzeResultStatus;
      stage?: AnalyzeResultStage | null;
      progress?: number | null;
      totalOrders: number;
      totalAssembledOrders: number;
      totalAssembledPartiallyOrders: number;
      totalLines: number;
      assembledLines: number;
      assembledPartiallyLines: number;
      ignoredLines: number;
      totalDistance: number;
      totalCost: number;
      totalDuration: number;
      costDetails?: {
        __typename?: 'AnalyzeResultCostDetails';
        processes: Array<{
          __typename?: 'AnalyzeResultProcessCost';
          processType: AnalyzeResultProcessType;
          distance: number;
          duration: number;
          cost: number;
        }>;
      } | null;
      summary?: {
        __typename?: 'AnalyzeResultSummary';
        eventTypes: Array<{
          __typename?: 'AnalyzeResultEventTypeSummary';
          eventType: PicklistEventType;
          processType?: AnalyzeResultProcessType | null;
          count: number;
          totalDuration: number;
          totalCost: number;
        }>;
        agents: Array<{
          __typename?: 'AnalyzeResultAgentSummary';
          agent: string;
          eventTypes: Array<{
            __typename?: 'AnalyzeResultEventTypeSummary';
            eventType: PicklistEventType;
            processType?: AnalyzeResultProcessType | null;
            count: number;
            totalDuration: number;
            totalCost: number;
          }>;
        }>;
        assignment?: {
          __typename?: 'AnalyzeResultAssignmentSummary';
          totalStoredItems?: number | null;
        } | null;
      } | null;
      resourcePolicy?: {
        __typename?: 'ResourcePolicy';
        agents: Array<{
          __typename?: 'AgentSettings';
          id: string;
          specs?: {
            __typename?: 'AgentSpecs';
            powerSource?: {
              __typename?: 'AgentPowerSourceSettings';
              energyConsumptionPerSecond?: number | null;
            } | null;
          } | null;
        }>;
      } | null;
    } | null;
    latestOptimizationRun?: {
      __typename?: 'OptimizationRun';
      id: string;
      progress?: number | null;
      steps?: number | null;
      seed?: number | null;
      status: OptimizationRunStatus;
      startedAt?: number | null;
      completedAt?: number | null;
      elapsedRunningTime: number;
      initialCost?: number | null;
      reassignedCost?: number | null;
      optimizedCost?: number | null;
      reassignImprovement?: number | null;
      optimizeImprovement?: number | null;
      improvement?: number | null;
      analyzeResult?: {
        __typename?: 'AnalyzeResult';
        id: string;
        status: AnalyzeResultStatus;
        stage?: AnalyzeResultStage | null;
        progress?: number | null;
        totalOrders: number;
        totalAssembledOrders: number;
        totalAssembledPartiallyOrders: number;
        totalLines: number;
        assembledLines: number;
        assembledPartiallyLines: number;
        ignoredLines: number;
        totalDistance: number;
        totalCost: number;
        totalDuration: number;
        costDetails?: {
          __typename?: 'AnalyzeResultCostDetails';
          processes: Array<{
            __typename?: 'AnalyzeResultProcessCost';
            processType: AnalyzeResultProcessType;
            distance: number;
            duration: number;
            cost: number;
          }>;
        } | null;
        summary?: {
          __typename?: 'AnalyzeResultSummary';
          eventTypes: Array<{
            __typename?: 'AnalyzeResultEventTypeSummary';
            eventType: PicklistEventType;
            processType?: AnalyzeResultProcessType | null;
            count: number;
            totalDuration: number;
            totalCost: number;
          }>;
          agents: Array<{
            __typename?: 'AnalyzeResultAgentSummary';
            agent: string;
            eventTypes: Array<{
              __typename?: 'AnalyzeResultEventTypeSummary';
              eventType: PicklistEventType;
              processType?: AnalyzeResultProcessType | null;
              count: number;
              totalDuration: number;
              totalCost: number;
            }>;
          }>;
          assignment?: {
            __typename?: 'AnalyzeResultAssignmentSummary';
            totalStoredItems?: number | null;
          } | null;
        } | null;
        resourcePolicy?: {
          __typename?: 'ResourcePolicy';
          agents: Array<{
            __typename?: 'AgentSettings';
            id: string;
            specs?: {
              __typename?: 'AgentSpecs';
              powerSource?: {
                __typename?: 'AgentPowerSourceSettings';
                energyConsumptionPerSecond?: number | null;
              } | null;
            } | null;
          }>;
        } | null;
      } | null;
      latestProgressDetails?: {
        __typename?: 'OptimizationRunProgress';
        step: number;
        startedAt: number;
        completedAt: number;
        progress: number;
        currentCost: number;
        elapsedComputeTime: number;
        remainingComputeTime?: number | null;
        currentMoveCount?: number | null;
        currentOptimizeImprovement?: number | null;
        currentTotalImprovement?: number | null;
        details?: {
          __typename?: 'OptimizationRunProgressDetails';
          improvement?: {
            __typename?: 'OptimizationImprovementSummary';
            effectiveImprovement: number;
            initialEffectiveCost: number;
            optimizedEffectiveCost: number;
            objectives: Array<{
              __typename?: 'OptimizationObjectiveImprovementSummary';
              id?: string | null;
              type: OptimizationObjectiveType;
              initialCost: number;
              objectiveWeight: number;
              improvement: number;
            }>;
          } | null;
        } | null;
      } | null;
      optimizationSummary?: {
        __typename?: 'OptimizationSummary';
        stopReason?: OptimizationStopReason | null;
        effectiveSteps?: number | null;
        totalComputeTime?: number | null;
        moveCount?: number | null;
        improvement?: {
          __typename?: 'OptimizationImprovementSummary';
          effectiveImprovement: number;
          initialEffectiveCost: number;
          optimizedEffectiveCost: number;
          objectives: Array<{
            __typename?: 'OptimizationObjectiveImprovementSummary';
            id?: string | null;
            type: OptimizationObjectiveType;
            initialCost: number;
            objectiveWeight: number;
            improvement: number;
          }>;
        } | null;
      } | null;
      resultAssignment?: { __typename?: 'Assignment'; id: string } | null;
      optimizationSettings?: {
        __typename?: 'OptimizationRunSettings';
        source?: {
          __typename?: 'OptimizationRunSourceReference';
          type: OptimizationRunSourceType;
          id: string;
        } | null;
      } | null;
    } | null;
    latestAllocationRun?: {
      __typename?: 'AllocationRun';
      id: string;
      progress?: number | null;
      status: BatchJobStatus;
      startedAt?: number | null;
      completedAt?: number | null;
      analyzeResult?: {
        __typename?: 'AnalyzeResult';
        id: string;
        status: AnalyzeResultStatus;
        stage?: AnalyzeResultStage | null;
        progress?: number | null;
        totalOrders: number;
        totalAssembledOrders: number;
        totalAssembledPartiallyOrders: number;
        totalLines: number;
        assembledLines: number;
        assembledPartiallyLines: number;
        ignoredLines: number;
        totalDistance: number;
        totalCost: number;
        totalDuration: number;
        costDetails?: {
          __typename?: 'AnalyzeResultCostDetails';
          processes: Array<{
            __typename?: 'AnalyzeResultProcessCost';
            processType: AnalyzeResultProcessType;
            distance: number;
            duration: number;
            cost: number;
          }>;
        } | null;
        summary?: {
          __typename?: 'AnalyzeResultSummary';
          eventTypes: Array<{
            __typename?: 'AnalyzeResultEventTypeSummary';
            eventType: PicklistEventType;
            processType?: AnalyzeResultProcessType | null;
            count: number;
            totalDuration: number;
            totalCost: number;
          }>;
          agents: Array<{
            __typename?: 'AnalyzeResultAgentSummary';
            agent: string;
            eventTypes: Array<{
              __typename?: 'AnalyzeResultEventTypeSummary';
              eventType: PicklistEventType;
              processType?: AnalyzeResultProcessType | null;
              count: number;
              totalDuration: number;
              totalCost: number;
            }>;
          }>;
          assignment?: {
            __typename?: 'AnalyzeResultAssignmentSummary';
            totalStoredItems?: number | null;
          } | null;
        } | null;
        resourcePolicy?: {
          __typename?: 'ResourcePolicy';
          agents: Array<{
            __typename?: 'AgentSettings';
            id: string;
            specs?: {
              __typename?: 'AgentSpecs';
              powerSource?: {
                __typename?: 'AgentPowerSourceSettings';
                energyConsumptionPerSecond?: number | null;
              } | null;
            } | null;
          }>;
        } | null;
      } | null;
    } | null;
  }>;
};

export type LoadSlottingDashboardQueryVariables = Exact<{
  warehouseId: Scalars['ID']['input'];
  page: SkipLimitPageSpec;
  sortBy?: InputMaybe<Array<SimulationsSortBy> | SimulationsSortBy>;
  filter?: InputMaybe<SimulationFilter>;
}>;

export type LoadSlottingDashboardQuery = {
  __typename?: 'Query';
  warehouse?: {
    __typename?: 'Warehouse';
    simulations: {
      __typename?: 'SimulationsConnection';
      totalCount: number;
      allUserTags: Array<string>;
      content: Array<{
        __typename?: 'Simulation';
        id: string;
        title: string;
        description?: string | null;
        updatedAt: number;
        createdAt: number;
        status: SimulationStatus;
        userTags?: Array<string> | null;
        layout?: {
          __typename?: 'Layout';
          id: string;
          title: string;
          summary?: {
            __typename?: 'LayoutSummary';
            locationCount: number;
            disabledLocationCount: number;
            aisleCount: number;
            bayCount: number;
            areas: Array<{
              __typename?: 'LayoutAreaSummary';
              areaId: string;
              title: string;
              locationCount: number;
              aisleCount: number;
              bayCount: number;
            }>;
          } | null;
        } | null;
        assignment?: {
          __typename?: 'Assignment';
          id: string;
          title: string;
        } | null;
        orderSet?: {
          __typename?: 'OrderSet';
          id: string;
          title: string;
        } | null;
        itemSet?: { __typename?: 'ItemSet'; id: string; title: string } | null;
        resourcePolicy?: {
          __typename?: 'ResourcePolicy';
          agents: Array<{
            __typename?: 'AgentSettings';
            id: string;
            title?: string | null;
            resourceTypeId: string;
            metadata?: any | null;
            roles?: Array<{
              __typename?: 'AgentRoleSettings';
              roleId: AgentRole;
              disabled?: boolean | null;
              preHandling?: {
                __typename?: 'PicklistProcessingEventSettings';
                timeFixed?: number | null;
                timePerOrder?: number | null;
                timePerPicklistLine?: number | null;
                timePerUom?: number | null;
              } | null;
              handlingPreparation?: {
                __typename?: 'PicklistLineProcessingEventSettings';
                timeFixed?: number | null;
                timePerUom?: number | null;
              } | null;
              handling?: {
                __typename?: 'HandlingEventSettings';
                timeFixed?: number | null;
                timePerWeight?: number | null;
                timePerVolume?: number | null;
                timePerUom?: Array<{
                  __typename?: 'UomHandlingSettings';
                  uom: string;
                  timePerUom?: number | null;
                }> | null;
              } | null;
              reordering?: {
                __typename?: 'ReorderEventSettings';
                timeFixed?: number | null;
                timePerReorderedPosition?: number | null;
                timePerWeight?: number | null;
                timePerVolume?: number | null;
                timePerUom?: Array<{
                  __typename?: 'UomHandlingSettings';
                  uom: string;
                  timePerUom?: number | null;
                }> | null;
              } | null;
              handlingCompletion?: {
                __typename?: 'PicklistLineProcessingEventSettings';
                timeFixed?: number | null;
                timePerUom?: number | null;
              } | null;
              postHandling?: {
                __typename?: 'PicklistProcessingEventSettings';
                timeFixed?: number | null;
                timePerOrder?: number | null;
                timePerPicklistLine?: number | null;
                timePerUom?: number | null;
              } | null;
            }> | null;
            specs?: {
              __typename?: 'AgentSpecs';
              horizontalTravelling?: {
                __typename?: 'HorizontalTravellingEventSettings';
                speed?: {
                  __typename?: 'SpeedSettings';
                  speedLaden?: number | null;
                  speedUnladen?: number | null;
                } | null;
              } | null;
              verticalTravelling?: {
                __typename?: 'VerticalTravellingEventSettings';
                speedUp?: {
                  __typename?: 'SpeedSettings';
                  speedLaden?: number | null;
                  speedUnladen?: number | null;
                } | null;
                speedDown?: {
                  __typename?: 'SpeedSettings';
                  speedLaden?: number | null;
                  speedUnladen?: number | null;
                } | null;
              } | null;
              capacity?: {
                __typename?: 'AgentCapacitySettings';
                maxVolume?: number | null;
                maxWeight?: number | null;
                maxUomQuantity?: Array<{
                  __typename?: 'AgentCapacityUomSettings';
                  uom: string;
                  quantity?: number | null;
                }> | null;
              } | null;
              powerSource?: {
                __typename?: 'AgentPowerSourceSettings';
                energyConsumptionPerSecond?: number | null;
              } | null;
            } | null;
            cost?: {
              __typename?: 'AgentCostSettings';
              costPerSecond?: number | null;
            } | null;
            utilisation?: {
              __typename?: 'AgentUtilisationSettings';
              agentAmount?: number | null;
              secondsOperation?: number | null;
            } | null;
          }>;
        } | null;
        optimizationSettings?: {
          __typename?: 'OptimizationSettings';
          locationSizeConstraint?: {
            __typename?: 'LocationSizeConstraintSettings';
            disabled?: boolean | null;
          } | null;
          bayWidthConstraint?: {
            __typename?: 'BayWidthConstraintSettings';
            disabled?: boolean | null;
          } | null;
          terminationSettings?: {
            __typename?: 'TerminationSettings';
            targetImprovement?: number | null;
            timeLimit?: number | null;
            minImprovementRate?: {
              __typename?: 'MinImprovementRateSettings';
              minImprovementChange: number;
              steps: number;
            } | null;
          } | null;
          swapSettings?: {
            __typename?: 'SwapSettings';
            rules?: Array<{
              __typename?: 'OptimizationSwapRule';
              id?: string | null;
              title?: string | null;
              src?: {
                __typename?: 'AssignmentSubsetSpec';
                unassignedLocationsMatch?: UnassignedLocationsMatch | null;
                locationsMatch?: {
                  __typename?: 'LocationFilterUnion';
                  anyOf: Array<{
                    __typename?: 'LocationFilterIntersection';
                    id?: string | null;
                    allOf: Array<{
                      __typename?: 'LocationFilter';
                      type: LocationFilterType;
                      isNull?: boolean | null;
                      isNot?: boolean | null;
                      valueIn?: Array<{
                        __typename?: 'FilterValue';
                        title: string;
                      }> | null;
                      range?: {
                        __typename?: 'NumberFilterRange';
                        from?: number | null;
                        to?: number | null;
                      } | null;
                      stringRange?: {
                        __typename?: 'StringFilterRange';
                        from?: string | null;
                        excludeFrom?: boolean | null;
                        to?: string | null;
                        excludeTo?: boolean | null;
                      } | null;
                    }>;
                  }>;
                } | null;
                itemsMatch?: {
                  __typename?: 'SimulationItemFilterUnion';
                  anyOf: Array<{
                    __typename?: 'SimulationItemFilterIntersection';
                    id?: string | null;
                    allOf: Array<{
                      __typename?: 'SimulationItemFilter';
                      type: SimulationItemFilterType;
                      isNull?: boolean | null;
                      isNot?: boolean | null;
                      valueIn?: Array<{
                        __typename?: 'FilterValue';
                        title: string;
                      }> | null;
                      range?: {
                        __typename?: 'NumberFilterRange';
                        from?: number | null;
                        to?: number | null;
                      } | null;
                      stringRange?: {
                        __typename?: 'StringFilterRange';
                        from?: string | null;
                        excludeFrom?: boolean | null;
                        to?: string | null;
                        excludeTo?: boolean | null;
                      } | null;
                    }>;
                  }>;
                } | null;
              } | null;
              dest?: {
                __typename?: 'AssignmentSubsetSpec';
                unassignedLocationsMatch?: UnassignedLocationsMatch | null;
                locationsMatch?: {
                  __typename?: 'LocationFilterUnion';
                  anyOf: Array<{
                    __typename?: 'LocationFilterIntersection';
                    id?: string | null;
                    allOf: Array<{
                      __typename?: 'LocationFilter';
                      type: LocationFilterType;
                      isNull?: boolean | null;
                      isNot?: boolean | null;
                      valueIn?: Array<{
                        __typename?: 'FilterValue';
                        title: string;
                      }> | null;
                      range?: {
                        __typename?: 'NumberFilterRange';
                        from?: number | null;
                        to?: number | null;
                      } | null;
                      stringRange?: {
                        __typename?: 'StringFilterRange';
                        from?: string | null;
                        excludeFrom?: boolean | null;
                        to?: string | null;
                        excludeTo?: boolean | null;
                      } | null;
                    }>;
                  }>;
                } | null;
                itemsMatch?: {
                  __typename?: 'SimulationItemFilterUnion';
                  anyOf: Array<{
                    __typename?: 'SimulationItemFilterIntersection';
                    id?: string | null;
                    allOf: Array<{
                      __typename?: 'SimulationItemFilter';
                      type: SimulationItemFilterType;
                      isNull?: boolean | null;
                      isNot?: boolean | null;
                      valueIn?: Array<{
                        __typename?: 'FilterValue';
                        title: string;
                      }> | null;
                      range?: {
                        __typename?: 'NumberFilterRange';
                        from?: number | null;
                        to?: number | null;
                      } | null;
                      stringRange?: {
                        __typename?: 'StringFilterRange';
                        from?: string | null;
                        excludeFrom?: boolean | null;
                        to?: string | null;
                        excludeTo?: boolean | null;
                      } | null;
                    }>;
                  }>;
                } | null;
              } | null;
            }> | null;
          } | null;
          loadBalancing?: {
            __typename?: 'LoadBalancingPolicy';
            rules: Array<{
              __typename?: 'LoadBalancingRule';
              id?: string | null;
              title?: string | null;
              type: LoadBalancingZoneType;
              objectiveWeight?: number | null;
              constraint?: {
                __typename?: 'LoadBalancingConstraint';
                maxRelativeDeviation: number;
              } | null;
              include?: {
                __typename?: 'LocationFilterUnion';
                anyOf: Array<{
                  __typename?: 'LocationFilterIntersection';
                  id?: string | null;
                  allOf: Array<{
                    __typename?: 'LocationFilter';
                    type: LocationFilterType;
                    isNull?: boolean | null;
                    isNot?: boolean | null;
                    valueIn?: Array<{
                      __typename?: 'FilterValue';
                      title: string;
                    }> | null;
                    range?: {
                      __typename?: 'NumberFilterRange';
                      from?: number | null;
                      to?: number | null;
                    } | null;
                    stringRange?: {
                      __typename?: 'StringFilterRange';
                      from?: string | null;
                      excludeFrom?: boolean | null;
                      to?: string | null;
                      excludeTo?: boolean | null;
                    } | null;
                  }>;
                }>;
              } | null;
            }>;
          } | null;
          limitSettings?: {
            __typename?: 'LimitSettings';
            maxMoves?: number | null;
          } | null;
          reassignJobs?: {
            __typename?: 'ReassignJobsSettings';
            disabled?: boolean | null;
            cycleMode?: ReassignJobCycleMode | null;
          } | null;
          policyOverrides?: {
            __typename?: 'OptimizationPolicyOverrides';
            resourcePolicy?: {
              __typename?: 'ResourcePolicy';
              agents: Array<{
                __typename?: 'AgentSettings';
                id: string;
                title?: string | null;
                resourceTypeId: string;
                metadata?: any | null;
                roles?: Array<{
                  __typename?: 'AgentRoleSettings';
                  roleId: AgentRole;
                  disabled?: boolean | null;
                  preHandling?: {
                    __typename?: 'PicklistProcessingEventSettings';
                    timeFixed?: number | null;
                    timePerOrder?: number | null;
                    timePerPicklistLine?: number | null;
                    timePerUom?: number | null;
                  } | null;
                  handlingPreparation?: {
                    __typename?: 'PicklistLineProcessingEventSettings';
                    timeFixed?: number | null;
                    timePerUom?: number | null;
                  } | null;
                  handling?: {
                    __typename?: 'HandlingEventSettings';
                    timeFixed?: number | null;
                    timePerWeight?: number | null;
                    timePerVolume?: number | null;
                    timePerUom?: Array<{
                      __typename?: 'UomHandlingSettings';
                      uom: string;
                      timePerUom?: number | null;
                    }> | null;
                  } | null;
                  reordering?: {
                    __typename?: 'ReorderEventSettings';
                    timeFixed?: number | null;
                    timePerReorderedPosition?: number | null;
                    timePerWeight?: number | null;
                    timePerVolume?: number | null;
                    timePerUom?: Array<{
                      __typename?: 'UomHandlingSettings';
                      uom: string;
                      timePerUom?: number | null;
                    }> | null;
                  } | null;
                  handlingCompletion?: {
                    __typename?: 'PicklistLineProcessingEventSettings';
                    timeFixed?: number | null;
                    timePerUom?: number | null;
                  } | null;
                  postHandling?: {
                    __typename?: 'PicklistProcessingEventSettings';
                    timeFixed?: number | null;
                    timePerOrder?: number | null;
                    timePerPicklistLine?: number | null;
                    timePerUom?: number | null;
                  } | null;
                }> | null;
                specs?: {
                  __typename?: 'AgentSpecs';
                  horizontalTravelling?: {
                    __typename?: 'HorizontalTravellingEventSettings';
                    speed?: {
                      __typename?: 'SpeedSettings';
                      speedLaden?: number | null;
                      speedUnladen?: number | null;
                    } | null;
                  } | null;
                  verticalTravelling?: {
                    __typename?: 'VerticalTravellingEventSettings';
                    speedUp?: {
                      __typename?: 'SpeedSettings';
                      speedLaden?: number | null;
                      speedUnladen?: number | null;
                    } | null;
                    speedDown?: {
                      __typename?: 'SpeedSettings';
                      speedLaden?: number | null;
                      speedUnladen?: number | null;
                    } | null;
                  } | null;
                  capacity?: {
                    __typename?: 'AgentCapacitySettings';
                    maxVolume?: number | null;
                    maxWeight?: number | null;
                    maxUomQuantity?: Array<{
                      __typename?: 'AgentCapacityUomSettings';
                      uom: string;
                      quantity?: number | null;
                    }> | null;
                  } | null;
                  powerSource?: {
                    __typename?: 'AgentPowerSourceSettings';
                    energyConsumptionPerSecond?: number | null;
                  } | null;
                } | null;
                cost?: {
                  __typename?: 'AgentCostSettings';
                  costPerSecond?: number | null;
                } | null;
                utilisation?: {
                  __typename?: 'AgentUtilisationSettings';
                  agentAmount?: number | null;
                  secondsOperation?: number | null;
                } | null;
              }>;
            } | null;
          } | null;
          pickabilityConstraint?: {
            __typename?: 'PickabilityConstraintSettings';
            disabled?: boolean | null;
          } | null;
          assignmentCapacitySettings?: {
            __typename?: 'AssignmentCapacitySettings';
            avoidMerge?: boolean | null;
          } | null;
        } | null;
        analyzeResult?: {
          __typename?: 'AnalyzeResult';
          startedAt?: number | null;
          completedAt?: number | null;
          id: string;
          status: AnalyzeResultStatus;
          stage?: AnalyzeResultStage | null;
          progress?: number | null;
          totalOrders: number;
          totalAssembledOrders: number;
          totalAssembledPartiallyOrders: number;
          totalLines: number;
          assembledLines: number;
          assembledPartiallyLines: number;
          ignoredLines: number;
          totalDistance: number;
          totalCost: number;
          totalDuration: number;
          costDetails?: {
            __typename?: 'AnalyzeResultCostDetails';
            processes: Array<{
              __typename?: 'AnalyzeResultProcessCost';
              processType: AnalyzeResultProcessType;
              distance: number;
              duration: number;
              cost: number;
            }>;
          } | null;
          summary?: {
            __typename?: 'AnalyzeResultSummary';
            eventTypes: Array<{
              __typename?: 'AnalyzeResultEventTypeSummary';
              eventType: PicklistEventType;
              processType?: AnalyzeResultProcessType | null;
              count: number;
              totalDuration: number;
              totalCost: number;
            }>;
            agents: Array<{
              __typename?: 'AnalyzeResultAgentSummary';
              agent: string;
              eventTypes: Array<{
                __typename?: 'AnalyzeResultEventTypeSummary';
                eventType: PicklistEventType;
                processType?: AnalyzeResultProcessType | null;
                count: number;
                totalDuration: number;
                totalCost: number;
              }>;
            }>;
            assignment?: {
              __typename?: 'AnalyzeResultAssignmentSummary';
              totalStoredItems?: number | null;
            } | null;
          } | null;
          resourcePolicy?: {
            __typename?: 'ResourcePolicy';
            agents: Array<{
              __typename?: 'AgentSettings';
              id: string;
              specs?: {
                __typename?: 'AgentSpecs';
                powerSource?: {
                  __typename?: 'AgentPowerSourceSettings';
                  energyConsumptionPerSecond?: number | null;
                } | null;
              } | null;
            }>;
          } | null;
        } | null;
        latestOptimizationRun?: {
          __typename?: 'OptimizationRun';
          id: string;
          progress?: number | null;
          steps?: number | null;
          seed?: number | null;
          status: OptimizationRunStatus;
          startedAt?: number | null;
          completedAt?: number | null;
          elapsedRunningTime: number;
          initialCost?: number | null;
          reassignedCost?: number | null;
          optimizedCost?: number | null;
          reassignImprovement?: number | null;
          optimizeImprovement?: number | null;
          improvement?: number | null;
          analyzeResult?: {
            __typename?: 'AnalyzeResult';
            id: string;
            status: AnalyzeResultStatus;
            stage?: AnalyzeResultStage | null;
            progress?: number | null;
            totalOrders: number;
            totalAssembledOrders: number;
            totalAssembledPartiallyOrders: number;
            totalLines: number;
            assembledLines: number;
            assembledPartiallyLines: number;
            ignoredLines: number;
            totalDistance: number;
            totalCost: number;
            totalDuration: number;
            costDetails?: {
              __typename?: 'AnalyzeResultCostDetails';
              processes: Array<{
                __typename?: 'AnalyzeResultProcessCost';
                processType: AnalyzeResultProcessType;
                distance: number;
                duration: number;
                cost: number;
              }>;
            } | null;
            summary?: {
              __typename?: 'AnalyzeResultSummary';
              eventTypes: Array<{
                __typename?: 'AnalyzeResultEventTypeSummary';
                eventType: PicklistEventType;
                processType?: AnalyzeResultProcessType | null;
                count: number;
                totalDuration: number;
                totalCost: number;
              }>;
              agents: Array<{
                __typename?: 'AnalyzeResultAgentSummary';
                agent: string;
                eventTypes: Array<{
                  __typename?: 'AnalyzeResultEventTypeSummary';
                  eventType: PicklistEventType;
                  processType?: AnalyzeResultProcessType | null;
                  count: number;
                  totalDuration: number;
                  totalCost: number;
                }>;
              }>;
              assignment?: {
                __typename?: 'AnalyzeResultAssignmentSummary';
                totalStoredItems?: number | null;
              } | null;
            } | null;
            resourcePolicy?: {
              __typename?: 'ResourcePolicy';
              agents: Array<{
                __typename?: 'AgentSettings';
                id: string;
                specs?: {
                  __typename?: 'AgentSpecs';
                  powerSource?: {
                    __typename?: 'AgentPowerSourceSettings';
                    energyConsumptionPerSecond?: number | null;
                  } | null;
                } | null;
              }>;
            } | null;
          } | null;
          latestProgressDetails?: {
            __typename?: 'OptimizationRunProgress';
            step: number;
            startedAt: number;
            completedAt: number;
            progress: number;
            currentCost: number;
            elapsedComputeTime: number;
            remainingComputeTime?: number | null;
            currentMoveCount?: number | null;
            currentOptimizeImprovement?: number | null;
            currentTotalImprovement?: number | null;
            details?: {
              __typename?: 'OptimizationRunProgressDetails';
              improvement?: {
                __typename?: 'OptimizationImprovementSummary';
                effectiveImprovement: number;
                initialEffectiveCost: number;
                optimizedEffectiveCost: number;
                objectives: Array<{
                  __typename?: 'OptimizationObjectiveImprovementSummary';
                  id?: string | null;
                  type: OptimizationObjectiveType;
                  initialCost: number;
                  objectiveWeight: number;
                  improvement: number;
                }>;
              } | null;
            } | null;
          } | null;
          optimizationSummary?: {
            __typename?: 'OptimizationSummary';
            stopReason?: OptimizationStopReason | null;
            effectiveSteps?: number | null;
            totalComputeTime?: number | null;
            moveCount?: number | null;
            improvement?: {
              __typename?: 'OptimizationImprovementSummary';
              effectiveImprovement: number;
              initialEffectiveCost: number;
              optimizedEffectiveCost: number;
              objectives: Array<{
                __typename?: 'OptimizationObjectiveImprovementSummary';
                id?: string | null;
                type: OptimizationObjectiveType;
                initialCost: number;
                objectiveWeight: number;
                improvement: number;
              }>;
            } | null;
          } | null;
          resultAssignment?: { __typename?: 'Assignment'; id: string } | null;
          optimizationSettings?: {
            __typename?: 'OptimizationRunSettings';
            source?: {
              __typename?: 'OptimizationRunSourceReference';
              type: OptimizationRunSourceType;
              id: string;
            } | null;
          } | null;
        } | null;
        latestAllocationRun?: {
          __typename?: 'AllocationRun';
          id: string;
          progress?: number | null;
          status: BatchJobStatus;
          startedAt?: number | null;
          completedAt?: number | null;
          analyzeResult?: {
            __typename?: 'AnalyzeResult';
            id: string;
            status: AnalyzeResultStatus;
            stage?: AnalyzeResultStage | null;
            progress?: number | null;
            totalOrders: number;
            totalAssembledOrders: number;
            totalAssembledPartiallyOrders: number;
            totalLines: number;
            assembledLines: number;
            assembledPartiallyLines: number;
            ignoredLines: number;
            totalDistance: number;
            totalCost: number;
            totalDuration: number;
            costDetails?: {
              __typename?: 'AnalyzeResultCostDetails';
              processes: Array<{
                __typename?: 'AnalyzeResultProcessCost';
                processType: AnalyzeResultProcessType;
                distance: number;
                duration: number;
                cost: number;
              }>;
            } | null;
            summary?: {
              __typename?: 'AnalyzeResultSummary';
              eventTypes: Array<{
                __typename?: 'AnalyzeResultEventTypeSummary';
                eventType: PicklistEventType;
                processType?: AnalyzeResultProcessType | null;
                count: number;
                totalDuration: number;
                totalCost: number;
              }>;
              agents: Array<{
                __typename?: 'AnalyzeResultAgentSummary';
                agent: string;
                eventTypes: Array<{
                  __typename?: 'AnalyzeResultEventTypeSummary';
                  eventType: PicklistEventType;
                  processType?: AnalyzeResultProcessType | null;
                  count: number;
                  totalDuration: number;
                  totalCost: number;
                }>;
              }>;
              assignment?: {
                __typename?: 'AnalyzeResultAssignmentSummary';
                totalStoredItems?: number | null;
              } | null;
            } | null;
            resourcePolicy?: {
              __typename?: 'ResourcePolicy';
              agents: Array<{
                __typename?: 'AgentSettings';
                id: string;
                specs?: {
                  __typename?: 'AgentSpecs';
                  powerSource?: {
                    __typename?: 'AgentPowerSourceSettings';
                    energyConsumptionPerSecond?: number | null;
                  } | null;
                } | null;
              }>;
            } | null;
          } | null;
        } | null;
      }>;
    };
  } | null;
};

export type LoadLayoutDashboardQueryVariables = Exact<{
  warehouseId: Scalars['ID']['input'];
  page: SkipLimitPageSpec;
  sortBy?: InputMaybe<Array<LayoutsOrDraftsSortBy> | LayoutsOrDraftsSortBy>;
  filter?: InputMaybe<LayoutsOrDraftsFilter>;
}>;

export type LoadLayoutDashboardQuery = {
  __typename?: 'Query';
  warehouse?: {
    __typename?: 'Warehouse';
    layoutsOrDrafts: {
      __typename?: 'LayoutsOrDraftsConnection';
      totalCount: number;
      allUserTags: Array<string>;
      content: Array<
        | {
            __typename: 'Layout';
            id: string;
            title: string;
            description?: string | null;
            createdAt: number;
            updatedAt: number;
            userTags?: Array<string> | null;
            importedFrom?: {
              __typename?: 'LayoutImport';
              id: string;
              title: string;
              inputFileName: string;
            } | null;
            summary?: {
              __typename?: 'LayoutSummary';
              locationCount: number;
              disabledLocationCount: number;
              aisleCount: number;
              bayCount: number;
              areas: Array<{
                __typename?: 'LayoutAreaSummary';
                areaId: string;
                title: string;
                locationCount: number;
                aisleCount: number;
                bayCount: number;
              }>;
            } | null;
            simulationsWith: {
              __typename?: 'SimulationsConnection';
              totalCount: number;
              content: Array<{
                __typename?: 'Simulation';
                id: string;
                title: string;
                description?: string | null;
                createdAt: number;
                updatedAt: number;
                userTags?: Array<string> | null;
              }>;
            };
          }
        | {
            __typename: 'LayoutImport';
            id: string;
            title: string;
            description?: string | null;
            createdAt: number;
            updatedAt: number;
            importTime?: number | null;
            userTags?: Array<string> | null;
            converterSummary?: {
              __typename?: 'LayoutImportSummary';
              locationCount: number;
              disabledLocationCount: number;
              aisleCount: number;
              bayCount: number;
              areas: Array<{
                __typename?: 'LayoutImportAreaSummary';
                areaId: string;
                title: string;
                locationCount: number;
              }>;
            } | null;
          }
      >;
    };
  } | null;
};

export type LoadAssignmentDashboardQueryVariables = Exact<{
  warehouseId: Scalars['ID']['input'];
  page: SkipLimitPageSpec;
  sortBy?: InputMaybe<Array<AssignmentsSortBy> | AssignmentsSortBy>;
  filter?: InputMaybe<AssignmentsFilter>;
}>;

export type LoadAssignmentDashboardQuery = {
  __typename?: 'Query';
  warehouse?: {
    __typename?: 'Warehouse';
    assignments: {
      __typename?: 'AssignmentsConnection';
      totalCount: number;
      allUserTags: Array<string>;
      content: Array<{
        __typename?: 'Assignment';
        id: string;
        title: string;
        description?: string | null;
        createdAt: number;
        updatedAt: number;
        userTags?: Array<string> | null;
        summary?: {
          __typename?: 'AssignmentSummary';
          totalItemsCount: number;
          totalLocationsCount: number;
          totalProductsCount: number;
          consignees: Array<{
            __typename?: 'AssignmentConsigneeSummary';
            consignee: string;
            itemsCount: number;
            locationsCount: number;
            productsCount: number;
          }>;
          fields?: Array<{
            __typename?: 'DatasetObjectFieldSummary';
            name: string;
            hasData?: boolean | null;
            distinctValueCount?: number | null;
          }> | null;
          rawDataFields?: Array<{
            __typename?: 'DatasetObjectRawDataFieldSummary';
            name: string;
            type?: Array<string> | null;
            hasData?: boolean | null;
            distinctValueCount?: number | null;
          }> | null;
        } | null;
        simulationsWith: {
          __typename?: 'SimulationsConnection';
          totalCount: number;
          content: Array<{
            __typename?: 'Simulation';
            id: string;
            title: string;
            description?: string | null;
            createdAt: number;
            updatedAt: number;
            userTags?: Array<string> | null;
          }>;
        };
      }>;
    };
  } | null;
};

export type LoadOrderSetDashboardQueryVariables = Exact<{
  warehouseId: Scalars['ID']['input'];
  page: SkipLimitPageSpec;
  sortBy?: InputMaybe<Array<OrderSetsSortBy> | OrderSetsSortBy>;
  filter: OrderSetsFilter;
}>;

export type LoadOrderSetDashboardQuery = {
  __typename?: 'Query';
  warehouse?: {
    __typename?: 'Warehouse';
    orderSets: {
      __typename?: 'OrderSetsConnection';
      totalCount: number;
      allUserTags: Array<string>;
      content: Array<{
        __typename?: 'OrderSet';
        id: string;
        title: string;
        description?: string | null;
        updatedAt: number;
        createdAt: number;
        userTags?: Array<string> | null;
        summary?: {
          __typename?: 'OrderSetSummary';
          orderCount: number;
          productCount?: number | null;
          consignees: Array<{
            __typename?: 'OrderSetConsigneeSummary';
            consignee: string;
            orderCount: number;
            orderLineCount: number;
            productCount: number;
          }>;
          dateRange?: {
            __typename?: 'DatasetObjectDateRange';
            from?: string | null;
            to?: string | null;
          } | null;
        } | null;
        simulationsWith: {
          __typename?: 'SimulationsConnection';
          totalCount: number;
          content: Array<{
            __typename?: 'Simulation';
            id: string;
            title: string;
            description?: string | null;
            createdAt: number;
            updatedAt: number;
            userTags?: Array<string> | null;
          }>;
        };
      }>;
    };
  } | null;
};

export type LoadItemSetDashboardQueryVariables = Exact<{
  warehouseId: Scalars['ID']['input'];
  page: SkipLimitPageSpec;
  sortBy?: InputMaybe<Array<ItemSetsSortBy> | ItemSetsSortBy>;
  filter?: InputMaybe<ItemSetsFilter>;
}>;

export type LoadItemSetDashboardQuery = {
  __typename?: 'Query';
  warehouse?: {
    __typename?: 'Warehouse';
    itemSets: {
      __typename?: 'ItemSetsConnection';
      totalCount: number;
      allUserTags: Array<string>;
      content: Array<{
        __typename?: 'ItemSet';
        id: string;
        title: string;
        description?: string | null;
        updatedAt: number;
        createdAt: number;
        userTags?: Array<string> | null;
        summary?: {
          __typename?: 'ItemSetSummary';
          totalItemsCount: number;
          consignees: Array<{
            __typename?: 'ItemSetConsigneeSummary';
            consignee: string;
            itemsCount: number;
          }>;
        } | null;
        simulationsWith: {
          __typename?: 'SimulationsConnection';
          totalCount: number;
          content: Array<{
            __typename?: 'Simulation';
            id: string;
            title: string;
            description?: string | null;
            createdAt: number;
            updatedAt: number;
            userTags?: Array<string> | null;
          }>;
        };
      }>;
    };
  } | null;
};

export type LoadDatasetObjectDashboardQueryVariables = Exact<{
  warehouseId: Scalars['ID']['input'];
  page: SkipLimitPageSpec;
  sortBy?: InputMaybe<Array<DatasetObjectsSortBy> | DatasetObjectsSortBy>;
  filter?: InputMaybe<DatasetObjectFilter>;
}>;

export type LoadDatasetObjectDashboardQuery = {
  __typename?: 'Query';
  warehouse?: {
    __typename?: 'Warehouse';
    datasetObjects: {
      __typename?: 'DatasetObjectsConnection';
      totalCount: number;
      allUserTags: Array<string>;
      content: Array<{
        __typename?: 'DatasetObject';
        id: string;
        title: string;
        description?: string | null;
        objectType: ImportJobImportType;
        userTags?: Array<string> | null;
        importedAt?: number | null;
        createdAt: number;
        updatedAt: number;
        summary?: {
          __typename?: 'DatasetObjectSummary';
          generatedAt?: number | null;
          totalCount?: number | null;
          fields?: Array<{
            __typename?: 'DatasetObjectFieldSummary';
            name: string;
            hasData?: boolean | null;
            distinctValueCount?: number | null;
          }> | null;
          rawDataFields?: Array<{
            __typename?: 'DatasetObjectRawDataFieldSummary';
            name: string;
            type?: Array<string> | null;
            hasData?: boolean | null;
            distinctValueCount?: number | null;
          }> | null;
        } | null;
      }>;
    };
  } | null;
};

export type StoredFileFragment = {
  __typename?: 'StoredFile';
  warehouseId: string;
  name: string;
  size: number;
  lastModified: number;
  downloadUrl: string;
};

export type LoadWarehouseFilesQueryVariables = Exact<{
  whId: Scalars['ID']['input'];
}>;

export type LoadWarehouseFilesQuery = {
  __typename?: 'Query';
  warehouse?: {
    __typename?: 'Warehouse';
    files: Array<{
      __typename?: 'StoredFile';
      warehouseId: string;
      name: string;
      size: number;
      lastModified: number;
      downloadUrl: string;
    }>;
  } | null;
};

export type BeginFileUploadMutationVariables = Exact<{
  id: StoredFileIdInput;
}>;

export type BeginFileUploadMutation = {
  __typename?: 'Mutation';
  beginFileUpload: string;
};

export type BeginFileMultipartUploadMutationVariables = Exact<{
  input: BeginFileMultipartUploadInput;
}>;

export type BeginFileMultipartUploadMutation = {
  __typename?: 'Mutation';
  beginFileMultipartUpload: {
    __typename?: 'BeginFileMultipartUploadResult';
    uploadId: string;
    uploadLinks: Array<string>;
  };
};

export type CompleteFileMultipartUploadMutationVariables = Exact<{
  input: CompletedFileMultipartUploadInput;
}>;

export type CompleteFileMultipartUploadMutation = {
  __typename?: 'Mutation';
  completeFileMultipartUpload: {
    __typename?: 'StoredFileId';
    warehouseId: string;
    name: string;
  };
};

export type RemoveWarehouseFileMutationVariables = Exact<{
  id: StoredFileIdInput;
}>;

export type RemoveWarehouseFileMutation = {
  __typename?: 'Mutation';
  deleteFile: boolean;
};

export type WeightComplianceLocationItemFragment = {
  __typename?: 'WeightComplianceLocationItem';
  consignee: string;
  sku: string;
  weight?: number | null;
  quantity?: number | null;
  uoms?: Array<{
    __typename?: 'UomQuantity';
    uomQuantity: number;
    uom: { __typename?: 'UomWeight'; uom: string };
  }> | null;
};

export type BayWeightComplianceSummaryItemFragment = {
  __typename?: 'BayWeightComplianceSummaryItem';
  totalCount: number;
  compliantCount: number;
  nonCompliantCount: number;
  unassignedCount: number;
  unknownCount: number;
  floorLevelCount: number;
  planeId: string;
};

export type LocationWeightComplianceSummaryItemFragment = {
  __typename?: 'LocationWeightComplianceSummaryItem';
  totalCount: number;
  compliantCount: number;
  nonCompliantCount: number;
  unassignedCount: number;
  unknownCount: number;
  floorLevelCount: number;
  planeId: string;
  level: number;
};

export type WeightComplianceBayDetailsFragment = {
  __typename?: 'WeightComplianceBayDetails';
  status: WeightComplianceStatus;
  weight?: number | null;
  maxWeight?: number | null;
  bay: {
    __typename?: 'LayoutFeature';
    id: string;
    title?: string | null;
    details?:
      | { __typename?: 'LayoutAisleDetails' }
      | { __typename?: 'LayoutBayDetails'; bayType?: string | null }
      | null;
  };
  plane: { __typename?: 'LayoutPlane'; id: string; title?: string | null };
};

export type WeightComplianceLocationDataFragment = {
  __typename?: 'LayoutLocation';
  locationId: string;
  locationRackingType: string;
  warehouseArea: string;
  locationLevel: number;
  bay?: {
    __typename?: 'LayoutFeature';
    id: string;
    title?: string | null;
  } | null;
  plane?: {
    __typename?: 'LayoutPlane';
    id: string;
    title?: string | null;
  } | null;
};

export type WeightComplianceLocationDetailsFragment = {
  __typename?: 'WeightComplianceLocationDetails';
  status: WeightComplianceStatus;
  weight?: number | null;
  maxWeight?: number | null;
  volume?: number | null;
  location: {
    __typename?: 'LayoutLocation';
    locationId: string;
    locationRackingType: string;
    warehouseArea: string;
    locationLevel: number;
    bay?: {
      __typename?: 'LayoutFeature';
      id: string;
      title?: string | null;
    } | null;
    plane?: {
      __typename?: 'LayoutPlane';
      id: string;
      title?: string | null;
    } | null;
  };
  items: Array<{
    __typename?: 'WeightComplianceLocationItem';
    consignee: string;
    sku: string;
    weight?: number | null;
    quantity?: number | null;
    uoms?: Array<{
      __typename?: 'UomQuantity';
      uomQuantity: number;
      uom: { __typename?: 'UomWeight'; uom: string };
    }> | null;
  }>;
};

export type WeightComplianceMetaFragment = {
  __typename?: 'WeightCompliance';
  id: string;
  status: BatchJobStatus;
  locationSummary?: {
    __typename?: 'LocationWeightComplianceSummary';
    items: Array<{
      __typename?: 'LocationWeightComplianceSummaryItem';
      totalCount: number;
      compliantCount: number;
      nonCompliantCount: number;
      unassignedCount: number;
      unknownCount: number;
      floorLevelCount: number;
      planeId: string;
      level: number;
    }>;
  } | null;
  baySummary?: {
    __typename?: 'BayWeightComplianceSummary';
    items: Array<{
      __typename?: 'BayWeightComplianceSummaryItem';
      totalCount: number;
      compliantCount: number;
      nonCompliantCount: number;
      unassignedCount: number;
      unknownCount: number;
      floorLevelCount: number;
      planeId: string;
    }>;
  } | null;
};

export type LoadWeightComplianceForBaysQueryVariables = Exact<{
  simulationId: Scalars['ID']['input'];
  filter?: InputMaybe<WeightComplianceBayFilter>;
  sortBy?: InputMaybe<
    Array<WeightComplianceBaySortBy> | WeightComplianceBaySortBy
  >;
  page?: InputMaybe<SkipLimitPageSpec>;
}>;

export type LoadWeightComplianceForBaysQuery = {
  __typename?: 'Query';
  simulation?: {
    __typename?: 'Simulation';
    weightCompliance?: {
      __typename?: 'WeightCompliance';
      bays: {
        __typename?: 'WeightComplianceBaysConnection';
        totalCount: number;
        content: Array<{
          __typename?: 'WeightComplianceBayDetails';
          status: WeightComplianceStatus;
          weight?: number | null;
          maxWeight?: number | null;
          bay: {
            __typename?: 'LayoutFeature';
            id: string;
            title?: string | null;
            details?:
              | { __typename?: 'LayoutAisleDetails' }
              | { __typename?: 'LayoutBayDetails'; bayType?: string | null }
              | null;
          };
          plane: {
            __typename?: 'LayoutPlane';
            id: string;
            title?: string | null;
          };
        }>;
      };
    } | null;
  } | null;
};

export type LoadWeightComplianceForLocationsQueryVariables = Exact<{
  simulationId: Scalars['ID']['input'];
  filter?: InputMaybe<WeightComplianceLocationFilter>;
  sortBy?: InputMaybe<
    Array<WeightComplianceLocationSortBy> | WeightComplianceLocationSortBy
  >;
  page?: InputMaybe<SkipLimitPageSpec>;
}>;

export type LoadWeightComplianceForLocationsQuery = {
  __typename?: 'Query';
  simulation?: {
    __typename?: 'Simulation';
    weightCompliance?: {
      __typename?: 'WeightCompliance';
      locations: {
        __typename?: 'WeightComplianceLocationsConnection';
        totalCount: number;
        content: Array<{
          __typename?: 'WeightComplianceLocationDetails';
          status: WeightComplianceStatus;
          weight?: number | null;
          maxWeight?: number | null;
          volume?: number | null;
          location: {
            __typename?: 'LayoutLocation';
            locationId: string;
            locationRackingType: string;
            warehouseArea: string;
            locationLevel: number;
            bay?: {
              __typename?: 'LayoutFeature';
              id: string;
              title?: string | null;
            } | null;
            plane?: {
              __typename?: 'LayoutPlane';
              id: string;
              title?: string | null;
            } | null;
          };
          items: Array<{
            __typename?: 'WeightComplianceLocationItem';
            consignee: string;
            sku: string;
            weight?: number | null;
            quantity?: number | null;
            uoms?: Array<{
              __typename?: 'UomQuantity';
              uomQuantity: number;
              uom: { __typename?: 'UomWeight'; uom: string };
            }> | null;
          }>;
        }>;
      };
    } | null;
  } | null;
};

export type LoadWeightComplianceMetaQueryVariables = Exact<{
  simulationId: Scalars['ID']['input'];
}>;

export type LoadWeightComplianceMetaQuery = {
  __typename?: 'Query';
  simulation?: {
    __typename?: 'Simulation';
    weightCompliance?: {
      __typename?: 'WeightCompliance';
      id: string;
      status: BatchJobStatus;
      locationSummary?: {
        __typename?: 'LocationWeightComplianceSummary';
        items: Array<{
          __typename?: 'LocationWeightComplianceSummaryItem';
          totalCount: number;
          compliantCount: number;
          nonCompliantCount: number;
          unassignedCount: number;
          unknownCount: number;
          floorLevelCount: number;
          planeId: string;
          level: number;
        }>;
      } | null;
      baySummary?: {
        __typename?: 'BayWeightComplianceSummary';
        items: Array<{
          __typename?: 'BayWeightComplianceSummaryItem';
          totalCount: number;
          compliantCount: number;
          nonCompliantCount: number;
          unassignedCount: number;
          unknownCount: number;
          floorLevelCount: number;
          planeId: string;
        }>;
      } | null;
    } | null;
  } | null;
};

export type RunCalculateWeightComplianceMutationVariables = Exact<{
  simulationId: Scalars['String']['input'];
}>;

export type RunCalculateWeightComplianceMutation = {
  __typename?: 'Mutation';
  calculateSimulationWeightCompliance: {
    __typename?: 'WeightCompliance';
    id: string;
    status: BatchJobStatus;
    locationSummary?: {
      __typename?: 'LocationWeightComplianceSummary';
      items: Array<{
        __typename?: 'LocationWeightComplianceSummaryItem';
        totalCount: number;
        compliantCount: number;
        nonCompliantCount: number;
        unassignedCount: number;
        unknownCount: number;
        floorLevelCount: number;
        planeId: string;
        level: number;
      }>;
    } | null;
    baySummary?: {
      __typename?: 'BayWeightComplianceSummary';
      items: Array<{
        __typename?: 'BayWeightComplianceSummaryItem';
        totalCount: number;
        compliantCount: number;
        nonCompliantCount: number;
        unassignedCount: number;
        unknownCount: number;
        floorLevelCount: number;
        planeId: string;
      }>;
    } | null;
  };
};

export type ActualityRefSummaryFragment = {
  __typename?: 'Actuality';
  id: string;
  title: string;
  description?: string | null;
  createdAt: number;
  updatedAt: number;
};

export type ActualityMetaStatusFragment = {
  __typename?: 'Actuality';
  id: string;
  title: string;
  description?: string | null;
  createdAt: number;
  updatedAt: number;
};

export type ActualityMetaFragment = {
  __typename?: 'Actuality';
  layoutId?: string | null;
  activityFeedId?: string | null;
  assignmentId?: string | null;
  orderSetId?: string | null;
  itemSetId?: string | null;
  extraSettings?: any | null;
  id: string;
  title: string;
  description?: string | null;
  createdAt: number;
  updatedAt: number;
  routingPolicy?: {
    __typename?: 'RoutingPolicy';
    rules?: Array<{
      __typename?: 'RoutingPolicyAgentRule';
      id?: string | null;
      title?: string | null;
      agentIds: Array<string>;
      featureRules?: Array<{
        __typename?: 'RoutingPolicyFeatureRule';
        featureId: string;
        direction?: { __typename?: 'Vector'; x: number; y: number } | null;
        threshold?: {
          __typename?: 'RoutingPolicyDirectionThreshold';
          angle?: number | null;
          distance?: number | null;
        } | null;
      }> | null;
    }> | null;
    defaultRule?: {
      __typename?: 'RoutingPolicyRule';
      featureRules?: Array<{
        __typename?: 'RoutingPolicyFeatureRule';
        featureId: string;
        direction?: { __typename?: 'Vector'; x: number; y: number } | null;
        threshold?: {
          __typename?: 'RoutingPolicyDirectionThreshold';
          angle?: number | null;
          distance?: number | null;
        } | null;
      }> | null;
    } | null;
    threshold?: {
      __typename?: 'RoutingPolicyDirectionThreshold';
      angle?: number | null;
      distance?: number | null;
    } | null;
  } | null;
  stackingPolicy?: {
    __typename?: 'StackingPolicy';
    topRules?: Array<{
      __typename?: 'StackingPolicyRule';
      id: string;
      title?: string | null;
      itemsMatch?: {
        __typename?: 'ItemFilterUnion';
        anyOf: Array<{
          __typename?: 'ItemFilterIntersection';
          id?: string | null;
          allOf: Array<{
            __typename?: 'ItemFilter';
            type: ItemFilterType;
            isNot?: boolean | null;
            isNull?: boolean | null;
            valueIn?: Array<{
              __typename?: 'FilterValue';
              title: string;
            }> | null;
            range?: {
              __typename?: 'NumberFilterRange';
              from?: number | null;
              to?: number | null;
            } | null;
            stringRange?: {
              __typename?: 'StringFilterRange';
              from?: string | null;
              excludeFrom?: boolean | null;
              to?: string | null;
              excludeTo?: boolean | null;
            } | null;
          }>;
        }>;
      } | null;
      subcategories?: {
        __typename?: 'StackingPolicyRuleSubcategories';
        type?: StackingPolicyRuleSubcategoryType | null;
        step?: number | null;
        boundaries?: Array<number> | null;
      } | null;
    }> | null;
    bottomRules?: Array<{
      __typename?: 'StackingPolicyRule';
      id: string;
      title?: string | null;
      itemsMatch?: {
        __typename?: 'ItemFilterUnion';
        anyOf: Array<{
          __typename?: 'ItemFilterIntersection';
          id?: string | null;
          allOf: Array<{
            __typename?: 'ItemFilter';
            type: ItemFilterType;
            isNot?: boolean | null;
            isNull?: boolean | null;
            valueIn?: Array<{
              __typename?: 'FilterValue';
              title: string;
            }> | null;
            range?: {
              __typename?: 'NumberFilterRange';
              from?: number | null;
              to?: number | null;
            } | null;
            stringRange?: {
              __typename?: 'StringFilterRange';
              from?: string | null;
              excludeFrom?: boolean | null;
              to?: string | null;
              excludeTo?: boolean | null;
            } | null;
          }>;
        }>;
      } | null;
      subcategories?: {
        __typename?: 'StackingPolicyRuleSubcategories';
        type?: StackingPolicyRuleSubcategoryType | null;
        step?: number | null;
        boundaries?: Array<number> | null;
      } | null;
    }> | null;
    fallbackRule?: {
      __typename?: 'StackingPolicyFallbackRule';
      subcategories?: {
        __typename?: 'StackingPolicyRuleSubcategories';
        type?: StackingPolicyRuleSubcategoryType | null;
        step?: number | null;
        boundaries?: Array<number> | null;
      } | null;
    } | null;
  } | null;
  assignmentPolicy?: {
    __typename?: 'AssignmentPolicy';
    fallbackLocationsAllowAnyItems?: boolean | null;
    fallbackItemsAllowAnyLocations?: boolean | null;
    rules: Array<{
      __typename?: 'AssignmentPolicyRule';
      id: string;
      title?: string | null;
      type?: AssignmentPolicyRuleMatchType | null;
      productsMatch?: {
        __typename?: 'SimulationItemFilterUnion';
        anyOf: Array<{
          __typename?: 'SimulationItemFilterIntersection';
          id?: string | null;
          allOf: Array<{
            __typename?: 'SimulationItemFilter';
            type: SimulationItemFilterType;
            isNull?: boolean | null;
            isNot?: boolean | null;
            valueIn?: Array<{
              __typename?: 'FilterValue';
              title: string;
            }> | null;
            range?: {
              __typename?: 'NumberFilterRange';
              from?: number | null;
              to?: number | null;
            } | null;
            stringRange?: {
              __typename?: 'StringFilterRange';
              from?: string | null;
              excludeFrom?: boolean | null;
              to?: string | null;
              excludeTo?: boolean | null;
            } | null;
          }>;
        }>;
      } | null;
      locationsMatch?: {
        __typename?: 'LocationFilterUnion';
        anyOf: Array<{
          __typename?: 'LocationFilterIntersection';
          id?: string | null;
          allOf: Array<{
            __typename?: 'LocationFilter';
            type: LocationFilterType;
            isNull?: boolean | null;
            isNot?: boolean | null;
            valueIn?: Array<{
              __typename?: 'FilterValue';
              title: string;
            }> | null;
            range?: {
              __typename?: 'NumberFilterRange';
              from?: number | null;
              to?: number | null;
            } | null;
            stringRange?: {
              __typename?: 'StringFilterRange';
              from?: string | null;
              excludeFrom?: boolean | null;
              to?: string | null;
              excludeTo?: boolean | null;
            } | null;
          }>;
        }>;
      } | null;
    }>;
    fallbackLocationsMatch?: {
      __typename?: 'LocationFilterUnion';
      anyOf: Array<{
        __typename?: 'LocationFilterIntersection';
        id?: string | null;
        allOf: Array<{
          __typename?: 'LocationFilter';
          type: LocationFilterType;
          isNull?: boolean | null;
          isNot?: boolean | null;
          valueIn?: Array<{ __typename?: 'FilterValue'; title: string }> | null;
          range?: {
            __typename?: 'NumberFilterRange';
            from?: number | null;
            to?: number | null;
          } | null;
          stringRange?: {
            __typename?: 'StringFilterRange';
            from?: string | null;
            excludeFrom?: boolean | null;
            to?: string | null;
            excludeTo?: boolean | null;
          } | null;
        }>;
      }>;
    } | null;
  } | null;
  locationSharingPolicy?: {
    __typename?: 'LocationSharingPolicy';
    defaultMaxItemsPerLocation?: number | null;
    rules?: Array<{
      __typename?: 'LocationSharingPolicyRule';
      id: string;
      title?: string | null;
      maxItemsPerLocation: number;
      locationsMatch?: {
        __typename?: 'LocationFilterUnion';
        anyOf: Array<{
          __typename?: 'LocationFilterIntersection';
          id?: string | null;
          allOf: Array<{
            __typename?: 'LocationFilter';
            type: LocationFilterType;
            isNull?: boolean | null;
            isNot?: boolean | null;
            valueIn?: Array<{
              __typename?: 'FilterValue';
              title: string;
            }> | null;
            range?: {
              __typename?: 'NumberFilterRange';
              from?: number | null;
              to?: number | null;
            } | null;
            stringRange?: {
              __typename?: 'StringFilterRange';
              from?: string | null;
              excludeFrom?: boolean | null;
              to?: string | null;
              excludeTo?: boolean | null;
            } | null;
          }>;
        }>;
      } | null;
    }> | null;
  } | null;
  resourcePolicy?: {
    __typename?: 'ResourcePolicy';
    agents: Array<{
      __typename?: 'AgentSettings';
      id: string;
      title?: string | null;
      resourceTypeId: string;
      metadata?: any | null;
      roles?: Array<{
        __typename?: 'AgentRoleSettings';
        roleId: AgentRole;
        disabled?: boolean | null;
        preHandling?: {
          __typename?: 'PicklistProcessingEventSettings';
          timeFixed?: number | null;
          timePerOrder?: number | null;
          timePerPicklistLine?: number | null;
          timePerUom?: number | null;
        } | null;
        handlingPreparation?: {
          __typename?: 'PicklistLineProcessingEventSettings';
          timeFixed?: number | null;
          timePerUom?: number | null;
        } | null;
        handling?: {
          __typename?: 'HandlingEventSettings';
          timeFixed?: number | null;
          timePerWeight?: number | null;
          timePerVolume?: number | null;
          timePerUom?: Array<{
            __typename?: 'UomHandlingSettings';
            uom: string;
            timePerUom?: number | null;
          }> | null;
        } | null;
        reordering?: {
          __typename?: 'ReorderEventSettings';
          timeFixed?: number | null;
          timePerReorderedPosition?: number | null;
          timePerWeight?: number | null;
          timePerVolume?: number | null;
          timePerUom?: Array<{
            __typename?: 'UomHandlingSettings';
            uom: string;
            timePerUom?: number | null;
          }> | null;
        } | null;
        handlingCompletion?: {
          __typename?: 'PicklistLineProcessingEventSettings';
          timeFixed?: number | null;
          timePerUom?: number | null;
        } | null;
        postHandling?: {
          __typename?: 'PicklistProcessingEventSettings';
          timeFixed?: number | null;
          timePerOrder?: number | null;
          timePerPicklistLine?: number | null;
          timePerUom?: number | null;
        } | null;
      }> | null;
      specs?: {
        __typename?: 'AgentSpecs';
        horizontalTravelling?: {
          __typename?: 'HorizontalTravellingEventSettings';
          speed?: {
            __typename?: 'SpeedSettings';
            speedLaden?: number | null;
            speedUnladen?: number | null;
          } | null;
        } | null;
        verticalTravelling?: {
          __typename?: 'VerticalTravellingEventSettings';
          speedUp?: {
            __typename?: 'SpeedSettings';
            speedLaden?: number | null;
            speedUnladen?: number | null;
          } | null;
          speedDown?: {
            __typename?: 'SpeedSettings';
            speedLaden?: number | null;
            speedUnladen?: number | null;
          } | null;
        } | null;
        capacity?: {
          __typename?: 'AgentCapacitySettings';
          maxVolume?: number | null;
          maxWeight?: number | null;
          maxUomQuantity?: Array<{
            __typename?: 'AgentCapacityUomSettings';
            uom: string;
            quantity?: number | null;
          }> | null;
        } | null;
        powerSource?: {
          __typename?: 'AgentPowerSourceSettings';
          energyConsumptionPerSecond?: number | null;
        } | null;
      } | null;
      cost?: {
        __typename?: 'AgentCostSettings';
        costPerSecond?: number | null;
      } | null;
      utilisation?: {
        __typename?: 'AgentUtilisationSettings';
        agentAmount?: number | null;
        secondsOperation?: number | null;
      } | null;
    }>;
  } | null;
  waypointPolicy?: {
    __typename?: 'WaypointPolicy';
    rules?: Array<{
      __typename?: 'WaypointPolicyRule';
      id: string;
      title?: string | null;
      startPoints?: {
        __typename?: 'WaypointPolicyRuleTerminalPoints';
        locationsMatch?: {
          __typename?: 'LocationFilterUnion';
          anyOf: Array<{
            __typename?: 'LocationFilterIntersection';
            id?: string | null;
            allOf: Array<{
              __typename?: 'LocationFilter';
              type: LocationFilterType;
              isNull?: boolean | null;
              isNot?: boolean | null;
              valueIn?: Array<{
                __typename?: 'FilterValue';
                title: string;
              }> | null;
              range?: {
                __typename?: 'NumberFilterRange';
                from?: number | null;
                to?: number | null;
              } | null;
              stringRange?: {
                __typename?: 'StringFilterRange';
                from?: string | null;
                excludeFrom?: boolean | null;
                to?: string | null;
                excludeTo?: boolean | null;
              } | null;
            }>;
          }>;
        } | null;
      } | null;
      endPoints?: {
        __typename?: 'WaypointPolicyRuleTerminalPoints';
        locationsMatch?: {
          __typename?: 'LocationFilterUnion';
          anyOf: Array<{
            __typename?: 'LocationFilterIntersection';
            id?: string | null;
            allOf: Array<{
              __typename?: 'LocationFilter';
              type: LocationFilterType;
              isNull?: boolean | null;
              isNot?: boolean | null;
              valueIn?: Array<{
                __typename?: 'FilterValue';
                title: string;
              }> | null;
              range?: {
                __typename?: 'NumberFilterRange';
                from?: number | null;
                to?: number | null;
              } | null;
              stringRange?: {
                __typename?: 'StringFilterRange';
                from?: string | null;
                excludeFrom?: boolean | null;
                to?: string | null;
                excludeTo?: boolean | null;
              } | null;
            }>;
          }>;
        } | null;
      } | null;
    }> | null;
  } | null;
  pickingPolicy?: {
    __typename?: 'PickingPolicy';
    orderLineRules?: Array<{
      __typename?: 'PickingPolicyOrderLineRule';
      id: string;
      title?: string | null;
      usePickingContainer?: boolean | null;
      pickingRequirementsSettings?: {
        __typename?: 'PickingPolicyPickingRequirementsSettings';
        groupBy: PickingRequirementsGroupBy;
        startLocationIdSource?: PickingRequirementTerminalLocationSource | null;
        endLocationIdSource?: PickingRequirementTerminalLocationSource | null;
        wave?: {
          __typename?: 'PickingWaveSettings';
          useDeliveryRoute?: boolean | null;
          useDock?: boolean | null;
          useStagingArea?: boolean | null;
          useWaveId?: boolean | null;
          pickByDateSettings?: {
            __typename?: 'PickingWavePickByDateSettings';
            mode: PickingWavePickByDateMode;
            fixedDeadlines?: Array<any> | null;
          } | null;
        } | null;
        batch?: {
          __typename?: 'PickingRequirementsBatchSettings';
          maxOrderCount?: number | null;
          maxOrderLineCount?: number | null;
        } | null;
      } | null;
      orderLinesMatch?: {
        __typename?: 'OrderLineFilterUnion';
        anyOf: Array<{
          __typename?: 'OrderLineFilterIntersection';
          id?: string | null;
          allOf: Array<{
            __typename?: 'OrderLineFilter';
            type: OrderLineFilterType;
            isNull?: boolean | null;
            isNot?: boolean | null;
            valueIn?: Array<{
              __typename?: 'FilterValue';
              title: string;
            }> | null;
            range?: {
              __typename?: 'NumberFilterRange';
              from?: number | null;
              to?: number | null;
            } | null;
            stringRange?: {
              __typename?: 'StringFilterRange';
              from?: string | null;
              excludeFrom?: boolean | null;
              to?: string | null;
              excludeTo?: boolean | null;
            } | null;
          }>;
        }>;
      } | null;
      pickingRules?: Array<{
        __typename?: 'PickingPolicyPickingRule';
        id: string;
        title?: string | null;
        uomTypes?: Array<string> | null;
        agentId: string;
        locationSelectionMode?: PickingPolicyLocationSelectionMode | null;
        picklistGroupKey?: string | null;
        locationsMatch?: {
          __typename?: 'LocationFilterUnion';
          anyOf: Array<{
            __typename?: 'LocationFilterIntersection';
            id?: string | null;
            allOf: Array<{
              __typename?: 'LocationFilter';
              type: LocationFilterType;
              isNull?: boolean | null;
              isNot?: boolean | null;
              valueIn?: Array<{
                __typename?: 'FilterValue';
                title: string;
              }> | null;
              range?: {
                __typename?: 'NumberFilterRange';
                from?: number | null;
                to?: number | null;
              } | null;
              stringRange?: {
                __typename?: 'StringFilterRange';
                from?: string | null;
                excludeFrom?: boolean | null;
                to?: string | null;
                excludeTo?: boolean | null;
              } | null;
            }>;
          }>;
        } | null;
        waypointSettings?: {
          __typename?: 'PickingPolicyWaypointSettings';
          ruleId?: string | null;
        } | null;
      }> | null;
      picklistSettings?: {
        __typename?: 'PickingPolicyPicklistSettings';
        usePickingPriority?: boolean | null;
        picklistLinesOrder?: Array<{
          __typename?: 'PicklistLinesSortBy';
          type: PicklistLinesOrderOptions;
          direction: PicklistLinesSortDirection;
          nullsFirst?: boolean | null;
        }> | null;
      } | null;
    }> | null;
  } | null;
  analyzePipeline?: {
    __typename?: 'ProcessingPipeline';
    id: string;
    title?: string | null;
    enabled: boolean;
    jobs: {
      __typename?: 'ProcessingPipelineJobsConnection';
      content: Array<{
        __typename?: 'ProcessingPipelineJob';
        id: string;
        pipelineType: ProcessingPipelineType;
        status: ProcessingPipelineJobStatus;
        progress: number;
        createdAt: number;
        updatedAt: number;
        startedAt?: number | null;
        completedAt?: number | null;
        details?: any | null;
      }>;
    };
  } | null;
};

export type AnalyzedFeedPicklistFragment = {
  __typename?: 'AnalyzedPicklist';
  picklistId: string;
  totalCost: number;
  totalDuration: number;
  totalDistance: number;
  events: Array<{
    __typename?: 'AnalyzeResultEvent';
    sequenceId: number;
    eventType: PicklistEventType;
    duration: number;
    cost: number;
    agents: Array<string>;
    orderIds: Array<string>;
    picklistLine?: number | null;
    locationId?: string | null;
    startPosition: { __typename?: 'Point'; x: number; y: number };
    endPosition?: { __typename?: 'Point'; x: number; y: number } | null;
    details?:
      | {
          __typename: 'HandlingExecutionDetails';
          uom?: string | null;
          uomQuantity: number;
          weight?: number | null;
          volume?: number | null;
        }
      | {
          __typename: 'HandlingPalletReorderingDetails';
          reordered: Array<{
            __typename?: 'HandlingPalletReorderCategory';
            weight?: number | null;
            volume?: number | null;
            uoms: Array<{
              __typename?: 'UomHandlingDetails';
              uom?: string | null;
              uomQuantity: number;
            }>;
          }>;
        }
      | {
          __typename: 'PicklistLineProcessingEventDetails';
          uomQuantity: number;
        }
      | {
          __typename: 'PicklistProcessingEventDetails';
          orderCount: number;
          orderLineCount: number;
          totalUomQuantity: number;
        }
      | { __typename: 'SourceEventIgnoredDetails' }
      | {
          __typename: 'TravellingHorizontalDetails';
          distance: number;
          weight?: number | null;
          volume?: number | null;
          route: Array<{
            __typename?: 'PicklistEventWaypoint';
            id: string;
            type: WaypointType;
            position: { __typename?: 'Point'; x: number; y: number };
          }>;
        }
      | {
          __typename: 'TravellingVerticalDetails';
          distance: number;
          weight?: number | null;
          volume?: number | null;
        }
      | null;
  }>;
};

export type LoadActualitiesQueryVariables = Exact<{
  warehouseId: Scalars['ID']['input'];
}>;

export type LoadActualitiesQuery = {
  __typename?: 'Query';
  warehouse?: {
    __typename?: 'Warehouse';
    actualities: {
      __typename?: 'ActualitiesConnection';
      totalCount: number;
      content: Array<{
        __typename?: 'Actuality';
        id: string;
        title: string;
        description?: string | null;
        createdAt: number;
        updatedAt: number;
      }>;
    };
  } | null;
};

export type AnalyzeFeedPicklistMutationVariables = Exact<{
  input: AnalyzePicklistInput;
}>;

export type AnalyzeFeedPicklistMutation = {
  __typename?: 'Mutation';
  analyzePicklist: {
    __typename?: 'AnalyzePicklistResult';
    picklists: Array<{
      __typename?: 'AnalyzedPicklist';
      picklistId: string;
      totalCost: number;
      totalDuration: number;
      totalDistance: number;
      events: Array<{
        __typename?: 'AnalyzeResultEvent';
        sequenceId: number;
        eventType: PicklistEventType;
        duration: number;
        cost: number;
        agents: Array<string>;
        orderIds: Array<string>;
        picklistLine?: number | null;
        locationId?: string | null;
        startPosition: { __typename?: 'Point'; x: number; y: number };
        endPosition?: { __typename?: 'Point'; x: number; y: number } | null;
        details?:
          | {
              __typename: 'HandlingExecutionDetails';
              uom?: string | null;
              uomQuantity: number;
              weight?: number | null;
              volume?: number | null;
            }
          | {
              __typename: 'HandlingPalletReorderingDetails';
              reordered: Array<{
                __typename?: 'HandlingPalletReorderCategory';
                weight?: number | null;
                volume?: number | null;
                uoms: Array<{
                  __typename?: 'UomHandlingDetails';
                  uom?: string | null;
                  uomQuantity: number;
                }>;
              }>;
            }
          | {
              __typename: 'PicklistLineProcessingEventDetails';
              uomQuantity: number;
            }
          | {
              __typename: 'PicklistProcessingEventDetails';
              orderCount: number;
              orderLineCount: number;
              totalUomQuantity: number;
            }
          | { __typename: 'SourceEventIgnoredDetails' }
          | {
              __typename: 'TravellingHorizontalDetails';
              distance: number;
              weight?: number | null;
              volume?: number | null;
              route: Array<{
                __typename?: 'PicklistEventWaypoint';
                id: string;
                type: WaypointType;
                position: { __typename?: 'Point'; x: number; y: number };
              }>;
            }
          | {
              __typename: 'TravellingVerticalDetails';
              distance: number;
              weight?: number | null;
              volume?: number | null;
            }
          | null;
      }>;
    }>;
  };
};

export type LoadAnalyzeActualityStatusQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;

export type LoadAnalyzeActualityStatusQuery = {
  __typename?: 'Query';
  analyzeActualityStatus: {
    __typename?: 'AnalyzeActualityStatus';
    actualityId: string;
    status: BatchJobExecutionStatus;
  };
};

export type CreateActualityMutationVariables = Exact<{
  input: CreateActualityInput;
}>;

export type CreateActualityMutation = {
  __typename?: 'Mutation';
  createActuality: {
    __typename?: 'Actuality';
    layoutId?: string | null;
    activityFeedId?: string | null;
    assignmentId?: string | null;
    orderSetId?: string | null;
    itemSetId?: string | null;
    extraSettings?: any | null;
    id: string;
    title: string;
    description?: string | null;
    createdAt: number;
    updatedAt: number;
    routingPolicy?: {
      __typename?: 'RoutingPolicy';
      rules?: Array<{
        __typename?: 'RoutingPolicyAgentRule';
        id?: string | null;
        title?: string | null;
        agentIds: Array<string>;
        featureRules?: Array<{
          __typename?: 'RoutingPolicyFeatureRule';
          featureId: string;
          direction?: { __typename?: 'Vector'; x: number; y: number } | null;
          threshold?: {
            __typename?: 'RoutingPolicyDirectionThreshold';
            angle?: number | null;
            distance?: number | null;
          } | null;
        }> | null;
      }> | null;
      defaultRule?: {
        __typename?: 'RoutingPolicyRule';
        featureRules?: Array<{
          __typename?: 'RoutingPolicyFeatureRule';
          featureId: string;
          direction?: { __typename?: 'Vector'; x: number; y: number } | null;
          threshold?: {
            __typename?: 'RoutingPolicyDirectionThreshold';
            angle?: number | null;
            distance?: number | null;
          } | null;
        }> | null;
      } | null;
      threshold?: {
        __typename?: 'RoutingPolicyDirectionThreshold';
        angle?: number | null;
        distance?: number | null;
      } | null;
    } | null;
    stackingPolicy?: {
      __typename?: 'StackingPolicy';
      topRules?: Array<{
        __typename?: 'StackingPolicyRule';
        id: string;
        title?: string | null;
        itemsMatch?: {
          __typename?: 'ItemFilterUnion';
          anyOf: Array<{
            __typename?: 'ItemFilterIntersection';
            id?: string | null;
            allOf: Array<{
              __typename?: 'ItemFilter';
              type: ItemFilterType;
              isNot?: boolean | null;
              isNull?: boolean | null;
              valueIn?: Array<{
                __typename?: 'FilterValue';
                title: string;
              }> | null;
              range?: {
                __typename?: 'NumberFilterRange';
                from?: number | null;
                to?: number | null;
              } | null;
              stringRange?: {
                __typename?: 'StringFilterRange';
                from?: string | null;
                excludeFrom?: boolean | null;
                to?: string | null;
                excludeTo?: boolean | null;
              } | null;
            }>;
          }>;
        } | null;
        subcategories?: {
          __typename?: 'StackingPolicyRuleSubcategories';
          type?: StackingPolicyRuleSubcategoryType | null;
          step?: number | null;
          boundaries?: Array<number> | null;
        } | null;
      }> | null;
      bottomRules?: Array<{
        __typename?: 'StackingPolicyRule';
        id: string;
        title?: string | null;
        itemsMatch?: {
          __typename?: 'ItemFilterUnion';
          anyOf: Array<{
            __typename?: 'ItemFilterIntersection';
            id?: string | null;
            allOf: Array<{
              __typename?: 'ItemFilter';
              type: ItemFilterType;
              isNot?: boolean | null;
              isNull?: boolean | null;
              valueIn?: Array<{
                __typename?: 'FilterValue';
                title: string;
              }> | null;
              range?: {
                __typename?: 'NumberFilterRange';
                from?: number | null;
                to?: number | null;
              } | null;
              stringRange?: {
                __typename?: 'StringFilterRange';
                from?: string | null;
                excludeFrom?: boolean | null;
                to?: string | null;
                excludeTo?: boolean | null;
              } | null;
            }>;
          }>;
        } | null;
        subcategories?: {
          __typename?: 'StackingPolicyRuleSubcategories';
          type?: StackingPolicyRuleSubcategoryType | null;
          step?: number | null;
          boundaries?: Array<number> | null;
        } | null;
      }> | null;
      fallbackRule?: {
        __typename?: 'StackingPolicyFallbackRule';
        subcategories?: {
          __typename?: 'StackingPolicyRuleSubcategories';
          type?: StackingPolicyRuleSubcategoryType | null;
          step?: number | null;
          boundaries?: Array<number> | null;
        } | null;
      } | null;
    } | null;
    assignmentPolicy?: {
      __typename?: 'AssignmentPolicy';
      fallbackLocationsAllowAnyItems?: boolean | null;
      fallbackItemsAllowAnyLocations?: boolean | null;
      rules: Array<{
        __typename?: 'AssignmentPolicyRule';
        id: string;
        title?: string | null;
        type?: AssignmentPolicyRuleMatchType | null;
        productsMatch?: {
          __typename?: 'SimulationItemFilterUnion';
          anyOf: Array<{
            __typename?: 'SimulationItemFilterIntersection';
            id?: string | null;
            allOf: Array<{
              __typename?: 'SimulationItemFilter';
              type: SimulationItemFilterType;
              isNull?: boolean | null;
              isNot?: boolean | null;
              valueIn?: Array<{
                __typename?: 'FilterValue';
                title: string;
              }> | null;
              range?: {
                __typename?: 'NumberFilterRange';
                from?: number | null;
                to?: number | null;
              } | null;
              stringRange?: {
                __typename?: 'StringFilterRange';
                from?: string | null;
                excludeFrom?: boolean | null;
                to?: string | null;
                excludeTo?: boolean | null;
              } | null;
            }>;
          }>;
        } | null;
        locationsMatch?: {
          __typename?: 'LocationFilterUnion';
          anyOf: Array<{
            __typename?: 'LocationFilterIntersection';
            id?: string | null;
            allOf: Array<{
              __typename?: 'LocationFilter';
              type: LocationFilterType;
              isNull?: boolean | null;
              isNot?: boolean | null;
              valueIn?: Array<{
                __typename?: 'FilterValue';
                title: string;
              }> | null;
              range?: {
                __typename?: 'NumberFilterRange';
                from?: number | null;
                to?: number | null;
              } | null;
              stringRange?: {
                __typename?: 'StringFilterRange';
                from?: string | null;
                excludeFrom?: boolean | null;
                to?: string | null;
                excludeTo?: boolean | null;
              } | null;
            }>;
          }>;
        } | null;
      }>;
      fallbackLocationsMatch?: {
        __typename?: 'LocationFilterUnion';
        anyOf: Array<{
          __typename?: 'LocationFilterIntersection';
          id?: string | null;
          allOf: Array<{
            __typename?: 'LocationFilter';
            type: LocationFilterType;
            isNull?: boolean | null;
            isNot?: boolean | null;
            valueIn?: Array<{
              __typename?: 'FilterValue';
              title: string;
            }> | null;
            range?: {
              __typename?: 'NumberFilterRange';
              from?: number | null;
              to?: number | null;
            } | null;
            stringRange?: {
              __typename?: 'StringFilterRange';
              from?: string | null;
              excludeFrom?: boolean | null;
              to?: string | null;
              excludeTo?: boolean | null;
            } | null;
          }>;
        }>;
      } | null;
    } | null;
    locationSharingPolicy?: {
      __typename?: 'LocationSharingPolicy';
      defaultMaxItemsPerLocation?: number | null;
      rules?: Array<{
        __typename?: 'LocationSharingPolicyRule';
        id: string;
        title?: string | null;
        maxItemsPerLocation: number;
        locationsMatch?: {
          __typename?: 'LocationFilterUnion';
          anyOf: Array<{
            __typename?: 'LocationFilterIntersection';
            id?: string | null;
            allOf: Array<{
              __typename?: 'LocationFilter';
              type: LocationFilterType;
              isNull?: boolean | null;
              isNot?: boolean | null;
              valueIn?: Array<{
                __typename?: 'FilterValue';
                title: string;
              }> | null;
              range?: {
                __typename?: 'NumberFilterRange';
                from?: number | null;
                to?: number | null;
              } | null;
              stringRange?: {
                __typename?: 'StringFilterRange';
                from?: string | null;
                excludeFrom?: boolean | null;
                to?: string | null;
                excludeTo?: boolean | null;
              } | null;
            }>;
          }>;
        } | null;
      }> | null;
    } | null;
    resourcePolicy?: {
      __typename?: 'ResourcePolicy';
      agents: Array<{
        __typename?: 'AgentSettings';
        id: string;
        title?: string | null;
        resourceTypeId: string;
        metadata?: any | null;
        roles?: Array<{
          __typename?: 'AgentRoleSettings';
          roleId: AgentRole;
          disabled?: boolean | null;
          preHandling?: {
            __typename?: 'PicklistProcessingEventSettings';
            timeFixed?: number | null;
            timePerOrder?: number | null;
            timePerPicklistLine?: number | null;
            timePerUom?: number | null;
          } | null;
          handlingPreparation?: {
            __typename?: 'PicklistLineProcessingEventSettings';
            timeFixed?: number | null;
            timePerUom?: number | null;
          } | null;
          handling?: {
            __typename?: 'HandlingEventSettings';
            timeFixed?: number | null;
            timePerWeight?: number | null;
            timePerVolume?: number | null;
            timePerUom?: Array<{
              __typename?: 'UomHandlingSettings';
              uom: string;
              timePerUom?: number | null;
            }> | null;
          } | null;
          reordering?: {
            __typename?: 'ReorderEventSettings';
            timeFixed?: number | null;
            timePerReorderedPosition?: number | null;
            timePerWeight?: number | null;
            timePerVolume?: number | null;
            timePerUom?: Array<{
              __typename?: 'UomHandlingSettings';
              uom: string;
              timePerUom?: number | null;
            }> | null;
          } | null;
          handlingCompletion?: {
            __typename?: 'PicklistLineProcessingEventSettings';
            timeFixed?: number | null;
            timePerUom?: number | null;
          } | null;
          postHandling?: {
            __typename?: 'PicklistProcessingEventSettings';
            timeFixed?: number | null;
            timePerOrder?: number | null;
            timePerPicklistLine?: number | null;
            timePerUom?: number | null;
          } | null;
        }> | null;
        specs?: {
          __typename?: 'AgentSpecs';
          horizontalTravelling?: {
            __typename?: 'HorizontalTravellingEventSettings';
            speed?: {
              __typename?: 'SpeedSettings';
              speedLaden?: number | null;
              speedUnladen?: number | null;
            } | null;
          } | null;
          verticalTravelling?: {
            __typename?: 'VerticalTravellingEventSettings';
            speedUp?: {
              __typename?: 'SpeedSettings';
              speedLaden?: number | null;
              speedUnladen?: number | null;
            } | null;
            speedDown?: {
              __typename?: 'SpeedSettings';
              speedLaden?: number | null;
              speedUnladen?: number | null;
            } | null;
          } | null;
          capacity?: {
            __typename?: 'AgentCapacitySettings';
            maxVolume?: number | null;
            maxWeight?: number | null;
            maxUomQuantity?: Array<{
              __typename?: 'AgentCapacityUomSettings';
              uom: string;
              quantity?: number | null;
            }> | null;
          } | null;
          powerSource?: {
            __typename?: 'AgentPowerSourceSettings';
            energyConsumptionPerSecond?: number | null;
          } | null;
        } | null;
        cost?: {
          __typename?: 'AgentCostSettings';
          costPerSecond?: number | null;
        } | null;
        utilisation?: {
          __typename?: 'AgentUtilisationSettings';
          agentAmount?: number | null;
          secondsOperation?: number | null;
        } | null;
      }>;
    } | null;
    waypointPolicy?: {
      __typename?: 'WaypointPolicy';
      rules?: Array<{
        __typename?: 'WaypointPolicyRule';
        id: string;
        title?: string | null;
        startPoints?: {
          __typename?: 'WaypointPolicyRuleTerminalPoints';
          locationsMatch?: {
            __typename?: 'LocationFilterUnion';
            anyOf: Array<{
              __typename?: 'LocationFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'LocationFilter';
                type: LocationFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
        } | null;
        endPoints?: {
          __typename?: 'WaypointPolicyRuleTerminalPoints';
          locationsMatch?: {
            __typename?: 'LocationFilterUnion';
            anyOf: Array<{
              __typename?: 'LocationFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'LocationFilter';
                type: LocationFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
        } | null;
      }> | null;
    } | null;
    pickingPolicy?: {
      __typename?: 'PickingPolicy';
      orderLineRules?: Array<{
        __typename?: 'PickingPolicyOrderLineRule';
        id: string;
        title?: string | null;
        usePickingContainer?: boolean | null;
        pickingRequirementsSettings?: {
          __typename?: 'PickingPolicyPickingRequirementsSettings';
          groupBy: PickingRequirementsGroupBy;
          startLocationIdSource?: PickingRequirementTerminalLocationSource | null;
          endLocationIdSource?: PickingRequirementTerminalLocationSource | null;
          wave?: {
            __typename?: 'PickingWaveSettings';
            useDeliveryRoute?: boolean | null;
            useDock?: boolean | null;
            useStagingArea?: boolean | null;
            useWaveId?: boolean | null;
            pickByDateSettings?: {
              __typename?: 'PickingWavePickByDateSettings';
              mode: PickingWavePickByDateMode;
              fixedDeadlines?: Array<any> | null;
            } | null;
          } | null;
          batch?: {
            __typename?: 'PickingRequirementsBatchSettings';
            maxOrderCount?: number | null;
            maxOrderLineCount?: number | null;
          } | null;
        } | null;
        orderLinesMatch?: {
          __typename?: 'OrderLineFilterUnion';
          anyOf: Array<{
            __typename?: 'OrderLineFilterIntersection';
            id?: string | null;
            allOf: Array<{
              __typename?: 'OrderLineFilter';
              type: OrderLineFilterType;
              isNull?: boolean | null;
              isNot?: boolean | null;
              valueIn?: Array<{
                __typename?: 'FilterValue';
                title: string;
              }> | null;
              range?: {
                __typename?: 'NumberFilterRange';
                from?: number | null;
                to?: number | null;
              } | null;
              stringRange?: {
                __typename?: 'StringFilterRange';
                from?: string | null;
                excludeFrom?: boolean | null;
                to?: string | null;
                excludeTo?: boolean | null;
              } | null;
            }>;
          }>;
        } | null;
        pickingRules?: Array<{
          __typename?: 'PickingPolicyPickingRule';
          id: string;
          title?: string | null;
          uomTypes?: Array<string> | null;
          agentId: string;
          locationSelectionMode?: PickingPolicyLocationSelectionMode | null;
          picklistGroupKey?: string | null;
          locationsMatch?: {
            __typename?: 'LocationFilterUnion';
            anyOf: Array<{
              __typename?: 'LocationFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'LocationFilter';
                type: LocationFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
          waypointSettings?: {
            __typename?: 'PickingPolicyWaypointSettings';
            ruleId?: string | null;
          } | null;
        }> | null;
        picklistSettings?: {
          __typename?: 'PickingPolicyPicklistSettings';
          usePickingPriority?: boolean | null;
          picklistLinesOrder?: Array<{
            __typename?: 'PicklistLinesSortBy';
            type: PicklistLinesOrderOptions;
            direction: PicklistLinesSortDirection;
            nullsFirst?: boolean | null;
          }> | null;
        } | null;
      }> | null;
    } | null;
    analyzePipeline?: {
      __typename?: 'ProcessingPipeline';
      id: string;
      title?: string | null;
      enabled: boolean;
      jobs: {
        __typename?: 'ProcessingPipelineJobsConnection';
        content: Array<{
          __typename?: 'ProcessingPipelineJob';
          id: string;
          pipelineType: ProcessingPipelineType;
          status: ProcessingPipelineJobStatus;
          progress: number;
          createdAt: number;
          updatedAt: number;
          startedAt?: number | null;
          completedAt?: number | null;
          details?: any | null;
        }>;
      };
    } | null;
  };
};

export type LoadActualityQueryVariables = Exact<{
  actualityId: Scalars['ID']['input'];
}>;

export type LoadActualityQuery = {
  __typename?: 'Query';
  actuality?: {
    __typename?: 'Actuality';
    layoutId?: string | null;
    activityFeedId?: string | null;
    assignmentId?: string | null;
    orderSetId?: string | null;
    itemSetId?: string | null;
    extraSettings?: any | null;
    id: string;
    title: string;
    description?: string | null;
    createdAt: number;
    updatedAt: number;
    routingPolicy?: {
      __typename?: 'RoutingPolicy';
      rules?: Array<{
        __typename?: 'RoutingPolicyAgentRule';
        id?: string | null;
        title?: string | null;
        agentIds: Array<string>;
        featureRules?: Array<{
          __typename?: 'RoutingPolicyFeatureRule';
          featureId: string;
          direction?: { __typename?: 'Vector'; x: number; y: number } | null;
          threshold?: {
            __typename?: 'RoutingPolicyDirectionThreshold';
            angle?: number | null;
            distance?: number | null;
          } | null;
        }> | null;
      }> | null;
      defaultRule?: {
        __typename?: 'RoutingPolicyRule';
        featureRules?: Array<{
          __typename?: 'RoutingPolicyFeatureRule';
          featureId: string;
          direction?: { __typename?: 'Vector'; x: number; y: number } | null;
          threshold?: {
            __typename?: 'RoutingPolicyDirectionThreshold';
            angle?: number | null;
            distance?: number | null;
          } | null;
        }> | null;
      } | null;
      threshold?: {
        __typename?: 'RoutingPolicyDirectionThreshold';
        angle?: number | null;
        distance?: number | null;
      } | null;
    } | null;
    stackingPolicy?: {
      __typename?: 'StackingPolicy';
      topRules?: Array<{
        __typename?: 'StackingPolicyRule';
        id: string;
        title?: string | null;
        itemsMatch?: {
          __typename?: 'ItemFilterUnion';
          anyOf: Array<{
            __typename?: 'ItemFilterIntersection';
            id?: string | null;
            allOf: Array<{
              __typename?: 'ItemFilter';
              type: ItemFilterType;
              isNot?: boolean | null;
              isNull?: boolean | null;
              valueIn?: Array<{
                __typename?: 'FilterValue';
                title: string;
              }> | null;
              range?: {
                __typename?: 'NumberFilterRange';
                from?: number | null;
                to?: number | null;
              } | null;
              stringRange?: {
                __typename?: 'StringFilterRange';
                from?: string | null;
                excludeFrom?: boolean | null;
                to?: string | null;
                excludeTo?: boolean | null;
              } | null;
            }>;
          }>;
        } | null;
        subcategories?: {
          __typename?: 'StackingPolicyRuleSubcategories';
          type?: StackingPolicyRuleSubcategoryType | null;
          step?: number | null;
          boundaries?: Array<number> | null;
        } | null;
      }> | null;
      bottomRules?: Array<{
        __typename?: 'StackingPolicyRule';
        id: string;
        title?: string | null;
        itemsMatch?: {
          __typename?: 'ItemFilterUnion';
          anyOf: Array<{
            __typename?: 'ItemFilterIntersection';
            id?: string | null;
            allOf: Array<{
              __typename?: 'ItemFilter';
              type: ItemFilterType;
              isNot?: boolean | null;
              isNull?: boolean | null;
              valueIn?: Array<{
                __typename?: 'FilterValue';
                title: string;
              }> | null;
              range?: {
                __typename?: 'NumberFilterRange';
                from?: number | null;
                to?: number | null;
              } | null;
              stringRange?: {
                __typename?: 'StringFilterRange';
                from?: string | null;
                excludeFrom?: boolean | null;
                to?: string | null;
                excludeTo?: boolean | null;
              } | null;
            }>;
          }>;
        } | null;
        subcategories?: {
          __typename?: 'StackingPolicyRuleSubcategories';
          type?: StackingPolicyRuleSubcategoryType | null;
          step?: number | null;
          boundaries?: Array<number> | null;
        } | null;
      }> | null;
      fallbackRule?: {
        __typename?: 'StackingPolicyFallbackRule';
        subcategories?: {
          __typename?: 'StackingPolicyRuleSubcategories';
          type?: StackingPolicyRuleSubcategoryType | null;
          step?: number | null;
          boundaries?: Array<number> | null;
        } | null;
      } | null;
    } | null;
    assignmentPolicy?: {
      __typename?: 'AssignmentPolicy';
      fallbackLocationsAllowAnyItems?: boolean | null;
      fallbackItemsAllowAnyLocations?: boolean | null;
      rules: Array<{
        __typename?: 'AssignmentPolicyRule';
        id: string;
        title?: string | null;
        type?: AssignmentPolicyRuleMatchType | null;
        productsMatch?: {
          __typename?: 'SimulationItemFilterUnion';
          anyOf: Array<{
            __typename?: 'SimulationItemFilterIntersection';
            id?: string | null;
            allOf: Array<{
              __typename?: 'SimulationItemFilter';
              type: SimulationItemFilterType;
              isNull?: boolean | null;
              isNot?: boolean | null;
              valueIn?: Array<{
                __typename?: 'FilterValue';
                title: string;
              }> | null;
              range?: {
                __typename?: 'NumberFilterRange';
                from?: number | null;
                to?: number | null;
              } | null;
              stringRange?: {
                __typename?: 'StringFilterRange';
                from?: string | null;
                excludeFrom?: boolean | null;
                to?: string | null;
                excludeTo?: boolean | null;
              } | null;
            }>;
          }>;
        } | null;
        locationsMatch?: {
          __typename?: 'LocationFilterUnion';
          anyOf: Array<{
            __typename?: 'LocationFilterIntersection';
            id?: string | null;
            allOf: Array<{
              __typename?: 'LocationFilter';
              type: LocationFilterType;
              isNull?: boolean | null;
              isNot?: boolean | null;
              valueIn?: Array<{
                __typename?: 'FilterValue';
                title: string;
              }> | null;
              range?: {
                __typename?: 'NumberFilterRange';
                from?: number | null;
                to?: number | null;
              } | null;
              stringRange?: {
                __typename?: 'StringFilterRange';
                from?: string | null;
                excludeFrom?: boolean | null;
                to?: string | null;
                excludeTo?: boolean | null;
              } | null;
            }>;
          }>;
        } | null;
      }>;
      fallbackLocationsMatch?: {
        __typename?: 'LocationFilterUnion';
        anyOf: Array<{
          __typename?: 'LocationFilterIntersection';
          id?: string | null;
          allOf: Array<{
            __typename?: 'LocationFilter';
            type: LocationFilterType;
            isNull?: boolean | null;
            isNot?: boolean | null;
            valueIn?: Array<{
              __typename?: 'FilterValue';
              title: string;
            }> | null;
            range?: {
              __typename?: 'NumberFilterRange';
              from?: number | null;
              to?: number | null;
            } | null;
            stringRange?: {
              __typename?: 'StringFilterRange';
              from?: string | null;
              excludeFrom?: boolean | null;
              to?: string | null;
              excludeTo?: boolean | null;
            } | null;
          }>;
        }>;
      } | null;
    } | null;
    locationSharingPolicy?: {
      __typename?: 'LocationSharingPolicy';
      defaultMaxItemsPerLocation?: number | null;
      rules?: Array<{
        __typename?: 'LocationSharingPolicyRule';
        id: string;
        title?: string | null;
        maxItemsPerLocation: number;
        locationsMatch?: {
          __typename?: 'LocationFilterUnion';
          anyOf: Array<{
            __typename?: 'LocationFilterIntersection';
            id?: string | null;
            allOf: Array<{
              __typename?: 'LocationFilter';
              type: LocationFilterType;
              isNull?: boolean | null;
              isNot?: boolean | null;
              valueIn?: Array<{
                __typename?: 'FilterValue';
                title: string;
              }> | null;
              range?: {
                __typename?: 'NumberFilterRange';
                from?: number | null;
                to?: number | null;
              } | null;
              stringRange?: {
                __typename?: 'StringFilterRange';
                from?: string | null;
                excludeFrom?: boolean | null;
                to?: string | null;
                excludeTo?: boolean | null;
              } | null;
            }>;
          }>;
        } | null;
      }> | null;
    } | null;
    resourcePolicy?: {
      __typename?: 'ResourcePolicy';
      agents: Array<{
        __typename?: 'AgentSettings';
        id: string;
        title?: string | null;
        resourceTypeId: string;
        metadata?: any | null;
        roles?: Array<{
          __typename?: 'AgentRoleSettings';
          roleId: AgentRole;
          disabled?: boolean | null;
          preHandling?: {
            __typename?: 'PicklistProcessingEventSettings';
            timeFixed?: number | null;
            timePerOrder?: number | null;
            timePerPicklistLine?: number | null;
            timePerUom?: number | null;
          } | null;
          handlingPreparation?: {
            __typename?: 'PicklistLineProcessingEventSettings';
            timeFixed?: number | null;
            timePerUom?: number | null;
          } | null;
          handling?: {
            __typename?: 'HandlingEventSettings';
            timeFixed?: number | null;
            timePerWeight?: number | null;
            timePerVolume?: number | null;
            timePerUom?: Array<{
              __typename?: 'UomHandlingSettings';
              uom: string;
              timePerUom?: number | null;
            }> | null;
          } | null;
          reordering?: {
            __typename?: 'ReorderEventSettings';
            timeFixed?: number | null;
            timePerReorderedPosition?: number | null;
            timePerWeight?: number | null;
            timePerVolume?: number | null;
            timePerUom?: Array<{
              __typename?: 'UomHandlingSettings';
              uom: string;
              timePerUom?: number | null;
            }> | null;
          } | null;
          handlingCompletion?: {
            __typename?: 'PicklistLineProcessingEventSettings';
            timeFixed?: number | null;
            timePerUom?: number | null;
          } | null;
          postHandling?: {
            __typename?: 'PicklistProcessingEventSettings';
            timeFixed?: number | null;
            timePerOrder?: number | null;
            timePerPicklistLine?: number | null;
            timePerUom?: number | null;
          } | null;
        }> | null;
        specs?: {
          __typename?: 'AgentSpecs';
          horizontalTravelling?: {
            __typename?: 'HorizontalTravellingEventSettings';
            speed?: {
              __typename?: 'SpeedSettings';
              speedLaden?: number | null;
              speedUnladen?: number | null;
            } | null;
          } | null;
          verticalTravelling?: {
            __typename?: 'VerticalTravellingEventSettings';
            speedUp?: {
              __typename?: 'SpeedSettings';
              speedLaden?: number | null;
              speedUnladen?: number | null;
            } | null;
            speedDown?: {
              __typename?: 'SpeedSettings';
              speedLaden?: number | null;
              speedUnladen?: number | null;
            } | null;
          } | null;
          capacity?: {
            __typename?: 'AgentCapacitySettings';
            maxVolume?: number | null;
            maxWeight?: number | null;
            maxUomQuantity?: Array<{
              __typename?: 'AgentCapacityUomSettings';
              uom: string;
              quantity?: number | null;
            }> | null;
          } | null;
          powerSource?: {
            __typename?: 'AgentPowerSourceSettings';
            energyConsumptionPerSecond?: number | null;
          } | null;
        } | null;
        cost?: {
          __typename?: 'AgentCostSettings';
          costPerSecond?: number | null;
        } | null;
        utilisation?: {
          __typename?: 'AgentUtilisationSettings';
          agentAmount?: number | null;
          secondsOperation?: number | null;
        } | null;
      }>;
    } | null;
    waypointPolicy?: {
      __typename?: 'WaypointPolicy';
      rules?: Array<{
        __typename?: 'WaypointPolicyRule';
        id: string;
        title?: string | null;
        startPoints?: {
          __typename?: 'WaypointPolicyRuleTerminalPoints';
          locationsMatch?: {
            __typename?: 'LocationFilterUnion';
            anyOf: Array<{
              __typename?: 'LocationFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'LocationFilter';
                type: LocationFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
        } | null;
        endPoints?: {
          __typename?: 'WaypointPolicyRuleTerminalPoints';
          locationsMatch?: {
            __typename?: 'LocationFilterUnion';
            anyOf: Array<{
              __typename?: 'LocationFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'LocationFilter';
                type: LocationFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
        } | null;
      }> | null;
    } | null;
    pickingPolicy?: {
      __typename?: 'PickingPolicy';
      orderLineRules?: Array<{
        __typename?: 'PickingPolicyOrderLineRule';
        id: string;
        title?: string | null;
        usePickingContainer?: boolean | null;
        pickingRequirementsSettings?: {
          __typename?: 'PickingPolicyPickingRequirementsSettings';
          groupBy: PickingRequirementsGroupBy;
          startLocationIdSource?: PickingRequirementTerminalLocationSource | null;
          endLocationIdSource?: PickingRequirementTerminalLocationSource | null;
          wave?: {
            __typename?: 'PickingWaveSettings';
            useDeliveryRoute?: boolean | null;
            useDock?: boolean | null;
            useStagingArea?: boolean | null;
            useWaveId?: boolean | null;
            pickByDateSettings?: {
              __typename?: 'PickingWavePickByDateSettings';
              mode: PickingWavePickByDateMode;
              fixedDeadlines?: Array<any> | null;
            } | null;
          } | null;
          batch?: {
            __typename?: 'PickingRequirementsBatchSettings';
            maxOrderCount?: number | null;
            maxOrderLineCount?: number | null;
          } | null;
        } | null;
        orderLinesMatch?: {
          __typename?: 'OrderLineFilterUnion';
          anyOf: Array<{
            __typename?: 'OrderLineFilterIntersection';
            id?: string | null;
            allOf: Array<{
              __typename?: 'OrderLineFilter';
              type: OrderLineFilterType;
              isNull?: boolean | null;
              isNot?: boolean | null;
              valueIn?: Array<{
                __typename?: 'FilterValue';
                title: string;
              }> | null;
              range?: {
                __typename?: 'NumberFilterRange';
                from?: number | null;
                to?: number | null;
              } | null;
              stringRange?: {
                __typename?: 'StringFilterRange';
                from?: string | null;
                excludeFrom?: boolean | null;
                to?: string | null;
                excludeTo?: boolean | null;
              } | null;
            }>;
          }>;
        } | null;
        pickingRules?: Array<{
          __typename?: 'PickingPolicyPickingRule';
          id: string;
          title?: string | null;
          uomTypes?: Array<string> | null;
          agentId: string;
          locationSelectionMode?: PickingPolicyLocationSelectionMode | null;
          picklistGroupKey?: string | null;
          locationsMatch?: {
            __typename?: 'LocationFilterUnion';
            anyOf: Array<{
              __typename?: 'LocationFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'LocationFilter';
                type: LocationFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
          waypointSettings?: {
            __typename?: 'PickingPolicyWaypointSettings';
            ruleId?: string | null;
          } | null;
        }> | null;
        picklistSettings?: {
          __typename?: 'PickingPolicyPicklistSettings';
          usePickingPriority?: boolean | null;
          picklistLinesOrder?: Array<{
            __typename?: 'PicklistLinesSortBy';
            type: PicklistLinesOrderOptions;
            direction: PicklistLinesSortDirection;
            nullsFirst?: boolean | null;
          }> | null;
        } | null;
      }> | null;
    } | null;
    analyzePipeline?: {
      __typename?: 'ProcessingPipeline';
      id: string;
      title?: string | null;
      enabled: boolean;
      jobs: {
        __typename?: 'ProcessingPipelineJobsConnection';
        content: Array<{
          __typename?: 'ProcessingPipelineJob';
          id: string;
          pipelineType: ProcessingPipelineType;
          status: ProcessingPipelineJobStatus;
          progress: number;
          createdAt: number;
          updatedAt: number;
          startedAt?: number | null;
          completedAt?: number | null;
          details?: any | null;
        }>;
      };
    } | null;
  } | null;
};

export type LoadActualityAnalyzeStatusQueryVariables = Exact<{
  actualityId: Scalars['ID']['input'];
}>;

export type LoadActualityAnalyzeStatusQuery = {
  __typename?: 'Query';
  actuality?: {
    __typename?: 'Actuality';
    analyzePipeline?: {
      __typename?: 'ProcessingPipeline';
      id: string;
      title?: string | null;
      enabled: boolean;
      jobs: {
        __typename?: 'ProcessingPipelineJobsConnection';
        content: Array<{
          __typename?: 'ProcessingPipelineJob';
          id: string;
          pipelineType: ProcessingPipelineType;
          status: ProcessingPipelineJobStatus;
          progress: number;
          createdAt: number;
          updatedAt: number;
          startedAt?: number | null;
          completedAt?: number | null;
          details?: any | null;
        }>;
      };
    } | null;
  } | null;
};

export type UpdateActualityMutationVariables = Exact<{
  input: UpdateActualityInput;
}>;

export type UpdateActualityMutation = {
  __typename?: 'Mutation';
  updateActuality: {
    __typename?: 'Actuality';
    id: string;
    title: string;
    description?: string | null;
    createdAt: number;
    updatedAt: number;
  };
};

export type RemoveActualityMutationVariables = Exact<{
  actualityId: Scalars['String']['input'];
}>;

export type RemoveActualityMutation = {
  __typename?: 'Mutation';
  removeActuality: boolean;
};

export type DuplicateActualityMutationVariables = Exact<{
  actualityId: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
}>;

export type DuplicateActualityMutation = {
  __typename?: 'Mutation';
  duplicateActuality: { __typename?: 'Actuality'; id: string };
};

export type RunAnalyzeActualityMutationVariables = Exact<{
  input: AnalyzeActualityInputInput;
}>;

export type RunAnalyzeActualityMutation = {
  __typename?: 'Mutation';
  analyzeActuality: {
    __typename?: 'AnalyzeActualityStatus';
    token: string;
    status: BatchJobExecutionStatus;
  };
};

export type ProcessingPipelineJobFragment = {
  __typename?: 'ProcessingPipelineJob';
  id: string;
  pipelineType: ProcessingPipelineType;
  status: ProcessingPipelineJobStatus;
  progress: number;
  createdAt: number;
  updatedAt: number;
  startedAt?: number | null;
  completedAt?: number | null;
  details?: any | null;
};

export type ProcessingPipelineJobsConnectionFragment = {
  __typename?: 'ProcessingPipelineJobsConnection';
  totalCount: number;
  content: Array<{
    __typename?: 'ProcessingPipelineJob';
    id: string;
    pipelineType: ProcessingPipelineType;
    status: ProcessingPipelineJobStatus;
    progress: number;
    createdAt: number;
    updatedAt: number;
    startedAt?: number | null;
    completedAt?: number | null;
    details?: any | null;
  }>;
};

export type ProcessingPipelineFragment = {
  __typename?: 'ProcessingPipeline';
  id: string;
  title?: string | null;
  enabled: boolean;
  jobs: {
    __typename?: 'ProcessingPipelineJobsConnection';
    content: Array<{
      __typename?: 'ProcessingPipelineJob';
      id: string;
      pipelineType: ProcessingPipelineType;
      status: ProcessingPipelineJobStatus;
      progress: number;
      createdAt: number;
      updatedAt: number;
      startedAt?: number | null;
      completedAt?: number | null;
      details?: any | null;
    }>;
  };
};

export type CreateProcessingPipelineMutationVariables = Exact<{
  input: CreateProcessingPipelineInput;
}>;

export type CreateProcessingPipelineMutation = {
  __typename?: 'Mutation';
  createProcessingPipeline: {
    __typename?: 'ProcessingPipeline';
    id: string;
    title?: string | null;
    enabled: boolean;
    jobs: {
      __typename?: 'ProcessingPipelineJobsConnection';
      content: Array<{
        __typename?: 'ProcessingPipelineJob';
        id: string;
        pipelineType: ProcessingPipelineType;
        status: ProcessingPipelineJobStatus;
        progress: number;
        createdAt: number;
        updatedAt: number;
        startedAt?: number | null;
        completedAt?: number | null;
        details?: any | null;
      }>;
    };
  };
};

export type UpdateProcessingPipelineMutationVariables = Exact<{
  input: UpdateProcessingPipelineInput;
}>;

export type UpdateProcessingPipelineMutation = {
  __typename?: 'Mutation';
  updateProcessingPipeline: {
    __typename?: 'ProcessingPipeline';
    id: string;
    title?: string | null;
    enabled: boolean;
    jobs: {
      __typename?: 'ProcessingPipelineJobsConnection';
      content: Array<{
        __typename?: 'ProcessingPipelineJob';
        id: string;
        pipelineType: ProcessingPipelineType;
        status: ProcessingPipelineJobStatus;
        progress: number;
        createdAt: number;
        updatedAt: number;
        startedAt?: number | null;
        completedAt?: number | null;
        details?: any | null;
      }>;
    };
  };
};

export type RemoveProcessingPipelineMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type RemoveProcessingPipelineMutation = {
  __typename?: 'Mutation';
  removeProcessingPipeline: boolean;
};

export type AllocationRunBaseFragment = {
  __typename?: 'AllocationRun';
  id: string;
  progress?: number | null;
  seed?: number | null;
  status: BatchJobStatus;
  startedAt?: number | null;
  completedAt?: number | null;
  createdAt: number;
  allocationRunType: AllocationType;
  resultAssignment?: { __typename?: 'Assignment'; id: string } | null;
  analyzeResult?: {
    __typename?: 'AnalyzeResult';
    id: string;
    status: AnalyzeResultStatus;
  } | null;
  allocationSettings?: {
    __typename?: 'AllocationSettings';
    allocationPolicy?: {
      __typename?: 'AllocationPolicy';
      rules?: Array<{
        __typename?: 'AllocationRule';
        id: string;
        title?: string | null;
        uomTypes?: Array<string> | null;
        itemsMatch?: {
          __typename?: 'SimulationItemFilterUnion';
          anyOf: Array<{
            __typename?: 'SimulationItemFilterIntersection';
            id?: string | null;
            allOf: Array<{
              __typename?: 'SimulationItemFilter';
              type: SimulationItemFilterType;
              isNull?: boolean | null;
              isNot?: boolean | null;
              valueIn?: Array<{
                __typename?: 'FilterValue';
                title: string;
              }> | null;
              range?: {
                __typename?: 'NumberFilterRange';
                from?: number | null;
                to?: number | null;
              } | null;
              stringRange?: {
                __typename?: 'StringFilterRange';
                from?: string | null;
                excludeFrom?: boolean | null;
                to?: string | null;
                excludeTo?: boolean | null;
              } | null;
            }>;
          }>;
        } | null;
        locationsMatch?: {
          __typename?: 'LocationFilterUnion';
          anyOf: Array<{
            __typename?: 'LocationFilterIntersection';
            id?: string | null;
            allOf: Array<{
              __typename?: 'LocationFilter';
              type: LocationFilterType;
              isNull?: boolean | null;
              isNot?: boolean | null;
              valueIn?: Array<{
                __typename?: 'FilterValue';
                title: string;
              }> | null;
              range?: {
                __typename?: 'NumberFilterRange';
                from?: number | null;
                to?: number | null;
              } | null;
              stringRange?: {
                __typename?: 'StringFilterRange';
                from?: string | null;
                excludeFrom?: boolean | null;
                to?: string | null;
                excludeTo?: boolean | null;
              } | null;
            }>;
          }>;
        } | null;
        limitSettings?: {
          __typename?: 'AllocationLimitSettings';
          applyTo?: AllocationLimitApplyTo | null;
          maxPickableAssignments?: number | null;
          maxAllocatedAssignments?: number | null;
          maxPickableVolume?: number | null;
          maxAllocatedVolume?: number | null;
        } | null;
        roundSettings?: {
          __typename?: 'AllocationRoundSettings';
          maxAssignmentsPerRound?: number | null;
        } | null;
        pickabilitySettings?: {
          __typename?: 'AllocationPickabilitySettings';
          enforcePickability?: AllocationEnforcePickability | null;
        } | null;
        prioritySettings?: {
          __typename?: 'AllocationPrioritySettings';
          itemPriority?: Array<{
            __typename?: 'AllocationItemPrioritySpec';
            priority: AllocationItemPriority;
            direction?: AllocationPriorityDirection | null;
          }> | null;
          locationPriority?: Array<{
            __typename?: 'AllocationLocationPrioritySpec';
            priority: AllocationLocationPriority;
            direction?: AllocationPriorityDirection | null;
          }> | null;
        } | null;
        replacementSettings?: {
          __typename?: 'AllocationReplacementSettings';
          allow?: AllocationAllowReplacement | null;
        } | null;
        multiplexingSettings?: {
          __typename?: 'AllocationMultiplexingSettings';
          allow?: AllocationAllowReplacement | null;
        } | null;
        requirementAggregationSettings?: {
          __typename?: 'AllocationRequirementAggregationSettings';
          aggregation?: AllocationRequirementAggregationMethod | null;
          numberOfPeriods?: number | null;
        } | null;
        constraintSettings?: {
          __typename?: 'AllocationConstraintSettings';
          assignmentPolicyConstraint?: {
            __typename?: 'AssignmentPolicyConstraintSettings';
            disabled?: boolean | null;
          } | null;
          locationSizeConstraint?: {
            __typename?: 'LocationSizeConstraintSettings';
            disabled?: boolean | null;
            unknownAsNoncompliant?: boolean | null;
          } | null;
          bayWidthConstraint?: {
            __typename?: 'BayWidthConstraintSettings';
            disabled?: boolean | null;
          } | null;
        } | null;
      }> | null;
      defaultRule?: {
        __typename?: 'AllocationRule';
        id: string;
        title?: string | null;
        uomTypes?: Array<string> | null;
        itemsMatch?: {
          __typename?: 'SimulationItemFilterUnion';
          anyOf: Array<{
            __typename?: 'SimulationItemFilterIntersection';
            id?: string | null;
            allOf: Array<{
              __typename?: 'SimulationItemFilter';
              type: SimulationItemFilterType;
              isNull?: boolean | null;
              isNot?: boolean | null;
              valueIn?: Array<{
                __typename?: 'FilterValue';
                title: string;
              }> | null;
              range?: {
                __typename?: 'NumberFilterRange';
                from?: number | null;
                to?: number | null;
              } | null;
              stringRange?: {
                __typename?: 'StringFilterRange';
                from?: string | null;
                excludeFrom?: boolean | null;
                to?: string | null;
                excludeTo?: boolean | null;
              } | null;
            }>;
          }>;
        } | null;
        locationsMatch?: {
          __typename?: 'LocationFilterUnion';
          anyOf: Array<{
            __typename?: 'LocationFilterIntersection';
            id?: string | null;
            allOf: Array<{
              __typename?: 'LocationFilter';
              type: LocationFilterType;
              isNull?: boolean | null;
              isNot?: boolean | null;
              valueIn?: Array<{
                __typename?: 'FilterValue';
                title: string;
              }> | null;
              range?: {
                __typename?: 'NumberFilterRange';
                from?: number | null;
                to?: number | null;
              } | null;
              stringRange?: {
                __typename?: 'StringFilterRange';
                from?: string | null;
                excludeFrom?: boolean | null;
                to?: string | null;
                excludeTo?: boolean | null;
              } | null;
            }>;
          }>;
        } | null;
        limitSettings?: {
          __typename?: 'AllocationLimitSettings';
          applyTo?: AllocationLimitApplyTo | null;
          maxPickableAssignments?: number | null;
          maxAllocatedAssignments?: number | null;
          maxPickableVolume?: number | null;
          maxAllocatedVolume?: number | null;
        } | null;
        roundSettings?: {
          __typename?: 'AllocationRoundSettings';
          maxAssignmentsPerRound?: number | null;
        } | null;
        pickabilitySettings?: {
          __typename?: 'AllocationPickabilitySettings';
          enforcePickability?: AllocationEnforcePickability | null;
        } | null;
        prioritySettings?: {
          __typename?: 'AllocationPrioritySettings';
          itemPriority?: Array<{
            __typename?: 'AllocationItemPrioritySpec';
            priority: AllocationItemPriority;
            direction?: AllocationPriorityDirection | null;
          }> | null;
          locationPriority?: Array<{
            __typename?: 'AllocationLocationPrioritySpec';
            priority: AllocationLocationPriority;
            direction?: AllocationPriorityDirection | null;
          }> | null;
        } | null;
        replacementSettings?: {
          __typename?: 'AllocationReplacementSettings';
          allow?: AllocationAllowReplacement | null;
        } | null;
        multiplexingSettings?: {
          __typename?: 'AllocationMultiplexingSettings';
          allow?: AllocationAllowReplacement | null;
        } | null;
        requirementAggregationSettings?: {
          __typename?: 'AllocationRequirementAggregationSettings';
          aggregation?: AllocationRequirementAggregationMethod | null;
          numberOfPeriods?: number | null;
        } | null;
        constraintSettings?: {
          __typename?: 'AllocationConstraintSettings';
          assignmentPolicyConstraint?: {
            __typename?: 'AssignmentPolicyConstraintSettings';
            disabled?: boolean | null;
          } | null;
          locationSizeConstraint?: {
            __typename?: 'LocationSizeConstraintSettings';
            disabled?: boolean | null;
            unknownAsNoncompliant?: boolean | null;
          } | null;
          bayWidthConstraint?: {
            __typename?: 'BayWidthConstraintSettings';
            disabled?: boolean | null;
          } | null;
        } | null;
      } | null;
    } | null;
    deallocationPolicy?: {
      __typename?: 'DeallocationPolicy';
      rules?: Array<{
        __typename?: 'DeallocationRule';
        id?: string | null;
        title?: string | null;
        deallocateType?: DeallocateType | null;
        reallocateType?: ReallocateType | null;
        itemsMatch?: {
          __typename?: 'SimulationItemFilterUnion';
          anyOf: Array<{
            __typename?: 'SimulationItemFilterIntersection';
            id?: string | null;
            allOf: Array<{
              __typename?: 'SimulationItemFilter';
              type: SimulationItemFilterType;
              isNull?: boolean | null;
              isNot?: boolean | null;
              valueIn?: Array<{
                __typename?: 'FilterValue';
                title: string;
              }> | null;
              range?: {
                __typename?: 'NumberFilterRange';
                from?: number | null;
                to?: number | null;
              } | null;
              stringRange?: {
                __typename?: 'StringFilterRange';
                from?: string | null;
                excludeFrom?: boolean | null;
                to?: string | null;
                excludeTo?: boolean | null;
              } | null;
            }>;
          }>;
        } | null;
        locationsMatch?: {
          __typename?: 'LocationFilterUnion';
          anyOf: Array<{
            __typename?: 'LocationFilterIntersection';
            id?: string | null;
            allOf: Array<{
              __typename?: 'LocationFilter';
              type: LocationFilterType;
              isNull?: boolean | null;
              isNot?: boolean | null;
              valueIn?: Array<{
                __typename?: 'FilterValue';
                title: string;
              }> | null;
              range?: {
                __typename?: 'NumberFilterRange';
                from?: number | null;
                to?: number | null;
              } | null;
              stringRange?: {
                __typename?: 'StringFilterRange';
                from?: string | null;
                excludeFrom?: boolean | null;
                to?: string | null;
                excludeTo?: boolean | null;
              } | null;
            }>;
          }>;
        } | null;
        constraintSettings?: {
          __typename?: 'AllocationConstraintSettings';
          assignmentPolicyConstraint?: {
            __typename?: 'AssignmentPolicyConstraintSettings';
            disabled?: boolean | null;
          } | null;
          locationSizeConstraint?: {
            __typename?: 'LocationSizeConstraintSettings';
            disabled?: boolean | null;
            unknownAsNoncompliant?: boolean | null;
          } | null;
          bayWidthConstraint?: {
            __typename?: 'BayWidthConstraintSettings';
            disabled?: boolean | null;
          } | null;
        } | null;
        reallocateQuantitySettings?: {
          __typename?: 'ReallocateQuantitySettings';
          reallocateUom?: ReallocateUomSource | null;
          reallocateQuantity?: ReallocateQuantitySource | null;
          fixedUom?: string | null;
          fixedUomQuantity?: number | null;
        } | null;
      }> | null;
      defaultRule?: {
        __typename?: 'DeallocationRule';
        id?: string | null;
        title?: string | null;
        deallocateType?: DeallocateType | null;
        reallocateType?: ReallocateType | null;
        itemsMatch?: {
          __typename?: 'SimulationItemFilterUnion';
          anyOf: Array<{
            __typename?: 'SimulationItemFilterIntersection';
            id?: string | null;
            allOf: Array<{
              __typename?: 'SimulationItemFilter';
              type: SimulationItemFilterType;
              isNull?: boolean | null;
              isNot?: boolean | null;
              valueIn?: Array<{
                __typename?: 'FilterValue';
                title: string;
              }> | null;
              range?: {
                __typename?: 'NumberFilterRange';
                from?: number | null;
                to?: number | null;
              } | null;
              stringRange?: {
                __typename?: 'StringFilterRange';
                from?: string | null;
                excludeFrom?: boolean | null;
                to?: string | null;
                excludeTo?: boolean | null;
              } | null;
            }>;
          }>;
        } | null;
        locationsMatch?: {
          __typename?: 'LocationFilterUnion';
          anyOf: Array<{
            __typename?: 'LocationFilterIntersection';
            id?: string | null;
            allOf: Array<{
              __typename?: 'LocationFilter';
              type: LocationFilterType;
              isNull?: boolean | null;
              isNot?: boolean | null;
              valueIn?: Array<{
                __typename?: 'FilterValue';
                title: string;
              }> | null;
              range?: {
                __typename?: 'NumberFilterRange';
                from?: number | null;
                to?: number | null;
              } | null;
              stringRange?: {
                __typename?: 'StringFilterRange';
                from?: string | null;
                excludeFrom?: boolean | null;
                to?: string | null;
                excludeTo?: boolean | null;
              } | null;
            }>;
          }>;
        } | null;
        constraintSettings?: {
          __typename?: 'AllocationConstraintSettings';
          assignmentPolicyConstraint?: {
            __typename?: 'AssignmentPolicyConstraintSettings';
            disabled?: boolean | null;
          } | null;
          locationSizeConstraint?: {
            __typename?: 'LocationSizeConstraintSettings';
            disabled?: boolean | null;
            unknownAsNoncompliant?: boolean | null;
          } | null;
          bayWidthConstraint?: {
            __typename?: 'BayWidthConstraintSettings';
            disabled?: boolean | null;
          } | null;
        } | null;
        reallocateQuantitySettings?: {
          __typename?: 'ReallocateQuantitySettings';
          reallocateUom?: ReallocateUomSource | null;
          reallocateQuantity?: ReallocateQuantitySource | null;
          fixedUom?: string | null;
          fixedUomQuantity?: number | null;
        } | null;
      } | null;
    } | null;
    orderSetFilter?: {
      __typename?: 'OrderSetFilter';
      orderDate?: {
        __typename?: 'LocalDateRangeFilter';
        from?: string | null;
        to?: string | null;
        excludeFrom?: boolean | null;
        excludeTo?: boolean | null;
        includeNull?: boolean | null;
      } | null;
    } | null;
    requirementSource?: {
      __typename?: 'AllocationRequirementSourceSettings';
      useOrderSetDemand?: boolean | null;
    } | null;
    stockLimitSettings?: {
      __typename?: 'AllocationStockLimitSettings';
      enabled?: boolean | null;
    } | null;
  } | null;
  resultRequirementsSummary: Array<{
    __typename?: 'AllocationRunResultRequirementSummary';
    key: {
      __typename?: 'AllocationRunResultRequirementSummaryKey';
      itemId?: string | null;
      consignee?: string | null;
      orderLineRuleId?: string | null;
      sku?: string | null;
      skuGroup?: string | null;
      subGroup?: string | null;
      transportClass?: string | null;
      stockCategory?: string | null;
      storageClass?: string | null;
      pollutionClass?: string | null;
    };
    required?: {
      __typename?: 'AllocationRunResultRequirementQuantity';
      totalQuantity?: number | null;
      uoms?: Array<{
        __typename?: 'AllocationRequirementUomQuantity';
        uom?: string | null;
        uomQuantity: number;
      }> | null;
    } | null;
    unpickable?: {
      __typename?: 'AllocationRunResultRequirementQuantity';
      totalQuantity?: number | null;
      uoms?: Array<{
        __typename?: 'AllocationRequirementUomQuantity';
        uom?: string | null;
        uomQuantity: number;
      }> | null;
    } | null;
    initiallyPickable?: {
      __typename?: 'AllocationRunResultRequirementPickableQuantitySummary';
      totalQuantity?: number | null;
      totalLocationCount?: number | null;
      uoms?: Array<{
        __typename?: 'AllocationRequirementUomQuantity';
        uom?: string | null;
        uomQuantity: number;
        totalVolume: number;
      }> | null;
    } | null;
    initiallyPickableFulfilled?: {
      __typename?: 'AllocationRunResultRequirementPickableQuantitySummary';
      totalQuantity?: number | null;
      totalLocationCount?: number | null;
      uoms?: Array<{
        __typename?: 'AllocationRequirementUomQuantity';
        uom?: string | null;
        uomQuantity: number;
        totalVolume: number;
      }> | null;
    } | null;
    toAllocateRequired?: {
      __typename?: 'AllocationRunResultRequirementQuantity';
      totalQuantity?: number | null;
      uoms?: Array<{
        __typename?: 'AllocationRequirementUomQuantity';
        uom?: string | null;
        uomQuantity: number;
        totalVolume: number;
        aggregatedTotalVolume?: number | null;
        aggregatedQuantity?: number | null;
        aggregatedUomQuantity?: number | null;
      }> | null;
    } | null;
    toReallocate?: {
      __typename?: 'AllocationRunResultRequirementQuantity';
      totalQuantity?: number | null;
      uoms?: Array<{
        __typename?: 'AllocationRequirementUomQuantity';
        uom?: string | null;
        uomQuantity: number;
        totalVolume: number;
        aggregatedTotalVolume?: number | null;
        aggregatedQuantity?: number | null;
        aggregatedUomQuantity?: number | null;
      }> | null;
    } | null;
    toAllocate?: {
      __typename?: 'AllocationRunResultRequirementQuantity';
      totalQuantity?: number | null;
      uoms?: Array<{
        __typename?: 'AllocationRequirementUomQuantity';
        uom?: string | null;
        uomQuantity: number;
        totalVolume: number;
        aggregatedTotalVolume?: number | null;
        aggregatedQuantity?: number | null;
        aggregatedUomQuantity?: number | null;
      }> | null;
    } | null;
    allocated?: {
      __typename?: 'AllocationRunResultRequirementPickableQuantitySummary';
      totalQuantity?: number | null;
      totalLocationCount?: number | null;
      uoms?: Array<{
        __typename?: 'AllocationRequirementUomQuantity';
        uom?: string | null;
        uomQuantity: number;
        totalVolume: number;
      }> | null;
    } | null;
    allocatedShared?: {
      __typename?: 'AllocationRunResultRequirementPickableQuantitySummary';
      totalQuantity?: number | null;
      totalLocationCount?: number | null;
      uoms?: Array<{
        __typename?: 'AllocationRequirementUomQuantity';
        uom?: string | null;
        uomQuantity: number;
        totalVolume: number;
      }> | null;
    } | null;
    allocatedFulfilled?: {
      __typename?: 'AllocationRunResultRequirementPickableQuantitySummary';
      totalQuantity?: number | null;
      totalLocationCount?: number | null;
      uoms?: Array<{
        __typename?: 'AllocationRequirementUomQuantity';
        uom?: string | null;
        uomQuantity: number;
        totalVolume: number;
      }> | null;
    } | null;
    unallocated?: {
      __typename?: 'AllocationRunResultRequirementUnallocatedQuantity';
      totalQuantity?: number | null;
      reasons?: Array<AllocationRunResultUnallocatedReason> | null;
      uoms?: Array<{
        __typename?: 'AllocationRequirementUomQuantity';
        uom?: string | null;
        uomQuantity: number;
      }> | null;
    } | null;
    deallocated?: {
      __typename?: 'AllocationRunResultRequirementDeallocatedQuantitySummary';
      totalQuantity?: number | null;
      totalLocationCount?: number | null;
      reasons?: Array<AllocationRunResultDeallocatedReason> | null;
      uoms?: Array<{
        __typename?: 'AllocationRequirementUomQuantity';
        uom?: string | null;
        uomQuantity: number;
        totalVolume: number;
      }> | null;
    } | null;
  }>;
};

export type CreateAllocationRunMutationVariables = Exact<{
  input: CreateAllocationRunInput;
}>;

export type CreateAllocationRunMutation = {
  __typename?: 'Mutation';
  createAllocationRun: {
    __typename?: 'AllocationRun';
    id: string;
    progress?: number | null;
    seed?: number | null;
    status: BatchJobStatus;
    startedAt?: number | null;
    completedAt?: number | null;
    createdAt: number;
    allocationRunType: AllocationType;
    resultAssignment?: { __typename?: 'Assignment'; id: string } | null;
    analyzeResult?: {
      __typename?: 'AnalyzeResult';
      id: string;
      status: AnalyzeResultStatus;
    } | null;
    allocationSettings?: {
      __typename?: 'AllocationSettings';
      allocationPolicy?: {
        __typename?: 'AllocationPolicy';
        rules?: Array<{
          __typename?: 'AllocationRule';
          id: string;
          title?: string | null;
          uomTypes?: Array<string> | null;
          itemsMatch?: {
            __typename?: 'SimulationItemFilterUnion';
            anyOf: Array<{
              __typename?: 'SimulationItemFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'SimulationItemFilter';
                type: SimulationItemFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
          locationsMatch?: {
            __typename?: 'LocationFilterUnion';
            anyOf: Array<{
              __typename?: 'LocationFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'LocationFilter';
                type: LocationFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
          limitSettings?: {
            __typename?: 'AllocationLimitSettings';
            applyTo?: AllocationLimitApplyTo | null;
            maxPickableAssignments?: number | null;
            maxAllocatedAssignments?: number | null;
            maxPickableVolume?: number | null;
            maxAllocatedVolume?: number | null;
          } | null;
          roundSettings?: {
            __typename?: 'AllocationRoundSettings';
            maxAssignmentsPerRound?: number | null;
          } | null;
          pickabilitySettings?: {
            __typename?: 'AllocationPickabilitySettings';
            enforcePickability?: AllocationEnforcePickability | null;
          } | null;
          prioritySettings?: {
            __typename?: 'AllocationPrioritySettings';
            itemPriority?: Array<{
              __typename?: 'AllocationItemPrioritySpec';
              priority: AllocationItemPriority;
              direction?: AllocationPriorityDirection | null;
            }> | null;
            locationPriority?: Array<{
              __typename?: 'AllocationLocationPrioritySpec';
              priority: AllocationLocationPriority;
              direction?: AllocationPriorityDirection | null;
            }> | null;
          } | null;
          replacementSettings?: {
            __typename?: 'AllocationReplacementSettings';
            allow?: AllocationAllowReplacement | null;
          } | null;
          multiplexingSettings?: {
            __typename?: 'AllocationMultiplexingSettings';
            allow?: AllocationAllowReplacement | null;
          } | null;
          requirementAggregationSettings?: {
            __typename?: 'AllocationRequirementAggregationSettings';
            aggregation?: AllocationRequirementAggregationMethod | null;
            numberOfPeriods?: number | null;
          } | null;
          constraintSettings?: {
            __typename?: 'AllocationConstraintSettings';
            assignmentPolicyConstraint?: {
              __typename?: 'AssignmentPolicyConstraintSettings';
              disabled?: boolean | null;
            } | null;
            locationSizeConstraint?: {
              __typename?: 'LocationSizeConstraintSettings';
              disabled?: boolean | null;
              unknownAsNoncompliant?: boolean | null;
            } | null;
            bayWidthConstraint?: {
              __typename?: 'BayWidthConstraintSettings';
              disabled?: boolean | null;
            } | null;
          } | null;
        }> | null;
        defaultRule?: {
          __typename?: 'AllocationRule';
          id: string;
          title?: string | null;
          uomTypes?: Array<string> | null;
          itemsMatch?: {
            __typename?: 'SimulationItemFilterUnion';
            anyOf: Array<{
              __typename?: 'SimulationItemFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'SimulationItemFilter';
                type: SimulationItemFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
          locationsMatch?: {
            __typename?: 'LocationFilterUnion';
            anyOf: Array<{
              __typename?: 'LocationFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'LocationFilter';
                type: LocationFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
          limitSettings?: {
            __typename?: 'AllocationLimitSettings';
            applyTo?: AllocationLimitApplyTo | null;
            maxPickableAssignments?: number | null;
            maxAllocatedAssignments?: number | null;
            maxPickableVolume?: number | null;
            maxAllocatedVolume?: number | null;
          } | null;
          roundSettings?: {
            __typename?: 'AllocationRoundSettings';
            maxAssignmentsPerRound?: number | null;
          } | null;
          pickabilitySettings?: {
            __typename?: 'AllocationPickabilitySettings';
            enforcePickability?: AllocationEnforcePickability | null;
          } | null;
          prioritySettings?: {
            __typename?: 'AllocationPrioritySettings';
            itemPriority?: Array<{
              __typename?: 'AllocationItemPrioritySpec';
              priority: AllocationItemPriority;
              direction?: AllocationPriorityDirection | null;
            }> | null;
            locationPriority?: Array<{
              __typename?: 'AllocationLocationPrioritySpec';
              priority: AllocationLocationPriority;
              direction?: AllocationPriorityDirection | null;
            }> | null;
          } | null;
          replacementSettings?: {
            __typename?: 'AllocationReplacementSettings';
            allow?: AllocationAllowReplacement | null;
          } | null;
          multiplexingSettings?: {
            __typename?: 'AllocationMultiplexingSettings';
            allow?: AllocationAllowReplacement | null;
          } | null;
          requirementAggregationSettings?: {
            __typename?: 'AllocationRequirementAggregationSettings';
            aggregation?: AllocationRequirementAggregationMethod | null;
            numberOfPeriods?: number | null;
          } | null;
          constraintSettings?: {
            __typename?: 'AllocationConstraintSettings';
            assignmentPolicyConstraint?: {
              __typename?: 'AssignmentPolicyConstraintSettings';
              disabled?: boolean | null;
            } | null;
            locationSizeConstraint?: {
              __typename?: 'LocationSizeConstraintSettings';
              disabled?: boolean | null;
              unknownAsNoncompliant?: boolean | null;
            } | null;
            bayWidthConstraint?: {
              __typename?: 'BayWidthConstraintSettings';
              disabled?: boolean | null;
            } | null;
          } | null;
        } | null;
      } | null;
      deallocationPolicy?: {
        __typename?: 'DeallocationPolicy';
        rules?: Array<{
          __typename?: 'DeallocationRule';
          id?: string | null;
          title?: string | null;
          deallocateType?: DeallocateType | null;
          reallocateType?: ReallocateType | null;
          itemsMatch?: {
            __typename?: 'SimulationItemFilterUnion';
            anyOf: Array<{
              __typename?: 'SimulationItemFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'SimulationItemFilter';
                type: SimulationItemFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
          locationsMatch?: {
            __typename?: 'LocationFilterUnion';
            anyOf: Array<{
              __typename?: 'LocationFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'LocationFilter';
                type: LocationFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
          constraintSettings?: {
            __typename?: 'AllocationConstraintSettings';
            assignmentPolicyConstraint?: {
              __typename?: 'AssignmentPolicyConstraintSettings';
              disabled?: boolean | null;
            } | null;
            locationSizeConstraint?: {
              __typename?: 'LocationSizeConstraintSettings';
              disabled?: boolean | null;
              unknownAsNoncompliant?: boolean | null;
            } | null;
            bayWidthConstraint?: {
              __typename?: 'BayWidthConstraintSettings';
              disabled?: boolean | null;
            } | null;
          } | null;
          reallocateQuantitySettings?: {
            __typename?: 'ReallocateQuantitySettings';
            reallocateUom?: ReallocateUomSource | null;
            reallocateQuantity?: ReallocateQuantitySource | null;
            fixedUom?: string | null;
            fixedUomQuantity?: number | null;
          } | null;
        }> | null;
        defaultRule?: {
          __typename?: 'DeallocationRule';
          id?: string | null;
          title?: string | null;
          deallocateType?: DeallocateType | null;
          reallocateType?: ReallocateType | null;
          itemsMatch?: {
            __typename?: 'SimulationItemFilterUnion';
            anyOf: Array<{
              __typename?: 'SimulationItemFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'SimulationItemFilter';
                type: SimulationItemFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
          locationsMatch?: {
            __typename?: 'LocationFilterUnion';
            anyOf: Array<{
              __typename?: 'LocationFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'LocationFilter';
                type: LocationFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
          constraintSettings?: {
            __typename?: 'AllocationConstraintSettings';
            assignmentPolicyConstraint?: {
              __typename?: 'AssignmentPolicyConstraintSettings';
              disabled?: boolean | null;
            } | null;
            locationSizeConstraint?: {
              __typename?: 'LocationSizeConstraintSettings';
              disabled?: boolean | null;
              unknownAsNoncompliant?: boolean | null;
            } | null;
            bayWidthConstraint?: {
              __typename?: 'BayWidthConstraintSettings';
              disabled?: boolean | null;
            } | null;
          } | null;
          reallocateQuantitySettings?: {
            __typename?: 'ReallocateQuantitySettings';
            reallocateUom?: ReallocateUomSource | null;
            reallocateQuantity?: ReallocateQuantitySource | null;
            fixedUom?: string | null;
            fixedUomQuantity?: number | null;
          } | null;
        } | null;
      } | null;
      orderSetFilter?: {
        __typename?: 'OrderSetFilter';
        orderDate?: {
          __typename?: 'LocalDateRangeFilter';
          from?: string | null;
          to?: string | null;
          excludeFrom?: boolean | null;
          excludeTo?: boolean | null;
          includeNull?: boolean | null;
        } | null;
      } | null;
      requirementSource?: {
        __typename?: 'AllocationRequirementSourceSettings';
        useOrderSetDemand?: boolean | null;
      } | null;
      stockLimitSettings?: {
        __typename?: 'AllocationStockLimitSettings';
        enabled?: boolean | null;
      } | null;
    } | null;
    resultRequirementsSummary: Array<{
      __typename?: 'AllocationRunResultRequirementSummary';
      key: {
        __typename?: 'AllocationRunResultRequirementSummaryKey';
        itemId?: string | null;
        consignee?: string | null;
        orderLineRuleId?: string | null;
        sku?: string | null;
        skuGroup?: string | null;
        subGroup?: string | null;
        transportClass?: string | null;
        stockCategory?: string | null;
        storageClass?: string | null;
        pollutionClass?: string | null;
      };
      required?: {
        __typename?: 'AllocationRunResultRequirementQuantity';
        totalQuantity?: number | null;
        uoms?: Array<{
          __typename?: 'AllocationRequirementUomQuantity';
          uom?: string | null;
          uomQuantity: number;
        }> | null;
      } | null;
      unpickable?: {
        __typename?: 'AllocationRunResultRequirementQuantity';
        totalQuantity?: number | null;
        uoms?: Array<{
          __typename?: 'AllocationRequirementUomQuantity';
          uom?: string | null;
          uomQuantity: number;
        }> | null;
      } | null;
      initiallyPickable?: {
        __typename?: 'AllocationRunResultRequirementPickableQuantitySummary';
        totalQuantity?: number | null;
        totalLocationCount?: number | null;
        uoms?: Array<{
          __typename?: 'AllocationRequirementUomQuantity';
          uom?: string | null;
          uomQuantity: number;
          totalVolume: number;
        }> | null;
      } | null;
      initiallyPickableFulfilled?: {
        __typename?: 'AllocationRunResultRequirementPickableQuantitySummary';
        totalQuantity?: number | null;
        totalLocationCount?: number | null;
        uoms?: Array<{
          __typename?: 'AllocationRequirementUomQuantity';
          uom?: string | null;
          uomQuantity: number;
          totalVolume: number;
        }> | null;
      } | null;
      toAllocateRequired?: {
        __typename?: 'AllocationRunResultRequirementQuantity';
        totalQuantity?: number | null;
        uoms?: Array<{
          __typename?: 'AllocationRequirementUomQuantity';
          uom?: string | null;
          uomQuantity: number;
          totalVolume: number;
          aggregatedTotalVolume?: number | null;
          aggregatedQuantity?: number | null;
          aggregatedUomQuantity?: number | null;
        }> | null;
      } | null;
      toReallocate?: {
        __typename?: 'AllocationRunResultRequirementQuantity';
        totalQuantity?: number | null;
        uoms?: Array<{
          __typename?: 'AllocationRequirementUomQuantity';
          uom?: string | null;
          uomQuantity: number;
          totalVolume: number;
          aggregatedTotalVolume?: number | null;
          aggregatedQuantity?: number | null;
          aggregatedUomQuantity?: number | null;
        }> | null;
      } | null;
      toAllocate?: {
        __typename?: 'AllocationRunResultRequirementQuantity';
        totalQuantity?: number | null;
        uoms?: Array<{
          __typename?: 'AllocationRequirementUomQuantity';
          uom?: string | null;
          uomQuantity: number;
          totalVolume: number;
          aggregatedTotalVolume?: number | null;
          aggregatedQuantity?: number | null;
          aggregatedUomQuantity?: number | null;
        }> | null;
      } | null;
      allocated?: {
        __typename?: 'AllocationRunResultRequirementPickableQuantitySummary';
        totalQuantity?: number | null;
        totalLocationCount?: number | null;
        uoms?: Array<{
          __typename?: 'AllocationRequirementUomQuantity';
          uom?: string | null;
          uomQuantity: number;
          totalVolume: number;
        }> | null;
      } | null;
      allocatedShared?: {
        __typename?: 'AllocationRunResultRequirementPickableQuantitySummary';
        totalQuantity?: number | null;
        totalLocationCount?: number | null;
        uoms?: Array<{
          __typename?: 'AllocationRequirementUomQuantity';
          uom?: string | null;
          uomQuantity: number;
          totalVolume: number;
        }> | null;
      } | null;
      allocatedFulfilled?: {
        __typename?: 'AllocationRunResultRequirementPickableQuantitySummary';
        totalQuantity?: number | null;
        totalLocationCount?: number | null;
        uoms?: Array<{
          __typename?: 'AllocationRequirementUomQuantity';
          uom?: string | null;
          uomQuantity: number;
          totalVolume: number;
        }> | null;
      } | null;
      unallocated?: {
        __typename?: 'AllocationRunResultRequirementUnallocatedQuantity';
        totalQuantity?: number | null;
        reasons?: Array<AllocationRunResultUnallocatedReason> | null;
        uoms?: Array<{
          __typename?: 'AllocationRequirementUomQuantity';
          uom?: string | null;
          uomQuantity: number;
        }> | null;
      } | null;
      deallocated?: {
        __typename?: 'AllocationRunResultRequirementDeallocatedQuantitySummary';
        totalQuantity?: number | null;
        totalLocationCount?: number | null;
        reasons?: Array<AllocationRunResultDeallocatedReason> | null;
        uoms?: Array<{
          __typename?: 'AllocationRequirementUomQuantity';
          uom?: string | null;
          uomQuantity: number;
          totalVolume: number;
        }> | null;
      } | null;
    }>;
  };
};

export type RestartAllocationRunMutationVariables = Exact<{
  input: RestartAllocationRunInput;
}>;

export type RestartAllocationRunMutation = {
  __typename?: 'Mutation';
  restartAllocationRun: {
    __typename?: 'AllocationRun';
    id: string;
    progress?: number | null;
    seed?: number | null;
    status: BatchJobStatus;
    startedAt?: number | null;
    completedAt?: number | null;
    createdAt: number;
    allocationRunType: AllocationType;
    resultAssignment?: { __typename?: 'Assignment'; id: string } | null;
    analyzeResult?: {
      __typename?: 'AnalyzeResult';
      id: string;
      status: AnalyzeResultStatus;
    } | null;
    allocationSettings?: {
      __typename?: 'AllocationSettings';
      allocationPolicy?: {
        __typename?: 'AllocationPolicy';
        rules?: Array<{
          __typename?: 'AllocationRule';
          id: string;
          title?: string | null;
          uomTypes?: Array<string> | null;
          itemsMatch?: {
            __typename?: 'SimulationItemFilterUnion';
            anyOf: Array<{
              __typename?: 'SimulationItemFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'SimulationItemFilter';
                type: SimulationItemFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
          locationsMatch?: {
            __typename?: 'LocationFilterUnion';
            anyOf: Array<{
              __typename?: 'LocationFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'LocationFilter';
                type: LocationFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
          limitSettings?: {
            __typename?: 'AllocationLimitSettings';
            applyTo?: AllocationLimitApplyTo | null;
            maxPickableAssignments?: number | null;
            maxAllocatedAssignments?: number | null;
            maxPickableVolume?: number | null;
            maxAllocatedVolume?: number | null;
          } | null;
          roundSettings?: {
            __typename?: 'AllocationRoundSettings';
            maxAssignmentsPerRound?: number | null;
          } | null;
          pickabilitySettings?: {
            __typename?: 'AllocationPickabilitySettings';
            enforcePickability?: AllocationEnforcePickability | null;
          } | null;
          prioritySettings?: {
            __typename?: 'AllocationPrioritySettings';
            itemPriority?: Array<{
              __typename?: 'AllocationItemPrioritySpec';
              priority: AllocationItemPriority;
              direction?: AllocationPriorityDirection | null;
            }> | null;
            locationPriority?: Array<{
              __typename?: 'AllocationLocationPrioritySpec';
              priority: AllocationLocationPriority;
              direction?: AllocationPriorityDirection | null;
            }> | null;
          } | null;
          replacementSettings?: {
            __typename?: 'AllocationReplacementSettings';
            allow?: AllocationAllowReplacement | null;
          } | null;
          multiplexingSettings?: {
            __typename?: 'AllocationMultiplexingSettings';
            allow?: AllocationAllowReplacement | null;
          } | null;
          requirementAggregationSettings?: {
            __typename?: 'AllocationRequirementAggregationSettings';
            aggregation?: AllocationRequirementAggregationMethod | null;
            numberOfPeriods?: number | null;
          } | null;
          constraintSettings?: {
            __typename?: 'AllocationConstraintSettings';
            assignmentPolicyConstraint?: {
              __typename?: 'AssignmentPolicyConstraintSettings';
              disabled?: boolean | null;
            } | null;
            locationSizeConstraint?: {
              __typename?: 'LocationSizeConstraintSettings';
              disabled?: boolean | null;
              unknownAsNoncompliant?: boolean | null;
            } | null;
            bayWidthConstraint?: {
              __typename?: 'BayWidthConstraintSettings';
              disabled?: boolean | null;
            } | null;
          } | null;
        }> | null;
        defaultRule?: {
          __typename?: 'AllocationRule';
          id: string;
          title?: string | null;
          uomTypes?: Array<string> | null;
          itemsMatch?: {
            __typename?: 'SimulationItemFilterUnion';
            anyOf: Array<{
              __typename?: 'SimulationItemFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'SimulationItemFilter';
                type: SimulationItemFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
          locationsMatch?: {
            __typename?: 'LocationFilterUnion';
            anyOf: Array<{
              __typename?: 'LocationFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'LocationFilter';
                type: LocationFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
          limitSettings?: {
            __typename?: 'AllocationLimitSettings';
            applyTo?: AllocationLimitApplyTo | null;
            maxPickableAssignments?: number | null;
            maxAllocatedAssignments?: number | null;
            maxPickableVolume?: number | null;
            maxAllocatedVolume?: number | null;
          } | null;
          roundSettings?: {
            __typename?: 'AllocationRoundSettings';
            maxAssignmentsPerRound?: number | null;
          } | null;
          pickabilitySettings?: {
            __typename?: 'AllocationPickabilitySettings';
            enforcePickability?: AllocationEnforcePickability | null;
          } | null;
          prioritySettings?: {
            __typename?: 'AllocationPrioritySettings';
            itemPriority?: Array<{
              __typename?: 'AllocationItemPrioritySpec';
              priority: AllocationItemPriority;
              direction?: AllocationPriorityDirection | null;
            }> | null;
            locationPriority?: Array<{
              __typename?: 'AllocationLocationPrioritySpec';
              priority: AllocationLocationPriority;
              direction?: AllocationPriorityDirection | null;
            }> | null;
          } | null;
          replacementSettings?: {
            __typename?: 'AllocationReplacementSettings';
            allow?: AllocationAllowReplacement | null;
          } | null;
          multiplexingSettings?: {
            __typename?: 'AllocationMultiplexingSettings';
            allow?: AllocationAllowReplacement | null;
          } | null;
          requirementAggregationSettings?: {
            __typename?: 'AllocationRequirementAggregationSettings';
            aggregation?: AllocationRequirementAggregationMethod | null;
            numberOfPeriods?: number | null;
          } | null;
          constraintSettings?: {
            __typename?: 'AllocationConstraintSettings';
            assignmentPolicyConstraint?: {
              __typename?: 'AssignmentPolicyConstraintSettings';
              disabled?: boolean | null;
            } | null;
            locationSizeConstraint?: {
              __typename?: 'LocationSizeConstraintSettings';
              disabled?: boolean | null;
              unknownAsNoncompliant?: boolean | null;
            } | null;
            bayWidthConstraint?: {
              __typename?: 'BayWidthConstraintSettings';
              disabled?: boolean | null;
            } | null;
          } | null;
        } | null;
      } | null;
      deallocationPolicy?: {
        __typename?: 'DeallocationPolicy';
        rules?: Array<{
          __typename?: 'DeallocationRule';
          id?: string | null;
          title?: string | null;
          deallocateType?: DeallocateType | null;
          reallocateType?: ReallocateType | null;
          itemsMatch?: {
            __typename?: 'SimulationItemFilterUnion';
            anyOf: Array<{
              __typename?: 'SimulationItemFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'SimulationItemFilter';
                type: SimulationItemFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
          locationsMatch?: {
            __typename?: 'LocationFilterUnion';
            anyOf: Array<{
              __typename?: 'LocationFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'LocationFilter';
                type: LocationFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
          constraintSettings?: {
            __typename?: 'AllocationConstraintSettings';
            assignmentPolicyConstraint?: {
              __typename?: 'AssignmentPolicyConstraintSettings';
              disabled?: boolean | null;
            } | null;
            locationSizeConstraint?: {
              __typename?: 'LocationSizeConstraintSettings';
              disabled?: boolean | null;
              unknownAsNoncompliant?: boolean | null;
            } | null;
            bayWidthConstraint?: {
              __typename?: 'BayWidthConstraintSettings';
              disabled?: boolean | null;
            } | null;
          } | null;
          reallocateQuantitySettings?: {
            __typename?: 'ReallocateQuantitySettings';
            reallocateUom?: ReallocateUomSource | null;
            reallocateQuantity?: ReallocateQuantitySource | null;
            fixedUom?: string | null;
            fixedUomQuantity?: number | null;
          } | null;
        }> | null;
        defaultRule?: {
          __typename?: 'DeallocationRule';
          id?: string | null;
          title?: string | null;
          deallocateType?: DeallocateType | null;
          reallocateType?: ReallocateType | null;
          itemsMatch?: {
            __typename?: 'SimulationItemFilterUnion';
            anyOf: Array<{
              __typename?: 'SimulationItemFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'SimulationItemFilter';
                type: SimulationItemFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
          locationsMatch?: {
            __typename?: 'LocationFilterUnion';
            anyOf: Array<{
              __typename?: 'LocationFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'LocationFilter';
                type: LocationFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
          constraintSettings?: {
            __typename?: 'AllocationConstraintSettings';
            assignmentPolicyConstraint?: {
              __typename?: 'AssignmentPolicyConstraintSettings';
              disabled?: boolean | null;
            } | null;
            locationSizeConstraint?: {
              __typename?: 'LocationSizeConstraintSettings';
              disabled?: boolean | null;
              unknownAsNoncompliant?: boolean | null;
            } | null;
            bayWidthConstraint?: {
              __typename?: 'BayWidthConstraintSettings';
              disabled?: boolean | null;
            } | null;
          } | null;
          reallocateQuantitySettings?: {
            __typename?: 'ReallocateQuantitySettings';
            reallocateUom?: ReallocateUomSource | null;
            reallocateQuantity?: ReallocateQuantitySource | null;
            fixedUom?: string | null;
            fixedUomQuantity?: number | null;
          } | null;
        } | null;
      } | null;
      orderSetFilter?: {
        __typename?: 'OrderSetFilter';
        orderDate?: {
          __typename?: 'LocalDateRangeFilter';
          from?: string | null;
          to?: string | null;
          excludeFrom?: boolean | null;
          excludeTo?: boolean | null;
          includeNull?: boolean | null;
        } | null;
      } | null;
      requirementSource?: {
        __typename?: 'AllocationRequirementSourceSettings';
        useOrderSetDemand?: boolean | null;
      } | null;
      stockLimitSettings?: {
        __typename?: 'AllocationStockLimitSettings';
        enabled?: boolean | null;
      } | null;
    } | null;
    resultRequirementsSummary: Array<{
      __typename?: 'AllocationRunResultRequirementSummary';
      key: {
        __typename?: 'AllocationRunResultRequirementSummaryKey';
        itemId?: string | null;
        consignee?: string | null;
        orderLineRuleId?: string | null;
        sku?: string | null;
        skuGroup?: string | null;
        subGroup?: string | null;
        transportClass?: string | null;
        stockCategory?: string | null;
        storageClass?: string | null;
        pollutionClass?: string | null;
      };
      required?: {
        __typename?: 'AllocationRunResultRequirementQuantity';
        totalQuantity?: number | null;
        uoms?: Array<{
          __typename?: 'AllocationRequirementUomQuantity';
          uom?: string | null;
          uomQuantity: number;
        }> | null;
      } | null;
      unpickable?: {
        __typename?: 'AllocationRunResultRequirementQuantity';
        totalQuantity?: number | null;
        uoms?: Array<{
          __typename?: 'AllocationRequirementUomQuantity';
          uom?: string | null;
          uomQuantity: number;
        }> | null;
      } | null;
      initiallyPickable?: {
        __typename?: 'AllocationRunResultRequirementPickableQuantitySummary';
        totalQuantity?: number | null;
        totalLocationCount?: number | null;
        uoms?: Array<{
          __typename?: 'AllocationRequirementUomQuantity';
          uom?: string | null;
          uomQuantity: number;
          totalVolume: number;
        }> | null;
      } | null;
      initiallyPickableFulfilled?: {
        __typename?: 'AllocationRunResultRequirementPickableQuantitySummary';
        totalQuantity?: number | null;
        totalLocationCount?: number | null;
        uoms?: Array<{
          __typename?: 'AllocationRequirementUomQuantity';
          uom?: string | null;
          uomQuantity: number;
          totalVolume: number;
        }> | null;
      } | null;
      toAllocateRequired?: {
        __typename?: 'AllocationRunResultRequirementQuantity';
        totalQuantity?: number | null;
        uoms?: Array<{
          __typename?: 'AllocationRequirementUomQuantity';
          uom?: string | null;
          uomQuantity: number;
          totalVolume: number;
          aggregatedTotalVolume?: number | null;
          aggregatedQuantity?: number | null;
          aggregatedUomQuantity?: number | null;
        }> | null;
      } | null;
      toReallocate?: {
        __typename?: 'AllocationRunResultRequirementQuantity';
        totalQuantity?: number | null;
        uoms?: Array<{
          __typename?: 'AllocationRequirementUomQuantity';
          uom?: string | null;
          uomQuantity: number;
          totalVolume: number;
          aggregatedTotalVolume?: number | null;
          aggregatedQuantity?: number | null;
          aggregatedUomQuantity?: number | null;
        }> | null;
      } | null;
      toAllocate?: {
        __typename?: 'AllocationRunResultRequirementQuantity';
        totalQuantity?: number | null;
        uoms?: Array<{
          __typename?: 'AllocationRequirementUomQuantity';
          uom?: string | null;
          uomQuantity: number;
          totalVolume: number;
          aggregatedTotalVolume?: number | null;
          aggregatedQuantity?: number | null;
          aggregatedUomQuantity?: number | null;
        }> | null;
      } | null;
      allocated?: {
        __typename?: 'AllocationRunResultRequirementPickableQuantitySummary';
        totalQuantity?: number | null;
        totalLocationCount?: number | null;
        uoms?: Array<{
          __typename?: 'AllocationRequirementUomQuantity';
          uom?: string | null;
          uomQuantity: number;
          totalVolume: number;
        }> | null;
      } | null;
      allocatedShared?: {
        __typename?: 'AllocationRunResultRequirementPickableQuantitySummary';
        totalQuantity?: number | null;
        totalLocationCount?: number | null;
        uoms?: Array<{
          __typename?: 'AllocationRequirementUomQuantity';
          uom?: string | null;
          uomQuantity: number;
          totalVolume: number;
        }> | null;
      } | null;
      allocatedFulfilled?: {
        __typename?: 'AllocationRunResultRequirementPickableQuantitySummary';
        totalQuantity?: number | null;
        totalLocationCount?: number | null;
        uoms?: Array<{
          __typename?: 'AllocationRequirementUomQuantity';
          uom?: string | null;
          uomQuantity: number;
          totalVolume: number;
        }> | null;
      } | null;
      unallocated?: {
        __typename?: 'AllocationRunResultRequirementUnallocatedQuantity';
        totalQuantity?: number | null;
        reasons?: Array<AllocationRunResultUnallocatedReason> | null;
        uoms?: Array<{
          __typename?: 'AllocationRequirementUomQuantity';
          uom?: string | null;
          uomQuantity: number;
        }> | null;
      } | null;
      deallocated?: {
        __typename?: 'AllocationRunResultRequirementDeallocatedQuantitySummary';
        totalQuantity?: number | null;
        totalLocationCount?: number | null;
        reasons?: Array<AllocationRunResultDeallocatedReason> | null;
        uoms?: Array<{
          __typename?: 'AllocationRequirementUomQuantity';
          uom?: string | null;
          uomQuantity: number;
          totalVolume: number;
        }> | null;
      } | null;
    }>;
  };
};

export type LoadAllocationRunStatusQueryVariables = Exact<{
  allocationRunId: Scalars['ID']['input'];
}>;

export type LoadAllocationRunStatusQuery = {
  __typename?: 'Query';
  allocationRun?: {
    __typename?: 'AllocationRun';
    id: string;
    progress?: number | null;
    seed?: number | null;
    status: BatchJobStatus;
    startedAt?: number | null;
    completedAt?: number | null;
    createdAt: number;
    allocationRunType: AllocationType;
    resultAssignment?: { __typename?: 'Assignment'; id: string } | null;
    analyzeResult?: {
      __typename?: 'AnalyzeResult';
      id: string;
      status: AnalyzeResultStatus;
    } | null;
    allocationSettings?: {
      __typename?: 'AllocationSettings';
      allocationPolicy?: {
        __typename?: 'AllocationPolicy';
        rules?: Array<{
          __typename?: 'AllocationRule';
          id: string;
          title?: string | null;
          uomTypes?: Array<string> | null;
          itemsMatch?: {
            __typename?: 'SimulationItemFilterUnion';
            anyOf: Array<{
              __typename?: 'SimulationItemFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'SimulationItemFilter';
                type: SimulationItemFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
          locationsMatch?: {
            __typename?: 'LocationFilterUnion';
            anyOf: Array<{
              __typename?: 'LocationFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'LocationFilter';
                type: LocationFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
          limitSettings?: {
            __typename?: 'AllocationLimitSettings';
            applyTo?: AllocationLimitApplyTo | null;
            maxPickableAssignments?: number | null;
            maxAllocatedAssignments?: number | null;
            maxPickableVolume?: number | null;
            maxAllocatedVolume?: number | null;
          } | null;
          roundSettings?: {
            __typename?: 'AllocationRoundSettings';
            maxAssignmentsPerRound?: number | null;
          } | null;
          pickabilitySettings?: {
            __typename?: 'AllocationPickabilitySettings';
            enforcePickability?: AllocationEnforcePickability | null;
          } | null;
          prioritySettings?: {
            __typename?: 'AllocationPrioritySettings';
            itemPriority?: Array<{
              __typename?: 'AllocationItemPrioritySpec';
              priority: AllocationItemPriority;
              direction?: AllocationPriorityDirection | null;
            }> | null;
            locationPriority?: Array<{
              __typename?: 'AllocationLocationPrioritySpec';
              priority: AllocationLocationPriority;
              direction?: AllocationPriorityDirection | null;
            }> | null;
          } | null;
          replacementSettings?: {
            __typename?: 'AllocationReplacementSettings';
            allow?: AllocationAllowReplacement | null;
          } | null;
          multiplexingSettings?: {
            __typename?: 'AllocationMultiplexingSettings';
            allow?: AllocationAllowReplacement | null;
          } | null;
          requirementAggregationSettings?: {
            __typename?: 'AllocationRequirementAggregationSettings';
            aggregation?: AllocationRequirementAggregationMethod | null;
            numberOfPeriods?: number | null;
          } | null;
          constraintSettings?: {
            __typename?: 'AllocationConstraintSettings';
            assignmentPolicyConstraint?: {
              __typename?: 'AssignmentPolicyConstraintSettings';
              disabled?: boolean | null;
            } | null;
            locationSizeConstraint?: {
              __typename?: 'LocationSizeConstraintSettings';
              disabled?: boolean | null;
              unknownAsNoncompliant?: boolean | null;
            } | null;
            bayWidthConstraint?: {
              __typename?: 'BayWidthConstraintSettings';
              disabled?: boolean | null;
            } | null;
          } | null;
        }> | null;
        defaultRule?: {
          __typename?: 'AllocationRule';
          id: string;
          title?: string | null;
          uomTypes?: Array<string> | null;
          itemsMatch?: {
            __typename?: 'SimulationItemFilterUnion';
            anyOf: Array<{
              __typename?: 'SimulationItemFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'SimulationItemFilter';
                type: SimulationItemFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
          locationsMatch?: {
            __typename?: 'LocationFilterUnion';
            anyOf: Array<{
              __typename?: 'LocationFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'LocationFilter';
                type: LocationFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
          limitSettings?: {
            __typename?: 'AllocationLimitSettings';
            applyTo?: AllocationLimitApplyTo | null;
            maxPickableAssignments?: number | null;
            maxAllocatedAssignments?: number | null;
            maxPickableVolume?: number | null;
            maxAllocatedVolume?: number | null;
          } | null;
          roundSettings?: {
            __typename?: 'AllocationRoundSettings';
            maxAssignmentsPerRound?: number | null;
          } | null;
          pickabilitySettings?: {
            __typename?: 'AllocationPickabilitySettings';
            enforcePickability?: AllocationEnforcePickability | null;
          } | null;
          prioritySettings?: {
            __typename?: 'AllocationPrioritySettings';
            itemPriority?: Array<{
              __typename?: 'AllocationItemPrioritySpec';
              priority: AllocationItemPriority;
              direction?: AllocationPriorityDirection | null;
            }> | null;
            locationPriority?: Array<{
              __typename?: 'AllocationLocationPrioritySpec';
              priority: AllocationLocationPriority;
              direction?: AllocationPriorityDirection | null;
            }> | null;
          } | null;
          replacementSettings?: {
            __typename?: 'AllocationReplacementSettings';
            allow?: AllocationAllowReplacement | null;
          } | null;
          multiplexingSettings?: {
            __typename?: 'AllocationMultiplexingSettings';
            allow?: AllocationAllowReplacement | null;
          } | null;
          requirementAggregationSettings?: {
            __typename?: 'AllocationRequirementAggregationSettings';
            aggregation?: AllocationRequirementAggregationMethod | null;
            numberOfPeriods?: number | null;
          } | null;
          constraintSettings?: {
            __typename?: 'AllocationConstraintSettings';
            assignmentPolicyConstraint?: {
              __typename?: 'AssignmentPolicyConstraintSettings';
              disabled?: boolean | null;
            } | null;
            locationSizeConstraint?: {
              __typename?: 'LocationSizeConstraintSettings';
              disabled?: boolean | null;
              unknownAsNoncompliant?: boolean | null;
            } | null;
            bayWidthConstraint?: {
              __typename?: 'BayWidthConstraintSettings';
              disabled?: boolean | null;
            } | null;
          } | null;
        } | null;
      } | null;
      deallocationPolicy?: {
        __typename?: 'DeallocationPolicy';
        rules?: Array<{
          __typename?: 'DeallocationRule';
          id?: string | null;
          title?: string | null;
          deallocateType?: DeallocateType | null;
          reallocateType?: ReallocateType | null;
          itemsMatch?: {
            __typename?: 'SimulationItemFilterUnion';
            anyOf: Array<{
              __typename?: 'SimulationItemFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'SimulationItemFilter';
                type: SimulationItemFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
          locationsMatch?: {
            __typename?: 'LocationFilterUnion';
            anyOf: Array<{
              __typename?: 'LocationFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'LocationFilter';
                type: LocationFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
          constraintSettings?: {
            __typename?: 'AllocationConstraintSettings';
            assignmentPolicyConstraint?: {
              __typename?: 'AssignmentPolicyConstraintSettings';
              disabled?: boolean | null;
            } | null;
            locationSizeConstraint?: {
              __typename?: 'LocationSizeConstraintSettings';
              disabled?: boolean | null;
              unknownAsNoncompliant?: boolean | null;
            } | null;
            bayWidthConstraint?: {
              __typename?: 'BayWidthConstraintSettings';
              disabled?: boolean | null;
            } | null;
          } | null;
          reallocateQuantitySettings?: {
            __typename?: 'ReallocateQuantitySettings';
            reallocateUom?: ReallocateUomSource | null;
            reallocateQuantity?: ReallocateQuantitySource | null;
            fixedUom?: string | null;
            fixedUomQuantity?: number | null;
          } | null;
        }> | null;
        defaultRule?: {
          __typename?: 'DeallocationRule';
          id?: string | null;
          title?: string | null;
          deallocateType?: DeallocateType | null;
          reallocateType?: ReallocateType | null;
          itemsMatch?: {
            __typename?: 'SimulationItemFilterUnion';
            anyOf: Array<{
              __typename?: 'SimulationItemFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'SimulationItemFilter';
                type: SimulationItemFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
          locationsMatch?: {
            __typename?: 'LocationFilterUnion';
            anyOf: Array<{
              __typename?: 'LocationFilterIntersection';
              id?: string | null;
              allOf: Array<{
                __typename?: 'LocationFilter';
                type: LocationFilterType;
                isNull?: boolean | null;
                isNot?: boolean | null;
                valueIn?: Array<{
                  __typename?: 'FilterValue';
                  title: string;
                }> | null;
                range?: {
                  __typename?: 'NumberFilterRange';
                  from?: number | null;
                  to?: number | null;
                } | null;
                stringRange?: {
                  __typename?: 'StringFilterRange';
                  from?: string | null;
                  excludeFrom?: boolean | null;
                  to?: string | null;
                  excludeTo?: boolean | null;
                } | null;
              }>;
            }>;
          } | null;
          constraintSettings?: {
            __typename?: 'AllocationConstraintSettings';
            assignmentPolicyConstraint?: {
              __typename?: 'AssignmentPolicyConstraintSettings';
              disabled?: boolean | null;
            } | null;
            locationSizeConstraint?: {
              __typename?: 'LocationSizeConstraintSettings';
              disabled?: boolean | null;
              unknownAsNoncompliant?: boolean | null;
            } | null;
            bayWidthConstraint?: {
              __typename?: 'BayWidthConstraintSettings';
              disabled?: boolean | null;
            } | null;
          } | null;
          reallocateQuantitySettings?: {
            __typename?: 'ReallocateQuantitySettings';
            reallocateUom?: ReallocateUomSource | null;
            reallocateQuantity?: ReallocateQuantitySource | null;
            fixedUom?: string | null;
            fixedUomQuantity?: number | null;
          } | null;
        } | null;
      } | null;
      orderSetFilter?: {
        __typename?: 'OrderSetFilter';
        orderDate?: {
          __typename?: 'LocalDateRangeFilter';
          from?: string | null;
          to?: string | null;
          excludeFrom?: boolean | null;
          excludeTo?: boolean | null;
          includeNull?: boolean | null;
        } | null;
      } | null;
      requirementSource?: {
        __typename?: 'AllocationRequirementSourceSettings';
        useOrderSetDemand?: boolean | null;
      } | null;
      stockLimitSettings?: {
        __typename?: 'AllocationStockLimitSettings';
        enabled?: boolean | null;
      } | null;
    } | null;
    resultRequirementsSummary: Array<{
      __typename?: 'AllocationRunResultRequirementSummary';
      key: {
        __typename?: 'AllocationRunResultRequirementSummaryKey';
        itemId?: string | null;
        consignee?: string | null;
        orderLineRuleId?: string | null;
        sku?: string | null;
        skuGroup?: string | null;
        subGroup?: string | null;
        transportClass?: string | null;
        stockCategory?: string | null;
        storageClass?: string | null;
        pollutionClass?: string | null;
      };
      required?: {
        __typename?: 'AllocationRunResultRequirementQuantity';
        totalQuantity?: number | null;
        uoms?: Array<{
          __typename?: 'AllocationRequirementUomQuantity';
          uom?: string | null;
          uomQuantity: number;
        }> | null;
      } | null;
      unpickable?: {
        __typename?: 'AllocationRunResultRequirementQuantity';
        totalQuantity?: number | null;
        uoms?: Array<{
          __typename?: 'AllocationRequirementUomQuantity';
          uom?: string | null;
          uomQuantity: number;
        }> | null;
      } | null;
      initiallyPickable?: {
        __typename?: 'AllocationRunResultRequirementPickableQuantitySummary';
        totalQuantity?: number | null;
        totalLocationCount?: number | null;
        uoms?: Array<{
          __typename?: 'AllocationRequirementUomQuantity';
          uom?: string | null;
          uomQuantity: number;
          totalVolume: number;
        }> | null;
      } | null;
      initiallyPickableFulfilled?: {
        __typename?: 'AllocationRunResultRequirementPickableQuantitySummary';
        totalQuantity?: number | null;
        totalLocationCount?: number | null;
        uoms?: Array<{
          __typename?: 'AllocationRequirementUomQuantity';
          uom?: string | null;
          uomQuantity: number;
          totalVolume: number;
        }> | null;
      } | null;
      toAllocateRequired?: {
        __typename?: 'AllocationRunResultRequirementQuantity';
        totalQuantity?: number | null;
        uoms?: Array<{
          __typename?: 'AllocationRequirementUomQuantity';
          uom?: string | null;
          uomQuantity: number;
          totalVolume: number;
          aggregatedTotalVolume?: number | null;
          aggregatedQuantity?: number | null;
          aggregatedUomQuantity?: number | null;
        }> | null;
      } | null;
      toReallocate?: {
        __typename?: 'AllocationRunResultRequirementQuantity';
        totalQuantity?: number | null;
        uoms?: Array<{
          __typename?: 'AllocationRequirementUomQuantity';
          uom?: string | null;
          uomQuantity: number;
          totalVolume: number;
          aggregatedTotalVolume?: number | null;
          aggregatedQuantity?: number | null;
          aggregatedUomQuantity?: number | null;
        }> | null;
      } | null;
      toAllocate?: {
        __typename?: 'AllocationRunResultRequirementQuantity';
        totalQuantity?: number | null;
        uoms?: Array<{
          __typename?: 'AllocationRequirementUomQuantity';
          uom?: string | null;
          uomQuantity: number;
          totalVolume: number;
          aggregatedTotalVolume?: number | null;
          aggregatedQuantity?: number | null;
          aggregatedUomQuantity?: number | null;
        }> | null;
      } | null;
      allocated?: {
        __typename?: 'AllocationRunResultRequirementPickableQuantitySummary';
        totalQuantity?: number | null;
        totalLocationCount?: number | null;
        uoms?: Array<{
          __typename?: 'AllocationRequirementUomQuantity';
          uom?: string | null;
          uomQuantity: number;
          totalVolume: number;
        }> | null;
      } | null;
      allocatedShared?: {
        __typename?: 'AllocationRunResultRequirementPickableQuantitySummary';
        totalQuantity?: number | null;
        totalLocationCount?: number | null;
        uoms?: Array<{
          __typename?: 'AllocationRequirementUomQuantity';
          uom?: string | null;
          uomQuantity: number;
          totalVolume: number;
        }> | null;
      } | null;
      allocatedFulfilled?: {
        __typename?: 'AllocationRunResultRequirementPickableQuantitySummary';
        totalQuantity?: number | null;
        totalLocationCount?: number | null;
        uoms?: Array<{
          __typename?: 'AllocationRequirementUomQuantity';
          uom?: string | null;
          uomQuantity: number;
          totalVolume: number;
        }> | null;
      } | null;
      unallocated?: {
        __typename?: 'AllocationRunResultRequirementUnallocatedQuantity';
        totalQuantity?: number | null;
        reasons?: Array<AllocationRunResultUnallocatedReason> | null;
        uoms?: Array<{
          __typename?: 'AllocationRequirementUomQuantity';
          uom?: string | null;
          uomQuantity: number;
        }> | null;
      } | null;
      deallocated?: {
        __typename?: 'AllocationRunResultRequirementDeallocatedQuantitySummary';
        totalQuantity?: number | null;
        totalLocationCount?: number | null;
        reasons?: Array<AllocationRunResultDeallocatedReason> | null;
        uoms?: Array<{
          __typename?: 'AllocationRequirementUomQuantity';
          uom?: string | null;
          uomQuantity: number;
          totalVolume: number;
        }> | null;
      } | null;
    }>;
  } | null;
};

export type AnalyzeAllocationRunMutationVariables = Exact<{
  input: AnalyzeAllocationRunInput;
}>;

export type AnalyzeAllocationRunMutation = {
  __typename?: 'Mutation';
  analyzeAllocationRun: {
    __typename?: 'AnalyzeResult';
    picklistPickByDates: Array<string | null>;
    maxLocationHitCount?: number | null;
    id: string;
    status: AnalyzeResultStatus;
    stage?: AnalyzeResultStage | null;
    progress?: number | null;
    totalOrders: number;
    totalAssembledOrders: number;
    totalAssembledPartiallyOrders: number;
    totalLines: number;
    assembledLines: number;
    assembledPartiallyLines: number;
    ignoredLines: number;
    totalDistance: number;
    totalCost: number;
    totalDuration: number;
    maxReorderTriggeredSummary?: {
      __typename?: 'AnalyzeResultReorderSummary';
      count: number;
      totalDuration: number;
      totalCost: number;
      totalReorderedWeight: number;
    } | null;
    maxReorderAppliedSummary?: {
      __typename?: 'AnalyzeResultReorderSummary';
      count: number;
      totalDuration: number;
      totalCost: number;
      totalReorderedWeight: number;
    } | null;
    costDetails?: {
      __typename?: 'AnalyzeResultCostDetails';
      processes: Array<{
        __typename?: 'AnalyzeResultProcessCost';
        processType: AnalyzeResultProcessType;
        distance: number;
        duration: number;
        cost: number;
      }>;
    } | null;
    summary?: {
      __typename?: 'AnalyzeResultSummary';
      eventTypes: Array<{
        __typename?: 'AnalyzeResultEventTypeSummary';
        eventType: PicklistEventType;
        processType?: AnalyzeResultProcessType | null;
        count: number;
        totalDuration: number;
        totalCost: number;
      }>;
      agents: Array<{
        __typename?: 'AnalyzeResultAgentSummary';
        agent: string;
        eventTypes: Array<{
          __typename?: 'AnalyzeResultEventTypeSummary';
          eventType: PicklistEventType;
          processType?: AnalyzeResultProcessType | null;
          count: number;
          totalDuration: number;
          totalCost: number;
        }>;
      }>;
      assignment?: {
        __typename?: 'AnalyzeResultAssignmentSummary';
        totalStoredItems?: number | null;
      } | null;
    } | null;
    resourcePolicy?: {
      __typename?: 'ResourcePolicy';
      agents: Array<{
        __typename?: 'AgentSettings';
        id: string;
        specs?: {
          __typename?: 'AgentSpecs';
          powerSource?: {
            __typename?: 'AgentPowerSourceSettings';
            energyConsumptionPerSecond?: number | null;
          } | null;
        } | null;
      }>;
    } | null;
  };
};

export type AllocationSummaryKeyFragment = {
  __typename?: 'AllocationRunResultRequirementSummaryKey';
  itemId?: string | null;
  consignee?: string | null;
  orderLineRuleId?: string | null;
  sku?: string | null;
  skuGroup?: string | null;
  subGroup?: string | null;
  transportClass?: string | null;
  stockCategory?: string | null;
  storageClass?: string | null;
  pollutionClass?: string | null;
};

export type AllocationSummaryRequirementDetailsFragment = {
  __typename?: 'AllocationRunResultRequirementQuantity';
  totalQuantity?: number | null;
  uoms?: Array<{
    __typename?: 'AllocationRequirementUomQuantity';
    uom?: string | null;
    uomQuantity: number;
  }> | null;
};

export type AllocationSummaryUnallocatedDetailsFragment = {
  __typename?: 'AllocationRunResultRequirementUnallocatedQuantity';
  totalQuantity?: number | null;
  reasons?: Array<AllocationRunResultUnallocatedReason> | null;
  uoms?: Array<{
    __typename?: 'AllocationRequirementUomQuantity';
    uom?: string | null;
    uomQuantity: number;
  }> | null;
};

export type AllocationSummaryPickableDetailsFragment = {
  __typename?: 'AllocationRunResultRequirementPickableQuantitySummary';
  totalQuantity?: number | null;
  totalLocationCount?: number | null;
  uoms?: Array<{
    __typename?: 'AllocationRequirementUomQuantity';
    uom?: string | null;
    uomQuantity: number;
    totalVolume: number;
  }> | null;
};

export type AllocationSummaryDeallocatedDetailsFragment = {
  __typename?: 'AllocationRunResultRequirementDeallocatedQuantitySummary';
  totalQuantity?: number | null;
  totalLocationCount?: number | null;
  reasons?: Array<AllocationRunResultDeallocatedReason> | null;
  uoms?: Array<{
    __typename?: 'AllocationRequirementUomQuantity';
    uom?: string | null;
    uomQuantity: number;
    totalVolume: number;
  }> | null;
};

export type AllocationRunResultRequirementQuantityFragment = {
  __typename?: 'AllocationRunResultRequirementQuantity';
  totalQuantity?: number | null;
  uoms?: Array<{
    __typename?: 'AllocationRequirementUomQuantity';
    uom?: string | null;
    uomQuantity: number;
    totalVolume: number;
    aggregatedTotalVolume?: number | null;
    aggregatedQuantity?: number | null;
    aggregatedUomQuantity?: number | null;
  }> | null;
};

export type AllocationSummaryFragment = {
  __typename?: 'AllocationRunResultRequirementSummary';
  key: {
    __typename?: 'AllocationRunResultRequirementSummaryKey';
    itemId?: string | null;
    consignee?: string | null;
    orderLineRuleId?: string | null;
    sku?: string | null;
    skuGroup?: string | null;
    subGroup?: string | null;
    transportClass?: string | null;
    stockCategory?: string | null;
    storageClass?: string | null;
    pollutionClass?: string | null;
  };
  required?: {
    __typename?: 'AllocationRunResultRequirementQuantity';
    totalQuantity?: number | null;
    uoms?: Array<{
      __typename?: 'AllocationRequirementUomQuantity';
      uom?: string | null;
      uomQuantity: number;
    }> | null;
  } | null;
  unpickable?: {
    __typename?: 'AllocationRunResultRequirementQuantity';
    totalQuantity?: number | null;
    uoms?: Array<{
      __typename?: 'AllocationRequirementUomQuantity';
      uom?: string | null;
      uomQuantity: number;
    }> | null;
  } | null;
  initiallyPickable?: {
    __typename?: 'AllocationRunResultRequirementPickableQuantitySummary';
    totalQuantity?: number | null;
    totalLocationCount?: number | null;
    uoms?: Array<{
      __typename?: 'AllocationRequirementUomQuantity';
      uom?: string | null;
      uomQuantity: number;
      totalVolume: number;
    }> | null;
  } | null;
  initiallyPickableFulfilled?: {
    __typename?: 'AllocationRunResultRequirementPickableQuantitySummary';
    totalQuantity?: number | null;
    totalLocationCount?: number | null;
    uoms?: Array<{
      __typename?: 'AllocationRequirementUomQuantity';
      uom?: string | null;
      uomQuantity: number;
      totalVolume: number;
    }> | null;
  } | null;
  toAllocateRequired?: {
    __typename?: 'AllocationRunResultRequirementQuantity';
    totalQuantity?: number | null;
    uoms?: Array<{
      __typename?: 'AllocationRequirementUomQuantity';
      uom?: string | null;
      uomQuantity: number;
      totalVolume: number;
      aggregatedTotalVolume?: number | null;
      aggregatedQuantity?: number | null;
      aggregatedUomQuantity?: number | null;
    }> | null;
  } | null;
  toReallocate?: {
    __typename?: 'AllocationRunResultRequirementQuantity';
    totalQuantity?: number | null;
    uoms?: Array<{
      __typename?: 'AllocationRequirementUomQuantity';
      uom?: string | null;
      uomQuantity: number;
      totalVolume: number;
      aggregatedTotalVolume?: number | null;
      aggregatedQuantity?: number | null;
      aggregatedUomQuantity?: number | null;
    }> | null;
  } | null;
  toAllocate?: {
    __typename?: 'AllocationRunResultRequirementQuantity';
    totalQuantity?: number | null;
    uoms?: Array<{
      __typename?: 'AllocationRequirementUomQuantity';
      uom?: string | null;
      uomQuantity: number;
      totalVolume: number;
      aggregatedTotalVolume?: number | null;
      aggregatedQuantity?: number | null;
      aggregatedUomQuantity?: number | null;
    }> | null;
  } | null;
  allocated?: {
    __typename?: 'AllocationRunResultRequirementPickableQuantitySummary';
    totalQuantity?: number | null;
    totalLocationCount?: number | null;
    uoms?: Array<{
      __typename?: 'AllocationRequirementUomQuantity';
      uom?: string | null;
      uomQuantity: number;
      totalVolume: number;
    }> | null;
  } | null;
  allocatedShared?: {
    __typename?: 'AllocationRunResultRequirementPickableQuantitySummary';
    totalQuantity?: number | null;
    totalLocationCount?: number | null;
    uoms?: Array<{
      __typename?: 'AllocationRequirementUomQuantity';
      uom?: string | null;
      uomQuantity: number;
      totalVolume: number;
    }> | null;
  } | null;
  allocatedFulfilled?: {
    __typename?: 'AllocationRunResultRequirementPickableQuantitySummary';
    totalQuantity?: number | null;
    totalLocationCount?: number | null;
    uoms?: Array<{
      __typename?: 'AllocationRequirementUomQuantity';
      uom?: string | null;
      uomQuantity: number;
      totalVolume: number;
    }> | null;
  } | null;
  unallocated?: {
    __typename?: 'AllocationRunResultRequirementUnallocatedQuantity';
    totalQuantity?: number | null;
    reasons?: Array<AllocationRunResultUnallocatedReason> | null;
    uoms?: Array<{
      __typename?: 'AllocationRequirementUomQuantity';
      uom?: string | null;
      uomQuantity: number;
    }> | null;
  } | null;
  deallocated?: {
    __typename?: 'AllocationRunResultRequirementDeallocatedQuantitySummary';
    totalQuantity?: number | null;
    totalLocationCount?: number | null;
    reasons?: Array<AllocationRunResultDeallocatedReason> | null;
    uoms?: Array<{
      __typename?: 'AllocationRequirementUomQuantity';
      uom?: string | null;
      uomQuantity: number;
      totalVolume: number;
    }> | null;
  } | null;
};

export type LoadAllocationSummaryQueryVariables = Exact<{
  simulationId: Scalars['ID']['input'];
  groupBy?: InputMaybe<
    | Array<AllocationRunResultRequirementGroupBy>
    | AllocationRunResultRequirementGroupBy
  >;
  filter?: InputMaybe<AllocationRunResultRequirementsFilter>;
}>;

export type LoadAllocationSummaryQuery = {
  __typename?: 'Query';
  simulation?: {
    __typename?: 'Simulation';
    latestAllocationRun?: {
      __typename?: 'AllocationRun';
      resultRequirementsSummary: Array<{
        __typename?: 'AllocationRunResultRequirementSummary';
        key: {
          __typename?: 'AllocationRunResultRequirementSummaryKey';
          itemId?: string | null;
          consignee?: string | null;
          orderLineRuleId?: string | null;
          sku?: string | null;
          skuGroup?: string | null;
          subGroup?: string | null;
          transportClass?: string | null;
          stockCategory?: string | null;
          storageClass?: string | null;
          pollutionClass?: string | null;
        };
        required?: {
          __typename?: 'AllocationRunResultRequirementQuantity';
          totalQuantity?: number | null;
          uoms?: Array<{
            __typename?: 'AllocationRequirementUomQuantity';
            uom?: string | null;
            uomQuantity: number;
          }> | null;
        } | null;
        unpickable?: {
          __typename?: 'AllocationRunResultRequirementQuantity';
          totalQuantity?: number | null;
          uoms?: Array<{
            __typename?: 'AllocationRequirementUomQuantity';
            uom?: string | null;
            uomQuantity: number;
          }> | null;
        } | null;
        initiallyPickable?: {
          __typename?: 'AllocationRunResultRequirementPickableQuantitySummary';
          totalQuantity?: number | null;
          totalLocationCount?: number | null;
          uoms?: Array<{
            __typename?: 'AllocationRequirementUomQuantity';
            uom?: string | null;
            uomQuantity: number;
            totalVolume: number;
          }> | null;
        } | null;
        initiallyPickableFulfilled?: {
          __typename?: 'AllocationRunResultRequirementPickableQuantitySummary';
          totalQuantity?: number | null;
          totalLocationCount?: number | null;
          uoms?: Array<{
            __typename?: 'AllocationRequirementUomQuantity';
            uom?: string | null;
            uomQuantity: number;
            totalVolume: number;
          }> | null;
        } | null;
        toAllocateRequired?: {
          __typename?: 'AllocationRunResultRequirementQuantity';
          totalQuantity?: number | null;
          uoms?: Array<{
            __typename?: 'AllocationRequirementUomQuantity';
            uom?: string | null;
            uomQuantity: number;
            totalVolume: number;
            aggregatedTotalVolume?: number | null;
            aggregatedQuantity?: number | null;
            aggregatedUomQuantity?: number | null;
          }> | null;
        } | null;
        toReallocate?: {
          __typename?: 'AllocationRunResultRequirementQuantity';
          totalQuantity?: number | null;
          uoms?: Array<{
            __typename?: 'AllocationRequirementUomQuantity';
            uom?: string | null;
            uomQuantity: number;
            totalVolume: number;
            aggregatedTotalVolume?: number | null;
            aggregatedQuantity?: number | null;
            aggregatedUomQuantity?: number | null;
          }> | null;
        } | null;
        toAllocate?: {
          __typename?: 'AllocationRunResultRequirementQuantity';
          totalQuantity?: number | null;
          uoms?: Array<{
            __typename?: 'AllocationRequirementUomQuantity';
            uom?: string | null;
            uomQuantity: number;
            totalVolume: number;
            aggregatedTotalVolume?: number | null;
            aggregatedQuantity?: number | null;
            aggregatedUomQuantity?: number | null;
          }> | null;
        } | null;
        allocated?: {
          __typename?: 'AllocationRunResultRequirementPickableQuantitySummary';
          totalQuantity?: number | null;
          totalLocationCount?: number | null;
          uoms?: Array<{
            __typename?: 'AllocationRequirementUomQuantity';
            uom?: string | null;
            uomQuantity: number;
            totalVolume: number;
          }> | null;
        } | null;
        allocatedShared?: {
          __typename?: 'AllocationRunResultRequirementPickableQuantitySummary';
          totalQuantity?: number | null;
          totalLocationCount?: number | null;
          uoms?: Array<{
            __typename?: 'AllocationRequirementUomQuantity';
            uom?: string | null;
            uomQuantity: number;
            totalVolume: number;
          }> | null;
        } | null;
        allocatedFulfilled?: {
          __typename?: 'AllocationRunResultRequirementPickableQuantitySummary';
          totalQuantity?: number | null;
          totalLocationCount?: number | null;
          uoms?: Array<{
            __typename?: 'AllocationRequirementUomQuantity';
            uom?: string | null;
            uomQuantity: number;
            totalVolume: number;
          }> | null;
        } | null;
        unallocated?: {
          __typename?: 'AllocationRunResultRequirementUnallocatedQuantity';
          totalQuantity?: number | null;
          reasons?: Array<AllocationRunResultUnallocatedReason> | null;
          uoms?: Array<{
            __typename?: 'AllocationRequirementUomQuantity';
            uom?: string | null;
            uomQuantity: number;
          }> | null;
        } | null;
        deallocated?: {
          __typename?: 'AllocationRunResultRequirementDeallocatedQuantitySummary';
          totalQuantity?: number | null;
          totalLocationCount?: number | null;
          reasons?: Array<AllocationRunResultDeallocatedReason> | null;
          uoms?: Array<{
            __typename?: 'AllocationRequirementUomQuantity';
            uom?: string | null;
            uomQuantity: number;
            totalVolume: number;
          }> | null;
        } | null;
      }>;
    } | null;
  } | null;
};

export type AllocationRequirementRuleUomDetailsFragment = {
  __typename?: 'AllocationRequirementUomQuantity';
  uom?: string | null;
  uomQuantity: number;
  quantity: number;
  totalVolume: number;
  aggregatedTotalVolume?: number | null;
  aggregatedQuantity?: number | null;
  aggregatedUomQuantity?: number | null;
};

export type AllocationRequirementRuleDetailsFragment = {
  __typename?: 'AllocationRequirementRuleDetails';
  pickingRuleId: string;
  requiredQuantity: number;
  preferredUoms: Array<{
    __typename?: 'AllocationRequirementUomQuantity';
    uom?: string | null;
    uomQuantity: number;
    quantity: number;
    totalVolume: number;
    aggregatedTotalVolume?: number | null;
    aggregatedQuantity?: number | null;
    aggregatedUomQuantity?: number | null;
  }>;
};

export type AllocationRequirementDetailsFragment = {
  __typename?: 'AllocationRequirementDetails';
  rules?: Array<{
    __typename?: 'AllocationRequirementRuleDetails';
    pickingRuleId: string;
    requiredQuantity: number;
    preferredUoms: Array<{
      __typename?: 'AllocationRequirementUomQuantity';
      uom?: string | null;
      uomQuantity: number;
      quantity: number;
      totalVolume: number;
      aggregatedTotalVolume?: number | null;
      aggregatedQuantity?: number | null;
      aggregatedUomQuantity?: number | null;
    }>;
  }> | null;
};

export type AllocationRequirementFragment = {
  __typename?: 'AllocationRequirement';
  itemId: string;
  consignee: string;
  sku: string;
  skuGroup?: string | null;
  subGroup?: string | null;
  transportClass?: string | null;
  stockCategory?: string | null;
  storageClass?: string | null;
  pollutionClass?: string | null;
  orderLineRuleId: string;
  requiredQuantity: number;
  preferredRequiredVolume: number;
  unpickableRequiredQuantity: number;
  aggregatedRequiredQuantity?: number | null;
  aggregatedPreferredRequiredVolume?: number | null;
  aggregatedUnpickableRequiredQuantity?: number | null;
  details?: {
    __typename?: 'AllocationRequirementDetails';
    rules?: Array<{
      __typename?: 'AllocationRequirementRuleDetails';
      pickingRuleId: string;
      requiredQuantity: number;
      preferredUoms: Array<{
        __typename?: 'AllocationRequirementUomQuantity';
        uom?: string | null;
        uomQuantity: number;
        quantity: number;
        totalVolume: number;
        aggregatedTotalVolume?: number | null;
        aggregatedQuantity?: number | null;
        aggregatedUomQuantity?: number | null;
      }>;
    }> | null;
  } | null;
};

export type AllocationRequirementsConnectionFragment = {
  __typename?: 'AllocationRequirementsConnection';
  totalCount: number;
  content: Array<{
    __typename?: 'AllocationRequirement';
    itemId: string;
    consignee: string;
    sku: string;
    skuGroup?: string | null;
    subGroup?: string | null;
    transportClass?: string | null;
    stockCategory?: string | null;
    storageClass?: string | null;
    pollutionClass?: string | null;
    orderLineRuleId: string;
    requiredQuantity: number;
    preferredRequiredVolume: number;
    unpickableRequiredQuantity: number;
    aggregatedRequiredQuantity?: number | null;
    aggregatedPreferredRequiredVolume?: number | null;
    aggregatedUnpickableRequiredQuantity?: number | null;
    details?: {
      __typename?: 'AllocationRequirementDetails';
      rules?: Array<{
        __typename?: 'AllocationRequirementRuleDetails';
        pickingRuleId: string;
        requiredQuantity: number;
        preferredUoms: Array<{
          __typename?: 'AllocationRequirementUomQuantity';
          uom?: string | null;
          uomQuantity: number;
          quantity: number;
          totalVolume: number;
          aggregatedTotalVolume?: number | null;
          aggregatedQuantity?: number | null;
          aggregatedUomQuantity?: number | null;
        }>;
      }> | null;
    } | null;
  }>;
};

export type AllocationRequirementSetMetaFragment = {
  __typename?: 'AllocationRequirementSet';
  id: string;
  status: BatchJobStatus;
  progress: number;
};

export type AllocationRequirementSummaryFragment = {
  __typename?: 'AllocationRequirementSummary';
  allocationRequirementCount: number;
  itemCount: number;
  requiredQuantity: number;
  preferredRequiredVolume: number;
  unpickableRequiredQuantity: number;
  aggregatedRequiredQuantity?: number | null;
  aggregatedPreferredRequiredVolume?: number | null;
  aggregatedUnpickableRequiredQuantity?: number | null;
  key: {
    __typename?: 'AllocationRequirementSummaryKey';
    itemId?: string | null;
    sku?: string | null;
    consignee?: string | null;
    orderLineRuleId?: string | null;
    possibleLocationMask?: string | null;
    requiredLocationMask?: string | null;
  };
};

export type AllocationRequirementSummaryKeyFragment = {
  __typename?: 'AllocationRequirementSummaryKey';
  itemId?: string | null;
  sku?: string | null;
  consignee?: string | null;
  orderLineRuleId?: string | null;
  possibleLocationMask?: string | null;
  requiredLocationMask?: string | null;
};

export type LoadAllocationRequirementSummaryQueryVariables = Exact<{
  simulationId: Scalars['ID']['input'];
  groupBy?: InputMaybe<
    Array<AllocationRequirementGroupBy> | AllocationRequirementGroupBy
  >;
  filter?: InputMaybe<AllocationRequirementsFilter>;
}>;

export type LoadAllocationRequirementSummaryQuery = {
  __typename?: 'Query';
  simulation?: {
    __typename?: 'Simulation';
    allocationRequirementSet?: {
      __typename?: 'AllocationRequirementSet';
      id: string;
      status: BatchJobStatus;
      progress: number;
      requirementsSummary: Array<{
        __typename?: 'AllocationRequirementSummary';
        allocationRequirementCount: number;
        itemCount: number;
        requiredQuantity: number;
        preferredRequiredVolume: number;
        unpickableRequiredQuantity: number;
        aggregatedRequiredQuantity?: number | null;
        aggregatedPreferredRequiredVolume?: number | null;
        aggregatedUnpickableRequiredQuantity?: number | null;
        key: {
          __typename?: 'AllocationRequirementSummaryKey';
          itemId?: string | null;
          sku?: string | null;
          consignee?: string | null;
          orderLineRuleId?: string | null;
          possibleLocationMask?: string | null;
          requiredLocationMask?: string | null;
        };
      }>;
    } | null;
  } | null;
};

export type LoadAllocationRequirementItemsQueryVariables = Exact<{
  simulationId: Scalars['ID']['input'];
  sortBy?: InputMaybe<
    Array<AllocationRequirementSortBy> | AllocationRequirementSortBy
  >;
  page?: InputMaybe<SkipLimitPageSpec>;
  filter?: InputMaybe<AllocationRequirementsFilter>;
}>;

export type LoadAllocationRequirementItemsQuery = {
  __typename?: 'Query';
  simulation?: {
    __typename?: 'Simulation';
    allocationRequirementSet?: {
      __typename?: 'AllocationRequirementSet';
      id: string;
      status: BatchJobStatus;
      progress: number;
      requirements: {
        __typename?: 'AllocationRequirementsConnection';
        totalCount: number;
        content: Array<{
          __typename?: 'AllocationRequirement';
          itemId: string;
          consignee: string;
          sku: string;
          skuGroup?: string | null;
          subGroup?: string | null;
          transportClass?: string | null;
          stockCategory?: string | null;
          storageClass?: string | null;
          pollutionClass?: string | null;
          orderLineRuleId: string;
          requiredQuantity: number;
          preferredRequiredVolume: number;
          unpickableRequiredQuantity: number;
          aggregatedRequiredQuantity?: number | null;
          aggregatedPreferredRequiredVolume?: number | null;
          aggregatedUnpickableRequiredQuantity?: number | null;
          details?: {
            __typename?: 'AllocationRequirementDetails';
            rules?: Array<{
              __typename?: 'AllocationRequirementRuleDetails';
              pickingRuleId: string;
              requiredQuantity: number;
              preferredUoms: Array<{
                __typename?: 'AllocationRequirementUomQuantity';
                uom?: string | null;
                uomQuantity: number;
                quantity: number;
                totalVolume: number;
                aggregatedTotalVolume?: number | null;
                aggregatedQuantity?: number | null;
                aggregatedUomQuantity?: number | null;
              }>;
            }> | null;
          } | null;
        }>;
      };
    } | null;
  } | null;
};

export type LoadAllocationRequirementMetaQueryVariables = Exact<{
  allocationRequirementId: Scalars['String']['input'];
}>;

export type LoadAllocationRequirementMetaQuery = {
  __typename?: 'Query';
  allocationRequirementSet?: {
    __typename?: 'AllocationRequirementSet';
    id: string;
    status: BatchJobStatus;
    progress: number;
  } | null;
};

export type RunCalculateAllocationRequirementMutationVariables = Exact<{
  simId: Scalars['ID']['input'];
}>;

export type RunCalculateAllocationRequirementMutation = {
  __typename?: 'Mutation';
  calculateSimulationAllocationRequirements: {
    __typename?: 'AllocationRequirementSet';
    id: string;
    status: BatchJobStatus;
    progress: number;
  };
};

export type AllocationLimitSettingsFragment = {
  __typename?: 'AllocationLimitSettings';
  applyTo?: AllocationLimitApplyTo | null;
  maxPickableAssignments?: number | null;
  maxAllocatedAssignments?: number | null;
  maxPickableVolume?: number | null;
  maxAllocatedVolume?: number | null;
};

export type AllocationRoundSettingsFragment = {
  __typename?: 'AllocationRoundSettings';
  maxAssignmentsPerRound?: number | null;
};

export type OrderSetFilterFragment = {
  __typename?: 'OrderSetFilter';
  orderDate?: {
    __typename?: 'LocalDateRangeFilter';
    from?: string | null;
    to?: string | null;
    excludeFrom?: boolean | null;
    excludeTo?: boolean | null;
    includeNull?: boolean | null;
  } | null;
};

export type AllocationPrioritySettingsFragment = {
  __typename?: 'AllocationPrioritySettings';
  itemPriority?: Array<{
    __typename?: 'AllocationItemPrioritySpec';
    priority: AllocationItemPriority;
    direction?: AllocationPriorityDirection | null;
  }> | null;
  locationPriority?: Array<{
    __typename?: 'AllocationLocationPrioritySpec';
    priority: AllocationLocationPriority;
    direction?: AllocationPriorityDirection | null;
  }> | null;
};

export type AllocationReplacementSettingsFragment = {
  __typename?: 'AllocationReplacementSettings';
  allow?: AllocationAllowReplacement | null;
};

export type AllocationMultiplexingSettingsFragment = {
  __typename?: 'AllocationMultiplexingSettings';
  allow?: AllocationAllowReplacement | null;
};

export type AllocationConstraintSettingsFragment = {
  __typename?: 'AllocationConstraintSettings';
  assignmentPolicyConstraint?: {
    __typename?: 'AssignmentPolicyConstraintSettings';
    disabled?: boolean | null;
  } | null;
  locationSizeConstraint?: {
    __typename?: 'LocationSizeConstraintSettings';
    disabled?: boolean | null;
    unknownAsNoncompliant?: boolean | null;
  } | null;
  bayWidthConstraint?: {
    __typename?: 'BayWidthConstraintSettings';
    disabled?: boolean | null;
  } | null;
};

export type AllocationRequirementAggregationSettingsFragment = {
  __typename?: 'AllocationRequirementAggregationSettings';
  aggregation?: AllocationRequirementAggregationMethod | null;
  numberOfPeriods?: number | null;
};

export type AllocationPickabilitySettingsFragment = {
  __typename?: 'AllocationPickabilitySettings';
  enforcePickability?: AllocationEnforcePickability | null;
};

export type AllocationRuleFragment = {
  __typename?: 'AllocationRule';
  id: string;
  title?: string | null;
  uomTypes?: Array<string> | null;
  itemsMatch?: {
    __typename?: 'SimulationItemFilterUnion';
    anyOf: Array<{
      __typename?: 'SimulationItemFilterIntersection';
      id?: string | null;
      allOf: Array<{
        __typename?: 'SimulationItemFilter';
        type: SimulationItemFilterType;
        isNull?: boolean | null;
        isNot?: boolean | null;
        valueIn?: Array<{ __typename?: 'FilterValue'; title: string }> | null;
        range?: {
          __typename?: 'NumberFilterRange';
          from?: number | null;
          to?: number | null;
        } | null;
        stringRange?: {
          __typename?: 'StringFilterRange';
          from?: string | null;
          excludeFrom?: boolean | null;
          to?: string | null;
          excludeTo?: boolean | null;
        } | null;
      }>;
    }>;
  } | null;
  locationsMatch?: {
    __typename?: 'LocationFilterUnion';
    anyOf: Array<{
      __typename?: 'LocationFilterIntersection';
      id?: string | null;
      allOf: Array<{
        __typename?: 'LocationFilter';
        type: LocationFilterType;
        isNull?: boolean | null;
        isNot?: boolean | null;
        valueIn?: Array<{ __typename?: 'FilterValue'; title: string }> | null;
        range?: {
          __typename?: 'NumberFilterRange';
          from?: number | null;
          to?: number | null;
        } | null;
        stringRange?: {
          __typename?: 'StringFilterRange';
          from?: string | null;
          excludeFrom?: boolean | null;
          to?: string | null;
          excludeTo?: boolean | null;
        } | null;
      }>;
    }>;
  } | null;
  limitSettings?: {
    __typename?: 'AllocationLimitSettings';
    applyTo?: AllocationLimitApplyTo | null;
    maxPickableAssignments?: number | null;
    maxAllocatedAssignments?: number | null;
    maxPickableVolume?: number | null;
    maxAllocatedVolume?: number | null;
  } | null;
  roundSettings?: {
    __typename?: 'AllocationRoundSettings';
    maxAssignmentsPerRound?: number | null;
  } | null;
  pickabilitySettings?: {
    __typename?: 'AllocationPickabilitySettings';
    enforcePickability?: AllocationEnforcePickability | null;
  } | null;
  prioritySettings?: {
    __typename?: 'AllocationPrioritySettings';
    itemPriority?: Array<{
      __typename?: 'AllocationItemPrioritySpec';
      priority: AllocationItemPriority;
      direction?: AllocationPriorityDirection | null;
    }> | null;
    locationPriority?: Array<{
      __typename?: 'AllocationLocationPrioritySpec';
      priority: AllocationLocationPriority;
      direction?: AllocationPriorityDirection | null;
    }> | null;
  } | null;
  replacementSettings?: {
    __typename?: 'AllocationReplacementSettings';
    allow?: AllocationAllowReplacement | null;
  } | null;
  multiplexingSettings?: {
    __typename?: 'AllocationMultiplexingSettings';
    allow?: AllocationAllowReplacement | null;
  } | null;
  requirementAggregationSettings?: {
    __typename?: 'AllocationRequirementAggregationSettings';
    aggregation?: AllocationRequirementAggregationMethod | null;
    numberOfPeriods?: number | null;
  } | null;
  constraintSettings?: {
    __typename?: 'AllocationConstraintSettings';
    assignmentPolicyConstraint?: {
      __typename?: 'AssignmentPolicyConstraintSettings';
      disabled?: boolean | null;
    } | null;
    locationSizeConstraint?: {
      __typename?: 'LocationSizeConstraintSettings';
      disabled?: boolean | null;
      unknownAsNoncompliant?: boolean | null;
    } | null;
    bayWidthConstraint?: {
      __typename?: 'BayWidthConstraintSettings';
      disabled?: boolean | null;
    } | null;
  } | null;
};

export type AllocationPolicyFragment = {
  __typename?: 'AllocationPolicy';
  rules?: Array<{
    __typename?: 'AllocationRule';
    id: string;
    title?: string | null;
    uomTypes?: Array<string> | null;
    itemsMatch?: {
      __typename?: 'SimulationItemFilterUnion';
      anyOf: Array<{
        __typename?: 'SimulationItemFilterIntersection';
        id?: string | null;
        allOf: Array<{
          __typename?: 'SimulationItemFilter';
          type: SimulationItemFilterType;
          isNull?: boolean | null;
          isNot?: boolean | null;
          valueIn?: Array<{ __typename?: 'FilterValue'; title: string }> | null;
          range?: {
            __typename?: 'NumberFilterRange';
            from?: number | null;
            to?: number | null;
          } | null;
          stringRange?: {
            __typename?: 'StringFilterRange';
            from?: string | null;
            excludeFrom?: boolean | null;
            to?: string | null;
            excludeTo?: boolean | null;
          } | null;
        }>;
      }>;
    } | null;
    locationsMatch?: {
      __typename?: 'LocationFilterUnion';
      anyOf: Array<{
        __typename?: 'LocationFilterIntersection';
        id?: string | null;
        allOf: Array<{
          __typename?: 'LocationFilter';
          type: LocationFilterType;
          isNull?: boolean | null;
          isNot?: boolean | null;
          valueIn?: Array<{ __typename?: 'FilterValue'; title: string }> | null;
          range?: {
            __typename?: 'NumberFilterRange';
            from?: number | null;
            to?: number | null;
          } | null;
          stringRange?: {
            __typename?: 'StringFilterRange';
            from?: string | null;
            excludeFrom?: boolean | null;
            to?: string | null;
            excludeTo?: boolean | null;
          } | null;
        }>;
      }>;
    } | null;
    limitSettings?: {
      __typename?: 'AllocationLimitSettings';
      applyTo?: AllocationLimitApplyTo | null;
      maxPickableAssignments?: number | null;
      maxAllocatedAssignments?: number | null;
      maxPickableVolume?: number | null;
      maxAllocatedVolume?: number | null;
    } | null;
    roundSettings?: {
      __typename?: 'AllocationRoundSettings';
      maxAssignmentsPerRound?: number | null;
    } | null;
    pickabilitySettings?: {
      __typename?: 'AllocationPickabilitySettings';
      enforcePickability?: AllocationEnforcePickability | null;
    } | null;
    prioritySettings?: {
      __typename?: 'AllocationPrioritySettings';
      itemPriority?: Array<{
        __typename?: 'AllocationItemPrioritySpec';
        priority: AllocationItemPriority;
        direction?: AllocationPriorityDirection | null;
      }> | null;
      locationPriority?: Array<{
        __typename?: 'AllocationLocationPrioritySpec';
        priority: AllocationLocationPriority;
        direction?: AllocationPriorityDirection | null;
      }> | null;
    } | null;
    replacementSettings?: {
      __typename?: 'AllocationReplacementSettings';
      allow?: AllocationAllowReplacement | null;
    } | null;
    multiplexingSettings?: {
      __typename?: 'AllocationMultiplexingSettings';
      allow?: AllocationAllowReplacement | null;
    } | null;
    requirementAggregationSettings?: {
      __typename?: 'AllocationRequirementAggregationSettings';
      aggregation?: AllocationRequirementAggregationMethod | null;
      numberOfPeriods?: number | null;
    } | null;
    constraintSettings?: {
      __typename?: 'AllocationConstraintSettings';
      assignmentPolicyConstraint?: {
        __typename?: 'AssignmentPolicyConstraintSettings';
        disabled?: boolean | null;
      } | null;
      locationSizeConstraint?: {
        __typename?: 'LocationSizeConstraintSettings';
        disabled?: boolean | null;
        unknownAsNoncompliant?: boolean | null;
      } | null;
      bayWidthConstraint?: {
        __typename?: 'BayWidthConstraintSettings';
        disabled?: boolean | null;
      } | null;
    } | null;
  }> | null;
  defaultRule?: {
    __typename?: 'AllocationRule';
    id: string;
    title?: string | null;
    uomTypes?: Array<string> | null;
    itemsMatch?: {
      __typename?: 'SimulationItemFilterUnion';
      anyOf: Array<{
        __typename?: 'SimulationItemFilterIntersection';
        id?: string | null;
        allOf: Array<{
          __typename?: 'SimulationItemFilter';
          type: SimulationItemFilterType;
          isNull?: boolean | null;
          isNot?: boolean | null;
          valueIn?: Array<{ __typename?: 'FilterValue'; title: string }> | null;
          range?: {
            __typename?: 'NumberFilterRange';
            from?: number | null;
            to?: number | null;
          } | null;
          stringRange?: {
            __typename?: 'StringFilterRange';
            from?: string | null;
            excludeFrom?: boolean | null;
            to?: string | null;
            excludeTo?: boolean | null;
          } | null;
        }>;
      }>;
    } | null;
    locationsMatch?: {
      __typename?: 'LocationFilterUnion';
      anyOf: Array<{
        __typename?: 'LocationFilterIntersection';
        id?: string | null;
        allOf: Array<{
          __typename?: 'LocationFilter';
          type: LocationFilterType;
          isNull?: boolean | null;
          isNot?: boolean | null;
          valueIn?: Array<{ __typename?: 'FilterValue'; title: string }> | null;
          range?: {
            __typename?: 'NumberFilterRange';
            from?: number | null;
            to?: number | null;
          } | null;
          stringRange?: {
            __typename?: 'StringFilterRange';
            from?: string | null;
            excludeFrom?: boolean | null;
            to?: string | null;
            excludeTo?: boolean | null;
          } | null;
        }>;
      }>;
    } | null;
    limitSettings?: {
      __typename?: 'AllocationLimitSettings';
      applyTo?: AllocationLimitApplyTo | null;
      maxPickableAssignments?: number | null;
      maxAllocatedAssignments?: number | null;
      maxPickableVolume?: number | null;
      maxAllocatedVolume?: number | null;
    } | null;
    roundSettings?: {
      __typename?: 'AllocationRoundSettings';
      maxAssignmentsPerRound?: number | null;
    } | null;
    pickabilitySettings?: {
      __typename?: 'AllocationPickabilitySettings';
      enforcePickability?: AllocationEnforcePickability | null;
    } | null;
    prioritySettings?: {
      __typename?: 'AllocationPrioritySettings';
      itemPriority?: Array<{
        __typename?: 'AllocationItemPrioritySpec';
        priority: AllocationItemPriority;
        direction?: AllocationPriorityDirection | null;
      }> | null;
      locationPriority?: Array<{
        __typename?: 'AllocationLocationPrioritySpec';
        priority: AllocationLocationPriority;
        direction?: AllocationPriorityDirection | null;
      }> | null;
    } | null;
    replacementSettings?: {
      __typename?: 'AllocationReplacementSettings';
      allow?: AllocationAllowReplacement | null;
    } | null;
    multiplexingSettings?: {
      __typename?: 'AllocationMultiplexingSettings';
      allow?: AllocationAllowReplacement | null;
    } | null;
    requirementAggregationSettings?: {
      __typename?: 'AllocationRequirementAggregationSettings';
      aggregation?: AllocationRequirementAggregationMethod | null;
      numberOfPeriods?: number | null;
    } | null;
    constraintSettings?: {
      __typename?: 'AllocationConstraintSettings';
      assignmentPolicyConstraint?: {
        __typename?: 'AssignmentPolicyConstraintSettings';
        disabled?: boolean | null;
      } | null;
      locationSizeConstraint?: {
        __typename?: 'LocationSizeConstraintSettings';
        disabled?: boolean | null;
        unknownAsNoncompliant?: boolean | null;
      } | null;
      bayWidthConstraint?: {
        __typename?: 'BayWidthConstraintSettings';
        disabled?: boolean | null;
      } | null;
    } | null;
  } | null;
};

export type DeallocationRuleFragment = {
  __typename?: 'DeallocationRule';
  id?: string | null;
  title?: string | null;
  deallocateType?: DeallocateType | null;
  reallocateType?: ReallocateType | null;
  itemsMatch?: {
    __typename?: 'SimulationItemFilterUnion';
    anyOf: Array<{
      __typename?: 'SimulationItemFilterIntersection';
      id?: string | null;
      allOf: Array<{
        __typename?: 'SimulationItemFilter';
        type: SimulationItemFilterType;
        isNull?: boolean | null;
        isNot?: boolean | null;
        valueIn?: Array<{ __typename?: 'FilterValue'; title: string }> | null;
        range?: {
          __typename?: 'NumberFilterRange';
          from?: number | null;
          to?: number | null;
        } | null;
        stringRange?: {
          __typename?: 'StringFilterRange';
          from?: string | null;
          excludeFrom?: boolean | null;
          to?: string | null;
          excludeTo?: boolean | null;
        } | null;
      }>;
    }>;
  } | null;
  locationsMatch?: {
    __typename?: 'LocationFilterUnion';
    anyOf: Array<{
      __typename?: 'LocationFilterIntersection';
      id?: string | null;
      allOf: Array<{
        __typename?: 'LocationFilter';
        type: LocationFilterType;
        isNull?: boolean | null;
        isNot?: boolean | null;
        valueIn?: Array<{ __typename?: 'FilterValue'; title: string }> | null;
        range?: {
          __typename?: 'NumberFilterRange';
          from?: number | null;
          to?: number | null;
        } | null;
        stringRange?: {
          __typename?: 'StringFilterRange';
          from?: string | null;
          excludeFrom?: boolean | null;
          to?: string | null;
          excludeTo?: boolean | null;
        } | null;
      }>;
    }>;
  } | null;
  constraintSettings?: {
    __typename?: 'AllocationConstraintSettings';
    assignmentPolicyConstraint?: {
      __typename?: 'AssignmentPolicyConstraintSettings';
      disabled?: boolean | null;
    } | null;
    locationSizeConstraint?: {
      __typename?: 'LocationSizeConstraintSettings';
      disabled?: boolean | null;
      unknownAsNoncompliant?: boolean | null;
    } | null;
    bayWidthConstraint?: {
      __typename?: 'BayWidthConstraintSettings';
      disabled?: boolean | null;
    } | null;
  } | null;
  reallocateQuantitySettings?: {
    __typename?: 'ReallocateQuantitySettings';
    reallocateUom?: ReallocateUomSource | null;
    reallocateQuantity?: ReallocateQuantitySource | null;
    fixedUom?: string | null;
    fixedUomQuantity?: number | null;
  } | null;
};

export type DeallocationPolicyFragment = {
  __typename?: 'DeallocationPolicy';
  rules?: Array<{
    __typename?: 'DeallocationRule';
    id?: string | null;
    title?: string | null;
    deallocateType?: DeallocateType | null;
    reallocateType?: ReallocateType | null;
    itemsMatch?: {
      __typename?: 'SimulationItemFilterUnion';
      anyOf: Array<{
        __typename?: 'SimulationItemFilterIntersection';
        id?: string | null;
        allOf: Array<{
          __typename?: 'SimulationItemFilter';
          type: SimulationItemFilterType;
          isNull?: boolean | null;
          isNot?: boolean | null;
          valueIn?: Array<{ __typename?: 'FilterValue'; title: string }> | null;
          range?: {
            __typename?: 'NumberFilterRange';
            from?: number | null;
            to?: number | null;
          } | null;
          stringRange?: {
            __typename?: 'StringFilterRange';
            from?: string | null;
            excludeFrom?: boolean | null;
            to?: string | null;
            excludeTo?: boolean | null;
          } | null;
        }>;
      }>;
    } | null;
    locationsMatch?: {
      __typename?: 'LocationFilterUnion';
      anyOf: Array<{
        __typename?: 'LocationFilterIntersection';
        id?: string | null;
        allOf: Array<{
          __typename?: 'LocationFilter';
          type: LocationFilterType;
          isNull?: boolean | null;
          isNot?: boolean | null;
          valueIn?: Array<{ __typename?: 'FilterValue'; title: string }> | null;
          range?: {
            __typename?: 'NumberFilterRange';
            from?: number | null;
            to?: number | null;
          } | null;
          stringRange?: {
            __typename?: 'StringFilterRange';
            from?: string | null;
            excludeFrom?: boolean | null;
            to?: string | null;
            excludeTo?: boolean | null;
          } | null;
        }>;
      }>;
    } | null;
    constraintSettings?: {
      __typename?: 'AllocationConstraintSettings';
      assignmentPolicyConstraint?: {
        __typename?: 'AssignmentPolicyConstraintSettings';
        disabled?: boolean | null;
      } | null;
      locationSizeConstraint?: {
        __typename?: 'LocationSizeConstraintSettings';
        disabled?: boolean | null;
        unknownAsNoncompliant?: boolean | null;
      } | null;
      bayWidthConstraint?: {
        __typename?: 'BayWidthConstraintSettings';
        disabled?: boolean | null;
      } | null;
    } | null;
    reallocateQuantitySettings?: {
      __typename?: 'ReallocateQuantitySettings';
      reallocateUom?: ReallocateUomSource | null;
      reallocateQuantity?: ReallocateQuantitySource | null;
      fixedUom?: string | null;
      fixedUomQuantity?: number | null;
    } | null;
  }> | null;
  defaultRule?: {
    __typename?: 'DeallocationRule';
    id?: string | null;
    title?: string | null;
    deallocateType?: DeallocateType | null;
    reallocateType?: ReallocateType | null;
    itemsMatch?: {
      __typename?: 'SimulationItemFilterUnion';
      anyOf: Array<{
        __typename?: 'SimulationItemFilterIntersection';
        id?: string | null;
        allOf: Array<{
          __typename?: 'SimulationItemFilter';
          type: SimulationItemFilterType;
          isNull?: boolean | null;
          isNot?: boolean | null;
          valueIn?: Array<{ __typename?: 'FilterValue'; title: string }> | null;
          range?: {
            __typename?: 'NumberFilterRange';
            from?: number | null;
            to?: number | null;
          } | null;
          stringRange?: {
            __typename?: 'StringFilterRange';
            from?: string | null;
            excludeFrom?: boolean | null;
            to?: string | null;
            excludeTo?: boolean | null;
          } | null;
        }>;
      }>;
    } | null;
    locationsMatch?: {
      __typename?: 'LocationFilterUnion';
      anyOf: Array<{
        __typename?: 'LocationFilterIntersection';
        id?: string | null;
        allOf: Array<{
          __typename?: 'LocationFilter';
          type: LocationFilterType;
          isNull?: boolean | null;
          isNot?: boolean | null;
          valueIn?: Array<{ __typename?: 'FilterValue'; title: string }> | null;
          range?: {
            __typename?: 'NumberFilterRange';
            from?: number | null;
            to?: number | null;
          } | null;
          stringRange?: {
            __typename?: 'StringFilterRange';
            from?: string | null;
            excludeFrom?: boolean | null;
            to?: string | null;
            excludeTo?: boolean | null;
          } | null;
        }>;
      }>;
    } | null;
    constraintSettings?: {
      __typename?: 'AllocationConstraintSettings';
      assignmentPolicyConstraint?: {
        __typename?: 'AssignmentPolicyConstraintSettings';
        disabled?: boolean | null;
      } | null;
      locationSizeConstraint?: {
        __typename?: 'LocationSizeConstraintSettings';
        disabled?: boolean | null;
        unknownAsNoncompliant?: boolean | null;
      } | null;
      bayWidthConstraint?: {
        __typename?: 'BayWidthConstraintSettings';
        disabled?: boolean | null;
      } | null;
    } | null;
    reallocateQuantitySettings?: {
      __typename?: 'ReallocateQuantitySettings';
      reallocateUom?: ReallocateUomSource | null;
      reallocateQuantity?: ReallocateQuantitySource | null;
      fixedUom?: string | null;
      fixedUomQuantity?: number | null;
    } | null;
  } | null;
};

export type AllocationSettingsFragment = {
  __typename?: 'AllocationSettings';
  allocationPolicy?: {
    __typename?: 'AllocationPolicy';
    rules?: Array<{
      __typename?: 'AllocationRule';
      id: string;
      title?: string | null;
      uomTypes?: Array<string> | null;
      itemsMatch?: {
        __typename?: 'SimulationItemFilterUnion';
        anyOf: Array<{
          __typename?: 'SimulationItemFilterIntersection';
          id?: string | null;
          allOf: Array<{
            __typename?: 'SimulationItemFilter';
            type: SimulationItemFilterType;
            isNull?: boolean | null;
            isNot?: boolean | null;
            valueIn?: Array<{
              __typename?: 'FilterValue';
              title: string;
            }> | null;
            range?: {
              __typename?: 'NumberFilterRange';
              from?: number | null;
              to?: number | null;
            } | null;
            stringRange?: {
              __typename?: 'StringFilterRange';
              from?: string | null;
              excludeFrom?: boolean | null;
              to?: string | null;
              excludeTo?: boolean | null;
            } | null;
          }>;
        }>;
      } | null;
      locationsMatch?: {
        __typename?: 'LocationFilterUnion';
        anyOf: Array<{
          __typename?: 'LocationFilterIntersection';
          id?: string | null;
          allOf: Array<{
            __typename?: 'LocationFilter';
            type: LocationFilterType;
            isNull?: boolean | null;
            isNot?: boolean | null;
            valueIn?: Array<{
              __typename?: 'FilterValue';
              title: string;
            }> | null;
            range?: {
              __typename?: 'NumberFilterRange';
              from?: number | null;
              to?: number | null;
            } | null;
            stringRange?: {
              __typename?: 'StringFilterRange';
              from?: string | null;
              excludeFrom?: boolean | null;
              to?: string | null;
              excludeTo?: boolean | null;
            } | null;
          }>;
        }>;
      } | null;
      limitSettings?: {
        __typename?: 'AllocationLimitSettings';
        applyTo?: AllocationLimitApplyTo | null;
        maxPickableAssignments?: number | null;
        maxAllocatedAssignments?: number | null;
        maxPickableVolume?: number | null;
        maxAllocatedVolume?: number | null;
      } | null;
      roundSettings?: {
        __typename?: 'AllocationRoundSettings';
        maxAssignmentsPerRound?: number | null;
      } | null;
      pickabilitySettings?: {
        __typename?: 'AllocationPickabilitySettings';
        enforcePickability?: AllocationEnforcePickability | null;
      } | null;
      prioritySettings?: {
        __typename?: 'AllocationPrioritySettings';
        itemPriority?: Array<{
          __typename?: 'AllocationItemPrioritySpec';
          priority: AllocationItemPriority;
          direction?: AllocationPriorityDirection | null;
        }> | null;
        locationPriority?: Array<{
          __typename?: 'AllocationLocationPrioritySpec';
          priority: AllocationLocationPriority;
          direction?: AllocationPriorityDirection | null;
        }> | null;
      } | null;
      replacementSettings?: {
        __typename?: 'AllocationReplacementSettings';
        allow?: AllocationAllowReplacement | null;
      } | null;
      multiplexingSettings?: {
        __typename?: 'AllocationMultiplexingSettings';
        allow?: AllocationAllowReplacement | null;
      } | null;
      requirementAggregationSettings?: {
        __typename?: 'AllocationRequirementAggregationSettings';
        aggregation?: AllocationRequirementAggregationMethod | null;
        numberOfPeriods?: number | null;
      } | null;
      constraintSettings?: {
        __typename?: 'AllocationConstraintSettings';
        assignmentPolicyConstraint?: {
          __typename?: 'AssignmentPolicyConstraintSettings';
          disabled?: boolean | null;
        } | null;
        locationSizeConstraint?: {
          __typename?: 'LocationSizeConstraintSettings';
          disabled?: boolean | null;
          unknownAsNoncompliant?: boolean | null;
        } | null;
        bayWidthConstraint?: {
          __typename?: 'BayWidthConstraintSettings';
          disabled?: boolean | null;
        } | null;
      } | null;
    }> | null;
    defaultRule?: {
      __typename?: 'AllocationRule';
      id: string;
      title?: string | null;
      uomTypes?: Array<string> | null;
      itemsMatch?: {
        __typename?: 'SimulationItemFilterUnion';
        anyOf: Array<{
          __typename?: 'SimulationItemFilterIntersection';
          id?: string | null;
          allOf: Array<{
            __typename?: 'SimulationItemFilter';
            type: SimulationItemFilterType;
            isNull?: boolean | null;
            isNot?: boolean | null;
            valueIn?: Array<{
              __typename?: 'FilterValue';
              title: string;
            }> | null;
            range?: {
              __typename?: 'NumberFilterRange';
              from?: number | null;
              to?: number | null;
            } | null;
            stringRange?: {
              __typename?: 'StringFilterRange';
              from?: string | null;
              excludeFrom?: boolean | null;
              to?: string | null;
              excludeTo?: boolean | null;
            } | null;
          }>;
        }>;
      } | null;
      locationsMatch?: {
        __typename?: 'LocationFilterUnion';
        anyOf: Array<{
          __typename?: 'LocationFilterIntersection';
          id?: string | null;
          allOf: Array<{
            __typename?: 'LocationFilter';
            type: LocationFilterType;
            isNull?: boolean | null;
            isNot?: boolean | null;
            valueIn?: Array<{
              __typename?: 'FilterValue';
              title: string;
            }> | null;
            range?: {
              __typename?: 'NumberFilterRange';
              from?: number | null;
              to?: number | null;
            } | null;
            stringRange?: {
              __typename?: 'StringFilterRange';
              from?: string | null;
              excludeFrom?: boolean | null;
              to?: string | null;
              excludeTo?: boolean | null;
            } | null;
          }>;
        }>;
      } | null;
      limitSettings?: {
        __typename?: 'AllocationLimitSettings';
        applyTo?: AllocationLimitApplyTo | null;
        maxPickableAssignments?: number | null;
        maxAllocatedAssignments?: number | null;
        maxPickableVolume?: number | null;
        maxAllocatedVolume?: number | null;
      } | null;
      roundSettings?: {
        __typename?: 'AllocationRoundSettings';
        maxAssignmentsPerRound?: number | null;
      } | null;
      pickabilitySettings?: {
        __typename?: 'AllocationPickabilitySettings';
        enforcePickability?: AllocationEnforcePickability | null;
      } | null;
      prioritySettings?: {
        __typename?: 'AllocationPrioritySettings';
        itemPriority?: Array<{
          __typename?: 'AllocationItemPrioritySpec';
          priority: AllocationItemPriority;
          direction?: AllocationPriorityDirection | null;
        }> | null;
        locationPriority?: Array<{
          __typename?: 'AllocationLocationPrioritySpec';
          priority: AllocationLocationPriority;
          direction?: AllocationPriorityDirection | null;
        }> | null;
      } | null;
      replacementSettings?: {
        __typename?: 'AllocationReplacementSettings';
        allow?: AllocationAllowReplacement | null;
      } | null;
      multiplexingSettings?: {
        __typename?: 'AllocationMultiplexingSettings';
        allow?: AllocationAllowReplacement | null;
      } | null;
      requirementAggregationSettings?: {
        __typename?: 'AllocationRequirementAggregationSettings';
        aggregation?: AllocationRequirementAggregationMethod | null;
        numberOfPeriods?: number | null;
      } | null;
      constraintSettings?: {
        __typename?: 'AllocationConstraintSettings';
        assignmentPolicyConstraint?: {
          __typename?: 'AssignmentPolicyConstraintSettings';
          disabled?: boolean | null;
        } | null;
        locationSizeConstraint?: {
          __typename?: 'LocationSizeConstraintSettings';
          disabled?: boolean | null;
          unknownAsNoncompliant?: boolean | null;
        } | null;
        bayWidthConstraint?: {
          __typename?: 'BayWidthConstraintSettings';
          disabled?: boolean | null;
        } | null;
      } | null;
    } | null;
  } | null;
  deallocationPolicy?: {
    __typename?: 'DeallocationPolicy';
    rules?: Array<{
      __typename?: 'DeallocationRule';
      id?: string | null;
      title?: string | null;
      deallocateType?: DeallocateType | null;
      reallocateType?: ReallocateType | null;
      itemsMatch?: {
        __typename?: 'SimulationItemFilterUnion';
        anyOf: Array<{
          __typename?: 'SimulationItemFilterIntersection';
          id?: string | null;
          allOf: Array<{
            __typename?: 'SimulationItemFilter';
            type: SimulationItemFilterType;
            isNull?: boolean | null;
            isNot?: boolean | null;
            valueIn?: Array<{
              __typename?: 'FilterValue';
              title: string;
            }> | null;
            range?: {
              __typename?: 'NumberFilterRange';
              from?: number | null;
              to?: number | null;
            } | null;
            stringRange?: {
              __typename?: 'StringFilterRange';
              from?: string | null;
              excludeFrom?: boolean | null;
              to?: string | null;
              excludeTo?: boolean | null;
            } | null;
          }>;
        }>;
      } | null;
      locationsMatch?: {
        __typename?: 'LocationFilterUnion';
        anyOf: Array<{
          __typename?: 'LocationFilterIntersection';
          id?: string | null;
          allOf: Array<{
            __typename?: 'LocationFilter';
            type: LocationFilterType;
            isNull?: boolean | null;
            isNot?: boolean | null;
            valueIn?: Array<{
              __typename?: 'FilterValue';
              title: string;
            }> | null;
            range?: {
              __typename?: 'NumberFilterRange';
              from?: number | null;
              to?: number | null;
            } | null;
            stringRange?: {
              __typename?: 'StringFilterRange';
              from?: string | null;
              excludeFrom?: boolean | null;
              to?: string | null;
              excludeTo?: boolean | null;
            } | null;
          }>;
        }>;
      } | null;
      constraintSettings?: {
        __typename?: 'AllocationConstraintSettings';
        assignmentPolicyConstraint?: {
          __typename?: 'AssignmentPolicyConstraintSettings';
          disabled?: boolean | null;
        } | null;
        locationSizeConstraint?: {
          __typename?: 'LocationSizeConstraintSettings';
          disabled?: boolean | null;
          unknownAsNoncompliant?: boolean | null;
        } | null;
        bayWidthConstraint?: {
          __typename?: 'BayWidthConstraintSettings';
          disabled?: boolean | null;
        } | null;
      } | null;
      reallocateQuantitySettings?: {
        __typename?: 'ReallocateQuantitySettings';
        reallocateUom?: ReallocateUomSource | null;
        reallocateQuantity?: ReallocateQuantitySource | null;
        fixedUom?: string | null;
        fixedUomQuantity?: number | null;
      } | null;
    }> | null;
    defaultRule?: {
      __typename?: 'DeallocationRule';
      id?: string | null;
      title?: string | null;
      deallocateType?: DeallocateType | null;
      reallocateType?: ReallocateType | null;
      itemsMatch?: {
        __typename?: 'SimulationItemFilterUnion';
        anyOf: Array<{
          __typename?: 'SimulationItemFilterIntersection';
          id?: string | null;
          allOf: Array<{
            __typename?: 'SimulationItemFilter';
            type: SimulationItemFilterType;
            isNull?: boolean | null;
            isNot?: boolean | null;
            valueIn?: Array<{
              __typename?: 'FilterValue';
              title: string;
            }> | null;
            range?: {
              __typename?: 'NumberFilterRange';
              from?: number | null;
              to?: number | null;
            } | null;
            stringRange?: {
              __typename?: 'StringFilterRange';
              from?: string | null;
              excludeFrom?: boolean | null;
              to?: string | null;
              excludeTo?: boolean | null;
            } | null;
          }>;
        }>;
      } | null;
      locationsMatch?: {
        __typename?: 'LocationFilterUnion';
        anyOf: Array<{
          __typename?: 'LocationFilterIntersection';
          id?: string | null;
          allOf: Array<{
            __typename?: 'LocationFilter';
            type: LocationFilterType;
            isNull?: boolean | null;
            isNot?: boolean | null;
            valueIn?: Array<{
              __typename?: 'FilterValue';
              title: string;
            }> | null;
            range?: {
              __typename?: 'NumberFilterRange';
              from?: number | null;
              to?: number | null;
            } | null;
            stringRange?: {
              __typename?: 'StringFilterRange';
              from?: string | null;
              excludeFrom?: boolean | null;
              to?: string | null;
              excludeTo?: boolean | null;
            } | null;
          }>;
        }>;
      } | null;
      constraintSettings?: {
        __typename?: 'AllocationConstraintSettings';
        assignmentPolicyConstraint?: {
          __typename?: 'AssignmentPolicyConstraintSettings';
          disabled?: boolean | null;
        } | null;
        locationSizeConstraint?: {
          __typename?: 'LocationSizeConstraintSettings';
          disabled?: boolean | null;
          unknownAsNoncompliant?: boolean | null;
        } | null;
        bayWidthConstraint?: {
          __typename?: 'BayWidthConstraintSettings';
          disabled?: boolean | null;
        } | null;
      } | null;
      reallocateQuantitySettings?: {
        __typename?: 'ReallocateQuantitySettings';
        reallocateUom?: ReallocateUomSource | null;
        reallocateQuantity?: ReallocateQuantitySource | null;
        fixedUom?: string | null;
        fixedUomQuantity?: number | null;
      } | null;
    } | null;
  } | null;
  orderSetFilter?: {
    __typename?: 'OrderSetFilter';
    orderDate?: {
      __typename?: 'LocalDateRangeFilter';
      from?: string | null;
      to?: string | null;
      excludeFrom?: boolean | null;
      excludeTo?: boolean | null;
      includeNull?: boolean | null;
    } | null;
  } | null;
  requirementSource?: {
    __typename?: 'AllocationRequirementSourceSettings';
    useOrderSetDemand?: boolean | null;
  } | null;
  stockLimitSettings?: {
    __typename?: 'AllocationStockLimitSettings';
    enabled?: boolean | null;
  } | null;
};

export type LayoutIssueSetFragment = {
  __typename?: 'LayoutIssueSet';
  status: BatchJobStatus;
  progress: number;
  summary?: {
    __typename?: 'LayoutIssueSummary';
    totalLocations: number;
    totalInvalidLocations: number;
    invalidLocations: Array<{
      __typename?: 'LayoutIssueInvalidLocationsSummary';
      planeId?: string | null;
      locationLevel?: number | null;
      reason: LayoutIssueInvalidLocationReason;
      count: number;
    }>;
  } | null;
};

export type LayoutIssueInvalidLocationFragment = {
  __typename?: 'LayoutIssueInvalidLocation';
  locationId: string;
  reason: Array<LayoutIssueInvalidLocationReason>;
};

export type GenerateLayoutIssueSetMutationVariables = Exact<{
  key: LayoutIssueSetKey;
}>;

export type GenerateLayoutIssueSetMutation = {
  __typename?: 'Mutation';
  generateLayoutIssueSet: {
    __typename?: 'LayoutIssueSet';
    status: BatchJobStatus;
    progress: number;
  };
};

export type LoadLayoutIssueSetQueryVariables = Exact<{
  key: LayoutIssueSetKey;
}>;

export type LoadLayoutIssueSetQuery = {
  __typename?: 'Query';
  layoutIssueSet?: {
    __typename?: 'LayoutIssueSet';
    status: BatchJobStatus;
    progress: number;
    summary?: {
      __typename?: 'LayoutIssueSummary';
      totalLocations: number;
      totalInvalidLocations: number;
      invalidLocations: Array<{
        __typename?: 'LayoutIssueInvalidLocationsSummary';
        planeId?: string | null;
        locationLevel?: number | null;
        reason: LayoutIssueInvalidLocationReason;
        count: number;
      }>;
    } | null;
  } | null;
};

export type LoadLayoutIssueDataQueryVariables = Exact<{
  key: LayoutIssueSetKey;
  planeId?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  level?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
  bayId?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
}>;

export type LoadLayoutIssueDataQuery = {
  __typename?: 'Query';
  layoutIssueSet?: {
    __typename?: 'LayoutIssueSet';
    invalidLocations: {
      __typename?: 'LayoutIssueInvalidLocationsConnection';
      totalCount: number;
      content: Array<{
        __typename?: 'LayoutIssueInvalidLocation';
        locationId: string;
        reason: Array<LayoutIssueInvalidLocationReason>;
      }>;
    };
  } | null;
};

export type AssignmentIssueSetFragment = {
  __typename?: 'AssignmentIssueSet';
  status: BatchJobStatus;
  progress: number;
  summary?: {
    __typename?: 'AssignmentIssueSummary';
    totalLocations: number;
    totalAssignedLocations: number;
    totalAssignmentItems: number;
    totalInvalidLocations: number;
    invalidLocations: Array<{
      __typename?: 'AssignmentIssueInvalidLocationsSummary';
      planeId?: string | null;
      locationLevel?: number | null;
      reason: AssignmentIssueInvalidLocationReason;
      count: number;
    }>;
  } | null;
};

export type AssignmentIssueInvalidLocationFragment = {
  __typename?: 'AssignmentIssueInvalidLocation';
  locationId: string;
  itemIds: Array<string>;
  reason: Array<AssignmentIssueInvalidLocationReason>;
  productIds: Array<{
    __typename?: 'ProductId';
    sku: string;
    consignee: string;
  }>;
};

export type AssignmentIssueInvalidLocationsDataFragment = {
  __typename?: 'AssignmentIssueInvalidLocationsConnection';
  totalCount: number;
  content: Array<{
    __typename?: 'AssignmentIssueInvalidLocation';
    locationId: string;
    itemIds: Array<string>;
    reason: Array<AssignmentIssueInvalidLocationReason>;
    productIds: Array<{
      __typename?: 'ProductId';
      sku: string;
      consignee: string;
    }>;
  }>;
};

export type GenerateAssignmentIssueSetMutationVariables = Exact<{
  key: AssignmentIssueSetKey;
}>;

export type GenerateAssignmentIssueSetMutation = {
  __typename?: 'Mutation';
  generateAssignmentIssueSet: {
    __typename?: 'AssignmentIssueSet';
    status: BatchJobStatus;
    progress: number;
  };
};

export type LoadAssignmentIssueSetQueryVariables = Exact<{
  key: AssignmentIssueSetKey;
}>;

export type LoadAssignmentIssueSetQuery = {
  __typename?: 'Query';
  assignmentIssueSet?: {
    __typename?: 'AssignmentIssueSet';
    status: BatchJobStatus;
    progress: number;
    summary?: {
      __typename?: 'AssignmentIssueSummary';
      totalLocations: number;
      totalAssignedLocations: number;
      totalAssignmentItems: number;
      totalInvalidLocations: number;
      invalidLocations: Array<{
        __typename?: 'AssignmentIssueInvalidLocationsSummary';
        planeId?: string | null;
        locationLevel?: number | null;
        reason: AssignmentIssueInvalidLocationReason;
        count: number;
      }>;
    } | null;
  } | null;
};

export type LoadAssignmentIssueDataQueryVariables = Exact<{
  key: AssignmentIssueSetKey;
  planeId?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  level?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
  bayId?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  reason?: InputMaybe<
    | Array<AssignmentIssueInvalidLocationReason>
    | AssignmentIssueInvalidLocationReason
  >;
  page?: InputMaybe<SkipLimitPageSpec>;
}>;

export type LoadAssignmentIssueDataQuery = {
  __typename?: 'Query';
  assignmentIssueSet?: {
    __typename?: 'AssignmentIssueSet';
    invalidLocations: {
      __typename?: 'AssignmentIssueInvalidLocationsConnection';
      totalCount: number;
      content: Array<{
        __typename?: 'AssignmentIssueInvalidLocation';
        locationId: string;
        itemIds: Array<string>;
        reason: Array<AssignmentIssueInvalidLocationReason>;
        productIds: Array<{
          __typename?: 'ProductId';
          sku: string;
          consignee: string;
        }>;
      }>;
    };
  } | null;
};

export type ItemSetIssueSetFragment = {
  __typename?: 'ItemSetIssueSet';
  status: BatchJobStatus;
  progress: number;
  summary?: {
    __typename?: 'ItemSetIssueSummary';
    totalItems: number;
    totalUoms: number;
    totalInvalidItems: number;
    totalInvalidUoms: number;
    totalAssignedItems: number;
    unknownAssignedItems: number;
    invalidItems: Array<{
      __typename?: 'ItemSetIssueInvalidItemsSummary';
      reason: ItemSetIssueInvalidItemReason;
      count: number;
    }>;
    invalidUoms: Array<{
      __typename?: 'ItemSetIssueInvalidUomsSummary';
      reason: ItemSetIssueInvalidUomReason;
      count: number;
    }>;
  } | null;
};

export type ItemSetIssueInvalidUomFragment = {
  __typename?: 'ItemSetIssueInvalidUom';
  consignee: string;
  sku: string;
  itemId: string;
  uom: string;
  reason: Array<ItemSetIssueInvalidUomReason>;
};

export type ItemSetIssueInvalidUomsDataFragment = {
  __typename?: 'ItemSetIssueInvalidUomsConnection';
  totalCount: number;
  content: Array<{
    __typename?: 'ItemSetIssueInvalidUom';
    consignee: string;
    sku: string;
    itemId: string;
    uom: string;
    reason: Array<ItemSetIssueInvalidUomReason>;
  }>;
};

export type ItemSetIssueInvalidItemFragment = {
  __typename?: 'ItemSetIssueInvalidItem';
  consignee: string;
  sku: string;
  itemId: string;
  reason: Array<ItemSetIssueInvalidItemReason>;
};

export type ItemSetIssueInvalidItemsDataFragment = {
  __typename?: 'ItemSetIssueInvalidItemsConnection';
  totalCount: number;
  content: Array<{
    __typename?: 'ItemSetIssueInvalidItem';
    consignee: string;
    sku: string;
    itemId: string;
    reason: Array<ItemSetIssueInvalidItemReason>;
  }>;
};

export type GenerateItemSetIssueSetMutationVariables = Exact<{
  key: ItemSetIssueSetKey;
}>;

export type GenerateItemSetIssueSetMutation = {
  __typename?: 'Mutation';
  generateItemSetIssueSet: {
    __typename?: 'ItemSetIssueSet';
    status: BatchJobStatus;
    progress: number;
  };
};

export type LoadItemSetIssueSetQueryVariables = Exact<{
  key: ItemSetIssueSetKey;
}>;

export type LoadItemSetIssueSetQuery = {
  __typename?: 'Query';
  itemSetIssueSet?: {
    __typename?: 'ItemSetIssueSet';
    status: BatchJobStatus;
    progress: number;
    summary?: {
      __typename?: 'ItemSetIssueSummary';
      totalItems: number;
      totalUoms: number;
      totalInvalidItems: number;
      totalInvalidUoms: number;
      totalAssignedItems: number;
      unknownAssignedItems: number;
      invalidItems: Array<{
        __typename?: 'ItemSetIssueInvalidItemsSummary';
        reason: ItemSetIssueInvalidItemReason;
        count: number;
      }>;
      invalidUoms: Array<{
        __typename?: 'ItemSetIssueInvalidUomsSummary';
        reason: ItemSetIssueInvalidUomReason;
        count: number;
      }>;
    } | null;
  } | null;
};

export type LoadItemSetUomIssueDataQueryVariables = Exact<{
  key: ItemSetIssueSetKey;
  reason?: InputMaybe<
    Array<ItemSetIssueInvalidUomReason> | ItemSetIssueInvalidUomReason
  >;
  page?: InputMaybe<SkipLimitPageSpec>;
}>;

export type LoadItemSetUomIssueDataQuery = {
  __typename?: 'Query';
  itemSetIssueSet?: {
    __typename?: 'ItemSetIssueSet';
    invalidUoms: {
      __typename?: 'ItemSetIssueInvalidUomsConnection';
      totalCount: number;
      content: Array<{
        __typename?: 'ItemSetIssueInvalidUom';
        consignee: string;
        sku: string;
        itemId: string;
        uom: string;
        reason: Array<ItemSetIssueInvalidUomReason>;
      }>;
    };
  } | null;
};

export type LoadItemSetItemIssueDataQueryVariables = Exact<{
  key: ItemSetIssueSetKey;
  reason?: InputMaybe<
    Array<ItemSetIssueInvalidItemReason> | ItemSetIssueInvalidItemReason
  >;
  page?: InputMaybe<SkipLimitPageSpec>;
}>;

export type LoadItemSetItemIssueDataQuery = {
  __typename?: 'Query';
  itemSetIssueSet?: {
    __typename?: 'ItemSetIssueSet';
    invalidItems: {
      __typename?: 'ItemSetIssueInvalidItemsConnection';
      totalCount: number;
      content: Array<{
        __typename?: 'ItemSetIssueInvalidItem';
        consignee: string;
        sku: string;
        itemId: string;
        reason: Array<ItemSetIssueInvalidItemReason>;
      }>;
    };
  } | null;
};

export type OrderSetIssueSetFragment = {
  __typename?: 'OrderSetIssueSet';
  status: BatchJobStatus;
  progress: number;
  summary?: {
    __typename?: 'OrderSetIssueSummary';
    totalLocations: number;
    totalOrderLines: number;
    totalUnpickableLines: number;
    totalItems?: number | null;
    unpickableLines: Array<{
      __typename?: 'OrderSetIssueUnpickableLinesSummary';
      reason: OrderSetIssueUnpickableLineReason;
      count: number;
    }>;
    unpickableItems?: Array<{
      __typename?: 'OrderSetIssueUnpickableItemsSummary';
      reason: OrderSetIssueUnpickableLineReason;
      count: number;
    }> | null;
  } | null;
};

export type OrderSetIssueUnpickableLineFragment = {
  __typename?: 'OrderSetIssueUnpickableLine';
  orderId: string;
  consignee: string;
  sku: string;
  itemId: string;
  reason: Array<OrderSetIssueUnpickableLineReason>;
  locationIds?: Array<string> | null;
};

export type OrderSetIssueUnpickableLinesDataFragment = {
  __typename?: 'OrderSetIssueUnpickableLinesConnection';
  totalCount: number;
  content: Array<{
    __typename?: 'OrderSetIssueUnpickableLine';
    orderId: string;
    consignee: string;
    sku: string;
    itemId: string;
    reason: Array<OrderSetIssueUnpickableLineReason>;
    locationIds?: Array<string> | null;
  }>;
};

export type GenerateOrderSetIssueSetMutationVariables = Exact<{
  key: OrderSetIssueSetKey;
}>;

export type GenerateOrderSetIssueSetMutation = {
  __typename?: 'Mutation';
  generateOrderSetIssueSet: {
    __typename?: 'OrderSetIssueSet';
    status: BatchJobStatus;
    progress: number;
  };
};

export type LoadOrderSetIssueSetQueryVariables = Exact<{
  key: OrderSetIssueSetKey;
}>;

export type LoadOrderSetIssueSetQuery = {
  __typename?: 'Query';
  orderSetIssueSet?: {
    __typename?: 'OrderSetIssueSet';
    status: BatchJobStatus;
    progress: number;
    summary?: {
      __typename?: 'OrderSetIssueSummary';
      totalLocations: number;
      totalOrderLines: number;
      totalUnpickableLines: number;
      totalItems?: number | null;
      unpickableLines: Array<{
        __typename?: 'OrderSetIssueUnpickableLinesSummary';
        reason: OrderSetIssueUnpickableLineReason;
        count: number;
      }>;
      unpickableItems?: Array<{
        __typename?: 'OrderSetIssueUnpickableItemsSummary';
        reason: OrderSetIssueUnpickableLineReason;
        count: number;
      }> | null;
    } | null;
  } | null;
};

export type LoadOrderSetIssueDataQueryVariables = Exact<{
  key: OrderSetIssueSetKey;
  reason?: InputMaybe<
    Array<OrderSetIssueUnpickableLineReason> | OrderSetIssueUnpickableLineReason
  >;
  page?: InputMaybe<SkipLimitPageSpec>;
}>;

export type LoadOrderSetIssueDataQuery = {
  __typename?: 'Query';
  orderSetIssueSet?: {
    __typename?: 'OrderSetIssueSet';
    unpickableLines: {
      __typename?: 'OrderSetIssueUnpickableLinesConnection';
      totalCount: number;
      content: Array<{
        __typename?: 'OrderSetIssueUnpickableLine';
        orderId: string;
        consignee: string;
        sku: string;
        itemId: string;
        reason: Array<OrderSetIssueUnpickableLineReason>;
        locationIds?: Array<string> | null;
      }>;
    };
  } | null;
};

export type ReassignJobMoveFragment = {
  __typename?: 'ReassignJobMove';
  itemId: string;
  moveId?: string | null;
  groupId?: string | null;
  chainNumber?: number | null;
  sequenceNumber?: number | null;
  consignee: string;
  sku: string;
  srcLocationId?: string | null;
  destLocationId?: string | null;
  destAction?: AssignmentChangeSequenceAction | null;
  estimatedCostImprovement?: number | null;
  movedQuantity?: {
    __typename?: 'AssignmentItemCapacity';
    breakdownByUom: Array<{
      __typename?: 'ItemUomQuantity';
      quantity: number;
      uom?: string | null;
      uomQuantity: number;
    }>;
  } | null;
  item?: {
    __typename?: 'ReassignJobMoveItem';
    name?: string | null;
    description?: string | null;
    percentRank?: number | null;
    cumulativePercentRank?: number | null;
    skuGroup?: string | null;
    subGroup?: string | null;
    transportClass?: string | null;
    stockCategory?: string | null;
    storageClass?: string | null;
    pollutionClass?: string | null;
  } | null;
};

export type ReassignJobDetailsFragment = {
  __typename?: 'ReassignJobDetails';
  moves: Array<{
    __typename?: 'ReassignJobMove';
    itemId: string;
    moveId?: string | null;
    groupId?: string | null;
    chainNumber?: number | null;
    sequenceNumber?: number | null;
    consignee: string;
    sku: string;
    srcLocationId?: string | null;
    destLocationId?: string | null;
    destAction?: AssignmentChangeSequenceAction | null;
    estimatedCostImprovement?: number | null;
    movedQuantity?: {
      __typename?: 'AssignmentItemCapacity';
      breakdownByUom: Array<{
        __typename?: 'ItemUomQuantity';
        quantity: number;
        uom?: string | null;
        uomQuantity: number;
      }>;
    } | null;
    item?: {
      __typename?: 'ReassignJobMoveItem';
      name?: string | null;
      description?: string | null;
      percentRank?: number | null;
      cumulativePercentRank?: number | null;
      skuGroup?: string | null;
      subGroup?: string | null;
      transportClass?: string | null;
      stockCategory?: string | null;
      storageClass?: string | null;
      pollutionClass?: string | null;
    } | null;
  }>;
};

type AnalyzeResultJobDetails_LoadingJobDetails_Fragment = {
  __typename?: 'LoadingJobDetails';
};

type AnalyzeResultJobDetails_PickingJobDetails_Fragment = {
  __typename?: 'PickingJobDetails';
};

type AnalyzeResultJobDetails_ReassignJobDetails_Fragment = {
  __typename?: 'ReassignJobDetails';
  moves: Array<{
    __typename?: 'ReassignJobMove';
    itemId: string;
    moveId?: string | null;
    groupId?: string | null;
    chainNumber?: number | null;
    sequenceNumber?: number | null;
    consignee: string;
    sku: string;
    srcLocationId?: string | null;
    destLocationId?: string | null;
    destAction?: AssignmentChangeSequenceAction | null;
    estimatedCostImprovement?: number | null;
    movedQuantity?: {
      __typename?: 'AssignmentItemCapacity';
      breakdownByUom: Array<{
        __typename?: 'ItemUomQuantity';
        quantity: number;
        uom?: string | null;
        uomQuantity: number;
      }>;
    } | null;
    item?: {
      __typename?: 'ReassignJobMoveItem';
      name?: string | null;
      description?: string | null;
      percentRank?: number | null;
      cumulativePercentRank?: number | null;
      skuGroup?: string | null;
      subGroup?: string | null;
      transportClass?: string | null;
      stockCategory?: string | null;
      storageClass?: string | null;
      pollutionClass?: string | null;
    } | null;
  }>;
};

type AnalyzeResultJobDetails_ReplenishmentJobDetails_Fragment = {
  __typename?: 'ReplenishmentJobDetails';
};

export type AnalyzeResultJobDetailsFragment =
  | AnalyzeResultJobDetails_LoadingJobDetails_Fragment
  | AnalyzeResultJobDetails_PickingJobDetails_Fragment
  | AnalyzeResultJobDetails_ReassignJobDetails_Fragment
  | AnalyzeResultJobDetails_ReplenishmentJobDetails_Fragment;

export type AnalyzeResultJobFragment = {
  __typename?: 'AnalyzeResultJob';
  id: string;
  agents: Array<string>;
  process: AnalyzeResultProcessType;
  jobDistance: number;
  jobDuration: number;
  jobCost: number;
  details?:
    | { __typename?: 'LoadingJobDetails' }
    | { __typename?: 'PickingJobDetails' }
    | {
        __typename?: 'ReassignJobDetails';
        moves: Array<{
          __typename?: 'ReassignJobMove';
          itemId: string;
          moveId?: string | null;
          groupId?: string | null;
          chainNumber?: number | null;
          sequenceNumber?: number | null;
          consignee: string;
          sku: string;
          srcLocationId?: string | null;
          destLocationId?: string | null;
          destAction?: AssignmentChangeSequenceAction | null;
          estimatedCostImprovement?: number | null;
          movedQuantity?: {
            __typename?: 'AssignmentItemCapacity';
            breakdownByUom: Array<{
              __typename?: 'ItemUomQuantity';
              quantity: number;
              uom?: string | null;
              uomQuantity: number;
            }>;
          } | null;
          item?: {
            __typename?: 'ReassignJobMoveItem';
            name?: string | null;
            description?: string | null;
            percentRank?: number | null;
            cumulativePercentRank?: number | null;
            skuGroup?: string | null;
            subGroup?: string | null;
            transportClass?: string | null;
            stockCategory?: string | null;
            storageClass?: string | null;
            pollutionClass?: string | null;
          } | null;
        }>;
      }
    | { __typename?: 'ReplenishmentJobDetails' }
    | null;
};

export type AnalyzeResultJobsConnectionFragment = {
  __typename?: 'AnalyzeResultJobsConnection';
  totalCount: number;
  content: Array<{
    __typename?: 'AnalyzeResultJob';
    id: string;
    agents: Array<string>;
    process: AnalyzeResultProcessType;
    jobDistance: number;
    jobDuration: number;
    jobCost: number;
    details?:
      | { __typename?: 'LoadingJobDetails' }
      | { __typename?: 'PickingJobDetails' }
      | {
          __typename?: 'ReassignJobDetails';
          moves: Array<{
            __typename?: 'ReassignJobMove';
            itemId: string;
            moveId?: string | null;
            groupId?: string | null;
            chainNumber?: number | null;
            sequenceNumber?: number | null;
            consignee: string;
            sku: string;
            srcLocationId?: string | null;
            destLocationId?: string | null;
            destAction?: AssignmentChangeSequenceAction | null;
            estimatedCostImprovement?: number | null;
            movedQuantity?: {
              __typename?: 'AssignmentItemCapacity';
              breakdownByUom: Array<{
                __typename?: 'ItemUomQuantity';
                quantity: number;
                uom?: string | null;
                uomQuantity: number;
              }>;
            } | null;
            item?: {
              __typename?: 'ReassignJobMoveItem';
              name?: string | null;
              description?: string | null;
              percentRank?: number | null;
              cumulativePercentRank?: number | null;
              skuGroup?: string | null;
              subGroup?: string | null;
              transportClass?: string | null;
              stockCategory?: string | null;
              storageClass?: string | null;
              pollutionClass?: string | null;
            } | null;
          }>;
        }
      | { __typename?: 'ReplenishmentJobDetails' }
      | null;
  }>;
};

export type LoadAnalyzeJobsDetailsQueryVariables = Exact<{
  analyzeId: Scalars['ID']['input'];
  filter?: InputMaybe<AnalyzeResultJobsFilter>;
  sortBy?: InputMaybe<Array<AnalyzeResultJobsSortBy> | AnalyzeResultJobsSortBy>;
  page?: InputMaybe<SkipLimitPageSpec>;
}>;

export type LoadAnalyzeJobsDetailsQuery = {
  __typename?: 'Query';
  analyzeResult?: {
    __typename?: 'AnalyzeResult';
    jobs?: {
      __typename?: 'AnalyzeResultJobsConnection';
      totalCount: number;
      content: Array<{
        __typename?: 'AnalyzeResultJob';
        id: string;
        agents: Array<string>;
        process: AnalyzeResultProcessType;
        jobDistance: number;
        jobDuration: number;
        jobCost: number;
        details?:
          | { __typename?: 'LoadingJobDetails' }
          | { __typename?: 'PickingJobDetails' }
          | {
              __typename?: 'ReassignJobDetails';
              moves: Array<{
                __typename?: 'ReassignJobMove';
                itemId: string;
                moveId?: string | null;
                groupId?: string | null;
                chainNumber?: number | null;
                sequenceNumber?: number | null;
                consignee: string;
                sku: string;
                srcLocationId?: string | null;
                destLocationId?: string | null;
                destAction?: AssignmentChangeSequenceAction | null;
                estimatedCostImprovement?: number | null;
                movedQuantity?: {
                  __typename?: 'AssignmentItemCapacity';
                  breakdownByUom: Array<{
                    __typename?: 'ItemUomQuantity';
                    quantity: number;
                    uom?: string | null;
                    uomQuantity: number;
                  }>;
                } | null;
                item?: {
                  __typename?: 'ReassignJobMoveItem';
                  name?: string | null;
                  description?: string | null;
                  percentRank?: number | null;
                  cumulativePercentRank?: number | null;
                  skuGroup?: string | null;
                  subGroup?: string | null;
                  transportClass?: string | null;
                  stockCategory?: string | null;
                  storageClass?: string | null;
                  pollutionClass?: string | null;
                } | null;
              }>;
            }
          | { __typename?: 'ReplenishmentJobDetails' }
          | null;
      }>;
    } | null;
  } | null;
};

export type AnalyzeResultProductReassignFragment = {
  __typename?: 'AnalyzeResultLocationProductReassignTask';
  action: ReassignTaskAction;
  quantity: number;
  breakdownByUom: Array<{
    __typename?: 'ItemUomQuantity';
    uom?: string | null;
    quantity: number;
    uomQuantity: number;
  }>;
};

export type AnalyzeResultProductReplenishmentFragment = {
  __typename?: 'AnalyzeResultLocationProductReplenishmentInfo';
  replenishmentQuantity: number;
  replenishmentUom?: string | null;
  replenishmentUomQuantity: number;
};

export type AnalyzeResultReorderSummaryFragment = {
  __typename?: 'AnalyzeResultReorderSummary';
  count: number;
  totalDuration: number;
  totalCost: number;
  totalReorderedWeight: number;
};

export type AnalyzeResultProductFragment = {
  __typename?: 'AnalyzeResultLocationProductInfo';
  itemId: string;
  consignee: string;
  sku: string;
  percentRank?: number | null;
  cumulativePercentRank?: number | null;
  hitCount: number;
  pickCount: number;
  replenishmentCount?: number | null;
  reassignCount?: number | null;
  quantityPicked: number;
  quantityReplenished?: number | null;
  quantityReassigned?: number | null;
  stackingCategoryIndex?: number | null;
  item?: {
    __typename?: 'SimulationItem';
    skuGroup?: string | null;
    subGroup?: string | null;
    pollutionClass?: string | null;
    transportClass?: string | null;
    stockCategory?: string | null;
    storageClass?: string | null;
    baseUom?: {
      __typename?: 'BaseUom';
      uom?: string | null;
      netWeight?: number | null;
      volume?: number | null;
      length?: number | null;
      width?: number | null;
      height?: number | null;
      maxStack?: number | null;
    } | null;
    details?: {
      __typename?: 'OrderSetItemDetails';
      dailyStats?: {
        __typename?: 'OrderSetItemDailyStats';
        totalDaysWithOrders: number;
        orderLineCount: {
          __typename?: 'ValueStats';
          avg?: number | null;
          max?: number | null;
          cv?: number | null;
        };
      } | null;
      weeklyStats?: {
        __typename?: 'OrderSetItemWeeklyStats';
        totalWeeksWithOrders: number;
        orderLineCount: {
          __typename?: 'ValueStats';
          max?: number | null;
          avg?: number | null;
          cv?: number | null;
        };
        daysWithOrders: {
          __typename?: 'ValueStats';
          max?: number | null;
          avg?: number | null;
          cv?: number | null;
        };
      } | null;
    } | null;
  } | null;
  replenishment?: {
    __typename?: 'AnalyzeResultLocationProductReplenishmentInfo';
    replenishmentQuantity: number;
    replenishmentUom?: string | null;
    replenishmentUomQuantity: number;
  } | null;
  appliedReorder?: {
    __typename?: 'AnalyzeResultReorderSummary';
    count: number;
    totalDuration: number;
    totalCost: number;
    totalReorderedWeight: number;
  } | null;
  triggeredReorder?: {
    __typename?: 'AnalyzeResultReorderSummary';
    count: number;
    totalDuration: number;
    totalCost: number;
    totalReorderedWeight: number;
  } | null;
  reassignTasks?: Array<{
    __typename?: 'AnalyzeResultLocationProductReassignTask';
    action: ReassignTaskAction;
    quantity: number;
    breakdownByUom: Array<{
      __typename?: 'ItemUomQuantity';
      uom?: string | null;
      quantity: number;
      uomQuantity: number;
    }>;
  }> | null;
};

export type AnalyzeResultLocationFragment = {
  __typename?: 'AnalyzeResultLocation';
  locationId: string;
  locationBayId: string;
  planeId: string;
  congestionZone?: string | null;
  products: Array<{
    __typename?: 'AnalyzeResultLocationProductInfo';
    itemId: string;
    consignee: string;
    sku: string;
    percentRank?: number | null;
    cumulativePercentRank?: number | null;
    hitCount: number;
    pickCount: number;
    replenishmentCount?: number | null;
    reassignCount?: number | null;
    quantityPicked: number;
    quantityReplenished?: number | null;
    quantityReassigned?: number | null;
    stackingCategoryIndex?: number | null;
    item?: {
      __typename?: 'SimulationItem';
      skuGroup?: string | null;
      subGroup?: string | null;
      pollutionClass?: string | null;
      transportClass?: string | null;
      stockCategory?: string | null;
      storageClass?: string | null;
      baseUom?: {
        __typename?: 'BaseUom';
        uom?: string | null;
        netWeight?: number | null;
        volume?: number | null;
        length?: number | null;
        width?: number | null;
        height?: number | null;
        maxStack?: number | null;
      } | null;
      details?: {
        __typename?: 'OrderSetItemDetails';
        dailyStats?: {
          __typename?: 'OrderSetItemDailyStats';
          totalDaysWithOrders: number;
          orderLineCount: {
            __typename?: 'ValueStats';
            avg?: number | null;
            max?: number | null;
            cv?: number | null;
          };
        } | null;
        weeklyStats?: {
          __typename?: 'OrderSetItemWeeklyStats';
          totalWeeksWithOrders: number;
          orderLineCount: {
            __typename?: 'ValueStats';
            max?: number | null;
            avg?: number | null;
            cv?: number | null;
          };
          daysWithOrders: {
            __typename?: 'ValueStats';
            max?: number | null;
            avg?: number | null;
            cv?: number | null;
          };
        } | null;
      } | null;
    } | null;
    replenishment?: {
      __typename?: 'AnalyzeResultLocationProductReplenishmentInfo';
      replenishmentQuantity: number;
      replenishmentUom?: string | null;
      replenishmentUomQuantity: number;
    } | null;
    appliedReorder?: {
      __typename?: 'AnalyzeResultReorderSummary';
      count: number;
      totalDuration: number;
      totalCost: number;
      totalReorderedWeight: number;
    } | null;
    triggeredReorder?: {
      __typename?: 'AnalyzeResultReorderSummary';
      count: number;
      totalDuration: number;
      totalCost: number;
      totalReorderedWeight: number;
    } | null;
    reassignTasks?: Array<{
      __typename?: 'AnalyzeResultLocationProductReassignTask';
      action: ReassignTaskAction;
      quantity: number;
      breakdownByUom: Array<{
        __typename?: 'ItemUomQuantity';
        uom?: string | null;
        quantity: number;
        uomQuantity: number;
      }>;
    }> | null;
  }>;
};

export type AnalyzeResultWaypointFragment = {
  __typename?: 'AnalyzeResultWaypoint';
  planeId: string;
  waypointType: string;
  waypointId: string;
  x: number;
  y: number;
  hitCount: number;
};

export type AnalyzeResultLocationsConnectionFragment = {
  __typename?: 'AnalyzeResultLocationsConnection';
  totalCount: number;
  content: Array<{
    __typename?: 'AnalyzeResultLocation';
    locationId: string;
    locationBayId: string;
    planeId: string;
    congestionZone?: string | null;
    products: Array<{
      __typename?: 'AnalyzeResultLocationProductInfo';
      itemId: string;
      consignee: string;
      sku: string;
      percentRank?: number | null;
      cumulativePercentRank?: number | null;
      hitCount: number;
      pickCount: number;
      replenishmentCount?: number | null;
      reassignCount?: number | null;
      quantityPicked: number;
      quantityReplenished?: number | null;
      quantityReassigned?: number | null;
      stackingCategoryIndex?: number | null;
      item?: {
        __typename?: 'SimulationItem';
        skuGroup?: string | null;
        subGroup?: string | null;
        pollutionClass?: string | null;
        transportClass?: string | null;
        stockCategory?: string | null;
        storageClass?: string | null;
        baseUom?: {
          __typename?: 'BaseUom';
          uom?: string | null;
          netWeight?: number | null;
          volume?: number | null;
          length?: number | null;
          width?: number | null;
          height?: number | null;
          maxStack?: number | null;
        } | null;
        details?: {
          __typename?: 'OrderSetItemDetails';
          dailyStats?: {
            __typename?: 'OrderSetItemDailyStats';
            totalDaysWithOrders: number;
            orderLineCount: {
              __typename?: 'ValueStats';
              avg?: number | null;
              max?: number | null;
              cv?: number | null;
            };
          } | null;
          weeklyStats?: {
            __typename?: 'OrderSetItemWeeklyStats';
            totalWeeksWithOrders: number;
            orderLineCount: {
              __typename?: 'ValueStats';
              max?: number | null;
              avg?: number | null;
              cv?: number | null;
            };
            daysWithOrders: {
              __typename?: 'ValueStats';
              max?: number | null;
              avg?: number | null;
              cv?: number | null;
            };
          } | null;
        } | null;
      } | null;
      replenishment?: {
        __typename?: 'AnalyzeResultLocationProductReplenishmentInfo';
        replenishmentQuantity: number;
        replenishmentUom?: string | null;
        replenishmentUomQuantity: number;
      } | null;
      appliedReorder?: {
        __typename?: 'AnalyzeResultReorderSummary';
        count: number;
        totalDuration: number;
        totalCost: number;
        totalReorderedWeight: number;
      } | null;
      triggeredReorder?: {
        __typename?: 'AnalyzeResultReorderSummary';
        count: number;
        totalDuration: number;
        totalCost: number;
        totalReorderedWeight: number;
      } | null;
      reassignTasks?: Array<{
        __typename?: 'AnalyzeResultLocationProductReassignTask';
        action: ReassignTaskAction;
        quantity: number;
        breakdownByUom: Array<{
          __typename?: 'ItemUomQuantity';
          uom?: string | null;
          quantity: number;
          uomQuantity: number;
        }>;
      }> | null;
    }>;
  }>;
};

export type LoadAnalyzeLocationsQueryVariables = Exact<{
  analyzeId: Scalars['ID']['input'];
  filter?: InputMaybe<AnalyzeResultLocationsAndProductsFilter>;
  page?: InputMaybe<SkipLimitPageSpec>;
  sortBy?: InputMaybe<
    Array<AnalyzeResultLocationsSortBy> | AnalyzeResultLocationsSortBy
  >;
}>;

export type LoadAnalyzeLocationsQuery = {
  __typename?: 'Query';
  analyzeResult?: {
    __typename?: 'AnalyzeResult';
    visitedLocations?: {
      __typename?: 'AnalyzeResultLocationsConnection';
      totalCount: number;
      content: Array<{
        __typename?: 'AnalyzeResultLocation';
        locationId: string;
        locationBayId: string;
        planeId: string;
        congestionZone?: string | null;
        products: Array<{
          __typename?: 'AnalyzeResultLocationProductInfo';
          itemId: string;
          consignee: string;
          sku: string;
          percentRank?: number | null;
          cumulativePercentRank?: number | null;
          hitCount: number;
          pickCount: number;
          replenishmentCount?: number | null;
          reassignCount?: number | null;
          quantityPicked: number;
          quantityReplenished?: number | null;
          quantityReassigned?: number | null;
          stackingCategoryIndex?: number | null;
          item?: {
            __typename?: 'SimulationItem';
            skuGroup?: string | null;
            subGroup?: string | null;
            pollutionClass?: string | null;
            transportClass?: string | null;
            stockCategory?: string | null;
            storageClass?: string | null;
            baseUom?: {
              __typename?: 'BaseUom';
              uom?: string | null;
              netWeight?: number | null;
              volume?: number | null;
              length?: number | null;
              width?: number | null;
              height?: number | null;
              maxStack?: number | null;
            } | null;
            details?: {
              __typename?: 'OrderSetItemDetails';
              dailyStats?: {
                __typename?: 'OrderSetItemDailyStats';
                totalDaysWithOrders: number;
                orderLineCount: {
                  __typename?: 'ValueStats';
                  avg?: number | null;
                  max?: number | null;
                  cv?: number | null;
                };
              } | null;
              weeklyStats?: {
                __typename?: 'OrderSetItemWeeklyStats';
                totalWeeksWithOrders: number;
                orderLineCount: {
                  __typename?: 'ValueStats';
                  max?: number | null;
                  avg?: number | null;
                  cv?: number | null;
                };
                daysWithOrders: {
                  __typename?: 'ValueStats';
                  max?: number | null;
                  avg?: number | null;
                  cv?: number | null;
                };
              } | null;
            } | null;
          } | null;
          replenishment?: {
            __typename?: 'AnalyzeResultLocationProductReplenishmentInfo';
            replenishmentQuantity: number;
            replenishmentUom?: string | null;
            replenishmentUomQuantity: number;
          } | null;
          appliedReorder?: {
            __typename?: 'AnalyzeResultReorderSummary';
            count: number;
            totalDuration: number;
            totalCost: number;
            totalReorderedWeight: number;
          } | null;
          triggeredReorder?: {
            __typename?: 'AnalyzeResultReorderSummary';
            count: number;
            totalDuration: number;
            totalCost: number;
            totalReorderedWeight: number;
          } | null;
          reassignTasks?: Array<{
            __typename?: 'AnalyzeResultLocationProductReassignTask';
            action: ReassignTaskAction;
            quantity: number;
            breakdownByUom: Array<{
              __typename?: 'ItemUomQuantity';
              uom?: string | null;
              quantity: number;
              uomQuantity: number;
            }>;
          }> | null;
        }>;
      }>;
    } | null;
  } | null;
};

export type LoadAnalyzeWaypointsQueryVariables = Exact<{
  analyzeId: Scalars['ID']['input'];
  planeId: Scalars['ID']['input'];
}>;

export type LoadAnalyzeWaypointsQuery = {
  __typename?: 'Query';
  analyzeResult?: {
    __typename?: 'AnalyzeResult';
    waypoints?: {
      __typename?: 'AnalyzeResultWaypointsConnection';
      content: Array<{
        __typename?: 'AnalyzeResultWaypoint';
        planeId: string;
        waypointType: string;
        waypointId: string;
        x: number;
        y: number;
        hitCount: number;
      }>;
    } | null;
  } | null;
};

export type PicklistEventWaypointFragment = {
  __typename?: 'PicklistEventWaypoint';
  id: string;
  type: WaypointType;
  position: { __typename?: 'Point'; x: number; y: number };
};

export type PicklistProcessingEventDetailsFragment = {
  __typename?: 'PicklistProcessingEventDetails';
  orderCount: number;
  orderLineCount: number;
  totalUomQuantity: number;
};

export type PicklistLineProcessingEventDetailsFragment = {
  __typename?: 'PicklistLineProcessingEventDetails';
  uomQuantity: number;
};

export type TravellingVerticalDetailsFragment = {
  __typename?: 'TravellingVerticalDetails';
  distance: number;
  weight?: number | null;
  volume?: number | null;
};

export type TravellingHorizontalDetailsFragment = {
  __typename?: 'TravellingHorizontalDetails';
  distance: number;
  weight?: number | null;
  volume?: number | null;
  route: Array<{
    __typename?: 'PicklistEventWaypoint';
    id: string;
    type: WaypointType;
    position: { __typename?: 'Point'; x: number; y: number };
  }>;
};

export type HandlingExecutionDetailsFragment = {
  __typename?: 'HandlingExecutionDetails';
  uom?: string | null;
  uomQuantity: number;
  weight?: number | null;
  volume?: number | null;
};

export type HandlingPalletReorderingDetailsFragment = {
  __typename?: 'HandlingPalletReorderingDetails';
  reordered: Array<{
    __typename?: 'HandlingPalletReorderCategory';
    weight?: number | null;
    volume?: number | null;
    uoms: Array<{
      __typename?: 'UomHandlingDetails';
      uom?: string | null;
      uomQuantity: number;
    }>;
  }>;
};

export type AnalyzeEventFragment = {
  __typename?: 'AnalyzeResultEvent';
  sequenceId: number;
  eventType: PicklistEventType;
  duration: number;
  cost: number;
  agents: Array<string>;
  orderIds: Array<string>;
  picklistLine?: number | null;
  locationId?: string | null;
  startPosition: { __typename?: 'Point'; x: number; y: number };
  endPosition?: { __typename?: 'Point'; x: number; y: number } | null;
  details?:
    | {
        __typename: 'HandlingExecutionDetails';
        uom?: string | null;
        uomQuantity: number;
        weight?: number | null;
        volume?: number | null;
      }
    | {
        __typename: 'HandlingPalletReorderingDetails';
        reordered: Array<{
          __typename?: 'HandlingPalletReorderCategory';
          weight?: number | null;
          volume?: number | null;
          uoms: Array<{
            __typename?: 'UomHandlingDetails';
            uom?: string | null;
            uomQuantity: number;
          }>;
        }>;
      }
    | { __typename: 'PicklistLineProcessingEventDetails'; uomQuantity: number }
    | {
        __typename: 'PicklistProcessingEventDetails';
        orderCount: number;
        orderLineCount: number;
        totalUomQuantity: number;
      }
    | { __typename: 'SourceEventIgnoredDetails' }
    | {
        __typename: 'TravellingHorizontalDetails';
        distance: number;
        weight?: number | null;
        volume?: number | null;
        route: Array<{
          __typename?: 'PicklistEventWaypoint';
          id: string;
          type: WaypointType;
          position: { __typename?: 'Point'; x: number; y: number };
        }>;
      }
    | {
        __typename: 'TravellingVerticalDetails';
        distance: number;
        weight?: number | null;
        volume?: number | null;
      }
    | null;
};

export type AnalyzePickListLineFragment = {
  __typename?: 'PicklistLine';
  picklistLine: number;
  consignee: string;
  sku: string;
  uom?: string | null;
  uomQuantity: number;
  quantity: number;
  locationId: string;
  pickingPriority?: string | null;
  details?: {
    __typename?: 'PicklistLineDetails';
    stackingPriority?: Array<number> | null;
    pickingContainer?: string | null;
  } | null;
};

export type AnalyzePicklistFragment = {
  __typename?: 'AnalyzeResultPicklist';
  picklistId: string;
  pickingMethod: string;
  planeId: string;
  agents: Array<string>;
  orderIds: Array<string>;
  unitOfWork?: string | null;
  pickByDate?: string | null;
  totalLines: number;
  totalEvents: number;
  totalDistance: number;
  totalDuration: number;
  totalCost: number;
  picklist: {
    __typename?: 'Picklist';
    id: string;
    title?: string | null;
    orderLineRuleId?: string | null;
    planeId: string;
    pickingMethod: string;
    agents: Array<string>;
    unitOfWork?: string | null;
    pickByDate?: string | null;
    orderIds: Array<string>;
    totalVolume?: number | null;
    totalNetWeight?: number | null;
    lines: Array<{
      __typename?: 'PicklistLine';
      picklistLine: number;
      consignee: string;
      sku: string;
      uom?: string | null;
      uomQuantity: number;
      quantity: number;
      locationId: string;
      pickingPriority?: string | null;
      details?: {
        __typename?: 'PicklistLineDetails';
        stackingPriority?: Array<number> | null;
        pickingContainer?: string | null;
      } | null;
    }>;
  };
};

export type AnalyzePicklistEventsFragment = {
  __typename?: 'AnalyzeResultPicklist';
  picklistId: string;
  pickingMethod: string;
  planeId: string;
  agents: Array<string>;
  orderIds: Array<string>;
  unitOfWork?: string | null;
  pickByDate?: string | null;
  totalLines: number;
  totalEvents: number;
  totalDistance: number;
  totalDuration: number;
  totalCost: number;
  events: Array<{
    __typename?: 'AnalyzeResultEvent';
    sequenceId: number;
    eventType: PicklistEventType;
    duration: number;
    cost: number;
    agents: Array<string>;
    orderIds: Array<string>;
    picklistLine?: number | null;
    locationId?: string | null;
    startPosition: { __typename?: 'Point'; x: number; y: number };
    endPosition?: { __typename?: 'Point'; x: number; y: number } | null;
    details?:
      | {
          __typename: 'HandlingExecutionDetails';
          uom?: string | null;
          uomQuantity: number;
          weight?: number | null;
          volume?: number | null;
        }
      | {
          __typename: 'HandlingPalletReorderingDetails';
          reordered: Array<{
            __typename?: 'HandlingPalletReorderCategory';
            weight?: number | null;
            volume?: number | null;
            uoms: Array<{
              __typename?: 'UomHandlingDetails';
              uom?: string | null;
              uomQuantity: number;
            }>;
          }>;
        }
      | {
          __typename: 'PicklistLineProcessingEventDetails';
          uomQuantity: number;
        }
      | {
          __typename: 'PicklistProcessingEventDetails';
          orderCount: number;
          orderLineCount: number;
          totalUomQuantity: number;
        }
      | { __typename: 'SourceEventIgnoredDetails' }
      | {
          __typename: 'TravellingHorizontalDetails';
          distance: number;
          weight?: number | null;
          volume?: number | null;
          route: Array<{
            __typename?: 'PicklistEventWaypoint';
            id: string;
            type: WaypointType;
            position: { __typename?: 'Point'; x: number; y: number };
          }>;
        }
      | {
          __typename: 'TravellingVerticalDetails';
          distance: number;
          weight?: number | null;
          volume?: number | null;
        }
      | null;
  }>;
  picklist: {
    __typename?: 'Picklist';
    id: string;
    title?: string | null;
    orderLineRuleId?: string | null;
    planeId: string;
    pickingMethod: string;
    agents: Array<string>;
    unitOfWork?: string | null;
    pickByDate?: string | null;
    orderIds: Array<string>;
    totalVolume?: number | null;
    totalNetWeight?: number | null;
    lines: Array<{
      __typename?: 'PicklistLine';
      picklistLine: number;
      consignee: string;
      sku: string;
      uom?: string | null;
      uomQuantity: number;
      quantity: number;
      locationId: string;
      pickingPriority?: string | null;
      details?: {
        __typename?: 'PicklistLineDetails';
        stackingPriority?: Array<number> | null;
        pickingContainer?: string | null;
      } | null;
    }>;
  };
};

export type AnalyzeResultOrderDetailsFragment = {
  __typename?: 'AnalyzeResultOrder';
  id: string;
  totalLines: number;
  assembledLines: number;
  assembledPartiallyLines: number;
  ignoredLines: number;
  visitedLocations?: Array<string> | null;
  totalDistance: number;
  totalDuration: number;
  totalCost: number;
  orderLinesStatus?: Array<{
    __typename?: 'AnalyzeResultOrderLine';
    orderLine: number;
    status: AnalyzeOrderLineStatus;
  }> | null;
  order: {
    __typename?: 'OrderHeader';
    consignee: string;
    orderDate?: string | null;
    customer?: string | null;
    id: string;
    orderId: string;
    details: Array<{
      __typename?: 'OrderLine';
      sku: string;
      orderLine: number;
      quantity: number;
    }>;
  };
  picklists: Array<{
    __typename?: 'AnalyzeResultPicklist';
    picklistId: string;
    pickingMethod: string;
    planeId: string;
    agents: Array<string>;
    orderIds: Array<string>;
    unitOfWork?: string | null;
    pickByDate?: string | null;
    totalLines: number;
    totalEvents: number;
    totalDistance: number;
    totalDuration: number;
    totalCost: number;
    picklist: {
      __typename?: 'Picklist';
      id: string;
      title?: string | null;
      orderLineRuleId?: string | null;
      planeId: string;
      pickingMethod: string;
      agents: Array<string>;
      unitOfWork?: string | null;
      pickByDate?: string | null;
      orderIds: Array<string>;
      totalVolume?: number | null;
      totalNetWeight?: number | null;
      lines: Array<{
        __typename?: 'PicklistLine';
        picklistLine: number;
        consignee: string;
        sku: string;
        uom?: string | null;
        uomQuantity: number;
        quantity: number;
        locationId: string;
        pickingPriority?: string | null;
        details?: {
          __typename?: 'PicklistLineDetails';
          stackingPriority?: Array<number> | null;
          pickingContainer?: string | null;
        } | null;
      }>;
    };
  }>;
};

export type AnalyzeResultOrderSummaryFragment = {
  __typename?: 'AnalyzeResultOrder';
  id: string;
  totalLines: number;
  assembledLines: number;
  assembledPartiallyLines: number;
  ignoredLines: number;
  visitedLocations?: Array<string> | null;
  totalDistance: number;
  totalDuration: number;
  totalCost: number;
  order: {
    __typename?: 'OrderHeader';
    id: string;
    consignee: string;
    orderId: string;
    orderDate?: string | null;
  };
};

export type LoadAnalyzedOrderDetailsQueryVariables = Exact<{
  analyzeId: Scalars['ID']['input'];
  orderId: Scalars['ID']['input'];
}>;

export type LoadAnalyzedOrderDetailsQuery = {
  __typename?: 'Query';
  analyzeResult?: {
    __typename?: 'AnalyzeResult';
    orders?: {
      __typename?: 'AnalyzeResultOrdersConnection';
      content: Array<{
        __typename?: 'AnalyzeResultOrder';
        id: string;
        totalLines: number;
        assembledLines: number;
        assembledPartiallyLines: number;
        ignoredLines: number;
        visitedLocations?: Array<string> | null;
        totalDistance: number;
        totalDuration: number;
        totalCost: number;
        orderLinesStatus?: Array<{
          __typename?: 'AnalyzeResultOrderLine';
          orderLine: number;
          status: AnalyzeOrderLineStatus;
        }> | null;
        order: {
          __typename?: 'OrderHeader';
          consignee: string;
          orderDate?: string | null;
          customer?: string | null;
          id: string;
          orderId: string;
          details: Array<{
            __typename?: 'OrderLine';
            sku: string;
            orderLine: number;
            quantity: number;
          }>;
        };
        picklists: Array<{
          __typename?: 'AnalyzeResultPicklist';
          picklistId: string;
          pickingMethod: string;
          planeId: string;
          agents: Array<string>;
          orderIds: Array<string>;
          unitOfWork?: string | null;
          pickByDate?: string | null;
          totalLines: number;
          totalEvents: number;
          totalDistance: number;
          totalDuration: number;
          totalCost: number;
          picklist: {
            __typename?: 'Picklist';
            id: string;
            title?: string | null;
            orderLineRuleId?: string | null;
            planeId: string;
            pickingMethod: string;
            agents: Array<string>;
            unitOfWork?: string | null;
            pickByDate?: string | null;
            orderIds: Array<string>;
            totalVolume?: number | null;
            totalNetWeight?: number | null;
            lines: Array<{
              __typename?: 'PicklistLine';
              picklistLine: number;
              consignee: string;
              sku: string;
              uom?: string | null;
              uomQuantity: number;
              quantity: number;
              locationId: string;
              pickingPriority?: string | null;
              details?: {
                __typename?: 'PicklistLineDetails';
                stackingPriority?: Array<number> | null;
                pickingContainer?: string | null;
              } | null;
            }>;
          };
        }>;
      }>;
    } | null;
  } | null;
};

export type LoadAnalyzedPickListDetailsQueryVariables = Exact<{
  analyzeId: Scalars['ID']['input'];
  orderId: Scalars['ID']['input'];
  picklistId: Scalars['ID']['input'];
}>;

export type LoadAnalyzedPickListDetailsQuery = {
  __typename?: 'Query';
  analyzeResult?: {
    __typename?: 'AnalyzeResult';
    orders?: {
      __typename?: 'AnalyzeResultOrdersConnection';
      content: Array<{
        __typename?: 'AnalyzeResultOrder';
        picklists: Array<{
          __typename?: 'AnalyzeResultPicklist';
          picklistId: string;
          pickingMethod: string;
          planeId: string;
          agents: Array<string>;
          orderIds: Array<string>;
          unitOfWork?: string | null;
          pickByDate?: string | null;
          totalLines: number;
          totalEvents: number;
          totalDistance: number;
          totalDuration: number;
          totalCost: number;
          events: Array<{
            __typename?: 'AnalyzeResultEvent';
            sequenceId: number;
            eventType: PicklistEventType;
            duration: number;
            cost: number;
            agents: Array<string>;
            orderIds: Array<string>;
            picklistLine?: number | null;
            locationId?: string | null;
            startPosition: { __typename?: 'Point'; x: number; y: number };
            endPosition?: { __typename?: 'Point'; x: number; y: number } | null;
            details?:
              | {
                  __typename: 'HandlingExecutionDetails';
                  uom?: string | null;
                  uomQuantity: number;
                  weight?: number | null;
                  volume?: number | null;
                }
              | {
                  __typename: 'HandlingPalletReorderingDetails';
                  reordered: Array<{
                    __typename?: 'HandlingPalletReorderCategory';
                    weight?: number | null;
                    volume?: number | null;
                    uoms: Array<{
                      __typename?: 'UomHandlingDetails';
                      uom?: string | null;
                      uomQuantity: number;
                    }>;
                  }>;
                }
              | {
                  __typename: 'PicklistLineProcessingEventDetails';
                  uomQuantity: number;
                }
              | {
                  __typename: 'PicklistProcessingEventDetails';
                  orderCount: number;
                  orderLineCount: number;
                  totalUomQuantity: number;
                }
              | { __typename: 'SourceEventIgnoredDetails' }
              | {
                  __typename: 'TravellingHorizontalDetails';
                  distance: number;
                  weight?: number | null;
                  volume?: number | null;
                  route: Array<{
                    __typename?: 'PicklistEventWaypoint';
                    id: string;
                    type: WaypointType;
                    position: { __typename?: 'Point'; x: number; y: number };
                  }>;
                }
              | {
                  __typename: 'TravellingVerticalDetails';
                  distance: number;
                  weight?: number | null;
                  volume?: number | null;
                }
              | null;
          }>;
          picklist: {
            __typename?: 'Picklist';
            id: string;
            title?: string | null;
            orderLineRuleId?: string | null;
            planeId: string;
            pickingMethod: string;
            agents: Array<string>;
            unitOfWork?: string | null;
            pickByDate?: string | null;
            orderIds: Array<string>;
            totalVolume?: number | null;
            totalNetWeight?: number | null;
            lines: Array<{
              __typename?: 'PicklistLine';
              picklistLine: number;
              consignee: string;
              sku: string;
              uom?: string | null;
              uomQuantity: number;
              quantity: number;
              locationId: string;
              pickingPriority?: string | null;
              details?: {
                __typename?: 'PicklistLineDetails';
                stackingPriority?: Array<number> | null;
                pickingContainer?: string | null;
              } | null;
            }>;
          };
        }>;
      }>;
    } | null;
  } | null;
};

export type LoadAnalyzeOrdersQueryVariables = Exact<{
  analyzeId: Scalars['ID']['input'];
  page?: InputMaybe<SkipLimitPageSpec>;
  filter?: InputMaybe<AnalyzeResultOrdersFilter>;
  sortBy?: InputMaybe<
    Array<AnalyzeResultOrdersSortBy> | AnalyzeResultOrdersSortBy
  >;
}>;

export type LoadAnalyzeOrdersQuery = {
  __typename?: 'Query';
  analyzeResult?: {
    __typename?: 'AnalyzeResult';
    orders?: {
      __typename?: 'AnalyzeResultOrdersConnection';
      totalCount: number;
      content: Array<{
        __typename?: 'AnalyzeResultOrder';
        id: string;
        totalLines: number;
        assembledLines: number;
        assembledPartiallyLines: number;
        ignoredLines: number;
        visitedLocations?: Array<string> | null;
        totalDistance: number;
        totalDuration: number;
        totalCost: number;
        order: {
          __typename?: 'OrderHeader';
          id: string;
          consignee: string;
          orderId: string;
          orderDate?: string | null;
        };
      }>;
    } | null;
  } | null;
};

export type GateUsageScheduleJobAssignmentFragment = {
  __typename?: 'GateUsageScheduleJobAssignment';
  startTime: string;
  completionTime: string;
  totalDuration?: number | null;
  dockingDuration?: number | null;
  undockingDuration?: number | null;
  job: {
    __typename?: 'GateUsageScheduleJob';
    id: string;
    duration: number;
    deadline?: string | null;
  };
  jobDetails?: {
    __typename?: 'AnalyzeResultJob';
    id: string;
    title?: string | null;
    process: AnalyzeResultProcessType;
    agents: Array<string>;
    jobDistance: number;
    jobDuration: number;
    totalCost: number;
    totalDistance: number;
    totalDuration: number;
    details?:
      | {
          __typename: 'LoadingJobDetails';
          picklistCount: number;
          picklistLineCount: number;
          orderCount: number;
          netWeight: number;
          volume: number;
          quantity: number;
          uomQuantities: Array<{
            __typename?: 'LoadingJobUomQuantity';
            uom: string;
            uomQuantity: number;
          }>;
          possibleGates: Array<{
            __typename?: 'LoadingJobGateDetails';
            gateId: string;
            distance: number;
            duration: number;
            cost: number;
          }>;
        }
      | { __typename: 'PickingJobDetails' }
      | { __typename: 'ReassignJobDetails' }
      | { __typename: 'ReplenishmentJobDetails' }
      | null;
  } | null;
};

export type GateUsageScheduleResourceFragment = {
  __typename?: 'GateUsageScheduleResource';
  id: string;
  assignments: Array<{
    __typename?: 'GateUsageScheduleJobAssignment';
    startTime: string;
    completionTime: string;
    totalDuration?: number | null;
    dockingDuration?: number | null;
    undockingDuration?: number | null;
    job: {
      __typename?: 'GateUsageScheduleJob';
      id: string;
      duration: number;
      deadline?: string | null;
    };
    jobDetails?: {
      __typename?: 'AnalyzeResultJob';
      id: string;
      title?: string | null;
      process: AnalyzeResultProcessType;
      agents: Array<string>;
      jobDistance: number;
      jobDuration: number;
      totalCost: number;
      totalDistance: number;
      totalDuration: number;
      details?:
        | {
            __typename: 'LoadingJobDetails';
            picklistCount: number;
            picklistLineCount: number;
            orderCount: number;
            netWeight: number;
            volume: number;
            quantity: number;
            uomQuantities: Array<{
              __typename?: 'LoadingJobUomQuantity';
              uom: string;
              uomQuantity: number;
            }>;
            possibleGates: Array<{
              __typename?: 'LoadingJobGateDetails';
              gateId: string;
              distance: number;
              duration: number;
              cost: number;
            }>;
          }
        | { __typename: 'PickingJobDetails' }
        | { __typename: 'ReassignJobDetails' }
        | { __typename: 'ReplenishmentJobDetails' }
        | null;
    } | null;
  }>;
};

export type GateUsageScheduleFragment = {
  __typename?: 'GateUsageSchedule';
  startTime: string;
  completionTime: string;
  deadlines: Array<string>;
  resources: Array<{
    __typename?: 'GateUsageScheduleResource';
    id: string;
    assignments: Array<{
      __typename?: 'GateUsageScheduleJobAssignment';
      startTime: string;
      completionTime: string;
      totalDuration?: number | null;
      dockingDuration?: number | null;
      undockingDuration?: number | null;
      job: {
        __typename?: 'GateUsageScheduleJob';
        id: string;
        duration: number;
        deadline?: string | null;
      };
      jobDetails?: {
        __typename?: 'AnalyzeResultJob';
        id: string;
        title?: string | null;
        process: AnalyzeResultProcessType;
        agents: Array<string>;
        jobDistance: number;
        jobDuration: number;
        totalCost: number;
        totalDistance: number;
        totalDuration: number;
        details?:
          | {
              __typename: 'LoadingJobDetails';
              picklistCount: number;
              picklistLineCount: number;
              orderCount: number;
              netWeight: number;
              volume: number;
              quantity: number;
              uomQuantities: Array<{
                __typename?: 'LoadingJobUomQuantity';
                uom: string;
                uomQuantity: number;
              }>;
              possibleGates: Array<{
                __typename?: 'LoadingJobGateDetails';
                gateId: string;
                distance: number;
                duration: number;
                cost: number;
              }>;
            }
          | { __typename: 'PickingJobDetails' }
          | { __typename: 'ReassignJobDetails' }
          | { __typename: 'ReplenishmentJobDetails' }
          | null;
      } | null;
    }>;
  }>;
  totalCost: {
    __typename?: 'GateUsageScheduleCost';
    tardiness: number;
    cost: number;
  };
  totalCostWithoutWaiting: {
    __typename?: 'GateUsageScheduleCost';
    tardiness: number;
    cost: number;
  };
};

export type ScheduleGateUsageMutationVariables = Exact<{
  input: ScheduleGateUsageInput;
}>;

export type ScheduleGateUsageMutation = {
  __typename?: 'Mutation';
  scheduleGateUsage: {
    __typename?: 'GateUsageSchedule';
    startTime: string;
    completionTime: string;
    deadlines: Array<string>;
    resources: Array<{
      __typename?: 'GateUsageScheduleResource';
      id: string;
      assignments: Array<{
        __typename?: 'GateUsageScheduleJobAssignment';
        startTime: string;
        completionTime: string;
        totalDuration?: number | null;
        dockingDuration?: number | null;
        undockingDuration?: number | null;
        job: {
          __typename?: 'GateUsageScheduleJob';
          id: string;
          duration: number;
          deadline?: string | null;
        };
        jobDetails?: {
          __typename?: 'AnalyzeResultJob';
          id: string;
          title?: string | null;
          process: AnalyzeResultProcessType;
          agents: Array<string>;
          jobDistance: number;
          jobDuration: number;
          totalCost: number;
          totalDistance: number;
          totalDuration: number;
          details?:
            | {
                __typename: 'LoadingJobDetails';
                picklistCount: number;
                picklistLineCount: number;
                orderCount: number;
                netWeight: number;
                volume: number;
                quantity: number;
                uomQuantities: Array<{
                  __typename?: 'LoadingJobUomQuantity';
                  uom: string;
                  uomQuantity: number;
                }>;
                possibleGates: Array<{
                  __typename?: 'LoadingJobGateDetails';
                  gateId: string;
                  distance: number;
                  duration: number;
                  cost: number;
                }>;
              }
            | { __typename: 'PickingJobDetails' }
            | { __typename: 'ReassignJobDetails' }
            | { __typename: 'ReplenishmentJobDetails' }
            | null;
        } | null;
      }>;
    }>;
    totalCost: {
      __typename?: 'GateUsageScheduleCost';
      tardiness: number;
      cost: number;
    };
    totalCostWithoutWaiting: {
      __typename?: 'GateUsageScheduleCost';
      tardiness: number;
      cost: number;
    };
  };
};

export type AnalyzeResultEventTypeSummaryFragment = {
  __typename?: 'AnalyzeResultEventTypeSummary';
  eventType: PicklistEventType;
  processType?: AnalyzeResultProcessType | null;
  count: number;
  totalDuration: number;
  totalCost: number;
};

export type AnalyzeResultStatusFragment = {
  __typename?: 'AnalyzeResult';
  id: string;
  status: AnalyzeResultStatus;
  stage?: AnalyzeResultStage | null;
  progress?: number | null;
};

export type AnalyzeResultProcessCostFragment = {
  __typename?: 'AnalyzeResultProcessCost';
  processType: AnalyzeResultProcessType;
  distance: number;
  duration: number;
  cost: number;
};

export type AnalyzeResultAgentSummaryFragment = {
  __typename?: 'AnalyzeResultAgentSummary';
  agent: string;
  eventTypes: Array<{
    __typename?: 'AnalyzeResultEventTypeSummary';
    eventType: PicklistEventType;
    processType?: AnalyzeResultProcessType | null;
    count: number;
    totalDuration: number;
    totalCost: number;
  }>;
};

export type AnalyzeResultSummaryFragment = {
  __typename?: 'AnalyzeResult';
  id: string;
  status: AnalyzeResultStatus;
  stage?: AnalyzeResultStage | null;
  progress?: number | null;
  totalOrders: number;
  totalAssembledOrders: number;
  totalAssembledPartiallyOrders: number;
  totalLines: number;
  assembledLines: number;
  assembledPartiallyLines: number;
  ignoredLines: number;
  totalDistance: number;
  totalCost: number;
  totalDuration: number;
  costDetails?: {
    __typename?: 'AnalyzeResultCostDetails';
    processes: Array<{
      __typename?: 'AnalyzeResultProcessCost';
      processType: AnalyzeResultProcessType;
      distance: number;
      duration: number;
      cost: number;
    }>;
  } | null;
  summary?: {
    __typename?: 'AnalyzeResultSummary';
    eventTypes: Array<{
      __typename?: 'AnalyzeResultEventTypeSummary';
      eventType: PicklistEventType;
      processType?: AnalyzeResultProcessType | null;
      count: number;
      totalDuration: number;
      totalCost: number;
    }>;
    agents: Array<{
      __typename?: 'AnalyzeResultAgentSummary';
      agent: string;
      eventTypes: Array<{
        __typename?: 'AnalyzeResultEventTypeSummary';
        eventType: PicklistEventType;
        processType?: AnalyzeResultProcessType | null;
        count: number;
        totalDuration: number;
        totalCost: number;
      }>;
    }>;
    assignment?: {
      __typename?: 'AnalyzeResultAssignmentSummary';
      totalStoredItems?: number | null;
    } | null;
  } | null;
  resourcePolicy?: {
    __typename?: 'ResourcePolicy';
    agents: Array<{
      __typename?: 'AgentSettings';
      id: string;
      specs?: {
        __typename?: 'AgentSpecs';
        powerSource?: {
          __typename?: 'AgentPowerSourceSettings';
          energyConsumptionPerSecond?: number | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type AnalyzeResultFragment = {
  __typename?: 'AnalyzeResult';
  picklistPickByDates: Array<string | null>;
  maxLocationHitCount?: number | null;
  id: string;
  status: AnalyzeResultStatus;
  stage?: AnalyzeResultStage | null;
  progress?: number | null;
  totalOrders: number;
  totalAssembledOrders: number;
  totalAssembledPartiallyOrders: number;
  totalLines: number;
  assembledLines: number;
  assembledPartiallyLines: number;
  ignoredLines: number;
  totalDistance: number;
  totalCost: number;
  totalDuration: number;
  maxReorderTriggeredSummary?: {
    __typename?: 'AnalyzeResultReorderSummary';
    count: number;
    totalDuration: number;
    totalCost: number;
    totalReorderedWeight: number;
  } | null;
  maxReorderAppliedSummary?: {
    __typename?: 'AnalyzeResultReorderSummary';
    count: number;
    totalDuration: number;
    totalCost: number;
    totalReorderedWeight: number;
  } | null;
  costDetails?: {
    __typename?: 'AnalyzeResultCostDetails';
    processes: Array<{
      __typename?: 'AnalyzeResultProcessCost';
      processType: AnalyzeResultProcessType;
      distance: number;
      duration: number;
      cost: number;
    }>;
  } | null;
  summary?: {
    __typename?: 'AnalyzeResultSummary';
    eventTypes: Array<{
      __typename?: 'AnalyzeResultEventTypeSummary';
      eventType: PicklistEventType;
      processType?: AnalyzeResultProcessType | null;
      count: number;
      totalDuration: number;
      totalCost: number;
    }>;
    agents: Array<{
      __typename?: 'AnalyzeResultAgentSummary';
      agent: string;
      eventTypes: Array<{
        __typename?: 'AnalyzeResultEventTypeSummary';
        eventType: PicklistEventType;
        processType?: AnalyzeResultProcessType | null;
        count: number;
        totalDuration: number;
        totalCost: number;
      }>;
    }>;
    assignment?: {
      __typename?: 'AnalyzeResultAssignmentSummary';
      totalStoredItems?: number | null;
    } | null;
  } | null;
  resourcePolicy?: {
    __typename?: 'ResourcePolicy';
    agents: Array<{
      __typename?: 'AgentSettings';
      id: string;
      specs?: {
        __typename?: 'AgentSpecs';
        powerSource?: {
          __typename?: 'AgentPowerSourceSettings';
          energyConsumptionPerSecond?: number | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type ProductLocationIdFragment = {
  __typename?: 'ProductLocationId';
  consignee: string;
  sku: string;
  locationId: string;
};

export type AnalyzeSimulationMutationVariables = Exact<{
  input: AnalyzeSimulationInput;
}>;

export type AnalyzeSimulationMutation = {
  __typename?: 'Mutation';
  analyzeSimulation: {
    __typename?: 'AnalyzeSimulationPayload';
    analyzeResult: {
      __typename?: 'AnalyzeResult';
      id: string;
      stage?: AnalyzeResultStage | null;
      status: AnalyzeResultStatus;
      progress?: number | null;
    };
  };
};

export type LoadAnalyzeQueryVariables = Exact<{
  analyzeId: Scalars['ID']['input'];
}>;

export type LoadAnalyzeQuery = {
  __typename?: 'Query';
  analyzeResult?: {
    __typename?: 'AnalyzeResult';
    picklistPickByDates: Array<string | null>;
    maxLocationHitCount?: number | null;
    id: string;
    status: AnalyzeResultStatus;
    stage?: AnalyzeResultStage | null;
    progress?: number | null;
    totalOrders: number;
    totalAssembledOrders: number;
    totalAssembledPartiallyOrders: number;
    totalLines: number;
    assembledLines: number;
    assembledPartiallyLines: number;
    ignoredLines: number;
    totalDistance: number;
    totalCost: number;
    totalDuration: number;
    maxReorderTriggeredSummary?: {
      __typename?: 'AnalyzeResultReorderSummary';
      count: number;
      totalDuration: number;
      totalCost: number;
      totalReorderedWeight: number;
    } | null;
    maxReorderAppliedSummary?: {
      __typename?: 'AnalyzeResultReorderSummary';
      count: number;
      totalDuration: number;
      totalCost: number;
      totalReorderedWeight: number;
    } | null;
    costDetails?: {
      __typename?: 'AnalyzeResultCostDetails';
      processes: Array<{
        __typename?: 'AnalyzeResultProcessCost';
        processType: AnalyzeResultProcessType;
        distance: number;
        duration: number;
        cost: number;
      }>;
    } | null;
    summary?: {
      __typename?: 'AnalyzeResultSummary';
      eventTypes: Array<{
        __typename?: 'AnalyzeResultEventTypeSummary';
        eventType: PicklistEventType;
        processType?: AnalyzeResultProcessType | null;
        count: number;
        totalDuration: number;
        totalCost: number;
      }>;
      agents: Array<{
        __typename?: 'AnalyzeResultAgentSummary';
        agent: string;
        eventTypes: Array<{
          __typename?: 'AnalyzeResultEventTypeSummary';
          eventType: PicklistEventType;
          processType?: AnalyzeResultProcessType | null;
          count: number;
          totalDuration: number;
          totalCost: number;
        }>;
      }>;
      assignment?: {
        __typename?: 'AnalyzeResultAssignmentSummary';
        totalStoredItems?: number | null;
      } | null;
    } | null;
    resourcePolicy?: {
      __typename?: 'ResourcePolicy';
      agents: Array<{
        __typename?: 'AgentSettings';
        id: string;
        specs?: {
          __typename?: 'AgentSpecs';
          powerSource?: {
            __typename?: 'AgentPowerSourceSettings';
            energyConsumptionPerSecond?: number | null;
          } | null;
        } | null;
      }>;
    } | null;
  } | null;
};

export type AnalyzeResultProductsCategoryFragment = {
  __typename?: 'AnalyzeResultProductsCategory';
  skip: number;
  count: number;
  limit: number;
  minBoundary?: number | null;
  maxBoundary?: number | null;
  minPercentRank?: number | null;
  maxPercentRank?: number | null;
  minCumulativePercentRank?: number | null;
  maxCumulativePercentRank?: number | null;
  totalHitCount: number;
};

export type AnalyzeProductsByCategoryFragment = {
  __typename?: 'AnalyzeResultProductsByCategoryConnection';
  totalCount: number;
  totalHitCount: number;
  categories: Array<{
    __typename?: 'AnalyzeResultProductsCategory';
    skip: number;
    count: number;
    limit: number;
    minBoundary?: number | null;
    maxBoundary?: number | null;
    minPercentRank?: number | null;
    maxPercentRank?: number | null;
    minCumulativePercentRank?: number | null;
    maxCumulativePercentRank?: number | null;
    totalHitCount: number;
  }>;
};

export type LoadAnalyzedProductByCategoriesQueryVariables = Exact<{
  analyzeId: Scalars['ID']['input'];
  categoryWidths: Array<Scalars['Float']['input']> | Scalars['Float']['input'];
}>;

export type LoadAnalyzedProductByCategoriesQuery = {
  __typename?: 'Query';
  analyzeResult?: {
    __typename?: 'AnalyzeResult';
    pickedProductsByCategory?: {
      __typename?: 'AnalyzeResultProductsByCategoryConnection';
      totalCount: number;
      totalHitCount: number;
      categories: Array<{
        __typename?: 'AnalyzeResultProductsCategory';
        skip: number;
        count: number;
        limit: number;
        minBoundary?: number | null;
        maxBoundary?: number | null;
        minPercentRank?: number | null;
        maxPercentRank?: number | null;
        minCumulativePercentRank?: number | null;
        maxCumulativePercentRank?: number | null;
        totalHitCount: number;
      }>;
    } | null;
  } | null;
};

export type AnalyzeResultToCompareFragment = {
  __typename?: 'AnalyzeResult';
  picklistPickByDates: Array<string | null>;
  maxLocationHitCount?: number | null;
  id: string;
  status: AnalyzeResultStatus;
  stage?: AnalyzeResultStage | null;
  progress?: number | null;
  totalOrders: number;
  totalAssembledOrders: number;
  totalAssembledPartiallyOrders: number;
  totalLines: number;
  assembledLines: number;
  assembledPartiallyLines: number;
  ignoredLines: number;
  totalDistance: number;
  totalCost: number;
  totalDuration: number;
  layout: { __typename?: 'Layout'; id: string; title: string };
  assignment: { __typename?: 'Assignment'; id: string; title: string };
  orderSet: { __typename?: 'OrderSet'; id: string; title: string };
  itemSet: { __typename?: 'ItemSet'; id: string; title: string };
  maxReorderTriggeredSummary?: {
    __typename?: 'AnalyzeResultReorderSummary';
    count: number;
    totalDuration: number;
    totalCost: number;
    totalReorderedWeight: number;
  } | null;
  maxReorderAppliedSummary?: {
    __typename?: 'AnalyzeResultReorderSummary';
    count: number;
    totalDuration: number;
    totalCost: number;
    totalReorderedWeight: number;
  } | null;
  costDetails?: {
    __typename?: 'AnalyzeResultCostDetails';
    processes: Array<{
      __typename?: 'AnalyzeResultProcessCost';
      processType: AnalyzeResultProcessType;
      distance: number;
      duration: number;
      cost: number;
    }>;
  } | null;
  summary?: {
    __typename?: 'AnalyzeResultSummary';
    eventTypes: Array<{
      __typename?: 'AnalyzeResultEventTypeSummary';
      eventType: PicklistEventType;
      processType?: AnalyzeResultProcessType | null;
      count: number;
      totalDuration: number;
      totalCost: number;
    }>;
    agents: Array<{
      __typename?: 'AnalyzeResultAgentSummary';
      agent: string;
      eventTypes: Array<{
        __typename?: 'AnalyzeResultEventTypeSummary';
        eventType: PicklistEventType;
        processType?: AnalyzeResultProcessType | null;
        count: number;
        totalDuration: number;
        totalCost: number;
      }>;
    }>;
    assignment?: {
      __typename?: 'AnalyzeResultAssignmentSummary';
      totalStoredItems?: number | null;
    } | null;
  } | null;
  resourcePolicy?: {
    __typename?: 'ResourcePolicy';
    agents: Array<{
      __typename?: 'AgentSettings';
      id: string;
      specs?: {
        __typename?: 'AgentSpecs';
        powerSource?: {
          __typename?: 'AgentPowerSourceSettings';
          energyConsumptionPerSecond?: number | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type AnalyzeResultListItemFragment = {
  __typename?: 'AnalyzeResult';
  id: string;
  createdAt: number;
  updatedAt: number;
};

export type SimulationAnalyzeListItemFragment = {
  __typename?: 'Simulation';
  id: string;
  title: string;
  createdAt: number;
  updatedAt: number;
  layout?: { __typename?: 'Layout'; id: string; title: string } | null;
  analyzeResult?: {
    __typename?: 'AnalyzeResult';
    id: string;
    createdAt: number;
    updatedAt: number;
  } | null;
  latestAllocationRun?: {
    __typename?: 'AllocationRun';
    analyzeResult?: {
      __typename?: 'AnalyzeResult';
      id: string;
      createdAt: number;
      updatedAt: number;
    } | null;
  } | null;
  latestOptimizationRun?: {
    __typename?: 'OptimizationRun';
    analyzeResult?: {
      __typename?: 'AnalyzeResult';
      id: string;
      createdAt: number;
      updatedAt: number;
    } | null;
  } | null;
};

export type LoadSimulationAnalyzeListQueryVariables = Exact<{
  warehouseId: Scalars['ID']['input'];
}>;

export type LoadSimulationAnalyzeListQuery = {
  __typename?: 'Query';
  warehouse?: {
    __typename?: 'Warehouse';
    simulations: {
      __typename?: 'SimulationsConnection';
      content: Array<{
        __typename?: 'Simulation';
        id: string;
        title: string;
        createdAt: number;
        updatedAt: number;
        layout?: { __typename?: 'Layout'; id: string; title: string } | null;
        analyzeResult?: {
          __typename?: 'AnalyzeResult';
          id: string;
          createdAt: number;
          updatedAt: number;
        } | null;
        latestAllocationRun?: {
          __typename?: 'AllocationRun';
          analyzeResult?: {
            __typename?: 'AnalyzeResult';
            id: string;
            createdAt: number;
            updatedAt: number;
          } | null;
        } | null;
        latestOptimizationRun?: {
          __typename?: 'OptimizationRun';
          analyzeResult?: {
            __typename?: 'AnalyzeResult';
            id: string;
            createdAt: number;
            updatedAt: number;
          } | null;
        } | null;
      }>;
    };
  } | null;
};

export type LoadAnalyzeToCompareQueryVariables = Exact<{
  analyzeId: Scalars['ID']['input'];
}>;

export type LoadAnalyzeToCompareQuery = {
  __typename?: 'Query';
  analyzeResult?: {
    __typename?: 'AnalyzeResult';
    picklistPickByDates: Array<string | null>;
    maxLocationHitCount?: number | null;
    id: string;
    status: AnalyzeResultStatus;
    stage?: AnalyzeResultStage | null;
    progress?: number | null;
    totalOrders: number;
    totalAssembledOrders: number;
    totalAssembledPartiallyOrders: number;
    totalLines: number;
    assembledLines: number;
    assembledPartiallyLines: number;
    ignoredLines: number;
    totalDistance: number;
    totalCost: number;
    totalDuration: number;
    layout: { __typename?: 'Layout'; id: string; title: string };
    assignment: { __typename?: 'Assignment'; id: string; title: string };
    orderSet: { __typename?: 'OrderSet'; id: string; title: string };
    itemSet: { __typename?: 'ItemSet'; id: string; title: string };
    maxReorderTriggeredSummary?: {
      __typename?: 'AnalyzeResultReorderSummary';
      count: number;
      totalDuration: number;
      totalCost: number;
      totalReorderedWeight: number;
    } | null;
    maxReorderAppliedSummary?: {
      __typename?: 'AnalyzeResultReorderSummary';
      count: number;
      totalDuration: number;
      totalCost: number;
      totalReorderedWeight: number;
    } | null;
    costDetails?: {
      __typename?: 'AnalyzeResultCostDetails';
      processes: Array<{
        __typename?: 'AnalyzeResultProcessCost';
        processType: AnalyzeResultProcessType;
        distance: number;
        duration: number;
        cost: number;
      }>;
    } | null;
    summary?: {
      __typename?: 'AnalyzeResultSummary';
      eventTypes: Array<{
        __typename?: 'AnalyzeResultEventTypeSummary';
        eventType: PicklistEventType;
        processType?: AnalyzeResultProcessType | null;
        count: number;
        totalDuration: number;
        totalCost: number;
      }>;
      agents: Array<{
        __typename?: 'AnalyzeResultAgentSummary';
        agent: string;
        eventTypes: Array<{
          __typename?: 'AnalyzeResultEventTypeSummary';
          eventType: PicklistEventType;
          processType?: AnalyzeResultProcessType | null;
          count: number;
          totalDuration: number;
          totalCost: number;
        }>;
      }>;
      assignment?: {
        __typename?: 'AnalyzeResultAssignmentSummary';
        totalStoredItems?: number | null;
      } | null;
    } | null;
    resourcePolicy?: {
      __typename?: 'ResourcePolicy';
      agents: Array<{
        __typename?: 'AgentSettings';
        id: string;
        specs?: {
          __typename?: 'AgentSpecs';
          powerSource?: {
            __typename?: 'AgentPowerSourceSettings';
            energyConsumptionPerSecond?: number | null;
          } | null;
        } | null;
      }>;
    } | null;
  } | null;
};

export type DatasetObjectFieldSummaryFragment = {
  __typename?: 'DatasetObjectFieldSummary';
  name: string;
  hasData?: boolean | null;
  distinctValueCount?: number | null;
};

export type DatasetObjectRawDataFieldSummaryFragment = {
  __typename?: 'DatasetObjectRawDataFieldSummary';
  name: string;
  type?: Array<string> | null;
  hasData?: boolean | null;
  distinctValueCount?: number | null;
};

export type DatasetObjectFragment = {
  __typename?: 'DatasetObject';
  id: string;
  title: string;
  description?: string | null;
  objectType: ImportJobImportType;
  userTags?: Array<string> | null;
  importedAt?: number | null;
  createdAt: number;
  updatedAt: number;
  summary?: {
    __typename?: 'DatasetObjectSummary';
    generatedAt?: number | null;
    totalCount?: number | null;
    fields?: Array<{
      __typename?: 'DatasetObjectFieldSummary';
      name: string;
      hasData?: boolean | null;
      distinctValueCount?: number | null;
    }> | null;
    rawDataFields?: Array<{
      __typename?: 'DatasetObjectRawDataFieldSummary';
      name: string;
      type?: Array<string> | null;
      hasData?: boolean | null;
      distinctValueCount?: number | null;
    }> | null;
  } | null;
};

export type DatasetObjectQueryResponseFragment = {
  __typename?: 'DatasetObjectQueryResponse';
  result: any;
  columns: Array<{
    __typename?: 'DatasetObjectQueryColumn';
    name: string;
    type: string;
  }>;
};

export type LoadDatasetObjectsQueryVariables = Exact<{
  warehouseId: Scalars['ID']['input'];
  filter?: InputMaybe<DatasetObjectFilter>;
}>;

export type LoadDatasetObjectsQuery = {
  __typename?: 'Query';
  warehouse?: {
    __typename?: 'Warehouse';
    datasetObjects: {
      __typename?: 'DatasetObjectsConnection';
      totalCount: number;
      content: Array<{
        __typename?: 'DatasetObject';
        id: string;
        title: string;
        description?: string | null;
        objectType: ImportJobImportType;
        userTags?: Array<string> | null;
        importedAt?: number | null;
        createdAt: number;
        updatedAt: number;
        summary?: {
          __typename?: 'DatasetObjectSummary';
          generatedAt?: number | null;
          totalCount?: number | null;
          fields?: Array<{
            __typename?: 'DatasetObjectFieldSummary';
            name: string;
            hasData?: boolean | null;
            distinctValueCount?: number | null;
          }> | null;
          rawDataFields?: Array<{
            __typename?: 'DatasetObjectRawDataFieldSummary';
            name: string;
            type?: Array<string> | null;
            hasData?: boolean | null;
            distinctValueCount?: number | null;
          }> | null;
        } | null;
      }>;
    };
  } | null;
};

export type QueryDatasetQueryVariables = Exact<{
  warehouseId: Scalars['ID']['input'];
  query: Scalars['String']['input'];
  params?: InputMaybe<
    Array<Scalars['JSON']['input']> | Scalars['JSON']['input']
  >;
}>;

export type QueryDatasetQuery = {
  __typename?: 'Query';
  warehouse?: {
    __typename?: 'Warehouse';
    datasetObjectQuery: {
      __typename?: 'DatasetObjectQueryResponse';
      result: any;
      columns: Array<{
        __typename?: 'DatasetObjectQueryColumn';
        name: string;
        type: string;
      }>;
    };
  } | null;
};

export type LoadDatasetObjectQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type LoadDatasetObjectQuery = {
  __typename?: 'Query';
  datasetObject?: {
    __typename?: 'DatasetObject';
    id: string;
    title: string;
    description?: string | null;
    objectType: ImportJobImportType;
    userTags?: Array<string> | null;
    importedAt?: number | null;
    createdAt: number;
    updatedAt: number;
    summary?: {
      __typename?: 'DatasetObjectSummary';
      generatedAt?: number | null;
      totalCount?: number | null;
      fields?: Array<{
        __typename?: 'DatasetObjectFieldSummary';
        name: string;
        hasData?: boolean | null;
        distinctValueCount?: number | null;
      }> | null;
      rawDataFields?: Array<{
        __typename?: 'DatasetObjectRawDataFieldSummary';
        name: string;
        type?: Array<string> | null;
        hasData?: boolean | null;
        distinctValueCount?: number | null;
      }> | null;
    } | null;
  } | null;
};

export type UpdateDatasetObjectMutationVariables = Exact<{
  input: UpdateDatasetObjectInput;
}>;

export type UpdateDatasetObjectMutation = {
  __typename?: 'Mutation';
  updateDatasetObject: {
    __typename?: 'DatasetObject';
    id: string;
    title: string;
    description?: string | null;
    objectType: ImportJobImportType;
    userTags?: Array<string> | null;
    importedAt?: number | null;
    createdAt: number;
    updatedAt: number;
    summary?: {
      __typename?: 'DatasetObjectSummary';
      generatedAt?: number | null;
      totalCount?: number | null;
      fields?: Array<{
        __typename?: 'DatasetObjectFieldSummary';
        name: string;
        hasData?: boolean | null;
        distinctValueCount?: number | null;
      }> | null;
      rawDataFields?: Array<{
        __typename?: 'DatasetObjectRawDataFieldSummary';
        name: string;
        type?: Array<string> | null;
        hasData?: boolean | null;
        distinctValueCount?: number | null;
      }> | null;
    } | null;
  };
};

export type RemoveDatasetObjectMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type RemoveDatasetObjectMutation = {
  __typename?: 'Mutation';
  removeDatasetObject: boolean;
};

export type StackingPolicyItemFragment = {
  __typename?: 'AssignmentItemWithMatchingFilter';
  locationId: string;
  itemId: string;
  consignee: string;
  sku: string;
  matchingFilter?: number | null;
};

export type StackingPolicyStatsFragment = {
  __typename?: 'CountAssignmentItemsByMatchingFilterResult';
  matchesCount: Array<number>;
  unmatchedCount: number;
};

export type LoadStackingPolicyStatsQueryVariables = Exact<{
  input: ApplyAssignmentItemFilterInput;
}>;

export type LoadStackingPolicyStatsQuery = {
  __typename?: 'Query';
  countAssignmentItemsByMatchingFilter: {
    __typename?: 'CountAssignmentItemsByMatchingFilterResult';
    matchesCount: Array<number>;
    unmatchedCount: number;
  };
};

export type LoadStackingPolicyItemsQueryVariables = Exact<{
  input: ApplyAssignmentItemFilterInput;
  filter?: InputMaybe<AssignmentItemsExtendedFilter>;
}>;

export type LoadStackingPolicyItemsQuery = {
  __typename?: 'Query';
  applyAssignmentItemFilter: {
    __typename?: 'AssignmentItemWithMatchingFiltersConnection';
    totalCount: number;
    content: Array<{
      __typename?: 'AssignmentItemWithMatchingFilter';
      locationId: string;
      itemId: string;
      consignee: string;
      sku: string;
      matchingFilter?: number | null;
    }>;
  };
};

export type LayoutLocationWithMatchingFiltersFragment = {
  __typename?: 'LayoutLocationWithMatchingFilters';
  locationId: string;
  allMatchingFilters: Array<number>;
};

export type LoadLocationsByFilterQueryVariables = Exact<{
  layoutId: Scalars['ID']['input'];
  input: GroupLocationsByFilterInput;
  filter?: InputMaybe<LayoutLocationFilter>;
}>;

export type LoadLocationsByFilterQuery = {
  __typename?: 'Query';
  layout?: {
    __typename?: 'Layout';
    locationsWithMatchingFilters: {
      __typename?: 'LayoutLocationWithMatchingFiltersConnection';
      totalCount: number;
      content: Array<{
        __typename?: 'LayoutLocationWithMatchingFilters';
        locationId: string;
        allMatchingFilters: Array<number>;
      }>;
    };
  } | null;
};

export type GroupLocationsByFilterResultFragment = {
  __typename?: 'GroupLocationsByFilterResult';
  filterMatches: Array<{
    __typename?: 'LayoutLocationIdsConnection';
    totalCount: number;
  }>;
  unmatched?: {
    __typename?: 'LayoutLocationIdsConnection';
    totalCount: number;
  } | null;
};

export type LoadLocationsCountByFilterQueryVariables = Exact<{
  layoutId: Scalars['ID']['input'];
  filter?: InputMaybe<LocationFilterUnionInput>;
}>;

export type LoadLocationsCountByFilterQuery = {
  __typename?: 'Query';
  layout?: {
    __typename?: 'Layout';
    locationGroupsByFilter: {
      __typename?: 'GroupLocationsByFilterResult';
      filterMatches: Array<{
        __typename?: 'LayoutLocationIdsConnection';
        totalCount: number;
      }>;
      unmatched?: {
        __typename?: 'LayoutLocationIdsConnection';
        totalCount: number;
      } | null;
    };
  } | null;
};

export type FindOrderLineFilterValuesQueryVariables = Exact<{
  input: FindSimulationOrderLineFilterValuesInput;
  page?: InputMaybe<SkipLimitPageSpec>;
}>;

export type FindOrderLineFilterValuesQuery = {
  __typename?: 'Query';
  findSimulationOrderLineFilterValues: {
    __typename?: 'FilterValuesConnection';
    totalCount?: number | null;
    content?: Array<{ __typename?: 'FilterValue'; title: string }> | null;
    range?: {
      __typename?: 'NumberFilterRange';
      from?: number | null;
      to?: number | null;
    } | null;
  };
};

export type UpdateLayoutMutationVariables = Exact<{
  layoutInput: UpdateLayoutInput;
}>;

export type UpdateLayoutMutation = {
  __typename?: 'Mutation';
  updateLayout: {
    __typename?: 'Layout';
    id: string;
    description?: string | null;
  };
};

export type RemoveLayoutMutationVariables = Exact<{
  layoutId: Scalars['ID']['input'];
}>;

export type RemoveLayoutMutation = {
  __typename?: 'Mutation';
  removeLayout: boolean;
};

export type PointFragment = { __typename?: 'Point'; x: number; y: number };

export type VectorFragment = { __typename?: 'Vector'; x: number; y: number };

export type SegmentFragment = {
  __typename?: 'Segment';
  start: { __typename?: 'Point'; x: number; y: number };
  end: { __typename?: 'Point'; x: number; y: number };
};

export type Segment1DFragment = {
  __typename?: 'Segment1D';
  start: number;
  end: number;
};

export type AisleFragment = {
  __typename?: 'Aisle';
  id: string;
  title?: string | null;
  nonNavigable?: boolean | null;
  points: Array<{ __typename?: 'Point'; x: number; y: number }>;
};

export type BayLevelFragment = {
  __typename?: 'BayLevel';
  level: number;
  levelHeight: number;
  heightFromFloor: number;
};

export type BayFragment = {
  __typename?: 'Bay';
  id: string;
  title?: string | null;
  width: number;
  depth: number;
  height?: number | null;
  locationOrder: BayLocationOrder;
  hasPass: boolean;
  bayType?: string | null;
  position: { __typename?: 'Point'; x: number; y: number };
  frontEdge: { __typename?: 'Vector'; x: number; y: number };
  levels?: Array<{
    __typename?: 'BayLevel';
    level: number;
    levelHeight: number;
    heightFromFloor: number;
  }> | null;
};

export type PlaneConnectivityGraphFragment = {
  __typename?: 'PlaneConnectivityGraph';
  id: string;
  aislePortals: Array<{
    __typename?: 'AislePortal';
    aisleId1: string;
    aisleId2: string;
    coords: {
      __typename?: 'Segment';
      start: { __typename?: 'Point'; x: number; y: number };
      end: { __typename?: 'Point'; x: number; y: number };
    };
  }>;
  bayPortals: Array<{
    __typename?: 'BayPortal';
    aisleId: string;
    bayId: string;
    coords: {
      __typename?: 'Segment';
      start: { __typename?: 'Point'; x: number; y: number };
      end: { __typename?: 'Point'; x: number; y: number };
    };
    bayProjection: { __typename?: 'Segment1D'; start: number; end: number };
  }>;
};

export type LayoutConnectivityGraphFragment = {
  __typename?: 'LayoutConnectivityGraph';
  planes: Array<{
    __typename?: 'PlaneConnectivityGraph';
    id: string;
    aislePortals: Array<{
      __typename?: 'AislePortal';
      aisleId1: string;
      aisleId2: string;
      coords: {
        __typename?: 'Segment';
        start: { __typename?: 'Point'; x: number; y: number };
        end: { __typename?: 'Point'; x: number; y: number };
      };
    }>;
    bayPortals: Array<{
      __typename?: 'BayPortal';
      aisleId: string;
      bayId: string;
      coords: {
        __typename?: 'Segment';
        start: { __typename?: 'Point'; x: number; y: number };
        end: { __typename?: 'Point'; x: number; y: number };
      };
      bayProjection: { __typename?: 'Segment1D'; start: number; end: number };
    }>;
  }>;
};

export type LayoutPLaneFragment = {
  __typename?: 'LayoutPlane';
  id: string;
  title?: string | null;
  levels?: Array<number> | null;
  outlineShape?: GeoJSON.Geometry | null;
  boundingBox?: GeoJSON.BBox | null;
  start?: {
    __typename?: 'PlaneAccessSpec';
    position: { __typename?: 'Point'; x: number; y: number };
  } | null;
};

export type LayoutViewFragment = {
  __typename?: 'Layout';
  id: string;
  title: string;
  description?: string | null;
  createdAt: number;
  updatedAt: number;
  userTags?: Array<string> | null;
  planes: Array<{
    __typename?: 'LayoutPlane';
    id: string;
    title?: string | null;
    levels?: Array<number> | null;
    outlineShape?: GeoJSON.Geometry | null;
    boundingBox?: GeoJSON.BBox | null;
    start?: {
      __typename?: 'PlaneAccessSpec';
      position: { __typename?: 'Point'; x: number; y: number };
    } | null;
  }>;
  layoutMap: {
    __typename?: 'LayoutMap';
    bayTypes?: Array<{
      __typename?: 'BayTypeModel';
      bayType?: string | null;
      verticalFrameProfile?: number | null;
      shelfHeight?: number | null;
      maxWeight?: number | null;
    }> | null;
    locationTypes?: Array<{
      __typename?: 'LocationTypeModel';
      locationRackingType?: string | null;
      gapWidth?: number | null;
    }> | null;
  };
  connectivityGraph: {
    __typename?: 'LayoutConnectivityGraph';
    planes: Array<{
      __typename?: 'PlaneConnectivityGraph';
      id: string;
      aislePortals: Array<{
        __typename?: 'AislePortal';
        aisleId1: string;
        aisleId2: string;
        coords: {
          __typename?: 'Segment';
          start: { __typename?: 'Point'; x: number; y: number };
          end: { __typename?: 'Point'; x: number; y: number };
        };
      }>;
      bayPortals: Array<{
        __typename?: 'BayPortal';
        aisleId: string;
        bayId: string;
        coords: {
          __typename?: 'Segment';
          start: { __typename?: 'Point'; x: number; y: number };
          end: { __typename?: 'Point'; x: number; y: number };
        };
        bayProjection: { __typename?: 'Segment1D'; start: number; end: number };
      }>;
    }>;
  };
  importedFrom?: {
    __typename?: 'LayoutImport';
    id: string;
    title: string;
    inputFileName: string;
  } | null;
  summary?: {
    __typename?: 'LayoutSummary';
    locationCount: number;
    disabledLocationCount: number;
    aisleCount: number;
    bayCount: number;
    areas: Array<{
      __typename?: 'LayoutAreaSummary';
      areaId: string;
      title: string;
      locationCount: number;
      aisleCount: number;
      bayCount: number;
    }>;
  } | null;
  simulationsWith: {
    __typename?: 'SimulationsConnection';
    totalCount: number;
    content: Array<{
      __typename?: 'Simulation';
      id: string;
      title: string;
      description?: string | null;
      createdAt: number;
      updatedAt: number;
      userTags?: Array<string> | null;
    }>;
  };
};

export type LoadLayoutViewQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type LoadLayoutViewQuery = {
  __typename?: 'Query';
  layout?: {
    __typename?: 'Layout';
    id: string;
    title: string;
    description?: string | null;
    createdAt: number;
    updatedAt: number;
    userTags?: Array<string> | null;
    planes: Array<{
      __typename?: 'LayoutPlane';
      id: string;
      title?: string | null;
      levels?: Array<number> | null;
      outlineShape?: GeoJSON.Geometry | null;
      boundingBox?: GeoJSON.BBox | null;
      start?: {
        __typename?: 'PlaneAccessSpec';
        position: { __typename?: 'Point'; x: number; y: number };
      } | null;
    }>;
    layoutMap: {
      __typename?: 'LayoutMap';
      bayTypes?: Array<{
        __typename?: 'BayTypeModel';
        bayType?: string | null;
        verticalFrameProfile?: number | null;
        shelfHeight?: number | null;
        maxWeight?: number | null;
      }> | null;
      locationTypes?: Array<{
        __typename?: 'LocationTypeModel';
        locationRackingType?: string | null;
        gapWidth?: number | null;
      }> | null;
    };
    connectivityGraph: {
      __typename?: 'LayoutConnectivityGraph';
      planes: Array<{
        __typename?: 'PlaneConnectivityGraph';
        id: string;
        aislePortals: Array<{
          __typename?: 'AislePortal';
          aisleId1: string;
          aisleId2: string;
          coords: {
            __typename?: 'Segment';
            start: { __typename?: 'Point'; x: number; y: number };
            end: { __typename?: 'Point'; x: number; y: number };
          };
        }>;
        bayPortals: Array<{
          __typename?: 'BayPortal';
          aisleId: string;
          bayId: string;
          coords: {
            __typename?: 'Segment';
            start: { __typename?: 'Point'; x: number; y: number };
            end: { __typename?: 'Point'; x: number; y: number };
          };
          bayProjection: {
            __typename?: 'Segment1D';
            start: number;
            end: number;
          };
        }>;
      }>;
    };
    importedFrom?: {
      __typename?: 'LayoutImport';
      id: string;
      title: string;
      inputFileName: string;
    } | null;
    summary?: {
      __typename?: 'LayoutSummary';
      locationCount: number;
      disabledLocationCount: number;
      aisleCount: number;
      bayCount: number;
      areas: Array<{
        __typename?: 'LayoutAreaSummary';
        areaId: string;
        title: string;
        locationCount: number;
        aisleCount: number;
        bayCount: number;
      }>;
    } | null;
    simulationsWith: {
      __typename?: 'SimulationsConnection';
      totalCount: number;
      content: Array<{
        __typename?: 'Simulation';
        id: string;
        title: string;
        description?: string | null;
        createdAt: number;
        updatedAt: number;
        userTags?: Array<string> | null;
      }>;
    };
  } | null;
};

export type BayTypeModelFragment = {
  __typename?: 'BayTypeModel';
  bayType?: string | null;
  verticalFrameProfile?: number | null;
  shelfHeight?: number | null;
  maxWeight?: number | null;
};

export type LocationTypeModelFragment = {
  __typename?: 'LocationTypeModel';
  locationRackingType?: string | null;
  gapWidth?: number | null;
};

export type LayoutLevelLocationFragment = {
  __typename?: 'LayoutLocation';
  locationId: string;
  locationStatus: boolean;
  locationBayId: string;
  locationOrder: string;
  shape?: GeoJSON.Polygon | null;
  portals?: Array<{
    __typename?: 'LocationPortal';
    type?: LocationPortalType | null;
    position: { __typename?: 'Point'; x: number; y: number };
  }> | null;
};

export type LayoutBayLocationFragment = {
  __typename?: 'LayoutLocation';
  locationRackingType: string;
  locationBayPosition: number;
  locationLevel: number;
  locationWidth: number;
  locationLength: number;
  locationHeight: number;
  warehouseArea: string;
  locationHeightFromFloor: number;
  locationDepthPosition: number;
  locationDepthFromFront: number;
  locationIndexFromFront: number;
  locationUsageType: string;
  congestionZone?: string | null;
  locationSide?: string | null;
  locationId: string;
  locationStatus: boolean;
  locationBayId: string;
  locationOrder: string;
  shape?: GeoJSON.Polygon | null;
  portals?: Array<{
    __typename?: 'LocationPortal';
    type?: LocationPortalType | null;
    aisleId: string;
    position: { __typename?: 'Point'; x: number; y: number };
  }> | null;
  plane?: {
    __typename?: 'LayoutPlane';
    id: string;
    title?: string | null;
  } | null;
  bay?: {
    __typename?: 'LayoutFeature';
    id: string;
    title?: string | null;
  } | null;
  aisle?: {
    __typename?: 'LayoutFeature';
    id: string;
    title?: string | null;
  } | null;
};

export type LoadLayoutLevelLocationsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  planeId: Scalars['ID']['input'];
  level: Scalars['Float']['input'];
}>;

export type LoadLayoutLevelLocationsQuery = {
  __typename?: 'Query';
  layout?: {
    __typename?: 'Layout';
    locations: {
      __typename?: 'LayoutLocationsConnection';
      content: Array<{
        __typename?: 'LayoutLocation';
        locationId: string;
        locationStatus: boolean;
        locationBayId: string;
        locationOrder: string;
        shape?: GeoJSON.Polygon | null;
        portals?: Array<{
          __typename?: 'LocationPortal';
          type?: LocationPortalType | null;
          position: { __typename?: 'Point'; x: number; y: number };
        }> | null;
      }>;
    };
  } | null;
};

export type LoadLayoutBayLocationsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  bayId: Scalars['ID']['input'];
}>;

export type LoadLayoutBayLocationsQuery = {
  __typename?: 'Query';
  layout?: {
    __typename?: 'Layout';
    locations: {
      __typename?: 'LayoutLocationsConnection';
      content: Array<{
        __typename?: 'LayoutLocation';
        locationRackingType: string;
        locationBayPosition: number;
        locationLevel: number;
        locationWidth: number;
        locationLength: number;
        locationHeight: number;
        warehouseArea: string;
        locationHeightFromFloor: number;
        locationDepthPosition: number;
        locationDepthFromFront: number;
        locationIndexFromFront: number;
        locationUsageType: string;
        congestionZone?: string | null;
        locationSide?: string | null;
        locationId: string;
        locationStatus: boolean;
        locationBayId: string;
        locationOrder: string;
        shape?: GeoJSON.Polygon | null;
        portals?: Array<{
          __typename?: 'LocationPortal';
          type?: LocationPortalType | null;
          aisleId: string;
          position: { __typename?: 'Point'; x: number; y: number };
        }> | null;
        plane?: {
          __typename?: 'LayoutPlane';
          id: string;
          title?: string | null;
        } | null;
        bay?: {
          __typename?: 'LayoutFeature';
          id: string;
          title?: string | null;
        } | null;
        aisle?: {
          __typename?: 'LayoutFeature';
          id: string;
          title?: string | null;
        } | null;
      }>;
    };
  } | null;
};

export type LayoutMetaFragment = {
  __typename?: 'Layout';
  id: string;
  title: string;
  status: LayoutStatus;
  description?: string | null;
  createdAt: number;
  updatedAt: number;
};

export type LoadAllLayoutsMetaQueryVariables = Exact<{
  warehouseId: Scalars['ID']['input'];
}>;

export type LoadAllLayoutsMetaQuery = {
  __typename?: 'Query';
  warehouse?: {
    __typename?: 'Warehouse';
    layouts: {
      __typename?: 'LayoutsConnection';
      content: Array<{
        __typename?: 'Layout';
        id: string;
        title: string;
        status: LayoutStatus;
        description?: string | null;
        createdAt: number;
        updatedAt: number;
      }>;
    };
  } | null;
};

export type LoadLayoutsSummaryQueryVariables = Exact<{
  layoutId: Scalars['ID']['input'];
}>;

export type LoadLayoutsSummaryQuery = {
  __typename?: 'Query';
  layout?: {
    __typename?: 'Layout';
    id: string;
    title: string;
    status: LayoutStatus;
    description?: string | null;
    createdAt: number;
    updatedAt: number;
    summary?: {
      __typename?: 'LayoutSummary';
      locationCount: number;
      disabledLocationCount: number;
      aisleCount: number;
      bayCount: number;
      areas: Array<{
        __typename?: 'LayoutAreaSummary';
        areaId: string;
        title: string;
        locationCount: number;
        aisleCount: number;
        bayCount: number;
      }>;
    } | null;
  } | null;
};

export type LoadLayoutMetaQueryVariables = Exact<{
  layoutId: Scalars['ID']['input'];
}>;

export type LoadLayoutMetaQuery = {
  __typename?: 'Query';
  layout?: {
    __typename?: 'Layout';
    id: string;
    title: string;
    status: LayoutStatus;
    description?: string | null;
    createdAt: number;
    updatedAt: number;
  } | null;
};

export type LocationPortalDataFragment = {
  __typename?: 'LocationPortal';
  type?: LocationPortalType | null;
  aisle?: {
    __typename?: 'LayoutFeature';
    id: string;
    title?: string | null;
  } | null;
};

export type LayoutLocationDataFragment = {
  __typename?: 'LayoutLocation';
  locationId: string;
  planeId: string;
  locationUsageType: string;
  warehouseArea: string;
  locationBayId: string;
  locationBayPosition: number;
  locmhtype?: string | null;
  locationRackingType: string;
  locationHeightFromFloor: number;
  locationOrder: string;
  locationStatus: boolean;
  locationLevel: number;
  locationLength: number;
  locationWidth: number;
  locationHeight: number;
  locationWeight: number;
  congestionZone?: string | null;
  bayType?: string | null;
  bay?: {
    __typename?: 'LayoutFeature';
    id: string;
    title?: string | null;
  } | null;
  aisle?: {
    __typename?: 'LayoutFeature';
    id: string;
    title?: string | null;
  } | null;
  portals?: Array<{
    __typename?: 'LocationPortal';
    type?: LocationPortalType | null;
    aisle?: {
      __typename?: 'LayoutFeature';
      id: string;
      title?: string | null;
    } | null;
  }> | null;
};

export type LoadLayoutDataQueryVariables = Exact<{
  layoutId: Scalars['ID']['input'];
  page?: InputMaybe<SkipLimitPageSpec>;
  filter?: InputMaybe<LayoutLocationsSearchFilter>;
  sortBy?: InputMaybe<Array<LayoutLocationsSortBy> | LayoutLocationsSortBy>;
}>;

export type LoadLayoutDataQuery = {
  __typename?: 'Query';
  layout?: {
    __typename?: 'Layout';
    locations: {
      __typename?: 'LayoutLocationsConnection';
      totalCount: number;
      content: Array<{
        __typename?: 'LayoutLocation';
        locationId: string;
        planeId: string;
        locationUsageType: string;
        warehouseArea: string;
        locationBayId: string;
        locationBayPosition: number;
        locmhtype?: string | null;
        locationRackingType: string;
        locationHeightFromFloor: number;
        locationOrder: string;
        locationStatus: boolean;
        locationLevel: number;
        locationLength: number;
        locationWidth: number;
        locationHeight: number;
        locationWeight: number;
        congestionZone?: string | null;
        bayType?: string | null;
        bay?: {
          __typename?: 'LayoutFeature';
          id: string;
          title?: string | null;
        } | null;
        aisle?: {
          __typename?: 'LayoutFeature';
          id: string;
          title?: string | null;
        } | null;
        portals?: Array<{
          __typename?: 'LocationPortal';
          type?: LocationPortalType | null;
          aisle?: {
            __typename?: 'LayoutFeature';
            id: string;
            title?: string | null;
          } | null;
        }> | null;
      }>;
    };
  } | null;
};

export type LayoutFeatureBaseFragment = {
  __typename?: 'LayoutFeature';
  id: string;
  type: LayoutFeatureType;
  title?: string | null;
  navigable: boolean;
  details?:
    | { __typename?: 'LayoutAisleDetails'; aisleType?: string | null }
    | { __typename?: 'LayoutBayDetails' }
    | null;
};

export type LayoutFeatureFragment = {
  __typename?: 'LayoutFeature';
  planeId: string;
  id: string;
  type: LayoutFeatureType;
  title?: string | null;
  navigable: boolean;
  details?:
    | { __typename?: 'LayoutAisleDetails'; aisleType?: string | null }
    | { __typename?: 'LayoutBayDetails' }
    | null;
};

export type AisleFeatureDetailsFragment = {
  __typename?: 'LayoutAisleDetails';
  aisleType?: string | null;
};

export type AisleFeatureFragment = {
  __typename?: 'LayoutFeature';
  shape?: GeoJSON.Polygon | null;
  id: string;
  type: LayoutFeatureType;
  title?: string | null;
  navigable: boolean;
  details?:
    | { __typename?: 'LayoutAisleDetails'; aisleType?: string | null }
    | { __typename?: 'LayoutBayDetails' }
    | null;
};

export type BayFeatureFragment = {
  __typename?: 'LayoutFeature';
  shape?: GeoJSON.Polygon | null;
  innerShape?: GeoJSON.Polygon | null;
  id: string;
  type: LayoutFeatureType;
  title?: string | null;
  navigable: boolean;
  details?:
    | { __typename?: 'LayoutAisleDetails'; aisleType?: string | null }
    | { __typename?: 'LayoutBayDetails'; bayType?: string | null }
    | null;
};

export type BayFeatureDetailsFragment = {
  __typename?: 'LayoutBayDetails';
  height?: number | null;
  width: number;
  depth: number;
  locationOrder: BayLocationOrder;
  bayType?: string | null;
  position: { __typename?: 'Point'; x: number; y: number };
  frontEdge: { __typename?: 'Vector'; x: number; y: number };
  levels?: Array<{
    __typename?: 'BayLevel';
    level: number;
    levelHeight: number;
    heightFromFloor: number;
  }> | null;
};

export type BayFeatureDetailedFragment = {
  __typename?: 'LayoutFeature';
  id: string;
  type: LayoutFeatureType;
  title?: string | null;
  navigable: boolean;
  shape?: GeoJSON.Polygon | null;
  innerShape?: GeoJSON.Polygon | null;
  bayAisles: Array<{
    __typename?: 'LayoutBayAisleConnection';
    aisle: { __typename?: 'LayoutFeature'; id: string; title?: string | null };
  }>;
  details?:
    | { __typename?: 'LayoutAisleDetails' }
    | {
        __typename?: 'LayoutBayDetails';
        height?: number | null;
        width: number;
        depth: number;
        locationOrder: BayLocationOrder;
        bayType?: string | null;
        position: { __typename?: 'Point'; x: number; y: number };
        frontEdge: { __typename?: 'Vector'; x: number; y: number };
        levels?: Array<{
          __typename?: 'BayLevel';
          level: number;
          levelHeight: number;
          heightFromFloor: number;
        }> | null;
      }
    | null;
};

export type LoadPlaneAislesQueryVariables = Exact<{
  layoutId: Scalars['ID']['input'];
  planeId?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
}>;

export type LoadPlaneAislesQuery = {
  __typename?: 'Query';
  layout?: {
    __typename?: 'Layout';
    features: Array<{
      __typename?: 'LayoutFeature';
      shape?: GeoJSON.Polygon | null;
      id: string;
      type: LayoutFeatureType;
      title?: string | null;
      navigable: boolean;
      details?:
        | { __typename?: 'LayoutAisleDetails'; aisleType?: string | null }
        | { __typename?: 'LayoutBayDetails' }
        | null;
    }>;
  } | null;
};

export type AisleFeatureMetaFragment = {
  __typename?: 'LayoutFeature';
  id: string;
  planeId: string;
  title?: string | null;
  shape?: GeoJSON.Polygon | null;
  details?:
    | { __typename: 'LayoutAisleDetails'; aisleType?: string | null }
    | { __typename: 'LayoutBayDetails' }
    | null;
};

export type LoadLayoutAislesMetaQueryVariables = Exact<{
  layoutId: Scalars['ID']['input'];
}>;

export type LoadLayoutAislesMetaQuery = {
  __typename?: 'Query';
  layout?: {
    __typename?: 'Layout';
    features: Array<{
      __typename?: 'LayoutFeature';
      id: string;
      planeId: string;
      title?: string | null;
      shape?: GeoJSON.Polygon | null;
      details?:
        | { __typename: 'LayoutAisleDetails'; aisleType?: string | null }
        | { __typename: 'LayoutBayDetails' }
        | null;
    }>;
  } | null;
};

export type LoadPlaneBaysQueryVariables = Exact<{
  layoutId: Scalars['ID']['input'];
  planeId?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
}>;

export type LoadPlaneBaysQuery = {
  __typename?: 'Query';
  layout?: {
    __typename?: 'Layout';
    features: Array<{
      __typename?: 'LayoutFeature';
      shape?: GeoJSON.Polygon | null;
      innerShape?: GeoJSON.Polygon | null;
      id: string;
      type: LayoutFeatureType;
      title?: string | null;
      navigable: boolean;
      details?:
        | { __typename?: 'LayoutAisleDetails'; aisleType?: string | null }
        | { __typename?: 'LayoutBayDetails'; bayType?: string | null }
        | null;
    }>;
  } | null;
};

export type LoadLayoutNavigableFeaturesQueryVariables = Exact<{
  layoutId: Scalars['ID']['input'];
}>;

export type LoadLayoutNavigableFeaturesQuery = {
  __typename?: 'Query';
  layout?: {
    __typename?: 'Layout';
    features: Array<{
      __typename?: 'LayoutFeature';
      planeId: string;
      id: string;
      type: LayoutFeatureType;
      title?: string | null;
      navigable: boolean;
      details?:
        | { __typename?: 'LayoutAisleDetails'; aisleType?: string | null }
        | { __typename?: 'LayoutBayDetails' }
        | null;
    }>;
  } | null;
};

export type LoadBayDetailsQueryVariables = Exact<{
  layoutId: Scalars['ID']['input'];
  planeId?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  bayId?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
}>;

export type LoadBayDetailsQuery = {
  __typename?: 'Query';
  layout?: {
    __typename?: 'Layout';
    features: Array<{
      __typename?: 'LayoutFeature';
      id: string;
      type: LayoutFeatureType;
      title?: string | null;
      navigable: boolean;
      shape?: GeoJSON.Polygon | null;
      innerShape?: GeoJSON.Polygon | null;
      bayAisles: Array<{
        __typename?: 'LayoutBayAisleConnection';
        aisle: {
          __typename?: 'LayoutFeature';
          id: string;
          title?: string | null;
        };
      }>;
      details?:
        | { __typename?: 'LayoutAisleDetails' }
        | {
            __typename?: 'LayoutBayDetails';
            height?: number | null;
            width: number;
            depth: number;
            locationOrder: BayLocationOrder;
            bayType?: string | null;
            position: { __typename?: 'Point'; x: number; y: number };
            frontEdge: { __typename?: 'Vector'; x: number; y: number };
            levels?: Array<{
              __typename?: 'BayLevel';
              level: number;
              levelHeight: number;
              heightFromFloor: number;
            }> | null;
          }
        | null;
    }>;
  } | null;
};

export type LayoutLocationSelectionDataFragment = {
  __typename?: 'LayoutLocation';
  locationId: string;
  planeId: string;
  locationBayId: string;
  locationLevel: number;
  shape?: GeoJSON.Polygon | null;
};

export type LoadLocationDataQueryVariables = Exact<{
  layoutId: Scalars['ID']['input'];
  locationId: Scalars['String']['input'];
}>;

export type LoadLocationDataQuery = {
  __typename?: 'Query';
  layout?: {
    __typename?: 'Layout';
    locations: {
      __typename?: 'LayoutLocationsConnection';
      totalCount: number;
      content: Array<{
        __typename?: 'LayoutLocation';
        locationId: string;
        planeId: string;
        locationBayId: string;
        locationLevel: number;
        shape?: GeoJSON.Polygon | null;
      }>;
    };
  } | null;
};

export type LayoutLocationsSummaryKeyFragment = {
  __typename?: 'LayoutLocationsSummaryKey';
  locationStatus?: boolean | null;
  locationLevel?: number | null;
  locationRackingType?: string | null;
  warehouseArea?: string | null;
  locationSide?: string | null;
  aisleId?: string | null;
  planeId?: string | null;
  assigned?: boolean | null;
  accessAisleId?: string | null;
  consignee?: string | null;
  locationHeight?: number | null;
  locationLength?: number | null;
  locationWidth?: number | null;
};

export type LayoutLocationsSummaryFragment = {
  __typename?: 'LayoutLocationsSummary';
  locationCount: number;
  key: {
    __typename?: 'LayoutLocationsSummaryKey';
    locationStatus?: boolean | null;
    locationLevel?: number | null;
    locationRackingType?: string | null;
    warehouseArea?: string | null;
    locationSide?: string | null;
    aisleId?: string | null;
    planeId?: string | null;
    assigned?: boolean | null;
    accessAisleId?: string | null;
    consignee?: string | null;
    locationHeight?: number | null;
    locationLength?: number | null;
    locationWidth?: number | null;
  };
  uoms?: Array<{
    __typename?: 'LayoutLocationSummaryUomCount';
    id?: string | null;
    uomCount: number;
  }> | null;
};

export type FindLocationsByFilterQueryVariables = Exact<{
  layoutId: Scalars['ID']['input'];
  input: FindLocationsByFilterInput;
  page?: InputMaybe<SkipLimitPageSpec>;
  filter?: InputMaybe<LayoutLocationFilter>;
}>;

export type FindLocationsByFilterQuery = {
  __typename?: 'Query';
  layout?: {
    __typename?: 'Layout';
    locationsByFilter: {
      __typename?: 'LayoutLocationsConnectionWithSummary';
      totalCount: number;
      content: Array<{ __typename?: 'LayoutLocation'; locationId: string }>;
    };
  } | null;
};

export type FindLocationsStatsFilterQueryVariables = Exact<{
  layoutId: Scalars['ID']['input'];
  input: FindLocationsByFilterInput;
  summary?: InputMaybe<LayoutLocationsSummaryRequest>;
}>;

export type FindLocationsStatsFilterQuery = {
  __typename?: 'Query';
  layout?: {
    __typename?: 'Layout';
    locationsByFilter: {
      __typename?: 'LayoutLocationsConnectionWithSummary';
      summary?: Array<{
        __typename?: 'LayoutLocationsSummary';
        locationCount: number;
        key: {
          __typename?: 'LayoutLocationsSummaryKey';
          locationStatus?: boolean | null;
          locationLevel?: number | null;
          locationRackingType?: string | null;
          warehouseArea?: string | null;
          locationSide?: string | null;
          aisleId?: string | null;
          planeId?: string | null;
          assigned?: boolean | null;
          accessAisleId?: string | null;
          consignee?: string | null;
          locationHeight?: number | null;
          locationLength?: number | null;
          locationWidth?: number | null;
        };
        uoms?: Array<{
          __typename?: 'LayoutLocationSummaryUomCount';
          id?: string | null;
          uomCount: number;
        }> | null;
      }> | null;
    };
  } | null;
};

export type AnalyzedPicklistEventsFragment = {
  __typename?: 'AnalyzeResultPicklist';
  picklistId: string;
  summary?: {
    __typename?: 'AnalyzeResultPicklistSummary';
    agents: Array<{
      __typename?: 'AnalyzeResultAgentSummary';
      agent: string;
      eventTypes: Array<{
        __typename?: 'AnalyzeResultEventTypeSummary';
        eventType: PicklistEventType;
        totalDuration: number;
      }>;
    }>;
  } | null;
};

export type AnalyzedOrderEventsFragment = {
  __typename?: 'AnalyzeResultOrder';
  orderId?: string | null;
  picklists: Array<{
    __typename?: 'AnalyzeResultPicklist';
    picklistId: string;
    summary?: {
      __typename?: 'AnalyzeResultPicklistSummary';
      agents: Array<{
        __typename?: 'AnalyzeResultAgentSummary';
        agent: string;
        eventTypes: Array<{
          __typename?: 'AnalyzeResultEventTypeSummary';
          eventType: PicklistEventType;
          totalDuration: number;
        }>;
      }>;
    } | null;
  }>;
};

export type LoadPicklistQueryVariables = Exact<{
  analyzeId: Scalars['ID']['input'];
}>;

export type LoadPicklistQuery = {
  __typename?: 'Query';
  analyzeResult?: {
    __typename?: 'AnalyzeResult';
    orders?: {
      __typename?: 'AnalyzeResultOrdersConnection';
      content: Array<{
        __typename?: 'AnalyzeResultOrder';
        orderId?: string | null;
        picklists: Array<{
          __typename?: 'AnalyzeResultPicklist';
          picklistId: string;
          summary?: {
            __typename?: 'AnalyzeResultPicklistSummary';
            agents: Array<{
              __typename?: 'AnalyzeResultAgentSummary';
              agent: string;
              eventTypes: Array<{
                __typename?: 'AnalyzeResultEventTypeSummary';
                eventType: PicklistEventType;
                totalDuration: number;
              }>;
            }>;
          } | null;
        }>;
      }>;
    } | null;
  } | null;
};

export type ResourceUsageSummaryFragment = {
  __typename?: 'ResourceUsageSummary';
  totalPicklists: number;
  totalPicklistLines: number;
  totalEvents: number;
  totalDuration: number;
  totalCost: number;
  key: {
    __typename?: 'ResourceUsageSummaryKey';
    pickByDate?: string | null;
    agent?: string | null;
    eventType?: PicklistEventType | null;
    orderLineRuleId?: string | null;
    picklistGroupKey?: string | null;
    planeId?: string | null;
  };
};

export type LoadWorkforceDataQueryVariables = Exact<{
  analyzeId: Scalars['ID']['input'];
  drill?: InputMaybe<
    Array<ResourceUsageSummaryGroupBy> | ResourceUsageSummaryGroupBy
  >;
  agentsId?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  from?: InputMaybe<Scalars['ID']['input']>;
  to?: InputMaybe<Scalars['ID']['input']>;
  eventType?: InputMaybe<Array<PicklistEventType> | PicklistEventType>;
}>;

export type LoadWorkforceDataQuery = {
  __typename?: 'Query';
  analyzeResult?: {
    __typename?: 'AnalyzeResult';
    resourceUsageSummary: Array<{
      __typename?: 'ResourceUsageSummary';
      totalPicklists: number;
      totalPicklistLines: number;
      totalEvents: number;
      totalDuration: number;
      totalCost: number;
      key: {
        __typename?: 'ResourceUsageSummaryKey';
        pickByDate?: string | null;
        agent?: string | null;
        eventType?: PicklistEventType | null;
        orderLineRuleId?: string | null;
        picklistGroupKey?: string | null;
        planeId?: string | null;
      };
    }>;
  } | null;
};

export const AssignmentMetaFragmentDoc = gql`
  fragment AssignmentMeta on Assignment {
    id
    title
    description
    createdAt
    updatedAt
    inputFileName
    status
    userTags
  }
`;
export const AssignmentConsigneeSummaryFragmentDoc = gql`
  fragment AssignmentConsigneeSummary on AssignmentConsigneeSummary {
    consignee
    itemsCount
    locationsCount
    productsCount
  }
`;
export const DatasetObjectFieldSummaryFragmentDoc = gql`
  fragment DatasetObjectFieldSummary on DatasetObjectFieldSummary {
    name
    hasData
    distinctValueCount
  }
`;
export const DatasetObjectRawDataFieldSummaryFragmentDoc = gql`
  fragment DatasetObjectRawDataFieldSummary on DatasetObjectRawDataFieldSummary {
    name
    type
    hasData
    distinctValueCount
  }
`;
export const AssignmentSummaryFragmentDoc = gql`
  fragment AssignmentSummary on AssignmentSummary {
    totalItemsCount
    totalLocationsCount
    totalProductsCount
    consignees {
      ...AssignmentConsigneeSummary
    }
    fields {
      ...DatasetObjectFieldSummary
    }
    rawDataFields {
      ...DatasetObjectRawDataFieldSummary
    }
  }
  ${AssignmentConsigneeSummaryFragmentDoc}
  ${DatasetObjectFieldSummaryFragmentDoc}
  ${DatasetObjectRawDataFieldSummaryFragmentDoc}
`;
export const SimulationRefSummaryFragmentDoc = gql`
  fragment SimulationRefSummary on Simulation {
    id
    title
    description
    createdAt
    updatedAt
    userTags
  }
`;
export const SimulationsConnectionFragmentDoc = gql`
  fragment SimulationsConnection on SimulationsConnection {
    totalCount
    content {
      ...SimulationRefSummary
    }
  }
  ${SimulationRefSummaryFragmentDoc}
`;
export const AssignmentFragmentDoc = gql`
  fragment Assignment on Assignment {
    ...AssignmentMeta
    summary {
      ...AssignmentSummary
    }
    simulationsWith(
      sortBy: [{ field: UPDATED_AT, direction: DESC }]
      page: null
    ) {
      ...SimulationsConnection
    }
  }
  ${AssignmentMetaFragmentDoc}
  ${AssignmentSummaryFragmentDoc}
  ${SimulationsConnectionFragmentDoc}
`;
export const AssignmentItemDataFragmentDoc = gql`
  fragment AssignmentItemData on AssignmentItem {
    consignee
    sku
    locationId
    stockUom
    stockUomQuantity
  }
`;
export const AssignmentPolicyComplianceRuleMatchFragmentDoc = gql`
  fragment AssignmentPolicyComplianceRuleMatch on AssignmentPolicyComplianceRuleMatch {
    totalCount
    compliantCount
    nonCompliantCount
  }
`;
export const AssignmentPolicyComplianceRuleSummaryFragmentDoc = gql`
  fragment AssignmentPolicyComplianceRuleSummary on AssignmentPolicyComplianceRuleSummary {
    ruleId
    matchedByLocation {
      ...AssignmentPolicyComplianceRuleMatch
    }
    matchedByItem {
      ...AssignmentPolicyComplianceRuleMatch
    }
  }
  ${AssignmentPolicyComplianceRuleMatchFragmentDoc}
`;
export const AssignmentPolicyComplianceRulesSummaryFragmentDoc = gql`
  fragment AssignmentPolicyComplianceRulesSummary on AssignmentPolicyComplianceRulesSummary {
    rules {
      ...AssignmentPolicyComplianceRuleSummary
    }
    unmatchedByLocation {
      ...AssignmentPolicyComplianceRuleMatch
    }
  }
  ${AssignmentPolicyComplianceRuleSummaryFragmentDoc}
  ${AssignmentPolicyComplianceRuleMatchFragmentDoc}
`;
export const AssignmentComplianceSummaryFragmentDoc = gql`
  fragment AssignmentComplianceSummary on AssignmentCompliance {
    id
    status
    assignmentId
    summary {
      totalSummary {
        nonCompliantCount
        compliantCount
        totalCount
      }
      rulesSummary {
        ...AssignmentPolicyComplianceRulesSummary
      }
    }
  }
  ${AssignmentPolicyComplianceRulesSummaryFragmentDoc}
`;
export const AssignmentComplianceItemFragmentDoc = gql`
  fragment AssignmentComplianceItem on AssignmentComplianceItem {
    itemId
    consignee
    sku
    locationId
    itemRuleIds
    locationRuleIds
    violatedRuleIds
    status
    item {
      skuGroup
      subGroup
    }
  }
`;
export const AssignmentComplianceDataFragmentDoc = gql`
  fragment AssignmentComplianceData on AssignmentComplianceItemsConnection {
    totalCount
    content {
      ...AssignmentComplianceItem
    }
  }
  ${AssignmentComplianceItemFragmentDoc}
`;
export const AssignmentOccupancyCapacitySummaryFragmentDoc = gql`
  fragment AssignmentOccupancyCapacitySummary on AssignmentOccupancyCapacitySummary {
    locationsWithCapacity
    locationsWithUnknownCapacity
    emptyLocations
    totalCapacityVolume
    totalEmptyVolume
    totalUnknownVolume
  }
`;
export const AssignmentOccupancySummaryFragmentDoc = gql`
  fragment AssignmentOccupancySummary on AssignmentOccupancySummary {
    totalStorageVolume
    totalOccupiedVolume
    totalUnknownVolume
    totalOccupancy
    maxOccupancy
    stockUomCapacitySummary {
      ...AssignmentOccupancyCapacitySummary
    }
    maxUomCapacitySummary {
      ...AssignmentOccupancyCapacitySummary
    }
  }
  ${AssignmentOccupancyCapacitySummaryFragmentDoc}
`;
export const AssignmentOccupancyMetaFragmentDoc = gql`
  fragment AssignmentOccupancyMeta on AssignmentOccupancy {
    id
    status
    progress
    createdAt
    updatedAt
    summary {
      ...AssignmentOccupancySummary
    }
  }
  ${AssignmentOccupancySummaryFragmentDoc}
`;
export const AssignmentOccupancyLocationsCategoryByStatusFragmentDoc = gql`
  fragment AssignmentOccupancyLocationsCategoryByStatus on AssignmentOccupancyLocationsCategoryByStatus {
    status
    count
    totalStorageVolume
  }
`;
export const AssignmentOccupancyLocationsCategoryFragmentDoc = gql`
  fragment AssignmentOccupancyLocationsCategory on AssignmentOccupancyLocationsCategory {
    minBoundary
    maxBoundary
    minOccupancy
    maxOccupancy
    count
    totalStorageVolume
    totalOccupiedVolume
  }
`;
export const AssignmentOccupancyFragmentDoc = gql`
  fragment AssignmentOccupancy on AssignmentOccupancy {
    ...AssignmentOccupancyMeta
    locationsByOccupancyCategory(
      input: { categoryWidths: $categoryWidths }
      filter: $filter
    ) {
      categoriesByStatus {
        ...AssignmentOccupancyLocationsCategoryByStatus
      }
      categories {
        ...AssignmentOccupancyLocationsCategory
      }
      totalCount
    }
  }
  ${AssignmentOccupancyMetaFragmentDoc}
  ${AssignmentOccupancyLocationsCategoryByStatusFragmentDoc}
  ${AssignmentOccupancyLocationsCategoryFragmentDoc}
`;
export const AssignmentOccupancyLocationItemFragmentDoc = gql`
  fragment AssignmentOccupancyLocationItem on AssignmentOccupancyLocationItem {
    itemId
    consignee
    sku
    status
    stockUom {
      uom
      length
      width
      height
      volume
      maxStack
    }
    stockUomQuantity
    stockUomQuantityCompliance
    occupiedVolume
    stockUomCapacity {
      uomQuantity
      quantity
      occupiedVolume
    }
    maxUomCapacity {
      uom {
        uom
        maxStack
      }
      uomQuantity
      quantity
      occupiedVolume
    }
  }
`;
export const AssignmentOccupancyLocationStatusFragmentDoc = gql`
  fragment AssignmentOccupancyLocationStatus on AssignmentOccupancyLocationDetails {
    locationId
    locationVolume
    occupiedVolume
    occupancy
    status
    items {
      ...AssignmentOccupancyLocationItem
    }
  }
  ${AssignmentOccupancyLocationItemFragmentDoc}
`;
export const AssignmentOccupancyLocationFragmentDoc = gql`
  fragment AssignmentOccupancyLocation on LayoutLocation {
    locationId
    locationLevel
    locationRackingType
    warehouseArea
    plane {
      id
      title
    }
    aisle {
      id
      title
    }
    bay {
      id
      title
    }
  }
`;
export const AssignmentOccupancyLocationDetailsFragmentDoc = gql`
  fragment AssignmentOccupancyLocationDetails on AssignmentOccupancyLocationDetails {
    ...AssignmentOccupancyLocationStatus
    location {
      ...AssignmentOccupancyLocation
    }
  }
  ${AssignmentOccupancyLocationStatusFragmentDoc}
  ${AssignmentOccupancyLocationFragmentDoc}
`;
export const AssignmentOccupancyDataFragmentDoc = gql`
  fragment AssignmentOccupancyData on AssignmentOccupancyLocationsConnection {
    totalCount
    content {
      ...AssignmentOccupancyLocationDetails
    }
  }
  ${AssignmentOccupancyLocationDetailsFragmentDoc}
`;
export const AssignmentOccupancySummaryGroupFragmentDoc = gql`
  fragment AssignmentOccupancySummaryGroup on AssignmentOccupancySummaryGroup {
    totalStorageVolume
    totalOccupiedVolume
    totalUnknownVolume
    totalOccupancy
    maxOccupancy
    locationCount
    stockUomCapacitySummary {
      ...AssignmentOccupancyCapacitySummary
    }
    maxUomCapacitySummary {
      ...AssignmentOccupancyCapacitySummary
    }
    key {
      status
      planeId
      warehouseArea
      locationLevel
      locationRackingType
    }
  }
  ${AssignmentOccupancyCapacitySummaryFragmentDoc}
`;
export const FindLocationFilterValuesFilterResultFragmentDoc = gql`
  fragment FindLocationFilterValuesFilterResult on FindLocationFilterValuesFilterResult {
    filterType
    totalCount
    content {
      title
    }
  }
`;
export const FilterMatchFragmentDoc = gql`
  fragment FilterMatch on FilterMatch {
    total
    exclusive
  }
`;
export const CheckedAssignmentPolicyRuleFragmentDoc = gql`
  fragment CheckedAssignmentPolicyRule on CheckedAssignmentPolicyRule {
    id
    title
    productFilterMatch {
      ...FilterMatch
    }
    locationFilterMatch {
      ...FilterMatch
    }
  }
  ${FilterMatchFragmentDoc}
`;
export const CheckedAssignmentPolicyFragmentDoc = gql`
  fragment CheckedAssignmentPolicy on CheckedAssignmentPolicy {
    rules {
      ...CheckedAssignmentPolicyRule
    }
    fallbackLocationFilterMatch {
      ...FilterMatch
    }
    fallbackItemFilterMatch {
      ...FilterMatch
    }
    allProducts {
      ...FilterMatch
    }
    allLocations {
      ...FilterMatch
    }
  }
  ${CheckedAssignmentPolicyRuleFragmentDoc}
  ${FilterMatchFragmentDoc}
`;
export const CheckSimulationAssignmentPolicyResultFragmentDoc = gql`
  fragment CheckSimulationAssignmentPolicyResult on CheckSimulationAssignmentPolicyResult {
    policy {
      ...CheckedAssignmentPolicy
    }
  }
  ${CheckedAssignmentPolicyFragmentDoc}
`;
export const ProductIdFragmentDoc = gql`
  fragment ProductId on ProductId {
    consignee
    sku
  }
`;
export const SimulationItemBaseUomDataFragmentDoc = gql`
  fragment SimulationItemBaseUomData on BaseUom {
    uom
    netWeight
    length
    width
    height
    volume
    maxStack
  }
`;
export const SimulationItemDataFragmentDoc = gql`
  fragment SimulationItemData on SimulationItem {
    id
    consignee
    sku
    skuGroup
    subGroup
    transportClass
    stockCategory
    percentRank
    cumulativePercentRank
    baseUom {
      ...SimulationItemBaseUomData
    }
  }
  ${SimulationItemBaseUomDataFragmentDoc}
`;
export const AssignmentItemStatFragmentDoc = gql`
  fragment AssignmentItemStat on Item {
    id
    consignee
    sku
    description
    skuGroup
    subGroup
    pollutionClass
    transportClass
    stockCategory
    storageClass
    baseUom {
      uom
      netWeight
      volume
      length
      width
      height
      maxStack
    }
  }
`;
export const AssignmentLocationItemStatFragmentDoc = gql`
  fragment AssignmentLocationItemStat on AssignmentItemWithMatchingFilter {
    locationId
    stockUom
    stockUomQuantity
    item {
      ...AssignmentItemStat
    }
    location {
      locationWidth
      locationHeight
      locationLength
      locationRackingType
      locationUsageType
      locationOrder
      congestionZone
    }
  }
  ${AssignmentItemStatFragmentDoc}
`;
export const ExportJobFragmentDoc = gql`
  fragment ExportJob on ExportJob {
    id
    status
    downloadUrl
    fileSize
    filename
  }
`;
export const ImportJobPipelineSourceSettingsFragmentDoc = gql`
  fragment ImportJobPipelineSourceSettings on ImportJobPipelineSourceSettings {
    format
    compression
  }
`;
export const ImportJobPipelineTargetSettingsFragmentDoc = gql`
  fragment ImportJobPipelineTargetSettings on ImportJobPipelineTargetSettings {
    target
    newSettings {
      targetTitle
    }
    appendSettings {
      targetId
      overwrite
    }
  }
`;
export const MappingValueResolverSpecItemFragmentDoc = gql`
  fragment MappingValueResolverSpecItem on MappingValueResolverSpecItem {
    title
    value
  }
`;
export const MappingValueResolverSpecFragmentDoc = gql`
  fragment MappingValueResolverSpec on MappingValueResolverSpec {
    values {
      ...MappingValueResolverSpecItem
    }
  }
  ${MappingValueResolverSpecItemFragmentDoc}
`;
export const MappingFieldSpecFragmentDoc = gql`
  fragment MappingFieldSpec on MappingFieldSpec {
    name
    columnName
    index
    measureValue
    valueResolver {
      ...MappingValueResolverSpec
    }
  }
  ${MappingValueResolverSpecFragmentDoc}
`;
export const CsvImportOptionsFragmentDoc = gql`
  fragment CsvImportOptions on CsvImportOptions {
    separator
    quote
    escape
  }
`;
export const ImportJobTransformationSettingsFragmentDoc = gql`
  fragment ImportJobTransformationSettings on ImportJobTransformationSettings {
    enabled
    query
  }
`;
export const ImportJobMappingSettingsFragmentDoc = gql`
  fragment ImportJobMappingSettings on ImportJobMappingSettings {
    hasHeaders
    columnNames
    mapping {
      fields {
        ...MappingFieldSpec
      }
    }
    csvOptions {
      ...CsvImportOptions
    }
    transformation {
      ...ImportJobTransformationSettings
    }
  }
  ${MappingFieldSpecFragmentDoc}
  ${CsvImportOptionsFragmentDoc}
  ${ImportJobTransformationSettingsFragmentDoc}
`;
export const ImportPipelineSftpPullConnectorSettingsFragmentDoc = gql`
  fragment ImportPipelineSftpPullConnectorSettings on ImportPipelineSftpPullConnectorSettings {
    schedule {
      cronExpression
    }
    host
    port
    username
    password
    path
    pattern
    afterPull {
      action
    }
  }
`;
export const ImportPipelineDatasetChangedConnectorSettingsFragmentDoc = gql`
  fragment ImportPipelineDatasetChangedConnectorSettings on ImportPipelineDatasetChangedConnectorSettings {
    sources {
      id
    }
  }
`;
export const ImportPipelineConnectorSettingsFragmentDoc = gql`
  fragment ImportPipelineConnectorSettings on ImportPipelineConnectorSettings {
    id
    type
    enabled
    sftpPull {
      ...ImportPipelineSftpPullConnectorSettings
    }
    datasetChanged {
      ...ImportPipelineDatasetChangedConnectorSettings
    }
  }
  ${ImportPipelineSftpPullConnectorSettingsFragmentDoc}
  ${ImportPipelineDatasetChangedConnectorSettingsFragmentDoc}
`;
export const ImportPipelineSettingsFragmentDoc = gql`
  fragment ImportPipelineSettings on ImportPipelineSettings {
    sourceSettings {
      ...ImportJobPipelineSourceSettings
    }
    targetSettings {
      ...ImportJobPipelineTargetSettings
    }
    mappingSettings {
      ...ImportJobMappingSettings
    }
    integrationSettings {
      connectors {
        ...ImportPipelineConnectorSettings
      }
    }
  }
  ${ImportJobPipelineSourceSettingsFragmentDoc}
  ${ImportJobPipelineTargetSettingsFragmentDoc}
  ${ImportJobMappingSettingsFragmentDoc}
  ${ImportPipelineConnectorSettingsFragmentDoc}
`;
export const ImportPipelineDatasetChangedConnectorDetailsFragmentDoc = gql`
  fragment ImportPipelineDatasetChangedConnectorDetails on ImportPipelineDatasetChangedConnectorDetails {
    sources {
      id
      title
      objectType
    }
  }
`;
export const ImportPipelineFragmentDoc = gql`
  fragment ImportPipeline on ImportPipeline {
    id
    userId
    importType
    title
    description
    enabled
    createdAt
    updatedAt
    settings {
      ...ImportPipelineSettings
    }
    extraSettings
    jobs {
      totalCount
    }
    connectorsState {
      id
      s3Push {
        s3Url
        awsCredentials {
          accessKeyId
          secretAccessKey
        }
      }
      apiPush {
        apiUrl
        apiAccessKey
      }
      emailPush {
        address
      }
      datasetChanged {
        ...ImportPipelineDatasetChangedConnectorDetails
      }
    }
  }
  ${ImportPipelineSettingsFragmentDoc}
  ${ImportPipelineDatasetChangedConnectorDetailsFragmentDoc}
`;
export const ImportJobSourceSettingsFragmentDoc = gql`
  fragment ImportJobSourceSettings on ImportJobSourceSettings {
    source
    format
    filename
    compression
    externalSourceSettings {
      s3FolderUrl
    }
  }
`;
export const ImportJobBaseFragmentDoc = gql`
  fragment ImportJobBase on ImportJob {
    id
    status
    progress
    createdAt
    updatedAt
    startedAt
    completedAt
    userId
    importType
    uploaded
    stage
    targetTitle
    locale
    extraSettings
    targetId
    downloadUrl
    sanitizedFailureReason
    importPipelineId
    importPipeline {
      ...ImportPipeline
    }
    sourceSettings {
      ...ImportJobSourceSettings
    }
    settings {
      ...ImportJobMappingSettings
    }
    summary {
      inputLineCount
      importedLineCount
      errorCount
    }
  }
  ${ImportPipelineFragmentDoc}
  ${ImportJobSourceSettingsFragmentDoc}
  ${ImportJobMappingSettingsFragmentDoc}
`;
export const MappingErrorFragmentDoc = gql`
  fragment MappingError on MappingError {
    code
    type
    property
    message
    value
    valueResolver {
      values {
        value
        title
      }
    }
    line
    count
  }
`;
export const ImportJobFragmentDoc = gql`
  fragment ImportJob on ImportJob {
    ...ImportJobBase
    errors {
      totalCount
      content {
        ...MappingError
      }
    }
  }
  ${ImportJobBaseFragmentDoc}
  ${MappingErrorFragmentDoc}
`;
export const WarehouseMetaFragmentDoc = gql`
  fragment WarehouseMeta on Warehouse {
    id
    title
    description
    createdAt
    updatedAt
    companyName
    address
    measurementSystem
    country
    currency
    type
    permissions {
      allowed
    }
    extraSettings
  }
`;
export const ImportJobsConnectionFragmentDoc = gql`
  fragment ImportJobsConnection on ImportJobsConnection {
    totalCount
    content {
      ...ImportJobBase
    }
  }
  ${ImportJobBaseFragmentDoc}
`;
export const ImportPipelinesConnectionFragmentDoc = gql`
  fragment ImportPipelinesConnection on ImportPipelinesConnection {
    totalCount
    content {
      ...ImportPipeline
    }
  }
  ${ImportPipelineFragmentDoc}
`;
export const SftpTestResultFragmentDoc = gql`
  fragment SftpTestResult on TestImportPipelineSftpConnectorResult {
    totalFiles
    matchedFiles
  }
`;
export const ItemSetMetaFragmentDoc = gql`
  fragment ItemSetMeta on ItemSet {
    id
    title
    status
    description
    createdAt
    updatedAt
    inputFileName
    userTags
  }
`;
export const FilterValueFragmentDoc = gql`
  fragment FilterValue on FilterValue {
    title
  }
`;
export const NumberFilterRangeFragmentDoc = gql`
  fragment NumberFilterRange on NumberFilterRange {
    from
    to
  }
`;
export const StringFilterRangeFragmentDoc = gql`
  fragment StringFilterRange on StringFilterRange {
    from
    excludeFrom
    to
    excludeTo
  }
`;
export const ItemFilterFragmentDoc = gql`
  fragment ItemFilter on ItemFilter {
    type
    valueIn {
      ...FilterValue
    }
    range {
      ...NumberFilterRange
    }
    stringRange {
      ...StringFilterRange
    }
    isNot
    isNull
  }
  ${FilterValueFragmentDoc}
  ${NumberFilterRangeFragmentDoc}
  ${StringFilterRangeFragmentDoc}
`;
export const ItemFilterIntersectionFragmentDoc = gql`
  fragment ItemFilterIntersection on ItemFilterIntersection {
    id
    allOf {
      ...ItemFilter
    }
  }
  ${ItemFilterFragmentDoc}
`;
export const ItemFilterUnionFragmentDoc = gql`
  fragment ItemFilterUnion on ItemFilterUnion {
    anyOf {
      ...ItemFilterIntersection
    }
  }
  ${ItemFilterIntersectionFragmentDoc}
`;
export const StackingPolicyRuleSubcategoriesFragmentDoc = gql`
  fragment StackingPolicyRuleSubcategories on StackingPolicyRuleSubcategories {
    type
    step
    boundaries
  }
`;
export const StackingPolicyRuleFragmentDoc = gql`
  fragment StackingPolicyRule on StackingPolicyRule {
    id
    title
    itemsMatch {
      ...ItemFilterUnion
    }
    subcategories {
      ...StackingPolicyRuleSubcategories
    }
  }
  ${ItemFilterUnionFragmentDoc}
  ${StackingPolicyRuleSubcategoriesFragmentDoc}
`;
export const StackingPolicyFallbackRuleFragmentDoc = gql`
  fragment StackingPolicyFallbackRule on StackingPolicyFallbackRule {
    subcategories {
      ...StackingPolicyRuleSubcategories
    }
  }
  ${StackingPolicyRuleSubcategoriesFragmentDoc}
`;
export const StackingPolicyFragmentDoc = gql`
  fragment StackingPolicy on StackingPolicy {
    topRules {
      ...StackingPolicyRule
    }
    bottomRules {
      ...StackingPolicyRule
    }
    fallbackRule {
      ...StackingPolicyFallbackRule
    }
  }
  ${StackingPolicyRuleFragmentDoc}
  ${StackingPolicyFallbackRuleFragmentDoc}
`;
export const ItemSetSummaryFragmentDoc = gql`
  fragment ItemSetSummary on ItemSetSummary {
    consignees {
      consignee
      itemsCount
    }
    totalItemsCount
  }
`;
export const ItemSetFragmentDoc = gql`
  fragment ItemSet on ItemSet {
    ...ItemSetMeta
    stackingPolicy {
      ...StackingPolicy
    }
    simulationsWith(
      sortBy: [{ field: UPDATED_AT, direction: DESC }]
      page: null
    ) {
      ...SimulationsConnection
    }
    summary {
      ...ItemSetSummary
    }
  }
  ${ItemSetMetaFragmentDoc}
  ${StackingPolicyFragmentDoc}
  ${SimulationsConnectionFragmentDoc}
  ${ItemSetSummaryFragmentDoc}
`;
export const ItemFragmentDoc = gql`
  fragment Item on Item {
    id
    consignee
    sku
    skuGroup
    name
    description
    subGroup
    transportClass
    stockCategory
    storageClass
    pollutionClass
  }
`;
export const UomFragmentDoc = gql`
  fragment UOM on ItemUom {
    uom
    netWeight
    length
    width
    height
    volume
    unitsPerLowestUom
    lowerUom
    ean
    upc
    maxStack
  }
`;
export const ItemUomFragmentDoc = gql`
  fragment ItemUom on ItemUomOrItem {
    item {
      ...Item
    }
    uom {
      ...UOM
    }
  }
  ${ItemFragmentDoc}
  ${UomFragmentDoc}
`;
export const ItemSetDataFragmentDoc = gql`
  fragment ItemSetData on ItemUomOrItemsConnection {
    totalCount
    content {
      ...ItemUom
    }
  }
  ${ItemUomFragmentDoc}
`;
export const ItemDataFragmentDoc = gql`
  fragment ItemData on Item {
    id
    consignee
    sku
    skuGroup
    name
    description
    subGroup
    transportClass
    stockCategory
    storageClass
    baseUom {
      uom
      netWeight
      length
      width
      height
      volume
      maxStack
      ean
      upc
    }
  }
`;
export const ItemStackingDetailsFragmentDoc = gql`
  fragment ItemStackingDetails on ItemStackingDetails {
    item {
      ...ItemData
    }
    stackingPolicyRuleId
  }
  ${ItemDataFragmentDoc}
`;
export const StackingTotalsFragmentDoc = gql`
  fragment StackingTotals on ApplyStackingPolicyResult {
    totalTopCount
    totalBottomCount
    totalCount
  }
`;
export const LayoutImportMetaFragmentDoc = gql`
  fragment LayoutImportMeta on LayoutImport {
    id
    title
    description
    createdAt
    updatedAt
  }
`;
export const LayoutImportConverterLocationFragmentDoc = gql`
  fragment LayoutImportConverterLocation on LayoutImportLocation {
    locationKey
    locationId
    locationOrder
    locationStatus
    locationLevel
    locationLength
    locationWidth
    locationHeight
    locationWeight
    locationAisle
    locationBayId
    locationBay
    locationBayPosition
    locationDepthPosition
    locationUsageType
    locmhtype
    locationRackingType
    bayType
    warehouseArea
    baySide
    portalSpecs {
      aisleId
      type
    }
  }
`;
export const LayoutImportConverterBayFragmentDoc = gql`
  fragment LayoutImportConverterBay on LayoutImportBay {
    bayId
    bay
    bayType
    depth
    locations {
      ...LayoutImportConverterLocation
    }
    locationCount
    levels {
      level
      locationCount
      usedWidth
      height
    }
  }
  ${LayoutImportConverterLocationFragmentDoc}
`;
export const LayoutImportConverterSideFragmentDoc = gql`
  fragment LayoutImportConverterSide on LayoutImportSide {
    side
    bays {
      ...LayoutImportConverterBay
    }
  }
  ${LayoutImportConverterBayFragmentDoc}
`;
export const LayoutImportConverterAisleFragmentDoc = gql`
  fragment LayoutImportConverterAisle on LayoutImportAisle {
    aisleId
    aisle
    sides {
      ...LayoutImportConverterSide
    }
  }
  ${LayoutImportConverterSideFragmentDoc}
`;
export const LayoutImportConverterAreaFragmentDoc = gql`
  fragment LayoutImportConverterArea on LayoutImportArea {
    area
    locationCount
    aisles {
      ...LayoutImportConverterAisle
    }
  }
  ${LayoutImportConverterAisleFragmentDoc}
`;
export const LayoutImportAreaSummaryFragmentDoc = gql`
  fragment LayoutImportAreaSummary on LayoutImportAreaSummary {
    areaId
    title
    locationCount
  }
`;
export const LayoutImportSummaryFragmentDoc = gql`
  fragment LayoutImportSummary on LayoutImportSummary {
    locationCount
    disabledLocationCount
    aisleCount
    bayCount
    areas {
      ...LayoutImportAreaSummary
    }
  }
  ${LayoutImportAreaSummaryFragmentDoc}
`;
export const LayoutImportConverterDataFragmentDoc = gql`
  fragment LayoutImportConverterData on LayoutImport {
    id
    title
    description
    status
    warehouse {
      title
    }
    locations {
      totalCount
    }
    settings
    createdAt
    updatedAt
    inputFileName
    converterSummary: summary {
      ...LayoutImportSummary
    }
  }
  ${LayoutImportSummaryFragmentDoc}
`;
export const LayoutImportLocationSummaryFragmentDoc = gql`
  fragment LayoutImportLocationSummary on LayoutImportLocation {
    locationId
    locationLevel
    locationWidth
    locationLength
    locationHeight
    locationRackingType
    locationBayPosition
    locationDepthPosition
  }
`;
export const LoadBalancingRuleSummaryBaseFragmentDoc = gql`
  fragment LoadBalancingRuleSummaryBase on LoadBalancingRuleSummary {
    totalLoad
    meanLoad
    variance
    stdDev
    maxRelativeDeviation
    constraintSatisfied
    locationCount
  }
`;
export const LoadBalancingZoneSummaryFragmentDoc = gql`
  fragment LoadBalancingZoneSummary on LoadBalancingZoneSummary {
    zoneId
    zoneLoad
    locationCount
  }
`;
export const LoadBalancingRuleSummaryFragmentDoc = gql`
  fragment LoadBalancingRuleSummary on LoadBalancingRuleSummary {
    ...LoadBalancingRuleSummaryBase
    zones {
      ...LoadBalancingZoneSummary
    }
  }
  ${LoadBalancingRuleSummaryBaseFragmentDoc}
  ${LoadBalancingZoneSummaryFragmentDoc}
`;
export const LoadBalancingZoneSummaryWithLocationsFragmentDoc = gql`
  fragment LoadBalancingZoneSummaryWithLocations on LoadBalancingZoneSummary {
    ...LoadBalancingZoneSummary
    locationIds
  }
  ${LoadBalancingZoneSummaryFragmentDoc}
`;
export const LoadBalancingRuleSummaryWithLocationsFragmentDoc = gql`
  fragment LoadBalancingRuleSummaryWithLocations on LoadBalancingRuleSummary {
    ...LoadBalancingRuleSummaryBase
    zones {
      ...LoadBalancingZoneSummaryWithLocations
    }
  }
  ${LoadBalancingRuleSummaryBaseFragmentDoc}
  ${LoadBalancingZoneSummaryWithLocationsFragmentDoc}
`;
export const OptimizationRunStatusFragmentDoc = gql`
  fragment OptimizationRunStatus on OptimizationRun {
    id
    progress
    steps
    status
  }
`;
export const AssignmentChangeItemMoveDetailsFragmentDoc = gql`
  fragment AssignmentChangeItemMoveDetails on AssignmentChangeSequenceMove {
    moveId
    groupId
    chainNumber
    sequenceNumber
    itemId
    consignee
    sku
    srcLocationId
    destLocationId
    destAction
  }
`;
export const OptimizationImprovementSummaryFragmentDoc = gql`
  fragment OptimizationImprovementSummary on OptimizationImprovementSummary {
    effectiveImprovement
    initialEffectiveCost
    optimizedEffectiveCost
    objectives {
      id
      type
      initialCost
      objectiveWeight
      improvement
    }
  }
`;
export const OptimizationRunBaseWithoutAnalyzeFragmentDoc = gql`
  fragment OptimizationRunBaseWithoutAnalyze on OptimizationRun {
    id
    progress
    steps
    seed
    optimizationSummary {
      stopReason
      effectiveSteps
      totalComputeTime
      moveCount
      improvement {
        ...OptimizationImprovementSummary
      }
    }
    status
    startedAt
    completedAt
    progress
    elapsedRunningTime
    initialCost
    reassignedCost
    optimizedCost
    reassignImprovement
    optimizeImprovement
    improvement
    resultAssignment {
      id
    }
    optimizationSettings {
      source {
        type
        id
      }
    }
  }
  ${OptimizationImprovementSummaryFragmentDoc}
`;
export const AnalyzeResultProcessCostFragmentDoc = gql`
  fragment AnalyzeResultProcessCost on AnalyzeResultProcessCost {
    processType
    distance
    duration
    cost
  }
`;
export const AnalyzeResultEventTypeSummaryFragmentDoc = gql`
  fragment AnalyzeResultEventTypeSummary on AnalyzeResultEventTypeSummary {
    eventType
    processType
    count
    totalDuration
    totalCost
  }
`;
export const AnalyzeResultAgentSummaryFragmentDoc = gql`
  fragment AnalyzeResultAgentSummary on AnalyzeResultAgentSummary {
    agent
    eventTypes {
      ...AnalyzeResultEventTypeSummary
    }
  }
  ${AnalyzeResultEventTypeSummaryFragmentDoc}
`;
export const AnalyzeResultSummaryFragmentDoc = gql`
  fragment AnalyzeResultSummary on AnalyzeResult {
    id
    status
    stage
    progress
    totalOrders
    totalAssembledOrders
    totalAssembledPartiallyOrders
    totalLines
    assembledLines
    assembledPartiallyLines
    ignoredLines
    totalDistance
    totalCost
    totalDuration
    costDetails {
      processes {
        ...AnalyzeResultProcessCost
      }
    }
    summary {
      eventTypes {
        ...AnalyzeResultEventTypeSummary
      }
      agents {
        ...AnalyzeResultAgentSummary
      }
      assignment {
        totalStoredItems
      }
    }
    resourcePolicy {
      agents {
        id
        specs {
          powerSource {
            energyConsumptionPerSecond
          }
        }
      }
    }
  }
  ${AnalyzeResultProcessCostFragmentDoc}
  ${AnalyzeResultEventTypeSummaryFragmentDoc}
  ${AnalyzeResultAgentSummaryFragmentDoc}
`;
export const AnalyzeResultReorderSummaryFragmentDoc = gql`
  fragment AnalyzeResultReorderSummary on AnalyzeResultReorderSummary {
    count
    totalDuration
    totalCost
    totalReorderedWeight
  }
`;
export const AnalyzeResultFragmentDoc = gql`
  fragment AnalyzeResult on AnalyzeResult {
    ...AnalyzeResultSummary
    picklistPickByDates
    maxLocationHitCount(processType: [PICKING, REPLENISHMENT])
    maxReorderTriggeredSummary: maxReorderSummary(
      summaryType: TRIGGERED_REORDER
    ) {
      ...AnalyzeResultReorderSummary
    }
    maxReorderAppliedSummary: maxReorderSummary(summaryType: APPLIED_REORDER) {
      ...AnalyzeResultReorderSummary
    }
  }
  ${AnalyzeResultSummaryFragmentDoc}
  ${AnalyzeResultReorderSummaryFragmentDoc}
`;
export const OptimizationRunBaseFragmentDoc = gql`
  fragment OptimizationRunBase on OptimizationRun {
    ...OptimizationRunBaseWithoutAnalyze
    analyzeResult {
      ...AnalyzeResult
    }
  }
  ${OptimizationRunBaseWithoutAnalyzeFragmentDoc}
  ${AnalyzeResultFragmentDoc}
`;
export const OptimizationRunProgressFragmentDoc = gql`
  fragment OptimizationRunProgress on OptimizationRunProgress {
    step
    startedAt
    completedAt
    progress
    currentCost
    elapsedComputeTime
    remainingComputeTime
    currentMoveCount
    currentOptimizeImprovement
    currentTotalImprovement
    details {
      improvement {
        ...OptimizationImprovementSummary
      }
    }
  }
  ${OptimizationImprovementSummaryFragmentDoc}
`;
export const OptimizationRunWithLastStepFragmentDoc = gql`
  fragment OptimizationRunWithLastStep on OptimizationRun {
    ...OptimizationRunBase
    latestProgressDetails {
      ...OptimizationRunProgress
    }
  }
  ${OptimizationRunBaseFragmentDoc}
  ${OptimizationRunProgressFragmentDoc}
`;
export const OptimizationRunFragmentDoc = gql`
  fragment OptimizationRun on OptimizationRun {
    ...OptimizationRunBase
    progressDetails {
      ...OptimizationRunProgress
    }
  }
  ${OptimizationRunBaseFragmentDoc}
  ${OptimizationRunProgressFragmentDoc}
`;
export const AssignmentDiffItemFragmentDoc = gql`
  fragment AssignmentDiffItem on AssignmentDiffItem {
    product {
      consignee
      sku
    }
    srcLocations {
      locationId
    }
    destLocations {
      locationId
    }
    productDetails {
      orderLineCount
      cumulativePercentRank
    }
  }
`;
export const LayoutLevelLocationFragmentDoc = gql`
  fragment LayoutLevelLocation on LayoutLocation {
    locationId
    locationStatus
    locationBayId
    locationOrder
    shape
    portals {
      position {
        x
        y
      }
      type
    }
  }
`;
export const PointFragmentDoc = gql`
  fragment Point on Point {
    x
    y
  }
`;
export const LayoutBayLocationFragmentDoc = gql`
  fragment LayoutBayLocation on LayoutLocation {
    ...LayoutLevelLocation
    locationRackingType
    locationBayPosition
    locationLevel
    locationWidth
    locationLength
    locationHeight
    warehouseArea
    locationHeightFromFloor
    locationDepthPosition
    locationDepthFromFront
    locationIndexFromFront
    locationUsageType
    congestionZone
    locationSide
    portals {
      type
      aisleId
      position {
        ...Point
      }
    }
    plane {
      id
      title
    }
    bay {
      id
      title
    }
    aisle {
      id
      title
    }
  }
  ${LayoutLevelLocationFragmentDoc}
  ${PointFragmentDoc}
`;
export const AssignmentDiffItemFullFragmentDoc = gql`
  fragment AssignmentDiffItemFull on AssignmentDiffItem {
    product {
      consignee
      sku
    }
    srcLocations {
      locationId
      location {
        ...LayoutBayLocation
      }
    }
    destLocations {
      locationId
      location {
        ...LayoutBayLocation
      }
    }
    productDetails {
      orderLineCount
      cumulativePercentRank
    }
  }
  ${LayoutBayLocationFragmentDoc}
`;
export const ItemQuantityDetailsFragmentDoc = gql`
  fragment ItemQuantityDetails on ItemQuantityDetails {
    breakdownByUom {
      quantity
      uom
      uomQuantity
    }
    quantityPerUom {
      quantity
      uom
      uomQuantity
    }
  }
`;
export const OrderedQuantityItemFragmentDoc = gql`
  fragment OrderedQuantityItem on OrderedQuantityReportItem {
    itemId
    consignee
    sku
    status
    orderedQuantity
    assignedCapacity
    stockQuantity
    stockQuantityGap
    assignedCapacityGap
    details {
      orderedQuantityDetails {
        ...ItemQuantityDetails
      }
      stockQuantityGapDetails {
        ...ItemQuantityDetails
      }
      assignedCapacityGapDetails {
        ...ItemQuantityDetails
      }
    }
  }
  ${ItemQuantityDetailsFragmentDoc}
`;
export const OrderedQuantityItemsFragmentDoc = gql`
  fragment OrderedQuantityItems on OrderedQuantityReportItemsConnection {
    totalCount
    content {
      ...OrderedQuantityItem
    }
  }
  ${OrderedQuantityItemFragmentDoc}
`;
export const OrderHeaderDataFragmentDoc = gql`
  fragment OrderHeaderData on OrderHeader {
    id
    consignee
    customer
    orderId
    orderDate
    deliveryRoute
    dock
    stagingArea
    waveId
    shipmentId
    loadByDate
  }
`;
export const OrderSetItemFragmentDoc = gql`
  fragment OrderSetItem on OrderSetItem {
    cumulativePercentRank
    details {
      dailyStats {
        totalDaysWithOrders
        orderLineCount {
          max
          avg
          cv
        }
      }
      weeklyStats {
        totalWeeksWithOrders
        daysWithOrders {
          max
          avg
          cv
        }
        orderLineCount {
          max
          avg
          cv
        }
      }
    }
  }
`;
export const OrderLineDataFragmentDoc = gql`
  fragment OrderLineData on OrderLine {
    orderHeader {
      ...OrderHeaderData
    }
    orderId
    orderLine
    sku
    quantity
    pickingPriority
    pickingContainer
    itemDetails {
      ...OrderSetItem
    }
  }
  ${OrderHeaderDataFragmentDoc}
  ${OrderSetItemFragmentDoc}
`;
export const OrderSetMetaFragmentDoc = gql`
  fragment OrderSetMeta on OrderSet {
    id
    title
    status
    description
    createdAt
    updatedAt
    inputFileName
    userTags
  }
`;
export const OrderSetConsigneeSummaryFragmentDoc = gql`
  fragment OrderSetConsigneeSummary on OrderSetConsigneeSummary {
    consignee
    orderCount
    orderLineCount
    productCount
  }
`;
export const OrderSetSummaryFragmentDoc = gql`
  fragment OrderSetSummary on OrderSetSummary {
    orderCount
    productCount
    consignees {
      ...OrderSetConsigneeSummary
    }
    dateRange {
      from
      to
    }
  }
  ${OrderSetConsigneeSummaryFragmentDoc}
`;
export const OrderSetFragmentDoc = gql`
  fragment OrderSet on OrderSet {
    ...OrderSetMeta
    status
    summary {
      ...OrderSetSummary
    }
    simulationsWith(
      sortBy: [{ field: UPDATED_AT, direction: DESC }]
      page: null
    ) {
      ...SimulationsConnection
    }
  }
  ${OrderSetMetaFragmentDoc}
  ${OrderSetSummaryFragmentDoc}
  ${SimulationsConnectionFragmentDoc}
`;
export const BaseUomFragmentDoc = gql`
  fragment BaseUOM on BaseUom {
    uom
    width
    height
    length
    netWeight
    volume
    maxStack
  }
`;
export const SimulationOrderLineFragmentDoc = gql`
  fragment SimulationOrderLine on SimulationOrderLine {
    orderLineId
    consignee
    orderId
    orderDate
    customer
    deliveryRoute
    dock
    stagingArea
    waveId
    orderLineCount
    orderTotalVolume
    orderTotalWeight
    orderLine
    sku
    orderLineQuantity
    pickingPriority
    pickingContainer
    orderLineVolume
    orderLineWeight
    itemId
    skuGroup
    subGroup
    transportClass
    stockCategory
    storageClass
    pollutionClass
    itemPercentRank
    itemCumulativePercentRank
    shipmentId
    loadByDate
    baseUom {
      ...BaseUOM
    }
  }
  ${BaseUomFragmentDoc}
`;
export const SimulationOrderLinesConnectionFragmentDoc = gql`
  fragment SimulationOrderLinesConnection on SimulationOrderLinesConnection {
    totalCount
    content {
      ...SimulationOrderLine
    }
  }
  ${SimulationOrderLineFragmentDoc}
`;
export const CheckedPickingPolicyOrderLineRuleFragmentDoc = gql`
  fragment CheckedPickingPolicyOrderLineRule on CheckedPickingPolicyOrderLineRule {
    id
    orderLineFilterMatch {
      count
    }
    pickingRules {
      id
      locationFilterMatch {
        count
      }
    }
  }
`;
export const CheckSimulationPickingPolicyResultFragmentDoc = gql`
  fragment CheckSimulationPickingPolicyResult on CheckSimulationPickingPolicyResult {
    policy {
      orderLineRules {
        ...CheckedPickingPolicyOrderLineRule
      }
      unmatchedOrderLines {
        count
      }
    }
  }
  ${CheckedPickingPolicyOrderLineRuleFragmentDoc}
`;
export const FilterValuesConnectionFragmentDoc = gql`
  fragment FilterValuesConnection on FilterValuesConnection {
    content {
      ...FilterValue
    }
    totalCount
    range {
      ...NumberFilterRange
    }
  }
  ${FilterValueFragmentDoc}
  ${NumberFilterRangeFragmentDoc}
`;
export const InaccessibleLayoutLocationFragmentDoc = gql`
  fragment InaccessibleLayoutLocation on InaccessibleLayoutLocation {
    locationId
    reason
  }
`;
export const LayoutPlaneRouteFragmentDoc = gql`
  fragment LayoutPlaneRoute on LayoutPlaneRoute {
    planeId
    distance
    unreachableLocations
    unreachableTerminals {
      id
      type
      position {
        x
        y
      }
    }
    waypoints {
      id
      type
      position {
        x
        y
      }
    }
  }
`;
export const LayoutRouteFragmentDoc = gql`
  fragment LayoutRoute on LayoutRoute {
    routes {
      ...LayoutPlaneRoute
    }
  }
  ${LayoutPlaneRouteFragmentDoc}
`;
export const OrderSetItemsCategoryFragmentDoc = gql`
  fragment OrderSetItemsCategory on OrderSetItemsCategory {
    skip
    count
    limit
    minPercentRank
    maxPercentRank
    minCumulativePercentRank
    maxCumulativePercentRank
    totalOrderLineCount
  }
`;
export const SimulationItemsCategoryFragmentDoc = gql`
  fragment SimulationItemsCategory on SimulationItemsCategory {
    skip
    count
    limit
    minBoundary
    maxBoundary
    minPercentRank
    maxPercentRank
    minCumulativePercentRank
    maxCumulativePercentRank
    totalOrderLineCount
  }
`;
export const SimulationAbcFragmentDoc = gql`
  fragment SimulationABC on Simulation {
    orderSet {
      itemsByCategory(
        categories: {
          categoryWidths: $categoryWidths
          categoryType: CUMULATIVE_PERCENT_RANK
        }
      ) {
        totalCount
        totalOrderLineCount
        categories {
          ...OrderSetItemsCategory
        }
      }
    }
    effectiveItemSet {
      itemsByCategory(
        categories: {
          categoryWidths: $categoryWidths
          categoryType: CUMULATIVE_PERCENT_RANK
        }
      ) {
        totalCount
        totalOrderLineCount
        categories {
          ...SimulationItemsCategory
        }
      }
    }
  }
  ${OrderSetItemsCategoryFragmentDoc}
  ${SimulationItemsCategoryFragmentDoc}
`;
export const SimulationItemMetaFragmentDoc = gql`
  fragment SimulationItemMeta on SimulationItem {
    id
    percentRank
    cumulativePercentRank
  }
`;
export const SizeComplianceSummaryItemFragmentDoc = gql`
  fragment SizeComplianceSummaryItem on SizeComplianceSummaryItem {
    totalCount
    compliantCount
    nonCompliantCount
    unassignedCount
    unknownCount
    planeId
    level
  }
`;
export const SizeComplianceMetaFragmentDoc = gql`
  fragment SizeComplianceMeta on SizeCompliance {
    id
    status
    summary {
      items {
        ...SizeComplianceSummaryItem
      }
    }
  }
  ${SizeComplianceSummaryItemFragmentDoc}
`;
export const LocationWeightComplianceSummaryItemFragmentDoc = gql`
  fragment LocationWeightComplianceSummaryItem on LocationWeightComplianceSummaryItem {
    totalCount
    compliantCount
    nonCompliantCount
    unassignedCount
    unknownCount
    floorLevelCount
    planeId
    level
  }
`;
export const BayWeightComplianceSummaryItemFragmentDoc = gql`
  fragment BayWeightComplianceSummaryItem on BayWeightComplianceSummaryItem {
    totalCount
    compliantCount
    nonCompliantCount
    unassignedCount
    unknownCount
    floorLevelCount
    planeId
  }
`;
export const WeightComplianceMetaFragmentDoc = gql`
  fragment WeightComplianceMeta on WeightCompliance {
    id
    status
    locationSummary {
      items {
        ...LocationWeightComplianceSummaryItem
      }
    }
    baySummary {
      items {
        ...BayWeightComplianceSummaryItem
      }
    }
  }
  ${LocationWeightComplianceSummaryItemFragmentDoc}
  ${BayWeightComplianceSummaryItemFragmentDoc}
`;
export const SimulationOrderLineSetFragmentDoc = gql`
  fragment SimulationOrderLineSet on SimulationOrderLineSet {
    id
    status
    progress
    createdAt
  }
`;
export const SimulationItemSetFragmentDoc = gql`
  fragment SimulationItemSet on SimulationItemSet {
    id
    status
    createdAt
  }
`;
export const LayoutIssueSetFragmentDoc = gql`
  fragment LayoutIssueSet on LayoutIssueSet {
    status
    progress
    summary {
      totalLocations
      totalInvalidLocations
      invalidLocations {
        planeId
        locationLevel
        reason
        count
      }
    }
  }
`;
export const AssignmentIssueSetFragmentDoc = gql`
  fragment AssignmentIssueSet on AssignmentIssueSet {
    status
    progress
    summary {
      totalLocations
      totalAssignedLocations
      totalAssignmentItems
      totalInvalidLocations
      invalidLocations {
        planeId
        locationLevel
        reason
        count
      }
    }
  }
`;
export const ItemSetIssueSetFragmentDoc = gql`
  fragment ItemSetIssueSet on ItemSetIssueSet {
    status
    progress
    summary {
      totalItems
      totalUoms
      totalInvalidItems
      totalInvalidUoms
      totalAssignedItems
      invalidItems {
        reason
        count
      }
      invalidUoms {
        reason
        count
      }
      unknownAssignedItems
    }
  }
`;
export const OrderSetIssueSetFragmentDoc = gql`
  fragment OrderSetIssueSet on OrderSetIssueSet {
    status
    progress
    summary {
      totalLocations
      totalOrderLines
      totalUnpickableLines
      totalItems
      unpickableLines {
        reason
        count
      }
      unpickableItems {
        reason
        count
      }
    }
  }
`;
export const AllocationRequirementSetMetaFragmentDoc = gql`
  fragment AllocationRequirementSetMeta on AllocationRequirementSet {
    id
    status
    progress
  }
`;
export const SimulationMetaStatusFragmentDoc = gql`
  fragment SimulationMetaStatus on Simulation {
    id
    userTags
    sizeCompliance {
      ...SizeComplianceMeta
    }
    weightCompliance {
      ...WeightComplianceMeta
    }
    orderLineSet {
      ...SimulationOrderLineSet
    }
    effectiveItemSet {
      ...SimulationItemSet
    }
    layoutIssues {
      ...LayoutIssueSet
    }
    assignmentIssues {
      ...AssignmentIssueSet
    }
    itemSetIssues {
      ...ItemSetIssueSet
    }
    orderSetIssues {
      ...OrderSetIssueSet
    }
    allocationRequirementSet {
      ...AllocationRequirementSetMeta
    }
    extraSettings
  }
  ${SizeComplianceMetaFragmentDoc}
  ${WeightComplianceMetaFragmentDoc}
  ${SimulationOrderLineSetFragmentDoc}
  ${SimulationItemSetFragmentDoc}
  ${LayoutIssueSetFragmentDoc}
  ${AssignmentIssueSetFragmentDoc}
  ${ItemSetIssueSetFragmentDoc}
  ${OrderSetIssueSetFragmentDoc}
  ${AllocationRequirementSetMetaFragmentDoc}
`;
export const OrderedQuantityReportSummaryFragmentDoc = gql`
  fragment OrderedQuantityReportSummary on OrderedQuantityReport {
    id
    status
    progress
    summary {
      enoughCount
      notEnoughCount
      notOrderedCount
    }
  }
`;
export const RoutingPolicyDirectionThresholdFragmentDoc = gql`
  fragment RoutingPolicyDirectionThreshold on RoutingPolicyDirectionThreshold {
    angle
    distance
  }
`;
export const RoutingPolicyFeatureRuleFragmentDoc = gql`
  fragment RoutingPolicyFeatureRule on RoutingPolicyFeatureRule {
    featureId
    direction {
      x
      y
    }
    threshold {
      ...RoutingPolicyDirectionThreshold
    }
  }
  ${RoutingPolicyDirectionThresholdFragmentDoc}
`;
export const RoutingPolicyAgentRuleFragmentDoc = gql`
  fragment RoutingPolicyAgentRule on RoutingPolicyAgentRule {
    id
    title
    agentIds
    featureRules {
      ...RoutingPolicyFeatureRule
    }
  }
  ${RoutingPolicyFeatureRuleFragmentDoc}
`;
export const RoutingPolicyRuleFragmentDoc = gql`
  fragment RoutingPolicyRule on RoutingPolicyRule {
    featureRules {
      ...RoutingPolicyFeatureRule
    }
  }
  ${RoutingPolicyFeatureRuleFragmentDoc}
`;
export const RoutingPolicyFragmentDoc = gql`
  fragment RoutingPolicy on RoutingPolicy {
    rules {
      ...RoutingPolicyAgentRule
    }
    defaultRule {
      ...RoutingPolicyRule
    }
    threshold {
      angle
      distance
    }
  }
  ${RoutingPolicyAgentRuleFragmentDoc}
  ${RoutingPolicyRuleFragmentDoc}
`;
export const SimulationItemFilterFragmentDoc = gql`
  fragment SimulationItemFilter on SimulationItemFilter {
    type
    valueIn {
      ...FilterValue
    }
    range {
      ...NumberFilterRange
    }
    stringRange {
      ...StringFilterRange
    }
    isNull
    isNot
  }
  ${FilterValueFragmentDoc}
  ${NumberFilterRangeFragmentDoc}
  ${StringFilterRangeFragmentDoc}
`;
export const SimulationItemFilterIntersectionFragmentDoc = gql`
  fragment SimulationItemFilterIntersection on SimulationItemFilterIntersection {
    id
    allOf {
      ...SimulationItemFilter
    }
  }
  ${SimulationItemFilterFragmentDoc}
`;
export const SimulationItemFilterUnionFragmentDoc = gql`
  fragment SimulationItemFilterUnion on SimulationItemFilterUnion {
    anyOf {
      ...SimulationItemFilterIntersection
    }
  }
  ${SimulationItemFilterIntersectionFragmentDoc}
`;
export const LocationFilterFragmentDoc = gql`
  fragment LocationFilter on LocationFilter {
    type
    valueIn {
      ...FilterValue
    }
    range {
      ...NumberFilterRange
    }
    stringRange {
      ...StringFilterRange
    }
    isNull
    isNot
  }
  ${FilterValueFragmentDoc}
  ${NumberFilterRangeFragmentDoc}
  ${StringFilterRangeFragmentDoc}
`;
export const LocationFilterIntersectionFragmentDoc = gql`
  fragment LocationFilterIntersection on LocationFilterIntersection {
    id
    allOf {
      ...LocationFilter
    }
  }
  ${LocationFilterFragmentDoc}
`;
export const LocationFilterUnionFragmentDoc = gql`
  fragment LocationFilterUnion on LocationFilterUnion {
    anyOf {
      ...LocationFilterIntersection
    }
  }
  ${LocationFilterIntersectionFragmentDoc}
`;
export const AssignmentPolicyRuleFragmentDoc = gql`
  fragment AssignmentPolicyRule on AssignmentPolicyRule {
    id
    title
    type
    productsMatch {
      ...SimulationItemFilterUnion
    }
    locationsMatch {
      ...LocationFilterUnion
    }
  }
  ${SimulationItemFilterUnionFragmentDoc}
  ${LocationFilterUnionFragmentDoc}
`;
export const AssignmentPolicyFragmentDoc = gql`
  fragment AssignmentPolicy on AssignmentPolicy {
    rules {
      ...AssignmentPolicyRule
    }
    fallbackLocationsMatch {
      ...LocationFilterUnion
    }
    fallbackLocationsAllowAnyItems
    fallbackItemsAllowAnyLocations
  }
  ${AssignmentPolicyRuleFragmentDoc}
  ${LocationFilterUnionFragmentDoc}
`;
export const LocationSharingPolicyRuleFragmentDoc = gql`
  fragment LocationSharingPolicyRule on LocationSharingPolicyRule {
    id
    title
    maxItemsPerLocation
    locationsMatch {
      ...LocationFilterUnion
    }
  }
  ${LocationFilterUnionFragmentDoc}
`;
export const LocationSharingPolicyFragmentDoc = gql`
  fragment LocationSharingPolicy on LocationSharingPolicy {
    defaultMaxItemsPerLocation
    rules {
      ...LocationSharingPolicyRule
    }
  }
  ${LocationSharingPolicyRuleFragmentDoc}
`;
export const PicklistProcessingEventSettingsFragmentDoc = gql`
  fragment PicklistProcessingEventSettings on PicklistProcessingEventSettings {
    timeFixed
    timePerOrder
    timePerPicklistLine
    timePerUom
  }
`;
export const PicklistLineProcessingEventSettingsFragmentDoc = gql`
  fragment PicklistLineProcessingEventSettings on PicklistLineProcessingEventSettings {
    timeFixed
    timePerUom
  }
`;
export const UomHandlingSettingsFragmentDoc = gql`
  fragment UomHandlingSettings on UomHandlingSettings {
    uom
    timePerUom
  }
`;
export const HandlingEventSettingsFragmentDoc = gql`
  fragment HandlingEventSettings on HandlingEventSettings {
    timeFixed
    timePerWeight
    timePerVolume
    timePerUom {
      ...UomHandlingSettings
    }
  }
  ${UomHandlingSettingsFragmentDoc}
`;
export const ReorderEventSettingsFragmentDoc = gql`
  fragment ReorderEventSettings on ReorderEventSettings {
    timeFixed
    timePerReorderedPosition
    timePerWeight
    timePerVolume
    timePerUom {
      ...UomHandlingSettings
    }
  }
  ${UomHandlingSettingsFragmentDoc}
`;
export const AgentRoleSettingsFragmentDoc = gql`
  fragment AgentRoleSettings on AgentRoleSettings {
    roleId
    disabled
    preHandling {
      ...PicklistProcessingEventSettings
    }
    handlingPreparation {
      ...PicklistLineProcessingEventSettings
    }
    handling {
      ...HandlingEventSettings
    }
    reordering {
      ...ReorderEventSettings
    }
    handlingCompletion {
      ...PicklistLineProcessingEventSettings
    }
    postHandling {
      ...PicklistProcessingEventSettings
    }
  }
  ${PicklistProcessingEventSettingsFragmentDoc}
  ${PicklistLineProcessingEventSettingsFragmentDoc}
  ${HandlingEventSettingsFragmentDoc}
  ${ReorderEventSettingsFragmentDoc}
`;
export const SpeedSettingsFragmentDoc = gql`
  fragment SpeedSettings on SpeedSettings {
    speedLaden
    speedUnladen
  }
`;
export const HorizontalTravellingEventSettingsFragmentDoc = gql`
  fragment HorizontalTravellingEventSettings on HorizontalTravellingEventSettings {
    speed {
      ...SpeedSettings
    }
  }
  ${SpeedSettingsFragmentDoc}
`;
export const VerticalTravellingEventSettingsFragmentDoc = gql`
  fragment VerticalTravellingEventSettings on VerticalTravellingEventSettings {
    speedUp {
      ...SpeedSettings
    }
    speedDown {
      ...SpeedSettings
    }
  }
  ${SpeedSettingsFragmentDoc}
`;
export const AgentCapacityUomSettingsFragmentDoc = gql`
  fragment AgentCapacityUomSettings on AgentCapacityUomSettings {
    uom
    quantity
  }
`;
export const AgentSpecsFragmentDoc = gql`
  fragment AgentSpecs on AgentSpecs {
    horizontalTravelling {
      ...HorizontalTravellingEventSettings
    }
    verticalTravelling {
      ...VerticalTravellingEventSettings
    }
    capacity {
      maxVolume
      maxWeight
      maxUomQuantity {
        ...AgentCapacityUomSettings
      }
    }
    powerSource {
      energyConsumptionPerSecond
    }
  }
  ${HorizontalTravellingEventSettingsFragmentDoc}
  ${VerticalTravellingEventSettingsFragmentDoc}
  ${AgentCapacityUomSettingsFragmentDoc}
`;
export const AgentCostSettingsFragmentDoc = gql`
  fragment AgentCostSettings on AgentCostSettings {
    costPerSecond
  }
`;
export const AgentUtilisationSettingsFragmentDoc = gql`
  fragment AgentUtilisationSettings on AgentUtilisationSettings {
    agentAmount
    secondsOperation
  }
`;
export const AgentSettingsFragmentDoc = gql`
  fragment AgentSettings on AgentSettings {
    id
    title
    resourceTypeId
    metadata
    roles {
      ...AgentRoleSettings
    }
    specs {
      ...AgentSpecs
    }
    cost {
      ...AgentCostSettings
    }
    utilisation {
      ...AgentUtilisationSettings
    }
  }
  ${AgentRoleSettingsFragmentDoc}
  ${AgentSpecsFragmentDoc}
  ${AgentCostSettingsFragmentDoc}
  ${AgentUtilisationSettingsFragmentDoc}
`;
export const ResourcePolicyFragmentDoc = gql`
  fragment ResourcePolicy on ResourcePolicy {
    agents {
      ...AgentSettings
    }
  }
  ${AgentSettingsFragmentDoc}
`;
export const WaypointPolicyRuleTerminalPointsFragmentDoc = gql`
  fragment WaypointPolicyRuleTerminalPoints on WaypointPolicyRuleTerminalPoints {
    locationsMatch {
      ...LocationFilterUnion
    }
  }
  ${LocationFilterUnionFragmentDoc}
`;
export const WaypointPolicyRuleFragmentDoc = gql`
  fragment WaypointPolicyRule on WaypointPolicyRule {
    id
    title
    startPoints {
      ...WaypointPolicyRuleTerminalPoints
    }
    endPoints {
      ...WaypointPolicyRuleTerminalPoints
    }
  }
  ${WaypointPolicyRuleTerminalPointsFragmentDoc}
`;
export const WaypointPolicyFragmentDoc = gql`
  fragment WaypointPolicy on WaypointPolicy {
    rules {
      ...WaypointPolicyRule
    }
  }
  ${WaypointPolicyRuleFragmentDoc}
`;
export const PickingWavePickByDateSettingsFragmentDoc = gql`
  fragment PickingWavePickByDateSettings on PickingWavePickByDateSettings {
    mode
    fixedDeadlines
  }
`;
export const PickingWaveSettingsFragmentDoc = gql`
  fragment PickingWaveSettings on PickingWaveSettings {
    pickByDateSettings {
      ...PickingWavePickByDateSettings
    }
    useDeliveryRoute
    useDock
    useStagingArea
    useWaveId
  }
  ${PickingWavePickByDateSettingsFragmentDoc}
`;
export const PickingRequirementsBatchSettingsFragmentDoc = gql`
  fragment PickingRequirementsBatchSettings on PickingRequirementsBatchSettings {
    maxOrderCount
    maxOrderLineCount
  }
`;
export const PickingRequirementsSettingsFragmentDoc = gql`
  fragment PickingRequirementsSettings on PickingPolicyPickingRequirementsSettings {
    groupBy
    wave {
      ...PickingWaveSettings
    }
    batch {
      ...PickingRequirementsBatchSettings
    }
    startLocationIdSource
    endLocationIdSource
  }
  ${PickingWaveSettingsFragmentDoc}
  ${PickingRequirementsBatchSettingsFragmentDoc}
`;
export const OrderLineFilterFragmentDoc = gql`
  fragment OrderLineFilter on OrderLineFilter {
    type
    valueIn {
      ...FilterValue
    }
    range {
      ...NumberFilterRange
    }
    stringRange {
      ...StringFilterRange
    }
    isNull
    isNot
  }
  ${FilterValueFragmentDoc}
  ${NumberFilterRangeFragmentDoc}
  ${StringFilterRangeFragmentDoc}
`;
export const OrderLineFilterIntersectionFragmentDoc = gql`
  fragment OrderLineFilterIntersection on OrderLineFilterIntersection {
    id
    allOf {
      ...OrderLineFilter
    }
  }
  ${OrderLineFilterFragmentDoc}
`;
export const OrderLineFilterUnionFragmentDoc = gql`
  fragment OrderLineFilterUnion on OrderLineFilterUnion {
    anyOf {
      ...OrderLineFilterIntersection
    }
  }
  ${OrderLineFilterIntersectionFragmentDoc}
`;
export const PickingPolicyPikingRuleConfigurationFragmentDoc = gql`
  fragment PickingPolicyPikingRuleConfiguration on PickingPolicyPickingRule {
    uomTypes
    agentId
    locationSelectionMode
    picklistGroupKey
    waypointSettings {
      ruleId
    }
  }
`;
export const PickingPolicyPickingRuleFragmentDoc = gql`
  fragment PickingPolicyPickingRule on PickingPolicyPickingRule {
    id
    title
    ...PickingPolicyPikingRuleConfiguration
    locationsMatch {
      ...LocationFilterUnion
    }
  }
  ${PickingPolicyPikingRuleConfigurationFragmentDoc}
  ${LocationFilterUnionFragmentDoc}
`;
export const PicklistLinesSortByFragmentDoc = gql`
  fragment PicklistLinesSortBy on PicklistLinesSortBy {
    type
    direction
    nullsFirst
  }
`;
export const PickingPolicyOrderLineRuleFragmentDoc = gql`
  fragment PickingPolicyOrderLineRule on PickingPolicyOrderLineRule {
    id
    title
    pickingRequirementsSettings {
      ...PickingRequirementsSettings
    }
    orderLinesMatch {
      ...OrderLineFilterUnion
    }
    pickingRules {
      ...PickingPolicyPickingRule
    }
    picklistSettings {
      usePickingPriority
      picklistLinesOrder {
        ...PicklistLinesSortBy
      }
    }
    usePickingContainer
  }
  ${PickingRequirementsSettingsFragmentDoc}
  ${OrderLineFilterUnionFragmentDoc}
  ${PickingPolicyPickingRuleFragmentDoc}
  ${PicklistLinesSortByFragmentDoc}
`;
export const PickingPolicyFragmentDoc = gql`
  fragment PickingPolicy on PickingPolicy {
    orderLineRules {
      ...PickingPolicyOrderLineRule
    }
  }
  ${PickingPolicyOrderLineRuleFragmentDoc}
`;
export const AllocationLimitSettingsFragmentDoc = gql`
  fragment AllocationLimitSettings on AllocationLimitSettings {
    applyTo
    maxPickableAssignments
    maxAllocatedAssignments
    maxPickableVolume
    maxAllocatedVolume
  }
`;
export const AllocationRoundSettingsFragmentDoc = gql`
  fragment AllocationRoundSettings on AllocationRoundSettings {
    maxAssignmentsPerRound
  }
`;
export const AllocationPickabilitySettingsFragmentDoc = gql`
  fragment AllocationPickabilitySettings on AllocationPickabilitySettings {
    enforcePickability
  }
`;
export const AllocationPrioritySettingsFragmentDoc = gql`
  fragment AllocationPrioritySettings on AllocationPrioritySettings {
    itemPriority {
      priority
      direction
    }
    locationPriority {
      priority
      direction
    }
  }
`;
export const AllocationReplacementSettingsFragmentDoc = gql`
  fragment AllocationReplacementSettings on AllocationReplacementSettings {
    allow
  }
`;
export const AllocationMultiplexingSettingsFragmentDoc = gql`
  fragment AllocationMultiplexingSettings on AllocationMultiplexingSettings {
    allow
  }
`;
export const AllocationRequirementAggregationSettingsFragmentDoc = gql`
  fragment AllocationRequirementAggregationSettings on AllocationRequirementAggregationSettings {
    aggregation
    numberOfPeriods
  }
`;
export const AllocationConstraintSettingsFragmentDoc = gql`
  fragment AllocationConstraintSettings on AllocationConstraintSettings {
    assignmentPolicyConstraint {
      disabled
    }
    locationSizeConstraint {
      disabled
      unknownAsNoncompliant
    }
    bayWidthConstraint {
      disabled
    }
  }
`;
export const AllocationRuleFragmentDoc = gql`
  fragment AllocationRule on AllocationRule {
    id
    title
    uomTypes
    itemsMatch {
      ...SimulationItemFilterUnion
    }
    locationsMatch {
      ...LocationFilterUnion
    }
    limitSettings {
      ...AllocationLimitSettings
    }
    roundSettings {
      ...AllocationRoundSettings
    }
    pickabilitySettings {
      ...AllocationPickabilitySettings
    }
    prioritySettings {
      ...AllocationPrioritySettings
    }
    replacementSettings {
      ...AllocationReplacementSettings
    }
    multiplexingSettings {
      ...AllocationMultiplexingSettings
    }
    requirementAggregationSettings {
      ...AllocationRequirementAggregationSettings
    }
    constraintSettings {
      ...AllocationConstraintSettings
    }
    pickabilitySettings {
      ...AllocationPickabilitySettings
    }
  }
  ${SimulationItemFilterUnionFragmentDoc}
  ${LocationFilterUnionFragmentDoc}
  ${AllocationLimitSettingsFragmentDoc}
  ${AllocationRoundSettingsFragmentDoc}
  ${AllocationPickabilitySettingsFragmentDoc}
  ${AllocationPrioritySettingsFragmentDoc}
  ${AllocationReplacementSettingsFragmentDoc}
  ${AllocationMultiplexingSettingsFragmentDoc}
  ${AllocationRequirementAggregationSettingsFragmentDoc}
  ${AllocationConstraintSettingsFragmentDoc}
`;
export const AllocationPolicyFragmentDoc = gql`
  fragment AllocationPolicy on AllocationPolicy {
    rules {
      ...AllocationRule
    }
    defaultRule {
      ...AllocationRule
    }
  }
  ${AllocationRuleFragmentDoc}
`;
export const DeallocationRuleFragmentDoc = gql`
  fragment DeallocationRule on DeallocationRule {
    id
    title
    itemsMatch {
      ...SimulationItemFilterUnion
    }
    locationsMatch {
      ...LocationFilterUnion
    }
    constraintSettings {
      ...AllocationConstraintSettings
    }
    deallocateType
    reallocateType
    reallocateQuantitySettings {
      reallocateUom
      reallocateQuantity
      fixedUom
      fixedUomQuantity
    }
  }
  ${SimulationItemFilterUnionFragmentDoc}
  ${LocationFilterUnionFragmentDoc}
  ${AllocationConstraintSettingsFragmentDoc}
`;
export const DeallocationPolicyFragmentDoc = gql`
  fragment DeallocationPolicy on DeallocationPolicy {
    rules {
      ...DeallocationRule
    }
    defaultRule {
      ...DeallocationRule
    }
  }
  ${DeallocationRuleFragmentDoc}
`;
export const OrderSetFilterFragmentDoc = gql`
  fragment OrderSetFilter on OrderSetFilter {
    orderDate {
      from
      to
      excludeFrom
      excludeTo
      includeNull
    }
  }
`;
export const AllocationSettingsFragmentDoc = gql`
  fragment AllocationSettings on AllocationSettings {
    allocationPolicy {
      ...AllocationPolicy
    }
    deallocationPolicy {
      ...DeallocationPolicy
    }
    orderSetFilter {
      ...OrderSetFilter
    }
    requirementSource {
      useOrderSetDemand
    }
    stockLimitSettings {
      enabled
    }
  }
  ${AllocationPolicyFragmentDoc}
  ${DeallocationPolicyFragmentDoc}
  ${OrderSetFilterFragmentDoc}
`;
export const LocationSizeConstraintSettingsFragmentDoc = gql`
  fragment LocationSizeConstraintSettings on LocationSizeConstraintSettings {
    disabled
  }
`;
export const BayWidthConstraintSettingsFragmentDoc = gql`
  fragment BayWidthConstraintSettings on BayWidthConstraintSettings {
    disabled
  }
`;
export const TerminationSettingsFragmentDoc = gql`
  fragment TerminationSettings on TerminationSettings {
    targetImprovement
    timeLimit
    minImprovementRate {
      minImprovementChange
      steps
    }
  }
`;
export const AssignmentsMatchFragmentDoc = gql`
  fragment AssignmentsMatch on AssignmentSubsetSpec {
    locationsMatch {
      ...LocationFilterUnion
    }
    itemsMatch {
      ...SimulationItemFilterUnion
    }
    unassignedLocationsMatch
  }
  ${LocationFilterUnionFragmentDoc}
  ${SimulationItemFilterUnionFragmentDoc}
`;
export const SwapPolicyRuleFragmentDoc = gql`
  fragment SwapPolicyRule on OptimizationSwapRule {
    id
    title
    src {
      ...AssignmentsMatch
    }
    dest {
      ...AssignmentsMatch
    }
  }
  ${AssignmentsMatchFragmentDoc}
`;
export const SwapPolicyFragmentDoc = gql`
  fragment SwapPolicy on SwapSettings {
    rules {
      ...SwapPolicyRule
    }
  }
  ${SwapPolicyRuleFragmentDoc}
`;
export const LoadBalancingConstraintFragmentDoc = gql`
  fragment LoadBalancingConstraint on LoadBalancingConstraint {
    maxRelativeDeviation
  }
`;
export const LoadBalancingRuleFragmentDoc = gql`
  fragment LoadBalancingRule on LoadBalancingRule {
    id
    title
    type
    objectiveWeight
    constraint {
      ...LoadBalancingConstraint
    }
    include {
      ...LocationFilterUnion
    }
  }
  ${LoadBalancingConstraintFragmentDoc}
  ${LocationFilterUnionFragmentDoc}
`;
export const LoadBalancingPolicyFragmentDoc = gql`
  fragment LoadBalancingPolicy on LoadBalancingPolicy {
    rules {
      ...LoadBalancingRule
    }
  }
  ${LoadBalancingRuleFragmentDoc}
`;
export const LimitSettingsFragmentDoc = gql`
  fragment LimitSettings on LimitSettings {
    maxMoves
  }
`;
export const OptimizationSettingsFragmentDoc = gql`
  fragment OptimizationSettings on OptimizationSettings {
    locationSizeConstraint {
      ...LocationSizeConstraintSettings
    }
    bayWidthConstraint {
      ...BayWidthConstraintSettings
    }
    terminationSettings {
      ...TerminationSettings
    }
    swapSettings {
      ...SwapPolicy
    }
    loadBalancing {
      ...LoadBalancingPolicy
    }
    limitSettings {
      ...LimitSettings
    }
    reassignJobs {
      disabled
      cycleMode
    }
    policyOverrides {
      resourcePolicy {
        ...ResourcePolicy
      }
    }
    pickabilityConstraint {
      disabled
    }
    assignmentCapacitySettings {
      avoidMerge
    }
  }
  ${LocationSizeConstraintSettingsFragmentDoc}
  ${BayWidthConstraintSettingsFragmentDoc}
  ${TerminationSettingsFragmentDoc}
  ${SwapPolicyFragmentDoc}
  ${LoadBalancingPolicyFragmentDoc}
  ${LimitSettingsFragmentDoc}
  ${ResourcePolicyFragmentDoc}
`;
export const AllocationSummaryKeyFragmentDoc = gql`
  fragment AllocationSummaryKey on AllocationRunResultRequirementSummaryKey {
    itemId
    consignee
    orderLineRuleId
    sku
    skuGroup
    subGroup
    transportClass
    stockCategory
    storageClass
    pollutionClass
  }
`;
export const AllocationSummaryRequirementDetailsFragmentDoc = gql`
  fragment AllocationSummaryRequirementDetails on AllocationRunResultRequirementQuantity {
    totalQuantity
    uoms {
      uom
      uomQuantity
    }
  }
`;
export const AllocationSummaryPickableDetailsFragmentDoc = gql`
  fragment AllocationSummaryPickableDetails on AllocationRunResultRequirementPickableQuantitySummary {
    totalQuantity
    uoms {
      uom
      uomQuantity
      totalVolume
    }
    totalLocationCount
  }
`;
export const AllocationRunResultRequirementQuantityFragmentDoc = gql`
  fragment AllocationRunResultRequirementQuantity on AllocationRunResultRequirementQuantity {
    totalQuantity
    uoms {
      uom
      uomQuantity
      totalVolume
      aggregatedTotalVolume
      aggregatedQuantity
      aggregatedUomQuantity
    }
  }
`;
export const AllocationSummaryUnallocatedDetailsFragmentDoc = gql`
  fragment AllocationSummaryUnallocatedDetails on AllocationRunResultRequirementUnallocatedQuantity {
    totalQuantity
    uoms {
      uom
      uomQuantity
    }
    reasons
  }
`;
export const AllocationSummaryDeallocatedDetailsFragmentDoc = gql`
  fragment AllocationSummaryDeallocatedDetails on AllocationRunResultRequirementDeallocatedQuantitySummary {
    totalQuantity
    uoms {
      uom
      uomQuantity
      totalVolume
    }
    totalLocationCount
    reasons
  }
`;
export const AllocationSummaryFragmentDoc = gql`
  fragment AllocationSummary on AllocationRunResultRequirementSummary {
    key {
      ...AllocationSummaryKey
    }
    required {
      ...AllocationSummaryRequirementDetails
    }
    unpickable {
      ...AllocationSummaryRequirementDetails
    }
    initiallyPickable {
      ...AllocationSummaryPickableDetails
    }
    initiallyPickableFulfilled {
      ...AllocationSummaryPickableDetails
    }
    toAllocateRequired {
      ...AllocationRunResultRequirementQuantity
    }
    toReallocate {
      ...AllocationRunResultRequirementQuantity
    }
    toAllocate {
      ...AllocationRunResultRequirementQuantity
    }
    allocated {
      ...AllocationSummaryPickableDetails
    }
    allocatedShared {
      ...AllocationSummaryPickableDetails
    }
    allocatedFulfilled {
      ...AllocationSummaryPickableDetails
    }
    unallocated {
      ...AllocationSummaryUnallocatedDetails
    }
    deallocated {
      ...AllocationSummaryDeallocatedDetails
    }
  }
  ${AllocationSummaryKeyFragmentDoc}
  ${AllocationSummaryRequirementDetailsFragmentDoc}
  ${AllocationSummaryPickableDetailsFragmentDoc}
  ${AllocationRunResultRequirementQuantityFragmentDoc}
  ${AllocationSummaryUnallocatedDetailsFragmentDoc}
  ${AllocationSummaryDeallocatedDetailsFragmentDoc}
`;
export const AllocationRunBaseFragmentDoc = gql`
  fragment AllocationRunBase on AllocationRun {
    id
    progress
    seed
    status
    startedAt
    completedAt
    createdAt
    allocationRunType
    resultAssignment {
      id
    }
    analyzeResult {
      id
      status
    }
    allocationSettings {
      ...AllocationSettings
    }
    resultRequirementsSummary {
      ...AllocationSummary
    }
  }
  ${AllocationSettingsFragmentDoc}
  ${AllocationSummaryFragmentDoc}
`;
export const SimulationMetaFragmentDoc = gql`
  fragment SimulationMeta on Simulation {
    ...SimulationMetaStatus
    title
    description
    status
    updatedAt
    layout {
      id
    }
    assignment {
      id
      title
    }
    orderSet {
      id
    }
    itemSet {
      id
      uomTypes {
        uom
      }
    }
    orderedQuantityReport {
      ...OrderedQuantityReportSummary
    }
    routingPolicy {
      ...RoutingPolicy
    }
    stackingPolicy {
      ...StackingPolicy
    }
    assignmentPolicy {
      ...AssignmentPolicy
    }
    locationSharingPolicy {
      ...LocationSharingPolicy
    }
    resourcePolicy {
      ...ResourcePolicy
    }
    waypointPolicy {
      ...WaypointPolicy
    }
    pickingPolicy {
      ...PickingPolicy
    }
    allocationSettings {
      ...AllocationSettings
    }
    analyzeResult {
      id
      status
    }
    optimizationRuns(page: { limit: 1, skip: 0 }) {
      content {
        id
        status
      }
    }
    optimizationSettings {
      ...OptimizationSettings
    }
    latestAllocationRun {
      ...AllocationRunBase
      allocationSettings {
        ...AllocationSettings
      }
    }
  }
  ${SimulationMetaStatusFragmentDoc}
  ${OrderedQuantityReportSummaryFragmentDoc}
  ${RoutingPolicyFragmentDoc}
  ${StackingPolicyFragmentDoc}
  ${AssignmentPolicyFragmentDoc}
  ${LocationSharingPolicyFragmentDoc}
  ${ResourcePolicyFragmentDoc}
  ${WaypointPolicyFragmentDoc}
  ${PickingPolicyFragmentDoc}
  ${AllocationSettingsFragmentDoc}
  ${OptimizationSettingsFragmentDoc}
  ${AllocationRunBaseFragmentDoc}
`;
export const SizeComplianceLocationFragmentDoc = gql`
  fragment SizeComplianceLocation on LayoutLocation {
    locationId
    locationLevel
    locationRackingType
    bay {
      id
      title
    }
    plane {
      id
      title
    }
    warehouseArea
  }
`;
export const SizeComplianceLocationItemFragmentDoc = gql`
  fragment SizeComplianceLocationItem on SizeComplianceLocationItem {
    consignee
    sku
    status
    uom {
      uom
      width
      length
      height
    }
  }
`;
export const SizeComplianceLocationDetailsFragmentDoc = gql`
  fragment SizeComplianceLocationDetails on SizeComplianceLocationDetails {
    location {
      ...SizeComplianceLocation
    }
    status
    items {
      ...SizeComplianceLocationItem
    }
  }
  ${SizeComplianceLocationFragmentDoc}
  ${SizeComplianceLocationItemFragmentDoc}
`;
export const SizeComplianceLocationStatusFragmentDoc = gql`
  fragment SizeComplianceLocationStatus on SizeComplianceLocationDetails {
    locationId
    status
  }
`;
export const AssignmentSubsetSummaryFragmentDoc = gql`
  fragment AssignmentSubsetSummary on AssignmentSubsetSummary {
    itemCount
    locationCount
    assignmentItemCount
    emptyLocationCount
  }
`;
export const CheckedOptimizationSwapRuleDetailsFragmentDoc = gql`
  fragment CheckedOptimizationSwapRuleDetails on CheckedOptimizationSwapRuleDetails {
    noValidSwapsReasons
    violatedConstrains
  }
`;
export const CheckedOptimizationSwapRuleFragmentDoc = gql`
  fragment CheckedOptimizationSwapRule on CheckedOptimizationSwapRule {
    id
    title
    hasValidSwaps
    srcMatch {
      ...AssignmentSubsetSummary
    }
    destMatch {
      ...AssignmentSubsetSummary
    }
    details {
      ...CheckedOptimizationSwapRuleDetails
    }
  }
  ${AssignmentSubsetSummaryFragmentDoc}
  ${CheckedOptimizationSwapRuleDetailsFragmentDoc}
`;
export const CheckOptimizationSwapPolicyResultFragmentDoc = gql`
  fragment CheckOptimizationSwapPolicyResult on CheckedOptimizationSwapPolicy {
    rules {
      ...CheckedOptimizationSwapRule
    }
    hasValidSwaps
  }
  ${CheckedOptimizationSwapRuleFragmentDoc}
`;
export const CheckOptimizationSwapPolicyJobFragmentDoc = gql`
  fragment CheckOptimizationSwapPolicyJob on CheckOptimizationSwapPolicyJob {
    id
    status
    result {
      ...CheckOptimizationSwapPolicyResult
    }
  }
  ${CheckOptimizationSwapPolicyResultFragmentDoc}
`;
export const UserSettingsBaseFragmentDoc = gql`
  fragment UserSettingsBase on UserSettings {
    configured
    configuredAt
    consents {
      privacyPolicy {
        version
        consentedAt
      }
      termsPolicy {
        version
        consentedAt
      }
    }
    notifications {
      simulations
      news
      insights
    }
    trialWarehouseCreated
    trialWarehouseCreatedAt
    createdAt
    updatedAt
    canCreateTrialWarehouse
  }
`;
export const UserSettingsFragmentDoc = gql`
  fragment UserSettings on UserSettings {
    ...UserSettingsBase
    featureRequests {
      billing
      contracts
      marketplace
      optimise
      sales
    }
    featureFlags
  }
  ${UserSettingsBaseFragmentDoc}
`;
export const LayoutImportDashboardItemFragmentDoc = gql`
  fragment LayoutImportDashboardItem on LayoutImport {
    id
    title
    description
    createdAt
    updatedAt
    importTime
    userTags
    converterSummary: summary {
      ...LayoutImportSummary
    }
  }
  ${LayoutImportSummaryFragmentDoc}
`;
export const AssignmentDashboardItemFragmentDoc = gql`
  fragment AssignmentDashboardItem on Assignment {
    id
    title
    description
    createdAt
    updatedAt
    userTags
    summary {
      ...AssignmentSummary
    }
    simulationsWith {
      ...SimulationsConnection
    }
  }
  ${AssignmentSummaryFragmentDoc}
  ${SimulationsConnectionFragmentDoc}
`;
export const OrderSetDashboardItemFragmentDoc = gql`
  fragment OrderSetDashboardItem on OrderSet {
    id
    title
    description
    updatedAt
    createdAt
    userTags
    summary {
      ...OrderSetSummary
    }
    simulationsWith {
      ...SimulationsConnection
    }
  }
  ${OrderSetSummaryFragmentDoc}
  ${SimulationsConnectionFragmentDoc}
`;
export const ItemSetDashboardItemFragmentDoc = gql`
  fragment ItemSetDashboardItem on ItemSet {
    id
    title
    description
    updatedAt
    createdAt
    userTags
    summary {
      ...ItemSetSummary
    }
    simulationsWith {
      ...SimulationsConnection
    }
  }
  ${ItemSetSummaryFragmentDoc}
  ${SimulationsConnectionFragmentDoc}
`;
export const LayoutAreaSummaryFragmentDoc = gql`
  fragment LayoutAreaSummary on LayoutAreaSummary {
    areaId
    title
    locationCount
    aisleCount
    bayCount
  }
`;
export const LayoutSummaryFragmentDoc = gql`
  fragment LayoutSummary on LayoutSummary {
    locationCount
    disabledLocationCount
    aisleCount
    bayCount
    areas {
      ...LayoutAreaSummary
    }
  }
  ${LayoutAreaSummaryFragmentDoc}
`;
export const OptimizationRunForDashboardFragmentDoc = gql`
  fragment OptimizationRunForDashboard on OptimizationRun {
    ...OptimizationRunBaseWithoutAnalyze
    analyzeResult {
      ...AnalyzeResultSummary
    }
    latestProgressDetails {
      ...OptimizationRunProgress
    }
  }
  ${OptimizationRunBaseWithoutAnalyzeFragmentDoc}
  ${AnalyzeResultSummaryFragmentDoc}
  ${OptimizationRunProgressFragmentDoc}
`;
export const SimulationDashboardItemFragmentDoc = gql`
  fragment SimulationDashboardItem on Simulation {
    id
    title
    description
    updatedAt
    createdAt
    status
    userTags
    layout {
      id
      title
      summary {
        ...LayoutSummary
      }
    }
    assignment {
      id
      title
    }
    orderSet {
      id
      title
    }
    itemSet {
      id
      title
    }
    resourcePolicy {
      ...ResourcePolicy
    }
    optimizationSettings {
      ...OptimizationSettings
    }
    analyzeResult {
      startedAt
      completedAt
      ...AnalyzeResultSummary
    }
    latestOptimizationRun {
      ...OptimizationRunForDashboard
    }
    latestAllocationRun {
      id
      progress
      status
      startedAt
      completedAt
      analyzeResult {
        ...AnalyzeResultSummary
      }
    }
  }
  ${LayoutSummaryFragmentDoc}
  ${ResourcePolicyFragmentDoc}
  ${OptimizationSettingsFragmentDoc}
  ${AnalyzeResultSummaryFragmentDoc}
  ${OptimizationRunForDashboardFragmentDoc}
`;
export const DashboardSimulationFragmentDoc = gql`
  fragment DashboardSimulation on SimulationsConnection {
    totalCount
    allUserTags
    content {
      ...SimulationDashboardItem
    }
  }
  ${SimulationDashboardItemFragmentDoc}
`;
export const StoredFileFragmentDoc = gql`
  fragment StoredFile on StoredFile {
    warehouseId
    name
    size
    lastModified
    downloadUrl
  }
`;
export const WeightComplianceBayDetailsFragmentDoc = gql`
  fragment WeightComplianceBayDetails on WeightComplianceBayDetails {
    bay {
      id
      title
      details {
        ... on LayoutBayDetails {
          bayType
        }
      }
    }
    plane {
      id
      title
    }
    status
    weight
    maxWeight
  }
`;
export const WeightComplianceLocationDataFragmentDoc = gql`
  fragment WeightComplianceLocationData on LayoutLocation {
    locationId
    locationRackingType
    warehouseArea
    locationLevel
    bay {
      id
      title
    }
    plane {
      id
      title
    }
  }
`;
export const WeightComplianceLocationItemFragmentDoc = gql`
  fragment WeightComplianceLocationItem on WeightComplianceLocationItem {
    consignee
    sku
    weight
    quantity
    uoms {
      uom {
        uom
      }
      uomQuantity
    }
  }
`;
export const WeightComplianceLocationDetailsFragmentDoc = gql`
  fragment WeightComplianceLocationDetails on WeightComplianceLocationDetails {
    location {
      ...WeightComplianceLocationData
    }
    status
    weight
    maxWeight
    volume
    items {
      ...WeightComplianceLocationItem
    }
  }
  ${WeightComplianceLocationDataFragmentDoc}
  ${WeightComplianceLocationItemFragmentDoc}
`;
export const ActualityRefSummaryFragmentDoc = gql`
  fragment ActualityRefSummary on Actuality {
    id
    title
    description
    createdAt
    updatedAt
  }
`;
export const ActualityMetaStatusFragmentDoc = gql`
  fragment ActualityMetaStatus on Actuality {
    ...ActualityRefSummary
  }
  ${ActualityRefSummaryFragmentDoc}
`;
export const ProcessingPipelineJobFragmentDoc = gql`
  fragment ProcessingPipelineJob on ProcessingPipelineJob {
    id
    pipelineType
    status
    progress
    createdAt
    updatedAt
    startedAt
    completedAt
    details
  }
`;
export const ProcessingPipelineFragmentDoc = gql`
  fragment ProcessingPipeline on ProcessingPipeline {
    id
    title
    enabled
    jobs(page: { limit: 5 }, sortBy: { field: CREATED_AT, direction: DESC }) {
      content {
        ...ProcessingPipelineJob
      }
    }
  }
  ${ProcessingPipelineJobFragmentDoc}
`;
export const ActualityMetaFragmentDoc = gql`
  fragment ActualityMeta on Actuality {
    ...ActualityMetaStatus
    layoutId
    activityFeedId
    assignmentId
    orderSetId
    itemSetId
    routingPolicy {
      ...RoutingPolicy
    }
    stackingPolicy {
      ...StackingPolicy
    }
    assignmentPolicy {
      ...AssignmentPolicy
    }
    locationSharingPolicy {
      ...LocationSharingPolicy
    }
    resourcePolicy {
      ...ResourcePolicy
    }
    waypointPolicy {
      ...WaypointPolicy
    }
    pickingPolicy {
      ...PickingPolicy
    }
    analyzePipeline {
      ...ProcessingPipeline
    }
    extraSettings
  }
  ${ActualityMetaStatusFragmentDoc}
  ${RoutingPolicyFragmentDoc}
  ${StackingPolicyFragmentDoc}
  ${AssignmentPolicyFragmentDoc}
  ${LocationSharingPolicyFragmentDoc}
  ${ResourcePolicyFragmentDoc}
  ${WaypointPolicyFragmentDoc}
  ${PickingPolicyFragmentDoc}
  ${ProcessingPipelineFragmentDoc}
`;
export const PicklistProcessingEventDetailsFragmentDoc = gql`
  fragment PicklistProcessingEventDetails on PicklistProcessingEventDetails {
    orderCount
    orderLineCount
    totalUomQuantity
  }
`;
export const PicklistLineProcessingEventDetailsFragmentDoc = gql`
  fragment PicklistLineProcessingEventDetails on PicklistLineProcessingEventDetails {
    uomQuantity
  }
`;
export const PicklistEventWaypointFragmentDoc = gql`
  fragment PicklistEventWaypoint on PicklistEventWaypoint {
    id
    type
    position {
      ...Point
    }
  }
  ${PointFragmentDoc}
`;
export const TravellingHorizontalDetailsFragmentDoc = gql`
  fragment TravellingHorizontalDetails on TravellingHorizontalDetails {
    distance
    weight
    volume
    route {
      ...PicklistEventWaypoint
    }
  }
  ${PicklistEventWaypointFragmentDoc}
`;
export const TravellingVerticalDetailsFragmentDoc = gql`
  fragment TravellingVerticalDetails on TravellingVerticalDetails {
    distance
    weight
    volume
  }
`;
export const HandlingExecutionDetailsFragmentDoc = gql`
  fragment HandlingExecutionDetails on HandlingExecutionDetails {
    uom
    uomQuantity
    weight
    volume
  }
`;
export const HandlingPalletReorderingDetailsFragmentDoc = gql`
  fragment HandlingPalletReorderingDetails on HandlingPalletReorderingDetails {
    reordered {
      weight
      volume
      uoms {
        uom
        uomQuantity
      }
    }
  }
`;
export const AnalyzeEventFragmentDoc = gql`
  fragment AnalyzeEvent on AnalyzeResultEvent {
    sequenceId
    eventType
    duration
    cost
    agents
    startPosition {
      ...Point
    }
    endPosition {
      ...Point
    }
    orderIds
    picklistLine
    locationId
    details {
      __typename
      ... on PicklistProcessingEventDetails {
        ...PicklistProcessingEventDetails
      }
      ... on PicklistLineProcessingEventDetails {
        ...PicklistLineProcessingEventDetails
      }
      ... on TravellingHorizontalDetails {
        ...TravellingHorizontalDetails
      }
      ... on TravellingVerticalDetails {
        ...TravellingVerticalDetails
      }
      ... on HandlingExecutionDetails {
        ...HandlingExecutionDetails
      }
      ... on HandlingPalletReorderingDetails {
        ...HandlingPalletReorderingDetails
      }
    }
  }
  ${PointFragmentDoc}
  ${PicklistProcessingEventDetailsFragmentDoc}
  ${PicklistLineProcessingEventDetailsFragmentDoc}
  ${TravellingHorizontalDetailsFragmentDoc}
  ${TravellingVerticalDetailsFragmentDoc}
  ${HandlingExecutionDetailsFragmentDoc}
  ${HandlingPalletReorderingDetailsFragmentDoc}
`;
export const AnalyzedFeedPicklistFragmentDoc = gql`
  fragment AnalyzedFeedPicklist on AnalyzedPicklist {
    picklistId
    totalCost
    totalDuration
    totalDistance
    events {
      ...AnalyzeEvent
    }
  }
  ${AnalyzeEventFragmentDoc}
`;
export const ProcessingPipelineJobsConnectionFragmentDoc = gql`
  fragment ProcessingPipelineJobsConnection on ProcessingPipelineJobsConnection {
    totalCount
    content {
      ...ProcessingPipelineJob
    }
  }
  ${ProcessingPipelineJobFragmentDoc}
`;
export const AllocationRequirementRuleUomDetailsFragmentDoc = gql`
  fragment AllocationRequirementRuleUomDetails on AllocationRequirementUomQuantity {
    uom
    uomQuantity
    quantity
    totalVolume
    aggregatedTotalVolume
    aggregatedQuantity
    aggregatedUomQuantity
  }
`;
export const AllocationRequirementRuleDetailsFragmentDoc = gql`
  fragment AllocationRequirementRuleDetails on AllocationRequirementRuleDetails {
    pickingRuleId
    requiredQuantity
    preferredUoms {
      ...AllocationRequirementRuleUomDetails
    }
  }
  ${AllocationRequirementRuleUomDetailsFragmentDoc}
`;
export const AllocationRequirementDetailsFragmentDoc = gql`
  fragment AllocationRequirementDetails on AllocationRequirementDetails {
    rules {
      ...AllocationRequirementRuleDetails
    }
  }
  ${AllocationRequirementRuleDetailsFragmentDoc}
`;
export const AllocationRequirementFragmentDoc = gql`
  fragment AllocationRequirement on AllocationRequirement {
    itemId
    consignee
    sku
    skuGroup
    subGroup
    transportClass
    stockCategory
    storageClass
    pollutionClass
    orderLineRuleId
    requiredQuantity
    preferredRequiredVolume
    unpickableRequiredQuantity
    aggregatedRequiredQuantity
    aggregatedPreferredRequiredVolume
    aggregatedUnpickableRequiredQuantity
    details {
      ...AllocationRequirementDetails
    }
  }
  ${AllocationRequirementDetailsFragmentDoc}
`;
export const AllocationRequirementsConnectionFragmentDoc = gql`
  fragment AllocationRequirementsConnection on AllocationRequirementsConnection {
    totalCount
    content {
      ...AllocationRequirement
    }
  }
  ${AllocationRequirementFragmentDoc}
`;
export const AllocationRequirementSummaryKeyFragmentDoc = gql`
  fragment AllocationRequirementSummaryKey on AllocationRequirementSummaryKey {
    itemId
    sku
    consignee
    orderLineRuleId
    possibleLocationMask
    requiredLocationMask
  }
`;
export const AllocationRequirementSummaryFragmentDoc = gql`
  fragment AllocationRequirementSummary on AllocationRequirementSummary {
    key {
      ...AllocationRequirementSummaryKey
    }
    allocationRequirementCount
    itemCount
    requiredQuantity
    preferredRequiredVolume
    unpickableRequiredQuantity
    aggregatedRequiredQuantity
    aggregatedPreferredRequiredVolume
    aggregatedUnpickableRequiredQuantity
  }
  ${AllocationRequirementSummaryKeyFragmentDoc}
`;
export const LayoutIssueInvalidLocationFragmentDoc = gql`
  fragment LayoutIssueInvalidLocation on LayoutIssueInvalidLocation {
    locationId
    reason
  }
`;
export const AssignmentIssueInvalidLocationFragmentDoc = gql`
  fragment AssignmentIssueInvalidLocation on AssignmentIssueInvalidLocation {
    locationId
    itemIds
    reason
    productIds {
      sku
      consignee
    }
  }
`;
export const AssignmentIssueInvalidLocationsDataFragmentDoc = gql`
  fragment AssignmentIssueInvalidLocationsData on AssignmentIssueInvalidLocationsConnection {
    totalCount
    content {
      ...AssignmentIssueInvalidLocation
    }
  }
  ${AssignmentIssueInvalidLocationFragmentDoc}
`;
export const ItemSetIssueInvalidUomFragmentDoc = gql`
  fragment ItemSetIssueInvalidUom on ItemSetIssueInvalidUom {
    consignee
    sku
    itemId
    uom
    reason
  }
`;
export const ItemSetIssueInvalidUomsDataFragmentDoc = gql`
  fragment ItemSetIssueInvalidUomsData on ItemSetIssueInvalidUomsConnection {
    totalCount
    content {
      ...ItemSetIssueInvalidUom
    }
  }
  ${ItemSetIssueInvalidUomFragmentDoc}
`;
export const ItemSetIssueInvalidItemFragmentDoc = gql`
  fragment ItemSetIssueInvalidItem on ItemSetIssueInvalidItem {
    consignee
    sku
    itemId
    reason
  }
`;
export const ItemSetIssueInvalidItemsDataFragmentDoc = gql`
  fragment ItemSetIssueInvalidItemsData on ItemSetIssueInvalidItemsConnection {
    totalCount
    content {
      ...ItemSetIssueInvalidItem
    }
  }
  ${ItemSetIssueInvalidItemFragmentDoc}
`;
export const OrderSetIssueUnpickableLineFragmentDoc = gql`
  fragment OrderSetIssueUnpickableLine on OrderSetIssueUnpickableLine {
    orderId
    consignee
    sku
    itemId
    reason
    locationIds
  }
`;
export const OrderSetIssueUnpickableLinesDataFragmentDoc = gql`
  fragment OrderSetIssueUnpickableLinesData on OrderSetIssueUnpickableLinesConnection {
    totalCount
    content {
      ...OrderSetIssueUnpickableLine
    }
  }
  ${OrderSetIssueUnpickableLineFragmentDoc}
`;
export const ReassignJobMoveFragmentDoc = gql`
  fragment ReassignJobMove on ReassignJobMove {
    itemId
    moveId
    groupId
    chainNumber
    sequenceNumber
    consignee
    sku
    srcLocationId
    destLocationId
    destAction
    estimatedCostImprovement
    movedQuantity {
      breakdownByUom {
        quantity
        uom
        uomQuantity
      }
    }
    item {
      name
      description
      percentRank
      cumulativePercentRank
      skuGroup
      subGroup
      transportClass
      stockCategory
      storageClass
      pollutionClass
    }
  }
`;
export const ReassignJobDetailsFragmentDoc = gql`
  fragment ReassignJobDetails on ReassignJobDetails {
    moves {
      ...ReassignJobMove
    }
  }
  ${ReassignJobMoveFragmentDoc}
`;
export const AnalyzeResultJobDetailsFragmentDoc = gql`
  fragment AnalyzeResultJobDetails on AnalyzeResultJobDetails {
    ... on ReassignJobDetails {
      ...ReassignJobDetails
    }
  }
  ${ReassignJobDetailsFragmentDoc}
`;
export const AnalyzeResultJobFragmentDoc = gql`
  fragment AnalyzeResultJob on AnalyzeResultJob {
    id
    agents
    process
    jobDistance
    jobDuration
    jobCost
    details {
      ...AnalyzeResultJobDetails
    }
  }
  ${AnalyzeResultJobDetailsFragmentDoc}
`;
export const AnalyzeResultJobsConnectionFragmentDoc = gql`
  fragment AnalyzeResultJobsConnection on AnalyzeResultJobsConnection {
    totalCount
    content {
      ...AnalyzeResultJob
    }
  }
  ${AnalyzeResultJobFragmentDoc}
`;
export const AnalyzeResultWaypointFragmentDoc = gql`
  fragment AnalyzeResultWaypoint on AnalyzeResultWaypoint {
    planeId
    waypointType
    waypointId
    x
    y
    hitCount
  }
`;
export const AnalyzeResultProductReplenishmentFragmentDoc = gql`
  fragment AnalyzeResultProductReplenishment on AnalyzeResultLocationProductReplenishmentInfo {
    replenishmentQuantity
    replenishmentUom
    replenishmentUomQuantity
  }
`;
export const AnalyzeResultProductReassignFragmentDoc = gql`
  fragment AnalyzeResultProductReassign on AnalyzeResultLocationProductReassignTask {
    action
    quantity
    breakdownByUom {
      uom
      quantity
      uomQuantity
    }
  }
`;
export const AnalyzeResultProductFragmentDoc = gql`
  fragment AnalyzeResultProduct on AnalyzeResultLocationProductInfo {
    itemId
    consignee
    sku
    percentRank
    cumulativePercentRank
    item {
      skuGroup
      subGroup
      pollutionClass
      transportClass
      stockCategory
      storageClass
      baseUom {
        uom
        netWeight
        volume
        length
        width
        height
        maxStack
      }
      details {
        dailyStats {
          totalDaysWithOrders
          orderLineCount {
            avg
            max
            cv
          }
        }
        weeklyStats {
          totalWeeksWithOrders
          orderLineCount {
            max
            avg
            cv
          }
          daysWithOrders {
            max
            avg
            cv
          }
        }
      }
    }
    hitCount
    pickCount
    replenishmentCount
    replenishment {
      replenishmentQuantity
      replenishmentUom
      replenishmentUomQuantity
    }
    reassignCount
    quantityPicked
    quantityReplenished
    quantityReassigned
    stackingCategoryIndex
    replenishment {
      ...AnalyzeResultProductReplenishment
    }
    appliedReorder {
      ...AnalyzeResultReorderSummary
    }
    triggeredReorder {
      ...AnalyzeResultReorderSummary
    }
    reassignTasks {
      ...AnalyzeResultProductReassign
    }
  }
  ${AnalyzeResultProductReplenishmentFragmentDoc}
  ${AnalyzeResultReorderSummaryFragmentDoc}
  ${AnalyzeResultProductReassignFragmentDoc}
`;
export const AnalyzeResultLocationFragmentDoc = gql`
  fragment AnalyzeResultLocation on AnalyzeResultLocation {
    locationId
    locationBayId
    planeId
    congestionZone
    products {
      ...AnalyzeResultProduct
    }
  }
  ${AnalyzeResultProductFragmentDoc}
`;
export const AnalyzeResultLocationsConnectionFragmentDoc = gql`
  fragment AnalyzeResultLocationsConnection on AnalyzeResultLocationsConnection {
    totalCount
    content {
      ...AnalyzeResultLocation
    }
  }
  ${AnalyzeResultLocationFragmentDoc}
`;
export const AnalyzePickListLineFragmentDoc = gql`
  fragment AnalyzePickListLine on PicklistLine {
    picklistLine
    consignee
    sku
    uom
    uomQuantity
    quantity
    locationId
    pickingPriority
    details {
      stackingPriority
      pickingContainer
    }
  }
`;
export const AnalyzePicklistFragmentDoc = gql`
  fragment AnalyzePicklist on AnalyzeResultPicklist {
    picklistId
    pickingMethod
    planeId
    agents
    orderIds
    unitOfWork
    pickByDate
    totalLines
    totalEvents
    totalDistance
    totalDuration
    totalCost
    picklist {
      id
      title
      orderLineRuleId
      planeId
      pickingMethod
      agents
      unitOfWork
      pickByDate
      orderIds
      totalVolume
      totalNetWeight
      lines {
        ...AnalyzePickListLine
      }
    }
  }
  ${AnalyzePickListLineFragmentDoc}
`;
export const AnalyzePicklistEventsFragmentDoc = gql`
  fragment AnalyzePicklistEvents on AnalyzeResultPicklist {
    ...AnalyzePicklist
    events {
      ...AnalyzeEvent
    }
  }
  ${AnalyzePicklistFragmentDoc}
  ${AnalyzeEventFragmentDoc}
`;
export const AnalyzeResultOrderSummaryFragmentDoc = gql`
  fragment AnalyzeResultOrderSummary on AnalyzeResultOrder {
    id
    order {
      id
      consignee
      orderId
      orderDate
    }
    totalLines
    assembledLines
    assembledPartiallyLines
    ignoredLines
    visitedLocations
    totalDistance
    totalDuration
    totalCost
  }
`;
export const AnalyzeResultOrderDetailsFragmentDoc = gql`
  fragment AnalyzeResultOrderDetails on AnalyzeResultOrder {
    ...AnalyzeResultOrderSummary
    orderLinesStatus {
      orderLine
      status
    }
    order {
      consignee
      orderDate
      customer
      details {
        sku
        orderLine
        quantity
      }
    }
    picklists {
      ...AnalyzePicklist
    }
  }
  ${AnalyzeResultOrderSummaryFragmentDoc}
  ${AnalyzePicklistFragmentDoc}
`;
export const GateUsageScheduleJobAssignmentFragmentDoc = gql`
  fragment GateUsageScheduleJobAssignment on GateUsageScheduleJobAssignment {
    job {
      id
      duration
      deadline
    }
    jobDetails {
      id
      title
      process
      agents
      jobDistance
      jobDuration
      totalCost
      totalDistance
      totalDuration
      totalCost
      details {
        __typename
        ... on LoadingJobDetails {
          picklistCount
          picklistLineCount
          orderCount
          netWeight
          volume
          quantity
          uomQuantities {
            uom
            uomQuantity
          }
          possibleGates {
            gateId
            distance
            duration
            cost
          }
        }
      }
    }
    startTime
    completionTime
    totalDuration
    dockingDuration
    undockingDuration
  }
`;
export const GateUsageScheduleResourceFragmentDoc = gql`
  fragment GateUsageScheduleResource on GateUsageScheduleResource {
    id
    assignments {
      ...GateUsageScheduleJobAssignment
    }
  }
  ${GateUsageScheduleJobAssignmentFragmentDoc}
`;
export const GateUsageScheduleFragmentDoc = gql`
  fragment GateUsageSchedule on GateUsageSchedule {
    startTime
    completionTime
    deadlines
    resources {
      ...GateUsageScheduleResource
    }
    totalCost {
      tardiness
      cost
    }
    totalCostWithoutWaiting {
      tardiness
      cost
    }
  }
  ${GateUsageScheduleResourceFragmentDoc}
`;
export const AnalyzeResultStatusFragmentDoc = gql`
  fragment AnalyzeResultStatus on AnalyzeResult {
    id
    status
    stage
    progress
  }
`;
export const ProductLocationIdFragmentDoc = gql`
  fragment ProductLocationId on ProductLocationId {
    consignee
    sku
    locationId
  }
`;
export const AnalyzeResultProductsCategoryFragmentDoc = gql`
  fragment AnalyzeResultProductsCategory on AnalyzeResultProductsCategory {
    skip
    count
    limit
    minBoundary
    maxBoundary
    minPercentRank
    maxPercentRank
    minCumulativePercentRank
    maxCumulativePercentRank
    totalHitCount
  }
`;
export const AnalyzeProductsByCategoryFragmentDoc = gql`
  fragment AnalyzeProductsByCategory on AnalyzeResultProductsByCategoryConnection {
    totalCount
    totalHitCount
    categories {
      ...AnalyzeResultProductsCategory
    }
  }
  ${AnalyzeResultProductsCategoryFragmentDoc}
`;
export const AnalyzeResultToCompareFragmentDoc = gql`
  fragment AnalyzeResultToCompare on AnalyzeResult {
    ...AnalyzeResult
    layout {
      id
      title
    }
    assignment {
      id
      title
    }
    orderSet {
      id
      title
    }
    itemSet {
      id
      title
    }
  }
  ${AnalyzeResultFragmentDoc}
`;
export const AnalyzeResultListItemFragmentDoc = gql`
  fragment AnalyzeResultListItem on AnalyzeResult {
    id
    createdAt
    updatedAt
  }
`;
export const SimulationAnalyzeListItemFragmentDoc = gql`
  fragment SimulationAnalyzeListItem on Simulation {
    id
    title
    layout {
      id
      title
    }
    createdAt
    updatedAt
    analyzeResult {
      ...AnalyzeResultListItem
    }
    latestAllocationRun {
      analyzeResult {
        ...AnalyzeResultListItem
      }
    }
    latestOptimizationRun {
      analyzeResult {
        ...AnalyzeResultListItem
      }
    }
  }
  ${AnalyzeResultListItemFragmentDoc}
`;
export const DatasetObjectFragmentDoc = gql`
  fragment DatasetObject on DatasetObject {
    id
    title
    description
    objectType
    userTags
    summary {
      generatedAt
      totalCount
      fields {
        ...DatasetObjectFieldSummary
      }
      rawDataFields {
        ...DatasetObjectRawDataFieldSummary
      }
    }
    importedAt
    createdAt
    updatedAt
  }
  ${DatasetObjectFieldSummaryFragmentDoc}
  ${DatasetObjectRawDataFieldSummaryFragmentDoc}
`;
export const DatasetObjectQueryResponseFragmentDoc = gql`
  fragment DatasetObjectQueryResponse on DatasetObjectQueryResponse {
    columns {
      name
      type
    }
    result
  }
`;
export const StackingPolicyItemFragmentDoc = gql`
  fragment StackingPolicyItem on AssignmentItemWithMatchingFilter {
    locationId
    itemId
    consignee
    sku
    matchingFilter
  }
`;
export const StackingPolicyStatsFragmentDoc = gql`
  fragment StackingPolicyStats on CountAssignmentItemsByMatchingFilterResult {
    matchesCount
    unmatchedCount
  }
`;
export const LayoutLocationWithMatchingFiltersFragmentDoc = gql`
  fragment LayoutLocationWithMatchingFilters on LayoutLocationWithMatchingFilters {
    locationId
    allMatchingFilters
  }
`;
export const GroupLocationsByFilterResultFragmentDoc = gql`
  fragment GroupLocationsByFilterResult on GroupLocationsByFilterResult {
    filterMatches {
      totalCount
    }
    unmatched {
      totalCount
    }
  }
`;
export const AisleFragmentDoc = gql`
  fragment Aisle on Aisle {
    id
    title
    nonNavigable
    points {
      ...Point
    }
  }
  ${PointFragmentDoc}
`;
export const VectorFragmentDoc = gql`
  fragment Vector on Vector {
    x
    y
  }
`;
export const BayLevelFragmentDoc = gql`
  fragment BayLevel on BayLevel {
    level
    levelHeight
    heightFromFloor
  }
`;
export const BayFragmentDoc = gql`
  fragment Bay on Bay {
    id
    title
    position {
      ...Point
    }
    frontEdge {
      ...Vector
    }
    width
    depth
    height
    locationOrder
    hasPass
    levels {
      ...BayLevel
    }
    bayType
  }
  ${PointFragmentDoc}
  ${VectorFragmentDoc}
  ${BayLevelFragmentDoc}
`;
export const LayoutDashboardItemFragmentDoc = gql`
  fragment LayoutDashboardItem on Layout {
    id
    title
    description
    createdAt
    updatedAt
    userTags
    importedFrom {
      id
      title
      inputFileName
    }
    summary {
      ...LayoutSummary
    }
    simulationsWith {
      ...SimulationsConnection
    }
  }
  ${LayoutSummaryFragmentDoc}
  ${SimulationsConnectionFragmentDoc}
`;
export const LayoutPLaneFragmentDoc = gql`
  fragment LayoutPLane on LayoutPlane {
    id
    title
    levels
    outlineShape
    boundingBox
    start {
      position {
        x
        y
      }
    }
  }
`;
export const BayTypeModelFragmentDoc = gql`
  fragment BayTypeModel on BayTypeModel {
    bayType
    verticalFrameProfile
    shelfHeight
    maxWeight
  }
`;
export const LocationTypeModelFragmentDoc = gql`
  fragment LocationTypeModel on LocationTypeModel {
    locationRackingType
    gapWidth
  }
`;
export const SegmentFragmentDoc = gql`
  fragment Segment on Segment {
    start {
      ...Point
    }
    end {
      ...Point
    }
  }
  ${PointFragmentDoc}
`;
export const Segment1DFragmentDoc = gql`
  fragment Segment1D on Segment1D {
    start
    end
  }
`;
export const PlaneConnectivityGraphFragmentDoc = gql`
  fragment PlaneConnectivityGraph on PlaneConnectivityGraph {
    id
    aislePortals {
      aisleId1
      aisleId2
      coords {
        ...Segment
      }
    }
    bayPortals {
      aisleId
      bayId
      coords {
        ...Segment
      }
      bayProjection {
        ...Segment1D
      }
    }
  }
  ${SegmentFragmentDoc}
  ${Segment1DFragmentDoc}
`;
export const LayoutConnectivityGraphFragmentDoc = gql`
  fragment LayoutConnectivityGraph on LayoutConnectivityGraph {
    planes {
      ...PlaneConnectivityGraph
    }
  }
  ${PlaneConnectivityGraphFragmentDoc}
`;
export const LayoutViewFragmentDoc = gql`
  fragment LayoutView on Layout {
    ...LayoutDashboardItem
    planes {
      ...LayoutPLane
    }
    layoutMap {
      bayTypes {
        ...BayTypeModel
      }
      locationTypes {
        ...LocationTypeModel
      }
    }
    connectivityGraph {
      ...LayoutConnectivityGraph
    }
  }
  ${LayoutDashboardItemFragmentDoc}
  ${LayoutPLaneFragmentDoc}
  ${BayTypeModelFragmentDoc}
  ${LocationTypeModelFragmentDoc}
  ${LayoutConnectivityGraphFragmentDoc}
`;
export const LayoutMetaFragmentDoc = gql`
  fragment LayoutMeta on Layout {
    id
    title
    status
    description
    createdAt
    updatedAt
  }
`;
export const LocationPortalDataFragmentDoc = gql`
  fragment LocationPortalData on LocationPortal {
    type
    aisle {
      id
      title
    }
  }
`;
export const LayoutLocationDataFragmentDoc = gql`
  fragment LayoutLocationData on LayoutLocation {
    locationId
    planeId
    locationUsageType
    warehouseArea
    locationBayId
    locationBayPosition
    locmhtype
    locationRackingType
    locationHeightFromFloor
    locationOrder
    locationStatus
    locationLevel
    locationLength
    locationWidth
    locationHeight
    locationWeight
    congestionZone
    bayType
    bay {
      id
      title
    }
    aisle {
      id
      title
    }
    portals {
      type
      aisle {
        id
        title
      }
    }
  }
`;
export const AisleFeatureDetailsFragmentDoc = gql`
  fragment AisleFeatureDetails on LayoutAisleDetails {
    aisleType
  }
`;
export const LayoutFeatureBaseFragmentDoc = gql`
  fragment LayoutFeatureBase on LayoutFeature {
    id
    type
    title
    navigable
    details {
      ... on LayoutAisleDetails {
        ...AisleFeatureDetails
      }
    }
  }
  ${AisleFeatureDetailsFragmentDoc}
`;
export const LayoutFeatureFragmentDoc = gql`
  fragment LayoutFeature on LayoutFeature {
    ...LayoutFeatureBase
    planeId
  }
  ${LayoutFeatureBaseFragmentDoc}
`;
export const AisleFeatureFragmentDoc = gql`
  fragment AisleFeature on LayoutFeature {
    ...LayoutFeatureBase
    shape
    details {
      ... on LayoutAisleDetails {
        ...AisleFeatureDetails
      }
    }
  }
  ${LayoutFeatureBaseFragmentDoc}
  ${AisleFeatureDetailsFragmentDoc}
`;
export const BayFeatureFragmentDoc = gql`
  fragment BayFeature on LayoutFeature {
    ...LayoutFeatureBase
    shape
    innerShape
    details {
      ... on LayoutBayDetails {
        bayType
      }
    }
  }
  ${LayoutFeatureBaseFragmentDoc}
`;
export const BayFeatureDetailsFragmentDoc = gql`
  fragment BayFeatureDetails on LayoutBayDetails {
    position {
      ...Point
    }
    frontEdge {
      ...Vector
    }
    levels {
      ...BayLevel
    }
    height
    width
    depth
    locationOrder
    bayType
  }
  ${PointFragmentDoc}
  ${VectorFragmentDoc}
  ${BayLevelFragmentDoc}
`;
export const BayFeatureDetailedFragmentDoc = gql`
  fragment BayFeatureDetailed on LayoutFeature {
    id
    type
    title
    navigable
    shape
    innerShape
    bayAisles {
      aisle {
        id
        title
      }
    }
    details {
      ... on LayoutBayDetails {
        ...BayFeatureDetails
      }
    }
  }
  ${BayFeatureDetailsFragmentDoc}
`;
export const AisleFeatureMetaFragmentDoc = gql`
  fragment AisleFeatureMeta on LayoutFeature {
    id
    planeId
    title
    shape
    details {
      __typename
      ... on LayoutAisleDetails {
        aisleType
      }
    }
  }
`;
export const LayoutLocationSelectionDataFragmentDoc = gql`
  fragment LayoutLocationSelectionData on LayoutLocation {
    locationId
    planeId
    locationBayId
    locationLevel
    shape
  }
`;
export const LayoutLocationsSummaryKeyFragmentDoc = gql`
  fragment LayoutLocationsSummaryKey on LayoutLocationsSummaryKey {
    locationStatus
    locationLevel
    locationRackingType
    warehouseArea
    locationSide
    aisleId
    planeId
    assigned
    accessAisleId
    consignee
    locationHeight
    locationLength
    locationWidth
  }
`;
export const LayoutLocationsSummaryFragmentDoc = gql`
  fragment LayoutLocationsSummary on LayoutLocationsSummary {
    key {
      ...LayoutLocationsSummaryKey
    }
    locationCount
    uoms {
      id
      uomCount
    }
  }
  ${LayoutLocationsSummaryKeyFragmentDoc}
`;
export const AnalyzedPicklistEventsFragmentDoc = gql`
  fragment AnalyzedPicklistEvents on AnalyzeResultPicklist {
    picklistId
    summary {
      agents {
        agent
        eventTypes {
          eventType
          totalDuration
        }
      }
    }
  }
`;
export const AnalyzedOrderEventsFragmentDoc = gql`
  fragment AnalyzedOrderEvents on AnalyzeResultOrder {
    orderId
    picklists {
      ...AnalyzedPicklistEvents
    }
  }
  ${AnalyzedPicklistEventsFragmentDoc}
`;
export const ResourceUsageSummaryFragmentDoc = gql`
  fragment ResourceUsageSummary on ResourceUsageSummary {
    key {
      pickByDate
      agent
      eventType
      orderLineRuleId
      picklistGroupKey
      planeId
    }
    totalPicklists
    totalPicklistLines
    totalEvents
    totalDuration
    totalCost
  }
`;
export const CreateAssignmentDocument = gql`
  mutation createAssignment($input: CreateAssignmentInput!) {
    createAssignment(input: $input) {
      id
    }
  }
`;
export type CreateAssignmentMutationFn = Apollo.MutationFunction<
  CreateAssignmentMutation,
  CreateAssignmentMutationVariables
>;

/**
 * __useCreateAssignmentMutation__
 *
 * To run a mutation, you first call `useCreateAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssignmentMutation, { data, loading, error }] = useCreateAssignmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAssignmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAssignmentMutation,
    CreateAssignmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAssignmentMutation,
    CreateAssignmentMutationVariables
  >(CreateAssignmentDocument, options);
}
export type CreateAssignmentMutationHookResult = ReturnType<
  typeof useCreateAssignmentMutation
>;
export type CreateAssignmentMutationResult =
  Apollo.MutationResult<CreateAssignmentMutation>;
export type CreateAssignmentMutationOptions = Apollo.BaseMutationOptions<
  CreateAssignmentMutation,
  CreateAssignmentMutationVariables
>;
export const UpdateAssignmentDocument = gql`
  mutation updateAssignment($assignmentInput: UpdateAssignmentInput!) {
    updateAssignment(input: $assignmentInput) {
      id
      description
    }
  }
`;
export type UpdateAssignmentMutationFn = Apollo.MutationFunction<
  UpdateAssignmentMutation,
  UpdateAssignmentMutationVariables
>;

/**
 * __useUpdateAssignmentMutation__
 *
 * To run a mutation, you first call `useUpdateAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssignmentMutation, { data, loading, error }] = useUpdateAssignmentMutation({
 *   variables: {
 *      assignmentInput: // value for 'assignmentInput'
 *   },
 * });
 */
export function useUpdateAssignmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAssignmentMutation,
    UpdateAssignmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAssignmentMutation,
    UpdateAssignmentMutationVariables
  >(UpdateAssignmentDocument, options);
}
export type UpdateAssignmentMutationHookResult = ReturnType<
  typeof useUpdateAssignmentMutation
>;
export type UpdateAssignmentMutationResult =
  Apollo.MutationResult<UpdateAssignmentMutation>;
export type UpdateAssignmentMutationOptions = Apollo.BaseMutationOptions<
  UpdateAssignmentMutation,
  UpdateAssignmentMutationVariables
>;
export const RemoveAssignmentDocument = gql`
  mutation removeAssignment($id: ID!) {
    removeAssignment(id: $id)
  }
`;
export type RemoveAssignmentMutationFn = Apollo.MutationFunction<
  RemoveAssignmentMutation,
  RemoveAssignmentMutationVariables
>;

/**
 * __useRemoveAssignmentMutation__
 *
 * To run a mutation, you first call `useRemoveAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAssignmentMutation, { data, loading, error }] = useRemoveAssignmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveAssignmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveAssignmentMutation,
    RemoveAssignmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveAssignmentMutation,
    RemoveAssignmentMutationVariables
  >(RemoveAssignmentDocument, options);
}
export type RemoveAssignmentMutationHookResult = ReturnType<
  typeof useRemoveAssignmentMutation
>;
export type RemoveAssignmentMutationResult =
  Apollo.MutationResult<RemoveAssignmentMutation>;
export type RemoveAssignmentMutationOptions = Apollo.BaseMutationOptions<
  RemoveAssignmentMutation,
  RemoveAssignmentMutationVariables
>;
export const LoadAllAssignmentsMetaDocument = gql`
  query loadAllAssignmentsMeta($warehouseId: ID!) {
    warehouse(id: $warehouseId) {
      assignments(
        page: { limit: null }
        filter: { status: [IMPORTED, COMPUTED, REASSIGNED] }
      ) {
        content {
          ...AssignmentMeta
        }
      }
    }
  }
  ${AssignmentMetaFragmentDoc}
`;

/**
 * __useLoadAllAssignmentsMetaQuery__
 *
 * To run a query within a React component, call `useLoadAllAssignmentsMetaQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadAllAssignmentsMetaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadAllAssignmentsMetaQuery({
 *   variables: {
 *      warehouseId: // value for 'warehouseId'
 *   },
 * });
 */
export function useLoadAllAssignmentsMetaQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadAllAssignmentsMetaQuery,
    LoadAllAssignmentsMetaQueryVariables
  > &
    (
      | { variables: LoadAllAssignmentsMetaQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadAllAssignmentsMetaQuery,
    LoadAllAssignmentsMetaQueryVariables
  >(LoadAllAssignmentsMetaDocument, options);
}
export function useLoadAllAssignmentsMetaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadAllAssignmentsMetaQuery,
    LoadAllAssignmentsMetaQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadAllAssignmentsMetaQuery,
    LoadAllAssignmentsMetaQueryVariables
  >(LoadAllAssignmentsMetaDocument, options);
}
export function useLoadAllAssignmentsMetaSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadAllAssignmentsMetaQuery,
        LoadAllAssignmentsMetaQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadAllAssignmentsMetaQuery,
    LoadAllAssignmentsMetaQueryVariables
  >(LoadAllAssignmentsMetaDocument, options);
}
export type LoadAllAssignmentsMetaQueryHookResult = ReturnType<
  typeof useLoadAllAssignmentsMetaQuery
>;
export type LoadAllAssignmentsMetaLazyQueryHookResult = ReturnType<
  typeof useLoadAllAssignmentsMetaLazyQuery
>;
export type LoadAllAssignmentsMetaSuspenseQueryHookResult = ReturnType<
  typeof useLoadAllAssignmentsMetaSuspenseQuery
>;
export type LoadAllAssignmentsMetaQueryResult = Apollo.QueryResult<
  LoadAllAssignmentsMetaQuery,
  LoadAllAssignmentsMetaQueryVariables
>;
export const LoadAssignmentSummaryDocument = gql`
  query loadAssignmentSummary($assignmentId: ID!) {
    assignment(id: $assignmentId) {
      ...AssignmentMeta
      summary {
        ...AssignmentSummary
      }
    }
  }
  ${AssignmentMetaFragmentDoc}
  ${AssignmentSummaryFragmentDoc}
`;

/**
 * __useLoadAssignmentSummaryQuery__
 *
 * To run a query within a React component, call `useLoadAssignmentSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadAssignmentSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadAssignmentSummaryQuery({
 *   variables: {
 *      assignmentId: // value for 'assignmentId'
 *   },
 * });
 */
export function useLoadAssignmentSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadAssignmentSummaryQuery,
    LoadAssignmentSummaryQueryVariables
  > &
    (
      | { variables: LoadAssignmentSummaryQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadAssignmentSummaryQuery,
    LoadAssignmentSummaryQueryVariables
  >(LoadAssignmentSummaryDocument, options);
}
export function useLoadAssignmentSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadAssignmentSummaryQuery,
    LoadAssignmentSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadAssignmentSummaryQuery,
    LoadAssignmentSummaryQueryVariables
  >(LoadAssignmentSummaryDocument, options);
}
export function useLoadAssignmentSummarySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadAssignmentSummaryQuery,
        LoadAssignmentSummaryQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadAssignmentSummaryQuery,
    LoadAssignmentSummaryQueryVariables
  >(LoadAssignmentSummaryDocument, options);
}
export type LoadAssignmentSummaryQueryHookResult = ReturnType<
  typeof useLoadAssignmentSummaryQuery
>;
export type LoadAssignmentSummaryLazyQueryHookResult = ReturnType<
  typeof useLoadAssignmentSummaryLazyQuery
>;
export type LoadAssignmentSummarySuspenseQueryHookResult = ReturnType<
  typeof useLoadAssignmentSummarySuspenseQuery
>;
export type LoadAssignmentSummaryQueryResult = Apollo.QueryResult<
  LoadAssignmentSummaryQuery,
  LoadAssignmentSummaryQueryVariables
>;
export const LoadAssignmentDataDocument = gql`
  query loadAssignmentData(
    $assignmentId: ID!
    $page: SkipLimitPageSpec
    $filter: AssignmentItemsSearchFilter
    $sortBy: [AssignmentItemsSortBy!]
  ) {
    assignment(id: $assignmentId) {
      ...AssignmentMeta
      summary {
        ...AssignmentSummary
      }
      items(page: $page, filter: $filter, sortBy: $sortBy) {
        totalCount
        content {
          ...AssignmentItemData
        }
      }
    }
  }
  ${AssignmentMetaFragmentDoc}
  ${AssignmentSummaryFragmentDoc}
  ${AssignmentItemDataFragmentDoc}
`;

/**
 * __useLoadAssignmentDataQuery__
 *
 * To run a query within a React component, call `useLoadAssignmentDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadAssignmentDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadAssignmentDataQuery({
 *   variables: {
 *      assignmentId: // value for 'assignmentId'
 *      page: // value for 'page'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useLoadAssignmentDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadAssignmentDataQuery,
    LoadAssignmentDataQueryVariables
  > &
    (
      | { variables: LoadAssignmentDataQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadAssignmentDataQuery,
    LoadAssignmentDataQueryVariables
  >(LoadAssignmentDataDocument, options);
}
export function useLoadAssignmentDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadAssignmentDataQuery,
    LoadAssignmentDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadAssignmentDataQuery,
    LoadAssignmentDataQueryVariables
  >(LoadAssignmentDataDocument, options);
}
export function useLoadAssignmentDataSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadAssignmentDataQuery,
        LoadAssignmentDataQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadAssignmentDataQuery,
    LoadAssignmentDataQueryVariables
  >(LoadAssignmentDataDocument, options);
}
export type LoadAssignmentDataQueryHookResult = ReturnType<
  typeof useLoadAssignmentDataQuery
>;
export type LoadAssignmentDataLazyQueryHookResult = ReturnType<
  typeof useLoadAssignmentDataLazyQuery
>;
export type LoadAssignmentDataSuspenseQueryHookResult = ReturnType<
  typeof useLoadAssignmentDataSuspenseQuery
>;
export type LoadAssignmentDataQueryResult = Apollo.QueryResult<
  LoadAssignmentDataQuery,
  LoadAssignmentDataQueryVariables
>;
export const LoadAssignmentDocument = gql`
  query loadAssignment($assignmentId: ID!) {
    assignment(id: $assignmentId) {
      ...Assignment
    }
  }
  ${AssignmentFragmentDoc}
`;

/**
 * __useLoadAssignmentQuery__
 *
 * To run a query within a React component, call `useLoadAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadAssignmentQuery({
 *   variables: {
 *      assignmentId: // value for 'assignmentId'
 *   },
 * });
 */
export function useLoadAssignmentQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadAssignmentQuery,
    LoadAssignmentQueryVariables
  > &
    (
      | { variables: LoadAssignmentQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadAssignmentQuery, LoadAssignmentQueryVariables>(
    LoadAssignmentDocument,
    options,
  );
}
export function useLoadAssignmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadAssignmentQuery,
    LoadAssignmentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LoadAssignmentQuery, LoadAssignmentQueryVariables>(
    LoadAssignmentDocument,
    options,
  );
}
export function useLoadAssignmentSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadAssignmentQuery,
        LoadAssignmentQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadAssignmentQuery,
    LoadAssignmentQueryVariables
  >(LoadAssignmentDocument, options);
}
export type LoadAssignmentQueryHookResult = ReturnType<
  typeof useLoadAssignmentQuery
>;
export type LoadAssignmentLazyQueryHookResult = ReturnType<
  typeof useLoadAssignmentLazyQuery
>;
export type LoadAssignmentSuspenseQueryHookResult = ReturnType<
  typeof useLoadAssignmentSuspenseQuery
>;
export type LoadAssignmentQueryResult = Apollo.QueryResult<
  LoadAssignmentQuery,
  LoadAssignmentQueryVariables
>;
export const LoadAssignmentComplianceSummaryDocument = gql`
  query loadAssignmentComplianceSummary($simulationId: ID!, $assignmentId: ID) {
    simulation(id: $simulationId) {
      assignmentCompliance(filter: { assignmentId: $assignmentId }) {
        ...AssignmentComplianceSummary
      }
    }
  }
  ${AssignmentComplianceSummaryFragmentDoc}
`;

/**
 * __useLoadAssignmentComplianceSummaryQuery__
 *
 * To run a query within a React component, call `useLoadAssignmentComplianceSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadAssignmentComplianceSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadAssignmentComplianceSummaryQuery({
 *   variables: {
 *      simulationId: // value for 'simulationId'
 *      assignmentId: // value for 'assignmentId'
 *   },
 * });
 */
export function useLoadAssignmentComplianceSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadAssignmentComplianceSummaryQuery,
    LoadAssignmentComplianceSummaryQueryVariables
  > &
    (
      | {
          variables: LoadAssignmentComplianceSummaryQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadAssignmentComplianceSummaryQuery,
    LoadAssignmentComplianceSummaryQueryVariables
  >(LoadAssignmentComplianceSummaryDocument, options);
}
export function useLoadAssignmentComplianceSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadAssignmentComplianceSummaryQuery,
    LoadAssignmentComplianceSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadAssignmentComplianceSummaryQuery,
    LoadAssignmentComplianceSummaryQueryVariables
  >(LoadAssignmentComplianceSummaryDocument, options);
}
export function useLoadAssignmentComplianceSummarySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadAssignmentComplianceSummaryQuery,
        LoadAssignmentComplianceSummaryQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadAssignmentComplianceSummaryQuery,
    LoadAssignmentComplianceSummaryQueryVariables
  >(LoadAssignmentComplianceSummaryDocument, options);
}
export type LoadAssignmentComplianceSummaryQueryHookResult = ReturnType<
  typeof useLoadAssignmentComplianceSummaryQuery
>;
export type LoadAssignmentComplianceSummaryLazyQueryHookResult = ReturnType<
  typeof useLoadAssignmentComplianceSummaryLazyQuery
>;
export type LoadAssignmentComplianceSummarySuspenseQueryHookResult = ReturnType<
  typeof useLoadAssignmentComplianceSummarySuspenseQuery
>;
export type LoadAssignmentComplianceSummaryQueryResult = Apollo.QueryResult<
  LoadAssignmentComplianceSummaryQuery,
  LoadAssignmentComplianceSummaryQueryVariables
>;
export const RunCalculateAssignmentComplianceDocument = gql`
  mutation runCalculateAssignmentCompliance(
    $simulationId: String!
    $assignmentId: ID
  ) {
    calculateSimulationAssignmentCompliance(
      simulationId: $simulationId
      options: { assignmentId: $assignmentId }
    ) {
      ...AssignmentComplianceSummary
    }
  }
  ${AssignmentComplianceSummaryFragmentDoc}
`;
export type RunCalculateAssignmentComplianceMutationFn =
  Apollo.MutationFunction<
    RunCalculateAssignmentComplianceMutation,
    RunCalculateAssignmentComplianceMutationVariables
  >;

/**
 * __useRunCalculateAssignmentComplianceMutation__
 *
 * To run a mutation, you first call `useRunCalculateAssignmentComplianceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunCalculateAssignmentComplianceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runCalculateAssignmentComplianceMutation, { data, loading, error }] = useRunCalculateAssignmentComplianceMutation({
 *   variables: {
 *      simulationId: // value for 'simulationId'
 *      assignmentId: // value for 'assignmentId'
 *   },
 * });
 */
export function useRunCalculateAssignmentComplianceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RunCalculateAssignmentComplianceMutation,
    RunCalculateAssignmentComplianceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RunCalculateAssignmentComplianceMutation,
    RunCalculateAssignmentComplianceMutationVariables
  >(RunCalculateAssignmentComplianceDocument, options);
}
export type RunCalculateAssignmentComplianceMutationHookResult = ReturnType<
  typeof useRunCalculateAssignmentComplianceMutation
>;
export type RunCalculateAssignmentComplianceMutationResult =
  Apollo.MutationResult<RunCalculateAssignmentComplianceMutation>;
export type RunCalculateAssignmentComplianceMutationOptions =
  Apollo.BaseMutationOptions<
    RunCalculateAssignmentComplianceMutation,
    RunCalculateAssignmentComplianceMutationVariables
  >;
export const LoadAssignmentComplianceLocationsDocument = gql`
  query loadAssignmentComplianceLocations(
    $simulationId: ID!
    $assignmentId: ID
    $filter: AssignmentComplianceItemFilter
  ) {
    simulation(id: $simulationId) {
      assignmentCompliance(filter: { assignmentId: $assignmentId }) {
        id
        assignmentId
        items(filter: $filter, page: null) {
          totalCount
          content {
            ...AssignmentComplianceItem
          }
        }
      }
    }
  }
  ${AssignmentComplianceItemFragmentDoc}
`;

/**
 * __useLoadAssignmentComplianceLocationsQuery__
 *
 * To run a query within a React component, call `useLoadAssignmentComplianceLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadAssignmentComplianceLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadAssignmentComplianceLocationsQuery({
 *   variables: {
 *      simulationId: // value for 'simulationId'
 *      assignmentId: // value for 'assignmentId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useLoadAssignmentComplianceLocationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadAssignmentComplianceLocationsQuery,
    LoadAssignmentComplianceLocationsQueryVariables
  > &
    (
      | {
          variables: LoadAssignmentComplianceLocationsQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadAssignmentComplianceLocationsQuery,
    LoadAssignmentComplianceLocationsQueryVariables
  >(LoadAssignmentComplianceLocationsDocument, options);
}
export function useLoadAssignmentComplianceLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadAssignmentComplianceLocationsQuery,
    LoadAssignmentComplianceLocationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadAssignmentComplianceLocationsQuery,
    LoadAssignmentComplianceLocationsQueryVariables
  >(LoadAssignmentComplianceLocationsDocument, options);
}
export function useLoadAssignmentComplianceLocationsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadAssignmentComplianceLocationsQuery,
        LoadAssignmentComplianceLocationsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadAssignmentComplianceLocationsQuery,
    LoadAssignmentComplianceLocationsQueryVariables
  >(LoadAssignmentComplianceLocationsDocument, options);
}
export type LoadAssignmentComplianceLocationsQueryHookResult = ReturnType<
  typeof useLoadAssignmentComplianceLocationsQuery
>;
export type LoadAssignmentComplianceLocationsLazyQueryHookResult = ReturnType<
  typeof useLoadAssignmentComplianceLocationsLazyQuery
>;
export type LoadAssignmentComplianceLocationsSuspenseQueryHookResult =
  ReturnType<typeof useLoadAssignmentComplianceLocationsSuspenseQuery>;
export type LoadAssignmentComplianceLocationsQueryResult = Apollo.QueryResult<
  LoadAssignmentComplianceLocationsQuery,
  LoadAssignmentComplianceLocationsQueryVariables
>;
export const LoadAssignmentComplianceItemsDocument = gql`
  query loadAssignmentComplianceItems(
    $simulationId: ID!
    $assignmentId: ID
    $filter: AssignmentComplianceItemFilter
    $sortBy: [AssignmentComplianceItemSortBy!]
    $page: SkipLimitPageSpec
  ) {
    simulation(id: $simulationId) {
      assignmentCompliance(filter: { assignmentId: $assignmentId }) {
        id
        assignmentId
        items(filter: $filter, page: $page, sortBy: $sortBy) {
          ...AssignmentComplianceData
        }
      }
    }
  }
  ${AssignmentComplianceDataFragmentDoc}
`;

/**
 * __useLoadAssignmentComplianceItemsQuery__
 *
 * To run a query within a React component, call `useLoadAssignmentComplianceItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadAssignmentComplianceItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadAssignmentComplianceItemsQuery({
 *   variables: {
 *      simulationId: // value for 'simulationId'
 *      assignmentId: // value for 'assignmentId'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useLoadAssignmentComplianceItemsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadAssignmentComplianceItemsQuery,
    LoadAssignmentComplianceItemsQueryVariables
  > &
    (
      | {
          variables: LoadAssignmentComplianceItemsQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadAssignmentComplianceItemsQuery,
    LoadAssignmentComplianceItemsQueryVariables
  >(LoadAssignmentComplianceItemsDocument, options);
}
export function useLoadAssignmentComplianceItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadAssignmentComplianceItemsQuery,
    LoadAssignmentComplianceItemsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadAssignmentComplianceItemsQuery,
    LoadAssignmentComplianceItemsQueryVariables
  >(LoadAssignmentComplianceItemsDocument, options);
}
export function useLoadAssignmentComplianceItemsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadAssignmentComplianceItemsQuery,
        LoadAssignmentComplianceItemsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadAssignmentComplianceItemsQuery,
    LoadAssignmentComplianceItemsQueryVariables
  >(LoadAssignmentComplianceItemsDocument, options);
}
export type LoadAssignmentComplianceItemsQueryHookResult = ReturnType<
  typeof useLoadAssignmentComplianceItemsQuery
>;
export type LoadAssignmentComplianceItemsLazyQueryHookResult = ReturnType<
  typeof useLoadAssignmentComplianceItemsLazyQuery
>;
export type LoadAssignmentComplianceItemsSuspenseQueryHookResult = ReturnType<
  typeof useLoadAssignmentComplianceItemsSuspenseQuery
>;
export type LoadAssignmentComplianceItemsQueryResult = Apollo.QueryResult<
  LoadAssignmentComplianceItemsQuery,
  LoadAssignmentComplianceItemsQueryVariables
>;
export const LoadAssignmentOccupancyLocationsDocument = gql`
  query loadAssignmentOccupancyLocations(
    $simulationId: ID!
    $assignmentId: ID!
    $filter: AssignmentOccupancyLocationFilter
    $sortBy: [AssignmentOccupancyLocationSortBy!]
    $page: SkipLimitPageSpec
  ) {
    simulation(id: $simulationId) {
      assignmentOccupancy(filter: { assignmentId: $assignmentId }) {
        locations(sortBy: $sortBy, page: $page, filter: $filter) {
          ...AssignmentOccupancyData
        }
      }
    }
  }
  ${AssignmentOccupancyDataFragmentDoc}
`;

/**
 * __useLoadAssignmentOccupancyLocationsQuery__
 *
 * To run a query within a React component, call `useLoadAssignmentOccupancyLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadAssignmentOccupancyLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadAssignmentOccupancyLocationsQuery({
 *   variables: {
 *      simulationId: // value for 'simulationId'
 *      assignmentId: // value for 'assignmentId'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useLoadAssignmentOccupancyLocationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadAssignmentOccupancyLocationsQuery,
    LoadAssignmentOccupancyLocationsQueryVariables
  > &
    (
      | {
          variables: LoadAssignmentOccupancyLocationsQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadAssignmentOccupancyLocationsQuery,
    LoadAssignmentOccupancyLocationsQueryVariables
  >(LoadAssignmentOccupancyLocationsDocument, options);
}
export function useLoadAssignmentOccupancyLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadAssignmentOccupancyLocationsQuery,
    LoadAssignmentOccupancyLocationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadAssignmentOccupancyLocationsQuery,
    LoadAssignmentOccupancyLocationsQueryVariables
  >(LoadAssignmentOccupancyLocationsDocument, options);
}
export function useLoadAssignmentOccupancyLocationsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadAssignmentOccupancyLocationsQuery,
        LoadAssignmentOccupancyLocationsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadAssignmentOccupancyLocationsQuery,
    LoadAssignmentOccupancyLocationsQueryVariables
  >(LoadAssignmentOccupancyLocationsDocument, options);
}
export type LoadAssignmentOccupancyLocationsQueryHookResult = ReturnType<
  typeof useLoadAssignmentOccupancyLocationsQuery
>;
export type LoadAssignmentOccupancyLocationsLazyQueryHookResult = ReturnType<
  typeof useLoadAssignmentOccupancyLocationsLazyQuery
>;
export type LoadAssignmentOccupancyLocationsSuspenseQueryHookResult =
  ReturnType<typeof useLoadAssignmentOccupancyLocationsSuspenseQuery>;
export type LoadAssignmentOccupancyLocationsQueryResult = Apollo.QueryResult<
  LoadAssignmentOccupancyLocationsQuery,
  LoadAssignmentOccupancyLocationsQueryVariables
>;
export const LoadAssignmentOccupancyForLocationsStatusOnlyDocument = gql`
  query loadAssignmentOccupancyForLocationsStatusOnly(
    $simulationId: ID!
    $assignmentId: ID!
    $filter: AssignmentOccupancyLocationFilter
    $sortBy: [AssignmentOccupancyLocationSortBy!]
    $page: SkipLimitPageSpec
  ) {
    simulation(id: $simulationId) {
      assignmentOccupancy(filter: { assignmentId: $assignmentId }) {
        locations(sortBy: $sortBy, page: $page, filter: $filter) {
          totalCount
          content {
            ...AssignmentOccupancyLocationStatus
          }
        }
      }
    }
  }
  ${AssignmentOccupancyLocationStatusFragmentDoc}
`;

/**
 * __useLoadAssignmentOccupancyForLocationsStatusOnlyQuery__
 *
 * To run a query within a React component, call `useLoadAssignmentOccupancyForLocationsStatusOnlyQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadAssignmentOccupancyForLocationsStatusOnlyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadAssignmentOccupancyForLocationsStatusOnlyQuery({
 *   variables: {
 *      simulationId: // value for 'simulationId'
 *      assignmentId: // value for 'assignmentId'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useLoadAssignmentOccupancyForLocationsStatusOnlyQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadAssignmentOccupancyForLocationsStatusOnlyQuery,
    LoadAssignmentOccupancyForLocationsStatusOnlyQueryVariables
  > &
    (
      | {
          variables: LoadAssignmentOccupancyForLocationsStatusOnlyQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadAssignmentOccupancyForLocationsStatusOnlyQuery,
    LoadAssignmentOccupancyForLocationsStatusOnlyQueryVariables
  >(LoadAssignmentOccupancyForLocationsStatusOnlyDocument, options);
}
export function useLoadAssignmentOccupancyForLocationsStatusOnlyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadAssignmentOccupancyForLocationsStatusOnlyQuery,
    LoadAssignmentOccupancyForLocationsStatusOnlyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadAssignmentOccupancyForLocationsStatusOnlyQuery,
    LoadAssignmentOccupancyForLocationsStatusOnlyQueryVariables
  >(LoadAssignmentOccupancyForLocationsStatusOnlyDocument, options);
}
export function useLoadAssignmentOccupancyForLocationsStatusOnlySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadAssignmentOccupancyForLocationsStatusOnlyQuery,
        LoadAssignmentOccupancyForLocationsStatusOnlyQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadAssignmentOccupancyForLocationsStatusOnlyQuery,
    LoadAssignmentOccupancyForLocationsStatusOnlyQueryVariables
  >(LoadAssignmentOccupancyForLocationsStatusOnlyDocument, options);
}
export type LoadAssignmentOccupancyForLocationsStatusOnlyQueryHookResult =
  ReturnType<typeof useLoadAssignmentOccupancyForLocationsStatusOnlyQuery>;
export type LoadAssignmentOccupancyForLocationsStatusOnlyLazyQueryHookResult =
  ReturnType<typeof useLoadAssignmentOccupancyForLocationsStatusOnlyLazyQuery>;
export type LoadAssignmentOccupancyForLocationsStatusOnlySuspenseQueryHookResult =
  ReturnType<
    typeof useLoadAssignmentOccupancyForLocationsStatusOnlySuspenseQuery
  >;
export type LoadAssignmentOccupancyForLocationsStatusOnlyQueryResult =
  Apollo.QueryResult<
    LoadAssignmentOccupancyForLocationsStatusOnlyQuery,
    LoadAssignmentOccupancyForLocationsStatusOnlyQueryVariables
  >;
export const LoadAssignmentOccupancyMetaDocument = gql`
  query loadAssignmentOccupancyMeta($simulationId: ID!, $assignmentId: ID!) {
    simulation(id: $simulationId) {
      assignmentOccupancy(filter: { assignmentId: $assignmentId }) {
        ...AssignmentOccupancyMeta
      }
    }
  }
  ${AssignmentOccupancyMetaFragmentDoc}
`;

/**
 * __useLoadAssignmentOccupancyMetaQuery__
 *
 * To run a query within a React component, call `useLoadAssignmentOccupancyMetaQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadAssignmentOccupancyMetaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadAssignmentOccupancyMetaQuery({
 *   variables: {
 *      simulationId: // value for 'simulationId'
 *      assignmentId: // value for 'assignmentId'
 *   },
 * });
 */
export function useLoadAssignmentOccupancyMetaQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadAssignmentOccupancyMetaQuery,
    LoadAssignmentOccupancyMetaQueryVariables
  > &
    (
      | { variables: LoadAssignmentOccupancyMetaQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadAssignmentOccupancyMetaQuery,
    LoadAssignmentOccupancyMetaQueryVariables
  >(LoadAssignmentOccupancyMetaDocument, options);
}
export function useLoadAssignmentOccupancyMetaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadAssignmentOccupancyMetaQuery,
    LoadAssignmentOccupancyMetaQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadAssignmentOccupancyMetaQuery,
    LoadAssignmentOccupancyMetaQueryVariables
  >(LoadAssignmentOccupancyMetaDocument, options);
}
export function useLoadAssignmentOccupancyMetaSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadAssignmentOccupancyMetaQuery,
        LoadAssignmentOccupancyMetaQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadAssignmentOccupancyMetaQuery,
    LoadAssignmentOccupancyMetaQueryVariables
  >(LoadAssignmentOccupancyMetaDocument, options);
}
export type LoadAssignmentOccupancyMetaQueryHookResult = ReturnType<
  typeof useLoadAssignmentOccupancyMetaQuery
>;
export type LoadAssignmentOccupancyMetaLazyQueryHookResult = ReturnType<
  typeof useLoadAssignmentOccupancyMetaLazyQuery
>;
export type LoadAssignmentOccupancyMetaSuspenseQueryHookResult = ReturnType<
  typeof useLoadAssignmentOccupancyMetaSuspenseQuery
>;
export type LoadAssignmentOccupancyMetaQueryResult = Apollo.QueryResult<
  LoadAssignmentOccupancyMetaQuery,
  LoadAssignmentOccupancyMetaQueryVariables
>;
export const LoadAssignmentOccupancyByGroupDocument = gql`
  query loadAssignmentOccupancyByGroup(
    $simulationId: ID!
    $assignmentId: ID!
    $filter: AssignmentOccupancyLocationFilter
    $groupBy: [AssignmentOccupancySummaryGroupBy!]
  ) {
    simulation(id: $simulationId) {
      assignmentOccupancy(filter: { assignmentId: $assignmentId }) {
        summaryByGroup(groupBy: $groupBy, filter: $filter) {
          ...AssignmentOccupancySummaryGroup
        }
      }
    }
  }
  ${AssignmentOccupancySummaryGroupFragmentDoc}
`;

/**
 * __useLoadAssignmentOccupancyByGroupQuery__
 *
 * To run a query within a React component, call `useLoadAssignmentOccupancyByGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadAssignmentOccupancyByGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadAssignmentOccupancyByGroupQuery({
 *   variables: {
 *      simulationId: // value for 'simulationId'
 *      assignmentId: // value for 'assignmentId'
 *      filter: // value for 'filter'
 *      groupBy: // value for 'groupBy'
 *   },
 * });
 */
export function useLoadAssignmentOccupancyByGroupQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadAssignmentOccupancyByGroupQuery,
    LoadAssignmentOccupancyByGroupQueryVariables
  > &
    (
      | {
          variables: LoadAssignmentOccupancyByGroupQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadAssignmentOccupancyByGroupQuery,
    LoadAssignmentOccupancyByGroupQueryVariables
  >(LoadAssignmentOccupancyByGroupDocument, options);
}
export function useLoadAssignmentOccupancyByGroupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadAssignmentOccupancyByGroupQuery,
    LoadAssignmentOccupancyByGroupQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadAssignmentOccupancyByGroupQuery,
    LoadAssignmentOccupancyByGroupQueryVariables
  >(LoadAssignmentOccupancyByGroupDocument, options);
}
export function useLoadAssignmentOccupancyByGroupSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadAssignmentOccupancyByGroupQuery,
        LoadAssignmentOccupancyByGroupQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadAssignmentOccupancyByGroupQuery,
    LoadAssignmentOccupancyByGroupQueryVariables
  >(LoadAssignmentOccupancyByGroupDocument, options);
}
export type LoadAssignmentOccupancyByGroupQueryHookResult = ReturnType<
  typeof useLoadAssignmentOccupancyByGroupQuery
>;
export type LoadAssignmentOccupancyByGroupLazyQueryHookResult = ReturnType<
  typeof useLoadAssignmentOccupancyByGroupLazyQuery
>;
export type LoadAssignmentOccupancyByGroupSuspenseQueryHookResult = ReturnType<
  typeof useLoadAssignmentOccupancyByGroupSuspenseQuery
>;
export type LoadAssignmentOccupancyByGroupQueryResult = Apollo.QueryResult<
  LoadAssignmentOccupancyByGroupQuery,
  LoadAssignmentOccupancyByGroupQueryVariables
>;
export const RunCalculateSimulationAssignmentOccupancyDocument = gql`
  mutation runCalculateSimulationAssignmentOccupancy(
    $simulationId: String!
    $assignmentId: ID!
  ) {
    calculateSimulationAssignmentOccupancy(
      simulationId: $simulationId
      options: { assignmentId: $assignmentId }
    ) {
      ...AssignmentOccupancyMeta
    }
  }
  ${AssignmentOccupancyMetaFragmentDoc}
`;
export type RunCalculateSimulationAssignmentOccupancyMutationFn =
  Apollo.MutationFunction<
    RunCalculateSimulationAssignmentOccupancyMutation,
    RunCalculateSimulationAssignmentOccupancyMutationVariables
  >;

/**
 * __useRunCalculateSimulationAssignmentOccupancyMutation__
 *
 * To run a mutation, you first call `useRunCalculateSimulationAssignmentOccupancyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunCalculateSimulationAssignmentOccupancyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runCalculateSimulationAssignmentOccupancyMutation, { data, loading, error }] = useRunCalculateSimulationAssignmentOccupancyMutation({
 *   variables: {
 *      simulationId: // value for 'simulationId'
 *      assignmentId: // value for 'assignmentId'
 *   },
 * });
 */
export function useRunCalculateSimulationAssignmentOccupancyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RunCalculateSimulationAssignmentOccupancyMutation,
    RunCalculateSimulationAssignmentOccupancyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RunCalculateSimulationAssignmentOccupancyMutation,
    RunCalculateSimulationAssignmentOccupancyMutationVariables
  >(RunCalculateSimulationAssignmentOccupancyDocument, options);
}
export type RunCalculateSimulationAssignmentOccupancyMutationHookResult =
  ReturnType<typeof useRunCalculateSimulationAssignmentOccupancyMutation>;
export type RunCalculateSimulationAssignmentOccupancyMutationResult =
  Apollo.MutationResult<RunCalculateSimulationAssignmentOccupancyMutation>;
export type RunCalculateSimulationAssignmentOccupancyMutationOptions =
  Apollo.BaseMutationOptions<
    RunCalculateSimulationAssignmentOccupancyMutation,
    RunCalculateSimulationAssignmentOccupancyMutationVariables
  >;
export const LoadAssignmentOccupancyDocument = gql`
  query loadAssignmentOccupancy(
    $simulationId: ID!
    $assignmentId: ID!
    $categoryWidths: [Float!]!
    $filter: AssignmentOccupancyLocationFilter
  ) {
    simulation(id: $simulationId) {
      assignmentOccupancy(filter: { assignmentId: $assignmentId }) {
        ...AssignmentOccupancy
      }
    }
  }
  ${AssignmentOccupancyFragmentDoc}
`;

/**
 * __useLoadAssignmentOccupancyQuery__
 *
 * To run a query within a React component, call `useLoadAssignmentOccupancyQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadAssignmentOccupancyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadAssignmentOccupancyQuery({
 *   variables: {
 *      simulationId: // value for 'simulationId'
 *      assignmentId: // value for 'assignmentId'
 *      categoryWidths: // value for 'categoryWidths'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useLoadAssignmentOccupancyQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadAssignmentOccupancyQuery,
    LoadAssignmentOccupancyQueryVariables
  > &
    (
      | { variables: LoadAssignmentOccupancyQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadAssignmentOccupancyQuery,
    LoadAssignmentOccupancyQueryVariables
  >(LoadAssignmentOccupancyDocument, options);
}
export function useLoadAssignmentOccupancyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadAssignmentOccupancyQuery,
    LoadAssignmentOccupancyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadAssignmentOccupancyQuery,
    LoadAssignmentOccupancyQueryVariables
  >(LoadAssignmentOccupancyDocument, options);
}
export function useLoadAssignmentOccupancySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadAssignmentOccupancyQuery,
        LoadAssignmentOccupancyQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadAssignmentOccupancyQuery,
    LoadAssignmentOccupancyQueryVariables
  >(LoadAssignmentOccupancyDocument, options);
}
export type LoadAssignmentOccupancyQueryHookResult = ReturnType<
  typeof useLoadAssignmentOccupancyQuery
>;
export type LoadAssignmentOccupancyLazyQueryHookResult = ReturnType<
  typeof useLoadAssignmentOccupancyLazyQuery
>;
export type LoadAssignmentOccupancySuspenseQueryHookResult = ReturnType<
  typeof useLoadAssignmentOccupancySuspenseQuery
>;
export type LoadAssignmentOccupancyQueryResult = Apollo.QueryResult<
  LoadAssignmentOccupancyQuery,
  LoadAssignmentOccupancyQueryVariables
>;
export const FindProductFilterValuesDocument = gql`
  query findProductFilterValues(
    $input: FindSimulationItemFilterValuesInput!
    $page: SkipLimitPageSpec
  ) {
    findSimulationItemFilterValues(input: $input, page: $page) {
      ...FilterValuesConnection
    }
  }
  ${FilterValuesConnectionFragmentDoc}
`;

/**
 * __useFindProductFilterValuesQuery__
 *
 * To run a query within a React component, call `useFindProductFilterValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindProductFilterValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindProductFilterValuesQuery({
 *   variables: {
 *      input: // value for 'input'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useFindProductFilterValuesQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindProductFilterValuesQuery,
    FindProductFilterValuesQueryVariables
  > &
    (
      | { variables: FindProductFilterValuesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindProductFilterValuesQuery,
    FindProductFilterValuesQueryVariables
  >(FindProductFilterValuesDocument, options);
}
export function useFindProductFilterValuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindProductFilterValuesQuery,
    FindProductFilterValuesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindProductFilterValuesQuery,
    FindProductFilterValuesQueryVariables
  >(FindProductFilterValuesDocument, options);
}
export function useFindProductFilterValuesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindProductFilterValuesQuery,
        FindProductFilterValuesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FindProductFilterValuesQuery,
    FindProductFilterValuesQueryVariables
  >(FindProductFilterValuesDocument, options);
}
export type FindProductFilterValuesQueryHookResult = ReturnType<
  typeof useFindProductFilterValuesQuery
>;
export type FindProductFilterValuesLazyQueryHookResult = ReturnType<
  typeof useFindProductFilterValuesLazyQuery
>;
export type FindProductFilterValuesSuspenseQueryHookResult = ReturnType<
  typeof useFindProductFilterValuesSuspenseQuery
>;
export type FindProductFilterValuesQueryResult = Apollo.QueryResult<
  FindProductFilterValuesQuery,
  FindProductFilterValuesQueryVariables
>;
export const FindLocationFilterValuesDocument = gql`
  query findLocationFilterValues(
    $layoutId: ID!
    $input: FindLocationFilterValuesInput!
  ) {
    layout(id: $layoutId) {
      locationFilterValues(input: $input, engine: OLD_POSTGRES) {
        ...FindLocationFilterValuesFilterResult
      }
    }
  }
  ${FindLocationFilterValuesFilterResultFragmentDoc}
`;

/**
 * __useFindLocationFilterValuesQuery__
 *
 * To run a query within a React component, call `useFindLocationFilterValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindLocationFilterValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindLocationFilterValuesQuery({
 *   variables: {
 *      layoutId: // value for 'layoutId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindLocationFilterValuesQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindLocationFilterValuesQuery,
    FindLocationFilterValuesQueryVariables
  > &
    (
      | { variables: FindLocationFilterValuesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindLocationFilterValuesQuery,
    FindLocationFilterValuesQueryVariables
  >(FindLocationFilterValuesDocument, options);
}
export function useFindLocationFilterValuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindLocationFilterValuesQuery,
    FindLocationFilterValuesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindLocationFilterValuesQuery,
    FindLocationFilterValuesQueryVariables
  >(FindLocationFilterValuesDocument, options);
}
export function useFindLocationFilterValuesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindLocationFilterValuesQuery,
        FindLocationFilterValuesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FindLocationFilterValuesQuery,
    FindLocationFilterValuesQueryVariables
  >(FindLocationFilterValuesDocument, options);
}
export type FindLocationFilterValuesQueryHookResult = ReturnType<
  typeof useFindLocationFilterValuesQuery
>;
export type FindLocationFilterValuesLazyQueryHookResult = ReturnType<
  typeof useFindLocationFilterValuesLazyQuery
>;
export type FindLocationFilterValuesSuspenseQueryHookResult = ReturnType<
  typeof useFindLocationFilterValuesSuspenseQuery
>;
export type FindLocationFilterValuesQueryResult = Apollo.QueryResult<
  FindLocationFilterValuesQuery,
  FindLocationFilterValuesQueryVariables
>;
export const CheckAssignmentPolicyDocument = gql`
  query checkAssignmentPolicy($input: CheckSimulationAssignmentPolicyInput!) {
    checkSimulationAssignmentPolicy(input: $input) {
      ...CheckSimulationAssignmentPolicyResult
    }
  }
  ${CheckSimulationAssignmentPolicyResultFragmentDoc}
`;

/**
 * __useCheckAssignmentPolicyQuery__
 *
 * To run a query within a React component, call `useCheckAssignmentPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckAssignmentPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckAssignmentPolicyQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckAssignmentPolicyQuery(
  baseOptions: Apollo.QueryHookOptions<
    CheckAssignmentPolicyQuery,
    CheckAssignmentPolicyQueryVariables
  > &
    (
      | { variables: CheckAssignmentPolicyQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CheckAssignmentPolicyQuery,
    CheckAssignmentPolicyQueryVariables
  >(CheckAssignmentPolicyDocument, options);
}
export function useCheckAssignmentPolicyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CheckAssignmentPolicyQuery,
    CheckAssignmentPolicyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CheckAssignmentPolicyQuery,
    CheckAssignmentPolicyQueryVariables
  >(CheckAssignmentPolicyDocument, options);
}
export function useCheckAssignmentPolicySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CheckAssignmentPolicyQuery,
        CheckAssignmentPolicyQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CheckAssignmentPolicyQuery,
    CheckAssignmentPolicyQueryVariables
  >(CheckAssignmentPolicyDocument, options);
}
export type CheckAssignmentPolicyQueryHookResult = ReturnType<
  typeof useCheckAssignmentPolicyQuery
>;
export type CheckAssignmentPolicyLazyQueryHookResult = ReturnType<
  typeof useCheckAssignmentPolicyLazyQuery
>;
export type CheckAssignmentPolicySuspenseQueryHookResult = ReturnType<
  typeof useCheckAssignmentPolicySuspenseQuery
>;
export type CheckAssignmentPolicyQueryResult = Apollo.QueryResult<
  CheckAssignmentPolicyQuery,
  CheckAssignmentPolicyQueryVariables
>;
export const FindSimulationItemsByFilterDocument = gql`
  query findSimulationItemsByFilter(
    $input: FindSimulationItemsByFilter!
    $page: SkipLimitPageSpec
    $filter: ItemsFilter
  ) {
    findSimulationItemsByFilter(
      input: $input
      page: $page
      filter: $filter
      sortBy: [
        { field: CONSIGNEE, direction: ASC }
        { field: SKU, direction: ASC }
      ]
    ) {
      content {
        ...SimulationItemData
      }
      totalCount
    }
  }
  ${SimulationItemDataFragmentDoc}
`;

/**
 * __useFindSimulationItemsByFilterQuery__
 *
 * To run a query within a React component, call `useFindSimulationItemsByFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindSimulationItemsByFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindSimulationItemsByFilterQuery({
 *   variables: {
 *      input: // value for 'input'
 *      page: // value for 'page'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFindSimulationItemsByFilterQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindSimulationItemsByFilterQuery,
    FindSimulationItemsByFilterQueryVariables
  > &
    (
      | { variables: FindSimulationItemsByFilterQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindSimulationItemsByFilterQuery,
    FindSimulationItemsByFilterQueryVariables
  >(FindSimulationItemsByFilterDocument, options);
}
export function useFindSimulationItemsByFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindSimulationItemsByFilterQuery,
    FindSimulationItemsByFilterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindSimulationItemsByFilterQuery,
    FindSimulationItemsByFilterQueryVariables
  >(FindSimulationItemsByFilterDocument, options);
}
export function useFindSimulationItemsByFilterSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindSimulationItemsByFilterQuery,
        FindSimulationItemsByFilterQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FindSimulationItemsByFilterQuery,
    FindSimulationItemsByFilterQueryVariables
  >(FindSimulationItemsByFilterDocument, options);
}
export type FindSimulationItemsByFilterQueryHookResult = ReturnType<
  typeof useFindSimulationItemsByFilterQuery
>;
export type FindSimulationItemsByFilterLazyQueryHookResult = ReturnType<
  typeof useFindSimulationItemsByFilterLazyQuery
>;
export type FindSimulationItemsByFilterSuspenseQueryHookResult = ReturnType<
  typeof useFindSimulationItemsByFilterSuspenseQuery
>;
export type FindSimulationItemsByFilterQueryResult = Apollo.QueryResult<
  FindSimulationItemsByFilterQuery,
  FindSimulationItemsByFilterQueryVariables
>;
export const FindSimulationItemsTotalByFilterDocument = gql`
  query findSimulationItemsTotalByFilter(
    $input: FindSimulationItemsByFilter!
    $filter: ItemsFilter
  ) {
    findSimulationItemsByFilter(input: $input, filter: $filter) {
      totalCount
    }
  }
`;

/**
 * __useFindSimulationItemsTotalByFilterQuery__
 *
 * To run a query within a React component, call `useFindSimulationItemsTotalByFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindSimulationItemsTotalByFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindSimulationItemsTotalByFilterQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFindSimulationItemsTotalByFilterQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindSimulationItemsTotalByFilterQuery,
    FindSimulationItemsTotalByFilterQueryVariables
  > &
    (
      | {
          variables: FindSimulationItemsTotalByFilterQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindSimulationItemsTotalByFilterQuery,
    FindSimulationItemsTotalByFilterQueryVariables
  >(FindSimulationItemsTotalByFilterDocument, options);
}
export function useFindSimulationItemsTotalByFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindSimulationItemsTotalByFilterQuery,
    FindSimulationItemsTotalByFilterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindSimulationItemsTotalByFilterQuery,
    FindSimulationItemsTotalByFilterQueryVariables
  >(FindSimulationItemsTotalByFilterDocument, options);
}
export function useFindSimulationItemsTotalByFilterSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindSimulationItemsTotalByFilterQuery,
        FindSimulationItemsTotalByFilterQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FindSimulationItemsTotalByFilterQuery,
    FindSimulationItemsTotalByFilterQueryVariables
  >(FindSimulationItemsTotalByFilterDocument, options);
}
export type FindSimulationItemsTotalByFilterQueryHookResult = ReturnType<
  typeof useFindSimulationItemsTotalByFilterQuery
>;
export type FindSimulationItemsTotalByFilterLazyQueryHookResult = ReturnType<
  typeof useFindSimulationItemsTotalByFilterLazyQuery
>;
export type FindSimulationItemsTotalByFilterSuspenseQueryHookResult =
  ReturnType<typeof useFindSimulationItemsTotalByFilterSuspenseQuery>;
export type FindSimulationItemsTotalByFilterQueryResult = Apollo.QueryResult<
  FindSimulationItemsTotalByFilterQuery,
  FindSimulationItemsTotalByFilterQueryVariables
>;
export const LoadSimulationItemSetDocument = gql`
  query loadSimulationItemSet($simulationId: ID!) {
    simulation(id: $simulationId) {
      effectiveItemSet {
        ...SimulationItemSet
      }
    }
  }
  ${SimulationItemSetFragmentDoc}
`;

/**
 * __useLoadSimulationItemSetQuery__
 *
 * To run a query within a React component, call `useLoadSimulationItemSetQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadSimulationItemSetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadSimulationItemSetQuery({
 *   variables: {
 *      simulationId: // value for 'simulationId'
 *   },
 * });
 */
export function useLoadSimulationItemSetQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadSimulationItemSetQuery,
    LoadSimulationItemSetQueryVariables
  > &
    (
      | { variables: LoadSimulationItemSetQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadSimulationItemSetQuery,
    LoadSimulationItemSetQueryVariables
  >(LoadSimulationItemSetDocument, options);
}
export function useLoadSimulationItemSetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadSimulationItemSetQuery,
    LoadSimulationItemSetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadSimulationItemSetQuery,
    LoadSimulationItemSetQueryVariables
  >(LoadSimulationItemSetDocument, options);
}
export function useLoadSimulationItemSetSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadSimulationItemSetQuery,
        LoadSimulationItemSetQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadSimulationItemSetQuery,
    LoadSimulationItemSetQueryVariables
  >(LoadSimulationItemSetDocument, options);
}
export type LoadSimulationItemSetQueryHookResult = ReturnType<
  typeof useLoadSimulationItemSetQuery
>;
export type LoadSimulationItemSetLazyQueryHookResult = ReturnType<
  typeof useLoadSimulationItemSetLazyQuery
>;
export type LoadSimulationItemSetSuspenseQueryHookResult = ReturnType<
  typeof useLoadSimulationItemSetSuspenseQuery
>;
export type LoadSimulationItemSetQueryResult = Apollo.QueryResult<
  LoadSimulationItemSetQuery,
  LoadSimulationItemSetQueryVariables
>;
export const RunGenerateSimulationItemSetDocument = gql`
  mutation runGenerateSimulationItemSet($simulationId: String!) {
    generateSimulationItemSet(simulationId: $simulationId) {
      ...SimulationItemSet
    }
  }
  ${SimulationItemSetFragmentDoc}
`;
export type RunGenerateSimulationItemSetMutationFn = Apollo.MutationFunction<
  RunGenerateSimulationItemSetMutation,
  RunGenerateSimulationItemSetMutationVariables
>;

/**
 * __useRunGenerateSimulationItemSetMutation__
 *
 * To run a mutation, you first call `useRunGenerateSimulationItemSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunGenerateSimulationItemSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runGenerateSimulationItemSetMutation, { data, loading, error }] = useRunGenerateSimulationItemSetMutation({
 *   variables: {
 *      simulationId: // value for 'simulationId'
 *   },
 * });
 */
export function useRunGenerateSimulationItemSetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RunGenerateSimulationItemSetMutation,
    RunGenerateSimulationItemSetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RunGenerateSimulationItemSetMutation,
    RunGenerateSimulationItemSetMutationVariables
  >(RunGenerateSimulationItemSetDocument, options);
}
export type RunGenerateSimulationItemSetMutationHookResult = ReturnType<
  typeof useRunGenerateSimulationItemSetMutation
>;
export type RunGenerateSimulationItemSetMutationResult =
  Apollo.MutationResult<RunGenerateSimulationItemSetMutation>;
export type RunGenerateSimulationItemSetMutationOptions =
  Apollo.BaseMutationOptions<
    RunGenerateSimulationItemSetMutation,
    RunGenerateSimulationItemSetMutationVariables
  >;
export const LoadAssignmentStatsDocument = gql`
  query loadAssignmentStats(
    $assignmentId: ID!
    $itemSetId: ID!
    $layoutId: ID!
    $filter: AssignmentItemsExtendedFilter
    $itemFilter: [ItemFilterUnionInput]
    $matchingOnly: Boolean
  ) {
    applyAssignmentItemFilter(
      input: {
        assignmentId: $assignmentId
        itemSetId: $itemSetId
        layoutId: $layoutId
        filters: $itemFilter
        matchingOnly: $matchingOnly
      }
      filter: $filter
      page: null
    ) {
      content {
        ...AssignmentLocationItemStat
      }
    }
  }
  ${AssignmentLocationItemStatFragmentDoc}
`;

/**
 * __useLoadAssignmentStatsQuery__
 *
 * To run a query within a React component, call `useLoadAssignmentStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadAssignmentStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadAssignmentStatsQuery({
 *   variables: {
 *      assignmentId: // value for 'assignmentId'
 *      itemSetId: // value for 'itemSetId'
 *      layoutId: // value for 'layoutId'
 *      filter: // value for 'filter'
 *      itemFilter: // value for 'itemFilter'
 *      matchingOnly: // value for 'matchingOnly'
 *   },
 * });
 */
export function useLoadAssignmentStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadAssignmentStatsQuery,
    LoadAssignmentStatsQueryVariables
  > &
    (
      | { variables: LoadAssignmentStatsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadAssignmentStatsQuery,
    LoadAssignmentStatsQueryVariables
  >(LoadAssignmentStatsDocument, options);
}
export function useLoadAssignmentStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadAssignmentStatsQuery,
    LoadAssignmentStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadAssignmentStatsQuery,
    LoadAssignmentStatsQueryVariables
  >(LoadAssignmentStatsDocument, options);
}
export function useLoadAssignmentStatsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadAssignmentStatsQuery,
        LoadAssignmentStatsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadAssignmentStatsQuery,
    LoadAssignmentStatsQueryVariables
  >(LoadAssignmentStatsDocument, options);
}
export type LoadAssignmentStatsQueryHookResult = ReturnType<
  typeof useLoadAssignmentStatsQuery
>;
export type LoadAssignmentStatsLazyQueryHookResult = ReturnType<
  typeof useLoadAssignmentStatsLazyQuery
>;
export type LoadAssignmentStatsSuspenseQueryHookResult = ReturnType<
  typeof useLoadAssignmentStatsSuspenseQuery
>;
export type LoadAssignmentStatsQueryResult = Apollo.QueryResult<
  LoadAssignmentStatsQuery,
  LoadAssignmentStatsQueryVariables
>;
export const LoadExportJobDocument = gql`
  query loadExportJob($id: String!) {
    exportJob(id: $id) {
      ...ExportJob
    }
  }
  ${ExportJobFragmentDoc}
`;

/**
 * __useLoadExportJobQuery__
 *
 * To run a query within a React component, call `useLoadExportJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadExportJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadExportJobQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoadExportJobQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadExportJobQuery,
    LoadExportJobQueryVariables
  > &
    (
      | { variables: LoadExportJobQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadExportJobQuery, LoadExportJobQueryVariables>(
    LoadExportJobDocument,
    options,
  );
}
export function useLoadExportJobLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadExportJobQuery,
    LoadExportJobQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LoadExportJobQuery, LoadExportJobQueryVariables>(
    LoadExportJobDocument,
    options,
  );
}
export function useLoadExportJobSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadExportJobQuery,
        LoadExportJobQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadExportJobQuery,
    LoadExportJobQueryVariables
  >(LoadExportJobDocument, options);
}
export type LoadExportJobQueryHookResult = ReturnType<
  typeof useLoadExportJobQuery
>;
export type LoadExportJobLazyQueryHookResult = ReturnType<
  typeof useLoadExportJobLazyQuery
>;
export type LoadExportJobSuspenseQueryHookResult = ReturnType<
  typeof useLoadExportJobSuspenseQuery
>;
export type LoadExportJobQueryResult = Apollo.QueryResult<
  LoadExportJobQuery,
  LoadExportJobQueryVariables
>;
export const ExportLayoutLocationsDocument = gql`
  mutation exportLayoutLocations($layoutId: String!) {
    exportLayoutLocations(id: $layoutId) {
      ...ExportJob
    }
  }
  ${ExportJobFragmentDoc}
`;
export type ExportLayoutLocationsMutationFn = Apollo.MutationFunction<
  ExportLayoutLocationsMutation,
  ExportLayoutLocationsMutationVariables
>;

/**
 * __useExportLayoutLocationsMutation__
 *
 * To run a mutation, you first call `useExportLayoutLocationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportLayoutLocationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportLayoutLocationsMutation, { data, loading, error }] = useExportLayoutLocationsMutation({
 *   variables: {
 *      layoutId: // value for 'layoutId'
 *   },
 * });
 */
export function useExportLayoutLocationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExportLayoutLocationsMutation,
    ExportLayoutLocationsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ExportLayoutLocationsMutation,
    ExportLayoutLocationsMutationVariables
  >(ExportLayoutLocationsDocument, options);
}
export type ExportLayoutLocationsMutationHookResult = ReturnType<
  typeof useExportLayoutLocationsMutation
>;
export type ExportLayoutLocationsMutationResult =
  Apollo.MutationResult<ExportLayoutLocationsMutation>;
export type ExportLayoutLocationsMutationOptions = Apollo.BaseMutationOptions<
  ExportLayoutLocationsMutation,
  ExportLayoutLocationsMutationVariables
>;
export const ExportLocationWeightComplianceDocument = gql`
  mutation exportLocationWeightCompliance(
    $csvOptions: CsvExportOptionsInput
    $filter: WeightComplianceLocationFilter
    $weightComplianceId: String!
  ) {
    exportLocationWeightCompliance(
      csvOptions: $csvOptions
      filter: $filter
      weightComplianceId: $weightComplianceId
    ) {
      ...ExportJob
    }
  }
  ${ExportJobFragmentDoc}
`;
export type ExportLocationWeightComplianceMutationFn = Apollo.MutationFunction<
  ExportLocationWeightComplianceMutation,
  ExportLocationWeightComplianceMutationVariables
>;

/**
 * __useExportLocationWeightComplianceMutation__
 *
 * To run a mutation, you first call `useExportLocationWeightComplianceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportLocationWeightComplianceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportLocationWeightComplianceMutation, { data, loading, error }] = useExportLocationWeightComplianceMutation({
 *   variables: {
 *      csvOptions: // value for 'csvOptions'
 *      filter: // value for 'filter'
 *      weightComplianceId: // value for 'weightComplianceId'
 *   },
 * });
 */
export function useExportLocationWeightComplianceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExportLocationWeightComplianceMutation,
    ExportLocationWeightComplianceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ExportLocationWeightComplianceMutation,
    ExportLocationWeightComplianceMutationVariables
  >(ExportLocationWeightComplianceDocument, options);
}
export type ExportLocationWeightComplianceMutationHookResult = ReturnType<
  typeof useExportLocationWeightComplianceMutation
>;
export type ExportLocationWeightComplianceMutationResult =
  Apollo.MutationResult<ExportLocationWeightComplianceMutation>;
export type ExportLocationWeightComplianceMutationOptions =
  Apollo.BaseMutationOptions<
    ExportLocationWeightComplianceMutation,
    ExportLocationWeightComplianceMutationVariables
  >;
export const ExportBayWeightComplianceDocument = gql`
  mutation exportBayWeightCompliance(
    $csvOptions: CsvExportOptionsInput
    $filter: WeightComplianceBayFilter
    $weightComplianceId: String!
  ) {
    exportBayWeightCompliance(
      csvOptions: $csvOptions
      filter: $filter
      weightComplianceId: $weightComplianceId
    ) {
      ...ExportJob
    }
  }
  ${ExportJobFragmentDoc}
`;
export type ExportBayWeightComplianceMutationFn = Apollo.MutationFunction<
  ExportBayWeightComplianceMutation,
  ExportBayWeightComplianceMutationVariables
>;

/**
 * __useExportBayWeightComplianceMutation__
 *
 * To run a mutation, you first call `useExportBayWeightComplianceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportBayWeightComplianceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportBayWeightComplianceMutation, { data, loading, error }] = useExportBayWeightComplianceMutation({
 *   variables: {
 *      csvOptions: // value for 'csvOptions'
 *      filter: // value for 'filter'
 *      weightComplianceId: // value for 'weightComplianceId'
 *   },
 * });
 */
export function useExportBayWeightComplianceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExportBayWeightComplianceMutation,
    ExportBayWeightComplianceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ExportBayWeightComplianceMutation,
    ExportBayWeightComplianceMutationVariables
  >(ExportBayWeightComplianceDocument, options);
}
export type ExportBayWeightComplianceMutationHookResult = ReturnType<
  typeof useExportBayWeightComplianceMutation
>;
export type ExportBayWeightComplianceMutationResult =
  Apollo.MutationResult<ExportBayWeightComplianceMutation>;
export type ExportBayWeightComplianceMutationOptions =
  Apollo.BaseMutationOptions<
    ExportBayWeightComplianceMutation,
    ExportBayWeightComplianceMutationVariables
  >;
export const ExportSizeComplianceDocument = gql`
  mutation exportSizeCompliance(
    $csvOptions: CsvExportOptionsInput
    $filter: SizeComplianceLocationFilter
    $sizeComplianceId: String!
  ) {
    exportSizeCompliance(
      csvOptions: $csvOptions
      filter: $filter
      sizeComplianceId: $sizeComplianceId
    ) {
      ...ExportJob
    }
  }
  ${ExportJobFragmentDoc}
`;
export type ExportSizeComplianceMutationFn = Apollo.MutationFunction<
  ExportSizeComplianceMutation,
  ExportSizeComplianceMutationVariables
>;

/**
 * __useExportSizeComplianceMutation__
 *
 * To run a mutation, you first call `useExportSizeComplianceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportSizeComplianceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportSizeComplianceMutation, { data, loading, error }] = useExportSizeComplianceMutation({
 *   variables: {
 *      csvOptions: // value for 'csvOptions'
 *      filter: // value for 'filter'
 *      sizeComplianceId: // value for 'sizeComplianceId'
 *   },
 * });
 */
export function useExportSizeComplianceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExportSizeComplianceMutation,
    ExportSizeComplianceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ExportSizeComplianceMutation,
    ExportSizeComplianceMutationVariables
  >(ExportSizeComplianceDocument, options);
}
export type ExportSizeComplianceMutationHookResult = ReturnType<
  typeof useExportSizeComplianceMutation
>;
export type ExportSizeComplianceMutationResult =
  Apollo.MutationResult<ExportSizeComplianceMutation>;
export type ExportSizeComplianceMutationOptions = Apollo.BaseMutationOptions<
  ExportSizeComplianceMutation,
  ExportSizeComplianceMutationVariables
>;
export const ExportAssignmentComplianceDocument = gql`
  mutation exportAssignmentCompliance(
    $csvOptions: CsvExportOptionsInput
    $filter: AssignmentComplianceItemFilter
    $assignmentComplianceId: String!
  ) {
    exportAssignmentCompliance(
      csvOptions: $csvOptions
      filter: $filter
      assignmentComplianceId: $assignmentComplianceId
    ) {
      ...ExportJob
    }
  }
  ${ExportJobFragmentDoc}
`;
export type ExportAssignmentComplianceMutationFn = Apollo.MutationFunction<
  ExportAssignmentComplianceMutation,
  ExportAssignmentComplianceMutationVariables
>;

/**
 * __useExportAssignmentComplianceMutation__
 *
 * To run a mutation, you first call `useExportAssignmentComplianceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportAssignmentComplianceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportAssignmentComplianceMutation, { data, loading, error }] = useExportAssignmentComplianceMutation({
 *   variables: {
 *      csvOptions: // value for 'csvOptions'
 *      filter: // value for 'filter'
 *      assignmentComplianceId: // value for 'assignmentComplianceId'
 *   },
 * });
 */
export function useExportAssignmentComplianceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExportAssignmentComplianceMutation,
    ExportAssignmentComplianceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ExportAssignmentComplianceMutation,
    ExportAssignmentComplianceMutationVariables
  >(ExportAssignmentComplianceDocument, options);
}
export type ExportAssignmentComplianceMutationHookResult = ReturnType<
  typeof useExportAssignmentComplianceMutation
>;
export type ExportAssignmentComplianceMutationResult =
  Apollo.MutationResult<ExportAssignmentComplianceMutation>;
export type ExportAssignmentComplianceMutationOptions =
  Apollo.BaseMutationOptions<
    ExportAssignmentComplianceMutation,
    ExportAssignmentComplianceMutationVariables
  >;
export const ExportOptimizationRunAssignmentDiffDocument = gql`
  mutation exportOptimizationRunAssignmentDiff(
    $id: String!
    $filter: AssignmentDiffItemsFilter
    $rankCategories: CategoryMappingRules
  ) {
    exportOptimizationRunAssignmentDiff(
      id: $id
      filter: $filter
      cumulativePercentRankCategories: $rankCategories
      sortBy: [{ field: HIT_COUNT, direction: DESC }]
    ) {
      ...ExportJob
    }
  }
  ${ExportJobFragmentDoc}
`;
export type ExportOptimizationRunAssignmentDiffMutationFn =
  Apollo.MutationFunction<
    ExportOptimizationRunAssignmentDiffMutation,
    ExportOptimizationRunAssignmentDiffMutationVariables
  >;

/**
 * __useExportOptimizationRunAssignmentDiffMutation__
 *
 * To run a mutation, you first call `useExportOptimizationRunAssignmentDiffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportOptimizationRunAssignmentDiffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportOptimizationRunAssignmentDiffMutation, { data, loading, error }] = useExportOptimizationRunAssignmentDiffMutation({
 *   variables: {
 *      id: // value for 'id'
 *      filter: // value for 'filter'
 *      rankCategories: // value for 'rankCategories'
 *   },
 * });
 */
export function useExportOptimizationRunAssignmentDiffMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExportOptimizationRunAssignmentDiffMutation,
    ExportOptimizationRunAssignmentDiffMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ExportOptimizationRunAssignmentDiffMutation,
    ExportOptimizationRunAssignmentDiffMutationVariables
  >(ExportOptimizationRunAssignmentDiffDocument, options);
}
export type ExportOptimizationRunAssignmentDiffMutationHookResult = ReturnType<
  typeof useExportOptimizationRunAssignmentDiffMutation
>;
export type ExportOptimizationRunAssignmentDiffMutationResult =
  Apollo.MutationResult<ExportOptimizationRunAssignmentDiffMutation>;
export type ExportOptimizationRunAssignmentDiffMutationOptions =
  Apollo.BaseMutationOptions<
    ExportOptimizationRunAssignmentDiffMutation,
    ExportOptimizationRunAssignmentDiffMutationVariables
  >;
export const ExportAssignmentOccupancyDocument = gql`
  mutation exportAssignmentOccupancy(
    $csvOptions: CsvExportOptionsInput
    $filter: AssignmentOccupancyLocationFilter
    $assignmentOccupancyId: String!
  ) {
    exportAssignmentOccupancy(
      csvOptions: $csvOptions
      filter: $filter
      assignmentOccupancyId: $assignmentOccupancyId
    ) {
      ...ExportJob
    }
  }
  ${ExportJobFragmentDoc}
`;
export type ExportAssignmentOccupancyMutationFn = Apollo.MutationFunction<
  ExportAssignmentOccupancyMutation,
  ExportAssignmentOccupancyMutationVariables
>;

/**
 * __useExportAssignmentOccupancyMutation__
 *
 * To run a mutation, you first call `useExportAssignmentOccupancyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportAssignmentOccupancyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportAssignmentOccupancyMutation, { data, loading, error }] = useExportAssignmentOccupancyMutation({
 *   variables: {
 *      csvOptions: // value for 'csvOptions'
 *      filter: // value for 'filter'
 *      assignmentOccupancyId: // value for 'assignmentOccupancyId'
 *   },
 * });
 */
export function useExportAssignmentOccupancyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExportAssignmentOccupancyMutation,
    ExportAssignmentOccupancyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ExportAssignmentOccupancyMutation,
    ExportAssignmentOccupancyMutationVariables
  >(ExportAssignmentOccupancyDocument, options);
}
export type ExportAssignmentOccupancyMutationHookResult = ReturnType<
  typeof useExportAssignmentOccupancyMutation
>;
export type ExportAssignmentOccupancyMutationResult =
  Apollo.MutationResult<ExportAssignmentOccupancyMutation>;
export type ExportAssignmentOccupancyMutationOptions =
  Apollo.BaseMutationOptions<
    ExportAssignmentOccupancyMutation,
    ExportAssignmentOccupancyMutationVariables
  >;
export const ExportSimulationAssignmentDetailsDocument = gql`
  mutation exportSimulationAssignmentDetails(
    $csvOptions: CsvExportOptionsInput
    $simulationId: String!
    $rankCategories: CategoryMappingRules
  ) {
    exportSimulationAssignmentDetails(
      simulationId: $simulationId
      csvOptions: $csvOptions
      cumulativePercentRankCategories: $rankCategories
      sortBy: [{ field: HIT_COUNT, direction: DESC }]
    ) {
      ...ExportJob
    }
  }
  ${ExportJobFragmentDoc}
`;
export type ExportSimulationAssignmentDetailsMutationFn =
  Apollo.MutationFunction<
    ExportSimulationAssignmentDetailsMutation,
    ExportSimulationAssignmentDetailsMutationVariables
  >;

/**
 * __useExportSimulationAssignmentDetailsMutation__
 *
 * To run a mutation, you first call `useExportSimulationAssignmentDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportSimulationAssignmentDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportSimulationAssignmentDetailsMutation, { data, loading, error }] = useExportSimulationAssignmentDetailsMutation({
 *   variables: {
 *      csvOptions: // value for 'csvOptions'
 *      simulationId: // value for 'simulationId'
 *      rankCategories: // value for 'rankCategories'
 *   },
 * });
 */
export function useExportSimulationAssignmentDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExportSimulationAssignmentDetailsMutation,
    ExportSimulationAssignmentDetailsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ExportSimulationAssignmentDetailsMutation,
    ExportSimulationAssignmentDetailsMutationVariables
  >(ExportSimulationAssignmentDetailsDocument, options);
}
export type ExportSimulationAssignmentDetailsMutationHookResult = ReturnType<
  typeof useExportSimulationAssignmentDetailsMutation
>;
export type ExportSimulationAssignmentDetailsMutationResult =
  Apollo.MutationResult<ExportSimulationAssignmentDetailsMutation>;
export type ExportSimulationAssignmentDetailsMutationOptions =
  Apollo.BaseMutationOptions<
    ExportSimulationAssignmentDetailsMutation,
    ExportSimulationAssignmentDetailsMutationVariables
  >;
export const RunExportJobDocument = gql`
  mutation runExportJob(
    $converterId: String!
    $query: String!
    $variables: JSON
    $filename: String!
    $config: JSON!
    $csvOptions: CsvExportOptionsInput
  ) {
    createExportJob(
      input: {
        sourceType: "GENERIC"
        sourceId: "GENERIC"
        filename: $filename
        query: $query
        variables: $variables
        converterId: $converterId
        csvOptions: $csvOptions
        converterConfig: $config
      }
    ) {
      ...ExportJob
    }
  }
  ${ExportJobFragmentDoc}
`;
export type RunExportJobMutationFn = Apollo.MutationFunction<
  RunExportJobMutation,
  RunExportJobMutationVariables
>;

/**
 * __useRunExportJobMutation__
 *
 * To run a mutation, you first call `useRunExportJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunExportJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runExportJobMutation, { data, loading, error }] = useRunExportJobMutation({
 *   variables: {
 *      converterId: // value for 'converterId'
 *      query: // value for 'query'
 *      variables: // value for 'variables'
 *      filename: // value for 'filename'
 *      config: // value for 'config'
 *      csvOptions: // value for 'csvOptions'
 *   },
 * });
 */
export function useRunExportJobMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RunExportJobMutation,
    RunExportJobMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RunExportJobMutation,
    RunExportJobMutationVariables
  >(RunExportJobDocument, options);
}
export type RunExportJobMutationHookResult = ReturnType<
  typeof useRunExportJobMutation
>;
export type RunExportJobMutationResult =
  Apollo.MutationResult<RunExportJobMutation>;
export type RunExportJobMutationOptions = Apollo.BaseMutationOptions<
  RunExportJobMutation,
  RunExportJobMutationVariables
>;
export const SendExportJobDocument = gql`
  mutation sendExportJob($input: SendExportJobInput!) {
    sendExportJob(input: $input) {
      id
      status
    }
  }
`;
export type SendExportJobMutationFn = Apollo.MutationFunction<
  SendExportJobMutation,
  SendExportJobMutationVariables
>;

/**
 * __useSendExportJobMutation__
 *
 * To run a mutation, you first call `useSendExportJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendExportJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendExportJobMutation, { data, loading, error }] = useSendExportJobMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendExportJobMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendExportJobMutation,
    SendExportJobMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendExportJobMutation,
    SendExportJobMutationVariables
  >(SendExportJobDocument, options);
}
export type SendExportJobMutationHookResult = ReturnType<
  typeof useSendExportJobMutation
>;
export type SendExportJobMutationResult =
  Apollo.MutationResult<SendExportJobMutation>;
export type SendExportJobMutationOptions = Apollo.BaseMutationOptions<
  SendExportJobMutation,
  SendExportJobMutationVariables
>;
export const CreateImportJobDocument = gql`
  mutation createImportJob($input: CreateImportJobInput!) {
    createImportJob(input: $input) {
      ...ImportJob
    }
  }
  ${ImportJobFragmentDoc}
`;
export type CreateImportJobMutationFn = Apollo.MutationFunction<
  CreateImportJobMutation,
  CreateImportJobMutationVariables
>;

/**
 * __useCreateImportJobMutation__
 *
 * To run a mutation, you first call `useCreateImportJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateImportJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createImportJobMutation, { data, loading, error }] = useCreateImportJobMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateImportJobMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateImportJobMutation,
    CreateImportJobMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateImportJobMutation,
    CreateImportJobMutationVariables
  >(CreateImportJobDocument, options);
}
export type CreateImportJobMutationHookResult = ReturnType<
  typeof useCreateImportJobMutation
>;
export type CreateImportJobMutationResult =
  Apollo.MutationResult<CreateImportJobMutation>;
export type CreateImportJobMutationOptions = Apollo.BaseMutationOptions<
  CreateImportJobMutation,
  CreateImportJobMutationVariables
>;
export const UpdateImportJobDocument = gql`
  mutation updateImportJob($input: UpdateImportJobInput!) {
    updateImportJob(input: $input) {
      ...ImportJob
    }
  }
  ${ImportJobFragmentDoc}
`;
export type UpdateImportJobMutationFn = Apollo.MutationFunction<
  UpdateImportJobMutation,
  UpdateImportJobMutationVariables
>;

/**
 * __useUpdateImportJobMutation__
 *
 * To run a mutation, you first call `useUpdateImportJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateImportJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateImportJobMutation, { data, loading, error }] = useUpdateImportJobMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateImportJobMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateImportJobMutation,
    UpdateImportJobMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateImportJobMutation,
    UpdateImportJobMutationVariables
  >(UpdateImportJobDocument, options);
}
export type UpdateImportJobMutationHookResult = ReturnType<
  typeof useUpdateImportJobMutation
>;
export type UpdateImportJobMutationResult =
  Apollo.MutationResult<UpdateImportJobMutation>;
export type UpdateImportJobMutationOptions = Apollo.BaseMutationOptions<
  UpdateImportJobMutation,
  UpdateImportJobMutationVariables
>;
export const BeginImportJobMultipartUploadDocument = gql`
  mutation beginImportJobMultipartUpload($id: String!, $parts: Float!) {
    beginImportJobMultipartUpload(id: $id, parts: $parts)
  }
`;
export type BeginImportJobMultipartUploadMutationFn = Apollo.MutationFunction<
  BeginImportJobMultipartUploadMutation,
  BeginImportJobMultipartUploadMutationVariables
>;

/**
 * __useBeginImportJobMultipartUploadMutation__
 *
 * To run a mutation, you first call `useBeginImportJobMultipartUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBeginImportJobMultipartUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [beginImportJobMultipartUploadMutation, { data, loading, error }] = useBeginImportJobMultipartUploadMutation({
 *   variables: {
 *      id: // value for 'id'
 *      parts: // value for 'parts'
 *   },
 * });
 */
export function useBeginImportJobMultipartUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BeginImportJobMultipartUploadMutation,
    BeginImportJobMultipartUploadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BeginImportJobMultipartUploadMutation,
    BeginImportJobMultipartUploadMutationVariables
  >(BeginImportJobMultipartUploadDocument, options);
}
export type BeginImportJobMultipartUploadMutationHookResult = ReturnType<
  typeof useBeginImportJobMultipartUploadMutation
>;
export type BeginImportJobMultipartUploadMutationResult =
  Apollo.MutationResult<BeginImportJobMultipartUploadMutation>;
export type BeginImportJobMultipartUploadMutationOptions =
  Apollo.BaseMutationOptions<
    BeginImportJobMultipartUploadMutation,
    BeginImportJobMultipartUploadMutationVariables
  >;
export const CompleteImportJobMultipartUploadDocument = gql`
  mutation completeImportJobMultipartUpload($id: ID!, $etags: [ID!]!) {
    completeImportJobMultipartUpload(input: { id: $id, etags: $etags }) {
      ...ImportJob
    }
  }
  ${ImportJobFragmentDoc}
`;
export type CompleteImportJobMultipartUploadMutationFn =
  Apollo.MutationFunction<
    CompleteImportJobMultipartUploadMutation,
    CompleteImportJobMultipartUploadMutationVariables
  >;

/**
 * __useCompleteImportJobMultipartUploadMutation__
 *
 * To run a mutation, you first call `useCompleteImportJobMultipartUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteImportJobMultipartUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeImportJobMultipartUploadMutation, { data, loading, error }] = useCompleteImportJobMultipartUploadMutation({
 *   variables: {
 *      id: // value for 'id'
 *      etags: // value for 'etags'
 *   },
 * });
 */
export function useCompleteImportJobMultipartUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteImportJobMultipartUploadMutation,
    CompleteImportJobMultipartUploadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompleteImportJobMultipartUploadMutation,
    CompleteImportJobMultipartUploadMutationVariables
  >(CompleteImportJobMultipartUploadDocument, options);
}
export type CompleteImportJobMultipartUploadMutationHookResult = ReturnType<
  typeof useCompleteImportJobMultipartUploadMutation
>;
export type CompleteImportJobMultipartUploadMutationResult =
  Apollo.MutationResult<CompleteImportJobMultipartUploadMutation>;
export type CompleteImportJobMultipartUploadMutationOptions =
  Apollo.BaseMutationOptions<
    CompleteImportJobMultipartUploadMutation,
    CompleteImportJobMultipartUploadMutationVariables
  >;
export const StartImportJobProcessingDocument = gql`
  mutation startImportJobProcessing(
    $id: String!
    $options: StartImportJobProcessingOptions
  ) {
    startImportJobProcessing(id: $id, options: $options) {
      ...ImportJob
    }
  }
  ${ImportJobFragmentDoc}
`;
export type StartImportJobProcessingMutationFn = Apollo.MutationFunction<
  StartImportJobProcessingMutation,
  StartImportJobProcessingMutationVariables
>;

/**
 * __useStartImportJobProcessingMutation__
 *
 * To run a mutation, you first call `useStartImportJobProcessingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartImportJobProcessingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startImportJobProcessingMutation, { data, loading, error }] = useStartImportJobProcessingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useStartImportJobProcessingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StartImportJobProcessingMutation,
    StartImportJobProcessingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    StartImportJobProcessingMutation,
    StartImportJobProcessingMutationVariables
  >(StartImportJobProcessingDocument, options);
}
export type StartImportJobProcessingMutationHookResult = ReturnType<
  typeof useStartImportJobProcessingMutation
>;
export type StartImportJobProcessingMutationResult =
  Apollo.MutationResult<StartImportJobProcessingMutation>;
export type StartImportJobProcessingMutationOptions =
  Apollo.BaseMutationOptions<
    StartImportJobProcessingMutation,
    StartImportJobProcessingMutationVariables
  >;
export const LoadImportJobDocument = gql`
  query loadImportJob($id: String!) {
    importJob(id: $id) {
      ...ImportJob
    }
  }
  ${ImportJobFragmentDoc}
`;

/**
 * __useLoadImportJobQuery__
 *
 * To run a query within a React component, call `useLoadImportJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadImportJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadImportJobQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoadImportJobQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadImportJobQuery,
    LoadImportJobQueryVariables
  > &
    (
      | { variables: LoadImportJobQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadImportJobQuery, LoadImportJobQueryVariables>(
    LoadImportJobDocument,
    options,
  );
}
export function useLoadImportJobLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadImportJobQuery,
    LoadImportJobQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LoadImportJobQuery, LoadImportJobQueryVariables>(
    LoadImportJobDocument,
    options,
  );
}
export function useLoadImportJobSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadImportJobQuery,
        LoadImportJobQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadImportJobQuery,
    LoadImportJobQueryVariables
  >(LoadImportJobDocument, options);
}
export type LoadImportJobQueryHookResult = ReturnType<
  typeof useLoadImportJobQuery
>;
export type LoadImportJobLazyQueryHookResult = ReturnType<
  typeof useLoadImportJobLazyQuery
>;
export type LoadImportJobSuspenseQueryHookResult = ReturnType<
  typeof useLoadImportJobSuspenseQuery
>;
export type LoadImportJobQueryResult = Apollo.QueryResult<
  LoadImportJobQuery,
  LoadImportJobQueryVariables
>;
export const LoadImportPreviewUrlDocument = gql`
  query loadImportPreviewUrl($jobId: String!, $contentLength: Float!) {
    importJob(id: $jobId) {
      previewUploadUrl(contentLength: $contentLength)
    }
  }
`;

/**
 * __useLoadImportPreviewUrlQuery__
 *
 * To run a query within a React component, call `useLoadImportPreviewUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadImportPreviewUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadImportPreviewUrlQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      contentLength: // value for 'contentLength'
 *   },
 * });
 */
export function useLoadImportPreviewUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadImportPreviewUrlQuery,
    LoadImportPreviewUrlQueryVariables
  > &
    (
      | { variables: LoadImportPreviewUrlQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadImportPreviewUrlQuery,
    LoadImportPreviewUrlQueryVariables
  >(LoadImportPreviewUrlDocument, options);
}
export function useLoadImportPreviewUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadImportPreviewUrlQuery,
    LoadImportPreviewUrlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadImportPreviewUrlQuery,
    LoadImportPreviewUrlQueryVariables
  >(LoadImportPreviewUrlDocument, options);
}
export function useLoadImportPreviewUrlSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadImportPreviewUrlQuery,
        LoadImportPreviewUrlQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadImportPreviewUrlQuery,
    LoadImportPreviewUrlQueryVariables
  >(LoadImportPreviewUrlDocument, options);
}
export type LoadImportPreviewUrlQueryHookResult = ReturnType<
  typeof useLoadImportPreviewUrlQuery
>;
export type LoadImportPreviewUrlLazyQueryHookResult = ReturnType<
  typeof useLoadImportPreviewUrlLazyQuery
>;
export type LoadImportPreviewUrlSuspenseQueryHookResult = ReturnType<
  typeof useLoadImportPreviewUrlSuspenseQuery
>;
export type LoadImportPreviewUrlQueryResult = Apollo.QueryResult<
  LoadImportPreviewUrlQuery,
  LoadImportPreviewUrlQueryVariables
>;
export const GetImportPreviewColumnsDocument = gql`
  mutation getImportPreviewColumns(
    $jobId: String!
    $options: PrepareImportJobPreviewTableOptions
  ) {
    prepareImportJobPreviewTable(id: $jobId, options: $options)
  }
`;
export type GetImportPreviewColumnsMutationFn = Apollo.MutationFunction<
  GetImportPreviewColumnsMutation,
  GetImportPreviewColumnsMutationVariables
>;

/**
 * __useGetImportPreviewColumnsMutation__
 *
 * To run a mutation, you first call `useGetImportPreviewColumnsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetImportPreviewColumnsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getImportPreviewColumnsMutation, { data, loading, error }] = useGetImportPreviewColumnsMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetImportPreviewColumnsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GetImportPreviewColumnsMutation,
    GetImportPreviewColumnsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GetImportPreviewColumnsMutation,
    GetImportPreviewColumnsMutationVariables
  >(GetImportPreviewColumnsDocument, options);
}
export type GetImportPreviewColumnsMutationHookResult = ReturnType<
  typeof useGetImportPreviewColumnsMutation
>;
export type GetImportPreviewColumnsMutationResult =
  Apollo.MutationResult<GetImportPreviewColumnsMutation>;
export type GetImportPreviewColumnsMutationOptions = Apollo.BaseMutationOptions<
  GetImportPreviewColumnsMutation,
  GetImportPreviewColumnsMutationVariables
>;
export const GetImportTransformedPreviewDocument = gql`
  query getImportTransformedPreview($jobId: String!, $query: String!) {
    importJob(id: $jobId) {
      previewQuery(query: $query) {
        columns {
          name
          type
        }
        result
      }
    }
  }
`;

/**
 * __useGetImportTransformedPreviewQuery__
 *
 * To run a query within a React component, call `useGetImportTransformedPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetImportTransformedPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetImportTransformedPreviewQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useGetImportTransformedPreviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetImportTransformedPreviewQuery,
    GetImportTransformedPreviewQueryVariables
  > &
    (
      | { variables: GetImportTransformedPreviewQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetImportTransformedPreviewQuery,
    GetImportTransformedPreviewQueryVariables
  >(GetImportTransformedPreviewDocument, options);
}
export function useGetImportTransformedPreviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetImportTransformedPreviewQuery,
    GetImportTransformedPreviewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetImportTransformedPreviewQuery,
    GetImportTransformedPreviewQueryVariables
  >(GetImportTransformedPreviewDocument, options);
}
export function useGetImportTransformedPreviewSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetImportTransformedPreviewQuery,
        GetImportTransformedPreviewQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetImportTransformedPreviewQuery,
    GetImportTransformedPreviewQueryVariables
  >(GetImportTransformedPreviewDocument, options);
}
export type GetImportTransformedPreviewQueryHookResult = ReturnType<
  typeof useGetImportTransformedPreviewQuery
>;
export type GetImportTransformedPreviewLazyQueryHookResult = ReturnType<
  typeof useGetImportTransformedPreviewLazyQuery
>;
export type GetImportTransformedPreviewSuspenseQueryHookResult = ReturnType<
  typeof useGetImportTransformedPreviewSuspenseQuery
>;
export type GetImportTransformedPreviewQueryResult = Apollo.QueryResult<
  GetImportTransformedPreviewQuery,
  GetImportTransformedPreviewQueryVariables
>;
export const ValidatePreviewDocument = gql`
  mutation validatePreview(
    $jobId: String!
    $mapping: MappingSpecInput!
    $transformation: ImportJobTransformationSettingsInput
    $page: SkipLimitPageSpec
  ) {
    validateImportJobPreviewMapping(
      id: $jobId
      mapping: $mapping
      transformation: $transformation
      page: $page
    ) {
      totalCount
      content {
        ...MappingError
      }
    }
  }
  ${MappingErrorFragmentDoc}
`;
export type ValidatePreviewMutationFn = Apollo.MutationFunction<
  ValidatePreviewMutation,
  ValidatePreviewMutationVariables
>;

/**
 * __useValidatePreviewMutation__
 *
 * To run a mutation, you first call `useValidatePreviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidatePreviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validatePreviewMutation, { data, loading, error }] = useValidatePreviewMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      mapping: // value for 'mapping'
 *      transformation: // value for 'transformation'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useValidatePreviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ValidatePreviewMutation,
    ValidatePreviewMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ValidatePreviewMutation,
    ValidatePreviewMutationVariables
  >(ValidatePreviewDocument, options);
}
export type ValidatePreviewMutationHookResult = ReturnType<
  typeof useValidatePreviewMutation
>;
export type ValidatePreviewMutationResult =
  Apollo.MutationResult<ValidatePreviewMutation>;
export type ValidatePreviewMutationOptions = Apollo.BaseMutationOptions<
  ValidatePreviewMutation,
  ValidatePreviewMutationVariables
>;
export const GetWarehousesDocument = gql`
  query GetWarehouses {
    warehouses(page: null) {
      content {
        ...WarehouseMeta
      }
    }
  }
  ${WarehouseMetaFragmentDoc}
`;

/**
 * __useGetWarehousesQuery__
 *
 * To run a query within a React component, call `useGetWarehousesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWarehousesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWarehousesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWarehousesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetWarehousesQuery,
    GetWarehousesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWarehousesQuery, GetWarehousesQueryVariables>(
    GetWarehousesDocument,
    options,
  );
}
export function useGetWarehousesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWarehousesQuery,
    GetWarehousesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWarehousesQuery, GetWarehousesQueryVariables>(
    GetWarehousesDocument,
    options,
  );
}
export function useGetWarehousesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetWarehousesQuery,
        GetWarehousesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetWarehousesQuery,
    GetWarehousesQueryVariables
  >(GetWarehousesDocument, options);
}
export type GetWarehousesQueryHookResult = ReturnType<
  typeof useGetWarehousesQuery
>;
export type GetWarehousesLazyQueryHookResult = ReturnType<
  typeof useGetWarehousesLazyQuery
>;
export type GetWarehousesSuspenseQueryHookResult = ReturnType<
  typeof useGetWarehousesSuspenseQuery
>;
export type GetWarehousesQueryResult = Apollo.QueryResult<
  GetWarehousesQuery,
  GetWarehousesQueryVariables
>;
export const GetWarehousesPermissionsDocument = gql`
  query GetWarehousesPermissions {
    warehouses(page: null) {
      content {
        id
        permissions {
          allowed
          token
        }
      }
    }
  }
`;

/**
 * __useGetWarehousesPermissionsQuery__
 *
 * To run a query within a React component, call `useGetWarehousesPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWarehousesPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWarehousesPermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWarehousesPermissionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetWarehousesPermissionsQuery,
    GetWarehousesPermissionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetWarehousesPermissionsQuery,
    GetWarehousesPermissionsQueryVariables
  >(GetWarehousesPermissionsDocument, options);
}
export function useGetWarehousesPermissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWarehousesPermissionsQuery,
    GetWarehousesPermissionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetWarehousesPermissionsQuery,
    GetWarehousesPermissionsQueryVariables
  >(GetWarehousesPermissionsDocument, options);
}
export function useGetWarehousesPermissionsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetWarehousesPermissionsQuery,
        GetWarehousesPermissionsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetWarehousesPermissionsQuery,
    GetWarehousesPermissionsQueryVariables
  >(GetWarehousesPermissionsDocument, options);
}
export type GetWarehousesPermissionsQueryHookResult = ReturnType<
  typeof useGetWarehousesPermissionsQuery
>;
export type GetWarehousesPermissionsLazyQueryHookResult = ReturnType<
  typeof useGetWarehousesPermissionsLazyQuery
>;
export type GetWarehousesPermissionsSuspenseQueryHookResult = ReturnType<
  typeof useGetWarehousesPermissionsSuspenseQuery
>;
export type GetWarehousesPermissionsQueryResult = Apollo.QueryResult<
  GetWarehousesPermissionsQuery,
  GetWarehousesPermissionsQueryVariables
>;
export const SendUserInvitationDocument = gql`
  mutation sendUserInvitation($input: SendUserInvitationLinkInput!) {
    sendUserInvitationLink(input: $input)
  }
`;
export type SendUserInvitationMutationFn = Apollo.MutationFunction<
  SendUserInvitationMutation,
  SendUserInvitationMutationVariables
>;

/**
 * __useSendUserInvitationMutation__
 *
 * To run a mutation, you first call `useSendUserInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendUserInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendUserInvitationMutation, { data, loading, error }] = useSendUserInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendUserInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendUserInvitationMutation,
    SendUserInvitationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendUserInvitationMutation,
    SendUserInvitationMutationVariables
  >(SendUserInvitationDocument, options);
}
export type SendUserInvitationMutationHookResult = ReturnType<
  typeof useSendUserInvitationMutation
>;
export type SendUserInvitationMutationResult =
  Apollo.MutationResult<SendUserInvitationMutation>;
export type SendUserInvitationMutationOptions = Apollo.BaseMutationOptions<
  SendUserInvitationMutation,
  SendUserInvitationMutationVariables
>;
export const LoadImportJobsDocument = gql`
  query loadImportJobs(
    $filter: ImportJobsFilter
    $sortBy: [ImportJobsSortBy!]
    $page: SkipLimitPageSpec
  ) {
    importJobs(filter: $filter, sortBy: $sortBy, page: $page) {
      ...ImportJobsConnection
    }
  }
  ${ImportJobsConnectionFragmentDoc}
`;

/**
 * __useLoadImportJobsQuery__
 *
 * To run a query within a React component, call `useLoadImportJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadImportJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadImportJobsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useLoadImportJobsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LoadImportJobsQuery,
    LoadImportJobsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadImportJobsQuery, LoadImportJobsQueryVariables>(
    LoadImportJobsDocument,
    options,
  );
}
export function useLoadImportJobsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadImportJobsQuery,
    LoadImportJobsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LoadImportJobsQuery, LoadImportJobsQueryVariables>(
    LoadImportJobsDocument,
    options,
  );
}
export function useLoadImportJobsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadImportJobsQuery,
        LoadImportJobsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadImportJobsQuery,
    LoadImportJobsQueryVariables
  >(LoadImportJobsDocument, options);
}
export type LoadImportJobsQueryHookResult = ReturnType<
  typeof useLoadImportJobsQuery
>;
export type LoadImportJobsLazyQueryHookResult = ReturnType<
  typeof useLoadImportJobsLazyQuery
>;
export type LoadImportJobsSuspenseQueryHookResult = ReturnType<
  typeof useLoadImportJobsSuspenseQuery
>;
export type LoadImportJobsQueryResult = Apollo.QueryResult<
  LoadImportJobsQuery,
  LoadImportJobsQueryVariables
>;
export const RemoveImportJobDocument = gql`
  mutation removeImportJob($id: String!, $options: RemoveImportJobOptions) {
    removeImportJobs(ids: [$id], options: $options)
  }
`;
export type RemoveImportJobMutationFn = Apollo.MutationFunction<
  RemoveImportJobMutation,
  RemoveImportJobMutationVariables
>;

/**
 * __useRemoveImportJobMutation__
 *
 * To run a mutation, you first call `useRemoveImportJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveImportJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeImportJobMutation, { data, loading, error }] = useRemoveImportJobMutation({
 *   variables: {
 *      id: // value for 'id'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useRemoveImportJobMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveImportJobMutation,
    RemoveImportJobMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveImportJobMutation,
    RemoveImportJobMutationVariables
  >(RemoveImportJobDocument, options);
}
export type RemoveImportJobMutationHookResult = ReturnType<
  typeof useRemoveImportJobMutation
>;
export type RemoveImportJobMutationResult =
  Apollo.MutationResult<RemoveImportJobMutation>;
export type RemoveImportJobMutationOptions = Apollo.BaseMutationOptions<
  RemoveImportJobMutation,
  RemoveImportJobMutationVariables
>;
export const RestartImportJobsDocument = gql`
  mutation restartImportJobs($input: BatchStartImportJobProcessingRequest!) {
    batchStartImportJobProcessing(input: $input) {
      ...ImportJob
    }
  }
  ${ImportJobFragmentDoc}
`;
export type RestartImportJobsMutationFn = Apollo.MutationFunction<
  RestartImportJobsMutation,
  RestartImportJobsMutationVariables
>;

/**
 * __useRestartImportJobsMutation__
 *
 * To run a mutation, you first call `useRestartImportJobsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestartImportJobsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restartImportJobsMutation, { data, loading, error }] = useRestartImportJobsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestartImportJobsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RestartImportJobsMutation,
    RestartImportJobsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RestartImportJobsMutation,
    RestartImportJobsMutationVariables
  >(RestartImportJobsDocument, options);
}
export type RestartImportJobsMutationHookResult = ReturnType<
  typeof useRestartImportJobsMutation
>;
export type RestartImportJobsMutationResult =
  Apollo.MutationResult<RestartImportJobsMutation>;
export type RestartImportJobsMutationOptions = Apollo.BaseMutationOptions<
  RestartImportJobsMutation,
  RestartImportJobsMutationVariables
>;
export const CreateImportPipelineJobDocument = gql`
  mutation createImportPipelineJob($input: CreateImportPipelineJobInput!) {
    createImportPipelineJob(input: $input) {
      ...ImportJob
    }
  }
  ${ImportJobFragmentDoc}
`;
export type CreateImportPipelineJobMutationFn = Apollo.MutationFunction<
  CreateImportPipelineJobMutation,
  CreateImportPipelineJobMutationVariables
>;

/**
 * __useCreateImportPipelineJobMutation__
 *
 * To run a mutation, you first call `useCreateImportPipelineJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateImportPipelineJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createImportPipelineJobMutation, { data, loading, error }] = useCreateImportPipelineJobMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateImportPipelineJobMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateImportPipelineJobMutation,
    CreateImportPipelineJobMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateImportPipelineJobMutation,
    CreateImportPipelineJobMutationVariables
  >(CreateImportPipelineJobDocument, options);
}
export type CreateImportPipelineJobMutationHookResult = ReturnType<
  typeof useCreateImportPipelineJobMutation
>;
export type CreateImportPipelineJobMutationResult =
  Apollo.MutationResult<CreateImportPipelineJobMutation>;
export type CreateImportPipelineJobMutationOptions = Apollo.BaseMutationOptions<
  CreateImportPipelineJobMutation,
  CreateImportPipelineJobMutationVariables
>;
export const LoadImportPipelinesDocument = gql`
  query loadImportPipelines(
    $filter: ImportPipelinesFilter
    $sortBy: [ImportPipelinesSortBy!]
    $page: SkipLimitPageSpec
  ) {
    importPipelines(filter: $filter, sortBy: $sortBy, page: $page) {
      ...ImportPipelinesConnection
    }
  }
  ${ImportPipelinesConnectionFragmentDoc}
`;

/**
 * __useLoadImportPipelinesQuery__
 *
 * To run a query within a React component, call `useLoadImportPipelinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadImportPipelinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadImportPipelinesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useLoadImportPipelinesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LoadImportPipelinesQuery,
    LoadImportPipelinesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadImportPipelinesQuery,
    LoadImportPipelinesQueryVariables
  >(LoadImportPipelinesDocument, options);
}
export function useLoadImportPipelinesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadImportPipelinesQuery,
    LoadImportPipelinesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadImportPipelinesQuery,
    LoadImportPipelinesQueryVariables
  >(LoadImportPipelinesDocument, options);
}
export function useLoadImportPipelinesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadImportPipelinesQuery,
        LoadImportPipelinesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadImportPipelinesQuery,
    LoadImportPipelinesQueryVariables
  >(LoadImportPipelinesDocument, options);
}
export type LoadImportPipelinesQueryHookResult = ReturnType<
  typeof useLoadImportPipelinesQuery
>;
export type LoadImportPipelinesLazyQueryHookResult = ReturnType<
  typeof useLoadImportPipelinesLazyQuery
>;
export type LoadImportPipelinesSuspenseQueryHookResult = ReturnType<
  typeof useLoadImportPipelinesSuspenseQuery
>;
export type LoadImportPipelinesQueryResult = Apollo.QueryResult<
  LoadImportPipelinesQuery,
  LoadImportPipelinesQueryVariables
>;
export const LoadImportPipelineDocument = gql`
  query loadImportPipeline($id: String!) {
    importPipeline(id: $id) {
      ...ImportPipeline
    }
  }
  ${ImportPipelineFragmentDoc}
`;

/**
 * __useLoadImportPipelineQuery__
 *
 * To run a query within a React component, call `useLoadImportPipelineQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadImportPipelineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadImportPipelineQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoadImportPipelineQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadImportPipelineQuery,
    LoadImportPipelineQueryVariables
  > &
    (
      | { variables: LoadImportPipelineQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadImportPipelineQuery,
    LoadImportPipelineQueryVariables
  >(LoadImportPipelineDocument, options);
}
export function useLoadImportPipelineLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadImportPipelineQuery,
    LoadImportPipelineQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadImportPipelineQuery,
    LoadImportPipelineQueryVariables
  >(LoadImportPipelineDocument, options);
}
export function useLoadImportPipelineSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadImportPipelineQuery,
        LoadImportPipelineQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadImportPipelineQuery,
    LoadImportPipelineQueryVariables
  >(LoadImportPipelineDocument, options);
}
export type LoadImportPipelineQueryHookResult = ReturnType<
  typeof useLoadImportPipelineQuery
>;
export type LoadImportPipelineLazyQueryHookResult = ReturnType<
  typeof useLoadImportPipelineLazyQuery
>;
export type LoadImportPipelineSuspenseQueryHookResult = ReturnType<
  typeof useLoadImportPipelineSuspenseQuery
>;
export type LoadImportPipelineQueryResult = Apollo.QueryResult<
  LoadImportPipelineQuery,
  LoadImportPipelineQueryVariables
>;
export const CreateImportPipelineDocument = gql`
  mutation createImportPipeline($input: CreateImportPipelineInput!) {
    createImportPipeline(input: $input) {
      ...ImportPipeline
    }
  }
  ${ImportPipelineFragmentDoc}
`;
export type CreateImportPipelineMutationFn = Apollo.MutationFunction<
  CreateImportPipelineMutation,
  CreateImportPipelineMutationVariables
>;

/**
 * __useCreateImportPipelineMutation__
 *
 * To run a mutation, you first call `useCreateImportPipelineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateImportPipelineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createImportPipelineMutation, { data, loading, error }] = useCreateImportPipelineMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateImportPipelineMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateImportPipelineMutation,
    CreateImportPipelineMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateImportPipelineMutation,
    CreateImportPipelineMutationVariables
  >(CreateImportPipelineDocument, options);
}
export type CreateImportPipelineMutationHookResult = ReturnType<
  typeof useCreateImportPipelineMutation
>;
export type CreateImportPipelineMutationResult =
  Apollo.MutationResult<CreateImportPipelineMutation>;
export type CreateImportPipelineMutationOptions = Apollo.BaseMutationOptions<
  CreateImportPipelineMutation,
  CreateImportPipelineMutationVariables
>;
export const CreateImportPipelineFromImportJobDocument = gql`
  mutation createImportPipelineFromImportJob(
    $input: CreateImportPipelineFromImportJobInput!
  ) {
    createImportPipelineFromImportJob(input: $input) {
      ...ImportPipeline
    }
  }
  ${ImportPipelineFragmentDoc}
`;
export type CreateImportPipelineFromImportJobMutationFn =
  Apollo.MutationFunction<
    CreateImportPipelineFromImportJobMutation,
    CreateImportPipelineFromImportJobMutationVariables
  >;

/**
 * __useCreateImportPipelineFromImportJobMutation__
 *
 * To run a mutation, you first call `useCreateImportPipelineFromImportJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateImportPipelineFromImportJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createImportPipelineFromImportJobMutation, { data, loading, error }] = useCreateImportPipelineFromImportJobMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateImportPipelineFromImportJobMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateImportPipelineFromImportJobMutation,
    CreateImportPipelineFromImportJobMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateImportPipelineFromImportJobMutation,
    CreateImportPipelineFromImportJobMutationVariables
  >(CreateImportPipelineFromImportJobDocument, options);
}
export type CreateImportPipelineFromImportJobMutationHookResult = ReturnType<
  typeof useCreateImportPipelineFromImportJobMutation
>;
export type CreateImportPipelineFromImportJobMutationResult =
  Apollo.MutationResult<CreateImportPipelineFromImportJobMutation>;
export type CreateImportPipelineFromImportJobMutationOptions =
  Apollo.BaseMutationOptions<
    CreateImportPipelineFromImportJobMutation,
    CreateImportPipelineFromImportJobMutationVariables
  >;
export const RemoveImportPipelineDocument = gql`
  mutation removeImportPipeline($id: String!) {
    removeImportPipeline(id: $id)
  }
`;
export type RemoveImportPipelineMutationFn = Apollo.MutationFunction<
  RemoveImportPipelineMutation,
  RemoveImportPipelineMutationVariables
>;

/**
 * __useRemoveImportPipelineMutation__
 *
 * To run a mutation, you first call `useRemoveImportPipelineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveImportPipelineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeImportPipelineMutation, { data, loading, error }] = useRemoveImportPipelineMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveImportPipelineMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveImportPipelineMutation,
    RemoveImportPipelineMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveImportPipelineMutation,
    RemoveImportPipelineMutationVariables
  >(RemoveImportPipelineDocument, options);
}
export type RemoveImportPipelineMutationHookResult = ReturnType<
  typeof useRemoveImportPipelineMutation
>;
export type RemoveImportPipelineMutationResult =
  Apollo.MutationResult<RemoveImportPipelineMutation>;
export type RemoveImportPipelineMutationOptions = Apollo.BaseMutationOptions<
  RemoveImportPipelineMutation,
  RemoveImportPipelineMutationVariables
>;
export const UpdateImportPipelineDocument = gql`
  mutation updateImportPipeline($input: UpdateImportPipelineInput!) {
    updateImportPipeline(input: $input) {
      ...ImportPipeline
    }
  }
  ${ImportPipelineFragmentDoc}
`;
export type UpdateImportPipelineMutationFn = Apollo.MutationFunction<
  UpdateImportPipelineMutation,
  UpdateImportPipelineMutationVariables
>;

/**
 * __useUpdateImportPipelineMutation__
 *
 * To run a mutation, you first call `useUpdateImportPipelineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateImportPipelineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateImportPipelineMutation, { data, loading, error }] = useUpdateImportPipelineMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateImportPipelineMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateImportPipelineMutation,
    UpdateImportPipelineMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateImportPipelineMutation,
    UpdateImportPipelineMutationVariables
  >(UpdateImportPipelineDocument, options);
}
export type UpdateImportPipelineMutationHookResult = ReturnType<
  typeof useUpdateImportPipelineMutation
>;
export type UpdateImportPipelineMutationResult =
  Apollo.MutationResult<UpdateImportPipelineMutation>;
export type UpdateImportPipelineMutationOptions = Apollo.BaseMutationOptions<
  UpdateImportPipelineMutation,
  UpdateImportPipelineMutationVariables
>;
export const TestImportPipelineSftpConnectorDocument = gql`
  mutation testImportPipelineSftpConnector(
    $sftp: TestImportPipelineSftpConnectorInput!
  ) {
    testImportPipelineSftpConnector(sftp: $sftp) {
      ...SftpTestResult
    }
  }
  ${SftpTestResultFragmentDoc}
`;
export type TestImportPipelineSftpConnectorMutationFn = Apollo.MutationFunction<
  TestImportPipelineSftpConnectorMutation,
  TestImportPipelineSftpConnectorMutationVariables
>;

/**
 * __useTestImportPipelineSftpConnectorMutation__
 *
 * To run a mutation, you first call `useTestImportPipelineSftpConnectorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTestImportPipelineSftpConnectorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [testImportPipelineSftpConnectorMutation, { data, loading, error }] = useTestImportPipelineSftpConnectorMutation({
 *   variables: {
 *      sftp: // value for 'sftp'
 *   },
 * });
 */
export function useTestImportPipelineSftpConnectorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TestImportPipelineSftpConnectorMutation,
    TestImportPipelineSftpConnectorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TestImportPipelineSftpConnectorMutation,
    TestImportPipelineSftpConnectorMutationVariables
  >(TestImportPipelineSftpConnectorDocument, options);
}
export type TestImportPipelineSftpConnectorMutationHookResult = ReturnType<
  typeof useTestImportPipelineSftpConnectorMutation
>;
export type TestImportPipelineSftpConnectorMutationResult =
  Apollo.MutationResult<TestImportPipelineSftpConnectorMutation>;
export type TestImportPipelineSftpConnectorMutationOptions =
  Apollo.BaseMutationOptions<
    TestImportPipelineSftpConnectorMutation,
    TestImportPipelineSftpConnectorMutationVariables
  >;
export const BeginImportPipelineMultipartUploadDocument = gql`
  mutation beginImportPipelineMultipartUpload(
    $input: BeginImportPipelineMultipartUploadInput!
  ) {
    beginImportPipelineMultipartUpload(input: $input) {
      uploadId
      uploadLinks
    }
  }
`;
export type BeginImportPipelineMultipartUploadMutationFn =
  Apollo.MutationFunction<
    BeginImportPipelineMultipartUploadMutation,
    BeginImportPipelineMultipartUploadMutationVariables
  >;

/**
 * __useBeginImportPipelineMultipartUploadMutation__
 *
 * To run a mutation, you first call `useBeginImportPipelineMultipartUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBeginImportPipelineMultipartUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [beginImportPipelineMultipartUploadMutation, { data, loading, error }] = useBeginImportPipelineMultipartUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBeginImportPipelineMultipartUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BeginImportPipelineMultipartUploadMutation,
    BeginImportPipelineMultipartUploadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BeginImportPipelineMultipartUploadMutation,
    BeginImportPipelineMultipartUploadMutationVariables
  >(BeginImportPipelineMultipartUploadDocument, options);
}
export type BeginImportPipelineMultipartUploadMutationHookResult = ReturnType<
  typeof useBeginImportPipelineMultipartUploadMutation
>;
export type BeginImportPipelineMultipartUploadMutationResult =
  Apollo.MutationResult<BeginImportPipelineMultipartUploadMutation>;
export type BeginImportPipelineMultipartUploadMutationOptions =
  Apollo.BaseMutationOptions<
    BeginImportPipelineMultipartUploadMutation,
    BeginImportPipelineMultipartUploadMutationVariables
  >;
export const CompleteImportPipelineMultipartUploadDocument = gql`
  mutation completeImportPipelineMultipartUpload(
    $input: CompletedImportPipelineMultipartUploadInput!
  ) {
    completeImportPipelineMultipartUpload(input: $input) {
      id
    }
  }
`;
export type CompleteImportPipelineMultipartUploadMutationFn =
  Apollo.MutationFunction<
    CompleteImportPipelineMultipartUploadMutation,
    CompleteImportPipelineMultipartUploadMutationVariables
  >;

/**
 * __useCompleteImportPipelineMultipartUploadMutation__
 *
 * To run a mutation, you first call `useCompleteImportPipelineMultipartUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteImportPipelineMultipartUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeImportPipelineMultipartUploadMutation, { data, loading, error }] = useCompleteImportPipelineMultipartUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteImportPipelineMultipartUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteImportPipelineMultipartUploadMutation,
    CompleteImportPipelineMultipartUploadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompleteImportPipelineMultipartUploadMutation,
    CompleteImportPipelineMultipartUploadMutationVariables
  >(CompleteImportPipelineMultipartUploadDocument, options);
}
export type CompleteImportPipelineMultipartUploadMutationHookResult =
  ReturnType<typeof useCompleteImportPipelineMultipartUploadMutation>;
export type CompleteImportPipelineMultipartUploadMutationResult =
  Apollo.MutationResult<CompleteImportPipelineMultipartUploadMutation>;
export type CompleteImportPipelineMultipartUploadMutationOptions =
  Apollo.BaseMutationOptions<
    CompleteImportPipelineMultipartUploadMutation,
    CompleteImportPipelineMultipartUploadMutationVariables
  >;
export const CreateItemSetDocument = gql`
  mutation createItemSet($input: CreateItemSetInput!) {
    createItemSet(input: $input) {
      id
    }
  }
`;
export type CreateItemSetMutationFn = Apollo.MutationFunction<
  CreateItemSetMutation,
  CreateItemSetMutationVariables
>;

/**
 * __useCreateItemSetMutation__
 *
 * To run a mutation, you first call `useCreateItemSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateItemSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createItemSetMutation, { data, loading, error }] = useCreateItemSetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateItemSetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateItemSetMutation,
    CreateItemSetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateItemSetMutation,
    CreateItemSetMutationVariables
  >(CreateItemSetDocument, options);
}
export type CreateItemSetMutationHookResult = ReturnType<
  typeof useCreateItemSetMutation
>;
export type CreateItemSetMutationResult =
  Apollo.MutationResult<CreateItemSetMutation>;
export type CreateItemSetMutationOptions = Apollo.BaseMutationOptions<
  CreateItemSetMutation,
  CreateItemSetMutationVariables
>;
export const UpdateItemSetDocument = gql`
  mutation updateItemSet($input: UpdateItemSetInput!) {
    updateItemSet(input: $input) {
      id
      description
    }
  }
`;
export type UpdateItemSetMutationFn = Apollo.MutationFunction<
  UpdateItemSetMutation,
  UpdateItemSetMutationVariables
>;

/**
 * __useUpdateItemSetMutation__
 *
 * To run a mutation, you first call `useUpdateItemSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateItemSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateItemSetMutation, { data, loading, error }] = useUpdateItemSetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateItemSetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateItemSetMutation,
    UpdateItemSetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateItemSetMutation,
    UpdateItemSetMutationVariables
  >(UpdateItemSetDocument, options);
}
export type UpdateItemSetMutationHookResult = ReturnType<
  typeof useUpdateItemSetMutation
>;
export type UpdateItemSetMutationResult =
  Apollo.MutationResult<UpdateItemSetMutation>;
export type UpdateItemSetMutationOptions = Apollo.BaseMutationOptions<
  UpdateItemSetMutation,
  UpdateItemSetMutationVariables
>;
export const RemoveItemSetDocument = gql`
  mutation removeItemSet($id: ID!) {
    removeItemSet(id: $id)
  }
`;
export type RemoveItemSetMutationFn = Apollo.MutationFunction<
  RemoveItemSetMutation,
  RemoveItemSetMutationVariables
>;

/**
 * __useRemoveItemSetMutation__
 *
 * To run a mutation, you first call `useRemoveItemSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveItemSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeItemSetMutation, { data, loading, error }] = useRemoveItemSetMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveItemSetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveItemSetMutation,
    RemoveItemSetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveItemSetMutation,
    RemoveItemSetMutationVariables
  >(RemoveItemSetDocument, options);
}
export type RemoveItemSetMutationHookResult = ReturnType<
  typeof useRemoveItemSetMutation
>;
export type RemoveItemSetMutationResult =
  Apollo.MutationResult<RemoveItemSetMutation>;
export type RemoveItemSetMutationOptions = Apollo.BaseMutationOptions<
  RemoveItemSetMutation,
  RemoveItemSetMutationVariables
>;
export const LoadItemSetDataDocument = gql`
  query loadItemSetData(
    $itemSetId: ID!
    $page: SkipLimitPageSpec
    $filter: ItemUomOrItemsSearchFilter
    $sortBy: [ItemUomOrItemsSortBy!]
  ) {
    itemSet(id: $itemSetId) {
      itemUoms(page: $page, filter: $filter, sortBy: $sortBy) {
        ...ItemSetData
      }
    }
  }
  ${ItemSetDataFragmentDoc}
`;

/**
 * __useLoadItemSetDataQuery__
 *
 * To run a query within a React component, call `useLoadItemSetDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadItemSetDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadItemSetDataQuery({
 *   variables: {
 *      itemSetId: // value for 'itemSetId'
 *      page: // value for 'page'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useLoadItemSetDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadItemSetDataQuery,
    LoadItemSetDataQueryVariables
  > &
    (
      | { variables: LoadItemSetDataQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadItemSetDataQuery, LoadItemSetDataQueryVariables>(
    LoadItemSetDataDocument,
    options,
  );
}
export function useLoadItemSetDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadItemSetDataQuery,
    LoadItemSetDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadItemSetDataQuery,
    LoadItemSetDataQueryVariables
  >(LoadItemSetDataDocument, options);
}
export function useLoadItemSetDataSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadItemSetDataQuery,
        LoadItemSetDataQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadItemSetDataQuery,
    LoadItemSetDataQueryVariables
  >(LoadItemSetDataDocument, options);
}
export type LoadItemSetDataQueryHookResult = ReturnType<
  typeof useLoadItemSetDataQuery
>;
export type LoadItemSetDataLazyQueryHookResult = ReturnType<
  typeof useLoadItemSetDataLazyQuery
>;
export type LoadItemSetDataSuspenseQueryHookResult = ReturnType<
  typeof useLoadItemSetDataSuspenseQuery
>;
export type LoadItemSetDataQueryResult = Apollo.QueryResult<
  LoadItemSetDataQuery,
  LoadItemSetDataQueryVariables
>;
export const LoadItemSetDocument = gql`
  query loadItemSet($itemSetId: ID!) {
    itemSet(id: $itemSetId) {
      ...ItemSet
    }
  }
  ${ItemSetFragmentDoc}
`;

/**
 * __useLoadItemSetQuery__
 *
 * To run a query within a React component, call `useLoadItemSetQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadItemSetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadItemSetQuery({
 *   variables: {
 *      itemSetId: // value for 'itemSetId'
 *   },
 * });
 */
export function useLoadItemSetQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadItemSetQuery,
    LoadItemSetQueryVariables
  > &
    (
      | { variables: LoadItemSetQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadItemSetQuery, LoadItemSetQueryVariables>(
    LoadItemSetDocument,
    options,
  );
}
export function useLoadItemSetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadItemSetQuery,
    LoadItemSetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LoadItemSetQuery, LoadItemSetQueryVariables>(
    LoadItemSetDocument,
    options,
  );
}
export function useLoadItemSetSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadItemSetQuery,
        LoadItemSetQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<LoadItemSetQuery, LoadItemSetQueryVariables>(
    LoadItemSetDocument,
    options,
  );
}
export type LoadItemSetQueryHookResult = ReturnType<typeof useLoadItemSetQuery>;
export type LoadItemSetLazyQueryHookResult = ReturnType<
  typeof useLoadItemSetLazyQuery
>;
export type LoadItemSetSuspenseQueryHookResult = ReturnType<
  typeof useLoadItemSetSuspenseQuery
>;
export type LoadItemSetQueryResult = Apollo.QueryResult<
  LoadItemSetQuery,
  LoadItemSetQueryVariables
>;
export const FindItemSetFilterValuesDocument = gql`
  query findItemSetFilterValues(
    $input: FindItemFilterValuesInput!
    $page: SkipLimitPageSpec
  ) {
    findItemFilterValues(input: $input, page: $page) {
      ...FilterValuesConnection
    }
  }
  ${FilterValuesConnectionFragmentDoc}
`;

/**
 * __useFindItemSetFilterValuesQuery__
 *
 * To run a query within a React component, call `useFindItemSetFilterValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindItemSetFilterValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindItemSetFilterValuesQuery({
 *   variables: {
 *      input: // value for 'input'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useFindItemSetFilterValuesQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindItemSetFilterValuesQuery,
    FindItemSetFilterValuesQueryVariables
  > &
    (
      | { variables: FindItemSetFilterValuesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindItemSetFilterValuesQuery,
    FindItemSetFilterValuesQueryVariables
  >(FindItemSetFilterValuesDocument, options);
}
export function useFindItemSetFilterValuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindItemSetFilterValuesQuery,
    FindItemSetFilterValuesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindItemSetFilterValuesQuery,
    FindItemSetFilterValuesQueryVariables
  >(FindItemSetFilterValuesDocument, options);
}
export function useFindItemSetFilterValuesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindItemSetFilterValuesQuery,
        FindItemSetFilterValuesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FindItemSetFilterValuesQuery,
    FindItemSetFilterValuesQueryVariables
  >(FindItemSetFilterValuesDocument, options);
}
export type FindItemSetFilterValuesQueryHookResult = ReturnType<
  typeof useFindItemSetFilterValuesQuery
>;
export type FindItemSetFilterValuesLazyQueryHookResult = ReturnType<
  typeof useFindItemSetFilterValuesLazyQuery
>;
export type FindItemSetFilterValuesSuspenseQueryHookResult = ReturnType<
  typeof useFindItemSetFilterValuesSuspenseQuery
>;
export type FindItemSetFilterValuesQueryResult = Apollo.QueryResult<
  FindItemSetFilterValuesQuery,
  FindItemSetFilterValuesQueryVariables
>;
export const FindItemsByFilterDocument = gql`
  query findItemsByFilter(
    $input: FindItemsByFilterInput!
    $page: SkipLimitPageSpec
    $filter: ItemsFilter
    $sortBy: [ItemsSortBy!]
  ) {
    findItemsByFilter(
      input: $input
      page: $page
      filter: $filter
      sortBy: $sortBy
    ) {
      totalCount
      content {
        ...ItemData
      }
    }
  }
  ${ItemDataFragmentDoc}
`;

/**
 * __useFindItemsByFilterQuery__
 *
 * To run a query within a React component, call `useFindItemsByFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindItemsByFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindItemsByFilterQuery({
 *   variables: {
 *      input: // value for 'input'
 *      page: // value for 'page'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useFindItemsByFilterQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindItemsByFilterQuery,
    FindItemsByFilterQueryVariables
  > &
    (
      | { variables: FindItemsByFilterQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindItemsByFilterQuery,
    FindItemsByFilterQueryVariables
  >(FindItemsByFilterDocument, options);
}
export function useFindItemsByFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindItemsByFilterQuery,
    FindItemsByFilterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindItemsByFilterQuery,
    FindItemsByFilterQueryVariables
  >(FindItemsByFilterDocument, options);
}
export function useFindItemsByFilterSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindItemsByFilterQuery,
        FindItemsByFilterQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FindItemsByFilterQuery,
    FindItemsByFilterQueryVariables
  >(FindItemsByFilterDocument, options);
}
export type FindItemsByFilterQueryHookResult = ReturnType<
  typeof useFindItemsByFilterQuery
>;
export type FindItemsByFilterLazyQueryHookResult = ReturnType<
  typeof useFindItemsByFilterLazyQuery
>;
export type FindItemsByFilterSuspenseQueryHookResult = ReturnType<
  typeof useFindItemsByFilterSuspenseQuery
>;
export type FindItemsByFilterQueryResult = Apollo.QueryResult<
  FindItemsByFilterQuery,
  FindItemsByFilterQueryVariables
>;
export const FindItemsByPolicyDocument = gql`
  query findItemsByPolicy(
    $input: ApplyStackingPolicyInput!
    $page: SkipLimitPageSpec
    $filter: ItemsFilter
    $sortBy: [ItemsSortBy!]
  ) {
    applyStackingPolicyRules(
      input: $input
      page: $page
      filter: $filter
      sortBy: $sortBy
    ) {
      totalCount
      content {
        ...ItemStackingDetails
      }
    }
  }
  ${ItemStackingDetailsFragmentDoc}
`;

/**
 * __useFindItemsByPolicyQuery__
 *
 * To run a query within a React component, call `useFindItemsByPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindItemsByPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindItemsByPolicyQuery({
 *   variables: {
 *      input: // value for 'input'
 *      page: // value for 'page'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useFindItemsByPolicyQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindItemsByPolicyQuery,
    FindItemsByPolicyQueryVariables
  > &
    (
      | { variables: FindItemsByPolicyQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindItemsByPolicyQuery,
    FindItemsByPolicyQueryVariables
  >(FindItemsByPolicyDocument, options);
}
export function useFindItemsByPolicyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindItemsByPolicyQuery,
    FindItemsByPolicyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindItemsByPolicyQuery,
    FindItemsByPolicyQueryVariables
  >(FindItemsByPolicyDocument, options);
}
export function useFindItemsByPolicySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindItemsByPolicyQuery,
        FindItemsByPolicyQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FindItemsByPolicyQuery,
    FindItemsByPolicyQueryVariables
  >(FindItemsByPolicyDocument, options);
}
export type FindItemsByPolicyQueryHookResult = ReturnType<
  typeof useFindItemsByPolicyQuery
>;
export type FindItemsByPolicyLazyQueryHookResult = ReturnType<
  typeof useFindItemsByPolicyLazyQuery
>;
export type FindItemsByPolicySuspenseQueryHookResult = ReturnType<
  typeof useFindItemsByPolicySuspenseQuery
>;
export type FindItemsByPolicyQueryResult = Apollo.QueryResult<
  FindItemsByPolicyQuery,
  FindItemsByPolicyQueryVariables
>;
export const LoadItemsCountByPolicyDocument = gql`
  query loadItemsCountByPolicy($input: ApplyStackingPolicyInput!) {
    applyStackingPolicyRules(input: $input) {
      ...StackingTotals
    }
  }
  ${StackingTotalsFragmentDoc}
`;

/**
 * __useLoadItemsCountByPolicyQuery__
 *
 * To run a query within a React component, call `useLoadItemsCountByPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadItemsCountByPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadItemsCountByPolicyQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoadItemsCountByPolicyQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadItemsCountByPolicyQuery,
    LoadItemsCountByPolicyQueryVariables
  > &
    (
      | { variables: LoadItemsCountByPolicyQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadItemsCountByPolicyQuery,
    LoadItemsCountByPolicyQueryVariables
  >(LoadItemsCountByPolicyDocument, options);
}
export function useLoadItemsCountByPolicyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadItemsCountByPolicyQuery,
    LoadItemsCountByPolicyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadItemsCountByPolicyQuery,
    LoadItemsCountByPolicyQueryVariables
  >(LoadItemsCountByPolicyDocument, options);
}
export function useLoadItemsCountByPolicySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadItemsCountByPolicyQuery,
        LoadItemsCountByPolicyQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadItemsCountByPolicyQuery,
    LoadItemsCountByPolicyQueryVariables
  >(LoadItemsCountByPolicyDocument, options);
}
export type LoadItemsCountByPolicyQueryHookResult = ReturnType<
  typeof useLoadItemsCountByPolicyQuery
>;
export type LoadItemsCountByPolicyLazyQueryHookResult = ReturnType<
  typeof useLoadItemsCountByPolicyLazyQuery
>;
export type LoadItemsCountByPolicySuspenseQueryHookResult = ReturnType<
  typeof useLoadItemsCountByPolicySuspenseQuery
>;
export type LoadItemsCountByPolicyQueryResult = Apollo.QueryResult<
  LoadItemsCountByPolicyQuery,
  LoadItemsCountByPolicyQueryVariables
>;
export const LoadAllItemSetsMetaDocument = gql`
  query loadAllItemSetsMeta($warehouseId: ID!) {
    warehouse(id: $warehouseId) {
      itemSets(page: { limit: null }) {
        content {
          ...ItemSetMeta
        }
      }
    }
  }
  ${ItemSetMetaFragmentDoc}
`;

/**
 * __useLoadAllItemSetsMetaQuery__
 *
 * To run a query within a React component, call `useLoadAllItemSetsMetaQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadAllItemSetsMetaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadAllItemSetsMetaQuery({
 *   variables: {
 *      warehouseId: // value for 'warehouseId'
 *   },
 * });
 */
export function useLoadAllItemSetsMetaQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadAllItemSetsMetaQuery,
    LoadAllItemSetsMetaQueryVariables
  > &
    (
      | { variables: LoadAllItemSetsMetaQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadAllItemSetsMetaQuery,
    LoadAllItemSetsMetaQueryVariables
  >(LoadAllItemSetsMetaDocument, options);
}
export function useLoadAllItemSetsMetaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadAllItemSetsMetaQuery,
    LoadAllItemSetsMetaQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadAllItemSetsMetaQuery,
    LoadAllItemSetsMetaQueryVariables
  >(LoadAllItemSetsMetaDocument, options);
}
export function useLoadAllItemSetsMetaSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadAllItemSetsMetaQuery,
        LoadAllItemSetsMetaQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadAllItemSetsMetaQuery,
    LoadAllItemSetsMetaQueryVariables
  >(LoadAllItemSetsMetaDocument, options);
}
export type LoadAllItemSetsMetaQueryHookResult = ReturnType<
  typeof useLoadAllItemSetsMetaQuery
>;
export type LoadAllItemSetsMetaLazyQueryHookResult = ReturnType<
  typeof useLoadAllItemSetsMetaLazyQuery
>;
export type LoadAllItemSetsMetaSuspenseQueryHookResult = ReturnType<
  typeof useLoadAllItemSetsMetaSuspenseQuery
>;
export type LoadAllItemSetsMetaQueryResult = Apollo.QueryResult<
  LoadAllItemSetsMetaQuery,
  LoadAllItemSetsMetaQueryVariables
>;
export const LoadItemSetSummaryDocument = gql`
  query loadItemSetSummary($itemSetId: ID!) {
    itemSet(id: $itemSetId) {
      ...ItemSetMeta
      summary {
        ...ItemSetSummary
      }
    }
  }
  ${ItemSetMetaFragmentDoc}
  ${ItemSetSummaryFragmentDoc}
`;

/**
 * __useLoadItemSetSummaryQuery__
 *
 * To run a query within a React component, call `useLoadItemSetSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadItemSetSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadItemSetSummaryQuery({
 *   variables: {
 *      itemSetId: // value for 'itemSetId'
 *   },
 * });
 */
export function useLoadItemSetSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadItemSetSummaryQuery,
    LoadItemSetSummaryQueryVariables
  > &
    (
      | { variables: LoadItemSetSummaryQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadItemSetSummaryQuery,
    LoadItemSetSummaryQueryVariables
  >(LoadItemSetSummaryDocument, options);
}
export function useLoadItemSetSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadItemSetSummaryQuery,
    LoadItemSetSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadItemSetSummaryQuery,
    LoadItemSetSummaryQueryVariables
  >(LoadItemSetSummaryDocument, options);
}
export function useLoadItemSetSummarySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadItemSetSummaryQuery,
        LoadItemSetSummaryQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadItemSetSummaryQuery,
    LoadItemSetSummaryQueryVariables
  >(LoadItemSetSummaryDocument, options);
}
export type LoadItemSetSummaryQueryHookResult = ReturnType<
  typeof useLoadItemSetSummaryQuery
>;
export type LoadItemSetSummaryLazyQueryHookResult = ReturnType<
  typeof useLoadItemSetSummaryLazyQuery
>;
export type LoadItemSetSummarySuspenseQueryHookResult = ReturnType<
  typeof useLoadItemSetSummarySuspenseQuery
>;
export type LoadItemSetSummaryQueryResult = Apollo.QueryResult<
  LoadItemSetSummaryQuery,
  LoadItemSetSummaryQueryVariables
>;
export const LoadItemSetUomsDocument = gql`
  query loadItemSetUoms($itemSetId: ID!) {
    itemSet(id: $itemSetId) {
      uomTypes {
        uom
      }
    }
  }
`;

/**
 * __useLoadItemSetUomsQuery__
 *
 * To run a query within a React component, call `useLoadItemSetUomsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadItemSetUomsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadItemSetUomsQuery({
 *   variables: {
 *      itemSetId: // value for 'itemSetId'
 *   },
 * });
 */
export function useLoadItemSetUomsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadItemSetUomsQuery,
    LoadItemSetUomsQueryVariables
  > &
    (
      | { variables: LoadItemSetUomsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadItemSetUomsQuery, LoadItemSetUomsQueryVariables>(
    LoadItemSetUomsDocument,
    options,
  );
}
export function useLoadItemSetUomsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadItemSetUomsQuery,
    LoadItemSetUomsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadItemSetUomsQuery,
    LoadItemSetUomsQueryVariables
  >(LoadItemSetUomsDocument, options);
}
export function useLoadItemSetUomsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadItemSetUomsQuery,
        LoadItemSetUomsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadItemSetUomsQuery,
    LoadItemSetUomsQueryVariables
  >(LoadItemSetUomsDocument, options);
}
export type LoadItemSetUomsQueryHookResult = ReturnType<
  typeof useLoadItemSetUomsQuery
>;
export type LoadItemSetUomsLazyQueryHookResult = ReturnType<
  typeof useLoadItemSetUomsLazyQuery
>;
export type LoadItemSetUomsSuspenseQueryHookResult = ReturnType<
  typeof useLoadItemSetUomsSuspenseQuery
>;
export type LoadItemSetUomsQueryResult = Apollo.QueryResult<
  LoadItemSetUomsQuery,
  LoadItemSetUomsQueryVariables
>;
export const CreateLayoutImportDocument = gql`
  mutation createLayoutImport(
    $warehouseId: ID!
    $input: CreateLayoutImportInput!
  ) {
    createLayoutImport(warehouseId: $warehouseId, input: $input) {
      id
    }
  }
`;
export type CreateLayoutImportMutationFn = Apollo.MutationFunction<
  CreateLayoutImportMutation,
  CreateLayoutImportMutationVariables
>;

/**
 * __useCreateLayoutImportMutation__
 *
 * To run a mutation, you first call `useCreateLayoutImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLayoutImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLayoutImportMutation, { data, loading, error }] = useCreateLayoutImportMutation({
 *   variables: {
 *      warehouseId: // value for 'warehouseId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLayoutImportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateLayoutImportMutation,
    CreateLayoutImportMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateLayoutImportMutation,
    CreateLayoutImportMutationVariables
  >(CreateLayoutImportDocument, options);
}
export type CreateLayoutImportMutationHookResult = ReturnType<
  typeof useCreateLayoutImportMutation
>;
export type CreateLayoutImportMutationResult =
  Apollo.MutationResult<CreateLayoutImportMutation>;
export type CreateLayoutImportMutationOptions = Apollo.BaseMutationOptions<
  CreateLayoutImportMutation,
  CreateLayoutImportMutationVariables
>;
export const UpdateLayoutImportDocument = gql`
  mutation updateLayoutImport($layoutImportInput: UpdateLayoutImportInput!) {
    updateLayoutImport(input: $layoutImportInput) {
      id
      description
    }
  }
`;
export type UpdateLayoutImportMutationFn = Apollo.MutationFunction<
  UpdateLayoutImportMutation,
  UpdateLayoutImportMutationVariables
>;

/**
 * __useUpdateLayoutImportMutation__
 *
 * To run a mutation, you first call `useUpdateLayoutImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLayoutImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLayoutImportMutation, { data, loading, error }] = useUpdateLayoutImportMutation({
 *   variables: {
 *      layoutImportInput: // value for 'layoutImportInput'
 *   },
 * });
 */
export function useUpdateLayoutImportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLayoutImportMutation,
    UpdateLayoutImportMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateLayoutImportMutation,
    UpdateLayoutImportMutationVariables
  >(UpdateLayoutImportDocument, options);
}
export type UpdateLayoutImportMutationHookResult = ReturnType<
  typeof useUpdateLayoutImportMutation
>;
export type UpdateLayoutImportMutationResult =
  Apollo.MutationResult<UpdateLayoutImportMutation>;
export type UpdateLayoutImportMutationOptions = Apollo.BaseMutationOptions<
  UpdateLayoutImportMutation,
  UpdateLayoutImportMutationVariables
>;
export const RemoveLayoutImportDocument = gql`
  mutation removeLayoutImport($layoutImportId: ID!) {
    removeLayoutImport(layoutImportId: $layoutImportId)
  }
`;
export type RemoveLayoutImportMutationFn = Apollo.MutationFunction<
  RemoveLayoutImportMutation,
  RemoveLayoutImportMutationVariables
>;

/**
 * __useRemoveLayoutImportMutation__
 *
 * To run a mutation, you first call `useRemoveLayoutImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveLayoutImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeLayoutImportMutation, { data, loading, error }] = useRemoveLayoutImportMutation({
 *   variables: {
 *      layoutImportId: // value for 'layoutImportId'
 *   },
 * });
 */
export function useRemoveLayoutImportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveLayoutImportMutation,
    RemoveLayoutImportMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveLayoutImportMutation,
    RemoveLayoutImportMutationVariables
  >(RemoveLayoutImportDocument, options);
}
export type RemoveLayoutImportMutationHookResult = ReturnType<
  typeof useRemoveLayoutImportMutation
>;
export type RemoveLayoutImportMutationResult =
  Apollo.MutationResult<RemoveLayoutImportMutation>;
export type RemoveLayoutImportMutationOptions = Apollo.BaseMutationOptions<
  RemoveLayoutImportMutation,
  RemoveLayoutImportMutationVariables
>;
export const LoadLayoutImportSummaryDocument = gql`
  query loadLayoutImportSummary($layoutImportId: ID!) {
    layoutImport(id: $layoutImportId) {
      ...LayoutImportMeta
      summary {
        ...LayoutImportSummary
      }
    }
  }
  ${LayoutImportMetaFragmentDoc}
  ${LayoutImportSummaryFragmentDoc}
`;

/**
 * __useLoadLayoutImportSummaryQuery__
 *
 * To run a query within a React component, call `useLoadLayoutImportSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadLayoutImportSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadLayoutImportSummaryQuery({
 *   variables: {
 *      layoutImportId: // value for 'layoutImportId'
 *   },
 * });
 */
export function useLoadLayoutImportSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadLayoutImportSummaryQuery,
    LoadLayoutImportSummaryQueryVariables
  > &
    (
      | { variables: LoadLayoutImportSummaryQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadLayoutImportSummaryQuery,
    LoadLayoutImportSummaryQueryVariables
  >(LoadLayoutImportSummaryDocument, options);
}
export function useLoadLayoutImportSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadLayoutImportSummaryQuery,
    LoadLayoutImportSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadLayoutImportSummaryQuery,
    LoadLayoutImportSummaryQueryVariables
  >(LoadLayoutImportSummaryDocument, options);
}
export function useLoadLayoutImportSummarySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadLayoutImportSummaryQuery,
        LoadLayoutImportSummaryQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadLayoutImportSummaryQuery,
    LoadLayoutImportSummaryQueryVariables
  >(LoadLayoutImportSummaryDocument, options);
}
export type LoadLayoutImportSummaryQueryHookResult = ReturnType<
  typeof useLoadLayoutImportSummaryQuery
>;
export type LoadLayoutImportSummaryLazyQueryHookResult = ReturnType<
  typeof useLoadLayoutImportSummaryLazyQuery
>;
export type LoadLayoutImportSummarySuspenseQueryHookResult = ReturnType<
  typeof useLoadLayoutImportSummarySuspenseQuery
>;
export type LoadLayoutImportSummaryQueryResult = Apollo.QueryResult<
  LoadLayoutImportSummaryQuery,
  LoadLayoutImportSummaryQueryVariables
>;
export const LoadLayoutImportConverterDataDocument = gql`
  query loadLayoutImportConverterData($id: ID!) {
    layoutImport(id: $id) {
      ...LayoutImportConverterData
    }
  }
  ${LayoutImportConverterDataFragmentDoc}
`;

/**
 * __useLoadLayoutImportConverterDataQuery__
 *
 * To run a query within a React component, call `useLoadLayoutImportConverterDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadLayoutImportConverterDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadLayoutImportConverterDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoadLayoutImportConverterDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadLayoutImportConverterDataQuery,
    LoadLayoutImportConverterDataQueryVariables
  > &
    (
      | {
          variables: LoadLayoutImportConverterDataQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadLayoutImportConverterDataQuery,
    LoadLayoutImportConverterDataQueryVariables
  >(LoadLayoutImportConverterDataDocument, options);
}
export function useLoadLayoutImportConverterDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadLayoutImportConverterDataQuery,
    LoadLayoutImportConverterDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadLayoutImportConverterDataQuery,
    LoadLayoutImportConverterDataQueryVariables
  >(LoadLayoutImportConverterDataDocument, options);
}
export function useLoadLayoutImportConverterDataSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadLayoutImportConverterDataQuery,
        LoadLayoutImportConverterDataQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadLayoutImportConverterDataQuery,
    LoadLayoutImportConverterDataQueryVariables
  >(LoadLayoutImportConverterDataDocument, options);
}
export type LoadLayoutImportConverterDataQueryHookResult = ReturnType<
  typeof useLoadLayoutImportConverterDataQuery
>;
export type LoadLayoutImportConverterDataLazyQueryHookResult = ReturnType<
  typeof useLoadLayoutImportConverterDataLazyQuery
>;
export type LoadLayoutImportConverterDataSuspenseQueryHookResult = ReturnType<
  typeof useLoadLayoutImportConverterDataSuspenseQuery
>;
export type LoadLayoutImportConverterDataQueryResult = Apollo.QueryResult<
  LoadLayoutImportConverterDataQuery,
  LoadLayoutImportConverterDataQueryVariables
>;
export const LoadLayoutImportBayLocationsDocument = gql`
  query loadLayoutImportBayLocations($id: ID!, $bayId: ID!) {
    layoutImport(id: $id) {
      locations(filter: { bayId: [$bayId] }, page: null, sortBy: null) {
        content {
          ...LayoutImportLocationSummary
        }
      }
    }
  }
  ${LayoutImportLocationSummaryFragmentDoc}
`;

/**
 * __useLoadLayoutImportBayLocationsQuery__
 *
 * To run a query within a React component, call `useLoadLayoutImportBayLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadLayoutImportBayLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadLayoutImportBayLocationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      bayId: // value for 'bayId'
 *   },
 * });
 */
export function useLoadLayoutImportBayLocationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadLayoutImportBayLocationsQuery,
    LoadLayoutImportBayLocationsQueryVariables
  > &
    (
      | {
          variables: LoadLayoutImportBayLocationsQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadLayoutImportBayLocationsQuery,
    LoadLayoutImportBayLocationsQueryVariables
  >(LoadLayoutImportBayLocationsDocument, options);
}
export function useLoadLayoutImportBayLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadLayoutImportBayLocationsQuery,
    LoadLayoutImportBayLocationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadLayoutImportBayLocationsQuery,
    LoadLayoutImportBayLocationsQueryVariables
  >(LoadLayoutImportBayLocationsDocument, options);
}
export function useLoadLayoutImportBayLocationsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadLayoutImportBayLocationsQuery,
        LoadLayoutImportBayLocationsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadLayoutImportBayLocationsQuery,
    LoadLayoutImportBayLocationsQueryVariables
  >(LoadLayoutImportBayLocationsDocument, options);
}
export type LoadLayoutImportBayLocationsQueryHookResult = ReturnType<
  typeof useLoadLayoutImportBayLocationsQuery
>;
export type LoadLayoutImportBayLocationsLazyQueryHookResult = ReturnType<
  typeof useLoadLayoutImportBayLocationsLazyQuery
>;
export type LoadLayoutImportBayLocationsSuspenseQueryHookResult = ReturnType<
  typeof useLoadLayoutImportBayLocationsSuspenseQuery
>;
export type LoadLayoutImportBayLocationsQueryResult = Apollo.QueryResult<
  LoadLayoutImportBayLocationsQuery,
  LoadLayoutImportBayLocationsQueryVariables
>;
export const LoadLayoutImportLocationsDocument = gql`
  query loadLayoutImportLocations(
    $layoutImportId: ID!
    $page: SkipLimitPageSpec
  ) {
    layoutImport(id: $layoutImportId) {
      locations(page: $page) {
        totalCount
        content {
          ...LayoutImportConverterLocation
        }
      }
    }
  }
  ${LayoutImportConverterLocationFragmentDoc}
`;

/**
 * __useLoadLayoutImportLocationsQuery__
 *
 * To run a query within a React component, call `useLoadLayoutImportLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadLayoutImportLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadLayoutImportLocationsQuery({
 *   variables: {
 *      layoutImportId: // value for 'layoutImportId'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useLoadLayoutImportLocationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadLayoutImportLocationsQuery,
    LoadLayoutImportLocationsQueryVariables
  > &
    (
      | { variables: LoadLayoutImportLocationsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadLayoutImportLocationsQuery,
    LoadLayoutImportLocationsQueryVariables
  >(LoadLayoutImportLocationsDocument, options);
}
export function useLoadLayoutImportLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadLayoutImportLocationsQuery,
    LoadLayoutImportLocationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadLayoutImportLocationsQuery,
    LoadLayoutImportLocationsQueryVariables
  >(LoadLayoutImportLocationsDocument, options);
}
export function useLoadLayoutImportLocationsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadLayoutImportLocationsQuery,
        LoadLayoutImportLocationsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadLayoutImportLocationsQuery,
    LoadLayoutImportLocationsQueryVariables
  >(LoadLayoutImportLocationsDocument, options);
}
export type LoadLayoutImportLocationsQueryHookResult = ReturnType<
  typeof useLoadLayoutImportLocationsQuery
>;
export type LoadLayoutImportLocationsLazyQueryHookResult = ReturnType<
  typeof useLoadLayoutImportLocationsLazyQuery
>;
export type LoadLayoutImportLocationsSuspenseQueryHookResult = ReturnType<
  typeof useLoadLayoutImportLocationsSuspenseQuery
>;
export type LoadLayoutImportLocationsQueryResult = Apollo.QueryResult<
  LoadLayoutImportLocationsQuery,
  LoadLayoutImportLocationsQueryVariables
>;
export const SaveLayoutImportConverterStateDocument = gql`
  mutation saveLayoutImportConverterState(
    $input: SaveLayoutImportConverterStateInput!
  ) {
    saveLayoutImportConverterState(input: $input) {
      id
    }
  }
`;
export type SaveLayoutImportConverterStateMutationFn = Apollo.MutationFunction<
  SaveLayoutImportConverterStateMutation,
  SaveLayoutImportConverterStateMutationVariables
>;

/**
 * __useSaveLayoutImportConverterStateMutation__
 *
 * To run a mutation, you first call `useSaveLayoutImportConverterStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveLayoutImportConverterStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveLayoutImportConverterStateMutation, { data, loading, error }] = useSaveLayoutImportConverterStateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveLayoutImportConverterStateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveLayoutImportConverterStateMutation,
    SaveLayoutImportConverterStateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveLayoutImportConverterStateMutation,
    SaveLayoutImportConverterStateMutationVariables
  >(SaveLayoutImportConverterStateDocument, options);
}
export type SaveLayoutImportConverterStateMutationHookResult = ReturnType<
  typeof useSaveLayoutImportConverterStateMutation
>;
export type SaveLayoutImportConverterStateMutationResult =
  Apollo.MutationResult<SaveLayoutImportConverterStateMutation>;
export type SaveLayoutImportConverterStateMutationOptions =
  Apollo.BaseMutationOptions<
    SaveLayoutImportConverterStateMutation,
    SaveLayoutImportConverterStateMutationVariables
  >;
export const ConvertLayoutImportDocument = gql`
  mutation convertLayoutImport($input: ConvertLayoutImportInput!) {
    convertLayoutImport(input: $input) {
      id
      status
    }
  }
`;
export type ConvertLayoutImportMutationFn = Apollo.MutationFunction<
  ConvertLayoutImportMutation,
  ConvertLayoutImportMutationVariables
>;

/**
 * __useConvertLayoutImportMutation__
 *
 * To run a mutation, you first call `useConvertLayoutImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConvertLayoutImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [convertLayoutImportMutation, { data, loading, error }] = useConvertLayoutImportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConvertLayoutImportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConvertLayoutImportMutation,
    ConvertLayoutImportMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ConvertLayoutImportMutation,
    ConvertLayoutImportMutationVariables
  >(ConvertLayoutImportDocument, options);
}
export type ConvertLayoutImportMutationHookResult = ReturnType<
  typeof useConvertLayoutImportMutation
>;
export type ConvertLayoutImportMutationResult =
  Apollo.MutationResult<ConvertLayoutImportMutation>;
export type ConvertLayoutImportMutationOptions = Apollo.BaseMutationOptions<
  ConvertLayoutImportMutation,
  ConvertLayoutImportMutationVariables
>;
export const LoadBalancingRuleLocationsDocument = gql`
  query loadBalancingRuleLocations(
    $analyzeId: ID!
    $rule: LoadBalancingRuleInput!
    $layoutLocationFilter: LayoutLocationFilter
  ) {
    analyzeResult(id: $analyzeId) {
      loadBalancingRuleSummary(
        rule: $rule
        locationFilter: $layoutLocationFilter
      ) {
        ...LoadBalancingRuleSummaryWithLocations
      }
    }
  }
  ${LoadBalancingRuleSummaryWithLocationsFragmentDoc}
`;

/**
 * __useLoadBalancingRuleLocationsQuery__
 *
 * To run a query within a React component, call `useLoadBalancingRuleLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadBalancingRuleLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadBalancingRuleLocationsQuery({
 *   variables: {
 *      analyzeId: // value for 'analyzeId'
 *      rule: // value for 'rule'
 *      layoutLocationFilter: // value for 'layoutLocationFilter'
 *   },
 * });
 */
export function useLoadBalancingRuleLocationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadBalancingRuleLocationsQuery,
    LoadBalancingRuleLocationsQueryVariables
  > &
    (
      | { variables: LoadBalancingRuleLocationsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadBalancingRuleLocationsQuery,
    LoadBalancingRuleLocationsQueryVariables
  >(LoadBalancingRuleLocationsDocument, options);
}
export function useLoadBalancingRuleLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadBalancingRuleLocationsQuery,
    LoadBalancingRuleLocationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadBalancingRuleLocationsQuery,
    LoadBalancingRuleLocationsQueryVariables
  >(LoadBalancingRuleLocationsDocument, options);
}
export function useLoadBalancingRuleLocationsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadBalancingRuleLocationsQuery,
        LoadBalancingRuleLocationsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadBalancingRuleLocationsQuery,
    LoadBalancingRuleLocationsQueryVariables
  >(LoadBalancingRuleLocationsDocument, options);
}
export type LoadBalancingRuleLocationsQueryHookResult = ReturnType<
  typeof useLoadBalancingRuleLocationsQuery
>;
export type LoadBalancingRuleLocationsLazyQueryHookResult = ReturnType<
  typeof useLoadBalancingRuleLocationsLazyQuery
>;
export type LoadBalancingRuleLocationsSuspenseQueryHookResult = ReturnType<
  typeof useLoadBalancingRuleLocationsSuspenseQuery
>;
export type LoadBalancingRuleLocationsQueryResult = Apollo.QueryResult<
  LoadBalancingRuleLocationsQuery,
  LoadBalancingRuleLocationsQueryVariables
>;
export const LoadBalancingRuleSummaryDocument = gql`
  query loadBalancingRuleSummary(
    $analyzeId: ID!
    $rule: LoadBalancingRuleInput!
  ) {
    analyzeResult(id: $analyzeId) {
      loadBalancingRuleSummary(rule: $rule) {
        ...LoadBalancingRuleSummary
      }
    }
  }
  ${LoadBalancingRuleSummaryFragmentDoc}
`;

/**
 * __useLoadBalancingRuleSummaryQuery__
 *
 * To run a query within a React component, call `useLoadBalancingRuleSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadBalancingRuleSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadBalancingRuleSummaryQuery({
 *   variables: {
 *      analyzeId: // value for 'analyzeId'
 *      rule: // value for 'rule'
 *   },
 * });
 */
export function useLoadBalancingRuleSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadBalancingRuleSummaryQuery,
    LoadBalancingRuleSummaryQueryVariables
  > &
    (
      | { variables: LoadBalancingRuleSummaryQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadBalancingRuleSummaryQuery,
    LoadBalancingRuleSummaryQueryVariables
  >(LoadBalancingRuleSummaryDocument, options);
}
export function useLoadBalancingRuleSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadBalancingRuleSummaryQuery,
    LoadBalancingRuleSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadBalancingRuleSummaryQuery,
    LoadBalancingRuleSummaryQueryVariables
  >(LoadBalancingRuleSummaryDocument, options);
}
export function useLoadBalancingRuleSummarySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadBalancingRuleSummaryQuery,
        LoadBalancingRuleSummaryQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadBalancingRuleSummaryQuery,
    LoadBalancingRuleSummaryQueryVariables
  >(LoadBalancingRuleSummaryDocument, options);
}
export type LoadBalancingRuleSummaryQueryHookResult = ReturnType<
  typeof useLoadBalancingRuleSummaryQuery
>;
export type LoadBalancingRuleSummaryLazyQueryHookResult = ReturnType<
  typeof useLoadBalancingRuleSummaryLazyQuery
>;
export type LoadBalancingRuleSummarySuspenseQueryHookResult = ReturnType<
  typeof useLoadBalancingRuleSummarySuspenseQuery
>;
export type LoadBalancingRuleSummaryQueryResult = Apollo.QueryResult<
  LoadBalancingRuleSummaryQuery,
  LoadBalancingRuleSummaryQueryVariables
>;
export const CreateOptimizationRunDocument = gql`
  mutation createOptimizationRun($input: CreateOptimizationRunInput!) {
    createOptimizationRun(input: $input) {
      ...OptimizationRun
    }
  }
  ${OptimizationRunFragmentDoc}
`;
export type CreateOptimizationRunMutationFn = Apollo.MutationFunction<
  CreateOptimizationRunMutation,
  CreateOptimizationRunMutationVariables
>;

/**
 * __useCreateOptimizationRunMutation__
 *
 * To run a mutation, you first call `useCreateOptimizationRunMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOptimizationRunMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOptimizationRunMutation, { data, loading, error }] = useCreateOptimizationRunMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOptimizationRunMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOptimizationRunMutation,
    CreateOptimizationRunMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOptimizationRunMutation,
    CreateOptimizationRunMutationVariables
  >(CreateOptimizationRunDocument, options);
}
export type CreateOptimizationRunMutationHookResult = ReturnType<
  typeof useCreateOptimizationRunMutation
>;
export type CreateOptimizationRunMutationResult =
  Apollo.MutationResult<CreateOptimizationRunMutation>;
export type CreateOptimizationRunMutationOptions = Apollo.BaseMutationOptions<
  CreateOptimizationRunMutation,
  CreateOptimizationRunMutationVariables
>;
export const StopOptimizationRunDocument = gql`
  mutation stopOptimizationRun($id: String!) {
    stopOptimizationRun(id: $id) {
      ...OptimizationRun
    }
  }
  ${OptimizationRunFragmentDoc}
`;
export type StopOptimizationRunMutationFn = Apollo.MutationFunction<
  StopOptimizationRunMutation,
  StopOptimizationRunMutationVariables
>;

/**
 * __useStopOptimizationRunMutation__
 *
 * To run a mutation, you first call `useStopOptimizationRunMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStopOptimizationRunMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stopOptimizationRunMutation, { data, loading, error }] = useStopOptimizationRunMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStopOptimizationRunMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StopOptimizationRunMutation,
    StopOptimizationRunMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    StopOptimizationRunMutation,
    StopOptimizationRunMutationVariables
  >(StopOptimizationRunDocument, options);
}
export type StopOptimizationRunMutationHookResult = ReturnType<
  typeof useStopOptimizationRunMutation
>;
export type StopOptimizationRunMutationResult =
  Apollo.MutationResult<StopOptimizationRunMutation>;
export type StopOptimizationRunMutationOptions = Apollo.BaseMutationOptions<
  StopOptimizationRunMutation,
  StopOptimizationRunMutationVariables
>;
export const LoadOptimizationRunStatusDocument = gql`
  query loadOptimizationRunStatus($optimisationRunId: ID!) {
    optimizationRun(id: $optimisationRunId) {
      ...OptimizationRunStatus
    }
  }
  ${OptimizationRunStatusFragmentDoc}
`;

/**
 * __useLoadOptimizationRunStatusQuery__
 *
 * To run a query within a React component, call `useLoadOptimizationRunStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadOptimizationRunStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadOptimizationRunStatusQuery({
 *   variables: {
 *      optimisationRunId: // value for 'optimisationRunId'
 *   },
 * });
 */
export function useLoadOptimizationRunStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadOptimizationRunStatusQuery,
    LoadOptimizationRunStatusQueryVariables
  > &
    (
      | { variables: LoadOptimizationRunStatusQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadOptimizationRunStatusQuery,
    LoadOptimizationRunStatusQueryVariables
  >(LoadOptimizationRunStatusDocument, options);
}
export function useLoadOptimizationRunStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadOptimizationRunStatusQuery,
    LoadOptimizationRunStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadOptimizationRunStatusQuery,
    LoadOptimizationRunStatusQueryVariables
  >(LoadOptimizationRunStatusDocument, options);
}
export function useLoadOptimizationRunStatusSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadOptimizationRunStatusQuery,
        LoadOptimizationRunStatusQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadOptimizationRunStatusQuery,
    LoadOptimizationRunStatusQueryVariables
  >(LoadOptimizationRunStatusDocument, options);
}
export type LoadOptimizationRunStatusQueryHookResult = ReturnType<
  typeof useLoadOptimizationRunStatusQuery
>;
export type LoadOptimizationRunStatusLazyQueryHookResult = ReturnType<
  typeof useLoadOptimizationRunStatusLazyQuery
>;
export type LoadOptimizationRunStatusSuspenseQueryHookResult = ReturnType<
  typeof useLoadOptimizationRunStatusSuspenseQuery
>;
export type LoadOptimizationRunStatusQueryResult = Apollo.QueryResult<
  LoadOptimizationRunStatusQuery,
  LoadOptimizationRunStatusQueryVariables
>;
export const LoadOptimizationAnalyzeDocument = gql`
  query loadOptimizationAnalyze($optimisationRunId: ID!) {
    optimizationRun(id: $optimisationRunId) {
      ...OptimizationRun
    }
  }
  ${OptimizationRunFragmentDoc}
`;

/**
 * __useLoadOptimizationAnalyzeQuery__
 *
 * To run a query within a React component, call `useLoadOptimizationAnalyzeQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadOptimizationAnalyzeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadOptimizationAnalyzeQuery({
 *   variables: {
 *      optimisationRunId: // value for 'optimisationRunId'
 *   },
 * });
 */
export function useLoadOptimizationAnalyzeQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadOptimizationAnalyzeQuery,
    LoadOptimizationAnalyzeQueryVariables
  > &
    (
      | { variables: LoadOptimizationAnalyzeQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadOptimizationAnalyzeQuery,
    LoadOptimizationAnalyzeQueryVariables
  >(LoadOptimizationAnalyzeDocument, options);
}
export function useLoadOptimizationAnalyzeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadOptimizationAnalyzeQuery,
    LoadOptimizationAnalyzeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadOptimizationAnalyzeQuery,
    LoadOptimizationAnalyzeQueryVariables
  >(LoadOptimizationAnalyzeDocument, options);
}
export function useLoadOptimizationAnalyzeSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadOptimizationAnalyzeQuery,
        LoadOptimizationAnalyzeQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadOptimizationAnalyzeQuery,
    LoadOptimizationAnalyzeQueryVariables
  >(LoadOptimizationAnalyzeDocument, options);
}
export type LoadOptimizationAnalyzeQueryHookResult = ReturnType<
  typeof useLoadOptimizationAnalyzeQuery
>;
export type LoadOptimizationAnalyzeLazyQueryHookResult = ReturnType<
  typeof useLoadOptimizationAnalyzeLazyQuery
>;
export type LoadOptimizationAnalyzeSuspenseQueryHookResult = ReturnType<
  typeof useLoadOptimizationAnalyzeSuspenseQuery
>;
export type LoadOptimizationAnalyzeQueryResult = Apollo.QueryResult<
  LoadOptimizationAnalyzeQuery,
  LoadOptimizationAnalyzeQueryVariables
>;
export const LoadOptimizationDiffDocument = gql`
  query loadOptimizationDiff(
    $optimisationRunId: ID!
    $filter: AssignmentDiffItemsFilter
    $sortBy: [AssignmentDiffItemsSortBy!]
    $page: SkipLimitPageSpec
  ) {
    optimizationRun(id: $optimisationRunId) {
      assignmentDiff {
        items(filter: $filter, page: $page, sortBy: $sortBy) {
          totalCount
          content {
            ...AssignmentDiffItem
          }
        }
      }
    }
  }
  ${AssignmentDiffItemFragmentDoc}
`;

/**
 * __useLoadOptimizationDiffQuery__
 *
 * To run a query within a React component, call `useLoadOptimizationDiffQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadOptimizationDiffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadOptimizationDiffQuery({
 *   variables: {
 *      optimisationRunId: // value for 'optimisationRunId'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useLoadOptimizationDiffQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadOptimizationDiffQuery,
    LoadOptimizationDiffQueryVariables
  > &
    (
      | { variables: LoadOptimizationDiffQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadOptimizationDiffQuery,
    LoadOptimizationDiffQueryVariables
  >(LoadOptimizationDiffDocument, options);
}
export function useLoadOptimizationDiffLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadOptimizationDiffQuery,
    LoadOptimizationDiffQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadOptimizationDiffQuery,
    LoadOptimizationDiffQueryVariables
  >(LoadOptimizationDiffDocument, options);
}
export function useLoadOptimizationDiffSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadOptimizationDiffQuery,
        LoadOptimizationDiffQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadOptimizationDiffQuery,
    LoadOptimizationDiffQueryVariables
  >(LoadOptimizationDiffDocument, options);
}
export type LoadOptimizationDiffQueryHookResult = ReturnType<
  typeof useLoadOptimizationDiffQuery
>;
export type LoadOptimizationDiffLazyQueryHookResult = ReturnType<
  typeof useLoadOptimizationDiffLazyQuery
>;
export type LoadOptimizationDiffSuspenseQueryHookResult = ReturnType<
  typeof useLoadOptimizationDiffSuspenseQuery
>;
export type LoadOptimizationDiffQueryResult = Apollo.QueryResult<
  LoadOptimizationDiffQuery,
  LoadOptimizationDiffQueryVariables
>;
export const LoadOptimizationDiffFullDocument = gql`
  query loadOptimizationDiffFull(
    $optimisationRunId: ID!
    $filter: AssignmentDiffItemsFilter
    $sortBy: [AssignmentDiffItemsSortBy!]
    $page: SkipLimitPageSpec
  ) {
    optimizationRun(id: $optimisationRunId) {
      assignmentDiff {
        items(filter: $filter, page: $page, sortBy: $sortBy) {
          totalCount
          content {
            ...AssignmentDiffItemFull
          }
        }
      }
    }
  }
  ${AssignmentDiffItemFullFragmentDoc}
`;

/**
 * __useLoadOptimizationDiffFullQuery__
 *
 * To run a query within a React component, call `useLoadOptimizationDiffFullQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadOptimizationDiffFullQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadOptimizationDiffFullQuery({
 *   variables: {
 *      optimisationRunId: // value for 'optimisationRunId'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useLoadOptimizationDiffFullQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadOptimizationDiffFullQuery,
    LoadOptimizationDiffFullQueryVariables
  > &
    (
      | { variables: LoadOptimizationDiffFullQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadOptimizationDiffFullQuery,
    LoadOptimizationDiffFullQueryVariables
  >(LoadOptimizationDiffFullDocument, options);
}
export function useLoadOptimizationDiffFullLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadOptimizationDiffFullQuery,
    LoadOptimizationDiffFullQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadOptimizationDiffFullQuery,
    LoadOptimizationDiffFullQueryVariables
  >(LoadOptimizationDiffFullDocument, options);
}
export function useLoadOptimizationDiffFullSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadOptimizationDiffFullQuery,
        LoadOptimizationDiffFullQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadOptimizationDiffFullQuery,
    LoadOptimizationDiffFullQueryVariables
  >(LoadOptimizationDiffFullDocument, options);
}
export type LoadOptimizationDiffFullQueryHookResult = ReturnType<
  typeof useLoadOptimizationDiffFullQuery
>;
export type LoadOptimizationDiffFullLazyQueryHookResult = ReturnType<
  typeof useLoadOptimizationDiffFullLazyQuery
>;
export type LoadOptimizationDiffFullSuspenseQueryHookResult = ReturnType<
  typeof useLoadOptimizationDiffFullSuspenseQuery
>;
export type LoadOptimizationDiffFullQueryResult = Apollo.QueryResult<
  LoadOptimizationDiffFullQuery,
  LoadOptimizationDiffFullQueryVariables
>;
export const LoadOptimizationMovesDocument = gql`
  query loadOptimizationMoves($optimisationRunId: ID!) {
    optimizationRun(id: $optimisationRunId) {
      assignmentChangeSequence {
        moves {
          ...AssignmentChangeItemMoveDetails
        }
      }
    }
  }
  ${AssignmentChangeItemMoveDetailsFragmentDoc}
`;

/**
 * __useLoadOptimizationMovesQuery__
 *
 * To run a query within a React component, call `useLoadOptimizationMovesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadOptimizationMovesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadOptimizationMovesQuery({
 *   variables: {
 *      optimisationRunId: // value for 'optimisationRunId'
 *   },
 * });
 */
export function useLoadOptimizationMovesQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadOptimizationMovesQuery,
    LoadOptimizationMovesQueryVariables
  > &
    (
      | { variables: LoadOptimizationMovesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadOptimizationMovesQuery,
    LoadOptimizationMovesQueryVariables
  >(LoadOptimizationMovesDocument, options);
}
export function useLoadOptimizationMovesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadOptimizationMovesQuery,
    LoadOptimizationMovesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadOptimizationMovesQuery,
    LoadOptimizationMovesQueryVariables
  >(LoadOptimizationMovesDocument, options);
}
export function useLoadOptimizationMovesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadOptimizationMovesQuery,
        LoadOptimizationMovesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadOptimizationMovesQuery,
    LoadOptimizationMovesQueryVariables
  >(LoadOptimizationMovesDocument, options);
}
export type LoadOptimizationMovesQueryHookResult = ReturnType<
  typeof useLoadOptimizationMovesQuery
>;
export type LoadOptimizationMovesLazyQueryHookResult = ReturnType<
  typeof useLoadOptimizationMovesLazyQuery
>;
export type LoadOptimizationMovesSuspenseQueryHookResult = ReturnType<
  typeof useLoadOptimizationMovesSuspenseQuery
>;
export type LoadOptimizationMovesQueryResult = Apollo.QueryResult<
  LoadOptimizationMovesQuery,
  LoadOptimizationMovesQueryVariables
>;
export const LoadOrderedQuantitySummaryDocument = gql`
  query loadOrderedQuantitySummary($simulationId: ID!) {
    simulation(id: $simulationId) {
      orderedQuantityReport {
        ...OrderedQuantityReportSummary
      }
    }
  }
  ${OrderedQuantityReportSummaryFragmentDoc}
`;

/**
 * __useLoadOrderedQuantitySummaryQuery__
 *
 * To run a query within a React component, call `useLoadOrderedQuantitySummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadOrderedQuantitySummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadOrderedQuantitySummaryQuery({
 *   variables: {
 *      simulationId: // value for 'simulationId'
 *   },
 * });
 */
export function useLoadOrderedQuantitySummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadOrderedQuantitySummaryQuery,
    LoadOrderedQuantitySummaryQueryVariables
  > &
    (
      | { variables: LoadOrderedQuantitySummaryQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadOrderedQuantitySummaryQuery,
    LoadOrderedQuantitySummaryQueryVariables
  >(LoadOrderedQuantitySummaryDocument, options);
}
export function useLoadOrderedQuantitySummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadOrderedQuantitySummaryQuery,
    LoadOrderedQuantitySummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadOrderedQuantitySummaryQuery,
    LoadOrderedQuantitySummaryQueryVariables
  >(LoadOrderedQuantitySummaryDocument, options);
}
export function useLoadOrderedQuantitySummarySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadOrderedQuantitySummaryQuery,
        LoadOrderedQuantitySummaryQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadOrderedQuantitySummaryQuery,
    LoadOrderedQuantitySummaryQueryVariables
  >(LoadOrderedQuantitySummaryDocument, options);
}
export type LoadOrderedQuantitySummaryQueryHookResult = ReturnType<
  typeof useLoadOrderedQuantitySummaryQuery
>;
export type LoadOrderedQuantitySummaryLazyQueryHookResult = ReturnType<
  typeof useLoadOrderedQuantitySummaryLazyQuery
>;
export type LoadOrderedQuantitySummarySuspenseQueryHookResult = ReturnType<
  typeof useLoadOrderedQuantitySummarySuspenseQuery
>;
export type LoadOrderedQuantitySummaryQueryResult = Apollo.QueryResult<
  LoadOrderedQuantitySummaryQuery,
  LoadOrderedQuantitySummaryQueryVariables
>;
export const LoadOrderedQuantityItemsDocument = gql`
  query loadOrderedQuantityItems(
    $simulationId: ID!
    $sortBy: [OrderedQuantityReportItemSortBy!]
    $filter: OrderedQuantityReportItemFilter
    $page: SkipLimitPageSpec
  ) {
    simulation(id: $simulationId) {
      orderedQuantityReport {
        id
        status
        items(sortBy: $sortBy, filter: $filter, page: $page) {
          ...OrderedQuantityItems
        }
      }
    }
  }
  ${OrderedQuantityItemsFragmentDoc}
`;

/**
 * __useLoadOrderedQuantityItemsQuery__
 *
 * To run a query within a React component, call `useLoadOrderedQuantityItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadOrderedQuantityItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadOrderedQuantityItemsQuery({
 *   variables: {
 *      simulationId: // value for 'simulationId'
 *      sortBy: // value for 'sortBy'
 *      filter: // value for 'filter'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useLoadOrderedQuantityItemsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadOrderedQuantityItemsQuery,
    LoadOrderedQuantityItemsQueryVariables
  > &
    (
      | { variables: LoadOrderedQuantityItemsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadOrderedQuantityItemsQuery,
    LoadOrderedQuantityItemsQueryVariables
  >(LoadOrderedQuantityItemsDocument, options);
}
export function useLoadOrderedQuantityItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadOrderedQuantityItemsQuery,
    LoadOrderedQuantityItemsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadOrderedQuantityItemsQuery,
    LoadOrderedQuantityItemsQueryVariables
  >(LoadOrderedQuantityItemsDocument, options);
}
export function useLoadOrderedQuantityItemsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadOrderedQuantityItemsQuery,
        LoadOrderedQuantityItemsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadOrderedQuantityItemsQuery,
    LoadOrderedQuantityItemsQueryVariables
  >(LoadOrderedQuantityItemsDocument, options);
}
export type LoadOrderedQuantityItemsQueryHookResult = ReturnType<
  typeof useLoadOrderedQuantityItemsQuery
>;
export type LoadOrderedQuantityItemsLazyQueryHookResult = ReturnType<
  typeof useLoadOrderedQuantityItemsLazyQuery
>;
export type LoadOrderedQuantityItemsSuspenseQueryHookResult = ReturnType<
  typeof useLoadOrderedQuantityItemsSuspenseQuery
>;
export type LoadOrderedQuantityItemsQueryResult = Apollo.QueryResult<
  LoadOrderedQuantityItemsQuery,
  LoadOrderedQuantityItemsQueryVariables
>;
export const RunGenerateQuantityReportDocument = gql`
  mutation runGenerateQuantityReport($simulationId: String!) {
    generateSimulationOrderedQuantityReport(simulationId: $simulationId) {
      id
      status
      progress
    }
  }
`;
export type RunGenerateQuantityReportMutationFn = Apollo.MutationFunction<
  RunGenerateQuantityReportMutation,
  RunGenerateQuantityReportMutationVariables
>;

/**
 * __useRunGenerateQuantityReportMutation__
 *
 * To run a mutation, you first call `useRunGenerateQuantityReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunGenerateQuantityReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runGenerateQuantityReportMutation, { data, loading, error }] = useRunGenerateQuantityReportMutation({
 *   variables: {
 *      simulationId: // value for 'simulationId'
 *   },
 * });
 */
export function useRunGenerateQuantityReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RunGenerateQuantityReportMutation,
    RunGenerateQuantityReportMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RunGenerateQuantityReportMutation,
    RunGenerateQuantityReportMutationVariables
  >(RunGenerateQuantityReportDocument, options);
}
export type RunGenerateQuantityReportMutationHookResult = ReturnType<
  typeof useRunGenerateQuantityReportMutation
>;
export type RunGenerateQuantityReportMutationResult =
  Apollo.MutationResult<RunGenerateQuantityReportMutation>;
export type RunGenerateQuantityReportMutationOptions =
  Apollo.BaseMutationOptions<
    RunGenerateQuantityReportMutation,
    RunGenerateQuantityReportMutationVariables
  >;
export const CreateOrderSetDocument = gql`
  mutation createOrderSet($input: CreateOrderSetInput!) {
    createOrderSet(input: $input) {
      id
    }
  }
`;
export type CreateOrderSetMutationFn = Apollo.MutationFunction<
  CreateOrderSetMutation,
  CreateOrderSetMutationVariables
>;

/**
 * __useCreateOrderSetMutation__
 *
 * To run a mutation, you first call `useCreateOrderSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderSetMutation, { data, loading, error }] = useCreateOrderSetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrderSetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOrderSetMutation,
    CreateOrderSetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOrderSetMutation,
    CreateOrderSetMutationVariables
  >(CreateOrderSetDocument, options);
}
export type CreateOrderSetMutationHookResult = ReturnType<
  typeof useCreateOrderSetMutation
>;
export type CreateOrderSetMutationResult =
  Apollo.MutationResult<CreateOrderSetMutation>;
export type CreateOrderSetMutationOptions = Apollo.BaseMutationOptions<
  CreateOrderSetMutation,
  CreateOrderSetMutationVariables
>;
export const UpdateOrderSetDocument = gql`
  mutation updateOrderSet($orderSetInput: UpdateOrderSetInput!) {
    updateOrderSet(input: $orderSetInput) {
      id
      description
    }
  }
`;
export type UpdateOrderSetMutationFn = Apollo.MutationFunction<
  UpdateOrderSetMutation,
  UpdateOrderSetMutationVariables
>;

/**
 * __useUpdateOrderSetMutation__
 *
 * To run a mutation, you first call `useUpdateOrderSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderSetMutation, { data, loading, error }] = useUpdateOrderSetMutation({
 *   variables: {
 *      orderSetInput: // value for 'orderSetInput'
 *   },
 * });
 */
export function useUpdateOrderSetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrderSetMutation,
    UpdateOrderSetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOrderSetMutation,
    UpdateOrderSetMutationVariables
  >(UpdateOrderSetDocument, options);
}
export type UpdateOrderSetMutationHookResult = ReturnType<
  typeof useUpdateOrderSetMutation
>;
export type UpdateOrderSetMutationResult =
  Apollo.MutationResult<UpdateOrderSetMutation>;
export type UpdateOrderSetMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrderSetMutation,
  UpdateOrderSetMutationVariables
>;
export const RemoveOrderSetDocument = gql`
  mutation removeOrderSet($id: ID!) {
    removeOrderSet(id: $id)
  }
`;
export type RemoveOrderSetMutationFn = Apollo.MutationFunction<
  RemoveOrderSetMutation,
  RemoveOrderSetMutationVariables
>;

/**
 * __useRemoveOrderSetMutation__
 *
 * To run a mutation, you first call `useRemoveOrderSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOrderSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOrderSetMutation, { data, loading, error }] = useRemoveOrderSetMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveOrderSetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveOrderSetMutation,
    RemoveOrderSetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveOrderSetMutation,
    RemoveOrderSetMutationVariables
  >(RemoveOrderSetDocument, options);
}
export type RemoveOrderSetMutationHookResult = ReturnType<
  typeof useRemoveOrderSetMutation
>;
export type RemoveOrderSetMutationResult =
  Apollo.MutationResult<RemoveOrderSetMutation>;
export type RemoveOrderSetMutationOptions = Apollo.BaseMutationOptions<
  RemoveOrderSetMutation,
  RemoveOrderSetMutationVariables
>;
export const LoadAllOrderSetsMetaDocument = gql`
  query loadAllOrderSetsMeta($warehouseId: ID!) {
    warehouse(id: $warehouseId) {
      orderSets(page: { limit: null }) {
        content {
          ...OrderSetMeta
        }
      }
    }
  }
  ${OrderSetMetaFragmentDoc}
`;

/**
 * __useLoadAllOrderSetsMetaQuery__
 *
 * To run a query within a React component, call `useLoadAllOrderSetsMetaQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadAllOrderSetsMetaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadAllOrderSetsMetaQuery({
 *   variables: {
 *      warehouseId: // value for 'warehouseId'
 *   },
 * });
 */
export function useLoadAllOrderSetsMetaQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadAllOrderSetsMetaQuery,
    LoadAllOrderSetsMetaQueryVariables
  > &
    (
      | { variables: LoadAllOrderSetsMetaQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadAllOrderSetsMetaQuery,
    LoadAllOrderSetsMetaQueryVariables
  >(LoadAllOrderSetsMetaDocument, options);
}
export function useLoadAllOrderSetsMetaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadAllOrderSetsMetaQuery,
    LoadAllOrderSetsMetaQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadAllOrderSetsMetaQuery,
    LoadAllOrderSetsMetaQueryVariables
  >(LoadAllOrderSetsMetaDocument, options);
}
export function useLoadAllOrderSetsMetaSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadAllOrderSetsMetaQuery,
        LoadAllOrderSetsMetaQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadAllOrderSetsMetaQuery,
    LoadAllOrderSetsMetaQueryVariables
  >(LoadAllOrderSetsMetaDocument, options);
}
export type LoadAllOrderSetsMetaQueryHookResult = ReturnType<
  typeof useLoadAllOrderSetsMetaQuery
>;
export type LoadAllOrderSetsMetaLazyQueryHookResult = ReturnType<
  typeof useLoadAllOrderSetsMetaLazyQuery
>;
export type LoadAllOrderSetsMetaSuspenseQueryHookResult = ReturnType<
  typeof useLoadAllOrderSetsMetaSuspenseQuery
>;
export type LoadAllOrderSetsMetaQueryResult = Apollo.QueryResult<
  LoadAllOrderSetsMetaQuery,
  LoadAllOrderSetsMetaQueryVariables
>;
export const LoadOrderSetsSummaryDocument = gql`
  query loadOrderSetsSummary($orderSetId: ID!) {
    orderSet(id: $orderSetId) {
      ...OrderSetMeta
      summary {
        ...OrderSetSummary
      }
    }
  }
  ${OrderSetMetaFragmentDoc}
  ${OrderSetSummaryFragmentDoc}
`;

/**
 * __useLoadOrderSetsSummaryQuery__
 *
 * To run a query within a React component, call `useLoadOrderSetsSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadOrderSetsSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadOrderSetsSummaryQuery({
 *   variables: {
 *      orderSetId: // value for 'orderSetId'
 *   },
 * });
 */
export function useLoadOrderSetsSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadOrderSetsSummaryQuery,
    LoadOrderSetsSummaryQueryVariables
  > &
    (
      | { variables: LoadOrderSetsSummaryQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadOrderSetsSummaryQuery,
    LoadOrderSetsSummaryQueryVariables
  >(LoadOrderSetsSummaryDocument, options);
}
export function useLoadOrderSetsSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadOrderSetsSummaryQuery,
    LoadOrderSetsSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadOrderSetsSummaryQuery,
    LoadOrderSetsSummaryQueryVariables
  >(LoadOrderSetsSummaryDocument, options);
}
export function useLoadOrderSetsSummarySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadOrderSetsSummaryQuery,
        LoadOrderSetsSummaryQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadOrderSetsSummaryQuery,
    LoadOrderSetsSummaryQueryVariables
  >(LoadOrderSetsSummaryDocument, options);
}
export type LoadOrderSetsSummaryQueryHookResult = ReturnType<
  typeof useLoadOrderSetsSummaryQuery
>;
export type LoadOrderSetsSummaryLazyQueryHookResult = ReturnType<
  typeof useLoadOrderSetsSummaryLazyQuery
>;
export type LoadOrderSetsSummarySuspenseQueryHookResult = ReturnType<
  typeof useLoadOrderSetsSummarySuspenseQuery
>;
export type LoadOrderSetsSummaryQueryResult = Apollo.QueryResult<
  LoadOrderSetsSummaryQuery,
  LoadOrderSetsSummaryQueryVariables
>;
export const LoadOrderSetDataDocument = gql`
  query loadOrderSetData(
    $orderSetId: ID!
    $page: SkipLimitPageSpec
    $filter: OrderLineSearchFilter
    $sortBy: [OrderLinesSortBy!]
  ) {
    orderSet(id: $orderSetId) {
      orderLines(page: $page, filter: $filter, sortBy: $sortBy) {
        totalCount
        content {
          ...OrderLineData
        }
      }
    }
  }
  ${OrderLineDataFragmentDoc}
`;

/**
 * __useLoadOrderSetDataQuery__
 *
 * To run a query within a React component, call `useLoadOrderSetDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadOrderSetDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadOrderSetDataQuery({
 *   variables: {
 *      orderSetId: // value for 'orderSetId'
 *      page: // value for 'page'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useLoadOrderSetDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadOrderSetDataQuery,
    LoadOrderSetDataQueryVariables
  > &
    (
      | { variables: LoadOrderSetDataQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadOrderSetDataQuery, LoadOrderSetDataQueryVariables>(
    LoadOrderSetDataDocument,
    options,
  );
}
export function useLoadOrderSetDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadOrderSetDataQuery,
    LoadOrderSetDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadOrderSetDataQuery,
    LoadOrderSetDataQueryVariables
  >(LoadOrderSetDataDocument, options);
}
export function useLoadOrderSetDataSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadOrderSetDataQuery,
        LoadOrderSetDataQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadOrderSetDataQuery,
    LoadOrderSetDataQueryVariables
  >(LoadOrderSetDataDocument, options);
}
export type LoadOrderSetDataQueryHookResult = ReturnType<
  typeof useLoadOrderSetDataQuery
>;
export type LoadOrderSetDataLazyQueryHookResult = ReturnType<
  typeof useLoadOrderSetDataLazyQuery
>;
export type LoadOrderSetDataSuspenseQueryHookResult = ReturnType<
  typeof useLoadOrderSetDataSuspenseQuery
>;
export type LoadOrderSetDataQueryResult = Apollo.QueryResult<
  LoadOrderSetDataQuery,
  LoadOrderSetDataQueryVariables
>;
export const LoadOrderSetDocument = gql`
  query loadOrderSet($orderSetId: ID!) {
    orderSet(id: $orderSetId) {
      ...OrderSet
    }
  }
  ${OrderSetFragmentDoc}
`;

/**
 * __useLoadOrderSetQuery__
 *
 * To run a query within a React component, call `useLoadOrderSetQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadOrderSetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadOrderSetQuery({
 *   variables: {
 *      orderSetId: // value for 'orderSetId'
 *   },
 * });
 */
export function useLoadOrderSetQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadOrderSetQuery,
    LoadOrderSetQueryVariables
  > &
    (
      | { variables: LoadOrderSetQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadOrderSetQuery, LoadOrderSetQueryVariables>(
    LoadOrderSetDocument,
    options,
  );
}
export function useLoadOrderSetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadOrderSetQuery,
    LoadOrderSetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LoadOrderSetQuery, LoadOrderSetQueryVariables>(
    LoadOrderSetDocument,
    options,
  );
}
export function useLoadOrderSetSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadOrderSetQuery,
        LoadOrderSetQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<LoadOrderSetQuery, LoadOrderSetQueryVariables>(
    LoadOrderSetDocument,
    options,
  );
}
export type LoadOrderSetQueryHookResult = ReturnType<
  typeof useLoadOrderSetQuery
>;
export type LoadOrderSetLazyQueryHookResult = ReturnType<
  typeof useLoadOrderSetLazyQuery
>;
export type LoadOrderSetSuspenseQueryHookResult = ReturnType<
  typeof useLoadOrderSetSuspenseQuery
>;
export type LoadOrderSetQueryResult = Apollo.QueryResult<
  LoadOrderSetQuery,
  LoadOrderSetQueryVariables
>;
export const LoadOrderLineSetDocument = gql`
  query loadOrderLineSet($simulationId: ID!) {
    simulation(id: $simulationId) {
      orderLineSet {
        ...SimulationOrderLineSet
      }
    }
  }
  ${SimulationOrderLineSetFragmentDoc}
`;

/**
 * __useLoadOrderLineSetQuery__
 *
 * To run a query within a React component, call `useLoadOrderLineSetQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadOrderLineSetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadOrderLineSetQuery({
 *   variables: {
 *      simulationId: // value for 'simulationId'
 *   },
 * });
 */
export function useLoadOrderLineSetQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadOrderLineSetQuery,
    LoadOrderLineSetQueryVariables
  > &
    (
      | { variables: LoadOrderLineSetQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadOrderLineSetQuery, LoadOrderLineSetQueryVariables>(
    LoadOrderLineSetDocument,
    options,
  );
}
export function useLoadOrderLineSetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadOrderLineSetQuery,
    LoadOrderLineSetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadOrderLineSetQuery,
    LoadOrderLineSetQueryVariables
  >(LoadOrderLineSetDocument, options);
}
export function useLoadOrderLineSetSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadOrderLineSetQuery,
        LoadOrderLineSetQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadOrderLineSetQuery,
    LoadOrderLineSetQueryVariables
  >(LoadOrderLineSetDocument, options);
}
export type LoadOrderLineSetQueryHookResult = ReturnType<
  typeof useLoadOrderLineSetQuery
>;
export type LoadOrderLineSetLazyQueryHookResult = ReturnType<
  typeof useLoadOrderLineSetLazyQuery
>;
export type LoadOrderLineSetSuspenseQueryHookResult = ReturnType<
  typeof useLoadOrderLineSetSuspenseQuery
>;
export type LoadOrderLineSetQueryResult = Apollo.QueryResult<
  LoadOrderLineSetQuery,
  LoadOrderLineSetQueryVariables
>;
export const RunGenerateSimulationOrderLineSetDocument = gql`
  mutation runGenerateSimulationOrderLineSet($simulationId: String!) {
    generateSimulationOrderLineSet(simulationId: $simulationId) {
      ...SimulationOrderLineSet
    }
  }
  ${SimulationOrderLineSetFragmentDoc}
`;
export type RunGenerateSimulationOrderLineSetMutationFn =
  Apollo.MutationFunction<
    RunGenerateSimulationOrderLineSetMutation,
    RunGenerateSimulationOrderLineSetMutationVariables
  >;

/**
 * __useRunGenerateSimulationOrderLineSetMutation__
 *
 * To run a mutation, you first call `useRunGenerateSimulationOrderLineSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunGenerateSimulationOrderLineSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runGenerateSimulationOrderLineSetMutation, { data, loading, error }] = useRunGenerateSimulationOrderLineSetMutation({
 *   variables: {
 *      simulationId: // value for 'simulationId'
 *   },
 * });
 */
export function useRunGenerateSimulationOrderLineSetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RunGenerateSimulationOrderLineSetMutation,
    RunGenerateSimulationOrderLineSetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RunGenerateSimulationOrderLineSetMutation,
    RunGenerateSimulationOrderLineSetMutationVariables
  >(RunGenerateSimulationOrderLineSetDocument, options);
}
export type RunGenerateSimulationOrderLineSetMutationHookResult = ReturnType<
  typeof useRunGenerateSimulationOrderLineSetMutation
>;
export type RunGenerateSimulationOrderLineSetMutationResult =
  Apollo.MutationResult<RunGenerateSimulationOrderLineSetMutation>;
export type RunGenerateSimulationOrderLineSetMutationOptions =
  Apollo.BaseMutationOptions<
    RunGenerateSimulationOrderLineSetMutation,
    RunGenerateSimulationOrderLineSetMutationVariables
  >;
export const FindOrderLinesByFilterDocument = gql`
  query findOrderLinesByFilter(
    $input: FindSimulationOrderLinesByFilter!
    $page: SkipLimitPageSpec
    $sortBy: [SimulationOrderLinesSortBy!]
  ) {
    findSimulationOrderLinesByFilter(
      input: $input
      page: $page
      sortBy: $sortBy
    ) {
      ...SimulationOrderLinesConnection
    }
  }
  ${SimulationOrderLinesConnectionFragmentDoc}
`;

/**
 * __useFindOrderLinesByFilterQuery__
 *
 * To run a query within a React component, call `useFindOrderLinesByFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindOrderLinesByFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindOrderLinesByFilterQuery({
 *   variables: {
 *      input: // value for 'input'
 *      page: // value for 'page'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useFindOrderLinesByFilterQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindOrderLinesByFilterQuery,
    FindOrderLinesByFilterQueryVariables
  > &
    (
      | { variables: FindOrderLinesByFilterQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindOrderLinesByFilterQuery,
    FindOrderLinesByFilterQueryVariables
  >(FindOrderLinesByFilterDocument, options);
}
export function useFindOrderLinesByFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindOrderLinesByFilterQuery,
    FindOrderLinesByFilterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindOrderLinesByFilterQuery,
    FindOrderLinesByFilterQueryVariables
  >(FindOrderLinesByFilterDocument, options);
}
export function useFindOrderLinesByFilterSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindOrderLinesByFilterQuery,
        FindOrderLinesByFilterQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FindOrderLinesByFilterQuery,
    FindOrderLinesByFilterQueryVariables
  >(FindOrderLinesByFilterDocument, options);
}
export type FindOrderLinesByFilterQueryHookResult = ReturnType<
  typeof useFindOrderLinesByFilterQuery
>;
export type FindOrderLinesByFilterLazyQueryHookResult = ReturnType<
  typeof useFindOrderLinesByFilterLazyQuery
>;
export type FindOrderLinesByFilterSuspenseQueryHookResult = ReturnType<
  typeof useFindOrderLinesByFilterSuspenseQuery
>;
export type FindOrderLinesByFilterQueryResult = Apollo.QueryResult<
  FindOrderLinesByFilterQuery,
  FindOrderLinesByFilterQueryVariables
>;
export const CheckPickingPolicyDocument = gql`
  query checkPickingPolicy($input: CheckSimulationPickingPolicyInput!) {
    checkSimulationPickingPolicy(input: $input) {
      ...CheckSimulationPickingPolicyResult
    }
  }
  ${CheckSimulationPickingPolicyResultFragmentDoc}
`;

/**
 * __useCheckPickingPolicyQuery__
 *
 * To run a query within a React component, call `useCheckPickingPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckPickingPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckPickingPolicyQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckPickingPolicyQuery(
  baseOptions: Apollo.QueryHookOptions<
    CheckPickingPolicyQuery,
    CheckPickingPolicyQueryVariables
  > &
    (
      | { variables: CheckPickingPolicyQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CheckPickingPolicyQuery,
    CheckPickingPolicyQueryVariables
  >(CheckPickingPolicyDocument, options);
}
export function useCheckPickingPolicyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CheckPickingPolicyQuery,
    CheckPickingPolicyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CheckPickingPolicyQuery,
    CheckPickingPolicyQueryVariables
  >(CheckPickingPolicyDocument, options);
}
export function useCheckPickingPolicySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CheckPickingPolicyQuery,
        CheckPickingPolicyQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    CheckPickingPolicyQuery,
    CheckPickingPolicyQueryVariables
  >(CheckPickingPolicyDocument, options);
}
export type CheckPickingPolicyQueryHookResult = ReturnType<
  typeof useCheckPickingPolicyQuery
>;
export type CheckPickingPolicyLazyQueryHookResult = ReturnType<
  typeof useCheckPickingPolicyLazyQuery
>;
export type CheckPickingPolicySuspenseQueryHookResult = ReturnType<
  typeof useCheckPickingPolicySuspenseQuery
>;
export type CheckPickingPolicyQueryResult = Apollo.QueryResult<
  CheckPickingPolicyQuery,
  CheckPickingPolicyQueryVariables
>;
export const LoadRoutingInaccessibleTotalDocument = gql`
  query loadRoutingInaccessibleTotal(
    $input: FindLayoutInaccessibleLocationsByRuleInput!
    $page: SkipLimitPageSpec
    $filter: LayoutLocationsSearchFilter
    $sortBy: [LayoutLocationsSortBy!]
  ) {
    findLayoutInaccessibleLocations(
      input: $input
      sortBy: $sortBy
      page: $page
      filter: $filter
    ) {
      totalCount
    }
  }
`;

/**
 * __useLoadRoutingInaccessibleTotalQuery__
 *
 * To run a query within a React component, call `useLoadRoutingInaccessibleTotalQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadRoutingInaccessibleTotalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadRoutingInaccessibleTotalQuery({
 *   variables: {
 *      input: // value for 'input'
 *      page: // value for 'page'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useLoadRoutingInaccessibleTotalQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadRoutingInaccessibleTotalQuery,
    LoadRoutingInaccessibleTotalQueryVariables
  > &
    (
      | {
          variables: LoadRoutingInaccessibleTotalQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadRoutingInaccessibleTotalQuery,
    LoadRoutingInaccessibleTotalQueryVariables
  >(LoadRoutingInaccessibleTotalDocument, options);
}
export function useLoadRoutingInaccessibleTotalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadRoutingInaccessibleTotalQuery,
    LoadRoutingInaccessibleTotalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadRoutingInaccessibleTotalQuery,
    LoadRoutingInaccessibleTotalQueryVariables
  >(LoadRoutingInaccessibleTotalDocument, options);
}
export function useLoadRoutingInaccessibleTotalSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadRoutingInaccessibleTotalQuery,
        LoadRoutingInaccessibleTotalQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadRoutingInaccessibleTotalQuery,
    LoadRoutingInaccessibleTotalQueryVariables
  >(LoadRoutingInaccessibleTotalDocument, options);
}
export type LoadRoutingInaccessibleTotalQueryHookResult = ReturnType<
  typeof useLoadRoutingInaccessibleTotalQuery
>;
export type LoadRoutingInaccessibleTotalLazyQueryHookResult = ReturnType<
  typeof useLoadRoutingInaccessibleTotalLazyQuery
>;
export type LoadRoutingInaccessibleTotalSuspenseQueryHookResult = ReturnType<
  typeof useLoadRoutingInaccessibleTotalSuspenseQuery
>;
export type LoadRoutingInaccessibleTotalQueryResult = Apollo.QueryResult<
  LoadRoutingInaccessibleTotalQuery,
  LoadRoutingInaccessibleTotalQueryVariables
>;
export const LoadRoutingInaccessibleLocationsDocument = gql`
  query loadRoutingInaccessibleLocations(
    $input: FindLayoutInaccessibleLocationsByRuleInput!
    $page: SkipLimitPageSpec
    $filter: LayoutLocationsSearchFilter
    $sortBy: [LayoutLocationsSortBy!]
  ) {
    findLayoutInaccessibleLocations(
      input: $input
      sortBy: $sortBy
      page: $page
      filter: $filter
    ) {
      totalCount
      content {
        reason
        locationId
      }
    }
  }
`;

/**
 * __useLoadRoutingInaccessibleLocationsQuery__
 *
 * To run a query within a React component, call `useLoadRoutingInaccessibleLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadRoutingInaccessibleLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadRoutingInaccessibleLocationsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      page: // value for 'page'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useLoadRoutingInaccessibleLocationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadRoutingInaccessibleLocationsQuery,
    LoadRoutingInaccessibleLocationsQueryVariables
  > &
    (
      | {
          variables: LoadRoutingInaccessibleLocationsQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadRoutingInaccessibleLocationsQuery,
    LoadRoutingInaccessibleLocationsQueryVariables
  >(LoadRoutingInaccessibleLocationsDocument, options);
}
export function useLoadRoutingInaccessibleLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadRoutingInaccessibleLocationsQuery,
    LoadRoutingInaccessibleLocationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadRoutingInaccessibleLocationsQuery,
    LoadRoutingInaccessibleLocationsQueryVariables
  >(LoadRoutingInaccessibleLocationsDocument, options);
}
export function useLoadRoutingInaccessibleLocationsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadRoutingInaccessibleLocationsQuery,
        LoadRoutingInaccessibleLocationsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadRoutingInaccessibleLocationsQuery,
    LoadRoutingInaccessibleLocationsQueryVariables
  >(LoadRoutingInaccessibleLocationsDocument, options);
}
export type LoadRoutingInaccessibleLocationsQueryHookResult = ReturnType<
  typeof useLoadRoutingInaccessibleLocationsQuery
>;
export type LoadRoutingInaccessibleLocationsLazyQueryHookResult = ReturnType<
  typeof useLoadRoutingInaccessibleLocationsLazyQuery
>;
export type LoadRoutingInaccessibleLocationsSuspenseQueryHookResult =
  ReturnType<typeof useLoadRoutingInaccessibleLocationsSuspenseQuery>;
export type LoadRoutingInaccessibleLocationsQueryResult = Apollo.QueryResult<
  LoadRoutingInaccessibleLocationsQuery,
  LoadRoutingInaccessibleLocationsQueryVariables
>;
export const BuildSimulationLayoutRouteDocument = gql`
  query buildSimulationLayoutRoute($input: BuildSimulationLayoutRouteInput!) {
    buildSimulationLayoutRoute(input: $input) {
      ...LayoutRoute
    }
  }
  ${LayoutRouteFragmentDoc}
`;

/**
 * __useBuildSimulationLayoutRouteQuery__
 *
 * To run a query within a React component, call `useBuildSimulationLayoutRouteQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuildSimulationLayoutRouteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuildSimulationLayoutRouteQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBuildSimulationLayoutRouteQuery(
  baseOptions: Apollo.QueryHookOptions<
    BuildSimulationLayoutRouteQuery,
    BuildSimulationLayoutRouteQueryVariables
  > &
    (
      | { variables: BuildSimulationLayoutRouteQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BuildSimulationLayoutRouteQuery,
    BuildSimulationLayoutRouteQueryVariables
  >(BuildSimulationLayoutRouteDocument, options);
}
export function useBuildSimulationLayoutRouteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BuildSimulationLayoutRouteQuery,
    BuildSimulationLayoutRouteQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BuildSimulationLayoutRouteQuery,
    BuildSimulationLayoutRouteQueryVariables
  >(BuildSimulationLayoutRouteDocument, options);
}
export function useBuildSimulationLayoutRouteSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BuildSimulationLayoutRouteQuery,
        BuildSimulationLayoutRouteQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    BuildSimulationLayoutRouteQuery,
    BuildSimulationLayoutRouteQueryVariables
  >(BuildSimulationLayoutRouteDocument, options);
}
export type BuildSimulationLayoutRouteQueryHookResult = ReturnType<
  typeof useBuildSimulationLayoutRouteQuery
>;
export type BuildSimulationLayoutRouteLazyQueryHookResult = ReturnType<
  typeof useBuildSimulationLayoutRouteLazyQuery
>;
export type BuildSimulationLayoutRouteSuspenseQueryHookResult = ReturnType<
  typeof useBuildSimulationLayoutRouteSuspenseQuery
>;
export type BuildSimulationLayoutRouteQueryResult = Apollo.QueryResult<
  BuildSimulationLayoutRouteQuery,
  BuildSimulationLayoutRouteQueryVariables
>;
export const BuildLayoutRouteDocument = gql`
  query buildLayoutRoute($input: BuildLayoutRouteInput!) {
    buildLayoutRoute(input: $input) {
      ...LayoutRoute
    }
  }
  ${LayoutRouteFragmentDoc}
`;

/**
 * __useBuildLayoutRouteQuery__
 *
 * To run a query within a React component, call `useBuildLayoutRouteQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuildLayoutRouteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuildLayoutRouteQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBuildLayoutRouteQuery(
  baseOptions: Apollo.QueryHookOptions<
    BuildLayoutRouteQuery,
    BuildLayoutRouteQueryVariables
  > &
    (
      | { variables: BuildLayoutRouteQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BuildLayoutRouteQuery, BuildLayoutRouteQueryVariables>(
    BuildLayoutRouteDocument,
    options,
  );
}
export function useBuildLayoutRouteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BuildLayoutRouteQuery,
    BuildLayoutRouteQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BuildLayoutRouteQuery,
    BuildLayoutRouteQueryVariables
  >(BuildLayoutRouteDocument, options);
}
export function useBuildLayoutRouteSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BuildLayoutRouteQuery,
        BuildLayoutRouteQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    BuildLayoutRouteQuery,
    BuildLayoutRouteQueryVariables
  >(BuildLayoutRouteDocument, options);
}
export type BuildLayoutRouteQueryHookResult = ReturnType<
  typeof useBuildLayoutRouteQuery
>;
export type BuildLayoutRouteLazyQueryHookResult = ReturnType<
  typeof useBuildLayoutRouteLazyQuery
>;
export type BuildLayoutRouteSuspenseQueryHookResult = ReturnType<
  typeof useBuildLayoutRouteSuspenseQuery
>;
export type BuildLayoutRouteQueryResult = Apollo.QueryResult<
  BuildLayoutRouteQuery,
  BuildLayoutRouteQueryVariables
>;
export const BatchBuildLayoutRouteDocument = gql`
  query batchBuildLayoutRoute($input: BatchBuildLayoutRouteInput!) {
    batchBuildLayoutRoute(input: $input) {
      ...LayoutRoute
    }
  }
  ${LayoutRouteFragmentDoc}
`;

/**
 * __useBatchBuildLayoutRouteQuery__
 *
 * To run a query within a React component, call `useBatchBuildLayoutRouteQuery` and pass it any options that fit your needs.
 * When your component renders, `useBatchBuildLayoutRouteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBatchBuildLayoutRouteQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBatchBuildLayoutRouteQuery(
  baseOptions: Apollo.QueryHookOptions<
    BatchBuildLayoutRouteQuery,
    BatchBuildLayoutRouteQueryVariables
  > &
    (
      | { variables: BatchBuildLayoutRouteQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BatchBuildLayoutRouteQuery,
    BatchBuildLayoutRouteQueryVariables
  >(BatchBuildLayoutRouteDocument, options);
}
export function useBatchBuildLayoutRouteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BatchBuildLayoutRouteQuery,
    BatchBuildLayoutRouteQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BatchBuildLayoutRouteQuery,
    BatchBuildLayoutRouteQueryVariables
  >(BatchBuildLayoutRouteDocument, options);
}
export function useBatchBuildLayoutRouteSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BatchBuildLayoutRouteQuery,
        BatchBuildLayoutRouteQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    BatchBuildLayoutRouteQuery,
    BatchBuildLayoutRouteQueryVariables
  >(BatchBuildLayoutRouteDocument, options);
}
export type BatchBuildLayoutRouteQueryHookResult = ReturnType<
  typeof useBatchBuildLayoutRouteQuery
>;
export type BatchBuildLayoutRouteLazyQueryHookResult = ReturnType<
  typeof useBatchBuildLayoutRouteLazyQuery
>;
export type BatchBuildLayoutRouteSuspenseQueryHookResult = ReturnType<
  typeof useBatchBuildLayoutRouteSuspenseQuery
>;
export type BatchBuildLayoutRouteQueryResult = Apollo.QueryResult<
  BatchBuildLayoutRouteQuery,
  BatchBuildLayoutRouteQueryVariables
>;
export const LoadProductByCategoriesDocument = gql`
  query loadProductByCategories(
    $simulationId: ID!
    $categoryWidths: [Float!]!
  ) {
    simulation(id: $simulationId) {
      ...SimulationABC
    }
  }
  ${SimulationAbcFragmentDoc}
`;

/**
 * __useLoadProductByCategoriesQuery__
 *
 * To run a query within a React component, call `useLoadProductByCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadProductByCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadProductByCategoriesQuery({
 *   variables: {
 *      simulationId: // value for 'simulationId'
 *      categoryWidths: // value for 'categoryWidths'
 *   },
 * });
 */
export function useLoadProductByCategoriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadProductByCategoriesQuery,
    LoadProductByCategoriesQueryVariables
  > &
    (
      | { variables: LoadProductByCategoriesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadProductByCategoriesQuery,
    LoadProductByCategoriesQueryVariables
  >(LoadProductByCategoriesDocument, options);
}
export function useLoadProductByCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadProductByCategoriesQuery,
    LoadProductByCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadProductByCategoriesQuery,
    LoadProductByCategoriesQueryVariables
  >(LoadProductByCategoriesDocument, options);
}
export function useLoadProductByCategoriesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadProductByCategoriesQuery,
        LoadProductByCategoriesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadProductByCategoriesQuery,
    LoadProductByCategoriesQueryVariables
  >(LoadProductByCategoriesDocument, options);
}
export type LoadProductByCategoriesQueryHookResult = ReturnType<
  typeof useLoadProductByCategoriesQuery
>;
export type LoadProductByCategoriesLazyQueryHookResult = ReturnType<
  typeof useLoadProductByCategoriesLazyQuery
>;
export type LoadProductByCategoriesSuspenseQueryHookResult = ReturnType<
  typeof useLoadProductByCategoriesSuspenseQuery
>;
export type LoadProductByCategoriesQueryResult = Apollo.QueryResult<
  LoadProductByCategoriesQuery,
  LoadProductByCategoriesQueryVariables
>;
export const LoadSimulationProductMetaDocument = gql`
  query loadSimulationProductMeta($simulationId: ID!) {
    findSimulationItemsByFilter(
      input: {
        simulationId: $simulationId
        includeMatching: {
          anyOf: [{ allOf: { type: PERCENT_RANK, isNot: true, isNull: true } }]
        }
      }
      page: null
    ) {
      content {
        ...SimulationItemMeta
      }
      totalCount
    }
  }
  ${SimulationItemMetaFragmentDoc}
`;

/**
 * __useLoadSimulationProductMetaQuery__
 *
 * To run a query within a React component, call `useLoadSimulationProductMetaQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadSimulationProductMetaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadSimulationProductMetaQuery({
 *   variables: {
 *      simulationId: // value for 'simulationId'
 *   },
 * });
 */
export function useLoadSimulationProductMetaQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadSimulationProductMetaQuery,
    LoadSimulationProductMetaQueryVariables
  > &
    (
      | { variables: LoadSimulationProductMetaQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadSimulationProductMetaQuery,
    LoadSimulationProductMetaQueryVariables
  >(LoadSimulationProductMetaDocument, options);
}
export function useLoadSimulationProductMetaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadSimulationProductMetaQuery,
    LoadSimulationProductMetaQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadSimulationProductMetaQuery,
    LoadSimulationProductMetaQueryVariables
  >(LoadSimulationProductMetaDocument, options);
}
export function useLoadSimulationProductMetaSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadSimulationProductMetaQuery,
        LoadSimulationProductMetaQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadSimulationProductMetaQuery,
    LoadSimulationProductMetaQueryVariables
  >(LoadSimulationProductMetaDocument, options);
}
export type LoadSimulationProductMetaQueryHookResult = ReturnType<
  typeof useLoadSimulationProductMetaQuery
>;
export type LoadSimulationProductMetaLazyQueryHookResult = ReturnType<
  typeof useLoadSimulationProductMetaLazyQuery
>;
export type LoadSimulationProductMetaSuspenseQueryHookResult = ReturnType<
  typeof useLoadSimulationProductMetaSuspenseQuery
>;
export type LoadSimulationProductMetaQueryResult = Apollo.QueryResult<
  LoadSimulationProductMetaQuery,
  LoadSimulationProductMetaQueryVariables
>;
export const CreateSimulationDocument = gql`
  mutation createSimulation($input: CreateSimulationInput!) {
    createSimulation(input: $input) {
      ...SimulationMeta
    }
  }
  ${SimulationMetaFragmentDoc}
`;
export type CreateSimulationMutationFn = Apollo.MutationFunction<
  CreateSimulationMutation,
  CreateSimulationMutationVariables
>;

/**
 * __useCreateSimulationMutation__
 *
 * To run a mutation, you first call `useCreateSimulationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSimulationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSimulationMutation, { data, loading, error }] = useCreateSimulationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSimulationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSimulationMutation,
    CreateSimulationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateSimulationMutation,
    CreateSimulationMutationVariables
  >(CreateSimulationDocument, options);
}
export type CreateSimulationMutationHookResult = ReturnType<
  typeof useCreateSimulationMutation
>;
export type CreateSimulationMutationResult =
  Apollo.MutationResult<CreateSimulationMutation>;
export type CreateSimulationMutationOptions = Apollo.BaseMutationOptions<
  CreateSimulationMutation,
  CreateSimulationMutationVariables
>;
export const LoadSimulationDocument = gql`
  query loadSimulation($simulationId: ID!) {
    simulation(id: $simulationId) {
      ...SimulationMeta
    }
  }
  ${SimulationMetaFragmentDoc}
`;

/**
 * __useLoadSimulationQuery__
 *
 * To run a query within a React component, call `useLoadSimulationQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadSimulationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadSimulationQuery({
 *   variables: {
 *      simulationId: // value for 'simulationId'
 *   },
 * });
 */
export function useLoadSimulationQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadSimulationQuery,
    LoadSimulationQueryVariables
  > &
    (
      | { variables: LoadSimulationQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadSimulationQuery, LoadSimulationQueryVariables>(
    LoadSimulationDocument,
    options,
  );
}
export function useLoadSimulationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadSimulationQuery,
    LoadSimulationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LoadSimulationQuery, LoadSimulationQueryVariables>(
    LoadSimulationDocument,
    options,
  );
}
export function useLoadSimulationSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadSimulationQuery,
        LoadSimulationQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadSimulationQuery,
    LoadSimulationQueryVariables
  >(LoadSimulationDocument, options);
}
export type LoadSimulationQueryHookResult = ReturnType<
  typeof useLoadSimulationQuery
>;
export type LoadSimulationLazyQueryHookResult = ReturnType<
  typeof useLoadSimulationLazyQuery
>;
export type LoadSimulationSuspenseQueryHookResult = ReturnType<
  typeof useLoadSimulationSuspenseQuery
>;
export type LoadSimulationQueryResult = Apollo.QueryResult<
  LoadSimulationQuery,
  LoadSimulationQueryVariables
>;
export const UpdateSimulationDocument = gql`
  mutation updateSimulation($input: UpdateSimulationInput!) {
    updateSimulation(input: $input) {
      ...SimulationMetaStatus
    }
  }
  ${SimulationMetaStatusFragmentDoc}
`;
export type UpdateSimulationMutationFn = Apollo.MutationFunction<
  UpdateSimulationMutation,
  UpdateSimulationMutationVariables
>;

/**
 * __useUpdateSimulationMutation__
 *
 * To run a mutation, you first call `useUpdateSimulationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSimulationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSimulationMutation, { data, loading, error }] = useUpdateSimulationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSimulationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSimulationMutation,
    UpdateSimulationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSimulationMutation,
    UpdateSimulationMutationVariables
  >(UpdateSimulationDocument, options);
}
export type UpdateSimulationMutationHookResult = ReturnType<
  typeof useUpdateSimulationMutation
>;
export type UpdateSimulationMutationResult =
  Apollo.MutationResult<UpdateSimulationMutation>;
export type UpdateSimulationMutationOptions = Apollo.BaseMutationOptions<
  UpdateSimulationMutation,
  UpdateSimulationMutationVariables
>;
export const RemoveSimulationDocument = gql`
  mutation removeSimulation($simulationId: String!) {
    removeSimulation(simulationId: $simulationId)
  }
`;
export type RemoveSimulationMutationFn = Apollo.MutationFunction<
  RemoveSimulationMutation,
  RemoveSimulationMutationVariables
>;

/**
 * __useRemoveSimulationMutation__
 *
 * To run a mutation, you first call `useRemoveSimulationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSimulationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSimulationMutation, { data, loading, error }] = useRemoveSimulationMutation({
 *   variables: {
 *      simulationId: // value for 'simulationId'
 *   },
 * });
 */
export function useRemoveSimulationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveSimulationMutation,
    RemoveSimulationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveSimulationMutation,
    RemoveSimulationMutationVariables
  >(RemoveSimulationDocument, options);
}
export type RemoveSimulationMutationHookResult = ReturnType<
  typeof useRemoveSimulationMutation
>;
export type RemoveSimulationMutationResult =
  Apollo.MutationResult<RemoveSimulationMutation>;
export type RemoveSimulationMutationOptions = Apollo.BaseMutationOptions<
  RemoveSimulationMutation,
  RemoveSimulationMutationVariables
>;
export const DuplicateSimulationDocument = gql`
  mutation duplicateSimulation($simulationId: ID!, $title: String) {
    duplicateSimulation(input: { simulationId: $simulationId, title: $title }) {
      id
    }
  }
`;
export type DuplicateSimulationMutationFn = Apollo.MutationFunction<
  DuplicateSimulationMutation,
  DuplicateSimulationMutationVariables
>;

/**
 * __useDuplicateSimulationMutation__
 *
 * To run a mutation, you first call `useDuplicateSimulationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateSimulationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateSimulationMutation, { data, loading, error }] = useDuplicateSimulationMutation({
 *   variables: {
 *      simulationId: // value for 'simulationId'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useDuplicateSimulationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DuplicateSimulationMutation,
    DuplicateSimulationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DuplicateSimulationMutation,
    DuplicateSimulationMutationVariables
  >(DuplicateSimulationDocument, options);
}
export type DuplicateSimulationMutationHookResult = ReturnType<
  typeof useDuplicateSimulationMutation
>;
export type DuplicateSimulationMutationResult =
  Apollo.MutationResult<DuplicateSimulationMutation>;
export type DuplicateSimulationMutationOptions = Apollo.BaseMutationOptions<
  DuplicateSimulationMutation,
  DuplicateSimulationMutationVariables
>;
export const LoadSizeComplianceForLocationsDocument = gql`
  query loadSizeComplianceForLocations(
    $simulationId: ID!
    $filter: SizeComplianceLocationFilter
    $sortBy: [SizeComplianceLocationSortBy!]
    $page: SkipLimitPageSpec
  ) {
    simulation(id: $simulationId) {
      sizeCompliance {
        locations(sortBy: $sortBy, page: $page, filter: $filter) {
          totalCount
          content {
            ...SizeComplianceLocationDetails
          }
        }
      }
    }
  }
  ${SizeComplianceLocationDetailsFragmentDoc}
`;

/**
 * __useLoadSizeComplianceForLocationsQuery__
 *
 * To run a query within a React component, call `useLoadSizeComplianceForLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadSizeComplianceForLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadSizeComplianceForLocationsQuery({
 *   variables: {
 *      simulationId: // value for 'simulationId'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useLoadSizeComplianceForLocationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadSizeComplianceForLocationsQuery,
    LoadSizeComplianceForLocationsQueryVariables
  > &
    (
      | {
          variables: LoadSizeComplianceForLocationsQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadSizeComplianceForLocationsQuery,
    LoadSizeComplianceForLocationsQueryVariables
  >(LoadSizeComplianceForLocationsDocument, options);
}
export function useLoadSizeComplianceForLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadSizeComplianceForLocationsQuery,
    LoadSizeComplianceForLocationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadSizeComplianceForLocationsQuery,
    LoadSizeComplianceForLocationsQueryVariables
  >(LoadSizeComplianceForLocationsDocument, options);
}
export function useLoadSizeComplianceForLocationsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadSizeComplianceForLocationsQuery,
        LoadSizeComplianceForLocationsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadSizeComplianceForLocationsQuery,
    LoadSizeComplianceForLocationsQueryVariables
  >(LoadSizeComplianceForLocationsDocument, options);
}
export type LoadSizeComplianceForLocationsQueryHookResult = ReturnType<
  typeof useLoadSizeComplianceForLocationsQuery
>;
export type LoadSizeComplianceForLocationsLazyQueryHookResult = ReturnType<
  typeof useLoadSizeComplianceForLocationsLazyQuery
>;
export type LoadSizeComplianceForLocationsSuspenseQueryHookResult = ReturnType<
  typeof useLoadSizeComplianceForLocationsSuspenseQuery
>;
export type LoadSizeComplianceForLocationsQueryResult = Apollo.QueryResult<
  LoadSizeComplianceForLocationsQuery,
  LoadSizeComplianceForLocationsQueryVariables
>;
export const LoadSizeComplianceForLocationsStatusOnlyDocument = gql`
  query loadSizeComplianceForLocationsStatusOnly(
    $simulationId: ID!
    $filter: SizeComplianceLocationFilter
    $sortBy: [SizeComplianceLocationSortBy!]
    $page: SkipLimitPageSpec
  ) {
    simulation(id: $simulationId) {
      sizeCompliance {
        locations(sortBy: $sortBy, page: $page, filter: $filter) {
          totalCount
          content {
            ...SizeComplianceLocationStatus
          }
        }
      }
    }
  }
  ${SizeComplianceLocationStatusFragmentDoc}
`;

/**
 * __useLoadSizeComplianceForLocationsStatusOnlyQuery__
 *
 * To run a query within a React component, call `useLoadSizeComplianceForLocationsStatusOnlyQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadSizeComplianceForLocationsStatusOnlyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadSizeComplianceForLocationsStatusOnlyQuery({
 *   variables: {
 *      simulationId: // value for 'simulationId'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useLoadSizeComplianceForLocationsStatusOnlyQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadSizeComplianceForLocationsStatusOnlyQuery,
    LoadSizeComplianceForLocationsStatusOnlyQueryVariables
  > &
    (
      | {
          variables: LoadSizeComplianceForLocationsStatusOnlyQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadSizeComplianceForLocationsStatusOnlyQuery,
    LoadSizeComplianceForLocationsStatusOnlyQueryVariables
  >(LoadSizeComplianceForLocationsStatusOnlyDocument, options);
}
export function useLoadSizeComplianceForLocationsStatusOnlyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadSizeComplianceForLocationsStatusOnlyQuery,
    LoadSizeComplianceForLocationsStatusOnlyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadSizeComplianceForLocationsStatusOnlyQuery,
    LoadSizeComplianceForLocationsStatusOnlyQueryVariables
  >(LoadSizeComplianceForLocationsStatusOnlyDocument, options);
}
export function useLoadSizeComplianceForLocationsStatusOnlySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadSizeComplianceForLocationsStatusOnlyQuery,
        LoadSizeComplianceForLocationsStatusOnlyQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadSizeComplianceForLocationsStatusOnlyQuery,
    LoadSizeComplianceForLocationsStatusOnlyQueryVariables
  >(LoadSizeComplianceForLocationsStatusOnlyDocument, options);
}
export type LoadSizeComplianceForLocationsStatusOnlyQueryHookResult =
  ReturnType<typeof useLoadSizeComplianceForLocationsStatusOnlyQuery>;
export type LoadSizeComplianceForLocationsStatusOnlyLazyQueryHookResult =
  ReturnType<typeof useLoadSizeComplianceForLocationsStatusOnlyLazyQuery>;
export type LoadSizeComplianceForLocationsStatusOnlySuspenseQueryHookResult =
  ReturnType<typeof useLoadSizeComplianceForLocationsStatusOnlySuspenseQuery>;
export type LoadSizeComplianceForLocationsStatusOnlyQueryResult =
  Apollo.QueryResult<
    LoadSizeComplianceForLocationsStatusOnlyQuery,
    LoadSizeComplianceForLocationsStatusOnlyQueryVariables
  >;
export const LoadSizeComplianceMetaDocument = gql`
  query loadSizeComplianceMeta($simulationId: ID!) {
    simulation(id: $simulationId) {
      sizeCompliance {
        ...SizeComplianceMeta
      }
    }
  }
  ${SizeComplianceMetaFragmentDoc}
`;

/**
 * __useLoadSizeComplianceMetaQuery__
 *
 * To run a query within a React component, call `useLoadSizeComplianceMetaQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadSizeComplianceMetaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadSizeComplianceMetaQuery({
 *   variables: {
 *      simulationId: // value for 'simulationId'
 *   },
 * });
 */
export function useLoadSizeComplianceMetaQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadSizeComplianceMetaQuery,
    LoadSizeComplianceMetaQueryVariables
  > &
    (
      | { variables: LoadSizeComplianceMetaQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadSizeComplianceMetaQuery,
    LoadSizeComplianceMetaQueryVariables
  >(LoadSizeComplianceMetaDocument, options);
}
export function useLoadSizeComplianceMetaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadSizeComplianceMetaQuery,
    LoadSizeComplianceMetaQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadSizeComplianceMetaQuery,
    LoadSizeComplianceMetaQueryVariables
  >(LoadSizeComplianceMetaDocument, options);
}
export function useLoadSizeComplianceMetaSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadSizeComplianceMetaQuery,
        LoadSizeComplianceMetaQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadSizeComplianceMetaQuery,
    LoadSizeComplianceMetaQueryVariables
  >(LoadSizeComplianceMetaDocument, options);
}
export type LoadSizeComplianceMetaQueryHookResult = ReturnType<
  typeof useLoadSizeComplianceMetaQuery
>;
export type LoadSizeComplianceMetaLazyQueryHookResult = ReturnType<
  typeof useLoadSizeComplianceMetaLazyQuery
>;
export type LoadSizeComplianceMetaSuspenseQueryHookResult = ReturnType<
  typeof useLoadSizeComplianceMetaSuspenseQuery
>;
export type LoadSizeComplianceMetaQueryResult = Apollo.QueryResult<
  LoadSizeComplianceMetaQuery,
  LoadSizeComplianceMetaQueryVariables
>;
export const RunCalculateSizeComplianceDocument = gql`
  mutation runCalculateSizeCompliance($simulationId: String!) {
    calculateSimulationSizeCompliance(simulationId: $simulationId) {
      ...SizeComplianceMeta
    }
  }
  ${SizeComplianceMetaFragmentDoc}
`;
export type RunCalculateSizeComplianceMutationFn = Apollo.MutationFunction<
  RunCalculateSizeComplianceMutation,
  RunCalculateSizeComplianceMutationVariables
>;

/**
 * __useRunCalculateSizeComplianceMutation__
 *
 * To run a mutation, you first call `useRunCalculateSizeComplianceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunCalculateSizeComplianceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runCalculateSizeComplianceMutation, { data, loading, error }] = useRunCalculateSizeComplianceMutation({
 *   variables: {
 *      simulationId: // value for 'simulationId'
 *   },
 * });
 */
export function useRunCalculateSizeComplianceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RunCalculateSizeComplianceMutation,
    RunCalculateSizeComplianceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RunCalculateSizeComplianceMutation,
    RunCalculateSizeComplianceMutationVariables
  >(RunCalculateSizeComplianceDocument, options);
}
export type RunCalculateSizeComplianceMutationHookResult = ReturnType<
  typeof useRunCalculateSizeComplianceMutation
>;
export type RunCalculateSizeComplianceMutationResult =
  Apollo.MutationResult<RunCalculateSizeComplianceMutation>;
export type RunCalculateSizeComplianceMutationOptions =
  Apollo.BaseMutationOptions<
    RunCalculateSizeComplianceMutation,
    RunCalculateSizeComplianceMutationVariables
  >;
export const RunCheckSwapPolicyDocument = gql`
  mutation runCheckSwapPolicy($input: CheckOptimizationSwapPolicyInput!) {
    startCheckOptimizationSwapPolicyJob(input: $input) {
      id
      status
    }
  }
`;
export type RunCheckSwapPolicyMutationFn = Apollo.MutationFunction<
  RunCheckSwapPolicyMutation,
  RunCheckSwapPolicyMutationVariables
>;

/**
 * __useRunCheckSwapPolicyMutation__
 *
 * To run a mutation, you first call `useRunCheckSwapPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunCheckSwapPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runCheckSwapPolicyMutation, { data, loading, error }] = useRunCheckSwapPolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRunCheckSwapPolicyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RunCheckSwapPolicyMutation,
    RunCheckSwapPolicyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RunCheckSwapPolicyMutation,
    RunCheckSwapPolicyMutationVariables
  >(RunCheckSwapPolicyDocument, options);
}
export type RunCheckSwapPolicyMutationHookResult = ReturnType<
  typeof useRunCheckSwapPolicyMutation
>;
export type RunCheckSwapPolicyMutationResult =
  Apollo.MutationResult<RunCheckSwapPolicyMutation>;
export type RunCheckSwapPolicyMutationOptions = Apollo.BaseMutationOptions<
  RunCheckSwapPolicyMutation,
  RunCheckSwapPolicyMutationVariables
>;
export const LoadCheckSwapPolicyJobDocument = gql`
  query loadCheckSwapPolicyJob($id: String!) {
    checkOptimizationSwapPolicyJob(id: $id) {
      ...CheckOptimizationSwapPolicyJob
    }
  }
  ${CheckOptimizationSwapPolicyJobFragmentDoc}
`;

/**
 * __useLoadCheckSwapPolicyJobQuery__
 *
 * To run a query within a React component, call `useLoadCheckSwapPolicyJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadCheckSwapPolicyJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadCheckSwapPolicyJobQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoadCheckSwapPolicyJobQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadCheckSwapPolicyJobQuery,
    LoadCheckSwapPolicyJobQueryVariables
  > &
    (
      | { variables: LoadCheckSwapPolicyJobQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadCheckSwapPolicyJobQuery,
    LoadCheckSwapPolicyJobQueryVariables
  >(LoadCheckSwapPolicyJobDocument, options);
}
export function useLoadCheckSwapPolicyJobLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadCheckSwapPolicyJobQuery,
    LoadCheckSwapPolicyJobQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadCheckSwapPolicyJobQuery,
    LoadCheckSwapPolicyJobQueryVariables
  >(LoadCheckSwapPolicyJobDocument, options);
}
export function useLoadCheckSwapPolicyJobSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadCheckSwapPolicyJobQuery,
        LoadCheckSwapPolicyJobQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadCheckSwapPolicyJobQuery,
    LoadCheckSwapPolicyJobQueryVariables
  >(LoadCheckSwapPolicyJobDocument, options);
}
export type LoadCheckSwapPolicyJobQueryHookResult = ReturnType<
  typeof useLoadCheckSwapPolicyJobQuery
>;
export type LoadCheckSwapPolicyJobLazyQueryHookResult = ReturnType<
  typeof useLoadCheckSwapPolicyJobLazyQuery
>;
export type LoadCheckSwapPolicyJobSuspenseQueryHookResult = ReturnType<
  typeof useLoadCheckSwapPolicyJobSuspenseQuery
>;
export type LoadCheckSwapPolicyJobQueryResult = Apollo.QueryResult<
  LoadCheckSwapPolicyJobQuery,
  LoadCheckSwapPolicyJobQueryVariables
>;
export const LoadCurrentUserSettingsDocument = gql`
  query loadCurrentUserSettings {
    currentUserSettings {
      ...UserSettings
    }
  }
  ${UserSettingsFragmentDoc}
`;

/**
 * __useLoadCurrentUserSettingsQuery__
 *
 * To run a query within a React component, call `useLoadCurrentUserSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadCurrentUserSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadCurrentUserSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoadCurrentUserSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LoadCurrentUserSettingsQuery,
    LoadCurrentUserSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadCurrentUserSettingsQuery,
    LoadCurrentUserSettingsQueryVariables
  >(LoadCurrentUserSettingsDocument, options);
}
export function useLoadCurrentUserSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadCurrentUserSettingsQuery,
    LoadCurrentUserSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadCurrentUserSettingsQuery,
    LoadCurrentUserSettingsQueryVariables
  >(LoadCurrentUserSettingsDocument, options);
}
export function useLoadCurrentUserSettingsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadCurrentUserSettingsQuery,
        LoadCurrentUserSettingsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadCurrentUserSettingsQuery,
    LoadCurrentUserSettingsQueryVariables
  >(LoadCurrentUserSettingsDocument, options);
}
export type LoadCurrentUserSettingsQueryHookResult = ReturnType<
  typeof useLoadCurrentUserSettingsQuery
>;
export type LoadCurrentUserSettingsLazyQueryHookResult = ReturnType<
  typeof useLoadCurrentUserSettingsLazyQuery
>;
export type LoadCurrentUserSettingsSuspenseQueryHookResult = ReturnType<
  typeof useLoadCurrentUserSettingsSuspenseQuery
>;
export type LoadCurrentUserSettingsQueryResult = Apollo.QueryResult<
  LoadCurrentUserSettingsQuery,
  LoadCurrentUserSettingsQueryVariables
>;
export const LoadCurrentUserBaseSettingDocument = gql`
  query loadCurrentUserBaseSetting {
    currentUserSettings {
      ...UserSettingsBase
    }
  }
  ${UserSettingsBaseFragmentDoc}
`;

/**
 * __useLoadCurrentUserBaseSettingQuery__
 *
 * To run a query within a React component, call `useLoadCurrentUserBaseSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadCurrentUserBaseSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadCurrentUserBaseSettingQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoadCurrentUserBaseSettingQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LoadCurrentUserBaseSettingQuery,
    LoadCurrentUserBaseSettingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadCurrentUserBaseSettingQuery,
    LoadCurrentUserBaseSettingQueryVariables
  >(LoadCurrentUserBaseSettingDocument, options);
}
export function useLoadCurrentUserBaseSettingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadCurrentUserBaseSettingQuery,
    LoadCurrentUserBaseSettingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadCurrentUserBaseSettingQuery,
    LoadCurrentUserBaseSettingQueryVariables
  >(LoadCurrentUserBaseSettingDocument, options);
}
export function useLoadCurrentUserBaseSettingSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadCurrentUserBaseSettingQuery,
        LoadCurrentUserBaseSettingQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadCurrentUserBaseSettingQuery,
    LoadCurrentUserBaseSettingQueryVariables
  >(LoadCurrentUserBaseSettingDocument, options);
}
export type LoadCurrentUserBaseSettingQueryHookResult = ReturnType<
  typeof useLoadCurrentUserBaseSettingQuery
>;
export type LoadCurrentUserBaseSettingLazyQueryHookResult = ReturnType<
  typeof useLoadCurrentUserBaseSettingLazyQuery
>;
export type LoadCurrentUserBaseSettingSuspenseQueryHookResult = ReturnType<
  typeof useLoadCurrentUserBaseSettingSuspenseQuery
>;
export type LoadCurrentUserBaseSettingQueryResult = Apollo.QueryResult<
  LoadCurrentUserBaseSettingQuery,
  LoadCurrentUserBaseSettingQueryVariables
>;
export const UpdateCurrentUserSettingsDocument = gql`
  mutation updateCurrentUserSettings($input: UpdateUserSettingsInput!) {
    updateCurrentUserSettings(input: $input) {
      ...UserSettings
    }
  }
  ${UserSettingsFragmentDoc}
`;
export type UpdateCurrentUserSettingsMutationFn = Apollo.MutationFunction<
  UpdateCurrentUserSettingsMutation,
  UpdateCurrentUserSettingsMutationVariables
>;

/**
 * __useUpdateCurrentUserSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateCurrentUserSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurrentUserSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurrentUserSettingsMutation, { data, loading, error }] = useUpdateCurrentUserSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCurrentUserSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCurrentUserSettingsMutation,
    UpdateCurrentUserSettingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCurrentUserSettingsMutation,
    UpdateCurrentUserSettingsMutationVariables
  >(UpdateCurrentUserSettingsDocument, options);
}
export type UpdateCurrentUserSettingsMutationHookResult = ReturnType<
  typeof useUpdateCurrentUserSettingsMutation
>;
export type UpdateCurrentUserSettingsMutationResult =
  Apollo.MutationResult<UpdateCurrentUserSettingsMutation>;
export type UpdateCurrentUserSettingsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateCurrentUserSettingsMutation,
    UpdateCurrentUserSettingsMutationVariables
  >;
export const CreateWarehouseDocument = gql`
  mutation createWarehouse($input: CreateWarehouseInput!) {
    createWarehouse(input: $input) {
      ...WarehouseMeta
    }
  }
  ${WarehouseMetaFragmentDoc}
`;
export type CreateWarehouseMutationFn = Apollo.MutationFunction<
  CreateWarehouseMutation,
  CreateWarehouseMutationVariables
>;

/**
 * __useCreateWarehouseMutation__
 *
 * To run a mutation, you first call `useCreateWarehouseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWarehouseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWarehouseMutation, { data, loading, error }] = useCreateWarehouseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWarehouseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateWarehouseMutation,
    CreateWarehouseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateWarehouseMutation,
    CreateWarehouseMutationVariables
  >(CreateWarehouseDocument, options);
}
export type CreateWarehouseMutationHookResult = ReturnType<
  typeof useCreateWarehouseMutation
>;
export type CreateWarehouseMutationResult =
  Apollo.MutationResult<CreateWarehouseMutation>;
export type CreateWarehouseMutationOptions = Apollo.BaseMutationOptions<
  CreateWarehouseMutation,
  CreateWarehouseMutationVariables
>;
export const UpdateWarehouseDocument = gql`
  mutation updateWarehouse($input: UpdateWarehouseInput!) {
    updateWarehouse(input: $input) {
      ...WarehouseMeta
    }
  }
  ${WarehouseMetaFragmentDoc}
`;
export type UpdateWarehouseMutationFn = Apollo.MutationFunction<
  UpdateWarehouseMutation,
  UpdateWarehouseMutationVariables
>;

/**
 * __useUpdateWarehouseMutation__
 *
 * To run a mutation, you first call `useUpdateWarehouseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWarehouseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWarehouseMutation, { data, loading, error }] = useUpdateWarehouseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWarehouseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateWarehouseMutation,
    UpdateWarehouseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateWarehouseMutation,
    UpdateWarehouseMutationVariables
  >(UpdateWarehouseDocument, options);
}
export type UpdateWarehouseMutationHookResult = ReturnType<
  typeof useUpdateWarehouseMutation
>;
export type UpdateWarehouseMutationResult =
  Apollo.MutationResult<UpdateWarehouseMutation>;
export type UpdateWarehouseMutationOptions = Apollo.BaseMutationOptions<
  UpdateWarehouseMutation,
  UpdateWarehouseMutationVariables
>;
export const RemoveWarehouseDocument = gql`
  mutation removeWarehouse($id: ID!) {
    removeWarehouse(id: $id)
  }
`;
export type RemoveWarehouseMutationFn = Apollo.MutationFunction<
  RemoveWarehouseMutation,
  RemoveWarehouseMutationVariables
>;

/**
 * __useRemoveWarehouseMutation__
 *
 * To run a mutation, you first call `useRemoveWarehouseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveWarehouseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeWarehouseMutation, { data, loading, error }] = useRemoveWarehouseMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveWarehouseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveWarehouseMutation,
    RemoveWarehouseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveWarehouseMutation,
    RemoveWarehouseMutationVariables
  >(RemoveWarehouseDocument, options);
}
export type RemoveWarehouseMutationHookResult = ReturnType<
  typeof useRemoveWarehouseMutation
>;
export type RemoveWarehouseMutationResult =
  Apollo.MutationResult<RemoveWarehouseMutation>;
export type RemoveWarehouseMutationOptions = Apollo.BaseMutationOptions<
  RemoveWarehouseMutation,
  RemoveWarehouseMutationVariables
>;
export const ChangeDemoWarehouseSubscriptionDocument = gql`
  mutation changeDemoWarehouseSubscription(
    $input: ChangeDemoWarehouseSubscriptionInput!
  ) {
    changeDemoWarehouseSubscription(input: $input) {
      warehouse {
        id
      }
      subscribed
    }
  }
`;
export type ChangeDemoWarehouseSubscriptionMutationFn = Apollo.MutationFunction<
  ChangeDemoWarehouseSubscriptionMutation,
  ChangeDemoWarehouseSubscriptionMutationVariables
>;

/**
 * __useChangeDemoWarehouseSubscriptionMutation__
 *
 * To run a mutation, you first call `useChangeDemoWarehouseSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeDemoWarehouseSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeDemoWarehouseSubscriptionMutation, { data, loading, error }] = useChangeDemoWarehouseSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeDemoWarehouseSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeDemoWarehouseSubscriptionMutation,
    ChangeDemoWarehouseSubscriptionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangeDemoWarehouseSubscriptionMutation,
    ChangeDemoWarehouseSubscriptionMutationVariables
  >(ChangeDemoWarehouseSubscriptionDocument, options);
}
export type ChangeDemoWarehouseSubscriptionMutationHookResult = ReturnType<
  typeof useChangeDemoWarehouseSubscriptionMutation
>;
export type ChangeDemoWarehouseSubscriptionMutationResult =
  Apollo.MutationResult<ChangeDemoWarehouseSubscriptionMutation>;
export type ChangeDemoWarehouseSubscriptionMutationOptions =
  Apollo.BaseMutationOptions<
    ChangeDemoWarehouseSubscriptionMutation,
    ChangeDemoWarehouseSubscriptionMutationVariables
  >;
export const DemoWarehousesDocument = gql`
  query demoWarehouses {
    demoWarehouses {
      warehouse {
        id
        title
        measurementSystem
      }
      subscribed
    }
  }
`;

/**
 * __useDemoWarehousesQuery__
 *
 * To run a query within a React component, call `useDemoWarehousesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDemoWarehousesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDemoWarehousesQuery({
 *   variables: {
 *   },
 * });
 */
export function useDemoWarehousesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DemoWarehousesQuery,
    DemoWarehousesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DemoWarehousesQuery, DemoWarehousesQueryVariables>(
    DemoWarehousesDocument,
    options,
  );
}
export function useDemoWarehousesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DemoWarehousesQuery,
    DemoWarehousesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DemoWarehousesQuery, DemoWarehousesQueryVariables>(
    DemoWarehousesDocument,
    options,
  );
}
export function useDemoWarehousesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        DemoWarehousesQuery,
        DemoWarehousesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    DemoWarehousesQuery,
    DemoWarehousesQueryVariables
  >(DemoWarehousesDocument, options);
}
export type DemoWarehousesQueryHookResult = ReturnType<
  typeof useDemoWarehousesQuery
>;
export type DemoWarehousesLazyQueryHookResult = ReturnType<
  typeof useDemoWarehousesLazyQuery
>;
export type DemoWarehousesSuspenseQueryHookResult = ReturnType<
  typeof useDemoWarehousesSuspenseQuery
>;
export type DemoWarehousesQueryResult = Apollo.QueryResult<
  DemoWarehousesQuery,
  DemoWarehousesQueryVariables
>;
export const LoadSlottingDashboardDocument = gql`
  query loadSlottingDashboard(
    $warehouseId: ID!
    $page: SkipLimitPageSpec!
    $sortBy: [SimulationsSortBy!]
    $filter: SimulationFilter
  ) {
    warehouse(id: $warehouseId) {
      simulations(page: $page, sortBy: $sortBy, filter: $filter) {
        ...DashboardSimulation
      }
    }
  }
  ${DashboardSimulationFragmentDoc}
`;

/**
 * __useLoadSlottingDashboardQuery__
 *
 * To run a query within a React component, call `useLoadSlottingDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadSlottingDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadSlottingDashboardQuery({
 *   variables: {
 *      warehouseId: // value for 'warehouseId'
 *      page: // value for 'page'
 *      sortBy: // value for 'sortBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useLoadSlottingDashboardQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadSlottingDashboardQuery,
    LoadSlottingDashboardQueryVariables
  > &
    (
      | { variables: LoadSlottingDashboardQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadSlottingDashboardQuery,
    LoadSlottingDashboardQueryVariables
  >(LoadSlottingDashboardDocument, options);
}
export function useLoadSlottingDashboardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadSlottingDashboardQuery,
    LoadSlottingDashboardQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadSlottingDashboardQuery,
    LoadSlottingDashboardQueryVariables
  >(LoadSlottingDashboardDocument, options);
}
export function useLoadSlottingDashboardSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadSlottingDashboardQuery,
        LoadSlottingDashboardQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadSlottingDashboardQuery,
    LoadSlottingDashboardQueryVariables
  >(LoadSlottingDashboardDocument, options);
}
export type LoadSlottingDashboardQueryHookResult = ReturnType<
  typeof useLoadSlottingDashboardQuery
>;
export type LoadSlottingDashboardLazyQueryHookResult = ReturnType<
  typeof useLoadSlottingDashboardLazyQuery
>;
export type LoadSlottingDashboardSuspenseQueryHookResult = ReturnType<
  typeof useLoadSlottingDashboardSuspenseQuery
>;
export type LoadSlottingDashboardQueryResult = Apollo.QueryResult<
  LoadSlottingDashboardQuery,
  LoadSlottingDashboardQueryVariables
>;
export const LoadLayoutDashboardDocument = gql`
  query loadLayoutDashboard(
    $warehouseId: ID!
    $page: SkipLimitPageSpec!
    $sortBy: [LayoutsOrDraftsSortBy!]
    $filter: LayoutsOrDraftsFilter
  ) {
    warehouse(id: $warehouseId) {
      layoutsOrDrafts(filter: $filter, page: $page, sortBy: $sortBy) {
        totalCount
        allUserTags
        content {
          __typename
          ... on LayoutImport {
            ...LayoutImportDashboardItem
          }
          ... on Layout {
            ...LayoutDashboardItem
          }
        }
      }
    }
  }
  ${LayoutImportDashboardItemFragmentDoc}
  ${LayoutDashboardItemFragmentDoc}
`;

/**
 * __useLoadLayoutDashboardQuery__
 *
 * To run a query within a React component, call `useLoadLayoutDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadLayoutDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadLayoutDashboardQuery({
 *   variables: {
 *      warehouseId: // value for 'warehouseId'
 *      page: // value for 'page'
 *      sortBy: // value for 'sortBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useLoadLayoutDashboardQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadLayoutDashboardQuery,
    LoadLayoutDashboardQueryVariables
  > &
    (
      | { variables: LoadLayoutDashboardQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadLayoutDashboardQuery,
    LoadLayoutDashboardQueryVariables
  >(LoadLayoutDashboardDocument, options);
}
export function useLoadLayoutDashboardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadLayoutDashboardQuery,
    LoadLayoutDashboardQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadLayoutDashboardQuery,
    LoadLayoutDashboardQueryVariables
  >(LoadLayoutDashboardDocument, options);
}
export function useLoadLayoutDashboardSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadLayoutDashboardQuery,
        LoadLayoutDashboardQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadLayoutDashboardQuery,
    LoadLayoutDashboardQueryVariables
  >(LoadLayoutDashboardDocument, options);
}
export type LoadLayoutDashboardQueryHookResult = ReturnType<
  typeof useLoadLayoutDashboardQuery
>;
export type LoadLayoutDashboardLazyQueryHookResult = ReturnType<
  typeof useLoadLayoutDashboardLazyQuery
>;
export type LoadLayoutDashboardSuspenseQueryHookResult = ReturnType<
  typeof useLoadLayoutDashboardSuspenseQuery
>;
export type LoadLayoutDashboardQueryResult = Apollo.QueryResult<
  LoadLayoutDashboardQuery,
  LoadLayoutDashboardQueryVariables
>;
export const LoadAssignmentDashboardDocument = gql`
  query loadAssignmentDashboard(
    $warehouseId: ID!
    $page: SkipLimitPageSpec!
    $sortBy: [AssignmentsSortBy!]
    $filter: AssignmentsFilter
  ) {
    warehouse(id: $warehouseId) {
      assignments(page: $page, sortBy: $sortBy, filter: $filter) {
        totalCount
        allUserTags
        content {
          ...AssignmentDashboardItem
        }
      }
    }
  }
  ${AssignmentDashboardItemFragmentDoc}
`;

/**
 * __useLoadAssignmentDashboardQuery__
 *
 * To run a query within a React component, call `useLoadAssignmentDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadAssignmentDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadAssignmentDashboardQuery({
 *   variables: {
 *      warehouseId: // value for 'warehouseId'
 *      page: // value for 'page'
 *      sortBy: // value for 'sortBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useLoadAssignmentDashboardQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadAssignmentDashboardQuery,
    LoadAssignmentDashboardQueryVariables
  > &
    (
      | { variables: LoadAssignmentDashboardQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadAssignmentDashboardQuery,
    LoadAssignmentDashboardQueryVariables
  >(LoadAssignmentDashboardDocument, options);
}
export function useLoadAssignmentDashboardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadAssignmentDashboardQuery,
    LoadAssignmentDashboardQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadAssignmentDashboardQuery,
    LoadAssignmentDashboardQueryVariables
  >(LoadAssignmentDashboardDocument, options);
}
export function useLoadAssignmentDashboardSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadAssignmentDashboardQuery,
        LoadAssignmentDashboardQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadAssignmentDashboardQuery,
    LoadAssignmentDashboardQueryVariables
  >(LoadAssignmentDashboardDocument, options);
}
export type LoadAssignmentDashboardQueryHookResult = ReturnType<
  typeof useLoadAssignmentDashboardQuery
>;
export type LoadAssignmentDashboardLazyQueryHookResult = ReturnType<
  typeof useLoadAssignmentDashboardLazyQuery
>;
export type LoadAssignmentDashboardSuspenseQueryHookResult = ReturnType<
  typeof useLoadAssignmentDashboardSuspenseQuery
>;
export type LoadAssignmentDashboardQueryResult = Apollo.QueryResult<
  LoadAssignmentDashboardQuery,
  LoadAssignmentDashboardQueryVariables
>;
export const LoadOrderSetDashboardDocument = gql`
  query loadOrderSetDashboard(
    $warehouseId: ID!
    $page: SkipLimitPageSpec!
    $sortBy: [OrderSetsSortBy!]
    $filter: OrderSetsFilter!
  ) {
    warehouse(id: $warehouseId) {
      orderSets(page: $page, sortBy: $sortBy, filter: $filter) {
        totalCount
        allUserTags
        content {
          ...OrderSetDashboardItem
        }
      }
    }
  }
  ${OrderSetDashboardItemFragmentDoc}
`;

/**
 * __useLoadOrderSetDashboardQuery__
 *
 * To run a query within a React component, call `useLoadOrderSetDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadOrderSetDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadOrderSetDashboardQuery({
 *   variables: {
 *      warehouseId: // value for 'warehouseId'
 *      page: // value for 'page'
 *      sortBy: // value for 'sortBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useLoadOrderSetDashboardQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadOrderSetDashboardQuery,
    LoadOrderSetDashboardQueryVariables
  > &
    (
      | { variables: LoadOrderSetDashboardQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadOrderSetDashboardQuery,
    LoadOrderSetDashboardQueryVariables
  >(LoadOrderSetDashboardDocument, options);
}
export function useLoadOrderSetDashboardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadOrderSetDashboardQuery,
    LoadOrderSetDashboardQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadOrderSetDashboardQuery,
    LoadOrderSetDashboardQueryVariables
  >(LoadOrderSetDashboardDocument, options);
}
export function useLoadOrderSetDashboardSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadOrderSetDashboardQuery,
        LoadOrderSetDashboardQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadOrderSetDashboardQuery,
    LoadOrderSetDashboardQueryVariables
  >(LoadOrderSetDashboardDocument, options);
}
export type LoadOrderSetDashboardQueryHookResult = ReturnType<
  typeof useLoadOrderSetDashboardQuery
>;
export type LoadOrderSetDashboardLazyQueryHookResult = ReturnType<
  typeof useLoadOrderSetDashboardLazyQuery
>;
export type LoadOrderSetDashboardSuspenseQueryHookResult = ReturnType<
  typeof useLoadOrderSetDashboardSuspenseQuery
>;
export type LoadOrderSetDashboardQueryResult = Apollo.QueryResult<
  LoadOrderSetDashboardQuery,
  LoadOrderSetDashboardQueryVariables
>;
export const LoadItemSetDashboardDocument = gql`
  query loadItemSetDashboard(
    $warehouseId: ID!
    $page: SkipLimitPageSpec!
    $sortBy: [ItemSetsSortBy!]
    $filter: ItemSetsFilter
  ) {
    warehouse(id: $warehouseId) {
      itemSets(page: $page, sortBy: $sortBy, filter: $filter) {
        totalCount
        allUserTags
        content {
          ...ItemSetDashboardItem
        }
      }
    }
  }
  ${ItemSetDashboardItemFragmentDoc}
`;

/**
 * __useLoadItemSetDashboardQuery__
 *
 * To run a query within a React component, call `useLoadItemSetDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadItemSetDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadItemSetDashboardQuery({
 *   variables: {
 *      warehouseId: // value for 'warehouseId'
 *      page: // value for 'page'
 *      sortBy: // value for 'sortBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useLoadItemSetDashboardQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadItemSetDashboardQuery,
    LoadItemSetDashboardQueryVariables
  > &
    (
      | { variables: LoadItemSetDashboardQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadItemSetDashboardQuery,
    LoadItemSetDashboardQueryVariables
  >(LoadItemSetDashboardDocument, options);
}
export function useLoadItemSetDashboardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadItemSetDashboardQuery,
    LoadItemSetDashboardQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadItemSetDashboardQuery,
    LoadItemSetDashboardQueryVariables
  >(LoadItemSetDashboardDocument, options);
}
export function useLoadItemSetDashboardSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadItemSetDashboardQuery,
        LoadItemSetDashboardQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadItemSetDashboardQuery,
    LoadItemSetDashboardQueryVariables
  >(LoadItemSetDashboardDocument, options);
}
export type LoadItemSetDashboardQueryHookResult = ReturnType<
  typeof useLoadItemSetDashboardQuery
>;
export type LoadItemSetDashboardLazyQueryHookResult = ReturnType<
  typeof useLoadItemSetDashboardLazyQuery
>;
export type LoadItemSetDashboardSuspenseQueryHookResult = ReturnType<
  typeof useLoadItemSetDashboardSuspenseQuery
>;
export type LoadItemSetDashboardQueryResult = Apollo.QueryResult<
  LoadItemSetDashboardQuery,
  LoadItemSetDashboardQueryVariables
>;
export const LoadDatasetObjectDashboardDocument = gql`
  query loadDatasetObjectDashboard(
    $warehouseId: ID!
    $page: SkipLimitPageSpec!
    $sortBy: [DatasetObjectsSortBy!]
    $filter: DatasetObjectFilter
  ) {
    warehouse(id: $warehouseId) {
      datasetObjects(page: $page, sortBy: $sortBy, filter: $filter) {
        totalCount
        allUserTags
        content {
          ...DatasetObject
        }
      }
    }
  }
  ${DatasetObjectFragmentDoc}
`;

/**
 * __useLoadDatasetObjectDashboardQuery__
 *
 * To run a query within a React component, call `useLoadDatasetObjectDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadDatasetObjectDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadDatasetObjectDashboardQuery({
 *   variables: {
 *      warehouseId: // value for 'warehouseId'
 *      page: // value for 'page'
 *      sortBy: // value for 'sortBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useLoadDatasetObjectDashboardQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadDatasetObjectDashboardQuery,
    LoadDatasetObjectDashboardQueryVariables
  > &
    (
      | { variables: LoadDatasetObjectDashboardQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadDatasetObjectDashboardQuery,
    LoadDatasetObjectDashboardQueryVariables
  >(LoadDatasetObjectDashboardDocument, options);
}
export function useLoadDatasetObjectDashboardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadDatasetObjectDashboardQuery,
    LoadDatasetObjectDashboardQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadDatasetObjectDashboardQuery,
    LoadDatasetObjectDashboardQueryVariables
  >(LoadDatasetObjectDashboardDocument, options);
}
export function useLoadDatasetObjectDashboardSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadDatasetObjectDashboardQuery,
        LoadDatasetObjectDashboardQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadDatasetObjectDashboardQuery,
    LoadDatasetObjectDashboardQueryVariables
  >(LoadDatasetObjectDashboardDocument, options);
}
export type LoadDatasetObjectDashboardQueryHookResult = ReturnType<
  typeof useLoadDatasetObjectDashboardQuery
>;
export type LoadDatasetObjectDashboardLazyQueryHookResult = ReturnType<
  typeof useLoadDatasetObjectDashboardLazyQuery
>;
export type LoadDatasetObjectDashboardSuspenseQueryHookResult = ReturnType<
  typeof useLoadDatasetObjectDashboardSuspenseQuery
>;
export type LoadDatasetObjectDashboardQueryResult = Apollo.QueryResult<
  LoadDatasetObjectDashboardQuery,
  LoadDatasetObjectDashboardQueryVariables
>;
export const LoadWarehouseFilesDocument = gql`
  query loadWarehouseFiles($whId: ID!) {
    warehouse(id: $whId) {
      files {
        ...StoredFile
      }
    }
  }
  ${StoredFileFragmentDoc}
`;

/**
 * __useLoadWarehouseFilesQuery__
 *
 * To run a query within a React component, call `useLoadWarehouseFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadWarehouseFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadWarehouseFilesQuery({
 *   variables: {
 *      whId: // value for 'whId'
 *   },
 * });
 */
export function useLoadWarehouseFilesQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadWarehouseFilesQuery,
    LoadWarehouseFilesQueryVariables
  > &
    (
      | { variables: LoadWarehouseFilesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadWarehouseFilesQuery,
    LoadWarehouseFilesQueryVariables
  >(LoadWarehouseFilesDocument, options);
}
export function useLoadWarehouseFilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadWarehouseFilesQuery,
    LoadWarehouseFilesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadWarehouseFilesQuery,
    LoadWarehouseFilesQueryVariables
  >(LoadWarehouseFilesDocument, options);
}
export function useLoadWarehouseFilesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadWarehouseFilesQuery,
        LoadWarehouseFilesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadWarehouseFilesQuery,
    LoadWarehouseFilesQueryVariables
  >(LoadWarehouseFilesDocument, options);
}
export type LoadWarehouseFilesQueryHookResult = ReturnType<
  typeof useLoadWarehouseFilesQuery
>;
export type LoadWarehouseFilesLazyQueryHookResult = ReturnType<
  typeof useLoadWarehouseFilesLazyQuery
>;
export type LoadWarehouseFilesSuspenseQueryHookResult = ReturnType<
  typeof useLoadWarehouseFilesSuspenseQuery
>;
export type LoadWarehouseFilesQueryResult = Apollo.QueryResult<
  LoadWarehouseFilesQuery,
  LoadWarehouseFilesQueryVariables
>;
export const BeginFileUploadDocument = gql`
  mutation beginFileUpload($id: StoredFileIdInput!) {
    beginFileUpload(id: $id)
  }
`;
export type BeginFileUploadMutationFn = Apollo.MutationFunction<
  BeginFileUploadMutation,
  BeginFileUploadMutationVariables
>;

/**
 * __useBeginFileUploadMutation__
 *
 * To run a mutation, you first call `useBeginFileUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBeginFileUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [beginFileUploadMutation, { data, loading, error }] = useBeginFileUploadMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBeginFileUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BeginFileUploadMutation,
    BeginFileUploadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BeginFileUploadMutation,
    BeginFileUploadMutationVariables
  >(BeginFileUploadDocument, options);
}
export type BeginFileUploadMutationHookResult = ReturnType<
  typeof useBeginFileUploadMutation
>;
export type BeginFileUploadMutationResult =
  Apollo.MutationResult<BeginFileUploadMutation>;
export type BeginFileUploadMutationOptions = Apollo.BaseMutationOptions<
  BeginFileUploadMutation,
  BeginFileUploadMutationVariables
>;
export const BeginFileMultipartUploadDocument = gql`
  mutation beginFileMultipartUpload($input: BeginFileMultipartUploadInput!) {
    beginFileMultipartUpload(input: $input) {
      uploadId
      uploadLinks
    }
  }
`;
export type BeginFileMultipartUploadMutationFn = Apollo.MutationFunction<
  BeginFileMultipartUploadMutation,
  BeginFileMultipartUploadMutationVariables
>;

/**
 * __useBeginFileMultipartUploadMutation__
 *
 * To run a mutation, you first call `useBeginFileMultipartUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBeginFileMultipartUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [beginFileMultipartUploadMutation, { data, loading, error }] = useBeginFileMultipartUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBeginFileMultipartUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BeginFileMultipartUploadMutation,
    BeginFileMultipartUploadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BeginFileMultipartUploadMutation,
    BeginFileMultipartUploadMutationVariables
  >(BeginFileMultipartUploadDocument, options);
}
export type BeginFileMultipartUploadMutationHookResult = ReturnType<
  typeof useBeginFileMultipartUploadMutation
>;
export type BeginFileMultipartUploadMutationResult =
  Apollo.MutationResult<BeginFileMultipartUploadMutation>;
export type BeginFileMultipartUploadMutationOptions =
  Apollo.BaseMutationOptions<
    BeginFileMultipartUploadMutation,
    BeginFileMultipartUploadMutationVariables
  >;
export const CompleteFileMultipartUploadDocument = gql`
  mutation completeFileMultipartUpload(
    $input: CompletedFileMultipartUploadInput!
  ) {
    completeFileMultipartUpload(input: $input) {
      warehouseId
      name
    }
  }
`;
export type CompleteFileMultipartUploadMutationFn = Apollo.MutationFunction<
  CompleteFileMultipartUploadMutation,
  CompleteFileMultipartUploadMutationVariables
>;

/**
 * __useCompleteFileMultipartUploadMutation__
 *
 * To run a mutation, you first call `useCompleteFileMultipartUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteFileMultipartUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeFileMultipartUploadMutation, { data, loading, error }] = useCompleteFileMultipartUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteFileMultipartUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteFileMultipartUploadMutation,
    CompleteFileMultipartUploadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompleteFileMultipartUploadMutation,
    CompleteFileMultipartUploadMutationVariables
  >(CompleteFileMultipartUploadDocument, options);
}
export type CompleteFileMultipartUploadMutationHookResult = ReturnType<
  typeof useCompleteFileMultipartUploadMutation
>;
export type CompleteFileMultipartUploadMutationResult =
  Apollo.MutationResult<CompleteFileMultipartUploadMutation>;
export type CompleteFileMultipartUploadMutationOptions =
  Apollo.BaseMutationOptions<
    CompleteFileMultipartUploadMutation,
    CompleteFileMultipartUploadMutationVariables
  >;
export const RemoveWarehouseFileDocument = gql`
  mutation removeWarehouseFile($id: StoredFileIdInput!) {
    deleteFile(id: $id)
  }
`;
export type RemoveWarehouseFileMutationFn = Apollo.MutationFunction<
  RemoveWarehouseFileMutation,
  RemoveWarehouseFileMutationVariables
>;

/**
 * __useRemoveWarehouseFileMutation__
 *
 * To run a mutation, you first call `useRemoveWarehouseFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveWarehouseFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeWarehouseFileMutation, { data, loading, error }] = useRemoveWarehouseFileMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveWarehouseFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveWarehouseFileMutation,
    RemoveWarehouseFileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveWarehouseFileMutation,
    RemoveWarehouseFileMutationVariables
  >(RemoveWarehouseFileDocument, options);
}
export type RemoveWarehouseFileMutationHookResult = ReturnType<
  typeof useRemoveWarehouseFileMutation
>;
export type RemoveWarehouseFileMutationResult =
  Apollo.MutationResult<RemoveWarehouseFileMutation>;
export type RemoveWarehouseFileMutationOptions = Apollo.BaseMutationOptions<
  RemoveWarehouseFileMutation,
  RemoveWarehouseFileMutationVariables
>;
export const LoadWeightComplianceForBaysDocument = gql`
  query loadWeightComplianceForBays(
    $simulationId: ID!
    $filter: WeightComplianceBayFilter
    $sortBy: [WeightComplianceBaySortBy!]
    $page: SkipLimitPageSpec
  ) {
    simulation(id: $simulationId) {
      weightCompliance {
        bays(sortBy: $sortBy, page: $page, filter: $filter) {
          totalCount
          content {
            ...WeightComplianceBayDetails
          }
        }
      }
    }
  }
  ${WeightComplianceBayDetailsFragmentDoc}
`;

/**
 * __useLoadWeightComplianceForBaysQuery__
 *
 * To run a query within a React component, call `useLoadWeightComplianceForBaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadWeightComplianceForBaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadWeightComplianceForBaysQuery({
 *   variables: {
 *      simulationId: // value for 'simulationId'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useLoadWeightComplianceForBaysQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadWeightComplianceForBaysQuery,
    LoadWeightComplianceForBaysQueryVariables
  > &
    (
      | { variables: LoadWeightComplianceForBaysQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadWeightComplianceForBaysQuery,
    LoadWeightComplianceForBaysQueryVariables
  >(LoadWeightComplianceForBaysDocument, options);
}
export function useLoadWeightComplianceForBaysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadWeightComplianceForBaysQuery,
    LoadWeightComplianceForBaysQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadWeightComplianceForBaysQuery,
    LoadWeightComplianceForBaysQueryVariables
  >(LoadWeightComplianceForBaysDocument, options);
}
export function useLoadWeightComplianceForBaysSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadWeightComplianceForBaysQuery,
        LoadWeightComplianceForBaysQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadWeightComplianceForBaysQuery,
    LoadWeightComplianceForBaysQueryVariables
  >(LoadWeightComplianceForBaysDocument, options);
}
export type LoadWeightComplianceForBaysQueryHookResult = ReturnType<
  typeof useLoadWeightComplianceForBaysQuery
>;
export type LoadWeightComplianceForBaysLazyQueryHookResult = ReturnType<
  typeof useLoadWeightComplianceForBaysLazyQuery
>;
export type LoadWeightComplianceForBaysSuspenseQueryHookResult = ReturnType<
  typeof useLoadWeightComplianceForBaysSuspenseQuery
>;
export type LoadWeightComplianceForBaysQueryResult = Apollo.QueryResult<
  LoadWeightComplianceForBaysQuery,
  LoadWeightComplianceForBaysQueryVariables
>;
export const LoadWeightComplianceForLocationsDocument = gql`
  query loadWeightComplianceForLocations(
    $simulationId: ID!
    $filter: WeightComplianceLocationFilter
    $sortBy: [WeightComplianceLocationSortBy!]
    $page: SkipLimitPageSpec
  ) {
    simulation(id: $simulationId) {
      weightCompliance {
        locations(sortBy: $sortBy, page: $page, filter: $filter) {
          totalCount
          content {
            ...WeightComplianceLocationDetails
          }
        }
      }
    }
  }
  ${WeightComplianceLocationDetailsFragmentDoc}
`;

/**
 * __useLoadWeightComplianceForLocationsQuery__
 *
 * To run a query within a React component, call `useLoadWeightComplianceForLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadWeightComplianceForLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadWeightComplianceForLocationsQuery({
 *   variables: {
 *      simulationId: // value for 'simulationId'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useLoadWeightComplianceForLocationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadWeightComplianceForLocationsQuery,
    LoadWeightComplianceForLocationsQueryVariables
  > &
    (
      | {
          variables: LoadWeightComplianceForLocationsQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadWeightComplianceForLocationsQuery,
    LoadWeightComplianceForLocationsQueryVariables
  >(LoadWeightComplianceForLocationsDocument, options);
}
export function useLoadWeightComplianceForLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadWeightComplianceForLocationsQuery,
    LoadWeightComplianceForLocationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadWeightComplianceForLocationsQuery,
    LoadWeightComplianceForLocationsQueryVariables
  >(LoadWeightComplianceForLocationsDocument, options);
}
export function useLoadWeightComplianceForLocationsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadWeightComplianceForLocationsQuery,
        LoadWeightComplianceForLocationsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadWeightComplianceForLocationsQuery,
    LoadWeightComplianceForLocationsQueryVariables
  >(LoadWeightComplianceForLocationsDocument, options);
}
export type LoadWeightComplianceForLocationsQueryHookResult = ReturnType<
  typeof useLoadWeightComplianceForLocationsQuery
>;
export type LoadWeightComplianceForLocationsLazyQueryHookResult = ReturnType<
  typeof useLoadWeightComplianceForLocationsLazyQuery
>;
export type LoadWeightComplianceForLocationsSuspenseQueryHookResult =
  ReturnType<typeof useLoadWeightComplianceForLocationsSuspenseQuery>;
export type LoadWeightComplianceForLocationsQueryResult = Apollo.QueryResult<
  LoadWeightComplianceForLocationsQuery,
  LoadWeightComplianceForLocationsQueryVariables
>;
export const LoadWeightComplianceMetaDocument = gql`
  query loadWeightComplianceMeta($simulationId: ID!) {
    simulation(id: $simulationId) {
      weightCompliance {
        ...WeightComplianceMeta
      }
    }
  }
  ${WeightComplianceMetaFragmentDoc}
`;

/**
 * __useLoadWeightComplianceMetaQuery__
 *
 * To run a query within a React component, call `useLoadWeightComplianceMetaQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadWeightComplianceMetaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadWeightComplianceMetaQuery({
 *   variables: {
 *      simulationId: // value for 'simulationId'
 *   },
 * });
 */
export function useLoadWeightComplianceMetaQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadWeightComplianceMetaQuery,
    LoadWeightComplianceMetaQueryVariables
  > &
    (
      | { variables: LoadWeightComplianceMetaQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadWeightComplianceMetaQuery,
    LoadWeightComplianceMetaQueryVariables
  >(LoadWeightComplianceMetaDocument, options);
}
export function useLoadWeightComplianceMetaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadWeightComplianceMetaQuery,
    LoadWeightComplianceMetaQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadWeightComplianceMetaQuery,
    LoadWeightComplianceMetaQueryVariables
  >(LoadWeightComplianceMetaDocument, options);
}
export function useLoadWeightComplianceMetaSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadWeightComplianceMetaQuery,
        LoadWeightComplianceMetaQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadWeightComplianceMetaQuery,
    LoadWeightComplianceMetaQueryVariables
  >(LoadWeightComplianceMetaDocument, options);
}
export type LoadWeightComplianceMetaQueryHookResult = ReturnType<
  typeof useLoadWeightComplianceMetaQuery
>;
export type LoadWeightComplianceMetaLazyQueryHookResult = ReturnType<
  typeof useLoadWeightComplianceMetaLazyQuery
>;
export type LoadWeightComplianceMetaSuspenseQueryHookResult = ReturnType<
  typeof useLoadWeightComplianceMetaSuspenseQuery
>;
export type LoadWeightComplianceMetaQueryResult = Apollo.QueryResult<
  LoadWeightComplianceMetaQuery,
  LoadWeightComplianceMetaQueryVariables
>;
export const RunCalculateWeightComplianceDocument = gql`
  mutation runCalculateWeightCompliance($simulationId: String!) {
    calculateSimulationWeightCompliance(simulationId: $simulationId) {
      ...WeightComplianceMeta
    }
  }
  ${WeightComplianceMetaFragmentDoc}
`;
export type RunCalculateWeightComplianceMutationFn = Apollo.MutationFunction<
  RunCalculateWeightComplianceMutation,
  RunCalculateWeightComplianceMutationVariables
>;

/**
 * __useRunCalculateWeightComplianceMutation__
 *
 * To run a mutation, you first call `useRunCalculateWeightComplianceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunCalculateWeightComplianceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runCalculateWeightComplianceMutation, { data, loading, error }] = useRunCalculateWeightComplianceMutation({
 *   variables: {
 *      simulationId: // value for 'simulationId'
 *   },
 * });
 */
export function useRunCalculateWeightComplianceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RunCalculateWeightComplianceMutation,
    RunCalculateWeightComplianceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RunCalculateWeightComplianceMutation,
    RunCalculateWeightComplianceMutationVariables
  >(RunCalculateWeightComplianceDocument, options);
}
export type RunCalculateWeightComplianceMutationHookResult = ReturnType<
  typeof useRunCalculateWeightComplianceMutation
>;
export type RunCalculateWeightComplianceMutationResult =
  Apollo.MutationResult<RunCalculateWeightComplianceMutation>;
export type RunCalculateWeightComplianceMutationOptions =
  Apollo.BaseMutationOptions<
    RunCalculateWeightComplianceMutation,
    RunCalculateWeightComplianceMutationVariables
  >;
export const LoadActualitiesDocument = gql`
  query loadActualities($warehouseId: ID!) {
    warehouse(id: $warehouseId) {
      actualities(page: null, sortBy: { field: CREATED_AT, direction: DESC }) {
        totalCount
        content {
          ...ActualityRefSummary
        }
      }
    }
  }
  ${ActualityRefSummaryFragmentDoc}
`;

/**
 * __useLoadActualitiesQuery__
 *
 * To run a query within a React component, call `useLoadActualitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadActualitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadActualitiesQuery({
 *   variables: {
 *      warehouseId: // value for 'warehouseId'
 *   },
 * });
 */
export function useLoadActualitiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadActualitiesQuery,
    LoadActualitiesQueryVariables
  > &
    (
      | { variables: LoadActualitiesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadActualitiesQuery, LoadActualitiesQueryVariables>(
    LoadActualitiesDocument,
    options,
  );
}
export function useLoadActualitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadActualitiesQuery,
    LoadActualitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadActualitiesQuery,
    LoadActualitiesQueryVariables
  >(LoadActualitiesDocument, options);
}
export function useLoadActualitiesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadActualitiesQuery,
        LoadActualitiesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadActualitiesQuery,
    LoadActualitiesQueryVariables
  >(LoadActualitiesDocument, options);
}
export type LoadActualitiesQueryHookResult = ReturnType<
  typeof useLoadActualitiesQuery
>;
export type LoadActualitiesLazyQueryHookResult = ReturnType<
  typeof useLoadActualitiesLazyQuery
>;
export type LoadActualitiesSuspenseQueryHookResult = ReturnType<
  typeof useLoadActualitiesSuspenseQuery
>;
export type LoadActualitiesQueryResult = Apollo.QueryResult<
  LoadActualitiesQuery,
  LoadActualitiesQueryVariables
>;
export const AnalyzeFeedPicklistDocument = gql`
  mutation analyzeFeedPicklist($input: AnalyzePicklistInput!) {
    analyzePicklist(input: $input) {
      picklists {
        ...AnalyzedFeedPicklist
      }
    }
  }
  ${AnalyzedFeedPicklistFragmentDoc}
`;
export type AnalyzeFeedPicklistMutationFn = Apollo.MutationFunction<
  AnalyzeFeedPicklistMutation,
  AnalyzeFeedPicklistMutationVariables
>;

/**
 * __useAnalyzeFeedPicklistMutation__
 *
 * To run a mutation, you first call `useAnalyzeFeedPicklistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnalyzeFeedPicklistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [analyzeFeedPicklistMutation, { data, loading, error }] = useAnalyzeFeedPicklistMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAnalyzeFeedPicklistMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AnalyzeFeedPicklistMutation,
    AnalyzeFeedPicklistMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AnalyzeFeedPicklistMutation,
    AnalyzeFeedPicklistMutationVariables
  >(AnalyzeFeedPicklistDocument, options);
}
export type AnalyzeFeedPicklistMutationHookResult = ReturnType<
  typeof useAnalyzeFeedPicklistMutation
>;
export type AnalyzeFeedPicklistMutationResult =
  Apollo.MutationResult<AnalyzeFeedPicklistMutation>;
export type AnalyzeFeedPicklistMutationOptions = Apollo.BaseMutationOptions<
  AnalyzeFeedPicklistMutation,
  AnalyzeFeedPicklistMutationVariables
>;
export const LoadAnalyzeActualityStatusDocument = gql`
  query loadAnalyzeActualityStatus($token: String!) {
    analyzeActualityStatus(token: $token) {
      actualityId
      status
    }
  }
`;

/**
 * __useLoadAnalyzeActualityStatusQuery__
 *
 * To run a query within a React component, call `useLoadAnalyzeActualityStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadAnalyzeActualityStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadAnalyzeActualityStatusQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useLoadAnalyzeActualityStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadAnalyzeActualityStatusQuery,
    LoadAnalyzeActualityStatusQueryVariables
  > &
    (
      | { variables: LoadAnalyzeActualityStatusQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadAnalyzeActualityStatusQuery,
    LoadAnalyzeActualityStatusQueryVariables
  >(LoadAnalyzeActualityStatusDocument, options);
}
export function useLoadAnalyzeActualityStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadAnalyzeActualityStatusQuery,
    LoadAnalyzeActualityStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadAnalyzeActualityStatusQuery,
    LoadAnalyzeActualityStatusQueryVariables
  >(LoadAnalyzeActualityStatusDocument, options);
}
export function useLoadAnalyzeActualityStatusSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadAnalyzeActualityStatusQuery,
        LoadAnalyzeActualityStatusQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadAnalyzeActualityStatusQuery,
    LoadAnalyzeActualityStatusQueryVariables
  >(LoadAnalyzeActualityStatusDocument, options);
}
export type LoadAnalyzeActualityStatusQueryHookResult = ReturnType<
  typeof useLoadAnalyzeActualityStatusQuery
>;
export type LoadAnalyzeActualityStatusLazyQueryHookResult = ReturnType<
  typeof useLoadAnalyzeActualityStatusLazyQuery
>;
export type LoadAnalyzeActualityStatusSuspenseQueryHookResult = ReturnType<
  typeof useLoadAnalyzeActualityStatusSuspenseQuery
>;
export type LoadAnalyzeActualityStatusQueryResult = Apollo.QueryResult<
  LoadAnalyzeActualityStatusQuery,
  LoadAnalyzeActualityStatusQueryVariables
>;
export const CreateActualityDocument = gql`
  mutation createActuality($input: CreateActualityInput!) {
    createActuality(input: $input) {
      ...ActualityMeta
    }
  }
  ${ActualityMetaFragmentDoc}
`;
export type CreateActualityMutationFn = Apollo.MutationFunction<
  CreateActualityMutation,
  CreateActualityMutationVariables
>;

/**
 * __useCreateActualityMutation__
 *
 * To run a mutation, you first call `useCreateActualityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateActualityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createActualityMutation, { data, loading, error }] = useCreateActualityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateActualityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateActualityMutation,
    CreateActualityMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateActualityMutation,
    CreateActualityMutationVariables
  >(CreateActualityDocument, options);
}
export type CreateActualityMutationHookResult = ReturnType<
  typeof useCreateActualityMutation
>;
export type CreateActualityMutationResult =
  Apollo.MutationResult<CreateActualityMutation>;
export type CreateActualityMutationOptions = Apollo.BaseMutationOptions<
  CreateActualityMutation,
  CreateActualityMutationVariables
>;
export const LoadActualityDocument = gql`
  query loadActuality($actualityId: ID!) {
    actuality(id: $actualityId) {
      ...ActualityMeta
    }
  }
  ${ActualityMetaFragmentDoc}
`;

/**
 * __useLoadActualityQuery__
 *
 * To run a query within a React component, call `useLoadActualityQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadActualityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadActualityQuery({
 *   variables: {
 *      actualityId: // value for 'actualityId'
 *   },
 * });
 */
export function useLoadActualityQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadActualityQuery,
    LoadActualityQueryVariables
  > &
    (
      | { variables: LoadActualityQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadActualityQuery, LoadActualityQueryVariables>(
    LoadActualityDocument,
    options,
  );
}
export function useLoadActualityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadActualityQuery,
    LoadActualityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LoadActualityQuery, LoadActualityQueryVariables>(
    LoadActualityDocument,
    options,
  );
}
export function useLoadActualitySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadActualityQuery,
        LoadActualityQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadActualityQuery,
    LoadActualityQueryVariables
  >(LoadActualityDocument, options);
}
export type LoadActualityQueryHookResult = ReturnType<
  typeof useLoadActualityQuery
>;
export type LoadActualityLazyQueryHookResult = ReturnType<
  typeof useLoadActualityLazyQuery
>;
export type LoadActualitySuspenseQueryHookResult = ReturnType<
  typeof useLoadActualitySuspenseQuery
>;
export type LoadActualityQueryResult = Apollo.QueryResult<
  LoadActualityQuery,
  LoadActualityQueryVariables
>;
export const LoadActualityAnalyzeStatusDocument = gql`
  query loadActualityAnalyzeStatus($actualityId: ID!) {
    actuality(id: $actualityId) {
      analyzePipeline {
        ...ProcessingPipeline
      }
    }
  }
  ${ProcessingPipelineFragmentDoc}
`;

/**
 * __useLoadActualityAnalyzeStatusQuery__
 *
 * To run a query within a React component, call `useLoadActualityAnalyzeStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadActualityAnalyzeStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadActualityAnalyzeStatusQuery({
 *   variables: {
 *      actualityId: // value for 'actualityId'
 *   },
 * });
 */
export function useLoadActualityAnalyzeStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadActualityAnalyzeStatusQuery,
    LoadActualityAnalyzeStatusQueryVariables
  > &
    (
      | { variables: LoadActualityAnalyzeStatusQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadActualityAnalyzeStatusQuery,
    LoadActualityAnalyzeStatusQueryVariables
  >(LoadActualityAnalyzeStatusDocument, options);
}
export function useLoadActualityAnalyzeStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadActualityAnalyzeStatusQuery,
    LoadActualityAnalyzeStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadActualityAnalyzeStatusQuery,
    LoadActualityAnalyzeStatusQueryVariables
  >(LoadActualityAnalyzeStatusDocument, options);
}
export function useLoadActualityAnalyzeStatusSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadActualityAnalyzeStatusQuery,
        LoadActualityAnalyzeStatusQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadActualityAnalyzeStatusQuery,
    LoadActualityAnalyzeStatusQueryVariables
  >(LoadActualityAnalyzeStatusDocument, options);
}
export type LoadActualityAnalyzeStatusQueryHookResult = ReturnType<
  typeof useLoadActualityAnalyzeStatusQuery
>;
export type LoadActualityAnalyzeStatusLazyQueryHookResult = ReturnType<
  typeof useLoadActualityAnalyzeStatusLazyQuery
>;
export type LoadActualityAnalyzeStatusSuspenseQueryHookResult = ReturnType<
  typeof useLoadActualityAnalyzeStatusSuspenseQuery
>;
export type LoadActualityAnalyzeStatusQueryResult = Apollo.QueryResult<
  LoadActualityAnalyzeStatusQuery,
  LoadActualityAnalyzeStatusQueryVariables
>;
export const UpdateActualityDocument = gql`
  mutation updateActuality($input: UpdateActualityInput!) {
    updateActuality(input: $input) {
      ...ActualityMetaStatus
    }
  }
  ${ActualityMetaStatusFragmentDoc}
`;
export type UpdateActualityMutationFn = Apollo.MutationFunction<
  UpdateActualityMutation,
  UpdateActualityMutationVariables
>;

/**
 * __useUpdateActualityMutation__
 *
 * To run a mutation, you first call `useUpdateActualityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActualityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActualityMutation, { data, loading, error }] = useUpdateActualityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateActualityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateActualityMutation,
    UpdateActualityMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateActualityMutation,
    UpdateActualityMutationVariables
  >(UpdateActualityDocument, options);
}
export type UpdateActualityMutationHookResult = ReturnType<
  typeof useUpdateActualityMutation
>;
export type UpdateActualityMutationResult =
  Apollo.MutationResult<UpdateActualityMutation>;
export type UpdateActualityMutationOptions = Apollo.BaseMutationOptions<
  UpdateActualityMutation,
  UpdateActualityMutationVariables
>;
export const RemoveActualityDocument = gql`
  mutation removeActuality($actualityId: String!) {
    removeActuality(actualityId: $actualityId)
  }
`;
export type RemoveActualityMutationFn = Apollo.MutationFunction<
  RemoveActualityMutation,
  RemoveActualityMutationVariables
>;

/**
 * __useRemoveActualityMutation__
 *
 * To run a mutation, you first call `useRemoveActualityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveActualityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeActualityMutation, { data, loading, error }] = useRemoveActualityMutation({
 *   variables: {
 *      actualityId: // value for 'actualityId'
 *   },
 * });
 */
export function useRemoveActualityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveActualityMutation,
    RemoveActualityMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveActualityMutation,
    RemoveActualityMutationVariables
  >(RemoveActualityDocument, options);
}
export type RemoveActualityMutationHookResult = ReturnType<
  typeof useRemoveActualityMutation
>;
export type RemoveActualityMutationResult =
  Apollo.MutationResult<RemoveActualityMutation>;
export type RemoveActualityMutationOptions = Apollo.BaseMutationOptions<
  RemoveActualityMutation,
  RemoveActualityMutationVariables
>;
export const DuplicateActualityDocument = gql`
  mutation duplicateActuality($actualityId: ID!, $title: String) {
    duplicateActuality(input: { actualityId: $actualityId, title: $title }) {
      id
    }
  }
`;
export type DuplicateActualityMutationFn = Apollo.MutationFunction<
  DuplicateActualityMutation,
  DuplicateActualityMutationVariables
>;

/**
 * __useDuplicateActualityMutation__
 *
 * To run a mutation, you first call `useDuplicateActualityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateActualityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateActualityMutation, { data, loading, error }] = useDuplicateActualityMutation({
 *   variables: {
 *      actualityId: // value for 'actualityId'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useDuplicateActualityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DuplicateActualityMutation,
    DuplicateActualityMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DuplicateActualityMutation,
    DuplicateActualityMutationVariables
  >(DuplicateActualityDocument, options);
}
export type DuplicateActualityMutationHookResult = ReturnType<
  typeof useDuplicateActualityMutation
>;
export type DuplicateActualityMutationResult =
  Apollo.MutationResult<DuplicateActualityMutation>;
export type DuplicateActualityMutationOptions = Apollo.BaseMutationOptions<
  DuplicateActualityMutation,
  DuplicateActualityMutationVariables
>;
export const RunAnalyzeActualityDocument = gql`
  mutation runAnalyzeActuality($input: AnalyzeActualityInputInput!) {
    analyzeActuality(input: $input) {
      token
      status
    }
  }
`;
export type RunAnalyzeActualityMutationFn = Apollo.MutationFunction<
  RunAnalyzeActualityMutation,
  RunAnalyzeActualityMutationVariables
>;

/**
 * __useRunAnalyzeActualityMutation__
 *
 * To run a mutation, you first call `useRunAnalyzeActualityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunAnalyzeActualityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runAnalyzeActualityMutation, { data, loading, error }] = useRunAnalyzeActualityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRunAnalyzeActualityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RunAnalyzeActualityMutation,
    RunAnalyzeActualityMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RunAnalyzeActualityMutation,
    RunAnalyzeActualityMutationVariables
  >(RunAnalyzeActualityDocument, options);
}
export type RunAnalyzeActualityMutationHookResult = ReturnType<
  typeof useRunAnalyzeActualityMutation
>;
export type RunAnalyzeActualityMutationResult =
  Apollo.MutationResult<RunAnalyzeActualityMutation>;
export type RunAnalyzeActualityMutationOptions = Apollo.BaseMutationOptions<
  RunAnalyzeActualityMutation,
  RunAnalyzeActualityMutationVariables
>;
export const CreateProcessingPipelineDocument = gql`
  mutation createProcessingPipeline($input: CreateProcessingPipelineInput!) {
    createProcessingPipeline(input: $input) {
      ...ProcessingPipeline
    }
  }
  ${ProcessingPipelineFragmentDoc}
`;
export type CreateProcessingPipelineMutationFn = Apollo.MutationFunction<
  CreateProcessingPipelineMutation,
  CreateProcessingPipelineMutationVariables
>;

/**
 * __useCreateProcessingPipelineMutation__
 *
 * To run a mutation, you first call `useCreateProcessingPipelineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProcessingPipelineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProcessingPipelineMutation, { data, loading, error }] = useCreateProcessingPipelineMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProcessingPipelineMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProcessingPipelineMutation,
    CreateProcessingPipelineMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateProcessingPipelineMutation,
    CreateProcessingPipelineMutationVariables
  >(CreateProcessingPipelineDocument, options);
}
export type CreateProcessingPipelineMutationHookResult = ReturnType<
  typeof useCreateProcessingPipelineMutation
>;
export type CreateProcessingPipelineMutationResult =
  Apollo.MutationResult<CreateProcessingPipelineMutation>;
export type CreateProcessingPipelineMutationOptions =
  Apollo.BaseMutationOptions<
    CreateProcessingPipelineMutation,
    CreateProcessingPipelineMutationVariables
  >;
export const UpdateProcessingPipelineDocument = gql`
  mutation updateProcessingPipeline($input: UpdateProcessingPipelineInput!) {
    updateProcessingPipeline(input: $input) {
      ...ProcessingPipeline
    }
  }
  ${ProcessingPipelineFragmentDoc}
`;
export type UpdateProcessingPipelineMutationFn = Apollo.MutationFunction<
  UpdateProcessingPipelineMutation,
  UpdateProcessingPipelineMutationVariables
>;

/**
 * __useUpdateProcessingPipelineMutation__
 *
 * To run a mutation, you first call `useUpdateProcessingPipelineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProcessingPipelineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProcessingPipelineMutation, { data, loading, error }] = useUpdateProcessingPipelineMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProcessingPipelineMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProcessingPipelineMutation,
    UpdateProcessingPipelineMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProcessingPipelineMutation,
    UpdateProcessingPipelineMutationVariables
  >(UpdateProcessingPipelineDocument, options);
}
export type UpdateProcessingPipelineMutationHookResult = ReturnType<
  typeof useUpdateProcessingPipelineMutation
>;
export type UpdateProcessingPipelineMutationResult =
  Apollo.MutationResult<UpdateProcessingPipelineMutation>;
export type UpdateProcessingPipelineMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateProcessingPipelineMutation,
    UpdateProcessingPipelineMutationVariables
  >;
export const RemoveProcessingPipelineDocument = gql`
  mutation removeProcessingPipeline($id: String!) {
    removeProcessingPipeline(id: $id)
  }
`;
export type RemoveProcessingPipelineMutationFn = Apollo.MutationFunction<
  RemoveProcessingPipelineMutation,
  RemoveProcessingPipelineMutationVariables
>;

/**
 * __useRemoveProcessingPipelineMutation__
 *
 * To run a mutation, you first call `useRemoveProcessingPipelineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveProcessingPipelineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeProcessingPipelineMutation, { data, loading, error }] = useRemoveProcessingPipelineMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveProcessingPipelineMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveProcessingPipelineMutation,
    RemoveProcessingPipelineMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveProcessingPipelineMutation,
    RemoveProcessingPipelineMutationVariables
  >(RemoveProcessingPipelineDocument, options);
}
export type RemoveProcessingPipelineMutationHookResult = ReturnType<
  typeof useRemoveProcessingPipelineMutation
>;
export type RemoveProcessingPipelineMutationResult =
  Apollo.MutationResult<RemoveProcessingPipelineMutation>;
export type RemoveProcessingPipelineMutationOptions =
  Apollo.BaseMutationOptions<
    RemoveProcessingPipelineMutation,
    RemoveProcessingPipelineMutationVariables
  >;
export const CreateAllocationRunDocument = gql`
  mutation createAllocationRun($input: CreateAllocationRunInput!) {
    createAllocationRun(input: $input) {
      ...AllocationRunBase
    }
  }
  ${AllocationRunBaseFragmentDoc}
`;
export type CreateAllocationRunMutationFn = Apollo.MutationFunction<
  CreateAllocationRunMutation,
  CreateAllocationRunMutationVariables
>;

/**
 * __useCreateAllocationRunMutation__
 *
 * To run a mutation, you first call `useCreateAllocationRunMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAllocationRunMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAllocationRunMutation, { data, loading, error }] = useCreateAllocationRunMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAllocationRunMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAllocationRunMutation,
    CreateAllocationRunMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAllocationRunMutation,
    CreateAllocationRunMutationVariables
  >(CreateAllocationRunDocument, options);
}
export type CreateAllocationRunMutationHookResult = ReturnType<
  typeof useCreateAllocationRunMutation
>;
export type CreateAllocationRunMutationResult =
  Apollo.MutationResult<CreateAllocationRunMutation>;
export type CreateAllocationRunMutationOptions = Apollo.BaseMutationOptions<
  CreateAllocationRunMutation,
  CreateAllocationRunMutationVariables
>;
export const RestartAllocationRunDocument = gql`
  mutation restartAllocationRun($input: RestartAllocationRunInput!) {
    restartAllocationRun(input: $input) {
      ...AllocationRunBase
    }
  }
  ${AllocationRunBaseFragmentDoc}
`;
export type RestartAllocationRunMutationFn = Apollo.MutationFunction<
  RestartAllocationRunMutation,
  RestartAllocationRunMutationVariables
>;

/**
 * __useRestartAllocationRunMutation__
 *
 * To run a mutation, you first call `useRestartAllocationRunMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestartAllocationRunMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restartAllocationRunMutation, { data, loading, error }] = useRestartAllocationRunMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestartAllocationRunMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RestartAllocationRunMutation,
    RestartAllocationRunMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RestartAllocationRunMutation,
    RestartAllocationRunMutationVariables
  >(RestartAllocationRunDocument, options);
}
export type RestartAllocationRunMutationHookResult = ReturnType<
  typeof useRestartAllocationRunMutation
>;
export type RestartAllocationRunMutationResult =
  Apollo.MutationResult<RestartAllocationRunMutation>;
export type RestartAllocationRunMutationOptions = Apollo.BaseMutationOptions<
  RestartAllocationRunMutation,
  RestartAllocationRunMutationVariables
>;
export const LoadAllocationRunStatusDocument = gql`
  query loadAllocationRunStatus($allocationRunId: ID!) {
    allocationRun(id: $allocationRunId) {
      ...AllocationRunBase
    }
  }
  ${AllocationRunBaseFragmentDoc}
`;

/**
 * __useLoadAllocationRunStatusQuery__
 *
 * To run a query within a React component, call `useLoadAllocationRunStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadAllocationRunStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadAllocationRunStatusQuery({
 *   variables: {
 *      allocationRunId: // value for 'allocationRunId'
 *   },
 * });
 */
export function useLoadAllocationRunStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadAllocationRunStatusQuery,
    LoadAllocationRunStatusQueryVariables
  > &
    (
      | { variables: LoadAllocationRunStatusQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadAllocationRunStatusQuery,
    LoadAllocationRunStatusQueryVariables
  >(LoadAllocationRunStatusDocument, options);
}
export function useLoadAllocationRunStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadAllocationRunStatusQuery,
    LoadAllocationRunStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadAllocationRunStatusQuery,
    LoadAllocationRunStatusQueryVariables
  >(LoadAllocationRunStatusDocument, options);
}
export function useLoadAllocationRunStatusSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadAllocationRunStatusQuery,
        LoadAllocationRunStatusQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadAllocationRunStatusQuery,
    LoadAllocationRunStatusQueryVariables
  >(LoadAllocationRunStatusDocument, options);
}
export type LoadAllocationRunStatusQueryHookResult = ReturnType<
  typeof useLoadAllocationRunStatusQuery
>;
export type LoadAllocationRunStatusLazyQueryHookResult = ReturnType<
  typeof useLoadAllocationRunStatusLazyQuery
>;
export type LoadAllocationRunStatusSuspenseQueryHookResult = ReturnType<
  typeof useLoadAllocationRunStatusSuspenseQuery
>;
export type LoadAllocationRunStatusQueryResult = Apollo.QueryResult<
  LoadAllocationRunStatusQuery,
  LoadAllocationRunStatusQueryVariables
>;
export const AnalyzeAllocationRunDocument = gql`
  mutation analyzeAllocationRun($input: AnalyzeAllocationRunInput!) {
    analyzeAllocationRun(input: $input) {
      ...AnalyzeResult
    }
  }
  ${AnalyzeResultFragmentDoc}
`;
export type AnalyzeAllocationRunMutationFn = Apollo.MutationFunction<
  AnalyzeAllocationRunMutation,
  AnalyzeAllocationRunMutationVariables
>;

/**
 * __useAnalyzeAllocationRunMutation__
 *
 * To run a mutation, you first call `useAnalyzeAllocationRunMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnalyzeAllocationRunMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [analyzeAllocationRunMutation, { data, loading, error }] = useAnalyzeAllocationRunMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAnalyzeAllocationRunMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AnalyzeAllocationRunMutation,
    AnalyzeAllocationRunMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AnalyzeAllocationRunMutation,
    AnalyzeAllocationRunMutationVariables
  >(AnalyzeAllocationRunDocument, options);
}
export type AnalyzeAllocationRunMutationHookResult = ReturnType<
  typeof useAnalyzeAllocationRunMutation
>;
export type AnalyzeAllocationRunMutationResult =
  Apollo.MutationResult<AnalyzeAllocationRunMutation>;
export type AnalyzeAllocationRunMutationOptions = Apollo.BaseMutationOptions<
  AnalyzeAllocationRunMutation,
  AnalyzeAllocationRunMutationVariables
>;
export const LoadAllocationSummaryDocument = gql`
  query loadAllocationSummary(
    $simulationId: ID!
    $groupBy: [AllocationRunResultRequirementGroupBy!]
    $filter: AllocationRunResultRequirementsFilter
  ) {
    simulation(id: $simulationId) {
      latestAllocationRun {
        resultRequirementsSummary(groupBy: $groupBy, filter: $filter) {
          ...AllocationSummary
        }
      }
    }
  }
  ${AllocationSummaryFragmentDoc}
`;

/**
 * __useLoadAllocationSummaryQuery__
 *
 * To run a query within a React component, call `useLoadAllocationSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadAllocationSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadAllocationSummaryQuery({
 *   variables: {
 *      simulationId: // value for 'simulationId'
 *      groupBy: // value for 'groupBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useLoadAllocationSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadAllocationSummaryQuery,
    LoadAllocationSummaryQueryVariables
  > &
    (
      | { variables: LoadAllocationSummaryQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadAllocationSummaryQuery,
    LoadAllocationSummaryQueryVariables
  >(LoadAllocationSummaryDocument, options);
}
export function useLoadAllocationSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadAllocationSummaryQuery,
    LoadAllocationSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadAllocationSummaryQuery,
    LoadAllocationSummaryQueryVariables
  >(LoadAllocationSummaryDocument, options);
}
export function useLoadAllocationSummarySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadAllocationSummaryQuery,
        LoadAllocationSummaryQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadAllocationSummaryQuery,
    LoadAllocationSummaryQueryVariables
  >(LoadAllocationSummaryDocument, options);
}
export type LoadAllocationSummaryQueryHookResult = ReturnType<
  typeof useLoadAllocationSummaryQuery
>;
export type LoadAllocationSummaryLazyQueryHookResult = ReturnType<
  typeof useLoadAllocationSummaryLazyQuery
>;
export type LoadAllocationSummarySuspenseQueryHookResult = ReturnType<
  typeof useLoadAllocationSummarySuspenseQuery
>;
export type LoadAllocationSummaryQueryResult = Apollo.QueryResult<
  LoadAllocationSummaryQuery,
  LoadAllocationSummaryQueryVariables
>;
export const LoadAllocationRequirementSummaryDocument = gql`
  query loadAllocationRequirementSummary(
    $simulationId: ID!
    $groupBy: [AllocationRequirementGroupBy!]
    $filter: AllocationRequirementsFilter
  ) {
    simulation(id: $simulationId) {
      allocationRequirementSet {
        ...AllocationRequirementSetMeta
        requirementsSummary(groupBy: $groupBy, filter: $filter) {
          ...AllocationRequirementSummary
        }
      }
    }
  }
  ${AllocationRequirementSetMetaFragmentDoc}
  ${AllocationRequirementSummaryFragmentDoc}
`;

/**
 * __useLoadAllocationRequirementSummaryQuery__
 *
 * To run a query within a React component, call `useLoadAllocationRequirementSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadAllocationRequirementSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadAllocationRequirementSummaryQuery({
 *   variables: {
 *      simulationId: // value for 'simulationId'
 *      groupBy: // value for 'groupBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useLoadAllocationRequirementSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadAllocationRequirementSummaryQuery,
    LoadAllocationRequirementSummaryQueryVariables
  > &
    (
      | {
          variables: LoadAllocationRequirementSummaryQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadAllocationRequirementSummaryQuery,
    LoadAllocationRequirementSummaryQueryVariables
  >(LoadAllocationRequirementSummaryDocument, options);
}
export function useLoadAllocationRequirementSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadAllocationRequirementSummaryQuery,
    LoadAllocationRequirementSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadAllocationRequirementSummaryQuery,
    LoadAllocationRequirementSummaryQueryVariables
  >(LoadAllocationRequirementSummaryDocument, options);
}
export function useLoadAllocationRequirementSummarySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadAllocationRequirementSummaryQuery,
        LoadAllocationRequirementSummaryQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadAllocationRequirementSummaryQuery,
    LoadAllocationRequirementSummaryQueryVariables
  >(LoadAllocationRequirementSummaryDocument, options);
}
export type LoadAllocationRequirementSummaryQueryHookResult = ReturnType<
  typeof useLoadAllocationRequirementSummaryQuery
>;
export type LoadAllocationRequirementSummaryLazyQueryHookResult = ReturnType<
  typeof useLoadAllocationRequirementSummaryLazyQuery
>;
export type LoadAllocationRequirementSummarySuspenseQueryHookResult =
  ReturnType<typeof useLoadAllocationRequirementSummarySuspenseQuery>;
export type LoadAllocationRequirementSummaryQueryResult = Apollo.QueryResult<
  LoadAllocationRequirementSummaryQuery,
  LoadAllocationRequirementSummaryQueryVariables
>;
export const LoadAllocationRequirementItemsDocument = gql`
  query loadAllocationRequirementItems(
    $simulationId: ID!
    $sortBy: [AllocationRequirementSortBy!]
    $page: SkipLimitPageSpec
    $filter: AllocationRequirementsFilter
  ) {
    simulation(id: $simulationId) {
      allocationRequirementSet {
        ...AllocationRequirementSetMeta
        requirements(sortBy: $sortBy, page: $page, filter: $filter) {
          ...AllocationRequirementsConnection
        }
      }
    }
  }
  ${AllocationRequirementSetMetaFragmentDoc}
  ${AllocationRequirementsConnectionFragmentDoc}
`;

/**
 * __useLoadAllocationRequirementItemsQuery__
 *
 * To run a query within a React component, call `useLoadAllocationRequirementItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadAllocationRequirementItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadAllocationRequirementItemsQuery({
 *   variables: {
 *      simulationId: // value for 'simulationId'
 *      sortBy: // value for 'sortBy'
 *      page: // value for 'page'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useLoadAllocationRequirementItemsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadAllocationRequirementItemsQuery,
    LoadAllocationRequirementItemsQueryVariables
  > &
    (
      | {
          variables: LoadAllocationRequirementItemsQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadAllocationRequirementItemsQuery,
    LoadAllocationRequirementItemsQueryVariables
  >(LoadAllocationRequirementItemsDocument, options);
}
export function useLoadAllocationRequirementItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadAllocationRequirementItemsQuery,
    LoadAllocationRequirementItemsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadAllocationRequirementItemsQuery,
    LoadAllocationRequirementItemsQueryVariables
  >(LoadAllocationRequirementItemsDocument, options);
}
export function useLoadAllocationRequirementItemsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadAllocationRequirementItemsQuery,
        LoadAllocationRequirementItemsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadAllocationRequirementItemsQuery,
    LoadAllocationRequirementItemsQueryVariables
  >(LoadAllocationRequirementItemsDocument, options);
}
export type LoadAllocationRequirementItemsQueryHookResult = ReturnType<
  typeof useLoadAllocationRequirementItemsQuery
>;
export type LoadAllocationRequirementItemsLazyQueryHookResult = ReturnType<
  typeof useLoadAllocationRequirementItemsLazyQuery
>;
export type LoadAllocationRequirementItemsSuspenseQueryHookResult = ReturnType<
  typeof useLoadAllocationRequirementItemsSuspenseQuery
>;
export type LoadAllocationRequirementItemsQueryResult = Apollo.QueryResult<
  LoadAllocationRequirementItemsQuery,
  LoadAllocationRequirementItemsQueryVariables
>;
export const LoadAllocationRequirementMetaDocument = gql`
  query loadAllocationRequirementMeta($allocationRequirementId: String!) {
    allocationRequirementSet(id: $allocationRequirementId) {
      ...AllocationRequirementSetMeta
    }
  }
  ${AllocationRequirementSetMetaFragmentDoc}
`;

/**
 * __useLoadAllocationRequirementMetaQuery__
 *
 * To run a query within a React component, call `useLoadAllocationRequirementMetaQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadAllocationRequirementMetaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadAllocationRequirementMetaQuery({
 *   variables: {
 *      allocationRequirementId: // value for 'allocationRequirementId'
 *   },
 * });
 */
export function useLoadAllocationRequirementMetaQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadAllocationRequirementMetaQuery,
    LoadAllocationRequirementMetaQueryVariables
  > &
    (
      | {
          variables: LoadAllocationRequirementMetaQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadAllocationRequirementMetaQuery,
    LoadAllocationRequirementMetaQueryVariables
  >(LoadAllocationRequirementMetaDocument, options);
}
export function useLoadAllocationRequirementMetaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadAllocationRequirementMetaQuery,
    LoadAllocationRequirementMetaQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadAllocationRequirementMetaQuery,
    LoadAllocationRequirementMetaQueryVariables
  >(LoadAllocationRequirementMetaDocument, options);
}
export function useLoadAllocationRequirementMetaSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadAllocationRequirementMetaQuery,
        LoadAllocationRequirementMetaQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadAllocationRequirementMetaQuery,
    LoadAllocationRequirementMetaQueryVariables
  >(LoadAllocationRequirementMetaDocument, options);
}
export type LoadAllocationRequirementMetaQueryHookResult = ReturnType<
  typeof useLoadAllocationRequirementMetaQuery
>;
export type LoadAllocationRequirementMetaLazyQueryHookResult = ReturnType<
  typeof useLoadAllocationRequirementMetaLazyQuery
>;
export type LoadAllocationRequirementMetaSuspenseQueryHookResult = ReturnType<
  typeof useLoadAllocationRequirementMetaSuspenseQuery
>;
export type LoadAllocationRequirementMetaQueryResult = Apollo.QueryResult<
  LoadAllocationRequirementMetaQuery,
  LoadAllocationRequirementMetaQueryVariables
>;
export const RunCalculateAllocationRequirementDocument = gql`
  mutation runCalculateAllocationRequirement($simId: ID!) {
    calculateSimulationAllocationRequirements(input: { simulationId: $simId }) {
      ...AllocationRequirementSetMeta
    }
  }
  ${AllocationRequirementSetMetaFragmentDoc}
`;
export type RunCalculateAllocationRequirementMutationFn =
  Apollo.MutationFunction<
    RunCalculateAllocationRequirementMutation,
    RunCalculateAllocationRequirementMutationVariables
  >;

/**
 * __useRunCalculateAllocationRequirementMutation__
 *
 * To run a mutation, you first call `useRunCalculateAllocationRequirementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunCalculateAllocationRequirementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runCalculateAllocationRequirementMutation, { data, loading, error }] = useRunCalculateAllocationRequirementMutation({
 *   variables: {
 *      simId: // value for 'simId'
 *   },
 * });
 */
export function useRunCalculateAllocationRequirementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RunCalculateAllocationRequirementMutation,
    RunCalculateAllocationRequirementMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RunCalculateAllocationRequirementMutation,
    RunCalculateAllocationRequirementMutationVariables
  >(RunCalculateAllocationRequirementDocument, options);
}
export type RunCalculateAllocationRequirementMutationHookResult = ReturnType<
  typeof useRunCalculateAllocationRequirementMutation
>;
export type RunCalculateAllocationRequirementMutationResult =
  Apollo.MutationResult<RunCalculateAllocationRequirementMutation>;
export type RunCalculateAllocationRequirementMutationOptions =
  Apollo.BaseMutationOptions<
    RunCalculateAllocationRequirementMutation,
    RunCalculateAllocationRequirementMutationVariables
  >;
export const GenerateLayoutIssueSetDocument = gql`
  mutation generateLayoutIssueSet($key: LayoutIssueSetKey!) {
    generateLayoutIssueSet(key: $key) {
      status
      progress
    }
  }
`;
export type GenerateLayoutIssueSetMutationFn = Apollo.MutationFunction<
  GenerateLayoutIssueSetMutation,
  GenerateLayoutIssueSetMutationVariables
>;

/**
 * __useGenerateLayoutIssueSetMutation__
 *
 * To run a mutation, you first call `useGenerateLayoutIssueSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateLayoutIssueSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateLayoutIssueSetMutation, { data, loading, error }] = useGenerateLayoutIssueSetMutation({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useGenerateLayoutIssueSetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateLayoutIssueSetMutation,
    GenerateLayoutIssueSetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateLayoutIssueSetMutation,
    GenerateLayoutIssueSetMutationVariables
  >(GenerateLayoutIssueSetDocument, options);
}
export type GenerateLayoutIssueSetMutationHookResult = ReturnType<
  typeof useGenerateLayoutIssueSetMutation
>;
export type GenerateLayoutIssueSetMutationResult =
  Apollo.MutationResult<GenerateLayoutIssueSetMutation>;
export type GenerateLayoutIssueSetMutationOptions = Apollo.BaseMutationOptions<
  GenerateLayoutIssueSetMutation,
  GenerateLayoutIssueSetMutationVariables
>;
export const LoadLayoutIssueSetDocument = gql`
  query loadLayoutIssueSet($key: LayoutIssueSetKey!) {
    layoutIssueSet(key: $key) {
      ...LayoutIssueSet
    }
  }
  ${LayoutIssueSetFragmentDoc}
`;

/**
 * __useLoadLayoutIssueSetQuery__
 *
 * To run a query within a React component, call `useLoadLayoutIssueSetQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadLayoutIssueSetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadLayoutIssueSetQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useLoadLayoutIssueSetQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadLayoutIssueSetQuery,
    LoadLayoutIssueSetQueryVariables
  > &
    (
      | { variables: LoadLayoutIssueSetQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadLayoutIssueSetQuery,
    LoadLayoutIssueSetQueryVariables
  >(LoadLayoutIssueSetDocument, options);
}
export function useLoadLayoutIssueSetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadLayoutIssueSetQuery,
    LoadLayoutIssueSetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadLayoutIssueSetQuery,
    LoadLayoutIssueSetQueryVariables
  >(LoadLayoutIssueSetDocument, options);
}
export function useLoadLayoutIssueSetSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadLayoutIssueSetQuery,
        LoadLayoutIssueSetQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadLayoutIssueSetQuery,
    LoadLayoutIssueSetQueryVariables
  >(LoadLayoutIssueSetDocument, options);
}
export type LoadLayoutIssueSetQueryHookResult = ReturnType<
  typeof useLoadLayoutIssueSetQuery
>;
export type LoadLayoutIssueSetLazyQueryHookResult = ReturnType<
  typeof useLoadLayoutIssueSetLazyQuery
>;
export type LoadLayoutIssueSetSuspenseQueryHookResult = ReturnType<
  typeof useLoadLayoutIssueSetSuspenseQuery
>;
export type LoadLayoutIssueSetQueryResult = Apollo.QueryResult<
  LoadLayoutIssueSetQuery,
  LoadLayoutIssueSetQueryVariables
>;
export const LoadLayoutIssueDataDocument = gql`
  query loadLayoutIssueData(
    $key: LayoutIssueSetKey!
    $planeId: [ID!]
    $level: [Int!]
    $bayId: [ID!]
  ) {
    layoutIssueSet(key: $key) {
      invalidLocations(
        page: null
        filter: {
          planeId: $planeId
          locationLevel: $level
          locationBayId: $bayId
        }
      ) {
        totalCount
        content {
          ...LayoutIssueInvalidLocation
        }
      }
    }
  }
  ${LayoutIssueInvalidLocationFragmentDoc}
`;

/**
 * __useLoadLayoutIssueDataQuery__
 *
 * To run a query within a React component, call `useLoadLayoutIssueDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadLayoutIssueDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadLayoutIssueDataQuery({
 *   variables: {
 *      key: // value for 'key'
 *      planeId: // value for 'planeId'
 *      level: // value for 'level'
 *      bayId: // value for 'bayId'
 *   },
 * });
 */
export function useLoadLayoutIssueDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadLayoutIssueDataQuery,
    LoadLayoutIssueDataQueryVariables
  > &
    (
      | { variables: LoadLayoutIssueDataQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadLayoutIssueDataQuery,
    LoadLayoutIssueDataQueryVariables
  >(LoadLayoutIssueDataDocument, options);
}
export function useLoadLayoutIssueDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadLayoutIssueDataQuery,
    LoadLayoutIssueDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadLayoutIssueDataQuery,
    LoadLayoutIssueDataQueryVariables
  >(LoadLayoutIssueDataDocument, options);
}
export function useLoadLayoutIssueDataSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadLayoutIssueDataQuery,
        LoadLayoutIssueDataQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadLayoutIssueDataQuery,
    LoadLayoutIssueDataQueryVariables
  >(LoadLayoutIssueDataDocument, options);
}
export type LoadLayoutIssueDataQueryHookResult = ReturnType<
  typeof useLoadLayoutIssueDataQuery
>;
export type LoadLayoutIssueDataLazyQueryHookResult = ReturnType<
  typeof useLoadLayoutIssueDataLazyQuery
>;
export type LoadLayoutIssueDataSuspenseQueryHookResult = ReturnType<
  typeof useLoadLayoutIssueDataSuspenseQuery
>;
export type LoadLayoutIssueDataQueryResult = Apollo.QueryResult<
  LoadLayoutIssueDataQuery,
  LoadLayoutIssueDataQueryVariables
>;
export const GenerateAssignmentIssueSetDocument = gql`
  mutation generateAssignmentIssueSet($key: AssignmentIssueSetKey!) {
    generateAssignmentIssueSet(key: $key) {
      status
      progress
    }
  }
`;
export type GenerateAssignmentIssueSetMutationFn = Apollo.MutationFunction<
  GenerateAssignmentIssueSetMutation,
  GenerateAssignmentIssueSetMutationVariables
>;

/**
 * __useGenerateAssignmentIssueSetMutation__
 *
 * To run a mutation, you first call `useGenerateAssignmentIssueSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateAssignmentIssueSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateAssignmentIssueSetMutation, { data, loading, error }] = useGenerateAssignmentIssueSetMutation({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useGenerateAssignmentIssueSetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateAssignmentIssueSetMutation,
    GenerateAssignmentIssueSetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateAssignmentIssueSetMutation,
    GenerateAssignmentIssueSetMutationVariables
  >(GenerateAssignmentIssueSetDocument, options);
}
export type GenerateAssignmentIssueSetMutationHookResult = ReturnType<
  typeof useGenerateAssignmentIssueSetMutation
>;
export type GenerateAssignmentIssueSetMutationResult =
  Apollo.MutationResult<GenerateAssignmentIssueSetMutation>;
export type GenerateAssignmentIssueSetMutationOptions =
  Apollo.BaseMutationOptions<
    GenerateAssignmentIssueSetMutation,
    GenerateAssignmentIssueSetMutationVariables
  >;
export const LoadAssignmentIssueSetDocument = gql`
  query loadAssignmentIssueSet($key: AssignmentIssueSetKey!) {
    assignmentIssueSet(key: $key) {
      ...AssignmentIssueSet
    }
  }
  ${AssignmentIssueSetFragmentDoc}
`;

/**
 * __useLoadAssignmentIssueSetQuery__
 *
 * To run a query within a React component, call `useLoadAssignmentIssueSetQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadAssignmentIssueSetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadAssignmentIssueSetQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useLoadAssignmentIssueSetQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadAssignmentIssueSetQuery,
    LoadAssignmentIssueSetQueryVariables
  > &
    (
      | { variables: LoadAssignmentIssueSetQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadAssignmentIssueSetQuery,
    LoadAssignmentIssueSetQueryVariables
  >(LoadAssignmentIssueSetDocument, options);
}
export function useLoadAssignmentIssueSetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadAssignmentIssueSetQuery,
    LoadAssignmentIssueSetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadAssignmentIssueSetQuery,
    LoadAssignmentIssueSetQueryVariables
  >(LoadAssignmentIssueSetDocument, options);
}
export function useLoadAssignmentIssueSetSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadAssignmentIssueSetQuery,
        LoadAssignmentIssueSetQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadAssignmentIssueSetQuery,
    LoadAssignmentIssueSetQueryVariables
  >(LoadAssignmentIssueSetDocument, options);
}
export type LoadAssignmentIssueSetQueryHookResult = ReturnType<
  typeof useLoadAssignmentIssueSetQuery
>;
export type LoadAssignmentIssueSetLazyQueryHookResult = ReturnType<
  typeof useLoadAssignmentIssueSetLazyQuery
>;
export type LoadAssignmentIssueSetSuspenseQueryHookResult = ReturnType<
  typeof useLoadAssignmentIssueSetSuspenseQuery
>;
export type LoadAssignmentIssueSetQueryResult = Apollo.QueryResult<
  LoadAssignmentIssueSetQuery,
  LoadAssignmentIssueSetQueryVariables
>;
export const LoadAssignmentIssueDataDocument = gql`
  query loadAssignmentIssueData(
    $key: AssignmentIssueSetKey!
    $planeId: [ID!]
    $level: [Int!]
    $bayId: [ID!]
    $reason: [AssignmentIssueInvalidLocationReason!]
    $page: SkipLimitPageSpec
  ) {
    assignmentIssueSet(key: $key) {
      invalidLocations(
        page: $page
        filter: {
          planeId: $planeId
          locationLevel: $level
          locationBayId: $bayId
          reason: $reason
        }
      ) {
        ...AssignmentIssueInvalidLocationsData
      }
    }
  }
  ${AssignmentIssueInvalidLocationsDataFragmentDoc}
`;

/**
 * __useLoadAssignmentIssueDataQuery__
 *
 * To run a query within a React component, call `useLoadAssignmentIssueDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadAssignmentIssueDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadAssignmentIssueDataQuery({
 *   variables: {
 *      key: // value for 'key'
 *      planeId: // value for 'planeId'
 *      level: // value for 'level'
 *      bayId: // value for 'bayId'
 *      reason: // value for 'reason'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useLoadAssignmentIssueDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadAssignmentIssueDataQuery,
    LoadAssignmentIssueDataQueryVariables
  > &
    (
      | { variables: LoadAssignmentIssueDataQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadAssignmentIssueDataQuery,
    LoadAssignmentIssueDataQueryVariables
  >(LoadAssignmentIssueDataDocument, options);
}
export function useLoadAssignmentIssueDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadAssignmentIssueDataQuery,
    LoadAssignmentIssueDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadAssignmentIssueDataQuery,
    LoadAssignmentIssueDataQueryVariables
  >(LoadAssignmentIssueDataDocument, options);
}
export function useLoadAssignmentIssueDataSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadAssignmentIssueDataQuery,
        LoadAssignmentIssueDataQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadAssignmentIssueDataQuery,
    LoadAssignmentIssueDataQueryVariables
  >(LoadAssignmentIssueDataDocument, options);
}
export type LoadAssignmentIssueDataQueryHookResult = ReturnType<
  typeof useLoadAssignmentIssueDataQuery
>;
export type LoadAssignmentIssueDataLazyQueryHookResult = ReturnType<
  typeof useLoadAssignmentIssueDataLazyQuery
>;
export type LoadAssignmentIssueDataSuspenseQueryHookResult = ReturnType<
  typeof useLoadAssignmentIssueDataSuspenseQuery
>;
export type LoadAssignmentIssueDataQueryResult = Apollo.QueryResult<
  LoadAssignmentIssueDataQuery,
  LoadAssignmentIssueDataQueryVariables
>;
export const GenerateItemSetIssueSetDocument = gql`
  mutation generateItemSetIssueSet($key: ItemSetIssueSetKey!) {
    generateItemSetIssueSet(key: $key) {
      status
      progress
    }
  }
`;
export type GenerateItemSetIssueSetMutationFn = Apollo.MutationFunction<
  GenerateItemSetIssueSetMutation,
  GenerateItemSetIssueSetMutationVariables
>;

/**
 * __useGenerateItemSetIssueSetMutation__
 *
 * To run a mutation, you first call `useGenerateItemSetIssueSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateItemSetIssueSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateItemSetIssueSetMutation, { data, loading, error }] = useGenerateItemSetIssueSetMutation({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useGenerateItemSetIssueSetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateItemSetIssueSetMutation,
    GenerateItemSetIssueSetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateItemSetIssueSetMutation,
    GenerateItemSetIssueSetMutationVariables
  >(GenerateItemSetIssueSetDocument, options);
}
export type GenerateItemSetIssueSetMutationHookResult = ReturnType<
  typeof useGenerateItemSetIssueSetMutation
>;
export type GenerateItemSetIssueSetMutationResult =
  Apollo.MutationResult<GenerateItemSetIssueSetMutation>;
export type GenerateItemSetIssueSetMutationOptions = Apollo.BaseMutationOptions<
  GenerateItemSetIssueSetMutation,
  GenerateItemSetIssueSetMutationVariables
>;
export const LoadItemSetIssueSetDocument = gql`
  query loadItemSetIssueSet($key: ItemSetIssueSetKey!) {
    itemSetIssueSet(key: $key) {
      ...ItemSetIssueSet
    }
  }
  ${ItemSetIssueSetFragmentDoc}
`;

/**
 * __useLoadItemSetIssueSetQuery__
 *
 * To run a query within a React component, call `useLoadItemSetIssueSetQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadItemSetIssueSetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadItemSetIssueSetQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useLoadItemSetIssueSetQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadItemSetIssueSetQuery,
    LoadItemSetIssueSetQueryVariables
  > &
    (
      | { variables: LoadItemSetIssueSetQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadItemSetIssueSetQuery,
    LoadItemSetIssueSetQueryVariables
  >(LoadItemSetIssueSetDocument, options);
}
export function useLoadItemSetIssueSetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadItemSetIssueSetQuery,
    LoadItemSetIssueSetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadItemSetIssueSetQuery,
    LoadItemSetIssueSetQueryVariables
  >(LoadItemSetIssueSetDocument, options);
}
export function useLoadItemSetIssueSetSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadItemSetIssueSetQuery,
        LoadItemSetIssueSetQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadItemSetIssueSetQuery,
    LoadItemSetIssueSetQueryVariables
  >(LoadItemSetIssueSetDocument, options);
}
export type LoadItemSetIssueSetQueryHookResult = ReturnType<
  typeof useLoadItemSetIssueSetQuery
>;
export type LoadItemSetIssueSetLazyQueryHookResult = ReturnType<
  typeof useLoadItemSetIssueSetLazyQuery
>;
export type LoadItemSetIssueSetSuspenseQueryHookResult = ReturnType<
  typeof useLoadItemSetIssueSetSuspenseQuery
>;
export type LoadItemSetIssueSetQueryResult = Apollo.QueryResult<
  LoadItemSetIssueSetQuery,
  LoadItemSetIssueSetQueryVariables
>;
export const LoadItemSetUomIssueDataDocument = gql`
  query loadItemSetUOMIssueData(
    $key: ItemSetIssueSetKey!
    $reason: [ItemSetIssueInvalidUomReason!]
    $page: SkipLimitPageSpec
  ) {
    itemSetIssueSet(key: $key) {
      invalidUoms(filter: { reason: $reason }, page: $page) {
        ...ItemSetIssueInvalidUomsData
      }
    }
  }
  ${ItemSetIssueInvalidUomsDataFragmentDoc}
`;

/**
 * __useLoadItemSetUomIssueDataQuery__
 *
 * To run a query within a React component, call `useLoadItemSetUomIssueDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadItemSetUomIssueDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadItemSetUomIssueDataQuery({
 *   variables: {
 *      key: // value for 'key'
 *      reason: // value for 'reason'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useLoadItemSetUomIssueDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadItemSetUomIssueDataQuery,
    LoadItemSetUomIssueDataQueryVariables
  > &
    (
      | { variables: LoadItemSetUomIssueDataQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadItemSetUomIssueDataQuery,
    LoadItemSetUomIssueDataQueryVariables
  >(LoadItemSetUomIssueDataDocument, options);
}
export function useLoadItemSetUomIssueDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadItemSetUomIssueDataQuery,
    LoadItemSetUomIssueDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadItemSetUomIssueDataQuery,
    LoadItemSetUomIssueDataQueryVariables
  >(LoadItemSetUomIssueDataDocument, options);
}
export function useLoadItemSetUomIssueDataSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadItemSetUomIssueDataQuery,
        LoadItemSetUomIssueDataQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadItemSetUomIssueDataQuery,
    LoadItemSetUomIssueDataQueryVariables
  >(LoadItemSetUomIssueDataDocument, options);
}
export type LoadItemSetUomIssueDataQueryHookResult = ReturnType<
  typeof useLoadItemSetUomIssueDataQuery
>;
export type LoadItemSetUomIssueDataLazyQueryHookResult = ReturnType<
  typeof useLoadItemSetUomIssueDataLazyQuery
>;
export type LoadItemSetUomIssueDataSuspenseQueryHookResult = ReturnType<
  typeof useLoadItemSetUomIssueDataSuspenseQuery
>;
export type LoadItemSetUomIssueDataQueryResult = Apollo.QueryResult<
  LoadItemSetUomIssueDataQuery,
  LoadItemSetUomIssueDataQueryVariables
>;
export const LoadItemSetItemIssueDataDocument = gql`
  query loadItemSetItemIssueData(
    $key: ItemSetIssueSetKey!
    $reason: [ItemSetIssueInvalidItemReason!]
    $page: SkipLimitPageSpec
  ) {
    itemSetIssueSet(key: $key) {
      invalidItems(filter: { reason: $reason }, page: $page) {
        ...ItemSetIssueInvalidItemsData
      }
    }
  }
  ${ItemSetIssueInvalidItemsDataFragmentDoc}
`;

/**
 * __useLoadItemSetItemIssueDataQuery__
 *
 * To run a query within a React component, call `useLoadItemSetItemIssueDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadItemSetItemIssueDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadItemSetItemIssueDataQuery({
 *   variables: {
 *      key: // value for 'key'
 *      reason: // value for 'reason'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useLoadItemSetItemIssueDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadItemSetItemIssueDataQuery,
    LoadItemSetItemIssueDataQueryVariables
  > &
    (
      | { variables: LoadItemSetItemIssueDataQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadItemSetItemIssueDataQuery,
    LoadItemSetItemIssueDataQueryVariables
  >(LoadItemSetItemIssueDataDocument, options);
}
export function useLoadItemSetItemIssueDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadItemSetItemIssueDataQuery,
    LoadItemSetItemIssueDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadItemSetItemIssueDataQuery,
    LoadItemSetItemIssueDataQueryVariables
  >(LoadItemSetItemIssueDataDocument, options);
}
export function useLoadItemSetItemIssueDataSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadItemSetItemIssueDataQuery,
        LoadItemSetItemIssueDataQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadItemSetItemIssueDataQuery,
    LoadItemSetItemIssueDataQueryVariables
  >(LoadItemSetItemIssueDataDocument, options);
}
export type LoadItemSetItemIssueDataQueryHookResult = ReturnType<
  typeof useLoadItemSetItemIssueDataQuery
>;
export type LoadItemSetItemIssueDataLazyQueryHookResult = ReturnType<
  typeof useLoadItemSetItemIssueDataLazyQuery
>;
export type LoadItemSetItemIssueDataSuspenseQueryHookResult = ReturnType<
  typeof useLoadItemSetItemIssueDataSuspenseQuery
>;
export type LoadItemSetItemIssueDataQueryResult = Apollo.QueryResult<
  LoadItemSetItemIssueDataQuery,
  LoadItemSetItemIssueDataQueryVariables
>;
export const GenerateOrderSetIssueSetDocument = gql`
  mutation generateOrderSetIssueSet($key: OrderSetIssueSetKey!) {
    generateOrderSetIssueSet(key: $key) {
      status
      progress
    }
  }
`;
export type GenerateOrderSetIssueSetMutationFn = Apollo.MutationFunction<
  GenerateOrderSetIssueSetMutation,
  GenerateOrderSetIssueSetMutationVariables
>;

/**
 * __useGenerateOrderSetIssueSetMutation__
 *
 * To run a mutation, you first call `useGenerateOrderSetIssueSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateOrderSetIssueSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateOrderSetIssueSetMutation, { data, loading, error }] = useGenerateOrderSetIssueSetMutation({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useGenerateOrderSetIssueSetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateOrderSetIssueSetMutation,
    GenerateOrderSetIssueSetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateOrderSetIssueSetMutation,
    GenerateOrderSetIssueSetMutationVariables
  >(GenerateOrderSetIssueSetDocument, options);
}
export type GenerateOrderSetIssueSetMutationHookResult = ReturnType<
  typeof useGenerateOrderSetIssueSetMutation
>;
export type GenerateOrderSetIssueSetMutationResult =
  Apollo.MutationResult<GenerateOrderSetIssueSetMutation>;
export type GenerateOrderSetIssueSetMutationOptions =
  Apollo.BaseMutationOptions<
    GenerateOrderSetIssueSetMutation,
    GenerateOrderSetIssueSetMutationVariables
  >;
export const LoadOrderSetIssueSetDocument = gql`
  query loadOrderSetIssueSet($key: OrderSetIssueSetKey!) {
    orderSetIssueSet(key: $key) {
      ...OrderSetIssueSet
    }
  }
  ${OrderSetIssueSetFragmentDoc}
`;

/**
 * __useLoadOrderSetIssueSetQuery__
 *
 * To run a query within a React component, call `useLoadOrderSetIssueSetQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadOrderSetIssueSetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadOrderSetIssueSetQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useLoadOrderSetIssueSetQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadOrderSetIssueSetQuery,
    LoadOrderSetIssueSetQueryVariables
  > &
    (
      | { variables: LoadOrderSetIssueSetQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadOrderSetIssueSetQuery,
    LoadOrderSetIssueSetQueryVariables
  >(LoadOrderSetIssueSetDocument, options);
}
export function useLoadOrderSetIssueSetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadOrderSetIssueSetQuery,
    LoadOrderSetIssueSetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadOrderSetIssueSetQuery,
    LoadOrderSetIssueSetQueryVariables
  >(LoadOrderSetIssueSetDocument, options);
}
export function useLoadOrderSetIssueSetSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadOrderSetIssueSetQuery,
        LoadOrderSetIssueSetQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadOrderSetIssueSetQuery,
    LoadOrderSetIssueSetQueryVariables
  >(LoadOrderSetIssueSetDocument, options);
}
export type LoadOrderSetIssueSetQueryHookResult = ReturnType<
  typeof useLoadOrderSetIssueSetQuery
>;
export type LoadOrderSetIssueSetLazyQueryHookResult = ReturnType<
  typeof useLoadOrderSetIssueSetLazyQuery
>;
export type LoadOrderSetIssueSetSuspenseQueryHookResult = ReturnType<
  typeof useLoadOrderSetIssueSetSuspenseQuery
>;
export type LoadOrderSetIssueSetQueryResult = Apollo.QueryResult<
  LoadOrderSetIssueSetQuery,
  LoadOrderSetIssueSetQueryVariables
>;
export const LoadOrderSetIssueDataDocument = gql`
  query loadOrderSetIssueData(
    $key: OrderSetIssueSetKey!
    $reason: [OrderSetIssueUnpickableLineReason!]
    $page: SkipLimitPageSpec
  ) {
    orderSetIssueSet(key: $key) {
      unpickableLines(filter: { reason: $reason }, page: $page) {
        ...OrderSetIssueUnpickableLinesData
      }
    }
  }
  ${OrderSetIssueUnpickableLinesDataFragmentDoc}
`;

/**
 * __useLoadOrderSetIssueDataQuery__
 *
 * To run a query within a React component, call `useLoadOrderSetIssueDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadOrderSetIssueDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadOrderSetIssueDataQuery({
 *   variables: {
 *      key: // value for 'key'
 *      reason: // value for 'reason'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useLoadOrderSetIssueDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadOrderSetIssueDataQuery,
    LoadOrderSetIssueDataQueryVariables
  > &
    (
      | { variables: LoadOrderSetIssueDataQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadOrderSetIssueDataQuery,
    LoadOrderSetIssueDataQueryVariables
  >(LoadOrderSetIssueDataDocument, options);
}
export function useLoadOrderSetIssueDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadOrderSetIssueDataQuery,
    LoadOrderSetIssueDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadOrderSetIssueDataQuery,
    LoadOrderSetIssueDataQueryVariables
  >(LoadOrderSetIssueDataDocument, options);
}
export function useLoadOrderSetIssueDataSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadOrderSetIssueDataQuery,
        LoadOrderSetIssueDataQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadOrderSetIssueDataQuery,
    LoadOrderSetIssueDataQueryVariables
  >(LoadOrderSetIssueDataDocument, options);
}
export type LoadOrderSetIssueDataQueryHookResult = ReturnType<
  typeof useLoadOrderSetIssueDataQuery
>;
export type LoadOrderSetIssueDataLazyQueryHookResult = ReturnType<
  typeof useLoadOrderSetIssueDataLazyQuery
>;
export type LoadOrderSetIssueDataSuspenseQueryHookResult = ReturnType<
  typeof useLoadOrderSetIssueDataSuspenseQuery
>;
export type LoadOrderSetIssueDataQueryResult = Apollo.QueryResult<
  LoadOrderSetIssueDataQuery,
  LoadOrderSetIssueDataQueryVariables
>;
export const LoadAnalyzeJobsDetailsDocument = gql`
  query loadAnalyzeJobsDetails(
    $analyzeId: ID!
    $filter: AnalyzeResultJobsFilter
    $sortBy: [AnalyzeResultJobsSortBy!]
    $page: SkipLimitPageSpec
  ) {
    analyzeResult(id: $analyzeId) {
      jobs(filter: $filter, page: $page, sortBy: $sortBy) {
        totalCount
        content {
          ...AnalyzeResultJob
        }
      }
    }
  }
  ${AnalyzeResultJobFragmentDoc}
`;

/**
 * __useLoadAnalyzeJobsDetailsQuery__
 *
 * To run a query within a React component, call `useLoadAnalyzeJobsDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadAnalyzeJobsDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadAnalyzeJobsDetailsQuery({
 *   variables: {
 *      analyzeId: // value for 'analyzeId'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useLoadAnalyzeJobsDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadAnalyzeJobsDetailsQuery,
    LoadAnalyzeJobsDetailsQueryVariables
  > &
    (
      | { variables: LoadAnalyzeJobsDetailsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadAnalyzeJobsDetailsQuery,
    LoadAnalyzeJobsDetailsQueryVariables
  >(LoadAnalyzeJobsDetailsDocument, options);
}
export function useLoadAnalyzeJobsDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadAnalyzeJobsDetailsQuery,
    LoadAnalyzeJobsDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadAnalyzeJobsDetailsQuery,
    LoadAnalyzeJobsDetailsQueryVariables
  >(LoadAnalyzeJobsDetailsDocument, options);
}
export function useLoadAnalyzeJobsDetailsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadAnalyzeJobsDetailsQuery,
        LoadAnalyzeJobsDetailsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadAnalyzeJobsDetailsQuery,
    LoadAnalyzeJobsDetailsQueryVariables
  >(LoadAnalyzeJobsDetailsDocument, options);
}
export type LoadAnalyzeJobsDetailsQueryHookResult = ReturnType<
  typeof useLoadAnalyzeJobsDetailsQuery
>;
export type LoadAnalyzeJobsDetailsLazyQueryHookResult = ReturnType<
  typeof useLoadAnalyzeJobsDetailsLazyQuery
>;
export type LoadAnalyzeJobsDetailsSuspenseQueryHookResult = ReturnType<
  typeof useLoadAnalyzeJobsDetailsSuspenseQuery
>;
export type LoadAnalyzeJobsDetailsQueryResult = Apollo.QueryResult<
  LoadAnalyzeJobsDetailsQuery,
  LoadAnalyzeJobsDetailsQueryVariables
>;
export const LoadAnalyzeLocationsDocument = gql`
  query loadAnalyzeLocations(
    $analyzeId: ID!
    $filter: AnalyzeResultLocationsAndProductsFilter
    $page: SkipLimitPageSpec
    $sortBy: [AnalyzeResultLocationsSortBy!]
  ) {
    analyzeResult(id: $analyzeId) {
      visitedLocations(filter: $filter, page: $page, sortBy: $sortBy) {
        ...AnalyzeResultLocationsConnection
      }
    }
  }
  ${AnalyzeResultLocationsConnectionFragmentDoc}
`;

/**
 * __useLoadAnalyzeLocationsQuery__
 *
 * To run a query within a React component, call `useLoadAnalyzeLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadAnalyzeLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadAnalyzeLocationsQuery({
 *   variables: {
 *      analyzeId: // value for 'analyzeId'
 *      filter: // value for 'filter'
 *      page: // value for 'page'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useLoadAnalyzeLocationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadAnalyzeLocationsQuery,
    LoadAnalyzeLocationsQueryVariables
  > &
    (
      | { variables: LoadAnalyzeLocationsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadAnalyzeLocationsQuery,
    LoadAnalyzeLocationsQueryVariables
  >(LoadAnalyzeLocationsDocument, options);
}
export function useLoadAnalyzeLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadAnalyzeLocationsQuery,
    LoadAnalyzeLocationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadAnalyzeLocationsQuery,
    LoadAnalyzeLocationsQueryVariables
  >(LoadAnalyzeLocationsDocument, options);
}
export function useLoadAnalyzeLocationsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadAnalyzeLocationsQuery,
        LoadAnalyzeLocationsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadAnalyzeLocationsQuery,
    LoadAnalyzeLocationsQueryVariables
  >(LoadAnalyzeLocationsDocument, options);
}
export type LoadAnalyzeLocationsQueryHookResult = ReturnType<
  typeof useLoadAnalyzeLocationsQuery
>;
export type LoadAnalyzeLocationsLazyQueryHookResult = ReturnType<
  typeof useLoadAnalyzeLocationsLazyQuery
>;
export type LoadAnalyzeLocationsSuspenseQueryHookResult = ReturnType<
  typeof useLoadAnalyzeLocationsSuspenseQuery
>;
export type LoadAnalyzeLocationsQueryResult = Apollo.QueryResult<
  LoadAnalyzeLocationsQuery,
  LoadAnalyzeLocationsQueryVariables
>;
export const LoadAnalyzeWaypointsDocument = gql`
  query loadAnalyzeWaypoints($analyzeId: ID!, $planeId: ID!) {
    analyzeResult(id: $analyzeId) {
      waypoints(
        filter: { planeId: [$planeId] }
        sortBy: { field: HIT_COUNT, direction: DESC }
        page: null
      ) {
        content {
          ...AnalyzeResultWaypoint
        }
      }
    }
  }
  ${AnalyzeResultWaypointFragmentDoc}
`;

/**
 * __useLoadAnalyzeWaypointsQuery__
 *
 * To run a query within a React component, call `useLoadAnalyzeWaypointsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadAnalyzeWaypointsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadAnalyzeWaypointsQuery({
 *   variables: {
 *      analyzeId: // value for 'analyzeId'
 *      planeId: // value for 'planeId'
 *   },
 * });
 */
export function useLoadAnalyzeWaypointsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadAnalyzeWaypointsQuery,
    LoadAnalyzeWaypointsQueryVariables
  > &
    (
      | { variables: LoadAnalyzeWaypointsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadAnalyzeWaypointsQuery,
    LoadAnalyzeWaypointsQueryVariables
  >(LoadAnalyzeWaypointsDocument, options);
}
export function useLoadAnalyzeWaypointsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadAnalyzeWaypointsQuery,
    LoadAnalyzeWaypointsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadAnalyzeWaypointsQuery,
    LoadAnalyzeWaypointsQueryVariables
  >(LoadAnalyzeWaypointsDocument, options);
}
export function useLoadAnalyzeWaypointsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadAnalyzeWaypointsQuery,
        LoadAnalyzeWaypointsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadAnalyzeWaypointsQuery,
    LoadAnalyzeWaypointsQueryVariables
  >(LoadAnalyzeWaypointsDocument, options);
}
export type LoadAnalyzeWaypointsQueryHookResult = ReturnType<
  typeof useLoadAnalyzeWaypointsQuery
>;
export type LoadAnalyzeWaypointsLazyQueryHookResult = ReturnType<
  typeof useLoadAnalyzeWaypointsLazyQuery
>;
export type LoadAnalyzeWaypointsSuspenseQueryHookResult = ReturnType<
  typeof useLoadAnalyzeWaypointsSuspenseQuery
>;
export type LoadAnalyzeWaypointsQueryResult = Apollo.QueryResult<
  LoadAnalyzeWaypointsQuery,
  LoadAnalyzeWaypointsQueryVariables
>;
export const LoadAnalyzedOrderDetailsDocument = gql`
  query loadAnalyzedOrderDetails($analyzeId: ID!, $orderId: ID!) {
    analyzeResult(id: $analyzeId) {
      orders(filter: { id: [$orderId] }) {
        content {
          ...AnalyzeResultOrderDetails
        }
      }
    }
  }
  ${AnalyzeResultOrderDetailsFragmentDoc}
`;

/**
 * __useLoadAnalyzedOrderDetailsQuery__
 *
 * To run a query within a React component, call `useLoadAnalyzedOrderDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadAnalyzedOrderDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadAnalyzedOrderDetailsQuery({
 *   variables: {
 *      analyzeId: // value for 'analyzeId'
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useLoadAnalyzedOrderDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadAnalyzedOrderDetailsQuery,
    LoadAnalyzedOrderDetailsQueryVariables
  > &
    (
      | { variables: LoadAnalyzedOrderDetailsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadAnalyzedOrderDetailsQuery,
    LoadAnalyzedOrderDetailsQueryVariables
  >(LoadAnalyzedOrderDetailsDocument, options);
}
export function useLoadAnalyzedOrderDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadAnalyzedOrderDetailsQuery,
    LoadAnalyzedOrderDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadAnalyzedOrderDetailsQuery,
    LoadAnalyzedOrderDetailsQueryVariables
  >(LoadAnalyzedOrderDetailsDocument, options);
}
export function useLoadAnalyzedOrderDetailsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadAnalyzedOrderDetailsQuery,
        LoadAnalyzedOrderDetailsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadAnalyzedOrderDetailsQuery,
    LoadAnalyzedOrderDetailsQueryVariables
  >(LoadAnalyzedOrderDetailsDocument, options);
}
export type LoadAnalyzedOrderDetailsQueryHookResult = ReturnType<
  typeof useLoadAnalyzedOrderDetailsQuery
>;
export type LoadAnalyzedOrderDetailsLazyQueryHookResult = ReturnType<
  typeof useLoadAnalyzedOrderDetailsLazyQuery
>;
export type LoadAnalyzedOrderDetailsSuspenseQueryHookResult = ReturnType<
  typeof useLoadAnalyzedOrderDetailsSuspenseQuery
>;
export type LoadAnalyzedOrderDetailsQueryResult = Apollo.QueryResult<
  LoadAnalyzedOrderDetailsQuery,
  LoadAnalyzedOrderDetailsQueryVariables
>;
export const LoadAnalyzedPickListDetailsDocument = gql`
  query loadAnalyzedPickListDetails(
    $analyzeId: ID!
    $orderId: ID!
    $picklistId: ID!
  ) {
    analyzeResult(id: $analyzeId) {
      orders(filter: { id: [$orderId] }) {
        content {
          picklists(filter: { picklistId: [$picklistId] }) {
            ...AnalyzePicklistEvents
          }
        }
      }
    }
  }
  ${AnalyzePicklistEventsFragmentDoc}
`;

/**
 * __useLoadAnalyzedPickListDetailsQuery__
 *
 * To run a query within a React component, call `useLoadAnalyzedPickListDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadAnalyzedPickListDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadAnalyzedPickListDetailsQuery({
 *   variables: {
 *      analyzeId: // value for 'analyzeId'
 *      orderId: // value for 'orderId'
 *      picklistId: // value for 'picklistId'
 *   },
 * });
 */
export function useLoadAnalyzedPickListDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadAnalyzedPickListDetailsQuery,
    LoadAnalyzedPickListDetailsQueryVariables
  > &
    (
      | { variables: LoadAnalyzedPickListDetailsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadAnalyzedPickListDetailsQuery,
    LoadAnalyzedPickListDetailsQueryVariables
  >(LoadAnalyzedPickListDetailsDocument, options);
}
export function useLoadAnalyzedPickListDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadAnalyzedPickListDetailsQuery,
    LoadAnalyzedPickListDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadAnalyzedPickListDetailsQuery,
    LoadAnalyzedPickListDetailsQueryVariables
  >(LoadAnalyzedPickListDetailsDocument, options);
}
export function useLoadAnalyzedPickListDetailsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadAnalyzedPickListDetailsQuery,
        LoadAnalyzedPickListDetailsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadAnalyzedPickListDetailsQuery,
    LoadAnalyzedPickListDetailsQueryVariables
  >(LoadAnalyzedPickListDetailsDocument, options);
}
export type LoadAnalyzedPickListDetailsQueryHookResult = ReturnType<
  typeof useLoadAnalyzedPickListDetailsQuery
>;
export type LoadAnalyzedPickListDetailsLazyQueryHookResult = ReturnType<
  typeof useLoadAnalyzedPickListDetailsLazyQuery
>;
export type LoadAnalyzedPickListDetailsSuspenseQueryHookResult = ReturnType<
  typeof useLoadAnalyzedPickListDetailsSuspenseQuery
>;
export type LoadAnalyzedPickListDetailsQueryResult = Apollo.QueryResult<
  LoadAnalyzedPickListDetailsQuery,
  LoadAnalyzedPickListDetailsQueryVariables
>;
export const LoadAnalyzeOrdersDocument = gql`
  query loadAnalyzeOrders(
    $analyzeId: ID!
    $page: SkipLimitPageSpec
    $filter: AnalyzeResultOrdersFilter
    $sortBy: [AnalyzeResultOrdersSortBy!]
  ) {
    analyzeResult(id: $analyzeId) {
      orders(page: $page, filter: $filter, sortBy: $sortBy) {
        totalCount
        content {
          ...AnalyzeResultOrderSummary
        }
      }
    }
  }
  ${AnalyzeResultOrderSummaryFragmentDoc}
`;

/**
 * __useLoadAnalyzeOrdersQuery__
 *
 * To run a query within a React component, call `useLoadAnalyzeOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadAnalyzeOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadAnalyzeOrdersQuery({
 *   variables: {
 *      analyzeId: // value for 'analyzeId'
 *      page: // value for 'page'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useLoadAnalyzeOrdersQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadAnalyzeOrdersQuery,
    LoadAnalyzeOrdersQueryVariables
  > &
    (
      | { variables: LoadAnalyzeOrdersQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadAnalyzeOrdersQuery,
    LoadAnalyzeOrdersQueryVariables
  >(LoadAnalyzeOrdersDocument, options);
}
export function useLoadAnalyzeOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadAnalyzeOrdersQuery,
    LoadAnalyzeOrdersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadAnalyzeOrdersQuery,
    LoadAnalyzeOrdersQueryVariables
  >(LoadAnalyzeOrdersDocument, options);
}
export function useLoadAnalyzeOrdersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadAnalyzeOrdersQuery,
        LoadAnalyzeOrdersQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadAnalyzeOrdersQuery,
    LoadAnalyzeOrdersQueryVariables
  >(LoadAnalyzeOrdersDocument, options);
}
export type LoadAnalyzeOrdersQueryHookResult = ReturnType<
  typeof useLoadAnalyzeOrdersQuery
>;
export type LoadAnalyzeOrdersLazyQueryHookResult = ReturnType<
  typeof useLoadAnalyzeOrdersLazyQuery
>;
export type LoadAnalyzeOrdersSuspenseQueryHookResult = ReturnType<
  typeof useLoadAnalyzeOrdersSuspenseQuery
>;
export type LoadAnalyzeOrdersQueryResult = Apollo.QueryResult<
  LoadAnalyzeOrdersQuery,
  LoadAnalyzeOrdersQueryVariables
>;
export const ScheduleGateUsageDocument = gql`
  mutation scheduleGateUsage($input: ScheduleGateUsageInput!) {
    scheduleGateUsage(input: $input) {
      ...GateUsageSchedule
    }
  }
  ${GateUsageScheduleFragmentDoc}
`;
export type ScheduleGateUsageMutationFn = Apollo.MutationFunction<
  ScheduleGateUsageMutation,
  ScheduleGateUsageMutationVariables
>;

/**
 * __useScheduleGateUsageMutation__
 *
 * To run a mutation, you first call `useScheduleGateUsageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScheduleGateUsageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scheduleGateUsageMutation, { data, loading, error }] = useScheduleGateUsageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useScheduleGateUsageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ScheduleGateUsageMutation,
    ScheduleGateUsageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ScheduleGateUsageMutation,
    ScheduleGateUsageMutationVariables
  >(ScheduleGateUsageDocument, options);
}
export type ScheduleGateUsageMutationHookResult = ReturnType<
  typeof useScheduleGateUsageMutation
>;
export type ScheduleGateUsageMutationResult =
  Apollo.MutationResult<ScheduleGateUsageMutation>;
export type ScheduleGateUsageMutationOptions = Apollo.BaseMutationOptions<
  ScheduleGateUsageMutation,
  ScheduleGateUsageMutationVariables
>;
export const AnalyzeSimulationDocument = gql`
  mutation analyzeSimulation($input: AnalyzeSimulationInput!) {
    analyzeSimulation(input: $input) {
      analyzeResult {
        id
        stage
        status
        progress
      }
    }
  }
`;
export type AnalyzeSimulationMutationFn = Apollo.MutationFunction<
  AnalyzeSimulationMutation,
  AnalyzeSimulationMutationVariables
>;

/**
 * __useAnalyzeSimulationMutation__
 *
 * To run a mutation, you first call `useAnalyzeSimulationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnalyzeSimulationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [analyzeSimulationMutation, { data, loading, error }] = useAnalyzeSimulationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAnalyzeSimulationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AnalyzeSimulationMutation,
    AnalyzeSimulationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AnalyzeSimulationMutation,
    AnalyzeSimulationMutationVariables
  >(AnalyzeSimulationDocument, options);
}
export type AnalyzeSimulationMutationHookResult = ReturnType<
  typeof useAnalyzeSimulationMutation
>;
export type AnalyzeSimulationMutationResult =
  Apollo.MutationResult<AnalyzeSimulationMutation>;
export type AnalyzeSimulationMutationOptions = Apollo.BaseMutationOptions<
  AnalyzeSimulationMutation,
  AnalyzeSimulationMutationVariables
>;
export const LoadAnalyzeDocument = gql`
  query loadAnalyze($analyzeId: ID!) {
    analyzeResult(id: $analyzeId) {
      ...AnalyzeResult
    }
  }
  ${AnalyzeResultFragmentDoc}
`;

/**
 * __useLoadAnalyzeQuery__
 *
 * To run a query within a React component, call `useLoadAnalyzeQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadAnalyzeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadAnalyzeQuery({
 *   variables: {
 *      analyzeId: // value for 'analyzeId'
 *   },
 * });
 */
export function useLoadAnalyzeQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadAnalyzeQuery,
    LoadAnalyzeQueryVariables
  > &
    (
      | { variables: LoadAnalyzeQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadAnalyzeQuery, LoadAnalyzeQueryVariables>(
    LoadAnalyzeDocument,
    options,
  );
}
export function useLoadAnalyzeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadAnalyzeQuery,
    LoadAnalyzeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LoadAnalyzeQuery, LoadAnalyzeQueryVariables>(
    LoadAnalyzeDocument,
    options,
  );
}
export function useLoadAnalyzeSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadAnalyzeQuery,
        LoadAnalyzeQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<LoadAnalyzeQuery, LoadAnalyzeQueryVariables>(
    LoadAnalyzeDocument,
    options,
  );
}
export type LoadAnalyzeQueryHookResult = ReturnType<typeof useLoadAnalyzeQuery>;
export type LoadAnalyzeLazyQueryHookResult = ReturnType<
  typeof useLoadAnalyzeLazyQuery
>;
export type LoadAnalyzeSuspenseQueryHookResult = ReturnType<
  typeof useLoadAnalyzeSuspenseQuery
>;
export type LoadAnalyzeQueryResult = Apollo.QueryResult<
  LoadAnalyzeQuery,
  LoadAnalyzeQueryVariables
>;
export const LoadAnalyzedProductByCategoriesDocument = gql`
  query loadAnalyzedProductByCategories(
    $analyzeId: ID!
    $categoryWidths: [Float!]!
  ) {
    analyzeResult(id: $analyzeId) {
      pickedProductsByCategory(
        categories: {
          categoryWidths: $categoryWidths
          categoryType: CUMULATIVE_PERCENT_RANK
        }
      ) {
        ...AnalyzeProductsByCategory
      }
    }
  }
  ${AnalyzeProductsByCategoryFragmentDoc}
`;

/**
 * __useLoadAnalyzedProductByCategoriesQuery__
 *
 * To run a query within a React component, call `useLoadAnalyzedProductByCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadAnalyzedProductByCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadAnalyzedProductByCategoriesQuery({
 *   variables: {
 *      analyzeId: // value for 'analyzeId'
 *      categoryWidths: // value for 'categoryWidths'
 *   },
 * });
 */
export function useLoadAnalyzedProductByCategoriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadAnalyzedProductByCategoriesQuery,
    LoadAnalyzedProductByCategoriesQueryVariables
  > &
    (
      | {
          variables: LoadAnalyzedProductByCategoriesQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadAnalyzedProductByCategoriesQuery,
    LoadAnalyzedProductByCategoriesQueryVariables
  >(LoadAnalyzedProductByCategoriesDocument, options);
}
export function useLoadAnalyzedProductByCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadAnalyzedProductByCategoriesQuery,
    LoadAnalyzedProductByCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadAnalyzedProductByCategoriesQuery,
    LoadAnalyzedProductByCategoriesQueryVariables
  >(LoadAnalyzedProductByCategoriesDocument, options);
}
export function useLoadAnalyzedProductByCategoriesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadAnalyzedProductByCategoriesQuery,
        LoadAnalyzedProductByCategoriesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadAnalyzedProductByCategoriesQuery,
    LoadAnalyzedProductByCategoriesQueryVariables
  >(LoadAnalyzedProductByCategoriesDocument, options);
}
export type LoadAnalyzedProductByCategoriesQueryHookResult = ReturnType<
  typeof useLoadAnalyzedProductByCategoriesQuery
>;
export type LoadAnalyzedProductByCategoriesLazyQueryHookResult = ReturnType<
  typeof useLoadAnalyzedProductByCategoriesLazyQuery
>;
export type LoadAnalyzedProductByCategoriesSuspenseQueryHookResult = ReturnType<
  typeof useLoadAnalyzedProductByCategoriesSuspenseQuery
>;
export type LoadAnalyzedProductByCategoriesQueryResult = Apollo.QueryResult<
  LoadAnalyzedProductByCategoriesQuery,
  LoadAnalyzedProductByCategoriesQueryVariables
>;
export const LoadSimulationAnalyzeListDocument = gql`
  query loadSimulationAnalyzeList($warehouseId: ID!) {
    warehouse(id: $warehouseId) {
      simulations(page: null) {
        content {
          ...SimulationAnalyzeListItem
        }
      }
    }
  }
  ${SimulationAnalyzeListItemFragmentDoc}
`;

/**
 * __useLoadSimulationAnalyzeListQuery__
 *
 * To run a query within a React component, call `useLoadSimulationAnalyzeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadSimulationAnalyzeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadSimulationAnalyzeListQuery({
 *   variables: {
 *      warehouseId: // value for 'warehouseId'
 *   },
 * });
 */
export function useLoadSimulationAnalyzeListQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadSimulationAnalyzeListQuery,
    LoadSimulationAnalyzeListQueryVariables
  > &
    (
      | { variables: LoadSimulationAnalyzeListQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadSimulationAnalyzeListQuery,
    LoadSimulationAnalyzeListQueryVariables
  >(LoadSimulationAnalyzeListDocument, options);
}
export function useLoadSimulationAnalyzeListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadSimulationAnalyzeListQuery,
    LoadSimulationAnalyzeListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadSimulationAnalyzeListQuery,
    LoadSimulationAnalyzeListQueryVariables
  >(LoadSimulationAnalyzeListDocument, options);
}
export function useLoadSimulationAnalyzeListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadSimulationAnalyzeListQuery,
        LoadSimulationAnalyzeListQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadSimulationAnalyzeListQuery,
    LoadSimulationAnalyzeListQueryVariables
  >(LoadSimulationAnalyzeListDocument, options);
}
export type LoadSimulationAnalyzeListQueryHookResult = ReturnType<
  typeof useLoadSimulationAnalyzeListQuery
>;
export type LoadSimulationAnalyzeListLazyQueryHookResult = ReturnType<
  typeof useLoadSimulationAnalyzeListLazyQuery
>;
export type LoadSimulationAnalyzeListSuspenseQueryHookResult = ReturnType<
  typeof useLoadSimulationAnalyzeListSuspenseQuery
>;
export type LoadSimulationAnalyzeListQueryResult = Apollo.QueryResult<
  LoadSimulationAnalyzeListQuery,
  LoadSimulationAnalyzeListQueryVariables
>;
export const LoadAnalyzeToCompareDocument = gql`
  query loadAnalyzeToCompare($analyzeId: ID!) {
    analyzeResult(id: $analyzeId) {
      ...AnalyzeResultToCompare
    }
  }
  ${AnalyzeResultToCompareFragmentDoc}
`;

/**
 * __useLoadAnalyzeToCompareQuery__
 *
 * To run a query within a React component, call `useLoadAnalyzeToCompareQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadAnalyzeToCompareQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadAnalyzeToCompareQuery({
 *   variables: {
 *      analyzeId: // value for 'analyzeId'
 *   },
 * });
 */
export function useLoadAnalyzeToCompareQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadAnalyzeToCompareQuery,
    LoadAnalyzeToCompareQueryVariables
  > &
    (
      | { variables: LoadAnalyzeToCompareQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadAnalyzeToCompareQuery,
    LoadAnalyzeToCompareQueryVariables
  >(LoadAnalyzeToCompareDocument, options);
}
export function useLoadAnalyzeToCompareLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadAnalyzeToCompareQuery,
    LoadAnalyzeToCompareQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadAnalyzeToCompareQuery,
    LoadAnalyzeToCompareQueryVariables
  >(LoadAnalyzeToCompareDocument, options);
}
export function useLoadAnalyzeToCompareSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadAnalyzeToCompareQuery,
        LoadAnalyzeToCompareQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadAnalyzeToCompareQuery,
    LoadAnalyzeToCompareQueryVariables
  >(LoadAnalyzeToCompareDocument, options);
}
export type LoadAnalyzeToCompareQueryHookResult = ReturnType<
  typeof useLoadAnalyzeToCompareQuery
>;
export type LoadAnalyzeToCompareLazyQueryHookResult = ReturnType<
  typeof useLoadAnalyzeToCompareLazyQuery
>;
export type LoadAnalyzeToCompareSuspenseQueryHookResult = ReturnType<
  typeof useLoadAnalyzeToCompareSuspenseQuery
>;
export type LoadAnalyzeToCompareQueryResult = Apollo.QueryResult<
  LoadAnalyzeToCompareQuery,
  LoadAnalyzeToCompareQueryVariables
>;
export const LoadDatasetObjectsDocument = gql`
  query loadDatasetObjects($warehouseId: ID!, $filter: DatasetObjectFilter) {
    warehouse(id: $warehouseId) {
      datasetObjects(page: { limit: null }, filter: $filter) {
        totalCount
        content {
          ...DatasetObject
        }
      }
    }
  }
  ${DatasetObjectFragmentDoc}
`;

/**
 * __useLoadDatasetObjectsQuery__
 *
 * To run a query within a React component, call `useLoadDatasetObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadDatasetObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadDatasetObjectsQuery({
 *   variables: {
 *      warehouseId: // value for 'warehouseId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useLoadDatasetObjectsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadDatasetObjectsQuery,
    LoadDatasetObjectsQueryVariables
  > &
    (
      | { variables: LoadDatasetObjectsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadDatasetObjectsQuery,
    LoadDatasetObjectsQueryVariables
  >(LoadDatasetObjectsDocument, options);
}
export function useLoadDatasetObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadDatasetObjectsQuery,
    LoadDatasetObjectsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadDatasetObjectsQuery,
    LoadDatasetObjectsQueryVariables
  >(LoadDatasetObjectsDocument, options);
}
export function useLoadDatasetObjectsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadDatasetObjectsQuery,
        LoadDatasetObjectsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadDatasetObjectsQuery,
    LoadDatasetObjectsQueryVariables
  >(LoadDatasetObjectsDocument, options);
}
export type LoadDatasetObjectsQueryHookResult = ReturnType<
  typeof useLoadDatasetObjectsQuery
>;
export type LoadDatasetObjectsLazyQueryHookResult = ReturnType<
  typeof useLoadDatasetObjectsLazyQuery
>;
export type LoadDatasetObjectsSuspenseQueryHookResult = ReturnType<
  typeof useLoadDatasetObjectsSuspenseQuery
>;
export type LoadDatasetObjectsQueryResult = Apollo.QueryResult<
  LoadDatasetObjectsQuery,
  LoadDatasetObjectsQueryVariables
>;
export const QueryDatasetDocument = gql`
  query queryDataset($warehouseId: ID!, $query: String!, $params: [JSON!]) {
    warehouse(id: $warehouseId) {
      datasetObjectQuery(query: $query, parameters: $params) {
        ...DatasetObjectQueryResponse
      }
    }
  }
  ${DatasetObjectQueryResponseFragmentDoc}
`;

/**
 * __useQueryDatasetQuery__
 *
 * To run a query within a React component, call `useQueryDatasetQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryDatasetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryDatasetQuery({
 *   variables: {
 *      warehouseId: // value for 'warehouseId'
 *      query: // value for 'query'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useQueryDatasetQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryDatasetQuery,
    QueryDatasetQueryVariables
  > &
    (
      | { variables: QueryDatasetQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueryDatasetQuery, QueryDatasetQueryVariables>(
    QueryDatasetDocument,
    options,
  );
}
export function useQueryDatasetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryDatasetQuery,
    QueryDatasetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueryDatasetQuery, QueryDatasetQueryVariables>(
    QueryDatasetDocument,
    options,
  );
}
export function useQueryDatasetSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        QueryDatasetQuery,
        QueryDatasetQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<QueryDatasetQuery, QueryDatasetQueryVariables>(
    QueryDatasetDocument,
    options,
  );
}
export type QueryDatasetQueryHookResult = ReturnType<
  typeof useQueryDatasetQuery
>;
export type QueryDatasetLazyQueryHookResult = ReturnType<
  typeof useQueryDatasetLazyQuery
>;
export type QueryDatasetSuspenseQueryHookResult = ReturnType<
  typeof useQueryDatasetSuspenseQuery
>;
export type QueryDatasetQueryResult = Apollo.QueryResult<
  QueryDatasetQuery,
  QueryDatasetQueryVariables
>;
export const LoadDatasetObjectDocument = gql`
  query loadDatasetObject($id: String!) {
    datasetObject(id: $id) {
      ...DatasetObject
    }
  }
  ${DatasetObjectFragmentDoc}
`;

/**
 * __useLoadDatasetObjectQuery__
 *
 * To run a query within a React component, call `useLoadDatasetObjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadDatasetObjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadDatasetObjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoadDatasetObjectQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadDatasetObjectQuery,
    LoadDatasetObjectQueryVariables
  > &
    (
      | { variables: LoadDatasetObjectQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadDatasetObjectQuery,
    LoadDatasetObjectQueryVariables
  >(LoadDatasetObjectDocument, options);
}
export function useLoadDatasetObjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadDatasetObjectQuery,
    LoadDatasetObjectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadDatasetObjectQuery,
    LoadDatasetObjectQueryVariables
  >(LoadDatasetObjectDocument, options);
}
export function useLoadDatasetObjectSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadDatasetObjectQuery,
        LoadDatasetObjectQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadDatasetObjectQuery,
    LoadDatasetObjectQueryVariables
  >(LoadDatasetObjectDocument, options);
}
export type LoadDatasetObjectQueryHookResult = ReturnType<
  typeof useLoadDatasetObjectQuery
>;
export type LoadDatasetObjectLazyQueryHookResult = ReturnType<
  typeof useLoadDatasetObjectLazyQuery
>;
export type LoadDatasetObjectSuspenseQueryHookResult = ReturnType<
  typeof useLoadDatasetObjectSuspenseQuery
>;
export type LoadDatasetObjectQueryResult = Apollo.QueryResult<
  LoadDatasetObjectQuery,
  LoadDatasetObjectQueryVariables
>;
export const UpdateDatasetObjectDocument = gql`
  mutation updateDatasetObject($input: UpdateDatasetObjectInput!) {
    updateDatasetObject(input: $input) {
      ...DatasetObject
    }
  }
  ${DatasetObjectFragmentDoc}
`;
export type UpdateDatasetObjectMutationFn = Apollo.MutationFunction<
  UpdateDatasetObjectMutation,
  UpdateDatasetObjectMutationVariables
>;

/**
 * __useUpdateDatasetObjectMutation__
 *
 * To run a mutation, you first call `useUpdateDatasetObjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDatasetObjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDatasetObjectMutation, { data, loading, error }] = useUpdateDatasetObjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDatasetObjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDatasetObjectMutation,
    UpdateDatasetObjectMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateDatasetObjectMutation,
    UpdateDatasetObjectMutationVariables
  >(UpdateDatasetObjectDocument, options);
}
export type UpdateDatasetObjectMutationHookResult = ReturnType<
  typeof useUpdateDatasetObjectMutation
>;
export type UpdateDatasetObjectMutationResult =
  Apollo.MutationResult<UpdateDatasetObjectMutation>;
export type UpdateDatasetObjectMutationOptions = Apollo.BaseMutationOptions<
  UpdateDatasetObjectMutation,
  UpdateDatasetObjectMutationVariables
>;
export const RemoveDatasetObjectDocument = gql`
  mutation removeDatasetObject($id: ID!) {
    removeDatasetObject(id: $id)
  }
`;
export type RemoveDatasetObjectMutationFn = Apollo.MutationFunction<
  RemoveDatasetObjectMutation,
  RemoveDatasetObjectMutationVariables
>;

/**
 * __useRemoveDatasetObjectMutation__
 *
 * To run a mutation, you first call `useRemoveDatasetObjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDatasetObjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDatasetObjectMutation, { data, loading, error }] = useRemoveDatasetObjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveDatasetObjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveDatasetObjectMutation,
    RemoveDatasetObjectMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveDatasetObjectMutation,
    RemoveDatasetObjectMutationVariables
  >(RemoveDatasetObjectDocument, options);
}
export type RemoveDatasetObjectMutationHookResult = ReturnType<
  typeof useRemoveDatasetObjectMutation
>;
export type RemoveDatasetObjectMutationResult =
  Apollo.MutationResult<RemoveDatasetObjectMutation>;
export type RemoveDatasetObjectMutationOptions = Apollo.BaseMutationOptions<
  RemoveDatasetObjectMutation,
  RemoveDatasetObjectMutationVariables
>;
export const LoadStackingPolicyStatsDocument = gql`
  query loadStackingPolicyStats($input: ApplyAssignmentItemFilterInput!) {
    countAssignmentItemsByMatchingFilter(input: $input) {
      ...StackingPolicyStats
    }
  }
  ${StackingPolicyStatsFragmentDoc}
`;

/**
 * __useLoadStackingPolicyStatsQuery__
 *
 * To run a query within a React component, call `useLoadStackingPolicyStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadStackingPolicyStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadStackingPolicyStatsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoadStackingPolicyStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadStackingPolicyStatsQuery,
    LoadStackingPolicyStatsQueryVariables
  > &
    (
      | { variables: LoadStackingPolicyStatsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadStackingPolicyStatsQuery,
    LoadStackingPolicyStatsQueryVariables
  >(LoadStackingPolicyStatsDocument, options);
}
export function useLoadStackingPolicyStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadStackingPolicyStatsQuery,
    LoadStackingPolicyStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadStackingPolicyStatsQuery,
    LoadStackingPolicyStatsQueryVariables
  >(LoadStackingPolicyStatsDocument, options);
}
export function useLoadStackingPolicyStatsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadStackingPolicyStatsQuery,
        LoadStackingPolicyStatsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadStackingPolicyStatsQuery,
    LoadStackingPolicyStatsQueryVariables
  >(LoadStackingPolicyStatsDocument, options);
}
export type LoadStackingPolicyStatsQueryHookResult = ReturnType<
  typeof useLoadStackingPolicyStatsQuery
>;
export type LoadStackingPolicyStatsLazyQueryHookResult = ReturnType<
  typeof useLoadStackingPolicyStatsLazyQuery
>;
export type LoadStackingPolicyStatsSuspenseQueryHookResult = ReturnType<
  typeof useLoadStackingPolicyStatsSuspenseQuery
>;
export type LoadStackingPolicyStatsQueryResult = Apollo.QueryResult<
  LoadStackingPolicyStatsQuery,
  LoadStackingPolicyStatsQueryVariables
>;
export const LoadStackingPolicyItemsDocument = gql`
  query loadStackingPolicyItems(
    $input: ApplyAssignmentItemFilterInput!
    $filter: AssignmentItemsExtendedFilter
  ) {
    applyAssignmentItemFilter(input: $input, filter: $filter, page: null) {
      totalCount
      content {
        ...StackingPolicyItem
      }
    }
  }
  ${StackingPolicyItemFragmentDoc}
`;

/**
 * __useLoadStackingPolicyItemsQuery__
 *
 * To run a query within a React component, call `useLoadStackingPolicyItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadStackingPolicyItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadStackingPolicyItemsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useLoadStackingPolicyItemsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadStackingPolicyItemsQuery,
    LoadStackingPolicyItemsQueryVariables
  > &
    (
      | { variables: LoadStackingPolicyItemsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadStackingPolicyItemsQuery,
    LoadStackingPolicyItemsQueryVariables
  >(LoadStackingPolicyItemsDocument, options);
}
export function useLoadStackingPolicyItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadStackingPolicyItemsQuery,
    LoadStackingPolicyItemsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadStackingPolicyItemsQuery,
    LoadStackingPolicyItemsQueryVariables
  >(LoadStackingPolicyItemsDocument, options);
}
export function useLoadStackingPolicyItemsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadStackingPolicyItemsQuery,
        LoadStackingPolicyItemsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadStackingPolicyItemsQuery,
    LoadStackingPolicyItemsQueryVariables
  >(LoadStackingPolicyItemsDocument, options);
}
export type LoadStackingPolicyItemsQueryHookResult = ReturnType<
  typeof useLoadStackingPolicyItemsQuery
>;
export type LoadStackingPolicyItemsLazyQueryHookResult = ReturnType<
  typeof useLoadStackingPolicyItemsLazyQuery
>;
export type LoadStackingPolicyItemsSuspenseQueryHookResult = ReturnType<
  typeof useLoadStackingPolicyItemsSuspenseQuery
>;
export type LoadStackingPolicyItemsQueryResult = Apollo.QueryResult<
  LoadStackingPolicyItemsQuery,
  LoadStackingPolicyItemsQueryVariables
>;
export const LoadLocationsByFilterDocument = gql`
  query loadLocationsByFilter(
    $layoutId: ID!
    $input: GroupLocationsByFilterInput!
    $filter: LayoutLocationFilter
  ) {
    layout(id: $layoutId) {
      locationsWithMatchingFilters(
        input: $input
        page: { limit: null }
        filter: $filter
      ) {
        totalCount
        content {
          ...LayoutLocationWithMatchingFilters
        }
      }
    }
  }
  ${LayoutLocationWithMatchingFiltersFragmentDoc}
`;

/**
 * __useLoadLocationsByFilterQuery__
 *
 * To run a query within a React component, call `useLoadLocationsByFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadLocationsByFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadLocationsByFilterQuery({
 *   variables: {
 *      layoutId: // value for 'layoutId'
 *      input: // value for 'input'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useLoadLocationsByFilterQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadLocationsByFilterQuery,
    LoadLocationsByFilterQueryVariables
  > &
    (
      | { variables: LoadLocationsByFilterQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadLocationsByFilterQuery,
    LoadLocationsByFilterQueryVariables
  >(LoadLocationsByFilterDocument, options);
}
export function useLoadLocationsByFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadLocationsByFilterQuery,
    LoadLocationsByFilterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadLocationsByFilterQuery,
    LoadLocationsByFilterQueryVariables
  >(LoadLocationsByFilterDocument, options);
}
export function useLoadLocationsByFilterSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadLocationsByFilterQuery,
        LoadLocationsByFilterQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadLocationsByFilterQuery,
    LoadLocationsByFilterQueryVariables
  >(LoadLocationsByFilterDocument, options);
}
export type LoadLocationsByFilterQueryHookResult = ReturnType<
  typeof useLoadLocationsByFilterQuery
>;
export type LoadLocationsByFilterLazyQueryHookResult = ReturnType<
  typeof useLoadLocationsByFilterLazyQuery
>;
export type LoadLocationsByFilterSuspenseQueryHookResult = ReturnType<
  typeof useLoadLocationsByFilterSuspenseQuery
>;
export type LoadLocationsByFilterQueryResult = Apollo.QueryResult<
  LoadLocationsByFilterQuery,
  LoadLocationsByFilterQueryVariables
>;
export const LoadLocationsCountByFilterDocument = gql`
  query loadLocationsCountByFilter(
    $layoutId: ID!
    $filter: LocationFilterUnionInput
  ) {
    layout(id: $layoutId) {
      locationGroupsByFilter(
        input: { filters: [$filter] }
        page: { limit: null }
      ) {
        ...GroupLocationsByFilterResult
      }
    }
  }
  ${GroupLocationsByFilterResultFragmentDoc}
`;

/**
 * __useLoadLocationsCountByFilterQuery__
 *
 * To run a query within a React component, call `useLoadLocationsCountByFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadLocationsCountByFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadLocationsCountByFilterQuery({
 *   variables: {
 *      layoutId: // value for 'layoutId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useLoadLocationsCountByFilterQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadLocationsCountByFilterQuery,
    LoadLocationsCountByFilterQueryVariables
  > &
    (
      | { variables: LoadLocationsCountByFilterQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadLocationsCountByFilterQuery,
    LoadLocationsCountByFilterQueryVariables
  >(LoadLocationsCountByFilterDocument, options);
}
export function useLoadLocationsCountByFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadLocationsCountByFilterQuery,
    LoadLocationsCountByFilterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadLocationsCountByFilterQuery,
    LoadLocationsCountByFilterQueryVariables
  >(LoadLocationsCountByFilterDocument, options);
}
export function useLoadLocationsCountByFilterSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadLocationsCountByFilterQuery,
        LoadLocationsCountByFilterQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadLocationsCountByFilterQuery,
    LoadLocationsCountByFilterQueryVariables
  >(LoadLocationsCountByFilterDocument, options);
}
export type LoadLocationsCountByFilterQueryHookResult = ReturnType<
  typeof useLoadLocationsCountByFilterQuery
>;
export type LoadLocationsCountByFilterLazyQueryHookResult = ReturnType<
  typeof useLoadLocationsCountByFilterLazyQuery
>;
export type LoadLocationsCountByFilterSuspenseQueryHookResult = ReturnType<
  typeof useLoadLocationsCountByFilterSuspenseQuery
>;
export type LoadLocationsCountByFilterQueryResult = Apollo.QueryResult<
  LoadLocationsCountByFilterQuery,
  LoadLocationsCountByFilterQueryVariables
>;
export const FindOrderLineFilterValuesDocument = gql`
  query findOrderLineFilterValues(
    $input: FindSimulationOrderLineFilterValuesInput!
    $page: SkipLimitPageSpec
  ) {
    findSimulationOrderLineFilterValues(input: $input, page: $page) {
      totalCount
      content {
        ...FilterValue
      }
      range {
        ...NumberFilterRange
      }
    }
  }
  ${FilterValueFragmentDoc}
  ${NumberFilterRangeFragmentDoc}
`;

/**
 * __useFindOrderLineFilterValuesQuery__
 *
 * To run a query within a React component, call `useFindOrderLineFilterValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindOrderLineFilterValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindOrderLineFilterValuesQuery({
 *   variables: {
 *      input: // value for 'input'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useFindOrderLineFilterValuesQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindOrderLineFilterValuesQuery,
    FindOrderLineFilterValuesQueryVariables
  > &
    (
      | { variables: FindOrderLineFilterValuesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindOrderLineFilterValuesQuery,
    FindOrderLineFilterValuesQueryVariables
  >(FindOrderLineFilterValuesDocument, options);
}
export function useFindOrderLineFilterValuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindOrderLineFilterValuesQuery,
    FindOrderLineFilterValuesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindOrderLineFilterValuesQuery,
    FindOrderLineFilterValuesQueryVariables
  >(FindOrderLineFilterValuesDocument, options);
}
export function useFindOrderLineFilterValuesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindOrderLineFilterValuesQuery,
        FindOrderLineFilterValuesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FindOrderLineFilterValuesQuery,
    FindOrderLineFilterValuesQueryVariables
  >(FindOrderLineFilterValuesDocument, options);
}
export type FindOrderLineFilterValuesQueryHookResult = ReturnType<
  typeof useFindOrderLineFilterValuesQuery
>;
export type FindOrderLineFilterValuesLazyQueryHookResult = ReturnType<
  typeof useFindOrderLineFilterValuesLazyQuery
>;
export type FindOrderLineFilterValuesSuspenseQueryHookResult = ReturnType<
  typeof useFindOrderLineFilterValuesSuspenseQuery
>;
export type FindOrderLineFilterValuesQueryResult = Apollo.QueryResult<
  FindOrderLineFilterValuesQuery,
  FindOrderLineFilterValuesQueryVariables
>;
export const UpdateLayoutDocument = gql`
  mutation updateLayout($layoutInput: UpdateLayoutInput!) {
    updateLayout(input: $layoutInput) {
      id
      description
    }
  }
`;
export type UpdateLayoutMutationFn = Apollo.MutationFunction<
  UpdateLayoutMutation,
  UpdateLayoutMutationVariables
>;

/**
 * __useUpdateLayoutMutation__
 *
 * To run a mutation, you first call `useUpdateLayoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLayoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLayoutMutation, { data, loading, error }] = useUpdateLayoutMutation({
 *   variables: {
 *      layoutInput: // value for 'layoutInput'
 *   },
 * });
 */
export function useUpdateLayoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLayoutMutation,
    UpdateLayoutMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateLayoutMutation,
    UpdateLayoutMutationVariables
  >(UpdateLayoutDocument, options);
}
export type UpdateLayoutMutationHookResult = ReturnType<
  typeof useUpdateLayoutMutation
>;
export type UpdateLayoutMutationResult =
  Apollo.MutationResult<UpdateLayoutMutation>;
export type UpdateLayoutMutationOptions = Apollo.BaseMutationOptions<
  UpdateLayoutMutation,
  UpdateLayoutMutationVariables
>;
export const RemoveLayoutDocument = gql`
  mutation removeLayout($layoutId: ID!) {
    removeLayout(id: $layoutId)
  }
`;
export type RemoveLayoutMutationFn = Apollo.MutationFunction<
  RemoveLayoutMutation,
  RemoveLayoutMutationVariables
>;

/**
 * __useRemoveLayoutMutation__
 *
 * To run a mutation, you first call `useRemoveLayoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveLayoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeLayoutMutation, { data, loading, error }] = useRemoveLayoutMutation({
 *   variables: {
 *      layoutId: // value for 'layoutId'
 *   },
 * });
 */
export function useRemoveLayoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveLayoutMutation,
    RemoveLayoutMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveLayoutMutation,
    RemoveLayoutMutationVariables
  >(RemoveLayoutDocument, options);
}
export type RemoveLayoutMutationHookResult = ReturnType<
  typeof useRemoveLayoutMutation
>;
export type RemoveLayoutMutationResult =
  Apollo.MutationResult<RemoveLayoutMutation>;
export type RemoveLayoutMutationOptions = Apollo.BaseMutationOptions<
  RemoveLayoutMutation,
  RemoveLayoutMutationVariables
>;
export const LoadLayoutViewDocument = gql`
  query loadLayoutView($id: ID!) {
    layout(id: $id) {
      ...LayoutView
    }
  }
  ${LayoutViewFragmentDoc}
`;

/**
 * __useLoadLayoutViewQuery__
 *
 * To run a query within a React component, call `useLoadLayoutViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadLayoutViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadLayoutViewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoadLayoutViewQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadLayoutViewQuery,
    LoadLayoutViewQueryVariables
  > &
    (
      | { variables: LoadLayoutViewQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadLayoutViewQuery, LoadLayoutViewQueryVariables>(
    LoadLayoutViewDocument,
    options,
  );
}
export function useLoadLayoutViewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadLayoutViewQuery,
    LoadLayoutViewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LoadLayoutViewQuery, LoadLayoutViewQueryVariables>(
    LoadLayoutViewDocument,
    options,
  );
}
export function useLoadLayoutViewSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadLayoutViewQuery,
        LoadLayoutViewQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadLayoutViewQuery,
    LoadLayoutViewQueryVariables
  >(LoadLayoutViewDocument, options);
}
export type LoadLayoutViewQueryHookResult = ReturnType<
  typeof useLoadLayoutViewQuery
>;
export type LoadLayoutViewLazyQueryHookResult = ReturnType<
  typeof useLoadLayoutViewLazyQuery
>;
export type LoadLayoutViewSuspenseQueryHookResult = ReturnType<
  typeof useLoadLayoutViewSuspenseQuery
>;
export type LoadLayoutViewQueryResult = Apollo.QueryResult<
  LoadLayoutViewQuery,
  LoadLayoutViewQueryVariables
>;
export const LoadLayoutLevelLocationsDocument = gql`
  query loadLayoutLevelLocations($id: ID!, $planeId: ID!, $level: Float!) {
    layout(id: $id) {
      locations(
        filter: {
          planeId: [$planeId]
          locationLevel: { value: $level, type: EQ }
        }
        page: null
        sortBy: null
      ) {
        content {
          ...LayoutLevelLocation
        }
      }
    }
  }
  ${LayoutLevelLocationFragmentDoc}
`;

/**
 * __useLoadLayoutLevelLocationsQuery__
 *
 * To run a query within a React component, call `useLoadLayoutLevelLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadLayoutLevelLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadLayoutLevelLocationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      planeId: // value for 'planeId'
 *      level: // value for 'level'
 *   },
 * });
 */
export function useLoadLayoutLevelLocationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadLayoutLevelLocationsQuery,
    LoadLayoutLevelLocationsQueryVariables
  > &
    (
      | { variables: LoadLayoutLevelLocationsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadLayoutLevelLocationsQuery,
    LoadLayoutLevelLocationsQueryVariables
  >(LoadLayoutLevelLocationsDocument, options);
}
export function useLoadLayoutLevelLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadLayoutLevelLocationsQuery,
    LoadLayoutLevelLocationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadLayoutLevelLocationsQuery,
    LoadLayoutLevelLocationsQueryVariables
  >(LoadLayoutLevelLocationsDocument, options);
}
export function useLoadLayoutLevelLocationsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadLayoutLevelLocationsQuery,
        LoadLayoutLevelLocationsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadLayoutLevelLocationsQuery,
    LoadLayoutLevelLocationsQueryVariables
  >(LoadLayoutLevelLocationsDocument, options);
}
export type LoadLayoutLevelLocationsQueryHookResult = ReturnType<
  typeof useLoadLayoutLevelLocationsQuery
>;
export type LoadLayoutLevelLocationsLazyQueryHookResult = ReturnType<
  typeof useLoadLayoutLevelLocationsLazyQuery
>;
export type LoadLayoutLevelLocationsSuspenseQueryHookResult = ReturnType<
  typeof useLoadLayoutLevelLocationsSuspenseQuery
>;
export type LoadLayoutLevelLocationsQueryResult = Apollo.QueryResult<
  LoadLayoutLevelLocationsQuery,
  LoadLayoutLevelLocationsQueryVariables
>;
export const LoadLayoutBayLocationsDocument = gql`
  query loadLayoutBayLocations($id: ID!, $bayId: ID!) {
    layout(id: $id) {
      locations(filter: { bayId: [$bayId] }, page: null, sortBy: null) {
        content {
          ...LayoutBayLocation
        }
      }
    }
  }
  ${LayoutBayLocationFragmentDoc}
`;

/**
 * __useLoadLayoutBayLocationsQuery__
 *
 * To run a query within a React component, call `useLoadLayoutBayLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadLayoutBayLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadLayoutBayLocationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      bayId: // value for 'bayId'
 *   },
 * });
 */
export function useLoadLayoutBayLocationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadLayoutBayLocationsQuery,
    LoadLayoutBayLocationsQueryVariables
  > &
    (
      | { variables: LoadLayoutBayLocationsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadLayoutBayLocationsQuery,
    LoadLayoutBayLocationsQueryVariables
  >(LoadLayoutBayLocationsDocument, options);
}
export function useLoadLayoutBayLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadLayoutBayLocationsQuery,
    LoadLayoutBayLocationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadLayoutBayLocationsQuery,
    LoadLayoutBayLocationsQueryVariables
  >(LoadLayoutBayLocationsDocument, options);
}
export function useLoadLayoutBayLocationsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadLayoutBayLocationsQuery,
        LoadLayoutBayLocationsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadLayoutBayLocationsQuery,
    LoadLayoutBayLocationsQueryVariables
  >(LoadLayoutBayLocationsDocument, options);
}
export type LoadLayoutBayLocationsQueryHookResult = ReturnType<
  typeof useLoadLayoutBayLocationsQuery
>;
export type LoadLayoutBayLocationsLazyQueryHookResult = ReturnType<
  typeof useLoadLayoutBayLocationsLazyQuery
>;
export type LoadLayoutBayLocationsSuspenseQueryHookResult = ReturnType<
  typeof useLoadLayoutBayLocationsSuspenseQuery
>;
export type LoadLayoutBayLocationsQueryResult = Apollo.QueryResult<
  LoadLayoutBayLocationsQuery,
  LoadLayoutBayLocationsQueryVariables
>;
export const LoadAllLayoutsMetaDocument = gql`
  query loadAllLayoutsMeta($warehouseId: ID!) {
    warehouse(id: $warehouseId) {
      layouts(page: { limit: null }) {
        content {
          ...LayoutMeta
        }
      }
    }
  }
  ${LayoutMetaFragmentDoc}
`;

/**
 * __useLoadAllLayoutsMetaQuery__
 *
 * To run a query within a React component, call `useLoadAllLayoutsMetaQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadAllLayoutsMetaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadAllLayoutsMetaQuery({
 *   variables: {
 *      warehouseId: // value for 'warehouseId'
 *   },
 * });
 */
export function useLoadAllLayoutsMetaQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadAllLayoutsMetaQuery,
    LoadAllLayoutsMetaQueryVariables
  > &
    (
      | { variables: LoadAllLayoutsMetaQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadAllLayoutsMetaQuery,
    LoadAllLayoutsMetaQueryVariables
  >(LoadAllLayoutsMetaDocument, options);
}
export function useLoadAllLayoutsMetaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadAllLayoutsMetaQuery,
    LoadAllLayoutsMetaQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadAllLayoutsMetaQuery,
    LoadAllLayoutsMetaQueryVariables
  >(LoadAllLayoutsMetaDocument, options);
}
export function useLoadAllLayoutsMetaSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadAllLayoutsMetaQuery,
        LoadAllLayoutsMetaQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadAllLayoutsMetaQuery,
    LoadAllLayoutsMetaQueryVariables
  >(LoadAllLayoutsMetaDocument, options);
}
export type LoadAllLayoutsMetaQueryHookResult = ReturnType<
  typeof useLoadAllLayoutsMetaQuery
>;
export type LoadAllLayoutsMetaLazyQueryHookResult = ReturnType<
  typeof useLoadAllLayoutsMetaLazyQuery
>;
export type LoadAllLayoutsMetaSuspenseQueryHookResult = ReturnType<
  typeof useLoadAllLayoutsMetaSuspenseQuery
>;
export type LoadAllLayoutsMetaQueryResult = Apollo.QueryResult<
  LoadAllLayoutsMetaQuery,
  LoadAllLayoutsMetaQueryVariables
>;
export const LoadLayoutsSummaryDocument = gql`
  query loadLayoutsSummary($layoutId: ID!) {
    layout(id: $layoutId) {
      ...LayoutMeta
      summary {
        ...LayoutSummary
      }
    }
  }
  ${LayoutMetaFragmentDoc}
  ${LayoutSummaryFragmentDoc}
`;

/**
 * __useLoadLayoutsSummaryQuery__
 *
 * To run a query within a React component, call `useLoadLayoutsSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadLayoutsSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadLayoutsSummaryQuery({
 *   variables: {
 *      layoutId: // value for 'layoutId'
 *   },
 * });
 */
export function useLoadLayoutsSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadLayoutsSummaryQuery,
    LoadLayoutsSummaryQueryVariables
  > &
    (
      | { variables: LoadLayoutsSummaryQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadLayoutsSummaryQuery,
    LoadLayoutsSummaryQueryVariables
  >(LoadLayoutsSummaryDocument, options);
}
export function useLoadLayoutsSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadLayoutsSummaryQuery,
    LoadLayoutsSummaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadLayoutsSummaryQuery,
    LoadLayoutsSummaryQueryVariables
  >(LoadLayoutsSummaryDocument, options);
}
export function useLoadLayoutsSummarySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadLayoutsSummaryQuery,
        LoadLayoutsSummaryQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadLayoutsSummaryQuery,
    LoadLayoutsSummaryQueryVariables
  >(LoadLayoutsSummaryDocument, options);
}
export type LoadLayoutsSummaryQueryHookResult = ReturnType<
  typeof useLoadLayoutsSummaryQuery
>;
export type LoadLayoutsSummaryLazyQueryHookResult = ReturnType<
  typeof useLoadLayoutsSummaryLazyQuery
>;
export type LoadLayoutsSummarySuspenseQueryHookResult = ReturnType<
  typeof useLoadLayoutsSummarySuspenseQuery
>;
export type LoadLayoutsSummaryQueryResult = Apollo.QueryResult<
  LoadLayoutsSummaryQuery,
  LoadLayoutsSummaryQueryVariables
>;
export const LoadLayoutMetaDocument = gql`
  query loadLayoutMeta($layoutId: ID!) {
    layout(id: $layoutId) {
      ...LayoutMeta
    }
  }
  ${LayoutMetaFragmentDoc}
`;

/**
 * __useLoadLayoutMetaQuery__
 *
 * To run a query within a React component, call `useLoadLayoutMetaQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadLayoutMetaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadLayoutMetaQuery({
 *   variables: {
 *      layoutId: // value for 'layoutId'
 *   },
 * });
 */
export function useLoadLayoutMetaQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadLayoutMetaQuery,
    LoadLayoutMetaQueryVariables
  > &
    (
      | { variables: LoadLayoutMetaQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadLayoutMetaQuery, LoadLayoutMetaQueryVariables>(
    LoadLayoutMetaDocument,
    options,
  );
}
export function useLoadLayoutMetaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadLayoutMetaQuery,
    LoadLayoutMetaQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LoadLayoutMetaQuery, LoadLayoutMetaQueryVariables>(
    LoadLayoutMetaDocument,
    options,
  );
}
export function useLoadLayoutMetaSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadLayoutMetaQuery,
        LoadLayoutMetaQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadLayoutMetaQuery,
    LoadLayoutMetaQueryVariables
  >(LoadLayoutMetaDocument, options);
}
export type LoadLayoutMetaQueryHookResult = ReturnType<
  typeof useLoadLayoutMetaQuery
>;
export type LoadLayoutMetaLazyQueryHookResult = ReturnType<
  typeof useLoadLayoutMetaLazyQuery
>;
export type LoadLayoutMetaSuspenseQueryHookResult = ReturnType<
  typeof useLoadLayoutMetaSuspenseQuery
>;
export type LoadLayoutMetaQueryResult = Apollo.QueryResult<
  LoadLayoutMetaQuery,
  LoadLayoutMetaQueryVariables
>;
export const LoadLayoutDataDocument = gql`
  query loadLayoutData(
    $layoutId: ID!
    $page: SkipLimitPageSpec
    $filter: LayoutLocationsSearchFilter
    $sortBy: [LayoutLocationsSortBy!]
  ) {
    layout(id: $layoutId) {
      locations(page: $page, filter: $filter, sortBy: $sortBy) {
        totalCount
        content {
          ...LayoutLocationData
        }
      }
    }
  }
  ${LayoutLocationDataFragmentDoc}
`;

/**
 * __useLoadLayoutDataQuery__
 *
 * To run a query within a React component, call `useLoadLayoutDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadLayoutDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadLayoutDataQuery({
 *   variables: {
 *      layoutId: // value for 'layoutId'
 *      page: // value for 'page'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useLoadLayoutDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadLayoutDataQuery,
    LoadLayoutDataQueryVariables
  > &
    (
      | { variables: LoadLayoutDataQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadLayoutDataQuery, LoadLayoutDataQueryVariables>(
    LoadLayoutDataDocument,
    options,
  );
}
export function useLoadLayoutDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadLayoutDataQuery,
    LoadLayoutDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LoadLayoutDataQuery, LoadLayoutDataQueryVariables>(
    LoadLayoutDataDocument,
    options,
  );
}
export function useLoadLayoutDataSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadLayoutDataQuery,
        LoadLayoutDataQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadLayoutDataQuery,
    LoadLayoutDataQueryVariables
  >(LoadLayoutDataDocument, options);
}
export type LoadLayoutDataQueryHookResult = ReturnType<
  typeof useLoadLayoutDataQuery
>;
export type LoadLayoutDataLazyQueryHookResult = ReturnType<
  typeof useLoadLayoutDataLazyQuery
>;
export type LoadLayoutDataSuspenseQueryHookResult = ReturnType<
  typeof useLoadLayoutDataSuspenseQuery
>;
export type LoadLayoutDataQueryResult = Apollo.QueryResult<
  LoadLayoutDataQuery,
  LoadLayoutDataQueryVariables
>;
export const LoadPlaneAislesDocument = gql`
  query loadPlaneAisles($layoutId: ID!, $planeId: [ID!]) {
    layout(id: $layoutId) {
      features(filter: { planeId: $planeId, type: AISLE }) {
        ...AisleFeature
      }
    }
  }
  ${AisleFeatureFragmentDoc}
`;

/**
 * __useLoadPlaneAislesQuery__
 *
 * To run a query within a React component, call `useLoadPlaneAislesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadPlaneAislesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadPlaneAislesQuery({
 *   variables: {
 *      layoutId: // value for 'layoutId'
 *      planeId: // value for 'planeId'
 *   },
 * });
 */
export function useLoadPlaneAislesQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadPlaneAislesQuery,
    LoadPlaneAislesQueryVariables
  > &
    (
      | { variables: LoadPlaneAislesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadPlaneAislesQuery, LoadPlaneAislesQueryVariables>(
    LoadPlaneAislesDocument,
    options,
  );
}
export function useLoadPlaneAislesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadPlaneAislesQuery,
    LoadPlaneAislesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadPlaneAislesQuery,
    LoadPlaneAislesQueryVariables
  >(LoadPlaneAislesDocument, options);
}
export function useLoadPlaneAislesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadPlaneAislesQuery,
        LoadPlaneAislesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadPlaneAislesQuery,
    LoadPlaneAislesQueryVariables
  >(LoadPlaneAislesDocument, options);
}
export type LoadPlaneAislesQueryHookResult = ReturnType<
  typeof useLoadPlaneAislesQuery
>;
export type LoadPlaneAislesLazyQueryHookResult = ReturnType<
  typeof useLoadPlaneAislesLazyQuery
>;
export type LoadPlaneAislesSuspenseQueryHookResult = ReturnType<
  typeof useLoadPlaneAislesSuspenseQuery
>;
export type LoadPlaneAislesQueryResult = Apollo.QueryResult<
  LoadPlaneAislesQuery,
  LoadPlaneAislesQueryVariables
>;
export const LoadLayoutAislesMetaDocument = gql`
  query loadLayoutAislesMeta($layoutId: ID!) {
    layout(id: $layoutId) {
      features(filter: { type: AISLE }) {
        ...AisleFeatureMeta
      }
    }
  }
  ${AisleFeatureMetaFragmentDoc}
`;

/**
 * __useLoadLayoutAislesMetaQuery__
 *
 * To run a query within a React component, call `useLoadLayoutAislesMetaQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadLayoutAislesMetaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadLayoutAislesMetaQuery({
 *   variables: {
 *      layoutId: // value for 'layoutId'
 *   },
 * });
 */
export function useLoadLayoutAislesMetaQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadLayoutAislesMetaQuery,
    LoadLayoutAislesMetaQueryVariables
  > &
    (
      | { variables: LoadLayoutAislesMetaQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadLayoutAislesMetaQuery,
    LoadLayoutAislesMetaQueryVariables
  >(LoadLayoutAislesMetaDocument, options);
}
export function useLoadLayoutAislesMetaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadLayoutAislesMetaQuery,
    LoadLayoutAislesMetaQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadLayoutAislesMetaQuery,
    LoadLayoutAislesMetaQueryVariables
  >(LoadLayoutAislesMetaDocument, options);
}
export function useLoadLayoutAislesMetaSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadLayoutAislesMetaQuery,
        LoadLayoutAislesMetaQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadLayoutAislesMetaQuery,
    LoadLayoutAislesMetaQueryVariables
  >(LoadLayoutAislesMetaDocument, options);
}
export type LoadLayoutAislesMetaQueryHookResult = ReturnType<
  typeof useLoadLayoutAislesMetaQuery
>;
export type LoadLayoutAislesMetaLazyQueryHookResult = ReturnType<
  typeof useLoadLayoutAislesMetaLazyQuery
>;
export type LoadLayoutAislesMetaSuspenseQueryHookResult = ReturnType<
  typeof useLoadLayoutAislesMetaSuspenseQuery
>;
export type LoadLayoutAislesMetaQueryResult = Apollo.QueryResult<
  LoadLayoutAislesMetaQuery,
  LoadLayoutAislesMetaQueryVariables
>;
export const LoadPlaneBaysDocument = gql`
  query loadPlaneBays($layoutId: ID!, $planeId: [ID!]) {
    layout(id: $layoutId) {
      features(filter: { planeId: $planeId, type: BAY }) {
        ...BayFeature
      }
    }
  }
  ${BayFeatureFragmentDoc}
`;

/**
 * __useLoadPlaneBaysQuery__
 *
 * To run a query within a React component, call `useLoadPlaneBaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadPlaneBaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadPlaneBaysQuery({
 *   variables: {
 *      layoutId: // value for 'layoutId'
 *      planeId: // value for 'planeId'
 *   },
 * });
 */
export function useLoadPlaneBaysQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadPlaneBaysQuery,
    LoadPlaneBaysQueryVariables
  > &
    (
      | { variables: LoadPlaneBaysQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadPlaneBaysQuery, LoadPlaneBaysQueryVariables>(
    LoadPlaneBaysDocument,
    options,
  );
}
export function useLoadPlaneBaysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadPlaneBaysQuery,
    LoadPlaneBaysQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LoadPlaneBaysQuery, LoadPlaneBaysQueryVariables>(
    LoadPlaneBaysDocument,
    options,
  );
}
export function useLoadPlaneBaysSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadPlaneBaysQuery,
        LoadPlaneBaysQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadPlaneBaysQuery,
    LoadPlaneBaysQueryVariables
  >(LoadPlaneBaysDocument, options);
}
export type LoadPlaneBaysQueryHookResult = ReturnType<
  typeof useLoadPlaneBaysQuery
>;
export type LoadPlaneBaysLazyQueryHookResult = ReturnType<
  typeof useLoadPlaneBaysLazyQuery
>;
export type LoadPlaneBaysSuspenseQueryHookResult = ReturnType<
  typeof useLoadPlaneBaysSuspenseQuery
>;
export type LoadPlaneBaysQueryResult = Apollo.QueryResult<
  LoadPlaneBaysQuery,
  LoadPlaneBaysQueryVariables
>;
export const LoadLayoutNavigableFeaturesDocument = gql`
  query loadLayoutNavigableFeatures($layoutId: ID!) {
    layout(id: $layoutId) {
      features {
        ...LayoutFeature
        planeId
      }
    }
  }
  ${LayoutFeatureFragmentDoc}
`;

/**
 * __useLoadLayoutNavigableFeaturesQuery__
 *
 * To run a query within a React component, call `useLoadLayoutNavigableFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadLayoutNavigableFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadLayoutNavigableFeaturesQuery({
 *   variables: {
 *      layoutId: // value for 'layoutId'
 *   },
 * });
 */
export function useLoadLayoutNavigableFeaturesQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadLayoutNavigableFeaturesQuery,
    LoadLayoutNavigableFeaturesQueryVariables
  > &
    (
      | { variables: LoadLayoutNavigableFeaturesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadLayoutNavigableFeaturesQuery,
    LoadLayoutNavigableFeaturesQueryVariables
  >(LoadLayoutNavigableFeaturesDocument, options);
}
export function useLoadLayoutNavigableFeaturesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadLayoutNavigableFeaturesQuery,
    LoadLayoutNavigableFeaturesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadLayoutNavigableFeaturesQuery,
    LoadLayoutNavigableFeaturesQueryVariables
  >(LoadLayoutNavigableFeaturesDocument, options);
}
export function useLoadLayoutNavigableFeaturesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadLayoutNavigableFeaturesQuery,
        LoadLayoutNavigableFeaturesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadLayoutNavigableFeaturesQuery,
    LoadLayoutNavigableFeaturesQueryVariables
  >(LoadLayoutNavigableFeaturesDocument, options);
}
export type LoadLayoutNavigableFeaturesQueryHookResult = ReturnType<
  typeof useLoadLayoutNavigableFeaturesQuery
>;
export type LoadLayoutNavigableFeaturesLazyQueryHookResult = ReturnType<
  typeof useLoadLayoutNavigableFeaturesLazyQuery
>;
export type LoadLayoutNavigableFeaturesSuspenseQueryHookResult = ReturnType<
  typeof useLoadLayoutNavigableFeaturesSuspenseQuery
>;
export type LoadLayoutNavigableFeaturesQueryResult = Apollo.QueryResult<
  LoadLayoutNavigableFeaturesQuery,
  LoadLayoutNavigableFeaturesQueryVariables
>;
export const LoadBayDetailsDocument = gql`
  query loadBayDetails($layoutId: ID!, $planeId: [ID!], $bayId: [ID!]) {
    layout(id: $layoutId) {
      features(filter: { planeId: $planeId, type: BAY, id: $bayId }) {
        ...BayFeatureDetailed
      }
    }
  }
  ${BayFeatureDetailedFragmentDoc}
`;

/**
 * __useLoadBayDetailsQuery__
 *
 * To run a query within a React component, call `useLoadBayDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadBayDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadBayDetailsQuery({
 *   variables: {
 *      layoutId: // value for 'layoutId'
 *      planeId: // value for 'planeId'
 *      bayId: // value for 'bayId'
 *   },
 * });
 */
export function useLoadBayDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadBayDetailsQuery,
    LoadBayDetailsQueryVariables
  > &
    (
      | { variables: LoadBayDetailsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadBayDetailsQuery, LoadBayDetailsQueryVariables>(
    LoadBayDetailsDocument,
    options,
  );
}
export function useLoadBayDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadBayDetailsQuery,
    LoadBayDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LoadBayDetailsQuery, LoadBayDetailsQueryVariables>(
    LoadBayDetailsDocument,
    options,
  );
}
export function useLoadBayDetailsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadBayDetailsQuery,
        LoadBayDetailsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadBayDetailsQuery,
    LoadBayDetailsQueryVariables
  >(LoadBayDetailsDocument, options);
}
export type LoadBayDetailsQueryHookResult = ReturnType<
  typeof useLoadBayDetailsQuery
>;
export type LoadBayDetailsLazyQueryHookResult = ReturnType<
  typeof useLoadBayDetailsLazyQuery
>;
export type LoadBayDetailsSuspenseQueryHookResult = ReturnType<
  typeof useLoadBayDetailsSuspenseQuery
>;
export type LoadBayDetailsQueryResult = Apollo.QueryResult<
  LoadBayDetailsQuery,
  LoadBayDetailsQueryVariables
>;
export const LoadLocationDataDocument = gql`
  query loadLocationData($layoutId: ID!, $locationId: String!) {
    layout(id: $layoutId) {
      locations(filter: { locationId: { type: EQ_CI, value: $locationId } }) {
        totalCount
        content {
          ...LayoutLocationSelectionData
        }
      }
    }
  }
  ${LayoutLocationSelectionDataFragmentDoc}
`;

/**
 * __useLoadLocationDataQuery__
 *
 * To run a query within a React component, call `useLoadLocationDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadLocationDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadLocationDataQuery({
 *   variables: {
 *      layoutId: // value for 'layoutId'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useLoadLocationDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadLocationDataQuery,
    LoadLocationDataQueryVariables
  > &
    (
      | { variables: LoadLocationDataQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadLocationDataQuery, LoadLocationDataQueryVariables>(
    LoadLocationDataDocument,
    options,
  );
}
export function useLoadLocationDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadLocationDataQuery,
    LoadLocationDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadLocationDataQuery,
    LoadLocationDataQueryVariables
  >(LoadLocationDataDocument, options);
}
export function useLoadLocationDataSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadLocationDataQuery,
        LoadLocationDataQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadLocationDataQuery,
    LoadLocationDataQueryVariables
  >(LoadLocationDataDocument, options);
}
export type LoadLocationDataQueryHookResult = ReturnType<
  typeof useLoadLocationDataQuery
>;
export type LoadLocationDataLazyQueryHookResult = ReturnType<
  typeof useLoadLocationDataLazyQuery
>;
export type LoadLocationDataSuspenseQueryHookResult = ReturnType<
  typeof useLoadLocationDataSuspenseQuery
>;
export type LoadLocationDataQueryResult = Apollo.QueryResult<
  LoadLocationDataQuery,
  LoadLocationDataQueryVariables
>;
export const FindLocationsByFilterDocument = gql`
  query findLocationsByFilter(
    $layoutId: ID!
    $input: FindLocationsByFilterInput!
    $page: SkipLimitPageSpec
    $filter: LayoutLocationFilter
  ) {
    layout(id: $layoutId) {
      locationsByFilter(input: $input, filter: $filter, page: $page) {
        content {
          locationId
        }
        totalCount
      }
    }
  }
`;

/**
 * __useFindLocationsByFilterQuery__
 *
 * To run a query within a React component, call `useFindLocationsByFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindLocationsByFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindLocationsByFilterQuery({
 *   variables: {
 *      layoutId: // value for 'layoutId'
 *      input: // value for 'input'
 *      page: // value for 'page'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFindLocationsByFilterQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindLocationsByFilterQuery,
    FindLocationsByFilterQueryVariables
  > &
    (
      | { variables: FindLocationsByFilterQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindLocationsByFilterQuery,
    FindLocationsByFilterQueryVariables
  >(FindLocationsByFilterDocument, options);
}
export function useFindLocationsByFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindLocationsByFilterQuery,
    FindLocationsByFilterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindLocationsByFilterQuery,
    FindLocationsByFilterQueryVariables
  >(FindLocationsByFilterDocument, options);
}
export function useFindLocationsByFilterSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindLocationsByFilterQuery,
        FindLocationsByFilterQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FindLocationsByFilterQuery,
    FindLocationsByFilterQueryVariables
  >(FindLocationsByFilterDocument, options);
}
export type FindLocationsByFilterQueryHookResult = ReturnType<
  typeof useFindLocationsByFilterQuery
>;
export type FindLocationsByFilterLazyQueryHookResult = ReturnType<
  typeof useFindLocationsByFilterLazyQuery
>;
export type FindLocationsByFilterSuspenseQueryHookResult = ReturnType<
  typeof useFindLocationsByFilterSuspenseQuery
>;
export type FindLocationsByFilterQueryResult = Apollo.QueryResult<
  FindLocationsByFilterQuery,
  FindLocationsByFilterQueryVariables
>;
export const FindLocationsStatsFilterDocument = gql`
  query findLocationsStatsFilter(
    $layoutId: ID!
    $input: FindLocationsByFilterInput!
    $summary: LayoutLocationsSummaryRequest
  ) {
    layout(id: $layoutId) {
      locationsByFilter(input: $input, summary: $summary) {
        summary {
          ...LayoutLocationsSummary
        }
      }
    }
  }
  ${LayoutLocationsSummaryFragmentDoc}
`;

/**
 * __useFindLocationsStatsFilterQuery__
 *
 * To run a query within a React component, call `useFindLocationsStatsFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindLocationsStatsFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindLocationsStatsFilterQuery({
 *   variables: {
 *      layoutId: // value for 'layoutId'
 *      input: // value for 'input'
 *      summary: // value for 'summary'
 *   },
 * });
 */
export function useFindLocationsStatsFilterQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindLocationsStatsFilterQuery,
    FindLocationsStatsFilterQueryVariables
  > &
    (
      | { variables: FindLocationsStatsFilterQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindLocationsStatsFilterQuery,
    FindLocationsStatsFilterQueryVariables
  >(FindLocationsStatsFilterDocument, options);
}
export function useFindLocationsStatsFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindLocationsStatsFilterQuery,
    FindLocationsStatsFilterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindLocationsStatsFilterQuery,
    FindLocationsStatsFilterQueryVariables
  >(FindLocationsStatsFilterDocument, options);
}
export function useFindLocationsStatsFilterSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FindLocationsStatsFilterQuery,
        FindLocationsStatsFilterQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FindLocationsStatsFilterQuery,
    FindLocationsStatsFilterQueryVariables
  >(FindLocationsStatsFilterDocument, options);
}
export type FindLocationsStatsFilterQueryHookResult = ReturnType<
  typeof useFindLocationsStatsFilterQuery
>;
export type FindLocationsStatsFilterLazyQueryHookResult = ReturnType<
  typeof useFindLocationsStatsFilterLazyQuery
>;
export type FindLocationsStatsFilterSuspenseQueryHookResult = ReturnType<
  typeof useFindLocationsStatsFilterSuspenseQuery
>;
export type FindLocationsStatsFilterQueryResult = Apollo.QueryResult<
  FindLocationsStatsFilterQuery,
  FindLocationsStatsFilterQueryVariables
>;
export const LoadPicklistDocument = gql`
  query loadPicklist($analyzeId: ID!) {
    analyzeResult(id: $analyzeId) {
      orders(
        page: { limit: 1500 }
        filter: {
          orderDateFrom: "2023-11-08T23:59:58.000"
          orderDateTo: "2023-11-09T23:59:58.000"
        }
      ) {
        content {
          ...AnalyzedOrderEvents
        }
      }
    }
  }
  ${AnalyzedOrderEventsFragmentDoc}
`;

/**
 * __useLoadPicklistQuery__
 *
 * To run a query within a React component, call `useLoadPicklistQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadPicklistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadPicklistQuery({
 *   variables: {
 *      analyzeId: // value for 'analyzeId'
 *   },
 * });
 */
export function useLoadPicklistQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadPicklistQuery,
    LoadPicklistQueryVariables
  > &
    (
      | { variables: LoadPicklistQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadPicklistQuery, LoadPicklistQueryVariables>(
    LoadPicklistDocument,
    options,
  );
}
export function useLoadPicklistLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadPicklistQuery,
    LoadPicklistQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LoadPicklistQuery, LoadPicklistQueryVariables>(
    LoadPicklistDocument,
    options,
  );
}
export function useLoadPicklistSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadPicklistQuery,
        LoadPicklistQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<LoadPicklistQuery, LoadPicklistQueryVariables>(
    LoadPicklistDocument,
    options,
  );
}
export type LoadPicklistQueryHookResult = ReturnType<
  typeof useLoadPicklistQuery
>;
export type LoadPicklistLazyQueryHookResult = ReturnType<
  typeof useLoadPicklistLazyQuery
>;
export type LoadPicklistSuspenseQueryHookResult = ReturnType<
  typeof useLoadPicklistSuspenseQuery
>;
export type LoadPicklistQueryResult = Apollo.QueryResult<
  LoadPicklistQuery,
  LoadPicklistQueryVariables
>;
export const LoadWorkforceDataDocument = gql`
  query loadWorkforceData(
    $analyzeId: ID!
    $drill: [ResourceUsageSummaryGroupBy!]
    $agentsId: [ID!]
    $from: ID
    $to: ID
    $eventType: [PicklistEventType!]
  ) {
    analyzeResult(id: $analyzeId) {
      resourceUsageSummary(
        groupBy: $drill
        filter: {
          agent: $agentsId
          pickByDateFrom: $from
          pickByDateTo: $to
          eventType: $eventType
        }
      ) {
        ...ResourceUsageSummary
      }
    }
  }
  ${ResourceUsageSummaryFragmentDoc}
`;

/**
 * __useLoadWorkforceDataQuery__
 *
 * To run a query within a React component, call `useLoadWorkforceDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadWorkforceDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadWorkforceDataQuery({
 *   variables: {
 *      analyzeId: // value for 'analyzeId'
 *      drill: // value for 'drill'
 *      agentsId: // value for 'agentsId'
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      eventType: // value for 'eventType'
 *   },
 * });
 */
export function useLoadWorkforceDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    LoadWorkforceDataQuery,
    LoadWorkforceDataQueryVariables
  > &
    (
      | { variables: LoadWorkforceDataQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadWorkforceDataQuery,
    LoadWorkforceDataQueryVariables
  >(LoadWorkforceDataDocument, options);
}
export function useLoadWorkforceDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadWorkforceDataQuery,
    LoadWorkforceDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadWorkforceDataQuery,
    LoadWorkforceDataQueryVariables
  >(LoadWorkforceDataDocument, options);
}
export function useLoadWorkforceDataSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LoadWorkforceDataQuery,
        LoadWorkforceDataQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LoadWorkforceDataQuery,
    LoadWorkforceDataQueryVariables
  >(LoadWorkforceDataDocument, options);
}
export type LoadWorkforceDataQueryHookResult = ReturnType<
  typeof useLoadWorkforceDataQuery
>;
export type LoadWorkforceDataLazyQueryHookResult = ReturnType<
  typeof useLoadWorkforceDataLazyQuery
>;
export type LoadWorkforceDataSuspenseQueryHookResult = ReturnType<
  typeof useLoadWorkforceDataSuspenseQuery
>;
export type LoadWorkforceDataQueryResult = Apollo.QueryResult<
  LoadWorkforceDataQuery,
  LoadWorkforceDataQueryVariables
>;
