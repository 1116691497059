import _ from 'lodash';
import {
  FilterFieldConfigBase,
  FilterFieldDataStateKey,
  FilterFieldDataType,
  FilterFieldEditorType,
} from '../common/types';
import { cloneWithoutTypename } from '../common/utils';
import {
  DatasetExtraField,
  DBFieldType,
} from '../datasetObject/store/datasetObject.types';
import {
  LoadAllPolicyFilterValuesParams,
  LoadPolicyFilterValuesParams,
  PolicyFilterBase,
  PolicyFilterIntersection,
  PolicyFilterUnion,
} from './policyFilter.types';

export function getPolicyFilterDataKeysByEditorType(
  editor: FilterFieldEditorType,
): FilterFieldDataStateKey[] {
  switch (editor) {
    case FilterFieldEditorType.StringRange:
      return ['main', 'extra'];
    default:
      return ['main'];
  }
}

export function getPolicyFilterKey(
  type: string,
  dataKey: FilterFieldDataStateKey,
) {
  return `${type}-${dataKey}`;
}

/**
 * Return is filter has a meaningful value ( non-empty)
 * @param filter Location | Item filter value
 * @returns boolean
 */
function hasPolicyFilterValue(filter: PolicyFilterBase<any>): boolean {
  return (
    !_.isEmpty(filter.valueIn) ||
    !_.isNil(filter.stringRange?.from) ||
    !_.isNil(filter.stringRange?.to) ||
    filter.isNull ||
    !_.isNil(filter.range?.from) ||
    !_.isNil(filter.range?.to)
  );
}

function getPolicyFilterInput<T extends string>(
  filter: PolicyFilterBase<T>,
): PolicyFilterBase<T> {
  return hasPolicyFilterValue(filter) ? cloneWithoutTypename(filter) : null;
}

/**
 *  Prepare  policy filter union for save ( clean meaningless filters and empty filter sets)
 * @param filterUnion dirty policy filter union
 * @returns
 */
export function getPolicyMatchInput<T extends string>(
  filterUnion: PolicyFilterUnion<T>,
  invertEmptyRule = false,
): PolicyFilterUnion<T> {
  const anyOf = _(filterUnion?.anyOf)
    .map(intersection => {
      const allOf = _(intersection.allOf)
        .map(getPolicyFilterInput)
        .compact()
        .value();

      const result: PolicyFilterIntersection<T> =
        _.size(allOf) > 0
          ? {
              id: intersection.id,
              allOf,
            }
          : null;
      return result;
    })
    .compact()
    .value();

  if (_.isEmpty(anyOf)) {
    return invertEmptyRule ? { anyOf: [] } : null;
  }

  return {
    anyOf,
  };
}

export type UpdatePolicyFilterValuesParams<T extends string> = {
  filterConfig: FilterFieldConfigBase<T>[];
  selectedFilterId: string;
  policyFilterUnion: PolicyFilterUnion<T>;
  load: (params: LoadPolicyFilterValuesParams<T>) => Promise<void>;
  cancel: (filterKey: string) => void;
};

export function updatePolicyFilterValues<T extends string>(
  params: UpdatePolicyFilterValuesParams<T>,
): void {
  const filterIntersection = _.find(
    params.policyFilterUnion?.anyOf,
    f => f.id === params.selectedFilterId,
  );

  _.forEach(params.filterConfig, configItem => {
    const keyParts = getPolicyFilterDataKeysByEditorType(configItem.editorType);

    keyParts.forEach(key => {
      const filterKey = getPolicyFilterKey(configItem.type, key);
      params.cancel(filterKey);
      params.load({
        field: configItem.type,
        filterKey,
        filterIntersection,
      });
    });
  });
}

export type UpdateAllPolicyFilterValuesParams<T extends string> = {
  filterConfig: FilterFieldConfigBase<T>[];
  selectedFilterId: string;
  policyFilterUnion: PolicyFilterUnion<T>;
  load: (params: LoadAllPolicyFilterValuesParams<T>) => Promise<void>;
  cancel: () => void;
};

export function updateAllPolicyFilterValues<T extends string>(
  params: UpdateAllPolicyFilterValuesParams<T>,
): void {
  const filterIntersection = _.find(
    params.policyFilterUnion?.anyOf,
    f => f.id === params.selectedFilterId,
  );

  params.cancel();
  params.load({
    field: null,
    filterKey: null,
    filterConfigs: params.filterConfig,
    affect: 'all',
    filterIntersection,
  });
}

export function getFilterFieldDataType(
  dataType: DBFieldType,
): FilterFieldDataType {
  switch (dataType) {
    case 'integer':
      return FilterFieldDataType.IntegerRange;
    case 'double':
      return FilterFieldDataType.NumberRange;
  }

  return FilterFieldDataType.String;
}

export function getFilterFieldEditorType(
  dataType: DBFieldType,
): FilterFieldEditorType {
  switch (dataType) {
    case 'integer':
    case 'double':
      return FilterFieldEditorType.SliderRange;
  }

  return FilterFieldEditorType.MultipleCheck;
}

export function getFilterDescriptorDataType(
  dataType: DBFieldType,
): 'string' | 'number' {
  switch (dataType) {
    case 'integer':
    case 'double':
      return 'number';
  }

  return 'string';
}

export function getFilterDescriptorDataTypeByConfig(
  cfg: FilterFieldConfigBase<string>,
): 'string' | 'number' {
  switch (cfg.dataType) {
    case FilterFieldDataType.IntegerRange:
    case FilterFieldDataType.NumberRange:
      return 'number';
  }

  return 'string';
}

export function getExtraFieldFieldConfig(
  extraField: DatasetExtraField,
): FilterFieldConfigBase<string> {
  const dbType = _.head(extraField.type);
  return {
    type: extraField.alias,
    title: extraField.title ?? extraField.name,
    editorType: getFilterFieldEditorType(dbType),
    dataType: getFilterFieldDataType(dbType),
    disabled: true,
  };
}
