import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import TitleSection from '../../../components/layout/TitleSection';
import PanelContainer from '../../../containers/PanelContainer';
import SettingsServiceOptionsActuality from '../settings/SettingsServiceOptionsActuality';
import { SettingsServiceOptionsCCTV } from '../settings/SettingsServiceOptionsCCTV';

export type PanelProps = PropsWithChildren & {
  className?: string;
};

const PanelIntegration: React.FC<PanelProps> = props => {
  const { t } = useTranslation('app');

  return (
    <PanelContainer
      id="panel-system-integrations-settings"
      title={t`Integration Settings`}
      collapsible
      // hasPadding
    >
      <TitleSection inPanelView title={t`Features & Services`}>
        <SettingsServiceOptionsActuality />
      </TitleSection>

      <TitleSection inPanelView title={t`Beta`}>
        <SettingsServiceOptionsCCTV />
      </TitleSection>
    </PanelContainer>
  );
};

export default PanelIntegration;
