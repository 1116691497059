import {
  ItemUomOrItemsSearchFilter,
  LoadItemSetDataDocument,
  useRunExportJobMutation,
} from '@warebee/frontend/data-access-api-graphql';
import {
  ItemSetDataTableColumn,
  ItemSetDataTableRow,
  ItemsExportJobParams,
  ItemsSetConverterConfig,
  getItemsTableRows,
  itemsExportJobParams,
} from '@warebee/shared/export-converter';
import { generateExportFilename } from 'libs/shared/export-converter/src/lib/export-filename.helper';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { containsFilter, numberFilter } from '../common/filterHelper';
import { AsyncLoadStatus, DatasetDataViewProps } from '../common/types';
import DatasetTable from '../components/DatasetTable';
import { brandName } from '../store/global.state';
import {
  warehouseSelected,
  warehouseSelectedId,
} from '../store/warehouse.state';
import useLoadItemSetData from './hooks/useLoadItemSetData';
import {
  itemSetItemsData,
  itemSetItemsDataState,
  itemSetItemsDataStatus,
} from './store/itemSet.state';
import useItemSetDataTableConfig from './useItemSetDataTableConfig';

const ItemSetDataTable: React.FC<
  DatasetDataViewProps<ItemSetDataTableRow>
> = props => {
  const { t } = useTranslation('dataset');
  const brandTitleState = useRecoilValue(brandName);
  const warehouse = useRecoilValue(warehouseSelected);
  const warehouseIdState = useRecoilValue(warehouseSelectedId);

  const data = useRecoilValue(itemSetItemsData);
  const dataLoadStatus = useRecoilValue(itemSetItemsDataStatus);
  const [dataState, setDataState] = useRecoilState(itemSetItemsDataState);
  const [loadCallback, cancelLoad] = useLoadItemSetData();
  const columnsConfig = useItemSetDataTableConfig();

  const [runExportCSV] = useRunExportJobMutation();

  function callDataLoad(
    searchValues: Partial<Record<ItemSetDataTableColumn, string>>,
    page = { isAppend: false, skip: 0 },
  ) {
    if (_.isNil(props.id)) return;

    const filter: ItemUomOrItemsSearchFilter = {
      consignee: containsFilter(searchValues['consignee']),
      height: numberFilter(searchValues['height']),
      id: containsFilter(searchValues['id']),
      length: numberFilter(searchValues['length']),
      lowerUom: containsFilter(searchValues['lowerUom']),
      netWeight: numberFilter(searchValues['netWeight']),
      pollutionClass: containsFilter(searchValues['pollutionClass']),
      maxStack: numberFilter(searchValues['maxStack']),
      sku: containsFilter(searchValues['sku']),
      skuGroup: containsFilter(searchValues['skuGroup']),
      stockCategory: containsFilter(searchValues['stockCategory']),
      storageClass: containsFilter(searchValues['storageClass']),
      subGroup: containsFilter(searchValues['subGroup']),
      transportClass: containsFilter(searchValues['transportClass']),
      unitsPerLowestUom: numberFilter(searchValues['unitsPerLowestUom']),
      uom: containsFilter(searchValues['uom']),
      volume: numberFilter(searchValues['volume']),
      width: numberFilter(searchValues['width']),
    };

    cancelLoad();
    loadCallback({
      itemSetId: props.id,
      filter,
      ...page,
    });
  }

  useEffect(() => {
    callDataLoad(dataState.searchValues);
  }, [props.id, dataState.searchValues]);

  const converterConfig: ItemsSetConverterConfig = {
    columnsConfig,
  };

  async function startExportCSV() {
    const filename = generateExportFilename({
      exportTitle: t`dataset-items`,
      brandTitleState,
      warehouse,
      warehouseIdState,
    });

    const variables: ItemsExportJobParams = {
      ...itemsExportJobParams,
      query: LoadItemSetDataDocument.loc.source.body,
      config: converterConfig,
      variables: {
        itemSetId: props.id,
        page: null,
      },
      filename: `${filename}.csv`,
    };
    const { data, errors } = await runExportCSV({
      variables,
    });
    return {
      errors: [],
      job: data.createExportJob,
    };
  }

  const isLoading =
    dataLoadStatus === AsyncLoadStatus.Loading ||
    dataLoadStatus === AsyncLoadStatus.None;
  const itemsCount = data?.content?.length || 0;
  const totalCount = data?.totalCount ?? 0;

  function onLoadNext() {
    callDataLoad(dataState.searchValues, { isAppend: true, skip: itemsCount });
  }
  const flattenData = getItemsTableRows(data?.content, converterConfig);

  return (
    <DatasetTable
      hasCounter
      hideScreenTitle={props.hideScreenTitle}
      subtitle={t`Item Set`}
      title={props.title}
      isSticky
      id={props.id}
      columnsConfig={columnsConfig}
      keyFields={['consignee', 'sku', 'uom']}
      data={flattenData}
      onLoadNext={onLoadNext}
      onSearch={v => {
        setDataState({ ...dataState, searchValues: v });
      }}
      totalCount={totalCount}
      searchValues={dataState.searchValues}
      isLoading={isLoading}
      onStartExportClick={startExportCSV}
    />
  );
};

export default ItemSetDataTable;
