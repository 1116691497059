import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { cn } from '../../../common/utils';
import PanelContainer from '../../../containers/PanelContainer';
import { SettingsSupportChat } from '../settings/SettingsSupportChat';
import { SettingsSupportHelpers } from '../settings/SettingsSupportHelpers';

export type PanelProps = PropsWithChildren & {
  className?: string;
};

const PanelSettingsSupport: React.FC<PanelProps> = props => {
  const { t } = useTranslation('app');

  return (
    <PanelContainer
      data-component="PanelSettingsSupport"
      id="panel-settings-support"
      title={t`Help & Support`}
      collapsible
      className={cn('', props.className)}
    >
      <div className="p-4">
        <SettingsSupportChat />
      </div>
      <div className="p-4">
        <SettingsSupportHelpers />
      </div>
    </PanelContainer>
  );
};

export default PanelSettingsSupport;
