import {
  SortDirection,
  StringSearchFilter,
  StringSearchFilterType,
} from '@warebee/frontend/data-access-api-graphql';
import { InferResult, QueryCreator, SelectQueryBuilder, sql } from 'kysely';
import _ from 'lodash';
import { KeysMatching } from '../../../common/utility.types';
import { DatasetExtraField } from '../../../datasetObject/store/datasetObject.types';
import {
  DatasetDatabase,
  datasetQueryBuilder,
} from './queryBuilder/datasetQueryBuilder';

const AssignmentHqAllFieldsStub = getAssignmentHqQueryBuilder({} as any)
  .selectFrom('hq')
  .selectAll();

const ItemSetFieldsStub = getItemsFilteredQueryBuilder(
  {} as any,
  datasetQueryBuilder,
).selectAll();

export type ItemSetRows = InferResult<typeof ItemSetFieldsStub>;

export type AssignmentHqRows = InferResult<typeof AssignmentHqAllFieldsStub>;

export type AssignmentHqRow = Record<string, number | string>; //AssignmentHqRows[number];
export type AssignmentHqField = keyof AssignmentHqRow;

export type AssignmentHqQueryBuilderParams = {
  layoutId: string;
  itemSetId: string;
  assignmentId: string;
  filterBy?: AssignmentHqFilterBy;
  assignmentExtraFields: DatasetExtraField[];
};

export type AssignmentHqSortBy = {
  direction?: SortDirection;
  field: AssignmentHqFieldWithStringFilter;
};

export type AssignmentHqFieldWithStringFilter = KeysMatching<
  AssignmentHqRow,
  string
>;

export type AssignmentHqFilterBy = Partial<Record<string, StringSearchFilter>>;

function getItemsFilteredQueryBuilder(
  params: AssignmentHqQueryBuilderParams,
  db: QueryCreator<DatasetDatabase>,
): SelectQueryBuilder<DatasetDatabase, '___item_set_iceberg___', {}> {
  let at = db
    .selectFrom('___item_set_iceberg___')
    .where('datasetObjectId', '=', params.itemSetId);
  return at;
}

function getLayoutFilteredQueryBuilder<T extends DatasetDatabase>(
  params: AssignmentHqQueryBuilderParams,
  db: QueryCreator<T>,
) {
  let at = db
    .selectFrom('___layout_location___')
    .where('datasetObjectId', '=', params.layoutId as any);
  return at;
}

function getAssignmentFilteredQueryBuilder<T extends DatasetDatabase>(
  params: AssignmentHqQueryBuilderParams,
  db: QueryCreator<T>,
) {
  let at = db
    .selectFrom('___assignment___')
    .where('datasetObjectId', '=', params.assignmentId as any);
  return at;
}

export function getAssignmentHqQueryBuilder(
  params: AssignmentHqQueryBuilderParams,
) {
  let builder = datasetQueryBuilder
    .with(
      cte => cte('is'),
      db => {
        return getItemsFilteredQueryBuilder(params, db).selectAll();
      },
    )
    .with(
      cte => cte('l'),
      db => {
        return getLayoutFilteredQueryBuilder(params, db).selectAll();
      },
    )
    .with(
      cte => cte('a'),
      db => {
        return getAssignmentFilteredQueryBuilder(params, db)
          .selectAll()
          .select(({ fn }) => [
            sql<string>`${sql.ref('consignee')} ||'-'|| ${sql.ref('sku')} `.as(
              'skuKey',
            ),
            ..._.map(params.assignmentExtraFields, fd =>
              sql<number>`json_value(${sql.ref('raw_data')}, 'strict $.${sql.raw(fd.name)}' returning ${sql.raw(fd.type?.[0])})`.as(
                fd.name,
              ),
            ),
          ]);
      },
    )
    .with(
      cte => cte('hqRaw'),
      db => {
        return db
          .selectFrom('a')
          .leftJoin('is', join =>
            join
              .onRef('a.consignee', '=', 'is.consignee')
              .onRef('a.sku', '=', 'is.sku')
              .onRef('a.stockUom', '=', 'is.uom'),
          )
          .leftJoin('l', join =>
            join.onRef('a.locationId', '=', 'l.locationId'),
          )
          .selectAll('a')
          .select([
            'is.skuGroup',
            'is.name',
            'is.description',
            'is.subGroup',
            'is.transportClass',
            'is.stockCategory',
            'is.storageClass',
            'is.pollutionClass',
            'is.lowerUom',
            'is.netWeight',
            'is.length',
            'is.width',
            'is.height',
            'is.volume',
            'is.unitsPerLowestUom',
            'is.ean',
            'is.upc',
            'l.locationId as layoutLocationId',
            'l.locationOrder',
            'l.locationStatus',
            'l.locationLevel',
            'l.locationLength',
            'l.locationWidth',
            'l.locationHeight',
            'l.locationWeight',
            'l.locationBayId',
            'l.locationBayTitle',
            'l.locationBayPosition',
            'l.locationDepthPosition',
            'l.locationUsageType',
            'l.locmhtype',
            'l.locationRackingType',
            'l.warehouseArea',
            'l.locationSide',
            'l.congestionZone',
            'l.locationBayProjection',
            'l.locationHeightFromFloor',
            'l.locationDepthFromFront',
            'l.locationIndexFromFront',
            'l.bayType',
            'l.aisleId',
            'l.aisleTitle',
            'l.planeId',
            'l.planeTitle',
          ])
          .select(
            sql<string>`${sql.ref('a.locationId')} || '-' || ${sql.ref('a.consignee')} ||'-'|| ${sql.ref('a.sku')} `.as(
              'assignmentItem',
            ),
          );
      },
    )
    .with(
      cte => cte('hq'),
      db => {
        let builder = db.selectFrom('hqRaw').selectAll();
        if (params.filterBy) {
          builder = _.reduce(
            params.filterBy,
            (q, value, key) => {
              if (
                value?.type === StringSearchFilterType.CONTAINS &&
                value?.value === 'undefined'
              ) {
                return q.where(key as any, 'is', null);
              }

              if (
                value?.type === StringSearchFilterType.CONTAINS &&
                !_.isEmpty(value?.value)
              ) {
                return q.where(key as any, 'like', `%${value.value}%`);
              }

              return q;
            },
            builder,
          );
        }
        return builder;
      },
    );

  return builder;
}
