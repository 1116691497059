import { Transition } from '@headlessui/react';
import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import { useRecoilState } from 'recoil';
import { Button } from '../components/actions/Button';
import * as Icon from '../components/icons';
import { CollapsibleId } from '../store/collapsible.default';
import { collapsibleState } from '../store/collapsible.state';

export type PanelContainerProps = PropsWithChildren & {
  id: CollapsibleId;
  className?: string;
  classNameChildren?: string;
  classNameHeader?: string;
  classNameInner?: string;
  title: string | React.ReactNode;
  subtitle?: string;
  hasFilter?: string;
  loadingMessage?: string;
  maxHeight?: number;
  hasScroll?: boolean;
  hasPadding?: boolean;
  hidden?: boolean;
  icon?: string;
  borderTop?: boolean;
  collapsible?: boolean;
  transparent?: boolean;
  hasHover?: boolean;
  componentName?: string;
  isAccordion?: boolean;
  onClick?: (isOpen: boolean, toggleOpen: () => void) => void;
};

const PanelContainer: React.FC<PanelContainerProps> = props => {
  const [state, setState] = useRecoilState(collapsibleState(props.id));
  const isClosed = props.collapsible && state.isCollapsed;
  const isCollapsible = props.collapsible;

  const toggleOpen = () => {
    setState({
      isCollapsed: !isClosed,
    });
  };

  const styleIconButton = classNames(
    'h-5 w-5',
    'fill-current',
    // 'opacity-60',
    'bg-menu/5',
    'hover:bg-menu-hover hover:text-menu-active-text',
    'text-menu-text',
    'p-0.5 mx-0.5',
    'rounded-full',
  );

  return (
    <div
      data-component={`PanelContainer${props.componentName ?? ''}`}
      className={classNames(
        'relative',
        { 'hover:bg-menu hover:bg-opacity-10': props.hasHover },
        { 'border-menu/70 border-b': isClosed },
        { 'flex flex-col overflow-hidden': props.isAccordion },
        { 'flex-1': props.isAccordion && !isClosed },
        props.className,
      )}
    >
      <Button
        full
        isTransparent
        buttonSize={`header`}
        isDisabled={!props.collapsible}
        className={classNames(
          'z-container group',
          'sticky top-0',
          'p-header',
          'h-header',
          'flex items-center',
          // 'transition duration-100 ease-in-out delay-75',
          'border-menu/50 border-b',
          'backdrop-saturate-110 backdrop-blur-lg',
          isCollapsible
            ? 'bg-sidebar-header/80 text-menu-text/90 cursor-pointer hover:bg-opacity-50'
            : 'bg-menu/70',
          { uppercase: props.transparent },

          props.classNameHeader,
        )}
      >
        {props.borderTop && (
          <hr className={classNames('border-app-panel-dark mb-2 mt-1')} />
        )}
        <h6
          className={classNames('mr-0 flex h-full flex-1 items-center')}
          onClick={() => props.collapsible && toggleOpen()}
        >
          {(props.collapsible && (
            <>
              {props.icon}
              {isClosed ? (
                <Icon.ChevronDualOpenVertical className={styleIconButton} />
              ) : (
                <Icon.ChevronDualCloseVertical className={styleIconButton} />
              )}

              <span
                className={classNames(
                  'flex flex-1',
                  'truncate text-start',
                  isClosed ? 'text-menu-text/60' : 'text-menu-text',
                  {
                    'group-hover:text-opacity-70': isCollapsible,
                  },
                )}
              >
                {props.title}
              </span>
            </>
          )) || (
            <span
              className={classNames(
                'ltr:pl-2 rtl:pr-2',
                'flex-1 truncate text-start text-sm uppercase',
                isClosed ? 'text-menu-text/60' : 'text-menu-text',
                {
                  'text-menu-active group-hover:text-opacity-70': isCollapsible,
                },
              )}
            >
              {props.title}
            </span>
          )}
          {props.hasFilter && (
            <div
              className={classNames(
                'flex items-center',
                'px-2 py-1',
                'text-xs',
                'rounded',
                'bg-menu-active text-menu-active-text hover:bg-menu-hover',
                'group',
              )}
            >
              <div
                className={classNames('block hover:hidden group-hover:hidden')}
                title="hide filter"
              >
                <Icon.Filter
                  className={classNames(
                    'h-4 ltr:mr-2 rtl:ml-2',
                    'text-menu-text',
                    'fill-current',
                  )}
                />
              </div>
              <div className="hidden group-hover:block" title="show filter">
                <Icon.CircleX
                  className={classNames(
                    'text-menu-text h-5 fill-current ltr:mr-2 rtl:ml-2',
                  )}
                />
              </div>
              <span className="flex-1">{props.hasFilter}</span>
            </div>
            // <button
            //   type="button"
            //   className="ltr:pl-2 rtl:pr-2 text-menu-text rounded bg-brand hover:bg-brand-hover "
            // >
            // </button>
          )}
          {props.subtitle && (
            <div
              className={classNames(
                'flex items-center',
                'px-1',
                'text-menu-text/60',
              )}
            >
              <span className="flex-1 text-xs">{props.subtitle}</span>
            </div>
          )}
        </h6>
      </Button>

      {!isClosed && props.children && (
        <Transition
          show={!isClosed}
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
          data-component={`PanelContainerBody`}
          className={classNames(
            props.collapsible ? 'bg-app-panel-dark/20' : '',
            'z-0',
            'w-full',
            { 'isHidden hidden': props.hidden },
            { 'p-2': props.hasPadding },
            { 'relative flex-1 overflow-hidden': props.isAccordion },
            props.classNameChildren,
          )}
        >
          {props.children}
        </Transition>
      )}
    </div>
  );
};

export default PanelContainer;
