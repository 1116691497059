import { ImportOrderSetLineInput } from '@warebee/shared/data-access-api-dto';
import { ORDER_SET_MAPPING_SCHEMA } from '@warebee/shared/import-converter';
import { TFunction } from 'i18next';
import { keyBy } from 'lodash';
import { MappingSettings } from '../../store/import.types';
import { consigneeAliases, skuAliases } from '../../store/sharedAliases';

export function getOrderSetSchema(
  t: TFunction<'importer'>,
): MappingSettings<ImportOrderSetLineInput> {
  const baseFields = keyBy(ORDER_SET_MAPPING_SCHEMA.fields, f => f.name);

  return {
    fields: [
      {
        ...baseFields['consignee'],
        title: t(`Client (Consignee)`, { ns: 'importer' }),
        description: t(
          `Client (consignee) — Name of your company (can be generated) or your client (For 3PLs)`,
          { ns: 'importer' },
        ),
        example: 'Client A',
        aliases: consigneeAliases,
      },
      {
        ...baseFields['orderId'],
        title: t(`Order Id`, { ns: 'importer' }),
        description: t(`Order Number`, { ns: 'importer' }),
        example: 'OL9876543231',
        aliases: [
          'orderid',
          'order_id',
          'ordernumber',
          'order_number',
          'order_no',
          '#',
          'order',
          'idorder',
          'id_order',
          'ord',
          'ordno',
          'ordnum',
          'picklist',
          'pick-list',
          'picklistid',
          'picklist_id',
          'pickslip_id',
          'pick_slip',
          'pickslip',
          'obndordnum',
          'keräyslenkki',
          'tilausnumero',
          'workorderno_',
          'orderno_',
          'no_',
          'outboundordernumber',
          'erp_order',
          'erporder',
          'order_identifier',
          'orderidentifier',
          'order_ref',
          'orderref',
          'referenceid',
          'reference_id',
          'ref_id',
          'refid',
          'order_reference',
          'orderreference',
        ],
      },
      {
        ...baseFields['orderLine'],
        title: t(`Order Line`, { ns: 'importer' }),
        description: t(`Order Line`, { ns: 'importer' }),
        example: '1',
        aliases: [
          'orderline',
          'orderlineid',
          'orderline_id',
          'linenumber',
          'line',
          'lineid',
          'line_num',
          'lines_num',
          'linenum',
          'lineno',
          'outboundorderline',
          'order_num_lines',
          'ordernumlines',
          'num_lines',
        ],
      },
      {
        ...baseFields['sku'],
        title: t(`Item`, { ns: 'importer' }),
        description: t(`Item code (SKU)`, { ns: 'importer' }),
        example: 'SKU123456789',
        aliases: skuAliases,
      },
      {
        ...baseFields['quantity'],
        title: t(`Quantity`, { ns: 'importer' }),
        description: t(`Quantity ordered in the lowest unit of measure (UOM)`, {
          ns: 'importer',
        }),
        example: '10',
        aliases: [
          'quantity',
          'units',
          'order_quantity',
          'order_qty',
          'orderquantity',
          'orderedquantity',
          'linequantity',
          'lineunits',
          'qty',
          'qty.',
          'qtyord',
          'qtyactual',
          'qtyshipped',
          'qtyorig',
          'qtymax',
          'qty_picked',
          'qtypicked',
          'qty_scanned',
          'qtyscanned',
          'qty_um',
          'pickedqty',
          'myyntierät',
          'pickquantityeaches',
          'totalcasespicked',
          'appliedquantityeaches',
          'reqquantity',
          'amount',
          'maxofquantity',
          'maxquantity',
          'maxqty',
          'pickedquantity',
          'picked_quantity',
          'picked_qty',
          'pickedqty',
          'picked',
        ],
      },
      {
        ...baseFields['orderDate'],
        title: t(`Order (Pick By) Date`, { ns: 'importer' }),
        description: t(
          'Order (Pick By) Date, Date time format example (multiple formats supported). Like: yyyy-mm-dd hh:mm:ss (ISO 8601)',
          {
            ns: 'importer',
          },
        ),
        example: '2020-01-01',
        contextHelpId: 'importer/date-time',
        aliases: [
          'orderdate',
          'order_date',
          'date_order',
          'date_time',
          'date',
          'deadline',
          'sla_date',
          'issue_date',
          'by_date',
          'pick_date',
          'pick_by_date',
          'pick_by_time',
          'pick_by',
          'shipping_date',
          'ship_date',
          'schedule',
          'delivery_time',
          'requested_date',
          'request_date',
          'dispatch_date',
          'manifest',
          'despatchdate',
          'despatched',
          'despatchedon',
          'dttmadded',
          'dttmstart',
          'Kalenteripäivä',
          'ShipDate',
          'OrderEndDate',
          'OrderEnd',
          'to_date',
          'date_to',
        ],
      },
      {
        ...baseFields['customer'],
        title: t(`Customer`, { ns: 'importer' }),
        description: t(`Order Customer ID (Order level)`, { ns: 'importer' }),
        example: 'Shop A',
        aliases: [
          'customer',
          'cust',
          'cust_id',
          'customerid',
          'company',
          'targetcompany',
          'idcustomer',
          'toimitusasiakas',
          'carrier',
          'destination',
          'ship-tocustomer',
          'shiptocustomer',
          'route-tocustomer',
          'routetocustomer',
          'order_customer_code',
          'ordercustomercode',
        ],
      },
      {
        ...baseFields['deliveryRoute'],
        title: t('Route', { ns: 'importer' }),
        description: t(
          'Transport/Delivery Route, Pick by Delivery, Pick by Trip',
          {
            ns: 'importer',
          },
        ),
        aliases: [
          'deliveryRoute',
          'route',
          'delivery',
          'transport',
          'transportID',
          'location',
          'path',
          'lineID',
          'line',
        ],
      },
      {
        ...baseFields['dock'],
        title: t('Dock', { ns: 'importer' }),
        description: t('Used for Pick by Dock/Door', { ns: 'importer' }),
        example: 'Dock A',
        aliases: ['dock', 'door', 'dock_id', 'door_id'],
      },

      {
        ...baseFields['stagingArea'],
        title: t('Staging Area', { ns: 'importer' }),
        description: t('Warehouse Staging Area', { ns: 'importer' }),
        example: 'Stage-A',
        aliases: [
          'stagingArea',
          'staging_area',
          'area',
          'area_id',
          'id_area',
          'staging',
          'DestinationLocation',
        ],
      },
      {
        ...baseFields['waveId'],
        title: t('Wave ID', { ns: 'importer' }),
        description: t('Picking Wave ID, for wave/cluster picking method', {
          ns: 'importer',
        }),
        example: 'W202301312359',
        aliases: [
          'waveId',
          'wave_id',
          'id_wave',
          'pick_wave',
          'pick_group',
          'wave',
          'wavenumber',
          'group',
          'manifest',
          'manifestid',
          'manifest_id',
          'ScheduleBatchNumber',
          'ScheduleBatchID',
          'batchid',
          'batchnumber',
        ],
      },
      {
        ...baseFields['shipmentId'],
        title: t('Shipment ID', { ns: 'importer' }),
        description: t('Shipment/Delivery identifier for grouping orders', {
          ns: 'importer',
        }),
        aliases: [
          'shipmentid',
          'shipmentLineID',
          'shipment',
          'shipmentID',
          'shipmentRoute',
          'shipment_id',
          'shipment_no',
          'shipment_number',
          'shipment_ref',
          'shipment_reference',
          'delivery_id',
          'delivery_no',
          'delivery_number',
          'delivery_reference',
          'transport_id',
          'transport_number',
          'transport_reference',
          'consignment_id',
          'consignment_number',
          'consignment_ref',
          'load_id',
          'load_number',
          'load_reference',
        ],
      },
      {
        ...baseFields['loadByDate'],
        title: t(`Load by Date`, { ns: 'importer' }),
        description: t(
          'Date and time when order needs to be loaded to transport',
          {
            ns: 'importer',
          },
        ),
        aliases: [
          'orderby',
          'loadby',
          'bydate',
          'load_by',
          'load_by_date',
          'load_by_time',
          'load_date',
          'loading_date',
          'loading_time',
          'load_deadline',
          'loading_deadline',
          'transport_by',
          'transport_by_date',
          'transport_date',
          'transport_deadline',
          'dispatch_by',
          'dispatch_by_date',
          'dispatch_deadline',
          'collection_date',
          'collection_time',
          'collection_by',
        ],
      },
      {
        ...baseFields['pickingPriority'],
        title: t('Picking Priority ID', { ns: 'importer' }),
        description: t('Picking priority ID for “shop friendly” picking.', {
          ns: 'importer',
        }),
        example: '1',
        aliases: [
          'pickingPriority',
          'picking_priority',
          'priority',
          'priority_id',
          'shop_id',
          'shipment',
          'shipment_id',
          'shipment_number',
          'shipment_no',
        ],
      },
      {
        ...baseFields['pickingContainer'],
        title: t('Picking Container', { ns: 'importer' }),
        description: t(
          'Picking container, when picking in batches of cages, totes or bin containers.',
          {
            ns: 'importer',
          },
        ),
        example: 'A',
        aliases: [
          'pickingContainer',
          'pickingContainerid',
          'pickingContainer_id',
          'container',
          'containerid',
          'container_id',
          'bin',
          'binid',
          'bin_id',
          'cage',
          'cageid',
          'cage_id',
          'tote',
          'toteid',
          'tote_id',
          'tray',
          'trayid',
          'tray_id',
          'section',
          'sectionid',
          'section_id',
          'basket',
          'basketid',
          'basket_id',
          'slot',
          'slotid',
          'slot_id',
        ],
      },
    ],
  };
}
