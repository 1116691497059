import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import DropdownSelector from '../../../components/actions/DropdownSelector';
import { Container } from '../../../components/layout/ContainerFlex';
import { StatGroup } from '../../../components/stats/StatGroup';
import { analyzeProductMetricSelected } from '../../../metrics/analyzeProduct/analyzeProductMetric.state';
import { analyzeJobDetailsSelectedRow } from '../../store/analyze.state';
import { optimisationSelectedAssignmentDiff } from '../../store/optimisation.state';
import OptimisationReassignJobsTable from './OptimisationReassignJobsTable';
import OptimizationAssignmentDiffTable from './OptimizationAssignmentDiffTable';

const viewOptionsIds = ['diff', 'moves', 'jobs'] as const;
type ViewOptionId = (typeof viewOptionsIds)[number];

const OptimisationReassignPanel: React.FC = () => {
  const { t } = useTranslation('simulation');
  const resetSelectedDiff = useResetRecoilState(
    optimisationSelectedAssignmentDiff,
  );
  const resetSelectedJob = useResetRecoilState(analyzeJobDetailsSelectedRow);
  const [viewAs, setViewAs] = useState<ViewOptionId>('jobs');
  const setProductHeatmapMetric = useSetRecoilState(
    analyzeProductMetricSelected,
  );

  useEffect(() => {
    setProductHeatmapMetric('reassign-task-type');
    // return () => {
    //   setProductHeatmapMetric('visits');
    //   resetSelectedJob();
    //   resetSelectedDiff();
    // };
  }, []);

  const showOptions = _.keyBy(
    [
      {
        key: 'jobs',
        title: t`Show Tasks Plan`,
      },
      {
        key: 'diff',
        title: t`Show Assignment Changes`,
      },
    ],
    o => o.key,
  ) as Record<ViewOptionId, { key: ViewOptionId; title: string }>;

  function onChange(option: ViewOptionId) {
    resetSelectedDiff();
    resetSelectedJob();
    setViewAs(option);
  }

  return (
    <Container col hasOverflowY>
      <StatGroup
        className="z-toolbar"
        title={
          viewAs === 'jobs'
            ? t`Optimisation — Tasks Plan`
            : t`Optimisation — Assignment Changes`
        }
        helpNavTo={'simulation/optimise/simulation-optimise-reassign'}
        titleAction={
          <DropdownSelector
            classNameLabel="text-sm"
            onClick={e => {
              e.stopPropagation();
            }}
            DropAlignRight
            buttonTransparent
            vertical
            panelMode
            w_lg
            value={'...'}
            values={_.keys(showOptions) as ViewOptionId[]}
            renderValue={o => showOptions[o]?.title ?? '...'}
            onChange={async (option: ViewOptionId, e) => {
              e.stopPropagation();
              onChange(option);
            }}
          />
        }
      />
      {viewAs === 'diff' && <OptimizationAssignmentDiffTable />}
      {viewAs === 'jobs' && <OptimisationReassignJobsTable />}
    </Container>
  );
};

export default OptimisationReassignPanel;
