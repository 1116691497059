import Markdoc from '@markdoc/markdoc';
import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { locale } from '../common/formatHelper';
import { AsyncLoadStatus } from '../common/types';
import { LoadingIndicator } from '../components/LoadingIndicator';
import Callout from './Callout';
import ContactSupport from './ContactSupport';
import { markdocComponentsConfig, markdocTagConfig } from './help.config';
import { HelpIndex as HelpId, HelpPathPrefix } from './help.default';

export type HelpContainerProps = {
  children?: React.ReactNode;
  className?: string;
  id: HelpId;
  hasPadding?: boolean;
  sidebarMode?: boolean;
  fullpageMode?: boolean;
};

const HelpContainer: React.FC<HelpContainerProps> = props => {
  const [sourceMd, setSourceMd] = useState(null);
  const [contentStatus, setContentStatus] = useState<AsyncLoadStatus>(
    AsyncLoadStatus.None,
  );
  const [statusCode, setStatusCode] = useState<number>(null);
  const { t } = useTranslation('help');

  const helpId = props.id ? props.id.replace('../', '').replace('.md', '') : '';

  React.useEffect(() => {
    (async () => {
      setContentStatus(AsyncLoadStatus.Loading);
      const localizedPath = `${HelpPathPrefix}/${locale}/${helpId}.md?${nanoid()}`;
      const defaultPath = `${HelpPathPrefix}/en-GB/${helpId}.md?${nanoid()}`;

      let response = await fetch(localizedPath, {
        // headers: { Accept: 'application/text' },
      });

      // if (response.status === 404) {
      if (
        response.status === 404 ||
        response.headers.get('content-type') !== 'text/markdown'
      ) {
        response = await fetch(defaultPath, {
          // headers: { Accept: 'application/text' },
        });
      }

      if (
        response.status === 200 &&
        response.headers.get('content-type') === 'text/html'
      ) {
        setContentStatus(AsyncLoadStatus.Error);
        return;
      }

      if (response.status !== 200) {
        setContentStatus(AsyncLoadStatus.Error);
        return;
      }

      const content = await response.text();
      setSourceMd(content);
      setContentStatus(AsyncLoadStatus.Ok);
      setStatusCode(response.status);
    })();
  }, [props.id]);

  if (statusCode === 404) {
    return (
      <div>
        <Callout panelMode type="suggestion">{t`No Help found`}</Callout>
      </div>
    );
  }

  if (
    contentStatus === AsyncLoadStatus.None ||
    contentStatus === AsyncLoadStatus.Loading
  ) {
    return (
      <LoadingIndicator
        className={classNames(
          'absolute left-0 right-0 m-auto',
          'top-[45%] max-h-28 max-w-10',
          'shadow-2xl',
        )}
        absolute
        selfCenter
        backdrop
        message={t`Loading Help...`}
      />
    );
  }
  if (contentStatus === AsyncLoadStatus.Error) {
    return (
      <div
        className={classNames(
          'flex flex-1 flex-col',
          'items-center justify-center',
        )}
      >
        <Callout panelMode type="suggestion">
          {t`Apologies, the documentation for this topic is being updated`}

          <br />
          <ContactSupport
            message={t`While we are update the documentation, please don't hesitate to contact us.`}
          />
        </Callout>
      </div>
    );
  }

  const tokenizer = new Markdoc.Tokenizer({
    // allowIndentation: true,
    allowComments: true,
  });

  const tokens = tokenizer.tokenize(sourceMd);
  const ast = Markdoc.parse(tokens);
  const content = Markdoc.transform(ast, markdocTagConfig);

  return (
    <article
      data-component="HelpContainer"
      id="HelpContainer"
      className={classNames(
        { 'px-4': props.hasPadding },
        { fullpageMode: props.fullpageMode },
        props.className,
      )}
    >
      {Markdoc.renderers.react(content, React, {
        components: markdocComponentsConfig,
      })}
      {props.children}
    </article>
  );
};

export default HelpContainer;
