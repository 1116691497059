import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { sidebarStateByType } from '../store/sidebar.state';
import ButtonToggle from './actions/ButtonToggle';
import * as Icon from './icons';

export type ButtonMenuProps = {
  children?: React.ReactNode;
  title?: string;
  className?: string;
  collapsible?: boolean;
};

export const ButtonMenuWarehouses = ({
  className,
  children,
  title,
  collapsible,
}: ButtonMenuProps) => {
  const [id] = useState<string>(_.uniqueId());
  const [state, setState] = useRecoilState(
    sidebarStateByType('sidebar-menu-warehouses'),
  );

  const styleIcon =
    'w-12 h-12 mb-1 ltr:ml-1 rtl:mr-1 ltr:mr-2 rtl:ml-2 fill-current';

  return (
    <ButtonToggle
      id={id}
      label={title}
      title={
        collapsible && state.isCollapsed
          ? 'Open —> Warehouse Menu'
          : 'Close —> Warehouse Menu'
      }
      classNameLabel={classNames('flex-1', 'truncate')}
      className={classNames(
        'flex flex-1 items-center md:flex-none',
        'px-2',
        'truncate',
        // 'min-w-64 xl:min-w-96',
        'text-menu-active',
        state.isCollapsed &&
          'hover:text-opacity-70 group-hover:text-opacity-100',
        collapsible ? 'cursor-pointer' : 'cursor-default',
        collapsible ? 'hover:bg-menu-active/10' : '',
        className,
      )}
      onChange={() =>
        collapsible && setState({ ...state, isCollapsed: !state.isCollapsed })
      }
    >
      {collapsible &&
        (!state.isCollapsed ? (
          <Icon.WarehouseMenuOption className={styleIcon} />
        ) : (
          <Icon.WarehouseBoxLeftArrow className={styleIcon} />
        ))}

      {children}
    </ButtonToggle>
  );
};
