import { Transition } from '@headlessui/react';
import { ImportJobImportType } from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { cn } from '../common/utils';
import { Button } from '../components/actions/Button';
import { ButtonLoadMore } from '../components/actions/ButtonLoadMore';
import ButtonSwitchMulti from '../components/actions/ButtonSwitchMulti';
import DropdownSelector from '../components/actions/DropdownSelector';
import * as Icon from '../components/icons';
import { InputSearch } from '../components/inputs/InputSearch';
import { Spacer } from '../components/layout/Spacer';
import UserTag from '../components/UserTag';
import { getDatasetConfigurations } from '../import/store/import.default';
import { CollapsibleId } from '../store/collapsible.default';
import { collapsibleState } from '../store/collapsible.state';
import { warehouseSelectedId } from '../store/warehouse.state';
import {
  showAutoUpdateDateTimeState,
  showHeaderAddButtonState,
  showHelperCardState,
} from './store/dashboard.state';
import {
  DashboardGroupState,
  DashboardOrder,
  DashboardViewMode,
} from './store/dashboard.types';

export type DashboardItemsGroupContainerProps = PropsWithChildren & {
  id: CollapsibleId;
  title: string;
  state: DashboardGroupState;
  className?: string;
  classNameContainerCards?: string;
  classNameContainerList?: string;
  loadingMessage?: string;
  hasScroll?: boolean;
  isLoading?: boolean;
  isSimulation?: boolean;
  showHelperCard?: boolean;
  collapsible?: boolean;
  updateDate?: Date;
  autoUpdate?: boolean;
  datasetJobType?: ImportJobImportType;
  datasetPath?: string;
  actionBar?: React.ReactNode;
  onViewModeChanged: (mode: DashboardViewMode) => void;
  onSortChanged: (mode: DashboardOrder) => void;
  onLoadMoreClick: () => void;
  onAutoUpdateChange?: (value: boolean) => void;
  viewFeatures?: string[];
  onViewFeaturesChanged?: (selectedFeature: string) => void;
  onFilterChange?: (
    filterProps: Pick<DashboardGroupState, 'selectedUserTags' | 'titleFilter'>,
  ) => void;
};

const DashboardItemsGroupContainer: React.FC<
  DashboardItemsGroupContainerProps
> = props => {
  const { t } = useTranslation('app');
  const [state, setState] = useRecoilState(collapsibleState(props.id));
  const [showHelperCard, setShowHelperCard] =
    useRecoilState(showHelperCardState);
  const [showHeaderAddButton, setShowHeaderAddButton] = useRecoilState(
    showHeaderAddButtonState,
  );
  const [showAutoUpdateDateTime, setShowAutoUpdateDateTime] = useRecoilState(
    showAutoUpdateDateTimeState,
  );

  const isClosed = props.collapsible && state.isCollapsed;

  const navigate = useNavigate();
  const warehouseId = useRecoilValue(warehouseSelectedId);

  const importConfigurations = getDatasetConfigurations(t);
  const datasetConfig = importConfigurations.find(
    config => config.jobType === props.datasetJobType,
  );

  const dataTypeIntegrationPath =
    datasetConfig?.getIntegrationPath(warehouseId);

  const toggleOpen = () => {
    setState({
      isCollapsed: !isClosed,
    });
  };

  const [viewOptions, setViewOptions] = useState<
    Map<DashboardViewMode | string, string>
  >(new Map());

  useEffect(() => {
    setViewOptions(
      new Map<DashboardViewMode | string, string>([
        [DashboardViewMode.card, t`View as cards`],
        [DashboardViewMode.list, t`View as list`],
        [DashboardViewMode.debug, t`View advanced stats`],
        [
          'showHelperCard',
          showHelperCard ? t`Hide Helper Card` : t`Show Helper Card`,
        ],
        [
          'showAutoUpdateDateTime',
          showAutoUpdateDateTime ? t`Hide Update Timer` : t`Show Update Timer`,
        ],
      ]),
    );
  }, [showHelperCard, showAutoUpdateDateTime]);

  const sortOptions = new Map<DashboardOrder, string>([
    [DashboardOrder.title, t`Title`],
    [DashboardOrder.createdAsc, t`Created (Oldest)`],
    [DashboardOrder.createdDesc, t`Created (Newest)`],
    [DashboardOrder.updatedAsc, t`Updated (Oldest)`],
    [DashboardOrder.updatedDesc, t`Recent`],
  ]);

  // const canLoadMore =
  //   props.state.totalCount > 0 &&
  //   props.state.loadedCount < props.state.totalCount;

  const handleViewModeChange = selectedOption => {
    if (selectedOption === 'showHelperCard') {
      setShowHelperCard(prevShowHelperCard => !prevShowHelperCard);
      setShowHeaderAddButton(true);
    } else if (selectedOption === 'showAutoUpdateDateTime') {
      setShowAutoUpdateDateTime(
        prevShowAutoUpdateDateTime => !prevShowAutoUpdateDateTime,
      );
    } else {
      props.onViewModeChanged(selectedOption);
    }
  };

  const styleIconButton = classNames(
    'h-5 w-5',
    'fill-current',
    'text-opacity-60 hover:text-menu-active',
    'bg-menu/5 hover:bg-menu-hover',
    'text-menu-text',
    'p-1 mx-0.5',
    'rounded-full',
  );

  const selectedUserTagsSet = new Set(props.state.selectedUserTags);
  const tagsToDisplay = _.uniq([
    ...(props.state.userTags ?? []),
    ...(props.state.selectedUserTags ?? []),
  ]);
  return (
    <>
      <h2
        data-component="TitleSectionDashboard"
        data-label={`button-${props.title || ''}`}
        aria-label={`button-${props.title || ''}`}
        className={classNames(
          'flex items-center',
          'flex-col',
          'bg-app-panel/20 text-app-title',

          'z-1000 sticky top-0',
          'backdrop-saturate-110 backdrop-blur-lg backdrop-filter',
          'px-4 pb-2 pt-4',
          // 'ltr:pl-2 rtl:pr-2',
          isClosed
            ? 'border-app-panel-dark border-b'
            : 'border-app-panel-dark border-b border-opacity-20 shadow-xl',
          { 'cursor-pointer': props.collapsible },
          props.className,
        )}
      >
        <div className="flex w-full flex-1 items-center">
          {props.collapsible ? (
            <button
              type="button"
              className={cn('ltr:mr-2 rtl:ml-2')}
              aria-label={'Button-Collapsible'}
              onClick={e => {
                props.collapsible && toggleOpen();
              }}
            >
              {isClosed ? (
                <Icon.ChevronDualOpenVertical
                  className={classNames(styleIconButton)}
                />
              ) : (
                <Icon.ChevronDualCloseHorizontal
                  className={classNames(styleIconButton)}
                />
              )}
            </button>
          ) : null}

          <InputSearch
            // headerTableMode
            // headerStatMode
            headerTitleMode
            placeholder={props.title}
            value={props.state.titleFilter ?? ''}
            onChange={v =>
              props.onFilterChange({
                titleFilter: v,
              })
            }
          />

          {/* <span
            className={cn('truncate', {
              // 'ltr:pl-4 rtl:pr-4': !props.collapsible,
            })}
            onClick={e => {
              props.collapsible && toggleOpen();
            }}
          >
            {props.title}
          </span> */}
          <ButtonLoadMore
            itemsCount={props.state.loadedCount}
            totalCount={props.state.totalCount}
            onNext={props.onLoadMoreClick}
            isLoading={props.isLoading}
          />
          {props.isSimulation ? null : (
            <>
              {props.datasetPath && (
                <>
                  <Spacer flexspace />
                  <Button
                    label={t`Integrations`}
                    buttonType="secondary"
                    buttonSize="xs"
                    hasIconBefore={
                      <Icon.ImportMap
                        className={classNames('h-5 w-5 fill-current')}
                      />
                    }
                    className={classNames('rounded', 'ltr:mr-4 rtl:ml-4')}
                    // `/wh/i/${warehouseId}/data/${props.datasetPath}/integration`,
                    onPress={() => {
                      navigate(dataTypeIntegrationPath);
                    }}
                  />
                </>
              )}
            </>
          )}

          {props.actionBar}

          {!props.datasetPath && (
            <Spacer flexspace noMarginRule dashboardMode />
          )}

          {_.isDate(props.updateDate) && (
            <div
              className={cn(
                'text-xs',
                'flex flex-col',
                // 'ltr:ml-4 rtl:mr-4'
              )}
            >
              <span
                className={cn('text-minimal uppercase', 'opacity-75')}
              >{t`updated:`}</span>
              <span>
                {new Intl.DateTimeFormat(undefined, {
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: undefined,
                }).format(props.updateDate)}
              </span>
            </div>
          )}
          {showAutoUpdateDateTime && _.isFunction(props.onAutoUpdateChange) && (
            <ButtonSwitchMulti
              buttonType="minimal"
              autoSize
              className={cn('mx-2 text-xs')}
              options={[{ label: t`auto` }, { label: t`off` }]}
              selectedIndex={props.autoUpdate ? 0 : 1}
              onClick={index => props.onAutoUpdateChange(index === 0)}
            />
          )}
          <div className={cn('text-sm', 'ltr:ml-1 rtl:mr-1')}>
            <DropdownSelector
              className={cn('text-xs uppercase')}
              classNameItem="text-xs"
              DropAlignRight
              value={props.state.order}
              values={[...sortOptions.keys()]}
              renderValue={key => sortOptions.get(key)}
              onChange={props.onSortChanged}
              w_lg
            />
          </div>
          <div className={cn('text-base ltr:ml-4 rtl:mr-4')}>
            <DropdownSelector
              className={cn('ltr:ml-2 rtl:mr-2', 'text-xs')}
              w_lg
              vertical
              // headerMode
              DropAlignRight
              value={props.state.viewMode}
              values={[...viewOptions.keys()]}
              renderValue={key => viewOptions.get(key)}
              // onChange={props.onViewModeChanged}
              onChange={handleViewModeChange}
            />
          </div>
        </div>
      </h2>
      {tagsToDisplay.length > 0 && (
        <div
          className={cn(
            'bg-app-panel/10',
            'flex-wrap gap-1',
            'flex items-center',
            'w-full',
            'px-6 pb-3 pt-3',
          )}
        >
          {props.state.selectedUserTags?.length > 0 && (
            <Button
              buttonType="minimal"
              buttonSize="xs"
              hasIconBefore={<Icon.CircleX className="h-4 w-4 fill-current" />}
              label={t`Clear`}
              className={cn(
                '!text-xxs',
                'border-menu-active/50 hover:border-menu-active border',
                'bg-app-background/80 text-menu-active',
                'rounded-full',
                '!py-0.5 !pl-1 ltr:mr-2',
              )}
              onPress={() => {
                props.onFilterChange({
                  selectedUserTags: [],
                });
              }}
            />
          )}
          {_(tagsToDisplay)
            .uniq()
            .sortBy()
            .map(tag => {
              const enabled = selectedUserTagsSet.has(tag);
              return (
                <UserTag
                  key={tag}
                  title={tag}
                  actionable
                  enabled={enabled}
                  noColor
                  className={
                    enabled
                      ? ''
                      : 'opacity-70 hover:opacity-100 hover:shadow-xl'
                  }
                  onClick={() => {
                    const others = _.filter(
                      props.state.selectedUserTags,
                      t => t !== tag,
                    );
                    if (!enabled) {
                      others.push(tag);
                    }
                    props.onFilterChange({
                      selectedUserTags: others,
                    });
                  }}
                />
              );
            })
            .value()}
        </div>
      )}

      <Transition
        show={!isClosed}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
        className={'relative mt-4'}
      >
        {props.state.viewMode === DashboardViewMode.card ||
        props.state.viewMode === DashboardViewMode.debug ? (
          <div
            id={`list-card-container`}
            className={cn(
              'max-w-screen flex flex-wrap',
              'px-4 pb-10',
              'relative',
              props.classNameContainerCards,
            )}
          >
            {props.children}
          </div>
        ) : (
          <div
            id={`list-container`}
            className={cn(
              'flex flex-col',
              'max-w-screen',
              'px-4 pb-10 pt-2',
              'relative',
              props.classNameContainerList,
            )}
          >
            {props.children}
          </div>
        )}
      </Transition>
    </>
  );
};

export default DashboardItemsGroupContainer;
