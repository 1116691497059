import { t } from 'i18next';
import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import * as Icon from '../../../components/icons';
import InputCheckbox from '../../../components/inputs/InputCheckbox';
import { ScreenTitle } from '../../../components/layout/ScreenTitle';
import PanelContainer from '../../../containers/PanelContainer';
import Callout from '../../../helpContext/Callout';

export type PanelProps = PropsWithChildren & {
  className?: string;
};

const PanelSettingsLegal: React.FC<PanelProps> = props => {
  return (
    <PanelContainer
      data-component="PanelSettingsLegal"
      id="panel-system-legal"
      title={t`Legal`}
      collapsible
      // className={cn('className', props.className)}
    >
      <ScreenTitle
        isSticky
        subtitle={t`Legal`}
        title={t`Terms of Service and Privacy Policy`}
      />

      <Callout
        panelMode
        type="suggestion"
        // iconAfter
        // icon={Icon.ArrowRightBottom}
      >
        <Icon.TriangleInfo className="text-alerts-warn h-4 w-4 fill-current ltr:mr-2 rtl:ml-2" />
        {t`Removing the checkboxes will deactivate your account.`}
      </Callout>

      <div className="p-4">
        <fieldset>
          <InputCheckbox
            name="tosConfirmation"
            // isDisabled
            isSelected
            // ref={register}
            className="pt-4 ltr:pl-2 rtl:pr-2"
          >
            <div>
              {t`I agree to`}
              {` `}
              {t`WareBee`}
              {` `}
              <Link to="/legal/terms" className="font-bold underline">
                {t`Terms of Service`}
              </Link>
            </div>
          </InputCheckbox>
        </fieldset>
        <fieldset>
          <InputCheckbox
            name="privacyPolicyConfirmation"
            // isDisabled
            isSelected
            // ref={register}
            className="ltr:pl-2 rtl:pr-2"
          >
            <div>
              {t`I accept WareBee use of my data for the service and everything else described in the `}
              <Link to="/legal/privacy" className="font-bold underline">
                {t`Privacy Policy`}
              </Link>
              .
            </div>
          </InputCheckbox>
        </fieldset>
      </div>
      {props.children}
    </PanelContainer>
  );
};

export default PanelSettingsLegal;
