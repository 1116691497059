import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import PanelContainer from '../../containers/PanelContainer';
import DatasetExtraFieldsSettingsEditor from '../../datasetObject/panels/DatasetExtraFieldsSettingsEditor';
import { DatasetExtraFieldSettings } from '../../datasetObject/store/datasetObject.types';
import { actualityDatasetExtraFieldsSettingsByType } from '../store/actuality.state';
import { ActualityActivityDatasetTypeKey } from '../store/actuality.types';
import { feedDatasetObject } from '../store/feed.state';

/**
 *  Configure Extra Fields of datasets used in actuality,
 *  that can be shown in results and can be used in metrics and filters
 */

const ActualityExtraFieldsSettings: React.FC = () => {
  const { t } = useTranslation('app');

  const activityFeedSummary = useRecoilValue(feedDatasetObject);
  const [fieldsSettings, setFieldsSettings] = useRecoilState(
    actualityDatasetExtraFieldsSettingsByType(ActualityActivityDatasetTypeKey),
  );

  function updateExtraFieldSettings(
    fieldsSettings: DatasetExtraFieldSettings[],
  ) {
    setFieldsSettings({
      datasetType: ActualityActivityDatasetTypeKey,
      fieldsSettings,
    });
  }

  const activityFilterOverrides = fieldsSettings?.fieldsSettings ?? [];

  return (
    <PanelContainer
      id="panel-extra-fields"
      title={t`Extra fields`}
      collapsible
      // hasPadding
    >
      <DatasetExtraFieldsSettingsEditor
        sectionId="activity-extra-fields"
        sectionTitle={t`Activity feed data Fields`}
        prefix={'af_extra'}
        extraFields={activityFeedSummary.summary?.rawDataFields}
        extraFieldsSettings={activityFilterOverrides}
        onChange={updateExtraFieldSettings}
      />
    </PanelContainer>
  );
};

export default ActualityExtraFieldsSettings;
