import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Icon from '../../components/icons';
import { DataDashboardPath } from '../../dashboard/store/dashboard.types';
import { HelpIndex as HelpId, HelpIndex, HelpNavTo } from '../../helpContext/help.default';
import { DatasetType } from '../../import/store/import.types';

interface DataTypeConfig {
  title: string;
  subtitle: string;
  icon: React.FC<React.HTMLAttributes<SVGElement>>;
  breadcrumbPath: string;
  importPath: string;
  dataDashboardPath: DataDashboardPath;
  updateField: string;
  helpNavTo?: HelpNavTo<HelpIndex>;
}

export function getDataTypeConfig(dataType: DatasetType): DataTypeConfig {
  const { t } = useTranslation('app');

  const config: Record<DatasetType, DataTypeConfig> = {
    layout: {
      title: t('Locations Dataset'),
      subtitle: t('Designer'),
      icon: Icon.SimulationVisualize,
      breadcrumbPath: 'layouts',
      importPath: 'layout',
      dataDashboardPath: 'layouts',
      updateField: 'layoutId',
      helpNavTo: 'simulation/dataset/simulation-dataset-layout',
    },
    convertedLayout: {
      title: t('Layout'),
      subtitle: t('Dataset'),
      icon: Icon.SimulationVisualize,
      breadcrumbPath: 'layouts',
      importPath: 'layout',
      dataDashboardPath: 'layouts',
      updateField: 'layoutId',
      helpNavTo: 'simulation/dataset/simulation-dataset-layout',
    },
    assignment: {
      title: t('Assignment'),
      subtitle: t('Dataset'),
      icon: Icon.DataAssignment,
      breadcrumbPath: 'assignments',
      importPath: 'assignment',
      dataDashboardPath: 'assignments',
      updateField: 'assignmentId',
      helpNavTo: 'simulation/dataset/simulation-dataset-assignment',
    },
    assignmentPolicy: {
      title: t('Storage Policy'),
      subtitle: t('Policy'),
      icon: Icon.PolicyAssignment,
      breadcrumbPath: 'assignments',
      importPath: 'assignment',
      dataDashboardPath: 'assignments',
      updateField: 'assignmentPolicyId',
      helpNavTo: 'simulation/dataset/simulation-dataset-assignment',
    },

    orders: {
      title: t('Orders'),
      subtitle: t('Dataset'),
      icon: Icon.DataOrders,
      breadcrumbPath: 'orders',
      importPath: 'orders',
      dataDashboardPath: 'orders',
      updateField: 'orderSetId',
      helpNavTo: 'simulation/dataset/simulation-dataset-orders',
    },
    items: {
      title: t('Items'),
      subtitle: t('Dataset'),
      icon: Icon.DataItems,
      breadcrumbPath: 'items',
      importPath: 'items',
      dataDashboardPath: 'items',
      updateField: 'itemSetId',
      helpNavTo: 'simulation/dataset/simulation-dataset-items',
    },
    activityFeed: {
      title: t('Activity Feed'),
      subtitle: t('Real-time Dataset'),
      icon: Icon.PickingWaveTime,
      breadcrumbPath: 'activity-feed',
      importPath: 'activityFeed',
      dataDashboardPath: 'activity',
      updateField: 'activityFeedId',
      helpNavTo: 'actuality/actuality-getting-started',
    },
    dataset: {
      title: t('Dataset'),
      subtitle: t('Real-time Dataset'),
      icon: Icon.PickingWaveTime,
      breadcrumbPath: 'dataset',
      importPath: 'dataset',
      dataDashboardPath: 'datasets',
      updateField: null,
      helpNavTo: null,
    },
  };

  return (
    config[dataType] || {
      title: '',
      subtitle: '',
      icon: Icon.DataDashboard,
      breadcrumbPath: '',
      importPath: '',
      dataDashboardPath: 'files',
      updateField: '',
    }
  );
}
