import classNames from 'classnames';
import * as H from 'history';
import React, { useId } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useMatch } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { warehouseSelectedId } from '../store/warehouse.state';
import { LabelModesBeta, LabelModesDemo } from './LabelModes';
import * as Icon from './icons';
import LinkTraced from './nav/LinkTraced';

export type AppHeaderMenuItemProps<S = H.LocationState> = {
  children?: React.ReactNode;
  className?: string;
  title: string;
  subtitle?: string;
  icon?: React.ReactNode;
  active?: boolean;
  actionable?: boolean;
  isInBeta?: boolean;
  isDemo?: boolean;
  project_state?: string;
  action_path?: string;
  url_path?: string;
  to?: string;
  exact?: boolean;
  exactActive?: boolean;
  showIfActive?: boolean;
  hidden?: boolean;
  small?: boolean;
  widthFull?: boolean;
  widthCustom?: string;
}; //& Parameters<Link<S>>;

export default function AppHeaderMenuItem({
  className,
  title,
  subtitle,
  isInBeta,
  isDemo,
  hidden,
  icon,
  to,
  children,
  actionable,
  action_path,
  small,
  widthFull,
  widthCustom,
  exactActive = false,
  showIfActive,
}: AppHeaderMenuItemProps) {
  const { t } = useTranslation('app');
  const warehouseSelected = useRecoilValue(warehouseSelectedId);
  const id = useId();
  const match = useMatch(`${to}/*`);
  const active = Boolean(match);

  return (
    <LinkTraced
      data-component="AppHeaderMenuItem"
      data-label={`app-header-menu-${title || ''}`}
      aria-label={`app-header-menu-${title || ''}`}
      titleTrace={`Menu (Header): ${title || 'AppHeaderMenuItem'}`}
      id={id}
      //{...linkProps}
      to={warehouseSelected === 'new' ? 'new' : to}
      className={classNames(
        'px-1 py-2 md:px-2 xl:px-4',
        'overflow-hidden',
        'h-16',
        hidden && !active ? 'hidden' : 'columns-xs:hidden md:flex',
        'group flex-1 items-center',
        'border-app-panel-dark/20 ltr:border-l rtl:border-r',
        active
          ? 'bg-menu-active text-menu-active-text'
          : 'text-app-header-text/85 hover:bg-menu-active/70 hover:text-menu-active-text',
        widthFull
          ? 'w-full'
          : widthCustom
            ? widthCustom
            : 'xl:max-w-46 min-w-28 max-w-40 flex-1 xl:min-w-40',
        'transition-all duration-300 ease-in-out',
        'truncate',
        className,
      )}
    >
      {icon && (
        <div
          data-component="AppHeaderMenuIcon"
          className={classNames('ltr:mr-1 rtl:ml-1', 'flex items-center')}
        >
          {icon}
        </div>
      )}
      {/* {warehouseSelected === 'new' ? 'new hidden' : ''} */}

      <div className={classNames('font-title flex flex-1 flex-col truncate')}>
        {subtitle && (
          <div
            data-component="AppHeaderMenuSubTitle"
            className={classNames(
              'mb-0.5 flex items-center truncate text-xs',
              active
                ? 'font-bold text-opacity-100'
                : 'text-opacity-90 group-hover:text-opacity-100',
            )}
          >
            {subtitle}
            {isDemo && (
              <LabelModesDemo active={active} title={t`Demo`} type="DEMO" />
            )}

            {isInBeta && (
              <LabelModesBeta active={active} title={t`Beta`} type="BETA" />
            )}
          </div>
        )}
        <div
          className={classNames(
            'truncate',
            'font-bold',
            small
              ? 'text-sm'
              : 'text-lg sm:text-lg lg:text-xl xl:text-xl 2xl:text-2xl',
            active
              ? 'text-opacity-100'
              : 'text-opacity-50 group-hover:text-opacity-100',
          )}
        >
          {title}
        </div>
      </div>
      {actionable && (
        <Link to={{ pathname: action_path }}>
          <Icon.BoxPlus
            className={classNames(
              'h-10 w-10',
              'ltr:ml-2 rtl:mr-2',
              'text-menu-active fill-current',
            )}
          />
        </Link>
      )}
    </LinkTraced>
  );
}
