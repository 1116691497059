import {
  AllocationRunResultRequirementsFilter,
  LoadAllocationSummaryDocument,
  useRunExportJobMutation,
} from '@warebee/frontend/data-access-api-graphql';
import {
  AllocationSummaryConverterConfig,
  AllocationSummaryExportJobParams,
  allocationSummaryExportJobParams,
  getAllocationSummaryTableRows,
} from '@warebee/shared/export-converter';
import { generateExportFilename } from 'libs/shared/export-converter/src/lib/export-filename.helper';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { AsyncLoadStatus } from '../../../common/types';
import { cn } from '../../../common/utils';
import DatasetTable from '../../../components/DatasetTable';
import ButtonSwitchMulti, {
  ButtonSwitchMultiOption,
} from '../../../components/actions/ButtonSwitchMulti';
import DropdownSelector from '../../../components/actions/DropdownSelector';
import { Container } from '../../../components/layout/ContainerFlex';
import { Spacer } from '../../../components/layout/Spacer';
import { ActionBar } from '../../../components/nav/ActionBar';
import { analyzeProductMetricSelected } from '../../../metrics/analyzeProduct/analyzeProductMetric.state';
import { getPolicyMatchInput } from '../../../policyFilters/policyFilter.helper';
import { brandName } from '../../../store/global.state';
import {
  warehouseSelected,
  warehouseSelectedId,
} from '../../../store/warehouse.state';

import { DatasetObjectFieldSummary } from '../../../components/DatasetFieldsSummary';
import {
  getAllocationMetricDescriptors,
  getAllocationSummaryGroutByOptions,
  getDeallocatedReasonOptions,
  getUnallocatedReasonOptions,
} from '../../store/allocation/allocation.helper';
import {
  allocationSummaryData,
  allocationSummaryDataLoadStatus,
  allocationSummaryGroupBy,
  allocationSummarySelectedMetric,
  allocationSummaryTableState,
} from '../../store/allocation/allocation.state';
import {
  allocationPolicyRule,
  allocationPolicySelectedIdentity,
} from '../../store/allocationPolicy/allocationPolicy.state';
import {
  simulationCurrent,
  simulationCurrentId,
} from '../../store/simulation.state';
import useAllocationSummaryTableConfig from './useAllocationSummaryTableConfig';
import useLoadAllocationSummary from './useLoadAllocationSummary';

/**
 *
 *
 * @return {*}
 */
const AllocationSummaryView: React.FC = () => {
  const { t } = useTranslation('simulation');
  const simulationId = useRecoilValue(simulationCurrentId);
  const sim = useRecoilValue(simulationCurrent);
  const brandTitleState = useRecoilValue(brandName);
  const warehouse = useRecoilValue(warehouseSelected);
  const warehouseIdState = useRecoilValue(warehouseSelectedId);

  const summaryData = useRecoilValue(allocationSummaryData);
  const [loadCallback, cancelLoad] = useLoadAllocationSummary();
  const loadStatus = useRecoilValue(allocationSummaryDataLoadStatus);
  const [state, setState] = useRecoilState(allocationSummaryTableState);
  const [runExportCSV] = useRunExportJobMutation();
  const [selectedGroupBy, setSelectedGroupBy] = useRecoilState(
    allocationSummaryGroupBy,
  );
  const [selectedMetric, setSelectedMetric] = useRecoilState(
    allocationSummarySelectedMetric,
  );
  const setHeatmapMetric = useSetRecoilState(analyzeProductMetricSelected);
  const metricDescriptors = getAllocationMetricDescriptors(t);
  const metricDescriptorsArray = _.values(metricDescriptors);
  const groupByOptions = getAllocationSummaryGroutByOptions(t);
  const selectedGroupByDescriptor = groupByOptions[selectedGroupBy];

  const allocationSelectedIdentity = useRecoilValue(
    allocationPolicySelectedIdentity,
  );
  const allocationRule = useRecoilValue(
    allocationPolicyRule(allocationSelectedIdentity?.ruleId),
  );

  const metricDescriptor = metricDescriptors[selectedMetric];
  const columnsConfig = useAllocationSummaryTableConfig({
    key: selectedGroupBy,
    metric: metricDescriptor,
  });
  const { searchValues, sortValues } = state;

  function getFilter(): AllocationRunResultRequirementsFilter {
    return {
      consigneeContains: searchValues?.consignee,
      skuContains: searchValues?.sku,
      skuGroupContains: searchValues?.skuGroup,
      itemsMatch: allocationRule
        ? getPolicyMatchInput(allocationRule?.itemsMatch)
        : null,
    };
  }

  useEffect(() => {
    setHeatmapMetric(selectedGroupByDescriptor.heatmapMetric);
    cancelLoad();

    loadCallback({
      simulationId,
      groupBy: selectedGroupByDescriptor.groupBy,
      filter: getFilter(),
    });
  }, [selectedGroupBy, searchValues, allocationRule]);

  const converterConfig: AllocationSummaryConverterConfig = {
    columnsConfig,
    filters: {
      columnShouldHasValues: metricDescriptor.columns,
      columnsShouldBeEmpty: metricDescriptor.emptyColumns,
      extraFilters: {
        unallocatedReason: _.some(
          columnsConfig,
          c => c.field === 'unAllocatedReasons',
        )
          ? searchValues.unAllocatedReasons
          : null,
        deallocatedReason: _.some(
          columnsConfig,
          c => c.field === 'deallocatedReasons',
        )
          ? searchValues.deallocatedReasons
          : null,
      },
    },
    sortValues,
  };

  async function startExportCSV() {
    const filename = generateExportFilename({
      exportTitle: t`allocation`,
      brandTitleState,
      warehouse,
      warehouseIdState,
      sim,
    });

    const variables: AllocationSummaryExportJobParams = {
      ...allocationSummaryExportJobParams,
      query: LoadAllocationSummaryDocument.loc.source.body,
      config: converterConfig,
      variables: {
        simulationId,
        groupBy: selectedGroupByDescriptor.groupBy,
      },
      filename: `${filename}.csv`,
    };
    const { data, errors } = await runExportCSV({
      variables,
    });

    return {
      errors: errors,
      job: data.createExportJob,
    };
  }

  const flattenData = getAllocationSummaryTableRows(
    summaryData,
    converterConfig,
  );

  const fieldsSummary: DatasetObjectFieldSummary[] = [
    {
      name: 'consignee',
      distinctValueCount: _.uniq(_.map(flattenData, 'consignee')).length,
    },
    {
      name: 'sku',
      distinctValueCount: _.uniq(_.map(flattenData, 'sku')).length,
    },
    {
      name: 'initiallyPickableLocationCount',
      totalSum: _.sumBy(flattenData, row => row.initiallyPickableLocationCount),
    },
    {
      name: 'allocatedLocationCount',
      totalSum: _.sumBy(flattenData, 'allocatedLocationCount'),
    },
    {
      name: 'allocatedSharedLocationCount',
      totalSum: _.sumBy(
        flattenData,
        row => row.allocatedSharedLocationCount ?? 0,
      ),
    },
    {
      name: 'deallocatedLocationCount',
      totalSum: _.sumBy(flattenData, row => row.deallocatedLocationCount ?? 0),
    },
    {
      name: 'initiallyPickableFulfilledQty',
      totalSum: _.sumBy(
        flattenData,
        row => row.initiallyPickableFulfilledQty ?? 0,
      ),
    },
    {
      name: 'requiredQty',
      totalSum: _.sumBy(flattenData, row => row.requiredQty ?? 0),
    },
    {
      name: 'toAllocateRequiredQty',
      totalSum: _.sumBy(flattenData, row => row.toAllocateRequiredQty ?? 0),
    },
    {
      name: 'toAllocateQty',
      totalSum: _.sumBy(flattenData, row => row.toAllocateQty ?? 0),
    },
    {
      name: 'allocatedFulfilledQty',
      totalSum: _.sumBy(flattenData, row => row.allocatedFulfilledQty ?? 0),
    },
    {
      name: 'unAllocatedQty',
      totalSum: _.sumBy(flattenData, row => row.unAllocatedQty ?? 0),
    },
    {
      name: 'toReallocateQty',
      totalSum: _.sumBy(flattenData, row => row.toReallocateQty ?? 0),
    },
    {
      name: 'deallocatedQty',
      totalSum: _.sumBy(flattenData, row => row.deallocatedQty ?? 0),
    },
    {
      name: 'allocatedSharedQty',
      totalSum: _.sumBy(flattenData, row => row.allocatedSharedQty ?? 0),
    },
    {
      name: 'unpickableQty',
      totalSum: _.sumBy(flattenData, row => row.unpickableQty ?? 0),
    },
    {
      name: 'unAllocatedReasons',
      valueCounters: _.reduce(
        getUnallocatedReasonOptions(t),
        (acc, reasonDesc) => ({
          ...acc,
          [reasonDesc.title]: _.sumBy(flattenData, row =>
            _.some(row.unAllocatedReasons, r => r === reasonDesc.id) ? 1 : 0,
          ),
        }),
        {},
      ),
    },
    {
      name: 'deallocatedReasons',
      valueCounters: _.reduce(
        getDeallocatedReasonOptions(t),
        (acc, reasonDesc) => ({
          ...acc,
          [reasonDesc.title]: _.sumBy(flattenData, row =>
            _.some(row.deallocatedReasons, r => r === reasonDesc.id) ? 1 : 0,
          ),
        }),
        {},
      ),
    },
  ];
  console.log('fieldsSummary', fieldsSummary);
  const isLoading = loadStatus === AsyncLoadStatus.Loading;

  const metricSwitchOptions: ButtonSwitchMultiOption[] = _.map(
    metricDescriptorsArray,
    d => ({ label: d.title }),
  );

  const selectedMetricIndex = _.findIndex(
    metricDescriptorsArray,
    o => o.type === selectedMetric,
  );

  const itemsCount = flattenData?.length ?? 0;
  const totalCount = flattenData?.length ?? 0;

  return (
    <>
      <ActionBar
        className={cn(
          'flex flex-col items-center lg:flex-row',
          'px-1 py-2',
          'space-y-2 lg:space-y-0',
        )}
      >
        <div className="hidden w-full flex-1 2xl:block">
          <ButtonSwitchMulti
            buttonType="minimal"
            className={cn('w-full flex-1')}
            autoSize
            options={metricSwitchOptions}
            selectedIndex={selectedMetricIndex}
            onClick={index => {
              setSelectedMetric(metricDescriptorsArray[index].type);
              setState(current => ({
                ...current,
                sortValues: metricDescriptorsArray[index].defaultSort
                  ? metricDescriptorsArray[index].defaultSort
                  : current.sortValues,
              }));
            }}
          />
        </div>
        <div className="w-full flex-1 2xl:hidden">
          <DropdownSelector
            panelMode
            className={cn('mx-0 2xl:mx-2 2xl:mt-0', 'w-full', 'text-xs')}
            value={selectedMetric}
            widthFull
            values={metricDescriptorsArray.map(d => d.type)}
            onChange={v => {
              setSelectedMetric(v);
              setState(current => ({
                ...current,
                sortValues: metricDescriptors[v].defaultSort
                  ? metricDescriptors[v].defaultSort
                  : current.sortValues,
              }));
            }}
            renderValue={v => metricDescriptors[v].title}
          />
        </div>
        <Spacer flexspace className="hidden max-w-6 lg:block" />
        <DropdownSelector
          // label={t`Group By`}
          panelMode
          className={cn('mx-0 2xl:mx-2', 'w-full', 'text-xs', 'flex-1')}
          value={selectedGroupBy}
          DropAlignRight
          widthFull
          values={_.map(groupByOptions, o => o.type)}
          onChange={v => {
            setSelectedGroupBy(v);
            setState(current => ({ ...current, searchValues: {} }));
          }}
          renderValue={o => groupByOptions[o].title}
        />
      </ActionBar>

      {/* <div className="h-full overflow-hidden">
        <AllocationSummaryChart
          data={chartData}
          selectedGroup={selectedGroupByDescriptor}
          selectedMetric={metricDescriptor}
          isLoading={isLoading}
        />
      </div> */}

      <Container fullHeight col>
        <DatasetTable
          id={'allocation-summary-table'}
          data={flattenData}
          columnsConfig={columnsConfig}
          keyFields={selectedGroupByDescriptor.keyColumns}
          isActionable
          hideScreenTitle={true}
          isSticky
          onLoadNext={_.noop}
          onSearch={searchValues => setState({ ...state, searchValues })}
          totalCount={totalCount}
          searchValues={searchValues}
          sortBy={sortValues}
          onSort={sortValues => setState({ ...state, sortValues })}
          isLoading={isLoading}
          onStartExportClick={startExportCSV}
          hasCounter
          fieldsSummary={fieldsSummary}
        />
      </Container>
    </>
  );
};

export default AllocationSummaryView;
