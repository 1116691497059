import {
  Field,
  ID,
  InputAndObjectType,
  registerEnumType,
} from '@warebee/shared/util-backend-only-types';
import {
  Filter,
  FilterBase,
  FilterIntersection,
  FilterUnion,
} from './filter.model';

export enum OrderLineFilterType {
  // Order match filters
  CONSIGNEE = 'CONSIGNEE',
  CUSTOMER = 'CUSTOMER',
  ORDER_TIME_MATCH = 'ORDER_TIME_MATCH',
  DELIVERY_ROUTE = 'DELIVERY_ROUTE',
  DOCK = 'DOCK',
  STAGING_AREA = 'STAGING_AREA',
  WAVE_ID = 'WAVE_ID',
  SHIPMENT_ID = 'SHIPMENT_ID',

  // Item match filters
  SKU = 'SKU',
  SKU_GROUP = 'SKU_GROUP',
  SUB_GROUP = 'SUB_GROUP',
  TRANSPORT_CLASS = 'TRANSPORT_CLASS',
  STOCK_CATEGORY = 'STOCK_CATEGORY',
  STORAGE_CLASS = 'STORAGE_CLASS',
  POLLUTION_CLASS = 'POLLUTION_CLASS',

  // Item range filters
  NET_WEIGHT = 'NET_WEIGHT',
  WIDTH = 'WIDTH',
  LENGTH = 'LENGTH',
  HEIGHT = 'HEIGHT',
  VOLUME = 'VOLUME',

  // order line range filters
  ORDER_LINE_QUANTITY = 'ORDER_LINE_QUANTITY',
  ORDER_LINE_VOLUME = 'ORDER_LINE_VOLUME',
  ORDER_LINE_WEIGHT = 'ORDER_LINE_WEIGHT',

  // order range filters
  ORDER_LINE_COUNT = 'ORDER_LINE_COUNT',
  ORDER_TOTAL_VOLUME = 'ORDER_TOTAL_VOLUME',
  ORDER_TOTAL_WEIGHT = 'ORDER_TOTAL_WEIGHT',

  // rank filters
  PERCENT_RANK = 'PERCENT_RANK',
  CUMULATIVE_PERCENT_RANK = 'CUMULATIVE_PERCENT_RANK',
}

registerEnumType(OrderLineFilterType, {
  name: 'OrderLineFilterType',
});

@InputAndObjectType()
export class OrderLineFilter
  extends FilterBase
  implements Filter<OrderLineFilterType>
{
  @Field(() => OrderLineFilterType)
  type: OrderLineFilterType;
}

@InputAndObjectType()
export class OrderLineFilterIntersection
  implements FilterIntersection<OrderLineFilter>
{
  @Field(() => ID, { nullable: true })
  id?: string;

  @Field(() => [OrderLineFilter])
  allOf: OrderLineFilter[];
}

@InputAndObjectType()
export class OrderLineFilterUnion implements FilterUnion<OrderLineFilter> {
  @Field(() => [OrderLineFilterIntersection])
  anyOf: OrderLineFilterIntersection[];
}
