import { InferResult } from 'kysely';
import { ActivityFeedQueryBuilderParams } from './queryBuilder/feedEventsQueryBuilders';
import { feedExtendedEventsQueryBuilder } from './queryBuilder/feedExtendedEventsQueryBuilder';

export type FeedItemsWithRank = InferResult<
  ReturnType<typeof getFeedItemsWithRanksQuery>
>;
export type FeedItemWithRank = FeedItemsWithRank[number];

export const getFeedItemsWithRanksQuery = (
  params: ActivityFeedQueryBuilderParams,
) => {
  return feedExtendedEventsQueryBuilder(params)
    .with(
      cte => cte('items'),
      db => {
        return db
          .selectFrom('af')
          .select(['skuKey', 'consignee', 'sku'])
          .groupBy(['skuKey', 'consignee', 'sku']);
      },
    )

    .selectFrom('items')
    .leftJoin('item_stats', join =>
      join.onRef('items.skuKey', '=', 'item_stats.skuKey'),
    )
    .select([
      'items.skuKey',
      'consignee',
      'sku',
      'orderLineCount',
      'cumulativePercentRank',
      'percentRank',
    ]);
};
