import {
  FindSimulationItemsByFilterDocument,
  useRunExportJobMutation,
} from '@warebee/frontend/data-access-api-graphql';
import {
  ProductsByRuleConverterConfig,
  productsByRuleExportJobParams,
  ProductsByRuleExportJobParams,
} from '@warebee/shared/export-converter';
import { generateExportFilename } from 'libs/shared/export-converter/src/lib/export-filename.helper';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import useLoadAssignmentPolicyProductsByRule from '../assignmentPolicy/useLoadAssignmentPolicyProductsByRule';
import { DataPageParams } from '../common/types';
import useFormatter from '../common/useFormatter';
import { PanelHeaderProps } from '../components/designer/panels/PanelHeader';
import InboxZero from '../components/InboxZero';
import {
  getProductCategories,
  getProductCategoryDescriptorExport,
} from '../simulation/store/abc/simulation.ABC.helper';
import { getItemFilterMatchings } from '../simulation/store/assignmentPolicy.helper';
import {
  assignmentPolicyDocument,
  assignmentPolicyRule,
  assignmentPolicySelectedIdentity,
} from '../simulation/store/assignmentPolicy.state';
import {
  simulationCurrent,
  simulationCurrentId,
} from '../simulation/store/simulation.state';
import SimulationProductByRuleTableView, {
  SimulationProductByRuleTableViewProps,
} from '../simulation/tableViews/SimulationProductByRuleTableView';
import { getSimulationProductTableConfigDefault } from '../simulation/tableViews/simulationProductTable.config';
import { brandName } from '../store/global.state';
import {
  warehouseSelected,
  warehouseSelectedId,
} from '../store/warehouse.state';

export type AssignmentPolicyProductByRuleTableViewProps = {
  panelHeader?: PanelHeaderProps;
};

const AssignmentPolicyProductByRuleTableView: React.FC<
  AssignmentPolicyProductByRuleTableViewProps
> = props => {
  const { t } = useTranslation('simulation');
  const sim = useRecoilValue(simulationCurrent);
  const brandTitleState = useRecoilValue(brandName);
  const warehouse = useRecoilValue(warehouseSelected);
  const warehouseIdState = useRecoilValue(warehouseSelectedId);

  const simulationId = useRecoilValue(simulationCurrentId);
  const policy = useRecoilValue(assignmentPolicyDocument);
  const selectedDetails = useRecoilValue(assignmentPolicySelectedIdentity);
  const rule = useRecoilValue(assignmentPolicyRule(selectedDetails?.ruleId));
  const [loadCallback, cancelLoad] = useLoadAssignmentPolicyProductsByRule();
  const [runExportCSV] = useRunExportJobMutation();
  const formatter = useFormatter();

  function callDataLoad(page?: DataPageParams) {
    cancelLoad();
    if (
      _.isNil(selectedDetails?.ruleId) ||
      selectedDetails?.filterType !== 'items'
    )
      return;

    loadCallback({
      policy,
      selectedDetails,
      page,
    });
  }

  // update data when policy changed
  useEffect(() => {
    callDataLoad();
  }, [policy, selectedDetails]);

  // cancel requests when unmount
  useEffect(() => {
    cancelLoad();
  }, []);

  const productCategories = getProductCategories(t);
  const columnsConfig = getSimulationProductTableConfigDefault(t, formatter);

  const converterConfig: ProductsByRuleConverterConfig = {
    columnsConfig,
    dictionaries: {
      categories: getProductCategoryDescriptorExport(productCategories),
    },
  };

  async function startExportCSV() {
    const filename = generateExportFilename({
      exportTitle: t`policy-storage-items`,
      brandTitleState,
      warehouse,
      warehouseIdState,
      sim,
    });
    const variables: ProductsByRuleExportJobParams = {
      ...productsByRuleExportJobParams,
      query: FindSimulationItemsByFilterDocument.loc.source.body,
      config: converterConfig,
      variables: {
        input: {
          simulationId,
          ...getItemFilterMatchings(policy, selectedDetails),
        },
        page: null,
      },
      filename: `${filename}.csv`,
    };

    const { data, errors } = await runExportCSV({
      variables,
    });

    return {
      errors: errors,
      job: data.createExportJob,
    };
  }

  if (!selectedDetails?.ruleId)
    return (
      <InboxZero
        selfCenter
        hasIcon
        message={t`No Rule selected`}
        // message_helper="Select Orders Dataset"
      />
    );

  const viewConfig: SimulationProductByRuleTableViewProps = {
    id: rule?.id,
    title: rule?.title,
    onChange: callDataLoad,
    onExport: startExportCSV,
    panelHeader: props.panelHeader,
  };

  return <SimulationProductByRuleTableView {...viewConfig} />;
};

export default AssignmentPolicyProductByRuleTableView;
