import classNames from 'classnames';
import { t } from 'i18next';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { cn } from '../common/utils';
import ContactSupport from '../helpContext/ContactSupport';
import { brandName } from '../store/global.state';
import { AppFooter } from './AppFooter';
import { AppVersion } from './AppVersion';
import LoadingIndicator from './LoadingIndicator';

export type LoadingWindowProps = {
  children?: React.ReactNode;
  className?: string;
  message?: string;
};

export const LoadingAppWindow = (props: LoadingWindowProps) => {
  const brandTitle = useRecoilValue(brandName);

  return (
    <div
      data-component="LoadingAppWindow"
      className={cn(
        'flex flex-col items-center',
        'h-full overflow-hidden',
        'bg-app-background',
        props.className,
      )}
    >
      <LoadingIndicator
        selfCenter
        message={props.message ?? t('{{brandTitle}} Loading', { brandTitle })}
      />
      {props.children}
      <AppFooter
        className={classNames(
          'px-6 py-4',
          // 'bg-app-background',
          'flex items-end',
        )}
      >
        <div className={classNames('flex flex-1 text-xs')}>
          <ContactSupport className="flex-1" />
        </div>
        <div className={classNames('text-center')}>
          <AppVersion />
        </div>
        <div className={classNames('flex-1')}></div>
      </AppFooter>
    </div>
  );
};

export default LoadingAppWindow;
