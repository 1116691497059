import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { AsyncLoadStatus } from '../../../common/types';
import ButtonSwitchMulti from '../../../components/actions/ButtonSwitchMulti';
import {
  warehouseSelected,
  warehouseUpdateLoadStatus,
} from '../../../store/warehouse.state';
import {
  LiveFeedFeatureSettings,
  WarehouseExtraSettings,
} from '../../../store/warehouse.types';
import useUpdateWarehouseExtraSettings from '../../../warehouse/hooks/useUpdateWarehouseExtraSettings';

const SettingsServiceOptionsActuality: React.FC = () => {
  const { t } = useTranslation('app');
  const warehouse = useRecoilValue(warehouseSelected);
  const status = useRecoilValue(warehouseUpdateLoadStatus);
  const extraSettings: WarehouseExtraSettings = warehouse?.extraSettings;
  const feedSettings = extraSettings?.liveFeed;

  const [updateFeedSettings] = useUpdateWarehouseExtraSettings();
  if (!warehouse) {
    return null; // or loading indicator
  }

  const isLoading = status === AsyncLoadStatus.Loading;

  const serviceOptions = [
    {
      key: 'activity-feed-enabled',
      label: t`On`,
    },
    {
      key: 'activity-feed-disabled',
      label: t`Off`,
    },
  ];

  // If feedSettings doesn't exist, enable it on component mount
  useEffect(() => {
    if (feedSettings === undefined) {
      const newFeedSettings: LiveFeedFeatureSettings = {
        enabled: true,
        jobId: undefined,
        layoutId: undefined,
      };
      updateFeedSettings({ patch: { liveFeed: newFeedSettings } });
    }
  }, [feedSettings, updateFeedSettings]);

  const handleChange = (index: number) => {
    const enabled = index === 0; // 0 for enabled, 1 for disabled
    const newFeedSettings: LiveFeedFeatureSettings = {
      enabled,
      jobId: feedSettings?.jobId,
      layoutId: feedSettings?.layoutId,
    };
    updateFeedSettings({ patch: { liveFeed: newFeedSettings } });
  };

  const selectedIndex = feedSettings?.enabled ? 0 : 1;

  return (
    <section
      className={classNames(
        'w-full',
        'mb-4',
        'divide-menu/50 divide-y divide-solid',
        'p-4',
      )}
    >
      <ButtonSwitchMulti
        title={t`Activity Feed`}
        buttonType="switchon"
        autoSize
        className="text-xs ltr:pr-1 rtl:pl-1"
        selectedIndex={selectedIndex}
        onClick={handleChange}
        options={serviceOptions}
        disabled={isLoading}
      />
    </section>
  );
};

export default SettingsServiceOptionsActuality;
