import { Editor } from '@monaco-editor/react';
import { t } from 'i18next';
import { editor } from 'monaco-editor';
import 'monaco-sql-languages/esm/languages/trino/trino.contribution';
import { useState } from 'react';
import { cn } from '../../common/utils';
import InboxZero from '../InboxZero';
import LoadingIndicator from '../LoadingIndicator';
import { codeEditorWarebeeThemeId } from './CodeEditorWarebeeTheme';

export type CodeEditorProps = {
  className?: string;
  value: string;
  onChange?: (value: string | undefined) => void;
  language: 'json' | 'trinosql' /*LanguageIdEnum.TRINO */;
  placeholder?: string;
  height?: string;
  minHeight?: string;
  editorOptions?: editor.IStandaloneEditorConstructionOptions;
};

const editorOptionsDefault: editor.IStandaloneEditorConstructionOptions = {
  minimap: { enabled: false },
  scrollBeyondLastLine: false,
  fontSize: 14,
  readOnly: false,
  lineNumbers: 'on',
  roundedSelection: false,
  scrollbar: { vertical: 'visible', horizontal: 'visible' },
  wordWrap: 'on',
  automaticLayout: true,
  padding: {
    top: 16,
    bottom: 48,
  },
  formatOnPaste: true,
  suggest: {
    showFields: true,
    showFunctions: true,
  },
  bracketPairColorization: {
    enabled: false,
  },
  tabSize: 4,
  renderLineHighlight: 'none',
  hideCursorInOverviewRuler: true,
  overviewRulerLanes: 0,
  overviewRulerBorder: false,
  folding: true,
};

const CodeEditor = ({
  value,
  onChange,
  language,
  height = '100%',
  className,
  minHeight = '200px',
  editorOptions = {},
}: CodeEditorProps) => {
  const [error, setError] = useState<Error | null>(null);

  const handleEditorValidation = (markers: any[]) => {
    // Handle validation markers if needed
    markers.forEach(marker => {
      //   console.log('Validation:', marker.message);
    });
  };

  if (error) {
    return (
      <InboxZero message={t`Failed to load. Please try refreshing the page.`} />
    );
  }

  return (
    <div
      className={cn(
        'relative h-full',
        'flex flex-1',
        'overflow-hidden',
        className,
      )}
    >
      <Editor
        defaultLanguage={language}
        language={language}
        value={value}
        onChange={onChange}
        className={minHeight ? `min-h-[${minHeight}]` : `min-h-[200px]`}
        loading={<LoadingIndicator message={t`Loading Query...`} selfCenter />}
        height={height}
        // automaticHeight
        options={{
          ...editorOptionsDefault,
          ...editorOptions,
        }}
        theme={codeEditorWarebeeThemeId}
      />
    </div>
  );
};

export default CodeEditor;
