import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import {
  APP_VER,
  BUILD_ENV,
  BUILD_HASH,
  BUILD_ID,
  BUILD_TIMESTAMP,
} from '../../../common/environmentVariables';
import { cn } from '../../../common/utils';
import KeyValueItem from '../../../components/KeyValueItem';
import { ScreenTitle } from '../../../components/layout/ScreenTitle';
import PanelContainer from '../../../containers/PanelContainer';
import Callout from '../../../helpContext/Callout';
import { brandName } from '../../../store/global.state';

export type PanelProps = PropsWithChildren & {
  className?: string;
};

const PanelSystemInfo: React.FC<PanelProps> = props => {
  const { t } = useTranslation('app');

  const brandTitle = useRecoilValue(brandName);

  return (
    <PanelContainer
      data-component="PanelSystemInfo"
      id="panel-system-info"
      title={t`System Info`}
      collapsible
      className={cn(props.className)}
    >
      <ScreenTitle isSticky subtitle={t`About`} title={t`WareBee`} />
      <Callout panelMode type="suggestion">
        {t('{{ brandTitle }} is running version:', { brandTitle })}
        <div className="flex items-center py-4">
          <div className="select-text">{APP_VER}</div>
        </div>
        <ul className="divide-menu-50/10 flex flex-col space-y-2 divide-y p-4 font-mono text-xs">
          <li className="w-full">
            <KeyValueItem
              keyName={`Timestamp`}
              keyValue={BUILD_TIMESTAMP}
              isDisabled={true}
              isCopyableValueOnly
              style={{ inline: true, noStyle: true }}
              classNameKeyName="text-menu-text/75 min-w-[10ch] justify-end"
              classNameKeyValue="text-md text-menu-text monospace"
              className="py-0.5"
            />
          </li>
          <li className="w-full">
            <KeyValueItem
              keyName={`Hash`}
              keyValue={BUILD_HASH}
              isDisabled={true}
              isCopyableValueOnly
              style={{ inline: true, noStyle: true }}
              classNameKeyName="text-menu-text/75 min-w-[10ch] justify-end"
              classNameKeyValue="text-md text-menu-text monospace"
              className="py-0.5"
            />
          </li>
          <li className="w-full">
            <KeyValueItem
              keyName={`ENV`}
              keyValue={BUILD_ENV}
              isDisabled={true}
              isCopyableValueOnly
              style={{ inline: true, noStyle: true }}
              classNameKeyName="text-menu-text/75 min-w-[10ch] justify-end"
              classNameKeyValue="text-md text-menu-text monospace"
              className="py-0.5"
            />
          </li>
          <li className="w-full">
            <KeyValueItem
              keyName={`Build ID`}
              keyValue={BUILD_ID}
              isDisabled={true}
              isCopyableValueOnly
              style={{ inline: true, noStyle: true }}
              classNameKeyName="text-menu-text/75 min-w-[10ch] justify-end"
              classNameKeyValue="text-md text-menu-text monospace"
              className="py-0.5"
            />
          </li>
        </ul>
      </Callout>
      {props.children}
    </PanelContainer>
  );
};

export default PanelSystemInfo;
