import classNames from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import CodeEditor from '../../../components/editors/CodeEditor';
import * as Icon from '../../../components/icons';
import TitleSection from '../../../components/layout/TitleSection';
import Tag from '../../../helpContext/Tag';
import { warehouseSelected } from '../../../store/warehouse.state';
import useUpdateWarehouseExtraSettings from '../../../warehouse/hooks/useUpdateWarehouseExtraSettings';
import { importPipelineSelected } from '../../store/importPipelines.state';

export const PipelineSectionSettings: React.FC = () => {
  const { t } = useTranslation('dataset');
  const wh = useRecoilValue(warehouseSelected);
  const pipeline = useRecoilValue(importPipelineSelected);
  const [isLoading, setIsLoading] = useState(false);

  const [query, setQuery] = useState(
    pipeline?.settings?.mappingSettings?.transformation?.query,
  );

  const queryCode = pipeline?.settings?.mappingSettings?.transformation?.query;

  const [updateWhExtraSettings] = useUpdateWarehouseExtraSettings();

  return (
    <>
      <TitleSection
        id={`pipeline-settings-query`}
        title={t`Settings: Transformation`}
        inPanelView
        hasScreenTitle
        collapsible
      >
        <CodeEditor
          value={queryCode}
          language={'trinosql'}
          onChange={query => setQuery(queryCode)}
          height={`${(queryCode.split('\n').length || 1) * 28}px`}
          minHeight="42px"
          className="flex-1"
          editorOptions={{
            scrollBeyondLastLine: false,
            overviewRulerBorder: false,
            overviewRulerLanes: 0,
            hideCursorInOverviewRuler: true,
            lineDecorationsWidth: 10,
            scrollbar: {
              vertical: 'hidden',
              horizontal: 'hidden',
              handleMouseWheel: false,
            },
            guides: {
              indentation: false,
              highlightActiveIndentation: true,
              // bracketPairs: false,
            },
            fontSize: 13,
            lineNumbers: 'off',
            folding: false,
            tabSize: 2,
            padding: { top: 10, bottom: 10 },
          }}
        />

        {/* <Editor
          value={pipeline?.settings?.mappingSettings?.transformation?.query}
          onValueChange={query =>
            setQuery(pipeline?.settings?.mappingSettings?.transformation?.query)
          }
          highlight={code => (_.isEmpty(code) ? code : highlight(code, sql))}
          padding={20}
          disabled={isLoading}
          style={{
            position: 'relative',
            overflowY: 'auto',
            fontFamily: 'monospace',
            fontSize: 13,
            paddingBottom: '3rem',
          }}
          className={classNames('bg-app-panel-dark')}
        /> */}
      </TitleSection>
      <TitleSection
        id={`pipeline-settings-column-names`}
        title={t`Settings: Fields`}
        inPanelView
        hasScreenTitle
        collapsible
      >
        <ul className={classNames('p-1', 'space-y-2')}>
          {pipeline.settings?.mappingSettings?.mapping?.fields.map((f, idx) => {
            return (
              <li
                key={`${f.index}-${idx}`}
                className={classNames(
                  'flex',
                  'px-1 py-2',
                  'border-menu border-b',
                  'select-text',
                  'text-menu-text text-xs',
                )}
              >
                <Tag leftTag fullWidth>
                  {f.columnName}
                </Tag>
                <div className="bg-app-panel/50 flex select-text flex-col items-center justify-center px-3 py-1 text-xs">
                  <Icon.ArrowRight
                    className={classNames('h-4 w-4 fill-current opacity-50')}
                  />
                </div>
                <Tag rightTag fullWidth>
                  {f.name}
                  {f.measureValue && (
                    <div className={classNames('select-text opacity-70')}>
                      {f.measureValue}
                    </div>
                  )}
                </Tag>
              </li>
            );
          })}
        </ul>
      </TitleSection>
    </>
  );
};
