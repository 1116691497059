import {
  AssignmentItemDataFragment,
  LoadAssignmentDataDocument,
  useRunExportJobMutation,
} from '@warebee/frontend/data-access-api-graphql';
import {
  AssignmentConverterConfig,
  assignmentExportJobParams,
  AssignmentExportJobParams,
  getAssignmentTableRows,
} from '@warebee/shared/export-converter';
import classNames from 'classnames';
import { generateExportFilename } from 'libs/shared/export-converter/src/lib/export-filename.helper';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { containsFilter } from '../common/filterHelper';
import { formatInteger } from '../common/formatHelper';
import { AsyncLoadStatus, DatasetDataViewProps } from '../common/types';
import { cn } from '../common/utils';
import { Button } from '../components/actions/Button';
import DatasetTable from '../components/DatasetTable';
import * as Icon from '../components/icons';
import { Spacer } from '../components/layout/Spacer';
import { StatListItem } from '../components/stats/StatListItem';
import { simulationCurrent } from '../simulation/store/simulation.state';
import { brandName } from '../store/global.state';
import {
  warehouseSelected,
  warehouseSelectedId,
} from '../store/warehouse.state';

import useLoadAssignmentData from './hooks/useLoadAssignmentData';
import {
  assignmentItemsData,
  assignmentItemsDataState,
  assignmentItemsDataStatus,
} from './store/assignment.state';
import useAssignmentDataTableConfig from './useAssignmentDataTableConfig';

const AssignmentDataTable: React.FC<
  DatasetDataViewProps<AssignmentItemDataFragment>
> = props => {
  const { t } = useTranslation('dataset');

  const sim = useRecoilValue(simulationCurrent);
  const brandTitleState = useRecoilValue(brandName);
  const warehouse = useRecoilValue(warehouseSelected);
  const warehouseIdState = useRecoilValue(warehouseSelectedId);

  const data = useRecoilValue(assignmentItemsData);
  const status = useRecoilValue(assignmentItemsDataStatus);
  const [dataState, setDataState] = useRecoilState(assignmentItemsDataState);
  const [runExportCSV] = useRunExportJobMutation();
  const [loadCallback, cancelLoad] = useLoadAssignmentData();
  const columnsConfig = useAssignmentDataTableConfig();
  const [showStats, setShowStats] = useState(false);

  function callDataLoad(
    searchValues: Record<string, string>,
    page = { isAppend: false, skip: 0 },
  ) {
    if (_.isNil(props.id)) return;

    const filter = {
      locationId: containsFilter(searchValues['locationId']),
      sku: containsFilter(searchValues['sku']),
      consignee: containsFilter(searchValues['consignee']),
    };

    cancelLoad();
    loadCallback({
      assignmentId: props.id,
      filter,
      ...page,
    });
  }

  useEffect(() => {
    callDataLoad(dataState.searchValues);
  }, [props.id, dataState.searchValues]);

  function onLoadNext() {
    callDataLoad(dataState.searchValues, {
      isAppend: true,
      skip: itemsCount,
    });
  }

  async function startExportCSV() {
    const filename = generateExportFilename({
      exportTitle: t`dataset-assignment`,
      brandTitleState,
      warehouse,
      warehouseIdState,
      sim,
    });

    const variables: AssignmentExportJobParams = {
      ...assignmentExportJobParams,
      query: LoadAssignmentDataDocument.loc.source.body,
      config: converterConfig,
      variables: {
        assignmentId: props.id,
        page: null,
      },
      filename: `${filename}.csv`,
    };
    const { data, errors } = await runExportCSV({
      variables,
    });
    //
    return {
      errors: [],
      job: data.createExportJob,
    };
  }

  const isLoading = status === AsyncLoadStatus.Loading;
  const itemsCount = data?.length || 0;
  const totalCount = dataState?.totalCount ?? 0;

  const converterConfig: AssignmentConverterConfig = {
    columnsConfig,
  };

  const flattenData = getAssignmentTableRows(data, converterConfig);

  const summaryStats = useMemo(() => {
    if (!flattenData?.length) return null;

    // Count unique values
    const uniqueCounts = {
      consignee: new Set(flattenData.map(item => item.consignee)).size,
      sku: new Set(flattenData.map(item => item.sku)).size,
      stockUom: new Set(flattenData.map(item => item.stockUom)).size,
      locations: new Set(flattenData.map(item => item.locationId)).size,
    };

    // Group quantities by UOM
    const quantityByUom = flattenData.reduce(
      (acc, item) => {
        const uom = item.stockUom;
        if (!acc[uom]) {
          acc[uom] = 0;
        }
        acc[uom] += Number(item.stockUomQuantity) || 0;
        return acc;
      },
      {} as Record<string, number>,
    );

    // Sum up total quantity
    const totalQuantity = Object.values(quantityByUom).reduce(
      (sum, qty) => sum + qty,
      0,
    );

    return {
      unique: uniqueCounts,
      totals: { totalQuantity },
      quantityByUom,
    };
  }, [flattenData]);

  if (props.customRenders) {
    _.forEach(props.customRenders, (renderer, key) => {
      const cfg = _.find(columnsConfig, c => c.field === key);
      if (!_.isNil(cfg)) {
        cfg.render = renderer;
      }
    });
  }

  return (
    <div className="flex-1">
      <DatasetTable
        hasCounter
        hideScreenTitle={props.hideScreenTitle}
        subtitle={t`Assignments`}
        title={props.title}
        isSticky
        id={props.id}
        columnsConfig={columnsConfig}
        keyFields={['consignee', 'sku', 'locationId']}
        data={flattenData}
        onLoadNext={onLoadNext}
        onSearch={v => {
          setDataState({ ...dataState, searchValues: v });
        }}
        totalCount={totalCount}
        searchValues={dataState.searchValues}
        isLoading={isLoading}
        onStartExportClick={startExportCSV}
        actionSection={
          <>
            <Button
              className={classNames(
                'hover:text-menu-active',
                'bg-menu/50 hover:bg-menu',
                'text-xxs',
                'flex items-center',
                'rounded-full',
                'px-2 py-1',
                'focus:outline-none focus:ring-0',
              )}
              buttonSize="xs"
              hasIconAfter
              buttonIcon={
                <Icon.DataStatsPie
                  className={cn(
                    'h-4 w-4 fill-current',
                    showStats ? '' : 'opacity-50 hover:opacity-85',
                  )}
                />
              }
              onPress={() => setShowStats(!showStats)}
            />
            <Spacer flexspace />
          </>
        }
        actionBar={
          <>
            {summaryStats && showStats && (
              <div className="flex flex-wrap items-end gap-px px-px py-px align-bottom">
                {summaryStats.unique.consignee > 0 && (
                  <StatListItem
                    title={t`Consignees`}
                    value={formatInteger(summaryStats.unique.consignee)}
                    className="bg-app-panel-stat h-full"
                  />
                )}
                {summaryStats.unique.stockUom > 0 && (
                  <StatListItem
                    title={t`UOMs`}
                    value={formatInteger(summaryStats.unique.stockUom)}
                    className="bg-app-panel-stat h-full"
                  />
                )}
                {summaryStats.unique.locations > 0 && (
                  <StatListItem
                    isPrimaryResult
                    title={t`Locations`}
                    value={formatInteger(summaryStats.unique.locations)}
                    className="bg-app-panel-stat h-full"
                  />
                )}

                <div className="flex-1">
                  {Object.entries(summaryStats.quantityByUom).map(
                    ([uom, qty]) =>
                      qty > 0 && (
                        <StatListItem
                          key={uom}
                          title={
                            uom && uom !== 'null' && uom !== 'undefined'
                              ? t('Qty {{uom}}', { uom })
                              : t('Qty')
                          }
                          value={formatInteger(qty)}
                          className="bg-app-panel-stat"
                        />
                      ),
                  )}
                </div>
              </div>
            )}
          </>
        }
      />
    </div>
  );
};

export default AssignmentDataTable;
