import { useApolloClient } from '@apollo/client';
import {
  LoadSlottingDashboardDocument,
  LoadSlottingDashboardQuery,
  LoadSlottingDashboardQueryVariables,
  StringSearchFilterType,
} from '@warebee/frontend/data-access-api-graphql';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';

import _ from 'lodash';
import useLoadWarehouseBillingState from '../../appBilling/hooks/useLoadWarehouseBillingState';
import { AsyncLoadStatus } from '../../common/types';
import { errorAppender } from '../../store/error.state';
import { warehouseSelectedId } from '../../store/warehouse.state';
import { DASHBOARD_ITEMS_COUNT } from '../store/dashboard.default';
import { geSortByParams } from '../store/dashboard.helper';
import {
  dashboardSimulationData,
  dashboardSimulationLoadState,
  dashboardSimulationState,
} from '../store/dashboard.state';
import { DashboardOrder } from '../store/dashboard.types';

export type LoadDashboardSimulationParams = {
  sort: DashboardOrder;
  skip?: number;
  limit?: number;
  isAppend?: boolean;
  filter?: string;
};

function useLoadSimulationDashboard() {
  const client = useApolloClient();
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load simulation dashboard`;

  const updateBillingState = useLoadWarehouseBillingState();

  const [observable, setObservable] = useState<ZenObservable.Subscription>();

  const initLoading = useRecoilCallback(({ set }) => async () => {
    set(dashboardSimulationLoadState, AsyncLoadStatus.Loading);
  });

  const loadDashDataCallback = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: LoadDashboardSimulationParams) => {
        const warehouseId = await snapshot.getPromise(warehouseSelectedId);
        const current = await snapshot.getPromise(dashboardSimulationData);
        const currentState = await snapshot.getPromise(
          dashboardSimulationState,
        );
        updateBillingState([warehouseId]);
        function handleError(details, stack) {
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(dashboardSimulationData, null);
          set(dashboardSimulationLoadState, AsyncLoadStatus.Error);
        }

        const query = client.watchQuery<
          LoadSlottingDashboardQuery,
          LoadSlottingDashboardQueryVariables
        >({
          query: LoadSlottingDashboardDocument,
          variables: {
            warehouseId,
            sortBy: geSortByParams(params.sort),
            page: {
              skip: params.skip ?? 0,
              limit: params.limit ?? DASHBOARD_ITEMS_COUNT,
            },
            filter: {
              userTags: !_.isEmpty(currentState.selectedUserTags)
                ? currentState.selectedUserTags
                : undefined,
              title: !_.isEmpty(currentState.titleFilter)
                ? {
                    type: StringSearchFilterType.CONTAINS,
                    value: currentState.titleFilter,
                  }
                : undefined,
            },
          },
        });

        // Subscribe to it, and do something with the data
        const observable = query.subscribe(
          ({ data, errors }) => {
            if (errors) {
              console.error(errors);

              handleError(null, errors.map(e => e.message).join('. '));
              return;
            }

            let items = data.warehouse.simulations.content;

            if (params.isAppend) {
              items = [...current, ...items];
            }

            set(dashboardSimulationData, items);
            set(dashboardSimulationLoadState, AsyncLoadStatus.Ok);
            set(dashboardSimulationState, {
              ...currentState,
              loadedCount: items.length,
              totalCount: data.warehouse.simulations.totalCount,
              order: params.sort,
              loadedDate: new Date(),
              userTags: data.warehouse.simulations.allUserTags,
            });
          },
          error => {
            console.error(error);
            handleError(error.message || error, error.stack || null);
          },
        );
        setObservable(observable);
      },
  );

  async function call(params: LoadDashboardSimulationParams) {
    await initLoading();
    return await loadDashDataCallback(params);
  }

  function cancel() {
    observable?.unsubscribe();
  }

  return [call, cancel] as const;
}

export default useLoadSimulationDashboard;
