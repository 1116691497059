import { useApolloClient } from '@apollo/client';
import {
  LoadItemSetDashboardDocument,
  LoadItemSetDashboardQuery,
  LoadItemSetDashboardQueryVariables,
  StringSearchFilterType,
} from '@warebee/frontend/data-access-api-graphql';
import { useRecoilCallback } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import { warehouseSelectedId } from '../../store/warehouse.state';
import { DASHBOARD_ITEMS_COUNT } from '../store/dashboard.default';
import { geSortByParams } from '../store/dashboard.helper';
import {
  dashboardItemSetData,
  dashboardItemSetLoadState,
  dashboardItemSetState,
} from '../store/dashboard.state';

import _ from 'lodash';
import { LoadDashboardParams } from '../store/dashboard.types';

function useLoadItemSetDashboard() {
  const client = useApolloClient();

  const initLoading = useRecoilCallback(({ set }) => async () => {
    set(dashboardItemSetLoadState, AsyncLoadStatus.Loading);
  });

  const loadDashDataCallback = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: LoadDashboardParams) => {
        const warehouseId = await snapshot.getPromise(warehouseSelectedId);
        const current = await snapshot.getPromise(dashboardItemSetData);
        const currentState = await snapshot.getPromise(dashboardItemSetState);

        const response = await client.query<
          LoadItemSetDashboardQuery,
          LoadItemSetDashboardQueryVariables
        >({
          query: LoadItemSetDashboardDocument,
          variables: {
            warehouseId,
            sortBy: geSortByParams(params.sort),
            page: {
              skip: params.skip ?? 0,
              limit: params.limit ?? DASHBOARD_ITEMS_COUNT,
            },
            filter: {
              userTags: !_.isEmpty(currentState.selectedUserTags)
                ? currentState.selectedUserTags
                : undefined,
              title: !_.isEmpty(currentState.titleFilter)
                ? {
                    type: StringSearchFilterType.CONTAINS,
                    value: currentState.titleFilter,
                  }
                : undefined,
            },
          },
        });

        let items = response.data.warehouse.itemSets.content;

        if (params.isAppend) {
          items = [...current, ...items];
        }

        set(dashboardItemSetData, items);
        set(dashboardItemSetLoadState, AsyncLoadStatus.Ok);
        set(dashboardItemSetState, {
          ...currentState,
          loadedCount: items.length,
          totalCount: response.data.warehouse.itemSets.totalCount,
          order: params.sort,
          userTags: response.data.warehouse.itemSets.allUserTags,
        });
      },
  );

  return async (params: LoadDashboardParams) => {
    await initLoading();
    await loadDashDataCallback(params);
  };
}

export default useLoadItemSetDashboard;
