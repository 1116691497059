import classNames from 'classnames';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { AppHeader } from '../components/AppHeader';
import Tabs, { TabItem } from '../components/actions/Tabs';
import * as Icon from '../components/icons';
import {
  warehouseSettingsSelectedTabId,
  WarehouseSettingsTabId,
} from '../store/warehouse.state';
import WarehouseSettingsDelete from './panels/WarehouseSettingsDelete';
import WarehouseSettingsPermissions from './panels/WarehouseSettingsPermissions';
import WarehouseSettingsProperties from './panels/WarehouseSettingsProperties';

const WarehouseSetup: React.FC = () => {
  const { t } = useTranslation('app');
  const [selectedTab, setSelectedTab] = useRecoilState(
    warehouseSettingsSelectedTabId,
  );
  // const visibleFeatures = useUserPermissionFeatures();

  const tabIconStyle = classNames(
    'fill-current w-10 lg:w-12 xl:w-16 2xl:w-20 h-auto',
  );

  const tabItems: TabItem<WarehouseSettingsTabId>[] = [
    {
      id: 'tab-warehouse-settings-properties',
      title: t`Properties`,
      icon: <Icon.Warehouse className={tabIconStyle} />,
      content: <WarehouseSettingsProperties />,
    },

    {
      id: 'tab-warehouse-settings-user',
      title: t`Users & Permissions`,
      icon: <Icon.WarehouseParams1 className={tabIconStyle} />,
      content: <WarehouseSettingsPermissions />,
    },
    {
      id: 'tab-warehouse-settings-extra',
      title: t`Extra Settings`,
      icon: <Icon.MenuDotsHorizontal className={tabIconStyle} />,
      content: <WarehouseSettingsDelete />,
    },
  ];

  // const shouldHaveScroll = selectedTab !== 'tab-feed-activity';

  // const allowedTabs = tabItems.filter(
  //   tab => visibleFeatures.find(f => f.id === tab.id)?.visible,
  // );

  return (
    <>
      <AppHeader hasMenuGlobal hasHelp hasUser hasMenuApps />
      <Tabs
        componentName="WarehouseSettingsPanel"
        items={tabItems}
        selectedIndex={_.findIndex(tabItems, i => i.id === selectedTab)}
        onChange={index => setSelectedTab(tabItems[index].id)}
        classNameTitle="uppercase"
        // hasScroll={shouldHaveScroll}
        hasScroll
        fullHeight
        hasActionbar={false}
        className="z-0"
        // hasActionbar={shouldHaveScroll ? false : true}
      />
    </>
  );
};

export default WarehouseSetup;
