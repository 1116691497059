import classNames from 'classnames';
import _ from 'lodash';
import { HTMLAttributes, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { LogoHeader } from '../components/layout/LogoHeader';
import { warehouseIsDemo } from '../store/warehouse.state';
import { LabelModes } from './LabelModes';
import { MenuGlobal } from './MenuGlobal';
import { SubTitle } from './layout/SubTitle';
import { Title } from './layout/Title';
import { NavBack } from './nav/NavBack';

export type ScreenHeaderProps = {
  children?: React.ReactNode;
  title?: string;
  subtitle?: string;
  backPath?: string;
  icon?: React.FC<HTMLAttributes<Element>>;
  logo?: boolean;
};

export default function ScreenHeader({
  children,
  title,
  subtitle,
  backPath,
  icon,
  logo,
}: ScreenHeaderProps) {
  const [id] = useState<string>(_.uniqueId());
  const { t } = useTranslation('app');
  const isDemo = useRecoilValue(warehouseIsDemo);
  const IconMenu = icon;

  return (
    <header
      data-component="ScreenHeader"
      id={id}
      className={classNames(
        'flex w-full items-center',
        // 'm-0',
        'border-menu/50 border-b',
        'bg-app-header',
      )}
    >
      <NavBack isHeaderBack isTitle action_path={backPath} />
      <div className={classNames('flex flex-1 flex-col', 'truncate')}>
        <nav
          className={classNames(
            'item-center flex flex-1',
            'pt-1 ltr:pl-2 rtl:pr-2',
          )}
        >
          <div className={classNames('flex flex-1 items-center')}>
            {icon && (
              <IconMenu
                className={classNames(
                  'fill-current',
                  'text-menu-h1',
                  'h-4 w-4',
                  'ltr:mr-2 rtl:ml-2',
                )}
              />
            )}

            {logo && (
              <LogoHeader
                headerMode
                logoApp
                logoSize={'header'}
                navTo={'/'}
                className={classNames('ltr:mr-1 rtl:ml-1')}
              />
            )}

            <SubTitle
              className={logo ? '!text-brand-warebee' : '!text-brand'}
              subtitle={subtitle}
            />

            {isDemo && (
              <LabelModes
                title={t`Demo Warehouse`}
                type="DEMO"
                className={classNames('ltr:ml-2 rtl:mr-2')}
              />
            )}
          </div>
          <MenuGlobal
            config={{
              setting: { show: true, showTitle: false },
              notifications: { show: true, showTitle: false },
              help: { show: true, showTitle: true },
              subscription: { show: true, showTitle: false },
            }}
          />
        </nav>
        <nav
          className={classNames(
            'item-center flex flex-1 flex-col',
            'px-1 lg:px-2',
          )}
        >
          <Title title={title}>{children}</Title>
        </nav>
      </div>
    </header>
  );
}
