import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import InboxZero from '../../components/InboxZero';
import { Container } from '../../components/layout/ContainerFlex';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import SidebarContainer, {
  SidebarContainerProps,
} from '../../containers/SidebarContainer';
import LocationSharingPolicyLocationFilterEditor from '../../locationSharingPolicy/LocationSharingPolicyLocationFilterEditor';
import useLoadLocationFilterValues from '../../policyFilters/useLoadLocationFilterValues';
import { getLocationFilterConfigCommon } from '../store/assignmentPolicy.default';
import {
  locationSharingPolicyRule,
  locationSharingPolicySelectedFilterIntersection,
  locationSharingPolicySelectedRuleId,
} from '../store/locationSharingPolicy/locationSharingPolicy.state';

const LocationSharingPolicyFiltersSidebar: React.FC = () => {
  const { t } = useTranslation('simulation');
  const ruleId = useRecoilValue(locationSharingPolicySelectedRuleId);

  const filterIntersection = useRecoilValue(
    locationSharingPolicySelectedFilterIntersection,
  );
  const rule = useRecoilValue(locationSharingPolicyRule(ruleId));
  const [loadLocationValues, cancelLoadLocationValues] =
    useLoadLocationFilterValues();

  useEffect(() => {
    if (_.isNil(filterIntersection)) {
      cancelLoadLocationValues();
      loadLocationValues({
        filterConfigs: getLocationFilterConfigCommon(t),
        affect: 'others',
        filterIntersection: filterIntersection,
        field: null,
        filterKey: null,
      });
    }
  }, [filterIntersection]);

  if (!filterIntersection || !rule) {
    return (
      <InboxZero
        selfCenter
        center
        message={t`No Policy filter group selected`}
        message_helper={t`Click on an Edit button in Policy Rule to change filters`}
      />
    );
  }

  return (
    <Container col hasOverflowY className="pb-20">
      <ScreenTitle
        subtitle={t`Filter Options for`}
        title={`${rule.title}`}
        isSticky
        truncate
      />
      {getLocationFilterConfigCommon(t).map(configItem => (
        <LocationSharingPolicyLocationFilterEditor
          key={`policy-filter-editor-${configItem.type}`}
          filterConfig={configItem}
          startLoadData={loadLocationValues}
          cancelLoadData={cancelLoadLocationValues}
        />
      ))}
    </Container>
  );
};

export default () => {
  const { t } = useTranslation('simulation');

  const containerProps: SidebarContainerProps = {
    type: 'sidebar-policy-location-sharing-editor',
    title: t`Filter Policy Params`,
    loadingMessage: t`Loading Location Sharing Policy...`,
    size: 'xs',
  };

  return (
    <SidebarContainer key={containerProps.type} {...containerProps}>
      <LocationSharingPolicyFiltersSidebar />
    </SidebarContainer>
  );
};
