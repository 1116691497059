import classNames from 'classnames';
import _ from 'lodash';
import React, { useState } from 'react';
// import * as Icon from '../components/icons';

export type UnitOfMeasureProps = {
  className?: string;
  children?: React.ReactNode;
  unitOfMeasure: string | number | React.ReactNode;
  isSelected?: boolean;
  classSize?: string;
};

export const UnitOfMeasure = ({
  className,
  children,
  unitOfMeasure,
  isSelected,
  classSize,
}: UnitOfMeasureProps) => {
  const [id] = useState<string>(_.uniqueId());

  return (
    <span
      id={id}
      data-component="unitOfMeasure"
      className={classNames(
        'items-baseline',
        unitOfMeasure ? 'mx-1 mb-1' : '',
        children ? 'mx-0.5' : '',
        classSize ? classSize : 'text-xs',
        isSelected ? 'opacity-75' : 'opacity-50',
        className,
      )}
    >
      {unitOfMeasure}
      {children}
    </span>
  );
};
