import { AnalyzeResultStatus } from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import ButtonSwitchMulti from '../components/actions/ButtonSwitchMulti';
import { allocationRunSummary } from '../simulation/store/allocation/allocation.state';
import {
  simulationAnalyzeStatus,
  simulationAnalyzeTypeSelected,
} from '../simulation/store/simulation.state';
import { AnalyzeResultType } from '../simulation/store/simulation.types';

const SwitchAnalyzeType: React.FC = () => {
  const { t } = useTranslation('app');
  const [typeSelected, setTypeSelected] = useRecoilState(
    simulationAnalyzeTypeSelected,
  );
  const allocationRun = useRecoilValue(allocationRunSummary);
  const isAllocating =
    allocationRun?.status === 'CALCULATING' ||
    allocationRun?.status === 'CREATED';

  const analyzeStatus = useRecoilValue(simulationAnalyzeStatus);
  const isAnalysing =
    analyzeStatus === AnalyzeResultStatus.ANALYZING ||
    analyzeStatus === AnalyzeResultStatus.CREATED;

  // Set default based on allocationRun existence
  React.useEffect(() => {
    if (!typeSelected) {
      setTypeSelected(allocationRun ? 'allocate' : 'initial');
    }
  }, [allocationRun, typeSelected, setTypeSelected]);

  if (_.isNil(allocationRun)) return null;

  const viewAs: {
    key: AnalyzeResultType;
    label: string;
    isLoading?: boolean;
  }[] = [
    {
      key: 'initial',
      label: isAnalysing ? t`Analysing...` : t`Analyse`,
      isLoading: isAnalysing,
    },
    {
      key: 'allocate',
      label: isAllocating ? t`Allocating...` : t`Allocate`,
      isLoading: isAllocating,
    },
  ];

  return (
    <ButtonSwitchMulti
      autoSize
      title=""
      classNameContainer="flex-none"
      // className="mx-3 w-full"
      // classNameLabel="text-xs py-1 px-2"
      className={classNames('mx-0.5 xl:mx-3', 'w-full')}
      classNameLabel={classNames('text-xs py-0.5 px-2')}
      buttonType="header"
      options={viewAs}
      // disabled={allocationRun?.status !== BatchJobStatus.READY}
      selectedIndex={_.findIndex(viewAs, o => o.key === typeSelected)}
      onClick={index => setTypeSelected(viewAs[index].key)}
    />
  );
};

export default SwitchAnalyzeType;
