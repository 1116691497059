import { t } from 'i18next';
import React, { PropsWithChildren } from 'react';
import { useRecoilValue } from 'recoil';
import { cn } from '../common/utils';
import { MailTo } from '../components/actions/MailTo';
import {
  appThemeSelectedId,
  getDefaultBrandConfig,
} from '../store/global.state';
import { whiteLabelConfigs } from '../store/whitelabel.types';

export type ContactSupportProps = PropsWithChildren & {
  className?: string;
  message?: string;
  messageError?: string;
  email?: string;
  subject?: string;
  body?: string;
};

const ContactSupport: React.FC<ContactSupportProps> = props => {
  const selectedTheme = useRecoilValue(appThemeSelectedId);

  // Using useMemo to memoize the config lookup
  const configBrandEntry = React.useMemo(
    () =>
      Object.entries(whiteLabelConfigs).find(
        ([_, config]) => config.themes?.name === selectedTheme,
      ),
    [selectedTheme], // Only re-run when selectedTheme changes
  );

  const supportEmail =
    selectedTheme && configBrandEntry
      ? configBrandEntry?.[1]?.support?.email
      : (getDefaultBrandConfig()?.support.email ?? 'support@warebee.com');

  const supportKeyword =
    selectedTheme && whiteLabelConfigs[selectedTheme]
      ? configBrandEntry?.[1]?.id
      : (getDefaultBrandConfig()?.id ?? 'WAREBEE');

  const emailMessage = props.message;
  const emailMessageError = props.messageError;

  return (
    <MailTo
      data-component="ContactSupport"
      email={props.email ?? supportEmail}
      subject={
        props.subject ??
        t('[{{supportKeyword}}] I need support with ', {
          supportKeyword,
        })
      }
      body={
        props.body ??
        (emailMessage ||
          t(
            'Hello, I need support with the following: \n 1. {{emailMessageError}}',
            {
              emailMessageError,
            },
          ))
      }
      className={cn('group', props.className)}
    >
      <div>{t`Need Support?`}</div>
      <div className={cn('ltr:ml-1 rtl:mr-1')}>{supportEmail}</div>
      {props.children}
    </MailTo>
  );
};

export default ContactSupport;
