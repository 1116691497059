import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { PanelFixedHeight } from '../common/component.types';
import { cn, getIndexedTitle } from '../common/utils';
import { Button } from '../components/actions/Button';
import { PanelHeader } from '../components/designer/panels/PanelHeader';
import { FormErrorField } from '../components/FormError';
import * as Icon from '../components/icons';
import InboxZero from '../components/InboxZero';
import QueryTag from '../components/QueryTag';
import PanelContainer from '../containers/PanelContainer';
import { queryInputHeight } from '../import/store/import.state';
import { simulationShowDatasetAsTable } from '../simulation/store/simulation.state';
import CustomQueryDataTable from './CustomQueryDataTable';
import CustomQueryEditor from './CustomQueryEditor';
import {
  customQueryDescriptorsAll,
  customQueryErrors,
} from './store/customQuery.state';

type CustomQueryComponentProps = {
  queryId: string;
};

const CustomQueryComponent: React.FC<CustomQueryComponentProps> = props => {
  const { t } = useTranslation('app');
  const [queryError, setQueryError] = useRecoilState(
    customQueryErrors(props.queryId),
  );

  const [height, setHeight] =
    useRecoilState<PanelFixedHeight>(queryInputHeight);

  const [showDatasetTable, setShowDatasetTable] = useRecoilState(
    simulationShowDatasetAsTable,
  );

  return (
    <>
      {/* <Container col hasOverflowY flex1> */}
      <PanelContainer
        id={`custom-query-sql`}
        title={t`Query`}
        collapsible={false}
        className={height !== 'h-min' ? 'h-full min-h-20 flex-1' : ''}
        classNameInner="h-full"
        isAccordion
      >
        {!_.isEmpty(queryError) && (
          <PanelContainer
            id={`custom-query-error`}
            collapsible
            className="bg-alerts-error/70"
            title={t`Errors`}
          >
            <FormErrorField message={queryError} />
          </PanelContainer>
        )}
        <CustomQueryEditor queryDescriptorId={props.queryId} />
      </PanelContainer>

      <PanelHeader
        title={t`Data`}
        closable={true}
        onCloseClick={() => setShowDatasetTable(null)}
        // filterPlaceholder={getFilterTags()}
        adjustable={!_.isNil(height)}
        fixedHeight={height}
        onHeightChange={setHeight}
      >
        {/* {genPanelTitleContent()} */}
      </PanelHeader>

      {height !== 'h-min' && (
        <CustomQueryDataTable queryDescriptorId={props.queryId}>
          {/* {props.queryId} */}
        </CustomQueryDataTable>
      )}
      {/* </Container> */}
    </>
  );
};

export type CustomQueryRootProps = {
  actionBefore?: React.ReactNode;
  actionAfter?: React.ReactNode;
};

const CustomQueryRoot: React.FC<CustomQueryRootProps> = props => {
  const { t } = useTranslation('app');
  const [activeQueryId, setActiveQueryId] = useState(null);

  const [descriptorsAll, setDescriptorsAll] = useRecoilState(
    customQueryDescriptorsAll,
  );

  const descriptorsArray = _.values(descriptorsAll);
  const titlePrefix = t`Query `;

  function addQueryDescriptor() {
    const titles = new Set(
      _(descriptorsAll)
        .map(v => v.title)
        .uniq()
        .value(),
    );
    const id = nanoid(8);
    const title = getIndexedTitle(titles, titlePrefix);

    setDescriptorsAll(current => ({
      ...current,
      [id]: {
        id,
        title,
        sql: '',
      },
    }));
    setActiveQueryId(id);
  }

  function removeQueryDescriptor(id: string) {
    if (activeQueryId === id) {
      //deselect
      const currentIndex = _.findIndex(descriptorsArray, d => d.id === id);
      if (currentIndex > 0) {
        setActiveQueryId(descriptorsArray[currentIndex - 1].id);
      }
      if (currentIndex === 0) {
        setActiveQueryId(descriptorsArray[currentIndex + 1]?.id ?? '');
      }
    }

    setDescriptorsAll(current => _.omit(current, id));
  }

  return (
    <>
      <PanelContainer
        id={`custom-queries-root`}
        collapsible
        isAccordion
        className="flex-1"
        classNameChildren="flex flex-col"
        title={
          <div className={cn('flex flex-1 items-center', 'gap-1')}>
            {props.actionBefore}
            {
              // list existing
              _.map(descriptorsAll, d => (
                <div
                  key={`custom-query-container-${d.id}`}
                  className="flex items-center gap-1"
                  onClick={e => {
                    e.stopPropagation();
                    setActiveQueryId(d.id);
                  }}
                >
                  <QueryTag
                    title={d.title}
                    removable
                    onRemove={() => removeQueryDescriptor(d.id)}
                    enabled={activeQueryId === d.id}
                  />
                </div>
              ))
            }

            <div
              className={cn(
                '',
                'flex items-center gap-1',
                'hover:text-menu-active',
                'mx-0.5 p-1',
              )}
              onClick={e => {
                e.stopPropagation();
                addQueryDescriptor();
              }}
            >
              <Icon.CirclePlus className={cn('h-5 w-5', 'fill-current')} />
            </div>
            {props.actionAfter}
          </div>
        }
      >
        {_.isEmpty(activeQueryId) && (
          <InboxZero selfCenter message={t`Click + to add custom query`}>
            <Button
              label={t`Add Query`}
              className={cn('rounded ltr:ml-4 rtl:mr-4')}
              buttonSize="xs"
              buttonType="primary"
              hasIconAfter={
                <Icon.CirclePlus className={cn('h-5 w-5 fill-current')} />
              }
              onPress={() => addQueryDescriptor()}
            />
          </InboxZero>
        )}
        {!_.isEmpty(activeQueryId) && (
          <CustomQueryComponent queryId={activeQueryId} />
        )}
      </PanelContainer>
    </>
  );
};

export default CustomQueryRoot;
