import { useSendUserInvitationMutation } from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { Button } from '../../../components/actions/Button';
import { InputEmail } from '../../../components/inputs/InputText';
import { Spacer } from '../../../components/layout/Spacer';
import { ActionBar } from '../../../components/nav/ActionBar';
import { loggedInUser } from '../../../store/auth.state';
import {
  warehouseSelected,
  warehouseUpdateLoadStatus,
} from '../../../store/warehouse.state';
import { WarehouseExtraSettings } from '../../../store/warehouse.types';

const SettingsInviteUsers: React.FC = () => {
  const { t } = useTranslation('app');

  const wh = useRecoilValue(warehouseSelected);
  const user = useRecoilValue(loggedInUser);
  const extra: WarehouseExtraSettings = wh?.extraSettings;
  const status = useRecoilValue(warehouseUpdateLoadStatus);
  const feedSettings = extra?.liveFeed;
  // local state
  const [localEnable, localSetEnable] = useState(feedSettings?.enabled);
  const [localJobId, localSetJobId] = useState(feedSettings?.jobId);
  const [localLayoutId, localSetLayoutId] = useState(feedSettings?.layoutId);
  const [email, setEmail] = useState('');

  useEffect(() => {
    localSetEnable(feedSettings?.enabled);
    localSetJobId(feedSettings?.jobId);
    localSetLayoutId(feedSettings?.layoutId);
  }, [feedSettings]);

  const [sendInvitation] = useSendUserInvitationMutation();

  async function sendInvitationLink() {
    try {
      await sendInvitation({
        variables: {
          input: {
            email,
            warehouse: [
              {
                id: wh.id,
                // role: 'USER',
              },
            ],
            enforceMfa: false,
          },
        },
      });
      setEmail('');
    } catch (error) {
      console.error('Failed to send invitation:', error);
    }
  }

  return (
    <>
      <section
        className={classNames(
          'w-full',
          'mb-4',
          'divide-menu/50 divide-y divide-solid',
          'p-4',
        )}
      >
        <InputEmail
          label={t`Email address`}
          value={email}
          onChange={v => setEmail(v)}
          className="mb-2 p-2"
          placeholder={`manager@example.com`}
        />
      </section>

      <ActionBar
        sticky
        stickyBottom
        className={classNames('flex w-full items-end', 'p-2')}
      >
        <Spacer flexspace />
        <Button
          label={t`Send Invitation`}
          buttonType="primary"
          // buttonSize="xs"
          className={classNames('rounded')}
          // isLoading={isLoading}
          // isDisabled={isLoading || !email}
          onPress={() => sendInvitationLink()}
        />
      </ActionBar>
    </>
  );
};

export default SettingsInviteUsers;
