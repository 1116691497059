import classNames from 'classnames';
import _ from 'lodash';
import React, { Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import InboxZero from '../../components/InboxZero';
import LoadingIndicator from '../../components/LoadingIndicator';
import ButtonSwitchMulti from '../../components/actions/ButtonSwitchMulti';
import { Alert } from '../../components/alerts/Alerts';
import { HeaderAppMenu } from '../../components/headers/HeaderAppMenu';
import * as Icon from '../../components/icons';
import FileEncodingCheck from '../../components/importer/FileEncodingCheck';
import { ToolbarImportParams } from '../../components/importer/toolbars/ToolbarImportParams';
import InputCheckbox from '../../components/inputs/InputCheckbox';
import { Container } from '../../components/layout/ContainerFlex';
import ImporterDataPreview from '../csv/components/ImporterDataPreview';
import ImporterFileSelector from '../csv/components/ImporterFileSelector';
import ImporterSelectedFileHeader from '../csv/components/ImporterSelectedFileHeader';
import { getImportConfiguration } from '../store/import.default';
import { isTextBasedFile } from '../store/import.helper';
import {
  importMappingSettingsByType,
  importRawPreview,
  importRawPreviewStatus,
  importSelectedFile,
  showImportParseOptions,
} from '../store/import.state';
import { ImportStepContainerProps } from '../store/import.types';
import useApplyMappingSettings from '../useApplyMappingSettings';
import useUploadPreviewFile from '../useUploadPreviewFile';

const ImportSelectFileStep: React.FC<ImportStepContainerProps> = props => {
  const { t } = useTranslation('importer');
  const previewData = useRecoilValue(importRawPreview);
  const status = useRecoilValue(importRawPreviewStatus);
  const mappingSettings = useRecoilValue(
    importMappingSettingsByType(props.type),
  );
  const applyMappingSettings = useApplyMappingSettings();
  const [showParseOptions, setShowParseOptions] = useRecoilState(
    showImportParseOptions,
  );

  const [datasSourceFile, setFile] = useRecoilState(importSelectedFile);

  const config = getImportConfiguration(props.type, t);

  const isLoading = status === AsyncLoadStatus.Loading;
  const hasError =
    !_.isEmpty(previewData?.errors) || status === AsyncLoadStatus.Error;
  const isParsed = !_.isNil(previewData) && !hasError;

  const [call, cancel, errorDetails] = useUploadPreviewFile();
  if (!_.isNil(errorDetails)) {
    console.error('UploadPreviewFile errorDetails::', errorDetails);
  }

  const [detectedEncoding, setDetectedEncoding] = useState<string>('UTF-8');
  const [isCheckingEncoding, setIsCheckingEncoding] = useState(false);
  const [isInitialEncodingDetection, setIsInitialEncodingDetection] =
    useState(true);

  useEffect(() => {
    if (datasSourceFile && !isTextBasedFile(datasSourceFile)) {
      call({ file: datasSourceFile });
    }
  }, [datasSourceFile]);

  const handleEncodingDetected = async (encoding: string) => {
    console.log('Encoding detected:', encoding);
    setDetectedEncoding(encoding);
    setIsCheckingEncoding(false);
    setIsInitialEncodingDetection(false);

    // Update CSV options with detected encoding
    if (mappingSettings?.csvOptions) {
      const newEncoding = encoding.replace('-', '_').toUpperCase();
      console.log('Updating CSV options with encoding:', newEncoding);
      await applyMappingSettings({
        skipValidation: true,
        mappingSettings: {
          ...mappingSettings,
          csvOptions: {
            ...mappingSettings.csvOptions,
            encoding: newEncoding as any,
          },
        },
      });
    }

    // Now that encoding is set, proceed with file upload
    if (datasSourceFile) {
      console.log('Starting file upload for:', datasSourceFile.name);
      try {
        await call({ file: datasSourceFile });
        console.log('File upload completed');
      } catch (error) {
        console.error('File upload failed:', error);
      }
    } else {
      console.warn('No file available for upload');
    }
  };

  const handleFileSelection = async (file: File) => {
    console.log('File selected:', file.name);
    setFile(file);
    if (isTextBasedFile(file)) {
      setIsCheckingEncoding(true);
      // The upload will be handled by handleEncodingDetected after encoding detection
    } else {
      // For non-text files, upload directly
      await call({ file });
    }
  };

  return (
    <Container
      col
      componentName="ImportSelectFileStep"
      overflow
      className={classNames(
        'flex-1',
        'border-app-panel-dark ltr:border-l rtl:border-r',
        'relative',
        'bg-app-panel-dark',
      )}
    >
      <HeaderAppMenu
        isLoading={isLoading}
        title={
          isLoading ? (
            t`Loading File...`
          ) : isParsed ? (
            <ImporterSelectedFileHeader />
          ) : (
            t`Select data file`
          )
        }
      />

      <Container
        col
        className={classNames(
          'flex-1 items-center',
          'w-full transition duration-500',
          'bg-sidebar-menu/60',
          'overflow-none',
        )}
      >
        <Suspense
          fallback={<LoadingIndicator selfCenter message={t`Loading...`} />}
        >
          {hasError && (
            <div
              className={classNames(
                'w-full',
                'px-2 py-2',
                'bg-sidebar-menu/60',
              )}
            >
              {status === AsyncLoadStatus.Error && (
                <Alert
                  hasStatusError
                  message={t(
                    'Cant upload/pre-process selected file. {{error}}',
                    { error: errorDetails },
                  )}
                />
              )}

              {_.map(previewData?.errors, (e, i) => (
                <Alert
                  hasStatusError
                  key={i}
                  message={e}
                  // message={`${e.message} (row ${e.row}, index ${i})`}
                />
              ))}
            </div>
          )}

          {datasSourceFile && isTextBasedFile(datasSourceFile) ? (
            <Suspense
              fallback={
                <LoadingIndicator message={t`Detecting file encoding...`} />
              }
            >
              <FileEncodingCheck
                file={datasSourceFile}
                onEncodingDetected={handleEncodingDetected}
              />
              {detectedEncoding &&
                detectedEncoding.toLowerCase() !== 'utf-8' && (
                  <Alert
                    hasStatusAlert={isInitialEncodingDetection}
                    hasStatusWarning={!isInitialEncodingDetection}
                    message={t(
                      isInitialEncodingDetection
                        ? 'File encoding detected: {{encoding}}. The file will be imported using this encoding.'
                        : 'Current file encoding: {{encoding}}. For best results, please use UTF-8 encoding.',
                      {
                        encoding: detectedEncoding,
                      },
                    )}
                    className="w-full"
                  />
                )}
            </Suspense>
          ) : null}

          <Container col className={classNames('h-full w-full flex-1')}>
            {((!isParsed && !isLoading) || hasError) && (
              <>
                <header
                  data-component="import-options"
                  className={classNames(
                    'flex items-center',
                    'px-4 py-4',
                    'bg-sidebar-title/75',
                  )}
                >
                  <InputCheckbox
                    label={t`Has header row?`}
                    isSelected={mappingSettings?.hasHeaders ?? true}
                    onChange={hasHeaders =>
                      applyMappingSettings({
                        skipValidation: true,
                        mappingSettings: {
                          ...mappingSettings,
                          hasHeaders,
                        },
                      })
                    }
                    size="xs"
                  >
                    {t`Has header row`}
                  </InputCheckbox>

                  {/* <Spacer flexspace /> */}

                  <ButtonSwitchMulti
                    // title={t`Import options`}
                    autoSize
                    options={[
                      { label: t('Import options') },
                      {
                        icon: (
                          <Icon.ShowTable
                            className={classNames(
                              'h-5 w-5',
                              // 'mx-1',
                              'fill-current',
                            )}
                          />
                        ),
                      },
                    ]}
                    selectedIndex={showParseOptions ? 0 : 1}
                    buttonType={showParseOptions ? 'secondary' : 'minimal'}
                    classNameLabel="!p-0"
                    onClick={e => setShowParseOptions(!showParseOptions)}
                  />

                  {/* <button
                    className={classNames(
                      'mx-1 px-2',
                      'border-b-1',
                      'cursor-pointer select-none',
                      'hover:border-menu-active hover:border-current',
                      showParseOptions
                        ? 'text-menu-active border-menu-active border'
                        : 'text-menu-text border-none',
                    )}
                    onClick={e => setShowParseOptions(!showParseOptions)}
                  >
                    {t`Import options`}
                  </button> */}
                </header>

                {showParseOptions && (
                  <ToolbarImportParams
                    settings={mappingSettings?.csvOptions}
                    encoding={detectedEncoding}
                    onChange={csvOptions =>
                      applyMappingSettings({
                        skipValidation: true,
                        mappingSettings: {
                          ...mappingSettings,
                          csvOptions,
                        },
                      })
                    }
                  />
                )}
              </>
            )}

            {!isParsed ? (
              isLoading ? (
                <LoadingIndicator
                  messageBefore={t('Loading Preview...')}
                  message={t('{{filename}}', {
                    filename: datasSourceFile?.name,
                  })}
                  selfCenter
                />
              ) : (
                <ImporterFileSelector
                  actionName={config.actionName}
                  filename={config.filenameTemplate}
                  onFileSelect={handleFileSelection}
                />
              )
            ) : (
              <>
                {isLoading ? (
                  <LoadingIndicator
                    message={t('Loading Preview...')}
                    // message={t('{{filename}}', {
                    //   filename: datasSourceFile?.name,
                    // })}
                    selfCenter
                  />
                ) : datasSourceFile ? (
                  <ImporterDataPreview
                    id={'source'}
                    data={previewData.data}
                    collapsible={false}
                    columns={previewData.fields}
                  />
                ) : (
                  <InboxZero
                    selfCenter
                    message={t`Dataset Mode`}
                    message_helper={t`Continue to Transform`}
                  />
                )}
              </>
            )}
          </Container>
        </Suspense>
      </Container>
    </Container>
  );
};

export default ImportSelectFileStep;
