import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { formatInteger } from '../../../common/formatHelper';
import ErrorIndicator from '../../../components/ErrorIndicator';
import { LoadingIndicator } from '../../../components/LoadingIndicator';
import { Container } from '../../../components/layout/ContainerFlex';
import { Stat } from '../../../components/stats/Stat';
import { StatListItem } from '../../../components/stats/StatListItem';
import { getAllocationMetricDescriptors } from '../../store/allocation/allocation.helper';
import { allocationRunSummary } from '../../store/allocation/allocation.state';
import { AllocationMetric } from '../../store/allocation/allocation.types';
import AllocationSummaryView from './AllocationSummaryView';

const AllocationSummary: React.FC = () => {
  const { t } = useTranslation('simulation');
  const allocationRun = useRecoilValue(allocationRunSummary);

  const isAllocating =
    allocationRun?.status === 'CALCULATING' ||
    allocationRun?.status === 'CREATED' ||
    allocationRun?.status === 'RESTARTED';

  const hasError =
    allocationRun?.status === 'FAILED' ||
    allocationRun?.status === 'TERMINATED';

  const isLoading = isAllocating;

  const summary = allocationRun?.resultRequirementsSummary?.[0];

  const metricDescriptors = getAllocationMetricDescriptors(t);

  const renderUomStats = (uoms: any[]) => {
    return [...uoms]
      .sort((a, b) => a.uom.localeCompare(b.uom))
      .map(uom => (
        <StatListItem
          key={`uom-${uom.uom}`}
          value={formatInteger(uom.uomQuantity)}
          title={uom.uom}
        />
      ));
  };

  const renderStat = (key: AllocationMetric, data: any) => {
    if (!data || data.totalQuantity === 0) return null;

    const descriptor = metricDescriptors[key];
    if (!descriptor) return null;

    return (
      <Stat
        classTitle={classNames(
          'sticky z-10 -top-4',
          'backdrop-filter backdrop-blur bg-app-panel-stat/70',
          'py-2 px-2 lg:px-4',
          '-mt-2 -mx-3 lg:-mx-4',
        )}
        className="h-full overflow-y-auto overflow-x-hidden"
        title={descriptor.title}
        key={`allocated-${key}`}
        value={
          data.totalQuantity > 1000000
            ? formatInteger(data.totalQuantity)
            : formatInteger(data.totalQuantity)
        }
      >
        {data.uoms && renderUomStats(data.uoms)}
      </Stat>
    );
  };

  const metricKeys: AllocationMetric[] = [
    'all',
    'required',
    'assigned',
    'initiallyPickable',
    'toAllocateRequired',
    'toReallocate',
    'toAllocate',
    'allocated',
    'allocatedShared',
    'reallocated',
    'unallocated',
    'deallocated',
    'unpickable',
  ];

  const summaryKeyMapping: Partial<
    Record<AllocationMetric, keyof typeof summary>
  > = {
    required: 'required',
    assigned: 'initiallyPickable',
    initiallyPickable: 'initiallyPickable',
    toAllocateRequired: 'toAllocateRequired',
    toReallocate: 'toReallocate',
    toAllocate: 'toAllocate',
    allocated: 'allocated',
    allocatedShared: 'allocatedShared',
    reallocated: 'toReallocate',
    unallocated: 'unallocated',
    deallocated: 'deallocated',
    unpickable: 'unpickable',
  };

  return (
    <>
      <Container col overflow componentName={`AllocationSummary`}>
        {hasError && <ErrorIndicator message={t`Allocate Error`} selfCenter />}

        {isLoading && (
          <div className="p-0.5">
            <LoadingIndicator oneLiner selfCenter message={t`Allocating...`} />
          </div>
        )}
        <header className="my-1 flex h-full max-h-[11rem] flex-wrap gap-1 overflow-hidden lg:max-h-[11rem]">
          {metricKeys.map(key =>
            renderStat(key, summary?.[summaryKeyMapping[key]]),
          )}
        </header>
        <AllocationSummaryView />
      </Container>
    </>
  );
};

export default AllocationSummary;
