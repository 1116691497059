import classNames from 'classnames';
import React, { HTMLAttributes } from 'react';
import { useRecoilState } from 'recoil';
import { analyticsAgent } from '../AnalyticTracker';
import { sidebarStateByType, SidebarType } from '../store/sidebar.state';
import ButtonToggle from './actions/ButtonToggle';

export const ButtonSize = {
  default: 'w-5 h-5',
  xs: 'w-6 h-6',
  sm: 'w-10 h-10',
  md: 'w-14 h-14',
  menu: 'w-12 h-12',
  lg: 'w-16 h-16',
  xl: 'w-20 h-20',
  '2xl': 'w-24 h-24',
};

export type ButtonSidebarToggleProps = {
  className?: string;
  children?: React.ReactNode;
  title?: string;
  sidebarID: SidebarType;
  icon?: React.FC<HTMLAttributes<Element>>;
  noTitle?: boolean;
  buttonSize?: keyof typeof ButtonSize | string;
};

export const ButtonSidebarToggle = ({
  className,
  children,
  title,
  icon,
  sidebarID,
  noTitle,
  buttonSize = 'default',
}: ButtonSidebarToggleProps) => {
  const Icon = icon;

  const [state, setState] = useRecoilState(sidebarStateByType(sidebarID));

  const handleChange = () => {
    setState({ ...state, isCollapsed: !state.isCollapsed });

    try {
      analyticsAgent?.track(`Button ${title}: (Toggle)`, {
        title,
      });
    } catch (ex) {
      console.debug('analyticsAgent Error:', ex);
    }
  };

  const sizeIconClass = ButtonSize[buttonSize] || buttonSize;

  return (
    <ButtonToggle
      title={title}
      aria-label={`button-sidebar-${title || ''}`}
      className={classNames(
        sidebarID,
        'flex flex-1 items-center justify-center',
        'px-2',
        'group',
        'group-hover:text-menu-active',
        state.isCollapsed
          ? 'isCollapsed text-menu-text'
          : 'isCollapsedNot text-menu-active hover:text-opacity-70',
        {
          'hover:bg-menu-hover hover:text-menu-active-text h-16 p-1':
            buttonSize === 'menu',
        },
        className,
      )}
      onChange={handleChange}
    >
      <Icon className={classNames('fill-current', sizeIconClass)} />
      {title && (
        <span
          className={classNames(
            'flex-1',
            'ltr:ml-1 rtl:mr-1',
            noTitle && 'sr-only',
          )}
        >
          {title}
        </span>
      )}
      {children}
    </ButtonToggle>
  );
};
