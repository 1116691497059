import { detectAll } from 'jschardet';
import React, { useEffect } from 'react';

interface FileEncodingCheckProps {
  file: File | null;
  onEncodingDetected: (encoding: string) => void;
}

const FileEncodingCheck: React.FC<FileEncodingCheckProps> = ({
  file,
  onEncodingDetected,
}): null => {
  useEffect(() => {
    if (!file || file.size === 0) return;

    const checkEncoding = async () => {
      const reader = new FileReader();
      reader.onload = e => {
        const result = e.target?.result;
        if (typeof result === 'string') {
          const detectedEncodings = detectAll(result);
          // Sort encodings by confidence and get the highest
          const bestMatch = detectedEncodings.sort(
            (a, b) => b.confidence - a.confidence,
          )[0];
          // If the best match is ASCII or ISO-8859-1, verify if it's actually UTF-8
          const finalEncoding = ['ascii', 'ISO-8859-1'].includes(
            bestMatch.encoding.toLowerCase(),
          )
            ? verifyUtf8(result)
            : bestMatch.encoding;

          onEncodingDetected(finalEncoding);
        }
      };
      reader.readAsBinaryString(file.slice(0, 16384));
    };

    checkEncoding();
  }, [file]);

  const verifyUtf8 = (content: string): string => {
    try {
      decodeURIComponent(escape(content));
      return 'UTF-8';
    } catch {
      return 'ASCII';
    }
  };

  return null;
};

export default FileEncodingCheck;
