import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import PanelContainer from '../../../containers/PanelContainer';
import ThemeSelector from '../ThemeSelector';

export type PanelProps = PropsWithChildren & {
  className?: string;
};

const PanelDisplaySettings: React.FC<PanelProps> = props => {
  const { t } = useTranslation('app');

  return (
    <PanelContainer
      id="panel-system-theme"
      title={t`Display Settings`}
      collapsible
    >
      <div className="p-4">
        <ThemeSelector />
      </div>
    </PanelContainer>
  );
};

export default PanelDisplaySettings;
