import { Field, ID, InputType } from '@warebee/shared/util-backend-only-types';
import { UpdateBaseEntityInput } from './update-base-entity.input';

@InputType()
export class UpdateWarehouseInput extends UpdateBaseEntityInput {
  @Field(() => ID)
  warehouseId: string;

  @Field({ nullable: true })
  companyName?: string;

  @Field({ nullable: true })
  address?: string;
}
