import React from 'react';
// import * as Icon from '../../icons';
import {
  CsvImportOptions,
  ImportFormat,
  ImportJobInputEncoding,
  ImportJobSourceCompression,
} from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { cn } from '../../../common/utils';
import ImporterPreviewSizeInput from '../../../import/csv/components/ImporterPreviewSizeInput';
import { importSourceSettings } from '../../../import/store/import.state';
import DropdownSelector from '../../actions/DropdownSelector';
import { InputGroupList } from '../../inputs/InputGroupList';
import { InputValue } from '../../inputs/InputValue';

export type ToolbarImportParamsProps = {
  settings: CsvImportOptions;
  encoding?: string;
  onChange: (value: CsvImportOptions) => void;
};

export const ToolbarImportParams: React.FC<
  ToolbarImportParamsProps
> = props => {
  const { t } = useTranslation('importer');
  const [sourceSettings, setSourceSettings] =
    useRecoilState(importSourceSettings);

  const formats: Record<ImportFormat, string> = {
    CSV: t`CSV`,
    JSON: t`JSON`,
    DATASET: t`Dataset`,
  };

  const isDataset = sourceSettings.format === ImportFormat.DATASET;
  const isDataCSV = sourceSettings.format === ImportFormat.CSV;
  const isDataJSON = sourceSettings.format === ImportFormat.JSON;

  const compressions: Record<ImportJobSourceCompression, string> = {
    NO: t`None`,
    GZIP: t`GZIP`,
  };

  const encodings: Record<ImportJobInputEncoding, string> = {
    UTF_8: 'UTF-8',
    UTF_16: 'UTF-16',
    UTF_16LE: 'UTF-16LE',
    UTF_16BE: 'UTF-16BE',
    WINDOWS_1251: 'WINDOWS-1251',
    WINDOWS_1252: 'WINDOWS-1252',
  };

  const getEncodingEnum = (encoding: string): ImportJobInputEncoding => {
    const normalized = encoding.replace('-', '_').toUpperCase();
    return normalized as ImportJobInputEncoding;
  };

  return (
    <header
      data-component="ToolbarImportParams"
      className={classNames(
        'flex',
        'flex-col lg:flex-row',
        'space-y-2 lg:space-y-0',
        'items-start',
        'p-3 xl:p-4',
        'bg-sidebar-menu',
        'lg:divide-menu/50 lg:divide-x',
      )}
    >
      <div className="h-full w-full flex-1 px-2">
        <DropdownSelector
          label={t`Data format`}
          value={sourceSettings.format}
          values={_.keys(formats) as ImportFormat[]}
          renderValue={v => formats[v]}
          onChange={v => setSourceSettings({ ...sourceSettings, format: v })}
          panelMode
          w_sm
          DropAlignRight
          labelVertical
          panelModeMultiline
          classNameLabel="text-menu-text"
          classNameValue="border-0"
        />
      </div>

      {!isDataset && (
        <div className="h-full w-full flex-1 px-2">
          <DropdownSelector
            label={t`Compression`}
            value={sourceSettings.compression}
            values={_.keys(compressions) as ImportJobSourceCompression[]}
            renderValue={v => compressions[v]}
            onChange={v =>
              setSourceSettings({ ...sourceSettings, compression: v })
            }
            w_sm
            panelMode
            DropAlignRight
            labelVertical
            panelModeMultiline
            classNameLabel="text-menu-text"
            classNameValue="border-0"
          />
        </div>
      )}
      {isDataCSV && (
        <>
          <div className="h-full w-full flex-1 px-2">
            <DropdownSelector
              label={t`Encoding`}
              value={
                props.settings?.encoding ||
                (props.encoding ? getEncodingEnum(props.encoding) : undefined)
              }
              values={_.keys(encodings) as ImportJobInputEncoding[]}
              renderValue={v => (v ? encodings[v] : props.encoding || '')}
              onChange={v =>
                props.onChange({
                  ...(props.settings ?? {}),
                  encoding:
                    v ||
                    (props.encoding
                      ? getEncodingEnum(props.encoding)
                      : undefined),
                })
              }
              w_sm
              panelMode
              panelModeMultiline
              labelVertical
              classNameLabel="text-menu-text"
              classNameValue="border-0"
              DropAlignRight
            />
          </div>
          {/* <InputGroupList className="flex-1 space-y-2"> */}
          <div className="h-full w-full flex-1 px-2">
            <InputValue
              title={t`Delimiter`}
              id_name="custom-delimiter"
              isDebouncedChange
              labelVertical
              value={
                props.settings?.separator === '\t'
                  ? 'tab'
                  : (props.settings?.separator ?? ',')
              }
              onChange={separator =>
                props.onChange({
                  ...(props.settings ?? {}),
                  separator:
                    separator === '\t' ||
                    separator === '\u0009' ||
                    separator === '\x09' ||
                    separator.toLowerCase() === 'tab'
                      ? '	'
                      : separator,
                })
              }
              // onPaste={e => {
              //   e.preventDefault();
              //   const pastedText = e.clipboardData.getData('text');
              //   props.onChange({
              //     ...(props.settings ?? {}),
              //     separator:
              //       pastedText === '\t' ||
              //       pastedText === '\u0009' ||
              //       pastedText === '\x09'
              //         ? '\t'
              //         : pastedText,
              //   });
              // }}
            />
          </div>
          <div className="h-full w-full flex-1 px-2">
            <InputValue
              title={t`Separator`}
              id_name="custom-separator"
              isDebouncedChange
              labelVertical
              value={props.settings?.quote ?? '"'}
              onChange={quote =>
                props.onChange({
                  ...(props.settings ?? {}),
                  quote,
                })
              }
            />
          </div>
          {/* </InputGroupList> */}
        </>
      )}
      <div className="h-full w-full flex-1 px-2">
        <InputGroupList
          className={cn(
            'flex flex-1',
            isDataCSV ? 'flex-col space-y-2' : 'flex-row',
          )}
        >
          <InputValue
            title={t`Skip Rows (header)`}
            id_name="rows-skip-header"
            isDebouncedChange
            labelVertical
            value={props.settings?.skipHeaderLines ?? 0}
            // isNumeric={true}
            onChange={skipHeaderLines =>
              props.onChange({
                ...(props.settings ?? {}),
                skipHeaderLines: Math.max(
                  0,
                  Math.floor(Number(skipHeaderLines)) || 0,
                ),
              })
            }
          />

          <InputValue
            title={t`Skip Rows (footer)`}
            id_name="rows-skip-footer"
            isDebouncedChange
            labelVertical
            value={props.settings?.skipFooterLines ?? 0}
            // isNumeric={true}
            onChange={skipFooterLines =>
              props.onChange({
                ...(props.settings ?? {}),
                skipFooterLines: Math.max(
                  0,
                  Math.floor(Number(skipFooterLines)) || 0,
                ),
              })
            }
          />
        </InputGroupList>
      </div>
      <div className="h-full w-full flex-1 px-2">
        <ImporterPreviewSizeInput />
      </div>
    </header>
  );
};
