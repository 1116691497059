import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from '../../components/layout/ContainerFlex';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import HelpContainer from '../../helpContext/HelpContainer';

export type ImportTransformPanelProps = {
  children?: React.ReactNode;
  className?: string;
};

export const ImportTransformPanel = ({
  children,
  className,
}: ImportTransformPanelProps) => {
  const [id] = useState<string>(_.uniqueId());
  const { t } = useTranslation('importer');

  return (
    <Container hasOverflowY flex1 col>
      <ScreenTitle
        isSticky
        title={t`Transform and Enrich`}
        subtitle={t`Data Import`}
        hasStepCounter={'3.'}
      />
      <HelpContainer id={'importer/transform-intro'} hasPadding />
      <HelpContainer id={'importer/transform-helper'} hasPadding />
    </Container>
  );
};
