import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import { errorAppender } from '../../store/error.state';
import { warehouseSelectedId } from '../../store/warehouse.state';
import {
  actualityHqTableData,
  actualityHqTableDataLoadStatus,
  actualityHqTableDataState,
  actualityHqTableTotalCount,
} from '../store/actuality.hq.state';
import {
  ActualityHqQueryBuilderPagedParams,
  getActualityHqDataRowsQuery,
  getActualityHqDataTotalQuery,
} from '../store/datasetQueries/actualityHqDataRows';
import { executeDatasetQuery } from '../store/feed.helper';

export type LoadActualityHqTableDataParams =
  ActualityHqQueryBuilderPagedParams & {
    isAppend?: boolean;
  };

function useLoadActualityHqTableData() {
  //const client = useApolloClient();
  const { t } = useTranslation('errors');
  const errorTitle = t`Cannot load Actuality Data`;
  //const [observable, setObservable] = useState<ZenObservable.Subscription>();

  const resetCallback = useRecoilCallback(({ reset }) => async () => {
    reset(actualityHqTableDataLoadStatus);
    reset(actualityHqTableData);
    reset(actualityHqTableDataState);
    reset(actualityHqTableTotalCount);
  });

  const initLoading = useRecoilCallback(
    ({ set, reset }) =>
      async (params: LoadActualityHqTableDataParams) => {
        set(actualityHqTableDataLoadStatus, AsyncLoadStatus.Loading);
        if (!params.isAppend) {
          reset(actualityHqTableData);
          reset(actualityHqTableTotalCount);
        }
      },
  );

  const loadDataCallback = useRecoilCallback(
    ({ snapshot, set }) =>
      async (params: LoadActualityHqTableDataParams) => {
        const warehouseId = await snapshot.getPromise(warehouseSelectedId);
        const current = await snapshot.getPromise(actualityHqTableData);
        let totalCount = await snapshot.getPromise(actualityHqTableTotalCount);

        function handleError(details, stack) {
          set(errorAppender, {
            id: nanoid(),
            title: errorTitle,
            details: details,
            callStack: stack,
          });
          set(actualityHqTableDataLoadStatus, AsyncLoadStatus.Error);
        }

        if (!params.isAppend) {
          const compiledQueryTotal =
            getActualityHqDataTotalQuery(params).compile();

          const [totalResult] = await executeDatasetQuery({
            warehouseId,
            compiledQuery: compiledQueryTotal,
            comment: '[actuality] Hq total rows',
          });

          // console.log('totalResult::', totalResult);
          totalCount =
            totalResult && totalResult.length > 0
              ? totalResult[0].totalCount
              : 0;
        }

        let rows = [];
        if (totalCount > 0) {
          const compiledQuery = getActualityHqDataRowsQuery(params).compile();

          [rows] = await executeDatasetQuery({
            warehouseId,
            compiledQuery,
            comment: '[actuality] Hq table data',
          });
        }

        if (params.isAppend) {
          rows = [...current, ...rows];
        }
        set(actualityHqTableData, rows);
        set(actualityHqTableTotalCount, totalCount);
        set(actualityHqTableDataLoadStatus, AsyncLoadStatus.Ok);
      },
  );

  async function call(params: LoadActualityHqTableDataParams) {
    await initLoading(params);
    await loadDataCallback(params);
  }

  async function reset() {
    await resetCallback();
  }

  return [call, reset] as const;
}

export default useLoadActualityHqTableData;
