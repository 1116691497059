const TechMachindraLogoShape = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2444.6 476.2" {...props}>
    <g className="TechMachindra">
      <path
        id="path14"
        className="st1"
        d="M1128.1,41.1h-173s0,40.5,0,40.5h63.1v133h46.8V81.6s63.1,0,63.1,0v-40.5Z"
      />
      <path
        id="path26"
        className="st1"
        d="M1598.7,335.3c0-15.2-12.4-27.4-27.5-27.4h-60.9c-5.3,0-8,2.7-10.8,6.9-.2.2-18.2,26.1-18.2,26.1h58.7c6.9.3,12.4,5.9,12.4,13v81.2h46.3v-99.5s0-.3,0-.3"
      />
      <path
        id="path28"
        className="st1"
        d="M1435,261.6h46.3v173.6h-46.3v-173.6Z"
      />
      <path
        id="path32"
        className="st1"
        d="M1837.5,307.9h-131.6v127.2h46.6v-94.2h52.9c7.2,0,13.2,5.8,13.2,13v81.2h46.5v-99.9c0-15.2-12.4-27.4-27.6-27.4"
      />
      <path
        id="path36"
        className="st1"
        d="M1177.7,261.6h-222.5v173.6h46.2v-133h49.5c4.7,0,8.5,3.9,8.5,8.6v124.4h46.3v-124.4c0-4.9,3.9-8.6,8.6-8.6h36.5c7,0,12.8,5.6,12.8,12.7v120.4h46.2v-141.6c0-17.7-14.2-31.9-31.9-31.9"
      />
      <path
        id="path40"
        className="st1"
        d="M1629.2,349.2v85.9h46.3v-125.8s-21.4,13.3-36.6,22.8c-5.8,3.8-9.7,10-9.7,17.1"
      />
      <path
        id="path44"
        className="st1"
        d="M1629.2,261.4h46.3v32.1l-46.3,30.7v-62.7Z"
      />
      <path
        id="path48"
        className="st1"
        d="M2112.8,307.9c-11.6,0-19.1,8.9-19.1,18.5v108.8h46.6v-81.1c0-7.2,5.2-13.3,12.5-13.3h34.6c5-.3,9.2-3.3,11.9-7.2.2-.2,18.2-25.7,18.2-25.7h-104.7Z"
      />
      <path
        id="path52"
        className="st1"
        d="M2016.7,261.6v141.8h-67.1c-4.7,0-8-4.1-8-7.5v-41.9h.2c0-7.2,5.8-13,13-13h34c5,0,10.2-3,12.4-6.4l15.7-26.6h-94.2c-15,0-27.4,12.2-27.4,27.4v77c0,14.7,11.7,22.9,22.5,22.9h145.6v-173.6h-46.5Z"
      />
      <path
        id="path56"
        className="st1"
        d="M2353.5,307.9h-107.1c-5.3,0-10.2,2.5-13.1,6.6l-18,26.4h107.8c6.7.3,12.2,6.1,12.2,13l-.2,50.6h-80.8v-14.6c0-2.8,2.3-5.2,5.2-5.2h56.5v-18.8c0-4.5-2.8-7-6.7-7h-78.3c-9.7,0-17.4,7.7-17.4,17.2v46.2c0,.3,0,.5.2.8,1.1,6.9,7,12,14.2,12h152.8v-99.9c0-15.2-12.2-27.4-27.4-27.4"
      />
      <path
        id="path60"
        className="st1"
        d="M1378.8,307.9h-107.1c-5.2,0-10.2,2.5-13,6.6l-18.2,26.4h107.8c6.7.3,12.2,6.1,12.2,13v50.6h-80.9v-14.6c0-2.8,2.3-5.2,5.2-5.2h56.5v-18.8c0-4.5-2.8-7-6.7-7h-78.3c-9.7,0-17.4,7.7-17.4,17.2v46.2c0,.3,0,.5.2.8,1.1,6.9,7,12,14.2,12h152.8v-99.9c0-15.2-12.2-27.4-27.4-27.4"
      />
      <path
        id="path64"
        className="st1"
        d="M1675.5,117c0-15.2-12.4-27.4-27.5-27.4h-62.5c-5.3,0-8,2.7-10.8,6.9-.2.2-18.2,26.1-18.2,26.1h60.3c6.9.3,12.4,6,12.4,13v81.2h46.3v-99.9ZM1510.2,43.3h46.3v173.6h-46.3V43.3Z"
      />
      <path
        id="path68"
        className="st1"
        d="M1381.6,170.8h.2c0,7.2,5.8,13,13.1,13h86.6s0,33,0,33h-119c-15.1,0-27.5-12.2-27.5-27.4v-94.2,19.5c0-15.2,12.4-27.4,27.5-27.4h118.7l-7.3,18.2c-1.5,3.8-2.3,7.1-5.9,9.6-2.5,1.8-4.6,3-7.9,3h-65.3c-7.2,0-13.1,5.8-13.1,13h-.2v39.7"
      />
      <path
        id="path72"
        className="st1"
        d="M1173.8,215.2h99.1c5.2,0,10.2-2.5,13-6.6l18.2-26.5h-99.9c-6.7-.3-12.2-6.1-12.2-13v-50.6h73v14.6c0,2.8-2.3,5.2-5.2,5.2h-48.6v18.8c0,4.5,2.8,7,6.7,7h70.3c9.7,0,17.4-7.7,17.4-17.2v-46.2c0-.3,0-.5-.2-.8-1.1-6.9-7-12.1-14.2-12.1h-144.8v99.8c0,15.2,12.2,27.4,27.4,27.4"
      />
    </g>
  </svg>
);

export default TechMachindraLogoShape;
