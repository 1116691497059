import { LayoutImportConverterLocationFragment } from '@warebee/frontend/data-access-api-graphql';
import { LocationPatch } from '@warebee/shared/data-access-layout-import-converter';
import { useTranslation } from 'react-i18next';
import { ColumnConfig } from '../../../../components/DatasetTable';
import { InputNumber } from '../../../../components/inputs/InputNumber';
import { InputValue } from '../../../../components/inputs/InputValue';
import useApplyLocationPatch from '../hooks/useApplyLocationPatch';

type LocationPatchEditorProps = {
  location: LayoutImportConverterLocationFragment;
  propertyName: keyof LayoutImportConverterLocationFragment;
};

const LocationPatchEditor: React.FC<LocationPatchEditorProps> = props => {
  // const setPatches = useSetRecoilState(converterLocationPatchSetter);
  const [applyPatch] = useApplyLocationPatch();
  const {
    locationId,
    locationKey,
    warehouseArea: areaId,
    locationBayId: bayId,
  } = props.location;
  const path = props.propertyName;

  function patch(p: LocationPatch) {
    applyPatch({
      patch: { ...p, locationKey },
    });
  }
  const value = props.location[path];
  return (
    <>
      {typeof value === 'number' && (
        <InputNumber
          ariaLabel={`${locationId}-${path}`}
          value={value}
          onChange={v => patch({ [path]: v })}
          range={[1, null]}
        />
      )}
      {typeof value === 'string' && (
        <InputValue
          value={value}
          // onChange={v => patch({ [path]: v })}
          isDebouncedChange={true}
          onEndEdit={v => patch({ [path]: v })}
        />
      )}
      {typeof value === 'boolean' && (
        <InputValue
          value={value}
          // onChange={v => patch({ [path]: v })}
          isDebouncedChange={true}
          onEndEdit={v => patch({ [path]: v })}
        />
      )}
    </>
  );
};

function useLayoutDataTableConfig(): ColumnConfig<LayoutImportConverterLocationFragment>[] {
  const { t } = useTranslation('designer');
  const columnsConfig: ColumnConfig<LayoutImportConverterLocationFragment>[] = [
    {
      field: 'locationId',
      title: t`Location`,
      hasFilter: true,
      render: (value, location) => (
        <LocationPatchEditor location={location} propertyName={'locationId'} />
      ),
    },
    {
      field: 'locationBay',
      title: t`Bay`,
      hasFilter: true,
      render: (value, location) => (
        <LocationPatchEditor location={location} propertyName={'locationBay'} />
      ),
    },
    {
      field: 'baySide',
      title: t`Bay Side`,
      hasFilter: true,
      render: (value, location) => (
        <LocationPatchEditor location={location} propertyName={'baySide'} />
      ),
    },
    {
      field: 'locationAisle',
      title: t`Aisle`,
      hasFilter: true,
      render: (value, location) => (
        <LocationPatchEditor
          location={location}
          propertyName={'locationAisle'}
        />
      ),
    },
    {
      field: 'warehouseArea',
      title: t`Area`,
      hasFilter: true,
      render: (value, location) => (
        <LocationPatchEditor
          location={location}
          propertyName={'warehouseArea'}
        />
      ),
    },

    {
      field: 'locationStatus',
      title: t`Status`,
      hasFilter: true,
      render: (value, location) => (
        <LocationPatchEditor
          location={location}
          propertyName={'locationStatus'}
        />
      ),
    },
    {
      field: 'locationLevel',
      title: t`Level`,
      hasFilter: true,
      render: (value, location) => (
        <LocationPatchEditor
          location={location}
          propertyName={'locationLevel'}
        />
      ),
    },
    {
      field: 'locationLength',
      title: t`Length`,
      hasFilter: true,
      render: (value, location) => (
        <LocationPatchEditor
          location={location}
          propertyName={'locationLength'}
        />
      ),
    },
    {
      field: 'locationWidth',
      title: t`Width`,
      hasFilter: true,
      render: (value, location) => (
        <LocationPatchEditor
          location={location}
          propertyName={'locationWidth'}
        />
      ),
    },
    {
      field: 'locationHeight',
      title: t`Height`,
      hasFilter: true,
      render: (value, location) => (
        <LocationPatchEditor
          location={location}
          propertyName={'locationHeight'}
        />
      ),
    },
    {
      field: 'locationWeight',
      title: t`Weight`,
      hasFilter: true,
      render: (value, location) => (
        <LocationPatchEditor
          location={location}
          propertyName={'locationWeight'}
        />
      ),
    },
    {
      field: 'locationDepthPosition',
      title: t`Position`,
      hasFilter: true,
      render: (value, location) => (
        <LocationPatchEditor
          location={location}
          propertyName={'locationDepthPosition'}
        />
      ),
    },

    {
      field: 'locationUsageType',
      title: t`Usage Type`,
      hasFilter: true,
      render: (value, location) => (
        <LocationPatchEditor
          location={location}
          propertyName={'locationUsageType'}
        />
      ),
    },
    {
      field: 'locationBayPosition',
      title: t`Bay Position`,
      hasFilter: true,
      render: (value, location) => (
        <LocationPatchEditor
          location={location}
          propertyName={'locationBayPosition'}
        />
      ),
    },
    {
      field: 'locationRackingType',
      title: t`Location Type`,
      hasFilter: true,
      render: (value, location) => (
        <LocationPatchEditor
          location={location}
          propertyName={'locationRackingType'}
        />
      ),
    },
    {
      field: 'locationOrder',
      title: t`Picking Order`,
      hasFilter: true,
      render: (value, location) => (
        <LocationPatchEditor
          location={location}
          propertyName={'locationOrder'}
        />
      ),
    },
  ];

  return columnsConfig;
}
export default useLayoutDataTableConfig;
