import {
  useDuplicateSimulationMutation,
  useRemoveSimulationMutation,
} from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import { PropsWithChildren, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { DASHBOARD_REFRESH_INTERVAL } from '../common/constants';
import { AsyncLoadStatus } from '../common/types';
import { cn } from '../common/utils';
import { DashboardActionbar } from '../components/DashboardActionbar';
import InboxZero from '../components/InboxZero';
import LoadingIndicator from '../components/LoadingIndicator';
import { Button } from '../components/actions/Button';
import * as Icon from '../components/icons';
import { Container } from '../components/layout/ContainerFlex';
import { HeroBannerCard } from '../pricingPlans/PlanCard';
import SimulationDashboardCard from '../simulation/cards/SimulationDashboardCard';
import { CollapsibleId } from '../store/collapsible.default';
import {
  billingStatusVisibilityState,
  importTriggeredBySim,
} from '../store/global.state';
import {
  warehouseCanUpdate,
  warehouseSelectedId,
} from '../store/warehouse.state';
import WarehouseContainer from '../warehouse/WarehouseContainer';
import DashboardItemsGroupContainer from './DashboardItemsGroupContainer';
import DashboardItemContainer, {
  DashboardItemContainerProps,
} from './containers/DashboardItemContainer';
import DashboardListItemContainer from './containers/DashboardListItemContainer';
import { ItemListCard } from './containers/ItemListCard';
import useLoadSimulationDashboard from './hooks/useLoadSimulationDashboard';
import DashboardSimulationInfoCard from './infoCards/DashboardSimulationInfoCard';
import { getSimulationStatus } from './store/dashboard.helper';
import {
  dashboardSimulationData,
  dashboardSimulationLoadState,
  dashboardSimulationState,
  showHeaderAddButtonState,
  showHelperCardState,
} from './store/dashboard.state';
import {
  DashboardGroupState,
  DashboardOrder,
  DashboardViewMode,
} from './store/dashboard.types';

export type PanelContainerProps = {
  id: CollapsibleId;
  collapsible?: boolean;
  showAdvanced?: boolean;
};

export type DashboardProps = PropsWithChildren & {
  // showHelperCard?: boolean;
};

const SimulationsDashboard: React.FC<DashboardProps> = props => {
  const { t } = useTranslation('simulation');
  const warehouseId = useRecoilValue(warehouseSelectedId);
  const [removeSimulation] = useRemoveSimulationMutation();
  const [duplicateSimulation] = useDuplicateSimulationMutation();
  const setImportTriggerSimId = useSetRecoilState(importTriggeredBySim);
  const dashData = useRecoilValue(dashboardSimulationData);
  const [dashState, setDashState] = useRecoilState(dashboardSimulationState);
  const loadingState = useRecoilValue(dashboardSimulationLoadState);
  const [loadDashboardData, cancelLoadDashboardData] =
    useLoadSimulationDashboard();
  const canUpdate = useRecoilValue(warehouseCanUpdate);
  const isBillingStatusVisible = useRecoilValue(billingStatusVisibilityState);

  const [showHelperCard, setShowHelperCard] =
    useRecoilState(showHelperCardState);
  const [showHeaderAddButton, setShowHeaderAddButton] = useRecoilState(
    showHeaderAddButtonState,
  );
  const navigate = useNavigate();

  useEffect(() => {
    // clear import from simulation state
    setImportTriggerSimId(null);
  });

  useEffect(() => {
    let timeoutId;

    if (dashState.autoUpdate) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        loadDashboardData({
          sort: dashState.order,
          limit: dashState.loadedCount,
        });
      }, DASHBOARD_REFRESH_INTERVAL);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [dashState]);

  useEffect(() => {
    cancelLoadDashboardData();
    loadDashboardData({ sort: dashState.order });
  }, [warehouseId]);

  function changeViewMode(viewMode: DashboardViewMode) {
    setDashState({
      ...dashState,
      viewMode,
    });
  }

  function changeSort(sortMode: DashboardOrder) {
    setDashState({
      ...dashState,
      order: sortMode,
    });
    loadDashboardData({ sort: sortMode, limit: dashState.loadedCount });
  }

  function loadMore() {
    loadDashboardData({
      sort: dashState.order,
      skip: dashState.loadedCount,
      isAppend: true,
    });
  }

  function applyFilter(
    filterProps: Pick<DashboardGroupState, 'selectedUserTags' | 'titleFilter'>,
  ) {
    setDashState({
      ...dashState,
      ...filterProps,
    });
    loadDashboardData({ sort: dashState.order, limit: null });
  }

  function StartButton() {
    if (!canUpdate) return null;
    return (
      <Button
        className={classNames('mt-4 w-full rounded')}
        label={t`Create Simulation`}
        buttonType="primary"
        isDisabled={isLoading}
        full
        hasIconAfter
        buttonIcon={
          <Icon.CirclePlus className={classNames('h-6 w-6 fill-current')} />
        }
        onPress={() => {
          navigate(`/wh/i/${warehouseId}/simulations/new`);
        }}
        isLoading={isLoading}
      />
    );
  }

  const isLoading = loadingState === AsyncLoadStatus.Loading;

  useEffect(() => {
    if (dashData?.length > 10) {
      setShowHelperCard(false);
      setShowHeaderAddButton(true);
    } else if (dashData?.length <= 10) {
      setShowHelperCard(true);
      setShowHeaderAddButton(false);
    } else if (dashState.viewMode === DashboardViewMode.list) {
      setShowHelperCard(false);
      setShowHeaderAddButton(true);
    }
  }, [dashData]);

  function getEmptyStateMessage() {
    if (dashState.titleFilter) {
      return t('No simulations found matching "{{filter}}"', {
        filter: dashState.titleFilter,
      });
    }
    if (dashState.selectedUserTags?.length > 0) {
      return t('No simulations found matching tags: "{{tags}}"', {
        tags: dashState.selectedUserTags.join('", "'),
      });
    }
    return t('No Simulation(s) found');
  }

  const getSimulationsCards = () => {
    if (_.isEmpty(dashData)) return null;
    return _.sortBy(dashData).map(sim => {
      const { id, title, description } = sim;
      const path = `/wh/i/${warehouseId}/simulations/${id}`;
      const status = getSimulationStatus(sim, t);

      const containerProps: DashboardItemContainerProps = {
        id,
        title,
        description,
        createdDate: new Date(sim.createdAt),
        updatedDate: new Date(sim.updatedAt),
        path,
        status,
        isLoading: isLoading,
        canDelete: canUpdate,
        userTags: sim.userTags,
        onDeleteClick: async () => {
          await removeSimulation({
            variables: { simulationId: id },
          });
          loadDashboardData({ sort: dashState.order });
        },
        actions: {
          Duplicate: async () => {
            await duplicateSimulation({
              variables: { simulationId: id },
            });
            loadDashboardData({ sort: dashState.order });
            return null;
          },
        },
      };

      return dashState.viewMode === DashboardViewMode.card ? (
        <DashboardItemContainer
          key={`card-simulation-${id}`}
          {...containerProps}
        >
          <SimulationDashboardCard sim={sim} status={status} />
        </DashboardItemContainer>
      ) : dashState.viewMode === DashboardViewMode.debug ? (
        <DashboardItemContainer
          key={`card-simulation-${id}`}
          {...containerProps}
        >
          <SimulationDashboardCard
            sim={sim}
            status={status}
            showAdvancedStats
          />
        </DashboardItemContainer>
      ) : (
        <DashboardListItemContainer
          key={`card-simulation-${id}`}
          {...containerProps}
        />
      );
    });
  };

  return (
    <WarehouseContainer fullHeaders={true}>
      <DashboardActionbar />
      {/* pb-24  */}
      <Container col hasOverflowY className={classNames('relative pt-0')}>
        {isBillingStatusVisible && <HeroBannerCard />}

        <DashboardItemsGroupContainer
          id={'dashboard-slotting'}
          title={t`Simulations`}
          state={dashState}
          isLoading={isLoading}
          onSortChanged={changeSort}
          onFilterChange={applyFilter}
          onViewModeChanged={changeViewMode}
          onLoadMoreClick={loadMore}
          updateDate={dashState?.loadedDate}
          onAutoUpdateChange={v =>
            setDashState({ ...dashState, autoUpdate: v })
          }
          autoUpdate={dashState?.autoUpdate}
          isSimulation
          viewFeatures={['showHelperCard', 'showAutoUpdateDateTime']}
          onViewFeaturesChanged={selectedFeature => {
            if (selectedFeature === 'showHelperCard') {
              setShowHelperCard(prevShowHelperCard => !prevShowHelperCard);
            }
          }}
          actionBar={
            <>
              <Button
                label={t`New`}
                buttonType="primary"
                buttonSize="xs"
                hasIconAfter
                className={classNames('ltr:mr-4 rtl:ml-4', 'rounded')}
                buttonIcon={
                  <Icon.CirclePlus
                    className={classNames('h-5 w-5 fill-current')}
                  />
                }
                onPress={() => {
                  navigate(`/wh/i/${warehouseId}/simulations/new`);
                }}
              />
              <Button
                label={t`Compare`}
                buttonType="secondary"
                buttonSize="xs"
                hasIconBefore={
                  <Icon.Compare
                    className={classNames('h-5 w-5 fill-current')}
                  />
                }
                className={classNames('ltr:mr-4 rtl:ml-4', 'rounded')}
                onPress={() => {
                  navigate(`/wh/i/${warehouseId}/simulations/compare`);
                }}
              />
            </>
          }
        >
          {!showHelperCard && isLoading && (
            <ItemListCard
              className={classNames('relative flex items-center bg-opacity-0')}
              fullWidth
            >
              <LoadingIndicator
                message={t`Loading Simulations`}
                // absolute
                selfCenter
              />
            </ItemListCard>
          )}

          {showHelperCard &&
            dashState.viewMode !== DashboardViewMode.list &&
            !dashState.titleFilter &&
            !dashState.selectedUserTags?.length && (
              <DashboardSimulationInfoCard
                showButtonInline={!_.isEmpty(dashData)}
                isLoading={isLoading}
              />
            )}

          {dashData?.length === 0 && (
            <ItemListCard fullWidth>
              {isLoading && (
                <LoadingIndicator
                  message={t`Loading Simulations`}
                  absolute
                  selfCenter
                />
              )}
              <InboxZero
                className={cn(
                  'w-full flex-1',
                  dashState.titleFilter ||
                    dashState.selectedUserTags?.length > 0
                    ? 'py-8'
                    : 'py-16',
                )}
                selfCenter
                message_helper={getEmptyStateMessage()}
                icon={dashState.titleFilter ? Icon.Search : null}
              >
                {!dashState.titleFilter &&
                  !(dashState.selectedUserTags?.length > 0) && <StartButton />}
              </InboxZero>
            </ItemListCard>
          )}

          {getSimulationsCards()}
        </DashboardItemsGroupContainer>
      </Container>
    </WarehouseContainer>
  );
};

export default SimulationsDashboard;
