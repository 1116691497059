import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppFooter } from './components/AppFooter';
import { AppVersion } from './components/AppVersion';
import ErrorIndicator from './components/ErrorIndicator';
import { MenuGlobal } from './components/MenuGlobal';
import { Alert } from './components/alerts/Alerts';
import { ContainerApp } from './components/layout/ContainerApp';
import { ContainerCol } from './components/layout/ContainerFlex';
import { LogoHeader } from './components/layout/LogoHeader';
import { Spacer } from './components/layout/Spacer';
import ContactSupport from './helpContext/ContactSupport';
import SidebarHelp from './screens/help/SidebarHelp';
import SidebarNotifications from './screens/notifications/SidebarNotifications';

export type ErrorOrigin =
  | 'notConnected'
  | 'unhandledException'
  | 'networkIssue';

export type ErrorFallbackProps = {
  errorOrigin: ErrorOrigin;
  error?: Error;
};

const ErrorFallback: React.FC<ErrorFallbackProps> = props => {
  const { t } = useTranslation('app');
  const navigate = useNavigate();

  React.useEffect(() => {
    if (props.error) {
      console.error('[Support][Error details]:', {
        message: props.error.message,
        stack: props.error.stack,
        origin: props.errorOrigin,
      });
    }
  }, [props.error, props.errorOrigin]);

  return (
    <ContainerApp
      data-component="ErrorFallback"
      className="bg-app-background h-full"
    >
      <div
        data-component={`AppHeader`}
        data-label={`header`}
        aria-label={`header`}
        className={classNames('flex items-center', 'w-full', 'bg-app-header')}
      >
        <div className={classNames('flex w-full flex-1 flex-col items-center')}>
          <div className={classNames('flex w-full items-center')}>
            <LogoHeader logoFull logoSize={'sm'} />
            <Spacer flexspace />
            <MenuGlobal
              config={{
                user: { show: true, showTitle: false },
                setting: { show: true, showTitle: false },
                notifications: { show: false, showTitle: false },
                help: { show: true, showTitle: true },
                subscription: { show: false, showTitle: false },
              }}
            />
          </div>
        </div>
      </div>
      <ContainerCol overflow className={`items-center justify-center`}>
        {props.errorOrigin === 'networkIssue' && (
          <Alert
            hasStatusError
            message={'Network issue detected'}
            className="pr-6"
          />
        )}
        {props.errorOrigin === 'notConnected' && (
          <Alert
            hasStatusError
            message={t`Apologies, \n Looks like we've been disconnected from the Server`}
            className="pr-6"
          />
        )}
        {props.errorOrigin === 'unhandledException' && (
          <ErrorIndicator message={''} />
        )}
      </ContainerCol>

      <SidebarHelp />
      <SidebarNotifications />
      <AppFooter className={classNames('px-6 py-4', 'bg-app-background')}>
        <div className={classNames('flex flex-1 text-xs')}>
          <ContactSupport />
        </div>
        <AppVersion />
      </AppFooter>
    </ContainerApp>
  );
};
export default ErrorFallback;
