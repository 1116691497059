import classNames from 'classnames';
import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatInteger } from '../../common/formatHelper';
import Loader from '../loaders/Loader';
import { Button } from './Button';

export type ButtonLoadMoreProps = {
  children?: React.ReactNode;
  className?: string;
  titleNext?: string;
  titlePrev?: string;
  titleFirst?: string;
  titleLast?: string;
  isLoading?: boolean;
  totalCount?: number;
  itemsCount?: number;
  totalLabel?: string;
  onNext?: () => void;
  onPrev?: () => void;
  onFirst?: () => void;
  onLast?: () => void;
  onJumpTo?: (page: number) => void;
  currentPage?: number;
  pageSize?: number;
  onLoadMore?: (offset: number) => void;
};

export const ButtonLoadMore = ({
  children,
  className,
  titleNext,
  titlePrev,
  titleFirst,
  titleLast,
  totalCount,
  onLoadMore,
  isLoading,

  itemsCount,
  totalLabel,
  pageSize = 20,
  onFirst,
  onPrev,
  onNext,
  onLast,
}: ButtonLoadMoreProps) => {
  const [id] = useState<string>(_.uniqueId());
  const [jumpValue, setJumpValue] = useState<string>('');
  const [isEditing, setIsEditing] = useState(false);
  const { t } = useTranslation('app');
  const defaultTitleNext = isLoading ? t`Loading...` : t`Load More`;
  const defaultTitlePrev = t`Prev`;
  const defaultTitleFirst = t`First`;
  const defaultTitleLast = t`Last`;
  const canLoadMoreNext = totalCount > 0;
  // const canLoadMoreNext = totalCount > 0 && itemsCount < totalCount;

  // Calculate current page
  const currentPage = Math.ceil(itemsCount / pageSize);
  const totalPages = Math.ceil(totalCount / pageSize);

  const handleJumpToPage = useCallback(
    (pageNumber: number) => {
      if (
        pageNumber > 0 &&
        pageNumber <= totalPages &&
        typeof onLoadMore === 'function'
      ) {
        const offset = (pageNumber - 1) * pageSize;
        console.log('Jumping to page:', {
          pageNumber,
          offset,
          totalPages,
        });
        onLoadMore(offset);
      }
    },
    [totalPages, pageSize, onLoadMore],
  );

  const handleJumpInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value.replace(/[^0-9]/g, '');
      setJumpValue(value);
    },
    [],
  );

  const handleBlur = useCallback(() => {
    if (jumpValue) {
      const pageNumber = parseInt(jumpValue, 10);
      handleJumpToPage(pageNumber);
    }
    setIsEditing(false);
    setJumpValue('');
  }, [jumpValue, handleJumpToPage]);

  const handleJumpKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' && jumpValue) {
        const pageNumber = parseInt(jumpValue, 10);
        handleJumpToPage(pageNumber);
        setJumpValue('');
        setIsEditing(false);
      } else if (e.key === 'Escape') {
        setIsEditing(false);
        setJumpValue('');
      }
    },
    [jumpValue, handleJumpToPage],
  );

  return (
    <div
      data-component="ButtonLoadMore"
      data-label={`ButtonLoadMore-${defaultTitleNext || ''}-${defaultTitlePrev || ''}-${defaultTitleFirst || ''}-${defaultTitleLast || ''}`}
      aria-label={`ButtonLoadMore-${defaultTitleNext || ''}-${defaultTitlePrev || ''}-${defaultTitleFirst || ''}-${defaultTitleLast || ''}`}
      id={id}
      className={classNames(
        'flex items-center',
        'ltr:ml-2 rtl:mr-2',
        className,
      )}
    >
      <div className={classNames('flex')}>
        <div
          className={classNames(
            'flex items-center justify-center',
            'text-menu-active text-sm',
            'h-8 min-w-8',
            'mx-2',
            'px-2 py-0.5',
            canLoadMoreNext || onPrev || onFirst || onLast
              ? 'bg-app-background/80 hover:bg-app-background cursor-pointer rounded-full'
              : 'bg-app-background/75 rounded-lg',
          )}
        >
          <div className={classNames('flex items-center')}>
            {onFirst && (
              <Button
                className={classNames(
                  'bg-menu/50 hover:bg-menu',
                  'rounded-full',
                  'text-menu-active text-xxs',
                )}
                buttonSize={'xs'}
                label={defaultTitleFirst}
                onPress={onFirst}
                isDisabled={itemsCount === 1}
              />
            )}
            {onPrev && (
              <Button
                className={classNames(
                  'ltr:ml-1 rtl:ml-1',
                  'ltr:mr-2 rtl:ml-2',
                  'bg-menu/50 hover:bg-menu',
                  'rounded-full',
                  'text-menu-active text-xxs',
                )}
                buttonSize={'xs'}
                label={defaultTitlePrev}
                onPress={onPrev}
                isDisabled={itemsCount === 1}
              />
            )}
            {canLoadMoreNext ? (
              <>
                {isLoading ? (
                  <span className={classNames('flex items-center', 'relative')}>
                    <Loader
                      size={23}
                      stroke={5}
                      type={'loading-original'}
                      className="text-menu-active"
                    />
                  </span>
                ) : (
                  <span
                    className={classNames('flex items-center', 'relative')}
                    onClick={() => setIsEditing(true)}
                  >
                    {isEditing ? (
                      <input
                        type="text"
                        value={jumpValue}
                        onChange={handleJumpInputChange}
                        onKeyDown={handleJumpKeyDown}
                        onBlur={handleBlur}
                        placeholder={currentPage.toString()}
                        className={classNames(
                          'w-12 px-2 py-1',
                          'bg-menu/50',
                          'rounded-full',
                          'text-menu-active text-center text-xs',
                          'focus:border-menu-active focus:outline-none focus:border',
                        )}
                        autoFocus
                      />
                    ) : (
                      <span>{currentPage}</span>
                    )}
                  </span>
                )}
                <span
                  className={classNames('opacity-70', 'mx-2')}
                >{t`of`}</span>
                <span data-component="totalCount">
                  {formatInteger(totalCount)}
                </span>
                <Button
                  className={classNames(
                    'ltr:ml-2 rtl:mr-2',
                    'bg-menu/50 hover:bg-menu',
                    'rounded-full',
                    'text-menu-active text-xxs',
                  )}
                  isLoading={isLoading}
                  isDisabled={isLoading || itemsCount === totalCount}
                  buttonSize={'xs'}
                  label={defaultTitleNext}
                  onPress={onNext}
                />
              </>
            ) : (
              <div
                data-component="LoadMoreNone"
                className={classNames('flex items-center', 'relative')}
              >
                {totalLabel && (
                  <span
                    className={classNames(
                      'opacity-60',
                      'mx-1',
                      'text-minimal uppercase',
                    )}
                  >
                    {totalLabel}
                  </span>
                )}

                {isLoading ? (
                  <span className={classNames('flex items-center', 'relative')}>
                    <Loader
                      size={18}
                      stroke={5}
                      type={'loading-original'}
                      className="text-menu-active"
                    />
                  </span>
                ) : (
                  <span data-component="totalCount">
                    {totalCount === 0 ? (
                      <span className={classNames('opacity-50')}>{`—`}</span>
                    ) : (
                      <span>{formatInteger(totalCount)}</span>
                    )}
                  </span>
                )}
              </div>
            )}
            {onLast && (
              <Button
                className={classNames(
                  'ltr:ml-1 rtl:mr-1',
                  'bg-menu/50 hover:bg-menu',
                  'rounded-full',
                  'text-menu-active text-xxs',
                )}
                buttonSize={'xs'}
                label={defaultTitleLast}
                onPress={onLast}
                isDisabled={isLoading || itemsCount === totalCount}
              />
            )}
          </div>

          {children}
        </div>
      </div>
    </div>
  );
};
