import {
  DatasetObjectFragment,
  SortDirection,
} from '@warebee/frontend/data-access-api-graphql';
import { ActivityFeedEventDataset } from '@warebee/shared/import-converter';
import { TFunction } from 'i18next';
import _ from 'lodash';
import { atom, selector, selectorFamily } from 'recoil';
import { persistAtom } from '../../common/recoil/persistAtom';
import {
  AsyncLoadStatus,
  DataTableState,
  WizardStepConfig,
} from '../../common/types';
import { datasetObjectMenuItems } from './datasetObject.default';
import {
  DatasetObjectDataRows,
  DatasetObjectMenuItemId,
} from './datasetObject.types';

const getKey = (postfix: string) => `warebee-dataset-object-${postfix}`;

export const datasetObjectDocumentId = atom<string>({
  key: getKey('document-id'),
  default: null,
});

export const datasetObjectDocument = atom<DatasetObjectFragment>({
  key: getKey('document'),
  default: null,
});

export const datasetObjectDocumentLoadStatus = atom<AsyncLoadStatus>({
  key: getKey('document-load-state'),
  default: AsyncLoadStatus.None,
});

export const datasetObjectDocumentSaveStatus = atom<AsyncLoadStatus>({
  key: getKey('document-save-state'),
  default: AsyncLoadStatus.None,
});

const datasetObjectSelectedMenuItemIdAll = persistAtom<
  Record<string, DatasetObjectMenuItemId>
>({
  key: getKey('selected-menu-item-id-all'),
  default: {},
});

export const datasetObjectSelectedMenuItemId = selectorFamily<
  DatasetObjectMenuItemId,
  string
>({
  key: getKey('selected-menu-item-id'),
  get:
    (datasetObjectId: string) =>
    ({ get }) => {
      return (
        get(datasetObjectSelectedMenuItemIdAll)?.[datasetObjectId] ||
        'dataset-object-menu-start'
      );
    },

  set:
    (datasetObjectId: string) =>
    ({ get, set }, value: DatasetObjectMenuItemId) => {
      set(datasetObjectSelectedMenuItemIdAll, {
        ...get(datasetObjectSelectedMenuItemIdAll),
        [datasetObjectId]: value,
      });
    },
});

export const datasetObjectSelectedMenuItem = selector<
  WizardStepConfig<DatasetObjectMenuItemId>
>({
  key: getKey('selected-step'),
  get: ({ get }) => {
    const datasetId = get(datasetObjectDocumentId);
    const menuId = get(datasetObjectSelectedMenuItemId(datasetId));
    return _.find(
      datasetObjectMenuItems(_.identity as TFunction),
      s => s.id === menuId,
    );
  },
});

export const datasetObjectData = atom<DatasetObjectDataRows>({
  key: getKey('table-data'),
  default: {
    totalCount: 0,
    content: [],
    columns: [],
  },
});

export const datasetObjectDataLoadStatus = atom<AsyncLoadStatus>({
  key: getKey('table-data-status'),
  default: AsyncLoadStatus.None,
});

export const datasetObjectDataState = atom<
  DataTableState<keyof ActivityFeedEventDataset>
>({
  key: getKey('table-data-state'),
  default: {
    sortValues: {
      eventEndTime: SortDirection.DESC,
    },
    searchValues: {},
  },
});
